import GeoCodingAPI from "../../api/GISLocationService/GeoCodingAPI";

class GeoCoding {
	static async getLocationByAddress(address) {
		const result = await GeoCodingAPI.GetLocationByAddress(address);
		if (result?.candidates[0]) {
			return [result.candidates[0].location.y.toFixed(5), result.candidates[0].location.x.toFixed(5)];
		}
	}
}

export default GeoCoding;
