import React from "react";
import "./common.scss";

const CheckBox = ({ name, label, options, type, value, defaultValue, isRequired = false, ...rest }) => {
  return (
    <div className="form-group text-left">
      <fieldset>
        <legend className="formLabel">
          {label}
          <span className="required">{isRequired ? " *" : ""}</span>
        </legend>
        {options.map((option, index) => (
          <div key={`${option}-key`} className={`form-check form-check-inline${index < options.length ? " mr-4" : ""}`}>
            <input
              className="form-check-input formData"
              name={name}
              id={`${option}-id`}
              {...rest}
              type={type}
              value={option}
              checked={option === value || option === defaultValue}
            />
            <label className="form-check-label" htmlFor={`${option}-id`}>
              {option}
            </label>
          </div>
        ))}
      </fieldset>
    </div>
  );
};

export default CheckBox;
