import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";

class CreateEditFateModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: this.props?.modalData
        ? this.props.modalData
        : {
            fateUniqueIdentifier: "",
            name: "",
            isAllowedGlobally: true,
            isAllowed: true,
            createdBy: "",
            createdDateTimeUTC: "",
            lastModifiedBy: "",
            lastModifiedDateTimeUTC: "",
          },
      errors: {},
    };
  }

  async doSubmit(e) {
    let form = e.currentTarget;
    if (form.checkValidity()) {
      this.setState({ validated: true });
      if (this.state.modalMode === "Create") {
        await this.props.createFate(this.state.data);
      } else if (this.state.modalMode === "Edit") {
        await this.props.editFate(this.state.data);
      }
    }
  }

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  createFateModalUI() {
    return (
      <div className={styles.createFateModalBody}>
        <Row>
          <Col>{this.renderInput("name", "Fate Name", "text", "Enter name", "required")}</Col>
        </Row>
      </div>
    );
  }

  editFateModalUI() {
    return (
      <div className={styles.editFateModalBody}>
        <Row>
          <Col className={styles.editFateModalGuid}>
            <label className={globalStyles.modalFormLabel}>
              ID
              <p id="editFateModalGuid">{this.state.data.fateUniqueIdentifier}</p>
            </label>
          </Col>
        </Row>
        <Row>
          <Col className={styles.editFateModalFateName}>
            {this.renderInput("name", "Fate Name", "text", "Enter name", "required")}
          </Col>
        </Row>
        <Row>
          <Col className={styles.editFateModalStatus}>
            {this.renderInlineCheckBoxSet(
              "isAllowedGlobally",
              "Status",
              ["Active", "Inactive"],
              "radio",
              this.state.data.isAllowedGlobally ? "Active" : "Inactive"
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editFateModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editFateModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createFateModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editFateModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Modal.Header className={globalStyles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} Fate</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyContent}</Modal.Body>
            <Modal.Footer className={globalStyles.modalFooter}>
              <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <Button type="submit" className={globalStyles.ModalCreateButton} disabled={this.state.data.name === ""}>
                <span className={globalStyles.ModalCreateButtonText}>{submitButtonText}</span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default CreateEditFateModal;

