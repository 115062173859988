import React from "react";
import { withRouter } from "react-router";
import styles from "../agreementEntities.module.scss";
import CustomForm from "../../common/form";
import AgreementAPI from "../../../api/AgreementEntities/AgreementAPI";
import { Button, Modal, Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

class ConflictingAgreementParcelModal extends CustomForm {
  state = {};

  handleAgreementNavigation = async (agreementGuid) => {
    const getAgreementAction = await AgreementAPI.GetAgreement(agreementGuid);
    if (getAgreementAction.successful) {
      this.props.history.push({
        pathname: "/agreement/details",
        state: { entityData: getAgreementAction.data },
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get Agreement",
        apiName: "GetAgreement",
        responseUnsuccessful: getAgreementAction?.unsuccessful,
        responseMessage: getAgreementAction?.message,
      });
    }
  };

  render() {
    let warningText =
      "A Parcel being used in the creation of this Property is already covered by a Workable (Pending or Active) Agreement, linked below.";
    if (this.props.usedOnAgreementSignature) {
      warningText =
        "A Parcel being used in the creation of this Agreement is already covered by a Workable (Pending or Active) Agreement, linked below. That Agreement must be expired before including this Parcel on a new Agreement.";
    }

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered size="lg">
        <div className="d-flex justify-content-center">
          <i className="fa fa-exclamation-circle fa-5x" style={{ color: "gray" }} aria-hidden="true"></i>
        </div>
        <Modal.Header>
          <div className="container ">
            <Row className="justify-content-center">
              <Col sm="auto">
                <h3>Warning!</h3>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <p className={styles.modalCenteredData}>{warningText}</p>
            </Row>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>Conflicting Agreements</Card.Header>
            <Card.Body>
              {this.props.conflictingAgreementUniqueIdentifiers.map((agreementGuid, index) => {
                return (
                  <Row xs={1} key={index}>
                    <span
                      className={styles.agreementCardLinks}
                      onClick={() => this.handleAgreementNavigation(agreementGuid)}
                    >
                      {agreementGuid}
                    </span>
                  </Row>
                );
              })}
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(ConflictingAgreementParcelModal);
