import { Component } from "react";
// import { appInsights } from "./AppInsights";
// import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { toast } from "react-toastify";
import { config } from "../../package.json";

class ErrorHandler extends Component {
  static handleError = (error, errorMessage) => {
    //log , track and show error
    this.showError(error, errorMessage);
    this.sendToAppInsights(error, errorMessage);
  };

  static handleApiErrorMessage = ({
    errorContextMessage,
    apiName,
    responseUnsuccessful,
    responseMessage,
    signal,
    overrideErrorToLog,
    overrideErrorForUser,
  }) => {
    if (signal?.aborted) {
      return;
    }
    const appInsightsMessage =
      overrideErrorToLog ||
      `${errorContextMessage}. ${apiName} call ${responseUnsuccessful ? "was unsuccessful" : "failed"}.`;
    const userMessage =
      overrideErrorForUser ||
      `${errorContextMessage}. ${responseMessage ? responseMessage : "Please try again later or contact your system admin"
      }.`;
    this.showErrorWithDetails(appInsightsMessage, userMessage);
    this.sendToAppInsights(appInsightsMessage);
  };

  static sendToAppInsights = (error) => {
    // track
    return;
    // var current = new Date();
    // appInsights.trackException({
    //   error: error + " User: " + localStorage.getItem("currentUserName") + " @ " + current.toLocaleString(),
    //   severityLevel: SeverityLevel.Error,
    // });
  };

  static logError(param1, param2) {
    switch (arguments.length) {
      case 1: {
        if (typeof arguments[0] === "object") this.logErrorwithObject(param1);
        else if (typeof arguments[0] === "string") this.logErrorWithMessage(param1);
        break;
      }
      case 2: {
        this.logErrorWithDetails(param1, param2);
        break;
      }
      default: {
        this.logErrorWithMessage(param1);
        break;
      }
    }
  }

  static showError(param1, param2) {
    switch (arguments.length) {
      case 1: {
        this.showErrorWithMessage(param1);
        break;
      }
      case 2: {
        if (typeof arguments[0] === "object") {
          this.showErrorWithDetails(param1, param2);
        }
        break;
      }
      default: {
        this.logErrorWithMessage(param1);
        break;
      }
    }
  }

  static logErrorwithObject = (error) => {
    if (config.environment.toLowerCase() !== "production") {
      console.log("Error Object: " + JSON.stringify(error) + ", Error Message: " + error.message);
    }
  };

  static logErrorWithMessage = (errorMessage) => {
    if (config.environment.toLowerCase() !== "production") {
      console.log("Error Message: " + errorMessage);
    }
  };

  static logErrorWithDetails(error, errorMessage) {
    if (config.environment.toLowerCase() !== "production") {
      console.log("Error Object: " + JSON.stringify(error) + ", Error Message: " + errorMessage);
    }
  }

  static showErrorWithDetails = (error, errorMessage) => {
    this.logErrorWithDetails(error, errorMessage);
    toast.error(errorMessage);
  };

  static showErrorWithMessage = (errorMessage) => {
    this.logErrorWithMessage(errorMessage);
    toast.error(errorMessage);
  };

  static showError;
}

export default ErrorHandler;
