import React from "react";
import CustomForm from "../common/form";
import styles from "./Warehouse.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, CloseButton } from "react-bootstrap";
import { toast } from "react-toastify";

class ViewTransactionHistoryModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        startDate: "",
        endDate: "",
      },
      currentUsaState: "",
      errors: {},
    };
  }

  componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState?.stateGuid) {
      toast.error("Error loading current State Id on Add Method Modal");
      return;
    }
    this.setState({ currentUsaState });
  }

  handleSpecificChanges(input) {
    if (["startDate", "endDate"].includes(input.name) && input.value) {
      const { data, errors } = this.state;
      if (new Date(Date.parse(input.value)) > new Date()) {
        errors[input.name] = "Date can not be in the future.";
        data[input.name] = "";
      } else {
        delete errors[input.name];
        data[input.name] = input.value;
      }

      this.setState({ data, errors });
    }
  }

  doSubmit = async () => {
    if (!this.isFormDataValid()) {
      const { data, errors } = this.state;
      if (!data.startDate) {
        errors.startDate = "Please enter a date";
      }
      if (!data.endDate) {
        errors.endDate = "Please enter a date";
      }
      this.setState({ errors });
    } else {
      this.props.onHide();
      this.props.onSubmit({ ...this.state.data });
      this.clearData();
    }
  };

  isFormDataValid() {
    const { data } = this.state;
    return data.startDate && data.endDate;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.startDate = "";
    data.endDate = "";
    this.setState({
      data,
      errors: {},
    });
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Transaction History</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 mt-1">
            <Col>{this.renderInput("startDate", "Start Date", "date", "Enter")}</Col>
          </Row>
          <Row className="mx-0 mt-1">
            <Col>{this.renderInput("endDate", "End Date", "date", "Enter")}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button
            className={this.isFormDataValid() ? "ml-2" : styles.greyedOutModalSaveButton}
            variant="primary"
            type="submit"
            onClick={this.doSubmit}
          >
            <span className={this.isFormDataValid() ? globalStyles.modalSubmitButtonText : styles.greyedOutButtonText}>
              Submit
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ViewTransactionHistoryModal;
