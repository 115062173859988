import API from "../API";
import { APIRoutes } from "../APIRoutes";
import ReferenceFileAPI from "../ReferenceFiles/ReferenceFileAPI";
import Permissions from "../../components/UserRoles/PermissionsClasses/Permissions";
class RoleAuthorizationAPI {
  static async GetAuthConfig() {
    let url = "/Token/Config";
    let api = new API(url, APIRoutes.RoleAuthorization);
    return await api.get();
  }

  static async GetUserByEAuthId(eauthID) {
    let url = "/User/GetUserByEAuthId/" + eauthID;
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    return result;
  }

  static async GetCurrentUser() {
    let url = "/User/GetCurrentUser";
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    return result;
  }

  static async GetTokenForCurrentUser() {
    let url = "/Token/GetTokenForCurrentUser";
    let api = new API(url, APIRoutes.RoleAuthorization);
    return await api.get();
  }

  static async GetAllUsers(pageSize, pageNumber) {
    let url = "/User/GetAllUsers/" + pageSize + "/" + pageNumber;
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    return result;
  }

  static async GetAllUsersWithProfileDetails(pageSize, pageNumber) {
    let url = "/User/GetAllUsersWithProfileDetails/" + pageSize + "/" + pageNumber;
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    return result;
  }

  static async GetFilteredUsersTableData(
    userName,
    type,
    email,
    phoneNumber,
    cityAndState,
    profileRole,
    profileState,
    lastModified,
    active,
    sortColumn,
    isAscending
  ) {
    const api = new API("/User/GetFilteredUsersTableData/", APIRoutes.RoleAuthorization);
    const body = {
      userName: userName,
      type: type,
      email: email,
      phoneNumber: phoneNumber,
      cityAndState: cityAndState,
      profileRole: profileRole,
      profileState: profileState,
      lastModified: lastModified,
      active: active,
      sortColumn: sortColumn,
      isAscending: isAscending,
    };

    return await api.put(body);
  }

  static async GetAllUserTypes() {
    let url = "/User/GetAllUserTypes";
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    return result;
  }

  static async GetRole(roleUniqueIdentifier) {
    let url = "/Role/GetRole/" + roleUniqueIdentifier;
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    return result;
  }

  static async GetPermissionsForRole(roleUniqueReference) {
    const api = new API(`/Role/GetPermissionsForRole/${roleUniqueReference}`, APIRoutes.RoleAuthorization);
    const result = await api.get();
    return result;
  }

  static async SearchUsersFreeText(body) {
    const api = new API(`/User/SearchUsersFreeText`, APIRoutes.RoleAuthorization);
    const result = await api.put(body);
    return result;
  }

  static async AddPermissionsToRole(roleUniqueReference, permissionEnumArray) {
    const api = new API(`/Role/AddPermissionsToRole/${roleUniqueReference}`, APIRoutes.RoleAuthorization);
    const result = await api.post({ permissionEnumIds: permissionEnumArray });
    return result;
  }

  static async RemovePermissionsFromRole(roleUniqueReference, permissionEnumArray) {
    const api = new API(`/Role/RemovePermissionsFromRole/${roleUniqueReference}`, APIRoutes.RoleAuthorization);
    const result = await api.post({ permissionEnumIds: permissionEnumArray });
    return result;
  }

  static async GetAllRoles() {
    let url = "/Role/GetAllRoles/0/0";
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Role/GetAllRoles/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.RoleAuthorization);
      result = await api.get();
    }
    return result;
  }

  static async GetAllRolesWithPermissions() {
    let url = "/Role/GetAllRolesWithPermissions/0/0";
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Role/GetAllRolesWithPermissions/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.RoleAuthorization);
      result = await api.get();
    }

    return result;
  }

  static async GetUsersCurrentProfile() {
    const api = new API("/User/GetUsersCurrentProfile", APIRoutes.RoleAuthorization);
    const result = await api.get();
    return result;
  }

  static async ResolveUserProfiles(body) {
    const api = new API(`/User/ResolveUserProfiles`, APIRoutes.RoleAuthorization);
    const result = await api.put(body);
    return result;
  }

  static async ResolveUserProfilesWithDistrictAssignments(body) {
    const api = new API("/User/ResolveUserProfilesWithDistrictAssignments", APIRoutes.RoleAuthorization);
    const result = await api.put(body);
    return result;
  }

  static async CreateRole(roleName) {
    const api = new API(`/Role/CreateRole/${roleName}`, APIRoutes.RoleAuthorization);
    const result = await api.post();
    return result;
  }

  static async RemoveUserProfile(wsUserProfileUniqueIdentifier) {
    const api = new API(`/User/RemoveUserProfile/${wsUserProfileUniqueIdentifier}`, APIRoutes.RoleAuthorization);
    const result = await api.post();
    return result;
  }

  static async LocalCookieSignin(email, username, displayName) {
    let url = `/User/LocalCookieSignin/${encodeURIComponent(email)}/${encodeURIComponent(
      username
    )}/${encodeURIComponent(displayName)}`;
    let api = new API(url, APIRoutes.RoleAuthorization);
    let result = await api.get();
    return result;
  }

  static async MyPermissions() {
    let getUSStates = await ReferenceFileAPI.GetUSStates();
    if (!getUSStates) {
      getUSStates = [
        "8de06d78-fb39-45db-b74b-4b2f4ff03081",
        "a6b35b2d-6713-4c02-b1cb-3d8b3f2c99bd",
        "b86f070e-2365-4f1e-a9b9-7a386e141fd8",
        "4a75e2d2-a9dd-4aa2-8dd0-4325e5215b5f",
        "519afda9-71b0-4f2b-962e-2c1b64270dab",
        "8c10d6b8-278b-490c-8fc1-8734e4019c4e",
        "21770a0d-b88c-48d3-aeb3-5779d4294459",
        "d043dd43-cc82-4220-bd95-2144e72cb368",
      ];
    }
    return {
      successful: true,
      data: {
        associatedStates: getUSStates.map((s) => {
          return s.stateUniqueIdentifier;
        }),
        permissions: ["ViewAnyAgreement", "EditMyAssignedStateAgreements"],
      },
    };
  }

  static async GetUserProfile(userPrincipleName, profileName) {
    return {
      successful: true,
      data: {
        wsUserProfileUniqueIdentifier: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        profileName: "string",
        roleUniqueReference: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        profileAssociatedStates: ["519afda9-71b0-4f2b-962e-2c1b64270dab"],
        specialPermissionForProfile: [
          {
            specialPermission: {
              id: 8,
              name: "Contact_Create_All",
              displayText: "Contact - Create",
            },
            specialPermissionAssociatedStates: ["519afda9-71b0-4f2b-962e-2c1b64270dab"],
          },
          {
            specialPermission: {
              id: 9,
              name: "Contact_Edit_All",
              displayText: "Contact - Edit",
            },
            specialPermissionAssociatedStates: ["519afda9-71b0-4f2b-962e-2c1b64270dab"],
          },
          {
            specialPermission: {
              id: 10,
              name: "Contact_View_All",
              displayText: "Contact - View",
            },
            specialPermissionAssociatedStates: ["519afda9-71b0-4f2b-962e-2c1b64270dab"],
          },
        ],
      },
    };
  }

  static async GetAllPermissions() {
    const api = new API("/Role/GetAllPermissions/", APIRoutes.RoleAuthorization);
    const result = await api.get();

    const permissions = new Permissions();
    permissions.init();
    const unusedPermissionsToHide = [
      permissions.conflictPermissions.flag,
      permissions.projectPermissions.assignUserDistrict,
      permissions.specialGroupPermissions.createAnyState,
      permissions.specialGroupPermissions.createAssignedStates,
      permissions.specialGroupPermissions.editAnyState,
      permissions.specialGroupPermissions.editAssignedStates,
      permissions.specialGroupPermissions.viewAnyState,
      permissions.specialGroupPermissions.viewAssignedStates,
      permissions.notificationPermissions.createAll,
      permissions.inventoryPermissions.createAssignedStates,
      permissions.inventoryPermissions.editAssignedStates,
      permissions.inventoryPermissions.viewAnyState,
      permissions.inventoryPermissions.viewAssignedStates,
      permissions.agreementsPermissions.createAnyState,
      permissions.agreementsPermissions.editAnyState,
      permissions.agreementsPermissions.flagAnyState,
      permissions.agreementsPermissions.viewAnyState,
      permissions.referenceDataPermissions.configureAnyState,
      permissions.referenceDataPermissions.viewAnyState,
    ];
    result.data = result.data.filter((x) => !unusedPermissionsToHide.includes(x.name));

    return result;
  }

  static async GetUserWhoOwnsProfile(wsUserProfileUniqueIdentifier) {
    const api = new API(`/User/GetUserWhoOwnsProfile/${wsUserProfileUniqueIdentifier}`, APIRoutes.RoleAuthorization);
    const result = await api.get();
    return result;
  }

  static async CreateUserProfile(body) {
    const api = new API("/User/CreateUserProfile", APIRoutes.RoleAuthorization);
    const result = await api.post(body);
    return result;
  }

  static async AssociateUserProfileWithStates(userPrincipleName, profileName, body) {
    const api = new API(
      `/User/AssociateUserProfileWithStates/${userPrincipleName}/${profileName}`,
      APIRoutes.RoleAuthorization
    );
    const result = await api.post(body);
    return result;
  }

  static async DisassociateStatesFromUserProfile(userPrincipleName, profileName, body) {
    const api = new API(
      `/User/DisassociateStatesFromUserProfile/${userPrincipleName}/${profileName}`,
      APIRoutes.RoleAuthorization
    );
    const result = await api.post(body);
    return result;
  }

  static async ProvisionUser(body) {
    const api = new API("/User/ProvisionUser", APIRoutes.RoleAuthorization);
    const result = await api.post(body);
    return result;
  }

  static async UpdateUser(body, userId) {
    const api = new API(`/User/UpdateUser/${userId}`, APIRoutes.RoleAuthorization);
    const result = await api.post(body);
    return result;
  }

  static async SetUsersCurrentProfile(profileName, stateUniqueReference) {
    const api = new API(
      `/User/SetUsersCurrentProfile/${profileName}/${stateUniqueReference}`,
      APIRoutes.RoleAuthorization
    );
    const result = await api.post();
    return result;
  }
  static async GetTokenWithUserContext(profile, stateUniqueReference) {
    const api = new API(
      `/User/GetTokenWithUserContext/${profile}/${stateUniqueReference}`,
      APIRoutes.RoleAuthorization
    );
    const result = await api.get();
    return result;
  }

  static async GetUser(userId) {
    const api = new API(`/User/GetUser/${userId}`, APIRoutes.RoleAuthorization);
    const result = await api.get();
    return result;
  }

  static async QuickEditUser(wsUserUniqueIdentifier, primaryPhoneNumber, isActive) {
    const api = new API(`/User/QuickEditUser/${wsUserUniqueIdentifier}`, APIRoutes.RoleAuthorization);
    const result = await api.post({ primaryPhoneNumber: primaryPhoneNumber, isActive: isActive });
    return result;
  }

  static async AssociateSpecialPermissionToUserProfile(userPrincipleName, profileName, body) {
    const api = new API(
      `/User/AssociateSpecialPermissionToUserProfile/${userPrincipleName}/${profileName}`,
      APIRoutes.RoleAuthorization
    );
    const result = await api.post(body);
    return result;
  }

  static async DisassociateSpecialPermissionFromUserProfile(userPrincipleName, profileName, body) {
    const api = new API(
      `/User/DisassociateSpecialPermissionFromUserProfile/${userPrincipleName}/${profileName}`,
      APIRoutes.RoleAuthorization
    );
    const result = await api.post(body);
    return result;
  }

  static async SetUserFavoriteReferenceFileData(userPrincipleName, body) {
    const api = new API(`/User/SetUserFavoriteReferenceFileData/${userPrincipleName}`, APIRoutes.RoleAuthorization);
    return await api.post(body);
  }

  static async AddUserPropertyProtectedResourceRatios(userPrincipleName, propertyUniqueReference, body) {
    const api = new API(
      `/User/AddUserPropertyProtectedResourceRatios/${userPrincipleName}/${propertyUniqueReference}`,
      APIRoutes.RoleAuthorization
    );
    return await api.post(body);
  }

  static async SearchDistrictFreetext(stateId, body, signal) {
    const api = new API(`/District/SearchDistrictFreetext/${stateId}`, APIRoutes.RoleAuthorization);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async CreateDistrict(body) {
    const api = new API("/District/CreateDistrict", APIRoutes.RoleAuthorization);
    const result = await api.post(body);
    return result;
  }

  static async SetDistrictUsers(districtId, body) {
    const api = new API(`/District/SetDistrictUsers/${districtId}`, APIRoutes.RoleAuthorization);
    const result = await api.post(body);
    return result;
  }

  static async GetDistrict(districtId) {
    const api = new API(`/District/GetDistrict/${districtId}`, APIRoutes.RoleAuthorization);
    const result = await api.get();
    return result;
  }

  static async UpdateDistrict(districtId, body) {
    const api = new API(`/District/UpdateDistrict/${districtId}`, APIRoutes.RoleAuthorization);
    const result = await api.post(body);
    return result;
  }

  static async GetDistrictsForProfiles(body) {
    const api = new API("/District/GetDistrictsForProfiles", APIRoutes.RoleAuthorization);
    const result = await api.put(body);
    return result;
  }
}

export default RoleAuthorizationAPI;
