import React from "react";
import { Modal, Button, Row, Col, CloseButton, Form } from "react-bootstrap";
import CustomForm from "../../../components/common/form";
import globalStyles from "../../../OARS.module.scss";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

class FlagAgreementModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: { reasonForFlag: "", flagHistory: "", newFlagHistory: "" },
      errors: {},
    };
  }
  handleSpecificChanges = async (input) => {
    //do nothing
  };

  doSubmit = () => {
    if (!this.state.data.reasonForFlag && !this.props.flagHistory) {
      ErrorHandler.showError("Please provide the reason for flagging");
    } else if (!this.state.data.newFlagHistory && this.props.flagHistory) {
      ErrorHandler.showError("Please provide your comments");
    } else {
      this.props.onHide();
      this.props.onSave(this.state.data);
      this.clearData();
    }
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.reasonForFlag = "";
    data.newFlagHistory = "";
    this.setState({ data });
  }

  renderFlagHistory() {
    if (this.props.flagHistory) {
      return (
        <div>
          <Row>
            <Col>
              <Form.Label className={globalStyles.formLabel}>
                <b>Reason for Flag</b>
              </Form.Label>
            </Col>
          </Row>

          <Row>
            <Col>
              <span>{this.props.flagHistory[0].flagReason}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <br></br>
            </Col>
          </Row>
          <Row hidden={!this.props.flagHistory[0].conversation.length > 0}>
            <Col>
              <Form.Label className={globalStyles.formLabel}>
                <b>Comments History</b>
              </Form.Label>
            </Col>
          </Row>
          {this.props.flagHistory[0].conversation.map((c, index) => {
            return (
              <Row key={index}>
                <Col>
                  <span className={globalStyles.formLabel}>
                    <b>{"@" + c.messageSenderName + "  " + new Date(Date.parse(c.timeSent)).toLocaleString("en-US")}</b>
                  </span>
                  <br></br>
                  <span>{c.messageContents}</span>
                  <br></br>
                </Col>
              </Row>
            );
          })}
          <Row>
            <Col>
              {" "}
              <br></br>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className={globalStyles.formLabel}>
                <b>Comments</b>
              </Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                name="newFlagHistory"
                value={this.state.data.newFlagHistory}
                placeholder="Enter"
                as="textarea"
                rows="5"
                style={{ resize: "none" }}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </div>
      );
    }
  }

  render() {
    let isFlagReason = false;
    let pageTitle = "Flag";
    if (this.props.flagHistory) {
      isFlagReason = true;
      pageTitle = "Flag History";
    }

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>{pageTitle}</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 my-3 text-left" hidden={isFlagReason}>
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Reason for Flag</Form.Label>
                <Form.Control
                  name="reasonForFlag"
                  value={this.state.data.reasonForFlag}
                  placeholder="Enter Reasons for Flagging"
                  as="textarea"
                  rows="5"
                  style={{ resize: "none" }}
                  onChange={this.handleChange}
                />
                {this.state.errors.reasonForFlag && (
                  <div className="alert alert-danger">{this.state.errors.reasonForFlag}</div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>{this.renderFlagHistory()}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>Submit</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FlagAgreementModal;
