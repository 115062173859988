import React from "react";
import CustomForm from "../common/form";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Form, Button, Row, Col, Image, Dropdown, CloseButton } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import nav1 from "../../assets/speciesSelectionModalNav1.png";
import nav2 from "../../assets/speciesSelectionModalNav2.png";
import chevronDownIcon from "../../assets/chevron-down.svg";
import searchIcon from "../../assets/search.svg";
import BootstrapTable from "react-bootstrap-table-next";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";

const ChevronDown = () => {
  return <img src={chevronDownIcon} alt="down arrow" />;
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    iconafter={searchIcon}
    onClick={onClick}
    className={styles.propertyAssociationDropdownMenuButton}
    variant="light"
  >
    <Row className="mx-0">
      <Col className="text-left pr-1 pl-0">
        <span className={styles.propertyAssocDropdownPlaceholder}>{children}</span>
      </Col>
      <Col sm={1} className="text-right px-0 pb-2">
        <ChevronDown />
      </Col>
    </Row>
  </Button>
));

const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-label="Crew Menu">
      <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
    </div>
  );
});

const tableStyles = {
  columnHeaderStyle1: {
    width: "20%",
    fontSize: "14px",
    borderBottom: "0.8px solid #008767",
    textAlign: "left",
  },
  columnHeaderStyle2: {
    width: "35%",
    fontSize: "14px",
    borderBottom: "0.8px solid #008767",
    textAlign: "left",
  },
  columnHeaderStyle3: { width: "15%", borderBottom: "0.8px solid #008767" },
  columnBodyStyle1: { width: "25%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "left" },
  columnBodyStyle2: { width: "35%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "left" },
  columnBodyStyle3: { width: "15%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "right" },
};

class SpeciesSelectionModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        selectedSpecies: [],
        selectedSpeciesForAssociation: [],
        speciesNameSearch: "",
        speciesClassesSearch: "",
        speciesOrdersSearch: "",
        speciesFamiliesSearch: "",
        speciesCategoriesSearch: "",
      },
      favoriteSpecies: [],
      allSpecies: [],
      allSpeciesOriginalData: [],
      filteredSpecies: [],
      speciesClasses: [],
      speciesOrders: [],
      speciesFamilies: [],
      speciesCategories: [],
      checkedPropertiesInDropdown: [],
      isInitialPage: true,
      isAdditionalSpeciesSearch: false,
      showPropertyAssociationDropdownMenu: false,
      selectedSpeciesTableData: [],
      selectedSpeciesColumns: [
        {
          dataField: "propertyUniqueIdentifier",
          hidden: true,
        },
        {
          dataField: "propertyName",
          text: "Property Name",
          headerStyle: () => {
            return tableStyles.columnHeaderStyle2;
          },
          style: tableStyles.columnBodyStyle1,
        },
        {
          dataField: "associatedSpecies",
          text: "Associated Damage Agents",
          formatter: (cell, row) => {
            return cell.map((s, index) => {
              if (index === cell.length - 1) {
                return s.name;
              } else {
                return s.name + ", ";
              }
            });
          },
          headerStyle: () => {
            return tableStyles.columnHeaderStyle1;
          },
          style: tableStyles.columnBodyStyle1,
        },
        {
          dataField: "speciesEditAssociations",
          text: "",
          formatter: (cell, row) => {
            return (
              <Button variant="link" className="px-0" onClick={() => this.deleteSpecies(row)}>
                <span className={styles.smallActionButtonsText}>Remove</span>
              </Button>
            );
          },
          headerStyle: () => {
            return tableStyles.columnHeaderStyle3;
          },
          style: tableStyles.columnBodyStyle3,
        },
      ],
      errors: {},
    };
  }

  async componentDidMount() {
    const { data } = this.state;
    if (!this.props.currentUsaState?.stateGuid) {
      toast.error("Error loading current State Id on Damage Agent Selection Modal");
      return;
    }

    let { allSpecies, allSpeciesOriginalData, speciesClasses, speciesOrders, speciesFamilies, speciesCategories } =
      this.state;
    const allSpeciesResponse = await ReferenceFileAPI.GetAllSpeciesByState(this.props.currentUsaState.stateGuid);
    if (allSpeciesResponse?.successful && allSpeciesResponse.data?.results?.length > 0) {
      allSpecies = allSpeciesResponse.data.results
        .filter((s) => s.isAllowedForState)
        .map((s) => ({
          ...s,
          isSelected: data.selectedSpecies?.find(
            (existingSpecies) => existingSpecies.speciesUniqueIdentifier === s.speciesUniqueIdentifier
          )
            ? true
            : false,
        }));
      allSpeciesOriginalData = JSON.parse(JSON.stringify(allSpecies));
      const speciesAllCategorizationResponse = await ReferenceFileAPI.GetAllSpeciesCategorizationInfo(
        this.props.currentUsaState.stateGuid
      );
      if (speciesAllCategorizationResponse?.successful) {
        speciesClasses = speciesAllCategorizationResponse.data?.allClasses?.map((item) => {
          return { value: item, label: item };
        });
        speciesOrders = speciesAllCategorizationResponse.data?.allOrders?.map((item) => {
          return { value: item, label: item };
        });
        speciesFamilies = speciesAllCategorizationResponse.data?.allFamilies?.map((item) => {
          return { value: item, label: item };
        });
        speciesCategories = speciesAllCategorizationResponse.data?.allSubTypes?.map((item) => {
          return { value: item, label: item };
        });
      } else {
        toast.error("Unable to retrieve damage agent categorization info");
      }

      this.setState({
        allSpecies,
        allSpeciesOriginalData,
        favoriteSpecies: this.getFavoriteSpecies(allSpecies),
        speciesClasses,
        speciesOrders,
        speciesFamilies,
        speciesCategories,
        data,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      let { data, allSpecies } = this.state;
      allSpecies.forEach((s) => {
        s.isSelected = data.selectedSpecies?.find(
          (existingSpecies) => existingSpecies.speciesUniqueIdentifier === s.speciesUniqueIdentifier
        )
          ? true
          : false;
      });
      this.setState({
        allSpecies,
        favoriteSpecies: this.getFavoriteSpecies(allSpecies),
        data,
      });
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  getFavoriteSpecies(allSpecies) {
    let favoriteSpecies = [];
    if (this.props.favoriteSpeciesIds?.length > 0 && allSpecies?.length > 0) {
      this.props.favoriteSpeciesIds.forEach((id) => {
        const matchingSpecies = allSpecies.find((s) => s.speciesUniqueIdentifier === id);
        if (matchingSpecies) {
          favoriteSpecies.push({ speciesUniqueIdentifier: id, name: matchingSpecies.name, isFavorite: true });
        }
      });
    }

    return favoriteSpecies;
  }

  doSubmit = async () => {
    if (this.state.isInitialPage && this.props.properties?.length > 1) {
      if (!(this.state.data.selectedSpecies?.length > 0)) {
        toast.warning("At least one damage agent must be selected.");
        return;
      }

      const { data } = this.state;

      this.setState({ data, isInitialPage: false });
    } else {
      if (!this.isFormDataValid()) {
        if (this.props.properties?.length < 2) {
          toast.warning("At least one damage agent must be selected.");
        } else {
          toast.warning("Please provide at least one associated property for each damage agent.");
        }
        return;
      }

      if (this.props.properties?.length < 2) {
        this.state.data.selectedSpecies.forEach((species) => species.checkedProperties.push(this.props.properties[0]));
      }

      this.props.onHide();
      this.props.onSave([...this.state.data.selectedSpecies]);
      this.clearData();
    }
  };

  isFormDataValid() {
    let isDataCompleted = true;
    if (this.state.data.selectedSpecies.length < 1) {
      isDataCompleted = false;
    }
    if (this.props.properties?.length < 2) {
      return isDataCompleted;
    }
    this.state.data.selectedSpecies.forEach((species) => {
      if (!(species.checkedProperties?.length > 0)) {
        isDataCompleted = false;
      }
    });
    return isDataCompleted;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearFilters = () => {
    const { data } = this.state;
    data.speciesNameSearch = "";
    data.speciesClassesSearch = "";
    data.speciesOrdersSearch = "";
    data.speciesFamiliesSearch = "";
    data.speciesCategoriesSearch = "";
    this.setState({ data });
  };

  clearData() {
    const { allSpeciesOriginalData, data } = this.state;
    this.clearFilters();
    data.selectedSpecies = [];
    data.selectedSpeciesForAssociation = [];
    this.setState({
      data,
      isInitialPage: true,
      isAdditionalSpeciesSearch: false,
      allSpecies: JSON.parse(JSON.stringify(allSpeciesOriginalData)),
      filteredSpecies: [],
      favoriteSpecies: this.getFavoriteSpecies(allSpeciesOriginalData),
      selectedSpeciesTableData: [],
    });
  }

  handleSpeciesSelection = (selectedSpecies, isSelectAll) => {
    const { data, favoriteSpecies, allSpecies } = this.state;

    const favoriteSpecie = favoriteSpecies.find(
      (s) => s.speciesUniqueIdentifier === selectedSpecies.speciesUniqueIdentifier
    );
    if (favoriteSpecie) {
      favoriteSpecie.isSelected = !favoriteSpecie.isSelected;
      if (isSelectAll) {
        favoriteSpecie.isSelected = true;
      }
    }

    const regularSpecies = allSpecies.find(
      (s) => s.speciesUniqueIdentifier === selectedSpecies.speciesUniqueIdentifier
    );
    if (regularSpecies) {
      regularSpecies.isSelected = !regularSpecies.isSelected;
      if (isSelectAll) {
        regularSpecies.isSelected = true;
      }
    }

    if (data.selectedSpecies.find((s) => s.speciesUniqueIdentifier === selectedSpecies.speciesUniqueIdentifier)) {
      if (!isSelectAll) {
        data.selectedSpecies = data.selectedSpecies.filter(
          (s) => s.speciesUniqueIdentifier !== selectedSpecies.speciesUniqueIdentifier
        );
      }
    } else {
      selectedSpecies.checkedProperties = [];
      selectedSpecies.isNewSpecies = true;
      data.selectedSpecies.push(selectedSpecies);
    }

    this.setState({ data, favoriteSpecies, allSpecies });
  };

  handleSpeciesRemoveSelection = (selectedSpecies) => {
    const { data, favoriteSpecies, allSpecies } = this.state;

    const favoriteSpecie = favoriteSpecies.find(
      (s) => s.speciesUniqueIdentifier === selectedSpecies.speciesUniqueIdentifier
    );
    if (favoriteSpecie) {
      favoriteSpecie.isSelected = false;
    }

    const regularSpecies = allSpecies.find(
      (s) => s.speciesUniqueIdentifier === selectedSpecies.speciesUniqueIdentifier
    );
    if (regularSpecies) {
      regularSpecies.isSelected = false;
    }
    data.selectedSpecies = [];
    this.setState({ data, favoriteSpecies, allSpecies });
  };

  handleSpeciesFilterSearch = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data }, this.filterAllSpecies);
  };

  handleSpeciesFilterSelect = (input, selectionField) => {
    const data = { ...this.state.data };
    if (!input) {
      data[selectionField] = "";
    } else {
      data[selectionField] = input;
      if (selectionField === "speciesCategoriesSearch") {
        document.getElementById("selectAllCheckboxForAves").checked = false;
      }
    }
    this.setState({ data }, this.filterAllSpecies);
  };

  onSpeciesSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data, favoriteSpecies, allSpecies } = this.state;

      const removedFavoriteSpeciesIndex = favoriteSpecies.findIndex(
        (s) => s.speciesUniqueIdentifier === removedValue.value
      );
      if (removedFavoriteSpeciesIndex !== -1) {
        favoriteSpecies[removedFavoriteSpeciesIndex].isSelected = false;
      }

      const removedSpeciesIndex = allSpecies.findIndex((s) => s.speciesUniqueIdentifier === removedValue.value);
      if (removedSpeciesIndex !== -1) {
        allSpecies[removedSpeciesIndex].isSelected = false;
      }

      data.selectedSpecies = data.selectedSpecies.filter((s) => s.speciesUniqueIdentifier !== removedValue.value);
      this.setState({ data, allSpecies, favoriteSpecies });
    }
  };

  handlePageNavigation = (step) => {
    switch (step) {
      case 1:
        this.setState({ isInitialPage: true });
        break;
      case 2:
        if (this.state.data.selectedSpecies?.length > 0) {
          this.setState({ isInitialPage: false });
        } else {
          toast.warning("Please select at least one damage agent.");
        }
        break;
    }
  };

  handleDeleteSpecies = (speciesToDelete) => {
    const { data, favoriteSpecies, allSpecies } = this.state;
    if (speciesToDelete.isFavorite) {
      const favoriteSpecie = favoriteSpecies.find(
        (s) => s.speciesUniqueIdentifier === speciesToDelete.speciesUniqueIdentifier
      );
      favoriteSpecie.isSelected = !favoriteSpecie.isSelected;
      data.selectedSpecies = data.selectedSpecies.filter(
        (s) => s.speciesUniqueIdentifier !== favoriteSpecie.speciesUniqueIdentifier
      );
    } else {
      const regularSpecies = allSpecies.find(
        (s) => s.speciesUniqueIdentifier === speciesToDelete.speciesUniqueIdentifier
      );
      regularSpecies.isSelected = !regularSpecies.isSelected;
      data.selectedSpecies = data.selectedSpecies.filter(
        (s) => s.speciesUniqueIdentifier !== regularSpecies.speciesUniqueIdentifier
      );
    }

    this.setState({ data, favoriteSpecies, allSpecies });
  };

  handlePropertySelection = (property, e) => {
    const { data } = this.state;
    if (e.target.checked) {
      this.state.checkedPropertiesInDropdown.push(property.propertyUniqueIdentifier);
    } else {
      this.setState({
        checkedPropertiesInDropdown: this.state.checkedPropertiesInDropdown.filter(
          (p) => p !== property.propertyUniqueIdentifier
        ),
      });
    }
    data.selectedSpecies.forEach((speciesToUpdate) => {
      if (speciesToUpdate.isCheckedForAssociation) {
        if (
          speciesToUpdate.checkedProperties?.find(
            (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
          )
        ) {
          // do nothing
        } else {
          speciesToUpdate.checkedProperties.push(property);
        }
      }
    });
    this.setState({ data });
  };

  handleSelectAllProperties = () => {
    const { data } = this.state;
    data.selectedSpecies.forEach((s) => {
      if (s.isCheckedForAssociation) {
        s.checkedProperties = this.props.properties;
      }
    });
    this.setState({ data, checkedPropertiesInDropdown: this.props.properties.map((p) => p.propertyUniqueIdentifier) });
  };

  handleUnselectAllProperties = () => {
    const { data } = this.state;
    data.selectedSpecies.forEach((s) => {
      if (s.isCheckedForAssociation) {
        s.checkedProperties = [];
      }
    });
    this.setState({ data, checkedPropertiesInDropdown: [] });
  };

  selectAllForBirdGuild = (e) => {
    if (e.target.checked) {
      this.state.filteredSpecies.forEach((species) => {
        this.handleSpeciesSelection(species, true);
      });
    } else {
      this.state.filteredSpecies.forEach((species) => {
        this.handleSpeciesRemoveSelection(species);
      });
    }
  };

  selectSpeciesForAssociation = (species) => {
    const { data } = this.state;
    let speciesSelectedForAssociation = data.selectedSpecies.find(
      (s) => s.speciesUniqueIdentifier === species?.speciesUniqueIdentifier
    );
    if (speciesSelectedForAssociation?.isCheckedForAssociation) {
      speciesSelectedForAssociation.isCheckedForAssociation = false;
    } else {
      speciesSelectedForAssociation.isCheckedForAssociation = true;
    }
    this.setState({ data });
  };

  selectAllSpeciesForAssociation = (e, outsideCheckbox) => {
    const { data } = this.state;
    data.selectedSpecies.forEach((s) => (s.isCheckedForAssociation = e.target.checked));
    if (outsideCheckbox) {
      document.getElementById("selectAllCheckboxForSpecies").checked = false;
    }
    this.setState({ data });
  };

  handleAssociateProperties = () => {
    const { data } = this.state;
    data.selectedSpecies.forEach((species) => {
      if (
        species.isCheckedForAssociation &&
        species.checkedProperties.length > 0 &&
        !data.selectedSpeciesForAssociation.find((s) => s.speciesUniqueIdentifier === species.speciesUniqueIdentifier)
      ) {
        data.selectedSpeciesForAssociation.push(species);
      }
    });
    this.setState(
      { data, checkedPropertiesInDropdown: [], showPropertyAssociationDropdownMenu: false },
      this.mapSelectedSpeciesTableData
    );
  };

  mapSelectedSpeciesTableData = () => {
    const { data } = this.state;
    let tableData = [];
    document.getElementById("selectAllCheckboxForSpecies").checked = false;
    data.selectedSpeciesForAssociation.forEach((s) => {
      s.isCheckedForAssociation = false;
      if (s.checkedProperties.length > 0) {
        s.checkedProperties.forEach((p) => {
          let currentTableProperty = tableData.find(
            (tableProperty) => tableProperty.propertyUniqueIdentifier === p.propertyUniqueIdentifier
          );
          if (currentTableProperty) {
            currentTableProperty.associatedSpecies.push(s);
          } else {
            let newTableProperty = JSON.parse(JSON.stringify(p));
            newTableProperty.associatedSpecies = [];
            newTableProperty.associatedSpecies.push(s);
            tableData.push(newTableProperty);
          }
        });
      }
    });
    this.setState({ selectedSpeciesTableData: tableData });
  };

  deleteSpecies = (row) => {
    let { data, selectedSpeciesTableData } = this.state;

    const updatedTableData = selectedSpeciesTableData.filter(
      (property) => property.propertyUniqueIdentifier !== row.propertyUniqueIdentifier
    );

    data.selectedSpecies.forEach((selectedSpeciesRecord) => {
      selectedSpeciesRecord.checkedProperties = selectedSpeciesRecord.checkedProperties.filter(
        (property) => property.propertyUniqueIdentifier !== row.propertyUniqueIdentifier
      );
    });

    this.setState({ selectedSpeciesTableData: updatedTableData, data });
  };

  renderSpeciesSelectionPage = () => {
    const { data } = this.state;
    return (
      <React.Fragment>
        <Row className="mx-0 mt-4">
          <Col>
            <span className={styles.refFilesModalSubTitle}>Favorite Damage Agents</span>
          </Col>
        </Row>
        <Row className="mx-0 my-2">
          {this.state.favoriteSpecies?.length > 0 && (
            <Col>
              <span className={styles.refFilesModalSubTitle}>
                <span className={styles.refFilesModalBoldListing}>Species Name</span>
              </span>
            </Col>
          )}
        </Row>
        <div className={styles.favoriteResourcesContainer}>
          {this.state.favoriteSpecies.map((species, index) => {
            return (
              <div key={index}>
                {index === 0 && <Row className={styles.greySeparatorRow}></Row>}
                <Row className="my-2 mx-0">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      inline
                      checked={species.isSelected}
                      onChange={() => this.handleSpeciesSelection(species)}
                      title={species.name}
                    />
                    <span className={globalStyles.formData}>{species.name}</span>
                  </Col>
                </Row>
                <Row className={styles.greySeparatorRow}></Row>
              </div>
            );
          })}
        </div>
        <div>
          <Row className="mx-0 mt-4">
            <Col>
              <span className={styles.refFilesModalSubTitle}>Search Additional Damage Agents</span>
            </Col>
          </Row>
          <Row className="mx-0 mt-2">
            <Col className="pr-0">
              <Form.Control
                name="speciesNameSearch"
                placeholder="Enter Damage Agent Name"
                value={data.speciesNameSearch}
                onChange={this.handleSpeciesFilterSearch}
              />
            </Col>
            <Col className="pr-0">
              <Select
                aria-label="Species Class"
                name="speciesClassesSearch"
                placeholder="Class"
                value={data.speciesClassesSearch}
                onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesClassesSearch")}
                isClearable
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                  option: (styles, { isFocused }) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "black",
                    backgroundColor: isFocused ? "lightgray" : "white",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "black",
                  }),
                  menuList: (styles) => ({ ...styles, textAlign: "left" }),
                }}
                options={this.state.speciesClasses}
              />
            </Col>
            <Col className="pr-0">
              <Select
                aria-label="Species Order"
                name="speciesOrdersSearch"
                placeholder="Order"
                value={data.speciesOrdersSearch}
                onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesOrdersSearch")}
                isClearable
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                  option: (styles, { isFocused }) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "black",
                    backgroundColor: isFocused ? "lightgray" : "white",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "black",
                  }),
                  menuList: (styles) => ({ ...styles, textAlign: "left" }),
                }}
                options={this.state.speciesOrders}
              />
            </Col>
            <Col className="pr-0">
              <Select
                aria-label="Species Family"
                name="speciesFamiliesSearch"
                placeholder="Family"
                value={data.speciesFamiliesSearch}
                onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesFamiliesSearch")}
                isClearable
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                  option: (styles, { isFocused }) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "black",
                    backgroundColor: isFocused ? "lightgray" : "white",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "black",
                  }),
                  menuList: (styles) => ({ ...styles, textAlign: "left" }),
                }}
                options={this.state.speciesFamilies}
              />
            </Col>
            <Col>
              <Select
                aria-label="Species Category"
                name="speciesCategoriesSearch"
                placeholder="Category"
                value={data.speciesCategoriesSearch}
                onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesCategoriesSearch")}
                isClearable
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                  option: (styles, { isFocused }) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "black",
                    backgroundColor: isFocused ? "lightgray" : "white",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "black",
                  }),
                  menuList: (styles) => ({ ...styles, textAlign: "left" }),
                }}
                options={this.state.speciesCategories}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col
              align="left"
              hidden={
                this.state.filteredSpecies[0]?.class?.toLowerCase() !== "aves" || data.speciesCategoriesSearch === ""
              }
            >
              <Row noGutters>
                <Col xs={1}>
                  <Form.Check
                    id="selectAllCheckboxForAves"
                    className={styles.speciesSelectionModalSelectAllCheckbox}
                    onClick={this.selectAllForBirdGuild}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Label htmlFor="selectAllCheckboxForAves" className={styles.speciesSelectionModalSelectAllLabel}>
                    Select All
                  </Form.Label>
                </Col>
              </Row>
            </Col>
            <Col align="right">
              <Button variant="link" onClick={this.clearFilters} style={{ fontSize: "14px" }}>
                Clear All Filters
              </Button>
            </Col>
          </Row>
          <Row className={styles.resultsBox}>{this.renderSearchResults()}</Row>
        </div>
        <Row className="mx-0 mt-4">
          <Col>
            <Form.Group>
              <Form.Label className={styles.refFilesModalSubTitle} htmlFor="speciesSelected">
                Damage Agents Selected
              </Form.Label>
              <Select
                aria-label="Species Selected"
                name="speciesSelected"
                id="speciesSelected"
                placeholder="Species Selected"
                isMulti
                isSearchable={false}
                menuIsOpen={false}
                isClearable={false}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: "white",
                      border: "solid 0.5px #D8DDE6",
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: "#0070D2",
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: "#53698D",
                    ":hover": {
                      backgroundColor: "white",
                    },
                  }),
                }}
                components={{
                  DropdownIndicator: (props) => {
                    return "";
                  },
                }}
                value={this.state.data?.selectedSpecies?.map((s) => ({
                  value: s.speciesUniqueIdentifier,
                  label: s.name,
                }))}
                onChange={this.onSpeciesSelectionChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  filterAllSpecies = () => {
    let filteredSpecies = [];
    const { data, allSpecies } = this.state;
    if (
      data.speciesNameSearch ||
      data.speciesClassesSearch ||
      data.speciesOrdersSearch ||
      data.speciesFamiliesSearch ||
      data.speciesCategoriesSearch
    ) {
      filteredSpecies = allSpecies.filter((species) => {
        let isMatch = true;
        if (data.speciesNameSearch) {
          isMatch = isMatch && species.name.toLowerCase().includes(data.speciesNameSearch.toLowerCase());
        }
        if (data.speciesClassesSearch) {
          isMatch = isMatch && species.class === data.speciesClassesSearch.value;
        }
        if (data.speciesOrdersSearch) {
          isMatch = isMatch && species.order === data.speciesOrdersSearch.value;
        }
        if (data.speciesFamiliesSearch) {
          isMatch = isMatch && species.family === data.speciesFamiliesSearch.value;
        }
        if (data.speciesCategoriesSearch) {
          isMatch = isMatch && species.subType === data.speciesCategoriesSearch.value;
        }

        return isMatch;
      });
    }
    this.setState({ filteredSpecies: filteredSpecies });
  };

  renderSearchResults = () => {
    let results = null;
    results = (
      <React.Fragment>
        {this.state.filteredSpecies.map((result, index) => {
          return (
            <div key={index}>
              <Row className={`mb-2 mx-0 ${index === 0 ? "mt-3" : "mt-2"}`}>
                <Col>
                  <Form.Check
                    type="checkbox"
                    inline
                    checked={result.isSelected}
                    onChange={() => this.handleSpeciesSelection(result)}
                    title={result.name}
                  />
                  <span className={globalStyles.formData}>{result.name}</span>
                </Col>
              </Row>
              <Row className={styles.greySeparatorRow}></Row>
            </div>
          );
        })}
      </React.Fragment>
    );

    return results;
  };

  renderSpeciesAssociationPage = () => {
    return (
      <React.Fragment>
        <Row className="mr-0 ml-4 mt-5 mb-3 px-0">
          <Col xs={1}>
            <Form.Check
              id={"selectAllCheckboxForSpecies"}
              className={styles.speciesSelectionModalSelectAllCheckbox}
              onClick={this.selectAllSpeciesForAssociation}
            />
          </Col>
          <Col className="p-1">
            <Form.Label
              htmlFor={"selectAllCheckboxForSpecies"}
              style={{ fontSize: "14px", fontWeight: "600", fontFamily: "Source Sans Pro", margin: "0" }}
            >
              Select All
            </Form.Label>
          </Col>
          <Col md={4} className="pl-2 pr-0">
            <Dropdown
              onToggle={(isOpen) => {
                if (!isOpen) {
                  this.setState({ showPropertyAssociationDropdownMenu: false });
                } else {
                  this.setState({ showPropertyAssociationDropdownMenu: true });
                }
              }}
              show={this.state.showPropertyAssociationDropdownMenu}
            >
              <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                <span className={styles.smallFormDataText}>Associate w/ Property</span>
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu} className={styles.propertyAssociationDropdownMenu}>
                <Dropdown.Header className="px-1 py-0 mb-2">
                  <Row className="mx-0">
                    <Col className="text-left px-1">
                      <Button variant="link" className="px-0" onClick={() => this.handleSelectAllProperties()}>
                        <span className={styles.smallActionButtonsText}>Check All</span>
                      </Button>
                    </Col>
                    <Col className="text-right px-1">
                      <Button variant="link" className="px-0" onClick={() => this.handleUnselectAllProperties()}>
                        <span className={styles.smallActionButtonsText}>Uncheck All</span>
                      </Button>
                    </Col>
                  </Row>
                </Dropdown.Header>
                {this.props.properties?.map((property, index) => {
                  return (
                    <Row key={index} className="px-3 mx-0 mb-2">
                      <Form.Check
                        type="checkbox"
                        inline
                        checked={this.state.checkedPropertiesInDropdown?.includes(property.propertyUniqueIdentifier)}
                        onChange={(e) => this.handlePropertySelection(property, e)}
                        title={property.propertyName}
                      />
                      <span className={styles.smallFormDataText}>{property.propertyName}</span>
                    </Row>
                  );
                })}
                <Row>
                  <Col align="center" className="mt-2">
                    <Button variant="primary" style={{ width: "75%" }} onClick={() => this.handleAssociateProperties()}>
                      <span>Associate</span>
                    </Button>
                  </Col>
                </Row>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className={styles.propertyAssociationGreySeparatorRow}></Row>
        {this.state.data.selectedSpecies?.map((species, index) => {
          return (
            <div key={index}>
              <Row className="mr-0 ml-4 my-3 px-0">
                <Col xs={1}>
                  <Form.Check
                    id={"selectCheckboxForSpecies" + index}
                    className={styles.speciesSelectionModalSelectAllCheckbox}
                    checked={species.isCheckedForAssociation}
                    onClick={() => this.selectSpeciesForAssociation(species)}
                  />
                </Col>
                <Col className="p-1">
                  <Form.Label
                    htmlFor={"selectCheckboxForSpecies" + index}
                    style={{ fontSize: "14px", fontFamily: "Source Sans Pro", margin: "0" }}
                  >
                    {species.name}
                  </Form.Label>
                </Col>
              </Row>
              <Row className={styles.propertyAssociationGreySeparatorRow}></Row>
            </div>
          );
        })}
        <Row className="mr-0 ml-4 my-3 px-0">
          <Col className={styles.speciesSelectionModalTableHeader}>
            <span className={styles.speciesSelectionModalTableHeaderText}>Association w/ Property</span>
          </Col>
        </Row>
        <Row className="mr-0 ml-4 my-3 px-0" hidden={this.state.data.selectedSpeciesForAssociation?.length === 0}>
          <BootstrapTable
            keyField="speciesTableId"
            data={this.state.selectedSpeciesTableData}
            columns={this.state.selectedSpeciesColumns}
            bootstrap4={true}
            hover={true}
            bordered={false}
          />
        </Row>
      </React.Fragment>
    );
  };

  render() {
    let bodyContent = null;
    let submitButtonText = this.props.properties?.length < 2 ? "Save" : "Next";
    let navImage = nav1;

    if (!this.state.isInitialPage) {
      submitButtonText = "Save";
      navImage = nav2;
      bodyContent = this.renderSpeciesAssociationPage();
    } else {
      bodyContent = this.renderSpeciesSelectionPage();
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.refFilesAssociationModal}
      >
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Add Damage Agents</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 my-3" hidden={this.props?.properties?.length < 2}>
            <Col className="text-center">
              <Image src={navImage} useMap="#speciesSelectionStepperMap" hidden={this.props?.properties?.length < 2} />
              <map name="speciesSelectionStepperMap" className={styles.refFileStepper}>
                <area
                  alt="Add Damage Agents"
                  title="Add Damage Agents"
                  onClick={() => this.handlePageNavigation(1)}
                  coords="3,1,334,36"
                  shape="rect"
                />
                <area
                  alt="Add Property Association"
                  title="Add Property Association"
                  onClick={() => this.handlePageNavigation(2)}
                  coords="335,2,691,36"
                  shape="rect"
                />
              </map>
            </Col>
          </Row>
          {bodyContent}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={styles.cancelButton}>Cancel</span>
          </Button>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>{submitButtonText}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SpeciesSelectionModal;
