import React from "react";
import { Modal, Button, Row, Col, CloseButton, Form } from "react-bootstrap";
import CustomForm from "../../../components/common/form";
import globalStyles from "../../../OARS.module.scss";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import RoleAuthorizationAPI from "../../../api/RoleAuthorization/RoleAuthorizationAPI";
import AsyncSelect from "react-select/async";

class reassignFieldAgenttModal extends CustomForm {
  constructor(props) {
    super(props);

    this.state = {
      selectedProjects: "",
      availableProjects: "",
      stateGuid: JSON.parse(localStorage.getItem("currentState")).stateGuid,
      errors: {},
      typeaheadIsLoading: false,
      typeaheadOptions: [],
      showPrimaryFieldAgentSearch: true,
      selectedFieldAgent: "",
      selectedOption: "",
    };
  }
  handleSpecificChanges = async (input) => {
    //do nothing
  };

  handleContactSearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.stateGuid,
    };

    this.setState({ typeaheadIsLoading: true });
    const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText(searchObj);
    console.log(JSON.stringify(contactsData));
    let outputContacts = [];
    if (contactsData?.data?.results) {
      let results = contactsData.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
              firstName: user.firstName,
              lastName: user.lastName,
              contactUniqueIdentifier: userProfile.wsUserProfileUniqueIdentifier,
              contactGuid: userProfile.wsUserProfileUniqueIdentifier,
              contactName: user.firstName + " " + user.lastName,
              emailAddress: user.userPrinciple,
              phoneNumber: user.primaryPhoneNumber,
              primaryMailingAddress: {
                mailingStreet: user.streetAddress,
                mailingCity: user.city,
                mailingZip: user.zip,
                mailingStateUniqueId: user.stateOfAddressUniqueReference,
              },
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    this.setState({ typeaheadOptions: outputContacts, typeaheadIsLoading: true });
    return outputContacts;
  };

  handleFieldAgentChange = (selectedOption) => {
    this.setState({ selectedOption, selectedFieldAgent: selectedOption });
  };

  doSubmit = () => {
    if (!this.state.selectedFieldAgent) {
      ErrorHandler.showError("Failed to reassign primary specialist");
    } else {
      this.props.onHide();
      this.props.onSave(this.state);
      this.clearData();
    }
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    this.setState({ typeaheadOptions: [], selectedFieldAgent: "" });
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  additionalFieldAgentContactsComponent = () => {
    let fieldAgentSearchBar = null;
    let fieldAgentsDisplay = null;
    let fieldAgentTitle = null;

    fieldAgentSearchBar = (
      <AsyncSelect
        value={this.state.selectedFieldAgent}
        openMenuOnClick={false}
        placeholder="Search"
        loadOptions={this.handleContactSearch}
        onChange={this.handleFieldAgentChange}
      />
    );

    return (
      <div>
        {fieldAgentTitle}
        {fieldAgentSearchBar}
        {fieldAgentsDisplay}
      </div>
    );
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Reassign Field Agent</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 my-3 text-left">
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Select Field Agent</Form.Label>
                <div>
                  <this.additionalFieldAgentContactsComponent />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>Submit</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default reassignFieldAgenttModal;
