import React from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import CustomForm from "../../../common/form";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import UtilityFunctions from "../../../common/UtilityFunctions";
import { toast } from "react-toastify";
import searchIcon from "../../../../assets/search.svg";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class NonInventoryLossModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        lossDate: "",
        lossQuantity: "",
        lossAmount: "",
        lossUom: "",
        selectedDamage: "",
        selectedDisease: "",
        selectedSpecies: "",
        selectedResource: "",
        reportedOrVerified: "",
        unitValue: "",
        addToProtectedResourceInventory: false,
      },
      resourceUOMs: [],
      damages: [],
      diseases: [],
      errors: {},
      selectedResourceData: [],
    };
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    this.validateStateData(currentUsaState);

    this.setState({
      resourceUOMs: await this.getResourceUOMs(),
      damages: await this.getDamages(currentUsaState),
      diseases: await this.getDiseases(currentUsaState),
      currentUsaState,
    });
  }

  async getResourceUOMs() {
    let result = [];
    let resourceUomData = await ReferenceFileAPI.GetAllResourceUOMs();
    if (resourceUomData?.data) {
      result = resourceUomData.data.map((resource) => ({
        value: resource.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(resource),
      }));
    }
    return result;
  }

  async getDamages(currentUsaState) {
    let damages = [];
    if (currentUsaState?.stateGuid) {
      const damagesData = await ReferenceFileAPI.GetDamagesForState(currentUsaState.stateGuid);

      if (damagesData?.data?.results) {
        damages = damagesData.data.results.map((result) => ({
          value: result.damageUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return damages;
  }

  async getDiseases(currentUsaState) {
    let diseases = [];
    if (currentUsaState?.stateGuid) {
      const diseasesData = await ReferenceFileAPI.GetDiseasesForState(currentUsaState.stateGuid);

      if (diseasesData?.data?.results) {
        diseases = diseasesData.data.results.map((result) => ({
          value: result.diseaseUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return diseases;
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  handleSpecificChanges(input) {
    if (["unitValue", "lossAmount", "lossQuantity"].includes(input.name)) {
      const { data } = this.state;
      data[input.name] = input.value;
      if (input.name === "unitValue" && data.lossQuantity) {
        data.lossAmount = parseFloat(input.value) * parseFloat(data.lossQuantity);
      } else if (input.name === "lossAmount" && data.lossQuantity) {
        data.unitValue = (parseFloat(input.value) / data.lossQuantity).toFixed(2);
      } else if (input.name === "lossQuantity") {
        if (data.unitValue) {
          data.lossAmount = parseFloat(input.value) * parseFloat(data.unitValue);
        } else if (data.lossAmount) {
          data.unitValue = parseFloat(data.lossAmount) / parseFloat(input.value);
        }
      }
      this.setState({ data });
    }
  }

  handleSelectChanges = (e, fieldName) => {
    const { data } = this.state;
    if (fieldName === "lossUom" && e.value === 6 && data.lossQuantity) {
      data.lossQuantity = Math.round(data.lossQuantity);
    }
    if (fieldName === "selectedResource") {
      this.props.resourceData.selectedSpecies = e;
      let resourceData = this.state.selectedResourceData?.find((res) => res.resourceUniqueIdentifier === e.value);
      this.setResourceValuation(resourceData);
    }
    data[fieldName] = e;
    this.setState({ data });
  };

  handleSpeciesSearch = async (inputValue) => {
    return await this.getSpeciesFromAPI(inputValue);
  };

  handleResourcesSearch = async (inputValue) => {
    return await this.getResourcesFromAPI(inputValue);
  };

  async getSpeciesFromAPI(searchValue) {
    return await this.getSearchResultsFromAPI(searchValue, "species");
  }

  async getResourcesFromAPI(searchValue) {
    return await this.getSearchResultsFromAPI(searchValue, "resource");
  }

  async getSearchResultsFromAPI(searchValue, entityType) {
    const { currentUsaState } = this.state;
    let searchResults = "";

    if (currentUsaState?.stateGuid) {
      // eslint-disable-next-line default-case
      switch (entityType) {
        case "species":
          const speciesData = await ReferenceFileAPI.FilterSpecies(
            currentUsaState.stateGuid,
            this.getFilterReferenceFilesBody(searchValue)
          );
          if (speciesData.data?.results) {
            let results = speciesData.data.results;
            searchResults = results.map((result) => ({
              value: result.speciesUniqueIdentifier,
              label: result.name,
            }));
          }
          break;
        case "resource":
          const resourcesData = await ReferenceFileAPI.FilterResources(
            currentUsaState.stateGuid,
            this.getFilterReferenceFilesBody(searchValue)
          );
          if (resourcesData.data?.results) {
            let results = resourcesData.data.results;
            this.setState({ selectedResourceData: results });
            searchResults = results.map((result) => ({
              value: result.resourceUniqueIdentifier,
              label: result.name,
            }));
          }
          break;
      }
    }

    return searchResults;
  }

  getFilterReferenceFilesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  handleAdditionToResourceInventory = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.addToProtectedResourceInventory = input.checked;
    this.setState({ data });
  };

  isFormDataValid() {
    const {
      lossDate,
      selectedResource,
      lossQuantity,
      lossUom,
      unitValue,
      lossAmount,
      selectedDamage,
      selectedDisease,
      selectedSpecies,
      reportedOrVerified,
    } = this.state.data;

    return (
      lossDate &&
      selectedResource &&
      lossQuantity &&
      lossUom &&
      unitValue &&
      lossAmount &&
      selectedDamage &&
      (selectedDamage?.label === "Disease" ? selectedDisease : true) &&
      selectedSpecies &&
      reportedOrVerified
    );
  }

  doSubmit() {
    const { data } = this.state;
    if (data.lossUom?.value === 6) {
      data.lossQuantity = Math.round(data.lossQuantity);
    }

    this.props.onSubmitLoss({ ...data });
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  }

  handleModalCancel = () => {
    const { data } = this.state;

    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  clearData(data) {
    data.lossDate = "";
    data.lossQuantity = "";
    data.lossUom = "";
    data.lossAmount = "";
    data.selectedDamage = "";
    data.selectedSpecies = "";
    data.reportedOrVerified = "";
    data.unitValue = "";
    data.addToProtectedResourceInventory = false;
  }

  setResourceValuation(resourceData) {
    const { data, resourceUOMs } = this.state;
    let unitValuation;

    if (resourceData?.stateResourceValuations?.length > 0) {
      unitValuation = resourceData.stateResourceValuations.sort((a, b) =>
        a.valuationYear > b.valuationYear ? -1 : 1
      )[0]?.valuePerUnitOfMeasure;
    }

    if (resourceData?.stateAllowedUnitOfMeasures?.length > 0) {
      let uomID = resourceData?.stateAllowedUnitOfMeasures[0].unitOfMeasure.id;
      data.lossUom = resourceUOMs?.find((uom) => uom.value === uomID);
    }
    data.unitValue = unitValuation;
    this.setState({ data });
  }

  render() {
    const { data, resourceUOMs } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.modalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>Enter Non-Inventory Loss</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mx-0">
              <Col>{this.renderInput("lossDate", "Date of Loss", "date", "Select Date")}</Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Damaged Resource<span className={globalStyles.asterisk508}>*</span>
                  </Form.Label>
                  <AsyncSelect
                    value={data.selectedResource}
                    aria-label="Damaged Resources Selection"
                    openMenuOnClick={false}
                    placeholder="Search for Resource"
                    components={{ DropdownIndicator }}
                    styles={this.customSelectStyles}
                    loadOptions={this.handleResourcesSearch}
                    onChange={(e) => this.handleSelectChanges(e, "selectedResource")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col className="pr-1">{this.renderInput("lossQuantity", "Quantity", "number", "Qty.", "required")}</Col>
              <Col className="pl-1">
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    UOM<span className={globalStyles.asterisk508}>*</span>
                  </Form.Label>
                  <Select
                    value={data.lossUom}
                    aria-label="UOM Selection"
                    placeholder="UOM"
                    styles={this.customSelectStyles}
                    options={resourceUOMs}
                    onChange={(e) => this.handleSelectChanges(e, "lossUom")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className={styles.greySeparatorRow}>
              <Col className={styles.greyLineSeparator}></Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Label className={styles.sectionLabels}>
                  Valuation<span className={globalStyles.asterisk508}> *</span>
                </Form.Label>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderInput("unitValue", "Unit Value", "number", "Enter Amount")}</Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderInput("lossAmount", "Total Amount (Dollar)", "number", "Enter Amount")}</Col>
            </Row>
            <Row className={styles.greySeparatorRow}>
              <Col className={styles.greyLineSeparator}></Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Label className={styles.sectionLabels}>
                  {"Damage & Damage Agents"}
                  <span className={globalStyles.asterisk508}>*</span>
                </Form.Label>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Damage</Form.Label>
                  <Select
                    aria-label="Damage Selection"
                    value={data.selectedDamage}
                    placeholder="Select Damage"
                    styles={this.customSelectStyles}
                    options={this.state.damages}
                    onChange={(e) => this.handleSelectChanges(e, "selectedDamage")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0" hidden={this.state.data.selectedDamage?.label !== "Disease"}>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Disease</Form.Label>
                  <Select
                    aria-label="Disease Selection"
                    value={data.selectedDisease}
                    placeholder="Select Disease"
                    styles={this.customSelectStyles}
                    options={this.state.diseases}
                    onChange={(e) => this.handleSelectChanges(e, "selectedDisease")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Damage Agents</Form.Label>
                  <AsyncSelect
                    aria-label="Damage Agents Selection"
                    value={data.selectedSpecies}
                    openMenuOnClick={false}
                    placeholder="Search for Damage Agents"
                    components={{ DropdownIndicator }}
                    styles={this.customSelectStyles}
                    loadOptions={this.handleSpeciesSearch}
                    onChange={(e) => this.handleSelectChanges(e, "selectedSpecies")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                {this.renderInlineCheckBoxSet(
                  "reportedOrVerified",
                  "Reported or Verified",
                  ["Reported", "Verified"],
                  "radio",
                  "",
                  "required"
                )}
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Check
                    label="Add to Protected Resource Inventory"
                    aria-label="Add to Protected Resource Inventory Checkbox"
                    onChange={this.handleAdditionToResourceInventory}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit" disabled={!this.isFormDataValid()}>
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default NonInventoryLossModal;
