import "./map.css";
import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";
import { esriPortalConfig } from "../../../package.json";
import ActiveIcon from "../../assets/Active-trap-icon.svg";
import InactiveIcon from "../../assets/Inactive-trap-icon.svg";

let previousGraphic;
let originalGraphic;

function WebMap(property) {
  const mapDiv = useRef(null);

  //default map values
  let longitude = -77.62544;
  let latitude = 39.52566;
  const methodSymbol = {
    type: "simple-marker",
    style: "diamond",
    color: "blue",
    size: "14px",
    outline: {
      color: [255, 255, 255],
      width: 2,
    },
  };

  let methodInactiveSymbol = {
    type: "picture-marker", // autocasts as new PictureMarkerSymbol()
    url: InactiveIcon,
    width: "32px",
    height: "32px",
  };
  let methodActiveSymbol = {
    type: "picture-marker", // autocasts as new PictureMarkerSymbol()
    url: ActiveIcon,
    width: "32px",
    height: "32px",
  };
  // if (property.location !== undefined && property.location && property.location !== "") {
  if (property.location) {
    let mapLoc = JSON.parse(property.location);
    longitude = mapLoc[1];
    latitude = mapLoc[0];
  }

  useEffect(() => {
    if (mapDiv.current) {
      loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/config",
        "esri/widgets/BasemapToggle",
        "esri/layers/GraphicsLayer",
        "esri/Graphic",
      ]).then(([Map, MapView, esriConfig, BasemapToggle, GraphicsLayer, Graphic]) => {
        esriConfig.url = esriPortalConfig.url;

        const initialGraphics = property.inventoryMapItems.map((method) => {
          let symbol = methodInactiveSymbol;
          if (method.status === "Live") {
            symbol = methodActiveSymbol;
          }
          return new Graphic({
            geometry: {
              type: "point",
              latitude: method.latitude,
              longitude: method.longitude,
            },
            symbol: symbol,
          });
        });

        const graphicsLayer = new GraphicsLayer({
          graphics: initialGraphics,
        });

        const map = new Map({ basemap: "hybrid", layers: [graphicsLayer] });

        const view = new MapView({
          map: map,
          center: [longitude, latitude],
          container: mapDiv.current,
          zoom: 16,
        });

        const point = {
          type: "point",
          longitude: longitude,
          latitude: latitude,
        };
        const simpleMarkerSymbol = {
          type: "simple-marker",
          color: "red",
          outline: {
            color: [255, 255, 255],
            width: 1,
          },
        };

        let addPropertyAction = {
          title: "Add",
          id: "add-property",
          image: "",
        };

        let popupTemplate;
        if (property.agreementData) {
          popupTemplate = {
            title: "{Name}",
            content: [
              {
                type: "fields",
                fieldInfos: [
                  { fieldName: "Address", label: "Address" },
                  { fieldName: "AgreementName", label: "Agreement Name" },
                  { fieldName: "AgreementStatus", label: "Agreement Status" },
                  { fieldName: "PrimaryFieldAgent", label: "Primary Field Agent" },
                  { fieldName: "ExpirationDate", label: "Expiration Date" },
                ],
              },
            ],
          };
        } else {
          popupTemplate =
            property?.isAddPropertyAvailable && property.name
              ? {
                  title: "{Name}",
                  content: "{Description}",
                  actions: [addPropertyAction],
                }
              : {
                  title: "{Name}",
                  content: "{Description}",
                };
        }

        const attributes = {
          Name: property.name,
          Description: property.address,
          Address: property.address,
          AgreementName: property.agreementData?.commonName,
          AgreementStatus: getAdreementStatus(property),
          PrimaryFieldAgent: getPrimaryFieldAgent(property),
          ExpirationDate: new Date(property.agreementData?.expirationDate).toLocaleDateString("en-US"),
        };

        if (property.location) {
          const pointGraphic = new Graphic({
            geometry: point,
            symbol: simpleMarkerSymbol,
            attributes: attributes,
            popupTemplate: popupTemplate,
          });
          graphicsLayer.add(pointGraphic);
        }

        // 1 - Create the widget
        var toggle = new BasemapToggle({
          // 2 - Set properties
          view: view, // view that provides access to the map's 'topo-vector' basemap
          nextBasemap: "topo", // allows for toggling to the 'hybrid' basemap
        });

        // Add widget to the top right corner of the view
        //comment till we resolve toggle for parcel map
        view.ui.add(toggle, "bottom-right");
        view.when(() => {
          let canvas = document.getElementsByTagName("CANVAS")[0];
          if (canvas) {
            canvas.title = "Search Map Canvas";
          }
        });

        if (property?.refreshMapItems?.length > 0) {
          let item = property.refreshMapItems[0];
          const lat = item.latitude;
          const lon = item.longitude;

          const tempPoint = {
            type: "point",
            longitude: lon,
            latitude: lat,
          };
          const newGraphic = new Graphic({
            geometry: tempPoint,
            symbol: methodSymbol,
          });
          if (previousGraphic) {
            graphicsLayer.remove(previousGraphic);
          }
          graphicsLayer.add(newGraphic);
          previousGraphic = newGraphic;

          map.layers.addMany([graphicsLayer]);
        }
        view.on("click", (e) => {
          let screenPoint = {
            x: e.x,
            y: e.y,
          };
          view.hitTest(screenPoint).then((response) => {
            if (response.results.length) {
              var graphic = response.results.filter((result) => {
                return result.graphic.layer === graphicsLayer;
              })[0]?.graphic;
              if (graphic) {
                let methodClicked = property.inventoryMapItems.find(
                  (i) => i.latitude === graphic.geometry.latitude && i.longitude === graphic.geometry.longitude
                );
                if (methodClicked) {
                  let actionsArray = [];
                  if (!property.isViewMap) {
                    actionsArray = methodClicked.configureActions?.map((action) => {
                      return {
                        title: action.configurationAction,
                        id: `${JSON.stringify(methodClicked)}****${action.configurationAction}`,
                      };
                    });
                    actionsArray.push({
                      title: "Remove Method",
                      id: `${JSON.stringify(methodClicked)}****Remove`,
                      className: "esri-icon-close",
                    });
                  }

                  let row = {};
                  row.deployedItemUniqueIdentifier = methodClicked.deployedItemUniqueIdentifier;
                  row.methodUniqueReference = methodClicked.methodUniqueReference;
                  const methodPoint = {
                    type: "point",
                    latitude: methodClicked.latitude,
                    longitude: methodClicked.longitude,
                  };
                  const methodMarkerPopupGraphic = new Graphic({
                    geometry: methodPoint,
                    symbol: methodSymbol,
                    attributes: {
                      Name: methodClicked.methodName,
                      Label: methodClicked.locationAlias,
                      Latitude: methodClicked.latitude,
                      Longitude: methodClicked.longitude,
                      Status: methodClicked.status,
                      CreateDate: methodClicked.createdDate,
                      LastModified: methodClicked.lastModifiedDate,
                    },
                    popupTemplate: {
                      title: "{Name}",
                      content: [
                        {
                          type: "fields",
                          fieldInfos: [
                            { fieldName: "Label", label: "Equipment Label" },
                            { fieldName: "Latitude", label: "Latitude" },
                            { fieldName: "Longitude", label: "Longitude" },
                            { fieldName: "Status", label: "Status" },
                            { fieldName: "CreateDate", label: "Original Set Date" },
                            { fieldName: "LastModified", label: "Date of Last Work Task" },
                          ],
                        },
                      ],
                      actions: actionsArray,
                    },
                  });
                  originalGraphic = methodMarkerPopupGraphic;
                  graphicsLayer.add(methodMarkerPopupGraphic);
                  property.setHighlightedMapPointMethod(methodClicked);
                  view.popup.open({
                    location: e.mapPoint,
                    features: [methodMarkerPopupGraphic],
                  });
                } else {
                  view.popup.open({
                    title: property.name,
                    content: property.address,
                    location: e.mapPoint,
                  });
                }
              }
            } else {
              view.popup.close();
              property.setHighlightedMapPointMethod({});
              if (originalGraphic) {
                originalGraphic.symbol = methodSymbol;
              }
              if (!property.isViewMap) {
                const lat = Math.round(e.mapPoint.latitude * 100000) / 100000;
                const lon = Math.round(e.mapPoint.longitude * 100000) / 100000;
                const tempPoint = {
                  type: "point",
                  longitude: lon,
                  latitude: lat,
                };
                const newGraphic = new Graphic({
                  geometry: tempPoint,
                  symbol: methodSymbol,
                });
                if (previousGraphic) {
                  graphicsLayer.remove(previousGraphic);
                }
                graphicsLayer.add(newGraphic);
                previousGraphic = newGraphic;

                property.setSelectPointLatitude(lat);
                property.setSelectPointLongitude(lon);
              }
            }
          });
        });

        let element = document.querySelector(".esri-view-surface");
        if (element) {
          element.title = "Search Map";
        }
        view.popup.on("trigger-action", (e) => {
          if (e.action?.id?.split("****")) {
            let method = JSON.parse(e.action.id.split("****")[0]);
            let configureAction = e.action.id.split("****")[1];
            if (configureAction === "Remove") {
              property.siteVisitInstance.handleRemoveItems(method);
            } else {
              let configureActionParamObj = method.configureActions?.find(
                (c) => c.configurationAction === configureAction
              );
              property.siteVisitInstance.handleGeolocatedAddItems(method, configureActionParamObj);
            }
          }
        });
      });
    }
  }, [property.inventoryMapItems, property.refreshMapItems]);

  return <div className="mapDiv" ref={mapDiv}></div>;
}

function getPrimaryFieldAgent(property) {
  let primaryFieldAgent = "";
  if (property.agreementData?.primaryFieldAgentName) {
    const { firstName, middleName, lastName, suffix } = property.agreementData?.primaryFieldAgentName;
    primaryFieldAgent = [firstName, middleName, lastName, suffix].join(" ");
  }
  return primaryFieldAgent;
}

function getAdreementStatus(property) {
  let agreementStatus = "";
  if (property.agreementData?.agreementStatus) {
    if (property.agreementData.agreementStatus.displayText) {
      agreementStatus = property.agreementData.agreementStatus.displayText;
    } else if (property.agreementData.agreementStatus.name) {
      agreementStatus = property.agreementData.agreementStatus.name;
    }
  }
  return agreementStatus;
}

export default WebMap;
