import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./DisplayEditPermit.module.scss";
import globalStyles from "../../../OARS.module.scss";
import CustomForm from "../../common/form";
import { Card, Form, Row, Col, Button, Image, Spinner } from "react-bootstrap";
import Select from "react-select";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import { toast } from "react-toastify";
import Input from "../../common/input";
import WorkTaskAPI from "../../../api/WorkTask/WorkTaskAPI";
import CreatePermitModal from "../CreatePermitModal/CreatePermitModal";
import PermitFileUploadModal from "../PermitFileUploadModal/PermitFileUploadModal";
import xCircle from "../../../assets/x-circle.svg";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

export class DislayEditPermit extends CustomForm {
  constructor(props) {
    super(props);

    let permitData = null;

    if (this.props.history.location.state) {
      permitData = this.props.history.location.state.entityData;
    } else {
      console.log("DislayEditPermit");
      window.location = "/home";
    }

    this.state = {
      isDetailsPage: this.props.mode === "details",
      validated: false,
      showPermitFileUploadModal: false,
      permitName: permitData.permitName,
      permitUniqueIdentifier: permitData.permitUniqueIdentifier,
      permitNumber: permitData.permitNumber,
      permitStartDate: permitData.permitStartDate,
      permitExpirationDate: permitData.permitExpirationDate,
      statesOfPermit: permitData.statesOfPermit,
      permitIssuedBy: permitData.permitIssuedBy,
      totalTakeCap: permitData.totalTakeCap,
      remainingBalance: permitData.remainingBalance,
      speciesOfPermit: permitData.speciesOfPermit,
      threshold: permitData.threshold,
      notification: permitData.notificationAction,
      permitData: "",
      statesList: [],
      issuedByList: [],
      notificationList: [],
      uploadedFiles: [],
      uploadedFilesForRemoval: [],
      showCreatePermitModal: false,
    };
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
      minHeight: "35px",
    }),
    container: (base) => ({
      ...base,
      minHeight: "35px",
    }),
  };

  async componentDidMount() {
    const getPermitResponse = await WorkTaskAPI.GetPermit(this.state.permitUniqueIdentifier);
    if (getPermitResponse?.successful) {
      this.setState({
        uploadedFiles: getPermitResponse.data?.pdfAttachments,
        permitData: getPermitResponse.data,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isDetailsPage !== this.state.isDetailsPage && !this.state.isDetailsPage) {
      this.setState({
        statesList: await this.getAllStatesFromAPI(),
        issuedByList: await this.getExternalOrganizationsForState(
          JSON.parse(localStorage.getItem("currentState") ? localStorage.getItem("currentState") : "")?.stateGuid
        ),
        notificationList: await this.getNotificationOptions(),
      });
    }
  }

  async getAllStatesFromAPI() {
    let usaStates = [];
    const usaStatesResponse = await ReferenceFileAPI.GetUSStates();
    if (usaStatesResponse?.length > 0) {
      usaStates = usaStatesResponse;
    } else {
      toast.warning("No states retreived");
    }
    return usaStates;
  }

  async getExternalOrganizationsForState(stateGuid) {
    const orgs = await ReferenceFileAPI.GetAllExternalOrganizationsInState(stateGuid);
    let orgsList = [];
    if (orgs?.successful) {
      const orgssData = orgs.data?.results;
      for (let i = 0; i < orgssData.length; i++) {
        if (orgssData[i].isAllowedInState) {
          orgsList.push({
            value: orgssData[i].externalOrganizationUniqueIdentifier,
            label: orgssData[i].externalOrganizationName,
          });
        }
      }
    } else if (orgs?.unsuccessful) {
      toast.error(orgs?.message);
    } else {
      toast.error("Could not retrieve External Organizations");
    }
    return orgsList;
  }

  getNotificationOptions = async () => {
    const notifResponse = await WorkTaskAPI.GetNotificationActionOptions();
    if (notifResponse?.successful) {
      return notifResponse.data;
    } else if (notifResponse?.unsuccessful) {
      toast.error(notifResponse.message);
      return [];
    } else {
      toast.error("Unable to retreive Notification options. Please try again later.");
      return [];
    }
  };

  changeModes = async () => {
    if (!this.state.isDetailsPage) {
      if (!this.validateData()) {
        toast.warn("Please fill out all required fields");
        return;
      }
      let body = {
        permitNumber: this.state.permitNumber,
        permitName: this.state.permitName,
        permitStartDate: this.state.permitStartDate,
        permitExpirationDate: this.state.permitExpirationDate,
        issuedByIssuingAgencyUniqueReference:
          this.state.permitIssuedBy.issuedByExternalOrganizationUniqueReference || this.state.permitIssuedBy.value,
        totalTakesCap: this.state.totalTakeCap,
        threshold: this.state.threshold,
        notificationActionEnumId: this.state.notification?.id || this.state.notification?.value,
        statesPermitIsEffectiveIn: this.state.statesOfPermit.map((state) => {
          return { stateUniqueReference: state.value || state.stateUniqueReference };
        }),
        speciesPermitAllowsToBeTaken: this.state.speciesOfPermit.map((species) => {
          return {
            speciesUniqueReference: species.speciesUniqueReference,
            totalCapForSpecies: species.totalCapForSpecies,
            threshold: species.threshold,
            notificationActionEnumId: species.notificationAction
              ? species.notificationAction.id
              : species.speciesNotification
                ? species.speciesNotification.value
                : null,
            speciesUnitOfMeasureReferenceFileEnumId: species.unitOfMeasure
              ? species.unitOfMeasure.id
              : species.speciesUom
                ? species.speciesUom.value
                : null,
          };
        }),
      };
      const permitUpdateResponse = await WorkTaskAPI.UpdatePermit(body, this.state.permitUniqueIdentifier);
      if (permitUpdateResponse?.successful) {
        if (this.state.uploadedFilesForRemoval.length > 0) {
          let attachmentFailResponsesFileNames = [];
          await Promise.all(
            this.state.uploadedFilesForRemoval.map(async (file) => {
              if (file.permitPdfReferenceUniqueIdentifier) {
                let removePDFFromPermitResponse = await WorkTaskAPI.RemovePDFFromPermit(
                  this.state.permitUniqueIdentifier,
                  file.permitPdfReferenceUniqueIdentifier
                );
                if (!removePDFFromPermitResponse?.successful) {
                  attachmentFailResponsesFileNames.push(
                    file?.name ||
                    file?.pdfName +
                    " failed to remove. " +
                    (removePDFFromPermitResponse ? removePDFFromPermitResponse.message + " " : "")
                  );
                }
              }
            })
          );
          if (attachmentFailResponsesFileNames.length !== 0) {
            ErrorHandler.showErrorWithMessage(
              "Unable to remove some files. Please try again later. " + attachmentFailResponsesFileNames.toString()
            );
          }
          this.setState({ uploadedFilesForRemoval: [] });
        }
        if (this.state.uploadedFiles.length > 0) {
          let attachmentFailResponsesFileNames = [];
          await Promise.all(
            this.state.uploadedFiles.map(async (file) => {
              if (!file.permitPdfReferenceUniqueIdentifier) {
                let data = new FormData();
                data.append("permitPDF", file, file?.name);
                let attachPDFToPermitResponse = await WorkTaskAPI.AttachPDFToPermit(
                  data,
                  this.state.permitUniqueIdentifier
                );
                if (!attachPDFToPermitResponse?.successful) {
                  attachmentFailResponsesFileNames.push(
                    file?.name +
                    " failed to attach. " +
                    (attachPDFToPermitResponse ? attachPDFToPermitResponse.message + " " : "")
                  );
                }
              }
            })
          );
          if (attachmentFailResponsesFileNames.length !== 0) {
            ErrorHandler.showErrorWithMessage(
              "Unable to attach some files. Please try again later. " + attachmentFailResponsesFileNames.toString()
            );
          }
        }
        toast.success("Successfully updated permit information.");
      } else {
        ErrorHandler.showErrorWithMessage(
          "Unable to update permit information. " + (permitUpdateResponse ? permitUpdateResponse.message : "")
        );
      }
    }
    this.setState({ isDetailsPage: !this.state.isDetailsPage });
  };

  renderPermitInformation = () => {
    return (
      <React.Fragment>
        <Row className={styles.informationRow}>
          <Col align="left">{this.renderPermitName()}</Col>
        </Row>
        <Row className={styles.informationRow}>
          <Col align="left">{this.renderPermitNumber()}</Col>
        </Row>
        <Row className={styles.informationRow}>
          <Col align="left">{this.renderPermitStartDate()}</Col>
        </Row>
        <Row className={styles.informationRow}>
          <Col align="left">{this.renderPermitExpirationDate()}</Col>
        </Row>
        <Row className={styles.informationRow}>
          <Col align="left">
            <h6 className={styles.fieldLabels}>
              States(s)
              <span className={globalStyles.asterisk508}>{!this.state.isDetailsPage ? " *" : ""}</span>
            </h6>
            {this.renderStates()}
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderPermitName = () => {
    if (this.state.isDetailsPage) {
      return (
        <React.Fragment>
          <h6 className={styles.fieldLabels}>Permit Name</h6>
          <span className={styles.dataFields}>{this.state.permitName}</span>
        </React.Fragment>
      );
    } else {
      return (
        <Input
          type="text"
          name="permitName"
          value={this.state.permitName}
          label="Permit Name"
          onChange={({ currentTarget: input }) => {
            this.setState({ permitName: input.value });
          }}
          isRequired={true}
        />
      );
    }
  };

  renderPermitNumber = () => {
    if (this.state.isDetailsPage) {
      return (
        <React.Fragment>
          <h6 className={styles.fieldLabels}>Permit Number</h6>
          <span className={styles.dataFields}>{this.state.permitNumber}</span>
        </React.Fragment>
      );
    } else {
      return (
        <Input
          type="number"
          name="permitNumber"
          value={this.state.permitNumber}
          label="Permit Number"
          onChange={({ currentTarget: input }) => {
            this.setState({ permitNumber: input.value });
          }}
          isRequired={true}
        />
      );
    }
  };

  renderPermitStartDate = () => {
    if (this.state.isDetailsPage) {
      return (
        <React.Fragment>
          <h6 className={styles.fieldLabels}>Permit Start Date</h6>
          <span className={styles.dataFields}>
            {new Date(
              new Date(this.state.permitStartDate).getTime() -
              new Date(this.state.permitStartDate).getTimezoneOffset() * -60000
            ).toLocaleDateString()}
          </span>
        </React.Fragment>
      );
    } else {
      return (
        <Input
          type="date"
          name="permitStartDate"
          value={new Date(this.state.permitStartDate).toISOString().split("T")[0]}
          label="Permit Start Date"
          onChange={({ currentTarget: input }) => {
            let date = new Date(input.value);
            let correctedTimezoneDate = new Date(date.getTime() - date.getTimezoneOffset() * -60000);
            this.setState({ permitStartDate: correctedTimezoneDate });
          }}
          isRequired={true}
        />
      );
    }
  };

  renderPermitExpirationDate = () => {
    if (this.state.isDetailsPage) {
      return (
        <React.Fragment>
          <h6 className={styles.fieldLabels}>Permit Expiration Date</h6>
          <span className={styles.dataFields}>
            {new Date(
              new Date(this.state.permitExpirationDate).getTime() -
              new Date(this.state.permitExpirationDate).getTimezoneOffset() * -60000
            ).toLocaleDateString()}
          </span>
        </React.Fragment>
      );
    } else {
      return (
        <Input
          type="date"
          name="permitExpirationDate"
          value={new Date(this.state.permitExpirationDate).toISOString().split("T")[0]}
          label="Permit Expiration Date"
          onChange={({ currentTarget: input }) => {
            let date = new Date(input.value);
            let correctedTimezoneDate = new Date(date.getTime() - date.getTimezoneOffset() * -60000);
            this.setState({ permitExpirationDate: correctedTimezoneDate });
          }}
          isRequired={true}
        />
      );
    }
  };

  renderStates = () => {
    if (this.state.isDetailsPage) {
      let statesStringList = "";
      if (this.state.statesOfPermit?.length > 0) {
        this.state.statesOfPermit.forEach((state, index) => {
          if (index === this.state.statesOfPermit.length - 1) {
            statesStringList = statesStringList + (state.stateName || state.label);
          } else {
            statesStringList = statesStringList + (state.stateName || state.label) + ", ";
          }
        });
      }
      return <span className={styles.dataFields}>{statesStringList}</span>;
    } else {
      return (
        <Select
          value={this.state.statesOfPermit?.map((state) => {
            return {
              value: state.stateUniqueReference || state.value,
              label: state.stateName || state.label,
            };
          })}
          aria-label="States Selection"
          placeholder="Select"
          isMulti={true}
          styles={this.customSelectStyles}
          options={this.state.statesList?.map((state) => {
            return { value: state.stateUniqueIdentifier, label: state.state_name };
          })}
          onChange={(e) => this.handleStateSelection(e)}
        />
      );
    }
  };

  handleStateSelection = (e) => {
    this.setState({ statesOfPermit: e });
  };

  renderAdditionalInformation = () => {
    return (
      <React.Fragment>
        <Row className={styles.informationRow}>
          <Col align="left">
            <h6 className={styles.fieldLabels}>
              Issued By
              <span className={globalStyles.asterisk508}>{!this.state.isDetailsPage ? " *" : ""}</span>
            </h6>
            {this.renderIssuedBy()}
          </Col>
          <Col align="left">{this.renderTotalTakesCap()}</Col>
        </Row>
        <Row className={styles.informationRow}>
          <Col align="left">{this.renderThreshold()}</Col>
          <Col align="left">
            <h6 className={styles.fieldLabels}>Notification</h6>
            {this.renderNotification()}
          </Col>
        </Row>
        <Row className={styles.informationRow}>
          <Col align="left">
            <h6 className={styles.fieldLabels}>Uploaded Files</h6>
            {this.renderUploads()}
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderIssuedBy = () => {
    if (this.state.isDetailsPage) {
      return (
        <span className={styles.dataFields}>
          {this.state.permitIssuedBy?.issuingAgencyName || this.state.permitIssuedBy?.label}
        </span>
      );
    } else {
      return (
        <div style={{ paddingTop: "4px" }}>
          <Select
            value={
              this.state.permitIssuedBy
                ? {
                  value:
                    this.state.permitIssuedBy.issuedByIssuingAgencyUniqueReference || this.state.permitIssuedBy.value,
                  label: this.state.permitIssuedBy.issuingAgencyName || this.state.permitIssuedBy.label,
                }
                : ""
            }
            aria-label="issuedByAgency"
            placeholder="Select"
            styles={this.customSelectStyles}
            options={this.state.issuedByList}
            onChange={(e) => this.handleIssuedBySelection(e)}
          />
        </div>
      );
    }
  };

  handleIssuedBySelection = (e) => {
    this.setState({ permitIssuedBy: e });
  };

  handleNotificationSelection = (e) => {
    this.setState({ notification: e });
  };

  renderTotalTakesCap = () => {
    if (this.state.isDetailsPage) {
      return (
        <React.Fragment>
          <h6 className={styles.fieldLabels}>Total Takes Cap</h6>
          <span className={styles.dataFields}>{this.state.totalTakeCap}</span>
        </React.Fragment>
      );
    } else {
      return (
        <Input
          type="number"
          name="totalTakeCap"
          value={this.state.totalTakeCap}
          label="Total Takes Cap"
          onChange={({ currentTarget: input }) => {
            let { threshold, totalTakeCap } = this.state;
            if (parseInt(input.value) < 0) {
              threshold = 0;
              totalTakeCap = 0;
            } else {
              let thresholdValue = parseInt((input.value ? input.value : 0) * 0.85);
              totalTakeCap = parseInt(input.value);
              threshold = thresholdValue;
            }
            this.setState({ threshold, totalTakeCap });
          }}
        />
      );
    }
  };

  renderThreshold = () => {
    if (this.state.isDetailsPage) {
      return (
        <React.Fragment>
          <h6 className={styles.fieldLabels}>Threshold</h6>
          <span className={styles.dataFields}>{this.state.threshold}</span>
        </React.Fragment>
      );
    } else {
      return (
        <Input
          type="number"
          name="threshold"
          value={this.state.threshold}
          label="Threshold"
          onChange={({ currentTarget: input }) => {
            let { threshold, totalTakeCap } = this.state;
            if (parseInt(input.value) < 0) {
              threshold = 0;
            } else if (parseInt(input.value) > totalTakeCap) {
              threshold = totalTakeCap;
            } else {
              threshold = parseInt(input.value);
            }
            this.setState({ threshold, totalTakeCap });
          }}
        />
      );
    }
  };

  renderNotification = () => {
    if (this.state.isDetailsPage) {
      return (
        <span className={styles.dataFields}>
          {this.state.notification ? this.state.notification.displayText || this.state.notification.label : "None"}
        </span>
      );
    } else {
      return (
        <Select
          value={
            this.state.notification
              ? {
                value: this.state.notification.id || this.state.notification.value,
                label: this.state.notification.displayText || this.state.notification.label,
              }
              : ""
          }
          aria-label="permit notification"
          placeholder="Select"
          styles={this.customSelectStyles}
          options={this.state.notificationList?.map((notif) => {
            return { value: notif.id, label: notif.displayText };
          })}
          onChange={(e) => this.handleNotificationSelection(e)}
        />
      );
    }
  };

  renderUploads = () => {
    return this.state.uploadedFiles.length > 0 ? (
      this.state.uploadedFiles.map((file, index) => {
        return (
          <Row key={file?.name || file.pdfName + index}>
            <Col xs={5} align="left">
              <Button
                className={styles.removePDFLinkButton}
                variant="link"
                onClick={async () => {
                  if (file?.permitPdfReferenceUniqueIdentifier) {
                    this.setState({
                      uploadedFiles: this.state.uploadedFiles.map((f) => {
                        if (f.permitPdfReferenceUniqueIdentifier === file.permitPdfReferenceUniqueIdentifier) {
                          f.isLoading = true;
                        }
                        return f;
                      }),
                    });
                    let pdfResponse = await WorkTaskAPI.RetrievePDFForPermit(file.permitPdfReferenceUniqueIdentifier);
                    let blob = new Blob([pdfResponse], { type: "application/pdf" });
                    let url = window.URL.createObjectURL(blob);
                    window.open(url, "_blank");
                    this.setState({
                      uploadedFiles: this.state.uploadedFiles.map((f) => {
                        if (f.permitPdfReferenceUniqueIdentifier === file.permitPdfReferenceUniqueIdentifier) {
                          f.isLoading = false;
                        }
                        return f;
                      }),
                    });
                  } else {
                    let url = window.URL.createObjectURL(file);
                    window.open(url, "_blank");
                  }
                }}
              >
                <Row>
                  <Col>
                    <span className={styles.actionButtonsText}>
                      {file?.name ? file.name : file.pdfName ? file.pdfName : "No Name"}
                    </span>
                  </Col>
                  <Col hidden={!file?.isLoading}>
                    <Spinner className={styles.loadingSpinner} animation="border" role="status">
                      <span className="visually-hidden" aria-label="Loading pdf..."></span>
                    </Spinner>
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col align="left" xs={1}>
              <Image
                hidden={this.state.isDetailsPage}
                style={{ width: "15px", cursor: "pointer" }}
                src={xCircle}
                onClick={() => {
                  let { uploadedFiles, uploadedFilesForRemoval } = this.state;
                  uploadedFiles.splice(index, 1);
                  if (file?.permitPdfReferenceUniqueIdentifier) {
                    uploadedFilesForRemoval.push(file);
                  }
                  this.setState({ uploadedFiles, uploadedFilesForRemoval });
                }}
                tabIndex="0"
                alt={"Remove file named: " + file?.name || file?.pdfName}
              />
            </Col>
          </Row>
        );
      })
    ) : (
      <span className={styles.dataFields}>None</span>
    );
  };

  removeSpeciesRow = (species) => {
    this.setState({
      speciesOfPermit: this.state.speciesOfPermit.filter(
        (s) => s.speciesUniqueReference !== species.speciesUniqueReference
      ),
    });
  };

  handleFileSelection = (files) => {
    if (files) {
      this.setState({ uploadedFiles: [...this.state.uploadedFiles, ...files] });
    }
  };

  validateData() {
    return (
      this.state.statesOfPermit.length > 0 &&
      this.state.permitNumber &&
      this.state.permitName &&
      this.state.permitStartDate &&
      this.state.permitExpirationDate &&
      this.state.permitIssuedBy
    );
  }

  handleSpeciesEdit = (speciesList) => {
    this.setState({
      showCreatePermitModal: false,
      speciesOfPermit: speciesList.map((s) => {
        s.speciesUniqueReference = s.species.value || s.speciesUniqueReference;
        s.speciesName = s.species.label || s.speciesName;
        if (s.speciesUom && s.unitOfMeasure) {
          s.unitOfMeasure.id = s.speciesUom.value;
          s.unitOfMeasure.displayText = s.speciesUom.label;
        } else if (s.speciesUom && s.unitOfMeasure === null) {
          s.unitOfMeasure = {
            id: s.speciesUom.value,
            displayText: s.speciesUom.label,
          };
        }
        if (s.speciesNotification && s.notificationAction) {
          s.notificationAction.id = s.speciesNotification.value;
          s.notificationAction.displayText = s.speciesNotification.label;
        } else if (s.speciesNotification && s.notificationAction === null) {
          s.notificationAction = {
            id: s.speciesNotification.value,
            displayText: s.speciesNotification.label,
          };
        }
        return s;
      }),
    });
  };

  renderSpeciesList = () => {
    return (
      <React.Fragment>
        <Row className={styles.speciesListTopRow}>
          <Col align="left">
            <span className={styles.dataFields}>Species Name</span>
          </Col>
          <Col align="left">
            <span className={styles.dataFields}>UOM</span>
          </Col>
          <Col align="left">
            <span className={styles.dataFields}>Take Cap</span>
          </Col>
          <Col align="left">
            <span className={styles.dataFields}>Threshold</span>
          </Col>
          <Col align="left">
            <span className={styles.dataFields}>Notification</span>
          </Col>
          <Col align="right" xs={2} hidden={this.state.isDetailsPage}></Col>
        </Row>
        {this.state.speciesOfPermit?.map((species, index) => {
          return (
            <Row className={styles.speciesListRow} key={"speciesRow" + index}>
              <Col align="left">
                <span className={styles.dataFields}>{species.speciesName}</span>
              </Col>
              <Col align="left">
                <span className={styles.dataFields}>
                  {species?.unitOfMeasure
                    ? species.unitOfMeasure.displayText
                    : species?.speciesUom
                      ? species.speciesUom.label
                      : "None"}
                </span>
              </Col>
              <Col align="left">
                <span className={styles.dataFields}>{species.totalCapForSpecies}</span>
              </Col>
              <Col align="left">
                <span className={styles.dataFields}>{species.threshold}</span>
              </Col>
              <Col align="left">
                <span className={styles.dataFields}>
                  {species?.notificationAction
                    ? species.notificationAction.displayText
                    : species?.speciesNotification
                      ? species.speciesNotification.label
                      : "None"}
                </span>
              </Col>
              <Col align="right" xs={2} hidden={this.state.isDetailsPage}>
                <Button
                  className={styles.removeLinkButton}
                  variant="link"
                  onClick={() => this.removeSpeciesRow(species)}
                >
                  <span className={styles.actionButtonsText}>Remove</span>
                </Button>
              </Col>
            </Row>
          );
        })}
      </React.Fragment>
    );
  };

  render() {
    const { permissions } = this.props;
    return (
      <Form noValidate validated={this.state.validated}>
        <Row className={styles.pageRow} noGutters>
          <Col>
            <h5 className={styles.pageTitle}>Permit Record Page</h5>
          </Col>
          <Col align="right" hidden={this.state.isDetailsPage}>
            <Button
              className={globalStyles.modalSubmitButton}
              variant="primary"
              onClick={() => {
                this.setState({ showPermitFileUploadModal: true });
              }}
            >
              <span className={globalStyles.modalSubmitButtonText}>Upload PDFs</span>
            </Button>
          </Col>
          {permissions.canIEditPermitsAssignedStates() && (
            <Col xs={1} align="right">
              <Button className={globalStyles.modalSubmitButton} variant="primary" onClick={this.changeModes}>
                <span className={globalStyles.modalSubmitButtonText}>
                  {this.state.isDetailsPage ? "Edit" : "Apply"}
                </span>
              </Button>
            </Col>
          )}
        </Row>
        <Row className={styles.pageRow}>
          <Col>
            <Card>
              <Card.Header className={styles.cardHeader}>
                <Row>
                  <Col lg={0.3}>
                    <i className="fa fa-user-circle-o fa-2x" aria-hidden="true"></i>
                  </Col>
                  <Col lg={2.5}>
                    <span className={styles.permitInfoTitle}>Permit Information</span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={4} className={styles.permitDataFields}>
                    {this.renderPermitInformation()}
                  </Col>
                  <Col lg={8} style={{ borderLeft: "1px solid #979797" }}>
                    <Card className={styles.noBorderCard} style={{ boxShadow: "0 0 0 0 #000000" }}>
                      <Card.Header as="h5" className={styles.cardHeader}>
                        <span className={styles.addressCardTitle}>Additional Information</span>
                      </Card.Header>
                      <Card.Body>{this.renderAdditionalInformation()}</Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className={styles.pageRow}>
          <Col>
            <Card>
              <Card.Header className={styles.cardHeader}>
                <span className={styles.permitInfoTitle}>Species</span>
                <Button
                  className={styles.headerLinkButton}
                  variant="link"
                  hidden={this.state.isDetailsPage}
                  onClick={() => {
                    this.setState({ showCreatePermitModal: true });
                  }}
                >
                  <span className={styles.actionButtonsText}>+ Add Damage Agent</span>
                </Button>
              </Card.Header>
              <Card.Body>{this.renderSpeciesList()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <CreatePermitModal
          show={this.state.showCreatePermitModal}
          onHide={() => {
            this.setState({ showCreatePermitModal: false });
          }}
          states={this.state.statesList}
          currentUsaState={JSON.parse(localStorage.getItem("currentState"))}
          fromDetailsPage={true}
          detailsState={this.state}
          handleSpeciesEdit={this.handleSpeciesEdit}
        />
        <PermitFileUploadModal
          show={this.state.showPermitFileUploadModal}
          onHide={() => {
            this.setState({ showPermitFileUploadModal: false });
          }}
          handleFileSelected={this.handleFileSelection}
        />
      </Form>
    );
  }
}

export default withRouter(DislayEditPermit);
