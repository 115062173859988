import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton, Form, Col } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { customFilter, selectFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CustomTextFilter from "../common/customTextFilter";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import CreateEditIntermediateFateModal from "../ReferenceFilesCreatePages/CreateEditIntermediateFateModal";

class IntermediateFateTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fateData: [],
      currentUsaState: props.currentUsaState,
      defaultSort: [{ dataField: "name", order: "asc" }],
      columns: this.getColumns(),
      showCreateModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
      isIntermediateFateAllowed: false,
    };
  }

  tableTextStyle = {};

  static getDerivedStateFromProps(nextProps) {
    return {
      currentUsaState: nextProps.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier ||
      ((prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData) &&
        this.props.currentTab === "intermediateFateTab")
    ) {
      await this.updateFateData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "intermediateFateTab") {
      await this.updateFateData();
    }
  }

  async updateFateData() {
    this.props.setIsLoading(true);
    this.setState(
      {
        columns: this.getColumns(),
        isIntermediateFateAllowed: await this.isFateAllowedInState(),
        fateData: await this.getDataFromAPI(),
      },
      () => {
        this.props.setIsLoading(false);
      }
    );
    window.scrollTo(0, 0);
  }

  async isFateAllowedInState() {
    let isIntermediateFateAllowed = { ...this.state.isIntermediateFateAllowed };
    if (this.state.currentUsaState?.stateGuid) {
      const getIntermediateFateStatesResponse = await ReferenceFileAPI.GetAllStatesForIntermediateFates();
      if (getIntermediateFateStatesResponse?.successful) {
        const currentStateData = getIntermediateFateStatesResponse.data?.find(
          (s) => s.stateUniqueReference === this.state.currentUsaState.stateGuid
        );
        isIntermediateFateAllowed = currentStateData?.isEnabled ? true : false;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve states for intermediate fate records",
          apiName: "GetAllStatesForIntermediateFates",
          responseUnsuccessful: getIntermediateFateStatesResponse?.unsuccessful,
          responseMessage: getIntermediateFateStatesResponse?.message,
        });
      }
    }
    return isIntermediateFateAllowed;
  }

  async getDataFromAPI() {
    return this.props.useGlobalData
      ? await this.getGlobalIntermediateFates()
      : await this.getIntermediateFateDataForState();
  }

  async getGlobalIntermediateFates() {
    let fatesData = [];
    let intermediateFatesResponse = await ReferenceFileAPI.GetAllIntermediateFates();
    if (intermediateFatesResponse?.successful) {
      if (intermediateFatesResponse.data?.length > 0) {
        fatesData = intermediateFatesResponse.data;
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve intermediate fate records",
        apiName: "GetAllIntermediateFates",
        responseUnsuccessful: intermediateFatesResponse?.unsuccessful,
        responseMessage: intermediateFatesResponse?.message,
      });
    }
    return fatesData;
  }

  async getIntermediateFateDataForState() {
    let fatesData = [];
    const { currentUsaState } = this.state;
    if (currentUsaState?.stateGuid) {
      let intermediateFatesResponse = await ReferenceFileAPI.GetIntermediateFatesForState(currentUsaState.stateGuid);
      if (intermediateFatesResponse?.successful) {
        if (intermediateFatesResponse.data?.length > 0) {
          fatesData = intermediateFatesResponse.data;
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve intermediate fate records",
          apiName: "GetIntermediateFatesForState",
          responseUnsuccessful: intermediateFatesResponse?.unsuccessful,
          responseMessage: intermediateFatesResponse?.message,
        });
      }
    }
    return fatesData;
  }

  getColumns() {
    const columns = [
      {
        dataField: "intermediateFateUniqueIdentifier",
        hidden: true,
        csvText: "Fate Unique Id",
      },
      {
        dataField: "name",
        text: "Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Fate Name Search" labelledBy="fateNameHeader" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "intermediateFateNameHeader" },
        attrs: { headers: "intermediateFateNameHeader", tabIndex: "0" },
      },
    ];

    if (this.props.useGlobalData) {
      columns.push(
        {
          dataField: "isEnabledGlobally",
          text: "Status",
          filter: selectFilter({
            options: { true: "Active", false: "Inactive" },
            getFilter: (filter) => {
              this.state.allowedFilter = filter;
            },
          }),
          formatter: (cell, row) => {
            return cell ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          },
          sort: true,
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3" };
          },
          headerAttrs: { id: "intermediateFateNameHeader" },
          attrs: { headers: "intermediateFateNameHeader", tabIndex: "0" },
        },
        {
          dataField: "lastModifiedDateTimeUTC",
          text: this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date",
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Last Updated Date" />
          ),
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return this.formatDate(cell);
          },
          sort: true,
          sortFunc: (a, b, order) => {
            let dateA = new Date(a);
            let dateB = new Date(b);
            if (order === "asc") {
              return dateB - dateA;
            }
            return dateA - dateB; // desc
          },
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          style: this.tableTextStyle,
          headerAttrs: { id: "intermediateFateDateHeader" },
          attrs: { headers: "intermediateFateDateHeader", tabIndex: "0" },
        },
        {
          dataField: "fateActions",
          text: "Actions",
          csvExport: false,
          editable: false,
          formatter: (cell, row) => {
            return (
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton id="dropdown-fate-actions" title="Actions" variant="link" className={styles.buttonLink}>
                  <Dropdown.Item
                    eventKey="fateEdit"
                    onSelect={() => this.handleFateEdit(row)}
                    hidden={!this.props.permissions.canIEditReferenceData()}
                  >
                    Edit
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            );
          },
          headerStyle: () => {
            return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
          },
          headerAlign: "right",
          align: "right",
          headerAttrs: { id: "intermediateFateActionsHeader" },
          attrs: { headers: "intermediateFateActionsHeader", tabIndex: "0" },
        }
      );
    } else {
      columns.push({
        dataField: "lastModifiedDateTimeUTC",
        text: "Last Updated Date",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Last Updated Date" />
        ),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return this.formatDate(cell);
        },
        sort: true,
        sortFunc: (a, b, order) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          if (order === "asc") {
            return dateB - dateA;
          }
          return dateA - dateB; // desc
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "intermediateFateDateHeader" },
        attrs: { headers: "intermediateFateDateHeader", tabIndex: "0" },
      });
    }

    return columns;
  }

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleDateString("en-US");
  }

  handleCreateFate = () => {
    this.setState({ showCreateModal: true });
  };

  handleFateEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleFateHistory = (row) => {
    //do nothing for now
  };

  createFate = async (modalData) => {
    let createFateResult = await ReferenceFileAPI.CreateIntermediateFate({ name: modalData.name });
    if (createFateResult?.successful) {
      this.insertNewFateFromResponse(createFateResult.data);
      this.setState({ showCreateSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create Fate",
        apiName: "CreateIntermediateFate",
        responseUnsuccessful: createFateResult?.unsuccessful,
        responseMessage: createFateResult?.message,
      });
    }
  };

  editFate = async (updatedData) => {
    if (
      this.state.modalData.name !== updatedData.name ||
      this.state.modalData.isEnabledGlobally !== updatedData.isEnabledGlobally
    ) {
      let editFateResult = await ReferenceFileAPI.UpdateIntermediateFate(updatedData.intermediateFateUniqueIdentifier, {
        name: updatedData.name,
        isEnabledGlobally: updatedData.isEnabledGlobally,
      });
      if (editFateResult?.successful) {
        this.updateFateFromResponse(editFateResult.data);
        this.setState({ showEditSuccessModal: true, modalData: "" });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update Fate",
          apiName: "UpdateIntermediateFate",
          responseUnsuccessful: editFateResult?.unsuccessful,
          responseMessage: editFateResult?.message,
        });
      }
    }
  };

  updateFateFromResponse(updatedFateData) {
    const { fateData } = this.state;
    const fateIndexToUpdate = fateData.findIndex(
      (f) => f.intermediateFateUniqueIdentifier === updatedFateData.intermediateFateUniqueIdentifier
    );
    if (fateIndexToUpdate !== -1) {
      fateData[fateIndexToUpdate] = updatedFateData;
    }

    this.setState({ fateData });
  }

  insertNewFateFromResponse(createFateData) {
    if (this.props.useGlobalData || this.state.isIntermediateFateAllowed) {
      this.setState({ fateData: [...this.state.fateData, createFateData] });
    }
  }

  isSuccessfulResult(result) {
    return result.successful || result.message?.includes("already") ? true : false;
  }

  handleAllowedFateSwitch = async () => {
    if (this.state.currentUsaState?.stateGuid) {
      if (this.state.isIntermediateFateAllowed) {
        await this.disableIntermediateFatesForStates();
      } else {
        await this.allowIntermediateFatesInStates();
      }
    }
    await this.updateFateData();
  };

  async allowIntermediateFatesInStates() {
    const allowFatesInStatesResponse = await ReferenceFileAPI.AllowIntermediateFateInStates([
      this.state.currentUsaState.stateGuid,
    ]);
    if (allowFatesInStatesResponse?.successful) {
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set intermediate fate record to allowed",
        apiName: "AllowIntermediateFateInStates",
        responseUnsuccessful: allowFatesInStatesResponse?.unsuccessful,
        responseMessage: allowFatesInStatesResponse?.message,
      });
    }
  }

  async disableIntermediateFatesForStates() {
    const disableFatesForStatesResponse = await ReferenceFileAPI.DisableIntermediateFatesForStates([
      this.state.currentUsaState.stateGuid,
    ]);
    if (disableFatesForStatesResponse?.successful) {
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set intermediate fate record to disallowed",
        apiName: "DisableIntermediateFatesForStates",
        responseUnsuccessful: disableFatesForStatesResponse?.unsuccessful,
        responseMessage: disableFatesForStatesResponse?.message,
      });
    }
  }

  renderCreateModal() {
    return (
      <CreateEditIntermediateFateModal
        show={this.state.showCreateModal}
        onHide={() => {
          this.setState({ showCreateModal: false });
        }}
        modalData={null}
        modalMode="Create"
        onSubmit={this.createFate}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditIntermediateFateModal
        show={this.state.showEditModal}
        onHide={() => {
          this.setState({ showEditModal: false });
        }}
        modalData={this.state.modalData}
        modalMode="Edit"
        onSubmit={this.editFate}
      />
    );
  }

  renderModals = () => {
    return (
      <React.Fragment>
        {this.state.showCreateModal ? this.renderCreateModal() : null}
        {this.state.showEditModal ? this.renderEditModal() : null}
      </React.Fragment>
    );
  };

  onHideEditSuccess = () => {
    this.setState({ showEditSuccessModal: false });
  };

  onHideCreateSuccess = () => {
    this.setState({ showCreateSuccessModal: false });
  };

  render() {
    return (
      <ToolkitProvider
        keyField="intermediateFateUniqueIdentifier"
        data={this.state.fateData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "fate-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Intermediate Fate"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleCreateFate}
                  useGlobalData={this.props.useGlobalData}
                  recordCount={this.state.fateData.length}
                  extraHeaderComponents={
                    !this.props.useGlobalData ? (
                      <Col>
                        <div className={styles.globalDataSwitchContainer}>
                          <Form.Check
                            label="Allowed in State"
                            className={styles.globalDataSwitch}
                            type="switch"
                            id="state-alowed-switch"
                            title="State Allowed Switch"
                            aria-label="Switch to enable and disable Intermediate Fate Reference File Data in State"
                            checked={this.state.isIntermediateFateAllowed}
                            onChange={this.handleAllowedFateSwitch}
                          />
                        </div>
                      </Col>
                    ) : null
                  }
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="intermediateFateUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.renderModals()}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={this.onHideCreateSuccess}
              messageType="create"
              objectName="Intermediate Fate"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={this.onHideEditSuccess}
              messageType="save"
              objectName="Intermediate Fate"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default IntermediateFateTab;
