import React, { Fragment } from "react";
import CustomForm from "../../common/form";
import styles from "../../Agreements/Agreement.module.scss";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import { Modal, Button, Row, Col, Form, Card } from "react-bootstrap";
import Addresses from "../../Agreements/contactAddresses";
import PhoneNumber from "../../Agreements/contactModalPhoneNumber";
import SuccessfulRecordModifiedModal from "../../common/create-updateOkMessageModal";
import { toast } from "react-toastify";
import UtilityFunctions from "../../common/UtilityFunctions";
import globalStyles from "../../../OARS.module.scss";
import AgreementAPI from "../../../api/AgreementEntities/AgreementAPI";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

class EditAgreementEditContactModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      addressValidated: {},
      phoneValidated: false,
      defaultCountry: "",
      data: this.props.contactData,
      errors: {},
      contactTypes: [],
      states: [],
    };
  }

  componentWillUnmount() {
    let { data } = this.state;
    data.otherAddresses.shift();
    data.otherAddresses = data.otherAddresses.filter((address) => address.streetAddress || address.mailingStreet);
    this.setState({ data });
  }

  async componentDidMount() {
    let { data } = this.state;
    if (data.primaryMailingAddress) {
      data.otherAddresses.unshift(data.primaryMailingAddress);
    } else {
      this.addEmptyAddressForComponent();
    }
    this.setState({ data });
    const countries = this.props.countries;
    if (countries) {
      for (let i = 0; i < countries.length; i++) {
        const country = countries[i];
        if (country.name === "United States of America") {
          let { data } = this.state;
          data.otherAddresses[0]["country"] = country.id;
          this.setState({ data, defaultCountry: country.id });
          this.updateAvailableStates(country.id);
        }
      }
    }
    if (this.state.contactTypes.length === 0) {
      const contactTypesCall = await AgreementEntitiesAPI.GetContactTypes();
      if (!contactTypesCall?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve contact types",
          apiName: "GetContactTypes",
          responseUnsuccessful: contactTypesCall?.unsuccessful,
          responseMessage: contactTypesCall?.message,
        });
      }
      let { data } = this.state;
      data.typeOfContactEnumId = contactTypesCall?.data.find((type) => type.id === data.typeOfContact.id)?.id;
      this.setState({ contactTypes: contactTypesCall?.successful ? contactTypesCall.data : [], data: data });
    }
  }

  addEmptyAddressForComponent() {
    let { data } = this.state;
    let newState = "";
    let newCountry = "";
    if (data.otherAddresses[0]?.state) {
      newState = data.otherAddresses[0]?.state;
    }
    if (data.otherAddresses[0]?.country) {
      newCountry = data.otherAddresses[0]?.country;
    }
    data.otherAddresses.push({
      streetAddress: "",
      city: "",
      state: newState,
      zipCode: "",
      country: newCountry,
    });
    this.setState({ data });
  }

  getMailingAddress() {
    const primaryAddress = this.state.data.otherAddresses[0];
    let primaryAddressObject = {
      mailingStreet: "",
      mailingCity: "",
      mailingStateUniqueId: "",
      mailingZip: "",
      mailingCountryUniqueId: "",
      addressPurpose: "mailing",
    };
    let streetBool = primaryAddress.streetAddress || primaryAddress.mailingStreet;
    let cityBool = primaryAddress.city || primaryAddress.mailingCity;
    let stateBool = primaryAddress.state || primaryAddress.mailingStateUniqueId;
    let zipBool = primaryAddress.zipCode || primaryAddress.mailingZip;
    let countryBool = primaryAddress.country || primaryAddress.mailingCountryUniqueId;
    if (streetBool && cityBool && stateBool && zipBool && countryBool) {
      primaryAddressObject = {
        mailingStreet: streetBool,
        mailingCity: cityBool,
        mailingStateUniqueId: stateBool,
        mailingZip: zipBool,
        mailingCountryUniqueId: countryBool,
        addressPurpose: "mailing",
      };
    }

    return primaryAddressObject;
  }

  handlePhoneNumberChange = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    let { data } = this.state;
    data.phoneNumbers[index][fieldName] = UtilityFunctions.formatPhoneNumber(input.value);
    this.setState({ data });
  };

  handleExtraPhoneNumber = ({ currentTarget: input }) => {
    let { data } = this.state;
    if (input.value === "add") {
      data.phoneNumbers.push({ phoneNumber: "", phoneTypeEnumId: "-1" });
    } else {
      data.phoneNumbers.splice(input.value, 1);
    }
    this.setState({ data });
  };
  dummyHandler = () => {
    //do nothing
  };
  handleSpecificChanges = (input) => {
    if (this.props.contactType === "cooperator") {
      let { data } = this.state;
      data[input.name] = input.value;
      this.setState({ data });
      this.props.onCooperatorEdit(this.state.data);
    }
  };

  contactTypeValid() {
    let type = parseInt(this.state.data.typeOfContactEnumId);
    return type > 0 && type < 4;
  }

  handleUpdate = async () => {
    const primaryAddress = this.getMailingAddress();
    let { data } = this.state;
    data.otherAddresses.shift();
    this.setState({ data });

    if (this.areRequiredFieldsValidated(primaryAddress)) {
      // API saving commented out, Contact Edits made on this page should align with Save Action. Saving code for later reuse
      const updateContactCall = await AgreementEntitiesAPI.UpdateContact(this.getUpdatedContactBody(primaryAddress));
      if (updateContactCall?.successful) {
        let currentContactObject = updateContactCall?.data;
        const refreshContactCall = await AgreementEntitiesAPI.GetContact(currentContactObject.contactUniqueId);
        if (!refreshContactCall?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to retrieve contact record for update",
            apiName: "GetContact",
            responseUnsuccessful: refreshContactCall?.unsuccessful,
            responseMessage: refreshContactCall?.message,
          });
        }
        currentContactObject = refreshContactCall?.data;

        if (
          (await this.replaceOtherAddressesOnContact(currentContactObject)) &&
          (await this.replacePhoneNumbersOnContact(currentContactObject))
        ) {
          let contactToPassBack = currentContactObject;
          contactToPassBack.propertyUniqueIdentifier = this.state.data.propertyUniqueIdentifier;
          contactToPassBack.fullName = `${contactToPassBack.firstName} ${contactToPassBack.middleName} ${contactToPassBack.lastName}`;
          contactToPassBack.primaryPhoneNumber = UtilityFunctions.formatPhoneNumber(
            this.state.data.phoneNumbers[0].phoneNumber
          );
          contactToPassBack.contactRole = this.state.data.contactRole;
          contactToPassBack.level = this.state.data.level;
          this.props.onHide(contactToPassBack);
          this.setState({ data: {} });
        } else {
          this.props.onHide(null);
          this.setState({ data: {} });
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update contact record",
          apiName: "UpdateContact",
          responseUnsuccessful: updateContactCall?.unsuccessful,
          responseMessage: updateContactCall?.message,
        });
      }
    } else {
      toast.warning(
        "Please provide First Name, Last Name, Contact Type, at least one Phone Number, and at least one Address. Emails and Phone Numbers must be in correct format if provided."
      );
      this.addEmptyAddressForComponent();
      return;
    }
  };

  clearData = () => {
    let { data } = this.state;
    data.otherAddresses.shift();
    this.setState({ data });
    this.props.onHide(null);
  };

  handleAddressChanges = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];
    let { data } = this.state;

    switch (fieldName) {
      case "country":
        this.updateContactCountry(data, input.value);
        //await this.updateAvailableStates(input.value);
        break;
      case "state":
        this.updateContactState(data, input.value);
        break;
      default:
        data.otherAddresses[index][fieldName] = input.value;
    }
    this.props.onCooperatorEdit(this.state.data);
    this.setState({ data });
  };

  handleExtraAddress = ({ currentTarget: input }) => {
    let { data } = this.state;

    if (input.value === "add") {
      if (
        data.otherAddresses[data.otherAddresses.length - 1].mailingStateUniqueId ||
        data.otherAddresses[data.otherAddresses.length - 1].state
      )
        data.otherAddresses.push({
          streetAddress: "",
          city: "",
          state: data.otherAddresses[0].mailingStateUniqueId,
          country: data.otherAddresses[0].country,
          zipCode: "",
        });
      else toast.warning("Please fill out all the required fields on previous address record.");
    } else {
      data.otherAddresses.splice(input.value, 1);
    }
    this.props.onCooperatorEdit(this.state.data);
    this.setState({ data });
  };

  async replacePhoneNumbersOnContact(currentContactObject) {
    let replacePhonesSuccess = true;
    if (this.state.data.phoneNumbers.length > 0) {
      const replacePhoneNumbersCall = await AgreementEntitiesAPI.ReplacePhoneNumbersOnContact(
        currentContactObject.contactUniqueId,
        this.state.data.phoneNumbers.map((phone) => {
          return {
            phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(phone.phoneNumber),
            phoneTypeEnumId: phone.phoneTypeEnumId ? parseInt(phone.phoneTypeEnumId) : phone.phoneType.id,
          };
        })
      );
      replacePhonesSuccess = replacePhoneNumbersCall?.successful;
      if (!replacePhonesSuccess) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update contact record phone numbers",
          apiName: "ReplacePhoneNumbersOnContact",
          responseUnsuccessful: replacePhoneNumbersCall?.unsuccessful,
          responseMessage: replacePhoneNumbersCall?.message,
        });
      }
    }
    return replacePhonesSuccess;
  }

  async replaceOtherAddressesOnContact(currentContactObject) {
    let replaceAddressSuccess = true;
    const apiOtherAddresses = this.getOtherAddresses(this.state.data);
    if (apiOtherAddresses.length > 0) {
      const replaceAddressesCall = await AgreementEntitiesAPI.ReplaceOtherAddressesOnContact(
        currentContactObject.contactUniqueId,
        apiOtherAddresses
      );
      replaceAddressSuccess = replaceAddressesCall?.successful;
      if (!replaceAddressSuccess) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update contact record adresses",
          apiName: "ReplaceOtherAddressesOnContact",
          responseUnsuccessful: replaceAddressesCall?.unsuccessful,
          responseMessage: replaceAddressesCall?.message,
        });
      }
    }
    return replaceAddressSuccess;
  }

  getUpdatedContactBody(primaryAddress) {
    const { data } = this.state;
    return {
      contactUniqueId: data.contactUniqueId,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      preferredName: data.preferredName,
      typeOfContactEnumId: parseInt(data.typeOfContactEnumId),
      suffix: data.suffix,
      emailAddress: data.emailAddress,
      primaryMailingAddress: primaryAddress,
    };
  }

  areRequiredFieldsValidated(primaryAddress) {
    return (
      this.state.data.firstName !== "" &&
      this.state.data.lastName !== "" &&
      this.contactTypeValid() &&
      this.isEmailValid() &&
      this.isPrimaryAddressValid(primaryAddress)
    );
  }

  isEmailValid() {
    return !this.state.data.emailAddress ? true : UtilityFunctions.validateEmail(this.state.data.emailAddress);
  }

  isPrimaryAddressValid(primaryAddress) {
    return (
      primaryAddress &&
      primaryAddress?.mailingStreet &&
      primaryAddress?.mailingCity &&
      primaryAddress?.mailingStateUniqueId &&
      primaryAddress?.mailingCountryUniqueId &&
      primaryAddress?.mailingZip
    );
  }

  getOtherAddresses(data) {
    if (data.otherAddresses.length === 0) {
      return [];
    }
    const otherAddresses = [...data.otherAddresses];
    const filteredMappedOtherAddresses = otherAddresses
      .filter(
        (address) =>
          (address.streetAddress !== "" &&
            address.city !== "" &&
            address.state !== "" &&
            address.zipCode !== "" &&
            address.country !== "") ||
          (address.mailingStreet !== "" &&
            address.mailingCity !== "" &&
            address.mailingStateUniqueId !== "" &&
            address.mailingZip !== "" &&
            address.mailingCountryUniqueId !== "")
      )
      .map((address) => ({
        mailingStreet: address.streetAddress || address.mailingStreet,
        mailingCity: address.city || address.mailingCity,
        mailingStateUniqueId: address.state || address.mailingStateUniqueId,
        mailingZip: address.zipCode || address.mailingZip,
        mailingCountryUniqueId: address.country || address.mailingCountryUniqueId,
        addressPurpose: "other",
      }));
    return filteredMappedOtherAddresses;
  }

  updateContactCountry(data, value) {
    data.otherAddresses = data.otherAddresses.map((address) => {
      address.country = value;
      return address;
    });
    this.props.onCooperatorEdit(this.state.data);
  }

  updateContactState(data, value) {
    data.otherAddresses = data.otherAddresses.map((address) => {
      address.state = value;
      return address;
    });
    this.props.onCooperatorEdit(this.state.data);
  }

  async updateAvailableStates(countryId) {
    const results = await ReferenceFileAPI.GetStatesByCountry(countryId);
    let returnStates = [];

    if (results) {
      returnStates = results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    if (returnStates.length > 0) {
      this.setState({ states: returnStates });
    }
  }

  handlePhoneNumberChange = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    let { data } = this.state;
    data.phoneNumbers[index][fieldName] = UtilityFunctions.formatPhoneNumber(input.value);
    this.props.onCooperatorEdit(this.state.data);
    this.setState({ data });
  };

  handleExtraPhoneNumber = ({ currentTarget: input }) => {
    let { data } = this.state;
    if (input.value === "add") {
      data.phoneNumbers.push({ phoneNumber: "", phoneTypeEnumId: "-1" });
    } else {
      data.phoneNumbers.splice(input.value, 1);
    }
    this.props.onCooperatorEdit(this.state.data);
    this.setState({ data });
  };

  render() {
    let addressComponent = null;
    if (this.state.states.length > 0) {
      addressComponent = (
        <Addresses
          addresses={this.state.data.otherAddresses}
          countries={this.props.countries}
          states={this.state.states}
          onAddressChange={this.handleAddressChanges}
          onExtraAddress={this.handleExtraAddress}
          contactType={this.props.contactType}
          onDummyChange={this.dummyHandler}
        />
      );
    }
    const body = (
      <div>
        <Row>
          <Col lg={4}>
            {this.renderInput("firstName", "First Name", "text", "Enter Name", ["required", "readOnly"])}
          </Col>
          <Col lg={2}>{this.renderInput("middleName", "Middle Name", "text", "Enter Name", "readOnly")}</Col>
          <Col lg={4}>{this.renderInput("lastName", "Last Name", "text", "Enter Name", ["required", "readOnly"])}</Col>
          <Col lg={2}>{this.renderInput("suffix", "Suffix", "text", "Enter Suffix")}</Col>
        </Row>
        <Row>
          <Col>{this.renderInput("preferredName", "Preferred Name", "text", "Enter Name")}</Col>
          {this.props.contactType === "cooperator" ? (
            <Col>{this.renderInput("emailAddress", "Email Address", "text", "Enter Email")}</Col>
          ) : (
            <Col>
              {this.renderSelect(
                "typeOfContactEnumId",
                "Contact Type",
                this.state.contactTypes,
                "Select Type",
                "required"
              )}
            </Col>
          )}
        </Row>
        <Row>
          <Col className={styles.editContactMidHeader}>Additional Information</Col>
        </Row>
        <Row>
          {this.props.contactType === "cooperator" ? null : (
            <Col>{this.renderInput("emailAddress", "Email Address", "text", "Enter Email")}</Col>
          )}
          <Col lg={this.props.contactType === "cooperator" ? 6 : 12}>
            <PhoneNumber
              phoneNumbers={this.state.data.phoneNumbers}
              phoneNumberTypes={this.props.phoneNumberTypes}
              onPhoneNumberChange={this.handlePhoneNumberChange}
              onExtraPhoneNumber={this.handleExtraPhoneNumber}
              contactType={this.props.contactType}
            />
          </Col>
        </Row>
        <Row>
          <Col className={styles.editContactMidHeader}>Address Information</Col>
        </Row>
        <Row>
          <Col style={{ paddingLeft: "5px" }}>{addressComponent}</Col>
        </Row>
      </div>
    );
    if (this.props.contactType === "cooperator") {
      return (
        <Card className={styles.viewPageCards}>
          <Card.Header as="h4" className={styles.cardTitles}>
            <span className={styles.cardTitleText}>Edit Cooperator</span>
          </Card.Header>
          <Card.Body className={styles.editContactModalBodyContainer}>{body}</Card.Body>
        </Card>
      );
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered size="lg">
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>Edit Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.editContactModalBodyContainer}>{body}</Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.clearData}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className={globalStyles.modalSubmitButton} onClick={this.handleUpdate}>
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Modal>
        <SuccessfulRecordModifiedModal
          show={this.state.showHappyModal}
          onHide={this.closeHappyModal}
          messageType="create"
          objectName="Contact"
        />
      </div>
    );
  }
}

export default EditAgreementEditContactModal;
