import React from "react";
import CustomForm from "../../../common/form";
import { Modal, Row, Col, Form, Button, Tabs, Tab, Image } from "react-bootstrap";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import searchIcon from "../../../../assets/search.svg";
import AgreementEntitiesAPI from "../../../../api/AgreementEntities/AgreementEntitiesAPI";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import { toast } from "react-toastify";
import xCircle from "../../../../assets/x-circle.svg";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import UtilityFunctions from "../../../common/UtilityFunctions";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class OtherSampleModal extends CustomForm {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        date: "",
        employeeName: "",
        selectedProperty: "",
        propertyName: "",
        locType: "",
        latitude: "",
        longitude: "",
        wSTake: false,
        diseasesData: [],
        sampleTypeOptions: [],
        selectedSpeciesName: "",
        selectedSpecies: "",
        destinations: [],
      },
      locationTypes: [],
      errors: {},
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
      otherSampleRows: [{ quantity: 0, disease: "", sample: "", destination: "" }],
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    const { takeData } = this.props;
    const propsOtherSampleChange = prevProps.takeData.isOtherSampling !== this.props.takeData.isOtherSampling;
    const propsShowModalChange = prevProps.show !== this.props.show;
    const propsLatitudeChange = prevProps.takeData.latitude !== takeData.latitude;
    const propsLongitudeChange = prevProps.takeData.longitude !== takeData.longitude;
    const propsintentionalSpeciesChange = prevProps.takeData.intentionalSpecies !== takeData.intentionalSpecies;
    const propsunIntentionalSpeciesChange = prevProps.takeData.unIntentionalSpecies !== takeData.unIntentionalSpecies;
    if (propsOtherSampleChange) {
      this.setState({
        otherSampleRows: [{ quantity: 0, disease: "", sample: "", destination: "" }],
      });
    }
    if (propsintentionalSpeciesChange || propsunIntentionalSpeciesChange) {
      if (takeData?.isUnintentionalTake) {
        data.selectedSpecies = takeData.unintentionalSpecies;
        data.selectedSpeciesName = takeData.unintentionalSpecies?.label;
      } else if (takeData.intentionalSpecies) {
        const speciesName = await this.getSpeciesName(takeData.intentionalSpecies);
        data.selectedSpecies = {
          value: takeData.intentionalSpecies,
          label: speciesName,
        };
        data.selectedSpeciesName = speciesName;
      }
      this.setState({ data });
    }

    if (propsShowModalChange && this.props.show) {
      const locationTypes = await this.getLocationTypes();
      if (this.props.fromSiteVisit) {
        data.locType = locationTypes?.find((loc) => loc.name === "Property")?.id;
      }
      const sampleTypes = await this.getSampleTypes();
      if (sampleTypes) {
        data.sampleTypeOptions = sampleTypes;
      }

      const diseasesData = await this.getDiseasesFromAPI(this.state.currentUsaState?.stateGuid);
      if (diseasesData) {
        data.diseasesData = diseasesData;
      }

      const organizationsData = await this.getExternalOrganizationsForState(this.state.currentUsaState?.stateGuid);
      if (organizationsData) {
        data.destinations = organizationsData;
      }

      const siteVisitStringDate = this.props.pvsDate.split("-")[0].trim();
      data.date = new Date(siteVisitStringDate).toLocaleDateString("en-CA", { timeZone: "UTC" });
      data.employeeName = this.props.employeeName;
      data.wSTake = this.props.fromSiteVisit;

      const { propertyData } = this.props;
      data.propertyName = propertyData?.propertyName;
      data.selectedProperty = {
        value: propertyData.propertyUniqueIdentifier,
        label: propertyData.propertyName,
      };

      this.setState({ data, locationTypes });
    }

    if (propsLatitudeChange) {
      data.latitude = takeData.latitude;
      this.setState({ data });
    }
    if (propsLongitudeChange) {
      data.longitude = takeData.longitude;
      this.setState({ data });
    }
  }

  async getLocationTypes() {
    let locationTypes = [];
    const locationTypesResponse = await WorkTaskAPI.GetLocationTypeOptions();
    if (locationTypesResponse?.successful && locationTypesResponse.data?.length > 0) {
      locationTypes = locationTypesResponse.data.map((type) => ({
        id: type.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(type),
      }));
    }

    return locationTypes;
  }

  async getSampleTypes() {
    let availablesampleTypes = [];
    const sampleTypesResponse = await WorkTaskAPI.GetSampleTypeOptions();
    if (sampleTypesResponse?.successful && sampleTypesResponse.data?.length > 0) {
      availablesampleTypes = sampleTypesResponse.data.map((sampleType) => ({
        value: sampleType.id,
        label: sampleType.displayText,
      }));
    }

    return availablesampleTypes;
  }

  async getSpeciesName(id) {
    const getSpeciesResponse = await ReferenceFileAPI.GetSpecies(id);
    if (getSpeciesResponse?.successful) {
      return getSpeciesResponse.data?.name;
    }
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  async getDiseasesFromAPI(currentUsaState) {
    let diseases = [];

    if (currentUsaState) {
      const diseasesData = await ReferenceFileAPI.GetDiseasesForState(currentUsaState);

      if (diseasesData?.data?.results) {
        diseases = diseasesData.data.results
          .filter((d) => d?.isAllowed)
          .map((result) => ({
            value: result.diseaseUniqueIdentifier,
            label: result.name,
          }));
      }
    }
    return diseases;
  }

  loadAvailableProperties = async (inputValue) => {
    const { currentUsaState } = this.state;
    let propertiesResult = [];
    if (currentUsaState?.stateGuid) {
      const propertiesData = await AgreementEntitiesAPI.SearchPropertiesFreeText(
        this.getSearchPropertiesBody(inputValue),
        currentUsaState.stateGuid
      );

      if (propertiesData?.successful && propertiesData.data?.results?.length > 0) {
        propertiesResult = propertiesData.data.results.map((result) => ({
          value: result.propertyUniqueIdentifier,
          label: result.propertyName,
        }));
      }
    }

    return propertiesResult;
  };

  getSearchPropertiesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 0,
    };
  }

  handlePropertySelection = (input) => {
    const { data } = this.state;
    data.selectedProperty = input;
    data.propertyName = input.label;
    this.setState({ data });
  };

  handleSpeciesSelection = (input) => {
    const { data } = this.state;
    data.selectedSpecies = input;
    data.selectedSpeciesName = input.label;
    this.setState({
      data,
    });
  };

  renderPropertyField() {
    if (this.props.fromSiteVisit) {
      return this.renderInput("propertyName", "Property Name", "text", "Enter", "readOnly");
    }
    return (
      <React.Fragment>
        <Form.Label className={globalStyles.formLabel}>Property Name</Form.Label>
        <AsyncSelect
          value={this.state.data.selectedProperty}
          aria-label="Search For Properties"
          openMenuOnClick={false}
          placeholder="Search here.."
          components={{ DropdownIndicator }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "",
              primary: "#ced4da",
            },
          })}
          styles={{
            control: (styles) => ({ ...styles, fontSize: "14px" }),
            menu: (styles) => ({ ...styles, marginTop: "0px" }),
            noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              const color = "#EAF3F1";
              return {
                ...styles,
                fontSize: "12px",
                textAlign: "left",
                borderBottom: "1px solid #f2f2f2",
                backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                color: data.color,
                cursor: isDisabled ? "not-allowed" : "default",

                ":active": {
                  ...styles[":active"],
                  backgroundColor: !isDisabled && (isSelected ? color : null),
                },
              };
            },
            indicatorSeparator: () => {
              //do nothing
            },
            placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
          }}
          loadOptions={this.loadAvailableProperties}
          onChange={this.handlePropertySelection}
        />
      </React.Fragment>
    );
  }

  handleAddAnotherSample = (e, i) => {
    let sampleRow = { quantity: 0, disease: "", sample: "", destination: "" };
    let sampleRows = this.state.otherSampleRows;
    sampleRows.push(sampleRow);
    this.setState({
      otherSampleRows: sampleRows,
    });
  };

  handleRemoveSample = (rowIndex) => {
    let sampleRows = this.state.otherSampleRows;
    sampleRows.splice(rowIndex, 1);
    this.setState({
      otherSampleRows: sampleRows,
    });
  };

  handleSampleTypeSelection = (e, rowIndex) => {
    const { otherSampleRows } = this.state;
    otherSampleRows[rowIndex].sample = e.value;
    this.setState({ otherSampleRows });
  };

  async handleSpecificChanges(input) {
    //do something
  }

  handleOtherSampleChanges = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    const { otherSampleRows } = this.state;
    otherSampleRows[index][fieldName] = input.value;

    this.setState({ otherSampleRows });
  };

  handleDiseaseSelection = (e, rowIndex) => {
    const { otherSampleRows } = this.state;
    otherSampleRows[rowIndex].disease = e.value;
    this.setState({ otherSampleRows });
  };

  handleDestinationSelection = (e, rowIndex) => {
    const { otherSampleRows } = this.state;
    otherSampleRows[rowIndex].destination = e.value;
    this.setState({ otherSampleRows });
  };

  handleNext = () => {
    if (this.validateData()) {
      const { data, otherSampleRows } = this.state;

      let otherSamples = {
        locationTypeEnumId: data.locType,
        date: data.date,
        speciesUniqueId: data.selectedSpecies?.value,
        samples: otherSampleRows.map((s) => ({
          quantity: Number.parseInt(s.quantity),
          sampleTypeEnumId: s.sample,
          diseaseUniqueReference: s.disease,
          destinationExternalOrgUniqueReference: s.destination,
        })),
      };
      this.props.save({ ...otherSamples }, this.props.takeData);
      this.clearData();
      this.props.onHide();
    } else {
      toast.error("Please fill out all required fields");
    }
  };

  clearData = () => {
    const { data } = this.state;
    data.date = "";
    data.employeeName = "";
    data.selectedProperty = "";
    data.propertyName = "";
    data.locType = "";
    data.latitude = "";
    data.longitude = "";
    data.wSTake = true;
    data.selectedSpecies = "";
    this.setState({ data, otherSampleRows: [{ quantity: 0, disease: "", sample: "", destination: "" }] });
  };

  validateData() {
    let { otherSampleRows, data } = this.state;
    return (
      data.date &&
      data.selectedProperty &&
      data.selectedSpecies &&
      data.locType &&
      !otherSampleRows.some((sample) => !sample.quantity || !sample.disease || !sample.sample || !sample.destination)
    );
  }

  async getExternalOrganizationsForState(stateGuid) {
    const orgs = await ReferenceFileAPI.GetAllExternalOrganizationsInState(stateGuid);
    let orgsList = [];
    if (orgs?.successful) {
      const orgssData = orgs.data?.results;
      for (let i = 0; i < orgssData.length; i++) {
        if (orgssData[i].isAllowedInState) {
          orgsList.push({
            value: orgssData[i].externalOrganizationUniqueIdentifier,
            label: orgssData[i].externalOrganizationName,
          });
        }
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve External Organizations",
        apiName: "GetAllExternalOrganizationsInState",
        responseUnsuccessful: orgs?.unsuccessful,
        responseMessage: orgs?.message,
      });
    }
    return orgsList;
  }

  handleSpeciesSearch = async (searchValue) => {
    let searchResults = "";
    if (this.state.currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(this.state.currentUsaState.stateGuid, {
        textToSearchFor: searchValue,
        pageSize: 10,
        pageNumber: 1,
      });
      if (speciesData?.data?.results) {
        searchResults = speciesData.data.results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return searchResults;
  };

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered size="xl" scrollable>
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Other</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "32px" }}>
          <div>
            <Row>
              <Col>
                <Form.Group controlId="wSTakeCheckbox">
                  <Form.Check
                    name="WS Take"
                    type="checkbox"
                    label="WS Take"
                    checked={this.state.data.wSTake}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                {this.renderInput(
                  "date",
                  "Date Taken",
                  "date",
                  "Select Date",
                  this.props.fromSiteVisit ? "readOnly" : "required"
                )}
              </Col>
              <Col lg={3}>{this.renderInput("employeeName", "Employee Name", "text", "Enter", "readOnly")}</Col>
              <Col lg={3}>
                {this.props.fromSiteVisit ? (
                  this.renderInput(
                    "selectedSpeciesName",
                    "Damage Agent",
                    "text",
                    this.state.data.selectedSpeciesName,
                    "readOnly"
                  )
                ) : (
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      Damage Agent<span className={globalStyles.asterisk508}>{" *"}</span>
                    </Form.Label>
                    <AsyncSelect
                      value={this.state.data.selectedSpecies}
                      aria-label="Search For Species"
                      placeholder="Search here.."
                      components={{ DropdownIndicator }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "",
                          primary: "#ced4da",
                        },
                      })}
                      styles={this.customSelectStyles}
                      loadOptions={this.handleSpeciesSearch}
                      onChange={this.handleSpeciesSelection}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={3} align="left" style={{ marginBottom: "16px" }}>
                {this.renderPropertyField()}
              </Col>
              <Col lg={3}>
                {this.renderInput(
                  "latitude",
                  "Latitude",
                  "number",
                  "Enter",
                  this.props.fromSiteVisit ? "readOnly" : ""
                )}
              </Col>
              <Col lg={3}>
                {this.renderInput(
                  "longitude",
                  "Longitude",
                  "number",
                  "Enter",
                  this.props.fromSiteVisit ? "readOnly" : ""
                )}
              </Col>
              <Col lg={3}>
                {this.renderSelect(
                  "locType",
                  "Loc Type",
                  this.state.locationTypes,
                  "Select",
                  this.props.fromSiteVisit ? "disabled" : "required"
                )}
              </Col>
            </Row>
            {this.state.otherSampleRows.map((row, rowIndex) => {
              return (
                <Row key={rowIndex}>
                  <Col>
                    <Form.Group controlId={`quantity-${rowIndex}`}>
                      <Form.Label className={globalStyles.formLabel}>Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Qty."
                        value={row.quantity}
                        onChange={this.handleOtherSampleChanges}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>
                        Disease<span className={globalStyles.asterisk508}>{" *"}</span>
                      </Form.Label>
                      <Select
                        id={"diseaseAndSampleRowDisease" + rowIndex}
                        value={this.state.data.diseasesData.filter((option) => option.value === row?.disease)}
                        aria-label="Disease Selection"
                        placeholder="Select"
                        styles={this.customSelectStyles}
                        options={this.state.data.diseasesData}
                        onChange={(e) => this.handleDiseaseSelection(e, rowIndex)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>
                        Sample Type<span className={globalStyles.asterisk508}>{" *"}</span>
                      </Form.Label>
                      <Select
                        id={"otherSampleRowsample" + rowIndex}
                        value={this.state.data.sampleTypeOptions.filter((option) => option.value === row?.sample)}
                        aria-label="Sample Type Selection"
                        placeholder="Select"
                        styles={this.customSelectStyles}
                        options={this.state.data.sampleTypeOptions}
                        onChange={(e) => this.handleSampleTypeSelection(e, rowIndex)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>
                        Destination<span className={globalStyles.asterisk508}>{" *"}</span>
                      </Form.Label>
                      <Select
                        id={"otherSampleRowdestination" + rowIndex}
                        value={this.state.data.destinations.filter((option) => option.value === row?.destination)}
                        aria-label="Destination Selection"
                        placeholder="Select"
                        styles={this.customSelectStyles}
                        options={this.state.data.destinations}
                        onChange={(e) => this.handleDestinationSelection(e, rowIndex)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={1} hidden={this.state.otherSampleRows.length < 2} className={styles.xCircleCol}>
                    <Image
                      className={styles.xCircle}
                      src={xCircle}
                      onClick={(e) => this.handleRemoveSample(rowIndex)}
                      tabIndex="-1"
                      alt="Remove Sample"
                    />
                  </Col>
                </Row>
              );
            })}

            <Row>
              <Col align="right">
                <Button
                  variant="link"
                  onClick={(e) => this.handleAddAnotherSample(e)}
                  className={styles.diseaseMonitoringTableText}
                >
                  <span>+ Add another Sample</span>
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Col>
              <Button className={globalStyles.ModalCreateButton} onClick={this.handleNext}>
                <span className={globalStyles.ModalCreateButtonText}>
                  {Number.parseInt(this.props.takeData?.diseaseSamplingQty) > 0 ||
                  Number.parseInt(this.props.takeData?.rabiesSamplingQty) > 0
                    ? "Save and Continue"
                    : "Submit"}
                </span>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default OtherSampleModal;
