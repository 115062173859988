import API from "../API";
import { APIRoutes } from "../APIRoutes";

class AgreementEntitiesAPI {
  static async CreateContact(body) {
    let url = "/Contact/CreateContact";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async UpdateContact(body) {
    let url = "/Contact/UpdateContact";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async ChangePrimaryMailingAddressOfContact(contactGuid, body) {
    let url = "/Contact/ChangePrimaryMailingAddressOfContact/" + contactGuid;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async ReplaceOtherAddressesOnContact(contactGuid, body) {
    let url = "/Contact/ReplaceOtherAddressesOnContact/" + contactGuid;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async ReplacePhoneNumbersOnContact(contactGuid, body) {
    let url = "/Contact/ReplacePhoneNumbersOnContact/" + contactGuid;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async GetContactTypes() {
    let url = "/Contact/GetContactTypes";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }

  static async GetContact(contactUniqueId, signal) {
    let url = "/Contact/GetContact/" + contactUniqueId;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async GetAllContacts(pageSize, pageNumber) {
    let url = "/Contact/GetAllContacts/" + pageSize.toString() + "/" + pageNumber.toString();
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    let results = result.data?.results;
    return results;
  }

  static async GetPhoneNumberTypes(signal) {
    let url = "/Contact/GetPhoneNumberTypes";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async ContactsStructuredSearch(body, signal) {
    let url = "/Contact/ContactsStructuredSearch";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async AddContactToProperty(propertyUniqueIdentifier, contactUniqueIdentifier, contactRoleEnumId) {
    let url =
      "/Property/AddContactToProperty/" +
      propertyUniqueIdentifier +
      "/" +
      contactUniqueIdentifier +
      "/" +
      contactRoleEnumId;
    let api = new API(url, APIRoutes.Properties);
    let result = await api.put(null);
    return result;
  }

  static async RemoveContactFromProperty(propertyUniqueIdentifier, contactUniqueIdentifier) {
    let url = "/Property/RemoveContactFromProperty/" + propertyUniqueIdentifier + "/" + contactUniqueIdentifier;
    let api = new API(url, APIRoutes.Properties);
    let result = await api.put(null);
    return result;
  }

  static async AssignOrganizationPointOfContact(organizationUniqueIdentifier, contactUniqueIdentifier) {
    let url =
      "/Organization/AssignOrganizationPointOfContact/" + organizationUniqueIdentifier + "/" + contactUniqueIdentifier;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }

  static async CreateProperty(property) {
    let url = "/Property/CreateProperty";
    let api = new API(url, APIRoutes.Properties);
    let result = await api.post(property);
    return result;
  }

  static async CreateParcels(parcels) {
    let url = "/Property/CreateParcels";
    let api = new API(url, APIRoutes.Properties);
    let result = await api.post(parcels);
    return result;
  }

  static async RemoveParcel(parcelId) {
    let url = "/Property/RemoveParcel/" + parcelId;
    let api = new API(url, APIRoutes.Properties);
    let result = await api.post();
    return result;
  }
  static async FindDuplicateProperties(property, resultLimit) {
    let url = "/Property/FindDuplicateProperties/" + resultLimit.toString();
    let api = new API(url, APIRoutes.Properties);
    let result = await api.post(property);
    return result;
  }

  static async FindDuplicateContacts(contact, resultLimit) {
    let url = "/Contact/FindDuplicateContacts/" + resultLimit.toString();
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(contact);
    return result;
  }

  static async GetAllPropertyLandClasses(signal) {
    let url = "/Property/GetAllPropertyLandClasses/";
    let api = new API(url, APIRoutes.Properties);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async GetProperty(propertyUniqueId, signal) {
    let url = "/Property/GetProperty/" + propertyUniqueId;
    let api = new API(url, APIRoutes.Properties);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async ResolveProperties(propertyUniqueReferences) {
    const api = new API("/Property/ResolveProperties", APIRoutes.Properties);
    const result = await api.put(propertyUniqueReferences);
    return result;
  }

  static async GetAllPropertyContactRoles() {
    let url = "/Property/GetAllPropertyContactRoles";
    let api = new API(url, APIRoutes.Properties);
    let result = await api.get();
    return result;
  }

  static async CreateOrganization(body) {
    let url = "/Organization/CreateOrganization";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async GetOrganizationTypes() {
    let url = "/Organization/GetOrganizationTypes";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }
  static async GetOrganization(orgUniqueId) {
    let url = "/Organization/GetOrganization/" + orgUniqueId;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }
  static async GetChildOrganizations(orgUniqueId) {
    let url = "/Organization/GetChildOrganizations/" + orgUniqueId;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }

  static async FilterOrganization(body) {
    let url = "/Organization/SearchOrganizationsFreeText";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async UpdateOrganizationPrimaryMailingAddress(orgUniqueID, body) {
    let url = "/Organization/UpdateOrganizationPrimaryMailingAddress/" + orgUniqueID;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async SetParentOrganization(orgUniqueID, newParentOrgUniqueID) {
    let url = "/Organization/SetParentOrganization/" + orgUniqueID + "/" + newParentOrgUniqueID;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }
  static async UpdateOrganizationPrimaryContactPhone(orgUniqueID, newPhoneNumber) {
    let url = "/Organization/UpdateOrganizationPrimaryContactPhone/" + orgUniqueID + "/" + newPhoneNumber;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }
  static async SearchContactFreeText(body) {
    let url = "/Contact/SearchContactFreeText";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }
  static async UpdateProperty(propertyUniqueIdentifier, body) {
    let url = "/Property/UpdateProperty/" + propertyUniqueIdentifier;
    let api = new API(url, APIRoutes.Properties);
    let result = await api.post(body);
    return result;
  }

  static async ReplaceAdditionalAddressesOnProperty(propertyUniqueIdentifier, body) {
    let url = "/Property/ReplaceAdditionalAddressesOnProperty/" + propertyUniqueIdentifier;
    let api = new API(url, APIRoutes.Properties);
    let result = await api.post(body);
    return result;
  }

  static async SearchPropertiesFreeText(body, stateUniqueIdentifier) {
    let url = "/Property/SearchPropertiesFreeText/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.Properties);
    let result = await api.post(body);
    return result;
  }

  static async GetArcGISMapsForStates(stateUniqueIdentifier) {
    let url = "/Mapping/GetArcGISMapsForStates/";
    let api = new API(url, APIRoutes.Properties);
    let result = await api.put(stateUniqueIdentifier);
    return result;
  }

  static async PropertiesStructuredSearch(body, signal) {
    let url = "/Property/PropertiesStructuredSearch";
    let api = new API(url, APIRoutes.Properties);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async GetAllProjects() {
    let url = "/Project/GetAllProjects/0/0";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/Project/GetAllProjects/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.OrganizationAndContact);
      result = await api.get();
    }

    return result;
  }

  static async GetMyAssociatedProjectsForState(stateGuid) {
    let url = "/Project/GetMyAssociatedProjectsForState/" + stateGuid;

    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }

  static async CreateProject(body) {
    let url = "/Project/CreateProject";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async UpdateProject(body, projectUniqueIdentifier) {
    let url = "/Project/UpdateProject/" + projectUniqueIdentifier;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async GetProject(projectId) {
    let url = `/Project/GetProject/${projectId}`;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }

  static async GetProjectsReport(stateUniqueReference, startDate, endDate) {
    let url = `/Project/GetProjectsReport/${stateUniqueReference}/${startDate}/${endDate}`;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.get();
    return result;
  }

  static async AddUserProfilesToProject(projectId, body) {
    let url = `/Project/AddUserProfilesToProject/${projectId}`;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async RemoveUserProfilesFromProject(projectId, body) {
    let url = `/Project/RemoveUserProfilesFromProject/${projectId}`;
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.post(body);
    return result;
  }

  static async ProjectStructuredSearch(body, signal) {
    let url = "/Project/ProjectStructuredSearch";
    let api = new API(url, APIRoutes.OrganizationAndContact);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }
}

export default AgreementEntitiesAPI;
