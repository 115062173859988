import "./map.css";
import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";
import { esriPortalConfig, config } from "../../../package.json";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

function ParcelMapView(property) {
  const mapDiv = useRef(null);

  //default map values
  let longitude = -77.62544;
  let latitude = 39.52566;

  if (property.location) {
    let mapLoc = JSON.parse(property.location);

    longitude = mapLoc[1];
    latitude = mapLoc[0];
  }

  useEffect(() => {
    if (mapDiv.current) {
      loadModules([
        "esri/portal/Portal",
        "esri/identity/OAuthInfo",
        "esri/identity/IdentityManager",
        "esri/portal/PortalItem",
        "esri/WebMap",
        "esri/views/MapView",
        "esri/config",
        "esri/layers/FeatureLayer",
        "esri/Graphic",
        "esri/symbols/SimpleFillSymbol",
        "esri/layers/GraphicsLayer",
        "esri/renderers/SimpleRenderer",
      ]).then(
        ([
          Portal,
          OAuthInfo,
          esriId,
          PortalItem,
          WebMap,
          MapView,
          esriConfig,
          FeatureLayer,
          Graphic,
          SimpleFillSymbol,
          GraphicsLayer,
          SimpleRenderer,
        ]) => {
          let appId, featureSvcUrl;
          let featureSvcName = sessionStorage.getItem("featureService");
          if (featureSvcName === "undefined") {
            //default Texas map, till all states has maps
            featureSvcName = esriPortalConfig.featureSvcName;
          }

          if (process.env.NODE_ENV === "production" && config.environment.toLowerCase() === "production") {
            //prod mapserver
            esriConfig.portalUrl = esriPortalConfig.production.portalUrl;
            appId = esriPortalConfig.production.appId;
            featureSvcUrl = esriPortalConfig.production.featureSvcs + featureSvcName + "/MapServer/0";
          } else {
            //QA - staging mapserver
            esriConfig.portalUrl = esriPortalConfig.staging.portalUrl;
            appId = esriPortalConfig.staging.appId;
            featureSvcUrl = esriPortalConfig.staging.featureSvcs + featureSvcName + "/FeatureServer/0";
          }

          const info = new OAuthInfo({
            appId: appId,
            portalUrl: esriConfig.portalUrl,
          });
          esriId.registerOAuthInfos([info]);
          esriId
            .checkSignInStatus(info.portalUrl + "/sharing")
            .then(() => {
              //connected to map server
            })
            .catch((err) => {
              ErrorHandler.sendToAppInsights("error while connecting to map server " + err);
              sessionStorage.setItem("mapAccess", "No");
              return;
            });

          esriId
            .getCredential(info.portalUrl + "/sharing")
            .then((creds) => {
              // got user credentials
            })
            .catch((err) => {
              ErrorHandler.sendToAppInsights("error while getting user credentials " + err);
              sessionStorage.setItem("mapAccess", "No");
              return;
            });
          let actions = [];
          let addParcelAction = {
            title: "Select",
            id: "add-property",
            image: "",
          };
          let removeParcelAction = {
            title: "Remove",
            id: "remove-property",
            image: "",
          };
          if (property.IsEditable) {
            actions = [addParcelAction, removeParcelAction];
          }
          //change made to use actions instead of [addParcelAction, removeParcelAction]
          const template = {
            // autocasts as new PopupTemplate()
            title: "PARCEL ID {APHIS_ID}",
            actions: actions,
            content: [
              {
                type: "fields",
                fieldInfos: [
                  {
                    fieldName: "OWN1_FRST",
                    label: "Owner First Name",
                  },
                  {
                    fieldName: "OWN1_LAST",
                    label: "Owner Last Name",
                  },
                  {
                    fieldName: "ADDR",
                    label: "Parcel Address",
                  },
                  {
                    fieldName: "CITY",
                    label: "City",
                  },
                  {
                    fieldName: "STATE",
                    label: "State",
                  },
                  {
                    fieldName: "COUNTY_NAME",
                    label: "County",
                  },
                  {
                    fieldName: "ZIP",
                    label: "Zip",
                  },
                  {
                    fieldName: "LAND_ACRES",
                    label: "Acres",
                  },
                  {
                    fieldName: "CENTROID_X",
                    label: "Latitude",
                  },
                  {
                    fieldName: "CENTROID_Y",
                    label: "Longitude",
                  },
                ],
              },
            ],
          };

          var resultsLayer = new GraphicsLayer();
          const graphicsLayer = new GraphicsLayer();

          var featureLayer = new FeatureLayer({
            url: featureSvcUrl,
            outFields: ["*"], // used by queryFeatures
            popupTemplate: template,
            overwriteActions: true,
          });

          featureLayer.popupTemplate = template;
          featureLayer.renderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "simple-fill",
              color: null,
              outline: {
                width: 0.5,
                color: null,
              },
            },
          };
          let mapId = sessionStorage.getItem("mapId");
          if (mapId === "undefined") {
            //default Texas map, till all states has maps
            mapId = esriPortalConfig.mapId;
          }
          let item = new PortalItem({
            id: mapId,
          });
          item.load();

          const webmap = new WebMap({
            portalItem: item,
            layers: [featureLayer, resultsLayer, graphicsLayer],
          });

          const view = new MapView({
            container: mapDiv.current,
            center: [longitude, latitude],
            map: webmap,
            zoom: 12,
            popup: {
              defaultPopupTemplateEnabled: false,
              dockEnabled: true,
              dockOptions: {
                buttonEnabled: false,
                breakpoint: false,
                position: "bottom-left",
              },
            },
          });

          let state = featureSvcName?.slice(featureSvcName.length - 2, featureSvcName.length);

          view.when(() => {
            let canvas = document.getElementsByTagName("CANVAS")[0];
            if (canvas) {
              canvas.title = "Search Map Canvas";
            }
            let where = "STATE  = " + state;

            queryFeatureLayer(view.extent, where);
          });

          function queryFeatureLayer(extent, whereClause) {
            var query = featureLayer.createQuery();

            query.where = getWhereClause();
            query.geometry = extent;
            query.attributes = ["*"];
            query.spatialRelationship = "intersects";

            featureLayer
              .queryFeatures(query)

              .then((results) => {
                displayResults(results);
              })
              .catch((error) => {
                ErrorHandler.sendToAppInsights("error while connecting to map server " + error);
              });
          }

          function getWhereClause() {
            const savedparcels = property.parcelDetails;

            let whereClause = "";
            let str = "";
            if (savedparcels?.length > 0) {
              savedparcels.forEach((parcel) => {
                whereClause += "'" + parcel.parcelId + "',";
              });
            }

            str = whereClause.substring(0, whereClause.length - 1);

            let query = "APHIS_ID IN (" + str + ")";

            return query;
          }
          let popupTemplate;

          if (property.agreementData) {
            popupTemplate = {
              title: "{Name}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    { fieldName: "Address", label: "Address" },
                    { fieldName: "AgreementName", label: "Agreement Name" },
                    { fieldName: "AgreementStatus", label: "Agreement Status" },
                    { fieldName: "PrimaryFieldAgent", label: "Primary WS Specialist" },
                    { fieldName: "ExpirationDate", label: "Expiration Date" },
                  ],
                },
              ],
            };
          } else {
            popupTemplate = {
              title: "{Name}",
              content: "{Description}",
            };
          }

          const attributes = {
            Name: property.name,
            Description: property.address,
            Address: property.address,
            AgreementName: property.agreementData?.commonName,
            AgreementStatus: getAgreementStatus(property),
            PrimaryFieldAgent: getPrimaryFieldAgent(property),
            ExpirationDate: new Date(property.agreementData?.expirationDate).toLocaleDateString("en-US"),
          };

          function displayResults(results) {
            const symbol = {
              type: "simple-fill",
              color: null,
              outline: {
                color: "blue",
                width: 3,
              },
            };
            // Assign styles and popup to features
            if (property.IsEditParcelMap) {
              results.features.map((feature) => {
                feature.symbol = symbol;
                return feature;
              });
            } else {
              results.features.map((feature) => {
                feature.symbol = symbol;
                feature.popupTemplate = popupTemplate;
                feature.attributes = attributes;
                return feature;
              });
            }

            // Clear display
            view.popup.close();
            view.graphics.removeAll();

            // Add features to graphics layer
            view.graphics.addMany(results.features);

            view.goTo(results.features[0].geometry.extent.expand(6));
          }

          view.on("click", function (evt) {
            var screenPoint = {
              x: evt.x,
              y: evt.y,
            };

            view.hitTest(screenPoint).then(getGraphics);
          });
          view.popup.on("trigger-action", (e) => {
            if (e.action?.id === "add-property") {
              let parcelData = null;
              let parcelCounty = view.popup.selectedFeature.attributes.COUNTY_NAME;

              parcelData = {
                OwnerFirstName: view.popup.selectedFeature.attributes.OWN1_FRST,
                OwnerLastName: view.popup.selectedFeature.attributes.OWN1_LAST,
                acctid: `${view.popup.selectedFeature.attributes.APHIS_ID}`,
                latitude: view.popup.selectedFeature.attributes.CENTROID_Y,
                longitude: view.popup.selectedFeature.attributes.CENTROID_X,
                streetAddress: view.popup.selectedFeature.attributes.ADDR,
                city: view.popup.selectedFeature.attributes.CITY,
                zip: view.popup.selectedFeature.attributes.ZIP,
                state: view.popup.selectedFeature.attributes.STATE,
                acres: view.popup.selectedFeature.attributes.LAND_ACRES,
                county: parcelCounty.slice(0, parcelCounty.length - 7),
              };
              localStorage.setItem("SelectedParcel", view.popup.selectedFeature.attributes.objectid);
              getGraphicsAdd(view.popup.selectedFeature.geometry);
              property.onAddProperty(parcelData);
            }
            if (e.action?.id === "remove-property") {
              let parcelData = null;

              parcelData = {
                OwnerFirstName: view.popup.selectedFeature.attributes.OWN1_FRST,
                OwnerLastName: view.popup.selectedFeature.attributes.OWN1_LAST,
                acctid: `${view.popup.selectedFeature.attributes.APHIS_ID}`,
                latitude: view.popup.selectedFeature.attributes.CENTROID_Y,
                longitude: view.popup.selectedFeature.attributes.CENTROID_X,
                streetAddress: view.popup.selectedFeature.attributes.ADDR,
                city: view.popup.selectedFeature.attributes.CITY,
                zip: view.popup.selectedFeature.attributes.ZIP,
                state: view.popup.selectedFeature.attributes.STATE,
                acres: view.popup.selectedFeature.attributes.LAND_ACRES,
              };
              getGraphicsRemove(view.popup.selectedFeature.geometry);
              property.onRemoveParcel(parcelData);
            }
          });

          function getGraphicsRemove(geometry) {
            const symbol = {
              type: "simple-fill",
              color: null,
              outline: {
                color: "black",
                width: 3,
              },
            };
            var graphic1 = new Graphic({
              //gotta set a defined geometry for the symbol to draw
              geometry: geometry,
              symbol: symbol,
            });
            view.graphics.add(graphic1);
          }
          function getGraphicsAdd(geometry) {
            const symbol = {
              type: "simple-fill",
              color: null,
              outline: {
                color: "white",
                width: 3,
              },
            };
            var graphic1 = new Graphic({
              //gotta set a defined geometry for the symbol to draw
              geometry: geometry,
              symbol: symbol,
            });
            view.graphics.add(graphic1);
          }

          function getGraphics(response) {
            if (!response.results[0]) {
              return;
            }

            var selectedParcel = response?.results[0]?.graphic?.attributes?.APHIS_ID;
            const savedparcels = property.parcelDetails;
            let bParcel = 0;
            if (savedparcels?.length > 0) {
              for (let i = 0; i < savedparcels.length; i++) {
                if (savedparcels[i].parcelId === selectedParcel) {
                  bParcel++;
                }
              }
              if (bParcel === 0) {
                return;
              }
            }

            var graphic = new Graphic({
              //gotta set a defined geometry for the symbol to draw
              geometry: response.results[0].graphic.geometry,
              symbol: new SimpleFillSymbol({
                color: null,
                outline: {
                  color: "white",
                  width: 3,
                },
              }),
            });
            view.graphics.add(graphic);
          }
        }
      );
    }
  });

  return <div className="mapDiv" ref={mapDiv}></div>;
}

function getPrimaryFieldAgent(property) {
  let primaryFieldAgent = "";
  if (property.agreementData?.primaryFieldAgentName) {
    const { firstName, middleName, lastName, suffix } = property.agreementData?.primaryFieldAgentName;
    primaryFieldAgent = [firstName, middleName, lastName, suffix].join(" ");
  }
  return primaryFieldAgent;
}

function getAgreementStatus(property) {
  let agreementStatus = "";
  if (property.agreementData?.agreementStatus) {
    if (property.agreementData.agreementStatus.displayText) {
      agreementStatus = property.agreementData.agreementStatus.displayText;
    } else if (property.agreementData.agreementStatus.name) {
      agreementStatus = property.agreementData.agreementStatus.name;
    }
  }
  return agreementStatus;
}

export default React.memo(ParcelMapView);
