import React from "react";
import styles from "./EditModalPages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";

class ConfigureAdminworkTaskActivityModal extends CustomForm {
  constructor(props) {
    super(props);
    if (!this.props.modalData) {
      this.props.onHide();
    }
    this.state = {
      validated: false,
      modalMode: this.props.modalMode,
      data: this.props.modalData,
      errors: {},
    };
  }

  async doSubmit(e) {
    let form = e.currentTarget;

    if (form.checkValidity()) {
      this.setState({ validated: true });
      this.configureActivity();
    }
  }

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  async configureActivity() {
    let { data } = this.state;
    this.props.onSubmit(data, this.state.modalMode);
    this.props.onHide();
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Modal.Header className={globalStyles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>Configure Admin work Task Activity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={styles.configureModalBody}>
                <Row>
                  <Col className={styles.configureModalItem}>
                    {this.renderLabel(
                      "adminActivityUniqueIdentifier",
                      "ID",
                      this.state.data.adminActivityUniqueIdentifier
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalItem}>
                    {this.renderLabel("activityName", "Activity", this.state.data.activityName)}
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalItem}>
                    {this.renderLabel(
                      "lastModifiedDateTimeUTC",
                      "Last Updated",
                      new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalAllowed}>
                    {this.renderInlineCheckBoxSet(
                      "isEnabledInState",
                      "Allowed",
                      ["Yes", "No"],
                      "radio",
                      this.state.data.isEnabledInState ? "Yes" : "No"
                    )}
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className={globalStyles.modalFooter}>
              <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <Button type="submit" className={globalStyles.modalSubmitButton}>
                <span className={globalStyles.modalSubmitButtonText}>Save</span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ConfigureAdminworkTaskActivityModal;
