import React, { Fragment } from "react";
import styles from "./Agreement.module.scss";
import { Row, Col, Form, Button } from "react-bootstrap";

const Addresses = ({
  addresses,
  countries,
  states,
  onAddressChange,
  onExtraAddress,
  contactType,
  onDummyChange,
  dataTestid = "",
}) => {
  let streetAddressFieldDisplay;
  let cityFieldDisplay;
  let zipCodeFieldDisplay;
  if (contactType !== "cooperator") {
    streetAddressFieldDisplay = <Form.Label className="formLabel">Street Address or PO Box</Form.Label>;
    cityFieldDisplay = <Form.Label className="formLabel">City</Form.Label>;
    zipCodeFieldDisplay = <Form.Label className="formLabel">ZIP Code</Form.Label>;
  } else {
    streetAddressFieldDisplay = (
      <Form.Label className="formLabel">
        Street Address or PO Box<span className="required"> *</span>
      </Form.Label>
    );
    cityFieldDisplay = (
      <Form.Label className="formLabel">
        City<span className="required"> *</span>
      </Form.Label>
    );
    zipCodeFieldDisplay = (
      <Form.Label className="formLabel">
        ZIP Code<span className="required"> *</span>
      </Form.Label>
    );
  }

  return (
    <div className="mx-2">
      {addresses.map((address, index) => {
        return (
          <div key={index} data-testid={dataTestid}>
            <Row>
              <Col>
                <Form.Group controlId={"mailingStreet-" + index} className="text-left">
                  {streetAddressFieldDisplay}
                  <Form.Control
                    data-testid="streetAddress"
                    type="text"
                    placeholder="Enter Address"
                    value={address?.mailingStreet}
                    onChange={onAddressChange}
                    style={{ fontSize: "14px" }}
                    title={`Street Address or PO Box ${index + 1}`}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row xs={1} sm={2} md={4}>
              <Col>
                <Form.Group controlId={"mailingCity-" + index} className="text-left">
                  {cityFieldDisplay}
                  <Form.Control
                    style={{ fontSize: "14px", height: "35px" }}
                    data-testid="city"
                    type="text"
                    placeholder="Enter City"
                    value={address?.mailingCity}
                    onChange={onAddressChange}
                    title={`City ${index + 1}`}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"mailingStateUniqueId-" + index} className="text-left">
                  <Form.Label className="formLabel">
                    State
                    {index === 0 && contactType === "cooperator" ? <span className="required"> *</span> : ""}
                  </Form.Label>
                  <Fragment>
                    <Form.Control
                      data-testid="state"
                      as="select"
                      type="text"
                      onChange={onAddressChange}
                      value={address?.mailingStateUniqueId}
                      className={styles.inputStyles}
                      title={`State ${index + 1}`}
                    >
                      <option>Select State</option>
                      {states.map((state) => (
                        <option
                          value={state.id || state.stateUniqueIdentifier}
                          key={state.id || state.stateUniqueIdentifier}
                        >
                          {state.name || state.state_code}
                        </option>
                      ))}
                    </Form.Control>
                  </Fragment>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"mailingCountryUniqueId-" + index} className="text-left">
                  <Form.Label className="formLabel">
                    Country{index === 0 ? <span className="required"> *</span> : ""}
                  </Form.Label>
                  <Form.Control
                    data-testid="country"
                    as="select"
                    type="text"
                    onChange={onAddressChange}
                    value={address?.mailingCountryUniqueId}
                    className={styles.inputStyles}
                    title={`Country ${index + 1}`}
                  >
                    <option>Select Country</option>
                    {countries.map((country) => (
                      <option value={country.id} key={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"mailingZip-" + index} className="text-left">
                  {zipCodeFieldDisplay}
                  <Form.Control
                    style={{ fontSize: "14px", height: "35px" }}
                    data-testid="zipCode"
                    type="text"
                    placeholder="Enter ZIP Code"
                    value={address?.mailingZip}
                    onChange={onAddressChange}
                    title={`Zip Code ${index + 1}`}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className={styles.buttonRow} style={{ marginTop: "-15px" }}>
              <Col align="right">
                <Button
                  variant="link"
                  onClick={onExtraAddress}
                  value={addresses.length > index + 1 ? index + 1 : "add"}
                  style={addresses.length > index + 1 ? { color: "red" } : {}}
                  title={addresses.length > index + 1 ? `Remove Address ${index + 2}` : `Add Address ${index + 2}`}
                  className={styles.linkButton}
                >
                  {addresses.length > index + 1 ? "- Remove Address" : "+ Add Address"}
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Addresses;
