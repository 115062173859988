import React from "react";
import { Modal, Row, Col, Form, Button, CloseButton } from "react-bootstrap";
import Select from "react-select";
import { components } from "react-select";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import CustomForm from "../../../common/form";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import { toast } from "react-toastify";
import searchIcon from "../../../../assets/search.svg";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

class AviationGroundCrewingModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        updatedAircrafts: [],
      },
      availableAircrafts: [],
      isAircraftMenuOpen: false,
      isInitialConfiguration: true,
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    this.validateStateData(currentUsaState);

    this.setState({
      currentUsaState,
      availableAircrafts: await this.getAircraftIDs(currentUsaState),
    });
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  async getAircraftIDs(currentUsaState) {
    let aircraftIDs = [];
    if (currentUsaState.stateGuid) {
      const aircraftsByStateResponse = await ReferenceFileAPI.GetAircraftsByState(currentUsaState.stateGuid);
      if (aircraftsByStateResponse?.successful && aircraftsByStateResponse.data?.length > 0) {
        aircraftIDs = aircraftsByStateResponse.data.map((a) => ({
          aircraftId: a.aircraftUniqueIdentifier,
          aircraftName: a.aircraftTailNumber,
          isSelected: false,
        }));
      }
    }
    return aircraftIDs;
  }

  handleSpecificChanges() {
    // do something
  }

  isFormDataValid() {
    return this.state.isInitialConfiguration
      ? this.props.data?.some((a) => a.isSelected)
      : this.state.data.updatedAircrafts?.some((a) => a.isSelected);
  }

  doSubmit() {
    if (!this.isFormDataValid()) {
      toast.warning("At least one aircraft must be selected for the Work task.");
      return;
    }

    this.props.onHide();
    if (!this.state.isInitialConfiguration) {
      const { data } = this.state;
      this.props.onSave(data.updatedAircrafts.filter((a) => a.isSelected));
      this.clearData(data);
      this.setState({ data, isInitialConfiguration: true });
    }
  }

  handleModalCancel = () => {
    const { data } = this.state;
    this.props.onHide();
    this.clearData(data);
    this.setState({ data, isInitialConfiguration: true });
  };

  handleAircraftSelection = (aircraftData) => {
    let { data, isInitialConfiguration } = this.state;

    if (isInitialConfiguration) {
      isInitialConfiguration = false;
      data.updatedAircrafts = this.getAvailableAircrafts();
    }

    let selectedAircraft = data.updatedAircrafts.find((a) => a.aircraftId === aircraftData.aircraftId);
    if (selectedAircraft) {
      selectedAircraft.isSelected = !selectedAircraft.isSelected;
      this.setState({ data, isInitialConfiguration });
    }
  };

  clearData(data) {
    data.updatedAircrafts = [];
  }

  getAvailableAircrafts() {
    const availableAircrafts = JSON.parse(JSON.stringify(this.state.availableAircrafts));
    if (this.props.data?.length > 0) {
      const { data: existingAircraftsData } = this.props;
      availableAircrafts.forEach((aircraft) => {
        aircraft.isSelected = existingAircraftsData.some((a) => a.aircraftId === aircraft.aircraftId);
      });
    }

    return availableAircrafts;
  }

  render() {
    let availableAircrafts = [];
    if (!this.state.isInitialConfiguration) {
      availableAircrafts = JSON.parse(JSON.stringify(this.state.data.updatedAircrafts));
    } else {
      availableAircrafts = this.getAvailableAircrafts();
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.modalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader}>
            <Row className={globalStyles.modalHeaderRow}>
              <Col className="px-0">
                <Modal.Title className={globalStyles.modalTitleText}>Aviation Ground Crewing</Modal.Title>
              </Col>
              <Col>
                <CloseButton onClick={this.handleModalCancel} />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body className={styles.aviationGroundCrewModalBoby}>
            <Row className="mx-0 my-3">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Select Aircraft(s)<span className={globalStyles.asterisk508}> *</span>
                  </Form.Label>
                  <Select
                    aria-label="Select Aircrafts"
                    placeholder={this.state.isAircraftMenuOpen ? "Search here" : "Select Aircrafts"}
                    styles={{
                      indicatorSeparator: () => {
                        //do nothing
                      },
                      placeholder: (styles) => ({ ...styles, fontSize: "14px" }),
                      menuList: (styles) => ({ ...styles, paddingLeft: "1rem", overflowX: "hidden" }),
                    }}
                    menuIsOpen={this.state.isAircraftMenuOpen}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    onMenuClose={() => {
                      this.setState({ isAircraftMenuOpen: false });
                    }}
                    onFocus={() => {
                      this.setState({ isAircraftMenuOpen: true });
                    }}
                    onBlur={() => {
                      this.setState({ isAircraftMenuOpen: false });
                    }}
                    filterOption={(option, inputValue) => {
                      return option?.data?.aircraftName.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                    components={{
                      Option: (props) => {
                        const { data, innerProps, innerRef } = props;
                        return (
                          <Row className="my-2" ref={innerRef} {...innerProps}>
                            <Col>
                              <Form.Check
                                type="checkbox"
                                inline
                                defaultChecked={data.isSelected}
                                onChange={() => this.handleAircraftSelection(data)}
                                title={`Select ${data.aircraftName}`}
                              />
                              <span className={data.isSelected ? globalStyles.formDataLinks : globalStyles.formData}>
                                {data.aircraftName}
                              </span>
                            </Col>
                          </Row>
                        );
                      },
                      DropdownIndicator: (props) => {
                        return this.state.isAircraftMenuOpen ? (
                          <components.DropdownIndicator {...props}>
                            <SearchIcon />
                          </components.DropdownIndicator>
                        ) : (
                          <components.DropdownIndicator {...props}></components.DropdownIndicator>
                        );
                      },
                    }}
                    options={availableAircrafts}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit">
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AviationGroundCrewingModal;
