import React, { Fragment } from "react";
import { withRouter } from "react-router";
import styles from "./Properties.module.scss";
import globalStyles from "../../../OARS.module.scss";
import CustomForm from "../../common/form";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import DuplicatedPropertyModal from "./duplicatedPropertyModal";
import SuccessfulRecordModifiedModal from "../../common/create-updateOkMessageModal";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import GeoCoding from "../../common/GeoCoding";
import SearchMap from "../../ESRIIntegration/SearchMap";

class CreatePropertyForm extends CustomForm {
  state = {
    data: {
      propertyType: "Non-Parcel",
      landClass: 0,
      district: "",
      county: "",
      countyName: "",
      mapPoint: "",
      propertyName: "",
      acres: "",
      calculatedAcres: "",
      primaryCooperator: "",
      streetAddress: "",
      city: "",
      zipCode: "",
      state: "",
      country: "",
      latitude: "",
      longitude: "",
      stateName: "",
      countryName: "",
    },
    errors: {},
    validated: false,
    landClasses: [],
    counties: [],
    states: [],
    countries: [],
    showDuplicatedPropertyModal: false,
    showSuccessfullCreateModal: false,
    duplicatedRecordData: "",
    createMethodBody: "",
    currentUsaState: "",
    updateAccessibilityTags: true,
    latitudeForMapFind: "",
    longitudeForMapFind: "",
    addressStringForMapFind: "",
    hasMapRendered: false,
    doesMapNeedRerender: true,
    routeNavigationData: "",
  };

  async componentDidMount() {
    let { data } = this.state;
    let states = [];
    let countries = [];
    let counties = [];
    data.propertyType = "Non-Parcel";

    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (currentUsaState?.stateGuid) {
      counties = await this.getCountyListbyState(currentUsaState.stateGuid);
    }

    if (currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE") {
      countries = await this.getAllCountriesFromAPI();
      states = await this.getAvailableStates();
      const dodObject = counties.find((county) => county.name === "DOD");
      data.county = dodObject.id;
      data.countyName = dodObject.name;
    } else {
      countries = await this.getAllCountriesFromAPI();
      const usaObject = countries.find((country) => country.name === "United States of America");
      states = await this.getAvailableStates(usaObject.id);
      data.country = usaObject.id;
      data.countryName = usaObject.name;
    }

    const stateObject = states.find((state) => state.id === currentUsaState.stateGuid);

    data.state = stateObject.id;
    data.stateName = currentUsaState.stateName;

    const landClasses = await this.getLandClasses();

    let element = document.getElementById("countyCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "county");
    element = document.getElementById("stateCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "state");
    element = document.getElementById("countryCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "country");

    this.setState({
      landClasses,
      counties,
      countries,
      states,
      data,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.doesMapNeedRerender) {
      this.setState({ doesMapNeedRerender: false });
    }
  }

  async getAllCountriesFromAPI() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));

    const results = await ReferenceFileAPI.GetAllCountries();
    let recordIndex = results.findIndex((a) => a.country_name === "United States of America");
    let countryList = [];
    if (results) {
      countryList = results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    if (currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE") {
      countryList.splice(recordIndex, 1);
    }

    return countryList;
  }

  async getContactTypeId(contactType) {
    const contactTypesData = await AgreementEntitiesAPI.GetContactTypes();

    let contactTypeId = "";
    if (contactTypesData.data) contactTypeId = contactTypesData.data.find((element) => element.name === contactType).id;

    return contactTypeId;
  }

  async getCooperatorsFromAPI(searchValue) {
    const contactsData = await AgreementEntitiesAPI.SearchContactFreeText({
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    });
    let cooperators = "";

    if (contactsData.data.results) {
      let results = contactsData.data.results;
      cooperators = results.map((result) => ({
        value: result.contactUniqueId,
        label: result.firstName.concat(" ", result.middleName, " ", result.lastName),
      }));
    }

    return cooperators;
  }

  async getLandClasses() {
    const landClasses = await AgreementEntitiesAPI.GetAllPropertyLandClasses();
    if (!landClasses) return [];
    return landClasses.data;
  }

  async getCountyListbyState(stateGuid) {
    const county = await ReferenceFileAPI.GetCountiesByState(stateGuid);
    let countyList = [];
    if (county) {
      countyList = county
        .map((county) => ({
          id: county.countyUniqueIdentifier,
          name: county.county_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    return countyList;
  }

  handleSpecificChanges(input) {
    if (
      !this.state.data.streetAddress &&
      !this.state.data.city &&
      !this.state.data.state &&
      !this.state.data.country &&
      !this.state.data.longitude &&
      !this.state.data.latitude
    ) {
      this.setState({ hasMapRendered: false });
    }
  }

  async doSubmit() {
    if (!this.state.hasMapRendered) {
      toast.warning("A valid property needs to be identified in the map.");
      return;
    }

    const { data, states, countries } = this.state;
    let { duplicatedRecordData, showDuplicatedPropertyModal, showSuccessfullCreateModal, routeNavigationData } =
      this.state;

    let propertyAddress = this.getPropertyAddress();
    let location = await GeoCoding.getLocationByAddress(propertyAddress);
    data.mapPoint = JSON.stringify(location);

    const messageBody = this.getMessageBody(data);
    const duplicateResponse = await AgreementEntitiesAPI.FindDuplicateProperties(messageBody, 1);

    if (duplicateResponse?.data && duplicateResponse?.data?.length > 0) {
      const duplicatedRecord = this.getDuplicatedRecordData(duplicateResponse.data[0], countries, states);
      duplicatedRecordData = duplicatedRecord;
      showDuplicatedPropertyModal = true;
    } else {
      const createResponse = await AgreementEntitiesAPI.CreateProperty(messageBody);
      if (createResponse?.successful) {
        showSuccessfullCreateModal = true;
        routeNavigationData = { entityData: createResponse.data, states };
      }
    }

    this.setState({
      createMethodBody: messageBody,
      duplicatedRecordData,
      showDuplicatedPropertyModal,
      routeNavigationData,
      showSuccessfullCreateModal,
    });
  }

  getPropertyAddress() {
    let propertyAddress = "";

    if (this.state.data.state && this.state.data.streetAddress && this.state.data.city) {
      let stateName = this.state.states.find((state) => state.id === this.state.data.state)?.name;
      propertyAddress = this.state.data.streetAddress.concat(
        ", ",
        this.state.data.city,
        ", ",
        stateName ? stateName : "",
        ", ",
        this.state.data.zipCode
      );
    }

    return propertyAddress;
  }

  getDuplicatedRecordData(duplicatedRecord, countries, states) {
    duplicatedRecord.countryName = countries.find(
      (country) => country.id === duplicatedRecord.countryUniqueIdentifier
    ).name;
    duplicatedRecord.stateName = states.find((state) => state.id === duplicatedRecord.state).name;

    return duplicatedRecord;
  }

  getMessageBody(data) {
    //TODO -- Remove displayText after API Integration
    let countyName = this.state.counties?.find((county) => county.id === data.county).name;
    return {
      propertyName: data.propertyName,
      propertyAbbreviation: "",
      landClassEnumId: parseInt(data.landClass, 10),
      //non-parcel id = 2
      propertyTypeEnumId: 2,
      acres: parseFloat(data.acres, 10),
      district: data.district,
      county: countyName,
      state: data.state,
      premID: "",
      specialInstructions: "",
      latitude: Math.round(parseFloat(data.latitude) * 1e5) / 1e5,
      longitude: Math.round(parseFloat(data.longitude) * 1e5) / 1e5,
      parcelCount: 0,
      defaultCooperatorUniqueIdentifier: data.primaryCooperator.value,
      countryUniqueIdentifier: data.country,
      additionalStreetAddresses: [
        {
          streetAddress: this.state.data.streetAddress,
          city: this.state.data.city,
          zipCode: this.state.data.zipCode,
        },
      ],
    };
  }

  async getAvailableStates(countryId) {
    let results = [];
    if (!countryId) {
      results = await ReferenceFileAPI.GetAllStates();
    } else {
      results = await ReferenceFileAPI.GetStatesByCountry(countryId);
    }
    let returnStates = [];
    if (results) {
      returnStates = results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
          ...state,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    if (returnStates) {
      this.setState({ returnStates });
    }
    return returnStates;
  }

  handleCooperatorSearch = async (inputValue) => {
    return await this.getCooperatorsFromAPI(inputValue);
  };

  handleCooperatorChange = (input) => {
    const { data } = this.state;
    data.primaryCooperator = input;

    this.setState({ data });
    return input;
  };

  closeDuplicatedPropertyModal = () => {
    this.setState({ showDuplicatedPropertyModal: false });
  };

  handleForceCreation = () => {
    this.setState({ showSuccessfullCreateModal: true });
  };

  closeSuccessfulCreateModal = () => {
    this.setState({ showSuccessfullCreateModal: false });
  };

  mapFind = async () => {
    let lat = parseFloat(this.state.data.latitude);
    let long = parseFloat(this.state.data.longitude);

    let propertyAddress = this.getPropertyAddress();
    if (lat && long) {
      this.setState({
        latitudeForMapFind: lat,
        longitudeForMapFind: long,
        addressStringForMapFind: "",
        hasMapRendered: true,
        doesMapNeedRerender: true,
      });
    } else if (propertyAddress) {
      let mapPointFromAddress = await GeoCoding.getLocationByAddress(propertyAddress);
      let data = this.state.data;
      data.latitude = mapPointFromAddress[0];
      data.longitude = mapPointFromAddress[1];
      this.setState({
        addressStringForMapFind: propertyAddress,
        latitudeForMapFind: mapPointFromAddress[0],
        longitudeForMapFind: mapPointFromAddress[1],
        data,
        hasMapRendered: true,
        doesMapNeedRerender: true,
      });
    } else {
      toast.error("Please provide either a full address or a latitude and longitude pair to find a map point.");
    }
  };

  render() {
    const { data } = this.state;
    let showMap = false;

    if (this.state.hasMapRendered) {
      showMap = true;
    }
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));

    return (
      <form onSubmit={this.handleSubmit} data-testid="propertyForm">
        <div className="container w-75">
          <Row className="my-3">
            <Col>
              <h5 className={globalStyles.pageTitle}>Create Property</h5>
            </Col>
          </Row>
          <Card className="mb-4 mt-2">
            <Card.Header className={styles.propertyInfoHeader}>
              <h4>{data.propertyType} Information</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={12}>{this.renderInput("streetAddress", "Street Address", "text", "Enter Address")}</Col>
              </Row>
              <Row>
                <Col lg={3}>{this.renderInput("city", "City", "text", "Enter City")}</Col>
                <Col lg={3} id="countyCol">
                  {currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE"
                    ? this.renderInput("countyName", "County", "text", "County", ["required", "readOnly"])
                    : this.renderSelect("county", "County", this.state.counties, "Select County", "required")}
                </Col>
                <Col lg={3} id="stateCol">
                  {this.renderInput("stateName", "State", "text", "State", ["required", "readOnly"])}
                </Col>
                <Col lg={3}>{this.renderInput("zipCode", "Zip Code", "text", "Enter Zip")}</Col>
              </Row>

              <Row xs={1} md={2}>
                <Col lg={3} id="countryCol">
                  {currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE"
                    ? this.renderSelect("country", "Country", this.state.countries, "Select", "required")
                    : this.renderInput("countryName", "Country", "text", "Country", ["required", "readOnly"])}
                </Col>
                <Col lg={3}>{this.renderInput("latitude", "Latitude", "number", "Enter Latitude", "required")}</Col>
                <Col lg={3}>{this.renderInput("longitude", "Longitude", "number", "Enter Longitude", "required")}</Col>
                <Col lg={3}>
                  <Button className={styles.findButton} onClick={this.mapFind}>
                    <span className={globalStyles.modalSubmitButtonText}>Find</span>
                  </Button>
                </Col>
              </Row>
              <div className="mx-2">
                <Row>
                  <br></br>
                </Row>
                <Row className={styles.mapContainer} id="search-map-container">
                  <SearchMap
                    latitude={this.state.latitudeForMapFind}
                    longitude={this.state.longitudeForMapFind}
                    address={this.state.addressStringForMapFind}
                    shouldIRecalculate={this.state.doesMapNeedRerender}
                  />
                </Row>
              </div>
            </Card.Body>
          </Card>
          {showMap && (
            <Card className="mb-4 mt-2" data-testid="additionalInfoCard">
              <Card.Header className="text-left">
                <h4>Additional Information</h4>
              </Card.Header>
              <Card.Body>
                <div className="mx-2">
                  <Row xs={1} md={2}>
                    <Col>{this.renderInput("propertyName", "Property Name", "text", "Enter name", "required")}</Col>
                    <Col className="text-left">
                      <Form.Label className={styles.fieldLabels}>Primary Cooperator</Form.Label>
                      <Fragment>
                        <AsyncSelect
                          aria-labelledby="formLabel"
                          openMenuOnClick={false}
                          placeholder="Enter name"
                          loadOptions={this.handleCooperatorSearch}
                          onChange={this.handleCooperatorChange}
                        />
                      </Fragment>
                    </Col>
                  </Row>
                  <Row xs={1} md={2}>
                    <Col>{this.renderInput("acres", "Area in Acres", "number", "Enter area in acres", "required")}</Col>
                    <Col>
                      {this.renderInput(
                        "calculatedAcres",
                        "Area in Acres (Calculated)",
                        "text",
                        data.calculatedAcres,
                        "readOnly"
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      {data.propertyType === "Non-Parcel" &&
                        this.renderSelect(
                          "landClass",
                          "Land Class",
                          this.state.landClasses,
                          "Select Type",
                          data.propertyType === "Non-Parcel" ? "required" : ""
                        )}
                    </Col>
                  </Row>
                  <Row xs={1} md={2}>
                    <Col></Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          )}
          <Row className="text-right">
            <Col>
              <span className={styles.createPropertyCancelButton}>{this.renderButton("Cancel", "cancel")}</span>
              {this.renderButton("Create", "submit")}
            </Col>
          </Row>
        </div>
        <DuplicatedPropertyModal
          show={this.state.showDuplicatedPropertyModal}
          onHide={this.closeDuplicatedPropertyModal}
          duplicatedRecordData={this.state.duplicatedRecordData}
          createMethodBody={this.state.createMethodBody}
          onForceCreation={this.handleForceCreation}
        />
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfullCreateModal}
          onHide={this.closeSuccessfulCreateModal}
          messageType="create"
          onAcknowledgeRoute="/property/details"
          routeData={this.state.routeNavigationData}
          objectName="Property"
        />
      </form>
    );
  }
}

export default withRouter(CreatePropertyForm);
