import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./Conflicts.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Form, Card, Row, Col, Button } from "react-bootstrap";
import CustomForm from "../common/form";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import searchIcon from "../../assets/search.svg";
import AddResourcesModal from "./AddResourcesModal";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import ConflictsAPI from "../../api/Conflicts/ConflictsAPI";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import CreateResourcesModal from "./CreateResourcesModal";
import UtilityFunctions from "../common/UtilityFunctions";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class ConflictForm extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        showResourceAdditionModal: false,
        showCreateResourcesModal: false,
        selectedSpecies: "",
        selectedProperty: "",
        properties: [],
        damages: [],
        routeAfterCreation: "/home",
        resourcesToRemove: [],
      },
      errors: {},
      currentUsaState: "",
      usaStates: [],
      countries: [],
      resourceUOMs: [],
      showCreateSuccessModal: false,
      formType: props.formType ? props.formType : "create",
      isFormEditable: true,
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    this.validateStateData(currentUsaState);

    let { data, formType, isFormEditable } = this.state;
    data.damages = await this.getThreatTypesFromAPI();

    const usaStates = await this.getUsaStates();
    let countries = [];

    if (formType === "create" && this.props.history?.location?.state) {
      await this.initializePropertiesFromRoutingData(data);
      countries = this.props.history.location.state?.countries;
    } else countries = await this.getAllCountriesFromAPI();

    if (formType === "update") {
      isFormEditable = false;
      this.updateRoutingData(data);
      await this.getConflictData(data);
    }

    this.setState({ data, countries, usaStates, isFormEditable, currentUsaState });
  }

  async initializePropertiesFromRoutingData(data) {
    const propertyData = this.props.history.location.state?.propertyData;
    if (propertyData) {
      await this.updateResourcesForPropertyOnAgreement(propertyData);
      data.properties.push(propertyData);
      data.routeAfterCreation = "/property/details";
      data.remoteEditPropertyData = propertyData;
    }
  }

  async getConflictData(data) {
    const conflictId = this.props.history.location.state?.conflictId;

    if (conflictId) {
      const conflictResponse = await ConflictsAPI.GetConflict(conflictId);
      if (conflictResponse?.data) {
        const conflictsData = conflictResponse.data;
        data.selectedSpecies =
          conflictsData.targetedSpeciesUniqueReference && conflictsData.targetedSpeciesName
            ? { value: conflictsData.targetedSpeciesUniqueReference, label: conflictsData.targetedSpeciesName }
            : "";

        data.currentUsaState = conflictsData.stateUniqueReference
          ? { stateGuid: conflictsData.stateUniqueReference }
          : "";

        if (conflictsData.properties?.length > 0) data.properties = await this.getPropertiesData(conflictsData);
      }
    }
  }

  updateRoutingData(data) {
    const propertyData = this.props.history.location.state?.propertyData;
    if (propertyData) {
      data.routeAfterCreation = "/property/details";
      data.remoteEditPropertyData = propertyData;
    }
  }

  async getPropertiesData(conflictsData) {
    let properties = [];

    for (let conflictProperty of conflictsData.properties) {
      const responseData = await AgreementEntitiesAPI.GetProperty(conflictProperty.propertyUniqueReference);
      if (responseData?.data) {
        const propertyData = responseData.data;
        propertyData.previouslyExisted = true;
        
        const getAgreementResponse = await AgreementAPI.GetActiveAgreementByProperty(
          conflictProperty.propertyUniqueReference
        );
        propertyData.agreementDate = (getAgreementResponse?.successful) ?
          UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(
            getAgreementResponse.data?.agreementSignatureDate).toLocaleDateString("en-US") : null;

        propertyData.protectedResources = conflictProperty.protectedResources.map((resource) => ({
          propertyUniqueIdentifier: conflictProperty.propertyUniqueReference,
          resourceUniqueReference: resource.protectedResourceUniqueReference,
          conflictResourceUniqueIdentifier: resource.conflictResourceUniqueIdentifier,
          resourceName: resource.protectedResourceName,
          threatType: resource.threatType,
          previouslyExisted: true,
        }));
        await this.updateResourcesForPropertyOnAgreement(propertyData);
        properties.push(propertyData);
      }
    }

    return properties;
  }

  async getUsaStates() {
    const usaStatesResponse = await ReferenceFileAPI.GetUSStates();
    return usaStatesResponse && usaStatesResponse.length > 0 ? usaStatesResponse : [];
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  async getThreatTypesFromAPI() {
    const threatTypes = await ConflictsAPI.GetAllThreatTypeOptions();
    if (threatTypes?.successful) {
      return threatTypes.data?.map((type) => {
        type.value = type.id;
        type.label = type.displayText;
        return type;
      });
    } else if (threatTypes?.unsuccessful) {
      toast.error(threatTypes.message);
    } else {
      toast.error("Failed to load threat types, please close the modal and try again.");
    }
  }

  handlePropertySelection = async (input) => {
    const { data } = this.state;

    const responseData = await AgreementEntitiesAPI.GetProperty(input.value);
    if (responseData?.data) {
      const propertyData = responseData.data;
      await this.updateResourcesForPropertyOnAgreement(propertyData);
      data.properties.push(propertyData);
    }

    this.setState({ data });
  };

  handleSpeciesSelection = (input) => {
    const { data } = this.state;
    data.selectedSpecies = input;

    this.setState({ data });
    return input;
  };

  loadAvailableProperties = async (inputValue) => {
    const { data } = this.state;
    const availableProperties = await this.getPropertiesFromAPI(inputValue);

    return availableProperties.filter(
      (property) => !data.properties.some((p) => p.propertyUniqueIdentifier === property.value)
    );
  };

  loadAvailableSpecies = async (inputValue) => {
    const { data } = this.state;
    return data.properties.length > 0 ? this.getSpeciesByAgreementProperties() : this.getSpeciesForState(inputValue);
  };

  async updateResourcesForPropertyOnAgreement(propertyData) {
    const agreementPropertyData = await AgreementAPI.GetAgreementPropertyFromActiveAgreement(
      propertyData.propertyUniqueIdentifier
    );

    if (agreementPropertyData?.data) {
      propertyData.agreementResources = agreementPropertyData.data.agreementResources
        .filter((r) => !r.isHistorical)
        .map((resource) => ({
          resourceUniqueReference: resource.agreementResourceUniqueIdentifier,
          resourceName: resource.resourceName,
        }));
    } else propertyData.agreementResources = [];
  }

  getPrimaryAddress(propertyData) {
    let primaryAddress = "";

    if (propertyData.additionalStreetAddresses?.length > 0) {
      const { streetAddress, city, zipCode } = propertyData.additionalStreetAddresses[0];
      primaryAddress = streetAddress.concat(
        ", ",
        city,
        ", ",
        this.getStateNameFromId(propertyData.state),
        " ",
        zipCode,
        ", " + this.getCountryNameFromId(propertyData.countryUniqueIdentifier)
      );
    }

    return primaryAddress;
  }

  getStateNameFromId(stateId) {
    const { usaStates } = this.state;
    const currentState = usaStates.find((state) => state.stateUniqueIdentifier === stateId);
    return currentState ? currentState.state_name : "";
  }

  getCountryNameFromId(countryId) {
    const { countries } = this.state;
    const country = countries.find((country) => country.id === countryId);
    return country ? country.name : "";
  }

  async getSpeciesForState(searchValue) {
    const { currentUsaState } = this.state;
    if (currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(
        currentUsaState.stateGuid,
        UtilityFunctions.getFilterReferenceFilesCallBody(searchValue)
      );

      if (speciesData?.data?.results) {
        let results = speciesData.data.results;
        return results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return "";
  }

  async getSpeciesByAgreementProperties() {
    const { currentUsaState } = this.state;
    if (currentUsaState?.stateGuid) {
      const speciesData = await AgreementAPI.GetTargetedSpeciesByActiveAgreementProperties(
        this.getSpeciesByAgreementBody(currentUsaState.stateGuid)
      );

      if (speciesData?.data?.length > 0) {
        return speciesData.data.map((result) => ({
          value: result.speciesUniqueReference,
          label: result.speciesName,
        }));
      }
    }

    return "";
  }

  getSpeciesByAgreementBody(stateId) {
    return {
      stateUniqueReference: stateId,
      properties: this.state.data.properties.map((property) => ({
        propertyUniqueReference: property.propertyUniqueIdentifier,
      })),
    };
  }

  async getPropertiesFromAPI(searchValue) {
    const { data, currentUsaState } = this.state;

    if (currentUsaState?.stateGuid) {
      const propertiesData = await AgreementAPI.SearchActiveAgreementPropertiesByTargetedSpecies(
        this.getSearchPropertiesBody(data.selectedSpecies, searchValue, currentUsaState.stateGuid)
      );

      if (propertiesData?.data?.results) {
        let results = propertiesData.data.results;
        return results.map((result) => ({
          value: result.propertyUniqueReference,
          label: result.propertyName,
        }));
      }
    }

    return "";
  }

  getSearchPropertiesBody(selectedSpecies, searchValue, stateId) {
    return {
      stateUniqueReference: stateId,
      speciesUniqueReference: selectedSpecies ? selectedSpecies.value : null,
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
    };
  }

  handleSpecificChanges(input) {
    //this is intentional
  }

  handleRemoveProperty = (propertyId) => {
    const { data } = this.state;
    data.properties = [...data.properties].filter((p) => p.propertyUniqueIdentifier !== propertyId);
    this.setState({ data });
  };

  handleRemoveResource = (resourceToDelete) => {
    const { data } = this.state;
    const resourceProperty = data.properties.find(
      (property) => property.propertyUniqueIdentifier === resourceToDelete.propertyUniqueIdentifier
    );

    if (this.state.formType === "update" && this.isOnlyOneResourceInConflict(data)) {
      toast.warning("Cannot remove all of the Protected Resources from a Conflict");
      return;
    }

    if (resourceProperty) {
      resourceProperty.protectedResources = resourceProperty.protectedResources.filter(
        (resource) => resource.resourceUniqueReference !== resourceToDelete.resourceUniqueReference
      );
      if (resourceToDelete.conflictResourceUniqueIdentifier)
        data.resourcesToRemove.push(resourceToDelete.conflictResourceUniqueIdentifier);

      this.setState({ data });
    }
  };

  isOnlyOneResourceInConflict(data) {
    let existingResourcesCount = 0;
    data.properties.forEach((property) => {
      existingResourcesCount += property.protectedResources?.length;
    });
    return existingResourcesCount === 1;
  }

  handleAddResources = () => {
    this.setState({ showResourceAdditionModal: true });
  };

  closeResourceAdditionModal = () => {
    this.setState({ showResourceAdditionModal: false });
  };

  closeCreateResourcesModal = (updatePropertyResources) => {
    if (updatePropertyResources) {
      for (let i = 0; i < this.state.data.properties.length; i++) {
        this.updateResourcesForPropertyOnAgreement(this.state.data.properties[i]);
      }
    }
    this.setState({ showCreateResourcesModal: false, showResourceAdditionModal: true });
  };

  showCreateResourcesModal = async () => {
    const uoms = await this.getResourceUOMs();
    this.setState({ showCreateResourcesModal: true, resourceUOMs: uoms, showResourceAdditionModal: false });
  };

  async getResourceUOMs() {
    let result = [];
    let resourceUomData = await ReferenceFileAPI.GetAllResourceUOMs();
    if (resourceUomData) {
      if (resourceUomData.data) {
        result = resourceUomData.data.map((resource) => ({ value: resource.id, label: resource.name }));
      }
    }
    return result;
  }

  saveNewResources = (updatedProperties) => {
    const { data } = this.state;
    data.properties = updatedProperties;
    this.setState({ data });
  };

  getConflictCreationBody() {
    return {
      targetedSpeciesUniqueReference: this.state.data.selectedSpecies.value,
      properties: this.getConflictProperties(),
    };
  }

  getConflictProperties() {
    let conflictProperties = [];
    this.state.data.properties.forEach((property) => {
      conflictProperties.push({
        propertyUniqueReference: property.propertyUniqueIdentifier,
        protectedResources: property.protectedResources?.map((resource) => ({
          protectedResourceUniqueReference: resource.resourceUniqueReference,
          threatType: resource.threatType,
          searchDateForAgreement: property.agreementDate
        })),
      });
    });
    return conflictProperties;
  }

  isValidConflictData() {
    const { data } = this.state;
    if (
      data.selectedSpecies?.value &&
      data.properties?.length > 0 &&
      data.properties?.some((property) => property.protectedResources?.length > 0)
    )
      return true;
    else {
      toast.info(
        <div>
          <div>Conflict data is incomplete.</div>
          <div>At least one property, one resource and one damage agent must be selected</div>
        </div>
      );
      return false;
    }
  }

  handleEditButton = async () => {
    if (!this.state.isFormEditable) this.setState({ isFormEditable: true });
    else {
      await this.doSubmit();
    }
  };

  handleCancelEditButton = () => {
    this.setState({ isFormEditable: false });
  };

  async doSubmit() {
    const { formType } = this.state;
    if (!this.isValidConflictData()) return;

    await this.submitConflict(formType);
  }

  async submitConflict(formType = "create") {
    let isProcessSuccessfull = false;

    try {
      const actionResponse =
        formType === "update"
          ? await this.editConflict()
          : await ConflictsAPI.CreateConflict(this.getConflictCreationBody());

      if (actionResponse?.successful) {
        isProcessSuccessfull = true;
        this.setState({ formType, showCreateSuccessModal: true });
      }
    } catch (error) {
      ErrorHandler.showError(error, "Conflict update failed.");
    }

    if (!isProcessSuccessfull) console.log("Conflict submit failed");
  }

  async editConflict() {
    const conflictId = this.props.history.location.state?.conflictId;
    if (conflictId) {
      const conflictDataToUpdate = this.getConflictDataToUpdate();

      if (await this.updateConflict(conflictDataToUpdate, conflictId)) return { successful: true };
      else ErrorHandler.showError("Conflict update could not be completed successfully.");
    }
  }

  getConflictDataToUpdate() {
    const resourcesToRemove = this.state.data.resourcesToRemove.map((resource) => ({
      conflictResourceUniqueIdentifier: resource,
    }));
    let propertiesToAdd = [];
    let resourcesToAdd = [];

    this.state.data.properties.forEach((property) => {
      if (!property.previouslyExisted) {
        propertiesToAdd.push({ propertyUniqueReference: property.propertyUniqueIdentifier
        , searchDateForAgreement:property.agreementDate });
      }

      resourcesToAdd = [
        ...resourcesToAdd,
        ...property.protectedResources
          .filter((r) => !r.previouslyExisted)
          .map((resource) => ({
            propertyUniqueReference: resource.propertyUniqueIdentifier,
            protectedResourceUniqueReference: resource.resourceUniqueReference,
            threatTypeEnumId: resource.threatType?.threatTypeEnumId,
            searchDateForAgreement: property.agreementDate
          })),
      ];
    });

    return { resourcesToRemove, propertiesToAdd: propertiesToAdd, resourcesToAdd: resourcesToAdd };
  }

  async updateConflict(conflictDataToUpdate, conflictId) {
    let isAddPropertiesSuccesful = true;
    let isAddResourcesSuccesful = true;
    let isRemoveResourcesSuccesful = true;
    const { resourcesToRemove, propertiesToAdd, resourcesToAdd } = conflictDataToUpdate;

    if (resourcesToRemove.length > 0)
      isRemoveResourcesSuccesful = await this.removeResourcesFromConflict(conflictId, resourcesToRemove);
    if (propertiesToAdd.length > 0)
      isAddPropertiesSuccesful = await this.addPropertiesToConflict(conflictId, propertiesToAdd);
    if (resourcesToAdd.length > 0)
      isAddResourcesSuccesful = await this.addResourcesToConflict(conflictId, resourcesToAdd);

    return isRemoveResourcesSuccesful && isAddPropertiesSuccesful && isAddResourcesSuccesful;
  }

  async removeResourcesFromConflict(conflictId, resourcesToRemove) {
    const addResourcesResponse = await ConflictsAPI.RemoveProtectedResourcesFromConflict(conflictId, resourcesToRemove);
    const isRemoveResourcesSuccesful = addResourcesResponse?.successful;
    if (!isRemoveResourcesSuccesful) console.log(addResourcesResponse?.message);

    return isRemoveResourcesSuccesful;
  }

  async addPropertiesToConflict(conflictId, propertiesToAdd) {
    const addPropertiesResponse = await ConflictsAPI.AddPropertiesToConflict(conflictId, propertiesToAdd);
    const isAddPropertiesSuccesful = addPropertiesResponse?.successful;
    if (!isAddPropertiesSuccesful) console.log(addPropertiesResponse?.message);

    return isAddPropertiesSuccesful;
  }

  async addResourcesToConflict(conflictId, resourcesToAdd) {
    const addResourcesResponse = await ConflictsAPI.AddProtectedResourcesToConflict(conflictId, resourcesToAdd);
    const isAddResourcesSuccesful = addResourcesResponse?.successful;
    if (!isAddResourcesSuccesful) console.log(addResourcesResponse?.message);

    return isAddResourcesSuccesful;
  }

  renderPropertyCardBody(data, isFormEditable) {
    let searchPropertyRow = null;
    if (isFormEditable) {
      searchPropertyRow = (
        <Row className="my-1 mx-1">
          <Col className="text-left">
            <label className={styles.formLabel}>Search for Properties</label>
            <AsyncSelect
              aria-label="Search for Properties Bar"
              value={data.selectedProperty}
              openMenuOnClick={false}
              placeholder="Search here.."
              components={{ DropdownIndicator }}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
              }}
              loadOptions={this.loadAvailableProperties}
              onChange={this.handlePropertySelection}
            />
          </Col>
        </Row>
      );
    }
    return (
      <React.Fragment>
        {searchPropertyRow}
        <div className="mt-5">
          {data.properties?.length > 0 &&
            data.properties.map((property, index) => (
              <Row key={index} className={index < data.properties.length - 1 ? styles.listedPropertyRow : "my-2 mx-1"}>
                <Col className="text-left">
                  {this.renderLabel(`property-${index}`, property.propertyName, this.getPrimaryAddress(property))}
                </Col>
                {isFormEditable && (
                  <Col className="text-right">
                    <Button variant="link" onClick={() => this.handleRemoveProperty(property.propertyUniqueIdentifier)}>
                      <span className={styles.formData}>Remove</span>
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
        </div>
      </React.Fragment>
    );
  }

  renderSpeciesCardBody(data, formType) {
    return (
      <Row className="mx-1 mt-1 mb-4">
        <Col sm={6} className="text-left">
          <label className={styles.formLabel}>Species Selection</label>
          {formType !== "create" && (
            <div>
              <label className={styles.formData}>{data.selectedSpecies?.label}</label>
            </div>
          )}
          {formType === "create" && (
            <div>
              <AsyncSelect
                aria-label="Select Species Bar"
                value={data.selectedSpecies}
                openMenuOnClick={false}
                placeholder="Select One"
                components={{ DropdownIndicator }}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                }}
                loadOptions={this.loadAvailableSpecies}
                onChange={this.handleSpeciesSelection}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  }

  renderResourcesCardBody(data, isFormEditable) {
    return (
      <React.Fragment>
        <Row className="mt-1 mx-1">
          <Col className="text-left">
            <label className={styles.formLabel}>Resources Selection</label>
          </Col>
        </Row>
        {data.properties.map((property) => {
          return (
            property.protectedResources?.length > 0 &&
            property.protectedResources.map((resource, index) => (
              <Row key={index} className={`mx-1 mb-4 ${index === 0 ? "mt-3" : "mt-4"}`}>
                <Col>
                  <Row>
                    <Col className="text-left">
                      <span className={styles.greenFormData}>{property.propertyName}</span>
                    </Col>
                  </Row>
                  <div className={styles.listedResourceRowSeparator}></div>
                  <Row>
                    <Col sm={2} className="text-left">
                      <span className={styles.formData}>{resource.resourceName}</span>
                    </Col>
                    <Col sm={2} className="text-left">
                      <span className={styles.formData}>
                        {UtilityFunctions.getDisplayTextFromFieldObject(resource?.threatType)}
                      </span>
                    </Col>
                    {isFormEditable && (
                      <Col className="text-right">
                        <Button variant="link" onClick={() => this.handleRemoveResource(resource)}>
                          <span className={styles.formData}>Remove</span>
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            ))
          );
        })}
        {isFormEditable && (
          <Row className="mx-1">
            <Col className="text-left">
              <Button variant="link" className={styles.addResourcesButton} onClick={this.handleAddResources}>
                <span className={styles.formLabel}>+ Add Resources</span>
              </Button>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { data, isFormEditable, formType, usaStates } = this.state;
    let cancelButton = null;
    let topPageButtons = null;
    let bottomPageButtons = null;
    if (isFormEditable) {
      cancelButton = (
        <div className="mr-2">
          <Button variant="outline-primary" onClick={this.handleCancelEditButton}>
            Cancel
          </Button>
        </div>
      );
    }
    if (formType !== "create") {
      topPageButtons = (
        <div className="form-inline justify-content-end">
          {cancelButton}
          <div className="ml-2">
            <Button variant="primary" onClick={this.handleEditButton}>
              {!isFormEditable ? "Edit" : "Save"}
            </Button>
          </div>
        </div>
      );
    }
    if (formType === "create") {
      bottomPageButtons = (
        <div className="form-inline justify-content-end mt-4">
          <div className="mr-2">{this.renderButton("Cancel", "cancel")}</div>
          <div className="ml-2">{this.renderButton("Create", "submit")}</div>
        </div>
      );
    }

    return (
      <div className="container w-75 mt-3">
        <Form noValidate onSubmit={this.handleSubmit}>
          <Row>
            <Col>
              <h1 className={styles.pageTitle}>{formType === "create" ? "Create Conflict" : "Conflict"}</h1>
            </Col>
            <Col>{topPageButtons}</Col>
          </Row>
          <Card className="mt-3 mb-2">
            <Card.Header className={styles.cardHeader}>
              <span className={globalStyles.modalTitleText}>{isFormEditable ? "Add Properties" : "Properties"}</span>
            </Card.Header>
            <Card.Body>{this.renderPropertyCardBody(data, isFormEditable)}</Card.Body>
          </Card>
          <Card className="my-4">
            <Card.Header className={styles.cardHeader}>
              <span className={globalStyles.modalTitleText}>
                {formType === "create" ? "Add Damage Agent" : "Damage Agent"}
              </span>
            </Card.Header>
            <Card.Body>{this.renderSpeciesCardBody(data, formType)}</Card.Body>
          </Card>
          <Card className="my-2">
            <Card.Header className={styles.cardHeader}>
              <span className={globalStyles.modalTitleText}>{isFormEditable ? "Add Resources" : "Resources"}</span>
            </Card.Header>
            <Card.Body>{this.renderResourcesCardBody(data, isFormEditable)}</Card.Body>
          </Card>
          {bottomPageButtons}
        </Form>
        {this.state.showResourceAdditionModal && (
          <AddResourcesModal
            show={this.state.showResourceAdditionModal}
            onHide={this.closeResourceAdditionModal}
            onSaveNewResources={this.saveNewResources}
            damages={data.damages}
            properties={data.properties}
            showCreateModal={this.showCreateResourcesModal}
          />
        )}
        {this.state.showCreateResourcesModal && (
          <CreateResourcesModal
            show={this.state.showCreateResourcesModal}
            onHide={this.closeCreateResourcesModal}
            properties={data.properties}
            resourceUOMs={this.state.resourceUOMs}
            currentUsaState={this.state.currentUsaState}
          />
        )}

        <SuccessfulRecordModifiedModal
          show={this.state.showCreateSuccessModal}
          onHide={() => {
            this.setState({ showCreateSuccessModal: false });
          }}
          onAcknowledgeRoute={data.routeAfterCreation}
          routeData={{ entityData: data.remoteEditPropertyData, states: usaStates }}
          messageType={this.state.formType}
          objectName="Conflict"
        />
      </div>
    );
  }
}
export default withRouter(ConflictForm);
