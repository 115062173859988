import React from "react";
// import 'globalthis/polyfill';
import "./App.css";
import "./components/Login/Login";
import { LoginFormModes } from "./components/Login/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import Dashboard from "./components/dashboard/dashboard";
import TopNavbar from "./components/navbar/navbar";
import ReferenceFiles from "./components/ReferenceFiles/ReferenceFiles";
import HomePage from "./components/HomePage/HomePage";
import CreateSpecies from "./components/ReferenceFilesCreatePages/CreateSpecies";
import CreateMethodPage from "./components/ReferenceFilesCreatePages/CreateMethodPage";
import ReferenceFilesAPI from "./api/ReferenceFiles/ReferenceFileAPI";
import EditSpecies from "./components/ReferenceFilesEditPages/EditSpecies";
import ResourceForm from "./components/ReferenceFilesCreatePages/resourceForm";
import EditMethodPage from "./components/ReferenceFilesEditPages/EditMethodPage";
import CreateContact from "./components/AgreementEntities/Contact/CreateContact";
import CreatePropertyForm from "./components/AgreementEntities/Properties/createPropertyForm";
import CreateParcelPropertyForm from "./components/AgreementEntities/Properties/CreateParcelPropertyForm";
import DetailsEditContact from "./components/AgreementEntities/Contact/DetailsEditContact";
import SearchAgreements from "./components/AgreementEntities/agreementsSearch/searchAgreements";
import DisplayEditProperty from "./components/AgreementEntities/Properties/DisplayEditProperty";
import Agreement from "./components/Agreements/Agreement";
import DisplayAgreementDetails from "./components/AgreementEntities/Agreements/displayAgreementDetails";
import RoleAuthorizationAPI from "./api/RoleAuthorization/RoleAuthorizationAPI";
import AgreementEntitiesAPI from "./api/AgreementEntities/AgreementEntitiesAPI";
import PropertyVisitSummary from "./components/AgreementEntities/Properties/PropertyVisitSummary/PropertyVisitSummary";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorHandler from "./ErrorHandler/ErrorHandler";
import AgreementsListPage from "./components/Agreements/AgreementsListPage";
import AerialWorkTask from "./components/AerialWorkTask/AerialWorkTask";
import AdminCenter from "./components/AdminCenter/AdminCenter";
import UserRecord from "./components/UserRoles/Users/UserRecord";
import AdditionalPermissions from "./components/AdminCenter/AdditionalPermissions";
import Permissions from "./components/UserRoles/PermissionsClasses/Permissions";
import IdleTimer from "react-idle-timer";
import { config } from "../package.json";
import DisplayEditPermit from "./components/PermitModals/DisplayEditPage/DisplayEditPermit";
import ProjectDetailsPage from "./components/Projects/ProjectDetailsPage";
import TAWorkTaskDetailsPage from "./components/TechnicalAssists/TAWorkTaskDetailsPage";
import DistrictDetailsPage from "./components/Districts/DistrictDetailsPage";
import WarehouseDetailsPage from "./components/Warehouse/WarehouseDetailsPage";
import CMITSListingPage from "./components/Warehouse/CMITSListingPage";
import ReportPage from "./components/Reports/ReportPage";
import TransactionHistoryPage from "./components/Warehouse/TransactionHistoryPage";
import RabiesDetailsPage from "./components/AgreementEntities/Properties/PropertyVisitSummary/RabiesDetailsPage";
import OtherSampleDetailsPage from "./components/AgreementEntities/Properties/PropertyVisitSummary/OtherSampleDetailsPage";
import DiseaseMonitoringDetailsPage from "./components/AgreementEntities/Properties/PropertyVisitSummary/DiseaseMonitoringDetailsPage";
import AuthMap from "./components/ESRIIntegration/MapAuthorization";
import OauthCallback from "./components/ESRIIntegration/OauthCallback";
import ConflictForm from "./components/Conflicts/ConflictForm";
import $ from "jquery";
import "./jquery-ui.css";
import datepickerFactory from "jquery-datepicker";
import lsm from "./api/lsm";
import ReactErrorBoundary from "./ErrorHandler/ErrorBoundary";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);

    this.state = {
      isSidebarDisplayed: false,
      isSearchBoxDisplayed: false,
      states: [],
      currentState: null,
      username: "",
      identity: null,
      userProfiles: [],
      currentUserProfile: "",
      isInitialRoleConfiguration: true,
      permissions: new Permissions(),
      permissionsLoaded: false,
      userData: "",
      isInitialLoad: true,
      path: window.location.pathname,
    };
  }

  async componentDidMount() {
    if (lsm.IsUserAuthenticated() === true) {
      const userData = await this.getUserData();
      const userProfiles = userData?.userProfiles?.length > 0 ? userData.userProfiles : [];
      const currentUserProfile = await this.getCurrentUserProfile();

      const currentState = lsm.GetCurrentState();
      if (currentState?.stateGuid) {
        this.setArcGISMapsForStates(currentState.stateGuid);
      }
      let initResult = await this.state.permissions.init(userData, currentUserProfile);
      const username = lsm.GetCurrentUsername();
      const identity = lsm.GetCurrentIdentity();
      this.setState(
        {
          currentState,
          userProfiles,
          currentUserProfile,
          username,
          identity,
          userData,
          isInitialLoad: false,
          permissionsLoaded: initResult?.rolePermissions || initResult?.specialPermissions,
        },
        async () => {
          this.setState({ states: await this.getAvailableStates() });
        }
      );
    } else {
      console.warn("User isn't logged in.");
      lsm.RedirectToLogin("App ComponentDidMount event reacts to Local Storage IsUserAuthenticated = false")
    }
    datepickerFactory($);
  }

  async getAvailableStates() {
    let availableStates = [];

    const associatedStates = await this.getAssociatedStates();
    if (associatedStates.length > 0) {
      const usaStates = await ReferenceFilesAPI.GetAllStates();
      if (usaStates?.length > 0) {
        for (const associatedStateId of associatedStates) {
          const associatedState = usaStates.find((state) => state.stateUniqueIdentifier === associatedStateId);
          if (associatedState) {
            availableStates.push({
              stateGuid: associatedStateId,
              stateName: associatedState.state_name,
            });
          }
        }
      }
    }
    return availableStates?.length > 0 ? availableStates.sort((a, b) => (a.stateName < b.stateName ? -1 : 1)) : [];
  }

  async getAssociatedStates() {
    let associatedStates = [];
    if (this.state.currentUserProfile?.profileAssociatedStates?.length > 0) {
      associatedStates = this.state.currentUserProfile.profileAssociatedStates;
    } else {
      const userPermissionsResponse = await RoleAuthorizationAPI.MyPermissions();
      if (userPermissionsResponse?.successful && userPermissionsResponse.data?.associatedStates?.length > 0) {
        associatedStates = userPermissionsResponse.data.associatedStates;
      }
    }

    return associatedStates;
  }

  async getCurrentUserProfile() {
    let currentUseProfile = "";
    const currentUseProfileResponse = await RoleAuthorizationAPI.GetUsersCurrentProfile();
    if (currentUseProfileResponse?.successful) {
      currentUseProfile = currentUseProfileResponse.data;
    } else {
      ErrorHandler.showError(
        `System failed to get user roles. ${currentUseProfileResponse?.message ? currentUseProfileResponse.message : ""
        }`
      );
    }
    return currentUseProfile;
  }

  async setArcGISMapsForStates(stateGuid) {
    let stateArray = [];
    stateArray.push(stateGuid);
    const stateMaps = await AgreementEntitiesAPI.GetArcGISMapsForStates(stateArray);
    if (stateMaps?.data?.length > 0) {
      sessionStorage.setItem("mapId", stateMaps?.data[0]?.mapId);
      sessionStorage.setItem("featureService", stateMaps?.data[0]?.featureServiceName);
    }
    if (!sessionStorage.getItem("mapAccess")) {
      sessionStorage.setItem("mapAccess", "No");
    }
    return;
  }

  handleMenuToggled = () => {
    this.setState({ isSidebarDisplayed: !this.state.isSidebarDisplayed });
  };

  closeMenu = () => {
    if (this.state.isSidebarDisplayed) {
      this.setState({ isSidebarDisplayed: false });
    }
  };

  handleSearchBoxToggled = () => {
    this.setState({ isSearchBoxDisplayed: !this.state.isSearchBoxDisplayed });
  };

  handleStateSelect = async (e) => {
    if (e !== this.state.currentState) {
      this.state.permissions.selectedState = e.stateGuid;
      await this.setArcGISMapsForStates(e.stateGuid);
      const r = await RoleAuthorizationAPI.GetTokenWithUserContext(
        this.state.currentUserProfile?.wsUserProfileUniqueIdentifier,
        e.stateGuid
      );
      if (r?.successful) {
        toast.info(`A new state (${e.stateName}) has been selected.`);
        localStorage.setItem("currentState", JSON.stringify(e));
        this.setState({ currentState: e });
        lsm.SetUserAuthenticated(r.data?.token);
      } else if (r?.unsuccessful) {
        ErrorHandler.showErrorWithDetails("Failed to set user state. ", r?.message);
      } else {
        ErrorHandler.showErrorWithMessage("Failed to set user state.");
      }
    }
  };

  handleRoleSelect = async (e) => {
    if (e?.wsUserProfileUniqueIdentifier !== this.state.currentUserProfile?.wsUserProfileUniqueIdentifier) {
      const r = await RoleAuthorizationAPI.GetTokenWithUserContext(
        e?.wsUserProfileUniqueIdentifier,
        this.state.currentState?.stateGuid
      );
      if (r?.successful) {
        const userData = await this.getUserData();
        e.userName = userData?.firstName + " " + userData?.lastName;
        e.mailingAddress = {
          mailingStreet: userData?.streetAddress,
          mailingCity: userData?.city,
          mailingZip: userData?.zip,
          mailingStateUniqueId: userData?.stateOfAddressUniqueReference,
        };
        e.phoneNumber = userData?.primaryPhoneNumber;
        localStorage.setItem("currentUserRole", JSON.stringify(e));
        let initResult = await this.state.permissions.init(userData, e);
        this.setState(
          {
            currentUserProfile: e,
            isInitialRoleConfiguration: false,
            userData,
            permissionsLoaded: initResult?.rolePermissions || initResult?.specialPermissions,
          },
          async () => {
            const states = await this.getAvailableStates();
            if (states?.length > 0 && !states.some((state) => state.stateGuid === this.state.currentState?.stateGuid)) {
              localStorage.setItem("currentState", JSON.stringify(states[0]));
              this.setState({ currentState: states[0] });
            }
            this.setState({ states: states }, () => {
              toast.info(`A new User Profile (${e.profileName}) has been selected.`);
            });
          }
        );
      } else if (r?.unsuccessful) {
        ErrorHandler.showErrorWithDetails("Failed to set user profile. ", r?.message);
      } else {
        ErrorHandler.showErrorWithMessage("Failed to set user profile.");
      }
    }
  };

  handleStatesUpdate = (states) => {
    this.setState({ states });
  };

  handleProfilesUpdate = (userProfiles) => {
    this.setState({ userProfiles });
  };

  async getUserData() {
    let userData = "";
    const userResponse = await RoleAuthorizationAPI.GetCurrentUser();
    if (userResponse?.successful) {
      userData = userResponse.data;
      if (userData?.userProfiles?.length > 0) {
        userData.userProfiles = userData.userProfiles
          .map((x) => {
            x.wsUserUniqueIdentifier = userData.wsUserUniqueIdentifier;
            return x;
          })
          .sort((a, b) => (a.profileName < b.profileName ? -1 : 1));
      }
    } else {
      //ErrorHandler.showError(`System failed to get user data. ${userResponse?.message ? userResponse.message : ""}`);
    }
    return userData;
  }

  handleOnIdle() {
    // if (window.location.pathname !== "/login") {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   window.location =
    //     window.location.origin +
    //     "/roleauth/Logout?ReturnUrl=" +
    //     encodeURIComponent(window.location.origin + "/login");
    // }
  }

  getPageContainer(page) {
    return (
      <React.Fragment>
        <TopNavbar
          closeMenu={this.closeMenu}
          onMenuToggled={this.handleMenuToggled}
          onSearchBoxToggled={this.handleSearchBoxToggled}
          isSidebarDisplayed={this.state.isSidebarDisplayed}
          userData={this.state}
          onStateSelect={this.handleStateSelect}
          onRoleSelect={this.handleRoleSelect}
          permissions={this.state.permissions}
        />
        <Dashboard
          closeMenu={this.closeMenu}
          isSidebarDisplayed={this.state.isSidebarDisplayed}
          userData={this.state}
          pageComponent={page}
          currentUserProfile={this.state.currentUserProfile}
          permissions={this.state.permissions}
          isSearchBoxDisplayed={this.state.isSearchBoxDisplayed}
          onSearchBoxToggled={this.handleSearchBoxToggled}
        />
      </React.Fragment>
    );
  }

  onError() {
    //ErrorHandler.sendToAppInsights(new Error("Something went wrong, please contact site Admin"));
  }

  renderProtectedRoute(pageComponent, path, isRouteAllowed) {
    return (
      <Route
        path={path}
        render={() => {
          if (!isRouteAllowed) {
            return <Redirect to="/home" />;
          }
          return this.getPageContainer(pageComponent);
        }}
      />
    );
  }

  render() {
    let { permissions, permissionsLoaded, userData, path } = this.state;
    return (
      <ReactErrorBoundary>
        <div className="App">
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={config.idleTimeoutInMillisecs}
            onIdle={this.handleOnIdle}
          />
          <Router>

            {/* <AppInsightsErrorBoundary onError={this.onError()} appInsights={reactPlugin}> */}
            <ToastContainer position="bottom-right" closeOnClick={false} draggable={false} pauseOnHover={true} autoClose={5000} role="alert" />
            {
              // permissionsLoaded ||
              //   path === "/login" ||
              //   path === "/404" ||
              //   path === "/" ||
              //   path === "/oauth-callback" ||
              //   path.includes("/Logout") 
              true
                ? (
                  <Switch>
                    <Route path="/login">
                      <LoginPage mode={LoginFormModes.Login} faqShowing={false} />
                    </Route>
                    <Route path="/home">
                      {this.getPageContainer(
                        <HomePage
                          userData={this.state}
                          onStatesUpdate={this.handleStatesUpdate}
                          onRolesUpdate={this.handleProfilesUpdate}
                          currentUserProfile={this.state.currentUserProfile}
                          states={this.state.states}
                          permissions={permissions}
                        />
                      )}
                    </Route>
                    {this.renderProtectedRoute(<AgreementsListPage />, "/agreementsList", permissions.canIViewAgreements())}
                    {this.renderProtectedRoute(<ReportPage permissions={permissions} />, "/reports-and-queries", true)}
                    {this.renderProtectedRoute(
                      <CreateParcelPropertyForm />,
                      "/property/createparcel",
                      permissions.canICreateProperties()
                    )}
                    {this.renderProtectedRoute(
                      <CreatePropertyForm />,
                      "/property/create",
                      permissions.canICreateProperties()
                    )}
                    {this.renderProtectedRoute(<SearchAgreements />, "/agreemententities/search", true)}
                    {this.renderProtectedRoute(
                      <DisplayEditProperty mode="details" permissions={permissions} />,
                      "/property/details",
                      permissions.canIViewProperties()
                    )}
                    {this.renderProtectedRoute(
                      <DisplayEditProperty mode="edit" />,
                      "/property/edit",
                      permissions.canIEditProperties()
                    )}

                    {this.renderProtectedRoute(
                      <ResourceForm formType="create" />,
                      "/referencefiles/resources/new",
                      permissions.canICreateReferenceData()
                    )}
                    {this.renderProtectedRoute(
                      <ResourceForm formType="edit" />,
                      "/referencefiles/resources/:id",
                      permissions.canIEditReferenceData()
                    )}
                    {this.renderProtectedRoute(
                      <ReferenceFiles permissions={permissions} userData={userData} />,
                      "/referencefiles",
                      permissions.canIViewReferenceData()
                    )}
                    {this.renderProtectedRoute(
                      <Agreement userData={userData} />,
                      "/agreement/create",
                      permissions.canICreateAgreements()
                    )}

                    {this.renderProtectedRoute(<CreateContact />, "/contact/create", permissions.canICreateContacts())}
                    {this.renderProtectedRoute(
                      <DetailsEditContact mode="details" />,
                      "/contact/details",
                      permissions.canIViewContacts()
                    )}
                    {this.renderProtectedRoute(
                      <DetailsEditContact mode="edit" />,
                      "/contact/edit",
                      permissions.canIEditContacts()
                    )}
                    {this.renderProtectedRoute(<CreateSpecies />, "/species/create", permissions.canICreateReferenceData())}
                    {this.renderProtectedRoute(<EditSpecies />, "/species/edit", permissions.canIEditReferenceData())}
                    {this.renderProtectedRoute(
                      <CreateMethodPage />,
                      "/method/create",
                      permissions.canICreateReferenceData()
                    )}
                    {this.renderProtectedRoute(<EditMethodPage />, "/method/edit", permissions.canIEditReferenceData())}
                    {this.renderProtectedRoute(
                      <DisplayAgreementDetails permissions={permissions} />,
                      "/agreement/details",
                      permissions.canIViewAgreements()
                    )}
                    {this.renderProtectedRoute(
                      <ConflictForm formType="update" />,
                      "/conflict/edit",
                      permissions.canIEditConflicts()
                    )}
                    {this.renderProtectedRoute(
                      <PropertyVisitSummary permissions={permissions} currentUserProfile={this.state.currentUserProfile} />,
                      "/sitevisit",
                      permissions.canICreatePVS()
                    )}
                    {this.renderProtectedRoute(
                      <AerialWorkTask />,
                      "/aerialworktask/create",
                      permissions.canICreateAerialWorkTask()
                    )}
                    {this.renderProtectedRoute(
                      <AerialWorkTask isDetailsPage={true} />,
                      "/aerialworktask/details",
                      permissions.canIViewAerialWorkTask()
                    )}
                    {this.renderProtectedRoute(
                      <AdminCenter permissions={permissions} />,
                      "/admincenter",
                      permissions.canIViewGlobalUserRecord() || permissions.canIViewGlobalRole()
                    )}
                    {this.renderProtectedRoute(
                      <UserRecord permissions={permissions} />,
                      "/user/details",
                      permissions.canIViewGlobalUserRecord()
                    )}
                    {this.renderProtectedRoute(
                      <AdditionalPermissions />,
                      "/additionalpermissions",
                      permissions.canIEditSpecialPermissionsAssignedStates()
                    )}
                    {this.renderProtectedRoute(
                      <DisplayEditPermit mode="details" permissions={permissions} />,
                      "/permit/details",
                      permissions.canIViewPermitsAssignedStates()
                    )}
                    {this.renderProtectedRoute(
                      <DisplayEditPermit mode="edit" />,
                      "/permit/edit",
                      permissions.canIEditPermitsAssignedStates()
                    )}
                    {this.renderProtectedRoute(
                      <ProjectDetailsPage permissions={permissions} />,
                      "/project/details",
                      permissions.canIViewProjectsAnyStates()
                    )}
                    {this.renderProtectedRoute(
                      <TAWorkTaskDetailsPage permissions={permissions} />,
                      "/taworktask/details",
                      permissions.canIViewTaWorkTasks()
                    )}
                    {this.renderProtectedRoute(
                      <WarehouseDetailsPage permissions={permissions} />,
                      "/warehouse/details",
                      true
                    )}
                    {this.renderProtectedRoute(
                      <DistrictDetailsPage permissions={permissions} />,
                      "/district/details",
                      true
                    )}
                    {this.renderProtectedRoute(
                      <CMITSListingPage permissions={permissions} userData={this.state} />,
                      "/cmits/details",
                      true
                    )}
                    {this.renderProtectedRoute(
                      <AuthMap permissions={permissions} userData={this.state} />,
                      "/connectmap",
                      true
                    )}
                    {this.renderProtectedRoute(<OauthCallback permissions={permissions} />, "/oauth-callback", true)}
                    {this.renderProtectedRoute(
                      <TransactionHistoryPage permissions={permissions} userData={this.state} />,
                      "/cmits/transactions",
                      true
                    )}
                    {this.renderProtectedRoute(
                      <RabiesDetailsPage permissions={permissions} currentUserProfile={this.state.currentUserProfile} />,
                      "/nrmp/details",
                      permissions.canIViewRabiesAssignedStates()
                    )}
                    {this.renderProtectedRoute(
                      <OtherSampleDetailsPage
                        permissions={permissions}
                        currentUserProfile={this.state.currentUserProfile}
                      />,
                      "/othersample/details",
                      permissions.canIViewSamplesAssignedStates()
                    )}
                    {this.renderProtectedRoute(
                      <DiseaseMonitoringDetailsPage
                        permissions={permissions}
                        currentUserProfile={this.state.currentUserProfile}
                      />,
                      "/diseasemonitoring/details",
                      permissions.canIViewSamplesAssignedStates()
                    )}
                    <Route path="/">
                      {() => {
                        if (lsm.IsUserAuthenticated() === true)
                          return <Redirect to="/home" />
                        else {
                          lsm.SetUserNotAuthenticated();
                          return <Redirect to="/login" />
                        }
                      }
                      }
                    </Route>
                    <Route path="/404">
                      <NotFoundPage />
                    </Route>
                    <Redirect to="/404" />
                  </Switch>
                ) : (
                  <div>Loading OARS Web Application...</div>
                )}
            {/* </AppInsightsErrorBoundary> */}
          </Router>
        </div>
      </ReactErrorBoundary >
    );
  }
}

export default App;
