import React from "react";
import shareIcon from "../../assets/share.svg";
import { Image } from "react-bootstrap";

const exportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Image
      src={shareIcon}
      style={{ cursor: "pointer" }}
      className="pr-2"
      onClick={handleClick}
      title="Export to CSV file"
      alt="Export"
    />
  );
};

export default exportCSV;
