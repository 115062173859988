import React from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import printJS from "print-js";
import CustomForm from "../common/form";
import styles from "./PDFHandling.module.scss";
import globalStyles from "../../OARS.module.scss";
import PrintAgreementForm from "./PrintAgreementForm";
import UtilityFunctions from "../common/UtilityFunctions";

const PrintAgreementTypes = {
  Single: "singleBlankAgreement",
  Multi: "multipropertyBlankAgreement",
};

class PrintAgreementModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: { selectedAgreementType: "", propertiesAmount: "" },
      printAgreementTypes: [
        { value: PrintAgreementTypes.Single, label: "Single Blank Agreement" },
        { value: PrintAgreementTypes.Multi, label: "Multi-Property Blank Agreement" },
      ],
      errors: {},
    };
  }

  componentDidMount() {
    const { data } = this.state;
    if (this.props.isCurrentAgreement) {
      data.selectedAgreementType = { value: "currentAgreement", label: "Current Agreement" };
      this.setState({ data });
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  handleModalCancel = () => {
    const { data } = this.state;
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  clearData(data) {
    if (data.selectedAgreementType?.value !== "currentAgreement") {
      data.selectedAgreementType = "";
    }
  }

  handleAgreementTypeSelection = (input) => {
    const { data } = this.state;
    data.selectedAgreementType = input;
    this.setState({ data });
  };

  downloadPDF = () => {
    const element = document.getElementById("pdfDocument").innerHTML;
    const fileName = this.props.agreementData?.agreementNumber
      ? `AgreementDetails_${this.props.agreementData.agreementNumber}`
      : "AgreementDetails";
    UtilityFunctions.downloadPDF(element, fileName);
  };

  isInvalidAgreementTypeSelected() {
    const { data } = this.state;
    return (
      !data.selectedAgreementType ||
      (data.selectedAgreementType.value === "multipropertyBlankAgreement" && !data.propertiesAmount)
    );
  }

  printAgreement() {
    // printJS({
    //   printable: "pdfDocument",
    //   type: "html",
    //   css: "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css",
    //   targetStyles: ["*"],
    // });
  }

  doSubmit() {}

  render() {
    const { data } = this.state;
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          backdrop="static"
          centered
          scrollable
          dialogClassName={styles.modalDialog}
        >
          <Form noValidate onSubmit={this.handleSubmit}>
            <Modal.Header className={styles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>Print</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.modalBoby}>
              <div className="container">
                <Row className="my-1">
                  <Col>
                    <label className={styles.modalFormLabel}>Agreement Selection</label>
                    <Select
                      placeholder="Select one"
                      styles={{
                        indicatorSeparator: () => {
                          //do nothing
                        },
                        placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                        menuList: (styles) => ({ ...styles, fontSize: "14px", fontWeight: "bolder" }),
                        singleValue: (styles) => ({
                          ...styles,
                          fontSize: "14px",
                          fontWeight: "normal",
                          color: "black",
                        }),
                      }}
                      value={data.selectedAgreementType}
                      onChange={this.handleAgreementTypeSelection}
                      options={this.state.printAgreementTypes}
                      isDisabled={this.props.isCurrentAgreement}
                    />
                  </Col>
                </Row>
                <Row className="my-4" hidden={data.selectedAgreementType?.value !== PrintAgreementTypes.Multi}>
                  <Col sm={6}>
                    {this.renderInput("propertiesAmount", "How Many Properties", "number", "Enter number")}
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={this.handleModalCancel}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <div className="ml-2">
                <Button
                  variant="primary"
                  className="mr-2"
                  onClick={this.downloadPDF}
                  disabled={this.isInvalidAgreementTypeSelected()}
                >
                  Download
                </Button>
                {/* <ReactToPrint
                  trigger={() => (
                    <Button variant="primary" className="mr-2">
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                /> */}
                <Button
                  variant="primary"
                  className="mr-2"
                  onClick={this.printAgreement}
                  disabled={this.isInvalidAgreementTypeSelected()}
                >
                  Print
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
        <div id="pdfDocument" className={styles.contentToPrint}>
          <PrintAgreementForm
            agreementData={this.props.agreementData}
            cooperatorData={this.props.cooperatorData}
            primaryFieldAgentData={this.props.primaryFieldAgentData}
            propertiesData={this.props.propertiesData}
            associatedContactsData={this.props.associatedContactsData}
            states={this.props.states}
            countries={this.props.countries}
            propertiesCount={
              data.selectedAgreementType?.value === "multipropertyBlankAgreement" && data.propertiesAmount
                ? parseInt(data.propertiesAmount)
                : 1
            }
            ref={(el) => (this.componentRef = el)}
          />
        </div>
      </div>
    );
  }
}

export default PrintAgreementModal;
