import React, { Component } from "react";
import { withRouter } from "react-router";
import styles from "../common/common.scss";
import { Button, Modal, Row, Col } from "react-bootstrap";

class ActionConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.okButton = React.createRef();
  }

  componentDidMount() {
    if (this.okButton.current) {
      this.okButton.current.focus();
    }
  }

  handleOk = async () => {
    this.props.onHide();
    this.props.onConfirmation();
  };

  handleCancel = () => {
    this.props.onHide();
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered size="sm">
        <div className="d-flex justify-content-center mt-2">
          <i className="fa fa-exclamation-circle fa-5x" style={{ color: "gray" }} aria-hidden="true"></i>
        </div>
        <Modal.Header>
          <div className="container ">
            <Row className="justify-content-center">
              <Col sm="auto">
                <p className="text-center">{`Are you you sure you want to ${this.props.actionDescription}?`}</p>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Button
                className="mr-1"
                ref={this.cancelButton}
                variant="primary"
                onClick={this.handleCancel}
                title="Cancel"
              >
                Cancel
              </Button>
              <Button className="ml-1" ref={this.okButton} variant="primary" onClick={this.handleOk} title="OK">
                OK
              </Button>
            </Row>
          </div>
        </Modal.Header>
      </Modal>
    );
  }
}

export default withRouter(ActionConfirmationModal);
