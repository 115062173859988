import API from "../API";
import { APIRoutes } from "../APIRoutes";

class ConflictsAPI {
  static async CreateConflict(body) {
    let url = "/Conflict/CreateConflict";
    let api = new API(url, APIRoutes.Conflicts);
    let result = await api.post(body);
    return result;
  }

  static async GetConflictsByProperty(propertyUniqueIdentifier) {
    let url = `/Conflict/GetConflictsByProperty/${propertyUniqueIdentifier}/0/0`;
    let api = new API(url, APIRoutes.Conflicts);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/Conflict/GetConflictsByProperty/${propertyUniqueIdentifier}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.Conflicts);
      result = await api.get();
    }
    return result;
  }

  static async GetConflictsForState(stateUniqueIdentifier) {
    let url = `/Conflict/GetConflictsForState/${stateUniqueIdentifier}/0/0`;
    let api = new API(url, APIRoutes.Conflicts);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/Conflict/GetConflictsForState/${stateUniqueIdentifier}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.Conflicts);
      result = await api.get();
    }

    return result;
  }

  static async GetConflict(conflictUniqueIdentifier) {
    let url = "/Conflict/GetConflict/" + conflictUniqueIdentifier;
    let api = new API(url, APIRoutes.Conflicts);
    let result = await api.get();
    return result;
  }

  static async GetAllThreatTypeOptions() {
    return await new API("/Conflict/GetAllThreatTypeOptions/", APIRoutes.Conflicts).get();
  }

  static async AddPropertiesToConflict(conflictUniqueIdentifier, body) {
    let url = "/Conflict/AddPropertiesToConflict/" + conflictUniqueIdentifier;
    let api = new API(url, APIRoutes.Conflicts);
    let result = await api.post(body);
    return result;
  }

  static async AddProtectedResourcesToConflict(conflictUniqueIdentifier, body) {
    let url = "/Conflict/AddProtectedResourcesToConflict/" + conflictUniqueIdentifier;
    let api = new API(url, APIRoutes.Conflicts);
    let result = await api.post(body);
    return result;
  }

  static async UpdateProtectedResourcesOnConflict(conflictUniqueIdentifier, body) {
    let url = "/Conflict/UpdateProtectedResourcesOnConflict/" + conflictUniqueIdentifier;
    let api = new API(url, APIRoutes.Conflicts);
    let result = await api.post(body);
    return result;
  }

  static async RemoveProtectedResourcesFromConflict(conflictUniqueIdentifier, body) {
    let url = "/Conflict/RemoveProtectedResourcesFromConflict/" + conflictUniqueIdentifier;
    let api = new API(url, APIRoutes.Conflicts);
    let result = await api.post(body);
    return result;
  }
}

export default ConflictsAPI;
