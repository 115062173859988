import React from "react";
import { Card, Form, Row, Col, Button, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import searchIcon from "../../assets/search.svg";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import AddContactModal from "./addContactModal";
import PropertyParcelAssociationModal from "./propertyParcelAssociationModal";
import CustomForm from "../common/form";
import BootstrapTable from "react-bootstrap-table-next";
import AddHistoricalLossModal from "./addHistoricalLossModal";
import { toast } from "react-toastify";
import UtilityFunctions from "../common/UtilityFunctions";
import ResourceSelectionModal from "./ResourceSelectionModal";
import SpeciesSelectionModal from "./SpeciesSelectionModal";
import MethodsSelectionModal from "./MethodsSelectionModal";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className={styles.noOptionsMessage}>
        <i className="fa fa-exclamation-circle " style={{ color: "gray", padding: "4px" }} aria-hidden="true"></i>
        Sorry, we couldn’t find a match for “{props.selectProps.inputValue}”. Please try another search
      </span>
    </components.NoOptionsMessage>
  );
};

class Step3 extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      contacts: this.props.step3Data.contacts,
      showContactPropertyAssociationModal: false,
      isPropertySelectionDisable: false,
      contactPropertyParcelModalData: {
        properties: this.props.step3Data.properties,
        currentContact: null,
        checkedProperties: [],
      },
      showAddContactModal: false,
      showAddContactModalForLandowner: false,
      contactTypes: this.props.step3Data.contactTypes,
      phoneNumberTypes: this.props.step3Data.phoneNumberTypes,
      usaCountryId: this.props.step3Data.usaCountryId,
      states: this.props.step3Data.states,
      countries: this.props.step3Data.countries,
      currentUsaState: this.props.step3Data.currentUsaState,
      agreementContactRoles: this.props.step3Data.agreementContactRoles,
      pageHeader: this.props.step3Data.pageHeader,
      agreementTypeEnumId: this.props.step3Data.agreementTypeEnumId,
      data: {
        resourceSearch: "",
        resourceQuantity: "",
        resourceUom: "",
        resourceUnitValue: "",
        resourceTotalValue: "",
        resourceYear: "",
        historicalResourceSearch: "",
        historicalResourceQuantity: "",
        historicalResourceUom: "",
        historicalResourceUnitValue: "",
        historicalResourceTotalValue: "",
        historicalResourceYear: "",
        historicalResourceStateValuationSetting: null,
        speciesSearch: "",
        methodsSearch: "",
        noProtectedResources: this.props.step3Data.noProtectedResources,
        noHistoricalResources: this.props.step3Data.noHistoricalResources,
        noLosses: this.props.step3Data.noLosses,
      },
      errors: [],
      resourceUOMs: this.props.step3Data.resourceUOMs,
      showResourcePropertyAssociationModal: false,
      showSpeciesPropertyAssociationModal: false,
      showMethodsPropertyAssociationModal: false,
      showAddHistoricalLossModal: false,
      showRecCemPropertyAssociationModal: false,
      addHistoricalLossData: "",
      selectedResources: this.props.step3Data.protectedResources,
      selectedHistoricalResources: this.props.step3Data.historicalResourcesInventory,
      selectedSpecies: this.props.step3Data.species,
      selectedMethods: this.props.step3Data.methods,
      selectedRecommendations: this.props.step3Data.recommendations,
      selectedCEMs: this.props.step3Data.cooperatorEmployedMethods,
      selectedRecTableData: [],
      selectedCemTableData: [],
      availableRecommendations: [],
      availableCEMs: [],
      selectedSpeciesTableData: [],
      selectedMethodsTableData: [],
      resourcePropertyParcelModalData: {
        currentResource: null,
        checkedProperties: [],
        currentAction: "add",
        resourceTableType: "",
      },
      speciesPropertyParcelModalData: {
        currentSpecies: null,
        checkedProperties: [],
        currentAction: "add",
      },
      methodsPropertyParcelModalData: {
        currentMethod: null,
        checkedProperties: [],
        currentAction: "add",
      },
      recCemPropertyParcelModalData: {
        currentRecCem: null,
        checkedProperties: [],
        currentAction: "add",
        entityType: "",
      },
      selectedSpeciesColumns: [
        { dataField: "speciesTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      selectedMethodsColumns: [
        { dataField: "methodsTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      selectedRecColumns: [
        { dataField: "tableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      selectedCemColumns: [
        { dataField: "tableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      propertySpecialInstructions: this.props.step3Data.propertySpecialInstructions,
      showSpecialInstructionsModal: false,
      instructionsPropertyParcelModalData: {
        properties: this.props.step3Data.properties,
        currentInstruction: null,
        checkedProperties: [],
      },
      currentPropertySpecialInstructions: "",
      isProtectedResourcesTableEditable: false,
      isHistoricalResourcesTableEditable: false,
      editedProtectedResource: { quantity: "", unitValue: "", totalValue: "" },
      editedHistoricalResource: { quantity: "", unitValue: "", totalValue: "", year: "" },
      showResourceSelectionModal: false,
      showSpeciesSelectionModal: false,
      showMethodsSelectionModal: false,
      isRecDropdownOpen: false,
      isCemDropdownOpen: false,
    };
  }

  async componentDidMount() {
    let data = this.state;

    data.resourceYear = new Date().getFullYear();
    data.selectedSpeciesColumns = this.getSpeciesDataColumns();
    data.selectedMethodsColumns = this.getMethodsDataColumns();
    data.selectedRecColumns = this.getRecCemDataColumns();
    data.selectedCemColumns = this.getRecCemDataColumns(false);

    const selectedSpeciesTableData = this.getSpeciesTableData();
    const selectedMethodsTableData = this.getMethodsTableData();
    const availableRecCems = await this.getAvailableRecCems();

    this.setState({
      data,
      selectedSpeciesTableData,
      selectedMethodsTableData,
      availableRecommendations: JSON.parse(JSON.stringify(availableRecCems)),
      availableCEMs: JSON.parse(JSON.stringify(availableRecCems)),
      selectedRecTableData: this.getRecCemTableData(),
      selectedCemTableData: this.getRecCemTableData(false),
      selectedRecommendations: [],
      selectedCEMs: [],
    });
    window.scrollTo(0, 0);
  }

  getRecCemTableData(isRecommendations = true) {
    const { selectedRecommendations, selectedCEMs } = this.state;
    const selectedRecCemData = isRecommendations ? selectedRecommendations : selectedCEMs;
    let selectedRecCemTableData = [];

    for (let recCem of selectedRecCemData) {
      recCem.checkedProperties.forEach((property) => {
        const newTableId = `${recCem.recCemId}|${property.propertyUniqueReference}`;
        if (!selectedRecCemTableData.find((r) => r.tableId === newTableId)) {
          selectedRecCemTableData.push({
            tableId: newTableId,
            recCemId: recCem.recCemId,
            recCemName: recCem.recCemName,
            property: {
              propertyUniqueIdentifier: property.propertyUniqueIdentifier,
              propertyName: property.propertyName,
            },
          });
        }
      });
    }

    return selectedRecCemTableData;
  }

  async getAvailableRecCems() {
    let recCemsData = [];
    if (this.state.currentUsaState?.stateGuid) {
      const recCemsResponse = await ReferenceFileAPI.GetAllRecCems(this.state.currentUsaState.stateGuid);
      if (recCemsResponse?.successful && recCemsResponse.data?.results?.length > 0) {
        recCemsData = recCemsResponse.data.results
          .filter((rc) => rc.isAllowedInState)
          ?.map((rc) => ({ value: rc.recCemUniqueIdentifier, label: rc.recCemName }));
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve Recommendations",
          apiName: "GetAllRecCems",
          responseUnsuccessful: recCemsResponse?.unsuccessful,
          responseMessage: recCemsResponse?.message,
        });
      }
    }
    return recCemsData;
  }

  closeContactPropertyAssociationModal = () => {
    let data = this.state;
    data.contactPropertyParcelModalData.currentContact = null;
    data.contactPropertyParcelModalData.checkedProperties = [];
    data.showContactPropertyAssociationModal = false;
    data.isPropertySelectionDisable = false;
    this.setState(data);
  };

  showContactPropertyAssociationModal = (index) => {
    let data = this.state;
    let contact = data.contacts[index];
    data.contactPropertyParcelModalData.currentContact = contact;
    data.contactPropertyParcelModalData.checkedProperties = contact.checkedProperties;
    data.isPropertySelectionDisable = contact.contactInheritedFrom === "Agreement";
    data.showContactPropertyAssociationModal = true;
    this.setState(data);
  };

  closeResourcePropertyAssociationModal = () => {
    let { resourcePropertyParcelModalData } = this.state;
    resourcePropertyParcelModalData.currentResource = null;
    resourcePropertyParcelModalData.checkedProperties = [];
    this.setState({ showResourcePropertyAssociationModal: false, resourcePropertyParcelModalData });
  };

  closeSpeciesPropertyAssociationModal = () => {
    let { speciesPropertyParcelModalData } = this.state;
    speciesPropertyParcelModalData.currentSpecies = null;
    speciesPropertyParcelModalData.checkedProperties = [];
    this.setState({ showSpeciesPropertyAssociationModal: false, speciesPropertyParcelModalData });
  };

  closeMethodsPropertyAssociationModal = () => {
    let { methodsPropertyParcelModalData } = this.state;
    methodsPropertyParcelModalData.currentMethod = null;
    methodsPropertyParcelModalData.checkedProperties = [];
    this.setState({ showMethodsPropertyAssociationModal: false, methodsPropertyParcelModalData });
  };

  closeRecCemPropertyAssociationModal = () => {
    let { recCemPropertyParcelModalData } = this.state;
    recCemPropertyParcelModalData.currentRecCem = null;
    recCemPropertyParcelModalData.checkedProperties = [];
    recCemPropertyParcelModalData.entityType = "";
    this.setState({ showRecCemPropertyAssociationModal: false, recCemPropertyParcelModalData });
  };

  closeHistoricalLossModal = () => {
    this.setState({ addHistoricalLossData: "", showAddHistoricalLossModal: false });
  };

  showResourcePropertyAssociationModal = () => {
    if (this.state.data.noProtectedResources) {
      toast.warning(
        'Resources can not be associated to the Agreement when option "No Protected Resources" is selected'
      );
      return;
    }

    const { resourceSearch, resourceQuantity, resourceUom, resourceUnitValue, resourceTotalValue, resourceYear } =
      this.state.data;
    const { properties } = this.props.step3Data;

    if (
      resourceSearch &&
      resourceQuantity &&
      resourceUom &&
      (resourceUnitValue || resourceTotalValue) &&
      resourceYear
    ) {
      const resourceData = {
        resourceUniqueIdentifier: resourceSearch.value,
        resourceName: resourceSearch.label,
        quantity:
          resourceUom.value === 6
            ? Math.round(Number.parseFloat(resourceQuantity))
            : Number.parseFloat(resourceQuantity),
        unitOfMeasureId: resourceUom.value,
        unitOfMeasureName: resourceUom.label,
        unitValue: Math.round(Number.parseFloat(resourceUnitValue || resourceTotalValue / resourceQuantity)),
        totalValue: Number.parseFloat(resourceTotalValue || resourceUnitValue * resourceQuantity),
        year: Number.parseInt(resourceYear),
        checkedProperties: [],
      };

      let { resourcePropertyParcelModalData } = this.state;
      resourcePropertyParcelModalData.currentResource = resourceData;
      resourcePropertyParcelModalData.checkedProperties = [];
      resourcePropertyParcelModalData.currentAction = "add";
      resourcePropertyParcelModalData.resourceTableType = "protectedResource";

      const showResourcePropertyAssociationModal = properties.length === 1 ? false : true;
      this.setState({ resourcePropertyParcelModalData, showResourcePropertyAssociationModal });

      if (properties.length === 1) {
        this.handleResourcePropertyParcelAssociation(properties);
      }
    } else {
      toast.info("Please fill out all the fields for the new resource to be linked to the Agreement.");
    }
  };

  editResourcePropertyAssociationModal = (row) => {
    const { resourcePropertyParcelModalData } = this.state;
    resourcePropertyParcelModalData.currentResource = row;
    resourcePropertyParcelModalData.checkedProperties = row.checkedProperties;
    resourcePropertyParcelModalData.currentAction = "edit";
    resourcePropertyParcelModalData.resourceTableType = "protectedResource";

    this.setState({ resourcePropertyParcelModalData, showResourcePropertyAssociationModal: true });
  };

  enableInlineResourceEdit = (resourceTable, row) => {
    if (resourceTable === "protectedResources") {
      const existingProtectedResource = {
        ...this.state.selectedResources.find((r) => r.resourceTableId === row.resourceTableId),
      };
      const existingHistoricalResource = {
        ...this.state.selectedHistoricalResources.find(
          (r) => r.resourceTableId.substring(0, r.resourceTableId.length - 5) === row.resourceTableId
        ),
      };
      const editedProtectedResource = existingProtectedResource
        ? existingProtectedResource
        : this.state.editedProtectedResource;
      const editedHistoricalResource = existingHistoricalResource
        ? existingHistoricalResource
        : this.state.editedHistoricalResource;
      this.setState({ isProtectedResourcesTableEditable: true, editedProtectedResource, editedHistoricalResource });
    } else {
      const existingResource = {
        ...this.state.selectedHistoricalResources.find((r) => r.resourceTableId === row.resourceTableId),
      };
      const editedHistoricalResource = existingResource ? existingResource : this.state.editedHistoricalResource;
      this.setState({ isHistoricalResourcesTableEditable: true, editedHistoricalResource });
    }
  };

  updateEditedProtectedResource = ({ currentTarget: input }, row) => {
    const editedProtectedResource = { ...this.state.editedProtectedResource };
    const editedHistoricalResource = { ...this.state.editedHistoricalResource };

    switch (input.name) {
      case "quantity":
        editedProtectedResource.quantity =
          row.unitOfMeasureId === 6
            ? Math.round(Number.parseFloat(input.value))
            : Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        editedProtectedResource.totalValue = editedProtectedResource.quantity * editedProtectedResource.unitValue;

        editedHistoricalResource.quantity =
          row.unitOfMeasureId === 6
            ? Math.round(Number.parseFloat(input.value))
            : Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        editedHistoricalResource.totalValue = editedHistoricalResource.quantity * editedHistoricalResource.unitValue;
        break;
      case "unitValue":
        editedProtectedResource.unitValue = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        editedProtectedResource.totalValue = editedProtectedResource.quantity * editedProtectedResource.unitValue;

        editedHistoricalResource.unitValue = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        editedHistoricalResource.totalValue = editedHistoricalResource.quantity * editedHistoricalResource.unitValue;
        break;
      case "totalValue":
        editedProtectedResource.totalValue = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        const resourceQuantity = editedProtectedResource.totalValue / editedProtectedResource.unitValue;
        editedProtectedResource.quantity =
          row.unitOfMeasureId === 6 ? Math.round(resourceQuantity) : Number.parseFloat(resourceQuantity.toFixed(2));

        editedHistoricalResource.totalValue = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        const resourceQuantityHis = editedHistoricalResource.totalValue / editedHistoricalResource.unitValue;
        editedHistoricalResource.quantity =
          row.unitOfMeasureId === 6
            ? Math.round(resourceQuantityHis)
            : Number.parseFloat(resourceQuantityHis.toFixed(2));
        break;
      default:
        break;
    }

    editedProtectedResource.isNewResource = true;
    editedHistoricalResource.isNewResource = true;
    this.setState({ editedProtectedResource, editedHistoricalResource });
  };

  updateEditedHistoricalResource = async ({ currentTarget: input }, row) => {
    const editedHistoricalResource = { ...this.state.editedHistoricalResource };

    switch (input.name) {
      case "year":
        editedHistoricalResource.year = Number.parseInt(input.value);
        editedHistoricalResource.unitValue = await this.getUpdatedHistoricalResourceValuation(
          row,
          Number.parseInt(input.value)
        );
        editedHistoricalResource.totalValue = editedHistoricalResource.quantity * editedHistoricalResource.unitValue;
        break;
      case "quantity":
        editedHistoricalResource.quantity =
          row.unitOfMeasureId === 6
            ? Math.round(Number.parseFloat(input.value))
            : Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        editedHistoricalResource.totalValue = editedHistoricalResource.quantity * editedHistoricalResource.unitValue;
        break;
      case "unitValue":
        editedHistoricalResource.unitValue = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        editedHistoricalResource.totalValue = editedHistoricalResource.quantity * editedHistoricalResource.unitValue;
        break;
      case "totalValue":
        editedHistoricalResource.totalValue = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
        const resourceQuantity = editedHistoricalResource.totalValue / editedHistoricalResource.unitValue;
        editedHistoricalResource.quantity =
          row.unitOfMeasureId === 6 ? Math.round(resourceQuantity) : Number.parseFloat(resourceQuantity.toFixed(2));
        break;
      default:
        break;
    }

    editedHistoricalResource.isNewResource = true;
    this.setState({ editedHistoricalResource });
  };

  editProtectedResourceEntry = () => {
    const { selectedResources, editedProtectedResource } = this.state;
    const rowIndex = selectedResources.findIndex((r) => r.resourceTableId === editedProtectedResource.resourceTableId);
    if (rowIndex !== -1) {
      selectedResources[rowIndex] = { ...editedProtectedResource };
    }

    this.props.passDataUp("protectedResources", selectedResources);
    this.editHistoricalResourceEntry();
    this.setState({ selectedResources, isProtectedResourcesTableEditable: false });
  };

  editHistoricalResourceEntry = () => {
    const { selectedHistoricalResources, editedHistoricalResource } = this.state;
    const rowIndex = selectedHistoricalResources.findIndex(
      (r) => r.resourceTableId === editedHistoricalResource.resourceTableId
    );
    if (rowIndex !== -1) {
      editedHistoricalResource.resourceTableId = editedHistoricalResource.resourceTableId = `${
        editedHistoricalResource.resourceUniqueIdentifier
      }|${
        editedHistoricalResource.checkedProperties[0]?.propertyUniqueReference ||
        editedHistoricalResource.checkedProperties[0]?.propertyUniqueIdentifier
      }|${editedHistoricalResource.unitValue}|${editedHistoricalResource.year}`;
      selectedHistoricalResources[rowIndex] = { ...editedHistoricalResource };
    }

    this.props.passDataUp("historicalResourcesInventory", selectedHistoricalResources);
    this.setState({ selectedHistoricalResources, isHistoricalResourcesTableEditable: false });
  };

  deleteProtectedResource = (row) => {
    const { selectedResources } = this.state;
    const associatedProperty = row.checkedProperties[0];
    const resourceTableId = `${row.resourceUniqueIdentifier}|${
      associatedProperty?.propertyUniqueIdentifier
        ? associatedProperty.propertyUniqueIdentifier
        : associatedProperty.propertyUniqueReference
    }`;

    const updatedSelectedResources = selectedResources.filter(
      (resource) => resource.resourceTableId.substring(0, resource.resourceTableId.lastIndexOf("|")) !== resourceTableId
    );
    this.props.passDataUp("protectedResources", updatedSelectedResources);

    this.setState({
      selectedResources: updatedSelectedResources,
    });
  };

  deleteHistoricalResource = (row) => {
    const { selectedHistoricalResources } = this.state;
    const resourceTableId = `${row.resourceUniqueIdentifier}|${
      row.checkedProperties[0].propertyUniqueIdentifier || row.checkedProperties[0]?.propertyUniqueReference
    }|${row.unitValue}|${row.year}`;
    const updatedHistoricalResources = selectedHistoricalResources.filter(
      (resource) => resource.resourceTableId !== resourceTableId
    );

    this.props.passDataUp("historicalResourcesInventory", updatedHistoricalResources);
    this.setState({ selectedHistoricalResources: updatedHistoricalResources });
  };

  removeRecEntry = (tableIdToRemove) => {
    const { availableRecommendations, selectedRecTableData } = this.state;
    let selectedRecommendationsToUpdate = JSON.parse(JSON.stringify(this.props.step3Data.recommendations));

    const recCemIdToRemove = tableIdToRemove.split("|")[0];

    const availableRec = availableRecommendations.find((r) => r.value === recCemIdToRemove);
    if (availableRec) {
      availableRec.isSelected = false;
    }

    const updatedRecTableData = selectedRecTableData.filter((r) => r.tableId !== tableIdToRemove);

    if (!updatedRecTableData.find((r) => r.recCemId === recCemIdToRemove)) {
      selectedRecommendationsToUpdate = selectedRecommendationsToUpdate.filter((r) => r.recCemId !== recCemIdToRemove);
    } else {
      let updatedCheckedProperties = [];
      updatedRecTableData
        .filter((r) => r.recCemId === recCemIdToRemove)
        .forEach((rec) => {
          updatedCheckedProperties.push(rec.property);
        });

      const recommendationToUpdate = selectedRecommendationsToUpdate.find((r) => r.recCemId === recCemIdToRemove);
      if (recommendationToUpdate) {
        recommendationToUpdate.checkedProperties = updatedCheckedProperties;
      }
    }

    this.props.passDataUp("recommendations", selectedRecommendationsToUpdate);
    this.setState({
      selectedRecTableData: updatedRecTableData,
      availableRecommendations,
    });
  };

  removeCemEntry = (tableIdToRemove) => {
    const { availableCEMs, selectedCemTableData } = this.state;
    let selectedCEMsToUpdate = JSON.parse(JSON.stringify(this.props.step3Data.cooperatorEmployedMethods));

    const recCemIdToRemove = tableIdToRemove.split("|")[0];
    const availableCEM = availableCEMs.find((r) => r.value === recCemIdToRemove);
    if (availableCEM) {
      availableCEM.isSelected = false;
    }

    const updatedCEMTableData = selectedCemTableData.filter((r) => r.tableId !== tableIdToRemove);

    if (!updatedCEMTableData.find((r) => r.recCemId === recCemIdToRemove)) {
      selectedCEMsToUpdate = selectedCEMsToUpdate.filter((r) => r.recCemId !== recCemIdToRemove);
    } else {
      let updatedCheckedProperties = [];
      updatedCEMTableData
        .filter((r) => r.recCemId === recCemIdToRemove)
        .forEach((rec) => {
          updatedCheckedProperties.push(rec.property);
        });

      const cemToUpdate = selectedCEMsToUpdate.find((r) => r.recCemId === recCemIdToRemove);
      if (cemToUpdate) {
        cemToUpdate.checkedProperties = updatedCheckedProperties;
      }
    }

    this.props.passDataUp("cooperatorEmployedMethods", selectedCEMsToUpdate);
    this.setState({
      selectedCemTableData: updatedCEMTableData,
      availableCEMs,
    });
  };

  deleteSpecies = (row) => {
    let { selectedSpecies, selectedSpeciesTableData } = this.state;
    const speciesTableId = row.speciesTableId;
    const speciesUniqueId = speciesTableId.split("|")[0];
    const currentPropertyId = speciesTableId.split("|")[1];

    const updatedCheckedProperties = row.checkedProperties.filter(
      (p) =>
        (p.propertyUniqueReference && p.propertyUniqueReference !== currentPropertyId) ||
        (p.propertyUniqueIdentifier && p.propertyUniqueIdentifier !== currentPropertyId)
    );
    const newTableIds = updatedCheckedProperties.map(
      (property) =>
        `${speciesUniqueId}|${
          property.propertyUniqueIdentifier ? property.propertyUniqueIdentifier : property.propertyUniqueReference
        }`
    );

    selectedSpeciesTableData = JSON.parse(
      JSON.stringify(selectedSpeciesTableData.filter((species) => species.speciesUniqueIdentifier !== speciesUniqueId))
    );

    newTableIds.forEach((tableId) => {
      const currentPropertyId = tableId.split("|")[1];
      const currentPropertyArray = [
        ...updatedCheckedProperties.filter(
          (property) =>
            (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === currentPropertyId) ||
            (property.propertyUniqueReference && property.propertyUniqueReference === currentPropertyId)
        ),
        ...updatedCheckedProperties.filter(
          (property) =>
            (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier !== currentPropertyId) ||
            (property.propertyUniqueReference && property.propertyUniqueReference !== currentPropertyId)
        ),
      ];
      selectedSpeciesTableData.push({
        speciesUniqueIdentifier: speciesUniqueId,
        speciesName: row.speciesName,
        checkedProperties: currentPropertyArray,
        speciesTableId: tableId,
      });
    });

    const currentSpecies = selectedSpecies.find((s) => s.speciesUniqueIdentifier === speciesUniqueId);
    currentSpecies.isNewSpecies = true;
    if (updatedCheckedProperties?.length > 0) {
      currentSpecies.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    } else {
      selectedSpecies = selectedSpecies.filter((s) => s.speciesUniqueIdentifier !== speciesUniqueId);
    }

    this.props.passDataUp("species", selectedSpecies);
    this.setState({ selectedSpecies, selectedSpeciesTableData });
  };

  deleteMethod = (row) => {
    let { selectedMethods, selectedMethodsTableData } = this.state;
    const methodTableId = row.methodsTableId;
    const methodUniqueId = methodTableId.split("|")[0];
    const currentPropertyId = methodTableId.split("|")[1];

    const updatedCheckedProperties = row.checkedProperties.filter(
      (p) =>
        (p.propertyUniqueReference && p.propertyUniqueReference !== currentPropertyId) ||
        (p.propertyUniqueIdentifier && p.propertyUniqueIdentifier !== currentPropertyId)
    );
    const newTableIds = updatedCheckedProperties.map(
      (property) =>
        `${methodUniqueId}|${
          property.propertyUniqueIdentifier ? property.propertyUniqueIdentifier : property.propertyUniqueReference
        }`
    );

    selectedMethodsTableData = JSON.parse(
      JSON.stringify(selectedMethodsTableData.filter((method) => method.methodUniqueIdentifier !== methodUniqueId))
    );

    newTableIds.forEach((tableId) => {
      const currentPropertyId = tableId.split("|")[1];
      const currentPropertyArray = [
        ...updatedCheckedProperties.filter(
          (property) =>
            (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === currentPropertyId) ||
            (property.propertyUniqueReference && property.propertyUniqueReference === currentPropertyId)
        ),
        ...updatedCheckedProperties.filter(
          (property) =>
            (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier !== currentPropertyId) ||
            (property.propertyUniqueReference && property.propertyUniqueReference !== currentPropertyId)
        ),
      ];
      selectedMethodsTableData.push({
        methodUniqueIdentifier: methodUniqueId,
        methodName: row.methodName,
        checkedProperties: currentPropertyArray,
        methodsTableId: tableId,
      });
    });

    const currentMethod = selectedMethods.find((method) => method.methodUniqueIdentifier === methodUniqueId);
    currentMethod.isNewMethod = true;
    if (updatedCheckedProperties?.length > 0) {
      currentMethod.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    } else {
      selectedMethods = selectedMethods.filter((method) => method.methodUniqueIdentifier !== methodUniqueId);
    }

    this.props.passDataUp("methods", selectedMethods);
    this.setState({ selectedMethods, selectedMethodsTableData });
  };

  deleteSpecialInstruction = (index) => {
    const { propertySpecialInstructions } = this.state;
    propertySpecialInstructions.splice(index, 1);

    this.props.passDataUp("propertySpecialInstructions", propertySpecialInstructions);
    this.setState({ propertySpecialInstructions });
  };

  deleteContact = (index) => {
    const { contacts } = this.state;
    contacts.splice(index, 1);

    this.props.passDataUp("additionalContacts", contacts);
    this.setState({ contacts });
  };

  handleHistoricalResourcesEditAssociations = (row) => {
    let { resourcePropertyParcelModalData } = this.state;
    resourcePropertyParcelModalData.currentResource = row;
    resourcePropertyParcelModalData.checkedProperties = row.checkedProperties;
    resourcePropertyParcelModalData.currentAction = "edit";
    resourcePropertyParcelModalData.resourceTableType = "historicalResourceInventory";

    this.setState({ resourcePropertyParcelModalData, showResourcePropertyAssociationModal: true });
  };

  handleHistoricalResourcesAddLoss = (row) => {
    if (this.state.data.noLosses) {
      toast.warning('"No Losses" option is selected. Please unselect this option before adding any historical losses.');
      return;
    }

    this.setState({ addHistoricalLossData: row, showAddHistoricalLossModal: true });
  };

  editSpeciesPropertyAssociationModal = (row) => {
    const { speciesPropertyParcelModalData } = this.state;
    speciesPropertyParcelModalData.currentSpecies = row;
    speciesPropertyParcelModalData.checkedProperties = row.checkedProperties;
    speciesPropertyParcelModalData.currentAction = "edit";

    this.setState({ speciesPropertyParcelModalData, showSpeciesPropertyAssociationModal: true });
  };

  editMethodPropertyAssociationModal = (row) => {
    const { methodsPropertyParcelModalData } = this.state;
    methodsPropertyParcelModalData.currentMethod = row;
    methodsPropertyParcelModalData.checkedProperties = row.checkedProperties;
    methodsPropertyParcelModalData.currentAction = "edit";

    this.setState({ methodsPropertyParcelModalData, showMethodsPropertyAssociationModal: true });
  };

  handleResourcePropertyParcelAssociation = async (updatedCheckedProperties) => {
    let { resourcePropertyParcelModalData } = this.state;

    if (resourcePropertyParcelModalData.resourceTableType === "protectedResource") {
      await this.handleProtectedResourcePropertyParcelAssociation(
        updatedCheckedProperties,
        resourcePropertyParcelModalData
      );
    } else {
      this.handleHistoricalResourcePropertyAssociation(updatedCheckedProperties, resourcePropertyParcelModalData);
    }
  };

  async handleProtectedResourcePropertyParcelAssociation(updatedCheckedProperties, resourcePropertyParcelModalData) {
    let { selectedResources, selectedHistoricalResources, data, currentUsaState } = this.state;
    let currentResource = JSON.parse(JSON.stringify(resourcePropertyParcelModalData.currentResource));
    currentResource.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    currentResource.isNewResource = true;
    const propertyId = updatedCheckedProperties[0]?.propertyUniqueIdentifier
      ? updatedCheckedProperties[0].propertyUniqueIdentifier
      : updatedCheckedProperties[0].propertyUniqueReference;
    const newTableId = `${currentResource.resourceUniqueIdentifier}|${propertyId}|${currentResource.unitValue}`;

    if (resourcePropertyParcelModalData.currentAction === "add") {
      if (selectedResources.find((resource) => resource.resourceTableId === newTableId)) {
        toast.info("The same valuation already exists for this resource with the specified property.");
        return;
      }

      currentResource.resourceTableId = newTableId;
      selectedResources.push(currentResource);
      let historicalResource = { ...currentResource };
      historicalResource.year = historicalResource.year - 1;
      historicalResource.totalValue = currentResource.totalValue;
      historicalResource.resourceTableId = `${historicalResource.resourceUniqueIdentifier}|${propertyId}|${historicalResource.unitValue}|${historicalResource.year}`;
      if (currentUsaState && currentUsaState.stateGuid) {
        const resourceServerResponse = await ReferenceFileAPI.GetResourceForState(
          historicalResource.resourceUniqueIdentifier,
          currentUsaState.stateGuid
        );
        if (resourceServerResponse?.successful) {
          const resourceData = resourceServerResponse.data;
          if (resourceData.stateResourceValuations && resourceData.stateResourceValuations.length > 0) {
            const resourceValuation = resourceData.stateResourceValuations
              .filter((resource) => resource.valuationYear <= historicalResource.year)
              .sort((a, b) => (a.valuationYear > b.valuationYear ? -1 : 1))[0];
            historicalResource.unitValue = resourceValuation ? resourceValuation.valuePerUnitOfMeasure : "";
            historicalResource.totalValue = historicalResource.unitValue * historicalResource.quantity;
            historicalResource.resourceTableId = `${historicalResource.resourceUniqueIdentifier}|${propertyId}|${currentResource.unitValue}|${currentResource.year}`;
          }
        }
      }

      if (
        !selectedHistoricalResources.find((resource) => resource.resourceTableId === historicalResource.resourceTableId)
      )
        selectedHistoricalResources.push(historicalResource);
    } else {
      if (
        currentResource.resourceTableId !== newTableId &&
        selectedResources.find((resource) => resource.resourceTableId === newTableId)
      ) {
        toast.info("The same valuation already exists for this resource with the specified property.");
        return;
      }

      let resourceToEdit = selectedResources.find(
        (resource) => resource.resourceTableId === currentResource.resourceTableId
      );
      if (resourceToEdit) {
        const index = selectedResources.indexOf(resourceToEdit);
        currentResource.resourceTableId = newTableId;
        selectedResources[index] = currentResource;
      }
    }

    this.props.passDataUp("protectedResources", selectedResources);
    this.props.passDataUp("historicalResourcesInventory", selectedHistoricalResources);
    this.setState({
      selectedResources,
      selectedHistoricalResources,
      data: this.clearDataObject(data, "protectedResources"),
    });
  }

  handleHistoricalResourcePropertyAssociation(updatedCheckedProperties, resourcePropertyParcelModalData) {
    let { selectedHistoricalResources, data } = this.state;
    let currentResource = JSON.parse(JSON.stringify(resourcePropertyParcelModalData.currentResource));
    currentResource.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    currentResource.isNewResource = true;
    const propertyId = updatedCheckedProperties[0]?.propertyUniqueIdentifier
      ? updatedCheckedProperties[0].propertyUniqueIdentifier
      : updatedCheckedProperties[0].propertyUniqueReference;
    const newTableId = `${currentResource.resourceUniqueIdentifier}|${propertyId}|${currentResource?.unitValue}|${currentResource.year}`;

    if (resourcePropertyParcelModalData.currentAction === "add") {
      if (selectedHistoricalResources.find((resource) => resource.resourceTableId === newTableId)) {
        toast.info(
          "The same valuation already exists for this resource in the specified property and the selected year."
        );
        return;
      }

      currentResource.resourceTableId = newTableId;
      selectedHistoricalResources.push(currentResource);
    } else {
      if (
        currentResource.resourceTableId !== newTableId &&
        selectedHistoricalResources.find((resource) => resource.resourceTableId === newTableId)
      ) {
        toast.info(
          "The same valuation already exists for this resource in the specified property and the selected year."
        );
        return;
      }

      let resourceToEdit = selectedHistoricalResources.find(
        (resource) => resource.resourceTableId === currentResource.resourceTableId
      );
      if (resourceToEdit) {
        const index = selectedHistoricalResources.indexOf(resourceToEdit);
        currentResource.resourceTableId = newTableId;
        selectedHistoricalResources[index] = currentResource;
      }
    }

    this.props.passDataUp("historicalResourcesInventory", selectedHistoricalResources);
    this.setState({ selectedHistoricalResources, data: this.clearDataObject(data, "historicalResourcesInventory") });
  }

  handleSpeciesPropertyParcelAssociation = (updatedCheckedProperties) => {
    let { speciesPropertyParcelModalData, selectedSpecies, data, selectedSpeciesTableData } = this.state;
    let currentSpecies = JSON.parse(JSON.stringify(speciesPropertyParcelModalData.currentSpecies));

    const newTableIds = updatedCheckedProperties.map(
      (property) =>
        `${currentSpecies.speciesUniqueIdentifier}|${
          property.propertyUniqueIdentifier ? property.propertyUniqueIdentifier : property.propertyUniqueReference
        }`
    );

    if (speciesPropertyParcelModalData.currentAction === "add") {
      if (
        selectedSpecies.find((species) => species.speciesUniqueIdentifier === currentSpecies.speciesUniqueIdentifier)
      ) {
        toast.info(
          "The selected species was already added to the agreement. You can edit its Property Associations on the specific record."
        );
        return;
      }
    } else {
      selectedSpeciesTableData = JSON.parse(
        JSON.stringify(
          selectedSpeciesTableData.filter(
            (species) => species.speciesUniqueIdentifier !== currentSpecies.speciesUniqueIdentifier
          )
        )
      );
      selectedSpecies = JSON.parse(
        JSON.stringify(
          selectedSpecies.filter(
            (species) => species.speciesUniqueIdentifier !== currentSpecies.speciesUniqueIdentifier
          )
        )
      );
    }
    newTableIds.forEach((tableId) => {
      const currentPropertyId = tableId.split("|")[1];
      const currentPropertyArray = [
        ...updatedCheckedProperties.filter(
          (property) =>
            (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === currentPropertyId) ||
            (property.propertyUniqueReference && property.propertyUniqueReference === currentPropertyId)
        ),
        ...updatedCheckedProperties.filter(
          (property) =>
            (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier !== currentPropertyId) ||
            (property.propertyUniqueReference && property.propertyUniqueReference !== currentPropertyId)
        ),
      ];
      selectedSpeciesTableData.push({
        speciesUniqueIdentifier: currentSpecies.speciesUniqueIdentifier,
        speciesName: currentSpecies.speciesName,
        checkedProperties: currentPropertyArray,
        speciesTableId: tableId,
      });
    });

    currentSpecies.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    currentSpecies.isNewSpecies = true;
    selectedSpecies.push(currentSpecies);
    data.speciesSearch = "";

    this.props.passDataUp("species", selectedSpecies);
    this.setState({ selectedSpecies, data, selectedSpeciesTableData });
  };

  handleMethodsPropertyParcelAssociation = (updatedCheckedProperties) => {
    let { methodsPropertyParcelModalData, selectedMethods, data, selectedMethodsTableData } = this.state;
    let currentMethod = JSON.parse(JSON.stringify(methodsPropertyParcelModalData.currentMethod));
    const newTableIds = updatedCheckedProperties.map(
      (property) =>
        `${currentMethod.methodUniqueIdentifier}|${
          property.propertyUniqueIdentifier ? property.propertyUniqueIdentifier : property.propertyUniqueReference
        }`
    );

    if (methodsPropertyParcelModalData.currentAction === "add") {
      if (selectedMethods.find((method) => method.methodUniqueIdentifier === currentMethod.methodUniqueIdentifier)) {
        toast.info(
          "The selected method was already added to the agreement. You can edit its Property Associations on the specific record."
        );
        return;
      }
    } else {
      selectedMethodsTableData = JSON.parse(
        JSON.stringify(
          selectedMethodsTableData.filter(
            (method) => method.methodUniqueIdentifier !== currentMethod.methodUniqueIdentifier
          )
        )
      );
      selectedMethods = JSON.parse(
        JSON.stringify(
          selectedMethods.filter((method) => method.methodUniqueIdentifier !== currentMethod.methodUniqueIdentifier)
        )
      );
    }
    newTableIds.forEach((tableId) => {
      const currentPropertyId = tableId.split("|")[1];
      const currentPropertyArray = [
        ...updatedCheckedProperties.filter(
          (property) =>
            (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === currentPropertyId) ||
            (property.propertyUniqueReference && property.propertyUniqueReference === currentPropertyId)
        ),
        ...updatedCheckedProperties.filter(
          (property) =>
            (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier !== currentPropertyId) ||
            (property.propertyUniqueReference && property.propertyUniqueReference !== currentPropertyId)
        ),
      ];
      selectedMethodsTableData.push({
        methodUniqueIdentifier: currentMethod.methodUniqueIdentifier,
        methodName: currentMethod.methodName,
        checkedProperties: currentPropertyArray,
        methodsTableId: tableId,
      });
    });

    currentMethod.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    currentMethod.isNewMethod = true;
    selectedMethods.push(currentMethod);
    data.methodsSearch = "";

    this.props.passDataUp("methods", selectedMethods);
    this.setState({ selectedMethods, data, selectedMethodsTableData });
  };

  handleRecPropertyParcelAssociation = (updatedCheckedProperties) => {
    let { selectedRecTableData, selectedRecommendations } = this.state;
    let areRecordsDuplicated = false;
    let selectedRecommendationsToUpdate = JSON.parse(JSON.stringify(this.props.step3Data.recommendations));

    for (let recommendation of selectedRecommendations) {
      updatedCheckedProperties.forEach((property) => {
        const propertyId = property.propertyUniqueIdentifier
          ? property.propertyUniqueIdentifier
          : property.propertyUniqueReference;
        const newTableId = `${recommendation.value}|${propertyId}`;
        if (selectedRecTableData.find((r) => r.tableId === newTableId)) {
          areRecordsDuplicated = true;
        } else {
          selectedRecTableData.push({
            tableId: newTableId,
            recCemId: recommendation.value,
            recCemName: recommendation.label,
            property: {
              propertyUniqueIdentifier: propertyId,
              propertyName: property.propertyName,
            },
          });
        }
      });

      const recommendationToUpdate = selectedRecommendationsToUpdate.find((r) => r.recCemId === recommendation.value);
      if (!recommendationToUpdate) {
        selectedRecommendationsToUpdate.push({
          recCemId: recommendation.value,
          recCemName: recommendation.label,
          checkedProperties: updatedCheckedProperties,
          isNewRecCem: true,
        });
      } else {
        recommendationToUpdate.checkedProperties = updatedCheckedProperties;
      }
    }

    if (areRecordsDuplicated) {
      toast.info(
        "One or more of these recommendations were previously added to the agreement. You can remove a the specific record and define new Property Associations."
      );
    }

    this.props.passDataUp("recommendations", selectedRecommendationsToUpdate);
    this.setState({
      selectedRecTableData,
      selectedRecommendations: [],
      recCemPropertyParcelModalData: {
        currentRecCem: null,
        checkedProperties: [],
        currentAction: "add",
        entityType: "",
      },
    });
  };

  handleCemPropertyParcelAssociation = (updatedCheckedProperties) => {
    let { selectedCemTableData, selectedCEMs } = this.state;
    let areRecordsDuplicated = false;
    let selectedCEMsToUpdate = JSON.parse(JSON.stringify(this.props.step3Data.cooperatorEmployedMethods));

    for (let employedMethod of selectedCEMs) {
      updatedCheckedProperties.forEach((property) => {
        const propertyId = property.propertyUniqueIdentifier
          ? property.propertyUniqueIdentifier
          : property.propertyUniqueReference;
        const newTableId = `${employedMethod.value}|${propertyId}`;
        if (selectedCemTableData.find((r) => r.tableId === newTableId)) {
          areRecordsDuplicated = true;
        } else {
          selectedCemTableData.push({
            tableId: newTableId,
            recCemId: employedMethod.value,
            recCemName: employedMethod.label,
            property: {
              propertyUniqueIdentifier: propertyId,
              propertyName: property.propertyName,
            },
          });
        }
      });

      const cemToUpdate = selectedCEMsToUpdate.find((r) => r.recCemId === employedMethod.value);
      if (!cemToUpdate) {
        selectedCEMsToUpdate.push({
          recCemId: employedMethod.value,
          recCemName: employedMethod.label,
          checkedProperties: updatedCheckedProperties,
          isNewRecCem: true,
        });
      } else {
        cemToUpdate.checkedProperties = updatedCheckedProperties;
      }
    }

    if (areRecordsDuplicated) {
      toast.info(
        "One or more of these Cooperator Employed Methods were previously added to the agreement. You can remove a the specific record and define new Property Associations."
      );
    }

    this.props.passDataUp("cooperatorEmployedMethods", selectedCEMsToUpdate);

    this.setState({
      selectedCemTableData,
      selectedCEMs: [],
      recCemPropertyParcelModalData: {
        currentRecCem: null,
        checkedProperties: [],
        currentAction: "add",
        entityType: "",
      },
    });
  };

  handleNewHstoricalLoss = (historicalLossData) => {
    let { selectedHistoricalResources, addHistoricalLossData } = this.state;
    let resourceToEdit = selectedHistoricalResources.find(
      (resource) => resource.resourceTableId === addHistoricalLossData.resourceTableId
    );

    if (resourceToEdit) {
      const newHistoricalResource = JSON.parse(JSON.stringify(resourceToEdit));
      newHistoricalResource.historicalLosses = historicalLossData;

      const index = selectedHistoricalResources.indexOf(resourceToEdit);
      selectedHistoricalResources[index] = newHistoricalResource;

      this.props.passDataUp("historicalResourcesInventory", selectedHistoricalResources);
      this.setState({ selectedHistoricalResources });
    }
  };

  showSpeciesPropertyAssociationModal = () => {
    const { data } = this.state;
    const { properties } = this.props.step3Data;

    if (data.speciesSearch) {
      const speciesData = {
        speciesUniqueIdentifier: data.speciesSearch.value,
        speciesName: data.speciesSearch.label,
        checkedProperties: [],
      };

      let { speciesPropertyParcelModalData } = this.state;
      speciesPropertyParcelModalData.currentSpecies = speciesData;
      speciesPropertyParcelModalData.checkedProperties = [];
      speciesPropertyParcelModalData.currentAction = "add";

      const showSpeciesPropertyAssociationModal = properties.length === 1 ? false : true;
      this.setState({ speciesPropertyParcelModalData, showSpeciesPropertyAssociationModal });

      if (properties.length === 1) this.handleSpeciesPropertyParcelAssociation(properties);
    } else toast.warning("Please select a new Damage Agent to be linked to the Agreement.");
  };

  showMethodsPropertyAssociationModal = () => {
    const { data } = this.state;
    const { properties } = this.props.step3Data;

    if (data.methodsSearch) {
      const methodData = {
        methodUniqueIdentifier: data.methodsSearch.value,
        methodName: data.methodsSearch.label,
        checkedProperties: [],
      };

      let { methodsPropertyParcelModalData } = this.state;
      methodsPropertyParcelModalData.currentMethod = methodData;
      methodsPropertyParcelModalData.checkedProperties = [];
      methodsPropertyParcelModalData.currentAction = "add";

      const showMethodsPropertyAssociationModal = properties.length === 1 ? false : true;
      this.setState({ methodsPropertyParcelModalData, showMethodsPropertyAssociationModal });

      if (properties.length === 1) this.handleMethodsPropertyParcelAssociation(properties);
    } else toast.warning("Please select a new method to be linked to the Agreement.");
  };

  showHistoricalResourcePropertyAssociationModal = () => {
    if (this.state.data.noHistoricalResources) {
      toast.warning(
        'Historical Resources can not be associated to the Agreement when option "No Historical Resources" is selected'
      );
      return;
    }

    const {
      historicalResourceSearch,
      historicalResourceQuantity,
      historicalResourceUom,
      historicalResourceUnitValue,
      historicalResourceTotalValue,
      historicalResourceYear,
      historicalResourceStateValuationSetting,
    } = this.state.data;
    const { properties } = this.props.step3Data;

    if (
      historicalResourceSearch &&
      historicalResourceQuantity &&
      historicalResourceUom &&
      historicalResourceYear &&
      (historicalResourceUnitValue || historicalResourceTotalValue || historicalResourceStateValuationSetting?.id !== 3)
    ) {
      let unitValueToUse = null;
      let totalValueToUse = null;
      if (historicalResourceUnitValue) {
        unitValueToUse = Math.round(
          Number.parseFloat(historicalResourceUnitValue || historicalResourceTotalValue / historicalResourceQuantity)
        );
      }
      if (historicalResourceTotalValue) {
        totalValueToUse = Number.parseFloat(
          historicalResourceTotalValue || historicalResourceUnitValue * historicalResourceQuantity
        );
      }

      const historicalResourceData = {
        resourceUniqueIdentifier: historicalResourceSearch.value,
        resourceName: historicalResourceSearch.label,
        quantity:
          historicalResourceUom.value === 6
            ? Math.round(Number.parseFloat(historicalResourceQuantity))
            : Number.parseFloat(historicalResourceQuantity),
        unitOfMeasureId: historicalResourceUom.value,
        unitOfMeasureName: historicalResourceUom.label,
        unitValue: unitValueToUse,
        totalValue: totalValueToUse,
        year: Number.parseInt(historicalResourceYear),
        checkedProperties: [],
        stateValuationSetting: historicalResourceStateValuationSetting,
      };

      let { resourcePropertyParcelModalData } = this.state;
      resourcePropertyParcelModalData.currentResource = historicalResourceData;
      resourcePropertyParcelModalData.checkedProperties = [];
      resourcePropertyParcelModalData.currentAction = "add";
      resourcePropertyParcelModalData.resourceTableType = "historicalResourceInventory";

      const showResourcePropertyAssociationModal = properties.length === 1 ? false : true;
      this.setState({ resourcePropertyParcelModalData, showResourcePropertyAssociationModal });

      if (properties.length === 1) {
        this.handleResourcePropertyParcelAssociation(properties);
      }
    } else {
      toast.warning("Please fill out all the fields for the new resource to be linked to the Agreement.");
    }
  };

  showRecommendationsPropertyAssociationModal = () => {
    const { selectedRecommendations } = this.state;
    const { properties } = this.props.step3Data;

    if (selectedRecommendations?.length > 0) {
      this.setRecCemPropertyModalData(selectedRecommendations);
      this.setState({ showRecCemPropertyAssociationModal: properties.length === 1 ? false : true });

      if (properties.length === 1) this.handleRecPropertyParcelAssociation(properties);
    } else toast.warning("Please select at least one recommendation to be linked to the Agreement.");
  };

  showCemPropertyAssociationModal = () => {
    const { selectedCEMs } = this.state;
    const { properties } = this.props.step3Data;
    if (selectedCEMs?.length > 0) {
      this.setRecCemPropertyModalData(selectedCEMs, false);
      this.setState({ showRecCemPropertyAssociationModal: properties.length === 1 ? false : true });

      if (properties.length === 1) this.handleCemPropertyParcelAssociation(properties);
    } else toast.warning("Please select at least one Cooperator Employed Method to be linked to the Agreement.");
  };

  setRecCemPropertyModalData(selectedRecCEMs, isRecommendations = true) {
    const { recCemPropertyParcelModalData } = this.state;
    recCemPropertyParcelModalData.currentRecCem = [...selectedRecCEMs];
    recCemPropertyParcelModalData.checkedProperties = [];
    recCemPropertyParcelModalData.currentAction = "add";
    recCemPropertyParcelModalData.entityType = isRecommendations ? "recommendations" : "cooperatorEmployedMethods";
    this.setState({ recCemPropertyParcelModalData });
  }

  getSpeciesTableData() {
    const selectedSpeciesTableData = [];
    const selectedSpecies = JSON.parse(JSON.stringify(this.props.step3Data.species));
    selectedSpecies.forEach((species) => {
      this.updateSpeciesTableData(species, selectedSpeciesTableData);
    });
    return selectedSpeciesTableData;
  }

  getMethodsTableData() {
    const selectedMethodsTableData = [];
    const selectedMethods = JSON.parse(JSON.stringify(this.props.step3Data.methods));
    selectedMethods.forEach((method) => {
      this.updateMethodsTableData(method, selectedMethodsTableData);
    });
    return selectedMethodsTableData;
  }

  updateSpeciesTableData(species, selectedSpeciesTableData) {
    species.checkedProperties.forEach((property) => {
      const currentPropertyArray = [
        ...species.checkedProperties.filter(
          (checkedProperty) =>
            (checkedProperty.propertyUniqueIdentifier &&
              checkedProperty.propertyUniqueIdentifier === property.propertyUniqueIdentifier) ||
            (checkedProperty.propertyUniqueReference &&
              checkedProperty.propertyUniqueReference === property.propertyUniqueReference)
        ),
        ...species.checkedProperties.filter(
          (checkedProperty) =>
            (checkedProperty.propertyUniqueIdentifier &&
              checkedProperty.propertyUniqueIdentifier !== property.propertyUniqueIdentifier) ||
            (checkedProperty.propertyUniqueReference &&
              checkedProperty.propertyUniqueReference !== property.propertyUniqueReference)
        ),
      ];
      const tableId = property.propertyUniqueIdentifier
        ? `${species.speciesUniqueIdentifier}|${property.propertyUniqueIdentifier}`
        : `${species.speciesUniqueIdentifier}|${property.propertyUniqueReference}`;

      selectedSpeciesTableData.push({
        speciesUniqueIdentifier: species.speciesUniqueIdentifier,
        speciesName: species.name,
        checkedProperties: currentPropertyArray,
        speciesTableId: tableId,
      });
    });
  }

  updateMethodsTableData(method, selectedMethodsTableData) {
    method.checkedProperties.map((property) => {
      const currentPropertyArray = [
        ...method.checkedProperties.filter(
          (checkedProperty) =>
            (checkedProperty.propertyUniqueIdentifier &&
              checkedProperty.propertyUniqueIdentifier === property.propertyUniqueIdentifier) ||
            (checkedProperty.propertyUniqueReference &&
              checkedProperty.propertyUniqueReference === property.propertyUniqueReference)
        ),
        ...method.checkedProperties.filter(
          (checkedProperty) =>
            (checkedProperty.propertyUniqueIdentifier &&
              checkedProperty.propertyUniqueIdentifier !== property.propertyUniqueIdentifier) ||
            (checkedProperty.propertyUniqueReference &&
              checkedProperty.propertyUniqueReference !== property.propertyUniqueReference)
        ),
      ];
      const tableId = property.propertyUniqueIdentifier
        ? `${method.methodUniqueIdentifier}|${property.propertyUniqueIdentifier}`
        : `${method.methodUniqueIdentifier}|${property.propertyUniqueReference}`;

      selectedMethodsTableData.push({
        methodUniqueIdentifier: method.methodUniqueIdentifier,
        methodName: method.methodName,
        checkedProperties: currentPropertyArray,
        methodsTableId: tableId,
      });
    });
  }

  clearDataObject(data, entityType) {
    if (entityType === "protectedResources") {
      data.resourceSearch = "";
      data.resourceQuantity = "";
      data.resourceUom = "";
      data.resourceUnitValue = "";
      data.resourceTotalValue = "";
      data.resourceYear = new Date().getFullYear();
    } else if (entityType === "historicalResourcesInventory") {
      data.historicalResourceSearch = "";
      data.historicalResourceQuantity = "";
      data.historicalResourceUom = "";
      data.historicalResourceUnitValue = "";
      data.historicalResourceTotalValue = "";
      data.historicalResourceYear = "";
      data.historicalResourceStateValuationSetting = null;
    }
    return data;
  }

  handleContactPropertyParcelAssociation = (updatedCheckedProperties) => {
    let data = this.state;
    const currentContact = data.contactPropertyParcelModalData.currentContact;
    currentContact.checkedProperties = updatedCheckedProperties;
    currentContact.isNewContact = true;
    let changedContactIndex = data.contacts.findIndex((contact) => contact.contactGuid === currentContact.contactGuid);
    data.contacts[changedContactIndex] = currentContact;
    this.props.passDataUp("additionalContacts", data.contacts);
    this.setState(data);
  };

  handleExtraContactAssociation = async (contactData) => {
    let data = this.state;
    let contact = await AgreementEntitiesAPI.GetContact(contactData.contactGuid);
    let formatContact = contact.data;
    formatContact.contactRoleEnumId = contactData.contactRoleEnumId;
    formatContact.contactGuid = contactData.contactGuid;
    formatContact.checkedProperties = [];
    formatContact.isNewContact = true;
    data.contacts.push(formatContact);
    this.props.passDataUp("additionalContacts", data.contacts);
    this.setState(data);
  };

  handleLandownerContactAssociation = (contactData) => {
    let property = this.props.step3Data.properties.find(
      (prop) =>
        prop.propertyUniqueIdentifier === this.state.currentPropertyForLandownerAssociation?.propertyUniqueIdentifier
    );
    if (contactData) {
      property.landOwnersContact = contactData.contactGuid;
      property.landOwnersContactName = contactData.contactName;
      this.props.passDataUp("agreementProperties", this.props.step3Data.properties);
    }
  };

  showAddContactModal = () => {
    this.setState({ showAddContactModal: true });
  };

  closeAddContactModal = () => {
    this.setState({ showAddContactModal: false });
  };

  closeAddContactModalForLandowner = () => {
    this.setState({ showAddContactModalForLandowner: false });
  };

  goToDetails = (contact) => {
    this.props.history.push({
      pathname: "/contact/details",
      state: { entityData: contact },
    });
  };

  handleProtectedResourceSelection = async (input) => {
    return await this.handleResourceSelection(input);
  };

  handleHistoricalResourceSelection = async (input) => {
    return await this.handleResourceSelection(input, true);
  };

  async handleResourceSelection(input, isHistoricalResource = false) {
    const { data, currentUsaState } = this.state;

    if (isHistoricalResource) {
      data.historicalResourceSearch = input;
    } else {
      data.resourceSearch = input;
    }

    const resourceId = input.value;
    if (currentUsaState?.stateGuid) {
      const resourceServerResponse = await ReferenceFileAPI.GetResourceForState(resourceId, currentUsaState.stateGuid);

      if (resourceServerResponse?.successful) {
        const resourceData = resourceServerResponse.data;
        data.historicalResourceStateValuationSetting = resourceData.stateValuationSetting;

        if (resourceData.stateResourceValuations?.length > 0) {
          if (isHistoricalResource) {
            data.historicalResourceUnitValue = this.getMostRecentValuationValueForResource(resourceData);
          } else {
            data.resourceUnitValue = this.getMostRecentValuationValueForResource(resourceData);
          }
        }
        if (resourceData.stateValuationUnitOfMeasure) {
          const { id, name } = resourceData.stateValuationUnitOfMeasure;
          if (isHistoricalResource) data.historicalResourceUom = { value: id, label: name };
          else data.resourceUom = { value: id, label: name };
        }
      }
    }

    this.setState({ data });
    return input;
  }

  getMostRecentValuationValueForResource(resourceData) {
    const value = resourceData?.stateResourceValuations?.sort((a, b) => (a.valuationYear > b.valuationYear ? -1 : 1))[0]
      ?.valuePerUnitOfMeasure;
    return value ?? "";
  }

  handleSpeciesSelection = (newSpecies) => {
    let { selectedSpecies, selectedSpeciesTableData } = this.state;
    let areRecordsDuplicated = false;

    for (let species of newSpecies) {
      if (selectedSpecies.find((s) => s.speciesUniqueIdentifier === species.speciesUniqueIdentifier)) {
        areRecordsDuplicated = true;
      } else {
        const newTableIds = species.checkedProperties.map((property) =>
          property.propertyUniqueIdentifier
            ? `${species.speciesUniqueIdentifier}|${property.propertyUniqueIdentifier}`
            : `${species.speciesUniqueIdentifier}|${property.propertyUniqueReference}`
        );

        newTableIds.forEach((tableId) => {
          const currentPropertyId = tableId.split("|")[1];
          const currentPropertyArray = [
            ...species.checkedProperties.filter(
              (property) =>
                (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === currentPropertyId) ||
                (property.propertyUniqueReference && property.propertyUniqueReference === currentPropertyId)
            ),
            ...species.checkedProperties.filter(
              (property) =>
                (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier !== currentPropertyId) ||
                (property.propertyUniqueReference && property.propertyUniqueReference !== currentPropertyId)
            ),
          ];
          selectedSpeciesTableData.push({
            speciesUniqueIdentifier: species.speciesUniqueIdentifier,
            speciesName: species.name,
            checkedProperties: currentPropertyArray,
            speciesTableId: tableId,
          });
        });

        selectedSpecies.push({
          name: species.name,
          speciesUniqueIdentifier: species.speciesUniqueIdentifier,
          checkedProperties: species.checkedProperties,
          isNewSpecies: true,
        });
      }
    }

    if (areRecordsDuplicated) {
      toast.info(
        "One or more of these damage agents were previously added to the agreement. You can edit their Property Associations on the specific record."
      );
    }

    this.props.passDataUp("species", selectedSpecies);
    this.setState({ selectedSpecies, selectedSpeciesTableData });
  };

  handleMethodsSelection = (input) => {
    const { data } = this.state;
    data.methodsSearch = input;

    this.setState({ data });
    return input;
  };

  handleResourceUomChange = (input) => {
    const { data } = this.state;
    data.resourceUom = input;
    if (input.value === 6 && data.resourceQuantity) {
      data.resourceQuantity = Math.round(data.resourceQuantity);
    }

    this.setState({ data });
    return input;
  };

  handleHistoricalResourceUomChange = (input) => {
    const { data } = this.state;
    data.historicalResourceUom = input;
    if (input.value === 6 && data.historicalResourceQuantity) {
      data.historicalResourceQuantity = Math.round(data.historicalResourceQuantity);
    }

    this.setState({ data });
    return input;
  };

  handleResourceSearch = async (inputValue) => {
    return await this.getResourcesFromAPI(inputValue);
  };

  async getResourcesFromAPI(searchValue) {
    const { currentUsaState } = this.state;
    if (currentUsaState && currentUsaState.stateGuid) {
      const resourcesData = await ReferenceFileAPI.FilterResources(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );
      if (resourcesData.data.results) {
        let results = resourcesData.data.results;

        return results.map((result) => ({
          value: result.resourceUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return "";
  }

  handleSpeciesSearch = async (inputValue) => {
    return await this.getSpeciesFromAPI(inputValue);
  };

  async getSpeciesFromAPI(searchValue) {
    const { currentUsaState } = this.state;
    if (currentUsaState && currentUsaState.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );

      if (speciesData.data.results) {
        let results = speciesData.data.results;
        return results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return "";
  }

  handleMethodsSearch = async (inputValue) => {
    return await this.getMethodsFromAPI(inputValue);
  };

  async getMethodsFromAPI(searchValue) {
    const { currentUsaState } = this.state;
    if (currentUsaState && currentUsaState.stateGuid) {
      const methodsData = await ReferenceFileAPI.FilterMethods(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );

      if (methodsData.data.results) {
        let results = methodsData.data.results;
        return results.map((result) => ({
          value: result.methodUniqueIdentifier,
          label: result.methodName,
        }));
      }
    }

    return "";
  }

  getFilterReferenceFilesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  handleResourceChanges = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;

    switch (input.name) {
      case "historicalResourceQuantity":
        if (input.value) {
          data.historicalResourceQuantity =
            data.historicalResourceUom?.value === 6
              ? Math.round(Number.parseFloat(input.value))
              : Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
          data.historicalResourceTotalValue = data.historicalResourceUnitValue
            ? data.historicalResourceQuantity * data.historicalResourceUnitValue
            : data.historicalResourceQuantity;
        } else {
          data.historicalResourceQuantity = "";
        }
        break;
      case "historicalResourceUnitValue":
        data.historicalResourceUnitValue = input.value
          ? Number.parseFloat(Number.parseFloat(input.value).toFixed(2))
          : "";
        data.historicalResourceTotalValue =
          data.historicalResourceQuantity && data.historicalResourceUnitValue
            ? data.historicalResourceQuantity * data.historicalResourceUnitValue
            : data.historicalResourceTotalValue;
        break;
      case "historicalResourceTotalValue":
        data.historicalResourceTotalValue = input.value
          ? Number.parseFloat(Number.parseFloat(input.value).toFixed(2))
          : "";
        if (data.historicalResourceUnitValue) {
          const resourceQuantity =
            data.historicalResourceTotalValue && data.historicalResourceUnitValue
              ? data.historicalResourceTotalValue / data.historicalResourceUnitValue
              : 0;
          data.historicalResourceQuantity =
            data.historicalResourceUom?.value === 6
              ? Math.round(resourceQuantity)
              : Number.parseFloat(resourceQuantity.toFixed(2));
        }
        break;
    }

    this.setState({ data });
  };

  getResourceDataColumns(resourceTable = "protectedResources") {
    let resourceDataColumns = [
      {
        dataField: "resourceTableId",
        hidden: true,
      },
      {
        dataField: "year",
        text: "Year",
        headerAlign: "left",
        align: "left",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          if (
            resourceTable === "historicalResourcesInventory" &&
            formatExtraData.isHistoricalResourcesTableEditable &&
            formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId
          ) {
            return (
              <Form.Control
                type="number"
                name="year"
                onChange={(e) => this.updateEditedHistoricalResource(e, row)}
                value={formatExtraData.editedHistoricalResource.year}
              />
            );
          } else {
            return cell;
          }
        },
        formatExtraData: this.state,
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
      },
      {
        dataField: "resourceName",
        text: "Resource",
        headerStyle: () => {
          return { width: "15%", fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
        headerAlign: "left",
        align: "left",
        editable: false,
      },
      {
        dataField: "quantity",
        text: "Quantity",
        headerAlign: "left",
        align: "left",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          if (
            (resourceTable === "protectedResources" &&
              formatExtraData.isProtectedResourcesTableEditable &&
              formatExtraData.editedProtectedResource.resourceTableId === row.resourceTableId) ||
            (resourceTable === "historicalResourcesInventory" &&
              formatExtraData.isHistoricalResourcesTableEditable &&
              formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId)
          ) {
            return (
              <Form.Control
                type="number"
                name="quantity"
                onChange={
                  resourceTable === "protectedResources"
                    ? (e) => this.updateEditedProtectedResource(e, row)
                    : (e) => this.updateEditedHistoricalResource(e, row)
                }
                value={
                  resourceTable === "protectedResources"
                    ? formatExtraData.editedProtectedResource.quantity
                    : formatExtraData.editedHistoricalResource.quantity
                }
              />
            );
          } else {
            return cell;
          }
        },
        formatExtraData: this.state,
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
      },
      {
        dataField: "unitOfMeasureName",
        text: "UOMs",
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
      },
      {
        dataField: "unitValue",
        text: "Unit Value",
        headerAlign: "left",
        align: "left",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          if (row.stateValuationSetting?.id !== 1) {
            if (
              (resourceTable === "protectedResources" &&
                formatExtraData.isProtectedResourcesTableEditable &&
                formatExtraData.editedProtectedResource.resourceTableId === row.resourceTableId) ||
              (resourceTable === "historicalResourcesInventory" &&
                formatExtraData.isHistoricalResourcesTableEditable &&
                formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId)
            ) {
              return (
                <Form.Control
                  type="number"
                  name="unitValue"
                  onChange={
                    resourceTable === "protectedResources"
                      ? (e) => this.updateEditedProtectedResource(e, row)
                      : (e) => this.updateEditedHistoricalResource(e, row)
                  }
                  value={
                    resourceTable === "protectedResources"
                      ? formatExtraData.editedProtectedResource.unitValue
                      : formatExtraData.editedHistoricalResource.unitValue
                  }
                />
              );
            } else {
              if (row.unitValue) {
                return UtilityFunctions.getValueAsCurrency(row.unitValue);
              }
            }
          }
        },
        formatExtraData: this.state,
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
      },
      {
        dataField: "totalValue",
        text: "Total Value",
        headerAlign: "left",
        align: "left",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          if (row.stateValuationSetting?.id !== 1) {
            if (
              (resourceTable === "protectedResources" &&
                formatExtraData.isProtectedResourcesTableEditable &&
                formatExtraData.editedProtectedResource.resourceTableId === row.resourceTableId) ||
              (resourceTable === "historicalResourcesInventory" &&
                formatExtraData.isHistoricalResourcesTableEditable &&
                formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId)
            ) {
              return (
                <Form.Control
                  type="number"
                  name="totalValue"
                  onChange={
                    resourceTable === "protectedResources"
                      ? (e) => this.updateEditedProtectedResource(e, row)
                      : (e) => this.updateEditedHistoricalResource(e, row)
                  }
                  value={
                    resourceTable === "protectedResources"
                      ? formatExtraData.editedProtectedResource.totalValue
                      : formatExtraData.editedHistoricalResource.totalValue
                  }
                />
              );
            } else {
              if (row.totalValue) {
                return UtilityFunctions.getValueAsCurrency(row.totalValue);
              }
            }
          }
        },
        formatExtraData: this.state,
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
      },
      {
        dataField: "checkedProperties",
        text: "Property",
        formatter: (cell, row) => {
          const selectedPropertyId = row.checkedProperties[0]?.propertyUniqueIdentifier
            ? row.checkedProperties[0].propertyUniqueIdentifier
            : row.checkedProperties[0]?.propertyUniqueReference;
          return this.props.step3Data.properties.find(
            (property) =>
              (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === selectedPropertyId) ||
              (property.propertyUniqueReference && property.propertyUniqueReference === selectedPropertyId)
          )?.propertyName;
        },
        headerStyle: () => {
          return { width: "15%", fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
        headerAlign: "left",
        align: "left",
      },
    ];

    if (resourceTable === "historicalResourcesInventory") {
      resourceDataColumns.push({
        dataField: "totalLossValue",
        text: "Total Loss Value",
        formatter: (cell, row) => {
          let totalLosses;
          if (row.historicalLosses?.length > 0 && row.stateValuationSetting?.id !== 1) {
            totalLosses = row.historicalLosses.reduce(
              (acumulator, currentLoss) => acumulator + currentLoss.totalValue,
              0
            );
          }
          return totalLosses ? (
            <Button className="pl-0 pt-0" variant="link" onClick={() => this.handleHistoricalResourcesAddLoss(row)}>
              {UtilityFunctions.getValueAsCurrency(totalLosses)}
            </Button>
          ) : (
            "N/A"
          );
        },
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
        headerAlign: "left",
        align: "left",
      });
    }

    resourceDataColumns.push({
      dataField: "resourceEditAssociations",
      text: "",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (resourceTable === "protectedResources") {
          if (
            formatExtraData.isProtectedResourcesTableEditable &&
            formatExtraData.editedProtectedResource.resourceTableId === row.resourceTableId
          ) {
            return (
              <Button variant="link" onClick={() => this.editProtectedResourceEntry()}>
                {<span className={styles.actionButtonsText}>Save</span>}
              </Button>
            );
          } else {
            return (
              <DropdownButton
                id="dropdown-protected-resources-actions"
                title={<span className={styles.actionButtonsText}>Actions</span>}
                variant="link"
              >
                <Dropdown.Item
                  eventKey="editResourceField"
                  onSelect={() => this.enableInlineResourceEdit(resourceTable, row)}
                >
                  <span className={styles.dropdownActionItems}>Edit</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="editAssociations"
                  onSelect={() => this.editResourcePropertyAssociationModal(row)}
                >
                  <span className={styles.dropdownActionItems}>Edit Associations</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="deleteResource" onSelect={() => this.deleteProtectedResource(row)}>
                  <span className={styles.dropdownActionItems}>Delete</span>
                </Dropdown.Item>
              </DropdownButton>
            );
          }
        } else {
          if (
            formatExtraData.isHistoricalResourcesTableEditable &&
            formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId
          ) {
            return (
              <Button variant="link" onClick={() => this.editHistoricalResourceEntry(resourceTable)}>
                {<span className={styles.actionButtonsText}>Save</span>}
              </Button>
            );
          } else {
            return (
              <DropdownButton
                id="dropdown-historical-resources-actions"
                title={<span className={styles.actionButtonsText}>Actions</span>}
                variant="link"
              >
                <Dropdown.Item
                  eventKey="editResourceField"
                  onSelect={() => this.enableInlineResourceEdit(resourceTable, row)}
                >
                  <span className={styles.dropdownActionItems}>Edit</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="editAssociations"
                  onSelect={() => this.handleHistoricalResourcesEditAssociations(row)}
                >
                  <span className={styles.dropdownActionItems}>Edit Associations</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="addLoss" onSelect={() => this.handleHistoricalResourcesAddLoss(row)}>
                  <span className={styles.dropdownActionItems}>
                    {row.historicalLosses && row.historicalLosses.length > 0 ? "Edit Loss" : "Add Loss"}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="duplicate">
                  <span className={styles.dropdownActionItems}>Duplicate</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="deleteResource" onSelect={() => this.deleteHistoricalResource(row)}>
                  <span className={styles.dropdownActionItems}>Delete</span>
                </Dropdown.Item>
              </DropdownButton>
            );
          }
        }
      },
      formatExtraData: this.state,
      headerStyle: () => {
        return { width: "15%", borderBottom: "0.8px solid #008767" };
      },
      headerAlign: "right",
      align: "right",
    });

    return resourceDataColumns;
  }

  getSpeciesAndMethodsTableStyles() {
    return {
      columnHeaderStyle1: {
        width: "20%",
        fontSize: "14px",
        borderBottom: "0.8px solid #008767",
        textAlign: "left",
      },
      columnHeaderStyle2: {
        width: "35%",
        fontSize: "14px",
        borderBottom: "0.8px solid #008767",
        textAlign: "left",
      },
      columnHeaderStyle3: { width: "15%", borderBottom: "0.8px solid #008767" },
      columnBodyStyle1: { width: "25%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "left" },
      columnBodyStyle2: { width: "35%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "left" },
      columnBodyStyle3: { width: "15%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "right" },
    };
  }

  getSpeciesDataColumns() {
    const tableStyles = this.getSpeciesAndMethodsTableStyles();
    return [
      {
        dataField: "speciesTableId",
        hidden: true,
      },
      {
        dataField: "speciesName",
        text: "Species",
        headerStyle: () => {
          return tableStyles.columnHeaderStyle2;
        },
        style: tableStyles.columnBodyStyle1,
      },
      {
        dataField: "checkedProperties",
        text: "Property",
        formatter: (cell, row) => {
          const selectedPropertyId = row.checkedProperties[0]?.propertyUniqueIdentifier
            ? row.checkedProperties[0].propertyUniqueIdentifier
            : row.checkedProperties[0]?.propertyUniqueReference;
          return this.props.step3Data.properties.find(
            (property) =>
              (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === selectedPropertyId) ||
              (property.propertyUniqueReference && property.propertyUniqueReference === selectedPropertyId)
          )?.propertyName;
        },
        headerStyle: () => {
          return tableStyles.columnHeaderStyle1;
        },
        style: tableStyles.columnBodyStyle1,
      },
      {
        dataField: "speciesEditAssociations",
        text: "",
        formatter: (cell, row) => {
          return (
            <DropdownButton id="dropdown-species-actions" title="Actions" variant="link">
              <Dropdown.Item eventKey="editAssociations" onSelect={() => this.editSpeciesPropertyAssociationModal(row)}>
                Edit Associations
              </Dropdown.Item>
              <Dropdown.Item eventKey="deleteSpecies" onSelect={() => this.deleteSpecies(row)}>
                Delete
              </Dropdown.Item>
            </DropdownButton>
          );
        },
        headerStyle: () => {
          return tableStyles.columnHeaderStyle3;
        },
        style: tableStyles.columnBodyStyle3,
      },
    ];
  }

  getMethodsDataColumns() {
    const tableStyles = this.getSpeciesAndMethodsTableStyles();
    return [
      {
        dataField: "methodTableId",
        hidden: true,
      },
      {
        dataField: "methodName",
        text: "Method",
        headerStyle: () => {
          return tableStyles.columnHeaderStyle2;
        },
        style: tableStyles.columnBodyStyle1,
      },
      {
        dataField: "checkedProperties",
        text: "Property",
        formatter: (cell, row) => {
          const selectedPropertyId = row.checkedProperties[0]?.propertyUniqueIdentifier
            ? row.checkedProperties[0].propertyUniqueIdentifier
            : row.checkedProperties[0]?.propertyUniqueReference;
          return this.props.step3Data.properties.find(
            (property) =>
              (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === selectedPropertyId) ||
              (property.propertyUniqueReference && property.propertyUniqueReference === selectedPropertyId)
          )?.propertyName;
        },
        headerStyle: () => {
          return tableStyles.columnHeaderStyle1;
        },
        style: tableStyles.columnBodyStyle1,
      },
      {
        dataField: "methodEditAssociations",
        text: "",
        formatter: (cell, row) => {
          return (
            <DropdownButton id="dropdown-methods-actions" title="Actions" variant="link">
              <Dropdown.Item eventKey="editAssociations" onSelect={() => this.editMethodPropertyAssociationModal(row)}>
                Edit Associations
              </Dropdown.Item>
              <Dropdown.Item eventKey="deleteMethods" onSelect={() => this.deleteMethod(row)}>
                Delete
              </Dropdown.Item>
            </DropdownButton>
          );
        },
        headerStyle: () => {
          return tableStyles.columnHeaderStyle3;
        },
        style: tableStyles.columnBodyStyle3,
      },
    ];
  }

  getRecCemDataColumns(isRecommendations = true) {
    return [
      { dataField: "tableId", hidden: true },
      {
        dataField: "recCemName",
        text: isRecommendations ? "Recommendations" : "CEMs",
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
      },
      {
        dataField: "checkedProperties",
        text: "Property",
        formatter: (cell, row) => {
          return row.property?.propertyName;
        },
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "recCemRemove",
        text: "",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <Button
              variant="link"
              onClick={() => (isRecommendations ? this.removeRecEntry(row.tableId) : this.removeCemEntry(row.tableId))}
            >
              {<span className={styles.actionButtonsText}>Remove</span>}
            </Button>
          );
        },
        headerStyle: () => {
          return { width: "40%", borderBottom: "0.8px solid #008767" };
        },
        headerAlign: "right",
        align: "right",
      },
    ];
  }

  addSpecialInstructionsPropertyAssociation = () => {
    if (
      !this.state.instructionsPropertyParcelModalData.properties ||
      this.state.instructionsPropertyParcelModalData.properties.length === 0
    ) {
      toast.warning("Please associate at least one property to this agreement first.");
      return;
    }
    if (this.state.currentPropertySpecialInstructions === "") {
      toast.warning("Please enter some special instructions before property association.");
      return;
    }

    let data = this.state;
    const { properties } = this.props.step3Data;

    let newInstruction = { text: data.currentPropertySpecialInstructions, checkedProperties: [] };
    data.currentPropertySpecialInstructions = "";
    data.instructionsPropertyParcelModalData.currentInstruction = newInstruction;
    data.instructionsPropertyParcelModalData.checkedProperties = newInstruction.checkedProperties;

    const showInstructionsPropertyAssociationModal = properties.length === 1 ? false : true;
    data.showInstructionsPropertyAssociationModal = showInstructionsPropertyAssociationModal;
    this.setState({ data });

    if (properties.length === 1) this.handleInstructionsPropertyParcelAssociation(properties);
  };

  editSpecialInstructionsPropertyAssociation = (index) => {
    let data = this.state;
    let specialInstruction = data.propertySpecialInstructions[index];
    data.instructionsPropertyParcelModalData.currentInstruction = specialInstruction;
    data.instructionsPropertyParcelModalData.checkedProperties = specialInstruction.checkedProperties;
    data.showInstructionsPropertyAssociationModal = true;
    this.setState({ data });
  };

  closeInstructionsPropertyAssociationModal = () => {
    let data = this.state;
    data.instructionsPropertyParcelModalData.currentInstruction = null;
    data.instructionsPropertyParcelModalData.checkedProperties = [];
    data.showInstructionsPropertyAssociationModal = false;
    this.setState({ data });
  };

  handleInstructionsPropertyParcelAssociation = (updatedCheckedProperties) => {
    let data = this.state;
    let currentInstruction = data.instructionsPropertyParcelModalData.currentInstruction;
    currentInstruction.checkedProperties = updatedCheckedProperties;
    data.instructionsPropertyParcelModalData.checkedProperties = updatedCheckedProperties;
    let combinedPropertyNames = [];
    currentInstruction.checkedProperties.map((property) => {
      property.propertyName = data.instructionsPropertyParcelModalData.properties.find(
        (prop) =>
          (prop.propertyUniqueIdentifier && prop.propertyUniqueIdentifier === property.propertyUniqueIdentifier) ||
          (prop.propertyUniqueReference && prop.propertyUniqueReference === property.propertyUniqueReference)
      ).propertyName;
      combinedPropertyNames.push(property.propertyName);
      return property;
    });
    currentInstruction.combinedPropertyName = combinedPropertyNames.join();
    currentInstruction.isUpdatedInstruction = true;
    let instructionsRecordExists = data.propertySpecialInstructions.find(
      (instr) => instr.text === currentInstruction.text
    );
    if (!instructionsRecordExists) {
      data.propertySpecialInstructions.push(currentInstruction);
    } else if (instructionsRecordExists && updatedCheckedProperties.length === 0) {
      let indexToRemove = data.propertySpecialInstructions.indexOf(instructionsRecordExists);
      data.propertySpecialInstructions.splice(indexToRemove, 1);
    }
    this.props.passDataUp("propertySpecialInstructions", data.propertySpecialInstructions);
    this.setState({ data });
  };

  handleStateChange = ({ currentTarget: input }) => {
    let propertyName = input.id;
    let data = this.state;
    data[propertyName] = input.value;
    this.setState({ data });
  };

  async getUpdatedHistoricalResourceValuation(row, year) {
    let unitValue = { ...row.unitValue };
    const { currentUsaState } = this.state;
    if (currentUsaState?.stateGuid) {
      const resourceServerResponse = await ReferenceFileAPI.GetResourceForState(
        row.resourceUniqueIdentifier,
        currentUsaState.stateGuid
      );

      if (resourceServerResponse?.successful) {
        const resourceData = resourceServerResponse.data;
        if (resourceData.stateResourceValuations?.length > 0) {
          const resourceValuation = resourceData.stateResourceValuations
            .filter((resource) => resource.valuationYear <= year)
            .sort((a, b) => (a.valuationYear > b.valuationYear ? -1 : 1))[0];
          unitValue = resourceValuation ? resourceValuation.valuePerUnitOfMeasure : "";
        }
      }
    }

    return unitValue;
  }

  handleNoResourcesDataSelection = ({ currentTarget: input }) => {
    const { data } = this.state;
    data[input.name] = input.checked;
    if (input.name === "noHistoricalResources") {
      data.noLosses = input.checked;
      this.props.passDataUp("noLosses", input.checked);
    }

    this.props.passDataUp(input.name, input.checked);
    this.setState({ data });
  };

  handleProtectedResourcesSelection = (newResources) => {
    let { selectedResources, selectedHistoricalResources } = this.state;
    let areRecordsDuplicated = false;

    for (let resource of newResources) {
      const tableIds = resource.checkedProperties.map((property) => ({
        tableId: `${resource.resourceUniqueIdentifier}|${property.propertyUniqueIdentifier}|${resource.unitValue}`,
        propertyId: property.propertyUniqueIdentifier,
      }));

      tableIds.forEach((t) => {
        if (selectedResources.some((r) => r.resourceTableId === t.tableId)) {
          areRecordsDuplicated = true;
        } else {
          const protectedResourceToBeAdded = {
            resourceTableId: t.tableId,
            resourceUniqueIdentifier: resource.resourceUniqueIdentifier,
            resourceName: resource.name,
            quantity: resource.quantity,
            unitValue: resource.unitValue,
            totalValue: resource.totalValue,
            unitOfMeasureId: resource.resourceUom?.value,
            unitOfMeasureName: resource.resourceUom?.label,
            year: new Date().getFullYear(),
            checkedProperties: resource.checkedProperties.filter((p) => p.propertyUniqueIdentifier === t.propertyId),
            isNewResource: true,
            stateValuationSetting: resource.stateValuationSetting,
          };
          selectedResources.push(protectedResourceToBeAdded);

          let historicalResource = { ...protectedResourceToBeAdded };
          historicalResource.year = historicalResource.year - 1;
          historicalResource.resourceTableId = `${historicalResource.resourceUniqueIdentifier}|${t.propertyId}|${historicalResource.unitValue}|${historicalResource.year}`;
          if (resource.stateResourceValuations?.length > 0) {
            const resourceValuation = resource.stateResourceValuations
              .filter((valuation) => valuation.valuationYear <= historicalResource.year)
              .sort((a, b) => (a.valuationYear > b.valuationYear ? -1 : 1))[0];
            historicalResource.unitValue =
              resourceValuation && resource.stateValuationSetting?.id !== 1
                ? resourceValuation.valuePerUnitOfMeasure
                : "";
            historicalResource.totalValue = historicalResource.unitValue
              ? historicalResource.unitValue * historicalResource.quantity
              : "";
            historicalResource.resourceTableId = `${historicalResource.resourceUniqueIdentifier}|${t.propertyId}|${historicalResource.unitValue}|${historicalResource.year}`;
          }

          if (!selectedHistoricalResources.some((r) => r.resourceTableId === historicalResource.resourceTableId)) {
            selectedHistoricalResources.push(historicalResource);
          }
        }
      });
    }

    if (areRecordsDuplicated) {
      toast.info(
        "Not all the resources were added as the same valuation already exists for some of their associated properties."
      );
    }

    this.props.passDataUp("protectedResources", selectedResources);
    this.props.passDataUp("historicalResourcesInventory", selectedHistoricalResources);
    this.setState({
      selectedResources,
      selectedHistoricalResources,
    });
  };

  handleMethodsSelectionSave = (newMethods) => {
    let { selectedMethods, selectedMethodsTableData } = this.state;
    let areRecordsDuplicated = false;

    for (let method of newMethods) {
      if (selectedMethods.find((m) => m.methodUniqueIdentifier === method.methodUniqueIdentifier)) {
        areRecordsDuplicated = true;
      } else {
        const newTableIds = method.checkedProperties.map((property) =>
          property.propertyUniqueIdentifier
            ? `${method.methodUniqueIdentifier}|${property.propertyUniqueIdentifier}`
            : `${method.methodUniqueIdentifier}|${property.propertyUniqueReference}`
        );

        newTableIds.forEach((tableId) => {
          const currentPropertyId = tableId.split("|")[1];
          const currentPropertyArray = [
            ...method.checkedProperties.filter(
              (property) =>
                (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier === currentPropertyId) ||
                (property.propertyUniqueReference && property.propertyUniqueReference === currentPropertyId)
            ),
            ...method.checkedProperties.filter(
              (property) =>
                (property.propertyUniqueIdentifier && property.propertyUniqueIdentifier !== currentPropertyId) ||
                (property.propertyUniqueReference && property.propertyUniqueReference !== currentPropertyId)
            ),
          ];
          selectedMethodsTableData.push({
            methodUniqueIdentifier: method.methodUniqueIdentifier,
            methodName: method.methodName,
            checkedProperties: currentPropertyArray,
            methodsTableId: tableId,
          });
        });

        selectedMethods.push({
          methodName: method.methodName,
          methodUniqueIdentifier: method.methodUniqueIdentifier,
          checkedProperties: method.checkedProperties,
          isNewMethod: true,
        });
      }
    }

    if (areRecordsDuplicated) {
      toast.info(
        "One or more of these methods were previously added to the agreement. You can edit their Property Associations on the specific record."
      );
    }

    this.props.passDataUp("methods", selectedMethods);
    this.setState({ selectedMethods, selectedMethodsTableData });
  };

  handleRecommendationsSelection = (recommendationData) => {
    let { selectedRecommendations, availableRecommendations } = this.state;
    const selectedRecCem = availableRecommendations?.find((r) => r.value === recommendationData.value);
    if (selectedRecCem) {
      if (!selectedRecCem.isSelected && !selectedRecommendations?.find((r) => r.value === recommendationData.value)) {
        selectedRecommendations = [...selectedRecommendations, recommendationData];
      } else if (
        selectedRecCem.isSelected &&
        selectedRecommendations?.find((r) => r.value === recommendationData.value)
      ) {
        selectedRecommendations = selectedRecommendations.filter((r) => r.value !== recommendationData.value);
      }
      selectedRecCem.isSelected = !selectedRecCem.isSelected;
    }

    this.setState({ selectedRecommendations, availableRecommendations });
  };

  handleCEMsSelection = (cemData) => {
    let { selectedCEMs, availableCEMs } = this.state;
    const selectedRecCem = availableCEMs?.find((r) => r.value === cemData.value);
    if (selectedRecCem) {
      if (!selectedRecCem.isSelected && !selectedCEMs?.find((r) => r.value === cemData.value)) {
        selectedCEMs = [...selectedCEMs, cemData];
      } else if (selectedRecCem.isSelected && selectedCEMs?.find((r) => r.value === cemData.value)) {
        selectedCEMs = selectedCEMs.filter((r) => r.value !== cemData.value);
      }
      selectedRecCem.isSelected = !selectedRecCem.isSelected;
    }

    this.setState({ selectedCEMs, availableCEMs });
  };

  renderProtectedResourcesCard() {
    const { data } = this.state;

    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col>
              {"Add Protected Resources "}
              <span>
                <i
                  className="fa fa-exclamation-circle"
                  style={{ color: "gray" }}
                  title="Input at least one Protected Resource, or check the No Protected Resources box"
                  aria-hidden="true"
                ></i>
              </span>
            </Col>
            <Col className="text-right" sm="auto">
              <Row>
                <Col sm="auto">
                  {this.state.selectedResources?.length === 0 && (
                    <Form.Group controlId="noProtectedResourcesCheckbox">
                      <Form.Check
                        name="noProtectedResources"
                        type="checkbox"
                        label="No Protected Resources"
                        onChange={this.handleNoResourcesDataSelection}
                        checked={data.noProtectedResources}
                      />
                    </Form.Group>
                  )}
                </Col>
                {this.state.selectedResources?.length === 0 && !this.state.data.noProtectedResources && "|"}
                {!data.noProtectedResources && (
                  <Col sm="auto">
                    <Button
                      className={styles.addButtonLinksInHeader}
                      variant="link"
                      onClick={() => {
                        this.setState({ showResourceSelectionModal: true });
                      }}
                    >
                      + Add Protected Resources
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="my-3" hidden={this.state.selectedResources.length === 0}>
            <BootstrapTable
              keyField="resourceTableId"
              data={this.state.selectedResources.sort(UtilityFunctions.agreementEntityTableSortFunc("resourceName"))}
              columns={this.getResourceDataColumns()}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderHistoricalResourcesCard() {
    const { data } = this.state;
    const { resourceUOMs } = this.props.step3Data;

    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col>
              {"Add Historical Resources and Losses "}
              <span>
                <i
                  className="fa fa-exclamation-circle"
                  style={{ color: "gray" }}
                  title="Input at least one Historical Resource and Historical Loss, or check the No Historical Resources or No Losses box"
                  aria-hidden="true"
                ></i>
              </span>
            </Col>
            <Col className="text-right">
              <div>
                {this.state.selectedHistoricalResources?.length === 0 && (
                  <Form.Check
                    aria-label="No Historical Resources"
                    type="checkbox"
                    label="No Historical Resources"
                    name="noHistoricalResources"
                    onChange={this.handleNoResourcesDataSelection}
                    checked={data.noHistoricalResources}
                    inline
                  />
                )}
                {!this.state.selectedHistoricalResources?.some((r) => r.historicalLosses?.length > 0) && (
                  <Form.Check
                    aria-label="No Historical Losses"
                    type="checkbox"
                    label="No Losses"
                    name="noLosses"
                    onChange={this.handleNoResourcesDataSelection}
                    checked={data.noLosses}
                    inline
                  />
                )}
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className={styles.informationRow} style={{ paddingLeft: "10px" }}>
            <Col md={1} className="pl-1 pr-0">
              <Form.Control
                name="historicalResourceYear"
                type="number"
                aria-label="Historical Resource Year Input"
                placeholder="Year"
                value={data.historicalResourceYear}
                onChange={this.handleResourceChanges}
                readOnly={data.noHistoricalResources}
              />
            </Col>
            <Col md={3} className="pr-2">
              {data.noHistoricalResources ? (
                <Form.Control
                  name="historicalResourcesSearchBar"
                  type="text"
                  placeholder="Search Resource"
                  value={data.historicalResourceSearch?.label}
                  onChange={this.handleResourceChanges}
                  readOnly
                />
              ) : (
                <AsyncSelect
                  aria-label="Search for Historical Resources Bar"
                  value={data.historicalResourceSearch}
                  openMenuOnClick={false}
                  placeholder="Search Resource"
                  loadOptions={this.handleResourceSearch}
                  onChange={this.handleHistoricalResourceSelection}
                  components={{ DropdownIndicator, NoOptionsMessage }}
                  isDisabled={data.noHistoricalResources}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "",
                      primary: "#ced4da",
                    },
                  })}
                  styles={{
                    control: (styles) => ({ ...styles, fontSize: "12px" }),
                    menu: (styles) => ({ ...styles, marginTop: "0px" }),
                    noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                      const color = "#EAF3F1";
                      return {
                        ...styles,
                        fontSize: "12px",
                        textAlign: "left",
                        borderBottom: "1px solid #f2f2f2",
                        backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                        color: isDisabled ? "#ccc" : isSelected ? ("#ccc" ? "white" : "black") : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor: color,
                        },
                      };
                    },
                    indicatorSeparator: () => {
                      //do nothing
                    },
                    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                  }}
                />
              )}
            </Col>
            <Col md={1} className="px-2">
              <Form.Control
                name="historicalResourceQuantity"
                type="number"
                aria-label="Historical Resource Quantity Input"
                placeholder="Qty."
                value={data.historicalResourceQuantity}
                onChange={this.handleResourceChanges}
                readOnly={data.noHistoricalResources}
              />
            </Col>
            <Col md={1} className="px-0">
              {data.noHistoricalResources ? (
                <Form.Control
                  name="historicalResourceUom"
                  type="text"
                  placeholder="UOM"
                  value={data.historicalResourceUom?.label}
                  onChange={this.handleResourceChanges}
                  readOnly
                />
              ) : (
                <Select
                  aria-label="Select UOM Dropdown"
                  value={data.historicalResourceUom}
                  placeholder="UOM"
                  options={resourceUOMs}
                  onChange={this.handleHistoricalResourceUomChange}
                />
              )}
            </Col>
            <Col md={2} className="pr-3">
              {this.state.data.historicalResourceStateValuationSetting?.id !== 1 && (
                <Form.Control
                  name="historicalResourceUnitValue"
                  aria-label="Historical Resource Unit Value Input"
                  type="number"
                  placeholder="Unit Value"
                  value={data.historicalResourceUnitValue}
                  onChange={this.handleResourceChanges}
                  readOnly={data.noHistoricalResources}
                />
              )}
            </Col>
            <Col md={2} className="pl-1 pr-3">
              {this.state.data.historicalResourceStateValuationSetting?.id !== 1 && (
                <Form.Control
                  name="historicalResourceTotalValue"
                  aria-label="Historical Resource Total Value Input"
                  type="number"
                  placeholder="Total Value"
                  value={data.historicalResourceTotalValue}
                  onChange={this.handleResourceChanges}
                  readOnly={data.noHistoricalResources}
                />
              )}
            </Col>
            <Col align="right">
              <Button
                className={styles.associationButtons}
                onClick={this.showHistoricalResourcePropertyAssociationModal}
              >
                <span className={styles.associationButtonsText}>Associate with Property</span>
              </Button>
            </Col>
          </Row>
          <Row className="my-3" hidden={this.state.selectedHistoricalResources.length === 0}>
            <BootstrapTable
              keyField="resourceTableId"
              data={this.state.selectedHistoricalResources.sort(
                UtilityFunctions.agreementEntityTableSortFunc("resourceName")
              )}
              columns={this.getResourceDataColumns("historicalResourcesInventory")}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderTargetedSpeciesCard() {
    const { data } = this.state;
    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col>
              Add Damage Agents<span className={globalStyles.asterisk508}>{" *"}</span>
            </Col>
            <Col className="text-right" sm="auto">
              <Row>
                <Col sm="auto">
                  <Button
                    className={styles.addButtonLinksInHeader}
                    variant="link"
                    onClick={() => {
                      this.setState({ showSpeciesSelectionModal: true });
                    }}
                  >
                    + Add Damage Agents
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="my-3" hidden={this.state.selectedSpeciesTableData.length === 0}>
            <BootstrapTable
              keyField="speciesTableId"
              data={this.state.selectedSpeciesTableData.sort(
                UtilityFunctions.agreementEntityTableSortFunc("speciesName")
              )}
              columns={this.state.selectedSpeciesColumns}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderMethodsCard() {
    const { data } = this.state;
    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col>
              Add Methods<span className={globalStyles.asterisk508}>{" *"}</span>
            </Col>
            <Col className="text-right" sm="auto">
              <Button
                className={styles.addButtonLinksInHeader}
                variant="link"
                onClick={() => {
                  this.setState({ showMethodsSelectionModal: true });
                }}
              >
                + Add Methods
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="my-3" hidden={this.state.selectedMethodsTableData.length === 0}>
            <BootstrapTable
              keyField="methodsTableId"
              data={this.state.selectedMethodsTableData.sort(
                UtilityFunctions.agreementEntityTableSortFunc("methodName")
              )}
              columns={this.state.selectedMethodsColumns}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderSpecialInstructionsCard() {
    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          Add Special Instructions
        </Card.Header>
        <Card.Body>
          <Row style={{ marginBottom: "30px" }}>
            <Col lg={8}>
              <Form.Control
                id="currentPropertySpecialInstructions"
                aria-label="Special Instructions Text Box"
                value={this.state.currentPropertySpecialInstructions}
                placeholder="Enter Instructions"
                as="textarea"
                rows="5"
                style={{ resize: "none" }}
                onChange={this.handleStateChange}
              />
            </Col>
            <Col align="right">
              <Button className={styles.associationButtons} onClick={this.addSpecialInstructionsPropertyAssociation}>
                <span className={styles.associationButtonsText}>Associate with Property</span>
              </Button>
            </Col>
          </Row>
          {this.state.propertySpecialInstructions.map((instruction, index) => {
            return (
              <Row style={{ paddingBottom: "15px" }}>
                <Col align="left" md={8}>
                  <h6 className={styles.fieldLabels}>{instruction.combinedPropertyName}</h6>
                  <p className={globalStyles.informationText}>{instruction.text}</p>
                </Col>
                <Col align="right">
                  <DropdownButton
                    id="dropdown-instructions-actions"
                    title="Actions"
                    variant="link"
                    className={styles.actionsDropdown}
                  >
                    <Dropdown.Item
                      eventKey="editAssociations"
                      onSelect={() => this.editSpecialInstructionsPropertyAssociation(index)}
                    >
                      Edit Associations
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="deleteMethods" onSelect={() => this.deleteSpecialInstruction(index)}>
                      Delete
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Row>
            );
          })}
        </Card.Body>
      </Card>
    );
  }

  renderContactsCard() {
    return (
      <Card className={styles.createPageCards} hidden={this.state.agreementTypeEnumId === 2}>
        <Card.Header as="h4" className={styles.cardTitles}>
          Add Contacts
          <Button className={styles.addButtonLinksInHeader} variant="link" onClick={this.showAddContactModal}>
            + Add another Contact
          </Button>
        </Card.Header>
        <Card.Body className={styles.centerCardBody}>
          {this.state.contacts.map((contact, index) => {
            let phoneNumber = "";
            if (contact.phoneNumbers?.length > 0) {
              phoneNumber = UtilityFunctions.formatPhoneNumber(contact.phoneNumbers[0].phoneNumber);
            }
            let agreementRole = "";
            if (contact.contactRoleEnumId !== "") {
              let agreementRoleLookup = this.state.agreementContactRoles.find(
                (role) => role.id === parseInt(contact.contactRoleEnumId)
              );
              if (agreementRoleLookup) {
                agreementRole = agreementRoleLookup.displayText;
              }
            }
            if (contact.firstName) {
              contact.contactName = contact.firstName + " " + contact.middleName + " " + contact.lastName;
            }
            return (
              <div key={index}>
                <Row className={styles.informationRow} noGutters="true">
                  <Col align="left">
                    <Button className="p-0" variant="link" onClick={() => this.goToDetails(contact)}>
                      <p style={{ fontSize: "14px", textAlign: "left", margin: "0" }}>{contact.contactName}</p>
                    </Button>
                  </Col>
                  <Col align="left">
                    <p className={globalStyles.informationText}>{phoneNumber ? phoneNumber : "No Phone Number"}</p>
                  </Col>
                  <Col align="left">
                    <p className={globalStyles.informationText}>
                      {contact.emailAddress ? contact.emailAddress : "No Email"}
                    </p>
                  </Col>
                  <Col align="left">
                    <p className={globalStyles.informationText}>{agreementRole}</p>
                  </Col>
                  <Col align="right">
                    <DropdownButton
                      className={styles.actionsDropdown}
                      id="dropdown-contacts-actions"
                      title="Actions"
                      variant="link"
                    >
                      <Dropdown.Item
                        eventKey="editAssociations"
                        onSelect={() => this.showContactPropertyAssociationModal(index)}
                      >
                        Edit Associations
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="deleteMethods" onSelect={() => this.deleteContact(index)}>
                        Remove
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Card.Body>
      </Card>
    );
  }

  renderAssignRolesCard() {
    return (
      <Card
        className={styles.createPageCards}
        hidden={
          this.state.agreementTypeEnumId === 2 ||
          this.props.step3Data?.primaryCooperatorRoleEnumId ===
            this.props.step3Data.agreementContactRoles?.find((role) => role.name === "owner")?.id
        }
      >
        <Card.Header as="h4" className={styles.cardTitles}>
          Assign Roles<span className={globalStyles.asterisk508}>{" *"}</span>
        </Card.Header>
        <Card.Body className={styles.centerCardBody}>
          {this.props.step3Data.properties.map((property, index) => {
            const propertyCooperatorRole = property.cooperatorsRoleOnPropertyEnumId
              ? this.props.step3Data.agreementContactRoles?.find(
                  (r) => r.id === property.cooperatorsRoleOnPropertyEnumId
                )
              : null;
            return (
              <div key={index} className={styles.informationRow}>
                <Row className="pb-3 pt-3" noGutters="true">
                  <Col align="left">
                    <p
                      className={globalStyles.informationText}
                      style={{ fontSize: "14px", textAlign: "left", margin: "0", fontWeight: "600" }}
                    >
                      {property.propertyName}
                    </p>
                  </Col>
                  <Col align="left">
                    <p className={globalStyles.informationText}>{this.props.step3Data.primaryCooperatorName}</p>
                  </Col>
                  <Col align="left">
                    <Select
                      aria-label={"Choose Cooperator's role for the " + property.propertyName + " property"}
                      openMenuOnClick={true}
                      value={propertyCooperatorRole}
                      placeholder="Select Role"
                      options={this.props.step3Data.agreementContactRoles.map((item) => {
                        item.value = item.id;
                        item.label = item.displayText;
                        return item;
                      })}
                      onChange={(input) => {
                        if (input.name.toLowerCase() === "owner") {
                          property.landOwnersContact = null;
                          property.landOwnersContactName = null;
                        }
                        property.cooperatorsRoleOnPropertyEnumId = input?.id;
                        this.props.passDataUp("agreementProperties", this.props.step3Data.properties);
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "",
                          primary: "#ced4da",
                        },
                      })}
                      styles={{
                        control: (styles) => ({ ...styles, fontSize: "14px" }),
                        menu: (styles) => ({ ...styles, marginTop: "0px" }),
                        noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          const color = "#EAF3F1";
                          return {
                            ...styles,
                            fontSize: "14px",
                            textAlign: "left",
                            borderBottom: "1px solid #f2f2f2",
                            backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                            color: isDisabled ? "#ccc" : isSelected ? ("#ccc" ? "white" : "black") : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor: color,
                            },
                          };
                        },
                        indicatorSeparator: () => {
                          //do nothing
                        },
                        placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                      }}
                    />
                  </Col>
                </Row>
                {propertyCooperatorRole?.name.toLowerCase() !== "owner" && (
                  <Row noGutters>
                    <Col align="left">
                      <p style={{ fontSize: "14px", fontWeight: "600" }} className={globalStyles.informationText}>
                        {property.landOwnersContact ? "Landowner" : ""}
                      </p>
                    </Col>
                    <Col align="left">
                      <p className={globalStyles.informationText}>
                        {property.landOwnersContactName ? property.landOwnersContactName : ""}
                      </p>
                    </Col>
                    <Col>
                      <Button
                        className={styles.addButtonLinksInHeader}
                        variant="link"
                        onClick={() => {
                          if (property.landOwnersContact) {
                            property.landOwnersContact = null;
                            property.landOwnersContactName = null;
                            this.props.passDataUp("agreementProperties", this.props.step3Data.properties);
                          } else {
                            this.setState({
                              showAddContactModalForLandowner: true,
                              currentPropertyForLandownerAssociation: property,
                            });
                          }
                        }}
                      >
                        {property.landOwnersContact ? "- Remove Landowner" : "+ Add Landowner Contact"}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            );
          })}
        </Card.Body>
      </Card>
    );
  }

  renderRecCEMsSection(isRecommendations = true) {
    const { selectedRecTableData, selectedCemTableData, availableRecommendations, availableCEMs } = this.state;
    let entityName;
    let tableData;
    let tableColumns;
    let selectionRowClassName;
    let menuIsOpen;
    let isTableHidden;
    let availableOptions;

    if (isRecommendations) {
      entityName = "Recommendations";
      tableData = selectedRecTableData;
      tableColumns = this.state.selectedRecColumns;
      selectionRowClassName = "my-2";
      menuIsOpen = this.state.isRecDropdownOpen;
      isTableHidden = this.state.selectedRecTableData.length === 0;
      availableOptions = availableRecommendations?.filter(
        (availableRec) => !selectedRecTableData?.find((rec) => rec.recCemId === availableRec.value)
      );
    } else {
      entityName = "Cooperator Employed Methods";
      tableData = selectedCemTableData;
      tableColumns = this.state.selectedCemColumns;
      selectionRowClassName = "mt-5 mb-2";
      menuIsOpen = this.state.isCemDropdownOpen;
      isTableHidden = this.state.selectedCemTableData.length === 0;
      availableOptions = availableCEMs?.filter(
        (availableCEM) => !selectedCemTableData?.find((cem) => cem.recCemId === availableCEM.value)
      );
    }

    return (
      <React.Fragment>
        <Row className={selectionRowClassName}>
          <Col md={4}>
            <Select
              aria-label={`${entityName} Selection`}
              name={entityName}
              placeholder={entityName}
              value=""
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                  backgroundColor: isFocused ? "lightgray" : "white",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                }),
                menuList: (styles) => ({ ...styles, textAlign: "left" }),
              }}
              menuIsOpen={menuIsOpen}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onMenuClose={() => {
                isRecommendations
                  ? this.setState({ isRecDropdownOpen: false })
                  : this.setState({ isCemDropdownOpen: false });
              }}
              onFocus={() => {
                isRecommendations
                  ? this.setState({ isRecDropdownOpen: true })
                  : this.setState({ isCemDropdownOpen: true });
              }}
              onBlur={() => {
                isRecommendations
                  ? this.setState({ isRecDropdownOpen: false })
                  : this.setState({ isCemDropdownOpen: false });
              }}
              filterOption={(option, inputValue) => {
                return option?.data?.label.toLowerCase().includes(inputValue.toLowerCase());
              }}
              components={{
                Option: (props) => {
                  const { data, innerProps, innerRef } = props;
                  return (
                    <Row className="my-1 mx-1" ref={innerRef} {...innerProps}>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          inline
                          checked={data.isSelected}
                          onChange={() =>
                            isRecommendations
                              ? this.handleRecommendationsSelection(data)
                              : this.handleCEMsSelection(data)
                          }
                          title={`Select ${data.label}`}
                        />
                        <span className={data.isSelected ? styles.blueFormData : styles.formData}>{data.label}</span>
                      </Col>
                    </Row>
                  );
                },
              }}
              options={availableOptions}
            />
          </Col>
          <Col align="right">
            <Button
              className={styles.associationButtons}
              onClick={
                isRecommendations
                  ? this.showRecommendationsPropertyAssociationModal
                  : this.showCemPropertyAssociationModal
              }
            >
              <span className={styles.associationButtonsText}>Associate with Property</span>
            </Button>
          </Col>
        </Row>
        <Row className="my-3" hidden={isTableHidden}>
          <BootstrapTable
            keyField="tableId"
            data={tableData}
            columns={tableColumns}
            bootstrap4={true}
            hover={true}
            bordered={false}
          />
        </Row>
      </React.Fragment>
    );
  }

  renderRecCemsCard() {
    return (
      <Card className={styles.createPageCards} hidden={this.state.agreementTypeEnumId === 2}>
        <Card.Header as="h4" className={styles.cardTitles}>
          Add Recommendations and/or Cooperator Employed Methods
        </Card.Header>
        <Card.Body>
          {this.renderRecCEMsSection()}
          {this.renderRecCEMsSection(false)}
        </Card.Body>
      </Card>
    );
  }

  render() {
    const { properties } = this.props.step3Data;

    return (
      <div className={styles.Step3} data-testid="Step3">
        <h5 className={styles.pageTitles}>{this.state.pageHeader}</h5>
        {this.renderAssignRolesCard()}
        {this.renderContactsCard()}
        {this.renderProtectedResourcesCard()}
        {this.renderTargetedSpeciesCard()}
        {this.renderMethodsCard()}
        {this.renderSpecialInstructionsCard()}
        {this.renderRecCemsCard()}
        {this.renderHistoricalResourcesCard()}
        <PropertyParcelAssociationModal
          show={this.state.showContactPropertyAssociationModal}
          onHide={this.closeContactPropertyAssociationModal}
          properties={this.state.contactPropertyParcelModalData.properties}
          checkedProperties={this.state.contactPropertyParcelModalData.checkedProperties}
          onSaveAssociation={this.handleContactPropertyParcelAssociation}
          entityType="contact"
          isSelectionDisable={this.state.isPropertySelectionDisable}
        />
        <AddContactModal
          show={this.state.showAddContactModal}
          onHide={this.closeAddContactModal}
          contactTypes={this.state.contactTypes}
          phoneNumberTypes={this.state.phoneNumberTypes}
          states={this.state.states}
          countries={this.state.countries}
          usaCountryId={this.state.usaCountryId}
          contactType="contact"
          onSaveAssociation={this.handleExtraContactAssociation}
          agreementContactRoles={this.state.agreementContactRoles}
        />
        <AddContactModal
          show={this.state.showAddContactModalForLandowner}
          onHide={this.closeAddContactModalForLandowner}
          contactTypes={this.state.contactTypes}
          phoneNumberTypes={this.state.phoneNumberTypes}
          states={this.state.states}
          countries={this.state.countries}
          usaCountryId={this.state.usaCountryId}
          contactType="contact"
          onSaveAssociation={this.handleLandownerContactAssociation}
          agreementContactRoles={this.state.agreementContactRoles}
          isForLandowner={true}
        />
        <PropertyParcelAssociationModal
          show={this.state.showResourcePropertyAssociationModal}
          onHide={this.closeResourcePropertyAssociationModal}
          properties={properties}
          checkedProperties={this.state.resourcePropertyParcelModalData.checkedProperties}
          onSaveAssociation={this.handleResourcePropertyParcelAssociation}
          entityType="protectedResource"
        />
        <PropertyParcelAssociationModal
          show={this.state.showSpeciesPropertyAssociationModal}
          onHide={this.closeSpeciesPropertyAssociationModal}
          properties={properties}
          checkedProperties={this.state.speciesPropertyParcelModalData.checkedProperties}
          onSaveAssociation={this.handleSpeciesPropertyParcelAssociation}
          entityType="species"
        />
        <PropertyParcelAssociationModal
          show={this.state.showMethodsPropertyAssociationModal}
          onHide={this.closeMethodsPropertyAssociationModal}
          properties={properties}
          checkedProperties={this.state.methodsPropertyParcelModalData.checkedProperties}
          onSaveAssociation={this.handleMethodsPropertyParcelAssociation}
          entityType="methods"
        />
        <PropertyParcelAssociationModal
          show={this.state.showInstructionsPropertyAssociationModal}
          onHide={this.closeInstructionsPropertyAssociationModal}
          properties={this.state.instructionsPropertyParcelModalData.properties}
          checkedProperties={this.state.instructionsPropertyParcelModalData.checkedProperties}
          onSaveAssociation={this.handleInstructionsPropertyParcelAssociation}
          entityType="specialInstructions"
        />
        <PropertyParcelAssociationModal
          show={this.state.showRecCemPropertyAssociationModal}
          onHide={this.closeRecCemPropertyAssociationModal}
          properties={properties}
          checkedProperties={this.state.recCemPropertyParcelModalData.checkedProperties}
          onSaveAssociation={
            this.state.recCemPropertyParcelModalData.entityType === "recommendations"
              ? this.handleRecPropertyParcelAssociation
              : this.handleCemPropertyParcelAssociation
          }
          entityType={this.state.recCemPropertyParcelModalData.entityType}
        />
        <AddHistoricalLossModal
          show={this.state.showAddHistoricalLossModal}
          onHide={this.closeHistoricalLossModal}
          properties={properties}
          historicalResourceData={this.state.addHistoricalLossData}
          onSaveHistoricalLoss={this.handleNewHstoricalLoss}
          currentUsaState={this.state.currentUsaState}
        />
        <ResourceSelectionModal
          show={this.state.showResourceSelectionModal}
          onHide={() => {
            this.setState({ showResourceSelectionModal: false });
          }}
          properties={properties}
          onSave={this.handleProtectedResourcesSelection}
          currentUsaState={this.state.currentUsaState}
          resourceUOMs={this.props.step3Data.resourceUOMs}
          favoriteResourceIds={this.props.userData?.preferredResourcesUniqueReferences}
        />
        <SpeciesSelectionModal
          show={this.state.showSpeciesSelectionModal}
          onHide={() => {
            this.setState({ showSpeciesSelectionModal: false });
          }}
          properties={properties}
          onSave={this.handleSpeciesSelection}
          currentUsaState={this.state.currentUsaState}
          favoriteSpeciesIds={this.props.userData?.preferredSpeciesUniqueReferences}
        />
        <MethodsSelectionModal
          show={this.state.showMethodsSelectionModal}
          onHide={() => {
            this.setState({ showMethodsSelectionModal: false });
          }}
          properties={properties}
          onSave={this.handleMethodsSelectionSave}
          currentUsaState={this.state.currentUsaState}
          favoriteMethodIds={this.props.userData?.preferredMethodsUniqueReferences}
        />
      </div>
    );
  }
}

export default withRouter(Step3);
