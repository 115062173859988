import React, { Component } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import UtilityFunctions from "../common/UtilityFunctions";
import styles from "./Agreement.module.scss";

class ShowHistoricalLossModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historicalResourceColumns: [
        { dataField: "resourceTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      historicalLossColumns: [
        { dataField: "historicalLossUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
    };
  }

  componentDidMount() {
    this.setState({
      historicalResourceColumns: this.getHistoricalResourceColumns(),
      historicalLossColumns: this.getHistoricalLossColumns(),
    });
  }

  getHistoricalResourceColumns() {
    return [
      {
        dataField: "resourceTableId",
        hidden: true,
      },
      {
        dataField: "resourceName",
        text: "Resource",
        headerStyle: () => {
          return { width: "20%" };
        },
      },
      { dataField: "quantity", text: "Quantity" },
      {
        dataField: "valuationUnitOfMeasure",
        text: "UOMs",
        formatter: (cell, row) => {
          return row.valuationUnitOfMeasure ? this.getDisplayTextFromFieldObject(row.valuationUnitOfMeasure) : "";
        },
      },
      {
        dataField: "valuePerUnitOfMeasure",
        text: "Unit Value",
        formatter: (cell, row) => {
          return UtilityFunctions.getValueAsCurrency(row.valuePerUnitOfMeasure);
        },
      },
      {
        dataField: "totalValuation",
        text: "Total Value",
        formatter: (cell, row) => {
          return UtilityFunctions.getValueAsCurrency(row.totalValuation);
        },
      },
      { dataField: "valuationYear", text: "Year" },
    ];
  }

  getHistoricalLossColumns() {
    return [
      {
        dataField: "historicalLossUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "resourceName",
        text: "Resource",
        headerStyle: () => {
          return { width: "20%" };
        },
      },
      { dataField: "quantity", text: "Loss Quantity" },
      { dataField: "unitOfMeasureName", text: "UOMs" },
      { dataField: "percentage", text: "Percentage" },
      {
        dataField: "totalValue",
        text: "Loss Value",
        formatter: (cell, row) => {
          return UtilityFunctions.getValueAsCurrency(cell);
        },
      },
      {
        dataField: "species",
        text: "Species",
        formatter: (cell, row) => {
          return cell?.label ? cell.label : "";
        },
      },
    ];
  }

  getDisplayTextFromFieldObject(object) {
    let objectName = "";
    if (object.displayText) objectName = object.displayText;
    else if (object.name) objectName = object.name;

    return objectName;
  }

  render() {
    const { historicalResourceData } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.wideModalSize}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Loss</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
          <Row>
            <Col>
              <span className={styles.smallComments}>
                <i className="fa fa-exclamation-circle " style={{ color: "gray" }} aria-hidden="true"></i> Review
                losses.
              </span>
            </Col>
          </Row>
          <Row className="mt-3 mb-2">
            <Col>
              <Modal.Title>
                {historicalResourceData?.checkedProperties?.length > 0
                  ? historicalResourceData.checkedProperties[0].propertyName
                  : ""}
              </Modal.Title>
            </Col>
          </Row>
          <Row>
            <BootstrapTable
              keyField="resourceTableId"
              data={[historicalResourceData]}
              columns={this.state.historicalResourceColumns}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
          <Row className="my-2">
            <Col>
              <Modal.Title>Losses</Modal.Title>
            </Col>
          </Row>
          {historicalResourceData.historicalLosses?.length > 0 && (
            <Row className="my-2">
              <BootstrapTable
                keyField="historicalLossUniqueIdentifier"
                data={historicalResourceData.historicalLosses}
                columns={this.state.historicalLossColumns}
                bootstrap4={true}
                hover={true}
                bordered={false}
              />
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ShowHistoricalLossModal;

