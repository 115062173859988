import React from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import printerIcon from "../../assets/printer.svg";
import fileTextIcon from "../../assets/file-text.svg";
import ExportCSVButton from "./ExportCSVButton";

const ReferenceFileCardHeader = ({
  referenceFileType,
  createButtonEvent,
  permissions,
  useGlobalData,
  extraHeaderComponents,
  recordCount,
  ...csvProps
}) => {
  let isCreateAvailable =
    (referenceFileType === "User" && permissions?.canICreateUserRecord()) ||
    (referenceFileType === "Role" && permissions?.canICreateRole()) ||
    (referenceFileType === "Warehouse" && permissions?.canICreateWarehouses()) ||
    (referenceFileType !== "User" &&
      referenceFileType !== "Role" &&
      referenceFileType !== "Districts" &&
      referenceFileType !== "Warehouse" &&
      permissions?.canICreateReferenceData());

  const isNotReferenceFile =
    referenceFileType === "User" ||
    referenceFileType === "Role" ||
    referenceFileType === "Warehouse" ||
    referenceFileType === "Districts";

  if (
    (referenceFileType === "Admin Activities" ||
      referenceFileType === "Damage Agent" ||
      referenceFileType === "Resource" ||
      referenceFileType === "Damage" ||
      referenceFileType === "Disease" ||
      referenceFileType === "Topics" ||
      referenceFileType === "Intermediate Fate" ||
      referenceFileType === "REC/CEMs" ||
      referenceFileType === "Method") &&
    useGlobalData === false
  ) {
    isCreateAvailable = false;
  }
  const tableTitle = referenceFileType === "Warehouse" ? "CMITS" : `${referenceFileType} Listing`;

  let leftColumnHeaderStyleClass = "text-left";
  if (extraHeaderComponents && referenceFileType !== "Intermediate Fate") {
    leftColumnHeaderStyleClass = styles.listingHeaderColumn;
  } else if (referenceFileType === "Warehouse") {
    leftColumnHeaderStyleClass = "text-left pl-0";
  }

  return (
    <Row className={referenceFileType === "Warehouse" ? styles.warehouseHeaderRow : styles.refFilesHeaderRow}>
      <Col className={leftColumnHeaderStyleClass} lg={8}>
        {referenceFileType !== "Warehouse" && <Image src={fileTextIcon} className="pr-2" aria-hidden="true" alt="" />}
        <span className={styles.refFilesTableTitle}>
          {tableTitle}
          {!isNotReferenceFile ? (
            <span className={styles.resultInfoText}>
              {" "}
              - Showing {recordCount} results.{" "}
              {recordCount > 50 ? "Please use additional search criteria to better narrow your results." : ""}
            </span>
          ) : (
            ""
          )}
        </span>
      </Col>
      {extraHeaderComponents}
      <Col className={`${referenceFileType === "Warehouse" ? "pr-0" : ""} text-right`}>
        {isCreateAvailable && (
          <Button variant="link" onClick={createButtonEvent}>
            + Create {referenceFileType}
          </Button>
        )}
        <ExportCSVButton {...csvProps} />
        {/* We can uncomment the print button when it is put in scope. For now, printing can be accomplished via exporting a table
				and then printing it from Excel or whatever application opens the exported .csv file */}
        {/* <Image src={printerIcon} className="pr-1 pl-2" alt="Print" title={`Print Filtered ${referenceFileType}`} /> */}
      </Col>
    </Row>
  );
};

export default ReferenceFileCardHeader;
