import API from "../API";
import { APIRoutes } from "../APIRoutes";

class WorkTaskAPI {
  static async GetAllWorkTaskTypes() {
    const api = new API("/WorkTask/GetAllWorkTaskTypes", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetMethodUseConfigureActions(methodUseIDs) {
    const api = new API("/Method/GetMethodUseConfigureActions", APIRoutes.ReferenceData);
    const result = await api.put(methodUseIDs);
    return result;
  }

  static async CreatePVS(propertyVisitedGuid, performedByGuid, enteredByGuid, dateEntered) {
    const api = new API("/WorkTask/CreatePVS", APIRoutes.WorkTask);
    const result = await api.post({
      propertyVisitedUniqueReference: propertyVisitedGuid,
      performedByWsUserProfileUniqueReference: performedByGuid,
      enteredByWsUserProfileUniqueReference: enteredByGuid,
      dateEntered: dateEntered,
    });
    return result;
  }

  static async GetAllPVSForSingleProperty(propertyUniqueIdentifier) {
    let url = `/WorkTask/GetAllPVSForSingleProperty/${propertyUniqueIdentifier}/0/0`;
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/WorkTask/GetAllPVSForSingleProperty/${propertyUniqueIdentifier}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.WorkTask);
      result = await api.get();
    }

    return result;
  }

  static async GetPVS(pvsGuid) {
    const api = new API(`/WorkTask/GetPVS/${pvsGuid}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async FindInheritedPVSProjectOptions(propertyGuid) {
    const api = new API(`/WorkTask/FindInheritedPVSProjectOptions/${propertyGuid}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetRecentSiteVisitPropertiesForFieldSpecialist(wsUserProfileUniqueReference, signal) {
    const api = new API(
      `/WorkTask/GetRecentSiteVisitPropertiesForFieldSpecialist/${wsUserProfileUniqueReference}`,
      APIRoutes.WorkTask
    );
    const result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async CreateWorkTaskForPVS(pvsGuid, propertyGuidArray, hoursSpent, minutesSpent, workTaskTypeEnumId) {
    const api = new API(`/WorkTask/CreateWorkTaskForPVS/${pvsGuid}`, APIRoutes.WorkTask);
    const result = await api.post({
      propertyWorkPerformedOnUniqueReferences: propertyGuidArray,
      hoursSpent: hoursSpent,
      minutesSpent: minutesSpent,
      workTaskTypeEnumId: workTaskTypeEnumId,
    });
    return result;
  }

  static async UpdatePVS(propertyVisitedUniqueReference, body) {
    let api = new API("/WorkTask/UpdatePVS/" + propertyVisitedUniqueReference, APIRoutes.WorkTask);
    let result = await api.post(body);
    return result;
  }

  static async EditProtectResourceEffortTimeOnPVS(propertyVisitSummaryUniqueIdentifier, body) {
    let api = new API(
      "/WorkTask/EditProtectResourceEffortTimeOnPVS/" + propertyVisitSummaryUniqueIdentifier,
      APIRoutes.WorkTask
    );
    let result = await api.post(body);
    return result;
  }

  static async CreateCoopTAWorkTask(body) {
    let api = new API("/IndividualCooperatorWorkTask/CreateCoopTAWorkTask", APIRoutes.WorkTask);
    let result = await api.post(body);
    return result;
  }

  static async UpdateCoopTAWorkTask(workTaskGuid, body) {
    let api = new API(`/IndividualCooperatorWorkTask/UpdateCoopTAWorkTask/${workTaskGuid}`, APIRoutes.WorkTask);
    let result = await api.post(body);
    return result;
  }

  static async ModifyWorkPerformedAssociationsOfTake(takeGuid, body) {
    let api = new API(`/Take/ModifyWorkPerformedAssociationsOfTake/${takeGuid}`, APIRoutes.WorkTask);
    let result = await api.post(body);
    return result;
  }

  static async GetCoopWorkTasksOnProperty(propertyGuid) {
    const api = new API(`/IndividualCooperatorWorkTask/GetCoopWorkTasksOnProperty/${propertyGuid}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetCoopWorkTasksOnPropertyVisitSummary(pvsGuid) {
    const api = new API(
      `/IndividualCooperatorWorkTask/GetCoopWorkTasksOnPropertyVisitSummary/${pvsGuid}`,
      APIRoutes.WorkTask
    );
    const result = await api.get();
    return result;
  }

  static async GetCoopTAWorkTask(taWorkTaskId) {
    const api = new API(`/IndividualCooperatorWorkTask/GetCoopTAWorkTask/${taWorkTaskId}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetIndividualCooperatorPermitRecommendationTypes() {
    const api = new API("/IndividualCooperatorWorkTask/GetPermitRecommendationTypes", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetIndividualCooperatorPermitActions() {
    const api = new API("/IndividualCooperatorWorkTask/GetPermitActions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async CreateHotlineTAWorkTask(body) {
    let api = new API("/HotlineWorkTask/CreateHotlineTAWorkTask", APIRoutes.WorkTask);
    let result = await api.post(body);
    return result;
  }

  static async UpdateHotlineTAWorkTask(workTaskGuid, body) {
    let api = new API(`/HotlineWorkTask/UpdateHotlineTAWorkTask/${workTaskGuid}`, APIRoutes.WorkTask);
    let result = await api.post(body);
    return result;
  }

  static async GetHotlineTAWorkTask(taWorkTaskId) {
    const api = new API(`/HotlineWorkTask/GetHotlineTAWorkTask/${taWorkTaskId}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetHotlinePermitRecommendationTypes() {
    const api = new API("/HotlineWorkTask/GetPermitRecommendationTypes", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetHotlinePermitActions() {
    const api = new API("/HotlineWorkTask/GetPermitActions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async CreateOutreachTaWorkTask(body) {
    let api = new API("/OutreachWorkTask/CreateOutreachTAWorkTask", APIRoutes.WorkTask);
    let result = await api.post(body);
    return result;
  }

  static async UpdateOutreachTAWorkTask(workTaskGuid, body) {
    let api = new API(`/OutreachWorkTask/UpdateOutreachTAWorkTask/${workTaskGuid}`, APIRoutes.WorkTask);
    let result = await api.post(body);
    return result;
  }

  static async GetOutreachTAWorkTask(taWorkTaskId) {
    const api = new API(`/OutreachWorkTask/GetOutreachTAWorkTask/${taWorkTaskId}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async CreateWorkPerformedOnWorkTask(workTaskGuid, body) {
    const api = new API(`/WorkTask/CreateWorkPerformedOnWorkTask/${workTaskGuid}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async UpdateWorkPerformedOnWorkTask(workTaskUniqueIdentifier, body) {
    const api = new API(`/WorkTask/UpdateWorkPerformedOnWorkTask/${workTaskUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async CreateManyWorkPerformedOnWorkTask(workTaskGuid, body) {
    const api = new API(`/WorkTask/CreateManyWorkPerformedOnWorkTask/${workTaskGuid}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async RemoveWorkPerformedFromWorkTask(workTaskGuid) {
    const api = new API(`/WorkTask/RemoveWorkPerformedFromWorkTask/${workTaskGuid}`, APIRoutes.WorkTask);
    const result = await api.post();
    return result;
  }

  static async AddConflictToPVS(pvsGuid, body) {
    const api = new API(`/WorkTask/AddConflictToPVS/${pvsGuid}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async RemoveConflictsFromPVS(propertyVisitSummaryUniqueIdentifier, body) {
    const api = new API(`/WorkTask/RemoveConflictsFromPVS/${propertyVisitSummaryUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async AddProjectsToPVS(pvsGuid, body) {
    const api = new API(`/WorkTask/AddProjectsToPVS/${pvsGuid}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async RemoveProjectsFromPVS(pvsGuid, body) {
    const api = new API(`/WorkTask/RemoveProjectsFromPVS/${pvsGuid}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async AddProjectsToWorkPerformed(workPerformedGuid, body) {
    const api = new API(`/WorkTask/AddProjectsToWorkPerformed/${workPerformedGuid}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async RemoveProjectsFromWorkPerformed(workPerformedGuid, body) {
    const api = new API(`/WorkTask/RemoveProjectsFromWorkPerformed/${workPerformedGuid}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async CancelPVS(pvsGuid) {
    const api = new API(`/WorkTask/CancelPvs/${pvsGuid}`, APIRoutes.WorkTask);
    const result = await api.post();
    return result;
  }

  static async CompletePVS(pvsGuid, body) {
    const api = new API(`/WorkTask/CompletePVS/${pvsGuid}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async CreateBadWeatherCheckPVS(body) {
    const api = new API("/WorkTask/CreateBadWeatherCheckPVS", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async GetAllTakeUnitsOfMeasure() {
    const api = new API("/Take/GetAllTakeUnitsOfMeasure", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetAllCountTakeTypes() {
    const api = new API("/Take/GetAllCountTakeTypes", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async CreateTake(body) {
    const api = new API("/Take/CreateTake", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async EditTake(takeUniqueIdentifier, stateId, body) {
    const api = new API(`/Take/EditTake/${takeUniqueIdentifier}/${stateId}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async RemoveTake(takeUniqueIdentifier) {
    const api = new API(`/Take/RemoveTake/${takeUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post();
    return result;
  }

  static async GetTakesForWorkPerformed(body) {
    const api = new API("/Take/GetTakesForWorkPerformed", APIRoutes.WorkTask);
    const result = await api.put(body);
    return result;
  }

  static async GetAllBandingTypes() {
    const api = new API("/Take/GetAllBandingTypes", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async AddBandsToTake(takeUniqueIdentifier, body) {
    const api = new API(`/Take/AddBandsToTake/${takeUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async RemoveBandsFromTake(takeUniqueIdentifier, body) {
    const api = new API(`/Take/RemoveBandsFromTake/${takeUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async CreatePermit(body) {
    const api = new API("/Permit/CreatePermit", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async GetPermit(guid) {
    const api = new API("/Permit/GetPermit/" + guid, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async RetrievePDFForPermit(guid) {
    const api = new API("/Permit/RetrievePDFForPermit/" + guid, APIRoutes.WorkTask);
    const result = await api.get([{ optionName: "responseType", optionContent: "blob" }]);
    return result;
  }

  static async UpdatePermit(body, permitUniqueIdentifier) {
    const api = new API("/Permit/UpdatePermit/" + permitUniqueIdentifier, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async AttachPDFToPermit(body, permitUniqueIdentifier) {
    const api = new API("/Permit/AttachPDFToPermit/" + permitUniqueIdentifier, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async RemovePDFFromPermit(permitUniqueIdentifier, permitPDFUniqueIdentifier) {
    const api = new API(
      "/Permit/RemovePDFFromPermit/" + permitUniqueIdentifier + "/" + permitPDFUniqueIdentifier,
      APIRoutes.WorkTask
    );
    const result = await api.post();
    return result;
  }

  static async GetNotificationActionOptions() {
    const api = new API("/Permit/GetNotificationActionOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async SearchForAnyPermits(body) {
    const api = new API("/Permit/SearchForAnyPermits", APIRoutes.WorkTask);
    const result = await api.put(body);
    return result;
  }

  static async SearchForValidPermits(body) {
    const api = new API("/Permit/SearchForValidPermits", APIRoutes.WorkTask);
    const result = await api.put(body);
    return result;
  }

  static async CreateAdminWorkTask(body) {
    const api = new API("/AdminWorkTask/CreateAdminWorkTask", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async CreateDiseaseMonitoringEntry(body) {
    const api = new API("/DiseaseMonitoring/CreateDiseaseMonitoringEntry", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async GetLocTypeOptions() {
    const api = new API("/DiseaseMonitoring/GetLocTypeOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetYesNoOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetYesNoOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetYesNoNotRecordedOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetYesNoNotRecordedOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetYesNoNotTestedOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetYesNoNotTestedOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetYesNoNoCaptureOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetYesNoNoCaptureOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetGenderOptions() {
    const api = new API("/DiseaseMonitoring/GetGenderOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetSexOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetSexOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetAgeOptions() {
    const api = new API("/DiseaseMonitoring/GetAgeOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetRabiesAgeOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetAgeOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetCollectorOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetCollectorOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetCollectionActivityOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetCollectionActivityOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetCollectionMethodOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetCollectionMethodOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetOrvBaitTypeOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetOrvBaitTypeOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetFateOfTrapOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetFateOfTrapOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetDrugOrVaccineOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetDrugOrVaccineOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetOtherSampleOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetOtherSampleOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetAnimalFateOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetAnimalFateOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetEuthanizationReasonOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetEuthanizationReasonOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetErsCategoryOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetErsCategoryOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetLabsForBrainSampleOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetLabsForBrainSampleOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetSampleConditionOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetSampleConditionOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetRabiesVariantOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetRabiesVariantOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetLabsForToothOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetLabsForToothOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetSampleOptions() {
    const api = new API("/DiseaseMonitoring/GetSampleOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetLabsForBloodOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetLabsForBloodOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetLabShipmentOptions() {
    const api = new API("/DiseaseMonitoring/GetLabShipmentOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetLabResultsPosNegIndOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetLabResultsPosNegIndOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetLocationTypeOptions() {
    const api = new API("/OtherSample/GetLocationTypeOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetInterpretationOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetInterpretationOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetRabiesVNAInterpretationOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetRabiesVNAInterpretationOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetSampleTypeOptions() {
    const api = new API("/OtherSample/GetSampleTypeOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetdRitOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetdRitOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetdMISTargetOptions() {
    const api = new API("/NationalRabiesMonitoringProgram/GetMistargetEnumOptions", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async CreateOtherSample(body) {
    const api = new API("/OtherSample/CreateOtherSample", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async CreateNRMP(body) {
    const api = new API("/NationalRabiesMonitoringProgram/CreateNRMP", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async CreateLoss(body) {
    const api = new API("/Loss/CreateLoss", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async PVSStructuredSearch(body, signal) {
    let url = "/WorkTask/PVSStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async AerialWorkTaskStructuredSearch(body, signal) {
    let url = "/AerialWorkTask/AerialWorkTaskStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async HotlineTAStructuredSearch(body, signal) {
    let url = "/HotlineWorkTask/HotlineTAStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async OutreachTAStructuredSearch(body, signal) {
    let url = "/OutreachWorkTask/OutreachTAStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async AdminWorkTaskStructuredSearch(body, signal) {
    let url = "/AdminWorkTask/AdminWorkTaskStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async GetAdminWorkTasksOnPropertyVisitSummary(pvsUniqueIdentifier) {
    let url = "/AdminWorkTask/GetAdminWorkTasksOnPropertyVisitSummary/" + pvsUniqueIdentifier;
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.get();
    return result;
  }

  static async CooperatorTAStructuredSearch(body, signal) {
    let url = "/IndividualCooperatorWorkTask/CooperatorTAStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async PermitsStructuredSearch(body, signal) {
    let url = "/Permit/PermitsStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async OtherSampleStructuredSearch(body, signal) {
    let url = "/OtherSample/OtherSampleStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async DiseaseMonitoringStructuredSearch(body, signal) {
    let url = "/DiseaseMonitoring/DiseaseMonitoringStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async NRMPStructuredSearch(body, signal) {
    let url = "/NationalRabiesMonitoringProgram/NRMPStructuredSearch";
    let api = new API(url, APIRoutes.WorkTask);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async GetEmployeeItinerary(employeeWsUserProfileUniqueReference, monthNumber, year) {
    const api = new API(
      `/WorkTask/GetEmployeeItinerary/${employeeWsUserProfileUniqueReference}/${monthNumber}/${year}`,
      APIRoutes.WorkTask
    );
    const result = await api.get();
    return result;
  }

  static async GetEmployeeItinerariesForSupervisor(body, monthNumber, year) {
    const api = new API(`/WorkTask/GetEmployeeItinerariesForSupervisor/${monthNumber}/${year}`, APIRoutes.WorkTask);
    const result = await api.put(body);
    return result;
  }

  static async GetNRMP(nrmpUniqueIdentifier) {
    const api = new API(`/NationalRabiesMonitoringProgram/GetNRMP/${nrmpUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async EditNRMP(nrmpUniqueIdentifier, body) {
    const api = new API(`/NationalRabiesMonitoringProgram/EditNRMP/${nrmpUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async GetOtherSample(otherSampleUniqueIdentifier) {
    const api = new API(`/OtherSample/GetOtherSample/${otherSampleUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async EditOtherSample(otherSampleUniqueIdentifier, body) {
    const api = new API(`/OtherSample/UpdateOtherSample/${otherSampleUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async GetDiseaseMonitoringEntry(diseaseMonitoringUniqueIdentifier) {
    const api = new API(
      `/DiseaseMonitoring/GetDiseaseMonitoringEntry/${diseaseMonitoringUniqueIdentifier}`,
      APIRoutes.WorkTask
    );
    const result = await api.get();
    return result;
  }

  static async UpdateDiseaseMonitoringEntry(diseaseMonitoringUniqueIdentifier, body) {
    const api = new API(
      `/DiseaseMonitoring/UpdateDiseaseMonitoringEntry/${diseaseMonitoringUniqueIdentifier}`,
      APIRoutes.WorkTask
    );
    const result = await api.post(body);
    return result;
  }

  static async GetAerialWorkTask(aerialWorkTaskUniqueIdentifier) {
    const api = new API(`/AerialWorkTask/GetAerialWorkTask/${aerialWorkTaskUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetAerialWorkTaskActivityTypes() {
    const api = new API("/AerialWorkTask/GetAerialWorkTaskActivityTypes/", APIRoutes.WorkTask);
    const result = await api.get();
    return result;
  }

  static async GetAerialWorkTaskProperty(aerialPropertyUniqueIdentifer) {
    const api = new API(
      `/AerialWorkTask/GetAerialWorkTaskProperty/${aerialPropertyUniqueIdentifer}`,
      APIRoutes.WorkTask
    );
    const result = await api.get();
    return result;
  }

  static async CreateAerialWorkTask(body) {
    const api = new API("/AerialWorkTask/CreateAerialWorkTask", APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async AddWorkPerformedOnAerialProperty(aerialPropertyUniqueIdentifer, body) {
    const api = new API(
      `/AerialWorkTask/AddWorkPerformedOnAerialProperty/${aerialPropertyUniqueIdentifer}`,
      APIRoutes.WorkTask
    );
    const result = await api.post(body);
    return result;
  }

  static async AddPropertiesToAerialWorkTask(aerialUniqueIdentifer, body) {
    const api = new API(`/AerialWorkTask/AddPropertiesToAerialWorkTask/${aerialUniqueIdentifer}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }

  static async RemovePropertiesFromAerialWorkTask(aerialWorkTaskUniqueIdentifier, body) {
    const api = new API(
      `/AerialWorkTask/RemovePropertiesFromAerialWorkTask/${aerialWorkTaskUniqueIdentifier}`,
      APIRoutes.WorkTask
    );
    const result = await api.post(body);
    return result;
  }

  static async AddConflictsToAerialProperty(aerialPropertyUniqueIdentifer, body) {
    const api = new API(
      `/AerialWorkTask/AddConflictsToAerialProperty/${aerialPropertyUniqueIdentifer}`,
      APIRoutes.WorkTask
    );
    const result = await api.post(body);
    return result;
  }

  static async RemoveConflictsToAerialProperty(aerialPropertyUniqueIdentifer, body) {
    const api = new API(
      `/AerialWorkTask/RemoveConflictsToAerialProperty/${aerialPropertyUniqueIdentifer}`,
      APIRoutes.WorkTask
    );
    const result = await api.post(body);
    return result;
  }

  static async CompleteAerialWorkTask(aerialUniqueIdentifer) {
    const api = new API(`/AerialWorkTask/CompleteAerialWorkTask/${aerialUniqueIdentifer}`, APIRoutes.WorkTask);
    const result = await api.post();
    return result;
  }

  static async EditCrewOnAerialProperty(aerialPropertyUniqueIdentifer, body) {
    const api = new API(
      `/AerialWorkTask/EditCrewOnAerialProperty/${aerialPropertyUniqueIdentifer}`,
      APIRoutes.WorkTask
    );
    const result = await api.post(body);
    return result;
  }

  static async RemoveWorkPerformedOnAerialProperty(aerialWorkPerformedUniqueIdentifier) {
    const api = new API(
      `/AerialWorkTask/RemoveWorkPerformedOnAerialProperty/${aerialWorkPerformedUniqueIdentifier}`,
      APIRoutes.WorkTask
    );
    const result = await api.post();
    return result;
  }

  static async CancelAerialWorkTask(aerialWorkTaskUniqueIdentifier) {
    const api = new API(`/AerialWorkTask/CancelAerialWorkTask/${aerialWorkTaskUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post();
    return result;
  }

  static async EditAerialWorkTask(aerialWorkTaskUniqueIdentifier, body) {
    const api = new API(`/AerialWorkTask/EditAerialWorkTask/${aerialWorkTaskUniqueIdentifier}`, APIRoutes.WorkTask);
    const result = await api.post(body);
    return result;
  }
}

export default WorkTaskAPI;
