import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Image, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class CreateEditDiseaseModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: this.props?.modalData
        ? this.props.modalData
        : {
            diseaseUniqueIdentifier: "",
            name: "",
            isAllowedGlobally: true,
            isAllowed: true,
            createdBy: "",
            createdDateTimeUTC: "",
            lastModifiedBy: "",
            lastModifiedDateTimeUTC: "",
          },
      errors: {},
    };
  }

  async doSubmit(e) {
    let form = e.currentTarget;
    if (form.checkValidity()) {
      this.setState({ validated: true });
      if (this.state.modalMode === "Create") {
        this.createDisease();
      } else if (this.state.modalMode === "Edit") {
        this.editDisease();
      }
    }
  }

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  async createDisease() {
    try {
      if (this.state.data.name === "") {
        throw new Error("Disease Name must be present.");
      }
      let createBody = { name: this.state.data.name };
      let createDiseaseResult = await ReferenceFileAPI.CreateDisease(createBody);

      if (createDiseaseResult.successful) {
        this.props.showSuccessModal();
      } else {
        if (!createDiseaseResult.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to create disease record",
            apiName: "CreateDisease",
            responseUnsuccessful: createDiseaseResult?.unsuccessful,
            responseMessage: createDiseaseResult?.message,
          });
        }
      }
    } catch (error) {
      ErrorHandler.showError(error, error.message);
    }
    this.modalCleanUp();
  }

  async editDisease() {
    try {
      if (this.state.data.name === "") {
        throw new Error("Disease Name must be present");
      }

      let editBody = { name: this.state.data.name };
      let editDiseaseResult = await ReferenceFileAPI.UpdateDisease(this.state.data.diseaseUniqueIdentifier, editBody);
      let allowanceResult = await this.getAllowanceResult();

      if (this.isSuccessfulResult(editDiseaseResult) && this.isSuccessfulResult(allowanceResult)) {
        this.props.showSuccessModal();
      } else {
        if (!editDiseaseResult.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to update disease record",
            apiName: "UpdateDisease",
            responseUnsuccessful: editDiseaseResult?.unsuccessful,
            responseMessage: editDiseaseResult?.message,
          });
        }
        if (!allowanceResult.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to allow updated disease record",
            apiName:
              this.state.data.isAllowedGlobally === "Active" || this.state.data.isAllowedGlobally === true
                ? "AllowDiseaseGlobally"
                : "DisallowDiseaseGlobally",
            responseUnsuccessful: allowanceResult?.unsuccessful,
            responseMessage: allowanceResult?.message,
          });
        }
      }
    } catch (error) {
      ErrorHandler.showError(error, error.message);
    }
    this.modalCleanUp();
  }

  async getAllowanceResult() {
    let allowanceResult = null;
    if (this.state.data.isAllowedGlobally === "Active" || this.state.data.isAllowedGlobally === true) {
      allowanceResult = await ReferenceFileAPI.AllowDiseaseGlobally(this.state.data.diseaseUniqueIdentifier);
    } else if (this.state.data.isAllowedGlobally === "Inactive" || this.state.data.isAllowedGlobally === false) {
      allowanceResult = await ReferenceFileAPI.DisallowDiseaseGlobally(this.state.data.diseaseUniqueIdentifier);
    }
    return allowanceResult;
  }

  isSuccessfulResult(result) {
    return result.successful || result.message?.includes("already") ? true : false;
  }

  createDiseaseModalUI() {
    return (
      <div className={styles.createDiseaseModalBody}>
        <Row>
          <Col>{this.renderInput("name", "Disease Name", "text", "Enter name", "required")}</Col>
        </Row>
      </div>
    );
  }

  editDiseaseModalUI() {
    return (
      <div className={styles.editDiseaseModalBody}>
        <Row>
          <Col className={styles.editDiseaseModalGuid}>
            <label className={globalStyles.modalFormLabel}>
              ID
              <p id="editDiseaseModalGuid">{this.state.data.diseaseUniqueIdentifier}</p>
            </label>
          </Col>
        </Row>
        <Row>
          <Col className={styles.editDiseaseModalDiseaseName}>
            {this.renderInput("name", "Disease Name", "text", "Enter name", "required")}
          </Col>
        </Row>
        <Row>
          <Col className={styles.editDiseaseModalStatus}>
            {this.renderInlineCheckBoxSet(
              "isAllowedGlobally",
              "Status",
              ["Active", "Inactive"],
              "radio",
              this.state.data.isAllowedGlobally ? "Active" : "Inactive"
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editDiseaseModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editDiseaseModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createDiseaseModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editDiseaseModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Modal.Header className={globalStyles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} Disease</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyContent}</Modal.Body>
            <Modal.Footer className={globalStyles.modalFooter}>
              <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <Button type="submit" className={globalStyles.modalSubmitButton} disabled={this.state.data.name === ""}>
                <span className={globalStyles.modalSubmitButtonText}>{submitButtonText}</span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default CreateEditDiseaseModal;
