import React from "react";
import CustomForm from "../common/form";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import globalStyles from "../../OARS.module.scss";
import styles from "./UserRoles.module.scss";
import Select from "react-select";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import lsm from "../../api/lsm";

class UserRolesModal extends CustomForm {
  state = {
    data: {
      selectedRole: this.props.userProfile
        ? {
          value: this.props.userProfile?.roleUniqueReference,
          label: this.props.userProfile?.profileName,
        }
        : "",
      selectedState: this.props.userProfile ? this.props.userProfile?.profileAssociatedStates[0] : "",
      availableStates: [],
    },
    errors: {},
  };

  handleSpecificChanges(input) {
    //do nothing
  }

  handleRoleSelection = (input) => {
    const { data } = { ...this.state };
    data.selectedRole = input;
    this.setState({ data }, () => {
      let availableStates = this.props.user?.userProfiles?.find(
        (profile) => profile?.roleUniqueReference === input?.value
      )?.profileAssociatedStates;
      let stateList = availableStates
        .map((stateGuid) => {
          let stateData = this.props.usaStates?.find((usaState) => usaState?.id === stateGuid);
          return { value: stateData?.id ? stateData.id : "", label: stateData?.name ? stateData.name : "" };
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1));
      data.availableStates = stateList;
      this.setState({ data });
    });
  };

  handleStateSelection = (input) => {
    const { data } = { ...this.state };
    data.selectedState = input;
    this.setState({ data });
  };

  handleModalCancel = () => {
    const { data } = this.state;
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  clearData(data) {
    data.selectedRole = "";
    data.selectedState = "";
    data.availableStates = [];
  }

  async doSubmit() {
    const { data } = this.state;

    let userProfileUniqueIdentifier = this.props.user.userProfiles?.find(
      (profile) => profile.roleUniqueReference === data.selectedRole?.value
    )?.wsUserProfileUniqueIdentifier;
    const r = await RoleAuthorizationAPI.GetTokenWithUserContext(
      userProfileUniqueIdentifier,
      data.selectedState?.value
    );
    if (r?.successful) {
      lsm.SetUserAuthenticated(r.data?.token);
      localStorage.setItem(
        "currentState",
        JSON.stringify({
          stateGuid: data.selectedState?.value,
          stateName: data.selectedState?.label,
        })
      );
      localStorage.setItem(
        "currentUserRole",
        JSON.stringify({
          profileName: data.selectedRole?.label,
          roleUniqueReference: data.selectedRole?.value,
          userName: this.props.user.firstName + " " + this.props.user.lastName,
          wsUserProfileUniqueIdentifier: userProfileUniqueIdentifier,
          wsUserUniqueIdentifier: this.props.user?.wsUserUniqueIdentifier,
          mailingAddress: {
            mailingStreet: this.props.user?.streetAddress,
            mailingCity: this.props.user?.city,
            mailingZip: this.props.user?.zip,
            mailingStateUniqueId: this.props.user?.stateOfAddressUniqueReference,
          },
          phoneNumber: this.props.user?.primaryPhoneNumber,
        })
      );
      this.clearData(data);
      this.props.handleGoToHomepage();
      this.props.onHide();
      this.setState({ data });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set User profile",
        apiName: "SetUsersCurrentProfile",
        responseUnsuccessful: r?.unsuccessful,
        responseMessage: r?.message,
      });
    }
  }

  render() {
    const userProfiles = this.props.user?.userProfiles;
    const { data } = this.state;
    const selectedState = data.selectedState;
    const selectedRole = data.selectedRole;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.modalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <div>
            <Modal.Header className={styles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>User Profiles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <Row>
                  <Col>
                    <Form.Group controlId="selectRole">
                      <Form.Label className={styles.formLabel}>Select Your User Profile</Form.Label>
                      <Select
                        value={this.state.data.selectedRole}
                        placeholder="Select Profile"
                        aria-label="Select Profile Dropdown"
                        styles={{
                          indicatorSeparator: () => {
                            //do nothing
                          },
                          placeholder: (styles) => ({
                            ...styles,
                            fontSize: "14px",
                            fontStyle: "normal",
                          }),
                          option: (styles, { isFocused }) => ({
                            ...styles,
                            fontSize: "12px",
                            fontWeight: "normal",
                            color: "black",
                            backgroundColor: isFocused ? "lightgray" : "white",
                          }),
                          singleValue: (styles) => ({
                            ...styles,
                            fontSize: "14px",
                            fontWeight: "normal",
                            color: "black",
                          }),
                        }}
                        options={userProfiles?.map((role) => ({
                          value: role.roleUniqueReference,
                          label: role.profileName,
                        }))}
                        onChange={this.handleRoleSelection}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="selectRole">
                      <Form.Label className={styles.formLabel}>State</Form.Label>
                      <Select
                        value={this.state.data.selectedState}
                        aria-label="Select State Dropdown"
                        placeholder="Select State"
                        styles={{
                          indicatorSeparator: () => {
                            //do nothing
                          },
                          placeholder: (styles) => ({ ...styles, fontSize: "14px", fontStyle: "normal" }),
                          option: (styles, { isFocused }) => ({
                            ...styles,
                            fontSize: "12px",
                            fontWeight: "normal",
                            color: "black",
                            backgroundColor: isFocused ? "lightgray" : "white",
                          }),
                          singleValue: (styles) => ({
                            ...styles,
                            fontSize: "14px",
                            fontWeight: "normal",
                            color: "black",
                          }),
                        }}
                        options={data.availableStates}
                        onChange={this.handleStateSelection}
                        defaultValue={data.selectedState}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={this.handleModalCancel}>
                <span>Cancel</span>
              </Button>
              <Button className="ml-2" variant="primary" type="submit" disabled={!selectedRole || !selectedState}>
                <span className={globalStyles.modalSubmitButtonText}>Save</span>
              </Button>
            </Modal.Footer>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default UserRolesModal;
