import React from "react";
import styles from "./Contact.module.scss";
import { withRouter } from "react-router-dom";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import { toast } from "react-toastify";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import UtilityFunctions from "../../common/UtilityFunctions";

class DuplicateContactModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			history: this.props.history,
			contactData: {},
			contactProperties: this.props.contactProperties,
			contactOrganizations: this.props.contactOrganizations,
			phoneNumbers: this.props.data.phoneNumbers,
			mailingStreet: "",
			mailingCity: "",
			mailingState: "",
			mailingZip: "",
			mailingCountry: "",
		};
		this.displayPhoneNumbers = this.displayPhoneNumbers.bind(this);
		this.displayAddress = this.displayAddress.bind(this);
		this.setContactData = this.setContactData.bind(this);
		this.handleModalCreate = this.handleModalCreate.bind(this);
	}

	displayPhoneNumbers() {
		let returnArray = [];
		const nums = this.props.data.phoneNumbers;
		for (let i = 0; i < nums.length; i++) {
			returnArray.push(<p key={`phonenumber-key-${i}`}>{UtilityFunctions.formatPhoneNumber(nums[i].phoneNumber)}</p>);
		}
		return returnArray;
	}

	displayAddress() {
		const address = this.props.data.primaryMailingAddress;
		const states = this.props.usaStates;
		const countries = this.props.countries;
		if (!address) return null;
		return (
			<div>
				<Row>
					<Col>
						<h6 className={styles.fieldLabels}>Street Address or PO Box</h6>
						<p>{address.mailingStreet}</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<h6 className={styles.fieldLabels}>City</h6>
						<p>{address.mailingCity}</p>
					</Col>
					<Col>
						<h6 className={styles.fieldLabels}>State</h6>
						<p>{states.find((state) => state.id === address.mailingStateUniqueId).name}</p>
					</Col>
					<Col>
						<h6 className={styles.fieldLabels}>Country</h6>
						<p>{countries.find((country) => country.id === address.mailingCountryUniqueId).name}</p>
					</Col>
					<Col>
						<h6 className={styles.fieldLabels}>ZIP Code</h6>
						<p>{address.mailingZip}</p>
					</Col>
				</Row>
			</div>
		);
	}

	setContactData() {
		this.setState(
			{
				contactData: this.props.createData,
				contactProperties: this.props.contactProperties,
				contactOrganizations: this.props.contactOrganizations,
			},
			() => this.handleModalCreate()
		);
	}

	async handleModalCreate() {
		if (this.state.contactData) {
			let createAPIResult = await AgreementEntitiesAPI.CreateContact(this.state.contactData);
			if (createAPIResult) {
				if (createAPIResult.successful) {
					let contactGuid = createAPIResult.data.contactUniqueId;
					let addContactsToPropertiesStatus = await this.addContactsToPropertiesCall(contactGuid);
					let addContactsToOrgsStatus = await this.addContactsToOrgsCall(contactGuid);

					if (addContactsToPropertiesStatus && addContactsToOrgsStatus) {
						toast.success("Contact creation successful.");
					} else if (!addContactsToPropertiesStatus) {
						toast.warning("Contact creation successful, property association error.");
					} else if (!addContactsToOrgsStatus) {
						toast.warning("Contact creation successful, Special Group association error.");
					}
					this.props.history.push("/home");
				}
			} else {
				ErrorHandler.showError("Contact creation failed.");
			}
		}
	}

	async addContactsToPropertiesCall(contactGuid) {
		let propertyAssociationSuccess = true;
		for (var i = 0; i < this.state.contactProperties.length; i++) {
			let currentProperty = this.state.contactProperties[i];
			let associationAPIResult = await AgreementEntitiesAPI.AddContactToProperty(
				currentProperty.value,
				contactGuid,
				currentProperty.roleType
			);
			if (associationAPIResult) {
				if (associationAPIResult.unsuccessful) {
					propertyAssociationSuccess = false;
				}
			} else {
				propertyAssociationSuccess = false;
			}
		}
		return propertyAssociationSuccess;
	}

	async addContactsToOrgsCall(contactGuid) {
		let orgsAssociationSuccess = true;
		for (var i = 0; i < this.state.contactOrganizations.length; i++) {
			let currentOrg = this.state.contactOrganizations[i];
			let associationAPIResult = await AgreementEntitiesAPI.AssignOrganizationPointOfContact(
				currentOrg.value,
				contactGuid
			);
			if (associationAPIResult) {
				if (associationAPIResult.unsuccessful) {
					orgsAssociationSuccess = false;
				}
			} else {
				orgsAssociationSuccess = false;
			}
		}
		return orgsAssociationSuccess;
	}

	setEditRoute = () => {
		this.setState({ contactData: this.props.data }, () => this.handleModalUpdate());
	};

	handleModalUpdate = () => {
		if (this.state.contactData) {
			console.log(this.state.contactData);
			this.props.history.push({
				pathname: "/contact/edit",
				state: { entityData: this.state.contactData },
			});
		}
	};

	render() {
		if (!this.props.data) {
			return null;
		}
		return (
			<Modal
				show={this.props.show}
				onHide={this.props.onHide}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<div className="d-flex justify-content-center">
					<i className="fa fa-exclamation-circle fa-5x" style={{ color: "gray" }} aria-hidden="true"></i>
				</div>
				<h4 style={{ textAlign: "center" }}>Warning!</h4>
				<Modal.Body>
					<div className={styles.modalCenteredText}>
						<p>
							It looks like the record you're creating might already exist in the system. You will have the option to
							create a new record based on the data entered, or to edit the currently exisiting record we detected.
							Please review the information and submit accordingly.
						</p>
					</div>
					<hr />
					<h5 className={styles.modalHeaders}>Personal Information</h5>
					<Row>
						<Col>
							<h6 className={styles.fieldLabels}>First Name</h6>
							<p>{this.props.createData.firstName}</p>
						</Col>
						<Col>
							<h6 className={styles.fieldLabels}>Middle Name</h6>
							<p>{this.props.createData.middleName}</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<h6 className={styles.fieldLabels}>Last Name</h6>
							<p>{this.props.createData.lastName}</p>
						</Col>
						<Col>
							<h6 className={styles.fieldLabels}>Suffix</h6>
							<p>{this.props.createData.suffix}</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<h6 className={styles.fieldLabels}>Preferred Name</h6>
							<p>{this.props.createData.preferredName}</p>
						</Col>
					</Row>
					<hr />
					<h5 className={styles.modalHeaders}>Additional Information</h5>
					<Row>
						<Col>
							<h6 className={styles.fieldLabels}>Phone Number(s)</h6>
							<this.displayPhoneNumbers />
						</Col>
						<Col>
							<h6 className={styles.fieldLabels}>Email Address</h6>
							<p>{this.props.data.emailAddress}</p>
						</Col>
					</Row>
					<hr />
					<h5 className={styles.modalHeaders}>Street Information</h5>
					<this.displayAddress />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.props.onHide}>
						Cancel
					</Button>
					<Button variant="primary" onClick={this.setEditRoute}>
						Update
					</Button>
					<Button variant="primary" onClick={this.setContactData}>
						Create
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
export default withRouter(DuplicateContactModal);
