import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Card, Form, Row, Col, Button, Image } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter, customFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import { toast } from "react-toastify";
import CustomForm from "../common/form";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import globalStyles from "../../OARS.module.scss";
import styles from "./Projects.module.scss";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import projectIcon from "../../assets/projectIcon.svg";
import AddProjectUsersModal from "./AddProjectUsersModal";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import CustomTextFilter from "../common/customTextFilter";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ProjectDetailsPage extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        projectName: "",
        projectStates: [],
        startDate: "",
        endDate: "",
        createdDate: "",
        createdBy: "",
        parentProjects: [],
        projectUniqueIdentifier: "",
      },
      assignedUsersTableColumns: [
        { dataField: "wsUserProfileUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],

      userTableRowsSelected: [],
      projectData: "",
      availableStates: [],
      availableParentProjects: [],
      isFormLoading: true,
      isEditMode: false,
      showSuccessfulUpdateModal: false,
      showAddUsersModal: false,
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
    }

    const projectData = await this.getProjectData();
    const data = projectData ? this.updatePageData(projectData) : { ...this.state.data };

    this.setState({
      currentUsaState,
      data,
      projectData,
      availableParentProjects: await this.getAvailableParentProjects(),
      availableStates: this.getAvailableStates(),
      assignedUsersTableColumns: this.getUserProfilesTableColumns(),
      isFormLoading: false,
    });
  }

  getAvailableStates() {
    const states = this.props.history.location.state?.availableStates
      ? this.props.history.location.state?.availableStates
      : this.props.history.location.state?.states;
    return states.map((s) => ({
      value: s.stateUniqueIdentifier,
      label: s.state_name,
      code: s.state_code,
    }));
  }

  async getProjectData() {
    let projectData = "";
    if (this.props.history.location.state?.isProjectCreation) {
      projectData = this.props.history.location.state.entityData;
    } else {
      const entityData = this.props.history.location.state?.entityData;
      if (entityData?.projectUniqueIdentifier) {
        projectData = await this.getProjectDataFromAPI(entityData.projectUniqueIdentifier);
      }
    }
    return projectData;
  }

  updatePageData(projectData) {
    const data = { ...this.state.data };

    if (projectData) {
      data.projectUniqueIdentifier = projectData.projectUniqueIdentifier;
      data.projectName = projectData.projectName;
      data.projectStates =
        projectData.states?.length > 0
          ? projectData.states.map((s) => ({ value: s.stateUniqueReference, label: s.stateName }))
          : [];
      data.startDate = projectData.startDate
        ? new Date(projectData.startDate).toLocaleDateString("en-CA", { timeZone: "UTC" })
        : "";
      data.endDate = projectData.endDate
        ? new Date(projectData.endDate).toLocaleDateString("en-CA", { timeZone: "UTC" })
        : "";
      data.createdDate = projectData.createdDateTimeUTC
        ? new Date(projectData.createdDateTimeUTC).toLocaleDateString("en-CA", { timeZone: "UTC" })
        : "";
      data.createdBy = projectData.createdBy;
      data.parentProjects =
        projectData.parentProjects?.length > 0
          ? projectData.parentProjects.map((p) => ({ value: p.projectUniqueIdentifier, label: p.projectName }))
          : [];
    }

    return data;
  }

  async getProjectDataFromAPI(projectId) {
    let projectData = "";
    const getProjectsResponse = await AgreementEntitiesAPI.GetProject(projectId);

    if (getProjectsResponse?.successful) {
      projectData = getProjectsResponse.data;

      await this.updateProfilesDataInProject(projectData);
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to load project data",
        apiName: "GetProject",
        responseUnsuccessful: getProjectsResponse?.unsuccessful,
        responseMessage: getProjectsResponse?.message,
      });
    }

    return projectData;
  }

  async updateProfilesDataInProject(projectData) {
    if (projectData.userProfiles?.length > 0) {
      const resolveProfilesResponse = await RoleAuthorizationAPI.ResolveUserProfilesWithDistrictAssignments(
        projectData.userProfiles.map((p) => p.userProfileUniqueReference)
      );
      if (resolveProfilesResponse?.successful && resolveProfilesResponse.data?.length > 0) {
        projectData.userProfiles = resolveProfilesResponse.data;
      }
    }
  }

  async getAvailableParentProjects() {
    let availableProjects = [];
    const projectsData = await this.getAllProjectsDataFromAPI();
    if (projectsData?.length > 0) {
      availableProjects = projectsData.map((p) => ({
        value: p.projectUniqueIdentifier,
        label: p.projectName,
      }));
    }

    return availableProjects;
  }

  async getAllProjectsDataFromAPI() {
    let projectsData = [];

    const projectsDataResponse = await AgreementEntitiesAPI.GetAllProjects();
    if (projectsDataResponse?.successful && projectsDataResponse.data?.results?.length > 0) {
      projectsData = projectsDataResponse.data.results;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to load all projects",
        apiName: "GetAllProjects",
        responseUnsuccessful: projectsDataResponse?.unsuccessful,
        responseMessage: projectsDataResponse?.message,
      });
    }

    return projectsData;
  }

  getUserProfilesTableColumns() {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
      paddingRight: "1.5rem",
    };
    return [
      {
        dataField: "wsUserProfileUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "userName",
        text: "User Name",
        filter: textFilter({
          onFilter: this.filterUserNameColumn,
        }),
        formatter: (cell, row) => {
          return (
            <span>
              <Button variant="link" onClick={() => this.handleUserDetailsNavigation(row)}>
                <span className={globalStyles.formDataLinks}>{`${row.firstName} ${row.lastName}`}</span>
              </Button>
            </span>
          );
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px", color: "#0071BC" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "profileName",
        text: "User Profile",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Profile Name Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "profileAssociatedStates",
        text: "States",
        filter: textFilter({
          onFilter: this.filterProfileStates,
        }),
        formatter: (cell, row) => {
          let stateNamesArray =
            cell?.length > 0
              ? cell.map((stateId) => {
                  return this.state.availableStates?.find((s) => s.value === stateId)?.code;
                })
              : "";

          let stateNames = "";
          if (stateNamesArray?.length > 0) {
            stateNames = stateNamesArray.join(", ");
          }
          if (stateNames?.length > 20) {
            stateNames = stateNames.substring(0, 19) + "...";
          }

          return stateNames;
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "districts",
        text: "Districts",
        filter: textFilter({
          onFilter: this.filterProfileDistricts,
        }),
        formatter: (cell, row) => {
          let districtNames = cell?.length > 0 ? cell.map((district) => district.districtName)?.join(", ") : "";
          if (districtNames?.length > 30) {
            districtNames = districtNames.substring(0, 29) + "...";
          }

          return districtNames;
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
    ];
  }

  filterUserNameColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (entry) =>
          entry.firstName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          entry.lastName?.toLowerCase().includes(filterValue?.toLowerCase())
      );
    }
  };

  filterProfileStates = (filterValue, data) => {
    if (filterValue) {
      const filteredStateIds = this.state.availableStates
        ?.filter(
          (state) =>
            state.code?.toLowerCase().includes(filterValue?.toLowerCase()) ||
            state.label?.toLowerCase().includes(filterValue?.toLowerCase())
        )
        ?.map((s) => s.value);
      return data.filter((entry) => filteredStateIds.some((id) => entry.profileAssociatedStates.includes(id)));
    }
  };

  filterProfileDistricts = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) =>
        entry.districts?.some((d) => d.districtName?.toLowerCase().includes(filterValue?.toLowerCase()))
      );
    }
  };

  handleSpecificChanges = () => {
    //do something
  };

  getEditProjectBody = () => {
    const { data } = this.state;
    let stateList = [];
    data.projectStates.forEach((p) => {
      stateList.push(p.value);
    });
    // projectUniqueIdentifier
    let projectList = [];
    data.parentProjects.forEach((p) => {
      projectList.push(p.value);
    });
    let editProject = {
      projectName: data.projectName,
      startDate: data.startDate,
      endDate: data.endDate,
      parentProjectUniqueReferences: projectList,
      stateUniqueReferences: stateList,
    };

    return editProject;
  };

  handleEditProject = async () => {
    const { data } = this.state;
    if (!this.state.isEditMode) {
      this.setState({ isEditMode: true });
    } else {
      const editProjectResponse = await AgreementEntitiesAPI.UpdateProject(
        this.getEditProjectBody(),
        data.projectUniqueIdentifier
      );
      if (editProjectResponse?.successful) {
        toast.success("Project Edited Successfully");
      }

      this.setState({ showSuccessfulUpdateModal: true, isEditMode: false });
    }
  };

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    delete errors[fieldName];
    this.setState({ data, errors });
  };

  handleAddUser = () => {
    this.setState({ showAddUsersModal: true });
  };

  handleUserDetailsNavigation = (row) => {
    if (this.props.permissions.canIViewGlobalUserRecord()) {
      //Navigation to User Details Page (Pending to confirm requirement)
    } else {
      toast.warning("User does not have permission to view record details.");
    }
  };

  handleUsersRowOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        userTableRowsSelected: [...this.state.userTableRowsSelected, row?.wsUserProfileUniqueIdentifier],
      });
    } else {
      this.setState({
        userTableRowsSelected: this.state.userTableRowsSelected.filter((x) => x !== row?.wsUserProfileUniqueIdentifier),
      });
    }
  };

  handleOnSelectAllUsers = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ userTableRowsSelected: rows.map((r) => r.wsUserProfileUniqueIdentifier) });
    } else {
      this.setState({ userTableRowsSelected: [] });
    }
  };

  handleProjectSelection = async (project) => {
    this.setState({ isFormLoading: true });
    const projectData = await this.getProjectDataFromAPI(project.value || project.projectUniqueIdentifier);
    const data = projectData ? this.updatePageData(projectData) : { ...this.state.data };

    this.setState({
      data,
      projectData,
      isFormLoading: false,
    });
  };

  handleUsersRemove = async () => {
    const { projectData: originalProjectData, userTableRowsSelected, currentUsaState } = this.state;
    if (userTableRowsSelected.length > 0) {
      const userProfilesIds = originalProjectData.userProfiles
        ?.filter(
          (profile) =>
            userTableRowsSelected.includes(profile.wsUserProfileUniqueIdentifier) &&
            profile.profileAssociatedStates.includes(currentUsaState?.stateGuid)
        )
        ?.map((p) => p.wsUserProfileUniqueIdentifier);
      if (userProfilesIds?.length > 0 && originalProjectData.projectUniqueIdentifier) {
        const removeUsersResponse = await AgreementEntitiesAPI.RemoveUserProfilesFromProject(
          originalProjectData.projectUniqueIdentifier,
          {
            userProfileUniqueReferences: userProfilesIds,
          }
        );
        if (removeUsersResponse?.successful) {
          toast.success("Users were successfully removed from the project.");
          const projectData = removeUsersResponse.data;
          await this.updateProfilesDataInProject(projectData);
          const data = projectData ? this.updatePageData(projectData) : { ...this.state.data };
          this.setState({ data, projectData, userTableRowsSelected: [] });
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to remove all the new users from the project",
            apiName: "RemoveUserProfilesFromProject",
            responseUnsuccessful: removeUsersResponse?.unsuccessful,
            responseMessage: removeUsersResponse?.message,
          });
        }
      }
    } else {
      toast.warning("Please select the rows you want to remove.");
    }
  };

  addUsersSelection = async (usersData) => {
    const { projectData: originalProjectData } = this.state;
    const userProfilesIds = usersData
      .map((user) => user.wsUserProfileUniqueIdentifier)
      ?.filter((id) => !originalProjectData.userProfiles?.some((p) => p.wsUserProfileUniqueIdentifier === id));
    if (userProfilesIds?.length > 0 && originalProjectData.projectUniqueIdentifier) {
      const addUsersResponse = await AgreementEntitiesAPI.AddUserProfilesToProject(
        originalProjectData.projectUniqueIdentifier,
        {
          userProfileUniqueReferences: userProfilesIds,
        }
      );
      if (addUsersResponse?.successful) {
        toast.success("Users were successfully added to the project.");
        const projectData = addUsersResponse.data;
        await this.updateProfilesDataInProject(projectData);
        const data = projectData ? this.updatePageData(projectData) : { ...this.state.data };
        this.setState({ data, projectData });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to remove all the new users from the project",
          apiName: "AddUserProfilesToProject",
          responseUnsuccessful: addUsersResponse?.unsuccessful,
          responseMessage: addUsersResponse?.message,
        });
      }
    }
  };

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "solid 0.5px #D8DDE6",
      };
    },
  };

  renderPageHeader() {
    return (
      <Row className="mt-4">
        <Col className="pl-0">
          <h1 className={globalStyles.pageTitle}>Project Record Page</h1>
        </Col>
        <Col className="text-right pr-0" hidden={!this.props.permissions.canIEditProjectsAnyStates()}>
          <Button variant="primary" onClick={this.handleEditProject}>
            <span className={globalStyles.modalSubmitButtonText}>
              {this.state.isEditMode ? "Apply Changes" : "Edit"}
            </span>
          </Button>
        </Col>
      </Row>
    );
  }

  renderProjectHierarchyCard() {
    return (
      <Card className={styles.projectHierarchyCard}>
        <Card.Header className={globalStyles.cardTitles}>
          <Image src={projectIcon} alt="Project Icon" className="mr-2" />
          <span className={globalStyles.cardTitleText}>Project Hierarchy</span>
        </Card.Header>
        <Card.Body>
          <Row className="text-left mt-3">
            <Col>{this.state.isEditMode ? this.renderParentsSelection() : this.renderProjectHierarchyTree()}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderParentsSelection() {
    return (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Parent Projects</Form.Label>
        <Select
          placeholder="Select"
          aria-label="Parent Projects Select"
          value={this.state.data.parentProjects}
          styles={this.customSelectStyles}
          isMulti
          options={this.state.availableParentProjects}
          onChange={(e) => this.handleSelectChanges(e, "parentProjects")}
        />
      </Form.Group>
    );
  }

  renderProjectHierarchyTree() {
    const { projectData, data } = this.state;
    return (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Project Hierarchy</Form.Label>
        {data.parentProjects?.length > 0 &&
          data.parentProjects.map((parent, index) => (
            <Row key={index} className="mx-0">
              <Col className="px-0">
                <Button variant="link" className="px-0 py-0" onClick={() => this.handleProjectSelection(parent)}>
                  <span className={globalStyles.formDataLinks}>{parent?.label}</span>
                </Button>
              </Col>
            </Row>
          ))}
        <Row className="mx-0">
          {data.parentProjects?.length > 0 && <Col sm={1} className={styles.treeIndentation}></Col>}
          <Col className="pl-0">
            <span className={globalStyles.formLabel}>{projectData?.projectName}</span>
          </Col>
        </Row>
        {projectData?.childProjects?.length > 0 &&
          projectData?.childProjects.map((child, index) => (
            <Row key={index} className="mx-0">
              <Col sm={data.parentProjects?.length > 0 ? 2 : 1} className={styles.treeIndentation}></Col>
              <Col className="px-0">
                <Button variant="link" className="px-0 py-0" onClick={() => this.handleProjectSelection(child)}>
                  <span className={globalStyles.formDataLinks}>{child?.projectName}</span>
                </Button>
              </Col>
            </Row>
          ))}
      </Form.Group>
    );
  }

  renderMainInfoCard() {
    const { isEditMode, data } = this.state;
    return (
      <Card>
        <Card.Header className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>Main Information</span>
        </Card.Header>
        <Card.Body>
          <Row className="text-left mt-3">
            <Col md={3}>
              {this.renderEditableField(
                isEditMode,
                "projectName",
                "Project Name",
                data.projectName,
                "text",
                [],
                "required"
              )}
            </Col>
          </Row>
          <Row className="text-left mt-3">
            <Col>{this.renderStatesField()}</Col>
            <Col>
              {this.renderEditableField(isEditMode, "startDate", "Start Date", data.startDate, "date", [], "required")}
            </Col>
            <Col>
              {this.renderEditableField(isEditMode, "endDate", "End Date", data.endDate, "date", [], "required")}
            </Col>
            <Col>
              {this.renderEditableField(
                isEditMode,
                "createdDate",
                "Created Date",
                data.createdDate,
                "date",
                [],
                "readOnly"
              )}
            </Col>
            <Col>
              {this.renderEditableField(isEditMode, "createdBy", "Created By", data.createdBy, "text", [], "readOnly")}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderUsersAssignedCard() {
    const areDataRowsSelected = this.state.userTableRowsSelected?.length > 0;
    return (
      <Card className={styles.usersCard}>
        <Card.Header className={globalStyles.cardTitles}>
          <Row>
            <Col>
              <span className={globalStyles.cardTitleText}>Users Assigned to Project</span>
            </Col>
            <Col className="text-right">
              {(this.props.permissions.canIAssignUserProjectsAnyStates() ||
                this.props.permissions.canIAssignUserProjectsOnAssignedStates()) && (
                <Button variant="link" className="px-0" onClick={this.handleAddUser}>
                  <span className={globalStyles.formDataLinks}>+ Add User</span>
                </Button>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {this.state.projectData?.userProfiles?.length > 0 && (
            <Row className="text-right">
              <Col className="pr-0">
                <Button
                  className={areDataRowsSelected ? "" : styles.greyedOutRemoveButton}
                  onClick={this.handleUsersRemove}
                >
                  <span className={areDataRowsSelected ? "" : styles.greyedOutButtonText}>Remove</span>
                </Button>
              </Col>
            </Row>
          )}
          <Row className="text-left mt-3">
            <Col>{this.renderUsersTable()}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderUsersTable() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.userTableRowsSelected,
      onSelect: this.handleUsersRowOnSelect,
      onSelectAll: this.handleOnSelectAllUsers,
      style: { backgroundColor: "#f5f5f5" },
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "1px solid #e8e8e8",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #dee2e6",
      },
    };

    const usersData = this.state.projectData?.userProfiles?.length > 0 ? this.state.projectData.userProfiles : [];

    return usersData?.length > 0 ? (
      <ToolkitProvider
        keyField="wsUserProfileUniqueIdentifier"
        data={usersData}
        columns={this.state.assignedUsersTableColumns}
        bootstrap4={true}
        hover={true}
      >
        {(props) => (
          <div>
            <BootstrapTable
              keyField="wsUserProfileUniqueIdentifier"
              filter={filterFactory()}
              bordered={false}
              condensed
              selectRow={selectRow}
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    ) : (
      <div className="text-center">
        <span className={globalStyles.formLabel}>Users data is not available.</span>
      </div>
    );
  }

  renderStatesField() {
    const { data, isEditMode } = this.state;

    let stateNames = data.projectStates
      ?.map((projectState) => {
        const associatedUsaState = this.state.availableStates?.find((s) => s.value === projectState.value);
        return associatedUsaState?.code || "";
      })
      ?.join(", ");
    if (stateNames?.length > 20) {
      stateNames = stateNames.substring(0, 19) + "...";
    }
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>
          State(s)<span className={globalStyles.asterisk508}> *</span>
        </Form.Label>
        <Select
          placeholder="Select"
          aria-label="States Select"
          value={data.projectStates}
          styles={this.customSelectStyles}
          isMulti
          options={this.state.availableStates}
          onChange={(e) => this.handleSelectChanges(e, "projectStates")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("projectStates", "State(s)", stateNames)
    );
  }

  render() {
    const existingUsersIds =
      this.state.projectData?.userProfiles?.length > 0
        ? this.state.projectData.userProfiles.map((u) => u.wsUserProfileUniqueIdentifier)
        : [];
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading Project Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.projectRecordPage}>
          <Form onSubmit={this.handleSubmit}>
            {this.renderPageHeader()}
            <Row className="mt-3">
              <Col md={4} className="pl-0">
                {this.renderProjectHierarchyCard()}
              </Col>
              <Col className="pr-0">
                {this.renderMainInfoCard()}
                {this.renderUsersAssignedCard()}
              </Col>
            </Row>
          </Form>
        </div>
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfulUpdateModal}
          onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
          routeData=""
          messageType="update"
          objectName="Project"
        />
        <AddProjectUsersModal
          show={this.state.showAddUsersModal}
          onHide={() => {
            this.setState({ showAddUsersModal: false });
          }}
          availableStates={this.state.availableStates}
          onAddUsers={this.addUsersSelection}
          existingUserIds={existingUsersIds}
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(ProjectDetailsPage);
