import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Card, Form, Row, Col, Button, Image, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter, customFilter } from "react-bootstrap-table2-filter";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import CustomForm from "../common/form";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import globalStyles from "../../OARS.module.scss";
import styles from "./Warehouse.module.scss";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import CustomTextFilter from "../common/customTextFilter";
import InventoryAPI from "../../api/Inventory/InventoryAPI";
import UtilityFunctions from "../common/UtilityFunctions";
import warehouseIcon from "../../assets/projectIcon.svg";
import searchIcon from "../../assets/search.svg";
import AddWarehouseUsersModal from "./AddWarehouseUsersModal";
import AddWarehouseMethodsModal from "./AddWarehouseMethodsModal";
import TransferInventoryModal from "./TransferInventoryModal";
import MixInventoryModal from "./MixInventoryModal";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ViewTransactionHistoryModal from "./ViewTransactionHistoryModal";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const WarehouseTypes = {
  State: 1,
  Property: 2,
  Cooperator: 3,
  Employee: 4,
};

const WarehouseTransactionTypes = {
  IncomingTransfer: 1,
  OutgoingTransfer: 2,
  Receive: 3,
  Loss: 5,
  Mixing: 7,
  Reconciliation: 8,
  Spent: 9,
};

class WarehouseDetailsPage extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        warehouseName: "",
        warehouseType: "",
        warehouseManager: "",
        streetAddress: "",
        city: "",
        warehouseState: "",
        zipCode: "",
        isActive: "",
        description: "",
        status: "",
      },
      assignedUsersTableColumns: [
        { dataField: "wsUserProfileUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      inventoryTableColumns: [
        { dataField: "warehouseInventoryUniqueIdentifer", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      userTableRowsSelected: [],
      warehouseTypes: [],
      warehouseData: "",
      availableStates: [],
      isFormLoading: true,
      isEditMode: false,
      showSuccessfulUpdateModal: false,
      showAddUsersModal: false,
      showAddMethodsModal: false,
      showTransferInventoryModal: false,
      showMixInventoryModal: false,
      showTransactionHistoryModal: false,
      transferInventoryModalData: "",
      transactionType: "",
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
    }
    const currentUserRole = JSON.parse(localStorage.getItem("currentUserRole"));
    if (!currentUserRole?.wsUserProfileUniqueIdentifier) {
      toast.error("Failed to load User Profile data. Please go back to the home page and navigate to this page again.");
    }

    const warehouseData = await this.getWarehouseData();
    const data = warehouseData ? this.updatePageData(warehouseData) : { ...this.state.data };

    this.setState({
      currentUsaState,
      currentUserRole,
      data,
      warehouseData,
      warehouseTypes: await this.getAvailableWarehouseTypes(),
      availableStates: this.getAvailableStates(),
      assignedUsersTableColumns: this.getUserProfilesTableColumns(),
      inventoryTableColumns: this.getInventoryTableColumns(),
      isFormLoading: false,
    });
  }

  async getAvailableWarehouseTypes() {
    let availableWarehouseTypes = [];
    const warehouseTypesResponse = await InventoryAPI.GetAllWarehouseTypes();
    if (warehouseTypesResponse?.successful && warehouseTypesResponse.data?.length > 0) {
      availableWarehouseTypes = warehouseTypesResponse.data.map((wh) => ({
        id: wh.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(wh),
      }));
    }
    return availableWarehouseTypes;
  }

  getAvailableStates() {
    return this.props.history.location.state?.isWarehouseCreation
      ? this.props.history.location.state?.availableStates?.map((s) => ({ id: s.value, name: s.label, code: s.code }))
      : this.props.history.location.state?.states?.map((s) => ({
        id: s.stateUniqueIdentifier,
        name: s.state_name,
        code: s.state_code,
      }));
  }

  async getWarehouseData() {
    let warehouseData = "";
    if (this.props.history.location.state?.isWarehouseCreation) {
      warehouseData = this.props.history.location.state.entityData;
    } else {
      const entityData = this.props.history.location.state?.entityData;
      if (entityData?.warehouseUniqueIdentifier) {
        warehouseData = await this.getWarehouseDataFromAPI(entityData.warehouseUniqueIdentifier);
      }
    }
    if (warehouseData) {
      await this.updateMembersDataInWarehouse(warehouseData);
      await this.updateInventoryDataInWarehouse(warehouseData);
    }
    return warehouseData;
  }

  updatePageData(warehouseData) {
    const data = { ...this.state.data };
    if (warehouseData) {
      data.warehouseName = warehouseData.warehouseName;
      data.warehouseType = warehouseData.warehouseType?.id;
      data.warehouseManager = {
        value: warehouseData.warehouseManagerWSUserProfileUniqueReference,
        label: warehouseData.warehouseManagerName,
      };
      data.streetAddress = warehouseData.warehouseStreetAddress;
      data.city = warehouseData.warehouseCity;
      data.warehouseState = warehouseData.warehouseStateUniqueReference;
      data.zipCode = warehouseData.warehouseZipcode;
      data.description = warehouseData.description;
      data.isActive = warehouseData.isActive;
      data.status = warehouseData.isActive ? "Active" : "Inactive";
    }

    return data;
  }

  async getWarehouseDataFromAPI(warehouseId) {
    let warehouseData = "";
    const getWarehouseResponse = await InventoryAPI.GetWarehouse(warehouseId);
    if (getWarehouseResponse?.successful) {
      warehouseData = getWarehouseResponse.data;
    } else {
      ErrorHandler.showErrorWithDetails(getWarehouseResponse, "Error getting warehouse data from API.");
    }
    return warehouseData;
  }

  async updateMembersDataInWarehouse(warehouseData) {
    if (warehouseData.warehouseMembers?.length > 0) {
      const resolveProfilesResponse = await RoleAuthorizationAPI.ResolveUserProfilesWithDistrictAssignments(
        warehouseData.warehouseMembers.map((m) => m.warehouseMemberWSUserProfileUniqueReference)
      );
      if (resolveProfilesResponse?.successful && resolveProfilesResponse.data?.length > 0) {
        warehouseData.warehouseMembers = resolveProfilesResponse.data;
      }
    }
  }

  async updateInventoryDataInWarehouse(warehouseData) {
    if (warehouseData.warehouseInventory?.length > 0) {
      const allCMITSMethodsResponse = await ReferenceFileAPI.GetAllCMITSMethods();
      if (allCMITSMethodsResponse?.successful && allCMITSMethodsResponse.data?.results?.length > 0) {
        warehouseData.warehouseInventory.forEach((inventoryMethod) => {
          const generalMethodData = allCMITSMethodsResponse.data.results.find(
            (m) => m.methodUniqueIdentifier === inventoryMethod.methodUniqueReference
          );
          if (generalMethodData) {
            inventoryMethod.isCMITSMethod = generalMethodData.isCMITSMethod;
            inventoryMethod.isMixingMethod = generalMethodData.isMixingMethod;
            inventoryMethod.isSerialNumberRequired = generalMethodData.isSerialNumberRequired;
            inventoryMethod.onlyTransferToPersonalWarehouse = generalMethodData.onlyTransferToPersonalWarehouse;
          }
        });
      }
    }
  }

  removeMethodInventory = (method) => {
    // Pending Implementation
  };

  getUserProfilesTableColumns() {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
      paddingRight: "1.5rem",
    };
    return [
      {
        dataField: "wsUserProfileUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "userName",
        text: "User Name",
        filter: textFilter({
          onFilter: this.filterUserNameColumn,
        }),
        formatter: (cell, row) => {
          return (
            <span>
              <Button variant="link" onClick={() => this.handleUserDetailsNavigation(row)}>
                <span className={globalStyles.formDataLinks}>{`${row.firstName} ${row.lastName}`}</span>
              </Button>
            </span>
          );
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px", color: "#0071BC" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "userPrincipleName",
        text: "Email",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Email Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "profileName",
        text: "Profile Role",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Profile Name Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "profileAssociatedStates",
        text: "Profile States",
        filter: textFilter({
          onFilter: this.filterProfileStates,
        }),
        formatter: (cell, row) => {
          let stateNamesArray =
            cell?.length > 0
              ? cell.map((stateId) => {
                return this.state.availableStates?.find((s) => s.id === stateId)?.code;
              })
              : "";

          let stateNames = "";
          if (stateNamesArray?.length > 0) {
            stateNames = stateNamesArray.join(", ");
          }
          if (stateNames?.length > 20) {
            stateNames = stateNames.substring(0, 19) + "...";
          }

          return stateNames;
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "districts",
        text: "Districts",
        filter: textFilter({
          onFilter: this.filterProfileDistricts,
        }),
        formatter: (cell, row) => {
          let districtNames = cell?.length > 0 ? cell.map((district) => district.districtName)?.join(", ") : "";
          if (districtNames?.length > 30) {
            districtNames = districtNames.substring(0, 29) + "...";
          }

          return districtNames;
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
    ];
  }

  getInventoryTableColumns() {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
      paddingRight: "1.5rem",
    };
    return [
      {
        dataField: "warehouseInventoryUniqueIdentifer",
        hidden: true,
      },
      {
        dataField: "methodName",
        text: "Method",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Method Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px", color: "#0071BC" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "methodCategory",
        text: "Category",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Category Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "methodSubCategory",
        text: "Subcategory",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Subcategory Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "methodUOM",
        text: "UOM",
        filter: textFilter({
          onFilter: this.filterMethodUomColumn,
        }),
        formatter: (cell, row) => {
          return UtilityFunctions.getDisplayTextFromFieldObject(cell);
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "quantity",
        text: "Quantity",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Quantity Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "inventoryAssociations",
        text: "",
        formatter: (cell, row) => {
          return (
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-inventory-actions">
                <span className={globalStyles.formDataLinks}>Actions</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.props.permissions.canIEditWarehouses() && (
                  <Dropdown.Item eventKey="removeInventory" onSelect={() => this.removeMethodInventory(row)}>
                    Remove
                  </Dropdown.Item>
                )}
                {this.props.permissions.canIWorktaskInteractionAssignedWarehouse() && (
                  <React.Fragment>
                    <Dropdown.Item
                      eventKey="transferInventory"
                      onSelect={() => this.showTransferInventoryOutModal(row)}
                    >
                      Transfer
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="receiveInventory" onSelect={() => this.showReceiveInventoryModal(row)}>
                      Receive
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="lossInventory" onSelect={() => this.showLossInventoryModal(row)}>
                      Loss
                    </Dropdown.Item>
                    {row.isMixingMethod && (
                      <Dropdown.Item eventKey="mixInventory" onSelect={() => this.showMixInventoryModal(row)}>
                        Mix
                      </Dropdown.Item>
                    )}
                  </React.Fragment>
                )}
                {this.props.permissions.canIReconcileInventoryAssignedWarehouse() && (
                  <Dropdown.Item eventKey="adjustInventory" onSelect={() => this.showAdjustInventoryModal(row)}>
                    Reconciliation
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          );
        },
        align: "right",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        style: { ...rowsStyle, fontWeight: 400, height: "45px", paddingRight: "0", color: "#0071bc" },
      },
    ];
  }

  filterUserNameColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (entry) =>
          entry.firstName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          entry.lastName?.toLowerCase().includes(filterValue?.toLowerCase())
      );
    }
  };

  filterMethodUomColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) =>
        UtilityFunctions.getDisplayTextFromFieldObject(entry.methodUOM)
          ?.toLowerCase()
          .includes(filterValue?.toLowerCase())
      );
    }
  };

  filterProfileStates = (filterValue, data) => {
    if (filterValue) {
      const filteredStateIds = this.state.availableStates
        ?.filter(
          (state) =>
            state.code?.toLowerCase().includes(filterValue?.toLowerCase()) ||
            state.name?.toLowerCase().includes(filterValue?.toLowerCase())
        )
        ?.map((s) => s.value);
      return data.filter((entry) => filteredStateIds.some((id) => entry.profileAssociatedStates.includes(id)));
    }
  };

  filterProfileDistricts = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) =>
        entry.districts?.some((d) => d.districtName?.toLowerCase().includes(filterValue?.toLowerCase()))
      );
    }
  };

  handleSpecificChanges = () => {
    //do something
  };

  showTransactionHistoryModal = () => {
    this.setState({ showTransactionHistoryModal: true });
  };

  handleEditWarehouse = async () => {
    if (!this.state.isEditMode) {
      this.setState({ isEditMode: true });
    } else {
      const { data, warehouseData } = this.state;
      const isDataValid = await this.isFormDataValid();
      if (!isDataValid) {
        this.checkForInvalidData();
      } else if (warehouseData?.warehouseUniqueIdentifier) {
        const isStatusChangeRequired = data.isActive !== warehouseData.isActive;
        const updateWarehouseResponse = await InventoryAPI.UpdateWarehouse(
          warehouseData.warehouseUniqueIdentifier,
          this.getUpdateWarehouseBody()
        );
        if (updateWarehouseResponse?.successful) {
          let warehouseData = updateWarehouseResponse.data;

          if (isStatusChangeRequired && warehouseData.warehouseUniqueIdentifier) {
            const changeStatusResponse = this.state.data.isActive
              ? await this.activateWarehouse(warehouseData.warehouseUniqueIdentifier)
              : await this.deactivateWarehouse(warehouseData.warehouseUniqueIdentifier);
            if (changeStatusResponse?.successful) {
              warehouseData = changeStatusResponse.data;
            }
          }

          await this.updateMembersDataInWarehouse(warehouseData);
          const data = warehouseData ? this.updatePageData(warehouseData) : { ...this.state.data };
          this.setState({ data, warehouseData, showSuccessfulUpdateModal: true, isEditMode: false });
        } else if (updateWarehouseResponse?.message) {
          ErrorHandler.showError(updateWarehouseResponse.message);
        } else {
          ErrorHandler.showError("Warehouse update failed. Contact system admin for more details.");
        }
      }
    }
  };

  getUpdateWarehouseBody() {
    const { data, warehouseData } = this.state;
    const warehouseMembers = warehouseData.warehouseMembers.map((m) => ({
      warehouseMemberWSUsweProfileUniqReference: m.wsUserProfileUniqueIdentifier,
    }));
    if (!warehouseMembers.find((w) => w.warehouseMemberWSUsweProfileUniqReference === data.warehouseManager?.value)) {
      warehouseMembers.push({ warehouseMemberWSUsweProfileUniqReference: data.warehouseManager?.value });
    }
    return {
      warehouseName: data.warehouseName,
      warehouseStreetAddress: data.streetAddress,
      warehouseStateUniqueReference: data.warehouseState,
      warehouseCity: data.city,
      warehouseZipcode: data.zipCode,
      description: data.description,
      warehouseManagerWSUserProfileUniqueReference: data.warehouseManager?.value,
      warehouseMembers: warehouseMembers,
    };
  }

  getUpdateUsersInWarehouseBody(userProfileIdsToUpdate) {
    const { warehouseData } = this.state;
    return {
      warehouseName: warehouseData.warehouseName,
      warehouseStreetAddress: warehouseData.warehouseStreetAddress,
      warehouseStateUniqueReference: warehouseData.warehouseStateUniqueReference,
      warehouseZipcode: warehouseData.warehouseZipcode,
      description: warehouseData.description,
      warehouseCity: warehouseData.warehouseCity,
      warehouseManagerWSUserProfileUniqueReference: warehouseData.warehouseManagerWSUserProfileUniqueReference,
      warehouseMembers: userProfileIdsToUpdate.map((id) => ({ warehouseMemberWSUsweProfileUniqReference: id })),
    };
  }

  async activateWarehouse(warehouseUniqueId) {
    const activateWarehouseResponse = await InventoryAPI.ActivateWarehouse(warehouseUniqueId);
    if (!activateWarehouseResponse?.successful) {
      ErrorHandler.showErrorWithDetails(
        activateWarehouseResponse,
        "System failed to activate warehouse. Please contact system admin for more details."
      );
    }
    return activateWarehouseResponse;
  }

  async deactivateWarehouse(warehouseUniqueId) {
    const deactivateWarehouseResponse = await InventoryAPI.DeactivateWarehouse(warehouseUniqueId);
    if (!deactivateWarehouseResponse?.successful) {
      ErrorHandler.showErrorWithDetails(
        deactivateWarehouseResponse,
        "System failed to deactivate warehouse. Please contact system admin for more details."
      );
    }
    return deactivateWarehouseResponse;
  }

  async isFormDataValid() {
    const { warehouseName, warehouseType, warehouseState, warehouseManager } = this.state.data;
    let isValidData = warehouseName && warehouseType && warehouseState && warehouseManager?.value;
    let isDuplicatedManager = true;
    if (Number.parseInt(warehouseType) === WarehouseTypes.Employee) {
      const employeeWarehousesResponse = await InventoryAPI.GetStateWarehousesByType(warehouseState, warehouseType);
      if (employeeWarehousesResponse?.successful && employeeWarehousesResponse.data?.results?.length > 0) {
        isDuplicatedManager = employeeWarehousesResponse.data.results.find(
          (wh) => wh.warehouseManagerWSUserProfileUniqueReference === warehouseManager?.value && wh.warehouseUniqueIdentifier !== this.state.warehouseData.warehouseUniqueIdentifier
        );
        if (isDuplicatedManager) {
          toast.warning(
            "The selected Manager is already associated to another Employee Warehouse in the state. Please select a new one."
          );
        }
        isValidData = isValidData && !isDuplicatedManager;
      }
    }

    return isValidData;
  }

  checkForInvalidData() {
    const { data, errors } = this.state;
    if (!data.warehouseName) {
      errors.warehouseName = "You must enter a valid Warehouse name.";
    }
    if (!data.warehouseType) {
      errors.warehouseType = "You must select a valid Warehouse type.";
    }
    if (!data.warehouseState) {
      errors.warehouseState = "You must select a State.";
    }
    if (!data.warehouseManager?.value) {
      errors.warehouseManager = "You must select a manager for the warehouse.";
    }
    this.setState(errors);
  }

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    delete errors[fieldName];

    this.setState({ data, errors });
  };

  showAddUsersModal = () => {
    this.setState({ showAddUsersModal: true });
  };

  showAddMethodsModal = () => {
    this.setState({ showAddMethodsModal: true });
  };

  showTransferInventoryOutModal = (method) => {
    this.setState({
      transferInventoryModalData: method,
      transactionType: WarehouseTransactionTypes.OutgoingTransfer,
      showTransferInventoryModal: true,
    });
  };

  showTransferInventoryInModal = (method) => {
    this.setState({
      transferInventoryModalData: method,
      transactionType: WarehouseTransactionTypes.IncomingTransfer,
      showTransferInventoryModal: true,
    });
  };

  showReceiveInventoryModal = (method) => {
    this.setState({
      transferInventoryModalData: method,
      transactionType: WarehouseTransactionTypes.Receive,
      showTransferInventoryModal: true,
    });
  };

  showLossInventoryModal = (method) => {
    this.setState({
      transferInventoryModalData: method,
      transactionType: WarehouseTransactionTypes.Loss,
      showTransferInventoryModal: true,
    });
  };

  showMixInventoryModal = (method) => {
    this.setState({
      transferInventoryModalData: method,
      transactionType: WarehouseTransactionTypes.Mixing,
      showMixInventoryModal: true,
    });
  };

  showAdjustInventoryModal = (method) => {
    this.setState({
      transferInventoryModalData: method,
      transactionType: WarehouseTransactionTypes.Reconciliation,
      showTransferInventoryModal: true,
    });
  };

  handleUserDetailsNavigation = (row) => {
    if (this.props.permissions.canIViewGlobalUserRecord()) {
      //Navigation to User Details Page (Pending to confirm requirement)
    } else {
      toast.warning("User does not have permission to view record details.");
    }
  };

  handleUsersRowOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        userTableRowsSelected: [...this.state.userTableRowsSelected, row?.wsUserProfileUniqueIdentifier],
      });
    } else {
      this.setState({
        userTableRowsSelected: this.state.userTableRowsSelected.filter((x) => x !== row?.wsUserProfileUniqueIdentifier),
      });
    }
  };

  handleOnSelectAllUsers = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ userTableRowsSelected: rows.map((r) => r.wsUserProfileUniqueIdentifier) });
    } else {
      this.setState({ userTableRowsSelected: [] });
    }
  };

  handleUsersRemove = async () => {
    const { warehouseData: originalWarehouseData, userTableRowsSelected } = this.state;
    if (userTableRowsSelected.length > 0) {
      const userProfileIdsToUpdate = originalWarehouseData.warehouseMembers
        ?.filter((m) => !userTableRowsSelected.includes(m.wsUserProfileUniqueIdentifier))
        ?.map((member) => member.wsUserProfileUniqueIdentifier);
      if (userProfileIdsToUpdate?.length > 0 && originalWarehouseData.warehouseUniqueIdentifier) {
        const updateWarehouseResponse = await InventoryAPI.UpdateWarehouse(
          originalWarehouseData.warehouseUniqueIdentifier,
          this.getUpdateUsersInWarehouseBody(userProfileIdsToUpdate)
        );
        if (updateWarehouseResponse?.successful) {
          toast.success("Users were successfully removed from the warehouse.");
          let warehouseData = updateWarehouseResponse.data;
          await this.updateMembersDataInWarehouse(warehouseData);
          const data = warehouseData ? this.updatePageData(warehouseData) : { ...this.state.data };
          this.setState({ data, warehouseData, isEditMode: false, userTableRowsSelected: [] });
        } else if (updateWarehouseResponse?.message) {
          ErrorHandler.showError(updateWarehouseResponse.message);
        } else {
          ErrorHandler.showError("Warehouse update failed. Contact system admin for more details.");
        }
      }
    } else {
      toast.warning("Please select the rows you want to remove.");
    }
  };

  handleWhStatusChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isActive = input.checked;
    this.setState({ data });
  };

  addUsersSelection = async (usersData) => {
    const { warehouseData: originalWarehouseData } = this.state;
    const newUserProfilesIds = usersData
      .map((user) => user.wsUserProfileUniqueIdentifier)
      ?.filter((id) => !originalWarehouseData.warehouseMembers?.some((m) => m.wsUserProfileUniqueIdentifier === id));
    const userProfileIdsToUpdate = [
      ...originalWarehouseData.warehouseMembers?.map((m) => m.wsUserProfileUniqueIdentifier),
      ...newUserProfilesIds,
    ];
    if (userProfileIdsToUpdate?.length > 0 && originalWarehouseData.warehouseUniqueIdentifier) {
      const updateWarehouseResponse = await InventoryAPI.UpdateWarehouse(
        originalWarehouseData.warehouseUniqueIdentifier,
        this.getUpdateUsersInWarehouseBody(userProfileIdsToUpdate)
      );
      if (updateWarehouseResponse?.successful) {
        toast.success("Users were successfully added to the warehouse.");
        let warehouseData = updateWarehouseResponse.data;
        await this.updateMembersDataInWarehouse(warehouseData);
        const data = warehouseData ? this.updatePageData(warehouseData) : { ...this.state.data };
        this.setState({ data, warehouseData, isEditMode: false });
      } else if (updateWarehouseResponse?.message) {
        ErrorHandler.showError(updateWarehouseResponse.message);
      } else {
        ErrorHandler.showError("Warehouse update failed. Contact system admin for more details.");
      }
    }
  };

  handleAddMethods = async (newMethods) => {
    const newMethodsToAdd = newMethods.filter(
      (m) =>
        !this.state.warehouseData?.warehouseInventory?.some((i) => i.methodUniqueReference === m.methodUniqueIdentifier)
    );
    if (newMethodsToAdd?.length > 0 && this.state.warehouseData.warehouseUniqueIdentifier) {
      const addMethodsResponse = await InventoryAPI.AddMethodsToWarehouse(
        this.state.warehouseData.warehouseUniqueIdentifier,
        this.getAddMethodsBody(newMethodsToAdd)
      );
      if (addMethodsResponse?.successful) {
        toast.success("Methods were successfully added to the warehouse.");
        const warehouseData = { ...this.state.warehouseData };
        warehouseData.warehouseInventory = addMethodsResponse.data?.warehouseInventory;
        await this.updateInventoryDataInWarehouse(warehouseData);
        const data = warehouseData ? this.updatePageData(warehouseData) : { ...this.state.data };
        this.setState({ data, warehouseData, isEditMode: false });
      } else {
        ErrorHandler.showError(
          addMethodsResponse.message
            ? addMethodsResponse.message
            : "Warehouse update failed. Contact system admin for more details."
        );
      }
    }
  };

  handleTransferInventory = async (transferData) => {
    const warehouseTransactionResponse = await InventoryAPI.PerformWarehouseTransaction(
      this.getTransactionBody(transferData)
    );
    if (warehouseTransactionResponse?.successful) {
      toast.success("Transaction was successfully recorded.");
      const warehouseData = await this.getWarehouseData();
      const data = warehouseData ? this.updatePageData(warehouseData) : { ...this.state.data };
      this.setState({ data, warehouseData });
    } else {
      ErrorHandler.showError(
        warehouseTransactionResponse?.message ? warehouseTransactionResponse?.message : "Adding transaction failed."
      );
    }

    this.setState({ transferInventoryModalData: "", transactionType: "" });
  };

  handleTransactionHistorySubmit = async (transactionHistoryData) => {
    const getTransactionHistoryResponse = await InventoryAPI.GetTransactionsForWarehouseForDateRange(
      this.getTransactionHistoryBody(transactionHistoryData)
    );
    if (getTransactionHistoryResponse?.successful) {
      if (getTransactionHistoryResponse.data?.length > 0) {
        this.props.history.push({
          pathname: "/cmits/transactions",
          state: {
            transactionsData: getTransactionHistoryResponse.data,
          },
        });
      } else {
        toast.warning("There are no transactions to show.");
      }
    } else {
      ErrorHandler.showErrorWithMessage(
        getTransactionHistoryResponse?.message
          ? getTransactionHistoryResponse.message
          : "Unable to retrieve transaction history."
      );
    }
  };

  getTransactionHistoryBody(transactionHistoryData) {
    return {
      warehouseUniqueReference: this.state.warehouseData?.warehouseUniqueIdentifier,
      startDateTransactionSearch: new Date(Date.parse(transactionHistoryData.startDate)).toISOString(),
      endDateTransactionSearch: new Date(Date.parse(transactionHistoryData.endDate)).toISOString(),
    };
  }

  loadAvailableUserProfiles = async (searchValue) => {
    let userProfiles = [];
    const usersSearchResponse = await RoleAuthorizationAPI.SearchUsersFreeText(this.getSearchUsersBody(searchValue));
    if (usersSearchResponse?.successful && usersSearchResponse.data?.results?.length > 0) {
      for (let user of usersSearchResponse.data.results) {
        if (user.userProfiles?.length > 0) {
          userProfiles = [...userProfiles, ...user.userProfiles];
        }
      }
    }
    return userProfiles?.length > 0
      ? userProfiles.map((profile) => ({
        value: profile.wsUserProfileUniqueIdentifier,
        label: `${profile.profileName} - ${profile.firstName} ${profile.lastName}`,
      }))
      : [];
  };

  getTransactionBody(transferData) {
    const { transactionType, warehouseData } = this.state;
    const transactionBody = {
      methodUOMEnumId: transferData.methodUOM?.id,
      transactionTypeEnumId: transactionType,
      enteredByUserProfileUniqueReference: this.state.currentUserRole?.wsUserProfileUniqueIdentifier,
      warehouseTransferToUniqueReference:
        Number.parseInt(transactionType) === WarehouseTransactionTypes.OutgoingTransfer
          ? transferData.transferWarehouse
          : warehouseData?.warehouseUniqueIdentifier,
      warehouseTransferToStateUniqueReference:
        Number.parseInt(transactionType) === WarehouseTransactionTypes.OutgoingTransfer
          ? transferData.transferState
          : warehouseData?.warehouseStateUniqueReference,
      inventoryAdjustmentDate: new Date().toISOString(),
    };

    if (Number.parseInt(transactionType) === WarehouseTransactionTypes.Mixing) {
      transactionBody.methodUniquereference = transferData.mixingMethod1.value;
      transactionBody.quantity = Number.parseInt(transferData.mixingQty1);
      transactionBody.secondMethodUniqueReferenceForMixing = transferData.mixingMethod2.value;
      transactionBody.secondMethodQuantity = Number.parseInt(transferData.mixingQty2);
      transactionBody.secondMethodUOMEnumId = warehouseData.warehouseInventory.find(
        (m) => m.methodUniqueReference === transferData.mixingMethod2.value
      )?.methodUOM?.id;
      transactionBody.mixingResultMethodUniquereference = transferData.mixingResultMethod.value;
      transactionBody.mixingResultMethodQuantity = Number.parseInt(transferData.mixingResultQty);
      transactionBody.mixingResultMethodUOMEnumId = warehouseData.warehouseInventory.find(
        (m) => m.methodUniqueReference === transferData.mixingResultMethod.value
      )?.methodUOM?.id;
    } else {
      transactionBody.methodUniquereference = transferData.methodUniqueReference;
      transactionBody.quantity = Number.parseInt(transferData.transferQuantity);
    }

    if (Number.parseInt(transactionType) === WarehouseTransactionTypes.IncomingTransfer) {
      transactionBody.warehouseTransferFromUniqueReference = transferData.transferWarehouse;
      transactionBody.warehouseTransferFromStateUniqueReference = transferData.transferState;
      transactionBody.transactionTypeEnumId = WarehouseTransactionTypes.OutgoingTransfer;
    } else {
      transactionBody.warehouseTransferFromUniqueReference = warehouseData?.warehouseUniqueIdentifier;
      transactionBody.warehouseTransferFromStateUniqueReference = warehouseData?.warehouseStateUniqueReference;
    }

    if (Number.parseInt(transactionType) === WarehouseTransactionTypes.Loss) {
      transactionBody.reason = transferData.lossReason?.label;
    } else if (Number.parseInt(transactionType) === WarehouseTransactionTypes.Reconciliation) {
      transactionBody.reason = transferData.reconciliationReason;
    }

    return transactionBody;
  }

  getAddMethodsBody(newMethods) {
    return newMethods.map((method) => ({
      methodUniqueReference: method.methodUniqueIdentifier,
      methodUnitOfMeasureEnumId: method.warehouseUom?.value,
      quantity:
        method.warehouseUom?.value === 1 ? Number.parseInt(method.quantity) : Number.parseFloat(method.quantity),
    }));
  }

  getSearchUsersBody(inputValue) {
    return {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "solid 0.5px #D8DDE6",
      };
    },
  };

  renderPageHeader() {
    return (
      <Row className="mt-4">
        <Col className="pl-0">
          <h1 className={globalStyles.pageTitle}>Warehouse Record Page</h1>
        </Col>
        <Col className="text-right pr-0">
          <Button variant="primary" className="mr-3" onClick={this.showTransactionHistoryModal}>
            <span className={globalStyles.modalSubmitButtonText}>{"Transaction History"}</span>
          </Button>
          {this.props.permissions.canIEditWarehouses() && (
            <Button variant="primary" onClick={this.handleEditWarehouse}>
              <span className={globalStyles.modalSubmitButtonText}>
                {this.state.isEditMode ? "Apply Changes" : "Edit"}
              </span>
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  renderMainInfoCard() {
    const { isEditMode, data } = this.state;
    return (
      <Card>
        <Card.Header className={globalStyles.cardTitles}>
          <Image src={warehouseIcon} alt="Info Icon" className="mr-2" />
          <span className={globalStyles.cardTitleText}>Main Information</span>
        </Card.Header>
        <Card.Body className="text-left">
          <Row className="mt-2">
            <Col md={6}>
              {this.renderEditableField(
                isEditMode,
                "warehouseName",
                "Name",
                data.warehouseName,
                "text",
                [],
                "required"
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderEditableField(
                isEditMode,
                "warehouseType",
                "Type",
                data.warehouseType,
                "select",
                this.state.warehouseTypes,
                "required"
              )}
            </Col>
          </Row>
          <Row>
            <Col>{this.renderManagerField()}</Col>
          </Row>
          <Row>
            <Col>
              {this.renderEditableField(isEditMode, "streetAddress", "Street Address", data.streetAddress, "text")}
            </Col>
          </Row>
          <Row>
            <Col>{this.renderEditableField(isEditMode, "city", "City", data.city, "text")}</Col>
            <Col>
              {this.renderEditableField(
                isEditMode,
                "warehouseState",
                "State",
                data.warehouseState,
                "select",
                this.state.availableStates,
                "required"
              )}
            </Col>
          </Row>
          <Row>
            <Col>{this.renderEditableField(isEditMode, "zipCode", "ZIP Code", data.zipCode, "number")}</Col>
          </Row>
          <Row className={isEditMode ? "mt-2 mb-3" : ""}>
            <Col>{this.renderStatusField()}</Col>
          </Row>
          <Row>
            <Col>{this.renderDescriptionField(isEditMode, "description", "Description", data.description, "text")}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderUsersAssignedCard() {
    const areDataRowsSelected = this.state.userTableRowsSelected?.length > 0;
    return (
      <Card className={styles.usersCard}>
        <Card.Header className={globalStyles.cardTitles}>
          <Row>
            <Col>
              <span className={globalStyles.cardTitleText}>Users Assigned to Warehouse</span>
            </Col>
            <Col className="text-right">
              {this.props.permissions.canIEditWarehouses() && (
                <Button variant="link" className="px-0" onClick={this.showAddUsersModal}>
                  <span className={globalStyles.formDataLinks}>+ Add User</span>
                </Button>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {this.state.warehouseData?.warehouseMembers?.length > 0 && (
            <Row className="text-right">
              <Col className="pr-0">
                {this.props.permissions.canIEditWarehouses() && (
                  <Button
                    className={areDataRowsSelected ? "" : styles.greyedOutRemoveButton}
                    onClick={this.handleUsersRemove}
                  >
                    <span className={areDataRowsSelected ? "" : styles.greyedOutButtonText}>Remove</span>
                  </Button>
                )}
              </Col>
            </Row>
          )}
          <Row className="text-left mt-3">
            <Col>{this.renderUsersTable()}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderCurrentInventoryCard() {
    return (
      <Card className="mt-4">
        <Card.Header className={globalStyles.cardTitles}>
          <Row>
            <Col>
              <span className={globalStyles.cardTitleText}>Current Inventory</span>
            </Col>
            <Col className="text-right">
              {this.props.permissions.canIEditWarehouses() && (
                <Button variant="link" className="px-0" onClick={this.showAddMethodsModal}>
                  <span className={globalStyles.formDataLinks}>+ Add Method</span>
                </Button>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="text-left mt-2">
            <Col>{this.renderInventoryTable()}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderUsersTable() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.userTableRowsSelected,
      onSelect: this.handleUsersRowOnSelect,
      onSelectAll: this.handleOnSelectAllUsers,
      style: { backgroundColor: "#f5f5f5" },
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "1px solid #e8e8e8",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #dee2e6",
      },
      nonSelectable: [this.state.data.warehouseManager?.value],
    };

    const usersData =
      this.state.warehouseData?.warehouseMembers?.length > 0 ? this.state.warehouseData.warehouseMembers : [];

    return usersData?.length > 0 ? (
      <ToolkitProvider
        keyField="wsUserProfileUniqueIdentifier"
        data={usersData}
        columns={this.state.assignedUsersTableColumns}
        bootstrap4={true}
        hover={true}
      >
        {(props) => (
          <div>
            <BootstrapTable
              keyField="wsUserProfileUniqueIdentifier"
              filter={filterFactory()}
              bordered={false}
              condensed
              selectRow={selectRow}
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    ) : (
      <div className="text-center">
        <span className={globalStyles.formLabel}>Users data is not available.</span>
      </div>
    );
  }

  renderInventoryTable() {
    const inventoryData =
      this.state.warehouseData?.warehouseInventory?.length > 0 ? this.state.warehouseData.warehouseInventory : [];

    return inventoryData?.length > 0 ? (
      <ToolkitProvider
        keyField="warehouseInventoryUniqueIdentifer"
        data={inventoryData}
        columns={this.state.inventoryTableColumns}
        bootstrap4={true}
        hover={true}
      >
        {(props) => (
          <div>
            <BootstrapTable
              keyField="warehouseInventoryUniqueIdentifer"
              filter={filterFactory()}
              bordered={false}
              condensed
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    ) : (
      <div className="text-center">
        <span className={globalStyles.formLabel}>Inventory data is not available.</span>
      </div>
    );
  }

  renderManagerField() {
    const { data, isEditMode, errors } = this.state;
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>
          Warehouse Manager<span className={globalStyles.asterisk508}> *</span>
        </Form.Label>
        <AsyncSelect
          placeholder="Search"
          aria-label="Manager Search"
          value={data.warehouseManager}
          styles={this.customSelectStyles}
          isClearable
          components={{ DropdownIndicator }}
          loadOptions={this.loadAvailableUserProfiles}
          onChange={(e) => this.handleSelectChanges(e, "warehouseManager")}
        />
        {errors.warehouseManager && <div className="alert alert-danger">{errors.warehouseManager}</div>}
      </Form.Group>
    ) : (
      this.renderLabel("projectStates", "Warehouse Manager", data.warehouseManager?.label)
    );
  }

  renderStatusField() {
    const { data, isEditMode } = this.state;
    return isEditMode ? (
      <Form.Check type="checkbox" id="isWarehouseActive">
        <Form.Check.Input type="checkbox" checked={data.isActive} onChange={this.handleWhStatusChange} />
        <Form.Check.Label>
          <span className={globalStyles.formLabel}>Active</span>
        </Form.Check.Label>
      </Form.Check>
    ) : (
      this.renderLabel("isActive", "Status", data.status)
    );
  }

  renderDescriptionField() {
    const { data, isEditMode } = this.state;
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Description</Form.Label>
        <Form.Control
          name="description"
          placeholder="Enter Description"
          type="text"
          as="textarea"
          rows={3}
          className={globalStyles.formData}
          value={data.description}
          onChange={this.handleChange}
        />
      </Form.Group>
    ) : (
      this.renderLabel("description", "Description", data.description)
    );
  }

  render() {
    const existingUsersIds =
      this.state.warehouseData?.warehouseMembers?.length > 0
        ? this.state.warehouseData.warehouseMembers.map((m) => m.wsUserProfileUniqueIdentifier)
        : [];
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading Warehouse Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.warehouseRecordPage}>
          <Form onSubmit={this.handleSubmit}>
            {this.renderPageHeader()}
            <Row className="mt-3">
              <Col md={4} className="pl-0">
                {this.renderMainInfoCard()}
              </Col>
              <Col className="pr-0">{this.renderUsersAssignedCard()}</Col>
            </Row>
            <Row>
              <Col className="px-0">{this.renderCurrentInventoryCard()}</Col>
            </Row>
          </Form>
        </div>
        <AddWarehouseUsersModal
          show={this.state.showAddUsersModal}
          onHide={() => {
            this.setState({ showAddUsersModal: false });
          }}
          availableStates={this.state.availableStates}
          onAddUsers={this.addUsersSelection}
          existingUserIds={existingUsersIds}
        />
        <AddWarehouseMethodsModal
          show={this.state.showAddMethodsModal}
          onHide={() => {
            this.setState({ showAddMethodsModal: false });
          }}
          onSave={this.handleAddMethods}
        />
        <TransferInventoryModal
          show={this.state.showTransferInventoryModal}
          onHide={() => {
            this.setState({ transferInventoryData: "", showTransferInventoryModal: false });
          }}
          onSave={this.handleTransferInventory}
          transferInventoryData={this.state.transferInventoryModalData}
          transactionType={this.state.transactionType}
          availableStates={this.state.availableStates}
          warehouseTypes={this.state.warehouseTypes}
          warehouseData={this.state.warehouseData}
        />
        <MixInventoryModal
          show={this.state.showMixInventoryModal}
          onHide={() => {
            this.setState({ transferInventoryData: "", showMixInventoryModal: false });
          }}
          onSave={this.handleTransferInventory}
          transferInventoryData={this.state.transferInventoryModalData}
          transactionType={this.state.transactionType}
          warehouseData={this.state.warehouseData}
        />
        <ViewTransactionHistoryModal
          show={this.state.showTransactionHistoryModal}
          onHide={() => {
            this.setState({ showTransactionHistoryModal: false });
          }}
          onSubmit={this.handleTransactionHistorySubmit}
        />
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfulUpdateModal}
          onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
          routeData=""
          messageType="update"
          objectName="Warehouse"
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(WarehouseDetailsPage);
