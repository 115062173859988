class lsm {

  static SetUserNotAuthenticated() {
    console.log("SetUserNotAuthenticated called");
    localStorage.setItem("isUserAuthenticated", false);
    localStorage.removeItem("token");
    localStorage.removeItem("currentUserProfile");
    localStorage.removeItem("currentUserName");
    localStorage.removeItem("currentIdentity");
    localStorage.removeItem("currentState");
    localStorage.removeItem("currentUserRole");
    localStorage.removeItem("currentState");
  }

  static SetUserAuthenticated(token) {
    localStorage.setItem("isUserAuthenticated", "true");
    localStorage.setItem("token", token);
  }

  static GetCurrentToken() {
    return lsm.IsUserAuthenticated() === true ? localStorage.getItem("token") : null;
  }

  static IsUserAuthenticated() {
    return localStorage.getItem("isUserAuthenticated") === "true";
  }

  static GetCurrentState() {
    return lsm.IsUserAuthenticated() === true ? JSON.parse(localStorage.getItem("currentState")) : null;
  }

  static GetCurrentUsername() {
    return lsm.IsUserAuthenticated() === true ? localStorage.getItem("currentUserName") : null;
  }

  static GetCurrentIdentity() {
    return lsm.IsUserAuthenticated() === true ? localStorage.getItem("currentIdentity") : null;
  }

  static GetCurrentUserProfile() {
    return lsm.IsUserAuthenticated() === true ? localStorage.getItem("currentUserProfile") : null;
  }

  static RedirectToLogin(reason) {
    if (!window.location.pathname.toLowerCase().includes("/login")) {
      console.warn("Redirecting to /login. Reason: " + reason);
      window.location = "/login";
    }
  }

  static UpdateProfileInfo(response) {
    let anyChanges = false;

    const currentUserProfile = lsm.GetCurrentUserProfile();
    const updatedUserProfile = response.headers["roledescription"];
    if (updatedUserProfile && (!currentUserProfile || updatedUserProfile !== currentUserProfile)) {
      localStorage.setItem("currentUserProfile", updatedUserProfile);
      anyChanges = true;
    }

    const currentDisplayedName = lsm.GetCurrentUsername();
    const updatedDisplayedName = response.headers["displayname"];
    if (updatedDisplayedName && (!currentDisplayedName || updatedDisplayedName !== currentDisplayedName)) {
      localStorage.setItem("currentUserName", updatedDisplayedName);
      anyChanges = true;
    }

    const currentIdentity = lsm.GetCurrentIdentity()
    const updatedIdentity = response.headers["userprinciplename"];
    if (updatedIdentity && (!currentIdentity || updatedIdentity !== currentIdentity)) {
      localStorage.setItem("currentIdentity", updatedIdentity);
      anyChanges = true;
    }
    return anyChanges;
  }
}

export default lsm;
