import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import styles from "./Contact.module.scss";
import globalStyles from "../../../OARS.module.scss";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import { withRouter } from "react-router-dom";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import PhoneNumber from "../../common/phonenumber";
import ContactOrgAddress from "../../common/ContactOrgAddress";
import PhoneNumberDisplay from "../../common/PhoneNumberDisplay";
import ContactOrgAddressDisplay from "../../common/ContactOrgAddressDisplay";
import UtilityFunctions from "../../common/UtilityFunctions";
import SuccessfulRecordModifiedModal from "../../common/create-updateOkMessageModal";
import { toast } from "react-toastify";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import AgreementAPI from "../../../api/AgreementEntities/AgreementAPI";

class DetailsEditContact extends React.Component {
  constructor(props) {
    super(props);

    let contactUniqueId = "";
    if (this.props.history.location?.state?.entityData?.contactUniqueId) {
      contactUniqueId = this.props.history.location.state.entityData.contactUniqueId;
    } else {
      this.props.history.push("/home");
    }

    this.state = {
      isDetailsPage: this.props.mode === "details",
      validated: false,
      contactUniqueId,
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "",
      preferredName: "",
      emailAddress: "",
      contactTypes: [],
      typeOfContactEnumId: "",
      contactTypeString: "",
      phoneNumbers: [],
      phoneNumberTypes: [],
      mailingStreet: "",
      mailingCity: "",
      mailingStateUniqueId: "",
      mailingZip: "",
      mailingCountryUniqueId: "",
      otherAddresses: [],
      usaStates: [],
      countries: [],
      organization: {},
      showHappyModal: false,
      agreements: [],
    };
  }

  async componentDidMount() {
    await this.updateContactData();

    if (this.state.countries.length === 0) {
      let result = await ReferenceFileAPI.GetAllCountries();
      let countries = result
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      const usaObject = countries.find((country) => country.name === "United States of America");
      let countryId = this.state.mailingCountryUniqueId;
      if (!countryId) {
        this.setState({ mailingCountryUniqueId: usaObject.id });
      }
      this.setState({ countries: countries, countryId });
    }
    if (this.state.usaStates.length === 0) {
      await this.getStatesFromAPI(this.state.mailingCountryUniqueId);
    }
    if (this.state.phoneNumberTypes.length === 0) {
      let phoneNumberTypes = await AgreementEntitiesAPI.GetPhoneNumberTypes();
      this.setState({ phoneNumberTypes: phoneNumberTypes.data });
    }

    if (this.state.contactTypes.length === 0) {
      let contactTypes = await AgreementEntitiesAPI.GetContactTypes();
      let currentContactType = contactTypes.data.find((type) => type.id === this.state.typeOfContactEnumId)?.name;
      this.setState({
        contactTypes: contactTypes.data,
        contactTypeString: currentContactType,
      });
    }
    if (this.state.agreements.length === 0) {
      const agreementsCall = await AgreementAPI.GetAgreementsByContact(this.state.contactUniqueId);
      if (agreementsCall?.successful) {
        this.setState({ agreements: agreementsCall.data });
      }
    }

    if (!this.state.otherAddresses.mailingCountryUniqueId) {
      const otherAddressObj = {
        mailingStreet: "",
        mailingCity: "",
        mailingStateUniqueId: null,
        mailingCountryUniqueId: this.state.mailingCountryUniqueId,
        mailingZip: "",
      };

      let otherAddress = this.state.otherAddresses;
      otherAddress.push(otherAddressObj);
      this.setState({ otherAddress });
    }
  }

  async updateContactData() {
    const { contactUniqueId } = this.state;
    if (!contactUniqueId) {
      return;
    }

    const getContactResponse = await AgreementEntitiesAPI.GetContact(contactUniqueId);
    if (getContactResponse?.successful) {
      let contact = getContactResponse.data;

      let primaryStreet = "";
      let primaryCity = "";
      let primaryStateId = "";
      let primaryZip = "";
      let primaryCountryId = "";
      if (contact?.primaryMailingAddress) {
        let fixAddresses = [];
        fixAddresses.push({
          mailingStreet: contact.primaryMailingAddress.mailingStreet,
          mailingCity: contact.primaryMailingAddress.mailingCity,
          mailingStateUniqueId: contact.primaryMailingAddress.mailingStateUniqueId,
          mailingZip: contact.primaryMailingAddress.mailingZip,
          mailingCountryUniqueId: contact.primaryMailingAddress.mailingCountryUniqueId,
        });

        contact.otherAddresses = fixAddresses.concat(contact.otherAddresses);

        primaryStreet = contact.primaryMailingAddress.mailingStreet;
        primaryCity = contact.primaryMailingAddress.mailingCity;
        primaryStateId = contact.primaryMailingAddress.mailingStateUniqueId;
        primaryZip = contact.primaryMailingAddress.mailingZip;
        primaryCountryId = contact.primaryMailingAddress.mailingCountryUniqueId;
      }
      if (contact?.phoneNumbers.length > 0 && !contact?.phoneNumbers[0]?.phoneTypeEnumId) {
        let fixPhones = [];
        contact.phoneNumbers.forEach((phone) => {
          let phoneTypeId = 1;
          if (phone.phoneType) {
            phoneTypeId = phone.phoneType.id;
          }
          fixPhones.push({
            phoneNumber: UtilityFunctions.formatPhoneNumber(phone.phoneNumber),
            phoneTypeEnumId: phoneTypeId,
          });
        });
        contact.phoneNumbers = fixPhones;
      }

      if (contact?.typeOfContactEnumId) {
        contact.typeOfContact = { id: contact.typeOfContactEnumId };
      }
      this.setState({
        contactUniqueId: contact.contactUniqueId,
        firstName: contact.firstName,
        middleName: contact.middleName,
        lastName: contact.lastName,
        suffix: contact.suffix,
        preferredName: contact.preferredName,
        emailAddress: contact.emailAddress,
        typeOfContactEnumId: contact.typeOfContact.id,
        phoneNumbers: contact.phoneNumbers,
        mailingStreet: primaryStreet,
        mailingCity: primaryCity,
        mailingStateUniqueId: primaryStateId,
        mailingZip: primaryZip,
        mailingCountryUniqueId: primaryCountryId,
        otherAddresses: contact.otherAddresses,
      });
    } else {
      ErrorHandler.showErrorWithMessage(
        getContactResponse?.message ? getContactResponse.message : "Getting Contact Data failed."
      );
    }
  }

  handleSubmitWrapper = (e) => {
    e.preventDefault();
    this.handleSubmit(e);
  };

  async handleSubmit(e) {
    let form = e.currentTarget;
    let phoneValidated = true;
    let emailValidated = true;
    let contactTypeValidated = this.state.typeOfContactEnumId !== "";

    for (var i = 0; i < this.state.phoneNumbers.length; i++) {
      var currentPhone = this.state.phoneNumbers[i];
      if (currentPhone.phoneNumber !== "" && currentPhone.phoneTypeEnumId === "-1") {
        phoneValidated = false;
      }
      if (currentPhone.phoneNumber !== "") {
        phoneValidated = UtilityFunctions.validatePhoneNumber(currentPhone.phoneNumber);
      }
    }
    if (this.state.emailAddress !== null && this.state.emailAddress !== "") {
      emailValidated = UtilityFunctions.validateEmail(this.state.emailAddress);
    }

    let extraValidation = phoneValidated && emailValidated && contactTypeValidated;

    if (form.checkValidity() === false || !extraValidation) {
      if (!emailValidated) {
        toast.warning("If entering an email address, it must be in valid email format.");
      } else if (!phoneValidated) {
        toast.warning("Please include at least one phone number in a valid format with a type selected.");
      } else {
        toast.warning("Please fill out all required fields.");
      }
      return;
    }
    this.setState({ validated: true });

    let primaryToSend = null;

    let otherAddresses = [];
    if (this.state.otherAddresses.length > 0) {
      otherAddresses = [...this.state.otherAddresses];
      let mailingAddress = otherAddresses.shift();

      if (this.isPrimaryMailingAddressEmpty()) {
        primaryToSend = {
          mailingStreet: mailingAddress.mailingStreet,
          mailingCity: mailingAddress.mailingCity,
          mailingStateUniqueId: mailingAddress.mailingStateUniqueId,
          mailingZip: mailingAddress.mailingZip,
          mailingCountryUniqueId: mailingAddress.mailingCountryUniqueId,
          addressPurpose: "mailing",
        };
        this.setState({
          otherAddresses: [mailingAddress, ...otherAddresses],
          mailingStreet: mailingAddress.mailingStreet,
          mailingCity: mailingAddress.mailingCity,
          mailingStateUniqueId: mailingAddress.mailingStateUniqueId,
          mailingZip: mailingAddress.mailingZip,
          mailingCountryUniqueId: mailingAddress.mailingCountryUniqueId,
        });
      } else {
        ErrorHandler.showErrorWithMessage("Contacts require a Country and State.");
        return;
      }
    }

    let verifiedPhones = [];
    if (this.state.phoneNumbers.length > 0) {
      for (let i = 0; i < this.state.phoneNumbers.length; i++) {
        var phone = this.state.phoneNumbers[i];
        if (phone.phoneNumber !== "" && phone.phoneTypeEnumId !== "-1" && phone.phoneTypeEnumId !== "") {
          verifiedPhones.push({
            phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(phone.phoneNumber),
            phoneTypeEnumId: parseInt(phone.phoneTypeEnumId),
          });
        }
      }
    }

    var updateContactObj = {
      contactUniqueId: this.state.contactUniqueId,
      firstName: this.state.firstName,
      middleName: this.state.middleName,
      lastName: this.state.lastName,
      preferredName: this.state.preferredName,
      typeOfContactEnumId: parseInt(this.state.typeOfContactEnumId),
      suffix: this.state.suffix,
      emailAddress: this.state.emailAddress,
      primaryMailingAddress: primaryToSend,
    };
    const updateAPIResult = await AgreementEntitiesAPI.UpdateContact(updateContactObj);
    if (updateAPIResult?.unsuccessful || !updateAPIResult) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to Update Contact",
        apiName: "UpdateContact",
        responseUnsuccessful: updateAPIResult?.unsuccessful,
        responseMessage: updateAPIResult?.message,
      });
      return;
    }

    if (!this.isOtherAddressEmpty()) {
      const updateOtherAddressesAPIResult = await AgreementEntitiesAPI.ReplaceOtherAddressesOnContact(
        this.state.contactUniqueId,
        otherAddresses
      );

      if (updateOtherAddressesAPIResult?.unsuccessful || !updateOtherAddressesAPIResult) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to Update Contact addresses",
          apiName: "ReplaceOtherAddressesOnContact",
          responseUnsuccessful: updateOtherAddressesAPIResult?.unsuccessful,
          responseMessage: updateOtherAddressesAPIResult?.message,
        });
        return;
      }
    }
    if (verifiedPhones.length > 0) {
      const updatePhoneNumbersAPIResult = await AgreementEntitiesAPI.ReplacePhoneNumbersOnContact(
        this.state.contactUniqueId,
        verifiedPhones
      );
      if (updatePhoneNumbersAPIResult?.unsuccessful || !updatePhoneNumbersAPIResult) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to Update Contact phone numbers",
          apiName: "ReplacePhoneNumbersOnContact",
          responseUnsuccessful: updatePhoneNumbersAPIResult?.unsuccessful,
          responseMessage: updatePhoneNumbersAPIResult?.message,
        });
        return;
      }
    }

    this.setState({ isDetailsPage: true, showHappyModal: true });
  }

  isPrimaryMailingAddressEmpty() {
    let otherAddresses = [...this.state.otherAddresses];
    let mailingAddress = otherAddresses.shift();
    return mailingAddress.mailingCountryUniqueId !== "Select" && mailingAddress.mailingStateUniqueId;
  }

  isOtherAddressEmpty() {
    let isAddressEmpty = true;
    let otherAddresses = [...this.state.otherAddresses];
    otherAddresses.shift();
    otherAddresses.forEach((address) => {
      if (address.mailingStreet && address.mailingCity) {
        if (address.mailingStateUniqueId && address.mailingCountryUniqueId) {
          isAddressEmpty = false;
        } else {
          toast.warning("Other Address cannot be updated, State and Country are required for address");
          isAddressEmpty = true;
          return;
        }
      }
    });
    return isAddressEmpty;
  }

  handleStateChange = ({ currentTarget: input }) => {
    let propertyName = input.id;
    let data = this.state;
    data[propertyName] = input.value;
    this.setState({ data });
  };

  firstNameField = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.firstName}</span>;
    } else {
      return (
        <Form.Control
          id="firstName"
          defaultValue={this.state.firstName}
          required
          type="text"
          onChange={this.handleStateChange}
          title="First Name"
        />
      );
    }
  };

  middleNameField = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.middleName}</span>;
    } else {
      return (
        <Form.Control
          id="middleName"
          defaultValue={this.state.middleName}
          type="text"
          onChange={this.handleStateChange}
          title="Middle Name"
        />
      );
    }
  };

  lastNameField = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.lastName}</span>;
    } else {
      return (
        <Form.Control
          id="lastName"
          defaultValue={this.state.lastName}
          required
          type="text"
          onChange={this.handleStateChange}
          title="Last Name"
        />
      );
    }
  };

  suffixField = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.suffix}</span>;
    } else {
      return (
        <Form.Control
          id="suffix"
          defaultValue={this.state.suffix}
          type="text"
          onChange={this.handleStateChange}
          title="Suffix"
        />
      );
    }
  };

  preferredNameField = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.preferredName}</span>;
    } else {
      return (
        <Form.Control
          id="preferredName"
          defaultValue={this.state.preferredName}
          type="text"
          onChange={this.handleStateChange}
          title="Preferred Name"
        />
      );
    }
  };

  contactTypeField = () => {
    if (this.state.isDetailsPage) {
      return (
        <span className={styles.dataFields}>{UtilityFunctions.capitalizeString(this.state.contactTypeString)}</span>
      );
    } else {
      return (
        <Form.Control
          id="typeOfContactEnumId"
          value={this.state.typeOfContactEnumId}
          required
          as="select"
          type="text"
          onChange={this.handleStateChange}
        >
          {this.state.contactTypes.map((type) => (
            <option value={type.id} key={type.id}>
              {type.name}
            </option>
          ))}
        </Form.Control>
      );
    }
  };

  phoneNumberFields = () => {
    if (this.state.isDetailsPage) {
      return (
        <PhoneNumberDisplay phoneNumbers={this.state.phoneNumbers} phoneNumberTypes={this.state.phoneNumberTypes} />
      );
    } else {
      return (
        <PhoneNumber
          phoneNumbers={this.state.phoneNumbers}
          phoneNumberTypes={this.state.phoneNumberTypes}
          onPhoneNumberChange={this.handlePhoneNumberChange}
          onExtraPhoneNumber={this.handleExtraPhoneNumber}
        />
      );
    }
  };

  emailField = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.emailAddress}</span>;
    } else {
      return (
        <Form.Control
          id="emailAddress"
          defaultValue={this.state.emailAddress}
          type="text"
          onChange={this.handleStateChange}
          title="Email Address"
        />
      );
    }
  };

  addressFields = () => {
    if (this.state.isDetailsPage) {
      if (this.state.countries.length > 0 && this.state.usaStates.length > 0) {
        return (
          <ContactOrgAddressDisplay
            addresses={this.state.otherAddresses}
            countries={this.state.countries}
            states={this.state.usaStates}
          />
        );
      }
    } else {
      return (
        <ContactOrgAddress
          addresses={this.state.otherAddresses}
          countries={this.state.countries}
          states={this.state.usaStates}
          onAddressChange={this.handleAddressChanges}
          onExtraAddress={this.handleExtraAddress}
        />
      );
    }
  };

  pageActionButtons = () => {
    if (this.state.isDetailsPage) {
      return (
        <Button className={globalStyles.modalSubmitButton} variant="primary" onClick={this.changeModes}>
          <span className={globalStyles.modalSubmitButtonText}>Edit</span>
        </Button>
      );
    } else {
      return (
        <div className={styles.actionButtons}>
          <Button
            style={{ marginLeft: "10px" }}
            className={globalStyles.modalCancelButton}
            variant="secondary"
            onClick={this.changeModes}
          >
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            className={globalStyles.modalSubmitButton}
            type="submit"
            variant="primary"
          >
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </div>
      );
    }
  };

  renderAgreementAssociationsCard = () => {
    return (
      <Col lg={6} className={styles.agreementCardColumn}>
        <Card>
          <Card.Header className={globalStyles.cardTitles}>
            <span className={globalStyles.cardTitleText}>Associated Agreements</span>
          </Card.Header>
          <Card.Body>
            {this.state.agreements?.map((agreement, index) => {
              let statusClass = "";
              switch (agreement.agreementStatus.id) {
                case 1:
                  statusClass = styles.agreementStatusDraft;
                  break;
                case 2:
                  statusClass = styles.agreementStatusPending;
                  break;
                case 3:
                  statusClass = styles.agreementStatusActive;
                  break;
                default:
                  statusClass = styles.agreementStatus;
                  break;
              }

              let roleColumn = null;
              let role = "";
              let additionalContact = agreement?.additionalContacts?.find(
                (contact) => contact?.contactUniqueReference === this.state.contactUniqueId
              );
              if (agreement?.cooperator?.contactUniqueReference === this.state.contactUniqueId) {
                role = "Cooperator";
              } else if (agreement?.primaryFieldAgent?.contactUniqueReference === this.state.contactUniqueId) {
                role = "WS Specialist";
              } else if (additionalContact) {
                role = additionalContact?.contactRoleOnAgreement?.displayText;
              }
              roleColumn = <Col className={styles.agreementStatus}>{role}</Col>;

              return (
                <Row className={styles.agreementRow} key={`agreement-${index}`}>
                  <Col>
                    <Button
                      variant="link"
                      className={styles.agreementLink}
                      onClick={() => this.navigateToAgreementRecordPage(agreement)}
                    >
                      {agreement.commonName}
                    </Button>
                  </Col>
                  {roleColumn}
                  <Col>
                    <span className={statusClass}>{agreement.agreementStatus?.displayText}</span>
                  </Col>
                </Row>
              );
            })}
          </Card.Body>
        </Card>
      </Col>
    );
  };

  navigateToAgreementRecordPage = (agreement) => {
    this.props.history.push({
      pathname: "/agreement/details",
      state: { entityData: agreement },
    });
  };

  handlePhoneNumberChange = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    let data = this.state;
    data.phoneNumbers[index][fieldName] = UtilityFunctions.formatPhoneNumber(input.value);
    this.setState({ data });
  };

  handleExtraPhoneNumber = ({ currentTarget: input }) => {
    let data = this.state;
    if (input.value === "add") {
      data.phoneNumbers.push({ phoneNumber: "", phoneTypeEnumId: "-1" });
    } else {
      data.phoneNumbers.splice(input.value, 1);
    }
    this.setState({ data });
  };

  handleAddressChanges = async ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    let data = this.state;
    let propertyValue = input.value.includes("Select") ? null : input.value;

    if (fieldName === "mailingCountryUniqueId" && propertyValue) {
      await this.getStatesFromAPI(propertyValue);
    }
    data.otherAddresses[index][fieldName] = propertyValue;
    this.setState({ data });
  };

  async getStatesFromAPI(countryId) {
    const results = await ReferenceFileAPI.GetStatesByCountry(countryId);

    if (results) {
      const states = results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      this.setState({ usaStates: states });
    }
  }

  handleExtraAddress = ({ currentTarget: input }) => {
    let data = this.state;

    if (input.value === "add") {
      data.otherAddresses.push({
        mailingStreet: "",
        mailingCity: "",
        mailingStateUniqueId: "",
        mailingCountryUniqueId: "",
        mailingZip: "",
      });
    } else {
      data.otherAddresses.splice(input.value, 1);
    }

    this.setState({ data });
  };

  changeModes = () => {
    let phoneNumbers = this.state.phoneNumbers;

    if (phoneNumbers.length === 1) {
      if (phoneNumbers[0].phoneTypeEnumId === "-1" && phoneNumbers[0].phoneNumber === "") {
        phoneNumbers = [];
      }
    } else if (phoneNumbers.length === 0) {
      phoneNumbers.push({
        phoneNumber: "",
        phoneTypeEnumId: "-1",
      });
    }

    let addresses = this.state.otherAddresses;
    if (addresses.length === 1) {
      if (
        addresses[0].mailingStreet === "" &&
        addresses[0].mailingCity === "" &&
        addresses[0].mailingStateUniqueId === "" &&
        addresses[0].mailingCountryUniqueId === "" &&
        addresses[0].mailingZip === ""
      ) {
        addresses = [];
      }
    } else if (addresses.length === 0) {
      addresses.push({
        mailingStreet: "",
        mailingCity: "",
        mailingStateUniqueId: "",
        mailingCountryUniqueId: "",
        mailingZip: "",
      });
    } else if (
      addresses.find(
        (address) =>
          address.mailingStreet === this.state.mailingStreet &&
          address.mailingCity === this.state.mailingCity &&
          address.mailingStateUniqueId === this.state.mailingStateUniqueId &&
          address.mailingCountryUniqueId === this.state.mailingCountryUniqueId &&
          address.mailingZip === this.state.mailingZip
      ) === undefined
    ) {
      let fixAddresses = [];
      fixAddresses.push({
        mailingStreet: this.state.mailingStreet,
        mailingCity: this.state.mailingCity,
        mailingStateUniqueId: this.state.mailingStateUniqueId,
        mailingZip: this.state.mailingZip,
        mailingCountryUniqueId: this.state.mailingCountryUniqueId,
      });

      addresses = fixAddresses.concat(addresses);
    }

    this.setState({
      isDetailsPage: !this.state.isDetailsPage,
      phoneNumbers: phoneNumbers,
      otherAddresses: addresses,
    });
  };

  closeHappyModal = () => {
    this.setState({ showHappyModal: false });
  };

  render() {
    return (
      <div>
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmitWrapper}>
          <Row className={styles.pageRow}>
            <Col>
              <h5 className={globalStyles.pageTitle}>Contact Record Page</h5>
            </Col>
            <Col className="text-right">{this.pageActionButtons()}</Col>
          </Row>
          <Row className={styles.pageRow}>
            <Card className={styles.createPageCards}>
              <Card.Header as="h5" className={styles.cardTitles}>
                <i className="fa fa-user-circle-o fa-2x" aria-hidden="true"></i>
                <span className={styles.personalInfo}>Personal Information</span>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={5}>
                    <Row className={styles.cardRows}>
                      <Col>
                        <h6 className={styles.fieldLabels}>
                          First Name<span className={globalStyles.asterisk508}>*</span>
                        </h6>
                        {this.firstNameField()}
                      </Col>
                    </Row>
                    <Row className={styles.cardRows}>
                      <Col>
                        <h6 className={styles.fieldLabels}>Middle Name</h6>
                        {this.middleNameField()}
                      </Col>
                    </Row>
                    <Row className={styles.cardRows}>
                      <Col>
                        <h6 className={styles.fieldLabels}>
                          Last Name<span className={globalStyles.asterisk508}>*</span>
                        </h6>
                        {this.lastNameField()}
                      </Col>
                    </Row>
                    <Row className={styles.cardRows}>
                      <Col>
                        <h6 className={styles.fieldLabels}>Suffix</h6>
                        {this.suffixField()}
                      </Col>
                    </Row>
                    <Row className={styles.cardRows}>
                      <Col>
                        <h6 className={styles.fieldLabels}>Preferred Name</h6>
                        {this.preferredNameField()}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={7}>
                    <Row>
                      <Card className={styles.createPageCards}>
                        <Card.Header as="h5" className={styles.cardTitles}>
                          Contact Information
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col>{this.phoneNumberFields()}</Col>
                          </Row>
                          <Row>
                            <Col>
                              <h6 className={styles.fieldLabels}>Email Address</h6>
                              {this.emailField()}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>
                    <Row>
                      <Card className={styles.createPageCards}>
                        <Card.Header as="h5" className={styles.cardTitles}>
                          Address Information
                        </Card.Header>
                        <Card.Body>{this.addressFields()}</Card.Body>
                      </Card>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row className={styles.pageRow}>{this.renderAgreementAssociationsCard()}</Row>
        </Form>
        <SuccessfulRecordModifiedModal
          show={this.state.showHappyModal}
          onHide={this.closeHappyModal}
          messageType="update"
          objectName="Contact"
        />
      </div>
    );
  }
}

export default withRouter(DetailsEditContact);
