import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./Conflicts.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Form, Row, Col, Button, Modal, Image, Card, CloseButton } from "react-bootstrap";
import CustomForm from "../common/form";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import searchIcon from "../../assets/search.svg";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import ConflictsAPI from "../../api/Conflicts/ConflictsAPI";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import nav1 from "../../assets/createConflictNav1.PNG";
import nav2 from "../../assets/createConflictNav2.PNG";
import nav3 from "../../assets/createConflictNav3.PNG";
import nav4 from "../../assets/createConflictNav4.PNG";
import xCircle from "../../assets/x-circle.svg";
import UtilityFunctions from "../common/UtilityFunctions";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};
const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className={styles.noOptionsMessage}>
        <i className="fa fa-exclamation-circle " style={{ color: "gray", padding: "4px" }} aria-hidden="true"></i>
                Sorry, we couldn’t find a match for “{props.selectProps.inputValue}”. Please try another search      
      </span>
    </components.NoOptionsMessage>
  );
};

class CreateConflictModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        selectedSpecies: "",
        selectedProperty: "",
        properties: [],
        damages: [],
        diseases: [],
        resourcesToRemove: [],
        protectedResources: [],
        resourceSearch: "",
        resourceQuantity: "",
        resourceUom: "",
        resourceUnitValue: "",
        resourceTotalValue: "",
        resourceYear: "",
      },
      selectedResourceColumns: [
        { dataField: "agreementResourceUniqueIdentifier", hidden: true },
        {
          dataField: "valuationYear",
          text: "Year",
          headerAlign: "left",
          align: "left",
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
        },
        {
          dataField: "resourceName",
          text: "Resource",
          headerStyle: () => {
            return { width: "15%", fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
          headerAlign: "left",
          align: "left",
          editable: false,
        },
        {
          dataField: "quantity",
          text: "Quantity",
          headerAlign: "left",
          align: "left",
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
        },
        {
          dataField: "valuationUnitOfMeasure",
          text: "UOMs",
          headerAlign: "left",
          align: "left",
          formatter: (cell) => {
            if (cell) {
              return cell.displayText;
            }
          },
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
          editable: false,
        },
        {
          dataField: "valuePerUnitOfMeasure",
          text: "Unit Value",
          headerAlign: "left",
          align: "left",
          formatter: (cell) => {
            return "$" + cell;
          },
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
        },
        {
          dataField: "totalValuation",
          text: "Total Value",
          headerAlign: "left",
          align: "left",
          formatter: (cell) => {
            return "$" + cell;
          },
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
        },
        {
          dataField: "propertyName",
          text: "Property",
          headerStyle: () => {
            return { width: "15%", fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
          headerAlign: "left",
          align: "left",
          editable: false,
        },
      ],
      protectedResourcesSelected: [],
      resourceRowSelected: [],
      errors: {},
      currentUsaState: "",
      usaStates: [],
      countries: [],
      species: [],
      showCreateSuccessModal: false,
      isFormEditable: true,
      modalStep: 1,
      isInitialConfiguration: true,
      isResourceMenuOpen: false,
      showAddResources: false,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    let { data, isFormEditable } = this.state;
    let showPropChange = prevProps.show !== this.props.show && this.props.show;
    if (showPropChange) {
      const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
      this.validateStateData(currentUsaState);
      data.resourceYear = new Date().getFullYear();
      const usaStates = await this.getUsaStates();
      let countries = [];

      if (this.props.propertyData) {
        const getPropertyResponse = await AgreementEntitiesAPI.GetProperty(
          this.props.propertyData.propertyUniqueIdentifier
        );
        if (getPropertyResponse?.successful) {
          const getAgreementResponse = await AgreementAPI.GetActiveAgreementByProperty(
            this.props.propertyData.propertyUniqueIdentifier
          );
          if (getAgreementResponse?.successful) {
            getPropertyResponse.data.agreementDate = getAgreementResponse.data?.agreementSignatureDate;
          }
          data.properties.push(getPropertyResponse.data);
        }
      } else if (this.props.history?.location?.state) {
        await this.initializePropertiesFromRoutingData(data);
      }

      data.damages = await this.getThreatTypesFromAPI();
      this.setState({
        data,
        countries: await this.getAllCountriesFromAPI(),
        usaStates,
        isFormEditable,
        currentUsaState,
      });
    }
  }

  async initializePropertiesFromRoutingData(data) {
    const propertyData =
      this.props.history.location.state?.propertyData || this.props.history.location.state?.entityData;
    if (propertyData) {
      if (this.props.agreementData) {
        propertyData.agreementDate = this.props.agreementData.agreementSignatureDate;
      }
      data.properties.push(propertyData);
    }
  }

  async getUsaStates() {
    const usaStatesResponse = await ReferenceFileAPI.GetUSStates();
    return usaStatesResponse && usaStatesResponse.length > 0 ? usaStatesResponse : [];
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  async getThreatTypesFromAPI() {
    const threatTypes = await ConflictsAPI.GetAllThreatTypeOptions();
    if (threatTypes?.successful) {
      return threatTypes.data?.map((type) => {
        type.value = type.id;
        type.label = type.displayText;
        return type;
      });
    } else if (threatTypes?.unsuccessful) {
      toast.error(threatTypes.message);
    } else {
      toast.error("Failed to load threat types, please close the modal and try again.");
    }
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  handlePropertySelection = async (input) => {
    const { data } = this.state;

    const responseData = await AgreementEntitiesAPI.GetProperty(input.value);
    if (responseData?.data) {
      const propertyData = responseData.data;
      data.properties.push(propertyData);
    }

    this.setState({ data });
  };

  handleSpeciesSelection = (input) => {
    const { data } = this.state;
    data.selectedSpecies = input;

    this.setState({ data });
    return input;
  };

  loadAvailableProperties = async (inputValue) => {
    const { data } = this.state;
    const availableProperties = await this.getPropertiesFromAPI(inputValue);

    return availableProperties.filter(
      (property) => !data.properties.some((p) => p.propertyUniqueIdentifier === property.value)
    );
  };

  async updateResourcesForPropertyOnAgreement(propertyData) {
    const agreementPropertyData = await AgreementAPI.GetAgreementPropertyFromActiveAgreement(
      propertyData.propertyUniqueIdentifier
    );

    if (agreementPropertyData?.data) {
      propertyData.agreementResources = agreementPropertyData.data.agreementResources.filter((r) => !r.isHistorical);
    } else propertyData.agreementResources = [];
  }

  getPrimaryAddress(propertyData) {
    let primaryAddress = "";

    if (propertyData.additionalStreetAddresses?.length > 0) {
      const { streetAddress, city, zipCode } = propertyData.additionalStreetAddresses[0];
      primaryAddress = streetAddress.concat(
        ", ",
        city,
        ", ",
        this.getStateNameFromId(propertyData.state),
        " ",
        zipCode,
        ", " + this.getCountryNameFromId(propertyData.countryUniqueIdentifier)
      );
    }

    return primaryAddress;
  }

  getStateNameFromId(stateId) {
    const { usaStates } = this.state;
    const currentState = usaStates.find((state) => state.stateUniqueIdentifier === stateId);
    return currentState ? currentState.state_name : "";
  }

  getCountryNameFromId(countryId) {
    const { countries } = this.state;
    const country = countries?.find((country) => country.id === countryId);
    return country ? country.name : "";
  }

  async getSpeciesForState(searchValue) {
    const { currentUsaState } = this.state;
    if (currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );

      if (speciesData?.data?.results) {
        let results = speciesData.data.results;
        return results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return "";
  }

  async getSpeciesByAgreementProperties() {
    const { currentUsaState } = this.state;
    let results = [];
    if (currentUsaState?.stateGuid) {
      const speciesData = await this.getSpeciesDataByPropertyAndAgreementActiveDate();
      if (speciesData?.length > 0) {
        const existingConflicts =
          this.props.history.location.pathname === "/home"
            ? await this.getExistingConflicts()
            : this.props.existingConflicts;
        if (existingConflicts?.length > 0) {
          results = speciesData
            ?.filter(
              (s) => !existingConflicts.some((c) => c.targetedSpeciesUniqueReference === s.speciesUniqueReference)
            )
            ?.map((result) => {
              result.value = result.speciesUniqueReference;
              result.label = result.speciesName;
              return result;
            });
        } else {
          results = speciesData?.map((result) => {
            result.value = result.speciesUniqueReference;
            result.label = result.speciesName;
            return result;
          });
        }
      }
    }
    return results;
  }

  async getExistingConflicts() {
    let retreivedConflicts = await Promise.all(
      this.state.data.properties.map(async function (property) {
        const getConflictsCall = await ConflictsAPI.GetConflictsByProperty(property.propertyUniqueIdentifier);
        if (getConflictsCall?.successful) {
          return getConflictsCall.data.results;
        }
      })
    );
    return retreivedConflicts.flat();
  }

  setResourcesOnProperty = (agreementProperties) => {
    let { data } = this.state;
    agreementProperties.forEach((agreementProperty) => {
      let propertyFromState = data.properties.find(
        (p) => p.propertyUniqueIdentifier === agreementProperty.propertyUniqueReference
      );
      if (propertyFromState) {
        propertyFromState.agreementResources = agreementProperty.agreementResources
          .sort(UtilityFunctions.agreementEntityTableSortFunc("resourceName"))
          .filter(function (item, pos, ary) {
            return (
              !item.isHistorical && (!pos || item?.resourceUniqueReference !== ary[pos - 1]?.resourceUniqueReference)
            );
          });
      }
    });

    this.setState({ data });
  };

  async getSpeciesDataByPropertyAndAgreementActiveDate() {
    const { data, currentUsaState } = this.state;
    let retrievedAgreementProperties = await Promise.all(
      data.properties.map(async function (property) {
        const confirmPropertyBelongsToActiveAgreementOnDateResponse =
          await AgreementAPI.ConfirmPropertyBelongsToActiveAgreementOnDate({
            stateUniqueReference: currentUsaState.stateGuid,
            propertyUniqueReference: property.propertyUniqueIdentifier,
            dayToCheck: new Date(property.agreementDate).toISOString(),
          });
        if (confirmPropertyBelongsToActiveAgreementOnDateResponse?.data?.wasPropertyCoveredAtGivenTime) {
          const agreementResponse = await AgreementAPI.GetAgreement(
            confirmPropertyBelongsToActiveAgreementOnDateResponse?.data?.agreementUniqueIdentifier
          );
          if (agreementResponse?.successful) {
            const agreementProperties = agreementResponse?.data?.agreementProperties;
            const agreementPropertyMatchingCurrentProperty = agreementProperties?.find(
              (p) => p.propertyUniqueReference === property.propertyUniqueIdentifier
            );
            if (agreementPropertyMatchingCurrentProperty) {
              property.agreementProperty = agreementPropertyMatchingCurrentProperty;
            }
          } else {
            ErrorHandler.handleApiErrorMessage({
              errorContextMessage: "Unable to retrieve agreement data for targeted species list",
              apiName: "GetAgreement",
              responseMessage: agreementResponse?.message,
              responseUnsuccessful: agreementResponse?.unsuccessful,
            });
          }
        } else if (!confirmPropertyBelongsToActiveAgreementOnDateResponse?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to confirm if a property had an active agreement on chosen date",
            apiName: "ConfirmPropertyBelongsToActiveAgreementOnDate",
            responseMessage: confirmPropertyBelongsToActiveAgreementOnDateResponse?.message,
            responseUnsuccessful: confirmPropertyBelongsToActiveAgreementOnDateResponse?.unsuccessful,
          });
        }
        return property;
      })
    );
    this.setResourcesOnProperty(
      retrievedAgreementProperties?.map((agreementProperty) => agreementProperty.agreementProperty)
    );
    return retrievedAgreementProperties
      ?.map((agreementProperty) => agreementProperty.agreementProperty.targetedSpecies)
      .flat()
      .sort(UtilityFunctions.agreementEntityTableSortFunc("speciesName"))
      .filter(function (item, pos, ary) {
        return !pos || item?.speciesUniqueReference !== ary[pos - 1]?.speciesUniqueReference;
      });
  }

  getSpeciesByAgreementBody(stateId) {
    return {
      stateUniqueReference: stateId,
      properties: this.state.data.properties.map((property) => ({
        propertyUniqueReference: property.propertyUniqueIdentifier,
      })),
    };
  }

  async getPropertiesFromAPI(searchValue) {
    const { data, currentUsaState } = this.state;

    if (currentUsaState?.stateGuid) {
      const propertiesData = await AgreementAPI.SearchActiveAgreementPropertiesByTargetedSpecies(
        this.getSearchPropertiesBody(data.selectedSpecies, searchValue, currentUsaState.stateGuid)
      );

      if (propertiesData?.data?.results) {
        let results = propertiesData.data.results;
        return results.map((result) => ({
          value: result.propertyUniqueReference,
          label: result.propertyName,
        }));
      }
    }

    return "";
  }

  getFilterReferenceFilesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  getSearchPropertiesBody(selectedSpecies, searchValue, stateId) {
    return {
      stateUniqueReference: stateId,
      speciesUniqueReference: selectedSpecies ? selectedSpecies.value : null,
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
    };
  }

  handleRemoveProperty = async (propertyId) => {
    if (propertyId === this.props.propertyGuid) {
      toast.error(
        "Property must be included on this Conflict. Creating a Conflict without this Property associated can be accomplished on the Home page."
      );
      return;
    }
    const { data } = this.state;
    data.properties = [...data.properties].filter((p) => p.propertyUniqueIdentifier !== propertyId);
    this.setState({ data });
  };

  isValidConflictData() {
    const { data } = this.state;
    if (data.selectedSpecies?.value && data.properties?.length > 0) {
      return true;
    } else {
      toast.info(
        <div>
          <div>Conflict data is incomplete.</div>
          <div>At least one property, one resource and one species must be selected</div>
        </div>
      );
      return false;
    }
  }

  async handleModalSubmit(e) {
    e.preventDefault();
    if (this.state.modalStep < 4) {
      let validPropertyAgreementDates = this.validatePropertyAgreementDates();
      if (this.state.modalStep === 1 && this.state.data.properties.length > 0 && validPropertyAgreementDates) {
        this.setState({ species: await this.getSpeciesByAgreementProperties() });
      } else if (
        this.state.modalStep === 1 &&
        !(this.state.data.properties.length > 0 && validPropertyAgreementDates)
      ) {
        toast.warning("Please select at least one Property and specify a date.");
        return;
      }
      this.setState({ modalStep: this.state.modalStep + 1 });
    } else if (this.state.modalStep === 4) {
      if (!this.isResourceDataValid()) {
        toast.warning("Invalid data provided. Please make sure to select a threat type.");
        return;
      }

      if (this.isValidConflictData()) {
        const conflictCall = await this.submitConflict();
        if (conflictCall) {
          this.handleCancel();
        }
      }
      this.setState({ isInitialConfiguration: true });
    }
  }

  updateProtectedResourcesInProperties() {
    let isExistingResource = false;
    this.state.protectedResourcesSelected.forEach((resource) => {
      const currentProperty = this.state.data.properties.find(
        (property) => property.propertyUniqueIdentifier === resource.propertyUniqueIdentifier
      );
      if (currentProperty) {
        if (this.resourceAlreadyExists(currentProperty, resource)) {
          isExistingResource = true;
        } else {
          currentProperty.protectedResources = currentProperty.protectedResources
            ? [...currentProperty.protectedResources, resource]
            : [resource];
        }
      }
    });

    if (isExistingResource)
      toast.warning(
        "Some of the selected resource(s) were not included in the conflict, as they were previously added."
      );
  }

  resourceAlreadyExists(currentProperty, resource) {
    return currentProperty.protectedResources?.some(
      (r) => r.resourceUniqueReference === resource.resourceUniqueReference
    );
  }

  async submitConflict() {
    const body = {
      targetedSpeciesUniqueReference: this.state.data.selectedSpecies?.value,
      properties: this.state.data.properties?.map((property) => {
        return {
          propertyUniqueReference: property.propertyUniqueIdentifier,
          searchDateForAgreement: new Date(property.agreementDate).toISOString(),
          protectedResources: this.state.protectedResourcesSelected
            ?.filter((x) => x.propertyUniqueIdentifier === property.propertyUniqueIdentifier)
            ?.map((resource) => ({
              protectedResourceUniqueReference: resource.agreementResourceUniqueIdentifier,
              threatTypeEnumId: resource.threatTypeEnumId,
            })),
        };
      }),
    };
    const createConflictCall = await ConflictsAPI.CreateConflict(body);
    if (createConflictCall?.successful) {
      this.props.onHide(createConflictCall?.data);
      toast.success("Conflict created.");
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create conflict",
        apiName: "CreateConflict",
        responseMessage: createConflictCall?.message,
        responseUnsuccessful: createConflictCall?.unsuccessful,
      });
    }
    return createConflictCall?.successful;
  }

  renderPropertyCardBody(data, isFormEditable) {
    return (
      <React.Fragment>
        <Row className="my-1 mx-1 mt-3" hidden={!isFormEditable}>
          <Col className="text-left">
            <label className={styles.formLabel}>Search for Properties</label>
            <AsyncSelect
              value={data.selectedProperty}
              aria-label="Search For Properties"
              openMenuOnClick={false}
              placeholder="Search here.."
              components={{ DropdownIndicator }}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                option: (provided) => ({ ...provided, textAlign: "left", fontSize: "14px" }),
                singleValue: (provided) => ({ ...provided, fontSize: "14px" }),
              }}
              theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#EAF3F1" } })}
              loadOptions={this.loadAvailableProperties}
              onChange={this.handlePropertySelection}
            />
          </Col>
        </Row>
        <div className="mt-5">
          {data.properties?.length > 0 &&
            data.properties.map((property, index) => (
              <div key={index} className={index < data.properties.length - 1 ? styles.listedPropertyRow : ""}>
                <Row className={"my-2 mx-1"}>
                  <Col className="text-left" lg={8}>
                    {this.renderLabel(`property-${index}`, property.propertyName, this.getPrimaryAddress(property))}
                  </Col>
                  <Col className="text-right" hidden={!isFormEditable} lg={4}>
                    <Button variant="link" onClick={() => this.handleRemoveProperty(property.propertyUniqueIdentifier)}>
                      <span className={styles.formData}>Remove</span>
                    </Button>
                  </Col>
                </Row>
                <Row className={"my-2 mx-1 mb-4"}>
                  <Col sm={4}>
                    <Form.Label className={globalStyles.formLabel}>Agreement Date for Property</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder=""
                      value={property.agreementDate ? new Date(property.agreementDate).toISOString().split("T")[0] : ""}
                      onChange={(e) => {
                        let { data } = this.state;
                        data.properties = data.properties.map((p) => {
                          if (p.propertyUniqueIdentifier === property.propertyUniqueIdentifier) {
                            p.agreementDate = e.target.value;
                          }
                          return p;
                        });
                        this.setState({ data });
                      }}
                    />
                  </Col>
                </Row>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }

  renderSpeciesCardBody(data) {
    return (
      <Row className={styles.selectionContainer}>
        <Col className="text-left">
          <label className={styles.formLabel}>Damage Agent Selection</label>
          <div>
            <Select
              value={data.selectedSpecies}
              aria-label="Search for Species"
              openMenuOnClick={true}
              placeholder="Select One"
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                option: (provided) => ({ ...provided, textAlign: "left", fontSize: "14px" }),
                singleValue: (provided) => ({ ...provided, fontSize: "14px" }),
              }}
              theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#EAF3F1" } })}
              options={this.state.species}
              onChange={this.handleSpeciesSelection}
            />
          </div>
        </Col>
      </Row>
    );
  }

  renderResourceModalSection() {
    const { data } = this.state;
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.resourceRowSelected,
      onSelect: this.handleSelectResourceRow,
      onSelectAll: (isSelect, rows) => {
        this.setState({
          resourceRowSelected: isSelect ? rows.map((r) => r.agreementResourceUniqueIdentifier) : [],
          protectedResourcesSelected: isSelect ? rows : [],
        });
      },
      style: { backgroundColor: "#DAE9E5" },
      headerColumnStyle: { borderTop: "hidden", borderBottom: "0.8px solid #008767" },
      selectColumnStyle: { verticalAlign: "middle" },
      selectionRenderer: ({ mode, ...rest }) => <input title="Row Selection Checkbox" type={mode} {...rest} />,
      selectionHeaderRenderer: ({ mode, ...rest }) => <input title="Select All Rows Checkbox" type={mode} {...rest} />,
    };
    let protectedResources = [];
    if (!this.state.isInitialConfiguration) {
      protectedResources = JSON.parse(JSON.stringify(this.state.data.protectedResources));
    } else {
      data.protectedResources = this.getAvailableResources();
    }
    const selectedResources = this.state.data.protectedResources.filter((resource) => resource.isSelected);

    return (
      <div className="container">
        <Row className={styles.xCircleRow} hidden={!this.state.showAddResources}>
          <Col align="right">
            <Image
              className={styles.xCircle}
              src={xCircle}
              onClick={() => {
                this.setState({ showAddResources: false });
              }}
              tabIndex="-1"
              alt="Hide Add New Resource Section"
            />
          </Col>
        </Row>
        <Row className={styles.inputRow} noGutters="true" hidden={!this.state.showAddResources}>
          <Col lg={1} className={styles.inputCol}>
            <Form.Control
              name="resourceYear"
              type="number"
              value={data.resourceYear}
              onChange={this.handleResourceChanges}
              disabled
            />
          </Col>
          <Col lg={3} className={styles.inputCol}>
            <AsyncSelect
              value={data.resourceSearch}
              openMenuOnClick={false}
              aria-label="Search for Resources"
              placeholder="Search Resource"
              loadOptions={this.handleResourceSearch}
              onChange={this.handleProtectedResourceSelection}
              components={{ DropdownIndicator, NoOptionsMessage }}
              theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "", primary: "#ced4da" } })}
              styles={{
                control: (styles) => ({ ...styles, fontSize: "12px" }),
                menu: (styles) => ({ ...styles, marginTop: "0px" }),
                noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  const color = "#EAF3F1";
                  return {
                    ...styles,
                    fontSize: "12px",
                    textAlign: "left",
                    borderBottom: "1px solid #f2f2f2",
                    backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                    color: isDisabled ? "#ccc" : isSelected ? ("#ccc" ? "white" : "black") : data.color,
                    cursor: isDisabled ? "not-allowed" : "default",
                    ":active": {
                      ...styles[":active"],
                      backgroundColor: !isDisabled && (isSelected ? color : null),
                    },
                  };
                },
              }}
            />
          </Col>
          <Col lg={1} className={styles.inputCol}>
            <Form.Control
              name="resourceQuantity"
              type="number"
              placeholder="Qty."
              value={data.resourceQuantity}
              onChange={this.handleResourceChanges}
            />
          </Col>
          <Col lg={1} className={styles.valueCol}>
            <Select
              value={data.resourceUom}
              aria-label="Select Resource Unit of Measure"
              placeholder="UOM"
              options={this.state.data.resourceUOMs}
              onChange={this.handleResourceUomChange}
            />
          </Col>
          <Col lg={1} className={styles.valueCol}>
            <Form.Control
              name="resourceUnitValue"
              type="number"
              placeholder="Unit Value"
              value={data.resourceUnitValue}
              onChange={this.handleResourceChanges}
            />
          </Col>
          <Col lg={1} className={styles.valueCol}>
            <Form.Control
              name="resourceTotalValue"
              type="number"
              placeholder="Total Value"
              value={data.resourceTotalValue}
              onChange={this.handleResourceChanges}
            />
          </Col>
          <Col align="right" className={styles.inputCol}>
            <Button
              className={styles.associationButtons}
              onClick={async () => {
                this.addResourcesToAgreement();
              }}
            >
              <span className={styles.associationButtonsText}>
                {this.state.data.properties.length > 1 ? "Associate with Properties" : "Associate with Property"}
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>Available Resources</Col>
          <Col align="right">
            <Button
              className={styles.addButtonLinksInHeader}
              variant="link"
              hidden={this.state.showAddResources}
              onClick={() => {
                this.setState({ showAddResources: true });
              }}
            >
              + Add New Resource
            </Button>
          </Col>
        </Row>
        <Row className={styles.tableRow}>
          <ToolkitProvider
            keyField="agreementResourceUniqueIdentifier"
            data={data.protectedResources}
            columns={this.state.selectedResourceColumns}
            bootstrap4={true}
            hover={true}
          >
            {(props) => (
              <div>
                <BootstrapTable
                  keyField="agreementResourceUniqueIdentifier"
                  selectRow={selectRow}
                  bootstrap4={true}
                  bordered={false}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </Row>
      </div>
    );
  }

  handleSelectResourceRow = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        resourceRowSelected: [...this.state.resourceRowSelected, row?.agreementResourceUniqueIdentifier],
        protectedResourcesSelected: [...this.state.protectedResourcesSelected, row],
      });
    } else {
      this.setState({
        resourceRowSelected: this.state.resourceRowSelected.filter((x) => x !== row?.agreementResourceUniqueIdentifier),
        protectedResourcesSelected: this.state.protectedResourcesSelected.filter(
          (x) => x.agreementResourceUniqueIdentifier != row?.agreementResourceUniqueIdentifier
        ),
      });
    }
  };

  handleResourceChanges = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleResourceSearch = async (inputValue) => {
    return await this.getResourcesFromAPI(inputValue);
  };

  getFilterReferenceFilesBody(searchValue) {
    return { textToSearchFor: searchValue, pageSize: 10, pageNumber: 1 };
  }

  async getResourcesFromAPI(searchValue) {
    const { currentUsaState } = this.state;
    if (currentUsaState && currentUsaState.stateGuid) {
      const resourcesData = await ReferenceFileAPI.FilterResources(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );
      if (resourcesData.data.results) {
        let results = resourcesData.data.results;
        return results.map((result) => ({ value: result.resourceUniqueIdentifier, label: result.name }));
      }
    }
    return "";
  }

  handleProtectedResourceSelection = async (input) => {
    return await this.handleResourceSelection(input);
  };

  handleResourceUomChange = (input) => {
    const { data } = this.state;
    data.resourceUom = input;
    this.setState({ data });
    return input;
  };

  async handleResourceSelection(input) {
    const { data } = this.state;
    data.resourceSearch = input;
    const resourceId = input.value;
    data.resourceId = resourceId;
    if (this.state.currentUsaState?.stateGuid) {
      const resourceServerResponse = await ReferenceFileAPI.GetResourceForState(
        resourceId,
        this.state.currentUsaState.stateGuid
      );
      if (resourceServerResponse?.successful) {
        const resourceData = resourceServerResponse.data;
        // data.resources.push(resourceData);
        if (resourceData?.stateValuationUnitOfMeasure) {
          const { id, name } = resourceData.stateValuationUnitOfMeasure;
          data.resourceUom = { value: id, label: name };
        }
      }
    }
    this.setState({ data });
    return input;
  }

  async addResourcesToAgreement() {
    const { data } = this.state;
    let success = false;
    let resourcesToAgreementResponse = "";
    for (let i = 0; i < data.properties.length; i++) {
      const currentProperty = data.properties[i];
      let agreementResponse = await AgreementAPI.GetAgreementsByProperty(currentProperty.propertyUniqueIdentifier);
      if (agreementResponse?.successful) {
        let foundWorkableAgreement = false;
        for (let j = 0; j < agreementResponse.data.length; j++) {
          const current = agreementResponse.data[j];
          if (current.agreementStatus.name === "active" || current.agreementStatus.name === "pending") {
            foundWorkableAgreement = true;
            let body = this.getResourceToAgreementBody(currentProperty);
            if (!body) {
              return;
            }
            resourcesToAgreementResponse = await AgreementAPI.AddResourcesToAgreementProperties(
              current.agreementUniqueIdentifier,
              body
            );
            if (resourcesToAgreementResponse?.successful) {
              success = true;
              break;
            } else {
              toast.warning("Something went wrong, Resource not created.");
              return;
            }
          }
        }
        if (!foundWorkableAgreement) {
          toast.warning("Resource not created, Property " + currentProperty.propertyName + " does not have a workable");
          return;
        }
      } else {
        toast.warning("Something went wrong, could not retreive property agreement. " + agreementResponse.message);
      }
    }
    if (success) {
      toast.success("Resource created.");
      const resourceId = data.resourceId;
      data.resourceSearch = "";
      data.resourceQuantity = "";
      data.resourceUom = "";
      data.resourceUnitValue = "";
      data.resourceTotalValue = "";
      data.resourceId = "";
      await this.refreshPropertyData();
      this.setState({ data }, () => this.selectCreatedResources(resourceId));
      return success;
    }
  }

  async refreshPropertyData() {
    const { data } = this.state;
    let properties = [];
    for (let i = 0; i < data.properties.length; i++) {
      let propertyData = data.properties[i];
      await this.updateResourcesForPropertyOnAgreement(propertyData);
      properties.push(propertyData);
    }
    data.properties = properties;
    this.setState({ data });
  }

  getResourceToAgreementBody(property) {
    const { data } = this.state;
    if (
      data.resourceSearch &&
      data.resourceQuantity &&
      data.resourceUom &&
      (data.resourceUnitValue || data.resourceTotalValue) &&
      data.resourceYear
    ) {
      return [
        {
          resourceUniqueReference: data.resourceId,
          unitOfMeasureEnumId: parseInt(data.resourceUom.value),
          currencyCodeEnumId: 0,
          valuationPerUnitOfMeasure: Math.round(
            Number.parseFloat(data.resourceUnitValue || data.resourceTotalValue / data.resourceQuantity)
          ),
          quantity:
            data.resourceUom.value === 6
              ? Math.round(Number.parseFloat(data.resourceQuantity))
              : Number.parseFloat(data.resourceQuantity),
          totalValuation: Number.parseFloat(data.resourceTotalValue || data.resourceUnitValue * data.resourceQuantity),
          valuationYear: parseInt(data.resourceYear),
          addToProperties: [{ propertyUniqueReference: property.propertyUniqueIdentifier }],
        },
      ];
    } else {
      toast.info("Please fill out all the fields for the new resource to be linked to the Agreement.");
      return false;
    }
  }

  selectCreatedResources(resourceId) {
    this.state.data.properties.forEach((property) => {
      let selectedResource = this.state.data.protectedResources.find(
        (resource) =>
          resource.resourceUniqueReference === resourceId &&
          resource.propertyUniqueIdentifier === property.propertyUniqueIdentifier
      );
      this.setState({
        resourceRowSelected: [...this.state.resourceRowSelected, selectedResource?.agreementResourceUniqueIdentifier],
        protectedResourcesSelected: [...this.state.protectedResourcesSelected, selectedResource],
      });
    });
  }

  handleResourceCheckSelection = (resourceData) => {
    let { data, isInitialConfiguration } = this.state;
    if (isInitialConfiguration) {
      isInitialConfiguration = false;
      data.protectedResources = this.getAvailableResources();
    }

    let selectedResource = data.protectedResources.find(
      (resource) =>
        resource.resourceUniqueReference === resourceData.resourceUniqueReference &&
        resource.propertyUniqueIdentifier === resourceData.propertyUniqueIdentifier
    );
    if (selectedResource) {
      selectedResource.isSelected = !selectedResource.isSelected;
      this.setState({ data, isInitialConfiguration });
    }
  };

  handleSpecificChanges(input) {
    //do nothing
  }

  getAvailableResources() {
    let availableResources = [];
    this.state.data.properties.forEach((property) => {
      availableResources = availableResources.concat(
        property.agreementResources?.map(function (resource) {
          resource.propertyName = property.propertyName;
          resource.propertyUniqueIdentifier = property.propertyUniqueIdentifier;
          return resource;
        })
      );
    });

    return availableResources;
  }

  SearchIcon = () => {
    return <img src={searchIcon} alt="search icon" />;
  };

  handleDamageSelection = (resourceId, e) => {
    let protectedResourcesSelected = this.state.protectedResourcesSelected;
    const resourceToUpdate = protectedResourcesSelected.find(
      (resource) => resource.agreementResourceUniqueIdentifier === resourceId
    );
    if (resourceToUpdate) {
      resourceToUpdate.threatTypeEnumId = e.value;
      resourceToUpdate.damageName = e.label;
      this.setState({ protectedResourcesSelected });
    }
  };

  isResourceDataValid() {
    let isValidData = true;
    this.state.protectedResourcesSelected.forEach((resource) => {
      if (!resource.threatTypeEnumId) isValidData = false;
    });
    return isValidData;
  }

  handleCancel = () => {
    this.props.onHide(null);
    this.clearData();
  };

  clearData() {
    let { data } = this.state;
    data.selectedSpecies = "";
    data.properties = [];
    this.setState({
      isInitialConfiguration: true,
      modalStep: 1,
      protectedResourcesSelected: [],
      resourceRowSelected: [],
      data,
    });
  }

  renderThreatSection = () => {
    const { data } = this.state;
    if (this.state.protectedResourcesSelected.length < 1) {
      return <div className="container">No Resources Selected</div>;
    }
    return (
      <div className="container">
        {this.state.protectedResourcesSelected.map((resource, index) => {
          return (
            <Row key={index}>
              <Col>
                <Card className={styles.threatCard}>
                  <Card.Body>
                    <Row>
                      <Col>
                        <BootstrapTable
                          keyField="agreementResourceUniqueIdentifier"
                          data={[resource]}
                          columns={this.state.selectedResourceColumns}
                          bootstrap4={true}
                          hover={true}
                          bordered={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Select
                          value={
                            data.damages
                              ? data.damages.find((damage) => damage.value === resource.threatTypeEnumId)
                              : ""
                          }
                          aria-label="Threat Type Selection"
                          placeholder="Threat Type"
                          styles={{
                            indicatorSeparator: () => {
                              //do nothing
                            },
                            placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                            option: (styles, { isFocused }) => ({
                              ...styles,
                              fontSize: "14px",
                              fontWeight: "normal",
                              color: "#313131",
                              backgroundColor: isFocused ? "lightgray" : "white",
                            }),
                            singleValue: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                              fontWeight: "normal",
                              color: "313131",
                            }),
                            control: (base) => ({
                              ...base,
                              minHeight: "35px",
                            }),
                            container: (base) => ({
                              ...base,
                              minHeight: "35px",
                            }),
                          }}
                          options={data.damages}
                          onChange={(e) => this.handleDamageSelection(resource.agreementResourceUniqueIdentifier, e)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Button className={styles.addButtonLinksInHeader} variant="link" onClick={() => {}}>
                        + Add another Threat
                      </Button>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  validatePropertyAgreementDates() {
    const { data } = this.state;
    return data.properties.every((property) => property.agreementDate);
  }

  goToStep = async (step) => {
    const goToStep2 = this.state.data.properties.length > 0 && this.validatePropertyAgreementDates();
    const goToStep3 = goToStep2 && this.state.data.selectedSpecies;
    const goToStep4 = goToStep3 && this.state.data.protectedResources.length > 0;
    let allow = false;
    let message = "";
    switch (step) {
      case 1:
        allow = true;
        break;
      case 2:
        allow = goToStep2;
        message = "Please select at least one Property and specify a date.";
        break;
      case 3:
        allow = goToStep3;
        message = "Please select a Damage Agent.";
        break;
      case 4:
        allow = goToStep4;
        message = "Please select at least one Resource.";
        break;
      default:
        return;
    }
    if (allow) {
      if (step === 2) {
        this.setState({ species: await this.getSpeciesByAgreementProperties() });
      }
      this.setState({ modalStep: step });
    } else if (!allow) {
      toast.warning(message);
    }
  };

  render() {
    let bodyContent = null;
    let submitButtonText = "Next";
    let navImage = nav1;
    switch (this.state.modalStep) {
      case 2:
        bodyContent = this.renderSpeciesCardBody(this.state.data);
        navImage = nav2;
        break;
      case 3:
        bodyContent = this.renderResourceModalSection();
        navImage = nav3;
        break;
      case 4:
        bodyContent = this.renderThreatSection();
        submitButtonText = "Save";
        navImage = nav4;
        break;
      default:
        bodyContent = this.renderPropertyCardBody(this.state.data, this.state.isFormEditable);
        break;
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleCancel} backdrop="static" centered size="xl">
          <Modal.Header className={globalStyles.modalHeader}>
            <Row className={globalStyles.modalHeaderRow}>
              <Col className="pl-0">
                <Modal.Title className={globalStyles.modalTitleText}>Create Conflict</Modal.Title>
              </Col>
              <Col className="pr-0">
                <CloseButton onClick={this.handleCancel} />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <Image src={navImage} useMap="#createConflictStepperMap" />
              <map name="createConflictStepperMap">
                <area
                  alt="Add Properties"
                  title="Add Properties"
                  onClick={() => this.goToStep(1)}
                  coords="154,32,9,1"
                  shape="rect"
                />
                <area
                  alt="Add Damage Agent"
                  title="Add Damage Agent"
                  onClick={() => this.goToStep(2)}
                  coords="164,2,310,31"
                  shape="rect"
                />
                <area
                  alt="Add Resources"
                  title="Add Resources"
                  onClick={() => this.goToStep(3)}
                  coords="327,3,460,31"
                  shape="rect"
                />
                <area
                  alt="Add Threat"
                  title="Add Threat"
                  onClick={() => this.goToStep(4)}
                  coords="480,3,621,33"
                  shape="rect"
                />
              </map>
            </div>
            {bodyContent}
            <div style={{ paddingBottom: "150px" }}></div>
          </Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.handleCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className={globalStyles.ModalCreateButton} onClick={(e) => this.handleModalSubmit(e)}>
              <span className={globalStyles.ModalCreateButtonText}>{submitButtonText}</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default withRouter(CreateConflictModal);
