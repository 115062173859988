import React from "react";
import { Modal, Button, Row, Col, CloseButton, Form } from "react-bootstrap";
import CustomForm from "../../../components/common/form";
import globalStyles from "../../../OARS.module.scss";

class RejectAgreementModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: { reasonForRejection: "" },
      errors: {},
    };
  }

  handleSpecificChanges = () => {
    //do nothing
  };

  doSubmit = () => {
    if (!this.state.data.reasonForRejection) {
      const errors = { ...this.state.errors };
      errors.reasonForRejection = "You must provide the reason(s) for rejection.";
      this.setState({ errors });
    } else {
      this.props.onHide();
      this.props.onSave(this.state.data);
      this.clearData();
    }
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.reasonForRejection = "";
    this.setState({ data });
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Reject</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 my-3 text-left">
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Reason for Rejection</Form.Label>
                <Form.Control
                  name="reasonForRejection"
                  value={this.state.data.reasonForRejection}
                  placeholder="Enter Reasons for Rejection"
                  as="textarea"
                  rows="5"
                  style={{ resize: "none" }}
                  onChange={this.handleChange}
                />
                {this.state.errors.reasonForRejection && (
                  <div className="alert alert-danger">{this.state.errors.reasonForRejection}</div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>Reject</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RejectAgreementModal;
