import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import styles from "./Contact.module.scss";
import globalStyles from "../../../OARS.module.scss";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import { withRouter } from "react-router-dom";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import PhoneNumber from "../../common/phonenumber";
import ContactOrgAddress from "../../common/ContactOrgAddress";
import AssociationCard from "../../common/AssociationCard";
import DuplicateContactModal from "./DuplicateContactModal";
import SuccessfulRecordModifiedModal from "../../common/create-updateOkMessageModal";
import { toast } from "react-toastify";
import UtilityFunctions from "../../common/UtilityFunctions";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

class CreateContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      showModal: false,
      modalData: {},
      modalCreateData: {},
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "",
      preferredName: "",
      emailAddress: "",
      contactTypes: [],
      typeOfContactEnumId: "3",
      phoneNumberTypes: [],
      phoneNumbers: [
        {
          phoneNumber: "",
          phoneTypeEnumId: "1",
        },
      ],
      mailingStreet: "",
      mailingCity: "",
      mailingStateUniqueId: "",
      mailingZip: "",
      mailingCountryUniqueId: "",
      otherAddresses: [],
      usaStates: [],
      countries: [],
      contactProperties: [],
      propertyRoleTypes: [],
      contactOrganizations: [],
      organizationRoleTypes: [],
      showHappyModal: false,
    };
  }

  async componentDidMount() {
    let states = [];
    let countries = [];
    let contactTypes = [];

    if (this.state.usaStates.length === 0) {
      let result = await ReferenceFileAPI.GetUSStates();
      states = result
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      this.setState({ usaStates: states });
      this.setState({ statesForOtherAddress: states });
    }
    if (this.state.countries.length === 0) {
      let result = await ReferenceFileAPI.GetAllCountries();
      countries = result
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      const usaObject = countries.find((country) => country.name === "United States of America");
      const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
      const dod = this.state.usaStates?.find((d) => d.name.toUpperCase() === "DEPARTMENT OF DEFENSE");
      const otherAddressObj = {
        mailingStreet: "",
        mailingCity: "",
        mailingStateUniqueId: "",
        mailingCountryUniqueId: currentUsaState.stateGuid !== dod?.id ? usaObject?.id : "",
        mailingZip: "",
      };
      let otherAddress = this.state.otherAddresses;
      otherAddress.push(otherAddressObj);
      if (currentUsaState.stateGuid !== dod?.id) {
        this.setState({ mailingCountryUniqueId: usaObject.id });
      }
      this.setState({ countries: countries, otherAddress });
    }
    if (this.state.phoneNumberTypes.length === 0) {
      const phoneNumberTypes = await AgreementEntitiesAPI.GetPhoneNumberTypes();
      if (phoneNumberTypes) {
        this.setState({ phoneNumberTypes: phoneNumberTypes?.data });
      }
    }
    if (this.state.contactTypes.length === 0) {
      contactTypes = await AgreementEntitiesAPI.GetContactTypes();
      if (contactTypes) {
        this.setState({ contactTypes: contactTypes.data });
      }
    }
    if (this.state.propertyRoleTypes.length === 0) {
      let roleTypes = await AgreementEntitiesAPI.GetAllPropertyContactRoles();
      if (roleTypes) {
        if (roleTypes.data) {
          this.setState({ propertyRoleTypes: roleTypes.data });
        }
      }
    }
    window.scrollTo(0, 0);
  }

  handleStateChange = async ({ currentTarget: input }) => {
    let propertyName = input.id;
    let data = this.state;
    let propertyValue = input.value;
    if (propertyName === "mailingCountryUniqueId") {
      await this.updateAvailableStates(propertyValue, false);
      this.setState({ mailingCountryUniqueId: propertyValue });
    }
    data[propertyName] = propertyValue;

    this.setState(data);
  };

  isMailingAddressPopulated() {
    let data = this.state;
    return (
      data.mailingStreet !== "" || data.mailingCity !== "" || data.mailingStateUniqueId !== "" || data.mailingZip !== ""
    );
  }

  async updateAvailableStates(countryId, otherAddress) {
    const states = await this.getStatesFromAPI(countryId);
    if (states) {
      if (otherAddress) {
        this.setState({ statesForOtherAddress: states });
      } else {
        this.setState({ usaStates: states });
      }
    }
  }

  async getStatesFromAPI(countryId) {
    const results = await ReferenceFileAPI.GetStatesByCountry(countryId);

    if (results) {
      return results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  handlePhoneNumberChange = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    let data = this.state;
    data.phoneNumbers[index][fieldName] = UtilityFunctions.formatPhoneNumber(input.value);
    this.setState(data);
  };

  handleExtraPhoneNumber = ({ currentTarget: input }) => {
    let data = this.state;
    if (input.value === "add") {
      data.phoneNumbers.push({ phoneNumber: "", phoneTypeEnumId: "-1" });
    } else {
      data.phoneNumbers.splice(input.value, 1);
    }
    this.setState(data);
  };

  handleAddressChanges = async ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];
    let propertyValue = input.value;

    let data = this.state;
    if (fieldName === "mailingCountryUniqueId") {
      await this.updateAvailableStates(propertyValue, true);
    }
    data.otherAddresses[index][fieldName] = propertyValue;
    this.setState(data);
  };

  handleExtraAddress = ({ currentTarget: input }) => {
    let data = this.state;

    if (input.value === "add") {
      data.otherAddresses.push({
        mailingStreet: "",
        mailingCity: "",
        mailingStateUniqueId: "",
        mailingCountryUniqueId: this.state.mailingCountryUniqueId,
        mailingZip: "",
      });
    } else {
      data.otherAddresses.splice(input.value, 1);
    }

    this.setState(data);
  };

  handleContactPropertyRoleChange = (e, index) => {
    let data = this.state;
    data.contactProperties[index].roleType = parseInt(e.target.value);
    this.setState(data);
  };

  handleContactPropertiesRemove = (e, index) => {
    let data = this.state;
    data.contactProperties.splice(index, 1);
    this.setState(data);
  };

  handleContactOrganizationsRoleChange = (e, index) => {
    let data = this.state;
    data.contactOrganizations[index].roleType = parseInt(e.target.value);
    this.setState(data);
  };

  handleContactOrganizationsRemove = (e, index) => {
    let data = this.state;
    data.contactOrganizations.splice(index, 1);
    this.setState(data);
  };

  handleOrgSearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfOrganizationIds: [1, 2, 3, 4],
    };
    const orgsData = await AgreementEntitiesAPI.FilterOrganization(searchObj);
    var outputOrg = "";
    if (orgsData.data.results) {
      let results = orgsData.data.results;
      outputOrg = results.map((result) => ({
        value: result.organizationUniqueIdentifier,
        label: result.name,
      }));
    }
    return outputOrg;
  };

  handleOrgChange = (input) => {
    const data = this.state;
    data.contactOrganizations.push(input);
    this.setState(data);
    return input;
  };

  handlePropertySearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 100,
      pageNumber: 1,
    };
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    var outputProperties = [];

    if (currentUsaState) {
      const propertiesData = await AgreementEntitiesAPI.SearchPropertiesFreeText(searchObj, currentUsaState.stateGuid);
      if (propertiesData?.data?.results) {
        let results = propertiesData.data.results;
        outputProperties = results.map((result) => ({
          value: result.propertyUniqueIdentifier,
          label: result.propertyName,
        }));
      }
    }

    return outputProperties;
  };

  handlePropertyChange = (input) => {
    const data = this.state;
    data.contactProperties.push(input);
    this.setState(data);
    return input;
  };

  handleSubmitWrapper = (e) => {
    e.preventDefault();
    this.handleSubmit(e);
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  async handleSubmit(e) {
    let form = e.currentTarget;
    let phoneValidated = true;
    let emailValidated = true;

    for (var i = 0; i < this.state.phoneNumbers.length; i++) {
      var currentPhone = this.state.phoneNumbers[i];
      if (currentPhone.phoneNumber !== "") {
        phoneValidated = UtilityFunctions.validatePhoneNumber(currentPhone.phoneNumber);
      }
      if (currentPhone.phoneNumber !== "" && currentPhone.phoneTypeEnumId === "-1") {
        phoneValidated = false;
      }
    }

    if (this.state.emailAddress !== "") {
      emailValidated = UtilityFunctions.validateEmail(this.state.emailAddress);
    }

    let extraValidation = phoneValidated && emailValidated;

    if (form.checkValidity() === false || !extraValidation) {
      if (!emailValidated) {
        toast.warning("If entering an email address, it must be in valid email format.");
      } else if (!phoneValidated) {
        toast.warning("If including a phone number, please use a valid format with a type selected.");
      } else {
        toast.warning("Please fill out all required fields.");
      }
      return;
    }
    this.setState({ validated: true });
    let verifiedOthers = [];
    let verifiedPhones = [];

    for (var i = 0; i < this.state.otherAddresses.length; i++) {
      var address = this.state.otherAddresses[i];
      if (
        address.mailingStreet !== "" &&
        address.mailingCity !== "" &&
        address.mailingStateUniqueId !== "" &&
        address.mailingZip !== "" &&
        address.mailingCountryUniqueId !== ""
      ) {
        verifiedOthers.push({
          mailingStreet: address.mailingStreet,
          mailingCity: address.mailingCity,
          mailingStateUniqueId: address.mailingStateUniqueId,
          mailingZip: address.mailingZip,
          mailingCountryUniqueId: address.mailingCountryUniqueId,
          addressPurpose: "other",
        });
      }
    }

    for (var i = 0; i < this.state.phoneNumbers.length; i++) {
      var phone = this.state.phoneNumbers[i];
      if (phone.phoneNumber !== "" && phone.phoneTypeEnumId !== "") {
        verifiedPhones.push({
          phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(phone.phoneNumber),
          phoneTypeEnumId: parseInt(phone.phoneTypeEnumId),
        });
      }
    }

    const primaryToSend = this.isMailingAddressPopulated()
      ? {
        mailingStreet: this.state.mailingStreet,
        mailingCity: this.state.mailingCity,
        mailingStateUniqueId: this.state.mailingStateUniqueId ? this.state.mailingStateUniqueId : null,
        mailingZip: this.state.mailingZip,
        mailingCountryUniqueId: this.state.mailingCountryUniqueId,
        addressPurpose: "mailing",
      }
      : null;

    var contactRecord = {
      firstName: this.state.firstName,
      middleName: this.state.middleName,
      lastName: this.state.lastName,
      suffix: this.state.suffix,
      preferredName: this.state.preferredName,
      typeOfContactEnumId: parseInt(this.state.typeOfContactEnumId),
      emailAddress: this.state.emailAddress,
      phoneNumbers: verifiedPhones,
      primaryMailingAddress: primaryToSend,
      otherAddresses: verifiedOthers,
    };

    const duplicateContact = await AgreementEntitiesAPI.FindDuplicateContacts(contactRecord, 1);
    if (duplicateContact?.data && duplicateContact.data.length > 0) {
      this.setState({ showModal: true, modalData: duplicateContact.data[0], modalCreateData: contactRecord });
    } else {
      this.defaultCreateContact(contactRecord);
    }
  }

  async defaultCreateContact(contactRecord) {
    let createAPIResult = await AgreementEntitiesAPI.CreateContact(contactRecord);
    if (createAPIResult) {
      if (createAPIResult.successful) {
        let contactGuid = createAPIResult.data.contactUniqueId;
        let addContactsToPropertiesStatus = await this.addContactsToPropertiesCall(contactGuid);
        let addContactsToOrgsStatus = await this.addContactsToOrgsCall(contactGuid);

        if (addContactsToPropertiesStatus && addContactsToOrgsStatus) {
          this.setState({ showHappyModal: true });
        } else if (!addContactsToPropertiesStatus) {
          toast.warning("Contact creation successful, property association error.");
        } else if (!addContactsToOrgsStatus) {
          toast.warning("Contact creation successful, Special Group association error.");
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create contact",
          apiName: "CreateContact",
          responseUnsuccessful: createAPIResult?.unsuccessful,
          responseMessage: createAPIResult?.message,
        });
      }
    }
  }

  async addContactsToPropertiesCall(contactGuid) {
    let propertyAssociationSuccess = true;
    for (var i = 0; i < this.state.contactProperties.length; i++) {
      let currentProperty = this.state.contactProperties[i];
      let associationAPIResult = await AgreementEntitiesAPI.AddContactToProperty(
        currentProperty.value,
        contactGuid,
        currentProperty.roleType
      );
      if (associationAPIResult) {
        if (!associationAPIResult.successful) {
          propertyAssociationSuccess = false;
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to add contact to property",
            apiName: "AddContactToProperty",
            responseUnsuccessful: associationAPIResult?.unsuccessful,
            responseMessage: associationAPIResult?.message,
          });
        }
      } else {
        propertyAssociationSuccess = false;
      }
    }
    return propertyAssociationSuccess;
  }

  async addContactsToOrgsCall(contactGuid) {
    let orgsAssociationSuccess = true;
    for (var i = 0; i < this.state.contactOrganizations.length; i++) {
      let currentOrg = this.state.contactOrganizations[i];
      let associationAPIResult = await AgreementEntitiesAPI.AssignOrganizationPointOfContact(
        currentOrg.value,
        contactGuid
      );
      if (associationAPIResult) {
        if (!associationAPIResult.successful) {
          orgsAssociationSuccess = false;
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to assign organization POC",
            apiName: "AssignOrganizationPointOfContact",
            responseUnsuccessful: associationAPIResult?.unsuccessful,
            responseMessage: associationAPIResult?.message,
          });
        }
      } else {
        orgsAssociationSuccess = false;
      }
    }
    return orgsAssociationSuccess;
  }

  getAddressComponent() {
    if (this.state.statesForOtherAddress !== undefined && this.state.countries.length > 0) {
      return (
        <ContactOrgAddress
          addresses={this.state.otherAddresses}
          countries={this.state.countries}
          states={this.state.statesForOtherAddress}
          onAddressChange={this.handleAddressChanges}
          onExtraAddress={this.handleExtraAddress}
        />
      );
    }
  }

  closeHappyModal = () => {
    this.setState({ showHappyModal: false });
  };
  render() {
    let successModal = null;
    if (this.state.showHappyModal) {
      successModal = (
        <SuccessfulRecordModifiedModal
          show={this.state.showHappyModal}
          onHide={this.closeHappyModal}
          messageType="create"
          onAcknowledgeRoute="/home"
          objectName="Contact"
        />
      );
    }
    return (
      <div className={styles.Contact} data-testid="CreateContact">
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmitWrapper}>
          <h5 className={styles.fieldLabels}>Create Contact</h5>
          <Card className={styles.createPageCards}>
            <Card.Header as="h4" className={styles.cardTitles}>
              Name
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <h6 className={styles.fieldLabels}>
                    First Name<span className={globalStyles.asterisk508}>*</span>
                  </h6>
                  <Form.Control
                    required
                    id="firstName"
                    data-testid="contactFirstName"
                    type="text"
                    placeholder="Enter Name"
                    onChange={this.handleStateChange}
                    title="First Name"
                  />
                </Col>
                <Col>
                  <h6 className={styles.fieldLabels}>Middle Name</h6>
                  <Form.Control
                    id="middleName"
                    data-testid="contactMiddleName"
                    type="text"
                    placeholder="Enter Name"
                    onChange={this.handleStateChange}
                    title="Middle Name"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className={styles.fieldLabels}>
                    Last Name<span className={globalStyles.asterisk508}>*</span>
                  </h6>
                  <Form.Control
                    required
                    id="lastName"
                    data-testid="contactLastName"
                    type="text"
                    placeholder="Enter Name"
                    onChange={this.handleStateChange}
                    title="Last Name"
                  />
                </Col>
                <Col>
                  <h6 className={styles.fieldLabels}>Suffix</h6>
                  <Form.Control
                    id="suffix"
                    data-testid="contactSuffix"
                    type="text"
                    placeholder="Enter Suffix"
                    onChange={this.handleStateChange}
                    title="Suffix"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <h6 className={styles.fieldLabels}>Preferred Name</h6>
                  <Form.Control
                    id="preferredName"
                    data-testid="contactPreferredName"
                    type="text"
                    placeholder="Enter Name"
                    onChange={this.handleStateChange}
                    title="Preferred Name"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className={styles.createPageCards}>
            <Card.Header as="h4" className={styles.cardTitles}>
              Phone &amp; Email
            </Card.Header>
            <Card.Body>
              <PhoneNumber
                phoneNumbers={this.state.phoneNumbers}
                phoneNumberTypes={this.state.phoneNumberTypes}
                onPhoneNumberChange={this.handlePhoneNumberChange}
                onExtraPhoneNumber={this.handleExtraPhoneNumber}
              />
              <Row>
                <Col>
                  <h6 className={styles.fieldLabels}>Email Address</h6>
                  <Form.Control
                    id="emailAddress"
                    data-testid="contactEmailAddress"
                    type="email"
                    placeholder="Enter Email"
                    onChange={this.handleStateChange}
                    title="Email Address"
                  />
                </Col>
                <Col></Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className={styles.createPageCards}>
            <Card.Header as="h4" className={styles.cardTitles}>
              Mailing Address
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <h6 className={styles.fieldLabels}>Street Address or P.O. Box</h6>
                  <Form.Control
                    id="mailingStreet"
                    data-testid="mailingAddressInput"
                    type="text"
                    placeholder="Enter Address"
                    onChange={this.handleStateChange}
                    title="Street Address or P.O. Box"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className={styles.fieldLabels}>City</h6>
                  <Form.Control
                    id="mailingCity"
                    data-testid="mailingCityInput"
                    type="text"
                    placeholder="Enter City"
                    onChange={this.handleStateChange}
                    title="City"
                  />
                </Col>
                <Col>
                  <h6 className={styles.fieldLabels}>State{<span className={globalStyles.asterisk508}>*</span>}</h6>
                  <Form.Control
                    as="select"
                    id="mailingStateUniqueId"
                    data-testid="mailingStateInput"
                    type="text"
                    onChange={this.handleStateChange}
                    title="State"
                    required={true}
                  >
                    <option>Select State</option>
                    {this.state.usaStates.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col>
                  <h6 className={styles.fieldLabels}>
                    Country<span className={globalStyles.asterisk508}>*</span>
                  </h6>
                  <Form.Control
                    as="select"
                    id="mailingCountryUniqueId"
                    data-testid="mailingCountryInput"
                    type="text"
                    value={this.state.mailingCountryUniqueId}
                    onChange={this.handleStateChange}
                    title="Country"
                    required={true}
                  >
                    <option>Select Country</option>
                    {this.state.countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col>
                  <h6 className={styles.fieldLabels}>Zip Code</h6>
                  <Form.Control
                    id="mailingZip"
                    data-testid="mailingZipInput"
                    type="text"
                    placeholder="Enter Zip"
                    onChange={this.handleStateChange}
                    title="Zip Code"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className={styles.createPageCards}>
            <Card.Header as="h4" className={styles.cardTitles}>
              Other Addresses
            </Card.Header>
            <Card.Body>{this.getAddressComponent()}</Card.Body>
          </Card>
          {/* 8-9-2022 Hiding these cards for now */}
          {/* <AssociationCard
            loadOptions={this.handleOrgSearch}
            chosenResults={this.state.contactOrganizations}
            onChange={this.handleOrgChange}
            onRemove={this.handleContactOrganizationsRemove}
            createLink="/organization/create"
            roleTypes={null}
            noun="Special Group"
            onRoleChange={this.handleContactOrganizationsRoleChange}
            styles={styles}
            history={this.props.history}
          />
          <AssociationCard
            loadOptions={this.handlePropertySearch}
            chosenResults={this.state.contactProperties}
            onChange={this.handlePropertyChange}
            onRemove={this.handleContactPropertiesRemove}
            createLink="/property/create"
            roleTypes={this.state.propertyRoleTypes}
            noun="Property"
            onRoleChange={this.handleContactPropertyRoleChange}
            styles={styles}
            history={this.props.history}
          /> */}
          <div className={styles.createPageButtonContainer}>
            <Button
              // className={globalStyles.modalCancelButton}
              variant="outline-primary"
              onClick={() => this.props.history.push("/home")}
            >
              <span>Cancel</span>
            </Button>
            <Button className={styles.buttons} type="submit" variant="primary">
              Create
            </Button>
          </div>
        </Form>
        <DuplicateContactModal
          show={this.state.showModal}
          onHide={this.closeModal}
          data={this.state.modalData}
          createData={this.state.modalCreateData}
          usaStates={this.state.usaStates}
          countries={this.state.countries}
          contactProperties={this.state.contactProperties}
          contactOrganizations={this.state.contactOrganizations}
        />
        {successModal}
      </div>
    );
  }
}

export default withRouter(CreateContact);
