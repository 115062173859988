import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Card, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter, textFilter, customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileCardHeader from "../ReferenceFiles/ReferenceFileCardHeader";
import { toast } from "react-toastify";
import CustomForm from "../common/form";
import globalStyles from "../../OARS.module.scss";
import styles from "./Warehouse.module.scss";
import InventoryAPI from "../../api/Inventory/InventoryAPI";
import CustomTextFilter from "../common/customTextFilter";
import UtilityFunctions from "../common/UtilityFunctions";
import CreateWarehouseModal from "./CreateWarehouseModal";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";

class CMITSListingPage extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      tableColumns: [
        { dataField: "warehouseUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      currentUsaState: "",
      warehousesData: [],
      warehouseTypes: [],
      availableStatesForProfile: [],
      showCreateModal: false,
      showHappyModal: false,
      happyModalMessageType: "",
      happyModalObjectType: "",
      happyModalOnAcknowledgeRoute: "",
      happyModalRouteData: "",
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState.stateGuid) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
    }

    const warehouseTypes = await this.getAvailableWarehouseTypes();
    const allStatesData = await this.getAllStatesFromAPI();

    this.setState({
      currentUsaState,
      warehousesData: await this.getWarehousesData(currentUsaState),
      warehouseTypes,
      tableColumns: this.getTableColumns(warehouseTypes),
      availableStatesForProfile: await this.getAvailableStates(allStatesData),
      allStatesData,
      showCreateWarehouseModal: false,
      isFormLoading: false,
    });
  }

  async getWarehousesData(currentUsaState) {
    let warehousesData = [];
    const getWarehousesResponse = await InventoryAPI.GetAllWarehousesByState(currentUsaState.stateGuid);
    if (getWarehousesResponse?.successful && getWarehousesResponse.data?.results?.length > 0) {
      warehousesData = getWarehousesResponse.data.results;
    }
    return warehousesData;
  }

  async getAvailableWarehouseTypes() {
    let availableWarehouseTypes = [];
    const warehouseTypesResponse = await InventoryAPI.GetAllWarehouseTypes();
    if (warehouseTypesResponse?.successful && warehouseTypesResponse.data?.length > 0) {
      availableWarehouseTypes = warehouseTypesResponse.data.map((wh) => ({
        id: wh.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(wh),
      }));
    }
    return availableWarehouseTypes;
  }

  async getAllStatesFromAPI() {
    let usaStates = [];
    const usaStatesResponse = await ReferenceFileAPI.GetAllStates();
    if (usaStatesResponse?.length > 0) {
      usaStates = usaStatesResponse;
    }
    return usaStates;
  }

  async getAvailableStates(usaStates) {
    let availableStates = [];
    if (this.props.userData.currentUserProfile?.profileAssociatedStates?.length > 0) {
      if (usaStates?.length > 0) {
        for (const associatedStateId of this.props.userData.currentUserProfile.profileAssociatedStates) {
          const associatedState = usaStates.find((state) => state.stateUniqueIdentifier === associatedStateId);
          if (associatedState) {
            availableStates.push({
              value: associatedStateId,
              label: associatedState.state_name,
              code: associatedState.state_code,
            });
          }
        }
      }
    } else {
      const userPermissionsResponse = await RoleAuthorizationAPI.MyPermissions();
      if (userPermissionsResponse?.successful && userPermissionsResponse.data?.associatedStates?.length > 0) {
        if (usaStates?.length > 0) {
          for (const associatedStateId of userPermissionsResponse.data.associatedStates) {
            const associatedState = usaStates.find((state) => state.stateUniqueIdentifier === associatedStateId);
            if (associatedState) {
              availableStates.push({
                value: associatedStateId,
                label: associatedState.state_name,
                code: associatedState.state_code,
              });
            }
          }
        }
      }
    }

    return availableStates;
  }

  getTableColumns(warehouseTypes) {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
      paddingRight: "1.5rem",
    };
    let typesToFilter = {};
    warehouseTypes.forEach((t) => {
      typesToFilter[t.id] = t.name;
    });
    return [
      {
        dataField: "warehouseUniqueIdentifier",
        hidden: true,
        csvText: "Warehouse Unique Id",
      },
      {
        dataField: "warehouseName",
        text: "Warehouse Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Warehouse Name" />
        ),
        formatter: (cell, row) => {
          return (
            <Button variant="link" onClick={() => this.handleWarehouseDetailsNavigation(row)}>
              <span className={globalStyles.formDataLinks}>{cell}</span>
            </Button>
          );
        },
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "warehouseNameHeader" },
        attrs: { headers: "warehouseNameHeader", tabIndex: "0" },
      },
      {
        dataField: "warehouseType",
        text: "Type",
        filter: selectFilter({
          options: typesToFilter,
          onFilter: this.filterWarehouseTypeColumn,
        }),
        formatter: (cell, row, rowIndex) => {
          let typeName = "";
          if (cell) {
            typeName = UtilityFunctions.getDisplayTextFromFieldObject(cell);
          }
          return typeName;
        },
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "warehouseTypeHeader" },
        attrs: { headers: "warehouseTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "warehouseStreetAddress",
        text: "Address",
        filter: textFilter({
          onFilter: this.filterWarehouseAddressColumn,
        }),
        formatter: (cell, row, rowIndex) => {
          return `${row.warehouseStreetAddress} ${row.warehouseCity}, ${row.warehouseZipcode}`;
        },
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === "asc") {
            return b - a;
          }
          return a - b; // desc
        },
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700, width: "30%" },
        headerAttrs: { id: "warehouseAddressHeader" },
        attrs: { headers: "warehouseAddressHeader", tabIndex: "0" },
      },
      {
        dataField: "warehouseStateName",
        text: "State",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Warehouse State" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "warehouseStateNameHeader" },
        attrs: { headers: "warehouseStateNameHeader", tabIndex: "0" },
      },
    ];
  }

  filterWarehouseAddressColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (entry) =>
          entry.warehouseStreetAddress?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          entry.warehouseCity?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          entry.warehouseZipcode?.toLowerCase().includes(filterValue?.toLowerCase())
      );
    }
  };

  filterWarehouseTypeColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) => entry.warehouseType?.id === parseInt(filterValue));
    }
  };

  handleWarehouseDetailsNavigation = (row) => {
    this.props.history.push({
      pathname: "/warehouse/details",
      state: {
        entityData: row,
        states: this.state.allStatesData,
      },
    });
  };

  showWarehouseCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  handleCreateWarehouse = async (warehouseData) => {
    let entityData = null;
    const createWarehouseResponse = await InventoryAPI.CreateWarehouse(this.getCreateWarehouseBody(warehouseData));
    if (createWarehouseResponse?.successful) {
      entityData = createWarehouseResponse.data;
      if (!warehouseData.isActive && createWarehouseResponse.data?.warehouseUniqueIdentifier) {
        const deactivateWarehouseResponse = await this.deactivateWarehouse(createWarehouseResponse);
        if (deactivateWarehouseResponse?.successful) {
          entityData = deactivateWarehouseResponse.data;
        }
      }
      this.setState({
        happyModalMessageType: "create",
        happyModalObjectType: "Warehouse",
        showHappyModal: true,
        happyModalOnAcknowledgeRoute: "/warehouse/details",
        happyModalRouteData: {
          entityData: entityData,
          availableStates: this.state.availableStatesForProfile,
          isWarehouseCreation: true,
        },
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create warehouse",
        apiName: "CreateWarehouse",
        responseUnsuccessful: createWarehouseResponse?.unsuccessful,
        responseMessage: createWarehouseResponse?.message,
      });
    }
  };

  getCreateWarehouseBody(warehouseData) {
    return {
      warehouseTypeEnumId: warehouseData.warehouseType,
      warehouseName: warehouseData.warehouseName,
      warehouseStreetAddress: warehouseData.streetAddress,
      warehouseStateUniqueReference: warehouseData.warehouseState,
      warehouseCountryUniqueReference: warehouseData.country,
      warehouseCity: warehouseData.city,
      warehouseZipcode: warehouseData.zipCode,
      description: warehouseData.description,
      warehouseManagerWSUserProfileUniqueReference: warehouseData.warehouseManager?.value,
      warehouseInventory: [],
      warehouseMembers: [],
    };
  }

  async deactivateWarehouse(createWarehouseResponse) {
    const deactivateWarehouseResponse = await InventoryAPI.DeactivateWarehouse(
      createWarehouseResponse.data.warehouseUniqueIdentifier
    );
    if (!deactivateWarehouseResponse?.successful) {
      ErrorHandler.showErrorWithDetails(
        deactivateWarehouseResponse,
        "System failed to deactivate warehouse. Please contact system admin for more details."
      );
    }
    return deactivateWarehouseResponse;
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading CMITS Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.cmitsListingPage}>
          <Form onSubmit={this.handleSubmit}>
            <ToolkitProvider
              keyField="warehouseUniqueIdentifier"
              data={this.state.warehousesData}
              columns={this.state.tableColumns}
              filter={filterFactory()}
              bootstrap4={true}
              hover={true}
              exportCSV={{
                fileName: "warehouses-data.csv",
                ignoreHeader: false,
                noAutoBOM: false,
                onlyExportFiltered: true,
                exportAll: false,
              }}
            >
              {(props) => (
                <div>
                  <Card className="mt-5">
                    <Card.Body>
                      <ReferenceFileCardHeader
                        referenceFileType="Warehouse"
                        permissions={this.props.permissions}
                        createButtonEvent={this.showWarehouseCreateModal}
                        {...props.csvProps}
                      />
                      <BootstrapTable
                        keyField="warehouseUniqueIdentifier"
                        filter={filterFactory()}
                        bordered={false}
                        {...props.baseProps}
                        defaultSorted={this.state.defaultSort}
                        defaultSortDirection="asc"
                      />
                    </Card.Body>
                  </Card>
                </div>
              )}
            </ToolkitProvider>
          </Form>
        </div>
        <CreateWarehouseModal
          show={this.state.showCreateModal}
          onHide={() => {
            this.setState({ showCreateModal: false });
          }}
          onCreateWarehouse={this.handleCreateWarehouse}
          availableStates={this.state.availableStatesForProfile}
        />
        <SuccessfulRecordModifiedModal
          show={this.state.showHappyModal}
          onHide={() => {
            this.setState({ showHappyModal: false });
          }}
          messageType={this.state.happyModalMessageType}
          objectName={this.state.happyModalObjectType}
          onAcknowledgeRoute={this.state.happyModalOnAcknowledgeRoute}
          routeData={this.state.happyModalRouteData}
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(CMITSListingPage);
