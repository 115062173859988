import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./AdminCenter.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";

import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import filterFactory, { selectFilter, customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import UtilityFunctions from "../common/UtilityFunctions";
import CustomTextFilter from "../common/customTextFilter";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class AdditionalPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      services: [],
      roleName: "",
      stateString: "",
      additionalPermissionLineItems: [],
      columns: [],
      columnFilterStates: [],
      scopes: [],
      regularPermissionsThatRoleHas: [],
      checkedForRoleRegularPermissions: false,
      specialPermissionsToAdd: [],
      specialPermissionsToRemove: [],
      showEditConfirmationModal: false,
    };
  }

  async componentDidMount() {
    if (this.state.permissions.length === 0) {
      var permissionsCall = await RoleAuthorizationAPI.GetAllPermissions();
      if (permissionsCall.successful) {
        this.setState({ permissions: permissionsCall.data });
      }
    }
    if (!this.state.roleName) {
      var rolesCall = await RoleAuthorizationAPI.GetAllRoles();
      if (rolesCall.successful) {
        var roleName = rolesCall.data.results.find(
          (r) => r.wsRoleUniqueIdentifier === this.props.history.location.state.userProfileData.roleUniqueReference
        )?.roleName;
        this.setState({ roleName });
      }
    }
    if (!this.state.stateString) {
      let statesString = "";
      this.props.history.location.state.userProfileData.profileAssociatedStates.map((profileState, index) => {
        var stateCode = this.props.history.location.state.states.find((s) => s.id === profileState).state_code;
        statesString += stateCode;
        if (index !== this.props.history.location.state.userProfileData.profileAssociatedStates.length - 1) {
          statesString += ", ";
        }
      });
      this.setState({ statesString });
    }
    if (!this.state.checkedForRoleRegularPermissions) {
      var getPermissionsForRoleAction = await RoleAuthorizationAPI.GetPermissionsForRole(
        this.props.history.location.state.userProfileData.roleUniqueReference
      );
      if (getPermissionsForRoleAction.successful) {
        this.setState({ regularPermissionsThatRoleHas: getPermissionsForRoleAction.data });
      }
    }
    this.buildTable();
    this.setState({ columns: this.getColumns() });
  }

  buildTable() {
    let additionalPermissionLineItems = [];
    let tableItemId = 1;
    let services = [];
    let scopes = [];
    this.state.permissions.forEach((p) => {
      var splitName = p.name.split("_");
      var service = UtilityFunctions.capitalizeString(splitName[0]);
      if (!services.includes(service)) {
        services.push(service);
      }
      var name = UtilityFunctions.capitalizeString(splitName[1]);
      var scope = UtilityFunctions.capitalizeString(splitName[2]);
      if (!scopes.includes(scope)) {
        scopes.push(scope);
      }
      if (scope === "Assignedstates") {
        tableItemId = this.buildStatePermissionOptions(
          additionalPermissionLineItems,
          tableItemId,
          p,
          service,
          name,
          scope
        );
      } else {
        tableItemId = this.buildNonStatePermissionOption(
          additionalPermissionLineItems,
          tableItemId,
          p,
          service,
          name,
          scope
        );
      }
    });
    let stateArrayToTurnIntoEnum = this.state.statesString.replace(/\s/g, "").split(",");
    stateArrayToTurnIntoEnum.push("N/A");
    this.setState({
      additionalPermissionLineItems,
      services: this.turnArrayIntoEnum(services),
      columnFilterStates: this.turnArrayIntoEnum(stateArrayToTurnIntoEnum),
      scopes: this.turnArrayIntoEnum(scopes),
    });
  }

  buildNonStatePermissionOption(additionalPermissionLineItems, tableItemId, p, service, name, scope) {
    additionalPermissionLineItems.push({
      additionalPermissionLineItemId: tableItemId,
      apiEnumId: p.id,
      service: service,
      name: name,
      state: "N/A",
      scope: scope,
      havePermission: this.props.history.location.state.userProfileData.specialPermissionsForProfile.find(
        (sp) => sp.specialPermission.id === p.id
      )
        ? true
        : false,
      isRegularPermission: this.state.regularPermissionsThatRoleHas.find((x) => x.id === p.id) ? true : false,
    });
    tableItemId++;
    return tableItemId;
  }

  buildStatePermissionOptions(additionalPermissionLineItems, tableItemId, p, service, name, scope) {
    var stateArray = this.state.statesString.replace(/\s/g, "").split(",");
    stateArray.forEach((s) => {
      let stateGuid = this.props.history.location.state.states.find((state) => state.state_code === s).id;
      additionalPermissionLineItems.push({
        additionalPermissionLineItemId: tableItemId,
        apiEnumId: p.id,
        service: service,
        name: name,
        state: s,
        scope: scope,
        havePermission:
          this.props.history.location.state.userProfileData.specialPermissionsForProfile.find(
            (sp) =>
              sp.specialPermission.id === p.id &&
              sp.specialPermissionAssociatedStates.find((spas) => spas === stateGuid)
          ) || this.state.regularPermissionsThatRoleHas.find((x) => x.id === p.id)
            ? true
            : false,
        isRegularPermission: this.state.regularPermissionsThatRoleHas.find((x) => x.id === p.id) ? true : false,
      });
      tableItemId++;
    });
    return tableItemId;
  }

  turnArrayIntoEnum(array) {
    let result = {};
    for (var i = 0; i < array.length; i++) {
      var current = array[i];
      result[current] = current;
    }
    return result;
  }

  getColumns() {
    return [
      {
        dataField: "additionalPermissionLineItemId",
        hidden: true,
      },
      {
        dataField: "service",
        text: "Service Type",
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
        },
        formatExtraData: this.state,
        filter: selectFilter({
          options: this.state.services,
        }),
        style: {},
        headerAttrs: { id: "serviceHeader" },
        attrs: { headers: "serviceHeader", tabIndex: "0" },
      },
      {
        dataField: "name",
        text: "Permission Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Permission Name Search" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        //style: {},
        headerAttrs: { id: "permissionNameHeader" },
        attrs: { headers: "permissionNameHeader", tabIndex: "0" },
      },
      {
        dataField: "state",
        text: "State",
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
        },
        formatExtraData: this.state,
        filter: selectFilter({
          options: this.state.columnFilterStates,
        }),
        style: {},
        headerAttrs: { id: "stateHeader" },
        attrs: { headers: "stateHeader", tabIndex: "0" },
      },
      {
        dataField: "scope",
        text: "Scope",
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
        },
        formatExtraData: this.state,
        filter: selectFilter({
          options: this.state.scopes,
        }),
        style: {},
        headerAttrs: { id: "scopeHeader" },
        attrs: { headers: "scopeHeader", tabIndex: "0" },
      },
      {
        dataField: "havePermission",
        text: "Have Permission",
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
        },
        formatter: (cell, row) => {
          return (
            <div
              title={
                row.isRegularPermission
                  ? "System Role Assigned Permission"
                  : `Toggle Permission for ${row.service} ${row.name} ${row.scope}`
              }
            >
              <input
                type="checkbox"
                defaultChecked={cell}
                disabled={row.isRegularPermission}
                onChange={(e) => this.toggleSpecialPermission(e, row)}
                title={
                  row.isRegularPermission
                    ? "System Role Assigned Permission"
                    : `Toggle Permission for ${row.service} ${row.name} ${row.scope}`
                }
                className={row.isRegularPermission ? "readOnlyPermissionCheckbox" : null}
              />
            </div>
          );
        },
        formatExtraData: this.state,
        filter: selectFilter({
          options: { 0: "Yes", 1: "No" },
          onFilter: (filterValue, data) => {
            if (!filterValue) {
              return data;
            }
            let searchValue = filterValue === "0" ? true : false;
            return data.filter((lineItem) => lineItem.havePermission === searchValue);
          },
        }),
        style: {},
        headerAttrs: { id: "scopeHeader" },
        attrs: { headers: "scopeHeader", tabIndex: "0" },
      },
    ];
  }

  toggleSpecialPermission = (e, rowData) => {
    let specialPermissionsToAdd = this.state.specialPermissionsToAdd;
    let specialPermissionsToRemove = this.state.specialPermissionsToRemove;
    if (e.currentTarget.checked) {
      specialPermissionsToAdd.push(rowData);
      var otherArrayIndex = specialPermissionsToRemove.indexOf(rowData);
      if (otherArrayIndex !== -1) {
        specialPermissionsToRemove.splice(otherArrayIndex, 1);
      }
    } else {
      specialPermissionsToRemove.push(rowData);
      var otherArrayIndex = specialPermissionsToAdd.indexOf(rowData);
      if (otherArrayIndex !== -1) {
        specialPermissionsToAdd.splice(otherArrayIndex, 1);
      }
    }
    this.setState({ specialPermissionsToAdd, specialPermissionsToRemove });
  };

  saveSpecialPermissionChanges = async () => {
    const associateBody = this.makeSpecialPermissionBody(this.state.specialPermissionsToAdd);
    const disassociateBody = this.makeSpecialPermissionBody(this.state.specialPermissionsToRemove);
    let associateSpecialPermissionsAction = [];
    let disassociateSpecialPermissionsAction = [];
    if (associateBody.length > 0) {
      associateSpecialPermissionsAction = await RoleAuthorizationAPI.AssociateSpecialPermissionToUserProfile(
        this.props.history.location.state.userData.userPrinciple,
        this.props.history.location.state.userData.selectedProfile,
        associateBody
      );
      if (associateSpecialPermissionsAction?.successful) {
        toast.success("Special permission changes saved.");
        this.navigateBackToUserRecord();
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to save Special permissions",
          apiName: "AssociateSpecialPermissionToUserProfile",
          responseUnsuccessful: associateSpecialPermissionsAction?.unsuccessful,
          responseMessage: associateSpecialPermissionsAction?.message,
        });
      }
    }
    if (disassociateBody.length > 0) {
      disassociateSpecialPermissionsAction = await RoleAuthorizationAPI.DisassociateSpecialPermissionFromUserProfile(
        this.props.history.location.state.userData.userPrinciple,
        this.props.history.location.state.userData.selectedProfile,
        disassociateBody
      );
      if (disassociateSpecialPermissionsAction?.successful) {
        toast.success("Special permission changes saved.");
        this.navigateBackToUserRecord();
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to save Special permissions",
          apiName: "AssociateSpecialPermissionToUserProfile",
          responseUnsuccessful: disassociateSpecialPermissionsAction?.unsuccessful,
          responseMessage: disassociateSpecialPermissionsAction?.message,
        });
      }

      if (associateBody.length < 1 && disassociateBody.length < 1) {
        toast.warn("No permissions to change");
      }
    }
  };

  makeSpecialPermissionBody(specialPermissionsArray) {
    var associateBody = [];
    specialPermissionsArray.forEach((specialPermission) => {
      let stateArray = [];
      if (
        specialPermission.scope.toLowerCase() === "all" ||
        specialPermission.scope.toLowerCase() === "anystate" ||
        specialPermission.scope.toLowerCase() === "global"
      ) {
        stateArray = this.props.history.location.state.states.map((s) => {
          return s.id;
        });
      } else if (specialPermission.state !== "N/A") {
        stateArray.push(
          this.props.history.location.state.states.find((s) => s.state_code === specialPermission.state).id
        );
      } else {
        stateArray = this.props.history.location.state.userProfileData.profileAssociatedStates;
      }
      stateArray = stateArray.map((s) => {
        return { stateUniqueReference: s };
      });
      associateBody.push({
        specialPermissionEnumId: specialPermission.apiEnumId,
        specialPermissionAssociatedStates: stateArray,
      });
    });
    return associateBody;
  }

  navigateBackToUserRecord = () => {
    this.props.history.push({
      pathname: "/user/details",
      state: {
        userData: this.props.history.location.state.userData,
        states: this.props.history.location.state.states,
        availableRoles: this.props.history.location.state.availableRoles,
        userTypes: this.props.history.location.state.userTypes,
      },
    });
  };

  showEditConfirmationModal = () => {
    if (this.state.specialPermissionsToAdd.length === 0 && this.state.specialPermissionsToRemove.length === 0) {
      toast.success("No changes detected.");
    } else {
      this.setState({ showEditConfirmationModal: true });
    }
  };

  renderEditConfirmationModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showEditConfirmationModal}
          onHide={() => this.setState({ showEditConfirmationModal: false })}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>Special Permissions Edit Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.confirmationModalBody}>
            {this.state.specialPermissionsToAdd.map((specialPermission) => {
              return (
                <Row className={styles.confirmationBodyRow}>
                  <Col lg={7} className={styles.confirmationPermissionName}>
                    {`${specialPermission.service} ${specialPermission.name} ${specialPermission.scope} ${
                      specialPermission.state !== "N/A" ? specialPermission.state : ""
                    }`}
                  </Col>
                  <Col className={styles.confirmationPermissionStatusAdd} lg={3.5}>
                    Added
                  </Col>
                </Row>
              );
            })}
            {this.state.specialPermissionsToRemove.map((specialPermission) => {
              return (
                <Row className={styles.confirmationBodyRow}>
                  <Col lg={7} className={styles.confirmationPermissionName}>
                    {`${specialPermission.service} ${specialPermission.name} ${specialPermission.scope} ${
                      specialPermission.state !== "N/A" ? specialPermission.state : ""
                    }`}
                  </Col>
                  <Col className={styles.confirmationPermissionsStatusRemove} lg={3.5}>
                    Removed
                  </Col>
                </Row>
              );
            })}
          </Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button
              className={globalStyles.modalCancelButton}
              onClick={() => this.setState({ showEditConfirmationModal: false })}
            >
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button
              type="submit"
              className={globalStyles.modalSubmitButton}
              onClick={this.saveSpecialPermissionChanges}
            >
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.adminCenterContainer}>
        <Row className={styles.adminCenterHeaderRow}>
          <Col>
            <h1 className={globalStyles.pageTitle}>Manage Special Permissions</h1>
          </Col>
        </Row>
        <Row>
          <Card className={styles.additionalPermissionsContainerCard}>
            <Card.Body>
              <Row>
                <Col className={styles.topCardInfoText}>User Name</Col>
                <Col className={styles.topCardInfoText}>Role Name</Col>
                <Col className={styles.topCardInfoText}>State(s)</Col>
              </Row>
              <Row>
                <Col className={styles.topCardInfoTextValues}>{this.props.history.location.state.userName}</Col>
                <Col className={styles.topCardInfoTextValues}>{this.state.roleName}</Col>
                <Col className={styles.topCardInfoTextValues}>{this.state.statesString}</Col>
              </Row>
              <Row className={styles.additionalPermissionsTableContainer}>
                <ToolkitProvider
                  keyField="additionalPermissionLineItemId"
                  data={this.state.additionalPermissionLineItems}
                  columns={this.getColumns()}
                  bootstrap4={true}
                  search={{ searchFormatted: true }}
                >
                  {(props) => (
                    <BootstrapTable
                      keyField="additionalPermissionLineItemId"
                      filter={filterFactory()}
                      bordered={false}
                      defaultSorted={[{ dataField: "service", order: "asc" }]}
                      defaultSortDirection="asc"
                      {...props.baseProps}
                    />
                  )}
                </ToolkitProvider>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row className={styles.bottomSubmissionButtons}>
          <Button className={globalStyles.modalCancelButton} onClick={this.navigateBackToUserRecord}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button type="submit" className={globalStyles.modalSubmitButton} onClick={this.showEditConfirmationModal}>
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Row>
        {this.state.showEditConfirmationModal && this.renderEditConfirmationModal()}
      </div>
    );
  }
}

export default withRouter(AdditionalPermissions);
