import React from "react";
import CustomForm from "../common/form";
import styles from "./Warehouse.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Form, Button, Row, Col, CloseButton } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import UtilityFunctions from "../common/UtilityFunctions";

class MixInventoryModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        mixingMethod1: "",
        mixingQty1: "",
        mixingMethod2: "",
        mixingQty2: "",
        mixingResultMethod: "",
        mixingResultQty: "",
      },
      currentUsaState: "",
      availableMixableMethods: [],
      errors: {},
    };
  }

  componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState?.stateGuid) {
      toast.error("Error loading current State Id on Add Method Modal");
      return;
    }

    this.setState({ currentUsaState });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      let { data, availableMixableMethods } = this.state;
      const { transferInventoryData } = this.props;
      data.mixingMethod1 = {
        value: transferInventoryData.methodUniqueReference,
        label: transferInventoryData.methodName,
      };

      if (this.props.warehouseData?.warehouseInventory?.length > 0) {
        availableMixableMethods = this.props.warehouseData.warehouseInventory
          .filter((m) => m.isMixingMethod)
          .map((m) => ({ value: m.methodUniqueReference, label: m.methodName, ...m }));
      }

      this.setState({ data, availableMixableMethods });
    }
  }

  async handleSpecificChanges(input) {
    if (["mixingQty1", "mixingQty2"].includes(input.name) && input.value) {
      const { data, availableMixableMethods, errors } = this.state;
      data[input.name] = input.value;

      if (!(Number.parseFloat(input.value) > 0)) {
        errors[input.name] = "Quantity must be bigger than 0.";
      } else {
        let existingQty = 0;
        let exixtingMethodData;
        if (input.name === "mixingQty1" && data.mixingMethod1) {
          exixtingMethodData = availableMixableMethods.find(
            (m) => m.methodUniqueReference === data.mixingMethod1.value
          );
        } else if (input.name === "mixingQty2" && data.mixingMethod2) {
          exixtingMethodData = availableMixableMethods.find(
            (m) => m.methodUniqueReference === data.mixingMethod2.value
          );
        }

        if (exixtingMethodData) {
          existingQty = exixtingMethodData.quantity;
          if (Number.parseFloat(input.value) > existingQty) {
            errors[input.name] = "Quantity exceeds existing inventory.";
          } else {
            delete errors[input.name];
          }
        }
      }

      this.setState({ data, errors });
    }
  }

  doSubmit = async () => {
    if (!this.isFormDataValid()) {
      toast.warning("Please select mixing methods and a result with their respective quantities.");
    } else {
      this.props.onHide();
      this.props.onSave({ ...this.props.transferInventoryData, ...this.state.data });
      this.clearData();
    }
  };

  isFormDataValid() {
    const { data } = this.state;
    return (
      data.mixingMethod1 &&
      data.mixingQty1 &&
      data.mixingMethod2 &&
      data.mixingQty2 &&
      data.mixingResultMethod &&
      data.mixingResultQty
    );
  }

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    delete errors[fieldName];

    if (fieldName === "mixingMethod2") {
      if (e.value === data.mixingResultMethod?.value) {
        data.mixingResultMethod = "";
      }

      if (data.mixingQty2) {
        {
          let existingQty = 0;
          const exixtingMethodData = this.state.availableMixableMethods.find(
            (m) => m.methodUniqueReference === e.value
          );

          if (exixtingMethodData) {
            existingQty = exixtingMethodData.quantity;
            if (Number.parseFloat(data.mixingQty2) > existingQty) {
              errors["mixingQty2"] = "Quantity exceeds existing inventory.";
            } else {
              delete errors["mixingQty2"];
            }
          }
        }
      }
    }

    this.setState({ data, errors });
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.mixingMethod1 = "";
    data.mixingQty1 = "";
    data.mixingMethod2 = "";
    data.mixingQty2 = "";
    data.mixingResultMethod = "";
    data.mixingResultQty = "";
    this.setState({
      data,
      availableMixableMethods: [],
    });
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "solid 0.5px #D8DDE6",
      };
    },
  };

  render() {
    const { data, availableMixableMethods, errors } = this.state;
    const { transferInventoryData } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.mixInventoryModalModal}
      >
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Mix</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 mt-1">
            <Col sm={6}>
              <Form.Label className={globalStyles.formLabel}>Mixing In</Form.Label>
            </Col>
            <Col>
              <Form.Label className={globalStyles.formLabel}>Qty</Form.Label>
            </Col>
            <Col>
              <Form.Label className={globalStyles.formLabel}>UOM</Form.Label>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col sm={6}>
              <Form.Control
                aria-label="Mixing Method1"
                name="mixingMethod1"
                placeholder="Select"
                value={data.mixingMethod1?.label}
                onChange={this.handleChange}
                readOnly
              />
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  aria-label="Mixing Method1 Qty"
                  name="mixingQty1"
                  type="number"
                  placeholder="Enter"
                  value={data.mixingQty1}
                  onChange={this.handleChange}
                />
                {errors["mixingQty1"] && <div className="alert alert-danger">{errors["mixingQty1"]}</div>}
              </Form.Group>
            </Col>
            <Col>
              <span className={globalStyles.formData}>
                {UtilityFunctions.getDisplayTextFromFieldObject(transferInventoryData.methodUOM)}
              </span>
            </Col>
          </Row>
          <Row className="mx-0 mt-3">
            <Col sm={6}>
              <Select
                aria-label="Mixing Method2 Select"
                name="mixingMethod2"
                placeholder="Select"
                value={data.mixingMethod2}
                onChange={(e) => this.handleSelectChanges(e, "mixingMethod2")}
                styles={this.customSelectStyles}
                options={availableMixableMethods.filter((m) => m.value !== data.mixingMethod1?.value)}
              />
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  aria-label="Mixing Method2 Qty"
                  name="mixingQty2"
                  type="number"
                  placeholder="Enter"
                  value={data.mixingQty2}
                  onChange={this.handleChange}
                />
                {errors["mixingQty2"] && <div className="alert alert-danger">{errors["mixingQty2"]}</div>}
              </Form.Group>
            </Col>
            <Col>
              <span className={globalStyles.formData}>
                {data.mixingMethod2
                  ? UtilityFunctions.getDisplayTextFromFieldObject(
                      availableMixableMethods.find((m) => m.value === data.mixingMethod2.value)?.methodUOM
                    )
                  : ""}
              </span>
            </Col>
          </Row>
          <Row className="mx-0 mt-4">
            <Col sm={6}>
              <Form.Label className={globalStyles.formLabel}>Creating</Form.Label>
            </Col>
            <Col>
              <Form.Label className={globalStyles.formLabel}>Qty</Form.Label>
            </Col>
            <Col>
              <Form.Label className={globalStyles.formLabel}>UOM</Form.Label>
            </Col>
          </Row>
          <Row className="mx-0 mb-3">
            <Col sm={6}>
              <Select
                aria-label="Result Method Select"
                name="mixingResultMethod"
                placeholder="Select"
                value={data.mixingResultMethod}
                onChange={(e) => this.handleSelectChanges(e, "mixingResultMethod")}
                styles={this.customSelectStyles}
                options={availableMixableMethods.filter(
                  (m) => m.value !== data.mixingMethod1?.value && m.value !== data.mixingMethod2?.value
                )}
              />
            </Col>
            <Col>
              <Form.Control
                aria-label="Result Method Qty"
                name="mixingResultQty"
                type="number"
                placeholder="Enter"
                value={data.mixingResultQty}
                onChange={this.handleChange}
              />
            </Col>
            <Col>
              <span className={globalStyles.formData}>
                {data.mixingResultMethod
                  ? UtilityFunctions.getDisplayTextFromFieldObject(
                      availableMixableMethods.find((m) => m.value === data.mixingResultMethod.value)?.methodUOM
                    )
                  : ""}
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button
            className={this.isFormDataValid() ? "ml-2" : styles.greyedOutModalSaveButton}
            variant="primary"
            type="submit"
            onClick={this.doSubmit}
          >
            <span className={this.isFormDataValid() ? globalStyles.modalSubmitButtonText : styles.greyedOutButtonText}>
              Submit
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MixInventoryModal;
