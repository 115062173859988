import React from "react";
import styles from "./Agreement.module.scss";
import { Row, Col, Form, Button } from "react-bootstrap";
import UtilityFunctions from "../common/UtilityFunctions";

const ContactModalPhoneNumber = ({
  phoneNumbers,
  phoneNumberTypes,
  onPhoneNumberChange,
  onExtraPhoneNumber,
  contactType,
}) => {
  let phoneNumberFieldDisplay;
  if (contactType !== "cooperator") {
    phoneNumberFieldDisplay = <Form.Label className="formLabel">Phone Number</Form.Label>;
  } else {
    phoneNumberFieldDisplay = (
      <Form.Label className="formLabel">
        Phone Number<span className="required"> *</span>
      </Form.Label>
    );
  }
  return (
    <div>
      {phoneNumbers.map((phoneNumber, index) => {
        let phoneTypeValue = phoneNumber.phoneTypeEnumId || phoneNumber?.phoneType?.id;
        if (phoneTypeValue === "-1") {
          phoneTypeValue = "1";
        }
        return (
          <div key={index}>
            <Row>
              <Col>
                <Form.Group controlId={"phoneNumber-" + index} className="text-left">
                  {phoneNumberFieldDisplay}
                  <Form.Control
                    type="text"
                    placeholder="xxx-xxx-xxxx"
                    value={UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber)}
                    onChange={onPhoneNumberChange}
                    className={styles.inputStyles}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"phoneTypeEnumId-" + index} className="text-left">
                  <Form.Label className="formLabel">
                    Phone Number Type
                    <span className="required"> *</span>
                  </Form.Label>
                  <Form.Control
                    value={phoneTypeValue}
                    as="select"
                    type="text"
                    onChange={onPhoneNumberChange}
                    className={styles.inputStyles}
                  >
                    {phoneNumberTypes.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row noGutters="true">
              <Col align="right">
                <Button
                  variant="link"
                  onClick={onExtraPhoneNumber}
                  value={phoneNumbers.length > index + 1 ? index + 1 : "add"}
                  style={phoneNumbers.length > index + 1 ? { color: "red" } : {}}
                  className={styles.linkButton}
                >
                  {phoneNumbers.length > index + 1 ? "- Remove Phone Number" : "+ Add Phone Number"}
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default ContactModalPhoneNumber;
