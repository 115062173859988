import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "./Agreement.module.scss";
import { Row, Button, Col } from "react-bootstrap";
import PrintAgreementModal from "../PDFHandling/PrintAgreementModal";

export class AgreementsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrintAgreementModal: false,
    };
  }

  viewPrintAgreementModal = () => {
    this.setState({ showPrintAgreementModal: true });
  };

  closePrintAgreementModal = () => {
    this.setState({ showPrintAgreementModal: false });
  };

  render() {
    return (
      <div>
        <Row>
          <Col>
            <p className={styles.pageTitles}>Dashboard</p>
          </Col>
          <Col className="text-right">
            <Button variant="primary" onClick={this.viewPrintAgreementModal}>
              Print
            </Button>
          </Col>
        </Row>
        <PrintAgreementModal
          show={this.state.showPrintAgreementModal}
          onHide={this.closePrintAgreementModal}
          isCurrentAgreement={false}
        />
      </div>
    );
  }
}

export default withRouter(AgreementsListPage);
