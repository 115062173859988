import React from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import AgreementAPI from "../../../api/AgreementEntities/AgreementAPI";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import CustomForm from "../../../components/common/form";
import { Card, Form, Row, Col, Button, Tabs, Tab, Image, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "../../Agreements/Agreement.module.scss";
import globalStyles from "../../../OARS.module.scss";
import ShowHistoricalLossModal from "../../Agreements/showHistoricalLossModal";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { components } from "react-select";
import { toast } from "react-toastify";
import searchIcon from "../../../assets/search.svg";
import PrintAgreementModal from "../../PDFHandling/PrintAgreementModal";
import linkImage from "../../../assets/external-link.svg";
import Addresses from "../Properties/propertyAddresses";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import PropertyParcelAssociationModal from "../../Agreements/propertyParcelAssociationModal";
import EditAgreementEditContactModal from "./EditAgreementEditContactModal";
import UtilityFunctions from "../../common/UtilityFunctions";
import GeoCoding from "../../common/GeoCoding";
import SuccessfulRecordModifiedModal from "../../common/create-updateOkMessageModal";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import DeleteAcknowledgeModal from "../../common/DeleteAcknowledgeModal";
import WebMap from "../../ESRIIntegration/EsriMap";
import ParcelMapView from "../../ESRIIntegration/ParcelMapView";
import RoleAuthorizationAPI from "../../../api/RoleAuthorization/RoleAuthorizationAPI";
import LoadingOverlay from "react-loading-overlay";
import RejectAgreementModal from "./RejectAgreementModal";
import FlagAgreementModal from "./FlagAgreementModal";
import AddHistoricalLossModal from "../../Agreements/addHistoricalLossModal";
import AddProjectModal from "../../Agreements/addProjectModal";
import ActionConfirmationModal from "../../common/ActionConfirmationModal";
import AgreementAmendmentHistory from "./AgreementAmendmentHistory";
import moment from "moment";

const AgreementsStatuses = {
  Draft: 1,
  Pending: 2,
  Active: 3,
  Expired: 4,
  Cancelled: 5,
  Inactive: 6,
};

const AgreementsRenewalStatuses = {
  NewAgreement: 1,
  RenewalAgreement: 2,
};

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class DisplayAgreementDetails extends CustomForm {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      agreementData: "",
      cooperatorData: "",
      propertiesData: [],
      states: this.props.history.location?.state?.states ? this.props.history.location.state.states : [],
      countries: this.props.history.location.state?.countries ? this.props.history.location.state.countries : [],
      counties: [],
      landClasses: [],
      protectedResourcesData: [],
      historicalResourcesData: [],
      associatedAgreementContactsData: [],
      associatedPropertyContactsData: [],
      specialInstructionsData: [],
      selectedRecommendations: [],
      selectedCEMs: [],
      selectedRecommendationsToUpdate: [],
      selectedCEMsToUpdate: [],
      currentHistoricalRecord: "",
      showHistoricalLossModal: false,
      showPrintAgreementModal: false,
      data: {
        agreementNumber: "",
        agreementStatus: "",
        commonName: "",
        agreementType: "",
        expirationDate: "",
        initialExpirationDate: "",
        agreementRenewalStatus: "",
        protectedResourceSearch: "",
        protectedResourceQuantity: "",
        protectedResourceUom: "",
        protectedResourceUnitValue: "",
        protectedResourceTotalValue: "",
        protectedResourceYear: "",
        historicalResourceSearch: "",
        historicalResourceQuantity: "",
        historicalResourceUom: "",
        historicalResourceUnitValue: "",
        historicalResourceTotalValue: "",
        historicalResourceYear: "",
        specialInstructions: "",
        historicalResourceStateValuationSetting: null,
        noProtectedResourcesRequired: false,
        noHistoricalResourcesRequired: false,
        noLossesRequired: false,
      },
      errors: {},
      recommendations: [],
      initialRecs: [],
      cooperatorEmployedMethods: [],
      initialCems: [],
      addHistoricalLossData: "",
      selectedRecTableData: [],
      selectedCemTableData: [],
      availableRecommendations: [],
      availableCEMs: [],
      selectedCemsToUpdate: [],
      isAgreementEditable: false,
      isAgreementActive: false,
      agreementTypes: [],
      agreementRenewalStatuses: [],
      currentProperty: null,
      resourceUOMs: [],
      currentUsaState: "",
      protectedResourcesToAdd: [],
      resourcePropertyParcelModalData: {
        currentResource: null,
        checkedProperties: [],
        currentAction: "add",
        resourceTableType: "",
      },
      resourcesToBeAddedToAgreement: [],
      specialInstructionsToAddToAgreement: [],
      contactsToBeAddedToAgreement: [],
      showResourcePropertyAssociationModal: false,
      showPrimaryFieldAgentSearch: false,
      typeaheadIsLoading: false,
      typeaheadOptions: [],
      showAdditionalFieldAgents: false,
      additionalFieldAgentSelect: "",
      speciesSearch: "",
      methodsSearch: "",
      showSpeciesPropertyAssociationModal: false,
      showMethodsPropertyAssociationModal: false,
      speciesPropertyParcelModalData: {
        currentSpecies: null,
        checkedProperties: [],
        currentAction: "add",
      },
      methodsPropertyParcelModalData: {
        currentMethod: null,
        checkedProperties: [],
        currentAction: "add",
      },
      initialSpeciesData: [],
      selectedSpeciesTableData: [],
      selectedMethodsTableData: [],
      selectedSpeciesColumns: [
        { dataField: "speciesTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      selectedMethodsColumns: [
        { dataField: "methodTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      recCemPropertyParcelModalData: {
        currentRecCem: null,
        checkedProperties: [],
        currentAction: "add",
        entityType: "",
      },
      currentEditContact: null,
      showEditContactModal: false,
      phoneNumberTypes: [],
      propertiesEdited: false,
      showSuccessfulUpdateModal: false,
      showRecCemPropertyAssociationModal: false,
      agreementRoles: [],
      contactRolesEdited: false,
      agreementDetailsEdited: false,
      fieldAgentsEdited: false,
      cooperatorEdited: false,
      originalFieldAgents: [],
      showDeleteAgreementModal: false,
      showAmendAgreementConfirmationModal: false,
      showAgreementAmendmentHistory: false,
      agreementAmendmentData: "",
      isProtectedResourcesTableEditable: false,
      isHistoricalResourcesTableEditable: false,
      expirationDateEdited: false,
      editedProtectedResource: { quantity: "", valuePerUnitOfMeasure: "", totalValuation: "" },
      editedHistoricalResource: { quantity: "", valuePerUnitOfMeasure: "", totalValuation: "", valuationYear: "" },
      isFormLoading: true,
      showAgreementRejectionModal: false,
      showAgreementFlagModal: false,
      wsUserProfileUniqueIdentifier: "",
      selectedRecColumns: [
        { dataField: "tableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      selectedCemColumns: [
        { dataField: "tableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      flagHistory: "",
      flagUniqueIdentifier: "",
      showAddHistoricalLossModal: false,
      agreementProjects: [],
      showAddProjectModal: false,
      agreement12aAttachment: null,
      agreement12aAttachmentName: null,
      otherAttachedFiles: [],
      cooperatorSignatureAttachment: null,
      wsSpecialistSignatureAttachment: null,
    };
  }

  async componentDidMount() {
    const currentUserRole = JSON.parse(localStorage.getItem("currentUserRole"));
    if (currentUserRole?.wsUserProfileUniqueIdentifier) {
      this.setState({ wsUserProfileUniqueIdentifier: currentUserRole.wsUserProfileUniqueIdentifier });
    }

    if (this.state.counties.length === 0) {
      let counties = await ReferenceFileAPI.GetCountiesByState(
        JSON.parse(localStorage.getItem("currentState")).stateGuid,
        this.controller.signal
      );
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ counties });
    }
    if (this.state.landClasses.length === 0) {
      let landClasses = await AgreementEntitiesAPI.GetAllPropertyLandClasses(this.controller.signal);
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ landClasses: landClasses?.data });
    }

    if (this.state.phoneNumberTypes.length === 0) {
      const phoneTypesCall = await AgreementEntitiesAPI.GetPhoneNumberTypes(this.controller.signal);
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ phoneNumberTypes: phoneTypesCall?.data });
    }
    if (this.state.phoneNumberTypes) {
      const phoneTypesCall = await AgreementEntitiesAPI.GetPhoneNumberTypes(this.controller.signal);
      if (phoneTypesCall?.successful) {
        this.setState({ phoneNumberTypes: phoneTypesCall?.data });
      }
    }
    if (this.state.agreementRoles.length === 0) {
      const agreementRolesCall = await AgreementAPI.GetAllAgreementContactRoles(this.controller.signal);
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ agreementRoles: agreementRolesCall?.successful ? agreementRolesCall.data : [] });
    }
    if (this.state.states?.length === 0) {
      let states = await this.getStatesFromAPI();
      this.setState({ states: states ? states : [] });
    }
    if (this.state.countries?.length === 0) {
      let countries = await this.getAllCountriesFromAPI();
      this.setState({ countries: countries ? countries : [] });

    }
    const { entityData, isEntityDataCompleted } = this.props.history.location.state;
    if (entityData) {
      let agreementDataResponse;
      if (isEntityDataCompleted) {
        agreementDataResponse = entityData;
      } else {
        const agreementUniqueId = entityData.agreementUniqueIdentifier;
        agreementDataResponse = await AgreementAPI.GetAgreement(agreementUniqueId, this.controller.signal);
      }
      if (this.controller.signal.aborted) {
        return;
      }
      //get flag history
      if (entityData.agreementUniqueIdentifier) {
        await this.getFlagsForAgreement(entityData.agreementUniqueIdentifier);
      }
      await this.updatePageData(agreementDataResponse);
    }
    const availableRecCems = await this.getAvailableRecCems();
    this.setState({
      availableRecommendations: JSON.parse(JSON.stringify(availableRecCems)),
      availableCEMs: JSON.parse(JSON.stringify(availableRecCems)),
      selectedRecTableData: this.getRecCemTableData(),
      selectedCemTableData: this.getRecCemTableData(false),
      selectedRecommendations: [],
      selectedCEMs: [],
      selectedRecColumns: this.getRecCemDataColumns(true, false),
      selectedCemColumns: this.getRecCemDataColumns(false, false),
      isFormLoading: false,
    });
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("displayAgreementDetails.jsx got unmounted");
    }
  }

  async getRecommendations(agreementData) {
    let recommendationsData = [];

    for (let property of agreementData.agreementProperties) {
      if (property?.recommendations?.length > 0) {
        property.recommendations.forEach((rec) => {
          const existingRecs = recommendationsData.find((r) => r.recCemId === rec.recCemUniqueReference);
          if (!existingRecs) {
            recommendationsData.push({
              recCemName: rec.recCemName,
              recCemId: rec.recCemUniqueReference,
              checkedProperties: [property],
            });
          } else if (
            !existingRecs.checkedProperties.find((p) => p.propertyUniqueReference === property.propertyUniqueReference)
          ) {
            existingRecs.checkedProperties = [...existingRecs.checkedProperties, property];
          }
        });
      }
    }

    return recommendationsData;
  }

  async getCEMs(agreementData) {
    let cemsData = [];

    for (let property of agreementData.agreementProperties) {
      if (property?.cems?.length > 0) {
        property.cems.forEach((cem) => {
          const existingRecs = cemsData.find((r) => r.recCemId === cem.recCemUniqueReference);
          if (!existingRecs) {
            cemsData.push({
              recCemName: cem.recCemName,
              recCemId: cem.recCemUniqueReference,
              checkedProperties: [property],
            });
          } else if (
            !existingRecs.checkedProperties.find((p) => p.propertyUniqueReference === property.propertyUniqueReference)
          ) {
            existingRecs.checkedProperties = [...existingRecs.checkedProperties, property];
          }
        });
      }
    }

    return cemsData;
  }

  getRecCemDataColumns(isRecommendations = true, isAgreementEditable = false) {
    return [
      { dataField: "tableId", hidden: true },
      {
        dataField: "recCemName",
        text: isRecommendations ? "Recommendations" : "CEMs",
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
      },
      {
        dataField: "checkedProperties",
        text: "Property",
        formatter: (cell, row) => {
          return row.property?.propertyName;
        },
        headerStyle: () => {
          return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "14px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "recCemRemove",
        text: "",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <Button
              variant="link"
              onClick={() => (isRecommendations ? this.removeRecEntry(row.tableId) : this.removeCemEntry(row.tableId))}
            >
              <span className={styles.actionButtonsText}>Remove</span>
            </Button>
          );
        },
        headerStyle: () => {
          return { width: "40%", borderBottom: "0.8px solid #008767" };
        },
        headerAlign: "right",
        align: "right",
        hidden: !isAgreementEditable,
      },
    ];
  }

  removeRecEntry = (tableIdToRemove) => {
    const { availableRecommendations, selectedRecTableData } = this.state;
    let selectedRecommendationsToUpdate = JSON.parse(JSON.stringify(this.state.recommendations));

    const recCemIdToRemove = tableIdToRemove.split("|")[0];

    const availableRec = availableRecommendations.find((r) => r.value === recCemIdToRemove);
    if (availableRec) {
      availableRec.isSelected = false;
    }

    const updatedRecTableData = selectedRecTableData.filter((r) => r.tableId !== tableIdToRemove);

    if (!updatedRecTableData.find((r) => r.recCemId === recCemIdToRemove)) {
      selectedRecommendationsToUpdate = selectedRecommendationsToUpdate.filter((r) => r.recCemId !== recCemIdToRemove);
    } else {
      let updatedCheckedProperties = [];
      updatedRecTableData
        .filter((r) => r.recCemId === recCemIdToRemove)
        .forEach((rec) => {
          updatedCheckedProperties.push(rec.property);
        });

      const recommendationToUpdate = selectedRecommendationsToUpdate.find((r) => r.recCemId === recCemIdToRemove);
      if (recommendationToUpdate) {
        recommendationToUpdate.checkedProperties = updatedCheckedProperties;
      }
    }

    this.setState({
      selectedRecTableData: updatedRecTableData,
      availableRecommendations,
      recommendations: selectedRecommendationsToUpdate,
    });
  };

  removeCemEntry = (tableIdToRemove) => {
    const { availableCEMs, selectedCemTableData } = this.state;
    let selectedCEMsToUpdate = JSON.parse(JSON.stringify(this.state.cooperatorEmployedMethods));

    const recCemIdToRemove = tableIdToRemove.split("|")[0];
    const availableCEM = availableCEMs.find((r) => r.value === recCemIdToRemove);
    if (availableCEM) {
      availableCEM.isSelected = false;
    }

    const updatedCEMTableData = selectedCemTableData.filter((r) => r.tableId !== tableIdToRemove);

    if (!updatedCEMTableData.find((r) => r.recCemId === recCemIdToRemove)) {
      selectedCEMsToUpdate = selectedCEMsToUpdate.filter((r) => r.recCemId !== recCemIdToRemove);
    } else {
      let updatedCheckedProperties = [];
      updatedCEMTableData
        .filter((r) => r.recCemId === recCemIdToRemove)
        .forEach((rec) => {
          updatedCheckedProperties.push(rec.property);
        });

      const cemToUpdate = selectedCEMsToUpdate.find((r) => r.recCemId === recCemIdToRemove);
      if (cemToUpdate) {
        cemToUpdate.checkedProperties = updatedCheckedProperties;
      }
    }

    this.setState({
      selectedCemTableData: updatedCEMTableData,
      availableCEMs,
      cooperatorEmployedMethods: selectedCEMsToUpdate,
    });
  };

  getRecCemTableData(isRecommendations = true) {
    const { recommendations, cooperatorEmployedMethods } = this.state;
    const selectedRecCemData = isRecommendations ? recommendations : cooperatorEmployedMethods;

    let selectedRecCemTableData = [];
    if (selectedRecCemData) {
      for (let recCem of selectedRecCemData) {
        recCem.checkedProperties.forEach((property) => {
          const newTableId = `${recCem.recCemId}|${property.propertyUniqueIdentifier}`;
          if (!selectedRecCemTableData.find((r) => r.tableId === newTableId)) {
            selectedRecCemTableData.push({
              tableId: newTableId,
              recCemId: recCem.recCemId,
              recCemName: recCem.recCemName,
              property: {
                propertyUniqueIdentifier: property.propertyUniqueIdentifier,
                propertyName: property.propertyName,
              },
            });
          }
        });
      }
    }
    return selectedRecCemTableData;
  }

  async updatePageData(agreementDataResponse) {
    if (agreementDataResponse && agreementDataResponse?.successful) {
      let agreementData = agreementDataResponse.data;
      const cooperatorData = agreementData.cooperator
        ? await this.getContactData(agreementData.cooperator.contactUniqueReference)
        : "";
      if (agreementData.primaryFieldAgent) {
        agreementData.primaryFieldAgent = await this.getUserData(
          agreementData.primaryFieldAgent.fieldAgentWsUserProfileUniqueReference
        );
      }
      const originalFieldAgents = agreementData.additionalFieldAgents;
      agreementData.additionalFieldAgents = await Promise.all(
        agreementData.additionalFieldAgents.map(async (agent) => {
          return await this.getUserData(agent.fieldAgentWsUserProfileUniqueReference);
        })
      );

      let { agreement12aAttachment, cooperatorSignatureAttachment, wsSpecialistSignatureAttachment } = this.state;
      if (agreementData.has12aAttachment) {
        agreement12aAttachment = await this.getAgreement12aAttachment(agreementData);
      } else {
        const signatureResponse = await AgreementAPI.GetSignature(agreementData.agreementUniqueIdentifier);
        if (signatureResponse?.data?.cooperatorSignatureBase64Image) {
          const base64CooperatorDataResponse = await fetch(
            `data:image/jpeg;base64,${signatureResponse.data.cooperatorSignatureBase64Image}`
          );
          cooperatorSignatureAttachment = await base64CooperatorDataResponse.blob();
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to retrieve agreement signature",
            apiName: "GetSignature",
            responseUnsuccessful: signatureResponse?.unsuccessful,
            responseMessage: signatureResponse?.message,
          });
        }
        if (signatureResponse?.data?.fieldAgentSignatureBase64Image) {
          const base64WsSpecialistDataResponse = await fetch(
            `data:image/jpeg;base64,${signatureResponse.data.fieldAgentSignatureBase64Image}`
          );
          wsSpecialistSignatureAttachment = await base64WsSpecialistDataResponse.blob();
        }
      }

      const propertiesData = await this.getPropertiesData(agreementData);
      const agreementTypes = await this.getAllAgreementTypes();
      const agreementRenewalStatuses = await this.getAllAgreementRenewalStatuses();

      const mappedResourcesData = this.mapAgreementResourcesData(agreementData, propertiesData);
      const speciesMapData = await this.getMappedSpeciesData(agreementData, propertiesData);
      const methodsMapData = await this.getMappedMethodsData(agreementData, propertiesData);
      const specialInstructionsData = this.getSpecialInstructionsData(agreementData);

      const associatedAgreementContacts = await this.getAgreementAssociatedContactsData(agreementData);
      const associatedPropertyContacts = await this.getPropertyAssociatedContactsData(agreementData);
      const recommendations = await this.getRecommendations(agreementData);
      const cooperatorEmployedMethods = await this.getCEMs(agreementData);

      let isAgreementActive = false;
      if (agreementData?.agreementStatus?.id === AgreementsStatuses.Active) {
        isAgreementActive = true;
      }
      const pageData = {
        agreementData,
        cooperatorData,
        propertiesData,
        selectedSpeciesTableData: [...speciesMapData],
        initialSpeciesData: JSON.parse(JSON.stringify(this.getSpeciesDataInAPIFormat(speciesMapData))),
        selectedMethodsTableData: [...methodsMapData],
        initialMethodsData: JSON.parse(JSON.stringify(this.getMethodsDataInAPIFormat(methodsMapData))),
        specialInstructionsData: [...specialInstructionsData],
        initialSpecialInstructionsData: JSON.parse(JSON.stringify(specialInstructionsData)),
        associatedAgreementContactsData: associatedAgreementContacts,
        associatedPropertyContactsData: associatedPropertyContacts,
        initialAssociatedAgreementContactsData: JSON.parse(JSON.stringify(associatedAgreementContacts)),
        initialAssociatedPropertyContactsData: JSON.parse(JSON.stringify(associatedPropertyContacts)),
        protectedResourcesData: [...mappedResourcesData.protectedResourcesData],
        initialProtectedResourcesData: JSON.parse(JSON.stringify(mappedResourcesData.protectedResourcesData)),
        historicalResourcesData: [...mappedResourcesData.historicalResourcesData],
        initialHistoricalResourcesData: JSON.parse(JSON.stringify(mappedResourcesData.historicalResourcesData)),
        selectedSpeciesColumns: this.getSpeciesDataColumns(propertiesData),
        selectedMethodsColumns: this.getMethodsDataColumns(propertiesData),
        data: this.getAgreementDataField(agreementData),
        agreementTypes: agreementTypes,
        agreementRenewalStatuses: agreementRenewalStatuses,
        currentProperty: propertiesData?.length > 0 ? propertiesData[0] : null,
        resourceUOMs: await this.getResourceUOMs(),
        currentUsaState: JSON.parse(localStorage.getItem("currentState")),
        originalFieldAgents,
        isAgreementActive: isAgreementActive,
        recommendations: recommendations,
        initialRecs: recommendations,
        cooperatorEmployedMethods: cooperatorEmployedMethods,
        initialCems: cooperatorEmployedMethods,
        agreementProjects: await this.updateProjectData(agreementData?.projectUniqueReferences),
        agreement12aAttachment,
        agreement12aAttachmentName: agreementData.signed12aAttachmentName,
        otherAttachedFiles: agreementData.otherAgreementAttachmentStorageReferences,
        cooperatorSignatureAttachment,
        wsSpecialistSignatureAttachment,
      };

      if (this.state.states?.length === 0) {
        pageData.states = await this.getStatesFromAPI();
      }
      if (this.state.countries?.length === 0) {
        pageData.countries = await this.getAllCountriesFromAPI();
      }

      this.setState(pageData);
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve agreement data",
        apiName: "GetAgreement",
        responseUnsuccessful: agreementDataResponse?.unsuccessful,
        responseMessage: agreementDataResponse?.message,
      });
    }
  }

  async getProjectsDataFromAPI() {
    let projectsData = [];
    const projectsDataResponse = await AgreementEntitiesAPI.GetMyAssociatedProjectsForState(
      JSON.parse(localStorage.getItem("currentState")).stateGuid
    );
    if (projectsDataResponse?.successful) {
      projectsData = projectsDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve user's associated projects",
        apiName: "GetMyAssociatedProjectsForState",
        responseUnsuccessful: projectsDataResponse?.unsuccessful,
        responseMessage: projectsDataResponse?.message,
      });
    }
    let projectsArray = [];
    projectsData.forEach((project) => {
      let startDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.startDate);
      let endDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.endDate);
      let currentDate = new Date();
      if (currentDate >= startDate && currentDate < endDate) {
        projectsArray.push({ value: project.projectUniqueIdentifier, label: project.projectName });
      }
    });
    return projectsArray;
  }

  async updateProjectData(agreementData) {
    let allProjects = await this.getProjectsDataFromAPI();

    let selectedProjects = [];
    if (allProjects) {
      selectedProjects = allProjects.filter((f) =>
        agreementData.some((item) => item.projectUniqueReference === f.value)
      );
    }
    return selectedProjects;
  }

  async getAvailableRecCems() {
    let recCemsData = [];
    if (this.state.currentUsaState?.stateGuid) {
      const recCemsResponse = await ReferenceFileAPI.GetAllRecCems(this.state.currentUsaState.stateGuid);
      if (recCemsResponse?.successful && recCemsResponse.data?.results?.length > 0) {
        recCemsData = recCemsResponse.data.results
          .filter((rc) => rc.isAllowedInState)
          ?.map((rc) => ({ value: rc.recCemUniqueIdentifier, label: rc.recCemName }));
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve Recommendations",
          apiName: "GetAllRecCems",
          responseUnsuccessful: recCemsResponse?.unsuccessful,
          responseMessage: recCemsResponse?.message,
        });
      }
    }
    return recCemsData;
  }

  async getStatesFromAPI() {
    let usaStates = [];
    const usaStatesResponse = await ReferenceFileAPI.GetUSStates();
    if (usaStatesResponse?.length > 0) {
      usaStates = usaStatesResponse;
    }
    return usaStates;
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  getSpeciesAndMethodsTableStyles() {
    return {
      columnHeaderStyle1: {
        width: "25%",
        fontSize: "14px",
        borderBottom: "0.8px solid #008767",
        textAlign: "left",
      },
      columnHeaderStyle2: {
        width: "35%",
        fontSize: "14px",
        borderBottom: "0.8px solid #008767",
        textAlign: "left",
      },
      columnHeaderStyle3: { width: "15%", borderBottom: "0.8px solid #008767" },
      columnBodyStyle1: { width: "25%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "left" },
      columnBodyStyle2: { width: "35%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "left" },
      columnBodyStyle3: { width: "15%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "right" },
    };
  }

  getSpeciesDataColumns(propertiesData, isAgreementEditable = false) {
    const tableStyles = this.getSpeciesAndMethodsTableStyles();
    return [
      {
        dataField: "speciesTableId",
        hidden: true,
      },
      {
        dataField: "speciesName",
        text: "Damage Agent",
        headerStyle: () => {
          return tableStyles.columnHeaderStyle1;
        },
        style: tableStyles.columnBodyStyle1,
      },
      {
        dataField: "checkedProperties",
        text: "Property",
        formatter: (cell, row) => {
          return propertiesData.find(
            (property) =>
              property.propertyUniqueIdentifier ===
              row.checkedProperties.find(
                (checkedProperty) =>
                  checkedProperty.propertyUniqueIdentifier === this.state.currentProperty.propertyUniqueIdentifier
              ).propertyUniqueIdentifier
          ).propertyName;
        },
        headerStyle: () => {
          return tableStyles.columnHeaderStyle1;
        },
        style: tableStyles.columnBodyStyle1,
      },
      {
        dataField: "speciesEditAssociations",
        text: "",
        formatter: (cell, row) => {
          return (
            <DropdownButton id="dropdown-species-actions" title="Actions" variant="link">
              <Dropdown.Item eventKey="deleteSpecies" onSelect={() => this.deleteSpecies(row)}>
                Delete
              </Dropdown.Item>
            </DropdownButton>
          );
        },
        headerStyle: () => {
          return tableStyles.columnHeaderStyle3;
        },
        style: tableStyles.columnBodyStyle3,
        hidden: !isAgreementEditable,
      },
    ];
  }

  getMethodsDataColumns(propertiesData, isAgreementEditable = false) {
    const tableStyles = this.getSpeciesAndMethodsTableStyles();
    return [
      {
        dataField: "methodTableId",
        hidden: true,
      },
      {
        dataField: "methodName",
        text: "Method",
        headerStyle: () => {
          return tableStyles.columnHeaderStyle1;
        },
        style: tableStyles.columnBodyStyle1,
      },
      {
        dataField: "checkedProperties",
        text: "Property",
        formatter: (cell, row) => {
          return propertiesData.find(
            (property) =>
              property.propertyUniqueIdentifier ===
              row.checkedProperties.find(
                (checkedProperty) =>
                  checkedProperty.propertyUniqueIdentifier === this.state.currentProperty.propertyUniqueIdentifier
              ).propertyUniqueIdentifier
          ).propertyName;
        },
        headerStyle: () => {
          return tableStyles.columnHeaderStyle1;
        },
        style: tableStyles.columnBodyStyle1,
      },
      {
        dataField: "methodEditAssociations",
        text: "",
        formatter: (cell, row) => {
          return (
            <DropdownButton id="dropdown-methods-actions" title="Actions" variant="link">
              <Dropdown.Item eventKey="deleteMethods" onSelect={() => this.deleteMethod(row)}>
                Delete
              </Dropdown.Item>
            </DropdownButton>
          );
        },
        headerStyle: () => {
          return tableStyles.columnHeaderStyle3;
        },
        style: tableStyles.columnBodyStyle3,
        hidden: !isAgreementEditable,
      },
    ];
  }

  deleteSpecies = (row) => {
    let { selectedSpeciesTableData } = this.state;
    const speciesTableId = row.speciesTableId;
    const updatedTableData = selectedSpeciesTableData.filter((species) => species.speciesTableId !== speciesTableId);

    this.setState({ selectedSpeciesTableData: updatedTableData });
  };

  deleteMethod = (row) => {
    const { selectedMethodsTableData } = this.state;
    const methodTableId = row.methodTableId;
    const updatedTableData = selectedMethodsTableData.filter((method) => method.methodTableId !== methodTableId);

    this.setState({ selectedMethodsTableData: updatedTableData });
  };

  async getResourceUOMs() {
    let result = [];
    let resourceUomData = await ReferenceFileAPI.GetAllResourceUOMs();
    if (resourceUomData?.successful) {
      result = resourceUomData.data?.map((resource) => ({
        value: resource.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(resource),
      }));
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve resource units of measure",
        apiName: "GetAllResourceUOMs",
        responseUnsuccessful: resourceUomData?.unsuccessful,
        responseMessage: resourceUomData?.message,
      });
    }
    return result;
  }

  async getAllAgreementTypes() {
    let agreementTypes = [];
    const agreementTypesResponse = await AgreementAPI.GetAllAgreementTypes();
    if (agreementTypesResponse?.successful && agreementTypesResponse.data?.length > 0) {
      agreementTypes = agreementTypesResponse.data;
    }
    return agreementTypes;
  }

  async getAllAgreementRenewalStatuses() {
    let agreementStatuses = [];
    const agreementStatusesResponse = await AgreementAPI.GetAllAgreementRenewalStatuses();
    if (agreementStatusesResponse?.successful && agreementStatusesResponse.data?.length > 0) {
      agreementStatuses = agreementStatusesResponse.data;
    }
    return agreementStatuses;
  }

  getAgreementDataField(agreementData) {
    const {
      agreementNumber,
      commonName,
      agreementType,
      agreementRenewalStatus,
      agreementStatus,
      expirationDate,
      isResourceRequired,
      isHistoricalResourceRequired,
      isHistoricalLossRequired,
    } = agreementData;
    const { data } = this.state;

    data.agreementNumber = agreementNumber;
    data.commonName = commonName;
    data.agreementType = agreementType?.id ? agreementType.id : "";
    data.agreementRenewalStatus = agreementRenewalStatus?.id ? agreementRenewalStatus.id : "";
    data.agreementStatus = agreementStatus?.id ? agreementStatus : "";
    data.protectedResourceYear = new Date().getFullYear();
    if (expirationDate) {
      data.expirationDate = new Date(expirationDate).toLocaleDateString("en-CA", { timeZone: "UTC" });
      data.initialExpirationDate = new Date(expirationDate).toLocaleDateString("en-CA", { timeZone: "UTC" });
    }
    data.noProtectedResourcesRequired = !isResourceRequired;
    data.noHistoricalResourcesRequired = !isHistoricalResourceRequired;
    data.noLossesRequired = !isHistoricalLossRequired;

    return data;
  }

  getResourceDataColumns(resourceTable = "protectedResources", isAgreementEditable = false) {
    let resourceDataColumns = [
      {
        dataField: "resourceTableId",
        hidden: true,
      },
      this.getValuationYearColumn(resourceTable),
      this.getResourceNameColumn(),
      this.getResourceQuantityColumn(resourceTable),
      this.getValuationUOMColumn(),
      this.getUnitValuationColumn(resourceTable),
      this.getTotalValuationColumn(resourceTable),
      this.getResourcePropertiesColumn(),
    ];

    if (resourceTable === "historicalResources") {
      resourceDataColumns.push(this.getTotalLossColumn(), this.getViewLossColumn(isAgreementEditable));
    }

    resourceDataColumns.push(this.getResourceEditAssociationsColumn(resourceTable, isAgreementEditable));

    return resourceDataColumns;
  }

  updateEditedProtectedResource = ({ currentTarget: input }, row) => {
    const editedProtectedResource = { ...this.state.editedProtectedResource };

    switch (input.name) {
      case "quantity":
        this.editResourceBasedOnQuantity(editedProtectedResource, row, input);
        break;
      case "valuePerUnitOfMeasure":
        this.editResourceBasedOnValuePerUOM(editedProtectedResource, input);
        break;
      case "totalValuation":
        this.editResourceBasedOnTotalValuation(editedProtectedResource, input, row);
        break;
      default:
        break;
    }

    this.setState({ editedProtectedResource });
  };

  updateEditedHistoricalResource = async ({ currentTarget: input }, row) => {
    const editedHistoricalResource = { ...this.state.editedHistoricalResource };

    switch (input.name) {
      case "valuationYear":
        await this.editHistoricalResourceBasedOnYear(editedHistoricalResource, input, row);
        break;
      case "quantity":
        this.basedHistoricalResourceBasedOnQuantity(editedHistoricalResource, row, input);
        break;
      case "valuePerUnitOfMeasure":
        this.editHistoricalResourceBasedOnValuePerUOM(editedHistoricalResource, input);
        break;
      case "totalValuation":
        this.editHistoricalResourceBasedOnTotalValuation(editedHistoricalResource, input, row);
        break;
      default:
        break;
    }

    this.setState({ editedHistoricalResource });
  };

  editProtectedResourceEntry = () => {
    const { protectedResourcesData, editedProtectedResource } = this.state;
    const rowIndex = protectedResourcesData.findIndex(
      (r) => r.resourceTableId === editedProtectedResource.resourceTableId
    );
    if (rowIndex !== -1) {
      protectedResourcesData[rowIndex] = { ...editedProtectedResource };
    }

    this.setState({ protectedResourcesData, isProtectedResourcesTableEditable: false });
  };

  editHistoricalResourceEntry = () => {
    const { historicalResourcesData, editedHistoricalResource } = this.state;
    const rowIndex = historicalResourcesData.findIndex(
      (r) => r.resourceTableId === editedHistoricalResource.resourceTableId
    );
    if (rowIndex !== -1) {
      historicalResourcesData[rowIndex] = { ...editedHistoricalResource };
    }

    this.setState({ historicalResourcesData, isHistoricalResourcesTableEditable: false });
  };

  viewLossModal = (row) => {
    this.setState({ currentHistoricalRecord: row, showHistoricalLossModal: true });
  };

  closeHistoricalLossViewModal = () => {
    this.setState({ currentHistoricalRecord: "", showHistoricalLossModal: false });
  };

  viewPrintAgreementModal = () => {
    this.setState({ showPrintAgreementModal: true });
  };

  closePrintAgreementModal = () => {
    this.setState({ showPrintAgreementModal: false });
  };

  editHistoricalResourceBasedOnTotalValuation(editedHistoricalResource, input, row) {
    editedHistoricalResource.totalValuation = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
    const resourceQuantity = editedHistoricalResource.totalValuation / editedHistoricalResource.valuePerUnitOfMeasure;
    editedHistoricalResource.quantity =
      row.valuationUnitOfMeasure?.id === 6
        ? Math.round(resourceQuantity)
        : Number.parseFloat(resourceQuantity.toFixed(2));
  }

  editHistoricalResourceBasedOnValuePerUOM(editedHistoricalResource, input) {
    editedHistoricalResource.valuePerUnitOfMeasure = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
    editedHistoricalResource.totalValuation =
      editedHistoricalResource.quantity * editedHistoricalResource.valuePerUnitOfMeasure;
  }

  basedHistoricalResourceBasedOnQuantity(editedHistoricalResource, row, input) {
    editedHistoricalResource.quantity =
      row.valuationUnitOfMeasure?.id === 6
        ? Math.round(Number.parseFloat(input.value))
        : Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
    editedHistoricalResource.totalValuation =
      editedHistoricalResource.quantity * editedHistoricalResource.valuePerUnitOfMeasure;
  }

  async editHistoricalResourceBasedOnYear(editedHistoricalResource, input, row) {
    editedHistoricalResource.valuationYear = Number.parseInt(input.value);
    editedHistoricalResource.valuePerUnitOfMeasure = await this.getUpdatedHistoricalResourceValuation(
      row,
      Number.parseInt(input.value)
    );
    editedHistoricalResource.totalValuation =
      editedHistoricalResource.quantity * editedHistoricalResource.valuePerUnitOfMeasure;
  }

  editResourceBasedOnTotalValuation(editedProtectedResource, input, row) {
    editedProtectedResource.totalValuation = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
    const resourceQuantity = editedProtectedResource.totalValuation / editedProtectedResource.valuePerUnitOfMeasure;
    editedProtectedResource.quantity =
      row.valuationUnitOfMeasure?.id === 6
        ? Math.round(resourceQuantity)
        : Number.parseFloat(resourceQuantity.toFixed(2));
  }

  editResourceBasedOnValuePerUOM(editedProtectedResource, input) {
    editedProtectedResource.valuePerUnitOfMeasure = Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
    editedProtectedResource.totalValuation =
      editedProtectedResource.quantity * editedProtectedResource.valuePerUnitOfMeasure;
  }

  editResourceBasedOnQuantity(editedProtectedResource, row, input) {
    editedProtectedResource.quantity =
      row.valuationUnitOfMeasure?.id === 6
        ? Math.round(Number.parseFloat(input.value))
        : Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
    editedProtectedResource.totalValuation =
      editedProtectedResource.quantity * editedProtectedResource.valuePerUnitOfMeasure;
  }

  enableInlineHistoricalResourceEdit(row) {
    const existingResource = {
      ...this.state.historicalResourcesData.find((r) => r.resourceTableId === row.resourceTableId),
    };
    const editedHistoricalResource = existingResource ? existingResource : this.state.editedHistoricalResource;
    this.setState({ isHistoricalResourcesTableEditable: true, editedHistoricalResource });
  }

  enableInlineProtectedResourceEdit(row) {
    const existingResource = {
      ...this.state.protectedResourcesData.find((r) => r.resourceTableId === row.resourceTableId),
    };
    const editedProtectedResource = existingResource ? existingResource : this.state.editedProtectedResource;
    this.setState({ isProtectedResourcesTableEditable: true, editedProtectedResource });
  }

  getResourceEditAssociationsColumn(resourceTable, isAgreementEditable) {
    return {
      dataField: "resourceEditAssociations",
      text: "",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (resourceTable === "protectedResources") {
          if (
            formatExtraData.isProtectedResourcesTableEditable &&
            formatExtraData.editedProtectedResource.resourceTableId === row.resourceTableId
          ) {
            return (
              <Button variant="link" onClick={() => this.editProtectedResourceEntry()}>
                {<span className={styles.actionButtonsText}>Save</span>}
              </Button>
            );
          } else {
            return (
              <DropdownButton
                id="dropdown-protected-resources-actions"
                title={<span className={styles.actionButtonsText}>Actions</span>}
                variant="link"
                className="pr-0"
              >
                <Dropdown.Item
                  eventKey="editResourceField"
                  onSelect={() =>
                    resourceTable === "protectedResources"
                      ? this.enableInlineProtectedResourceEdit(row)
                      : this.enableInlineHistoricalResourceEdit(row)
                  }
                >
                  <span className={styles.dropdownActionItems}>Edit</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="deleteResource" onSelect={() => this.deleteProtectedResource(row)}>
                  <span className={styles.dropdownActionItems}>Delete</span>
                </Dropdown.Item>
              </DropdownButton>
            );
          }
        } else {
          if (
            formatExtraData.isHistoricalResourcesTableEditable &&
            formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId
          ) {
            return (
              <Button variant="link" onClick={() => this.editHistoricalResourceEntry(resourceTable)}>
                {<span className={styles.actionButtonsText}>Save</span>}
              </Button>
            );
          } else {
            return (
              <DropdownButton
                id="dropdown-historical-resources-actions"
                title={<span className={styles.actionButtonsText}>Actions</span>}
                variant="link"
                className="pr-0"
              >
                <Dropdown.Item
                  eventKey="editResourceField"
                  onSelect={() =>
                    resourceTable === "protectedResources"
                      ? this.enableInlineProtectedResourceEdit(row)
                      : this.enableInlineHistoricalResourceEdit(row)
                  }
                >
                  <span className={styles.dropdownActionItems}>Edit</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="addLoss" onSelect={() => this.handleHistoricalResourcesAddLoss(row)}>
                  <span className={styles.dropdownActionItems}>
                    {row.historicalLosses && row.historicalLosses.length > 0 ? "Edit Loss" : "Add Loss"}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="duplicate">
                  <span className={styles.dropdownActionItems}>Duplicate</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey="deleteResource" onSelect={() => this.deleteHistoricalResource(row)}>
                  <span className={styles.dropdownActionItems}>Delete</span>
                </Dropdown.Item>
              </DropdownButton>
            );
          }
        }
      },
      formatExtraData: this.state,
      headerStyle: () => {
        return { width: "15%", borderBottom: "0.8px solid #008767" };
      },
      headerAlign: "right",
      align: "right",
      hidden: !isAgreementEditable,
      editable: false,
    };
  }

  getViewLossColumn(isAgreementEditable) {
    return {
      dataField: "viewLossButton",
      text: "",
      formatter: (cell, row) => {
        return (
          <Button variant="link" onClick={() => this.viewLossModal(row)} className="text-right">
            <span className={styles.actionButtonsText}>View Loss</span>
          </Button>
        );
      },
      headerStyle: () => {
        return { width: "15%", fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      headerAlign: "right",
      align: "right",
      hidden: isAgreementEditable,
      editable: false,
    };
  }

  getTotalLossColumn() {
    return {
      dataField: "totalLossValue",
      text: "Total Loss Value",
      formatter: (cell, row) => {
        let totalLossValue = "";
        if (row.historicalLosses?.length > 0) {
          totalLossValue = row.historicalLosses.reduce(
            (acumulator, currentLoss) => acumulator + currentLoss.totalValue,
            0
          );
        }
        return UtilityFunctions.getValueAsCurrency(totalLossValue);
      },
      headerStyle: () => {
        return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      style: { fontSize: "14px" },
      headerAlign: "left",
      align: "left",
    };
  }

  getResourcePropertiesColumn() {
    return {
      dataField: "checkedProperties",
      text: "Property",
      formatter: (cell, row) => {
        return row.checkedProperties && row.checkedProperties.length > 0 ? row.checkedProperties[0].propertyName : "";
      },
      headerStyle: () => {
        return { width: "15%", fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      style: { fontSize: "14px" },
      headerAlign: "left",
      align: "left",
      editable: false,
    };
  }

  getTotalValuationColumn(resourceTable) {
    return {
      dataField: "totalValuation",
      text: "Total Value",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (
          (resourceTable === "protectedResources" &&
            formatExtraData.isProtectedResourcesTableEditable &&
            formatExtraData.editedProtectedResource.resourceTableId === row.resourceTableId) ||
          (resourceTable === "historicalResources" &&
            formatExtraData.isHistoricalResourcesTableEditable &&
            formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId)
        ) {
          return (
            <Form.Control
              type="number"
              name="totalValuation"
              onChange={
                resourceTable === "protectedResources"
                  ? (e) => this.updateEditedProtectedResource(e, row)
                  : (e) => this.updateEditedHistoricalResource(e, row)
              }
              value={
                resourceTable === "protectedResources"
                  ? formatExtraData.editedProtectedResource.totalValuation
                  : formatExtraData.editedHistoricalResource.totalValuation
              }
            />
          );
        } else {
          return UtilityFunctions.getValueAsCurrency(row.totalValuation);
        }
      },
      formatExtraData: this.state,
      headerAlign: "left",
      align: "left",
      headerStyle: () => {
        return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      style: { fontSize: "14px" },
    };
  }

  getUnitValuationColumn(resourceTable) {
    return {
      dataField: "valuePerUnitOfMeasure",
      text: "Unit Value",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (
          (resourceTable === "protectedResources" &&
            formatExtraData.isProtectedResourcesTableEditable &&
            formatExtraData.editedProtectedResource.resourceTableId === row.resourceTableId) ||
          (resourceTable === "historicalResources" &&
            formatExtraData.isHistoricalResourcesTableEditable &&
            formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId)
        ) {
          return (
            <Form.Control
              type="number"
              name="valuePerUnitOfMeasure"
              onChange={
                resourceTable === "protectedResources"
                  ? (e) => this.updateEditedProtectedResource(e, row)
                  : (e) => this.updateEditedHistoricalResource(e, row)
              }
              value={
                resourceTable === "protectedResources"
                  ? formatExtraData.editedProtectedResource.valuePerUnitOfMeasure
                  : formatExtraData.editedHistoricalResource.valuePerUnitOfMeasure
              }
            />
          );
        } else {
          return UtilityFunctions.getValueAsCurrency(row.valuePerUnitOfMeasure);
        }
      },
      formatExtraData: this.state,
      headerAlign: "left",
      align: "left",
      headerStyle: () => {
        return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      style: { fontSize: "14px" },
    };
  }

  getValuationUOMColumn() {
    return {
      dataField: "valuationUnitOfMeasure",
      text: "UOMs",
      formatter: (cell, row) => {
        return UtilityFunctions.getDisplayTextFromFieldObject(row.valuationUnitOfMeasure);
      },
      headerAlign: "left",
      align: "left",
      headerStyle: () => {
        return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      style: { fontSize: "14px" },
      editable: false,
    };
  }

  getResourceQuantityColumn(resourceTable) {
    return {
      dataField: "quantity",
      text: "Quantity",
      headerAlign: "left",
      align: "left",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (
          (resourceTable === "protectedResources" &&
            formatExtraData.isProtectedResourcesTableEditable &&
            formatExtraData.editedProtectedResource.resourceTableId === row.resourceTableId) ||
          (resourceTable === "historicalResources" &&
            formatExtraData.isHistoricalResourcesTableEditable &&
            formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId)
        ) {
          return (
            <Form.Control
              type="number"
              name="quantity"
              onChange={
                resourceTable === "protectedResources"
                  ? (e) => this.updateEditedProtectedResource(e, row)
                  : (e) => this.updateEditedHistoricalResource(e, row)
              }
              value={
                resourceTable === "protectedResources"
                  ? formatExtraData.editedProtectedResource.quantity
                  : formatExtraData.editedHistoricalResource.quantity
              }
            />
          );
        } else {
          return cell;
        }
      },
      formatExtraData: this.state,
      headerStyle: () => {
        return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      style: { fontSize: "14px" },
    };
  }

  getResourceNameColumn() {
    return {
      dataField: "resourceName",
      text: "Resource",
      headerStyle: () => {
        return { width: "15%", fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      style: { fontSize: "14px" },
      headerAlign: "left",
      align: "left",
      editable: false,
    };
  }

  getValuationYearColumn(resourceTable) {
    return {
      dataField: "valuationYear",
      text: "Year",
      headerAlign: "left",
      align: "left",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (
          resourceTable === "historicalResources" &&
          formatExtraData.isHistoricalResourcesTableEditable &&
          formatExtraData.editedHistoricalResource.resourceTableId === row.resourceTableId
        ) {
          return (
            <Form.Control
              type="number"
              name="valuationYear"
              onChange={(e) => this.updateEditedHistoricalResource(e, row)}
              value={formatExtraData.editedHistoricalResource.valuationYear}
            />
          );
        } else {
          return cell;
        }
      },
      formatExtraData: this.state,
      headerStyle: () => {
        return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
      },
      style: { fontSize: "14px" },
    };
  }

  async getMappedRecCemsData(agreementData, propertiesData) {
    let recCemsData = [];
    await Promise.all(
      agreementData.agreementProperties.map(async (property) => {
        const propertyData = propertiesData.find(
          (p) => p.propertyUniqueIdentifier === property.propertyUniqueReference
        );
        property.recommendations.forEach((recommendation) => {
          if (propertyData) {
            recommendation.speciesTableId = `${recommendation.recCemUniqueReference}|${property.propertyUniqueReference}`;
            recommendation.speciesUniqueIdentifier = recommendation.recCemUniqueReference;
            this.mapSpeciesPropertiesData(propertyData, recommendation);
          }
          recCemsData.push(recommendation);
        });
        property.cems.forEach((cems) => {
          if (propertyData) {
            cems.speciesTableId = `${cems.recCemUniqueReference}|${property.propertyUniqueReference}`;
            cems.speciesUniqueIdentifier = cems.recCemUniqueReference;
            this.mapSpeciesPropertiesData(propertyData, cems);
          }
          recCemsData.push(cems);
        });
      })
    );
    return recCemsData;
  }

  handleNewHstoricalLoss = (historicalLossData) => {
    let { historicalResourcesData, addHistoricalLossData } = this.state;
    let resourceToEdit = historicalResourcesData.find(
      (resource) => resource.resourceTableId === addHistoricalLossData.resourceTableId
    );

    if (resourceToEdit) {
      const newHistoricalResource = JSON.parse(JSON.stringify(resourceToEdit));
      newHistoricalResource.historicalLosses = historicalLossData;

      const index = historicalResourcesData.indexOf(resourceToEdit);
      historicalResourcesData[index] = newHistoricalResource;

      this.setState({ historicalResourcesData });
    }
  };

  mapAgreementResourcesData(agreementData, propertiesData) {
    const protectedResourcesData = [];
    const historicalResourcesData = [];

    agreementData.agreementProperties.forEach((property) => {
      if (property.agreementResources?.length > 0) {
        for (const resource of property.agreementResources) {
          resource.resourceTableId = resource.isHistorical
            ? `${resource.resourceUniqueReference}|${property.propertyUniqueReference}|${resource.valuationYear}|${resource.valuePerUnitOfMeasure}`
            : `${resource.resourceUniqueReference}|${property.propertyUniqueReference}|${resource.valuePerUnitOfMeasure}`;

          const propertyData = propertiesData.find(
            (p) => p.propertyUniqueIdentifier === property.propertyUniqueReference
          );
          if (propertyData) {
            resource.resourceUniqueIdentifier = resource.resourceUniqueReference;
            this.mapResourcePropertiesData(propertyData, resource);
          }

          if (resource.isHistorical) {
            this.mapLossData(resource);
            historicalResourcesData.push(resource);
          } else protectedResourcesData.push(resource);
        }
      }
    });

    return { protectedResourcesData, historicalResourcesData };
  }

  mapResourcePropertiesData(propertyData, resource) {
    let checkedParcels = [];
    let parcelNumber = 1;
    if (propertyData.parcels && propertyData.parcels.length > 0) {
      checkedParcels = propertyData.parcels.map((parcel) => ({
        parcelUniqueIdentifier: parcel.parcelUniqueIdentifier,
        parcelName: "Parcel " + parcelNumber++,
      }));
    }

    resource.checkedProperties = [
      {
        propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
        propertyName: propertyData.propertyName,
        checkedParcels: checkedParcels,
        parcels: checkedParcels,
      },
    ];
  }

  mapLossData(resource) {
    if (resource.historicalLossRecords && resource.historicalLossRecords.length > 0)
      resource.historicalLosses = resource.historicalLossRecords?.map((loss) => ({
        lossTableId: `${resource.resourceUniqueReference}|${loss.causingSpeciesUniqueReference}`,
        percentage: loss.lossPercentage,
        quantity: loss.lossQuantity,
        resourceName: resource.resourceName,
        species: {
          label: loss.causingSpeciesName,
          value: loss.causingSpeciesUniqueReference,
        },
        totalValue: loss.totalValuation,
        unitOfMeasureName: UtilityFunctions.getDisplayTextFromFieldObject(resource.valuationUnitOfMeasure),
        historicalLossUniqueIdentifier: loss.historicalLossUniqueIdentifier,
      }));
  }

  async getMappedSpeciesData(agreementData, propertiesData) {
    let targetedSpeciesData = [];
    await Promise.all(
      agreementData.agreementProperties.map(async (property) => {
        const propertyData = propertiesData.find(
          (p) => p.propertyUniqueIdentifier === property.propertyUniqueReference
        );
        property.targetedSpecies.forEach((species) => {
          if (propertyData) {
            species.speciesTableId = `${species.speciesUniqueReference}|${property.propertyUniqueReference}`;
            species.speciesUniqueIdentifier = species.speciesUniqueReference;
            this.mapSpeciesPropertiesData(propertyData, species);
          }
          targetedSpeciesData.push(species);
        });

        await Promise.all(
          property.parcels.map(async (parcel, index) => {
            await this.mapSpeciesParcelData(parcel, property, propertyData, targetedSpeciesData, index);
          })
        );
      })
    );
    return targetedSpeciesData;
  }

  mapSpeciesPropertiesData(propertyData, species) {
    let checkedParcels = [];
    let parcelNumber = 1;
    if (propertyData.parcels && propertyData.parcels.length > 0) {
      checkedParcels = propertyData.parcels.map((parcel) => ({
        parcelUniqueIdentifier: parcel.parcelUniqueIdentifier,
        parcelName: "Parcel " + parcelNumber++,
      }));
    }
    species.checkedProperties = [
      {
        propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
        propertyName: propertyData.propertyName,
        checkedParcels: checkedParcels,
        parcels: checkedParcels,
      },
    ];
  }

  async mapSpeciesParcelData(parcel, property, propertyData, targetedSpeciesData, index) {
    await Promise.all(
      parcel.targetedSpecies.map(async (species) => {
        if (
          !property.targetedSpecies.find(
            (propertySpecies) => propertySpecies.speciesUniqueReference === species.speciesUniqueReference
          )
        ) {
          species.speciesTableId = `${species.speciesUniqueReference}|${propertyData.propertyUniqueIdentifier}`;
          species.speciesUniqueIdentifier = species.speciesUniqueReference;
          let findSpeciesName = targetedSpeciesData.find(
            (targetedSpecies) => targetedSpecies.speciesUniqueReference === species.speciesUniqueReference
          )?.speciesName;

          findSpeciesName
            ? (species.speciesName = findSpeciesName)
            : (species.speciesName = await this.getSpeciesName(species.speciesUniqueReference));
          species.checkedProperties = [
            {
              propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
              propertyName: propertyData.propertyName,
              checkedParcels: [
                {
                  parcelUniqueIdentifier: parcel.parcelUniqueReference,
                  parcelName: "Parcel " + (index + 1),
                },
              ],
              parcels: [
                {
                  parcelUniqueIdentifier: parcel.parcelUniqueReference,
                  parcelName: "Parcel " + (index + 1),
                },
              ],
            },
          ];
          let foundSpeciesOnPropertyParcel = false;
          targetedSpeciesData.forEach((targetedSpecies) => {
            targetedSpecies.checkedProperties.forEach((targetedSpeciesCheckedProperty) => {
              const speciesMatch = targetedSpecies.speciesUniqueReference === species.speciesUniqueReference;
              const propertyMatch =
                targetedSpeciesCheckedProperty.propertyUniqueIdentifier === propertyData.propertyUniqueIdentifier;
              const parcelNotIncluded = !targetedSpeciesCheckedProperty.checkedParcels.find(
                (p) => p.parcelUniqueIdentifier === parcel.parcelUniqueIdentifier
              );
              if (speciesMatch && propertyMatch && parcelNotIncluded) {
                targetedSpeciesCheckedProperty.checkedParcels.push({
                  parcelUniqueIdentifier: parcel.parcelUniqueReference,
                  parcelName: "Parcel " + (index + 1),
                });
                targetedSpeciesCheckedProperty.parcels.push({
                  parcelUniqueIdentifier: parcel.parcelUniqueReference,
                  parcelName: "Parcel " + (index + 1),
                });
                foundSpeciesOnPropertyParcel = true;
              }
            });
          });
          if (!foundSpeciesOnPropertyParcel) {
            targetedSpeciesData.push(species);
          }
        }
      })
    );
  }

  async getSpeciesName(speciesGuid) {
    let speciesName = "";
    const getCall = await ReferenceFileAPI.GetSpecies(speciesGuid);
    if (getCall?.successful) {
      speciesName = getCall.data.name;
    }
    return speciesName;
  }

  async getMappedMethodsData(agreementData, propertiesData) {
    let methodsData = [];
    await Promise.all(
      agreementData.agreementProperties.map(async (property) => {
        const propertyData = propertiesData.find(
          (p) => p.propertyUniqueIdentifier === property.propertyUniqueReference
        );
        property.allowedMethods.forEach((method) => {
          if (propertyData) {
            method.methodTableId = `${method.methodUniqueReference}|${property.propertyUniqueReference}`;
            method.methodUniqueIdentifier = method.methodUniqueReference;
            this.mapMethodPropertiesdata(propertyData, method);
          }

          methodsData.push(method);
        });

        await Promise.all(
          property.parcels.map(async (parcel, index) => {
            await this.mapMethodParcelsData(parcel, property, propertyData, methodsData, index);
          })
        );
      })
    );
    return methodsData;
  }

  mapMethodPropertiesdata(propertyData, method) {
    let checkedParcels = [];
    let parcelNumber = 1;
    if (propertyData.parcels && propertyData.parcels.length > 0) {
      checkedParcels = propertyData.parcels?.map((parcel) => ({
        parcelUniqueIdentifier: parcel.parcelUniqueIdentifier,
        parcelName: "Parcel " + parcelNumber++,
      }));
    }
    method.checkedProperties = [
      {
        propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
        propertyName: propertyData.propertyName,
        checkedParcels: checkedParcels,
        parcels: checkedParcels,
      },
    ];
  }

  async mapMethodParcelsData(parcel, property, propertyData, methodsData, index) {
    await Promise.all(
      parcel.allowedMethods.map(async (method) => {
        if (
          !property.allowedMethods.find(
            (propertyMethod) => propertyMethod.methodUniqueReference === method.methodUniqueReference
          )
        ) {
          method.methodTableId = `${method.methodUniqueReference}|${propertyData.propertyUniqueIdentifier}`;
          method.methodUniqueIdentifier = method.methodUniqueReference;
          let findMethodName = methodsData.find(
            (allowedMethod) => allowedMethod.methodUniqueReference === method.methodUniqueReference
          )?.methodName;
          findMethodName
            ? (method.methodName = findMethodName)
            : (method.methodName = await this.getMethodName(method.methodUniqueReference));

          method.checkedProperties = [
            {
              propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
              propertyName: propertyData.propertyName,
              checkedParcels: [
                {
                  parcelUniqueIdentifier: parcel.parcelUniqueReference,
                  parcelName: "Parcel " + (index + 1),
                },
              ],
              parcels: [
                {
                  parcelUniqueIdentifier: parcel.parcelUniqueReference,
                  parcelName: "Parcel " + (index + 1),
                },
              ],
            },
          ];
          let foundMethodOnPropertyParcel = false;
          methodsData.forEach((allowedMethod) => {
            allowedMethod.checkedProperties.forEach((allowedMethodCheckedProperty) => {
              const methodMatch = allowedMethod.methodUniqueReference === method.methodUniqueReference;
              const propertyMatch =
                allowedMethodCheckedProperty.propertyUniqueIdentifier === propertyData.propertyUniqueIdentifier;
              const parcelNotIncluded = !allowedMethodCheckedProperty.checkedParcels.find(
                (p) => p.parcelUniqueIdentifier === parcel.parcelUniqueIdentifier
              );
              if (methodMatch && propertyMatch && parcelNotIncluded) {
                allowedMethodCheckedProperty.checkedParcels.push({
                  parcelUniqueIdentifier: parcel.parcelUniqueReference,
                  parcelName: "Parcel " + (index + 1),
                });
                allowedMethodCheckedProperty.parcels.push({
                  parcelUniqueIdentifier: parcel.parcelUniqueReference,
                  parcelName: "Parcel " + (index + 1),
                });
                foundMethodOnPropertyParcel = true;
              }
            });
          });
          if (!foundMethodOnPropertyParcel) {
            methodsData.push(method);
          }
        }
      })
    );
  }

  async getMethodName(methodGuid) {
    let name = "";
    const getCall = await ReferenceFileAPI.GetMethod(methodGuid);
    if (getCall?.successful) {
      name = getCall.data.methodName;
    }
    return name;
  }

  getSpecialInstructionsData(agreementData) {
    const specialInstructionsData = [];
    agreementData.agreementProperties.forEach((property) => {
      if (property.specialInstructions?.length > 0) {
        this.mapSpecialInstructionsData(property, specialInstructionsData);
      }
    });
    return specialInstructionsData;
  }

  mapSpecialInstructionsData(property, specialInstructionsData) {
    for (const specialInstruction of property.specialInstructions) {
      specialInstruction.propertyUniqueIdentifier = property.propertyUniqueReference;
      specialInstructionsData.push(specialInstruction);
    }
  }

  async getPropertyAssociatedContactsData(agreementData) {
    let associatedPropertyContactsData = [];

    for (const property of agreementData.agreementProperties) {
      if (property.additionalContacts?.length > 0) {
        await this.mapPropertiesContacstData(property, associatedPropertyContactsData);
      }
    }

    return associatedPropertyContactsData;
  }

  async mapPropertiesContacstData(property, associatedPropertyContactsData) {
    for (const contact of property.additionalContacts) {
      let contactData = await this.getContactData(contact.contactUniqueReference);
      if (contactData) {
        contactData.propertyUniqueIdentifier = property.propertyUniqueReference;
        contactData.contactRole = UtilityFunctions.getDisplayTextFromFieldObject(contact.contactRoleOnAgreement);
        associatedPropertyContactsData.push(contactData);
      }
    }
  }

  async getAgreementAssociatedContactsData(agreementData) {
    let associatedAgreementContactsData = [];

    for (const contact of agreementData.additionalContacts) {
      let contactData = await this.getContactData(contact.contactUniqueReference);
      if (contactData) {
        contactData.contactRole = UtilityFunctions.getDisplayTextFromFieldObject(contact.contactRoleOnAgreement);
        associatedAgreementContactsData.push(contactData);
      }
    }

    return associatedAgreementContactsData;
  }

  async getAgreement12aAttachment(agreementData) {
    if (agreementData.agreementUniqueIdentifier) {
      const response = await AgreementAPI.Get12aAttachment(agreementData.agreementUniqueIdentifier);
      if (response) {
        let blob = new Blob([response], { type: "application/pdf" });
        return blob;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve 12A attachment",
          apiName: "Get12aAttachment",
          responseUnsuccessful: response?.unsuccessful,
          responseMessage: response?.message,
        });
      }
    }
  }

  async getContactData(contactUniqueId) {
    const contactDataResponse = await AgreementEntitiesAPI.GetContact(contactUniqueId);
    if (!contactDataResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve contact data",
        apiName: "GetContact",
        responseUnsuccessful: contactDataResponse?.unsuccessful,
        responseMessage: contactDataResponse?.message,
      });
      return null;
    }

    let contactData = contactDataResponse.data;
    contactData.primaryPhoneNumber = UtilityFunctions.getPrimaryPhoneNumber(contactData.phoneNumbers);
    contactData.fullName = UtilityFunctions.getContactNameFromDataObject(contactData);
    contactData.fullMailingAddress = this.getPrimaryAddress(contactData.primaryMailingAddress, "contact");
    return contactData;
  }

  async getUserData(userProfileId) {
    const userDataResponse = await RoleAuthorizationAPI.GetUserWhoOwnsProfile(userProfileId);
    if (userDataResponse?.successful) {
      let userData = userDataResponse.data;
      userData.fullName = userData.firstName + " " + userData.lastName;
      userData.fullMailingAddress = this.getPrimaryAddress(
        {
          mailingStreet: userData.streetAddress,
          mailingCity: userData.city,
          mailingZip: userData.zip,
          mailingStateUniqueId: userData.stateOfAddressUniqueReference,
        },
        "contact"
      );
      userData.fieldAgentWsUserProfileUniqueReference = userProfileId;
      return userData;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve user data from profile",
        apiName: "GetUserWhoOwnsProfile",
        responseUnsuccessful: userDataResponse?.unsuccessful,
        responseMessage: userDataResponse?.message,
      });
    }
  }

  async removeRecCemsFromAgreementProperties() {
    let removeRecCallSuccess = true;
    let removeCemCallSuccess = true;
    let recommendationsToDelete = [];
    let cemsToDelete = [];

    this.state.initialRecs.forEach((rec) => {
      const currentResourceRecord = this.state.recommendations.find((r) => r.recCemId === rec.recCemId);
      if (!currentResourceRecord) {
        recommendationsToDelete.push(rec);
      }
    });

    this.state.initialCems.forEach((rec) => {
      const currentResourceRecord = this.state.cooperatorEmployedMethods.find((r) => r.recCemId === rec.recCemId);
      if (!currentResourceRecord) {
        cemsToDelete.push(rec);
      }
    });

    let removeRecsBody = [];
    let removeCemsBody = [];

    if (recommendationsToDelete.length > 0 || cemsToDelete.length > 0) {
      this.state.agreementData.agreementProperties.forEach((property) => {
        let propertyID = property.propertyUniqueReference
          ? property.propertyUniqueReference
          : property.propertyUniqueIdentifier;

        const removeRecs = recommendationsToDelete.filter((rec) =>
          rec.checkedProperties.some(
            (p) => (p.propertyUniqueReference ? p.propertyUniqueReference : p.propertyUniqueIdentifier) === propertyID
          )
        );
        const removeCems = cemsToDelete.filter((rec) =>
          rec.checkedProperties.some(
            (p) => (p.propertyUniqueReference ? p.propertyUniqueReference : p.propertyUniqueIdentifier) === propertyID
          )
        );

        let recForThisProperty = [];
        let cemForThisProperty = [];
        removeRecs.forEach((rec) => {
          recForThisProperty.push({ recCemUniqueReference: rec.recCemId });
        });
        removeCems.forEach((rec) => {
          cemForThisProperty.push({ recCemUniqueReference: rec.recCemId });
        });

        let removeRecRecord = {
          recCemsToRemove: recForThisProperty,
          propertyUniqueReference: propertyID,
        };
        removeRecsBody.push(removeRecRecord);

        let removeCemRecord = {
          recCemsToRemove: cemForThisProperty,
          propertyUniqueReference: propertyID,
        };
        removeCemsBody.push(removeCemRecord);
      });

      if (removeRecsBody.length > 0) {
        var removeRecAction = await AgreementAPI.RemoveRecommendationsFromAgreementProperties(
          this.state.agreementData.agreementUniqueIdentifier,
          removeRecsBody
        );
        if (!removeRecAction?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error removing Recommendations from agreement property",
            apiName: "RemoveRecommendationsFromAgreementProperties",
            responseUnsuccessful: removeRecAction?.unsuccessful,
            responseMessage: removeRecAction?.message,
          });
        }
        removeRecCallSuccess = removeRecAction.successful ? removeRecAction.successful : false;
      }

      if (removeCemsBody.length > 0) {
        var removeCemAction = await AgreementAPI.RemoveCooperatorEmployedMethodsFromAgreementProperties(
          this.state.agreementData.agreementUniqueIdentifier,
          removeCemsBody
        );
        if (!removeCemAction?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error removing Cooperator Employed Methods from agreement property",
            apiName: "RemoveCooperatorEmployedMethodsFromAgreementProperties",
            responseUnsuccessful: removeCemAction?.unsuccessful,
            responseMessage: removeCemAction?.message,
          });
        }
        removeCemCallSuccess = removeCemAction.successful ? removeCemAction.successful : false;
      }
    }

    return removeRecCallSuccess && removeCemCallSuccess;
  }

  async addRecCemsToAgreementProperties() {
    const { recommendations, cooperatorEmployedMethods, agreementData } = this.state;

    let recCemsToAgreementPropResponse = "";
    let success = false;
    let recommendationReferencesToAdd = [];
    let cemReferencesToAdd = [];
    let sendArray = [];
    let recArray = [];
    let cemsArray = [];
    let addToProperties = [];
    if (recommendations) {
      for (let recCem of recommendations) {
        if (recCem.isNewRecCem) {
          recommendationReferencesToAdd.push(recCem.recCemId);

          recCem.checkedProperties.forEach((property) => {
            addToProperties.push(property.propertyUniqueReference);
          });
          let recDataObj = {
            recommendationReferencesToAdd: recommendationReferencesToAdd,
            cemReferencesToAdd: [],
            addToProperties: addToProperties,
          };
          recArray.push(recDataObj);
          sendArray.push(recDataObj);
          addToProperties = [];
          recommendationReferencesToAdd = [];
        }
      }
    }

    if (cooperatorEmployedMethods) {
      for (let recCem of cooperatorEmployedMethods) {
        if (recCem.isNewRecCem) {
          cemReferencesToAdd.push(recCem.recCemId);

          recCem.checkedProperties.forEach((property) => {
            addToProperties.push(property.propertyUniqueReference);
          });
          let recDataObj = {
            recommendationReferencesToAdd: [],
            cemReferencesToAdd: cemReferencesToAdd,
            addToProperties: addToProperties,
          };
          cemsArray.push(recDataObj);
          sendArray.push(recDataObj);
          addToProperties = [];
          cemReferencesToAdd = [];
        }
      }
    }

    if (sendArray.length > 0) {
      let addResult = await AgreementAPI.AddRecCemsToAgreementProperties(
        agreementData.agreementUniqueIdentifier,
        sendArray
      );
      if (addResult?.successful) {
        success = true;
        recCemsToAgreementPropResponse = addResult;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding Rec/Cems to the agreement properties",
          apiName: "AddRecCemsToAgreementProperties",
          responseUnsuccessful: addResult?.unsuccessful,
          responseMessage: addResult?.message,
        });
      }
    } else {
      success = true;
    }

    return { success, recCemsToAgreementPropResponse };
  }

  getStateNameFromId(stateId) {
    const { states } = this.state;
    const currentState = states.find((state) => state.stateUniqueIdentifier === stateId);
    return currentState ? currentState.state_name : "";
  }

  getCountryNameFromId(countryId) {
    const { countries } = this.state;
    const country = countries.find((country) => country.id === countryId);
    return country ? country.name : "";
  }

  getPrimaryAddress(providedAddress, entityType) {
    let primaryAddress = "";

    switch (entityType) {
      case "contact":
        primaryAddress = this.getContactPrimaryAddress(providedAddress, primaryAddress);
        break;
      case "property":
        UtilityFunctions.getPropertyPrimaryAddress(providedAddress);
        break;
      default:
        break;
    }

    return primaryAddress;
  }

  getContactPrimaryAddress(providedAddress) {
    let primaryAddress = "";
    if (providedAddress) {
      const { mailingStreet, mailingCity, mailingStateUniqueId, mailingZip, mailingCountryUniqueId } = providedAddress;
      primaryAddress = `${mailingStreet ? mailingStreet + ", " : ""}${mailingCity ? mailingCity + ", " : ""}${mailingStateUniqueId ? this.getStateNameFromId(mailingStateUniqueId) + ", " : ""
        }${mailingZip ? mailingZip : ""}${mailingCountryUniqueId ? ", " + this.getCountryNameFromId(mailingCountryUniqueId) : ""
        }`;
    }

    return primaryAddress;
  }

  async getPropertiesData(agreementData) {
    let propertiesData = [];

    const propertyUniqueReferences = agreementData.agreementProperties.map((x) => x.propertyUniqueReference);
    const resolvePropertiesAction = await AgreementEntitiesAPI.ResolveProperties(propertyUniqueReferences);
    if (resolvePropertiesAction.successful) {
      for (let property of resolvePropertiesAction.data) {
        let propertyToUse = property;
        if (property.proposedEditsForPropertyWhenPartOfActiveAgreement) {
          propertyToUse = property.proposedEditsForPropertyWhenPartOfActiveAgreement;
        }
        propertyToUse.checkedParcels = propertyToUse.parcels;
        const associatedAgreementProperty = agreementData.agreementProperties.find(
          (p) => p.propertyUniqueReference === propertyToUse.propertyUniqueIdentifier
        );
        propertiesData.push({ ...propertyToUse, ...associatedAgreementProperty });
      }
    }

    return propertiesData;
  }

  handleApprovalProcess = async () => {
    const { agreementData } = this.state;
    let agreementApprovalResult = true;

    if (!agreementData.agreementSigned && Number.parseInt(this.state.data.agreementType) !== 2) {
      agreementApprovalResult = false;
      toast.warning("You must first upload the signature before submitting the agreement.");
    } else if (agreementData.agreementStatus?.id === AgreementsStatuses.Draft) {
      agreementApprovalResult = await this.sendAgreementForApproval();
    } else if (agreementData.agreementStatus?.id === AgreementsStatuses.Pending) {
      agreementApprovalResult = await this.recallAgreementApproval();
    }

    this.validateApprovalResults(agreementApprovalResult);
  };

  handleAgreementApproval = async () => {
    const agreementApprovalResponse = await AgreementAPI.ApproveAgreement(
      this.state.agreementData.agreementUniqueIdentifier
    );
    const isApprovalSuccesfull = this.isSuccessfulAPIResponse(agreementApprovalResponse);
    if (isApprovalSuccesfull) {
      toast.success("Agreement was successfully approved.");
      const { agreementData } = this.state;
      agreementData.agreementStatus = {
        displayText: "Active",
        id: 3,
        name: "active",
      };
      this.setState({ agreementData });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to approve agreement",
        apiName: "ApproveAgreement",
        responseUnsuccessful: agreementApprovalResponse?.unsuccessful,
        responseMessage: agreementApprovalResponse?.message,
      });
    }
  };

  showFlagAgreementModal = () => {
    this.setState({ showFlagAgreementModal: true });
  };

  handleFlagAgreement = async (flagData) => {
    if (!this.state.flagUniqueIdentifier && !flagData.flagHistory) {
      let body = {
        flagReason: flagData.reasonForFlag,
      };
      const agreementApprovalResponse = await AgreementAPI.FlagAgreement(
        this.state.agreementData.agreementUniqueIdentifier,
        body
      );
      const isApprovalSuccesfull = this.isSuccessfulAPIResponse(agreementApprovalResponse);
      if (isApprovalSuccesfull) {
        toast.success("Agreement was successfully flagged.");
        await this.getFlagsForAgreement(this.state.agreementData.agreementUniqueIdentifier);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Agreement flag process failed",
          apiName: "FlagAgreement",
          responseUnsuccessful: agreementApprovalResponse?.unsuccessful,
          responseMessage: agreementApprovalResponse?.message,
        });
      }
    } else {
      let body = {
        messageContents: flagData.newFlagHistory,
      };
      const agreementApprovalResponse = await AgreementAPI.AddMessageToFlag(this.state.flagUniqueIdentifier, body);
      const isApprovalSuccesfull = this.isSuccessfulAPIResponse(agreementApprovalResponse);
      if (isApprovalSuccesfull) {
        toast.success("Flag history was successfully added to the Agreement.");
        await this.getFlagsForAgreement(this.state.agreementData.agreementUniqueIdentifier);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Agreement flag process failed",
          apiName: "AddMessageToFlag",
          responseUnsuccessful: agreementApprovalResponse?.unsuccessful,
          responseMessage: agreementApprovalResponse?.message,
        });
      }
    }
  };

  getFlagsForAgreement = async (agreementUniqueIdentifier) => {
    let flagHistory = await AgreementAPI.GetFlagsForAgreement(agreementUniqueIdentifier);
    if (flagHistory?.data?.length > 0) {
      this.setState({
        flagHistory: flagHistory?.data,
        flagUniqueIdentifier: flagHistory?.data[0].flagUniqueIdentifier,
      });
    }
  };

  showAgreementRejectionModal = async () => {
    this.setState({ showAgreementRejectionModal: true });
  };

  showAgreementFlagModal = async () => {
    this.setState({ showAgreementFlagModal: true });
  };

  showDeleteAgreementModal = () => {
    this.setState({ showDeleteAgreementModal: true });
  };

  showAmendAgreementConfirmationModal = () => {
    this.setState({ showAmendAgreementConfirmationModal: true });
  };

  extendFLA = async () => {
    const extendFLA = await this.ExtendFederalLandAgreementOneQuarter();
    if (!extendFLA) {
      ErrorHandler.showError("Extending FLA to one quarter failed");
    } else {
      toast.success("FLA has been extended by a quarter");
      await this.updatePageAgreementData();
    }
  };

  showAgreementAmendmentHistory = async () => {
    this.setState({ isFormLoading: true });
    const agreementAmendmentData = await this.getAgreementAmendmentData();
    this.setState({ agreementAmendmentData, showAgreementAmendmentHistory: true, isFormLoading: false });
  };

  async getAgreementAmendmentData() {
    let { agreementData, agreementAmendmentData } = this.state;
    if (agreementData?.agreementUniqueIdentifier && agreementData.parentAgreement) {
      const getAgreementChangeFieldsResponse = await AgreementAPI.GetAmendedAgreementChangedFields(
        agreementData.parentAgreement,
        agreementData.agreementUniqueIdentifier
      );
      if (!getAgreementChangeFieldsResponse?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Agreement Amendment History could not be retrieved",
          apiName: "GetAmendedAgreementChangedFields",
          responseUnsuccessful: getAgreementChangeFieldsResponse?.unsuccessful,
          responseMessage: getAgreementChangeFieldsResponse?.message,
        });
      } else if (getAgreementChangeFieldsResponse.data?.rootElement) {
        agreementAmendmentData = getAgreementChangeFieldsResponse.data.rootElement;
      }
    }
    return agreementAmendmentData;
  }

  validateApprovalResults(agreementApprovalResult) {
    const { agreementData } = this.state;

    if (agreementApprovalResult) {
      if (agreementData.agreementStatus?.id === 1) {
        this.handleSuccessfulAgreementSubmission(agreementData);
      } else {
        this.handleSuccessfulAgreementRecall(agreementData);
      }
      this.setState({ agreementData });
    } else if (agreementData?.agreementSigned) {
      this.handleApprovalValidationErrors(agreementData);
    }
  }

  handleSuccessfulAgreementRecall(agreementData) {
    toast.success("You have successfully recall the approval of this agreement.");
    agreementData.agreementStatus = {
      id: 1,
      name: "draft",
      displayText: "Draft",
    };
  }

  handleSuccessfulAgreementSubmission(agreementData) {
    toast.success("You have successfully submitted this agreement.");

    agreementData.agreementStatus = {
      displayText: "Pending",
      id: 2,
      name: "pending",
    };
  }

  handleApprovalValidationErrors(agreementData) {
    if (agreementData.agreementStatus?.id === 1) {
      ErrorHandler.showError("Agreement submit for approval failed. See console log for more details.");
    } else if (agreementData.agreementStatus?.id === 2) {
      ErrorHandler.showError("Agreement approval recall failed. See console log for more details.");
    }
  }

  async sendAgreementForApproval() {
    const { agreementData, data } = this.state;
    const agreementApprovalResponse = await AgreementAPI.SendAgreementForApproval(
      agreementData.agreementUniqueIdentifier
    );

    let isSetParentExpirationSuccesfull = true;
    if (agreementApprovalResponse?.successful && agreementData.parentAgreement) {
      const setExpirationDateResponse = await AgreementAPI.SetAgreementExpirationDate(
        agreementData.parentAgreement,
        new Date().toISOString()
      );
      if (!setExpirationDateResponse?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "System failed to expire Parent Agreement",
          apiName: "SetAgreementExpirationDate",
          responseUnsuccessful: setExpirationDateResponse?.unsuccessful,
          responseMessage: setExpirationDateResponse?.message,
        });
      }

      isSetParentExpirationSuccesfull = setExpirationDateResponse?.successful === true;
    }
    const isApprovalSuccesfull = agreementApprovalResponse?.successful && isSetParentExpirationSuccesfull;
    if (isApprovalSuccesfull) {
      data.expirationDate = new Date(agreementApprovalResponse?.data.expirationDate).toLocaleDateString("en-CA", {
        timeZone: "UTC",
      });
      this.setState({ data });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "System failed to approve Agreement",
        apiName: "SendAgreementForApproval",
        responseUnsuccessful: agreementApprovalResponse?.unsuccessful,
        responseMessage: agreementApprovalResponse?.message,
      });
    }
    return isApprovalSuccesfull;
  }

  async ExtendFederalLandAgreementOneQuarter() {
    const { agreementData } = this.state;
    const agreementApprovalResponse = await AgreementAPI.ExtendFederalLandAgreementOneQuarter(
      agreementData.agreementUniqueIdentifier
    );
    if (!agreementApprovalResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "System failed to Extend Federal Land Agreement one quarter",
        apiName: "ExtendFederalLandAgreementOneQuarter",
        responseUnsuccessful: agreementApprovalResponse?.unsuccessful,
        responseMessage: agreementApprovalResponse?.message,
      });
    }
    const isApprovalSuccesfull = agreementApprovalResponse?.successful;
    return isApprovalSuccesfull;
  }

  async recallAgreementApproval() {
    const agreementRecallResponse = await AgreementAPI.RecallAgreement(
      this.state.agreementData.agreementUniqueIdentifier
    );
    const isRecallSuccesfull = this.isSuccessfulAPIResponse(agreementRecallResponse);
    if (!agreementRecallResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "System failed to recall agreement",
        apiName: "RecallAgreement",
        responseUnsuccessful: agreementRecallResponse?.unsuccessful,
        responseMessage: agreementRecallResponse?.message,
      });
    }
    return isRecallSuccesfull;
  }

  isSuccessfulAPIResponse(...responses) {
    let success = true;
    responses.forEach((response) => {
      if (!response || response.unsuccessful) success = false;
    });
    return success;
  }

  isAgreementApprovalEditable() {
    const { agreementData } = this.state;
    return (
      agreementData &&
      (agreementData.agreementStatus?.id === AgreementsStatuses.Draft ||
        agreementData.agreementStatus?.id === AgreementsStatuses.Pending)
    );
  }

  handleAgreementAmendOrRenew = async () => {
    this.setState({ isFormLoading: true });
    if (this.state.agreementData?.agreementUniqueIdentifier) {
      const agreementForAmendmentResponse = await AgreementAPI.SetupAgreementForAmendmentOrRenewal(
        this.state.agreementData.agreementUniqueIdentifier
      );
      if (agreementForAmendmentResponse?.successful) {
        toast.success("Agreement was successfully amended/renewed.");
        await this.updatePageData(agreementForAmendmentResponse);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to Amend/Renew Agreement",
          apiName: "SetupAgreementForAmendmentOrRenewal",
          responseUnsuccessful: agreementForAmendmentResponse?.unsuccessful,
          responseMessage: agreementForAmendmentResponse?.message,
        });
      }
    }
    this.setState({ isFormLoading: false });
  };

  editAgreement = async () => {
    if (
      this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Pending &&
      !this.props.permissions.canIApproveAgreementsForAssignedStates()
    ) {
      toast.warning(
        "Agreements in Pending status can only be edited by approvers. You can Recall the agreement to continue with the changes."
      );
    } else {
      if (!this.state.isAgreementEditable) {
        this.makeAgreementFormEditable();
      } else {
        await this.applyChanges();
      }
    }
  };

  makeAgreementFormEditable() {
    if (
      this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Draft ||
      this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Pending
    ) {
      this.props.history.push({
        pathname: "/agreement/create",
        state: {
          entityData: { agreementUniqueIdentifier: this.state.agreementData.agreementUniqueIdentifier },
          signatureNotRequired: this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Pending,
        },
      });
    } else {
      this.setState({
        isAgreementEditable: true,
        selectedSpeciesColumns: this.getSpeciesDataColumns(this.state.propertiesData, true),
        selectedMethodsColumns: this.getMethodsDataColumns(this.state.propertiesData, true),
        selectedRecColumns: this.getRecCemDataColumns(true, true),
        selectedCemColumns: this.getRecCemDataColumns(false, true),
        data: this.getAgreementDataField(this.state.agreementData),
      });
    }
  }

  async applyChanges() {
    //validation is not required for FLA
    if (parseInt(this.state.data.agreementType) !== 2) {
      if (
        this.state.agreementData?.isResourceRequired &&
        !(this.state.protectedResourcesData?.length > 0) &&
        !this.state.data.noProtectedResourcesRequired
      ) {
        toast.warning(
          'Please add at least one Protected Resource or select the option for "No Protected Resources" before applying the changes.'
        );
        return;
      }
      if (
        this.state.agreementData?.isHistoricalResourceRequired &&
        !(this.state.historicalResourcesData?.length > 0) &&
        !this.state.data.noHistoricalResourcesRequired
      ) {
        toast.warning(
          'Please add at least one Historical Resource or select the option for "No Historical Resources" before applying the changes.'
        );
        return;
      } else if (
        this.state.agreementData?.isHistoricalLossRequired &&
        !this.state.historicalResourcesData?.some((r) => r.historicalLosses?.length > 0) &&
        !this.state.data.noLossesRequired
      ) {
        toast.warning(
          'Please add at least one year\'s worth of data for historical losses or select the option for "No Losses" before applying the changes.'
        );
        return;
      }
    }

    let { showSuccessfulUpdateModal, isAgreementEditable } = this.state;
    const successCondition = await this.updateDataAndReturnResult();
    if (successCondition) {
      showSuccessfulUpdateModal = true;
      isAgreementEditable = false;
    } else {
      toast.error("Agreement Edit failed.");
    }
    await this.updatePageAgreementData();

    this.setState({ showSuccessfulUpdateModal, isAgreementEditable });
  }

  async updateDataAndReturnResult() {
    let updatedAgreementData = JSON.parse(JSON.stringify(this.state.agreementData));
    const isEditPropertySuccessful = await this.updatePropertiesData();
    const isContactRoleEditSuccessful = this.updateContactRoles();
    const isAgreementDetailsEditSuccessful = await this.updateAgreementDetails();
    const isFieldAgentsEditSuccessful = await this.updateFieldAgents();
    const isExpirationEditSuccessful = await this.updateExpirationDate();
    const isCooperatorEditSuccessful = await this.updateCooperator();
    const isEditSpeciesSuccessful = await this.updateSpeciesData();
    const isEditMethodsSuccessful = await this.updateMethodsData();
    const isEditInstructionsSuccessful = await this.updateSpecialInstructionsData();
    let isDataRequirementUpdateSuccessful = await this.updateDataRequirementFields();

    const {
      isResourcesUpdateSuccessful: isEditProtectedResourcesSuccessful,
      updatedData: updatedDataAfterProtectedResourcesUpdate,
    } = await this.updateResourcesData();
    if (updatedDataAfterProtectedResourcesUpdate) {
      updatedAgreementData = updatedDataAfterProtectedResourcesUpdate;
    }
    const isRemoveLossesFromAgreementSuccessful = await this.removeHistoricalLossesFromAgreement(updatedAgreementData);
    const {
      isResourcesUpdateSuccessful: isEditHistoricalResourcesSuccessful,
      updatedData: updatedDataAfterHistoricalResourcesUpdate,
    } = await this.updateResourcesData(true);
    if (updatedDataAfterHistoricalResourcesUpdate) {
      updatedAgreementData = updatedDataAfterHistoricalResourcesUpdate;
    }

    const isAddLossesToAgreementSuccessful = await this.addHistoricalLossesToAgreement(updatedAgreementData);
    const isAddRecCemResponseSuccessful = await this.addRecCemsToAgreementProperties();
    const isrecCemsRemoveAgreementPropResponse = await this.removeRecCemsFromAgreementProperties();

    const successCondition =
      isEditPropertySuccessful &&
      isEditSpeciesSuccessful &&
      isEditMethodsSuccessful &&
      isContactRoleEditSuccessful &&
      isAgreementDetailsEditSuccessful &&
      isFieldAgentsEditSuccessful &&
      isCooperatorEditSuccessful &&
      isEditInstructionsSuccessful &&
      isEditProtectedResourcesSuccessful &&
      isRemoveLossesFromAgreementSuccessful &&
      isEditHistoricalResourcesSuccessful &&
      isAddLossesToAgreementSuccessful &&
      isAddRecCemResponseSuccessful &&
      isrecCemsRemoveAgreementPropResponse &&
      isExpirationEditSuccessful &&
      isDataRequirementUpdateSuccessful;
    return successCondition;
  }

  async updateDataRequirementFields() {
    let agreementData = { ...this.state.agreementData };
    const { isProtectedResourceRequirementSuccessful, updatedAgreementData: setProtectedResourceRequirementData } =
      await this.setProtectedResourcesRequirement(agreementData);
    const { isHistoricalResourceRequirementSuccessful, updatedAgreementData: setHistoricalResourceRequirementData } =
      await this.setHistoricalResourcesRequirement(setProtectedResourceRequirementData);
    const isHistoricalLossesRequirementSuccessful = await this.setHistoricalLossesRequirement(
      setHistoricalResourceRequirementData
    );

    return (
      isProtectedResourceRequirementSuccessful &&
      isHistoricalResourceRequirementSuccessful &&
      isHistoricalLossesRequirementSuccessful
    );
  }

  async setProtectedResourcesRequirement(agreementData) {
    const { data } = this.state;
    let updatedAgreementData = JSON.parse(JSON.stringify(agreementData));
    let isProtectedResourceRequirementSuccessful = true;

    if (data.noProtectedResourcesRequired && agreementData?.isResourceRequired) {
      const setResourcesNotRequiredResponse = await this.setProtectedResourcesNotRequiredForAgreement();
      if (setResourcesNotRequiredResponse?.successful) {
        updatedAgreementData = setResourcesNotRequiredResponse.data;
      }
      isProtectedResourceRequirementSuccessful =
        isProtectedResourceRequirementSuccessful && setResourcesNotRequiredResponse?.successful;
    } else if (
      !agreementData?.isResourceRequired &&
      (!data.noProtectedResourcesRequired || this.state.protectedResourcesData?.length > 0)
    ) {
      const setResourcesRequiredResponse = await this.setProtectedResourcesRequiredForAgreement();
      if (setResourcesRequiredResponse?.successful) {
        updatedAgreementData = setResourcesRequiredResponse.data;
      }
      isProtectedResourceRequirementSuccessful =
        isProtectedResourceRequirementSuccessful && setResourcesRequiredResponse?.successful;
    }
    return { isProtectedResourceRequirementSuccessful, updatedAgreementData };
  }

  async setHistoricalResourcesRequirement(agreementData) {
    const { data } = this.state;
    let updatedAgreementData = JSON.parse(JSON.stringify(agreementData));
    let isHistoricalResourceRequirementSuccessful = true;

    if (data.noHistoricalResourcesRequired && agreementData?.isHistoricalResourceRequired) {
      const setHistoricalResourcesNotRequiredResponse = await this.setHistoricalResourcesNotRequiredForAgreement();
      if (setHistoricalResourcesNotRequiredResponse?.successful) {
        updatedAgreementData = setHistoricalResourcesNotRequiredResponse.data;
      }
      isHistoricalResourceRequirementSuccessful =
        isHistoricalResourceRequirementSuccessful && setHistoricalResourcesNotRequiredResponse.successful;
    } else if (
      !agreementData?.isHistoricalResourceRequired &&
      (!data.noHistoricalResourcesRequired || this.state.historicalResourcesData?.length > 0)
    ) {
      const setHistoricalResourcesRequiredResponse = (isHistoricalResourceRequirementSuccessful =
        await this.setHistoricalResourcesRequiredForAgreement());
      if (isHistoricalResourceRequirementSuccessful?.successful) {
        updatedAgreementData = isHistoricalResourceRequirementSuccessful.data;
      }
      isHistoricalResourceRequirementSuccessful =
        isHistoricalResourceRequirementSuccessful && isHistoricalResourceRequirementSuccessful.successful;
    }
    return { isHistoricalResourceRequirementSuccessful, updatedAgreementData };
  }

  async setHistoricalLossesRequirement(agreementData) {
    const { data } = this.state;
    let isHistoricalLossesRequirementSuccessful = true;

    if (data.noLossesRequired && agreementData?.isHistoricalLossRequired) {
      isHistoricalLossesRequirementSuccessful =
        isHistoricalLossesRequirementSuccessful && (await this.setHistoricalLossesNotRequiredForAgreement());
    } else if (
      !agreementData?.isHistoricalLossRequired &&
      (!data.noLossesRequired || this.state.historicalResourcesData?.some((r) => r.historicalLosses?.length > 0))
    ) {
      isHistoricalLossesRequirementSuccessful =
        isHistoricalLossesRequirementSuccessful && (await this.setHistoricalLossesRequiredForAgreement());
    }
    return isHistoricalLossesRequirementSuccessful;
  }

  async setProtectedResourcesNotRequiredForAgreement() {
    const setResourcesNotRequiredResponse = await AgreementAPI.ResourcesNotRequiredForAgreement(
      this.state.agreementData?.agreementUniqueIdentifier
    );
    if (!setResourcesNotRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set resources as not required for this agreement",
        apiName: "ResourcesNotRequiredForAgreement",
        responseUnsuccessful: setResourcesNotRequiredResponse?.unsuccessful,
        responseMessage: setResourcesNotRequiredResponse?.message,
      });
    }
    return setResourcesNotRequiredResponse;
  }

  async setProtectedResourcesRequiredForAgreement() {
    const setResourcesRequiredResponse = await AgreementAPI.ResourcesRequiredForAgreement(
      this.state.agreementData?.agreementUniqueIdentifier
    );
    if (!setResourcesRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set resources as required for this agreement",
        apiName: "ResourcesRequiredForAgreement",
        responseUnsuccessful: setResourcesRequiredResponse?.unsuccessful,
        responseMessage: setResourcesRequiredResponse?.message,
      });
    }
    return setResourcesRequiredResponse;
  }

  async setHistoricalResourcesNotRequiredForAgreement() {
    const setHistoricalResourcesNotRequiredResponse = await AgreementAPI.HistoricalResourcesNotRequiredForAgreement(
      this.state.agreementData?.agreementUniqueIdentifier
    );
    if (!setHistoricalResourcesNotRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set historical resources as not required for this agreement",
        apiName: "HistoricalResourcesNotRequiredForAgreement",
        responseUnsuccessful: setHistoricalResourcesNotRequiredResponse?.unsuccessful,
        responseMessage: setHistoricalResourcesNotRequiredResponse?.message,
      });
    }
    return setHistoricalResourcesNotRequiredResponse;
  }

  async setHistoricalResourcesRequiredForAgreement() {
    const setHistoricalResourcesRequiredResponse = await AgreementAPI.HistoricalResourcesRequiredForAgreement(
      this.state.agreementData?.agreementUniqueIdentifier
    );
    if (!setHistoricalResourcesRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set historical resources as required for this agreement",
        apiName: "HistoricalResourcesRequiredForAgreement",
        responseUnsuccessful: setHistoricalResourcesRequiredResponse?.unsuccessful,
        responseMessage: setHistoricalResourcesRequiredResponse?.message,
      });
    }
    return setHistoricalResourcesRequiredResponse;
  }

  async setHistoricalLossesNotRequiredForAgreement() {
    const setLossesNotRequiredResponse = await AgreementAPI.HistoricalLossesNotRequiredForAgreement(
      this.state.agreementData?.agreementUniqueIdentifier
    );
    if (!setLossesNotRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set historical losses as not required for this agreement",
        apiName: "HistoricalLossesNotRequiredForAgreement",
        responseUnsuccessful: setLossesNotRequiredResponse?.unsuccessful,
        responseMessage: setLossesNotRequiredResponse?.message,
      });
    }
    return setLossesNotRequiredResponse?.successful;
  }

  async setHistoricalLossesRequiredForAgreement() {
    const setLossesRequiredResponse = await AgreementAPI.HistoricalLossesRequiredForAgreement(
      this.state.agreementData?.agreementUniqueIdentifier
    );
    if (!setLossesRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set historical losses as required for this agreement",
        apiName: "HistoricalLossesRequiredForAgreement",
        responseUnsuccessful: setLossesRequiredResponse?.unsuccessful,
        responseMessage: setLossesRequiredResponse?.message,
      });
    }
    return setLossesRequiredResponse?.successful;
  }

  async updateExpirationDate() {
    let isExpirationDateEditSuccessful = true;

    if (this.state.data.expirationDate && this.state.expirationDateEdited) {
      const editExpirationDateCall = await AgreementAPI.SetAgreementExpirationDate(
        this.state.agreementData.agreementUniqueIdentifier,
        this.state.data.expirationDate
      );
      if (!editExpirationDateCall?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set agreement expiration date for parent agreement",
          apiName: "SetAgreementExpirationDate",
          responseUnsuccessful: editExpirationDateCall?.unsuccessful,
          responseMessage: editExpirationDateCall?.message,
        });
      }
      isExpirationDateEditSuccessful = editExpirationDateCall?.successful;
    }
    return isExpirationDateEditSuccessful;
  }

  async updateFieldAgents() {
    let isFieldAgentsEditSuccessful = true;
    if (this.state.fieldAgentsEdited) {
      const addCallBody = this.state.agreementData.additionalFieldAgents.map((f) => {
        return {
          fieldAgentWsUserProfileUniqueReference: f.contactGuid || f.contactUniqueId || f.contactUniqueReference,
        };
      });

      const removeFieldAgentsCall = await AgreementAPI.RemoveFieldAgentsFromAgreement(
        this.state.agreementData.agreementUniqueIdentifier,
        this.state.originalFieldAgents
      );
      if (!removeFieldAgentsCall?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to remove Field Agents from Agreement",
          apiName: "RemoveFieldAgentsFromAgreement",
          responseUnsuccessful: removeFieldAgentsCall?.unsuccessful,
          responseMessage: removeFieldAgentsCall?.message,
        });
      }
      const addFieldAgentsCall = await AgreementAPI.AddFieldAgentsToAgreement(
        this.state.agreementData.agreementUniqueIdentifier,
        addCallBody
      );
      if (!addFieldAgentsCall?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to add Field Agents from Agreement",
          apiName: "AddFieldAgentsToAgreement",
          responseUnsuccessful: addFieldAgentsCall?.unsuccessful,
          responseMessage: addFieldAgentsCall?.message,
        });
      }
      isFieldAgentsEditSuccessful = removeFieldAgentsCall?.successful && addFieldAgentsCall?.successful;
    }

    return isFieldAgentsEditSuccessful;
  }

  async updateCooperator() {
    let isCooperatorEditSuccessful = true;
    if (this.state.cooperatorEdited) {
      const primaryAddress = this.state.cooperatorData.primaryMailingAddress;
      let { cooperatorData } = this.state;
      cooperatorData.otherAddresses.shift();
      this.setState({ cooperatorData });

      const primaryAddressValid =
        primaryAddress &&
        primaryAddress?.mailingStreet &&
        primaryAddress?.mailingCity &&
        primaryAddress?.mailingStateUniqueId &&
        primaryAddress?.mailingCountryUniqueId &&
        primaryAddress?.mailingZip;
      let emailValid = !this.state.cooperatorData.emailAddress
        ? true
        : UtilityFunctions.validateEmail(this.state.cooperatorData.emailAddress);
      let phoneNumbersValid = this.state.cooperatorData.phoneNumbers.every((number) =>
        UtilityFunctions.validatePhoneNumber(number.phoneNumber)
      );
      const requiredFieldsValidated =
        this.state.cooperatorData.firstName !== "" &&
        this.state.cooperatorData.lastName !== "" &&
        phoneNumbersValid &&
        emailValid &&
        primaryAddressValid;
      if (requiredFieldsValidated) {
        // API saving commented out, Contact Edits made on this page should align with Save Action. Saving code for later reuse
        const updateContactBody = {
          contactUniqueId: this.state.cooperatorData.contactUniqueId,
          firstName: this.state.cooperatorData.firstName,
          middleName: this.state.cooperatorData.middleName,
          lastName: this.state.cooperatorData.lastName,
          preferredName: this.state.cooperatorData.preferredName,
          typeOfContactEnumId: parseInt(this.state.cooperatorData.typeOfContactEnumId),
          suffix: this.state.cooperatorData.suffix,
          emailAddress: this.state.cooperatorData.emailAddress,
          primaryMailingAddress: primaryAddress,
        };
        const updateContactCall = await AgreementEntitiesAPI.UpdateContact(updateContactBody);
        if (updateContactCall?.successful) {
          let currentContactObject = updateContactCall?.data;

          const apiOtherAddresses = this.getOtherAddresses(this.state.cooperatorData);
          let replaceAddressSuccess = true;
          if (apiOtherAddresses.length > 0) {
            const replaceAddressesCall = await AgreementEntitiesAPI.ReplaceOtherAddressesOnContact(
              currentContactObject.contactUniqueId,
              apiOtherAddresses
            );
            if (!replaceAddressesCall?.successful) {
              ErrorHandler.handleApiErrorMessage({
                errorContextMessage: "Unable to update contact address",
                apiName: "ReplaceOtherAddressesOnContact",
                responseUnsuccessful: replaceAddressesCall?.unsuccessful,
                responseMessage: replaceAddressesCall?.message,
              });
            }
            replaceAddressSuccess = replaceAddressesCall?.successful;
          }

          let replacePhonesSuccess = true;
          if (this.state.cooperatorData.phoneNumbers.length > 0) {
            const replacePhoneNumbersCall = await AgreementEntitiesAPI.ReplacePhoneNumbersOnContact(
              currentContactObject.contactUniqueId,
              this.state.cooperatorData.phoneNumbers.map((phone) => ({
                phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(phone.phoneNumber),
                phoneTypeEnumId: phone.phoneTypeEnumId ? parseInt(phone.phoneTypeEnumId) : phone.phoneType.id,
              }))
            );
            if (!replacePhoneNumbersCall?.successful) {
              ErrorHandler.handleApiErrorMessage({
                errorContextMessage: "Unable to update contact phone numbers",
                apiName: "ReplacePhoneNumbersOnContact",
                responseUnsuccessful: replacePhoneNumbersCall?.unsuccessful,
                responseMessage: replacePhoneNumbersCall?.message,
              });
            }
            replacePhonesSuccess = replacePhoneNumbersCall?.successful;
          }

          const refreshContactCall = await AgreementEntitiesAPI.GetContact(currentContactObject.contactUniqueId);
          currentContactObject = refreshContactCall?.data;
          if (replaceAddressSuccess && replacePhonesSuccess) {
            let contactToPassBack = currentContactObject;
            contactToPassBack.propertyUniqueIdentifier = this.state.cooperatorData.propertyUniqueIdentifier;
            contactToPassBack.fullName = `${contactToPassBack.firstName} ${contactToPassBack.middleName} ${contactToPassBack.lastName}`;
            contactToPassBack.primaryPhoneNumber = UtilityFunctions.formatPhoneNumber(
              this.state.cooperatorData.phoneNumbers[0].phoneNumber
            );
            contactToPassBack.fullMailingAddress = this.getPrimaryAddress(
              contactToPassBack.primaryMailingAddress,
              "contact"
            );
            contactToPassBack.contactRole = this.state.cooperatorData.contactRole;
            contactToPassBack.level = this.state.cooperatorData.level;
            this.closeEditContactModal(contactToPassBack);
            this.setState({ cooperatorData: contactToPassBack });
            isCooperatorEditSuccessful = true;
          } else {
            toast.error("Contact Edit failed.");
            isCooperatorEditSuccessful = false;
          }
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to update contact",
            apiName: "UpdateContact",
            responseUnsuccessful: updateContactCall?.unsuccessful,
            responseMessage: updateContactCall?.message,
          });
        }
      } else {
        toast.warning(
          "Please provide First Name, Last Name, Contact Type, at least one Phone Number (xxx-xxx-xxxx), and at least one Address. Emails must be in the correct format (e.g. user@usda.gov)."
        );
        isCooperatorEditSuccessful = false;
      }
    }

    return isCooperatorEditSuccessful;
  }

  getOtherAddresses(data) {
    if (data.otherAddresses.length === 0) {
      return [];
    }
    const otherAddresses = [...data.otherAddresses];
    const filteredMappedOtherAddresses = otherAddresses
      .filter(
        (address) =>
          (address.streetAddress !== "" &&
            address.city !== "" &&
            address.state !== "" &&
            address.zipCode !== "" &&
            address.country !== "") ||
          (address.mailingStreet !== "" &&
            address.mailingCity !== "" &&
            address.mailingStateUniqueId !== "" &&
            address.mailingZip !== "" &&
            address.mailingCountryUniqueId !== "")
      )
      .map((address) => ({
        mailingStreet: address.streetAddress || address.mailingStreet,
        mailingCity: address.city || address.mailingCity,
        mailingStateUniqueId: address.state || address.mailingStateUniqueId,
        mailingZip: address.zipCode || address.mailingZip,
        mailingCountryUniqueId: address.country || address.mailingCountryUniqueId,
        addressPurpose: "other",
      }));
    return filteredMappedOtherAddresses;
  }

  async updateAgreementDetails() {
    let isAgreementDetailsEditSuccessful = true;
    if (this.state.agreementDetailsEdited) {
      let result = true;
      const updateBody = {
        agreementTypeEnumId: parseInt(this.state.data.agreementType),
        commonName: this.state.data.commonName,
        agreementNumberSuffix: this.state.data.agreementNumber,
        primaryFieldAgentWsUserProfileUniqueReference:
          this.state.agreementData.primaryFieldAgent?.fieldAgentWsUserProfileUniqueReference,
        cooperatorContactUniqueReference: this.state.agreementData.cooperator?.contactUniqueReference,
        enforceDetailedWorkPerformedEnumId: 2,
        cooperatorsRoleOnAgreementEnumId: this.state.agreementData.cooperator?.cooperatorRole?.id,
        projectUniqueReferences: this.state.agreementProjects?.map((p) => p.value),
      };

      const updateAgreementCall = await AgreementAPI.UpdateAgreement(
        this.state.agreementData.agreementUniqueIdentifier,
        updateBody
      );
      result = updateAgreementCall?.successful;
      if (!updateAgreementCall?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update agreement",
          apiName: "UpdateAgreement",
          responseUnsuccessful: updateAgreementCall?.unsuccessful,
          responseMessage: updateAgreementCall?.message,
        });
      }
      isAgreementDetailsEditSuccessful = result;
    }

    return isAgreementDetailsEditSuccessful;
  }

  contructAddContactsAPIPostBody() {
    return this.state.associatedAgreementContactsData.map((contact) => {
      return {
        contactUniqueReference: contact.contactUniqueId,
        contactRoleOnAgreementEnumId: this.state.agreementRoles.find((r) => r.displayText === contact.contactRole).id,
      };
    });
  }

  async updateContactRoles() {
    let isContactRoleEditSuccessful = true;
    if (this.state.contactRolesEdited) {
      let agreementGuid = this.state.agreementData.agreementUniqueIdentifier;
      const removeContactsBody = this.state.initialAssociatedAgreementContactsData.map((contact) => {
        return { contactUniqueReference: contact.contactUniqueId };
      });
      const removeContactsFromAgreementCall = await AgreementAPI.RemoveContactsFromAgreement(
        agreementGuid,
        removeContactsBody
      );
      const addContactsBody = this.contructAddContactsAPIPostBody();
      const addContactsToAgreementCall = await AgreementAPI.AddContactsToAgreement(agreementGuid, addContactsBody);
      const agreementLevelSuccess =
        removeContactsFromAgreementCall?.successful && addContactsToAgreementCall?.successful;
      if (!removeContactsFromAgreementCall?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update agreement contacts",
          apiName: "RemoveContactsFromAgreement",
          responseUnsuccessful: removeContactsFromAgreementCall?.unsuccessful,
          responseMessage: removeContactsFromAgreementCall?.message,
        });
      }
      if (addContactsToAgreementCall?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update agreement contacts",
          apiName: "AddContactsToAgreement",
          responseUnsuccessful: addContactsToAgreementCall?.unsuccessful,
          responseMessage: addContactsToAgreementCall?.message,
        });
      }
      let propertyLevelRemoveSuccess = true;
      let propertyLevelAddSuccess = true;
      await Promise.all(
        this.state.initialAssociatedPropertyContactsData.map(async (contact) => {
          const removeBody = [
            {
              propertyUniqueReference: contact.propertyUniqueIdentifier,
              contactsToRemove: [
                {
                  contactUniqueReference: contact.contactUniqueId,
                },
              ],
            },
          ];
          const removeContactFromAgreementPropertiesCall = await AgreementAPI.RemoveContactsFromAgreementProperties(
            agreementGuid,
            removeBody
          );
          propertyLevelRemoveSuccess = removeContactFromAgreementPropertiesCall?.successful;
          if (!propertyLevelRemoveSuccess) {
            ErrorHandler.handleApiErrorMessage({
              errorContextMessage: "Unable to update agreement property contacts",
              apiName: "RemoveContactsFromAgreementProperties",
              responseUnsuccessful: removeContactFromAgreementPropertiesCall?.unsuccessful,
              responseMessage: removeContactFromAgreementPropertiesCall?.message,
            });
          }
        })
      );
      await Promise.all(
        this.state.associatedPropertyContactsData.map(async (contact) => {
          const addBody = [
            {
              contactUniqueReference: contact.contactUniqueId,
              contactRoleOnAgreementEnumId: this.state.agreementRoles.find((r) => r.displayText === contact.contactRole)
                .id,
              addToProperties: [
                {
                  propertyUniqueReference: contact.propertyUniqueIdentifier,
                },
              ],
            },
          ];
          const addContactToAgreementPropertiesCall = await AgreementAPI.AddContactsToAgreementProperties(
            agreementGuid,
            addBody
          );
          propertyLevelAddSuccess = addContactToAgreementPropertiesCall?.successful;
          if (!propertyLevelAddSuccess) {
            ErrorHandler.handleApiErrorMessage({
              errorContextMessage: "Unable to update agreement property contacts",
              apiName: "AddContactsToAgreementProperties",
              responseUnsuccessful: addContactToAgreementPropertiesCall?.unsuccessful,
              responseMessage: addContactToAgreementPropertiesCall?.message,
            });
          }
        })
      );

      isContactRoleEditSuccessful = agreementLevelSuccess && propertyLevelRemoveSuccess && propertyLevelAddSuccess;
    }

    return isContactRoleEditSuccessful;
  }

  async updatePageAgreementData() {
    const agreementUniqueId = this.state.agreementData?.agreementUniqueIdentifier;
    if (agreementUniqueId) {
      const agreementDataResponse = await AgreementAPI.GetAgreement(agreementUniqueId);
      if (agreementDataResponse?.successful) {
        let agreementData = agreementDataResponse.data;
        await this.updatePrimaryFieldAgentData(agreementData);
        const originalFieldAgents = agreementData.additionalFieldAgents;
        await this.updateAdditionalFieldAgentsData(agreementData);
        const propertiesData = await this.getPropertiesData(agreementData);
        const mappedResourcesData = this.mapAgreementResourcesData(agreementData, propertiesData);
        const speciesMapData = await this.getMappedSpeciesData(agreementData, propertiesData);
        const methodsMapData = await this.getMappedMethodsData(agreementData, propertiesData);
        const specialInstructionsData = this.getSpecialInstructionsData(agreementData);
        const associatedAgreementContacts = await this.getAgreementAssociatedContactsData(agreementData);
        const associatedPropertyContacts = await this.getPropertyAssociatedContactsData(agreementData);

        const pageData = {
          agreementData,
          propertiesData,
          selectedSpeciesTableData: [...speciesMapData],
          initialSpeciesData: JSON.parse(JSON.stringify(this.getSpeciesDataInAPIFormat(speciesMapData))),
          selectedMethodsTableData: [...methodsMapData],
          initialMethodsData: JSON.parse(JSON.stringify(this.getMethodsDataInAPIFormat(methodsMapData))),
          specialInstructionsData: [...specialInstructionsData],
          initialSpecialInstructionsData: JSON.parse(JSON.stringify(specialInstructionsData)),
          associatedAgreementContactsData: associatedAgreementContacts,
          associatedPropertyContactsData: associatedPropertyContacts,
          initialAssociatedAgreementContactsData: JSON.parse(JSON.stringify(associatedAgreementContacts)),
          initialAssociatedPropertyContactsData: JSON.parse(JSON.stringify(associatedPropertyContacts)),
          protectedResourcesData: [...mappedResourcesData.protectedResourcesData],
          initialProtectedResourcesData: JSON.parse(JSON.stringify(mappedResourcesData.protectedResourcesData)),
          historicalResourcesData: [...mappedResourcesData.historicalResourcesData],
          initialHistoricalResourcesData: JSON.parse(JSON.stringify(mappedResourcesData.historicalResourcesData)),
          data: this.getAgreementDataField(agreementData),
          originalFieldAgents,
        };

        this.setState(pageData);
      }
    }
  }

  updatePropertiesData = async () => {
    let isEditPropertySuccessful = true;
    if (this.state.propertiesEdited) {
      let success = false;
      await Promise.all(
        this.state.propertiesData.map(async (property) => {
          const updatePropertyBody = await this.getUpdatedPropertyBody(property);
          let addressUpdate = await this.updatePropertyAddresses(property);

          const updateAPIResult = await AgreementEntitiesAPI.UpdateProperty(
            property.propertyUniqueIdentifier,
            updatePropertyBody
          );
          success = addressUpdate && updateAPIResult.successful;
          if (!updateAPIResult.successful) {
            ErrorHandler.handleApiErrorMessage({
              errorContextMessage: "Unable to update property",
              apiName: "UpdateProperty",
              responseUnsuccessful: updateAPIResult?.unsuccessful,
              responseMessage: updateAPIResult?.message,
            });
          }
        })
      );
      isEditPropertySuccessful = success;
    }

    return isEditPropertySuccessful;
  };

  handleResourceChanges = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleResourceUomChange = (input) => {
    const { data } = this.state;
    data.protectedResourceUom = input;
    if (input.value === 6 && data.protectedResourceQuantity) {
      data.protectedResourceQuantity = Math.round(data.protectedResourceQuantity);
    }

    this.setState({ data });
    return input;
  };

  handleHistoricalResourceUomChange = (input) => {
    const { data } = this.state;
    data.historicalResourceUom = input;
    if (input.value === 6 && data.historicalResourceQuantity) {
      data.historicalResourceQuantity = Math.round(data.historicalResourceQuantity);
    }

    this.setState({ data });
    return input;
  };

  handleResourceSearch = async (inputValue) => {
    return await this.getResourcesFromAPI(inputValue);
  };

  async updatePropertyAddresses(property) {
    let addressUpdate = true;
    let additionalAddresses = [];
    if (property.additionalStreetAddresses) {
      for (var i = 0; i < property.additionalStreetAddresses.length; i++) {
        additionalAddresses.push({
          streetAddress: property.additionalStreetAddresses[i].streetAddress,
          city: property.additionalStreetAddresses[i].city,
          zipCode: property.additionalStreetAddresses[i].zipCode,
        });
      }

      const updateAddressAPIResult = await AgreementEntitiesAPI.ReplaceAdditionalAddressesOnProperty(
        property.propertyUniqueIdentifier,
        additionalAddresses
      );
      addressUpdate = updateAddressAPIResult.successful;
      if (!addressUpdate) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update addresses on property",
          apiName: "ReplaceAdditionalAddressesOnProperty",
          responseUnsuccessful: updateAddressAPIResult?.unsuccessful,
          responseMessage: updateAddressAPIResult?.message,
        });
      }
    }
    return addressUpdate;
  }

  async getUpdatedPropertyBody(property) {
    const mapPoint = await this.getPropertyMapPoint(property);
    const updatePropertyBody = {
      propertyName: property.propertyName,
      propertyAbbreviation: "",
      landClassEnumId: parseInt(property.landClass?.id),
      acres: parseInt(property.acres),
      district: property.district,
      county: property.county,
      state: property.state,
      latitude: JSON.parse(mapPoint)[0] ? parseFloat(JSON.parse(mapPoint)[0]) : null,
      longitude: JSON.parse(mapPoint)[1] ? parseFloat(JSON.parse(mapPoint)[1]) : null,
      defaultCooperatorUniqueIdentifier: property.defaultCooperatorUniqueIdentifier,
      countryUniqueIdentifier: property.countryUniqueIdentifier,
    };
    return updatePropertyBody;
  }

  async getPropertyMapPoint(property) {
    let mapPoint = "";
    let propertyAddress = "";
    if (property.additionalStreetAddresses && property.additionalStreetAddresses.length > 0) {
      let address = property.additionalStreetAddresses[0];
      propertyAddress = address.streetAddress.concat(", ", address.city, ", ", address.state, ", ", address.zipCode);
      if (property.mapPoint || property.longitude || property.latitude) {
        mapPoint = await GeoCoding.getLocationByAddress(propertyAddress);
      }
    }
    return mapPoint;
  }

  async updateAdditionalFieldAgentsData(agreementData) {
    agreementData.additionalFieldAgents = await Promise.all(
      agreementData.additionalFieldAgents.map(async (agent) => {
        return await this.getUserData(agent.fieldAgentWsUserProfileUniqueReference);
      })
    );
  }

  async updatePrimaryFieldAgentData(agreementData) {
    if (agreementData.primaryFieldAgent) {
      agreementData.primaryFieldAgent = await this.getUserData(
        agreementData.primaryFieldAgent.fieldAgentWsUserProfileUniqueReference
      );
    }
  }

  async updateSpeciesData() {
    let { speciesToBeRemovedFromProperties, speciesToBeAddedToProperties } = this.getMappededSpeciesData();

    let isRemoveSpeciesFromAgreementSuccessful = true;
    if (speciesToBeRemovedFromProperties?.length > 0) {
      isRemoveSpeciesFromAgreementSuccessful = await this.removeSpeciesFromAgreement(speciesToBeRemovedFromProperties);
    }

    let isAddSpeciesToAgreementSuccessful = true;
    if (speciesToBeAddedToProperties?.length > 0) {
      isAddSpeciesToAgreementSuccessful = await this.addSpeciesToAgreement(speciesToBeAddedToProperties);
    }

    return isAddSpeciesToAgreementSuccessful && isRemoveSpeciesFromAgreementSuccessful;
  }

  getMappededSpeciesData() {
    const { initialSpeciesData, selectedSpeciesTableData } = this.state;
    const selectedSpecies = JSON.parse(JSON.stringify(this.getSpeciesDataInAPIFormat(selectedSpeciesTableData)));
    let speciesToBeAdded = [];
    let speciesToBeDeleted = [];

    selectedSpecies.forEach((species) => {
      const initialSpeciesRecord = initialSpeciesData.find(
        (s) => s.speciesUniqueReference === species.speciesUniqueIdentifier
      );
      if (!initialSpeciesRecord) {
        speciesToBeAdded.push(species);
      } else {
        if (
          !UtilityFunctions.arraysEqualIgnoreOrder(
            initialSpeciesRecord.checkedProperties.map((p) => p.propertyUniqueIdentifier),
            species.checkedProperties.map((p) => p.propertyUniqueIdentifier)
          )
        ) {
          if (
            species.checkedProperties.some(
              (property) =>
                !initialSpeciesRecord.checkedProperties?.some(
                  (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
                )
            )
          ) {
            const speciesRecordToAdd = JSON.parse(JSON.stringify(species));
            speciesRecordToAdd.checkedProperties = speciesRecordToAdd.checkedProperties.filter((property) => {
              return !initialSpeciesRecord.checkedProperties.some(
                (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
              );
            });
            speciesToBeAdded.push(speciesRecordToAdd);
          }

          if (
            initialSpeciesRecord.checkedProperties.some((property) => {
              return !species.checkedProperties.some(
                (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
              );
            })
          ) {
            const speciesRecordToDelete = JSON.parse(JSON.stringify(species));
            speciesRecordToDelete.checkedProperties = initialSpeciesRecord.checkedProperties.filter((property) => {
              return !speciesRecordToDelete.checkedProperties.some(
                (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
              );
            });
            speciesToBeDeleted.push(speciesRecordToDelete);
          }
        }
      }
    });

    const fullyDeletedSpecies = initialSpeciesData.filter((initialSpecies) => {
      return !selectedSpecies.some(
        (species) => species.speciesUniqueIdentifier === initialSpecies.speciesUniqueReference
      );
    });
    if (fullyDeletedSpecies?.length > 0) {
      speciesToBeDeleted = [...speciesToBeDeleted, ...fullyDeletedSpecies];
    }

    let speciesToBeAddedToProperties = this.getMappedSpeciesToBeAddedToAgreement(speciesToBeAdded);
    let speciesToBeRemovedFromProperties = this.getMappedSpeciesToBeRemovedFromAgreement(speciesToBeDeleted);
    return { speciesToBeRemovedFromProperties, speciesToBeAddedToProperties };
  }

  async updateMethodsData() {
    let { methodsToBeRemovedFromProperties, methodsToBeAddedToProperties } = this.getMappededMethodsData();

    let isRemoveMethodsFromAgreementSuccessful = true;
    if (methodsToBeRemovedFromProperties?.length > 0) {
      isRemoveMethodsFromAgreementSuccessful = await this.removeMethodsFromAgreement(methodsToBeRemovedFromProperties);
    }

    let isAddMethodsToAgreementSuccessful = true;
    if (methodsToBeAddedToProperties?.length > 0) {
      isAddMethodsToAgreementSuccessful = await this.addMethodsToAgreement(methodsToBeAddedToProperties);
    }

    return isAddMethodsToAgreementSuccessful && isRemoveMethodsFromAgreementSuccessful;
  }

  getMappededMethodsData() {
    const { initialMethodsData, selectedMethodsTableData } = this.state;
    const selectedMethods = JSON.parse(JSON.stringify(this.getMethodsDataInAPIFormat(selectedMethodsTableData)));

    let methodsToBeAdded = [];
    let methodsToBeDeleted = [];

    selectedMethods.forEach((method) => {
      const initialMethodRecord = initialMethodsData.find(
        (m) => m.methodUniqueReference === method.methodUniqueReference
      );
      if (!initialMethodRecord) {
        methodsToBeAdded.push(method);
      } else {
        if (
          !UtilityFunctions.arraysEqualIgnoreOrder(
            initialMethodRecord.checkedProperties.map((p) => p.propertyUniqueIdentifier),
            method.checkedProperties.map((p) => p.propertyUniqueIdentifier)
          )
        ) {
          if (
            method.checkedProperties.some(
              (property) =>
                !initialMethodRecord.checkedProperties?.some(
                  (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
                )
            )
          ) {
            const methodRecordToAdd = JSON.parse(JSON.stringify(method));
            methodRecordToAdd.checkedProperties = methodRecordToAdd.checkedProperties.filter((property) => {
              return !initialMethodRecord.checkedProperties.some(
                (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
              );
            });
            methodsToBeAdded.push(methodRecordToAdd);
          }

          if (
            initialMethodRecord.checkedProperties.some((property) => {
              return !method.checkedProperties.some(
                (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
              );
            })
          ) {
            const methodRecordToDelete = JSON.parse(JSON.stringify(method));
            methodRecordToDelete.checkedProperties = initialMethodRecord.checkedProperties.filter((property) => {
              return !methodRecordToDelete.checkedProperties.some(
                (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
              );
            });
            methodsToBeDeleted.push(methodRecordToDelete);
          }
        }
      }
    });

    const fullyDeletedMethods = initialMethodsData.filter((initialMethod) => {
      return !selectedMethods.some((method) => method.methodUniqueIdentifier === initialMethod.methodUniqueReference);
    });
    if (fullyDeletedMethods?.length > 0) {
      methodsToBeDeleted = [...methodsToBeDeleted, ...fullyDeletedMethods];
    }

    let methodsToBeAddedToProperties = this.getMappedMethodsToBeAddedToAgreement(methodsToBeAdded);
    let methodsToBeRemovedFromProperties = this.getMappedMethodsToBeRemovedFromAgreement(methodsToBeDeleted);
    return { methodsToBeRemovedFromProperties, methodsToBeAddedToProperties };
  }

  async updateSpecialInstructionsData() {
    const isRemoveSpecialInstructionsSuccessful = await this.removeSpecialInstructionsFromProperties();
    const isAddSpecialInstructionsSuccessful = await this.addSpecialInstructionsToProperties();

    return isAddSpecialInstructionsSuccessful && isRemoveSpecialInstructionsSuccessful;
  }

  async updateResourcesData(isHistoricalData = false) {
    let initialData = [];
    let updatedData = [];

    if (isHistoricalData) {
      initialData = this.state.initialHistoricalResourcesData;
      updatedData = this.state.historicalResourcesData;
    } else {
      initialData = this.state.initialProtectedResourcesData;
      updatedData = this.state.protectedResourcesData;
    }

    let resourcesToBeAdded = [];
    let resourcesToBeDeleted = [];
    let resourcesToBeAddedToProperties = [];
    let resourcesToBeRemovedFromProperties = [];

    updatedData.forEach((resource) => {
      const initialResourceRecord = initialData.find(
        (r) =>
          r.resourceUniqueIdentifier === resource.resourceUniqueIdentifier &&
          resource.checkedProperties[0]?.propertyUniqueIdentifier ===
          r.checkedProperties[0]?.propertyUniqueIdentifier &&
          r.valuationUnitOfMeasure?.id === resource.valuationUnitOfMeasure?.id &&
          r.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure &&
          r.quantity === resource.quantity
      );
      if (!initialResourceRecord) {
        resourcesToBeAdded.push(resource);
      }
    });

    initialData.forEach((resource) => {
      const currentResourceRecord = updatedData.find(
        (r) =>
          r.resourceUniqueIdentifier === resource.resourceUniqueIdentifier &&
          resource.checkedProperties[0]?.propertyUniqueIdentifier ===
          r.checkedProperties[0]?.propertyUniqueIdentifier &&
          r.valuationUnitOfMeasure?.id === resource.valuationUnitOfMeasure?.id &&
          r.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure &&
          r.quantity === resource.quantity
      );
      if (!currentResourceRecord) {
        resourcesToBeDeleted.push(resource);
      }
    });

    const mappedResourcesToBeAddedToProperties = this.getMappedResourcesToBeAddedToProperties(resourcesToBeAdded);
    if (mappedResourcesToBeAddedToProperties?.length > 0) {
      resourcesToBeAddedToProperties = mappedResourcesToBeAddedToProperties;
    }

    const mappedResourcesToBeRemovedFromProperties =
      this.getMappedResourcesToBeRemovedFromProperties(resourcesToBeDeleted);
    if (mappedResourcesToBeRemovedFromProperties?.length > 0) {
      resourcesToBeRemovedFromProperties = mappedResourcesToBeRemovedFromProperties;
    }

    let updatedAgreementData = "";
    let isRemoveResourcesFromAgreementSuccessful = true;
    if (resourcesToBeRemovedFromProperties?.length > 0) {
      const { success: isRemoveResourcesSuccessful, removeResourcesResponseData } =
        await this.removeResourcesFromAgreement(resourcesToBeRemovedFromProperties, isHistoricalData);
      isRemoveResourcesFromAgreementSuccessful = isRemoveResourcesSuccessful;
      if (removeResourcesResponseData) {
        updatedAgreementData = removeResourcesResponseData;
      }
    }

    let isAddResourcessToAgreementSuccessful = true;
    if (resourcesToBeAddedToProperties?.length > 0) {
      const { success: isAddResourcesSuccessful, addResourcesResponseData } = await this.addResourcesToAgreement(
        resourcesToBeAddedToProperties,
        isHistoricalData
      );
      isAddResourcessToAgreementSuccessful = isAddResourcesSuccessful;
      if (addResourcesResponseData) {
        updatedAgreementData = addResourcesResponseData;
      }
    }

    return {
      isResourcesUpdateSuccessful: isAddResourcessToAgreementSuccessful && isRemoveResourcesFromAgreementSuccessful,
      updatedData: updatedAgreementData,
    };
  }

  async addHistoricalLossesToAgreement(updatedAgreementData) {
    const { agreementData } = this.state;
    let success = true;

    const historicalLosses = await this.getAddHistoricalLossesBody(updatedAgreementData);
    if (historicalLosses?.length > 0 && agreementData?.agreementUniqueIdentifier) {
      const lossesToResourcesResponse = await AgreementAPI.AddHistoricalLossesToAgreementProperties(
        agreementData.agreementUniqueIdentifier,
        historicalLosses
      );
      if (!lossesToResourcesResponse?.successful) {
        success = false;
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update historical losses on agreement property",
          apiName: "AddHistoricalLossesToAgreementProperties",
          responseUnsuccessful: lossesToResourcesResponse?.unsuccessful,
          responseMessage: lossesToResourcesResponse?.message,
        });
      }
    }

    return success;
  }

  async removeHistoricalLossesFromAgreement(updatedAgreementData) {
    const { agreementData } = this.state;
    let success = true;

    const historicalLossesToRemove = this.getHistoricalLossesToRemoveBody(updatedAgreementData);
    if (historicalLossesToRemove?.length > 0 && agreementData?.agreementUniqueIdentifier) {
      const removeLossesResponse = await AgreementAPI.RemoveHistoricalLossesFromAgreementProperties(
        agreementData.agreementUniqueIdentifier,
        historicalLossesToRemove
      );
      if (!removeLossesResponse?.successful) {
        success = false;
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update historical losses on agreement property",
          apiName: "RemoveHistoricalLossesFromAgreementProperties",
          responseUnsuccessful: removeLossesResponse?.unsuccessful,
          responseMessage: removeLossesResponse?.message,
        });
      }
    }

    return success;
  }

  async getAddHistoricalLossesBody(updatedAgreementData) {
    let historicalLosses = [];
    const { historicalResourcesData } = this.state;

    const agreementProperties =
      updatedAgreementData.agreementProperties?.length > 0 ? updatedAgreementData.agreementProperties : [];
    let agreementResources = [];
    agreementProperties.forEach((property) => {
      agreementResources = agreementResources.concat(property.agreementResources);
    });

    historicalResourcesData.forEach((resource) => {
      if (
        resource.historicalLosses?.length > 0 &&
        (resource.isNewResource || resource.historicalLosses.some((l) => l.isNewLoss))
      ) {
        let parcelsIdentifiers = [];
        if (resource?.checkedProperties[0]?.parcels?.length > 0) {
          let parcelAssign = resource?.checkedProperties[0]?.parcels.map((parcel) => {
            if (parcel?.parcelUniqueIdentifier) {
              parcelsIdentifiers.push({
                parcelUniqueReference: parcel.parcelUniqueIdentifier,
              });
            }
          });
        }

        const agreementResource = agreementResources.find(
          (r) =>
            r.resourceUniqueReference === resource.resourceUniqueIdentifier &&
            r.valuationYear === resource.valuationYear
        );
        if (agreementResource) {
          const historicalLossesToBeAdded = agreementResource.isNewResource
            ? resource.historicalLosses
            : resource.historicalLosses.filter((l) => l.isNewLoss);
          if (historicalLossesToBeAdded?.length > 0) {
            historicalLosses = historicalLosses.concat(
              historicalLossesToBeAdded.map((loss) => ({
                agreementResourceUniqueIdentifier: agreementResource.agreementResourceUniqueIdentifier,
                causingSpeciesUniqueReference: loss.species.value,
                lossAssociatedWithParcels: parcelsIdentifiers,
                lossQuantity: loss.quantity,
                lossPercentage: loss.percentage,
                lossTotalValuation: loss.totalValue,
              }))
            );
          }
        }
      }
    });
    return historicalLosses;
  }

  getHistoricalLossesToRemoveBody(updatedAgreementData) {
    let historicalLosses = [];
    const { historicalResourcesData } = this.state;

    if (updatedAgreementData?.agreementProperties?.length > 0) {
      let agreementResources = [];
      updatedAgreementData.agreementProperties.forEach((property) => {
        agreementResources = agreementResources.concat(
          property.agreementResources.filter((r) => r.isHistorical && r.historicalLossRecords?.length > 0)
        );
      });

      for (let resource of agreementResources) {
        resource.historicalLossRecords.forEach((exixtingLoss) => {
          if (
            !historicalResourcesData.some((r) =>
              r.historicalLosses.find(
                (l) => l.historicalLossUniqueIdentifier === exixtingLoss.historicalLossUniqueIdentifier
              )
            )
          ) {
            historicalLosses.push({
              historicalLossUniqueReference: exixtingLoss.historicalLossUniqueIdentifier,
            });
          }
        });
      }
    }
    return historicalLosses;
  }

  async addSpecialInstructionsToProperties() {
    let isAddSpecialInstructionsSuccessful = true;
    const specialInstructionsToBeAddedToProperties = this.getSpecialInstructionsToBeAddedToProperties();
    if (specialInstructionsToBeAddedToProperties?.length > 0) {
      const addSpecialInstructionsResponse = await AgreementAPI.AddSpecialInstructionsToAgreementProperties(
        this.state.agreementData?.agreementUniqueIdentifier,
        specialInstructionsToBeAddedToProperties
      );
      isAddSpecialInstructionsSuccessful = addSpecialInstructionsResponse.successful;
      if (!isAddSpecialInstructionsSuccessful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update special instructions on agreement property",
          apiName: "AddSpecialInstructionsToAgreementProperties",
          responseUnsuccessful: addSpecialInstructionsResponse?.unsuccessful,
          responseMessage: addSpecialInstructionsResponse?.message,
        });
      }
    }

    return isAddSpecialInstructionsSuccessful;
  }

  async removeSpecialInstructionsFromProperties() {
    let isRemoveSpecialInstructionsSuccessful = true;
    const specialInstructionsToBeRemovedFromProperties = this.getSpecialInstrictionsToBeRemovedFromProperties();
    if (specialInstructionsToBeRemovedFromProperties?.length > 0) {
      const removeSpecialInstructionsResponse = await AgreementAPI.RemoveSpecialInstructionsFromAgreementProperties(
        this.state.agreementData?.agreementUniqueIdentifier,
        specialInstructionsToBeRemovedFromProperties
      );
      isRemoveSpecialInstructionsSuccessful = removeSpecialInstructionsResponse.successful;
      if (!isRemoveSpecialInstructionsSuccessful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update special instructions on agreement property",
          apiName: "RemoveSpecialInstructionsFromAgreementProperties",
          responseUnsuccessful: removeSpecialInstructionsResponse?.unsuccessful,
          responseMessage: removeSpecialInstructionsResponse?.message,
        });
      }
    }

    return isRemoveSpecialInstructionsSuccessful;
  }

  getSpecialInstructionsToBeAddedToProperties() {
    const { initialSpecialInstructionsData, specialInstructionsData } = this.state;
    let specialInstructionsToBeAddedToProperties = [];

    specialInstructionsData.forEach((instruction) => {
      const existingInstructionRecords = initialSpecialInstructionsData.filter(
        (i) => i.specialInstruction.trim() === instruction.specialInstruction.trim()
      );
      if (existingInstructionRecords) {
        if (
          !existingInstructionRecords.some((r) => r.propertyUniqueIdentifier === instruction.propertyUniqueIdentifier)
        ) {
          const existingRecordToBeAdded = specialInstructionsToBeAddedToProperties.find(
            (i) => i.specialInstructions === instruction.specialInstructions
          );
          if (!existingRecordToBeAdded) {
            specialInstructionsToBeAddedToProperties.push({
              specialInstructions: instruction.specialInstruction,
              addToProperties: [{ propertyUniqueReference: instruction.propertyUniqueIdentifier }],
            });
          } else if (
            !existingRecordToBeAdded.addToProperties?.find(
              (r) => r.propertyUniqueReference === instruction.propertyUniqueIdentifier
            )
          ) {
            existingRecordToBeAdded.addToProperties.push({
              propertyUniqueReference: instruction.propertyUniqueIdentifier,
            });
          }
        }
      } else {
        specialInstructionsToBeAddedToProperties.push({
          specialInstructions: instruction.specialInstruction,
          addToProperties: [{ propertyUniqueReference: instruction.propertyUniqueIdentifier }],
        });
      }
    });

    return specialInstructionsToBeAddedToProperties;
  }

  getSpecialInstrictionsToBeRemovedFromProperties() {
    const { initialSpecialInstructionsData, specialInstructionsData } = this.state;
    let specialInstructionsToBeRemovedFromProperties = [];

    initialSpecialInstructionsData.forEach((instruction) => {
      if (
        !specialInstructionsData.find(
          (i) =>
            i.specialInstruction.trim() === instruction.specialInstruction.trim() &&
            i.propertyUniqueIdentifier === instruction.propertyUniqueIdentifier
        )
      ) {
        const existingInstructionsToDelete = specialInstructionsToBeRemovedFromProperties.find(
          (i) => i.propertyUniqueReference === instruction.propertyUniqueIdentifier
        );
        if (!existingInstructionsToDelete) {
          specialInstructionsToBeRemovedFromProperties.push({
            propertyUniqueReference: instruction.propertyUniqueIdentifier,
            specialInstructionUniqueIdentifiersToRemove: [
              {
                specialInstructionUniqueIdentifier: instruction.specialInstructionUniqueIdentifier,
              },
            ],
          });
        } else if (
          !existingInstructionsToDelete.specialInstructionUniqueIdentifiersToRemove.find(
            (x) => x.specialInstructionUniqueIdentifier === instruction.specialInstructionUniqueIdentifier
          )
        ) {
          existingInstructionsToDelete.specialInstructionUniqueIdentifiersToRemove.push({
            specialInstructionUniqueIdentifier: instruction.specialInstructionUniqueIdentifier,
          });
        }
      }
    });

    return specialInstructionsToBeRemovedFromProperties;
  }

  getMappedSpeciesToBeRemovedFromAgreement(speciesToBeFullyDeleted) {
    let speciesToBeRemovedFromProperties = [];

    speciesToBeFullyDeleted.forEach((species) => {
      species.checkedProperties.forEach((p) => {
        const existingPropertyRecodToDelete = speciesToBeRemovedFromProperties.find(
          (entry) => entry.propertyUniqueReference === p.propertyUniqueIdentifier
        );
        if (existingPropertyRecodToDelete) {
          if (
            !existingPropertyRecodToDelete.speciesToRemove?.find(
              (s) => s.speciesUniqueReference === species.speciesUniqueIdentifier
            )
          ) {
            existingPropertyRecodToDelete.speciesToRemove.push({
              speciesUniqueReference: species.speciesUniqueIdentifier,
            });
          }
        } else {
          speciesToBeRemovedFromProperties.push({
            propertyUniqueReference: p.propertyUniqueIdentifier,
            speciesToRemove: [
              {
                speciesUniqueReference: species.speciesUniqueIdentifier,
              },
            ],
          });
        }
      });
    });

    return speciesToBeRemovedFromProperties;
  }

  getMappedMethodsToBeRemovedFromAgreement(methodsToBeFullyDeleted) {
    let methodsToBeRemovedFromProperties = [];

    methodsToBeFullyDeleted.forEach((method) => {
      if (method.checkedProperties?.length > 0) {
        const existingMethodToBeDeletedFromProperties = methodsToBeRemovedFromProperties.find(
          (entry) => entry.methodUniqueReference === method.methodUniqueIdentifier
        );
        if (existingMethodToBeDeletedFromProperties) {
          existingMethodToBeDeletedFromProperties.removeFromProperties = [
            ...existingMethodToBeDeletedFromProperties.removeFromProperties,
            ...method.checkedProperties.map((property) => ({
              propertyUniqueReference: property.propertyUniqueIdentifier,
            })),
          ];
        } else {
          methodsToBeRemovedFromProperties.push({
            methodUniqueReference: method.methodUniqueIdentifier,
            removeFromProperties: method.checkedProperties.map((property) => ({
              propertyUniqueReference: property.propertyUniqueIdentifier,
            })),
          });
        }
      }
    });

    return methodsToBeRemovedFromProperties;
  }

  async addSpeciesToAgreement(speciesToBeAddedToProperties) {
    let success = false;
    const { agreementUniqueIdentifier } = this.state.agreementData;
    const speciesToAgreementPropertiesResponse = await AgreementAPI.AddTargetedSpeciesToAgreementProperties(
      agreementUniqueIdentifier,
      speciesToBeAddedToProperties
    );
    if (speciesToAgreementPropertiesResponse?.successful) {
      success = true;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Failed to add Targeted Damage Agents to Agreement Properties",
        apiName: "AddTargetedSpeciesToAgreementProperties",
        responseUnsuccessful: speciesToAgreementPropertiesResponse?.unsuccessful,
        responseMessage: speciesToAgreementPropertiesResponse?.message,
      });
    }

    return success;
  }

  async removeSpeciesFromAgreement(speciesToBeRemovedFromProperties) {
    let success = false;
    const { agreementUniqueIdentifier } = this.state.agreementData;
    const speciesFromAgreementPropertiesResponse = await AgreementAPI.RemoveTargetedSpeciesFromAgreementProperties(
      agreementUniqueIdentifier,
      speciesToBeRemovedFromProperties
    );
    if (speciesFromAgreementPropertiesResponse?.successful) {
      success = true;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Failed to remove Targeted Damage Agents from Agreement Properties",
        apiName: "RemoveTargetedSpeciesFromAgreementProperties",
        responseUnsuccessful: speciesFromAgreementPropertiesResponse?.unsuccessful,
        responseMessage: speciesFromAgreementPropertiesResponse?.message,
      });
    }

    return success;
  }

  async addMethodsToAgreement(methodsToBeAddedToProperties) {
    let success = false;
    const { agreementUniqueIdentifier } = this.state.agreementData;
    const methodsToAgreementPropertiesResponse = await AgreementAPI.AddAllowedMethodsToAgreementProperties(
      agreementUniqueIdentifier,
      methodsToBeAddedToProperties
    );
    if (methodsToAgreementPropertiesResponse?.successful) {
      success = true;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Failed to add Allowed Methods to Agreement Properties",
        apiName: "AddAllowedMethodsToAgreementProperties",
        responseUnsuccessful: methodsToAgreementPropertiesResponse?.unsuccessful,
        responseMessage: methodsToAgreementPropertiesResponse?.message,
      });
    }

    return success;
  }

  async removeMethodsFromAgreement(methodsToBeRemovedFromProperties) {
    let success = false;
    const { agreementUniqueIdentifier } = this.state.agreementData;
    const methodsFromAgreementPropertiesResponse = await AgreementAPI.RemoveAllowedMethodsFromAgreementProperties(
      agreementUniqueIdentifier,
      methodsToBeRemovedFromProperties
    );
    if (methodsFromAgreementPropertiesResponse?.successful) {
      success = true;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Failed to remove Allowed Methods from Agreement Properties",
        apiName: "RemoveAllowedMethodsFromAgreementProperties",
        responseUnsuccessful: methodsFromAgreementPropertiesResponse?.unsuccessful,
        responseMessage: methodsFromAgreementPropertiesResponse?.message,
      });
    }

    return success;
  }

  async addResourcesToAgreement(resourcesToBeAddedToProperties, isHistoricalData) {
    let success = false;
    let addResourcesResponseData = null;
    const { agreementUniqueIdentifier } = this.state.agreementData;
    const resourcesToAgreementPropertiesResponse = await AgreementAPI.AddResourcesToAgreementProperties(
      agreementUniqueIdentifier,
      resourcesToBeAddedToProperties
    );
    if (resourcesToAgreementPropertiesResponse?.successful) {
      success = true;
      addResourcesResponseData = resourcesToAgreementPropertiesResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: `Failed to add ${isHistoricalData ? "Historical" : "Protected"
          } Resources to Agreement Properties.`,
        apiName: "AddResourcesToAgreementProperties",
        responseUnsuccessful: resourcesToAgreementPropertiesResponse?.unsuccessful,
        responseMessage: resourcesToAgreementPropertiesResponse?.message,
      });
    }

    return { success, addResourcesResponseData };
  }

  async removeResourcesFromAgreement(resourcesToBeRemovedFromProperties, isHistoricalData) {
    let success = false;
    let removeResourcesResponseData = null;
    const resourcesFromAgreementPropertiesResponse = await AgreementAPI.RemoveResourcesFromAgreementProperties(
      resourcesToBeRemovedFromProperties
    );
    if (resourcesFromAgreementPropertiesResponse?.successful) {
      success = true;
      removeResourcesResponseData = resourcesFromAgreementPropertiesResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: `Failed to remove ${isHistoricalData ? "Historical" : "Protected"
          } Resources from Agreement Properties.`,
        apiName: "RemoveResourcesFromAgreementProperties",
        responseUnsuccessful: resourcesFromAgreementPropertiesResponse?.unsuccessful,
        responseMessage: resourcesFromAgreementPropertiesResponse?.message,
      });
    }

    return { success, removeResourcesResponseData };
  }

  getMappedSpeciesToBeAddedToAgreement(speciesData) {
    const speciesToBeAddedToProperties = [];

    speciesData.forEach((species) => {
      if (species.checkedProperties?.length > 0)
        speciesToBeAddedToProperties.push({
          speciesUniqueReference: species.speciesUniqueIdentifier,
          addToProperties: species.checkedProperties.map((p) => ({
            propertyUniqueReference: p.propertyUniqueIdentifier,
          })),
        });
    });

    return speciesToBeAddedToProperties;
  }

  getMappedMethodsToBeAddedToAgreement(methodsData) {
    const methodsToBeAddedToProperties = [];

    methodsData.forEach((method) => {
      if (method.checkedProperties?.length > 0)
        methodsToBeAddedToProperties.push({
          methodUniqueReference: method.methodUniqueIdentifier,
          addToProperties: method.checkedProperties.map((p) => ({
            propertyUniqueReference: p.propertyUniqueIdentifier,
          })),
        });
    });

    return methodsToBeAddedToProperties;
  }

  getMappedResourcesToBeAddedToProperties(resourcesData) {
    const resourcesToBeAddedToProperties = [];

    resourcesData.forEach((resource) => {
      if (resource.checkedProperties?.length > 0) {
        resourcesToBeAddedToProperties.push({
          resourceUniqueReference: resource.resourceUniqueIdentifier,
          unitOfMeasureEnumId: resource.valuationUnitOfMeasure?.id,
          currencyCodeEnumId: resource.valuationCurrencyCode?.id,
          valuationPerUnitOfMeasure: resource.valuePerUnitOfMeasure,
          quantity: resource.quantity,
          totalValuation: resource.totalValuation,
          valuationYear: resource.valuationYear,
          addToProperties: resource.checkedProperties.map((p) => ({
            propertyUniqueReference: p.propertyUniqueIdentifier,
          })),
        });
      }
    });

    return resourcesToBeAddedToProperties;
  }

  getMappedResourcesToBeRemovedFromProperties(resourcesData) {
    const resourcesToBeRemovedFromProperties = [];
    resourcesData.forEach((resource) => {
      resourcesToBeRemovedFromProperties.push({
        agreementResourceUniqueIdentifier: resource.agreementResourceUniqueIdentifier,
      });
    });

    return resourcesToBeRemovedFromProperties;
  }

  getSpeciesDataInAPIFormat(speciesDataArray) {
    const mappedSpeciesData = [];
    speciesDataArray.forEach((species) => {
      const selectedSpeciesRecord = mappedSpeciesData.find(
        (s) => s.speciesUniqueIdentifier === species.speciesUniqueIdentifier
      );
      if (selectedSpeciesRecord) {
        selectedSpeciesRecord.checkedProperties.push(...species.checkedProperties);
      } else {
        mappedSpeciesData.push(JSON.parse(JSON.stringify(species)));
      }
    });

    return mappedSpeciesData;
  }

  getMethodsDataInAPIFormat(methodsDataArray) {
    const mappedMethodsData = [];
    methodsDataArray.forEach((method) => {
      const selectedMethodRecord = mappedMethodsData.find(
        (m) => m.methodUniqueIdentifier === method.methodUniqueIdentifier
      );
      if (selectedMethodRecord) {
        selectedMethodRecord.checkedProperties.push(...method.checkedProperties);
      } else {
        mappedMethodsData.push(JSON.parse(JSON.stringify(method)));
      }
    });

    return mappedMethodsData;
  }

  getResourcessDataInAPIFormat(resourcessDataArray) {
    const mappedResourcessData = [];
    resourcessDataArray.forEach((resource) => {
      const selectedResourceRecord = mappedResourcessData.find(
        (r) => r.resourceUniqueIdentifier === resource.resourceUniqueIdentifier
      );
      if (selectedResourceRecord) {
        selectedResourceRecord.checkedProperties.push(...resource.checkedProperties);
      } else {
        mappedResourcessData.push(JSON.parse(JSON.stringify(resource)));
      }
    });

    return mappedResourcessData;
  }

  async getResourcesFromAPI(searchValue) {
    let searchResult = "";
    const { currentUsaState } = this.state;
    if (currentUsaState && currentUsaState.stateGuid) {
      const resourcesData = await ReferenceFileAPI.FilterResources(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );
      if (resourcesData.data.results) {
        let results = resourcesData.data.results;
        searchResult = results.map((result) => ({
          value: result.resourceUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return searchResult;
  }

  handleProtectedResourceSelection = async (input) => {
    return await this.handleResourceSelection(input);
  };

  handleHistoricalResourceSelection = async (input) => {
    return await this.handleResourceSelection(input, true);
  };

  async handleResourceSelection(input, isHistoricalResource = false) {
    const { data, currentUsaState } = this.state;

    if (isHistoricalResource) {
      data.historicalResourceSearch = input;
    } else {
      data.protectedResourceSearch = input;
    }

    const resourceId = input.value;
    if (currentUsaState?.stateGuid) {
      const resourceServerResponse = await ReferenceFileAPI.GetResourceForState(resourceId, currentUsaState.stateGuid);
      if (resourceServerResponse?.successful) {
        const resourceData = resourceServerResponse.data;
        data.historicalResourceStateValuationSetting = resourceData.stateValuationSetting;
        if (resourceData.stateResourceValuations?.length > 0) {
          if (isHistoricalResource) this.setHistoricalResourceValuation(resourceData, data);
          else this.setProtectedResourceValuation(resourceData, data);
        }
        if (resourceData.stateValuationUnitOfMeasure) {
          const { id, displayText } = resourceData.stateValuationUnitOfMeasure;
          if (isHistoricalResource) {
            data.historicalResourceUom = { value: id, label: displayText };
          } else {
            data.protectedResourceUom = { value: id, label: displayText };
          }
        }
      }
    }

    this.setState({ data });
    return input;
  }

  setHistoricalResourceValuation(resourceData, data) {
    const resourceValuation = resourceData.stateResourceValuations
      .filter((resource) => resource.valuationYear <= data.historicalResourceYear)
      .sort((a, b) => (a.valuationYear > b.valuationYear ? -1 : 1))[0];
    data.historicalResourceUnitValue = resourceValuation ? resourceValuation.valuePerUnitOfMeasure : "";
  }

  setProtectedResourceValuation(resourceData, data) {
    const resourceValuation = resourceData.stateResourceValuations.sort((a, b) =>
      a.valuationYear > b.valuationYear ? -1 : 1
    )[0];
    data.protectedResourceUnitValue = resourceValuation ? resourceValuation.valuePerUnitOfMeasure : "";
  }

  getFilterReferenceFilesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  showResourcePropertyAssociationModal = () => {
    const {
      protectedResourceSearch,
      protectedResourceQuantity,
      protectedResourceUom,
      protectedResourceUnitValue,
      protectedResourceTotalValue,
      protectedResourceYear,
    } = this.state.data;

    if (
      protectedResourceSearch &&
      protectedResourceQuantity &&
      protectedResourceUom &&
      (protectedResourceUnitValue || protectedResourceTotalValue) &&
      protectedResourceYear
    ) {
      const resourceData = {
        resourceUniqueReference: protectedResourceSearch.value,
        resourceName: protectedResourceSearch.label,
        quantity:
          protectedResourceUom.value === 6
            ? Math.round(Number.parseFloat(protectedResourceQuantity))
            : Number.parseFloat(protectedResourceQuantity),
        valuationUnitOfMeasure: {
          id: protectedResourceUom.value,
          displayText: protectedResourceUom.label,
          name: protectedResourceUom.label,
        },
        valuePerUnitOfMeasure: Math.round(
          Number.parseFloat(protectedResourceUnitValue || protectedResourceTotalValue / protectedResourceQuantity)
        ),
        totalValuation: Number.parseFloat(
          protectedResourceTotalValue || protectedResourceUnitValue * protectedResourceQuantity
        ),
        valuationYear: Number.parseInt(protectedResourceYear),
        checkedProperties: [],
      };

      let { resourcePropertyParcelModalData } = this.state;
      resourcePropertyParcelModalData.currentResource = resourceData;
      resourcePropertyParcelModalData.checkedProperties = [];
      resourcePropertyParcelModalData.currentAction = "add";
      resourcePropertyParcelModalData.resourceTableType = "protectedResource";

      this.setState({ resourcePropertyParcelModalData });
      this.handleResourcePropertyParcelAssociation([this.state.currentProperty]);
    } else toast.info("Please fill out all the fields for the new resource to be linked to the Agreement.");
  };

  showHistoricalResourcePropertyAssociationModal = () => {
    const {
      historicalResourceSearch,
      historicalResourceQuantity,
      historicalResourceUom,
      historicalResourceUnitValue,
      historicalResourceTotalValue,
      historicalResourceYear,
      historicalResourceStateValuationSetting,
    } = this.state.data;

    if (
      historicalResourceSearch &&
      historicalResourceQuantity &&
      historicalResourceUom &&
      (historicalResourceUnitValue ||
        historicalResourceTotalValue ||
        historicalResourceStateValuationSetting?.id !== 3) &&
      historicalResourceYear
    ) {
      const historicalResourceData = {
        resourceUniqueReference: historicalResourceSearch.value,
        resourceName: historicalResourceSearch.label,
        quantity:
          historicalResourceUom.value === 6
            ? Math.round(Number.parseFloat(historicalResourceQuantity))
            : Number.parseFloat(historicalResourceQuantity),
        unitOfMeasureId: historicalResourceUom.value,
        valuationUnitOfMeasure: {
          id: historicalResourceUom.value,
          displayText: historicalResourceUom.label,
          name: historicalResourceUom.label,
        },
        valuePerUnitOfMeasure: Math.round(
          Number.parseFloat(historicalResourceUnitValue || historicalResourceTotalValue / historicalResourceQuantity)
        ),
        totalValuation: Number.parseFloat(
          historicalResourceTotalValue || historicalResourceUnitValue * historicalResourceQuantity
        ),
        valuationYear: Number.parseInt(historicalResourceYear),
        checkedProperties: [],
        stateValuationSetting: historicalResourceStateValuationSetting,
      };

      let { resourcePropertyParcelModalData } = this.state;
      resourcePropertyParcelModalData.currentResource = historicalResourceData;
      resourcePropertyParcelModalData.checkedProperties = [];
      resourcePropertyParcelModalData.currentAction = "add";
      resourcePropertyParcelModalData.resourceTableType = "historicalResourceInventory";

      this.setState({ resourcePropertyParcelModalData });
      this.handleResourcePropertyParcelAssociation([this.state.currentProperty]);
    } else {
      toast.warning("Please fill out all the fields for the new resource to be linked to the Agreement.");
    }
  };

  handleResourcePropertyParcelAssociation = async (updatedCheckedProperties) => {
    let { resourcePropertyParcelModalData } = this.state;
    if (resourcePropertyParcelModalData.resourceTableType === "protectedResource")
      await this.handleProtectedResourcePropertyParcelAssociation(updatedCheckedProperties);
    else this.handleHistoricalResourcePropertyAssociation(updatedCheckedProperties);
  };

  async handleProtectedResourcePropertyParcelAssociation(updatedCheckedProperties) {
    let { protectedResourcesData, data, resourcePropertyParcelModalData } = this.state;
    let currentResource = JSON.parse(JSON.stringify(resourcePropertyParcelModalData.currentResource));
    currentResource.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));

    const updatedProperty = updatedCheckedProperties[0];
    const newTableId = `${currentResource.resourceUniqueReference}|${updatedProperty.propertyUniqueIdentifier}|${currentResource.valuePerUnitOfMeasure}`;
    const selectedResources = protectedResourcesData.filter(
      (resource) => resource.checkedProperties[0].propertyUniqueIdentifier === updatedProperty.propertyUniqueIdentifier
    );
    if (resourcePropertyParcelModalData.currentAction === "add") {
      if (selectedResources.some((resource) => resource.resourceTableId === newTableId)) {
        toast.info("The same valuation already exists for this resource with the specified property.");
        this.setState({ data: this.getClearedDataObject(data, "protectedResources") });
        return;
      }
    } else {
      protectedResourcesData = JSON.parse(
        JSON.stringify(
          protectedResourcesData.filter(
            (resource) =>
              resource.resourceUniqueIdentifier !== currentResource.resourceUniqueIdentifier ||
              resource.checkedProperties[0]?.propertyUniqueIdentifier !== updatedProperty?.propertyUniqueIdentifier
          )
        )
      );
    }

    currentResource.resourceTableId = newTableId;
    currentResource.isHistorical = false;
    currentResource.resourceUniqueIdentifier = currentResource.resourceUniqueReference;
    protectedResourcesData.push(currentResource);

    this.setState({
      protectedResourcesData,
      data: this.getClearedDataObject(data, "protectedResources"),
    });
  }

  handleHistoricalResourcePropertyAssociation(updatedCheckedProperties) {
    let { historicalResourcesData, data, resourcePropertyParcelModalData } = this.state;
    let currentResource = JSON.parse(JSON.stringify(resourcePropertyParcelModalData.currentResource));
    currentResource.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    currentResource.isNewResource = true;

    const updatedProperty = updatedCheckedProperties[0];
    const newTableId = `${currentResource.resourceUniqueReference}|${updatedProperty.propertyUniqueIdentifier}|${currentResource.valuationYear}|${currentResource.valuePerUnitOfMeasure}`;
    const selectedHistoricalResources = historicalResourcesData.filter(
      (resource) => resource.checkedProperties[0].propertyUniqueIdentifier === updatedProperty.propertyUniqueIdentifier
    );
    if (resourcePropertyParcelModalData.currentAction === "add") {
      if (selectedHistoricalResources.some((resource) => resource.resourceTableId === newTableId)) {
        toast.info(
          "The same valuation already exists for this resource in the specified property and the selected year."
        );
        this.setState({ data: this.getClearedDataObject(data, "historicalResourcesInventory") });
        return;
      }
    } else {
      historicalResourcesData = JSON.parse(
        JSON.stringify(historicalResourcesData.filter((resource) => resource.resourceTableId !== newTableId))
      );
    }

    currentResource.resourceTableId = newTableId;
    currentResource.isHistorical = true;
    currentResource.resourceUniqueIdentifier = currentResource.resourceUniqueReference;
    historicalResourcesData.push(currentResource);

    this.setState({
      historicalResourcesData,
      data: this.getClearedDataObject(data, "historicalResourcesInventory"),
    });
  }

  deleteProtectedResource = (row) => {
    const { protectedResourcesData, resourcesToBeAddedToAgreement } = this.state;

    const updatedResourcesData = protectedResourcesData.filter(
      (resource) => resource.resourceTableId !== row.resourceTableId
    );
    const updatedResourcesToBeAdded = resourcesToBeAddedToAgreement.filter(
      (resource) => resource.resourceTableId !== row.resourceTableId
    );

    this.setState({
      protectedResourcesData: updatedResourcesData,
      resourcesToBeAddedToAgreement: updatedResourcesToBeAdded,
    });
  };

  deleteHistoricalResource = (row) => {
    const { historicalResourcesData, resourcesToBeAddedToAgreement } = this.state;
    const updatedResourcesData = historicalResourcesData.filter(
      (resource) => resource.resourceTableId !== row.resourceTableId
    );
    const updatedResourcesToBeAdded = resourcesToBeAddedToAgreement.filter(
      (resource) => resource.resourceTableId !== row.resourceTableId
    );

    this.setState({
      historicalResourcesData: updatedResourcesData,
      resourcesToBeAddedToAgreement: updatedResourcesToBeAdded,
    });
  };

  closeHistoricalLossModal = () => {
    this.setState({ addHistoricalLossData: "", showAddHistoricalLossModal: false });
  };

  showAddProjectModal = () => {
    this.setState({ showAddProjectModal: true });
  };

  closeAddProjectModal = () => {
    this.setState({ showAddProjectModal: false });
  };

  handleHistoricalResourcesAddLoss = (row) => {
    const addHistoricalLossData = { ...row };
    addHistoricalLossData.unitOfMeasureId = row.valuationUnitOfMeasure?.id;
    addHistoricalLossData.unitOfMeasureName = UtilityFunctions.getDisplayTextFromFieldObject(
      row.valuationUnitOfMeasure
    );
    addHistoricalLossData.totalValue = row.totalValuation;
    addHistoricalLossData.unitValue = row.valuePerUnitOfMeasure;
    addHistoricalLossData.year = row.valuationYear;

    this.setState({ addHistoricalLossData, showAddHistoricalLossModal: true });
  };

  getClearedDataObject(data, entityType) {
    if (entityType === "protectedResources") {
      data.protectedResourceSearch = "";
      data.protectedResourceQuantity = "";
      data.protectedResourceUom = "";
      data.protectedResourceUnitValue = "";
      data.protectedResourceTotalValue = "";
      data.protectedResourceYear = new Date().getFullYear();
    } else if (entityType === "historicalResourcesInventory") {
      data.historicalResourceSearch = "";
      data.historicalResourceQuantity = "";
      data.historicalResourceUom = "";
      data.historicalResourceUnitValue = "";
      data.historicalResourceTotalValue = "";
      data.historicalResourceYear = "";
    }
    return data;
  }

  async updateHistoricalResourceRow(row, column) {
    const { historicalResourcesData } = this.state;
    const rowIndex = historicalResourcesData.indexOf(row);

    historicalResourcesData[rowIndex].valuePerUnitOfMeasure = await this.getUpdatedHistoricalResourceValuation(
      row,
      column
    );
    historicalResourcesData[rowIndex].quantity = Number.parseFloat(row.quantity);
    historicalResourcesData[rowIndex].totalValuation =
      column.dataField === "totalValuation" && row.totalValuation
        ? Number.parseFloat(row.totalValuation)
        : row.quantity * row.valuePerUnitOfMeasure;
    historicalResourcesData[rowIndex].valuationYear = Number.parseInt(row.valuationYear);

    this.setState({ historicalResourcesData });
  }

  async getUpdatedHistoricalResourceValuation(row, year) {
    let unitValue = Math.round(Number.parseFloat(row.valuePerUnitOfMeasure));

    const { currentUsaState } = this.state;
    if (currentUsaState?.stateGuid) {
      const resourceServerResponse = await ReferenceFileAPI.GetResourceForState(
        row.resourceUniqueReference,
        currentUsaState.stateGuid
      );

      if (resourceServerResponse?.successful) {
        const resourceData = resourceServerResponse.data;
        if (resourceData.stateResourceValuations?.length > 0) {
          const resourceValuation = resourceData.stateResourceValuations
            .filter((resource) => resource.valuationYear <= year)
            .sort((a, b) => (a.valuationYear > b.valuationYear ? -1 : 1))[0];
          if (resourceValuation?.valuePerUnitOfMeasure) {
            unitValue = resourceValuation.valuePerUnitOfMeasure;
          }
        }
      }
    }

    return unitValue;
  }

  handleSpecialInstructionsAssociation = () => {
    const { specialInstructionsData, specialInstructionsToAddToAgreement, data } = this.state;

    if (data.specialInstructions.trim()) {
      const newspecialInstruction = {
        propertyUniqueIdentifier: this.state.currentProperty?.propertyUniqueIdentifier,
        specialInstruction: data.specialInstructions,
      };
      specialInstructionsData.push(newspecialInstruction);
      specialInstructionsToAddToAgreement.push(newspecialInstruction);
      data.specialInstructions = "";

      this.setState({ specialInstructionsData, specialInstructionsToAddToAgreement, data });
    } else {
      toast.info("Please enter a valid special instruction to be associated with the property.");
    }
  };

  editSpecialInstruction = ({ currentTarget: input }, index) => {
    const { specialInstructionsData, specialInstructionsToAddToAgreement } = this.state;
    const specialInstruction = specialInstructionsData.filter(
      (i) => i.propertyUniqueIdentifier === this.state.currentProperty?.propertyUniqueIdentifier
    )[index];

    const instructionsDataIndex = specialInstructionsData.indexOf(specialInstruction);
    if (instructionsDataIndex !== -1) {
      specialInstructionsData[instructionsDataIndex].specialInstruction = input.value;
    }
    const instructionsToAddIndex = specialInstructionsToAddToAgreement.indexOf(specialInstruction);
    if (instructionsToAddIndex !== -1) {
      specialInstructionsToAddToAgreement[instructionsToAddIndex].specialInstruction = input.value;
    }

    this.setState({ specialInstructionsData, specialInstructionsToAddToAgreement });
  };

  deleteSpecialInstruction = (index) => {
    const { specialInstructionsData, specialInstructionsToAddToAgreement } = this.state;
    const specialInstruction = specialInstructionsData.filter(
      (i) => i.propertyUniqueIdentifier === this.state.currentProperty?.propertyUniqueIdentifier
    )[index];

    this.setState({
      specialInstructionsData: specialInstructionsData.filter((i) => i !== specialInstruction),
      specialInstructionsToAddToAgreement: specialInstructionsToAddToAgreement.filter((i) => i !== specialInstruction),
    });
  };

  handleEditContact = (contact, level) => {
    contact.propertyUniqueIdentifier = this.state.currentProperty.propertyUniqueIdentifier;
    contact.level = level;
    this.setState({ currentEditContact: contact, showEditContactModal: true });
  };

  deleteContact = (contact, level) => {
    const { associatedPropertyContactsData, contactsToBeAddedToAgreement } = this.state;
    const associatedAgreementContactsData =
      level === "Agreement"
        ? this.state.associatedAgreementContactsData.filter((c) => c.contactUniqueId !== contact.contactUniqueId)
        : this.state.associatedAgreementContactsData;
    this.setState({
      associatedPropertyContactsData: associatedPropertyContactsData.filter((c) => c !== contact),
      contactsToBeAddedToAgreement: contactsToBeAddedToAgreement.filter((c) => c !== contact),
      associatedAgreementContactsData,
      contactRolesEdited: true,
    });
  };

  closeEditContactModal = async (newContactObject) => {
    if (newContactObject) {
      let associatedAgreementContactsData = this.state.associatedAgreementContactsData;
      let associatedPropertyContactsData = this.state.associatedPropertyContactsData;
      if (newContactObject.level === "Agreement") {
        associatedAgreementContactsData.splice(
          associatedAgreementContactsData.findIndex(
            (contact) => contact.contactUniqueId === newContactObject.contactUniqueId
          ),
          1,
          newContactObject
        );
      } else if (newContactObject.level === "Property") {
        associatedPropertyContactsData.splice(
          associatedPropertyContactsData.findIndex(
            (contact) => contact.contactUniqueId === newContactObject.contactUniqueId
          ),
          1,
          newContactObject
        );
      }
      this.setState({
        showEditContactModal: false,
        currentEditContact: null,
        associatedAgreementContactsData,
        associatedPropertyContactsData,
      });
    } else {
      this.setState({ showEditContactModal: false });
    }
  };

  handleProjectNavigation = async (project) => {
    this.props.history.push({
      pathname: "/project/details",
      state: {
        entityData: { projectUniqueIdentifier: project.value },
        states: this.state.states,
        countries: this.state.countries,
      },
    });
  };

  handleSpecificChanges(input) {
    if (["agreementType", "commonName"].includes(input.name)) {
      this.setState({ agreementDetailsEdited: true });
    }
    if (input.name === "phoneNumber") {
      let { data } = this.state;
      data.phoneNumber = UtilityFunctions.formatPhoneNumber(input.value);
      this.setState({ data });
    }
  }

  getAgreementBeacon() {
    let beaconText = "";
    let beaconClass = null;
    let beacon = null;

    let agreementStatusId = this.state.agreementData?.agreementStatus?.id;
    if (agreementStatusId === AgreementsStatuses.Active) {
      beaconText = "Active Agreement";
      beaconClass = styles.activeAgreementBeacon;
    } else if (agreementStatusId === AgreementsStatuses.Pending) {
      beaconText = "Pending Agreement";
      beaconClass = styles.pendingAgreementBeacon;
    } else if (agreementStatusId === AgreementsStatuses.Draft) {
      beaconText = "Draft Agreement";
      beaconClass = styles.draftAgreementBeacon;
    } else if (agreementStatusId === AgreementsStatuses.Expired) {
      beaconText = "Expired Agreement";
      beaconClass = styles.draftAgreementBeacon;
    }

    if (beaconClass) {
      beacon = (
        <p className={beaconClass}>
          <i className="fa fa-circle" style={{ paddingTop: "1.7%", paddingRight: "1%" }} aria-hidden="true"></i>
          {beaconText}
        </p>
      );
    }

    return beacon;
  }

  getPageHeader() {
    let pageHeader = "";
    const { data } = this.state;

    if (Number.parseInt(data.agreementType) === 2) {
      pageHeader = "Federal Land Agreement Record Page";
    } else pageHeader = "Agreement Record Page";

    return pageHeader;
  }

  removeProject = (index) => {
    let agreementProjects = this.state.agreementProjects;
    agreementProjects.splice(index, 1);
    this.setState({ agreementProjects: agreementProjects, agreementDetailsEdited: true });
  };

  renderProjectInfoCard() {
    let projectCard = (
      <Card className={styles.viewPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col>
              <span className={styles.cardTitleText}>Project Information</span>
            </Col>
            <Col className="text-right" hidden={!this.state.isAgreementEditable}>
              <Button variant="link" onClick={() => this.showAddProjectModal()} className="text-right">
                <span className={styles.actionButtonsText}>+ Add Project</span>
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className={styles.centerCardBody}>
          {this.state.agreementProjects?.map((project, index) => {
            return (
              <div key={index}>
                <Row>
                  <Col align="left" lg={2}>
                    <Button
                      variant="link"
                      className="pl-0"
                      title={project.label}
                      onClick={() => this.handleProjectNavigation(project)}
                    >
                      <span className={globalStyles.formDataLinks}>{project.label}</span>
                    </Button>
                  </Col>
                  <Col align="right" hidden={!this.state.isAgreementEditable}>
                    <Button
                      variant="link"
                      style={{ cursor: "pointer" }}
                      className={styles.fieldAgentCardText}
                      onClick={() => this.removeProject(index)}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Card.Body>
      </Card>
    );
    return projectCard;
  }

  projectsComponent = () => {
    let returnArray = [];
    if (this.state.agreementProjects?.length > 0) {
      let projects = this.state.agreementProjects;
      for (let i = 0; i < projects.length; i++) {
        returnArray.push(
          <Row className={styles.informationRow} noGutters="true">
            <Col align="left" lg={2}>
              <p className={globalStyles.informationText}>{projects[i].label}</p>
            </Col>
            <Col align="right" lg={7} hidden={!this.state.isAgreementEditable}>
              <Button
                variant="link"
                style={{ cursor: "pointer" }}
                className={styles.removeButtonLinks}
                onClick={() => {
                  projects.splice(i, 1);
                  this.setState({ agreementProjects: projects, agreementDetailsEdited: true });
                }}
              >
                Remove
              </Button>
            </Col>
          </Row>
        );
      }
    }
    return returnArray;
  };

  getHowLongSigned() {
    if (!this.state.agreementData?.agreementSignatureDate) {
      return;
    }

    let d1 = new Date(this.state.agreementData?.agreementSignatureDate);
    let d2 = new Date();

    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    if (months >= 12) {
      return `${Math.trunc(months / 12)} years, ${months % 12} months`;
    } else {
      return `${months} months`;
    }
  }

  renderHeaderButtoms() {
    let agreementStatusBeacon = this.getAgreementBeacon();

    return (
      <div className={styles.pageHeader}>
        <Row className="mx-0">
          <Col className="pl-0" md={5} align="left">
            <span>
              <h1 className={globalStyles.pageTitle} style={{ display: "inline" }}>
                {this.getPageHeader()}
              </h1>
              {agreementStatusBeacon}
            </span>
          </Col>
          <Col className="px-0">
            <div className="form-inline justify-content-end">
              {this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Pending && (
                <div>
                  <Button
                    variant="primary"
                    onClick={this.showAgreementFlagModal}
                    className="mr-2"
                    hidden={!this.state.flagHistory}
                  >
                    Flag History
                  </Button>
                </div>
              )}
              {this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Pending &&
                this.props.permissions.canIApproveAgreementsForAssignedStates() && (
                  <div>
                    {this.state.agreementData?.agreementRenewalStatus?.id ===
                      AgreementsRenewalStatuses.RenewalAgreement && (
                        <Button variant="primary" onClick={this.showAgreementAmendmentHistory} className="mr-2">
                          View Amendment
                        </Button>
                      )}
                    <Button
                      variant="primary"
                      onClick={this.showAgreementFlagModal}
                      className="mr-2"
                      hidden={this.state.flagHistory}
                    >
                      Flag
                    </Button>
                    <Button variant="primary" onClick={this.handleAgreementApproval} className="mr-2">
                      Approve Agreement
                    </Button>
                    <Button variant="primary" onClick={this.showAgreementRejectionModal} className="mr-2">
                      Reject
                    </Button>
                  </div>
                )}
              {this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Active && (
                <Button variant="primary" onClick={this.showAmendAgreementConfirmationModal} className="mr-2">
                  <span className={globalStyles.modalSubmitButtonText}>Amend/Renew</span>
                </Button>
              )}
              {parseInt(this.state.data.agreementType) === 2 &&
                this.props.permissions.CanIExtendFLAForAssignedstates() &&
                this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Active && (
                  <Button variant="primary" onClick={this.extendFLA} className="mr-2">
                    <span className={globalStyles.modalSubmitButtonText}>Extend</span>
                  </Button>
                )}
              <Button variant="primary" onClick={this.viewPrintAgreementModal}>
                <span className={globalStyles.modalSubmitButtonText}>Print</span>
              </Button>
              {this.props.permissions.canIEditAgreements() && (
                <Button
                  variant={
                    this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Pending &&
                      !this.props.permissions.canIApproveAgreementsForAssignedStates()
                      ? "secondary"
                      : "primary"
                  }
                  onClick={this.editAgreement}
                  className="ml-2"
                >
                  <span className={globalStyles.modalSubmitButtonText}>
                    {this.state.isAgreementEditable ? "Apply Changes" : "Edit"}
                  </span>
                </Button>
              )}
              {this.isAgreementApprovalEditable() && this.props.permissions.canIEditAgreements() && (
                <div>
                  {this.isUserThePrimaryFieldAgent() && (
                    <Button variant="primary" onClick={this.handleApprovalProcess} className="ml-2">
                      <span className={globalStyles.modalSubmitButtonText}>
                        {this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Draft
                          ? "Submit for Approval"
                          : "Recall"}
                      </span>
                    </Button>
                  )}
                  {this.state.agreementData?.agreementStatus?.id === AgreementsStatuses.Draft && (
                    <Button variant="primary" onClick={this.showDeleteAgreementModal} className="ml-2">
                      <span className={globalStyles.modalSubmitButtonText}>Delete Draft</span>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  isUserThePrimaryFieldAgent() {
    const { agreementData, wsUserProfileUniqueIdentifier } = this.state;
    return (
      agreementData?.primaryFieldAgent?.fieldAgentWsUserProfileUniqueReference &&
      agreementData.primaryFieldAgent.fieldAgentWsUserProfileUniqueReference === wsUserProfileUniqueIdentifier
    );
  }

  handleCooperatorSearch = async (searchValue) => {
    const contactsData = await AgreementEntitiesAPI.SearchContactFreeText({
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    });
    let cooperators = [];
    if (contactsData?.data?.results) {
      let results = contactsData.data.results;
      cooperators = results.map((result) => ({
        value: result.contactUniqueId,
        label: result.firstName.concat(" ", result.middleName, " ", result.lastName),
      }));
    }
    return cooperators;
  };

  handleCooperatorChange = (input, index) => {
    let propertiesData = this.state.propertiesData;
    let property = propertiesData[index];
    property.defaultCooperatorUniqueIdentifier = input.value;
    property.cooperatorName = {
      contactUniqueReference: input.value,
      firstname: input.label.split(" ")[0],
      middlename: input.label.split(" ")[1],
      lastName: input.label.split(" ")[2],
      knownAs: "",
      suffix: "",
    };
    this.setState({ propertiesData, propertiesEdited: true });
  };

  editPropertyData(e, index) {
    let input = e.currentTarget;
    let propertiesData = this.state.propertiesData;
    propertiesData[index][input.name] = input.value;
    this.setState({ propertiesData, propertiesEdited: true });
  }

  editLatLong(e, index) {
    let input = e.currentTarget;
    let propertiesData = this.state.propertiesData;
    let property = propertiesData[index];
    let newMapPoint = property.mapPoint ? JSON.parse(property.mapPoint) : [];
    if (input.name === "longitude") {
      newMapPoint[0] = parseFloat(input.value);
    } else if (input.name === "latitude") {
      newMapPoint[1] = parseFloat(input.value);
    }
    property.mapPoint = JSON.stringify(newMapPoint);
    this.setState({ propertiesData, propertiesEdited: true });
  }

  sendToPropertyRecordPage = async () => {
    const property = await AgreementEntitiesAPI.GetProperty(this.state.currentProperty.propertyUniqueIdentifier);
    window.scrollTo(0, 0);
    this.props.history.push({
      pathname: "/property/details",
      state: { entityData: property.data, states: this.state.states, countries: this.state.countries },
    });
  };

  getEditPropertyDetailsTab(agreementProperty, index) {
    let propertyData = this.state.propertiesData.find(
      (p) => p.propertyUniqueIdentifier === agreementProperty.propertyUniqueReference
    );
    if (!propertyData) {
      return;
    }

    if (propertyData.additionalStreetAddresses?.length === 0) {
      propertyData.additionalStreetAddresses.push({
        streetAddress: "",
        city: "",
        zipCode: "",
        state: propertyData.state,
        country: propertyData.countryUniqueIdentifier,
      });
    }
    propertyData.additionalStreetAddresses = propertyData.additionalStreetAddresses?.map((address) => {
      address.state = propertyData.state;
      address.country = propertyData.countryUniqueIdentifier;
      return address;
    });
    let statesForAddressComponent = this.state.states.map((state) => {
      return { id: state.stateUniqueIdentifier, name: state.state_code };
    });
    let propertyCooperator = null;
    if (propertyData.defaultCooperatorUniqueIdentifier && propertyData.cooperatorName) {
      propertyCooperator = {
        label: propertyData.cooperatorName.firstname.concat(
          " ",
          propertyData.cooperatorName.middlename,
          " ",
          propertyData.cooperatorName.lastName
        ),
        value: propertyData.defaultCooperatorUniqueIdentifier,
      };
    }

    return (
      <Tab
        key={agreementProperty.propertyUniqueReference}
        eventKey={agreementProperty.propertyUniqueReference}
        title={propertyData.propertyName}
        className={styles.editAgreementPropertyTab}
      >
        <Row className={styles.pageRow}>
          <Button variant="link" className={styles.propertyRecordLink} onClick={this.sendToPropertyRecordPage}>
            <Image src={linkImage} thumbnail className={styles.externalLinkImage} alt="Property Record Link" />
            View Property Record Page
          </Button>
        </Row>

        <Row className={styles.pageRow}>
          <Col lg={6}>
            <Row className={styles.editAgreementLabel}>Property Name</Row>
            <Row className={styles.propertyEditField}>
              <Form.Control
                name={"propertyName"}
                value={propertyData.propertyName}
                placeholder="Enter Name"
                onChange={(e) => this.editPropertyData(e, index)}
                className={styles.editAgreementEditPropertyField}
              />
            </Row>
          </Col>
          <Col lg={6} style={{ padding: "0px" }}>
            <Row className={styles.editPropertyCooperatorSearchLabel}>Primary Cooperator</Row>
            <AsyncSelect
              value={propertyCooperator}
              openMenuOnClick={false}
              placeholder="Search for Cooperator by Name"
              loadOptions={this.handleCooperatorSearch}
              onChange={(e) => this.handleCooperatorChange(e, index)}
              className={styles.editPropertyCooperatorSearch}
            />
          </Col>
        </Row>
        <Row className={styles.pageRow}>
          <Col lg={6}>
            <Row className={styles.editAgreementLabel}>Area in Acres</Row>
            <Row className={styles.propertyEditField}>
              <Form.Control
                name="acres"
                value={propertyData.acres}
                placeholder="Enter Acres"
                onChange={(e) => this.editPropertyData(e, index)}
                className={styles.editAgreementEditPropertyField}
              />
            </Row>
          </Col>
          <Col lg={6}>
            <Row className={styles.editAgreementLabel}>Area in Acres (Calculated)</Row>
            <Row className={styles.propertyEditField}>
              <Form.Control
                name="calculatedAcres"
                value={propertyData.acres}
                disabled
                className={styles.editAgreementEditPropertyField}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={styles.propertyEditAddressContainer}>
            <Addresses
              addresses={propertyData.additionalStreetAddresses}
              countries={this.state.countries}
              states={statesForAddressComponent}
              onAddressChange={(e) => this.handleAddressChanges(e, index)}
              onExtraAddress={(e) => this.handleExtraAddress(e, index)}
              dataTestid="agreementDetailsPropertyEditAddresses"
            />
          </Col>
        </Row>
        <Row className={styles.pageRow}>
          <Col lg={6}>
            <Row className={styles.editAgreementLabel}>County</Row>
            <Row className={styles.propertyEditField}>
              <Form.Control
                name="county"
                value={propertyData.county}
                placeholder="Select County"
                as="select"
                onChange={(e) => this.editPropertyData(e, index)}
                className={styles.editAgreementEditPropertyField}
              >
                <option></option>
                {this.state.counties?.map((county) => (
                  <option value={county.county_name} key={county.countyUniqueIdentifier}>
                    {county.county_name}
                  </option>
                ))}
              </Form.Control>
            </Row>
          </Col>
          {/* <Col lg={6}>
            <Row className={styles.editAgreementLabel}>District</Row>
            <Row className={styles.propertyEditField}>
              <Form.Control
                name="district"
                value={propertyData.district}
                placeholder="Select District"
                onChange={(e) => this.editPropertyData(e, index)}
                className={styles.editAgreementEditPropertyField}
              />
            </Row>
          </Col> */}
          <Col></Col>
        </Row>
        <Row className={styles.pageRow}>
          <Col lg={6}>
            <Row className={styles.editAgreementLabel}>Land Class</Row>
            <Row className={styles.propertyEditField}>
              <Form.Control
                name="landClass"
                value={propertyData.landClass.id}
                placeholder="Select Type"
                as="select"
                onChange={(e) => this.editPropertyData(e, index)}
                className={styles.editAgreementEditPropertyField}
              >
                <option></option>
                {this.state.landClasses?.map((landClass) => (
                  <option value={landClass.id} key={landClass.displayText}>
                    {landClass.displayText}
                  </option>
                ))}
              </Form.Control>
            </Row>
          </Col>
          <Col>
            <Row className={styles.editAgreementLabel}>Map Point (Coordinates)</Row>
            <Row className={styles.propertyEditField}>
              <Col style={{ paddingLeft: "0px", paddingRight: "30px" }}>
                <Form.Control
                  type="text"
                  placeholder="Longitude"
                  name="longitude"
                  value={
                    propertyData.mapPoint && JSON.parse(propertyData.mapPoint)?.length > 0
                      ? JSON.parse(propertyData.mapPoint)[0]
                      : ""
                  }
                  onChange={(e) => this.editLatLong(e, index)}
                  className={styles.editAgreementEditPropertyField}
                />
              </Col>
              <Col style={{ padding: "0px" }}>
                <Form.Control
                  type="text"
                  placeholder="Latitude"
                  name="latitude"
                  value={
                    propertyData.mapPoint && JSON.parse(propertyData.mapPoint)?.length > 1
                      ? JSON.parse(propertyData.mapPoint)[1]
                      : ""
                  }
                  onChange={(e) => this.editLatLong(e, index)}
                  className={styles.editAgreementEditPropertyField}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Tab>
    );
  }

  handleAddressChanges = async ({ currentTarget: input }, propertyIndex) => {
    const controlId = input.id.split("-");
    let fieldName = controlId[0];
    if (fieldName === "street") {
      fieldName = "streetAddress";
    }
    const index = controlId[1];
    let propertiesData = this.state.propertiesData;
    propertiesData[propertyIndex].additionalStreetAddresses[index][fieldName] = input.value;
    this.setState({ propertiesData, propertiesEdited: true });
  };

  handleExtraAddress = ({ currentTarget: input }, propertyIndex) => {
    let propertiesData = this.state.propertiesData;
    let addresses = propertiesData[propertyIndex]?.additionalStreetAddresses;
    if (!addresses) {
      return;
    }

    if (input.value === "add") {
      if (addresses[addresses.length - 1].state)
        addresses.push({
          street: "",
          city: "",
          state: addresses[0].state,
          country: addresses[0].country,
          zipCode: "",
        });
      else {
        toast.warning("Please fill out all the required fields on previous address record.");
      }
    } else {
      addresses.splice(input.value, 1);
    }

    this.setState({ propertiesData });
  };

  handlePropertySelection = (e) => {
    const currentProperty = this.state.propertiesData?.find((property) => property.propertyUniqueIdentifier === e);
    this.setState({ currentProperty });
  };

  showParcelMap = (propertyData, addressString) => {
    if (sessionStorage.getItem("mapAccess") === "Yes") {
      return (
        <ParcelMapView
          parcelDetails={propertyData.checkedParcels}
          address={addressString}
          name={propertyData.propertyName}
          location={JSON.stringify([propertyData.latitude?.toString(), propertyData.longitude?.toString()])}
          agreementData={this.state.agreementData}
        />
      );
    } else {
      return (
        <WebMap
          address={addressString}
          name={propertyData.propertyName}
          location={JSON.stringify([propertyData.latitude?.toString(), propertyData.longitude?.toString()])}
          agreementData={this.state.agreementData}
          isAddPropertyAvailable={false}
          onAddProperty={""}
        />
      );
    }
  };

  getPropertyTab(agreementProperty, index) {
    const { propertiesData } = this.state;
    const propertyData = propertiesData.find(
      (p) => p.propertyUniqueIdentifier === agreementProperty.propertyUniqueReference
    );
    if (!propertyData) {
      return "";
    }

    const addressString = propertyData.additionalStreetAddresses[0]?.streetAddress.concat(
      ", ",
      propertyData.additionalStreetAddresses[0].city,
      ", ",
      this.getStateNameFromId(propertyData.state),
      ", ",
      propertyData.additionalStreetAddresses[0].zipCode
    );

    return (
      <Tab
        key={agreementProperty.propertyUniqueReference}
        eventKey={agreementProperty.propertyUniqueReference}
        title={propertyData.propertyName}
      >
        <div className="mt-5 mb-3 mx-2">
          <Row className={styles.mapContainer} hidden={propertyData.parcelCount > 0}>
            <WebMap
              address={addressString}
              name={propertyData.propertyName}
              location={JSON.stringify([propertyData.latitude?.toString(), propertyData.longitude?.toString()])}
              agreementData={this.state.agreementData}
              isAddPropertyAvailable={false}
              onAddProperty={""}
            />
          </Row>
          <Row className={styles.mapContainer} hidden={propertyData.parcelCount === 0}>
            {this.showParcelMap(propertyData, addressString)}
          </Row>
          <Row className={styles.pageRow}>
            <Button variant="link" className={styles.propertyRecordLink} onClick={this.sendToPropertyRecordPage}>
              <Image src={linkImage} thumbnail className={styles.externalLinkImage} alt="Property Record Link" />
              View Property Record Page
            </Button>
          </Row>
          <Row>
            <Col sm={6}>
              {this.renderLabel(
                "propertyName-" + index,
                "Property Name",
                propertyData.propertyName ? propertyData.propertyName : ""
              )}
            </Col>
            <Col sm={6}>{this.renderLabel("propertyAddress", "Address", addressString)}</Col>
          </Row>
          <Row>
            <Col sm={6}>{this.renderLabel("county", "County", propertyData.county)}</Col>
            <Col sm={6}>{this.renderLabel("state", "State", this.getStateNameFromId(propertyData.state))}</Col>
          </Row>
          <Row>
            <Col sm={6}>{this.renderLabel("acreage", "Acreage", propertyData.acres)}</Col>
            <Col sm={6}>
              {this.renderLabel(
                "landClass",
                "Land Class",
                UtilityFunctions.getDisplayTextFromFieldObject(propertyData.landClass)
              )}
            </Col>
          </Row>
        </div>
      </Tab>
    );
  }

  handleContactSearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState?.stateGuid,
    };
    this.setState({ typeaheadIsLoading: true });
    const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText(searchObj);
    let outputContacts = [];
    if (contactsData?.data?.results) {
      let results = contactsData.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              firstName: user.firstName,
              lastName: user.lastName,
              contactUniqueIdentifier: userProfile.wsUserProfileUniqueIdentifier,
              contactGuid: userProfile.wsUserProfileUniqueIdentifier,
              contactName: user.firstName + " " + user.lastName,
              emailAddress: user.userPrinciple,
              phoneNumber: user.primaryPhoneNumber,
              primaryMailingAddress: {
                mailingStreet: user.streetAddress,
                mailingCity: user.city,
                mailingZip: user.zip,
                mailingStateUniqueId: user.stateOfAddressUniqueReference,
              },
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    this.setState({ typeaheadOptions: outputContacts, typeaheadIsLoading: false });

    return outputContacts;
  };

  handlePrimaryFieldAgentChange = async (selectedOption) => {
    let agreementData = this.state.agreementData;
    agreementData.primaryFieldAgent = await this.getUserData(selectedOption[0].contactGuid);
    this.setState({
      agreementData,
      showPrimaryFieldAgentSearch: false,
      typeaheadIsLoading: false,
      agreementDetailsEdited: true,
    });
  };

  primaryFieldAgentComponent = () => {
    let renderArray = [];

    if (this.state.showPrimaryFieldAgentSearch) {
      renderArray.push(
        <AsyncTypeahead
          id="asyncMultiColSelect"
          filterBy={["firstName", "lastName"]}
          clearButton
          isLoading={this.state.typeaheadIsLoading}
          labelKey="firstName"
          minLength={1}
          onSearch={this.handleContactSearch}
          options={this.state.typeaheadOptions}
          placeholder="Search for WS Specialists"
          onChange={this.handlePrimaryFieldAgentChange}
          renderMenuItemChildren={(option) => this.formatMenu(option)}
          defaultInputValue={
            this.state.agreementData.primaryFieldAgent?.fullName
              ? this.state.agreementData.primaryFieldAgent.fullName
              : ""
          }
          className={styles.fieldAgentCardText}
        />
      );
    } else if (this.state.agreementData?.primaryFieldAgent) {
      renderArray.push(
        <Row>
          <Col align="left">
            <span>{this.state.agreementData.primaryFieldAgent.fullName}</span>
          </Col>
          <Col align="right">
            <span>
              <Button
                variant="link"
                style={{ cursor: "pointer" }}
                className={styles.fieldAgentCardText}
                onClick={() => {
                  let data = this.state;
                  data.agreementData.primaryFieldAgent = "";
                  data.showPrimaryFieldAgentSearch = true;
                  this.setState(data);
                }}
              >
                Remove
              </Button>
            </span>
          </Col>
        </Row>
      );
    }

    return renderArray;
  };

  removeFieldAgent = (index) => {
    let agreement = this.state.agreementData;
    let array = agreement.additionalFieldAgents;
    array.splice(index, 1);
    this.setState({ agreementData: agreement, fieldAgentsEdited: true }, () => {
      if (array.length < 1) {
        this.setState({ showAdditionalFieldAgents: false });
      }
    });
  };

  additionalFieldAgentContactsComponent = () => {
    let addAgentsButton = null;
    let fieldAgentSearchBar = null;
    let fieldAgentsDisplay = null;
    let fieldAgentTitle = null;
    if (this.state.showAdditionalFieldAgents) {
      fieldAgentSearchBar = (
        <AsyncSelect
          value={this.state.additionalFieldAgentSelect}
          openMenuOnClick={false}
          placeholder="Search"
          loadOptions={this.handleContactSearch}
          onChange={this.handleAddAFieldAgentContact}
        />
      );
      fieldAgentTitle = <h6 className={styles.addFieldAgentsLabel}>Additional WS Specialists</h6>;
    } else {
      addAgentsButton = (
        <div>
          <hr></hr>
          <Button
            variant="link"
            onClick={() => this.setState({ showAdditionalFieldAgents: true })}
            className={styles.addAnotherAgentButton}
          >
            + Add another Specialist
          </Button>
        </div>
      );
    }
    if (this.state.agreementData?.additionalFieldAgents?.length > 0) {
      fieldAgentTitle = <h6 className={styles.addFieldAgentsLabel}>Additional WS Specialists</h6>;
      fieldAgentsDisplay = this.state.agreementData.additionalFieldAgents.map((item, index) => (
        <Row style={{ marginTop: "10px" }} key={index}>
          <Col align="left">
            <span>{item?.firstName + " " + item?.lastName}</span>
          </Col>
          <Col align="right">
            <span>
              <Button
                variant="link"
                style={{ cursor: "pointer" }}
                className={styles.fieldAgentCardText}
                onClick={() => this.removeFieldAgent(index)}
              >
                Remove
              </Button>
            </span>
          </Col>
        </Row>
      ));
    }
    return (
      <div>
        <div>
          {fieldAgentTitle}
          {fieldAgentSearchBar}
          {fieldAgentsDisplay}
        </div>
        {addAgentsButton}
      </div>
    );
  };

  handleAddAFieldAgentContact = (input) => {
    let agreement = this.state.agreementData;
    let additionalAgents = agreement.additionalFieldAgents;
    additionalAgents.push(input);
    this.setState({ agreementData: agreement, additionalFieldAgentSelect: "", fieldAgentsEdited: true });
    return input;
  };

  formatMenu = (option) => {
    let phoneNumber = "No Phone Number";
    let address = "No Address";
    if (option?.phoneNumber) {
      phoneNumber = UtilityFunctions.formatPhoneNumber(option.phoneNumber);
    }
    if (option?.primaryMailingAddress) {
      if (
        option?.primaryMailingAddress.mailingStreet ||
        option?.primaryMailingAddress.mailingCity ||
        option?.primaryMailingAddress.mailingZip
      ) {
        let state = "";
        let states = this.state.states;
        for (let i = 0; i < this.state.states.length; i++) {
          if (states[i].id === option.primaryMailingAddress.mailingStateUniqueId) {
            state = states[i].name;
          }
        }
        address =
          option.primaryMailingAddress.mailingStreet +
          ", " +
          option.primaryMailingAddress.mailingCity +
          " " +
          state +
          " " +
          option.primaryMailingAddress.mailingZip;
      }
    }
    return (
      <Row className={styles.informationRow}>
        <Col lg={2} align="left">
          <p className={styles.informationTextTypeahead} style={{ fontWeight: "600" }}>
            {option.contactName}
          </p>
        </Col>
        <Col lg={2} align="left">
          <p className={styles.informationTextTypeahead}>{phoneNumber}</p>
        </Col>
        <Col lg={3} align="left">
          <p className={styles.informationTextTypeahead}>{option.emailAddress ? option.emailAddress : "No Email"}</p>
        </Col>
        <Col lg={5} align="left">
          <p className={styles.informationTextTypeahead}>{address}</p>
        </Col>
      </Row>
    );
  };

  handleSpecialInstructionsChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.id] = input.value;
    this.setState({ data });
  };

  handleFieldsChanges = ({ currentTarget: input }) => {
    const { data, errors } = this.state;

    if (input.value > data.initialExpirationDate) {
      toast.warning("Updated Expiration date should not be later than current Expiration Date.");
    } else {
      if (errors[input.name]) {
        errors[input.name] = "";
      }

      data[input.name] = input.value;
      this.setState({ data, errors, expirationDateEdited: true });
    }
  };

  renderAgreementInfoCard() {
    const { data, isAgreementEditable } = this.state;

    return (
      <Card className={styles.viewPageCards}>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>{`${isAgreementEditable ? "Edit " : ""
            }Agreement Information`}</span>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={4}>{this.renderLabel("agreementNumber", "Agreement Number", data.agreementNumber)}</Col>
            <Col sm={isAgreementEditable ? 2 : 4}>
              {this.renderLabel("agreementSignatureDate", "Time Signed", this.getHowLongSigned())}
            </Col>
            <Col sm={4} className="text-left">
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Expiration Date</Form.Label>
                <Form.Control
                  name="expirationDate"
                  type="date"
                  placeholder="Enter Date"
                  value={this.state.data?.expirationDate}
                  className={globalStyles.formData}
                  onChange={this.handleFieldsChanges}
                  disabled={!isAgreementEditable}
                />
              </Form.Group>
            </Col>
            <Col sm={2}></Col>
          </Row>

          <Row>
            <Col sm={isAgreementEditable ? 6 : 4}>
              {this.renderEditableField(isAgreementEditable, "commonName", "Agreement Name", data.commonName)}
            </Col>

            <Col sm={isAgreementEditable ? 6 : 4}>
              {this.renderEditableField(
                false,
                "agreementType",
                "Agreement Type",
                data.agreementType,
                "select",
                this.state.agreementTypes
              )}
            </Col>
            <Col sm={4} hidden={isAgreementEditable}>
              {this.renderEditableField(
                isAgreementEditable,
                "agreementRenewalStatus",
                "Agreement Renewal Status",
                data.agreementRenewalStatus,
                "select",
                this.state.agreementRenewalStatuses
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderFieldAgentCard() {
    let cardBody = "";
    let cardTitle = "WS Specialists Information";
    if (this.state.isAgreementEditable) {
      cardTitle = "Add WS Specialist";
      cardBody = (
        <Row>
          <Col>
            <h6 className={styles.editAgreementLabel}>
              Primary WS Specialist<span className={globalStyles.asterisk508}>*</span>
            </h6>
            <div>
              <this.primaryFieldAgentComponent />
              <this.additionalFieldAgentContactsComponent />
            </div>
          </Col>
        </Row>
      );
    } else if (this.state.agreementData?.primaryFieldAgent) {
      cardBody = (
        <div>
          <Row>
            <Col sm={3}>
              {this.renderLabel(
                "fieldAgentName",
                "Primary WS Specialist Name",
                this.state.agreementData.primaryFieldAgent.fullName
              )}
            </Col>
            <Col sm={3}>
              {this.renderLabel(
                "phoneNumber",
                "Phone Number",
                UtilityFunctions.formatPhoneNumber(this.state.agreementData.primaryFieldAgent.primaryPhoneNumber)
              )}
            </Col>
            <Col sm={6}>
              {this.renderLabel(
                "stateOffice",
                "State Office",
                this.state.agreementData.primaryFieldAgent.fullMailingAddress
              )}
            </Col>
          </Row>
          {this.state.agreementData.additionalFieldAgents.map((agent, index) => {
            return (
              <Row style={{ paddingTop: "10px" }} key={index}>
                <Col sm={3}>{this.renderLabel("WSSpecialistName-" + index, "WS Specialist Name", agent?.fullName)}</Col>
                <Col sm={3}>
                  {this.renderLabel(
                    "phoneNumber-" + index,
                    "Phone Number",
                    UtilityFunctions.formatPhoneNumber(agent?.primaryPhoneNumber)
                  )}
                </Col>
                <Col sm={6}>{this.renderLabel("stateOffice-" + index, "State Office", agent?.fullMailingAddress)}</Col>
              </Row>
            );
          })}
        </div>
      );
    }
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>{cardTitle}</span>
        </Card.Header>
        <Card.Body className={styles.fieldAgentCardText}>{cardBody}</Card.Body>
      </Card>
    );
  }

  renderCooperatorInfoCard() {
    if (Number.parseInt(this.state.data.agreementType) === 2) {
      return null;
    }
    let cooperatorCard = (
      <Card className={styles.viewPageCards}>
        <Card.Header className={globalStyles.cardTitles}>
          <h4 className={globalStyles.cardTitleText}>Cooperator Information</h4>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              {this.renderLabel(
                "cooperatorName",
                "Cooperator Name",
                this.state.cooperatorData?.fullName ? this.state.cooperatorData.fullName : ""
              )}
            </Col>
            <Col sm={6}>
              {this.renderLabel(
                "phoneNumber",
                "Phone Number",
                this.state.cooperatorData?.primaryPhoneNumber
                  ? UtilityFunctions.formatPhoneNumber(this.state.cooperatorData.primaryPhoneNumber)
                  : ""
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              {this.renderLabel(
                "emailAddress",
                "Email Address",
                this.state.cooperatorData?.emailAddress ? this.state.cooperatorData?.emailAddress : ""
              )}
            </Col>
            <Col sm={6}>
              {this.renderLabel(
                "cooperatorAddress",
                "Address",
                this.state.cooperatorData?.fullMailingAddress ? this.state.cooperatorData.fullMailingAddress : ""
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
    if (this.state.isAgreementEditable) {
      cooperatorCard = (
        <EditAgreementEditContactModal
          show={this.state.showEditContactModal}
          onHide={this.closeEditContactModal}
          phoneNumberTypes={this.state.phoneNumberTypes}
          states={this.state.states}
          countries={this.state.countries}
          contactType="cooperator"
          contactData={this.state.cooperatorData}
          agreementGuid={this.state.agreementData.agreementUniqueIdentifier}
          onCooperatorEdit={this.cooperatorEdit}
        />
      );
    }
    return cooperatorCard;
  }

  cooperatorEdit = (cooperatorData) => {
    cooperatorData.fullMailingAddress = this.getPrimaryAddress(cooperatorData.primaryMailingAddress, "contact");
    cooperatorData.primaryPhoneNumber = UtilityFunctions.formatPhoneNumber(cooperatorData.primaryPhoneNumber);
    this.setState({ cooperatorData: cooperatorData, cooperatorEdited: true });
  };

  renderTargetedSpeciesCard() {
    const speciesDataFromCurrentProperty = this.state.selectedSpeciesTableData
      .filter((species) => {
        let propertyIncluded = false;
        species.checkedProperties.forEach((property) => {
          propertyIncluded = property.propertyUniqueIdentifier === this.state.currentProperty.propertyUniqueIdentifier;
        });
        return propertyIncluded;
      })
      .sort(UtilityFunctions.agreementEntityTableSortFunc("speciesName"));
    const { isAgreementActive, isAgreementEditable } = this.state;
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>Add Targeted Damage Agents</span>
        </Card.Header>
        <Card.Body className={styles.speciesMethodsCardBody}>
          <Row className="mb-3" hidden={!isAgreementEditable || isAgreementActive}>
            <Col lg={6}>
              <AsyncSelect
                value={this.state.speciesSearch}
                openMenuOnClick={false}
                placeholder="Search for a Damage Agent"
                loadOptions={this.handleSpeciesSearch}
                onChange={this.handleSpeciesSelection}
              />
            </Col>
            <Col lg={4}></Col>
            <Col lg={2} className={styles.speciesMethodsAssociationButtons}>
              <Button className={styles.associationButtons} onClick={this.showSpeciesPropertyAssociationModal}>
                <span className={styles.associationButtonsText}>Associate with Property</span>
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <BootstrapTable
              keyField="speciesTableId"
              data={speciesDataFromCurrentProperty}
              columns={this.state.selectedSpeciesColumns}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderMethodsCard() {
    const methodsDataFromCurrentProperty = this.state.selectedMethodsTableData
      .filter((method) => {
        let propertyIncluded = false;
        method.checkedProperties.forEach((property) => {
          propertyIncluded = property.propertyUniqueIdentifier === this.state.currentProperty.propertyUniqueIdentifier;
        });
        return propertyIncluded;
      })
      .sort(UtilityFunctions.agreementEntityTableSortFunc("methodName"));
    const { isAgreementActive, isAgreementEditable } = this.state;
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>Add Methods</span>
        </Card.Header>
        <Card.Body className={styles.speciesMethodsCardBody}>
          <Row className="mb-3" hidden={!isAgreementEditable || isAgreementActive}>
            <Col lg={6}>
              <AsyncSelect
                value={this.state.methodsSearch}
                openMenuOnClick={false}
                placeholder="Search for a Method"
                loadOptions={this.handleMethodsSearch}
                onChange={this.handleMethodsSelection}
              />
            </Col>
            <Col lg={4}></Col>
            <Col lg={2} className={styles.speciesMethodsAssociationButtons}>
              <Button className={styles.associationButtons} onClick={this.showMethodsPropertyAssociationModal}>
                <span className={styles.associationButtonsText}>Associate with Property</span>
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <BootstrapTable
              keyField="methodTableId"
              data={methodsDataFromCurrentProperty}
              columns={this.state.selectedMethodsColumns}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
        </Card.Body>
      </Card>
    );
  }

  handleSpeciesSearch = async (searchValue) => {
    let searchResults = "";
    if (this.state.currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(this.state.currentUsaState.stateGuid, {
        textToSearchFor: searchValue,
        pageSize: 10,
        pageNumber: 1,
      });
      if (speciesData?.data?.results) {
        searchResults = speciesData.data.results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return searchResults;
  };

  handleMethodsSearch = async (searchValue) => {
    let searchResults = "";
    if (this.state.currentUsaState?.stateGuid) {
      const methodsData = await ReferenceFileAPI.FilterMethods(this.state.currentUsaState.stateGuid, {
        textToSearchFor: searchValue,
        pageSize: 10,
        pageNumber: 1,
      });
      if (methodsData?.data?.results) {
        searchResults = methodsData.data.results.map((result) => ({
          value: result.methodUniqueIdentifier,
          label: result.methodName,
        }));
      }
    }
    return searchResults;
  };

  handleSpeciesSelection = (input) => {
    this.setState({ speciesSearch: input });
    return input;
  };

  handleMethodsSelection = (input) => {
    this.setState({ methodsSearch: input });
    return input;
  };

  closeSpeciesPropertyAssociationModal = () => {
    let { speciesPropertyParcelModalData } = this.state;
    speciesPropertyParcelModalData.currentSpecies = null;
    speciesPropertyParcelModalData.checkedProperties = [];
    this.setState({ showSpeciesPropertyAssociationModal: false, speciesPropertyParcelModalData });
  };

  closeMethodsPropertyAssociationModal = () => {
    let { methodsPropertyParcelModalData } = this.state;
    methodsPropertyParcelModalData.currentMethod = null;
    methodsPropertyParcelModalData.checkedProperties = [];
    this.setState({ showMethodsPropertyAssociationModal: false, methodsPropertyParcelModalData });
  };

  closeResourcePropertyAssociationModal = () => {
    let { resourcePropertyParcelModalData } = this.state;
    resourcePropertyParcelModalData.currentResource = null;
    resourcePropertyParcelModalData.checkedProperties = [];
    this.setState({ showResourcePropertyAssociationModal: false, resourcePropertyParcelModalData });
  };

  handleSpeciesPropertyParcelAssociation = (updatedCheckedProperties) => {
    let { speciesPropertyParcelModalData, selectedSpeciesTableData } = this.state;
    let currentSpecies = JSON.parse(JSON.stringify(speciesPropertyParcelModalData.currentSpecies));
    const newTableIds = updatedCheckedProperties.map(
      (property) => `${currentSpecies.speciesUniqueIdentifier}|${property.propertyUniqueIdentifier}`
    );

    const updatedProperty = updatedCheckedProperties[0];
    if (speciesPropertyParcelModalData.currentAction === "add") {
      if (
        selectedSpeciesTableData.find(
          (species) =>
            species.speciesUniqueIdentifier === currentSpecies.speciesUniqueIdentifier &&
            species.checkedProperties[0]?.propertyUniqueIdentifier === updatedProperty?.propertyUniqueIdentifier
        )
      ) {
        toast.info(
          "The selected species was already added to the agreement. You can edit its Property Associations on the specific record."
        );
        return;
      }
    } else {
      selectedSpeciesTableData = JSON.parse(
        JSON.stringify(
          selectedSpeciesTableData.filter(
            (species) =>
              species.speciesUniqueIdentifier !== currentSpecies.speciesUniqueIdentifier ||
              species.checkedProperties[0]?.propertyUniqueIdentifier !== updatedProperty?.propertyUniqueIdentifier
          )
        )
      );
    }
    newTableIds.forEach((tableId) => {
      const currentPropertyId = tableId.split("|")[1];
      const currentPropertyArray = [
        ...updatedCheckedProperties.filter((property) => property.propertyUniqueIdentifier === currentPropertyId),
        ...updatedCheckedProperties.filter((property) => property.propertyUniqueIdentifier !== currentPropertyId),
      ];
      selectedSpeciesTableData.push({
        speciesUniqueIdentifier: currentSpecies.speciesUniqueIdentifier,
        speciesName: currentSpecies.speciesName,
        checkedProperties: currentPropertyArray,
        speciesTableId: tableId,
      });
    });

    currentSpecies.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    this.setState({ speciesSearch: "", selectedSpeciesTableData });
  };

  handleMethodsPropertyParcelAssociation = (updatedCheckedProperties) => {
    let { methodsPropertyParcelModalData, selectedMethodsTableData } = this.state;
    let currentMethod = JSON.parse(JSON.stringify(methodsPropertyParcelModalData.currentMethod));
    const newTableIds = updatedCheckedProperties.map(
      (property) => `${currentMethod.methodUniqueIdentifier}|${property.propertyUniqueIdentifier}`
    );

    const updatedProperty = updatedCheckedProperties[0];
    if (methodsPropertyParcelModalData.currentAction === "add") {
      if (
        selectedMethodsTableData.find(
          (method) =>
            method.methodUniqueIdentifier === currentMethod.methodUniqueIdentifier &&
            method.checkedProperties[0]?.propertyUniqueIdentifier === updatedProperty?.propertyUniqueIdentifier
        )
      ) {
        toast.info(
          "The selected method was already added to the agreement. You can edit its Property Associations on the specific record."
        );
        return;
      }
    } else {
      selectedMethodsTableData = JSON.parse(
        JSON.stringify(
          selectedMethodsTableData.filter(
            (method) =>
              method.methodUniqueIdentifier !== currentMethod.methodUniqueIdentifier ||
              method.checkedProperties[0]?.propertyUniqueIdentifier !== updatedProperty?.propertyUniqueIdentifier
          )
        )
      );
    }
    newTableIds.forEach((tableId) => {
      const currentPropertyId = tableId.split("|")[1];
      const currentPropertyArray = [
        ...updatedCheckedProperties.filter((property) => property.propertyUniqueIdentifier === currentPropertyId),
        ...updatedCheckedProperties.filter((property) => property.propertyUniqueIdentifier !== currentPropertyId),
      ];
      selectedMethodsTableData.push({
        methodUniqueIdentifier: currentMethod.methodUniqueIdentifier,
        methodName: currentMethod.methodName,
        checkedProperties: currentPropertyArray,
        methodTableId: tableId,
      });
    });

    currentMethod.checkedProperties = JSON.parse(JSON.stringify(updatedCheckedProperties));
    this.setState({ methodsSearch: "", selectedMethodsTableData });
  };

  showSpeciesPropertyAssociationModal = () => {
    if (this.state.speciesSearch) {
      const speciesData = {
        speciesUniqueIdentifier: this.state.speciesSearch.value,
        speciesName: this.state.speciesSearch.label,
        checkedProperties: [],
      };

      let { speciesPropertyParcelModalData } = this.state;
      speciesPropertyParcelModalData.currentSpecies = speciesData;
      speciesPropertyParcelModalData.checkedProperties = [];
      speciesPropertyParcelModalData.currentAction = "add";

      this.setState({ speciesPropertyParcelModalData });
      this.handleSpeciesPropertyParcelAssociation([this.state.currentProperty]);
    } else toast.warning("Please select a new damage agent to be linked to the Agreement.");
  };

  showMethodsPropertyAssociationModal = () => {
    if (this.state.methodsSearch) {
      const methodData = {
        methodUniqueIdentifier: this.state.methodsSearch.value,
        methodName: this.state.methodsSearch.label,
        checkedProperties: [],
      };

      let { methodsPropertyParcelModalData } = this.state;
      methodsPropertyParcelModalData.currentMethod = methodData;
      methodsPropertyParcelModalData.checkedProperties = [];
      methodsPropertyParcelModalData.currentAction = "add";

      this.setState({ methodsPropertyParcelModalData });
      this.handleMethodsPropertyParcelAssociation([this.state.currentProperty]);
    } else toast.warning("Please select a new method to be linked to the Agreement.");
  };

  addContact = () => {
    console.log("Show AddContact modal");
  };

  handleNoResourcesDataSelection = ({ currentTarget: input }) => {
    const { data } = this.state;
    data[input.name] = input.checked;
    if (input.name === "noHistoricalResourcesRequired" && input.checked) {
      data.noLossesRequired = input.checked;
    }
    this.setState({ data });
  };

  renderProtectedResourcesCard() {
    const { data, currentProperty, isAgreementEditable } = this.state;
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <Row>
            <Col>
              <span className={globalStyles.cardTitleText}>{`${isAgreementEditable ? "Edit " : ""
                }Protected Resources`}</span>
            </Col>
            <Col className="text-right" sm="auto">
              {this.state.protectedResourcesData?.length === 0 && (
                <Form.Group controlId="noProtectedResourcesCheckbox">
                  <Form.Check
                    name="noProtectedResourcesRequired"
                    type="checkbox"
                    label="No Protected Resources"
                    onChange={this.handleNoResourcesDataSelection}
                    checked={data.noProtectedResourcesRequired}
                    disabled={!isAgreementEditable}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row hidden={!isAgreementEditable}>
            <Col md={1} className="ml-0 pr-0">
              <Form.Control
                name="protectedResourceYear"
                type="number"
                value={data.protectedResourceYear}
                onChange={this.handleResourceChanges}
                disabled
              />
            </Col>
            <Col md={3} className="px-2">
              <AsyncSelect
                value={data.protectedResourceSearch}
                openMenuOnClick={false}
                placeholder="Search for Resource"
                components={{ DropdownIndicator }}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "12px" }),
                }}
                loadOptions={this.handleResourceSearch}
                onChange={this.handleProtectedResourceSelection}
              />
            </Col>
            <Col md={1} className="pl-0 pr-2">
              <Form.Control
                name="protectedResourceQuantity"
                type="number"
                placeholder="Qty."
                value={data.protectedResourceQuantity}
                onChange={this.handleResourceChanges}
              />
            </Col>
            <Col md={1} className="pl-0 pr-2">
              <Select
                value={data.protectedResourceUom}
                placeholder="UOM"
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "12px" }),
                }}
                options={this.state.resourceUOMs}
                onChange={this.handleResourceUomChange}
              />
            </Col>
            <Col md={2} className="pl-0 pr-2">
              <Form.Control
                name="protectedResourceUnitValue"
                type="number"
                placeholder="Unit Value (USD)"
                value={data.protectedResourceUnitValue}
                onChange={this.handleResourceChanges}
              />
            </Col>
            <Col md={2} className="pl-0 pr-2">
              <Form.Control
                name="protectedResourceTotalValue"
                type="number"
                placeholder="Total Value (USD)"
                value={data.protectedResourceTotalValue}
                onChange={this.handleResourceChanges}
              />
            </Col>
            <Col md={2} className="text-right pl-0 ml-0">
              <Button className={styles.associationButtons} onClick={this.showResourcePropertyAssociationModal}>
                <span className={styles.associationButtonsText}>Associate with Property</span>
              </Button>
            </Col>
          </Row>
          <Row className="mt-4 mb-3 px-3" hidden={!isAgreementEditable}>
            <Col className={styles.rowLineSeparator}></Col>
          </Row>
          <Row>
            <Col>
              <BootstrapTable
                keyField="resourceTableId"
                data={this.state.protectedResourcesData
                  .filter(
                    (resource) =>
                      resource.checkedProperties[0].propertyUniqueIdentifier ===
                      currentProperty?.propertyUniqueIdentifier
                  )
                  .sort(UtilityFunctions.agreementEntityTableSortFunc("resourceName"))}
                columns={this.getResourceDataColumns("protectedResources", isAgreementEditable)}
                bootstrap4={true}
                hover={true}
                bordered={false}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderHistoricalResourcesCard() {
    const { data, currentProperty, isAgreementEditable, historicalResourcesData } = this.state;
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <Row>
            <Col>
              <span className={globalStyles.cardTitleText}>{`${isAgreementEditable ? "Edit " : ""
                }Historical Resources and Losses`}</span>
            </Col>
            <Col>
              <Col className="text-right">
                <div>
                  {historicalResourcesData?.length === 0 && (
                    <Form.Check
                      aria-label="No Historical Resources"
                      type="checkbox"
                      label="No Historical Resources"
                      name="noHistoricalResourcesRequired"
                      onChange={this.handleNoResourcesDataSelection}
                      checked={data.noHistoricalResourcesRequired}
                      disabled={!isAgreementEditable}
                      inline
                    />
                  )}
                  {!historicalResourcesData?.some((r) => r.historicalLosses?.length > 0) && (
                    <Form.Check
                      aria-label="No Historical Losses"
                      type="checkbox"
                      label="No Losses"
                      name="noLossesRequired"
                      onChange={this.handleNoResourcesDataSelection}
                      checked={data.noLossesRequired}
                      disabled={!isAgreementEditable}
                      inline
                    />
                  )}
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row hidden={!isAgreementEditable}>
            <Col md={1} className="ml-0 pr-0">
              <Form.Control
                name="historicalResourceYear"
                placeholder="Year"
                type="number"
                value={data.historicalResourceYear}
                onChange={this.handleResourceChanges}
                readOnly={data.noHistoricalResourcesRequired}
              />
            </Col>
            <Col md={3} className="px-2">
              {data.noHistoricalResourcesRequired ? (
                <Form.Control
                  name="historicalResourceSearch"
                  type="text"
                  placeholder="Search Resource"
                  value={data.historicalResourceSearch?.label}
                  onChange={this.handleResourceChanges}
                  readOnly
                />
              ) : (
                <AsyncSelect
                  value={data.historicalResourceSearch}
                  openMenuOnClick={false}
                  placeholder="Search for Resource"
                  components={{ DropdownIndicator }}
                  styles={{
                    indicatorSeparator: () => {
                      //do nothing
                    },
                    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "12px" }),
                  }}
                  loadOptions={this.handleResourceSearch}
                  onChange={this.handleHistoricalResourceSelection}
                />
              )}
            </Col>
            <Col md={1} className="pl-0 pr-2">
              <Form.Control
                name="historicalResourceQuantity"
                type="number"
                placeholder="Qty."
                value={data.historicalResourceQuantity}
                onChange={this.handleResourceChanges}
                readOnly={data.noHistoricalResourcesRequired}
              />
            </Col>
            <Col md={1} className="pl-0 pr-2">
              {data.noHistoricalResourcesRequired ? (
                <Form.Control
                  name="historicalResourceUom"
                  type="text"
                  placeholder="UOM"
                  value={data.historicalResourceUom?.label}
                  onChange={this.handleResourceChanges}
                  readOnly
                />
              ) : (
                <Select
                  value={data.historicalResourceUom}
                  placeholder="UOM"
                  styles={{
                    indicatorSeparator: () => {
                      //do nothing
                    },
                    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "12px" }),
                  }}
                  options={this.state.resourceUOMs}
                  onChange={this.handleHistoricalResourceUomChange}
                />
              )}
            </Col>
            <Col md={2} className="pl-0 pr-2">
              <Form.Control
                name="historicalResourceUnitValue"
                type="number"
                placeholder="Unit Value"
                value={data.historicalResourceUnitValue}
                onChange={this.handleResourceChanges}
                readOnly={data.noHistoricalResourcesRequired}
              />
            </Col>
            <Col md={2} className="pl-0 pr-2">
              <Form.Control
                name="historicalResourceTotalValue"
                type="number"
                placeholder="Total Value"
                value={data.historicalResourceTotalValue}
                onChange={this.handleResourceChanges}
                readOnly={data.noHistoricalResourcesRequired}
              />
            </Col>
            <Col md={2} className="text-right pl-0 ml-0">
              <Button
                className={styles.associationButtons}
                onClick={this.showHistoricalResourcePropertyAssociationModal}
              >
                <span className={styles.associationButtonsText}>Associate with Property</span>
              </Button>
            </Col>
          </Row>
          <Row className="mt-4 mb-3 px-3" hidden={!isAgreementEditable}>
            <Col className={styles.rowLineSeparator}></Col>
          </Row>
          <Row>
            <Col>
              <BootstrapTable
                keyField="resourceTableId"
                data={historicalResourcesData
                  .filter(
                    (resource) =>
                      resource.checkedProperties[0].propertyUniqueIdentifier ===
                      currentProperty?.propertyUniqueIdentifier
                  )
                  .sort(UtilityFunctions.agreementEntityTableSortFunc("resourceName"))}
                columns={this.getResourceDataColumns("historicalResources", isAgreementEditable)}
                bootstrap4={true}
                hover={true}
                bordered={false}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  closeRecCemPropertyAssociationModal = () => {
    let { recCemPropertyParcelModalData } = this.state;
    recCemPropertyParcelModalData.currentRecCem = null;
    recCemPropertyParcelModalData.checkedProperties = [];
    recCemPropertyParcelModalData.entityType = "";
    this.setState({ showRecCemPropertyAssociationModal: false, recCemPropertyParcelModalData });
  };

  setRecCemPropertyModalData(selectedRecCEMs, isRecommendations = true) {
    const { recCemPropertyParcelModalData } = this.state;
    recCemPropertyParcelModalData.currentRecCem = [...selectedRecCEMs];
    recCemPropertyParcelModalData.checkedProperties = [];
    recCemPropertyParcelModalData.currentAction = "add";
    recCemPropertyParcelModalData.entityType = isRecommendations ? "recommendations" : "cooperatorEmployedMethods";
    this.setState({ recCemPropertyParcelModalData });
  }

  showRecommendationsPropertyAssociationModal = () => {
    const { selectedRecommendations, agreementData } = this.state;
    const properties = agreementData.agreementProperties;

    if (selectedRecommendations?.length > 0) {
      this.setRecCemPropertyModalData(selectedRecommendations);
      this.setState({ showRecCemPropertyAssociationModal: properties.length === 1 ? false : true });

      if (properties.length === 1) {
        this.handleRecPropertyParcelAssociation(properties);
      }
    } else {
      toast.warning("Please select at least one recommendation to be linked to the Agreement.");
    }
  };

  showCemPropertyAssociationModal = () => {
    const { selectedCEMs, agreementData } = this.state;
    const properties = agreementData.agreementProperties;
    if (selectedCEMs?.length > 0) {
      this.setRecCemPropertyModalData(selectedCEMs, false);
      this.setState({
        showRecCemPropertyAssociationModal: agreementData.agreementProperties.length === 1 ? false : true,
      });

      if (properties.length === 1) {
        this.handleCemPropertyParcelAssociation(properties);
      }
    } else {
      toast.warning("Please select at least one Cooperator Employed Method to be linked to the Agreement.");
    }
  };

  handleRecPropertyParcelAssociation = (updatedCheckedProperties) => {
    let { selectedRecTableData, selectedRecommendations } = this.state;
    let areRecordsDuplicated = false;
    let selectedRecommendationsToUpdate = JSON.parse(JSON.stringify(this.state.recommendations));

    for (let recommendation of selectedRecommendations) {
      updatedCheckedProperties.forEach((property) => {
        const newTableId = `${recommendation.value}|${property.propertyUniqueIdentifier}`;
        if (selectedRecTableData.find((r) => r.tableId === newTableId)) {
          areRecordsDuplicated = true;
        } else {
          selectedRecTableData.push({
            tableId: newTableId,
            recCemId: recommendation.value,
            recCemName: recommendation.label,
            property: {
              propertyUniqueIdentifier: property.propertyUniqueIdentifier,
              propertyName: property.propertyName,
            },
          });
        }
      });

      const recommendationToUpdate = selectedRecommendationsToUpdate.find((r) => r.recCemId === recommendation.value);
      if (!recommendationToUpdate) {
        selectedRecommendationsToUpdate.push({
          recCemId: recommendation.value,
          recCemName: recommendation.label,
          checkedProperties: updatedCheckedProperties,
          isNewRecCem: true,
        });
      } else {
        recommendationToUpdate.checkedProperties = updatedCheckedProperties;
      }
    }

    if (areRecordsDuplicated) {
      toast.info(
        "One or more of these recommendations were previously added to the agreement. You can remove a the specific record and define new Property Associations."
      );
    }

    this.setState({
      selectedRecTableData,
      selectedRecommendations: selectedRecommendationsToUpdate,
      recommendations: selectedRecommendationsToUpdate,
      recCemPropertyParcelModalData: {
        currentRecCem: null,
        checkedProperties: [],
        currentAction: "add",
        entityType: "",
      },
    });
  };

  handleCemPropertyParcelAssociation = (updatedCheckedProperties) => {
    let { selectedCemTableData, selectedCEMs } = this.state;
    let areRecordsDuplicated = false;
    let selectedCEMsToUpdate = JSON.parse(JSON.stringify(this.state.cooperatorEmployedMethods));

    for (let employedMethod of selectedCEMs) {
      updatedCheckedProperties.forEach((property) => {
        const newTableId = `${employedMethod.value}|${property.propertyUniqueIdentifier}`;
        if (selectedCemTableData.find((r) => r.tableId === newTableId)) {
          areRecordsDuplicated = true;
        } else {
          selectedCemTableData.push({
            tableId: newTableId,
            recCemId: employedMethod.value,
            recCemName: employedMethod.label,
            property: {
              propertyUniqueIdentifier: property.propertyUniqueIdentifier,
              propertyName: property.propertyName,
            },
          });
        }
      });

      const cemToUpdate = selectedCEMsToUpdate.find((r) => r.recCemId === employedMethod.value);
      if (!cemToUpdate) {
        selectedCEMsToUpdate.push({
          recCemId: employedMethod.value,
          recCemName: employedMethod.label,
          checkedProperties: updatedCheckedProperties,
          isNewRecCem: true,
        });
      } else {
        cemToUpdate.checkedProperties = updatedCheckedProperties;
      }
    }

    if (areRecordsDuplicated) {
      toast.info(
        "One or more of these Cooperator Employed Methods were previously added to the agreement. You can remove a the specific record and define new Property Associations."
      );
    }

    this.setState({
      selectedCemTableData,
      selectedCEMs: selectedCEMsToUpdate,
      cooperatorEmployedMethods: selectedCEMsToUpdate,
      recCemPropertyParcelModalData: {
        currentRecCem: null,
        checkedProperties: [],
        currentAction: "add",
        entityType: "",
      },
    });
  };

  handleRecommendationsSelection = (recommendationData) => {
    let { selectedRecommendations, availableRecommendations } = this.state;
    const selectedRecCem = availableRecommendations?.find((r) => r.value === recommendationData.value);
    if (selectedRecCem) {
      if (!selectedRecCem.isSelected && !selectedRecommendations?.find((r) => r.value === recommendationData.value)) {
        selectedRecommendations = [...selectedRecommendations, recommendationData];
      } else if (
        selectedRecCem.isSelected &&
        selectedRecommendations?.find((r) => r.value === recommendationData.value)
      ) {
        selectedRecommendations = selectedRecommendations.filter((r) => r.value !== recommendationData.value);
      }
      selectedRecCem.isSelected = !selectedRecCem.isSelected;
    }

    this.setState({ selectedRecommendations, availableRecommendations });
  };

  handleCEMsSelection = (cemData) => {
    let { selectedCEMs, availableCEMs } = this.state;
    const selectedRecCem = availableCEMs?.find((r) => r.value === cemData.value);
    if (selectedRecCem) {
      if (!selectedRecCem.isSelected && !selectedCEMs?.find((r) => r.value === cemData.value)) {
        selectedCEMs = [...selectedCEMs, cemData];
      } else if (selectedRecCem.isSelected && selectedCEMs?.find((r) => r.value === cemData.value)) {
        selectedCEMs = selectedCEMs.filter((r) => r.value !== cemData.value);
      }
      selectedRecCem.isSelected = !selectedRecCem.isSelected;
    }

    this.setState({ selectedCEMs, availableCEMs });
  };

  renderRecCEMsSection(isRecommendations = true) {
    const { selectedRecTableData, selectedCemTableData, availableRecommendations, availableCEMs } = this.state;
    let entityName;
    let tableData;
    let tableColumns;
    let selectionRowClassName;
    let menuIsOpen;
    let isTableHidden;
    let availableOptions;

    if (isRecommendations) {
      entityName = "Recommendations";
      tableData = selectedRecTableData;
      tableColumns = this.state.selectedRecColumns;
      selectionRowClassName = "my-2";
      menuIsOpen = this.state.isRecDropdownOpen;
      isTableHidden = this.state.selectedRecTableData?.length === 0;
      availableOptions = availableRecommendations?.filter(
        (availableRec) => !selectedRecTableData?.find((rec) => rec.recCemId === availableRec.value)
      );
    } else {
      entityName = "Cooperator Employed Methods";
      tableData = selectedCemTableData;
      tableColumns = this.state.selectedCemColumns;
      selectionRowClassName = "mt-5 mb-2";
      menuIsOpen = this.state.isCemDropdownOpen;
      isTableHidden = this.state.selectedCemTableData?.length === 0;
      availableOptions = availableCEMs?.filter(
        (availableCEM) => !selectedCemTableData?.find((cem) => cem.recCemId === availableCEM.value)
      );
    }

    return (
      <React.Fragment>
        <Row className={selectionRowClassName}>
          <Col md={4}>
            <Select
              aria-label={`${entityName} Selection`}
              name={entityName}
              placeholder={entityName}
              value=""
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                  backgroundColor: isFocused ? "lightgray" : "white",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                }),
                menuList: (styles) => ({ ...styles, textAlign: "left" }),
              }}
              menuIsOpen={menuIsOpen}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onMenuClose={() => {
                isRecommendations
                  ? this.setState({ isRecDropdownOpen: false })
                  : this.setState({ isCemDropdownOpen: false });
              }}
              onFocus={() => {
                isRecommendations
                  ? this.setState({ isRecDropdownOpen: true })
                  : this.setState({ isCemDropdownOpen: true });
              }}
              onBlur={() => {
                isRecommendations
                  ? this.setState({ isRecDropdownOpen: false })
                  : this.setState({ isCemDropdownOpen: false });
              }}
              filterOption={(option, inputValue) => {
                return option?.data?.label.toLowerCase().includes(inputValue.toLowerCase());
              }}
              components={{
                Option: (props) => {
                  const { data, innerProps, innerRef } = props;
                  return (
                    <Row className="my-1 mx-1" ref={innerRef} {...innerProps}>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          inline
                          checked={data.isSelected}
                          onChange={() =>
                            isRecommendations
                              ? this.handleRecommendationsSelection(data)
                              : this.handleCEMsSelection(data)
                          }
                          title={`Select ${data.label}`}
                        />
                        <span className={data.isSelected ? styles.blueFormData : styles.formData}>{data.label}</span>
                      </Col>
                    </Row>
                  );
                },
              }}
              options={availableOptions}
            />
          </Col>
          <Col align="right" hidden={!this.state.isAgreementEditable}>
            <Button
              className={styles.associationButtons}
              onClick={
                isRecommendations
                  ? this.showRecommendationsPropertyAssociationModal
                  : this.showCemPropertyAssociationModal
              }
            >
              <span className={styles.associationButtonsText}>Associate with Property</span>
            </Button>
          </Col>
        </Row>
        <Row className="my-3" hidden={isTableHidden}>
          <BootstrapTable
            keyField="tableId"
            data={tableData}
            columns={tableColumns}
            bootstrap4={true}
            hover={true}
            bordered={false}
          />
        </Row>
      </React.Fragment>
    );
  }

  renderRecCemsCard() {
    return (
      <Card className={styles.viewPageCards} hidden={Number.parseInt(this.state.data.agreementType) === 2}>
        <Card.Header as="h4" className={styles.cardTitles}>
          Edit Recommendations and/or Cooperator Employed Methods
        </Card.Header>
        <Card.Body>
          {this.renderRecCEMsSection()}
          {this.renderRecCEMsSection(false)}
        </Card.Body>
      </Card>
    );
  }

  renderSpecialInstructionsCard() {
    const { data, currentProperty, specialInstructionsData, isAgreementEditable, isAgreementActive } = this.state;
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header className={globalStyles.cardTitles}>
          <h4 className={globalStyles.cardTitleText}>{`${isAgreementEditable && !isAgreementActive ? "Edit " : ""
            }Special Instructions`}</h4>
        </Card.Header>
        <Card.Body>
          <Row hidden={!isAgreementEditable || isAgreementActive}>
            <Col sm={8}>
              <Form.Control
                id="specialInstructions"
                placeholder="Enter Instructions"
                value={data.specialInstructions}
                as="textarea"
                rows="5"
                style={{ fontSize: "12px", border: "solid 0.8px #e1e1e1" }}
                onChange={this.handleSpecialInstructionsChange}
              ></Form.Control>
            </Col>
            <Col sm={4} className="text-right">
              <Button className={styles.associationButtons} onClick={this.handleSpecialInstructionsAssociation}>
                <span className={styles.associationButtonsText}>Associate with Property</span>
              </Button>
            </Col>
          </Row>
          {specialInstructionsData
            .filter((i) => i.propertyUniqueIdentifier === currentProperty?.propertyUniqueIdentifier)
            .map((instruction, index) => (
              <Row key={index} className={`text-left mb-3 ${index === 0 ? "mt-3" : ""}`}>
                <Col sm={8}>
                  {isAgreementEditable && !isAgreementActive && (
                    <Form.Control
                      className={styles.formData}
                      style={isAgreementEditable ? { border: "solid 0.8px #e1e1e1" } : {}}
                      name="specialInstructions"
                      value={instruction.specialInstruction}
                      onChange={(e) => this.editSpecialInstruction(e, index)}
                    ></Form.Control>
                  )}
                  {(!isAgreementEditable || isAgreementActive) && (
                    <p className={globalStyles.informationText}>{instruction.specialInstruction}</p>
                  )}
                </Col>
                <Col sm={4} className="text-right" hidden={!isAgreementEditable || isAgreementActive}>
                  <Button variant="link" className="" onClick={() => this.deleteSpecialInstruction(index)}>
                    <span className={styles.actionButtonsText}>Delete</span>
                  </Button>
                </Col>
              </Row>
            ))}
        </Card.Body>
      </Card>
    );
  }

  handleProjectsAssociation = (projectData) => {
    let projects = this.state.agreementProjects;

    for (let i = 0; i < projectData.selectedProjects.length; i++) {
      let recordExists = false;
      if (projects?.find((p) => p.value === projectData.selectedProjects[i].value)) {
        toast.warning("Selected project is already associated");
        recordExists = true;
      }
      if (!recordExists) {
        projects.push(projectData.selectedProjects[i]);
        this.setState({ agreementProjects: projects, agreementDetailsEdited: true });
      }
    }
  };
  handleRoleChange = async (e, contact, level) => {
    let agreementData = this.state.agreementData;
    let associatedAgreementContactsData = this.state.associatedAgreementContactsData;
    let associatedPropertyContactsData = this.state.associatedPropertyContactsData;
    if (level === "Agreement") {
      let findContact = agreementData.additionalContacts.find(
        (c) => c.contactUniqueReference === contact.contactUniqueId
      );
      findContact.contactRoleOnAgreement = this.state.agreementRoles.find((r) => r.id === parseInt(e.target.value));
      associatedAgreementContactsData = await this.getAgreementAssociatedContactsData(agreementData);
    } else if (level === "Property") {
      let findContact = agreementData.agreementProperties
        .find((p) => p.propertyUniqueReference === contact.propertyUniqueIdentifier)
        .additionalContacts.find((c) => c.contactUniqueReference === contact.contactUniqueId);
      findContact.contactRoleOnAgreement = this.state.agreementRoles.find((r) => r.id === parseInt(e.target.value));
      associatedPropertyContactsData = await this.getPropertyAssociatedContactsData(agreementData);
    }
    this.setState({
      agreementData,
      associatedAgreementContactsData,
      associatedPropertyContactsData,
      contactRolesEdited: true,
    });
  };

  handleAgreementRejection = (rejectionData) => {
    //Pending to implement
  };

  handleOtherAttachmentLinkClick = async (attachmentId) => {
    if (attachmentId) {
      let pdfResponse = await AgreementAPI.GetOtherAgreementAttachment(
        this.state.agreementData.agreementUniqueIdentifier,
        attachmentId
      );
      if (pdfResponse) {
        let blob = new Blob([pdfResponse], { type: "application/pdf" });
        this.handlePdfLinkClick(blob);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve agreement's attached files",
          apiName: "GetOtherAgreementAttachment",
          responseUnsuccessful: pdfResponse?.unsuccessful,
          responseMessage: pdfResponse?.message,
        });
      }
    }
  };

  handlePdfLinkClick = async (pdfContent) => {
    let url = window.URL.createObjectURL(pdfContent);
    window.open(url, "_blank");
  };

  renderAgreementContactsCard() {
    let result = null;
    if (this.state.associatedAgreementContactsData) {
      result = this.renderContactsCard(this.state.associatedAgreementContactsData, "Agreement");
    }
    return result;
  }

  renderPropertyContactsCard() {
    let result = null;
    if (this.state.associatedPropertyContactsData) {
      result = this.renderContactsCard(
        this.state.associatedPropertyContactsData.filter(
          (c) => c.propertyUniqueIdentifier === this.state.currentProperty?.propertyUniqueIdentifier
        ),
        "Property"
      );
    }
    return result;
  }

  renderContactsCard(contactsData, level) {
    return (
      <Card className={styles.viewPageCards} hidden={Number.parseInt(this.state.data.agreementType) === 2}>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <Row>
            <Col>
              <span className={globalStyles.cardTitleText}>{`${this.state.isAgreementEditable ? "Edit" : ""
                } ${level} Contacts`}</span>
            </Col>
            <Col className="text-right" hidden={!this.state.isAgreementEditable}>
              <Button variant="link" onClick={() => this.addContact()} className="text-right">
                <span className={globalStyles.cardTitleText}>+ Add Another Contact</span>
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {contactsData.map((contact, index) => {
            let agreementRoleElement = <span className={styles.formData}>{contact.contactRole}</span>;
            if (this.state.isAgreementEditable) {
              const agreementRoleValue = this.state.agreementRoles.find(
                (r) => r.displayText === contact.contactRole
              )?.id;
              agreementRoleElement = (
                <Form.Control
                  name="agreementRoleEnumId"
                  value={agreementRoleValue}
                  as="select"
                  onChange={(e) => this.handleRoleChange(e, contact, level)}
                  className={styles.editAgreementEditContact}
                  title=""
                >
                  {this.state.agreementRoles?.map((role) => (
                    <option value={role.id} key={role.name}>
                      {role.displayText}
                    </option>
                  ))}
                </Form.Control>
              );
            }
            return (
              <div key={index}>
                <Row className={`text-left mb-3 ${index !== 0 ? "mt-2" : ""}`}>
                  <Col sm={3}>
                    <span className={styles.actionButtonsText}>{contact.fullName}</span>
                  </Col>
                  <Col>
                    <span className={styles.formData}>
                      {UtilityFunctions.formatPhoneNumber(contact.primaryPhoneNumber)}
                    </span>
                  </Col>
                  <Col>
                    <span className={styles.formData}>{contact.emailAddress}</span>
                  </Col>
                  <Col>{agreementRoleElement}</Col>
                  <Col sm={3} className="text-right" hidden={!this.state.isAgreementEditable}>
                    <DropdownButton
                      id="dropdown-contacts-actions"
                      title={<span className={styles.actionButtonsText}>Actions</span>}
                      variant="link"
                      className="pr-0 text-right"
                    >
                      <Dropdown.Item eventKey="editContact" onSelect={() => this.handleEditContact(contact, level)}>
                        <span className={styles.dropdownActionItems}>Edit Contact</span>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="deleteResource" onSelect={() => this.deleteContact(contact, level)}>
                        <span className={styles.dropdownActionItems}>Remove</span>
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
                <Row className="px-2">
                  <Col className={styles.rowLineSeparator}></Col>
                </Row>
              </div>
            );
          })}
        </Card.Body>
      </Card>
    );
  }

  renderEditContactModal = () => {
    return (
      <EditAgreementEditContactModal
        show={this.state.showEditContactModal}
        onHide={this.closeEditContactModal}
        phoneNumberTypes={this.state.phoneNumberTypes}
        states={this.state.states}
        countries={this.state.countries}
        contactType="contact"
        contactData={this.state.currentEditContact}
        agreementGuid={this.state.agreementData.agreementUniqueIdentifier}
      />
    );
  };

  renderSignaturesCard() {
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header className={globalStyles.cardTitles}>
          <h4 className={globalStyles.cardTitleText}>Signatures/Uploads</h4>
        </Card.Header>
        <Card.Body className="text-left">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>12A Signature</Form.Label>
                {this.state.agreement12aAttachment ? (
                  <Button
                    className={styles.PDFLinkButton}
                    variant="link"
                    onClick={() => this.handlePdfLinkClick(this.state.agreement12aAttachment)}
                  >
                    <span className={globalStyles.formDataLinks}>
                      {this.state.agreement12aAttachmentName ?? "12a Signature"}
                    </span>
                  </Button>
                ) : (
                  <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Cooperator Signature</Form.Label>
                {this.state.cooperatorSignatureAttachment ? (
                  <Button
                    className={styles.PDFLinkButton}
                    variant="link"
                    onClick={() => this.handlePdfLinkClick(this.state.cooperatorSignatureAttachment)}
                  >
                    <span className={globalStyles.formDataLinks}>Cooperator Signature</span>
                  </Button>
                ) : (
                  <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>WS Specialist Signature</Form.Label>
                {this.state.wsSpecialistSignatureAttachment ? (
                  <Button
                    className={styles.PDFLinkButton}
                    variant="link"
                    onClick={() => this.handlePdfLinkClick(this.state.wsSpecialistSignatureAttachment)}
                  >
                    <span className={globalStyles.formDataLinks}>WS Specialist Signature</span>
                  </Button>
                ) : (
                  <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Other Attachments</Form.Label>
                {this.state.otherAttachedFiles?.length > 0 ? (
                  this.state.otherAttachedFiles.map((file, index) => {
                    return (
                      <Row key={"otherAttachedFiles" + index}>
                        <Col>
                          <Button
                            className={styles.PDFLinkButton}
                            variant="link"
                            onClick={() =>
                              this.handleOtherAttachmentLinkClick(file?.agreementOtherAttachmentUniqueIdentifier)
                            }
                          >
                            <span className={globalStyles.formDataLinks}>{file?.attachmentName}</span>
                          </Button>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  render() {
    const { agreementData, cooperatorData } = this.state;

    const propertiesDataForParcelAssociation = this.state.propertiesData.filter(
      (p) => p.propertyUniqueIdentifier === this.state.currentProperty.propertyUniqueIdentifier
    );
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading Agreement Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.Step3}>
          {this.renderHeaderButtoms()}
          {this.renderAgreementInfoCard()}
          {this.renderFieldAgentCard()}
          {this.renderAgreementContactsCard()}
          {this.renderCooperatorInfoCard()}
          {this.renderProjectInfoCard()}
          <Card className={styles.viewPageCards}>
            <Card.Header as="h4" className={styles.cardTitles}>
              <span className={styles.cardTitleText}>Properties Summary</span>
            </Card.Header>
            <Card.Body>
              <Tabs variant="pills" onSelect={this.handlePropertySelection} className={styles.propertyTabsContainer}>
                {this.state.isAgreementEditable && this.state.agreementData?.agreementStatus?.id === 1
                  ? agreementData.agreementProperties?.map((property, index) => {
                    return this.getEditPropertyDetailsTab(property, index);
                  })
                  : agreementData.agreementProperties?.map((property, index) => {
                    return this.getPropertyTab(property, index);
                  })}
              </Tabs>
            </Card.Body>
          </Card>
          {this.renderProtectedResourcesCard()}
          {this.renderTargetedSpeciesCard()}
          {this.renderMethodsCard()}
          {this.renderSpecialInstructionsCard()}
          {this.renderRecCemsCard()}
          {this.renderHistoricalResourcesCard()}
          {this.renderPropertyContactsCard()}
          {this.renderSignaturesCard()}
          <ShowHistoricalLossModal
            show={this.state.showHistoricalLossModal}
            onHide={this.closeHistoricalLossViewModal}
            historicalResourceData={this.state.currentHistoricalRecord}
          />
          <PropertyParcelAssociationModal
            show={this.state.showSpeciesPropertyAssociationModal}
            onHide={this.closeSpeciesPropertyAssociationModal}
            properties={propertiesDataForParcelAssociation}
            checkedProperties={this.state.speciesPropertyParcelModalData.checkedProperties}
            onSaveAssociation={this.handleSpeciesPropertyParcelAssociation}
            entityType="species"
          />
          <PropertyParcelAssociationModal
            show={this.state.showMethodsPropertyAssociationModal}
            onHide={this.closeMethodsPropertyAssociationModal}
            properties={propertiesDataForParcelAssociation}
            checkedProperties={this.state.methodsPropertyParcelModalData.checkedProperties}
            onSaveAssociation={this.handleMethodsPropertyParcelAssociation}
            entityType="methods"
          />
          <PropertyParcelAssociationModal
            show={this.state.showResourcePropertyAssociationModal}
            onHide={this.closeResourcePropertyAssociationModal}
            properties={propertiesDataForParcelAssociation}
            checkedProperties={this.state.resourcePropertyParcelModalData.checkedProperties}
            onSaveAssociation={this.handleResourcePropertyParcelAssociation}
            entityType="protectedResource"
          />
          <PropertyParcelAssociationModal
            show={this.state.showRecCemPropertyAssociationModal}
            onHide={this.closeRecCemPropertyAssociationModal}
            properties={propertiesDataForParcelAssociation}
            checkedProperties={this.state.recCemPropertyParcelModalData.checkedProperties}
            onSaveAssociation={
              this.state.recCemPropertyParcelModalData.entityType === "recommendations"
                ? this.handleRecPropertyParcelAssociation
                : this.handleCemPropertyParcelAssociation
            }
            entityType={this.state.recCemPropertyParcelModalData.entityType}
          />
          <PrintAgreementModal
            show={this.state.showPrintAgreementModal}
            onHide={this.closePrintAgreementModal}
            isCurrentAgreement={true}
            agreementData={agreementData}
            cooperatorData={cooperatorData}
            primaryFieldAgentData={this.state.agreementData?.primaryFieldAgent}
            propertiesData={this.state.propertiesData}
            associatedContactsData={this.state.associatedPropertyContactsData}
            states={this.state.states}
            countries={this.state.countries}
          />
          {this.state.showEditContactModal ? this.renderEditContactModal() : null}
          <SuccessfulRecordModifiedModal
            show={this.state.showSuccessfulUpdateModal}
            onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
            routeData={{
              entityData: this.state.agreementData,
              isEntityDataCompleted: false,
              states: this.state.states,
              countries: this.state.countries,
            }}
            messageType="update"
            onAcknowledgeRoute="/agreement/details"
            objectName="Agreement"
          />
          <DeleteAcknowledgeModal
            show={this.state.showDeleteAgreementModal}
            onHide={() => this.setState({ showDeleteAgreementModal: false })}
            objectUniqueId={this.state.agreementData?.agreementUniqueIdentifier}
            onAcknowledgeRoute="/home"
            objectName="Agreement"
          />
          <ActionConfirmationModal
            show={this.state.showAmendAgreementConfirmationModal}
            onHide={() => this.setState({ showAmendAgreementConfirmationModal: false })}
            actionDescription="Amend/Renew this Agreement"
            onConfirmation={this.handleAgreementAmendOrRenew}
          />
          <AgreementAmendmentHistory
            show={this.state.showAgreementAmendmentHistory}
            onHide={() => this.setState({ showAgreementAmendmentHistory: false, agreementAmendmentData: "" })}
            agreementAmendmentData={this.state.agreementAmendmentData}
          />
          <RejectAgreementModal
            show={this.state.showAgreementRejectionModal}
            onHide={() => {
              this.setState({ showAgreementRejectionModal: false });
            }}
            onSave={this.handleAgreementRejection}
          />
          <FlagAgreementModal
            show={this.state.showAgreementFlagModal}
            onHide={() => {
              this.setState({ showAgreementFlagModal: false });
            }}
            onSave={this.handleFlagAgreement}
            flagHistory={this.state.flagHistory}
          />
          <AddHistoricalLossModal
            show={this.state.showAddHistoricalLossModal}
            onHide={this.closeHistoricalLossModal}
            properties={this.state.propertiesData}
            historicalResourceData={this.state.addHistoricalLossData}
            onSaveHistoricalLoss={this.handleNewHstoricalLoss}
            currentUsaState={this.state.currentUsaState}
          />
          <AddProjectModal
            show={this.state.showAddProjectModal}
            onHide={this.closeAddProjectModal}
            onSaveAssociation={this.handleProjectsAssociation}
          />
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(DisplayAgreementDetails);
