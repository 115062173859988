import API from "../API";
import { APIRoutes } from "../APIRoutes";

class ReferenceFileAPI {
  static async BanMethodsInStates(body) {
    let url = "/Method/BanMethodsInState";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async CreateMethod(body) {
    let url = "/Method/CreateMethod";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async CreateNewSpecies(body) {
    const api = new API(`/Species/CreateNewSpecies`, APIRoutes.ReferenceData);
    const result = await api.post(body);
    return result;
  }

  static async UpdateSpecies(body) {
    let url = "/Species/UpdateSpecies";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async UpdateMethod(body) {
    let url = "/Method/UpdateMethod";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async GetAllMethods(pageSize, pageNumber) {
    let url = "/Method/GetAllMethods/" + pageSize + "/" + pageNumber;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetFilteredMethodsTableData(
    type,
    subType,
    name,
    allowedUses,
    allowedUOMs,
    allowed,
    cmits,
    lastUpdatedDate,
    sortColumn,
    isAscending,
    stateUniqueReference,
    signal
  ) {
    let url = "/Method/GetFilteredGlobalMethodsTableData/";
    if (stateUniqueReference) {
      url = `/Method/GetFilteredStateMethodsTableData/${stateUniqueReference}`;
    }

    const api = new API(url, APIRoutes.ReferenceData);
    const body = {
      type: type,
      subType: subType,
      name: name,
      allowedUses: allowedUses,
      allowedUOMs: allowedUOMs,
      allowed: allowed,
      lastUpdatedDate: lastUpdatedDate,
      sortColumn: sortColumn,
      isAscending: isAscending,
      cmits: cmits,
    };

    return await api.put(body, [{ optionName: "signal", optionContent: signal }]);
  }

  static async GetMethod(methodUniqueIdentifier) {
    let url = "/Method/GetMethod/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetMethodForState(methodUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Method/GetMethodForState/" + methodUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllMethodsByState(stateID) {
    let url = "/Method/GetMethodsForState/" + stateID + "/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Method/GetMethodsForState/" + stateID + "/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async GetCMITSMethodsForState(stateID) {
    let url = `/Method/GetCMITSMethodsForState/${stateID}/0/0`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/Method/GetCMITSMethodsForState/${stateID}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async GetAllCMITSMethods() {
    let url = `/Method/GetAllCMITSMethods/0/0`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/Method/GetAllCMITSMethods/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async SetMethodActive(methodUniqueIdentifier, isActive) {
    let url = "/Method/SetMethodActive/" + methodUniqueIdentifier + "/" + isActive;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetMethodTypes() {
    let url = "/Method/GetMethodTypes/";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetMethodTypesEnum(signal) {
    const api = new API("/Method/GetMethodTypesEnum", APIRoutes.ReferenceData);
    return await api.get([{ optionName: "signal", optionContent: signal }]);
  }

  static async GetAllMethodUses() {
    let url = "/Method/GetAllMethodUses";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllMethodUOMs() {
    let url = "/Method/GetAllMethodUOMs";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetMethodSubTypes(type) {
    let url = "/Method/GetMethodSubTypes/" + encodeURIComponent(type);
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetMethodSubTypesEnum() {
    const api = new API("/Method/GetMethodSubTypesEnum", APIRoutes.ReferenceData);
    return await api.get();
  }

  static async GetMethodSubTypesEnumForType(typeEnumId) {
    const api = new API(`/Method/GetMethodSubTypesEnumForType/${typeEnumId}`, APIRoutes.ReferenceData);
    return await api.get();
  }

  static async GetAllMethodsByType(type) {
    let url = `/Method/GetAllMethodsByType/${encodeURIComponent(type)}/0/0`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/Method/GetAllMethodsByType/${encodeURIComponent(type)}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }

    return result;
  }

  static async GetSpecies(speciesUniqueIdentifier) {
    let url = "/Species/GetSpecies/" + speciesUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetStateSpecies(speciesUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Species/GetSpeciesForState/" + speciesUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllSpecies(pageSize, pageNumber) {
    let url = "/Species/GetAllSpecies/" + pageSize + "/" + pageNumber;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllSpeciesByState(stateID) {
    let url = "/Species/GetSpeciesForState/" + stateID + "/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Species/GetSpeciesForState/" + stateID + "/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async GetFilteredSpeciesTableData(
    speciesClass,
    order,
    family,
    category,
    name,
    allowedUOMs,
    allowed,
    lastUpdatedDate,
    sortColumn,
    isAscending,
    stateUniqueReference,
    signal
  ) {
    let url = "/Species/GetFilteredGlobalSpeciesTableData/";
    if (stateUniqueReference) {
      url = `/Species/GetFilteredStateSpeciesTableData/${stateUniqueReference}`;
    }

    const api = new API(url, APIRoutes.ReferenceData);
    const body = {
      class: speciesClass,
      order: order,
      family: family,
      category: category,
      name: name,
      allowedUOMs: allowedUOMs,
      allowed: allowed,
      lastUpdatedDate: lastUpdatedDate,
      sortColumn: sortColumn,
      isAscending: isAscending,
    };

    return await api.put(body, [{ optionName: "signal", optionContent: signal }]);
  }

  static async GetAllSpeciesUOMs() {
    let url = "/Species/GetAllSpeciesUOMs";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllSpeciesCategorizationInfo(stateID) {
    let url = "/Species/GetAllSpeciesCategorizationInfo/" + stateID;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetStateIDByName(stateName) {
    let usID = await ReferenceFileAPI.GetCountryIDByName("United States of America");
    let url = "/Geographic/GetStatesByCountry/" + usID + "/100/1";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    let results = result.data.results;
    let stateObj = results.find((state) => state.state_name === stateName);
    return stateObj.stateUniqueIdentifier;
  }

  static async GetCountryIDByName(countryName) {
    let url = "/Geographic/GetAllCountries/500/1";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    let results = result?.data?.results;
    let countryObj = results?.find((country) => country.country_name === countryName);
    return countryObj?.countryUniqueIdentifier;
  }

  static async GetUSA() {
    let url = "/Geographic/GetAllCountries/500/1";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    let results = result.data.results;
    let countryObj = results.find((country) => country.country_name === "United States of America");
    return countryObj;
  }

  static async GetAllCountries() {
    let url = "/Geographic/GetAllCountries/1000/1";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    let results = result?.data?.results;
    return results;
  }

  static async GetStateCodeByName(stateName) {
    let usID = await ReferenceFileAPI.GetCountryIDByName("United States of America");
    let url = "/Geographic/GetStatesByCountry/" + usID + "/100/1";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    let results = result.data.results;
    let stateObj = results.find((state) => state.state_name === stateName);
    return stateObj.state_code;
  }

  static async GetUSStates() {
    let usID = await ReferenceFileAPI.GetCountryIDByName("United States of America");
    let url = "/Geographic/GetStatesByCountry/" + usID + "/100/1";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    let results = result?.data?.results;
    return results;
  }

  static async GetStatesByCountry(countryId) {
    let results = "";
    if (countryId) {
      let url = "/Geographic/GetStatesByCountry/" + countryId + "/1000/1";
      let api = new API(url, APIRoutes.ReferenceData);
      let result = await api.get();
      results = result?.data?.results;
    }

    return results;
  }

  static async GetState(stateUniqueIdentifier) {
    let url = "/Geographic/GetState/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result?.data;
  }

  static async GetAllStates() {
    let url = "/Geographic/GetAllStates/true";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result?.data;
  }

  static async GetCountiesByState(stateUniqueIdentifier, signal) {
    let url = "/Geographic/GetCountiesByState/" + stateUniqueIdentifier + "/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();

    if (result?.data?.recordCount > 0) {
      url = "/Geographic/GetCountiesByState/" + stateUniqueIdentifier + "/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get([{ optionName: "signal", optionContent: signal }]);
    }
    return result?.data?.results;
  }

  static async CreateCountry(country) {
    let url = "/Geographic/CreateCountry";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(country);
    return result;
  }

  static async ActivateCountry(countryUniqueIdentifier) {
    let url = "/Geographic/ActivateCountry/" + countryUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put();
    return result;
  }

  static async DeactivateCountry(countryUniqueIdentifier) {
    let url = "/Geographic/DeactivateCountry/" + countryUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put();
    return result;
  }
  static async GetAllDepartmentOfDefenseCountries() {
    let url = "/Geographic/GetAllDepartmentOfDefenseCountries/1000/1";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetSpeciesTypes() {
    let url = "/Species/GetSpeciesTypes/";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetSpeciesSubTypes(type) {
    let url = "/Species/GetSpeciesSubTypes/" + encodeURIComponent(type);
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllSpeciesEndangeredStatuses() {
    let url = "/Species/GetAllSpeciesEndangeredStatuses";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async SetResourceSubType(resourceUniqueIdentifier, body) {
    let url = "/Resource/SetResourceSubType/" + resourceUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async DisallowSpeciesToBeTargettedInState(speciesUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Species/DisallowSpeciesToBeTargettedInState/" + speciesUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(null);
    return result;
  }

  static async AllowSpeciesToBeTargettedInState(speciesUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Species/AllowSpeciesToBeTargettedInState/" + speciesUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(null);
    return result;
  }

  static async DisallowMethodInState(body, methodUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Method/DisallowMethodInState/" + methodUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async AllowMethodInState(body, methodUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Method/AllowMethodInState/" + methodUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async AllowMethodGlobally(body, methodUniqueIdentifier) {
    let url = "/Method/AllowMethodGlobally/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async DisallowMethodGlobally(body, methodUniqueIdentifier) {
    let url = "/Method/DisallowMethodGlobally/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async SetGeoLocationOptionalInState(methodUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Method/SetGeoLocationOptionalInState/" + methodUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async SetGeoLocationRequiredInState(methodUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Method/SetGeoLocationRequiredInState/" + methodUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async SetGeoLocationRequiredGlobally(methodUniqueIdentifier) {
    let url = "/Method/SetGeoLocationRequiredGlobally/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async SetGeoLocationOptionalGlobally(methodUniqueIdentifier) {
    let url = "/Method/SetGeoLocationOptionalGlobally/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async SetMethodLabelToOptionalInState(methodUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Method/SetMethodLabelToOptionalInState/" + methodUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async SetMethodLabelToRequiredInState(methodUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Method/SetMethodLabelToRequiredInState/" + methodUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async SetMethodLabelToRequiredGlobally(methodUniqueIdentifier) {
    let url = "/Method/SetMethodLabelToRequiredGlobally/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async SetMethodLabelToOptionalGlobally(methodUniqueIdentifier) {
    let url = "/Method/SetMethodLabelToOptionalGlobally/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DeactivateSpecies(speciesUniqueIdentifier) {
    let url = "/Species/DeactivateSpecies/" + speciesUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.delete();
    return result;
  }

  static async CreateResource(resource) {
    let url = "/Resource/CreateResource";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(resource);
    return result;
  }

  static async GetResourceForState(resourceUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Resource/GetResourceForState/" + resourceUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetGlobalResource(resourceUniqueIdentifier) {
    let url = "/Resource/GetGlobalResource/" + resourceUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetResourceTypes() {
    let url = "/Resource/GetResourceTypes/";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetResourceTypesEnum(signal) {
    const api = new API("/Resource/GetResourceTypesEnum", APIRoutes.ReferenceData);
    return await api.get([{ optionName: "signal", optionContent: signal }]);
  }

  static async GetResourceSubTypes(type) {
    let url = "/Resource/GetResourceSubTypes/" + encodeURIComponent(type);
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetResourceSubTypesEnum() {
    const api = new API("/Resource/GetResourceTypesEnum", APIRoutes.ReferenceData);
    return await api.get();
  }

  static async GetResourceSubTypesEnumForType(typeEnumId) {
    const api = new API(`/Resource/GetResourceSubTypesEnumForType/${typeEnumId}`, APIRoutes.ReferenceData);
    return await api.get();
  }

  static async GetAllResourceUOMs(signal) {
    let url = "/Resource/GetAllResourceUOMs";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async UpdateResource(resourceUniqueIdentifier, body) {
    let url = "/Resource/UpdateResource/" + resourceUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async AddGlobalValuation(resourceUniqueIdentifier, valuationYear, value) {
    let url = `/Resource/AddGlobalValuation/${resourceUniqueIdentifier}/${valuationYear}/${value}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(null);
    return result;
  }

  static async RemoveGlobalValuation(resourceUniqueIdentifier, valuationYear) {
    let url = `/Resource/RemoveGlobalValuation/${resourceUniqueIdentifier}/${valuationYear}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(null);
    return result;
  }

  static async GetAllResources(pageSize, pageNumber) {
    let url = "/Resource/GetAllResources/" + pageSize + "/" + pageNumber;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetFilteredResourcesTableData(
    type,
    subType,
    name,
    allowedUOMs,
    allowed,
    lastUpdatedDate,
    sortColumn,
    isAscending,
    stateUniqueReference,
    signal
  ) {
    let url = "/Resource/GetFilteredGlobalResourcesTableData/";
    if (stateUniqueReference) {
      url = `/Resource/GetFilteredStateResourcesTableData/${stateUniqueReference}`;
    }

    const api = new API(url, APIRoutes.ReferenceData);
    const body = {
      type: type,
      subType: subType,
      name: name,
      allowedUOMs: allowedUOMs,
      allowed: allowed,
      lastUpdatedDate: lastUpdatedDate,
      sortColumn: sortColumn,
      isAscending: isAscending,
    };

    return await api.put(body, [{ optionName: "signal", optionContent: signal }]);
  }

  static async GetResourcesForState(stateID) {
    let url = "/Resource/GetResourcesForState/" + stateID + "/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Resource/GetResourcesForState/" + stateID + "/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async DisallowResourceForState(stateUniqueIdentifier, resourceUniqueIdentifier) {
    let url = "/Resource/DisallowResourceForState/" + resourceUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(null);
    return result;
  }

  static async AllowResourceForState(stateUniqueIdentifier, resourceUniqueIdentifier) {
    let url = "/Resource/AllowResourceForState/" + resourceUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(null);
    return result;
  }

  static async ActivateResourcesInStates(resourceUniqueIdentifier, statesArray) {
    let url = "/Resource/GetAllResources/" + resourceUniqueIdentifier + "/" + JSON.stringify(statesArray);
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async FilterResources(stateUniqueIdentifier, body) {
    let url = "/Resource/SearchResourcesFreeText/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async EnableResourceUOMsInState(resourceUniqueIdentifier, stateUniqueIdentifier, body) {
    let url = "/Resource/EnableResourceUOMsInState/" + resourceUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async DisableResourceUOMsInState(resourceUniqueIdentifier, stateUniqueIdentifier, body) {
    let url = "/Resource/DisableResourceUOMsInState/" + resourceUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async AddAllowedSpeciesUOMs(speciesUniqueIdentifier, body) {
    let url = "/Species/AddAllowedSpeciesUOMs/" + speciesUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async RemoveAllowedSpeciesUOMs(speciesUniqueIdentifier, body) {
    let url = "/Species/RemoveAllowedSpeciesUOMs/" + speciesUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async EnableSpeciesUOMsInState(speciesUniqueIdentifier, stateUniqueIdentifier, body) {
    let url = "/Species/EnableSpeciesUOMsInState/" + speciesUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async DisableSpeciesUOMsInState(speciesUniqueIdentifier, stateUniqueIdentifier, body) {
    let url = "/Species/DisableSpeciesUOMsInState/" + speciesUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async AddAdditionalResourceUOMs(resourceUniqueIdentifier, body) {
    let url = "/Resource/AddAdditionalResourceUOMs/" + resourceUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async RemoveAdditionalResourceUOMs(resourceUniqueIdentifier, body) {
    let url = "/Resource/RemoveAdditionalResourceUOMs/" + resourceUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async FilterSpecies(stateUniqueIdentifier, body) {
    let url = "/Species/SearchSpeciesFreeText/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async FilterMethods(stateUniqueIdentifier, body) {
    let url = "/Method/SearchMethodsFreeText/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async CreateDisease(disease) {
    let url = "/Disease/CreateDisease";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(disease);
    return result;
  }

  static async UpdateDisease(diseaseUniqueIdentifier, disease) {
    let url = "/Disease/UpdateDisease/" + diseaseUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(disease);
    return result;
  }

  static async AllowDiseaseForState(diseaseUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Disease/AllowDiseaseForState/" + diseaseUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowDiseaseForState(diseaseUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Disease/DisallowDiseaseForState/" + diseaseUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async AllowDiseaseGlobally(diseaseUniqueIdentifier) {
    let url = "/Disease/AllowDiseaseGlobally/" + diseaseUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowDiseaseGlobally(diseaseUniqueIdentifier) {
    let url = "/Disease/DisallowDiseaseGlobally/" + diseaseUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async GetDiseasesForState(stateUniqueIdentifier) {
    let url = "/Disease/GetDiseasesForState/" + stateUniqueIdentifier + "/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Disease/GetDiseasesForState/" + stateUniqueIdentifier + "/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async GetAllDiseases() {
    let url = "/Disease/GetAllDiseases/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Disease/GetAllDiseases/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async CreateDamage(damage) {
    let url = "/Damage/CreateDamage";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(damage);
    return result;
  }

  static async UpdateDamage(damageUniqueIdentifier, damage) {
    let url = "/Damage/UpdateDamage/" + damageUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(damage);
    return result;
  }

  static async AllowDamageForState(damageUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Damage/AllowDamageForState/" + stateUniqueIdentifier + "/" + damageUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowDamageForState(damageUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Damage/DisallowDamageForState/" + stateUniqueIdentifier + "/" + damageUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async AllowDamageGlobally(damageUniqueIdentifier) {
    let url = "/Damage/AllowDamageGlobally/" + damageUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowDamageGlobally(damageUniqueIdentifier) {
    let url = "/Damage/DisallowDamageGlobally/" + damageUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async GetDamagesForState(stateUniqueIdentifier) {
    let url = "/Damage/GetDamagesForState/" + stateUniqueIdentifier + "/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Damage/GetDamagesForState/" + stateUniqueIdentifier + "/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async GetAllDamages() {
    let url = "/Damage/GetAllDamages/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Damage/GetAllDamages/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async CreateFate(fate) {
    let url = "/Fate/CreateFate";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(fate);
    return result;
  }

  static async UpdateFate(fateUniqueIdentifier, fate) {
    let url = "/Fate/UpdateFate/" + fateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(fate);
    return result;
  }

  static async AllowFateForState(fateUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Fate/AllowFateForState/" + fateUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowFateForState(fateUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Fate/DisallowFateForState/" + fateUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async AllowFateGlobally(fateUniqueIdentifier) {
    let url = "/Fate/AllowFateGlobally/" + fateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowFateGlobally(fateUniqueIdentifier) {
    let url = "/Fate/DisallowFateGlobally/" + fateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async GetAllFates() {
    let url = "/Fate/GetAllFates/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Fate/GetAllFates/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async CreateTopic(topic) {
    let url = "/Topic/CreateTopic";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(topic);
    return result;
  }

  static async UpdateTopic(topicUniqueIdentifier, topic) {
    let url = "/Topic/UpdateTopic/" + topicUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(topic);
    return result;
  }

  static async AllowTopicInState(topicUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Topic/AllowTopicInState/" + topicUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowTopicInState(topicUniqueIdentifier, stateUniqueIdentifier) {
    let url = "/Topic/DisallowTopicInState/" + topicUniqueIdentifier + "/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async AllowTopicGlobally(topicUniqueIdentifier) {
    let url = "/Topic/AllowTopicGlobally/" + topicUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowTopicGlobally(topicUniqueIdentifier) {
    let url = "/Topic/DisallowTopicGlobally/" + topicUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async GetTopicsForState(stateUniqueIdentifier) {
    let url = "/Topic/GetTopicsForState/" + stateUniqueIdentifier + "/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Topic/GetTopicsForState/" + stateUniqueIdentifier + "/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async GetAllTopics() {
    let url = "/Topic/GetAllTopics/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/Topic/GetAllTopics/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async GetAllTechnicalAssistActivitiesForTaType(technicalAssistActivityTypeEnumId) {
    let url = "/TechnicalAssistActivity/GetAllTechnicalAssistActivitiesForTaType/" + technicalAssistActivityTypeEnumId;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllExternalOrganizationsInState(stateUniqueReference) {
    let url = "/ExternalOrganization/GetAllExternalOrganizationsInState/" + stateUniqueReference + "/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url =
        "/ExternalOrganization/GetAllExternalOrganizationsInState/" +
        stateUniqueReference +
        "/" +
        result?.data?.recordCount +
        "/" +
        1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async UpdateCountry(countryUniqueIdentifier, country) {
    let url = "/Country/UpdateCountry/" + countryUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(country);
    return result;
  }

  static async AddAllowedMethodUses(methodUniqueIdentifier, body) {
    let url = "/Method/AddAllowedMethodUses/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async RemoveAllowedMethodUses(methodUniqueIdentifier, body) {
    let url = "/Method/RemoveAllowedMethodUses/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async AddAllowedMethodUOMs(methodUniqueIdentifier, body) {
    let url = "/Method/AddAllowedMethodUOMs/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async RemoveAllowedMethodUOMs(methodUniqueIdentifier, body) {
    let url = "/Method/RemoveAllowedMethodUOMs/" + methodUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async EnableMethodUsesInState(methodUniqueIdentifier, stateUniqueIdentifier, body) {
    let url = `/Method/EnableMethodUsesInState/${methodUniqueIdentifier}/${stateUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async DisableMethodUsesInState(methodUniqueIdentifier, stateUniqueIdentifier, body) {
    let url = `/Method/DisableMethodUsesInState/${methodUniqueIdentifier}/${stateUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async EnableMethodUOMsInState(methodUniqueIdentifier, stateUniqueIdentifier, body) {
    let url = `/Method/EnableMethodUOMsInState/${methodUniqueIdentifier}/${stateUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async DisableMethodUOMsInState(methodUniqueIdentifier, stateUniqueIdentifier, body) {
    let url = `/Method/DisableMethodUOMsInState/${methodUniqueIdentifier}/${stateUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async ResolveMethods(methodGuids) {
    const api = new API(`/Method/ResolveMethods`, APIRoutes.ReferenceData);
    const result = await api.put(methodGuids);
    return result;
  }

  static async ResolveResources(resourceGuids) {
    const api = new API(`/Resource/ResolveResources`, APIRoutes.ReferenceData);
    const result = await api.put(resourceGuids);
    return result;
  }

  static async ResolveSpecies(speciesGuids) {
    const api = new API(`/Species/ResolveSpecies`, APIRoutes.ReferenceData);
    const result = await api.put(speciesGuids);
    return result;
  }

  static async GetTechnicalAssistTypes() {
    let url = "/TechnicalAssistActivity/GetTechnicalAssistTypes";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async CreateTechnicalAssistActivity(body) {
    let url = "/TechnicalAssistActivity/CreateTechnicalAssistActivity";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async GetAllTechnicalAssistActivities() {
    let url = "/TechnicalAssistActivity/GetAllTechnicalAssistActivities/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/TechnicalAssistActivity/GetAllTechnicalAssistActivities/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async EditTechnicalAssistActivity(taActivityUniqueIdentifier, body) {
    let url = `/TechnicalAssistActivity/EditTechnicalAssistActivity/${taActivityUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async GetAllAircraftType() {
    let url = "/Aircraft/GetAllAircraftType";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async CreateAircraft(body) {
    let url = "/Aircraft/CreateAircraft";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async GetAircraftsByState(stateUniqueIdentifier) {
    let url = `/Aircraft/GetAircraftByState/${stateUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAircraft(aircraftUniqueIdentifier) {
    let url = `/Aircraft/GetAircraft/${aircraftUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async UpdateAircraft(aircraftUniqueIdentifier, body) {
    let url = `/Aircraft/UpdateAircraft/${aircraftUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }
  static async GetAllAdminActivities() {
    let url = "/AdminActivity/GetAllAdminActivities/0/0";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = "/AdminActivity/GetAllAdminActivities/" + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async CreateAdminActivity(body) {
    let url = "/AdminActivity/CreateAdminActivity";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async GetAdminActivitiesForState(stateUniqueIdentifier) {
    let url = "/AdminActivity/GetAdminActivitiesForState/" + stateUniqueIdentifier + "/1000/1";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url =
        "/AdminActivity/GetAdminActivitiesForState/" +
        stateUniqueIdentifier +
        "/" +
        result?.data?.recordCount +
        "/" +
        1;
      result = await api.get();
    }
    return result;
  }

  static async GetAllRecCems(stateUniqueIdentifier) {
    let url = `/RecCem/GetAllRecCems/${stateUniqueIdentifier}/0/0`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/RecCem/GetAllRecCems/${stateUniqueIdentifier}/` + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async GetRecCems() {
    let url = `/RecCem/GetRecCems/0/0`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/RecCem/GetRecCems/` + result?.data?.recordCount + "/" + 1;
      api = new API(url, APIRoutes.ReferenceData);
      result = await api.get();
    }
    return result;
  }

  static async UpdateAdminActivity(adminActivityUniqueIdentifier, body) {
    let url = `/AdminActivity/EditAdminActivity/${adminActivityUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async EnableAdminActivityInState(adminActivityUniqueIdentifier, stateUniqueIdentifier) {
    let url = `/AdminActivity/EnableAdminActivityInState/${adminActivityUniqueIdentifier}/${stateUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisableAdminActivityInState(adminActivityUniqueIdentifier, stateUniqueIdentifier) {
    let url = `/AdminActivity/DisableAdminActivityInState/${adminActivityUniqueIdentifier}/${stateUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async CreateRecCem(body) {
    let url = "/RecCem/CreateRecCem";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }
  static async UpdateRecCem(recCemUniqueIdentifier, body) {
    let url = `/RecCem/UpdateRecCem/${recCemUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async AllowRecCemGlobally(recCemUniqueIdentifier) {
    let url = `/RecCem/AllowRecCemGlobally/${recCemUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisallowRecCemGlobally(recCemUniqueIdentifier) {
    let url = `/RecCem/DisallowRecCemGlobally/${recCemUniqueIdentifier}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async AllowRecCemInStates(recCemUniqueIdentifier, stateUniqueIdentifier) {
    const api = new API(`/RecCem/AllowRecCemInStates/${recCemUniqueIdentifier}`, APIRoutes.ReferenceData);
    const result = await api.post([stateUniqueIdentifier]);
    return result;
  }

  static async DisallowRecCemInStates(recCemUniqueIdentifier, stateUniqueIdentifier) {
    const api = new API(`/RecCem/DisallowRecCemInStates/${recCemUniqueIdentifier}`, APIRoutes.ReferenceData);
    const result = await api.post([stateUniqueIdentifier]);
    return result;
  }

  static async CreateExternalOrganization(body) {
    let url = "/ExternalOrganization/CreateExternalOrganization";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async UpdateExternalOrganization(externalOrganizationUniqueId, body) {
    let url = `/ExternalOrganization/UpdateExternalOrganization/${externalOrganizationUniqueId}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async EnableExternalOrganization(externalOrganizationUniqueId) {
    let url = `/ExternalOrganization/EnableExternalOrganization/${externalOrganizationUniqueId}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async DisableExternalOrganization(externalOrganizationUniqueId) {
    let url = `/ExternalOrganization/DisableExternalOrganization/${externalOrganizationUniqueId}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post();
    return result;
  }

  static async SpeciesStructuredSearch(body) {
    let url = "/Species/SpeciesStructuredSearch";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.put(body);
    return result;
  }

  static async GetAllResourceValuationSettings(signal) {
    const api = new API("/Resource/GetAllResourceValuationSettings", APIRoutes.ReferenceData);
    const result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async SetGlobalResourceValuationSetting(resourceUniqueIdentifier, settingEnumId) {
    const api = new API(
      `/Resource/SetGlobalResourceValuationSetting/${resourceUniqueIdentifier}/${settingEnumId}`,
      APIRoutes.ReferenceData
    );
    const result = await api.post();
    return result;
  }

  static async SetStateResourceValuationSetting(resourceUniqueIdentifier, stateUniqueIdentifier, settingEnumId) {
    const api = new API(
      `/Resource/SetStateResourceValuationSetting/${resourceUniqueIdentifier}/${stateUniqueIdentifier}/${settingEnumId}`,
      APIRoutes.ReferenceData
    );
    const result = await api.post();
    return result;
  }

  static async OverrideGlobalValuationForState(resourceUniqueIdentifier, stateUniqueIdentifier, valuationYear, value) {
    const api = new API(
      `/Resource/OverrideGlobalValuationForState/${resourceUniqueIdentifier}/${stateUniqueIdentifier}/${valuationYear}/${value}`,
      APIRoutes.ReferenceData
    );
    const result = await api.put();
    return result;
  }

  static async RemoveGlobalValuationOverrideForState(resourceUniqueIdentifier, stateUniqueIdentifier, valuationYear) {
    const api = new API(
      `/Resource/RemoveGlobalValuationOverrideForState/${resourceUniqueIdentifier}/${stateUniqueIdentifier}/${valuationYear}`,
      APIRoutes.ReferenceData
    );
    const result = await api.put();
    return result;
  }

  static async CreateIntermediateFate(body) {
    let url = "/IntermediateFate/CreateIntermediateFate";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async UpdateIntermediateFate(fateUniqueIdentifier, body) {
    let url = "/IntermediateFate/UpdateIntermediateFate/" + fateUniqueIdentifier;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async GetIntermediateFatesForState(stateId) {
    let url = `/IntermediateFate/GetIntermediateFatesForState/${stateId}`;
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllIntermediateFates() {
    let url = "/IntermediateFate/GetAllIntermediateFates";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async GetAllStatesForIntermediateFates() {
    let url = "/IntermediateFate/GetAllStatesForIntermediateFates";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.get();
    return result;
  }

  static async AllowIntermediateFateInStates(body) {
    let url = "/IntermediateFate/AllowIntermediateFateInStates";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }

  static async DisableIntermediateFatesForStates(body) {
    let url = "/IntermediateFate/DisableIntermediateFatesForStates";
    let api = new API(url, APIRoutes.ReferenceData);
    let result = await api.post(body);
    return result;
  }
}

export default ReferenceFileAPI;
