const PermissionName = {
  entity: {
    referenceData: "referencedata",
    contact: "contact",
    specialGroup: "specialgroup",
    agreement: "agreement",
    property: "property",
    conflict: "conflict",
    pvs: "pvs",
    report: "report",
    query: "query",
    inventory: "inventory",
    user: "user",
    role: "role",
    permits: "permits",
    specialpermissions: "specialpermissions",
    taWorkTask: "ta",
    aerialWorkTask: "aerialwork",
    adminWork: "adminwork",
    project: "project",
    district: "district",
    warehouse: "warehouse",
    rabies: "rabies",
    samples: "samples",
    notification: "notification",
  },
  right: {
    create: "create",
    edit: "edit",
    editAny: "editany",
    editMine: "editmine",
    view: "view",
    viewMine: "viewmine",
    viewAny: "viewany",
    configure: "configure",
    flag: "flag",
    assign: "assign",
    editAssignments: "editassignments",
    viewAssignments: "viewassignments",
    approve: "approve",
    deactivate: "deactivate",
    delete: "delete",
    assignuser: "assignuser",
    assignSupervisor: "assignsupervisor",
    assignAdditionalSupervisors: "assignadditionalsupervisors",
    assignEmployees: "assignemployees",
    extendFLA: "extendfla",
    manageinventory: "manageinventory",
    reconcileinventory: "reconcileinventory",
    worktaskinteraction: "worktaskinteraction",
  },
  scope: {
    global: "global",
    assignedStates: "assignedstates",
    anyState: "anystate",
    all: "all",
    performedBy: "performedby",
    onAgreement: "onagreement",
    assignedProjects: "assignedprojects",
    specialPermissions: "specialpermissions",
    self: "self",
    supervisorWithinDistrict: "supervisorwithindistrict",
    districtSupervisor: "districtsupervisor",
    assignedDistricts: "assigneddistricts",
    district: "district",
    assignedWarehouse: "assignedwarehouse",
  },
};
export default PermissionName;
