import React, { Fragment } from "react";
import { withRouter } from "react-router";
import styles from "./Properties.module.scss";
import globalStyles from "../../../OARS.module.scss";
import CustomForm from "../../common/form";
import { Card, Row, Col, Form, Button, Image } from "react-bootstrap";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../../api/AgreementEntities/AgreementAPI";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import DuplicatedPropertyModal from "./duplicatedPropertyModal";
import SuccessfulRecordModifiedModal from "../../common/create-updateOkMessageModal";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import GeoCoding from "../../common/GeoCoding";
import ParcelMap from "../../ESRIIntegration/ParcelMap";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import missingmap from "../../../assets/map_not_available.png";
import ConflictingAgreementParcelModal from "./ConflictingAgreementParcelModal";

class CreateParcelPropertyForm extends CustomForm {
  state = {
    data: {
      propertyType: "Parcel",
      landClass: 0,
      district: "",
      county: "",
      countyName: "",
      mapPoint: "",
      propertyName: "",
      acres: "",
      calculatedAcres: "",
      primaryCooperator: "",
      streetAddress: "",
      city: "",
      zipCode: "",
      state: "",
      stateID: "",
      country: "",
      latitude: "", //39.52566,
      longitude: "", //-77.62544,
      specialInstructions: "",
      countryName: "",
    },
    errors: {},
    validated: false,
    landClasses: [],
    counties: [],
    states: [],
    countries: [],
    showDuplicatedPropertyModal: false,
    showSuccessfullCreateModal: false,
    duplicatedRecordData: "",
    createMethodBody: "",
    currentUsaState: "",
    showMap: false,
    updateAccessibilityTags: true,
    latitudeForMapFind: "",
    longitudeForMapFind: "",
    addressStringForMapFind: "",
    hasMapRendered: false,
    doesMapNeedRerender: true,
    isParcelSelected: false,
    parcelData: [],
    parcelDataArray: [],
    propertyOwner: "",
    routeNavigationData: "",
    conflictingAgreementUniqueIdentifiers: [],
    showConflictingAgreementParcelModal: false,
    dismissedConflictingAgreementParcelModal: false,
  };

  async componentDidMount() {
    let { data } = this.state;
    data.propertyType = "Parcel";

    const countries = await this.getAllCountriesFromAPI();
    const usaObject = countries.find((country) => country.name === "United States of America");
    const states = await this.getAvailableStates(usaObject.id);

    const landClasses = await this.getLandClasses();
    let counties = "";
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (currentUsaState?.stateGuid) {
      counties = await this.getCountyListbyState(currentUsaState.stateGuid);
    }
    data.countryName = usaObject.name;
    data.country = usaObject.id;
    data.state = currentUsaState.stateName;

    this.setState({
      landClasses,
      counties,
      countries,
      states,
      data,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.doesMapNeedRerender) {
      this.setState({ doesMapNeedRerender: false });
    }
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  async getContactTypeId(contactType) {
    const contactTypesData = await AgreementEntitiesAPI.GetContactTypes();

    let contactTypeId = "";
    if (contactTypesData.data) contactTypeId = contactTypesData.data.find((element) => element.name === contactType).id;

    return contactTypeId;
  }

  async getCooperatorsFromAPI(searchValue) {
    const contactsData = await AgreementEntitiesAPI.SearchContactFreeText({
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    });
    let cooperators = [];

    if (contactsData?.data?.results?.length > 0) {
      let results = contactsData.data.results;
      cooperators = results.map((result) => ({
        value: result.contactUniqueId,
        label: result.firstName.concat(" ", result.middleName, " ", result.lastName),
      }));
    }

    return cooperators;
  }

  async getLandClasses() {
    const landClasses = await AgreementEntitiesAPI.GetAllPropertyLandClasses();
    if (!landClasses) return [];
    return landClasses.data;
  }

  async getCountyListbyState(stateGuid) {
    const counties = await ReferenceFileAPI.GetCountiesByState(stateGuid);
    let countyList = [];
    if (counties) {
      countyList = counties
        .map((county) => ({
          id: county.countyUniqueIdentifier,
          name: county.county_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    return countyList;
  }

  setParcelProperty() {
    const { data } = this.state;
    data.propertyType = "Parcel";
    this.setState({ data });
  }

  setNonParcelProperty() {
    const { data } = this.state;
    data.propertyType = "Non-Parcel";
    this.setState({ data });
  }

  handleSpecificChanges(input) {
    if (input.name === "acres") {
      let { data } = this.state;
      data.acres = input.value;
      this.setState({ data });
    }
    if (
      !this.state.data.streetAddress &&
      !this.state.data.city &&
      !this.state.data.state &&
      !this.state.data.country &&
      !this.state.data.longitude &&
      !this.state.data.latitude
    ) {
      this.setState({ hasMapRendered: false });
    }
  }

  async doSubmit(e) {
    const { data, states, countries, errors } = this.state;
    let {
      duplicatedRecordData,
      showDuplicatedPropertyModal,
      showSuccessfullCreateModal,
      routeNavigationData,
      showConflictingAgreementParcelModal,
    } = this.state;
    e.preventDefault();

    if (e.nativeEvent.submitter.name !== "formSubmit") {
      e.stopPropagation();
      return;
    } else {
      if (errors.propertyName) {
        toast.warning("Please address all the errors before submitting the form");
        return;
      }

      if (
        data.propertyName !== "" &&
        data.acres !== "" &&
        data.county !== undefined &&
        this.state.parcelData?.length > 0
      ) {
        this.setState({ validated: true });
      } else {
        if (!this.state.parcelData) {
          toast.warning("Parcel selection is required to create Property with Parcels");
        } else {
          toast.warning("All required fields must be completed, Acres, County and Property Name are required");
        }
        return;
      }
    }

    let propertyAddress = this.getPropertyAddress();
    let location = await GeoCoding.getLocationByAddress(propertyAddress);
    if ((!data.latitude || !data.longitude) && location.length > 0) {
      data.latitude = location[0];
      data.longitude = location[1];
    }
    const messageBody = this.getMessageBody(data);
    const duplicateResponse = await AgreementEntitiesAPI.FindDuplicateProperties(messageBody, 1);
    const parcelAgreementCheck = await this.canTheseParcelsMakeUpANewAgreement();

    if (!parcelAgreementCheck && !this.state.dismissedConflictingAgreementParcelModal) {
      showConflictingAgreementParcelModal = true;
    } else if (duplicateResponse?.data && duplicateResponse?.data?.length > 0) {
      duplicatedRecordData = this.getDuplicatedRecordData(duplicateResponse.data[0], countries, states);
      showDuplicatedPropertyModal = true;
    } else {
      const createResponse = await AgreementEntitiesAPI.CreateProperty(messageBody);
      if (createResponse?.successful) {
        const parcelAdditionSuccess = this.addParceltoProperty(createResponse?.data?.propertyUniqueIdentifier);
        if (parcelAdditionSuccess) {
          showSuccessfullCreateModal = true;
          routeNavigationData = { entityData: createResponse.data, states };
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to add parcels",
            apiName: "addParceltoProperty",
            responseUnsuccessful: parcelAdditionSuccess?.unsuccessful,
            responseMessage: parcelAdditionSuccess?.message,
          });
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create property",
          apiName: "CreateProperty",
          responseUnsuccessful: createResponse?.unsuccessful,
          responseMessage: createResponse?.message,
        });
      }
    }

    this.setState({
      createMethodBody: messageBody,
      duplicatedRecordData,
      showDuplicatedPropertyModal,
      routeNavigationData,
      showSuccessfullCreateModal,
      showConflictingAgreementParcelModal,
    });
  }

  async canTheseParcelsMakeUpANewAgreement() {
    let result = true;
    const esriParcelIds = this.state.parcelData.map((x) => x.acctid);
    if (esriParcelIds.length > 0) {
      const parcelAgreementCheckAction = await AgreementAPI.CanTheseParcelsMakeUpANewAgreement(esriParcelIds);
      if (parcelAgreementCheckAction.successful) {
        result = parcelAgreementCheckAction.data.canTheseParcelsMakeANewAgreement;
        this.setState({
          conflictingAgreementUniqueIdentifiers: parcelAgreementCheckAction.data.conflictingAgreementIdentifiers,
        });
      }
    }

    return result;
  }

  getPropertyAddress() {
    let propertyAddress = "";

    if (this.state.data.state && this.state.data.streetAddress && this.state.data.city && this.state.data.zipCode) {
      let stateName = this.state.states.find((state) => state.id === this.state.data.state)?.name;
      propertyAddress = this.state.data.streetAddress.concat(
        ", ",
        this.state.data.city,
        ", ",
        stateName ? stateName : "",
        ", ",
        this.state.data.zipCode
      );
    }

    return propertyAddress;
  }

  getDuplicatedRecordData(duplicatedRecord, countries, states) {
    duplicatedRecord.countryName = countries.find(
      (country) => country.id === duplicatedRecord.countryUniqueIdentifier
    ).name;
    duplicatedRecord.stateName = states.find((state) => state.id === duplicatedRecord.state).name;

    return duplicatedRecord;
  }

  async addParceltoProperty(propertyID) {
    //lanclass need to be captured

    let parcels = [];

    for (let i = 0; i < this.state.parcelData.length; i++) {
      const parcel = this.state.parcelData[i];
      let acres = 0;
      if (parcel.acres) {
        acres = parseInt(parcel.acres, 10);
      }
      let Owner = parcel.OwnerFirstName + " " + parcel.OwnerLastName;
      let parcelBody = {
        propertyUniqueIdentifier: propertyID,
        landClassEnumId: 8,
        acres: acres,
        district: "",
        parcelOwner: Owner,
        parcelId: parcel.acctid,
      };
      parcels.push(parcelBody);
    }

    const createResponse = await AgreementEntitiesAPI.CreateParcels(parcels);

    return createResponse?.successful;
  }

  getMessageBody(data) {
    let county = "";
    if (data.county) county = data.county;
    return {
      propertyName: data.propertyName,
      propertyAbbreviation: "",
      landClassEnumId: 8,
      //parcel id = 1
      propertyTypeEnumId: 1,
      acres: parseFloat(data.acres, 10),
      district: "",
      county: county,
      state: data.stateID,
      specialInstructions: "",
      latitude: this.state.data.latitude ? parseFloat(this.state.data.latitude) : null,
      longitude: this.state.data.longitude ? parseFloat(this.state.data.longitude) : null,
      parcelCount: 0,
      defaultCooperatorUniqueIdentifier: data?.primaryCooperator?.value,
      countryUniqueIdentifier: data.country,
      additionalStreetAddresses: [
        {
          streetAddress: this.state.data.streetAddress,
          city: this.state.data.city,
          zipCode: this.state.data.zipCode,
        },
      ],
    };
  }

  async updateAvailableStates(countryId) {
    const states = await this.getAvailableStates(countryId);
    if (states) {
      this.setState({ states });
    }
  }

  async updateCountiesByState(stateId) {
    const counties = await this.getCountyListbyState(stateId);
    if (counties) this.setState({ counties });
  }

  async getAvailableStates(countryId) {
    const results = await ReferenceFileAPI.GetStatesByCountry(countryId);
    let returnStates = [];

    if (results) {
      returnStates = results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
          ...state,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return returnStates;
  }

  handleCooperatorSearch = async (inputValue) => {
    return await this.getCooperatorsFromAPI(inputValue);
  };

  handleCooperatorChange = (input) => {
    const { data } = this.state;
    data.primaryCooperator = input;

    this.setState({ data });
    return input;
  };

  closeDuplicatedPropertyModal = () => {
    this.setState({ showDuplicatedPropertyModal: false });
  };

  handleForceCreation = () => {
    this.setState({ showSuccessfullCreateModal: true });
  };

  closeSuccessfulCreateModal = () => {
    this.setState({ showSuccessfullCreateModal: false });
  };

  closeConflictingAgreementParcelModal = () => {
    this.setState({ showConflictingAgreementParcelModal: false, dismissedConflictingAgreementParcelModal: true });
  };

  mapFind = async () => {
    let lat = parseFloat(this.state.data.latitude);
    let long = parseFloat(this.state.data.longitude);
    let propertyAddress = this.getPropertyAddress();
    if (lat && long) {
      this.setState({
        latitudeForMapFind: lat,
        longitudeForMapFind: long,
        addressStringForMapFind: "",
        hasMapRendered: true,
        doesMapNeedRerender: true,
      });
    } else if (propertyAddress) {
      let mapPointFromAddress = await GeoCoding.getLocationByAddress(propertyAddress);
      let data = this.state.data;
      data.latitude = mapPointFromAddress[0];
      data.longitude = mapPointFromAddress[1];
      this.setState({
        addressStringForMapFind: propertyAddress,
        latitudeForMapFind: mapPointFromAddress[0],
        longitudeForMapFind: mapPointFromAddress[1],
        data,
        hasMapRendered: true,
        doesMapNeedRerender: true,
      });
    } else {
      toast.error("Please provide either a full address or a latitude and longitude pair to find a map point.");
    }
  };

  handlePropertyAddition = async (parcelData) => {
    const { data } = this.state;
    let Owner = parcelData.OwnerFirstName + " " + parcelData.OwnerLastName;
    let parcels = this.state.parcelData;
    let totalArea = 0;
    if (parcels.length > 0) {
      let parcel = parcels.find((parcelObj) => parcelObj.acctid === parcelData.acctid);

      if (!parcel) {
        parcels.push(parcelData);
      }
      parcels.forEach((parcelObj2) => {
        if (parcelObj2.acres) {
          totalArea += parcelObj2.acres ? parcelObj2.acres : "";
        }
      });
    } else {
      parcels.push(parcelData);
      totalArea = parcelData.acres;
    }
    if (totalArea > 0) {
      data.calculatedAcres = totalArea;
    }
    this.setState({ parcelData: parcels, isParcelSelected: true, propertyOwner: Owner, data });
  };
  handleStateChange = ({ currentTarget: input }) => {
    let propertyName = input.id;
    let data = this.state;
    data[propertyName] = input.value;
    this.setState({ data });
  };
  handleCityChange = (input) => {
    const { data } = this.state;
    data.city = input.value;
    this.setState({ data });
    return input;
  };
  handleCountyChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.countyName = input.value;
    data.county = input.value;
    this.setState({ data });
  };

  handleRemoveParcel = (parcel, index) => {
    const { data } = this.state;

    let parcels = this.state.parcelData;

    parcels.splice(index, 1);
    data.calculatedAcres = data.calculatedAcres - parcel.acres;
    this.setState({ parcelData: parcels, data });
  };

  handleParcelAssociation = (e, parcel) => {
    const { data } = this.state;
    let stateID = this.getStateGuid(parcel.state);
    if (e.target.checked) {
      data.city = parcel.city;
      data.state = parcel.state;
      data.stateID = stateID;
      data.zipCode = parcel.zip;
      data.streetAddress = parcel.streetAddress;
      data.propertyOwner = parcel.OwnerFirstName + " " + parcel.OwnerLastName;
      data.longitude = parcel.longitude;
      data.latitude = parcel.latitude;
      data.county = parcel.county.toUpperCase();
    } else {
      data.city = "";
      data.state = "";
      data.zipCode = "";
      data.streetAddress = "";
      data.acres = "";
      data.calculatedAcres = "";
      data.propertyOwner = "";
      data.longitude = "";
      data.latitude = "";
      data.county = "";
    }

    this.setState({ data });
  };

  getStateGuid = (state_code) => {
    let stateGuid = this.state.states.find((state) => state.name === state_code)?.id;
    return stateGuid;
  };

  renderSelectedParcels() {
    if (!this.state.isParcelSelected) return;

    return (
      <div>
        <Row>
          <h5>Parcel Information</h5>
        </Row>
        <Row>
          <Col sm={2} className="pl-0">
            <span className={globalStyles.formLabel}>Primary Parcel</span>
          </Col>
          <Col sm={2} className="pl-0">
            <span className={globalStyles.formLabel}>Parcel ID</span>
          </Col>
          <Col sm={3}>
            <span className={globalStyles.formLabel}>Parcel Owner</span>
          </Col>
          <Col sm={3}>
            <span className={globalStyles.formLabel}>Parcel Acreage</span>
          </Col>
        </Row>
        {this.state.parcelData?.map((parcel, index) => {
          let owner = parcel.OwnerFirstName + " " + parcel.OwnerLastName;
          return (
            <React.Fragment key={index}>
              <Row>
                <Col sm={2} className="pl-0">
                  <Form.Check type="radio" name="parcel" onChange={(e) => this.handleParcelAssociation(e, parcel)} />
                </Col>
                <Col sm={2} className="pl-0">
                  <span className={globalStyles.formData}>{parcel.acctid}</span>
                </Col>
                <Col sm={3}>
                  <span className={globalStyles.formData}>{owner}</span>
                </Col>
                <Col sm={3}>
                  <span className={globalStyles.formData}>{parcel.acres}</span>
                </Col>
                <Col align="left">
                  <span>
                    <i
                      className="fa fa-times-circle-o"
                      style={{ cursor: "pointer" }}
                      aria-hidden="true"
                      onClick={(e) => this.handleRemoveParcel(parcel, index)}
                    ></i>
                  </span>
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
  showParcelMap() {
    if (sessionStorage.getItem("mapAccess") === "Yes") {
      return <ParcelMap onAddProperty={this.handlePropertyAddition} onRemoveParcel={this.handleRemoveParcel} />;
    } else {
      let map = (
        <div className={styles.mapContainer}>
          {" "}
          <Image src={missingmap} alt="missing map" className={styles.mapMissing} />
        </div>
      );
      return map;
    }
  }
  render() {
    const { data } = this.state;

    return (
      <div>
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} data-testid="propertyForm">
          <div className="container w-75">
            <h1 className={styles.createPropertyHeader}>Create Property</h1>

            <Card className="mb-4 mt-2">
              <Card.Header className={styles.propertyInfoHeader}>
                <h5>Parcel Search</h5>
              </Card.Header>
              <Card.Body>
                <div className="mx-2">
                  <Row>
                    <br></br>
                  </Row>
                  <Row className="mx-0" id="search-map-container">
                    <Col className="px-0">
                      <div className={styles.mapContainer}>{this.showParcelMap()}</div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <br></br>
                </Row>

                {this.renderSelectedParcels()}
                <Row>
                  <br></br>
                </Row>
                <Row>
                  <Col></Col>
                  <Col lg={3}>
                    {this.renderInput("acres", "Total Acreage", "number", "Enter area in acres", "required")}
                  </Col>
                  <Col lg={3}>
                    {this.renderInput(
                      "calculatedAcres",
                      "Total Acreage (Calculated)",
                      "text",
                      data.calculatedAcres,
                      "readOnly"
                    )}
                  </Col>
                </Row>

                <Row className={styles.greenBottomLine} />
                <Row>
                  <br></br>
                </Row>
                <Row>
                  {" "}
                  <h5>Property Information</h5>
                </Row>
                <Row>
                  <Col lg={12}>
                    <h6 className={styles.fieldLabels}>Street Address</h6>
                    <Form.Control
                      id="streetAddress"
                      aria-label="Steet Address Input Form"
                      defaultValue={data.streetAddress}
                      type="text"
                      onChange={this.handleStateChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <h6 className={styles.fieldLabels}> City</h6>
                    <Form.Control
                      name="city"
                      defaultValue={data.city}
                      placeholder="Select City"
                      onChange={this.handleCityChange}
                    />
                  </Col>
                  <Col lg={3}>
                    <Form.Label className="formLabel">
                      County<span className="required"> *</span>
                    </Form.Label>

                    <Form.Control
                      data-testid="county"
                      aria-label="county Select"
                      as="select"
                      type="text"
                      onChange={this.handleCountyChange}
                      value={data.county}
                      className={styles.editFields}
                    >
                      <option>Select County</option>
                      {this.state.counties.map((county) => (
                        <option value={county.name} key={county.id}>
                          {county.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col lg={3}>{this.renderInput("state", "State", "text", "State", ["required", "readOnly"])}</Col>
                  <Col lg={3}>{this.renderInput("zipCode", "Zip Code", "text", "Enter Zip")}</Col>
                </Row>

                <Row xs={1} md={2}>
                  <Col lg={3} id="countryCol">
                    {this.renderInput("countryName", "Country", "text", "Country", ["required", "readOnly"])}
                  </Col>
                  <Col lg={3}>
                    {this.renderInput(
                      "latitude",
                      "Latitude",
                      "number",
                      "Enter Latitude",
                      data.propertyType === "Non-Parcel" ? "required" : ""
                    )}
                  </Col>
                  <Col lg={3}>
                    {this.renderInput(
                      "longitude",
                      "Longitude",
                      "number",
                      "Enter Longitude",
                      data.propertyType === "Non-Parcel" ? "required" : ""
                    )}
                  </Col>

                  <Col lg={3}>
                    <h6 className={styles.fieldLabels}> Property Owner</h6>
                    <Form.Control
                      name="propertyOwner"
                      aria-label="Property Owner Input"
                      value={data.propertyOwner}
                      placeholder="Select Property Owner"
                      onChange={this.handleCityChange}
                    />
                  </Col>
                </Row>

                <div className="mx-2">
                  <Row xs={1} md={2}>
                    <Col>{this.renderInput("propertyName", "Property Name", "text", "Enter name", "required")}</Col>
                    <Col className="text-left">
                      <Form.Label className={styles.fieldLabels}>Primary Cooperator</Form.Label>
                      <Fragment>
                        <AsyncSelect
                          aria-label="Primary Cooperator Select"
                          openMenuOnClick={false}
                          placeholder="Enter name"
                          loadOptions={this.handleCooperatorSearch}
                          onChange={this.handleCooperatorChange}
                        />
                      </Fragment>
                    </Col>
                  </Row>

                  <Row xs={1} md={2}>
                    <Col></Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>

            <Row className="text-right">
              <Col>
                <span className={styles.createPropertyCancelButton}>{this.renderButton("Cancel", "cancel")}</span>
                <Button className={styles.submitFormButton} name="formSubmit" variant="primary" type="submit">
                  Create
                </Button>
              </Col>
            </Row>
          </div>
          <DuplicatedPropertyModal
            show={this.state.showDuplicatedPropertyModal}
            onHide={this.closeDuplicatedPropertyModal}
            duplicatedRecordData={this.state.duplicatedRecordData}
            createMethodBody={this.state.createMethodBody}
            onForceCreation={this.handleForceCreation}
          />
          <SuccessfulRecordModifiedModal
            show={this.state.showSuccessfullCreateModal}
            onHide={this.closeSuccessfulCreateModal}
            messageType="create"
            onAcknowledgeRoute="/property/details"
            routeData={this.state.routeNavigationData}
            objectName="Parcel Property"
          />
        </Form>
        <ConflictingAgreementParcelModal
          show={this.state.showConflictingAgreementParcelModal}
          onHide={this.closeConflictingAgreementParcelModal}
          usedOnAgreementSignature={false}
          conflictingAgreementUniqueIdentifiers={this.state.conflictingAgreementUniqueIdentifiers}
        />
      </div>
    );
  }
}

export default withRouter(CreateParcelPropertyForm);
