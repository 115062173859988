import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import { components } from "react-select";
import { toast } from "react-toastify";
import CustomForm from "../../../common/form";
import globalStyles from "../../../../OARS.module.scss";
import styles from "./PropertyVisitSummary.module.scss";
import SuccessfulRecordModifiedModal from "../../../common/create-updateOkMessageModal";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import searchIcon from "../../../../assets/search.svg";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import UtilityFunctions from "../../../common/UtilityFunctions";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import RoleAuthorizationAPI from "../../../../api/RoleAuthorization/RoleAuthorizationAPI";
import AgreementEntitiesAPI from "../../../../api/AgreementEntities/AgreementEntitiesAPI";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

class OtherSampleDetailsPage extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        dateTaken: "",
        employeeName: "",
        selectedProperty: "",
        selectedSpecies: "",
        locationType: "",
        latitude: "",
        longitude: "",
        isFromTake: "",
        samples: [],
      },
      locationTypes: [],
      sampleTypes: [],
      diseases: [],
      organizations: [],
      states: props.history.location.state?.states.map((state) => {
        state.id = state.stateUniqueIdentifier || state.id;
        state.name = state.state_name || state.name;
        return state;
      }),
      countries: props.history.location.state?.countries,
      currentUsaState: "",
      otherSampleData: "",
      isFormLoading: true,
      isEditMode: false,
      showSuccessfulUpdateModal: false,
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
    }

    const organizations = await this.getExternalOrganizations(currentUsaState?.stateGuid);
    const otherSampleData = await this.getOtherSampleDataFromAPI();
    const data = otherSampleData
      ? await this.updatePageData({ ...otherSampleData }, organizations)
      : { ...this.state.data };

    this.setState({
      data,
      otherSampleData,
      locationTypes: await this.getLocationTypes(),
      sampleTypes: await this.getSampleTypes(),
      diseases: await this.getDiseasesFromAPI(currentUsaState?.stateGuid),
      organizations,
      isFormLoading: false,
      currentUsaState,
    });
  }

  async getOtherSampleDataFromAPI() {
    let otherSampleData = "";
    const entityData = this.props.history.location.state?.entityData;
    if (entityData?.otherSampleUniqueIdentifier) {
      const getOtherSampleResponse = await WorkTaskAPI.GetOtherSample(entityData.otherSampleUniqueIdentifier);
      if (getOtherSampleResponse?.successful) {
        otherSampleData = getOtherSampleResponse.data;
      } else {
        ErrorHandler.showErrorWithMessage(
          getOtherSampleResponse?.message ? getOtherSampleResponse.message : "Sample Data could not be retrieved"
        );
      }
    }

    return otherSampleData;
  }

  async getLocationTypes() {
    let locationTypes = [];
    const locationTypesResponse = await WorkTaskAPI.GetLocationTypeOptions();
    if (locationTypesResponse?.successful && locationTypesResponse.data?.length > 0) {
      locationTypes = locationTypesResponse.data.map((location) => ({
        id: location.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(location),
      }));
    }

    return locationTypes;
  }

  async getSampleTypes() {
    let availablesampleTypes = [];
    const sampleTypesResponse = await WorkTaskAPI.GetSampleTypeOptions();
    if (sampleTypesResponse?.successful && sampleTypesResponse.data?.length > 0) {
      availablesampleTypes = sampleTypesResponse.data.map((sampleType) => ({
        value: sampleType.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(sampleType),
      }));
    }

    return availablesampleTypes;
  }

  async getDiseasesFromAPI(stateId) {
    let diseases = [];

    if (stateId) {
      const diseasesResponse = await ReferenceFileAPI.GetDiseasesForState(stateId);
      if (diseasesResponse?.successful && diseasesResponse.data?.results?.length > 0) {
        diseases = diseasesResponse.data.results
          .filter((d) => d?.isAllowed)
          ?.map((result) => ({
            value: result.diseaseUniqueIdentifier,
            label: result.name,
          }));
      }
    }
    return diseases;
  }

  async getExternalOrganizations(stateId) {
    let organizations = [];

    if (stateId) {
      const organizationsResponse = await ReferenceFileAPI.GetAllExternalOrganizationsInState(stateId);
      if (organizationsResponse?.successful && organizationsResponse.data?.results?.length > 0) {
        organizations = organizationsResponse.data.results
          .filter((o) => o?.isAllowedInState)
          ?.map((org) => ({
            value: org.externalOrganizationUniqueIdentifier,
            label: org.externalOrganizationName,
          }));
      }
    }
    return organizations;
  }

  async updatePageData(otherSampleData, organizations) {
    const data = { ...this.state.data };

    if (!data.dateTaken) {
      data.isFromTake = otherSampleData.isFromTake;
      data.dateTaken = otherSampleData.dateTaken
        ? new Date(otherSampleData.dateTaken).toLocaleDateString("en-CA", { timeZone: "UTC" })
        : "";
      data.employeeName = {
        label: otherSampleData.userFullName,
        profileName: otherSampleData.profileName,
        value: otherSampleData.wsUserProfileUniqueReference,
      };
      data.selectedSpecies = { value: otherSampleData.speciesUniqueReference, label: otherSampleData.speciesName };

      // Temp code to get property Name. Will be modified once BE team implements a pending change
      if (otherSampleData.propertyUniqueReference) {
        const propertyResponse = await AgreementEntitiesAPI.GetProperty(otherSampleData.propertyUniqueReference);
        if (propertyResponse?.successful) {
          data.selectedProperty = {
            value: propertyResponse.data?.propertyUniqueIdentifier,
            label: propertyResponse.data?.propertyName,
          };
        }
      }
    }
    data.latitude = otherSampleData.latitude || "";
    data.longitude = otherSampleData.longitude || "";
    data.locationType = otherSampleData.locationType?.id;
    data.samples = otherSampleData.samples?.map((s) => {
      const organizationObject = organizations.find((o) => o.value === s.destinationExternalOrgUniqueReference);
      const destination = organizationObject ? organizationObject : "";
      return {
        quantity: s.quantity,
        disease: { value: s.diseaseUniqueReference, label: s.diseaseName },
        type: { value: s.sampleType?.id, label: UtilityFunctions.getDisplayTextFromFieldObject(s.sampleType) },
        destination,
      };
    });

    return data;
  }

  handleSpecificChanges = () => {
    // do something
  };

  handleCheckboxChanges = ({ currentTarget: input }) => {
    const { data } = this.state;
    data[input.name] = !data[input.name];
    this.setState({ data });
  };

  handleSelectChanges = (e, fieldName) => {
    const { data } = this.state;
    data[fieldName] = e;
    this.setState({ data });
  };

  handleWsSearch = async (inputValue) => {
    let profilesResults = [];

    if (this.state.currentUsaState?.stateGuid) {
      const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText({
        textToSearchFor: inputValue,
        pageSize: 25,
        pageNumber: 1,
        stateUniqueReference: this.state.currentUsaState.stateGuid,
      });
      if (contactsData?.data?.results?.length > 0) {
        let results = contactsData.data.results;
        for (let user of contactsData.data.results) {
          let userObject;
          for (let j = 0; j < user.userProfiles.length; j++) {
            let userProfile = user.userProfiles[j];
            if (userProfile) {
              userObject = {
                value: userProfile.wsUserProfileUniqueIdentifier,
                label: user.firstName + " " + user.lastName,
                profileName: userProfile.profileName,
                wsUserUniqueIdentifier: user.wsUserUniqueIdentifier,
              };
              profilesResults.push(userObject);
            }
          }
        }
      }
    }
    return profilesResults;
  };

  handleSpeciesSearch = async (searchValue) => {
    let speciesResult = [];

    if (this.state.currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(this.state.currentUsaState.stateGuid, {
        textToSearchFor: searchValue,
        pageSize: 10,
        pageNumber: 1,
      });
      if (speciesData?.data?.results?.length > 0) {
        speciesResult = speciesData.data.results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return speciesResult;
  };

  handlePropertiesSearch = async (inputValue) => {
    let propertiesResult = [];
    if (this.state.currentUsaState?.stateGuid) {
      const getPropertiesResponse = await AgreementEntitiesAPI.SearchPropertiesFreeText(
        this.getSearchPropertiesBody(inputValue),
        this.state.currentUsaState.stateGuid
      );
      if (!getPropertiesResponse.successful) {
        ErrorHandler.showErrorWithMessage(
          getPropertiesResponse?.message ? getPropertiesResponse.message : "Properties data could not be retrieved."
        );
      } else if (getPropertiesResponse.data?.results?.length > 0) {
        propertiesResult = getPropertiesResponse.data.results.map((result) => ({
          value: result.propertyUniqueIdentifier,
          label: result.propertyName,
        }));
      }
    }

    return propertiesResult;
  };

  getSearchPropertiesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 0,
    };
  }

  handleSamplesChanges = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    const { data } = this.state;
    data.samples[index][fieldName] = input.value;

    this.setState({ data });
  };

  handleSamplesDiseaseChanges = (input, index) => {
    const { data } = this.state;
    data.samples[index]["disease"] = input;
    this.setState({ data });
  };

  handleSamplesTypeChanges = (input, index) => {
    const { data } = this.state;
    data.samples[index]["type"] = input;
    this.setState({ data });
  };

  handleSamplesDestinationChanges = (input, index) => {
    const { data } = this.state;
    data.samples[index]["destination"] = input;
    this.setState({ data });
  };

  handleEditSamples = async () => {
    if (!this.state.isEditMode) {
      const { data } = this.state;

      if (data.samples.length === 0) {
        data.samples = [{ quantity: "", disease: "", type: "", destination: "" }];
      }
      this.setState({ data, isEditMode: true });
    } else {
      this.setState({ isFormLoading: true });
      let { otherSampleData } = this.state;
      if (!this.isFormDataValid()) {
        toast.warning("Please fill out all the required fields.");
      } else if (otherSampleData?.otherSampleUniqueIdentifier) {
        const editOtherResponse = await WorkTaskAPI.EditOtherSample(
          otherSampleData.otherSampleUniqueIdentifier,
          this.saveOtherSample()
        );

        if (editOtherResponse?.successful) {
          otherSampleData = editOtherResponse.data;

          const data = otherSampleData
            ? await this.updatePageData({ ...otherSampleData }, this.state.organizations)
            : { ...this.state.data };

          this.setState({ data, otherSampleData, showSuccessfulUpdateModal: true, isEditMode: false });
        } else if (editOtherResponse?.message) {
          ErrorHandler.showError(editOtherResponse.message);
        } else {
          ErrorHandler.showError("Sample update failed. Contact system admin for more details.");
        }
        this.setState({ showSuccessfulUpdateModal: true, isEditMode: false });
      }
      this.setState({ isFormLoading: false });
    }
  };

  saveOtherSample = () => {
    //create Other sample

    const { data } = this.state;

    let otherSampleData = {
      latitude: data.latitude?.toString(),
      longitude: data.longitude?.toString(),
      locationTypeEnumId: data.locationType,
      samples: data.samples.map((s) => ({
        quantity: Number.parseInt(s.quantity),
        sampleTypeEnumId: s.type.value,
        diseaseUniqueReference: s.disease.value,
        destinationExternalOrgUniqueReference: s.destination.value,
      })),
    };

    return otherSampleData;
  };

  handleDeleteSample = (index) => {
    const { data } = this.state;
    data.samples.splice(index, 1);
    this.setState({ data });
  };

  handleAddAnotherSample = (e, i) => {
    const { data } = this.state;
    let sampleRow = { quantity: 0, disease: "", type: "", destination: "" };
    let sampleRows = data.samples;
    sampleRows.push(sampleRow);
    data.samples = sampleRows;

    this.setState({
      data,
    });
  };

  isFormDataValid() {
    const { data } = this.state;

    return !data.samples.some((sample) => !sample.quantity || !sample.disease || !sample.type || !sample.destination);
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  renderPageHeader() {
    return (
      <Row className="mt-4">
        <Col className="pl-0">
          <h1 className={globalStyles.pageTitle}>Other Samples Record Page</h1>
        </Col>
        {
          <Col className="text-right pr-0">
            {this.props.permissions.canIEditSamplesAssignedStates() && (
              <Button variant="primary" onClick={this.handleEditSamples}>
                <span className={globalStyles.modalSubmitButtonText}>
                  {this.state.isEditMode ? "Apply Changes" : "Edit"}
                </span>
              </Button>
            )}
          </Col>
        }
      </Row>
    );
  }

  renderGeneralInfoSession() {
    const { data, isEditMode } = this.state;
    return (
      <Row className="mt-4">
        <Col className="px-0">
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Check type="checkbox" id="WSTakeCheckbox" inline>
                    <Form.Check.Input
                      className="mr-2"
                      name="isFromTake"
                      type="checkbox"
                      value={data.isFromTake}
                      onChange={this.handleCheckboxChanges}
                      checked={data.isFromTake}
                      disabled={!isEditMode}
                    />
                    <Form.Check.Label>
                      <span className={globalStyles.formLabel}>WS Take</span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Date Taken</Form.Label>
                    <Form.Text className={globalStyles.formData}>{data.dateTaken}</Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Employee Name</Form.Label>
                    <Form.Text className={globalStyles.formData}>{data.employeeName?.label}</Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      Damage Agent{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                    </Form.Label>
                    <Form.Text className={globalStyles.formData}>{data.selectedSpecies?.label}</Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Property Name</Form.Label>
                    <Form.Text className={globalStyles.formData}>{data.selectedProperty?.label}</Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Latitude</Form.Label>
                    {isEditMode ? (
                      <Form.Control
                        name="latitude"
                        placeholder="Enter latitude"
                        type="number"
                        step="0.00001"
                        className={globalStyles.formData}
                        value={data.latitude}
                        onChange={this.handleChange}
                      />
                    ) : (
                      <Form.Text className={globalStyles.formData}>{data.latitude || "N/A"}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Longitude</Form.Label>
                    {isEditMode ? (
                      <Form.Control
                        name="longitude"
                        placeholder="Enter longitude"
                        type="number"
                        step="0.00001"
                        className={globalStyles.formData}
                        value={data.longitude}
                        onChange={this.handleChange}
                      />
                    ) : (
                      <Form.Text className={globalStyles.formData}>{data.longitude || "N/A"}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  {this.renderEditableField(
                    isEditMode,
                    "locationType",
                    "Location Type",
                    data.locationType,
                    "select",
                    this.state.locationTypes
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderSamplesSession() {
    const { data, isEditMode } = this.state;
    return (
      <Row className="mt-4">
        <Col className="px-0">
          <Card>
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Samples</span>
            </Card.Header>
            <Card.Body>
              {data.samples?.length > 0 ? (
                data.samples?.map((sample, index) => {
                  return (
                    <Row key={index}>
                      <Col>
                        <Form.Group controlId={`quantity-${index}`}>
                          <Form.Label className={globalStyles.formLabel}>Quantity</Form.Label>
                          {isEditMode ? (
                            <Form.Control
                              type="number"
                              placeholder="Enter Qty."
                              value={sample.quantity}
                              onChange={this.handleSamplesChanges}
                            />
                          ) : (
                            <Form.Text className={globalStyles.formData}>{sample.quantity}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label className={globalStyles.formLabel}>
                            Disease{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                          </Form.Label>
                          {isEditMode ? (
                            <Select
                              aria-label="Sample Disease Selection"
                              name="disease"
                              value={sample.disease}
                              placeholder="Select"
                              styles={this.customSelectStyles}
                              onChange={(e) => this.handleSamplesDiseaseChanges(e, index)}
                              options={this.state.diseases}
                            />
                          ) : (
                            <Form.Text className={globalStyles.formData}>{sample.disease?.label}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label className={globalStyles.formLabel}>
                            Sample Type{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                          </Form.Label>
                          {isEditMode ? (
                            <Select
                              aria-label="Sample Type Selection"
                              name="type"
                              value={sample.type}
                              placeholder="Select"
                              styles={this.customSelectStyles}
                              onChange={(e) => this.handleSamplesTypeChanges(e, index)}
                              options={this.state.sampleTypes}
                            />
                          ) : (
                            <Form.Text className={globalStyles.formData}>{sample.type?.label}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label className={globalStyles.formLabel}>
                            Destination{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                          </Form.Label>
                          {isEditMode ? (
                            <Select
                              aria-label="Sample Destination Selection"
                              name="type"
                              value={sample.destination}
                              placeholder="Select"
                              styles={this.customSelectStyles}
                              onChange={(e) => this.handleSamplesDestinationChanges(e, index)}
                              options={this.state.organizations}
                            />
                          ) : (
                            <Form.Text className={globalStyles.formData}>{sample.destination?.label}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      {isEditMode && (
                        <React.Fragment>
                          {data.samples?.length > 1 && (
                            <Col sm={1} className="pt-4">
                              <i
                                className="fa fa-times-circle-o pt-3"
                                aria-hidden="true"
                                onClick={() => this.handleDeleteSample(index)}
                              ></i>
                            </Col>
                          )}
                          <Col md={2} className="pt-4">
                            {data.samples.length === index + 1 && (
                              <Button
                                variant="link"
                                className={globalStyles.formDataLinks}
                                onClick={(e) => this.handleAddAnotherSample(e)}
                              >
                                + Add another sample
                              </Button>
                            )}
                          </Col>
                        </React.Fragment>
                      )}
                    </Row>
                  );
                })
              ) : (
                <Row className="text-center">
                  <Col>
                    <span className={globalStyles.formData}>There are no samples available</span>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading Samples Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.samplesRecordPage}>
          <Form className="text-left" onSubmit={this.handleSubmit}>
            {this.renderPageHeader()}
            {this.renderGeneralInfoSession()}
            {this.renderSamplesSession()}
          </Form>
        </div>
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfulUpdateModal}
          onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
          routeData=""
          messageType="update"
          objectName="Sample"
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(OtherSampleDetailsPage);
