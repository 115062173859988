import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import { toast } from "react-toastify";

class CreateEditRECCEMModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: this.props?.modalData
        ? { ...this.props.modalData }
        : {
            recCemUniqueIdentifier: "",
            recCemName: "",
            globalStatus: true,
            stateStatus: true,
            createdDateTimeUTC: "",
            lastModifiedDateTimeUTC: "",
          },
      errors: {},
    };
  }

  submitModal = () => {
    if (!this.isFormDataValid()) {
      toast.warning("Please provide a valid Name.");
      return;
    }

    const { data } = this.state;
    this.props.onSubmit({ ...data }, this.state.modalMode);
    this.handleModalCancel();
  };

  isFormDataValid() {
    const { recCemName } = this.state.data;
    return recCemName ? true : false;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.recCemName = "";
    this.setState({ data });
  }

  handleStatusChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isAllowedGlobally = input.id === "active" ? true : false;
    this.setState({ data });
  };

  createRecCemModalUI() {
    return (
      <div className={styles.createDamageModalBody}>
        <Row>
          <Col>{this.renderInput("recCemName", "RecCem Name", "text", "Enter name", "required")}</Col>
        </Row>
      </div>
    );
  }

  editRecCemModalUI() {
    const { data } = this.state;
    return (
      <div className={styles.editDamageModalBody}>
        <Row>
          <Col className={styles.editDamageModalDamageName}>
            {this.renderInput("recCemName", "REC/CEM Name", "text", "Enter name", "required")}
          </Col>
        </Row>
        <Row>
          <Col className={styles.editDamageModalStatus}>
            <Form.Group>
              <Form.Check
                type="radio"
                name="isAllowedGlobally"
                inline
                label="Active"
                id="active"
                onChange={this.handleStatusChange}
                defaultChecked={data.isAllowedGlobally}
              />
              <Form.Check
                type="radio"
                name="isAllowedGlobally"
                inline
                label="Inactive"
                id="inactive"
                onChange={this.handleStatusChange}
                defaultChecked={!data.isAllowedGlobally}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editDamageModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editDamageModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createRecCemModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editRecCemModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} RecCem</Modal.Title>
          </Modal.Header>
          <Modal.Body>{bodyContent}</Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button type="submit" className={globalStyles.ModalCreateButton} onClick={this.submitModal}>
              <span className={globalStyles.ModalCreateButtonText}>{submitButtonText}</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CreateEditRECCEMModal;

