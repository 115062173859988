import React from 'react';
import styles from './NotFoundPage.module.scss';

const NotFoundPage = () => (
  <div className={styles.NotFoundPage} data-testid="NotFoundPage">
    404 - Not Found
  </div>
);

export default NotFoundPage;
