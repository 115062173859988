import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import globalStyles from "../../OARS.module.scss";
import styles from "./common.scss";

const CustomSelect = ({
  name,
  label,
  options,
  error,
  isRequired = false,
  isClearable = false,
  isDisabled = false,
  placeHolder,
  value,
  ...rest
}) => {
  const selectOptions = options.map((option) => {
    let display = option.name;
    if (option.displayText) {
      display = option.displayText;
    }
    return { value: option.id, label: display };
  });

  const selectedValue = selectOptions.find((o) => o.value === value);
  return (
    <Form.Group className="text-left">
      <Form.Label className="formLabel">
        {label}
        <span className="required">{isRequired ? " *" : ""}</span>
      </Form.Label>
      {isDisabled ? (
        <Form.Control
          type="text"
          defaultValue={selectedValue ? selectedValue.label : ""}
          className={globalStyles.formData}
          readOnly
        />
      ) : (
        <Select
          aria-label={label}
          name={name}
          id={name}
          placeholder={placeHolder}
          value={selectedValue ? selectedValue : ""}
          isClearable={isClearable}
          className={error ? "is-invalid" : ""}
          {...rest}
          styles={{
            indicatorSeparator: () => {
              //do nothing
            },
            placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
            option: (styles, { isFocused }) => ({
              ...styles,
              fontSize: "14px",
              fontWeight: "normal",
              color: "black",
              backgroundColor: isFocused ? "lightgray" : "white",
            }),
            singleValue: (styles) => ({
              ...styles,
              fontSize: "14px",
              fontWeight: "normal",
              color: "black",
            }),
            menuList: (styles) => ({ ...styles, textAlign: "left" }),
            control: (styles) => ({ ...styles, borderColor: error ? "#dc3545" : "#ced4da" }),
          }}
          options={selectOptions}
        />
      )}
      <span className="invalid-feedback">{error}</span>
    </Form.Group>
  );
};

export default CustomSelect;

