import React, { Fragment } from "react";
import CustomForm from "../common/form";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { Modal, Button, Row, Col, Form, Card, Tabs, Tab, Nav } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Addresses from "./contactAddresses";
import ContactOrgAddressDisplay from "../common/ContactOrgAddressDisplay";
import PhoneNumber from "./contactModalPhoneNumber";
import PhoneNumberDisplay from "../common/PhoneNumberDisplay";
import DuplicateContactModal from "../AgreementEntities/Contact/DuplicateContactModal";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import { toast } from "react-toastify";
import UtilityFunctions from "../common/UtilityFunctions";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class AddContactModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        selectedContact: "",
        contactRoleEnumId: "",
        contactType: "",
        typeaheadOptions: [],
        typeaheadIsLoading: false,
        firstName: "",
        middleName: "",
        lastName: "",
        suffix: "",
        preferredName: "",
        emailAddress: "",
        phoneNumbers: [
          {
            phoneNumber: "",
            phoneTypeEnumId: "1",
          },
        ],
        addresses: [
          {
            streetAddress: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
        ],
        firstNameEdit: "",
        middleNameEdit: "",
        lastNameEdit: "",
        suffixEdit: "",
        preferredNameEdit: "",
        emailAddressEdit: "",
        phoneNumbersEdit: [],
        addressesEdit: [],
      },
      errors: {},
      states: "",
      phoneNumberTypes: [],
      showDuplicateModal: false,
      duplicateModalData: "",
      duplicateModalCreateData: "",
      contactProperties: [],
      contactOrganizations: [],
      showHappyModal: false,
      updatedContacts: [],
      isUserEditing: false,
      modalMode: "create",
    };
  }

  handleContactSearch = async (inputValue) => {
    return await this.getContactsFromAPI(inputValue);
  };

  handleContactChange = async (input) => {
    let { data } = this.state;
    if (!this.isDuplicate(input[0])) {
      data.selectedContact = input[0];
      data.typeaheadIsLoading = false;
      data.isContactSelected = true;
      if (data.selectedContact) {
        const getFullContactObj = await AgreementEntitiesAPI.GetContact(data.selectedContact.value);
        data = this.populateEditContactObjectData(data, getFullContactObj?.data);
      } else {
        data.firstNameEdit = "";
        data.middleNameEdit = "";
        data.lastNameEdit = "";
        data.suffixEdit = "";
        data.preferredNameEdit = "";
        data.emailAddressEdit = "";
        data.phoneNumbersEdit = [];
        data.addressesEdit = [];
      }

      this.setState({ data });
    } else {
      toast.warning("Selected contact is already associated");
    }

    return input;
  };

  populateEditContactObjectData(data, fullEditContactObj) {
    data.firstNameEdit = fullEditContactObj.firstName;
    data.middleNameEdit = fullEditContactObj.middleName;
    data.lastNameEdit = fullEditContactObj.lastName;
    data.suffixEdit = fullEditContactObj.suffix;
    data.preferredNameEdit = fullEditContactObj.preferredName;
    data.emailAddressEdit = fullEditContactObj.emailAddress;
    data.phoneNumbersEdit = fullEditContactObj.phoneNumbers?.map((phoneNumber) => {
      phoneNumber.phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber);
      return phoneNumber;
    });
    let allAddressArray = fullEditContactObj.otherAddresses;
    if (fullEditContactObj.primaryMailingAddress) {
      allAddressArray.unshift(fullEditContactObj.primaryMailingAddress);
    }
    if (allAddressArray.length === 0) {
      allAddressArray.push({
        streetAddress: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      });
    }
    if (data.phoneNumbersEdit.length === 0) {
      data.phoneNumbersEdit.push({
        phoneNumber: "",
        phoneTypeEnumId: "1",
      });
    }
    data.addressesEdit = allAddressArray;
    return data;
  }

  async getContactsFromAPI(searchValue) {
    const contactsData = await AgreementEntitiesAPI.SearchContactFreeText(this.getFilterContactBody(searchValue));
    this.setState({ typeaheadIsLoading: true });
    let outputContacts = [];
    if (contactsData.data.results) {
      let results = contactsData.data.results;
      outputContacts = results.map((result) => ({
        value: result.contactUniqueId,
        label: result.firstName.concat(" ", result.middleName, " ", result.lastName),
        phoneNumbers: result.phoneNumbers?.map((phoneNumber) => {
          phoneNumber.phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber);
          return phoneNumber;
        }),
        primaryMailingAddress: result.primaryMailingAddress,
        firstName: result.firstName,
        middleName: result.middleName,
        lastName: result.lastName,
        emailAddress: result.emailAddress,
      }));
      this.setState({ typeaheadOptions: outputContacts });

      this.setState({ typeaheadIsLoading: false });
    }

    return outputContacts;
  }

  formatMenu = (option) => {
    let phoneNumber = "No Phone Number";
    let address = "No Address";
    if (option.phoneNumbers.length > 0) {
      phoneNumber = UtilityFunctions.formatPhoneNumber(option.phoneNumbers[0].phoneNumber);
    }
    if (option.primaryMailingAddress) {
      if (
        option.primaryMailingAddress.mailingStreet ||
        option.primaryMailingAddress.mailingCity ||
        option.primaryMailingAddress.mailingZip
      ) {
        let state = "";
        let states = this.props.states;
        for (let i = 0; i < this.props.states.length; i++) {
          if (states[i].id === option.primaryMailingAddress.mailingStateUniqueId) {
            state = states[i].name;
          }
        }
        address =
          option.primaryMailingAddress.mailingStreet +
          ", " +
          option.primaryMailingAddress.mailingCity +
          " " +
          state +
          " " +
          option.primaryMailingAddress.mailingZip;
      }
    }
    return (
      <Fragment>
        <Row>
          <Col style={{ fontWeight: "600", fontSize: "12px" }}>{option.label}</Col>
        </Row>
        <Row>
          <Col style={{ fontSize: "12px" }}>{phoneNumber}</Col>
        </Row>
        <Row>
          <Col style={{ fontSize: "12px" }}>{option.emailAddress ? option.emailAddress : "No Email"}</Col>
        </Row>
        <Row className={styles.informationRow}>
          <Col style={{ fontSize: "12px" }}>{address}</Col>
        </Row>
      </Fragment>
    );
  };

  getFilterContactBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    };
  }

  handleAddressChanges = async ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];
    let { data } = this.state;

    switch (fieldName) {
      case "country":
        await this.handleCountryChange(data, input);
        break;
      case "state":
        this.handleStateChange(data, input);
        break;
      default:
        this.handleAddressDetailChange(data, index, fieldName, input);
        break;
    }

    this.setState({ data });
  };

  handleExtraAddress = ({ currentTarget: input }) => {
    let { data } = this.state;

    if (!this.state.isUserEditing) {
      if (input.value === "add") {
        if (data.addresses[data.addresses.length - 1].state) {
          data.addresses.push({
            streetAddress: "",
            city: "",
            state: data.addresses[0].state,
            country: data.addresses[0].country,
            zipCode: "",
          });
        } else {
          toast.warning("Please fill out all the required fields on previous address record.");
        }
      } else {
        data.addresses.splice(input.value, 1);
      }
    } else {
      if (input.value === "add") {
        if (data.addressesEdit[data.addressesEdit.length - 1].mailingStateUniqueId) {
          data.addressesEdit.push({
            mailingStreet: "",
            mailingCity: "",
            mailingStateUniqueId: data.addressesEdit[0].mailingStateUniqueId,
            mailingCountryUniqueId: data.addressesEdit[0].mailingCountryUniqueId,
            mailingZip: "",
          });
        } else {
          toast.warning("Please fill out all the required fields on previous address record.");
        }
      } else {
        data.addressesEdit.splice(input.value, 1);
      }
    }

    this.setState({ data });
  };

  dummyHandler = () => {
    //do nothing
  };
  handleAddressDetailChange(data, index, fieldName, input) {
    if (this.state.isUserEditing) {
      data.addressesEdit[index][fieldName] = input.value;
    } else {
      data.addresses[index][fieldName] = input.value;
    }
  }

  handleStateChange(data, input) {
    this.state.isUserEditing
      ? (data.addressesEdit = data.addressesEdit.map((address) => {
          address.state = input.value;
          return address;
        }))
      : (data.addresses = data.addresses.map((address) => {
          address.state = input.value;
          return address;
        }));
  }

  async handleCountryChange(data, input) {
    this.state.isUserEditing
      ? (data.addressesEdit = data.addressesEdit.map((address) => {
          address.country = input.value;
          return address;
        }))
      : (data.addresses = data.addresses.map((address) => {
          address.country = input.value;
          return address;
        }));
    const results = await ReferenceFileAPI.GetStatesByCountry(input.value);
    if (results) {
      this.setState({
        states: results
          .map((state) => ({
            id: state.stateUniqueIdentifier,
            name: state.state_code,
          }))
          .sort((a, b) => (a.name < b.name ? -1 : 1)),
      });
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  errorOnIncompleteInfo() {
    toast.error(
      "First Name, Last Name, State, and Country must be filled out. Email Address must be in valid format. Phone Type must be selected for each phone number."
    );
  }

  handleSave = async () => {
    let contactData = null;
    let errorsExist = false;
    let isCreateContact = false;
    const { data } = this.state;
    const contactRoleName =
      data.contactRoleEnumId && this.props.agreementContactRoles.some((c) => c.id === parseInt(data.contactRoleEnumId))
        ? UtilityFunctions.getDisplayTextFromFieldObject(
            this.props.agreementContactRoles.find((role) => role.id === parseInt(data.contactRoleEnumId))
          )
        : "";
    if (!contactRoleName && !this.props.isForLandowner) {
      ErrorHandler.showError("Please select an Agreement Role for this Contact.");
      errorsExist = true;
      return;
    }

    if (data.firstName !== "" && data.lastName !== "") {
      isCreateContact = true;
    }

    if (!isCreateContact && data.selectedContact) {
      if (this.isDuplicate(data.selectedContact)) {
        ErrorHandler.showError("Selected Contact is already Associated, please select another Contact");
        errorsExist = true;
      } else {
        const selectedContactValidated =
          data.selectedContact.primaryMailingAddress?.mailingCountryUniqueId &&
          data.selectedContact.primaryMailingAddress?.mailingStateUniqueId &&
          data.selectedContact.firstName &&
          data.selectedContact.lastName;
        if (!selectedContactValidated && !this.state.isUserEditing) {
          this.errorOnIncompleteInfo();
          errorsExist = true;
        } else if (this.state.isUserEditing && !errorsExist) {
          contactData = await this.editContact();
          if (this.validateRequiredFields()) {
            contactData.contactGuid = data.selectedContact.value;
            contactData.contactName = contactData.firstName.concat(
              " ",
              contactData.middleName,
              " ",
              contactData.lastName
            );
            contactData.contactRoleEnumId = data.contactRoleEnumId;
            contactData.contactRoleName = contactRoleName;
          } else {
            this.errorOnIncompleteInfo();
            errorsExist = true;
          }
        } else {
          contactData = {
            contactGuid: data.selectedContact.value,
            contactName: data.selectedContact.label,
            firstName: data.selectedContact.firstName,
            middleName: data.selectedContact.middleName,
            lastName: data.selectedContact.lastName,
            contactRoleEnumId: data.contactRoleEnumId,
            phoneNumbers: data.selectedContact.phoneNumbers?.map((phoneNumber) => {
              phoneNumber.phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber);
              return phoneNumber;
            }),
            primaryMailingAddress: data.selectedContact.primaryMailingAddress,
            emailAddress: data.selectedContact.emailAddress,
            contactRoleName: contactRoleName,
          };
        }
      }
    } else {
      if (this.validateRequiredFields()) {
        contactData = await this.createContact(data);
        if (contactData) {
          contactData.contactRoleEnumId = data.contactRoleEnumId;
          contactData.contactRoleName = contactRoleName;
        } else {
          errorsExist = true;
        }
      } else {
        this.errorOnIncompleteInfo();
        errorsExist = true;
      }
    }

    if (!errorsExist) {
      this.props.onHide();
      this.props.onSaveAssociation(contactData);
      this.setState({
        contactRoleEnumId: "",
        isUserEditing: false,
        modalMode: "create",
        data: this.getClearContactData(),
      });
    }
  };

  isDuplicate(contact) {
    if (this.props.updatedContacts !== undefined && contact !== undefined) {
      for (var i = 0; i < this.props.updatedContacts.length; i++) {
        if (this.props.updatedContacts[i].contactGuid === contact.value) return true;
      }
    }
    return false;
  }

  async editContact() {
    let contactResult = {};
    const updateContactObj = {
      contactUniqueId: this.state.data.selectedContact.value,
      firstName: this.state.data.firstNameEdit,
      middleName: this.state.data.middleNameEdit,
      lastName: this.state.data.lastNameEdit,
      preferredName: this.state.data.preferredNameEdit,
      typeOfContactEnumId: 1,
      suffix: this.state.data.suffixEdit,
      emailAddress: this.state.data.emailAddressEdit,
      primaryMailingAddress: this.getMailingAddress(this.state.data),
    };
    const updateAPIResult = await AgreementEntitiesAPI.UpdateContact(updateContactObj);
    let otherAddresses = this.getOtherAddresses(this.state.data);
    const updateOtherAddressesAPIResult = await AgreementEntitiesAPI.ReplaceOtherAddressesOnContact(
      this.state.data.selectedContact.value,
      otherAddresses
    );
    const updatePhoneNumbersAPIResult = await AgreementEntitiesAPI.ReplacePhoneNumbersOnContact(
      this.state.data.selectedContact.value,
      this.state.data.phoneNumbersEdit
        .filter((phone) => phone.phoneNumber !== "" && phone?.phoneTypeEnumId !== "")
        .map((p) => {
          return {
            phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(p.phoneNumber),
            phoneTypeEnumId: parseInt(p.phoneTypeEnumId) || parseInt(p.phoneType.id),
          };
        })
    );

    if (this.areUpdateCallsSuccessful(updateAPIResult, updateOtherAddressesAPIResult, updatePhoneNumbersAPIResult)) {
      contactResult = updatePhoneNumbersAPIResult?.data;
      toast.success("Contact edited and added to Agreement.");
    } else {
      if (!updateAPIResult?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update contact information",
          apiName: "UpdateContact",
          responseUnsuccessful: updateAPIResult?.unsuccessful,
          responseMessage: updateAPIResult?.message,
        });
      }
      if (!updateOtherAddressesAPIResult?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update contact address",
          apiName: "ReplaceOtherAddressesOnContact",
          responseUnsuccessful: updateOtherAddressesAPIResult?.unsuccessful,
          responseMessage: updateOtherAddressesAPIResult?.message,
        });
      }
      if (!updatePhoneNumbersAPIResult?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update contact phone numbers",
          apiName: "ReplacePhoneNumbersOnContact",
          responseUnsuccessful: updatePhoneNumbersAPIResult?.unsuccessful,
          responseMessage: updatePhoneNumbersAPIResult?.message,
        });
      }
    }
    return contactResult;
  }

  areUpdateCallsSuccessful(updateAPIResult, updateOtherAddressesAPIResult, updatePhoneNumbersAPIResult) {
    return (
      updateAPIResult?.successful &&
      updateOtherAddressesAPIResult?.successful &&
      updatePhoneNumbersAPIResult?.successful
    );
  }

  async createContact(data) {
    const primaryAddress = this.getMailingAddress(data);
    let otherAddresses = this.getOtherAddresses(data);
    const verifiedPhones = this.getPhoneNumbers(data);

    var contactRecord = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      suffix: data.suffix,
      preferredName: data.preferredName,
      typeOfContactEnumId: 1,
      emailAddress: data.emailAddress,
      phoneNumbers: verifiedPhones,
      primaryMailingAddress: primaryAddress,
      otherAddresses: otherAddresses,
    };

    const duplicateContact = await AgreementEntitiesAPI.FindDuplicateContacts(contactRecord, 1);

    if (duplicateContact.data && duplicateContact.data.length > 0) {
      this.setState({
        showDuplicateModal: true,
        duplicateModalData: duplicateContact.data[0],
        duplicateModalCreateData: contactRecord,
      });
      return "";
    } else {
      return this.defaultCreateContact(contactRecord);
    }
  }

  async defaultCreateContact(contactRecord) {
    let createAPIResult = await AgreementEntitiesAPI.CreateContact(contactRecord);
    if (createAPIResult?.successful) {
      this.setState({ showHappyModal: true, data: this.getClearContactData() });
      const { contactUniqueId, firstName, middleName, lastName, phoneNumbers, primaryMailingAddress } =
        createAPIResult.data;
      return {
        contactGuid: contactUniqueId,
        contactName: firstName.concat(" ", middleName, " ", lastName),
        phoneNumbers: phoneNumbers?.map((phoneNumber) => {
          phoneNumber.phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber);
          return phoneNumber;
        }),
        primaryMailingAddress: primaryMailingAddress,
      };
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create contact",
        apiName: "CreateContact",
        responseUnsuccessful: createAPIResult?.unsuccessful,
        responseMessage: createAPIResult?.message,
      });
    }
  }

  getClearContactData() {
    return {
      selectedContact: "",
      contactRoleEnumId: "",
      contactType: "",
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "",
      preferredName: "",
      emailAddress: "",
      phoneNumbers: [
        {
          phoneNumber: "",
          phoneTypeEnumId: "1",
        },
      ],
      addresses: [
        {
          streetAddress: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
      ],
      firstNameEdit: "",
      middleNameEdit: "",
      lastNameEdit: "",
      suffixEdit: "",
      preferredNameEdit: "",
      emailAddressEdit: "",
      phoneNumbersEdit: [],
      addressesEdit: [],
    };
  }

  getMailingAddress(data) {
    const primaryAddress = this.state.isUserEditing ? data.addressesEdit[0] : data.addresses[0];
    const primaryAddressObject =
      this.props.contactType === "cooperator" ||
      ((primaryAddress.state || primaryAddress.mailingStateUniqueId) &&
        (primaryAddress.country || primaryAddress.mailingCountryUniqueId))
        ? {
            mailingStreet: primaryAddress.streetAddress || primaryAddress.mailingStreet,
            mailingCity: primaryAddress.city || primaryAddress.mailingCity,
            mailingStateUniqueId: primaryAddress.state || primaryAddress.mailingStateUniqueId,
            mailingZip: primaryAddress.zipCode || primaryAddress.mailingZip,
            mailingCountryUniqueId: primaryAddress.country || primaryAddress.mailingCountryUniqueId,
            addressPurpose: "mailing",
          }
        : null;
    return primaryAddressObject;
  }

  getOtherAddresses(data) {
    let addressesToUse = data.addresses;
    if (this.state.isUserEditing) {
      addressesToUse = data.addressesEdit;
    }
    if (addressesToUse.length <= 1) {
      return [];
    }

    const otherAddresses = [...addressesToUse];
    otherAddresses.shift();
    return otherAddresses
      .filter((address) => this.filterOtherAddress(address))
      .map((address) => ({
        mailingStreet: address.streetAddress || address.mailingStreet,
        mailingCity: address.city || address.mailingCity,
        mailingStateUniqueId: address.state || address.mailingStateUniqueId,
        mailingZip: address.zipCode || address.mailingZip,
        mailingCountryUniqueId: address.country || address.mailingCountryUniqueId,
        addressPurpose: "other",
      }));
  }

  filterOtherAddress(address) {
    return (
      (address.streetAddress !== "" &&
        address.city !== "" &&
        address.state !== "" &&
        address.zipCode !== "" &&
        address.country !== "") ||
      (address.mailingStreet !== "" &&
        address.mailingCity !== "" &&
        address.mailingStateUniqueId !== "" &&
        address.mailingZip !== "" &&
        address.mailingCountryUniqueId !== "")
    );
  }

  getPhoneNumbers(data) {
    return data.phoneNumbers
      .filter((phone) => phone.phoneNumber !== "" && phone.phoneTypeEnumId !== "")
      .map((phone) => ({
        phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(phone.phoneNumber),
        phoneTypeEnumId: parseInt(phone.phoneTypeEnumId),
      }));
  }

  validateRequiredFields() {
    if (!this.state.isUserEditing) {
      let emailValidated = true;
      let phonesValidated = this.validatePhoneNumbers();

      if (this.state.data.emailAddress !== "") {
        emailValidated = UtilityFunctions.validateEmail(this.state.data.emailAddress);
      }

      let generalInformation =
        this.state.data.firstName !== "" &&
        this.state.data.lastName !== "" &&
        (this.state.data.addresses[0].country !== "" || this.state.data.addresses[0].mailingCountryUniqueId) &&
        phonesValidated &&
        emailValidated;
      let cooperatorSpecificInformation =
        (this.state.data.addresses[0].state !== "" || this.state.data.addresses[0].mailingStateUniqueId) &&
        (this.state.data.addresses[0].streetAddress !== "" || this.state.data.addresses[0].mailingStreet) &&
        (this.state.data.addresses[0].city !== "" || this.state.data.addresses[0].mailingCity) &&
        (this.state.data.addresses[0].zipCode !== "" || this.state.data.addresses[0].mailingZip);

      if (this.props.contactType !== "cooperator") {
        return generalInformation;
      } else {
        return generalInformation && cooperatorSpecificInformation;
      }
    } else {
      let emailValidated = true;
      let phonesValidated = this.validatePhoneNumbers();

      if (this.state.data.emailAddressEdit) {
        emailValidated = UtilityFunctions.validateEmail(this.state.data.emailAddressEdit);
      }

      let generalInformation =
        this.state.data.firstNameEdit !== "" &&
        this.state.data.lastNameEdit !== "" &&
        this.state.data.addressesEdit[0].mailingStateUniqueId !== "" &&
        this.state.data.addressesEdit[0].mailingCountryUniqueId !== "" &&
        phonesValidated &&
        emailValidated;
      let cooperatorSpecificInformation =
        this.state.data.addressesEdit[0].streetAddress !== "" &&
        this.state.data.addressesEdit[0].city !== "" &&
        this.state.data.addressesEdit[0].zipCode !== "";

      if (this.props.contactType !== "cooperator") {
        return generalInformation;
      } else {
        return generalInformation && cooperatorSpecificInformation;
      }
    }
  }

  validatePhoneNumbers() {
    let phoneNumbersToValidate = this.state.isUserEditing
      ? this.state.data.phoneNumbersEdit
      : this.state.data.phoneNumbers;
    let result = true;
    phoneNumbersToValidate.forEach((phoneNumber) => {
      let phoneNumberNotEmpty = phoneNumber.phoneNumber !== "";
      let typeNotSelected = phoneNumber.phoneTypeEnumId === "-1";
      let numberInvalid = !UtilityFunctions.validatePhoneNumber(phoneNumber.phoneNumber);
      if (phoneNumberNotEmpty) {
        if (typeNotSelected || numberInvalid) {
          result = false;
        }
      } else if (!phoneNumberNotEmpty && this.props.contactType === "cooperator") {
        result = false;
      }
    });
    return result;
  }

  handlePhoneNumberChange = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    let { data } = this.state;
    this.state.isUserEditing
      ? (data.phoneNumbersEdit[index][fieldName] = UtilityFunctions.formatPhoneNumber(input.value))
      : (data.phoneNumbers[index][fieldName] = UtilityFunctions.formatPhoneNumber(input.value));
    this.setState({ data });
  };

  handleExtraPhoneNumber = ({ currentTarget: input }) => {
    let { data } = this.state;
    if (input.value === "add") {
      this.state.isUserEditing
        ? data.phoneNumbersEdit.push({ phoneNumber: "", phoneTypeEnumId: "1" })
        : data.phoneNumbers.push({ phoneNumber: "", phoneTypeEnumId: "1" });
    } else {
      this.state.isUserEditing
        ? data.phoneNumbersEdit.splice(input.value, 1)
        : data.phoneNumbers.splice(input.value, 1);
    }
    this.setState({ data });
  };

  closeDuplicateModal = () => {
    this.setState({ showDuplicateModal: false });
  };

  closeHappyModal = () => {
    this.setState({ showHappyModal: false });
  };

  isSaveButtonDisabled() {
    let contactSelected = this.state.data.selectedContact && this.state.data.selectedContact !== "";
    let reqFieldsDoneForCreation = this.validateRequiredFields();
    let roleSelected = true;
    if (this.props.contactType !== "cooperator") {
      roleSelected = this.state.data.contactRoleEnumId !== "";
    }
    return !((contactSelected || reqFieldsDoneForCreation) && roleSelected);
  }

  renderSearchEditSection = () => {
    return (
      <div className={styles.addContactModalSearchEditContainer}>
        <Row className={styles.smallCommentsRow}>
          <Col>
            <span className={styles.smallComments}>
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              Search for and add an existing contact or create a new one to add to the agreement.
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <Form.Label className={styles.addContactSearchInfo}>
              Search for an Existing {this.props.contactType === "cooperator" ? " Cooperator" : " Contact"}
            </Form.Label>
            <div className={styles.asyncTypeaheadContainer}>
              <AsyncTypeahead
                id="asyncMultiColSelect"
                filterBy={["firstName", "lastName", "phoneNumbers", "emailAddress", "primaryMailingAddress"]}
                clearButton
                isLoading={this.state.typeaheadIsLoading}
                labelKey="label"
                minLength={1}
                onSearch={this.handleContactSearch}
                options={this.state.typeaheadOptions}
                placeholder={
                  this.props.contactType === "cooperator" ? "Search for a Cooperator" : "Search for a Contact"
                }
                onChange={this.handleContactChange}
                renderMenuItemChildren={(option) => this.formatMenu(option)}
                className={styles.addContactModalSearchBar}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{this.renderEditContactForm()}</Col>
        </Row>
      </div>
    );
  };

  renderCreateSection = () => {
    return (
      <div>
        <Card className={styles.my3}>
          <Card.Body>
            <Row>
              <Col sm={4}>{this.renderInput("firstName", "First Name", "text", "Enter Name", "required")}</Col>
              <Col sm={2}>{this.renderInput("middleName", "Middle Name", "text", "Enter Name")}</Col>
              <Col sm={4}>{this.renderInput("lastName", "Last Name", "text", "Enter Name", "required")}</Col>
              <Col sm={2}>{this.renderInput("suffix", "Suffix", "text", "Enter Suffix")}</Col>
            </Row>
            <Row>
              <Col sm={6} md={6} lg={6}>
                {this.renderInput("preferredName", "Preferred Name", "text", "Enter Name")}
              </Col>
            </Row>
            <Row>
              <Col>{this.renderInput("emailAddress", "Email Address", "email", "Enter Email", "required")}</Col>
              <Col>
                <PhoneNumber
                  phoneNumbers={this.state.data.phoneNumbers}
                  phoneNumberTypes={this.props.phoneNumberTypes}
                  onPhoneNumberChange={this.handlePhoneNumberChange}
                  onExtraPhoneNumber={this.handleExtraPhoneNumber}
                  contactType={this.props.contactType}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className={styles.my3}>
          <Card.Header className={styles.my3Header}>Address Information</Card.Header>
          <Card.Body style={{ marginLeft: "-8px" }}>
            <Addresses
              addresses={this.state.data.addresses}
              countries={this.props.countries}
              states={this.state.states || this.props.states}
              onAddressChange={this.handleAddressChanges}
              onExtraAddress={this.handleExtraAddress}
              contactType={this.props.contactType}
              onDummyChange={this.dummyHandler}
            />
            <Row hidden={this.props.isForLandowner}>
              <Col sm={6} md={6} lg={6} style={{ marginLeft: "8px" }}>
                {this.renderSelect(
                  "contactRoleEnumId",
                  "Agreement Role",
                  this.props.agreementContactRoles,
                  "Select Type",
                  "required"
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  };

  renderEditContactForm = () => {
    if (!this.state.data.selectedContact) {
      return <div style={{ paddingTop: "300px" }}></div>;
    }
    const isEditable = this.state.modalMode === "search" && this.state.isUserEditing;

    return (
      <div>
        <Row hidden={this.props.isForLandowner}>
          <Col sm={6} md={6} lg={6}>
            {this.renderSelect(
              "contactRoleEnumId",
              "Agreement Role",
              this.props.agreementContactRoles,
              "Select Type",
              "required"
            )}
          </Col>
        </Row>
        <Row className={styles.addContactSearchResultHeaderTopBorder}>
          <Col>
            <span className={styles.contactInfoHeader}>Contact Information</span>
            <Button
              variant="link"
              className={styles.addContactSearchEditLink}
              onClick={() => this.setState({ isUserEditing: !this.state.isUserEditing })}
            >
              <span className={styles.addContactSearchEditLinkText}>Edit</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            {isEditable
              ? this.renderInput("firstNameEdit", "First Name", "text", "Enter Name", "required")
              : this.renderLabel("firstNameEdit", "First Name", this.state.data.firstNameEdit)}
          </Col>
          <Col sm={2}>
            {isEditable
              ? this.renderInput("middleNameEdit", "Middle Name", "text", "Enter Name")
              : this.renderLabel("middleNameEdit", "Middle Name", this.state.data.middleNameEdit)}
          </Col>
          <Col sm={4}>
            {isEditable
              ? this.renderInput("lastNameEdit", "Last Name", "text", "Enter Name", "required")
              : this.renderLabel("lastNameEdit", "Last Name", this.state.data.lastNameEdit)}
          </Col>
          <Col sm={2}>
            {isEditable
              ? this.renderInput("suffixEdit", "Suffix", "text", "Enter Suffix")
              : this.renderLabel("suffixEdit", "Suffix", this.state.data.suffixEdit)}
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            {isEditable
              ? this.renderInput("preferredNameEdit", "Preferred Name", "text", "Enter Name")
              : this.renderLabel("preferredNameEdit", "Preferred Name", this.state.data.preferredNameEdit)}
          </Col>
        </Row>
        <Row>
          <Col>
            {isEditable
              ? this.renderInput("emailAddressEdit", "Email Address", "email", "Enter Email", "required")
              : this.renderLabel("emailAddressEdit", "Email Address", this.state.data.emailAddressEdit)}
          </Col>
          <Col>
            {isEditable ? (
              <PhoneNumber
                phoneNumbers={this.state.data.phoneNumbersEdit}
                phoneNumberTypes={this.props.phoneNumberTypes}
                onPhoneNumberChange={this.handlePhoneNumberChange}
                onExtraPhoneNumber={this.handleExtraPhoneNumber}
                contactType={this.props.contactType}
              />
            ) : (
              <PhoneNumberDisplay
                phoneNumbers={this.state.data.phoneNumbersEdit}
                phoneNumberTypes={this.props.phoneNumberTypes}
              />
            )}
          </Col>
        </Row>
        <Row className={styles.addContactModalEditContactAddressHeaderRow}>
          <Col>
            <span className={styles.addContactModalEditContactAddressHeader}>Address Information</span>
          </Col>
        </Row>
        <Row>
          <Col className={styles.addContactAddressContainer}>
            {isEditable ? (
              <Addresses
                addresses={this.state.data.addressesEdit}
                countries={this.props.countries}
                states={this.state.states || this.props.states}
                onAddressChange={this.handleAddressChanges}
                onExtraAddress={this.handleExtraAddress}
                contactType={this.props.contactType}
                onDummyChange={this.dummyHandler}
              />
            ) : (
              <ContactOrgAddressDisplay
                addresses={this.state.data.addressesEdit}
                countries={this.props.countries}
                states={this.state.states || this.props.states}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  };

  handleModalModeSelection = (e) => {
    this.setState({ modalMode: e });
  };

  handleModalClose = () => {
    this.props.onHide();
    this.setState({ isUserEditing: false, data: this.getClearContactData() });
  };

  render() {
    const { data } = this.state;
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    const dod = this.props.states?.find((d) => d.name === "DD");
    if (!data.addresses[0]?.country && currentUsaState?.stateGuid !== dod?.id) {
      data.addresses[0].country = this.props.usaCountryId;
    } else if (!data.addresses[0]?.mailingCountryUniqueId && currentUsaState?.stateGuid !== dod?.id) {
      data.addresses[0].mailingCountryUniqueId = this.props.usaCountryId;
    }
    let modalTitle = "Add a Contact";
    if (this.props.contactType === "cooperator") {
      modalTitle = "Add a Cooperator";
    }
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleModalClose}
          backdrop="static"
          centered
          scrollable
          dialogClassName={styles.wideModalSize}
        >
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <div className="container">
              <Row>
                <Col>
                  <Modal.Title>{modalTitle}</Modal.Title>
                </Col>
              </Row>
            </div>
          </Modal.Header>
          <Modal.Body className={styles.bigTabsModalBody}>
            <div className="modalBigGreenTabs">
              <Tabs defaultActiveKey="create" onSelect={this.handleModalModeSelection}>
                <Tab eventKey="create" title="Create a New Contact">
                  {this.renderCreateSection()}
                </Tab>
                <Tab eventKey="search" title="Search for existing Contacts">
                  {this.renderSearchEditSection()}
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className={globalStyles.modalCancelButton} onClick={this.handleModalClose}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button variant="primary" className={globalStyles.modalSubmitButton} onClick={this.handleSave}>
              <span className={globalStyles.modalSubmitButtonText}>
                {this.state.modalMode === "create" ? "Create" : this.state.isUserEditing ? "Save and Add" : "Add"}
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
        <DuplicateContactModal
          show={this.state.showDuplicateModal}
          onHide={this.closeDuplicateModal}
          data={this.state.duplicateModalData}
          createData={this.state.duplicateModalCreateData}
          usaStates={this.state.states || this.props.states}
          countries={this.props.countries}
          contactProperties={this.state.contactProperties}
          contactOrganizations={this.state.contactOrganizations}
        />
        <SuccessfulRecordModifiedModal
          show={this.state.showHappyModal}
          onHide={this.closeHappyModal}
          messageType="create"
          objectName="Contact"
        />
      </div>
    );
  }
}
export default AddContactModal;
