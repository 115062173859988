import React from "react";
import { withRouter } from "react-router-dom";
import globalStyles from "../../OARS.module.scss";
import { Form, Row, Col, Button, Modal, CloseButton } from "react-bootstrap";
import CustomForm from "../common/form";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import searchIcon from "../../assets/search.svg";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import { toast } from "react-toastify";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class CreateDistrictModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        districtName: "",
        supervisor: "",
        assistantSupervisors: [],
      },
      isModalInitialized: false,
      currentUsaState: "",
      errors: {},
    };
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show && !this.state.isModalInitialized) {
      const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
      this.validateStateData(currentUsaState);

      this.setState({
        currentUsaState,
        isModalInitialized: true,
      });
    }
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there.",
        {
          autoClose: 8000,
        }
      );
    }
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "solid 0.5px #D8DDE6",
      };
    },
  };

  isFormDataValid() {
    const { data } = this.state;
    return data.districtName && data.supervisor;
  }

  checkForInvalidData() {
    const { data, errors } = this.state;
    if (!data.districtName) {
      errors.districtName = "You must enter a valid District Name.";
    }
    if (!data.supervisor) {
      errors.supervisor = "You must select a Supervisor.";
    }

    this.setState(errors);
  }

  doSubmit() {
    const { data } = this.state;
    if (!this.isFormDataValid()) {
      this.checkForInvalidData();
    } else {
      this.props.onCreateDistrict({ ...data });
      this.clearData(data);
      this.props.onHide();
      this.setState({ data });
    }
  }

  handleSpecificChanges() {
    // do something
  }

  handleModalCancel = () => {
    const { data } = this.state;
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    delete errors[fieldName];
    this.setState({ data, errors });
  };

  loadAvailableUserProfiles = async (inputValue) => {
    let outputContacts = [];
    const contactsDataResponse = await RoleAuthorizationAPI.SearchUsersFreeText(this.getSearchUsersBody(inputValue));
    if (contactsDataResponse?.successful && contactsDataResponse.data?.results?.length > 0) {
      let results = contactsDataResponse.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    return outputContacts;
  };

  getSearchUsersBody(inputValue) {
    return {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
  }

  clearData(data) {
    data.districtName = "";
    data.supervisor = "";
    data.assistantSupervisors = [];
  }

  render() {
    const { data, errors } = this.state;
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader}>
            <Row className={globalStyles.modalHeaderRow}>
              <Col className="px-0">
                <Modal.Title className={globalStyles.modalTitleText}>Create District</Modal.Title>
              </Col>
              <Col className="pr-0">
                <CloseButton onClick={this.handleModalCancel} />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <Row className="mx-0">
              <Col>{this.renderInput("districtName", "District Name", "text", "Enter Name", "required")}</Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Supervisor<span className={globalStyles.asterisk508}> *</span>
                  </Form.Label>
                  <AsyncSelect
                    placeholder="Search"
                    aria-label="Supervisor Search"
                    value={data.supervisor}
                    styles={this.customSelectStyles}
                    components={{ DropdownIndicator, Option }}
                    loadOptions={this.loadAvailableUserProfiles}
                    onChange={(e) => this.handleSelectChanges(e, "supervisor")}
                  />
                  {errors.supervisor && <div className="alert alert-danger">{errors.supervisor}</div>}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Assistant Supervisor</Form.Label>
                  <AsyncSelect
                    placeholder="Search"
                    aria-label="Assistant Supervisors Search"
                    value={data.assistantSupervisors}
                    styles={this.customSelectStyles}
                    isMulti
                    components={{ DropdownIndicator, Option }}
                    loadOptions={this.loadAvailableUserProfiles}
                    onChange={(e) => this.handleSelectChanges(e, "assistantSupervisors")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit">
              <span className={globalStyles.modalSubmitButtonText}>Create</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default withRouter(CreateDistrictModal);
