import React from "react";
import styles from "./AdminWorkTask.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import CustomForm from "../common/form";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { components } from "react-select";
import searchIcon from "../../assets/search.svg";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";

const SearchIcon = () => {
  return <img src={searchIcon} alt="Search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class AdminWorkTaskModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        taskDate: new Date().toLocaleDateString("en-CA"),
        timeHours: "",
        timeMinutes: "",
        selectedActivity: [],
        selectedAgreement: "",
        adminWorkTaskComments: "",
      },
      availableActivities: [],
      errors: {},
    };
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    this.validateStateData(currentUsaState);

    this.setState({
      availableActivities: await this.getAvailableActivities(currentUsaState),
      currentUsaState,
    });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.data?.selectedAgreement !== this.props.data?.selectedAgreement) {
      const { data } = this.state;
      data.selectedAgreement = this.props.selectedAgreement;
      this.setState({ data });
    }
  }

  async getAvailableActivities(currentUsaState) {
    let availableActivities = [];
    if (currentUsaState?.stateGuid) {
      const activitiesResponse = await ReferenceFileAPI.GetAdminActivitiesForState(currentUsaState.stateGuid);
      if (activitiesResponse?.data?.results?.length > 0) {
        availableActivities = activitiesResponse.data.results.map((activity) => ({
          value: activity.adminActivityUniqueIdentifier,
          label: activity.activityName,
        }));
      }
    }
    return availableActivities;
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there.",
        {
          autoClose: 8000,
        }
      );
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  handleSelectChanges = (e, fieldName) => {
    const { data } = this.state;
    data[fieldName] = e;
    this.setState({ data });
  };

  handleAgreementsSearch = async (inputValue) => {
    return await this.getAgreementsFromAPI(inputValue);
  };

  async getAgreementsFromAPI(searchValue) {
    const { currentUsaState } = this.state;
    let searchResults = "";

    if (currentUsaState?.stateGuid) {
      const agreementsDataResponse = await AgreementAPI.SearchAgreementFreeText(
        this.getSearchEntitiesBody(searchValue),
        currentUsaState.stateGuid
      );

      if (agreementsDataResponse?.data?.results) {
        searchResults = agreementsDataResponse.data.results.map((result) => ({
          value: result.agreementUniqueIdentifier,
          label: result.commonName,
        }));
      }
    }

    return searchResults;
  }

  getSearchEntitiesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  isFormDataValid() {
    const { taskDate, timeHours, timeMinutes, selectedActivity } = this.state.data;
    return (
      !this.state.errors["adminWorkTaskComments"] &&
      taskDate &&
      (timeHours || timeMinutes) &&
      selectedActivity.length > 0
    );
  }

  doSubmit() {
    const { data } = this.state;

    this.props.onSubmitTask({ ...data });
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  }

  handleModalCancel = () => {
    const { data } = this.state;

    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  clearData(data) {
    data.taskDate = new Date().toLocaleDateString("en-CA");
    data.timeHours = "";
    data.timeMinutes = "";
    data.selectedActivity = [];
    data.selectedAgreement = "";
    data.adminWorkTaskComments = "";
  }

  render() {
    const { data, errors } = this.state;

    let taskDate = data.taskDate;
    let selectedAgreement = data.selectedAgreement;
    if (this.props.data) {
      taskDate = this.props.data.pvsDate;
      selectedAgreement = this.props.data.selectedAgreement;
    }

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>Create Admin Work Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Date<span className={styles.requiredFieldIndication}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    aria-label="adminWorkTaskDate"
                    placeholder="Select Date"
                    value={taskDate}
                    onChange={this.handleChange}
                    name="taskDate"
                    disabled={this.props.data}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Label className={globalStyles.formLabel}>
                  Time<span className={styles.requiredFieldIndication}>*</span>
                </Form.Label>
              </Col>
            </Row>
            <Row className="mx-0 mb-3">
              <Col>
                <Form.Control
                  type="number"
                  aria-label="adminWorkTaskHours"
                  placeholder="Hours"
                  value={data.timeHours}
                  onChange={this.handleChange}
                  name="timeHours"
                />
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  aria-label="adminWorkTaskMinutes"
                  placeholder="Minutes"
                  value={data.timeMinutes}
                  onChange={this.handleChange}
                  name="timeMinutes"
                />
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Activity<span className={styles.requiredFieldIndication}>*</span>
                  </Form.Label>
                  <Select
                    value={data.selectedActivity}
                    aria-label="adminWorkTaskActivity"
                    placeholder="Select"
                    isMulti={true}
                    styles={this.customSelectStyles}
                    options={this.state.availableActivities}
                    onChange={(e) => this.handleSelectChanges(e, "selectedActivity")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>{`Agreement${
                    this.props.data ? "" : " (Optional)"
                  }`}</Form.Label>
                  <AsyncSelect
                    value={selectedAgreement}
                    aria-label="adminWorkTaskAgreementSelection"
                    openMenuOnClick={false}
                    placeholder="Search for Agreement"
                    components={{ DropdownIndicator }}
                    styles={this.customSelectStyles}
                    loadOptions={this.handleAgreementsSearch}
                    onChange={(e) => this.handleSelectChanges(e, "selectedAgreement")}
                    isDisabled={this.props.data}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Comments</Form.Label>
                  <Form.Control
                    type="text"
                    aria-label="adminWorkTaskComments"
                    value={data.adminWorkTaskComments}
                    onChange={this.handleChange}
                    as="textarea"
                    rows="4"
                    style={{ fontSize: "12px", border: "solid 0.8px #e1e1e1" }}
                    name="adminWorkTaskComments"
                  />
                  {errors["adminWorkTaskComments"] && (
                    <div className="alert alert-danger">{errors["adminWorkTaskComments"]}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit" disabled={!this.isFormDataValid()}>
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AdminWorkTaskModal;
