import React from "react";
import { Nav } from "react-bootstrap";
import { withRouter } from "react-router";
import "../sidebar/sidebar.css";
import SidebarItem from "../sidebar-item/sidebar-item";

const Side = (props) => {
  const { isSidebarDisplayed, permissions } = props;
  const menuDisplayed = isSidebarDisplayed ? " menu-displayed" : "";
  const username = localStorage.getItem("currentUserName");

  const handleClick = (directory) => {
    props.closeMenu();
    props.history.push(directory);
  };

  return (
    <Nav
      className={`col-md-12 d-none d-md-block px-0 py-0 sidebar${menuDisplayed}`}
      style={{ zIndex: "1000" }}
      activeKey="/home"
      data-testid="sidebarElement"
    >
      <SidebarItem
        itemClass="phone-side-menu username"
        iconClass="fa fa-user-circle nav-icons"
        label={username}
        onClick={() => props.history.push("/home")}
      />
      <SidebarItem
        itemClass="my-3"
        iconClass="fa fa-home nav-icons"
        label="Home"
        onClick={() => handleClick("/home")}
      />
      {permissions.canIViewReferenceData() && (
        <SidebarItem
          itemClass="my-3"
          iconClass="fa fa-archive nav-icons"
          label="Reference Files"
          onClick={() => handleClick("/referencefiles")}
        />
      )}

      <SidebarItem
        itemClass="my-3"
        iconClass="fa fa-file-text-o nav-icons"
        label="Reports and Queries"
        onClick={() => handleClick("/reports-and-queries")}
      />
      {(permissions.canIViewGlobalUserRecord() || permissions.canIViewGlobalRole()) && (
        <SidebarItem
          itemClass="my-3"
          iconClass="fa fa-users nav-icons"
          label="User Management"
          onClick={() => handleClick("/admincenter")}
        />
      )}
      <SidebarItem
        itemClass="my-3"
        link="/map"
        iconClass="fa fa-map-o nav-icons"
        label="Map"
        onClick={() => handleClick("/connectmap")}
      />
      {permissions.canIViewWarehouses() && (
        <SidebarItem
          itemClass="my-3"
          iconClass="fa fa-building nav-icons"
          label="CMITS"
          onClick={() => handleClick("/cmits/details")}
        />
      )}
      <SidebarItem
        itemClass="my-3"
        iconClass="fa fa-info-circle nav-icons"
        label="Help"
        onClick={() => handleClick("/help")}
      />
      <SidebarItem
        itemClass="mt-3 phone-side-menu"
        iconClass="fa fa-sign-out nav-icons"
        label="Logout"
        onClick={() => props.history.push("/logout")}
      />
    </Nav>
  );
};
const Sidebar = withRouter(Side);
export default Sidebar;
