import React from "react";

const Input = ({
  name,
  label,
  error,
  value,
  placeholder,
  type,
  isReadOnly = false,
  isRequired = false,
  maxLength,
  ...rest
}) => {
  return (
    <div className="form-group text-left">
      <label htmlFor={name} className="formLabel">
        {label}
        <span className="required">{isRequired ? " *" : ""}</span>
      </label>

      <input
        {...rest}
        type={type}
        name={name}
        id={name}
        className={`form-control formData ${error ? "is-invalid" : ""}`}
        value={value}
        placeholder={placeholder}
        readOnly={isReadOnly}
        required={isRequired}
        maxLength={maxLength}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};

export default Input;
