import PermissionName from "./PermissionName";
import RoleAuthorizationAPI from "../../../api/RoleAuthorization/RoleAuthorizationAPI";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import lsm from "../../../api/lsm";

class Permissions {
  constructor() {
    this.rolePermissions = [];
    this.specialPermissions = [];
    this.selectedState = "";
    this.profileAssociatedStates = [];
    this.currentUserUniqueId = "";

    this.referenceDataPermissions = {
      createGlobal: `${PermissionName.entity.referenceData}_${PermissionName.right.create}_${PermissionName.scope.global}`,
      editGlobal: `${PermissionName.entity.referenceData}_${PermissionName.right.edit}_${PermissionName.scope.global}`,
      viewGlobal: `${PermissionName.entity.referenceData}_${PermissionName.right.view}_${PermissionName.scope.global}`,
      configureAssignedStates: `${PermissionName.entity.referenceData}_${PermissionName.right.configure}_${PermissionName.scope.assignedStates}`,
      configureAnyState: `${PermissionName.entity.referenceData}_${PermissionName.right.configure}_${PermissionName.scope.anyState}`,
      viewAssignedStates: `${PermissionName.entity.referenceData}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
      viewAnyState: `${PermissionName.entity.referenceData}_${PermissionName.right.view}_${PermissionName.scope.anyState}`,
    };
    this.contactPermissions = {
      create: `${PermissionName.entity.contact}_${PermissionName.right.create}_${PermissionName.scope.all}`,
      edit: `${PermissionName.entity.contact}_${PermissionName.right.edit}_${PermissionName.scope.all}`,
      view: `${PermissionName.entity.contact}_${PermissionName.right.view}_${PermissionName.scope.all}`,
    };
    this.specialGroupPermissions = {
      createAssignedStates: `${PermissionName.entity.specialGroup}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      createAnyState: `${PermissionName.entity.specialGroup}_${PermissionName.right.create}_${PermissionName.scope.anyState}`,
      editAssignedStates: `${PermissionName.entity.specialGroup}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      editAnyState: `${PermissionName.entity.specialGroup}_${PermissionName.right.edit}_${PermissionName.scope.anyState}`,
      viewAssignedStates: `${PermissionName.entity.specialGroup}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
      viewAnyState: `${PermissionName.entity.specialGroup}_${PermissionName.right.view}_${PermissionName.scope.anyState}`,
    };
    this.agreementsPermissions = {
      createAssignedStates: `${PermissionName.entity.agreement}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      createAnyState: `${PermissionName.entity.agreement}_${PermissionName.right.create}_${PermissionName.scope.anyState}`,
      editAssignedStates: `${PermissionName.entity.agreement}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      editAnyState: `${PermissionName.entity.agreement}_${PermissionName.right.edit}_${PermissionName.scope.anyState}`,
      viewAssignedStates: `${PermissionName.entity.agreement}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
      viewAnyState: `${PermissionName.entity.agreement}_${PermissionName.right.view}_${PermissionName.scope.anyState}`,
      flagAssignedStates: `${PermissionName.entity.agreement}_${PermissionName.right.flag}_${PermissionName.scope.assignedStates}`,
      flagAnyState: `${PermissionName.entity.agreement}_${PermissionName.right.flag}_${PermissionName.scope.anyState}`,
      approveAssignedStates: `${PermissionName.entity.agreement}_${PermissionName.right.approve}_${PermissionName.scope.assignedStates}`,
      extendFLAAssignedStates: `${PermissionName.entity.agreement}_${PermissionName.right.extendFLA}_${PermissionName.scope.assignedStates}`,
    };
    this.propertyPermissions = {
      createAssignedStates: `${PermissionName.entity.property}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      createAnyState: `${PermissionName.entity.property}_${PermissionName.right.create}_${PermissionName.scope.anyState}`,
      editAssignedStates: `${PermissionName.entity.property}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      editAnyState: `${PermissionName.entity.property}_${PermissionName.right.edit}_${PermissionName.scope.anyState}`,
      viewAssignedStates: `${PermissionName.entity.property}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
      viewAnyState: `${PermissionName.entity.property}_${PermissionName.right.view}_${PermissionName.scope.anyState}`,
    };
    this.conflictPermissions = {
      create: `${PermissionName.entity.conflict}_${PermissionName.right.create}_${PermissionName.scope.all}`,
      edit: `${PermissionName.entity.conflict}_${PermissionName.right.edit}_${PermissionName.scope.all}`,
      flag: `${PermissionName.entity.conflict}_${PermissionName.right.flag}_${PermissionName.scope.all}`,
      view: `${PermissionName.entity.conflict}_${PermissionName.right.view}_${PermissionName.scope.all}`,
    };
    this.taWorkTaskPermissions = {
      create: `${PermissionName.entity.taWorkTask}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      edit: `${PermissionName.entity.taWorkTask}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      viewAnyState: `${PermissionName.entity.taWorkTask}_${PermissionName.right.viewAny}_${PermissionName.scope.assignedStates}`,
      viewMine: `${PermissionName.entity.taWorkTask}_${PermissionName.right.viewMine}_${PermissionName.scope.assignedStates}`,
    };
    this.aerialWorkTaskPermissions = {
      create: `${PermissionName.entity.aerialWorkTask}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      edit: `${PermissionName.entity.aerialWorkTask}_${PermissionName.right.edit}_${PermissionName.scope.supervisorWithinDistrict}`,
      editMine: `${PermissionName.entity.aerialWorkTask}_${PermissionName.right.editMine}_${PermissionName.scope.assignedStates}`,
      editAny: `${PermissionName.entity.aerialWorkTask}_${PermissionName.right.editAny}_${PermissionName.scope.assignedStates}`,
      view: `${PermissionName.entity.aerialWorkTask}_${PermissionName.right.view}_${PermissionName.scope.supervisorWithinDistrict}`,
      viewMine: `${PermissionName.entity.aerialWorkTask}_${PermissionName.right.viewMine}_${PermissionName.scope.assignedStates}`,
      viewAny: `${PermissionName.entity.aerialWorkTask}_${PermissionName.right.viewAny}_${PermissionName.scope.assignedStates}`,
    };
    this.adminWorkPermissions = {
      create: `${PermissionName.entity.adminWork}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      edit: `${PermissionName.entity.adminWork}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      view: `${PermissionName.entity.adminWork}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
    };
    this.pvsPermissions = {
      createAssignedStates: `${PermissionName.entity.pvs}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      createAnyState: `${PermissionName.entity.pvs}_${PermissionName.right.create}_${PermissionName.scope.anyState}`,
      editAssignedStates: `${PermissionName.entity.pvs}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      editAnyState: `${PermissionName.entity.pvs}_${PermissionName.right.edit}_${PermissionName.scope.anyState}`,
      viewPerformedBy: `${PermissionName.entity.pvs}_${PermissionName.right.view}_${PermissionName.scope.performedBy}`,
      viewOnAgreement: `${PermissionName.entity.pvs}_${PermissionName.right.view}_${PermissionName.scope.onAgreement}`,
      viewAssignedProjects: `${PermissionName.entity.pvs}_${PermissionName.right.view}_${PermissionName.scope.assignedProjects}`,
      viewAssignedStates: `${PermissionName.entity.pvs}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
      viewAnyState: `${PermissionName.entity.pvs}_${PermissionName.right.view}_${PermissionName.scope.anyState}`,
      viewSupervisor: `${PermissionName.entity.pvs}_${PermissionName.right.view}_${PermissionName.scope.districtSupervisor}`,
      flagAssignedStates: `${PermissionName.entity.pvs}_${PermissionName.right.flag}_${PermissionName.scope.assignedStates}`,
      flagAnyState: `${PermissionName.entity.pvs}_${PermissionName.right.flag}_${PermissionName.scope.anyState}`,
    };
    this.inventoryPermissions = {
      createAssignedStates: `${PermissionName.entity.inventory}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      editAssignedStates: `${PermissionName.entity.inventory}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      viewAssignedStates: `${PermissionName.entity.inventory}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
      viewAnyState: `${PermissionName.entity.inventory}_${PermissionName.right.view}_${PermissionName.scope.anyState}`,
    };
    this.userProvisionPermissions = {
      createRecordGlobal: `${PermissionName.entity.user}_${PermissionName.right.create}_${PermissionName.scope.global}`,
      createRecordAssignedStates: `${PermissionName.entity.user}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      editRecordGlobal: `${PermissionName.entity.user}_${PermissionName.right.edit}_${PermissionName.scope.global}`,
      editRecordSelf: `${PermissionName.entity.user}_${PermissionName.right.edit}_${PermissionName.scope.self}`,
      editRecordAssignedStates: `${PermissionName.entity.user}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      deactivateRecordGlobal: `${PermissionName.entity.user}_${PermissionName.right.deactivate}_${PermissionName.scope.global}`,
      viewRecordGlobal: `${PermissionName.entity.user}_${PermissionName.right.view}_${PermissionName.scope.global}`,
      viewRecordAssignedStates: `${PermissionName.entity.user}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
      createRoleGlobal: `${PermissionName.entity.role}_${PermissionName.right.create}_${PermissionName.scope.global}`,
      editRoleGlobal: `${PermissionName.entity.role}_${PermissionName.right.edit}_${PermissionName.scope.global}`,
      viewRoleGlobal: `${PermissionName.entity.role}_${PermissionName.right.view}_${PermissionName.scope.global}`,
      viewRoleAssignmentAnyState: `${PermissionName.entity.role}_${PermissionName.right.viewAssignments}_${PermissionName.scope.anyState}`,
      viewRoleAssignmentAssignedStates: `${PermissionName.entity.role}_${PermissionName.right.viewAssignments}_${PermissionName.scope.assignedStates}`,
      editRoleAssignmentAnyState: `${PermissionName.entity.role}_${PermissionName.right.editAssignments}_${PermissionName.scope.anyState}`,
      editRoleAssignmentAssignedStates: `${PermissionName.entity.role}_${PermissionName.right.editAssignments}_${PermissionName.scope.assignedStates}`,
      assignRoleAnyState: `${PermissionName.entity.role}_${PermissionName.right.assign}_${PermissionName.scope.anyState}`,
      assignRoleAssignedStates: `${PermissionName.entity.role}_${PermissionName.right.assign}_${PermissionName.scope.assignedStates}`,
      editRoleSpecialPermissions: `${PermissionName.entity.role}_${PermissionName.right.edit}_${PermissionName.scope.specialPermissions}`,
      editSpecialPermissionsAssignedStates: `${PermissionName.entity.user}_${PermissionName.entity.specialpermissions}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
    };
    this.permitsPermissions = {
      createPermitsAssignedStates: `${PermissionName.entity.permits}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      editPermitsAssignedStates: `${PermissionName.entity.permits}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      viewPermitsAssignedStates: `${PermissionName.entity.permits}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
    };
    this.projectPermissions = {
      createProjectsAnyStates: `${PermissionName.entity.project}_${PermissionName.right.create}_${PermissionName.scope.anyState}`,
      viewProjectsAnyStates: `${PermissionName.entity.project}_${PermissionName.right.view}_${PermissionName.scope.anyState}`,
      editProjectsAnyStates: `${PermissionName.entity.project}_${PermissionName.right.edit}_${PermissionName.scope.anyState}`,
      assignUserProjectsAnyStates: `${PermissionName.entity.project}_${PermissionName.right.assignuser}_${PermissionName.scope.anyState}`,
      assignUserProjectsAssignedStates: `${PermissionName.entity.project}_${PermissionName.right.assignuser}_${PermissionName.scope.assignedStates}`,
      assignUserDistrict: `${PermissionName.entity.project}_${PermissionName.right.assignuser}_${PermissionName.scope.district}`,
    };
    this.districtsPermissions = {
      createDistrictsAssignedStates: `${PermissionName.entity.district}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      editDistrictsAssignedStates: `${PermissionName.entity.district}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      deleteDistrictsAssignedStates: `${PermissionName.entity.district}_${PermissionName.right.delete}_${PermissionName.scope.assignedStates}`,
      assignSupervisorAssignedStates: `${PermissionName.entity.district}_${PermissionName.right.assignSupervisor}_${PermissionName.scope.assignedStates}`,
      assignAdditionalSupervisorsAssignedStates: `${PermissionName.entity.district}_${PermissionName.right.assignAdditionalSupervisors}_${PermissionName.scope.assignedStates}`,
      assignSupervisorAssignedDistricts: `${PermissionName.entity.district}_${PermissionName.right.assignSupervisor}_${PermissionName.scope.assignedDistricts}`,
      assignAdditionalSupervisorsAssignedDistricts: `${PermissionName.entity.district}_${PermissionName.right.assignAdditionalSupervisors}_${PermissionName.scope.assignedDistricts}`,
      assignEmployeeAssignedStates: `${PermissionName.entity.district}_${PermissionName.right.assignEmployees}_${PermissionName.scope.assignedStates}`,
      assignEmployeeAssignedDistricts: `${PermissionName.entity.district}_${PermissionName.right.assignEmployees}_${PermissionName.scope.assignedDistricts}`,
    };
    this.warehousePermissions = {
      createWarehouseAssignedStates: `${PermissionName.entity.warehouse}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      editWarehouseAssignedStates: `${PermissionName.entity.warehouse}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      viewWarehouseAssignedStates: `${PermissionName.entity.warehouse}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
      manageInventoryAssignedWarehouse: `${PermissionName.entity.warehouse}_${PermissionName.right.manageinventory}_${PermissionName.scope.assignedWarehouse}`,
      worktaskInteractionAssignedWarehouse: `${PermissionName.entity.warehouse}_${PermissionName.right.worktaskinteraction}_${PermissionName.scope.assignedWarehouse}`,
      reconcileInventoryAssignedWarehouse: `${PermissionName.entity.warehouse}_${PermissionName.right.reconcileinventory}_${PermissionName.scope.assignedWarehouse}`,
    };
    this.rabiessPermissions = {
      createRabiesAssignedStates: `${PermissionName.entity.rabies}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      editRabiesAssignedStates: `${PermissionName.entity.rabies}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      viewRabiesAssignedStates: `${PermissionName.entity.rabies}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
    };
    this.samplesPermissions = {
      createSamplesAssignedStates: `${PermissionName.entity.samples}_${PermissionName.right.create}_${PermissionName.scope.assignedStates}`,
      editSamplesAssignedStates: `${PermissionName.entity.samples}_${PermissionName.right.edit}_${PermissionName.scope.assignedStates}`,
      viewSamplesAssignedStates: `${PermissionName.entity.samples}_${PermissionName.right.view}_${PermissionName.scope.assignedStates}`,
    };
    this.notificationPermissions = {
      createAll: `${PermissionName.entity.notification}_${PermissionName.right.create}_${PermissionName.scope.all}`,
      editAll: `${PermissionName.entity.notification}_${PermissionName.right.edit}_${PermissionName.scope.all}`,
      viewAll: `${PermissionName.entity.notification}_${PermissionName.right.view}_${PermissionName.scope.all}`,
    };
  }

  //constructors cannot be async, so init design pattern is used. init() must be called before object can be used.
  async init(userData, currentUserProfile) {
    let returnObj = {};
    if (currentUserProfile) {
      this.profileAssociatedStates = currentUserProfile.profileAssociatedStates;
      if (currentUserProfile.roleUniqueReference) {
        const getRolePermissionsResponse = await RoleAuthorizationAPI.GetPermissionsForRole(
          currentUserProfile.roleUniqueReference
        );
        if (getRolePermissionsResponse?.successful) {
          this.rolePermissions = [...getRolePermissionsResponse.data];
          returnObj.rolePermissions = true;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to get user's permissions",
            overrideErrorForUser:
              "Unable to get user's role permissions. " +
              (getRolePermissionsResponse ? getRolePermissionsResponse?.message : "") +
              " Please try logging in again or ask your system administator for help.",
            apiName: "GetPermissionsForRole",
            responseMessage: getRolePermissionsResponse?.message,
            responseUnsuccessful: getRolePermissionsResponse?.unsuccessful,
          });
          await new Promise((r) => setTimeout(r, 5000));
          lsm.RedirectToLogin("Permissions-init");
          return;
        }
      }
    }

    if (userData) {
      this.currentUserUniqueId = userData.wsUserUniqueIdentifier;
      this.selectedState = userData.currentlySelectedStateOfOperation;
      if (userData.userProfiles?.length > 0 && userData.selectedProfile) {
        const selectedProfile = userData.userProfiles.find(
          (profile) => profile.profileName === userData.selectedProfile
        );
        if (selectedProfile?.specialPermissionsForProfile?.length > 0) {
          this.specialPermissions = [...selectedProfile.specialPermissionsForProfile];
          returnObj.specialPermissions = true;
        }
      }
    }
    return returnObj;
  }

  initFromSessionStorage(storedPermissions) {
    this.profileAssociatedStates = storedPermissions.profileAssociatedStates;
    this.rolePermissions = storedPermissions.rolePermissions;

    this.currentUserUniqueId = localStorage.getItem("currentUserRole")
      ? JSON.parse(localStorage.getItem("currentUserRole"))?.wsUserUniqueIdentifier
      : "";
    this.selectedState = localStorage.getItem("currentState")
      ? JSON.parse(localStorage.getItem("currentState"))?.stateGuid
      : "";
    this.specialPermissions = storedPermissions.specialPermissionsForProfile
      ? storedPermissions.specialPermissionsForProfile
      : [];
  }

  //#region Reference Data
  canICreateReferenceData() {
    return this.rolePermissions.find((p) => p.name === this.referenceDataPermissions.createGlobal);
  }

  canIEditReferenceData() {
    return this.rolePermissions.find((p) => p.name === this.referenceDataPermissions.editGlobal);
  }

  canIViewNationalRefData() {
    return (
      this.rolePermissions.find(
        (p) =>
          p.name === this.referenceDataPermissions.viewGlobal || p.name === this.referenceDataPermissions.viewAnyState
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.referenceDataPermissions.viewAnyState ||
          p.specialPermission?.name === this.referenceDataPermissions.viewGlobal
      )
    );
  }

  canIViewReferenceData(stateId = this.selectedState) {
    return (
      this.rolePermissions.find(
        (p) =>
          p.name === this.referenceDataPermissions.viewGlobal || p.name === this.referenceDataPermissions.viewAnyState
      ) ||
      (this.rolePermissions.some((p) => p.name === this.referenceDataPermissions.viewAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.referenceDataPermissions.viewAnyState ||
          p.specialPermission?.name === this.referenceDataPermissions.viewGlobal
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.referenceDataPermissions.viewAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIConfigureRefData(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.referenceDataPermissions.configureAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.referenceDataPermissions.configureAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.referenceDataPermissions.configureAnyState
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.referenceDataPermissions.configureAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  //#endregion

  //#region Contacts
  canICreateContacts() {
    return this.rolePermissions.find((p) => p.name === this.contactPermissions.create);
  }

  canIEditContacts() {
    return this.rolePermissions.find((p) => p.name === this.contactPermissions.edit);
  }

  canIViewContacts() {
    return this.rolePermissions.find((p) => p.name === this.contactPermissions.view);
  }
  //#endregion

  //#region Agreement
  canICreateAgreements(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.agreementsPermissions.createAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.agreementsPermissions.createAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.agreementsPermissions.createAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.agreementsPermissions.createAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditAgreements(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.agreementsPermissions.editAnyState) ||
      (this.rolePermissions.find((p) => p.name === this.agreementsPermissions.editAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.agreementsPermissions.editAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.agreementsPermissions.editAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewAgreements(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.agreementsPermissions.viewAnyState) ||
      (this.rolePermissions.find((p) => p.name === this.agreementsPermissions.viewAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.agreementsPermissions.viewAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.agreementsPermissions.viewAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIFlagAgreements(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.agreementsPermissions.flagAnyState) ||
      (this.rolePermissions.find((p) => p.name === this.agreementsPermissions.flagAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.agreementsPermissions.flagAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.agreementsPermissions.flagAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIApproveAgreementsForAssignedStates() {
    return this.rolePermissions.find((p) => p.name === this.agreementsPermissions.approveAssignedStates);
  }

  CanIExtendFLAForAssignedstates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.agreementsPermissions.extendFLAAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.agreementsPermissions.extendFLAAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion

  //#region Property
  canICreateProperties(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.propertyPermissions.createAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.propertyPermissions.createAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.propertyPermissions.createAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.propertyPermissions.createAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditProperties(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.propertyPermissions.editAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.propertyPermissions.editAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.propertyPermissions.editAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.propertyPermissions.editAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewProperties(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.propertyPermissions.viewAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.propertyPermissions.viewAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.propertyPermissions.viewAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.propertyPermissions.viewAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion

  //#region Conflict
  canICreateConflicts() {
    return this.rolePermissions.find((p) => p.name === this.conflictPermissions.create);
  }

  canIEditConflicts() {
    return this.rolePermissions.find((p) => p.name === this.conflictPermissions.edit);
  }

  canIFlagConflicts() {
    return this.rolePermissions.find((p) => p.name === this.conflictPermissions.flag);
  }

  canIViewConflicts() {
    return this.rolePermissions.find((p) => p.name === this.conflictPermissions.view);
  }
  //#endregion

  //#region Aerial Work Task

  canICreateAerialWorkTask(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.aerialWorkTaskPermissions.create) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.aerialWorkTaskPermissions.create &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewAerialWorkTask(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.aerialWorkTaskPermissions.view) ||
      this.rolePermissions.find((p) => p.name === this.aerialWorkTaskPermissions.viewMine) ||
      this.rolePermissions.find(
        (p) => p.name === this.aerialWorkTaskPermissions.viewAny && this.profileAssociatedStates.includes(stateId)
      ) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.aerialWorkTaskPermissions.view) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.aerialWorkTaskPermissions.viewMine) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.aerialWorkTaskPermissions.viewAny &&
          this.profileAssociatedStates.includes(stateId)
      )
    );
  }

  //#endregion

  //#region TA Work Task

  canICreateTaWorkTasks(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.taWorkTaskPermissions.create) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.taWorkTaskPermissions.create &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditTaWorkTasks(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.taWorkTaskPermissions.edit) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.taWorkTaskPermissions.edit &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewTaWorkTasks(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.taWorkTaskPermissions.viewAnyState) ||
      this.rolePermissions.some((p) => p.name === this.taWorkTaskPermissions.viewMine) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.taWorkTaskPermissions.viewAnyState) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.taWorkTaskPermissions.viewMine)
    );
  }
  //#endregion

  //#region Admin Work

  canICreateAdminWork(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.adminWorkPermissions.create) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.adminWorkPermissions.create &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditAdminWork(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.adminWorkPermissions.edit) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.adminWorkPermissions.edit &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewAdminWork(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.adminWorkPermissions.view) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.adminWorkPermissions.view &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion

  //#region Permits

  canICreatePermitsAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.permitsPermissions.createPermitsAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.permitsPermissions.createPermitsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditPermitsAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.permitsPermissions.editPermitsAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.permitsPermissions.editPermitsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewPermitsAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.permitsPermissions.viewPermitsAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.permitsPermissions.viewPermitsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion

  //#region PVS
  canICreatePVS(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.pvsPermissions.createAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.pvsPermissions.createAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.pvsPermissions.createAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.pvsPermissions.createAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditPVS(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.pvsPermissions.editAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.pvsPermissions.editAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.pvsPermissions.editAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.pvsPermissions.editAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIFlagPVS(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.pvsPermissions.flagAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.pvsPermissions.flagAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.pvsPermissions.flagAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.pvsPermissions.flagAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewPVSPerformedBy() {
    return this.rolePermissions.find((p) => p.name === this.pvsPermissions.viewPerformedBy);
  }

  canIViewPVSOnAgreement() {
    return this.rolePermissions.find((p) => p.name === this.pvsPermissions.viewOnAgreement);
  }

  canIViewPVSForAssignedProjects() {
    return this.rolePermissions.find((p) => p.name === this.pvsPermissions.viewAssignedProjects);
  }

  canIViewPVSForAssignedStates() {
    return this.rolePermissions.find((p) => p.name === this.pvsPermissions.editAssignedStates);
  }

  canIViewPVSForAnyState() {
    return this.rolePermissions.find((p) => p.name === this.pvsPermissions.editAnyState);
  }

  canIViewPVSForSupervisor() {
    return this.rolePermissions.find((p) => p.name === this.pvsPermissions.viewSupervisor);
  }
  //#endregion

  //#region Inventory
  canICreateInventoryForAssignedStates() {
    return this.rolePermissions.find((p) => p.name === this.inventoryPermissions.createAssignedStates);
  }

  canIEditInventoryForAssignedStates() {
    return this.rolePermissions.find((p) => p.name === this.inventoryPermissions.editAssignedStates);
  }

  canIViewInventory(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.inventoryPermissions.viewAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.inventoryPermissions.viewAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.inventoryPermissions.viewAnyState) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.inventoryPermissions.viewAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion

  //#region User Provisioning & Management
  canICreateUserRecord(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.createRecordGlobal) ||
      (this.rolePermissions.some((p) => p.name === this.userProvisionPermissions.createRecordAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.userProvisionPermissions.createRecordGlobal
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.createRecordAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditUserRecord(userId = this.currentUserUniqueId, stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.editRecordGlobal) ||
      (this.rolePermissions.some((p) => p.name === this.userProvisionPermissions.editRecordAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      (this.rolePermissions.some((p) => p.name === this.userProvisionPermissions.editRecordSelf) &&
        userId === this.currentUserUniqueId) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.userProvisionPermissions.editRecordGlobal
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.editRecordAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.editRecordSelf &&
          userId === this.currentUserUniqueId
      )
    );
  }

  canIEditGlobalUserRecord() {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.editRecordGlobal) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.userProvisionPermissions.editRecordGlobal)
    );
  }

  canIEditUserRecordForAssignedStates(stateId = this.selectedState) {
    return (
      (this.rolePermissions.some((p) => p.name === this.userProvisionPermissions.editRecordAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.editRecordAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewUserRecord(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.viewRecordGlobal) ||
      (this.rolePermissions.some((p) => p.name === this.userProvisionPermissions.viewRecordAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.userProvisionPermissions.viewRecordGlobal
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.viewRecordAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewGlobalUserRecord() {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.viewRecordGlobal) ||
      this.specialPermissions.some((p) => p.specialPermission?.name === this.userProvisionPermissions.viewRecordGlobal)
    );
  }

  canIDeactivateUser() {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.deactivateRecordGlobal) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.userProvisionPermissions.deactivateRecordGlobal
      )
    );
  }

  canICreateRole() {
    return this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.createRoleGlobal);
  }

  canIEditRole() {
    return this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.editRoleGlobal);
  }

  canIViewGlobalRole() {
    return this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.viewRoleGlobal);
  }

  canIViewRoleAssignment(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.viewRoleAssignmentAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.userProvisionPermissions.viewRoleAssignmentAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.userProvisionPermissions.viewRoleAssignmentAnyState
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.viewRoleAssignmentAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditRoleAssignment(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.editRoleAssignmentAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.userProvisionPermissions.editRoleAssignmentAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.userProvisionPermissions.editRoleAssignmentAnyState
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.editRoleAssignmentAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIAssignRole(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.assignRoleAnyState) ||
      (this.rolePermissions.some((p) => p.name === this.userProvisionPermissions.assignRoleAssignedStates) &&
        this.profileAssociatedStates.includes(stateId)) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.userProvisionPermissions.assignRoleAnyState
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.assignRoleAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditProfileSpecialPermissions() {
    return this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.editRoleSpecialPermissions);
  }

  canIEditSpecialPermissionsAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.userProvisionPermissions.editSpecialPermissionsAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.userProvisionPermissions.editSpecialPermissionsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  doIHaveUserProvisionPermissions() {
    const userProvisionPermissionNames = Object.values(this.userProvisionPermissions);
    return (
      this.rolePermissions.some((p) => userProvisionPermissionNames.includes(p.name)) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name && userProvisionPermissionNames.includes(p.specialPermission.name)
      )
    );
  }
  //#endregion

  //#region Projects

  canICreateProjectsAnyStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.projectPermissions.createProjectsAnyStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.projectPermissions.createProjectsAnyStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewProjectsAnyStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.projectPermissions.viewProjectsAnyStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.projectPermissions.viewProjectsAnyStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditProjectsAnyStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.projectPermissions.editProjectsAnyStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.projectPermissions.editProjectsAnyStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIAssignUserProjectsAnyStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.projectPermissions.assignUserProjectsAnyStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.projectPermissions.assignUserProjectsAnyStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIAssignUserProjectsOnAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.projectPermissions.assignUserProjectsAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.projectPermissions.assignUserProjectsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion

  //#region Districts

  canICreateDistrictsForAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.districtsPermissions.createDistrictsAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.createDistrictsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditDistrictsForAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.districtsPermissions.editDistrictsAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.editDistrictsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIDeleteDistrictsForAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.districtsPermissions.deleteDistrictsAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.deleteDistrictsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIAssignDistrictSupervisorsForAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.districtsPermissions.assignSupervisorAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.assignSupervisorAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIAssignEmployeeForAssignedDistricts(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.districtsPermissions.assignEmployeeAssignedDistricts) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.assignEmployeeAssignedDistricts &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIAssignEmployeeForAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.districtsPermissions.assignEmployeeAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.assignEmployeeAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIAssignDistrictSupervisorsForAssignedDistricts(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.districtsPermissions.assignSupervisorAssignedDistricts) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.assignSupervisorAssignedDistricts &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  canIAssignDistrictAdditionalSupervisorsForAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find(
        (p) => p.name === this.districtsPermissions.assignAdditionalSupervisorsAssignedStates
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.assignAdditionalSupervisorsAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  canIAssignDistrictAdditionalSupervisorsForAssignedDistricts(stateId = this.selectedState) {
    return (
      this.rolePermissions.find(
        (p) => p.name === this.districtsPermissions.assignAdditionalSupervisorsAssignedDistricts
      ) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.districtsPermissions.assignAdditionalSupervisorsAssignedDistricts &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion

  //#region Warehouse
  canICreateWarehouses(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.warehousePermissions.createWarehouseAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.warehousePermissions.createWarehouseAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditWarehouses(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.warehousePermissions.editWarehouseAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.warehousePermissions.editWarehouseAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewWarehouses(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.warehousePermissions.viewWarehouseAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.warehousePermissions.viewWarehouseAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIManageInventoryAssignedWarehouse(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.warehousePermissions.manageInventoryAssignedWarehouse) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.warehousePermissions.manageInventoryAssignedWarehouse
      )
    );
  }

  canIWorktaskInteractionAssignedWarehouse(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.warehousePermissions.worktaskInteractionAssignedWarehouse) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.warehousePermissions.worktaskInteractionAssignedWarehouse
      )
    );
  }

  canIReconcileInventoryAssignedWarehouse(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.warehousePermissions.reconcileInventoryAssignedWarehouse) ||
      this.specialPermissions.some(
        (p) => p.specialPermission?.name === this.warehousePermissions.reconcileInventoryAssignedWarehouse
      )
    );
  }
  //#endregion

  //#region Rabies

  canICreateRabiesAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.rabiessPermissions.createRabiesAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.rabiessPermissions.createRabiesAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditRabiesAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.rabiessPermissions.editRabiesAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.rabiessPermissions.editRabiesAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewRabiesAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.rabiessPermissions.viewRabiesAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.rabiessPermissions.viewRabiesAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion

  //#region Samples

  canICreateSamplesAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.samplesPermissions.createSamplesAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.samplesPermissions.createSamplesAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIEditSamplesAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.samplesPermissions.editSamplesAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.samplesPermissions.editSamplesAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }

  canIViewSamplesAssignedStates(stateId = this.selectedState) {
    return (
      this.rolePermissions.find((p) => p.name === this.samplesPermissions.viewSamplesAssignedStates) ||
      this.specialPermissions.some(
        (p) =>
          p.specialPermission?.name === this.samplesPermissions.viewSamplesAssignedStates &&
          p.specialPermissionAssociatedStates?.includes(stateId)
      )
    );
  }
  //#endregion
}
export default Permissions;
