import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import NavbarUtilities from "../navbar-utilities/navbar-utilities";
import "./navbar.css";

class TopNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      states: props.userData.states,
      currentState: props.userData.currentState,
      username: props.userData.username,
      identity: props.userData.identity,
      userProfiles: props.userData.userProfiles,
      currentUserProfile: props.userData.currentUserProfile,
      permissions: props.permissions,
    };
  }

  handleSelectState = (e) => {
    this.setState({ currentState: e });
    this.props.onStateSelect(e);
  };

  handleSelectRole = (e) => {
    this.setState({ currentUserProfile: e });
    this.props.onRoleSelect(e);
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      states: nextProps.userData.states,
      currentState: nextProps.userData.currentState,
      username: nextProps.userData.username,
      userProfiles: nextProps.userData.userProfiles,
      currentUserProfile: nextProps.userData.currentUserProfile,
      permissions: nextProps.permissions,
    };
  }

  render() {
    return (
      <Navbar
        className="px-0 py-0 main-navbar"
        expand="lg"
        data-testid="mainNavbar"
        fixed="top"
        style={{ marginBottom: "60px" }}
      >
        <Nav className="main-hamberguer-menu clickable" onClick={this.props.onMenuToggled} data-testid="hamberguerMenu">
          <i
            className={this.props.isSidebarDisplayed ? "fa fa-chevron-left nav-icons" : "fa fa-chevron-right nav-icons"}
            aria-hidden="true"
            title="Expand Side Menu"
          ></i>
        </Nav>
        <div onClick={this.props.closeMenu} style={{ display: "contents" }}>
          <Nav className="mr-auto">
            <Nav.Link as="h4" href="#home" className="navbar-logo">
              OARS
            </Nav.Link>
          </Nav>
          <NavbarUtilities
            currentState={this.state.currentState}
            states={this.state.states}
            username={this.state.username}
            identity={this.state.identity}
            onStateSelect={this.handleSelectState}
            userProfiles={this.state.userProfiles}
            currentUserProfile={this.state.currentUserProfile}
            onRoleSelect={this.handleSelectRole}
            permissions={this.state.permissions}
            onSearchBoxToggled={this.props.onSearchBoxToggled}
          />
        </div>
      </Navbar>
    );
  }
}

export default TopNavbar;
