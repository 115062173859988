import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./AdminCenter.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Col, Dropdown, Button, Card, Image, Form, InputGroup } from "react-bootstrap";
import CustomTextFilter from "../common/customTextFilter";
import ReferenceFileCardHeader from "../ReferenceFiles/ReferenceFileCardHeader";
import filterFactory, { selectFilter, customFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import searchIcon from "../../assets/search.svg";
import deactivateUser from "../../assets/deactivateUser.PNG";
import resetPassword from "../../assets/resetPassword.PNG";
import chevronDown from "../../assets/chevron-down.svg";
import chevronRight from "../../assets/chevron-right.svg";
import CreateUserModal from "../UserRoles/Users/CreateUserModal";
import UtilityFunctions from "../common/UtilityFunctions";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import moment from "moment";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigFilteredUsers: [],
      usingBigFilter: false,
      users: [],
      tableEditingIndex: -1,
      listEditingIndex: -1,
      defaultSort: [{ dataField: "userName", order: "asc" }],
      filterStates: [],
      columns: this.getColumns(),
      showCreateUserModal: false,
      tableUpdated: true,
      newUserType: "",
      newUserPhoneNumber: "",
      newUserActive: "",
      newUserEmail: "",
      userTypes: [],
      states: [],
      showCreateUserSuccessModal: false,
      userNavigationDetails: "",
      extendedSubRowData: [],
      expandedRows: [],
      isActiveFilter: null,

      userNameFilterValue: "",
      typeFilterValue: "",
      emailFilterValue: "",
      phoneNumberFilterValue: "",
      cityAndStateFilterValue: "",
      profileRoleFilterValue: "",
      profileStateFilterValue: "",
      lastModifiedFilterValue: "",
      activeFilterValue: "",
      sortColumn: "userName",
      isAscending: true,
      waitingForAPIData: false,
    };
  }

  getColumns() {
    return [
      {
        dataField: "wsUserUniqueIdentifier",
        hidden: true,
        csvText: "User Unique Id",
      },
      this.getUserSelectColumn(),
      this.getUserNameColumn(),
      this.getUserTypeColumn(),
      this.getEmailColumn(),
      this.getPhoneNumberColumn(),
      this.getCityStateColumn(),
      this.getProfileRoleColumn(),
      this.getProfileStateColumn(),
      this.getLastModifiedColumn(),
      this.getIsActiveColumn(),
      this.getEditColumn(),
    ];
  }

  getProfileStateCodes(row, stateObjectData) {
    let profileStates = [];
    if (row.userProfiles?.length > 0) {
      row.userProfiles.map((profile) => {
        return profile.profileAssociatedStates?.map((profileStateId) => {
          const currentState = stateObjectData.states.find((s) => s.stateUniqueIdentifier === profileStateId);
          if (currentState && !profileStates.includes(currentState.state_code)) {
            profileStates.push(currentState.state_code);
          }
        });
      });
    }
    return profileStates;
  }

  getEditColumn() {
    return {
      dataField: "edit",
      text: "Edit",
      csvExport: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return this.props.permissions?.canIEditGlobalUserRecord() ? (
          <Button
            variant="link"
            className={globalStyles.formDataLinks}
            onClick={() =>
              this.state.listEditingIndex === -1 ? this.saveRowQuickEdit(row, rowIndex) : this.quickEditRow()
            }
            hidden={this.state.listEditingIndex !== -1 && this.state.listEditingIndex !== rowIndex}
          >
            {formatExtraData.listEditingIndex !== -1 && rowIndex === formatExtraData.tableEditingIndex
              ? "Save"
              : "Quick Edit"}
          </Button>
        ) : (
          ""
        );
      },
      formatExtraData: this.state,
      headerStyle: () => {
        return { width: "8%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
      },
      headerAlign: "right",
      align: "right",
      headerAttrs: {
        id: "userEditHeader",
        title: "Edit Column Header",
      },
      attrs: { headers: "userEditHeader" },
    };
  }

  getIsActiveColumn() {
    return {
      dataField: "isActive",
      text: "Active",
      sort: true,
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { backgroundColor: "#f3f3f3", paddingBottom: "75px", verticalAlign: "text-top", paddingRight: "0px" };
      },
      style: {},
      headerAttrs: { id: "userActiveHeader" },
      attrs: { headers: "userActiveHeader", tabIndex: "0" },
      filter: selectFilter({
        options: { true: "Active", false: "Inactive" },
        getFilter: (filter) => {
          this.state.isActiveFilter = filter;
        },
        onInput: (e) => {
          let textValue = "";
          if (e.currentTarget.value === "true") {
            textValue = "Active";
          } else if (e.currentTarget.value === "false") {
            textValue = "Inactive";
          }
          this.setState({ activeFilterValue: textValue });
        },
        onFilter: (filterValue, data) => {
          if (filterValue === "") {
            return data;
          }

          return data.filter((x) => x.isActive.toString() === filterValue);
        },
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (formatExtraData.listEditingIndex !== -1 && rowIndex === formatExtraData.tableEditingIndex) {
          return (
            <Form.Control
              as="select"
              type="text"
              onChange={(e) => {
                this.setState({ newUserActive: e.currentTarget.value });
              }}
              value={formatExtraData.newUserActive}
              className={globalStyles.formData}
            >
              <option value="Yes" key="Yes">
                Active
              </option>
              <option value="No" key="No">
                Inactive
              </option>
            </Form.Control>
          );
        } else {
          return (
            <span className={globalStyles.formData}>
              {Boolean(row.isActive) ? <span style={{ color: "#008767" }}>Active</span> : "Inactive"}
            </span>
          );
        }
      },
      formatExtraData: this.state,
    };
  }

  getLastModifiedColumn() {
    return {
      dataField: "lastModifiedDateTimeUTC",
      text: "Last Modified",
      filter: customFilter(),
      filterRenderer: (onFilter, column) => (
        <CustomTextFilter
          onFilter={onFilter}
          column={column}
          dataType="date"
          setColumnFilterValue={this}
          columnName="lastModified"
          title="Last Updated Date"
        />
      ),
      sort: true,
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingBottom: "75px", paddingRight: "0px" };
      },
      style: {},
      headerAttrs: { id: "userPermissionStatesHeader" },
      attrs: { headers: "userPermissionStatesHeader", tabIndex: "0" },
      formatter: (cell, row) => {
        return <span className={globalStyles.formData}>{new Date(cell).toLocaleDateString("en-US")}</span>;
      },
    };
  }

  getStateGuidFromCodeOrName(stateCodeOrName) {
    var stateGuid = this.state.states.find(
      (x) =>
        x.state_code.toLowerCase().includes(stateCodeOrName) || x.state_name.toLowerCase().includes(stateCodeOrName)
    )?.stateUniqueIdentifier;
    return stateGuid;
  }

  getProfileStateColumn() {
    return {
      dataField: "profileState",
      text: "Profile State",
      filter: customFilter({
        onFilter: (filterValue, data) => {
          if (filterValue === "") {
            return data;
          }

          var results = data.filter((x) =>
            x.userProfiles.find((y) =>
              y.profileAssociatedStates.find((z) => z === this.getStateGuidFromCodeOrName(filterValue.toLowerCase()))
            )
          );
          return results;
        },
      }),
      filterRenderer: (onFilter, column) => (
        <CustomTextFilter
          onFilter={onFilter}
          column={column}
          setColumnFilterValue={this}
          columnName="profileState"
          title="User Role Name"
        />
      ),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const profileStateCodesA = this.getProfileStateCodes(rowA, this.state);
        const stateFieldValueA = profileStateCodesA?.length > 0 ? profileStateCodesA.join(", ") : "";
        const profileStateCodesB = this.getProfileStateCodes(rowB, this.state);
        const stateFieldValueB = profileStateCodesB?.length > 0 ? profileStateCodesA.join(", ") : "";
        if (order === "asc") {
          return stateFieldValueB > stateFieldValueA ? 1 : -1;
        } else {
          return stateFieldValueB > stateFieldValueA ? -1 : 1;
        }
      },
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        let profileStateCodes = this.getProfileStateCodes(row, formatExtraData);

        return (
          <p
            className={globalStyles.formData}
            style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
          >
            {profileStateCodes.length > 0 ? profileStateCodes.join(", ") : ""}
          </p>
        );
      },
      formatExtraData: this.state,
      style: {},
      headerAttrs: { id: "userRoleHeader" },
      attrs: { headers: "userRoleHeader", tabIndex: "0" },
    };
  }

  getProfileRoleColumn() {
    return {
      dataField: "profileRole",
      text: "Profile Role",
      filter: customFilter({
        onFilter: (filterValue, data) => {
          return data.filter((x) =>
            x.userProfiles.find((y) => y.profileName.toLowerCase().includes(filterValue.toLowerCase()))
          );
        },
      }),
      filterRenderer: (onFilter, column) => (
        <CustomTextFilter
          onFilter={onFilter}
          column={column}
          setColumnFilterValue={this}
          columnName="profileRole"
          title="User Role Name"
        />
      ),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const rowAValue = rowA.userProfiles?.length === 1 ? rowA.userProfiles[0].profileName : "";
        const rowBValue = rowB.userProfiles?.length === 1 ? rowB.userProfiles[0].profileName : "";
        if (order === "asc") {
          return rowBValue > rowAValue ? 1 : -1;
        } else {
          return rowBValue > rowAValue ? -1 : 1;
        }
      },
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
      },
      formatter: (cell, row, rowIndex) => {
        let profileRole = "";
        if (row.userProfiles?.length > 0) {
          profileRole = row.userProfiles.length === 1 ? row.userProfiles[0].profileName : "Multiple";
        }

        return <span className={globalStyles.formData}>{profileRole}</span>;
      },
      style: {},
      headerAttrs: { id: "userRoleHeader" },
      attrs: { headers: "userRoleHeader", tabIndex: "0" },
    };
  }

  getCityStateColumn() {
    return {
      dataField: "userCityState",
      text: "City/State",
      filter: customFilter({ onFilter: this.filterUsersByCityState }),
      filterRenderer: (onFilter, column) => (
        <CustomTextFilter
          onFilter={onFilter}
          column={column}
          setColumnFilterValue={this}
          columnName="cityAndState"
          title="User City and State"
        />
      ),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const currentStateA = this.state.states?.find(
          (state) => state.stateUniqueIdentifier === rowA.stateOfAddressUniqueReference
        );
        const rowAValue = `${rowA.city}/${currentStateA?.state_name ? currentStateA.state_name : ""}`;
        const currentStateB = this.state.states?.find(
          (state) => state.stateUniqueIdentifier === rowB.stateOfAddressUniqueReference
        );
        const rowBValue = `${rowB.city}/${currentStateB?.state_name ? currentStateB.state_name : ""}`;
        if (order === "asc") {
          return rowBValue > rowAValue ? 1 : -1;
        } else {
          return rowBValue > rowAValue ? -1 : 1;
        }
      },
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const currentState = formatExtraData.states?.find(
          (state) => state.stateUniqueIdentifier === row.stateOfAddressUniqueReference
        );
        return (
          <span className={globalStyles.formData}>{`${row.city}/${
            currentState?.state_name ? currentState.state_name : ""
          }`}</span>
        );
      },
      formatExtraData: this.state,
      style: {},
      headerAttrs: { id: "userCityStateHeader" },
      attrs: { headers: "userCityStateHeader", tabIndex: "0" },
    };
  }

  getPhoneNumberColumn() {
    return {
      dataField: "primaryPhoneNumber",
      text: "Phone Number",
      filter: customFilter(),
      filterRenderer: (onFilter, column) => (
        <CustomTextFilter
          onFilter={onFilter}
          column={column}
          setColumnFilterValue={this}
          columnName="phoneNumber"
          title="User Phone Number"
        />
      ),
      sort: true,
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (formatExtraData.listEditingIndex !== -1 && rowIndex === formatExtraData.tableEditingIndex) {
          return (
            <Form.Control
              as="input"
              type="text"
              onChange={(e) => {
                this.setState({ newUserPhoneNumber: UtilityFunctions.formatPhoneNumber(e.currentTarget.value) });
              }}
              value={UtilityFunctions.formatPhoneNumber(formatExtraData.newUserPhoneNumber)}
              className={globalStyles.formData}
            />
          );
        } else {
          return <span className={globalStyles.formData}>{UtilityFunctions.formatPhoneNumber(cell)}</span>;
        }
      },
      formatExtraData: this.state,
      style: {},
      headerAttrs: { id: "userPhoneHeader" },
      attrs: { headers: "userPhoneHeader", tabIndex: "0" },
    };
  }

  getEmailColumn() {
    return {
      dataField: "userPrinciple",
      text: "Email",
      filter: customFilter({ onFilter: this.filterUsersByEmail }),
      filterRenderer: (onFilter, column) => (
        <CustomTextFilter
          onFilter={onFilter}
          column={column}
          setColumnFilterValue={this}
          columnName="email"
          title="User Email"
        />
      ),
      sort: true,
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span className={globalStyles.formData}>{row.userPrinciple}</span>;
      },
      formatExtraData: this.state,
      style: {},
      headerAttrs: { id: "userEmailHeader" },
      attrs: { headers: "userEmailHeader", tabIndex: "0" },
    };
  }

  getUserTypeColumn() {
    return {
      dataField: "userType",
      text: "Type",
      sort: true,
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span className={globalStyles.formData}>{UtilityFunctions.getDisplayTextFromFieldObject(row.userType)}</span>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const rowAValue = UtilityFunctions.getDisplayTextFromFieldObject(rowA.userType);
        const rowBValue = UtilityFunctions.getDisplayTextFromFieldObject(rowB.userType);
        if (order === "asc") {
          return rowBValue > rowAValue ? 1 : -1;
        } else {
          return rowBValue > rowAValue ? -1 : 1;
        }
      },
      formatExtraData: this.state,
      filter: selectFilter({
        options: this.getUserTypeOptions(),
        onInput: (e) => {
          let textValue = "";
          if (e.currentTarget.value === "1") {
            textValue = "Employee";
          } else if (e.currentTarget.value === "2") {
            textValue = "Temp";
          }
          this.setState({ typeFilterValue: textValue });
        },
        onFilter: (filterValue, data) => {
          if (filterValue === "") {
            return data;
          }

          return data.filter((x) => x.userType.id === parseInt(filterValue));
        },
      }),
      style: {},
      headerAttrs: { id: "userTypeHeader" },
      attrs: { headers: "userTypeHeader", tabIndex: "0" },
    };
  }

  getUserNameColumn() {
    return {
      dataField: "userName",
      text: "User Name",
      filter: customFilter({
        onFilter: this.filterUsersByName,
      }),
      filterRenderer: (onFilter, column) => (
        <CustomTextFilter
          onFilter={onFilter}
          column={column}
          setColumnFilterValue={this}
          columnName="userName"
          title="User Name"
        />
      ),
      formatter: (cell, row) => {
        return (
          <span>
            <Button variant="link" onClick={() => this.handleUserDetailsNavigation(row)}>
              <span className={globalStyles.formDataLinks}>{`${row.firstName} ${row.lastName}`}</span>
            </Button>
          </span>
        );
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const rowAValue = `${rowA.firstName} ${rowA.lastName}`;
        const rowBValue = `${rowB.firstName} ${rowB.lastName}`;
        if (order === "asc") {
          return rowBValue > rowAValue ? 1 : -1;
        } else {
          return rowBValue > rowAValue ? -1 : 1;
        }
      },
      onSort: (field, order) => {
        this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
      },
      align: "left",
      headerAlign: "left",
      headerStyle: () => {
        return { width: "16%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "0px" };
      },
      style: {},
      headerAttrs: { id: "userNameHeader" },
      attrs: { headers: "userNameHeader", tabIndex: "0" },
    };
  }

  getUserSelectColumn() {
    return {
      dataField: "userSelect",
      text: "User Select",
      csvExport: false,
      headerFormatter: () => {
        return (
          <div>
            <div style={{ paddingBottom: "25px" }}>Select</div>
            <input type="checkbox" onChange={(e) => this.selectAllUsers(e)} aria-label="Select all Users" />
          </div>
        );
      },
      formatter: (cell, row) => {
        let index = this.state.users.findIndex((user) => user.wsUserUniqueIdentifier === row.wsUserUniqueIdentifier);
        return (
          <input
            type="checkbox"
            checked={cell}
            onChange={() => this.selectUser(index)}
            aria-label={`Select ${row.firstName} ${row.lastName}`}
          />
        );
      },
      headerStyle: () => {
        return {
          width: "4%",
          backgroundColor: "#f3f3f3",
          paddingBottom: "25px",
          paddingRight: "0px",
          verticalAlign: "text-top",
        };
      },
      style: { paddingLeft: "0px", paddingBottom: "10px" },
      align: "right",
      headerAttrs: {
        id: "userSelectionHeader",
        title: "User Select Header",
      },
      attrs: { headers: "userSelectionHeader" },
    };
  }

  getUserTypeOptions() {
    let userTypeOptions = {};
    if (this.state?.userTypes.length > 0) {
      for (let type of this.state.userTypes) {
        userTypeOptions[type.id] = type.displayText;
      }
    }
    return userTypeOptions;
  }

  getExpandedColumns() {
    return [
      {
        dataField: "wsUserProfileUniqueIdentifier",
        hidden: true,
        csvText: "User Profile Id",
      },
      {
        dataField: "dummyColumn1",
        text: "Dummy Column 1",
        csvExport: false,
        formatter: (cell, row) => {
          return "";
        },
        headerStyle: () => {
          return { borderTop: "hidden", borderBottom: "hidden" };
        },
        align: "right",
        style: (cell, row, rowIndex, colIndex) => {
          return rowIndex === 0 ? { borderTop: "hidden" } : "";
        },
        headerAttrs: {
          id: "dummyColumn1Header",
          title: "Dummy Column 1 Header",
          hidden: true,
        },
        attrs: { headers: "dummyColumn1Header" },
      },
      {
        dataField: "dummyColumn2",
        text: "",
        csvExport: false,
        formatter: (cell, row) => {
          return "";
        },
        headerStyle: () => {
          return { borderTop: "hidden", borderBottom: "hidden", width: "56%" };
        },
        align: "right",
        style: (cell, row, rowIndex, colIndex) => {
          return rowIndex === 0 ? { borderTop: "hidden" } : "";
        },
        headerAttrs: {
          id: "dummyColumnHeader",
          title: "Dummy Column 2 Header",
        },
        attrs: { headers: "dummyColumn2Header" },
      },
      {
        dataField: "profileName",
        text: "",
        csvExport: false,
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{row.hasSpecialPermissions ? `${cell}+` : cell}</span>;
        },
        headerStyle: () => {
          return { borderTop: "hidden", borderBottom: "hidden", width: "9%" };
        },
        style: (cell, row, rowIndex, colIndex) => {
          return rowIndex === 0 ? { borderTop: "hidden", paddingLeft: "0px" } : { paddingLeft: "0px" };
        },
        align: "left",
        headerAttrs: {
          id: "profileNameHeader",
          title: "Profile Name Header",
        },
        attrs: { headers: "profileNameHeader" },
      },
      {
        dataField: "profileStates",
        text: "",
        csvExport: false,
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell.join(", ")}</span>;
        },
        headerStyle: () => {
          return { borderTop: "hidden", borderBottom: "hidden", width: "9%" };
        },
        style: (cell, row, rowIndex, colIndex) => {
          return rowIndex === 0 ? { borderTop: "hidden", paddingLeft: "0px" } : { paddingLeft: "0px" };
        },
        align: "left",
        headerAttrs: {
          id: "profileStatesHeader",
          title: "Profile States Header",
        },
        attrs: { headers: "profileStatesHeader" },
      },
      {
        dataField: "dummyColumn3",
        text: "Dummy Column 3",
        csvExport: false,
        formatter: (cell, row) => {
          return "";
        },
        headerStyle: () => {
          return { borderTop: "hidden", borderBottom: "hidden" };
        },
        align: "right",
        style: (cell, row, rowIndex, colIndex) => {
          return rowIndex === 0 ? { borderTop: "hidden" } : "";
        },
        headerAttrs: {
          id: "dummyColumn3Header",
          title: "Dummy Column 3 Header",
          hidden: true,
        },
        attrs: { headers: "dummyColumn3Header" },
      },
    ];
  }

  async componentDidMount() {
    const availableStates = await ReferenceFileAPI.GetAllStates();
    await this.getDataFromAPI();
    const users = this.state.users;

    if (this.state.filterStates.length === 0) {
      let filterStates = [];
      users.forEach((user) => {
        if (user.userProfiles?.length > 0) {
          user.userProfiles.forEach((profile) => {
            if (profile.profileAssociatedStates?.length > 0) {
              const statesToAdd = profile.profileAssociatedStates.filter(
                (stateId) => !filterStates.find((s) => s.stateUniqueIdentifier === stateId)
              );
              if (statesToAdd?.length > 0) {
                filterStates = [
                  ...filterStates,
                  ...statesToAdd.map((s) => availableStates.find((state) => state.stateUniqueIdentifier === s)),
                ];
              }
            }
          });
        }
      });

      this.setState({
        filterStates: filterStates.sort((a, b) => (a.state_name > b.state_name ? 1 : -1)),
        users,
        userTypes: await this.getUsersTypes(),
        states: availableStates,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.typeFilterValue !== this.state.typeFilterValue ||
      prevState.activeFilterValue !== this.state.activeFilterValue ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.isAscending !== this.state.isAscending
    ) {
      await this.getDataFromAPI();
      this.setState({ waitingForAPIData: false });
    }
  }

  async getDataFromAPI() {
    if (this.state.waitingForAPIData) {
      return;
    }

    this.setState({ waitingForAPIData: true });

    let dateValue = "";
    if (this.state.lastModifiedFilterValue !== "") {
      dateValue = moment(this.state.lastModifiedFilterValue, "YYYY-MM-DD").utc().format("MM/DD/YYYY");
    }

    const getUsersResponse = await RoleAuthorizationAPI.GetFilteredUsersTableData(
      this.state.userNameFilterValue,
      this.state.typeFilterValue,
      this.state.emailFilterValue,
      this.state.phoneNumberFilterValue,
      this.state.cityAndStateFilterValue,
      this.state.profileRoleFilterValue,
      this.state.profileStateFilterValue,
      dateValue,
      this.state.activeFilterValue,
      this.state.sortColumn,
      this.state.isAscending
    );
    if (getUsersResponse?.successful) {
      this.setState({ users: getUsersResponse?.data.results });
    }
  }

  async getUsersTypes() {
    let usersTypes = [];
    const userTypesResponse = await RoleAuthorizationAPI.GetAllUserTypes();
    if (userTypesResponse?.successful && userTypesResponse.data?.length > 0) {
      usersTypes = userTypesResponse.data;
    }

    return usersTypes;
  }

  filterUsersByName = (filterValue, data) => {
    const searchValue = filterValue.toLowerCase();
    return data.filter(
      (user) => user.firstName.toLowerCase().includes(searchValue) || user.lastName.toLowerCase().includes(searchValue)
    );
  };

  filterUsersByType = (filterValue, data) => {
    let dataResults = data;
    if (filterValue) {
      dataResults = data.filter((user) => user.userType?.id === Number.parseInt(filterValue));
    }

    return dataResults;
  };

  filterUsersByCityState = (filterValue, data) => {
    let dataResults = data;
    if (filterValue) {
      const searchedValue = filterValue.toLowerCase();
      const filteredStates = this.state.states.filter((s) => s.state_name.toLowerCase().includes(searchedValue));
      dataResults = data.filter(
        (user) =>
          user.city.toLowerCase().includes(searchedValue) ||
          filteredStates.some((s) => s.stateUniqueIdentifier === user.stateOfAddressUniqueReference)
      );
    }
    return dataResults;
  };

  filterUsersByEmail = (filterValue, data) => {
    let dataResults = data;
    if (filterValue) {
      const searchedValue = filterValue.toLowerCase();
      dataResults = data.filter((user) => user.userPrinciple.includes(searchedValue));
    }
    return dataResults;
  };

  selectUser = (incomingIndex) => {
    const newUsers = this.state.users.map((user, index) => {
      let select = user.userSelect;
      if (index === incomingIndex) {
        select = !select;
      }
      return { ...user, userSelect: select };
    });
    this.setState({ users: newUsers });
  };

  handleUserDetailsNavigation = (row) => {
    if (this.props.permissions.canIViewGlobalUserRecord()) {
      this.props.history.push({
        pathname: "/user/details",
        state: {
          userData: row,
          states: this.state.states,
          availableRoles: this.props.availableRoles,
          userTypes: this.state.userTypes,
        },
      });
    } else {
      toast.warning("User does not have permission to view record details.");
    }
  };

  getExtraHeaderComponents = () => {
    return [
      <Col lg={3} key="globalSearch">
        <InputGroup>
          <Form.Control
            aria-label="Search user, email, phone"
            type="text"
            placeholder="Search user, email, phone"
            onChange={this.onAllFilterChange}
          ></Form.Control>
          <div className="searchIcon">
            <img src={searchIcon} aria-hidden="true" alt="" />
          </div>
        </InputGroup>
      </Col>,
      <Col lg={1} key="globalStateFilter">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" style={{ backgroundColor: "#767676" }}>
            View By State
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.stateFilterItem}>
            <Dropdown.Item key="Reset" eventKey="Reset" onSelect={this.onViewStateClick}>
              <span className={globalStyles.formDataLinks}>Reset</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            {this.state.filterStates.map((state) => {
              return (
                <Dropdown.Item
                  key={state.stateUniqueIdentifier}
                  eventKey={state.stateUniqueIdentifier}
                  onSelect={this.onViewStateClick}
                >
                  <span
                    className={
                      state.stateUniqueIdentifier === this.state.usingBigFilter
                        ? globalStyles.formLabel
                        : globalStyles.formData
                    }
                  >
                    {state.state_name}
                  </span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Col>,
      <Col lg={2} key="globalUserOptions">
        <Image
          src={deactivateUser}
          alt="Deactivate User(s)"
          className={styles.deactivateUserImage}
          onClick={this.deactivateUsers}
        />
        <Image
          src={resetPassword}
          className={styles.resetPasswordImage}
          alt="Reset Password(s)"
          onClick={this.resetPasswords}
        />
      </Col>,
      <Col lg={2} key="dummyHeaderColumn"></Col>,
    ];
  };

  saveRowQuickEdit(row, rowIndex) {
    this.setState({
      listEditingIndex: this.state.users.findIndex(
        (user) => user.wsUserUniqueIdentifier === row.wsUserUniqueIdentifier
      ),
      tableEditingIndex: rowIndex,
      tableUpdated: false,
      newUserActive: row.isActive ? "Yes" : "No",
      newUserPhoneNumber: row.primaryPhoneNumber,
    });
  }

  async quickEditRow() {
    const newUsers = this.state.users.map((user) => {
      return { ...user };
    });
    const newBigFilteredUsers = this.state.bigFilteredUsers.map((user) => {
      return { ...user };
    });
    if (UtilityFunctions.validatePhoneNumber(this.state.newUserPhoneNumber)) {
      let editedUser = newUsers[this.state.listEditingIndex];
      editedUser.primaryPhoneNumber = this.state.newUserPhoneNumber;
      editedUser.isActive = this.state.newUserActive === "Yes" ? true : false;
      let bigFilteredIndex = newBigFilteredUsers.findIndex(
        (bfu) => bfu.wsUserUniqueIdentifier === editedUser.wsUserUniqueIdentifier
      );
      newBigFilteredUsers[bigFilteredIndex] = editedUser;
      var quickEditUserAction = await RoleAuthorizationAPI.QuickEditUser(
        editedUser.wsUserUniqueIdentifier,
        UtilityFunctions.formatPhoneNumberWithoutDashes(editedUser.primaryPhoneNumber),
        editedUser.isActive
      );
      if (quickEditUserAction?.successful) {
        toast.success("User edit successful.");
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to complete User quick edit",
          apiName: "QuickEditUser",
          responseUnsuccessful: quickEditUserAction?.unsuccessful,
          responseMessage: quickEditUserAction?.message,
        });
      }
      this.setState({
        users: newUsers,
        bigFilteredUsers: newBigFilteredUsers,
        listEditingIndex: -1,
        tableEditingIndex: -1,
        newUserActive: "",
        newUserEmail: "",
        tableUpdated: false,
      });
    } else {
      toast.error("Please enter a valid phone number before saving.");
    }
  }

  anyUsersSelected() {
    return this.state.users.find((user) => user.userSelect === true);
  }

  deactivateUsers = () => {
    if (!this.anyUsersSelected()) {
      toast.error("No users selected.");
      return;
    }

    const newUsers = this.state.users.map((user) => {
      let active = user.isActive;
      if (user.userSelect) {
        active = false;
      }
      return { ...user, isActive: active };
    });
    this.setState({ users: newUsers });
    toast.success("Selected users deactivated.");
  };

  resetPasswords = () => {
    if (!this.anyUsersSelected()) {
      toast.error("No users selected.");
      return;
    }

    toast.success("Selected user password(s) reset.");
  };

  onViewStateClick = (input) => {
    let newFilteredUsers = [];
    let usingBigFilter = "";
    if (input === "Reset") {
      newFilteredUsers = [];
    } else if (input) {
      newFilteredUsers = this.state.users.filter((user) =>
        user.userProfiles.some((p) => p?.profileAssociatedStates.includes(input))
      );
      usingBigFilter = input;
    }
    this.setState({ bigFilteredUsers: newFilteredUsers, usingBigFilter });
  };

  onAllFilterChange = (e) => {
    const term = e.currentTarget.value.toLowerCase();
    let newFilteredUsers = [];
    if (term) {
      newFilteredUsers = this.state.users.filter(
        (user) =>
          user.firstName?.toLowerCase().includes(term) ||
          user.lastName?.toLowerCase().includes(term) ||
          (user.firstName + " " + user.lastName)?.toLowerCase().includes(term) ||
          user.primaryPhoneNumber?.toLowerCase().includes(term) ||
          user.userPrinciple?.toLowerCase().includes(term) ||
          user.userProfiles.some((p) => p.profileName.toLowerCase().includes(term))
      );
    }
    this.setState({ bigFilteredUsers: newFilteredUsers, usingBigFilter: term });
  };

  handleCreateUserClick = () => {
    this.setState({ showCreateUserModal: true });
  };

  selectAllUsers = () => {
    const newUsers = this.state.users.map((user) => {
      return { ...user, userSelect: !user.userSelect };
    });
    this.setState({ users: newUsers });
  };

  createUser = async (user) => {
    const provisionUserBody = {
      firstName: user.firstName,
      lastName: user.lastName,
      userPrincipleName: user.emailAddress,
      userTypeEnumId: user.userType,
      streetAddress: user.streetAddress,
      city: user.city,
      stateOfAddressUniqueReference: user.state,
      zip: user.zipCode,
      primaryPhoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(user.phoneNumber),
      isActive: true,
      preferredSpeciesUniqueReferences: [],
      preferredMethodsUniqueReferences: [],
      preferredResourcesUniqueReferences: [],
    };

    const userProvisionResponse = await RoleAuthorizationAPI.ProvisionUser(provisionUserBody);
    if (userProvisionResponse?.successful) {
      this.setState({ userNavigationDetails: userProvisionResponse.data, showCreateUserSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create user",
        apiName: "ProvisionUser",
        responseUnsuccessful: userProvisionResponse?.unsuccessful,
        responseMessage: userProvisionResponse?.message,
      });
    }
  };

  getExpandRowObject = () => {
    const expandRow = {
      renderer: (row, rowIndex) => {
        const dummyExpandRow = {
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
              return <b></b>;
            }
            return <b></b>;
          },
        };
        return (
          <div>
            <ToolkitProvider
              keyField="wsUserProfileUniqueIdentifier"
              data={this.state.extendedSubRowData}
              columns={this.getExpandedColumns()}
              bootstrap4={true}
              hover={true}
            >
              {(props) => (
                <div>
                  <BootstrapTable
                    keyField="wsUserProfileUniqueIdentifier"
                    bordered={false}
                    condensed
                    expandRow={dummyExpandRow}
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        );
      },
      className: styles.expandRows,
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "hidden",
      },
      expanded: this.state.expandedRows,
      onExpand: (row, isExpand, rowIndex, e) => {
        let extendedSubRowData = [];
        let expandedRows;
        if (isExpand) {
          expandedRows = [...this.state.expandedRows, row.wsUserUniqueIdentifier];
          for (let profile of row.userProfiles) {
            const profileStates = profile.profileAssociatedStates?.map((profileStateId) => {
              let stateCode = "";
              const currentState = this.state.states.find((s) => s.stateUniqueIdentifier === profileStateId);
              if (currentState) {
                stateCode = currentState.state_code;
              }
              return stateCode;
            });
            extendedSubRowData.push({
              profileName: profile.profileName,
              profileStates,
              wsUserProfileUniqueIdentifier: profile.wsUserProfileUniqueIdentifier,
              hasSpecialPermissions: profile.specialPermissionsForProfile.length > 0,
            });
          }
        } else {
          expandedRows = this.state.expandedRows.filter((r) => r !== row.wsUserUniqueIdentifier);
        }
        this.setState({ expandedRows, extendedSubRowData });
      },
      nonExpandable: this.state.users
        .filter((user) => !(user.userProfiles?.length > 1))
        .map((u) => u.wsUserUniqueIdentifier),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: () => {
        return "";
      },
      expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
        let icon = <Image src={chevronRight} alt="" />;
        if (expanded) {
          icon = <Image src={chevronDown} alt="" />;
        }
        return expandable ? icon : null;
      },
    };
    return expandRow;
  };

  render() {
    return (
      <div>
        <ToolkitProvider
          keyField="wsUserUniqueIdentifier"
          data={this.state.usingBigFilter ? this.state.bigFilteredUsers : this.state.users}
          columns={this.getColumns()}
          bootstrap4={true}
          search={{ searchFormatted: true }}
          exportCSV={{
            fileName: "users-data.csv",
            ignoreHeader: false,
            noAutoBOM: false,
            onlyExportFiltered: true,
            exportAll: false,
          }}
        >
          {(props) => (
            <div data-testid="UserTab">
              <Card className={styles.adminCenterContainerCard}>
                <Card.Body className={styles.userTableContainer}>
                  <ReferenceFileCardHeader
                    referenceFileType="User"
                    createButtonEvent={this.handleCreateUserClick}
                    extraHeaderComponents={this.getExtraHeaderComponents()}
                    permissions={this.props.permissions}
                    {...props.csvProps}
                  />
                  <BootstrapTable
                    keyField="wsUserUniqueIdentifier"
                    filter={filterFactory()}
                    bordered={false}
                    defaultSorted={this.state.defaultSort}
                    defaultSortDirection="asc"
                    expandRow={this.getExpandRowObject()}
                    {...props.baseProps}
                  />
                </Card.Body>
              </Card>
            </div>
          )}
        </ToolkitProvider>
        <CreateUserModal
          show={this.state.showCreateUserModal}
          onHide={() => this.setState({ showCreateUserModal: false })}
          userTypes={this.state.userTypes}
          onUserCreate={this.createUser}
        />
        <SuccessfulRecordModifiedModal
          show={this.state.showCreateUserSuccessModal}
          onHide={() => {
            this.setState({ showCreateUserSuccessModal: false });
          }}
          objectName="User"
          onAcknowledgeRoute="/user/details"
          routeData={{
            userData: this.state.userNavigationDetails,
            availableRoles: this.props.availableRoles,
            states: this.state.states,
            userTypes: this.state.userTypes,
          }}
        />
      </div>
    );
  }
}

export default withRouter(UserTable);
