import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton, InputGroup, Col, Row } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CreateEditCountryModal from "../ReferenceFilesCreatePages/CreateEditCountryModal";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CustomTextFilter from "../common/customTextFilter";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class CountryTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryData: [],
      currentUsaState: props.currentUsaState,
      defaultSort: [{ dataField: "name", order: "asc" }],
      columns: [
        {
          dataField: "countryUniqueIdentifier",
          hidden: true,
          csvText: "Country Unique Id",
        },
        {
          dataField: "country_name",
          text: "Name",
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter
              onFilter={onFilter}
              column={column}
              title="Country Name Search"
              labelledBy="CountryNameHeader"
            />
          ),
          sort: true,
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3" };
          },
          headerAttrs: { id: "CountryNameHeader" },
          attrs: { headers: "CountryNameHeader", tabIndex: "0" },
        },
        {
          dataField: "country_code",
          text: "Code",
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter
              onFilter={onFilter}
              column={column}
              title="Country Code Search"
              labelledBy="CountryCodeHeader"
            />
          ),
          sort: true,
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3" };
          },
          headerAttrs: { id: "CountryCodeHeader" },
          attrs: { headers: "CountryCodeHeader", tabIndex: "0" },
        },
        {
          dataField: "active",
          text: "Active",
          formatter: (cell, row, rowIndex) => {
            return row.active ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          },
          sort: true,
          sortFunc: (a, b, order) => {
            if (order === "asc") {
              return b - a;
            }
            return a - b; // desc
          },
          align: "left",
          headerAlign: "left",
          style: this.tableTextStyle,
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          headerAttrs: { id: "CountryAllowedHeader" },
          attrs: { headers: "CountryAllowedHeader", tabIndex: "0" },
        },

        {
          dataField: "lastModifiedDateTimeUTC",
          text: "Last Modified Date",
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return this.formatDate(cell);
          },
          sort: true,
          sortFunc: (a, b, order) => {
            let dateA = new Date(a);
            let dateB = new Date(b);
            if (order === "asc") {
              return dateB - dateA;
            }
            return dateA - dateB; // desc
          },
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          style: this.tableTextStyle,
          headerAttrs: { id: "CountryLastModifiedHeader" },
          attrs: { headers: "CountryLastModifiedHeader", tabIndex: "0" },
        },
        {
          dataField: "CountryActions",
          text: "Actions",
          csvExport: false,
          editable: false,
          formatter: (cell, row) => {
            return (
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton
                  id="dropdown-Country-actions"
                  title="Actions"
                  variant="link"
                  className={styles.buttonLink}
                >
                  <Dropdown.Item
                    eventKey="CountryEdit"
                    onSelect={() => this.handleCountryEdit(row)}
                    hidden={!this.props.permissions.canIEditReferenceData()}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="CountryHistoryTracking" onSelect={() => this.handleCountryHistory(row)}>
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            );
          },
          headerStyle: () => {
            return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
          },
          headerAlign: "right",
          align: "right",
          headerAttrs: { id: "CountryActionsHeader" },
          attrs: { headers: "CountryActionsHeader", tabIndex: "0" },
        },
      ],
      showCreateModal: false,
      showConfigureModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
    };
  }

  tableTextStyle = {};

  static getDerivedStateFromProps(nextProps) {
    return {
      currentUsaState: nextProps.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier) {
      await this.updateCountryData();
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "countryTab") {
      await this.updateCountryData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "countryTab") {
      await this.updateCountryData();
    }
  }

  async updateCountryData() {
    this.props.setIsLoading(true);
    const countryData = await this.getDataFromAPI();
    this.setState({ countryData }, () => {
      this.props.setIsLoading(false);
    });

    window.scrollTo(0, 0);
  }

  async getDataFromAPI() {
    let Countries = await ReferenceFileAPI.GetAllDepartmentOfDefenseCountries();
    if (Countries?.data?.results) {
      return Countries.data.results;
    } else {
      return [];
    }
  }

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleDateString("en-US");
  }

  closeAllModals = () => {
    this.setState({ showCreateModal: false, showEditModal: false, showConfigureModal: false, modalData: null });
  };

  handleCreateCountry = () => {
    this.setState({ showCreateModal: true });
  };

  handleCountryEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleCountryHistory = (row) => {};

  async onModalEdit(newcountryData) {
    let { countryData } = this.state;
    if (newcountryData.isCountryEdited && !newcountryData.active) {
      //deactivate country
      const editCountryResponse = await ReferenceFileAPI.DeactivateCountry(newcountryData.countryUniqueIdentifier);
      if (editCountryResponse?.successful) {
        this.setState({ showCreateSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to deactivate country record",
          apiName: "DeactivateCountry",
          responseUnsuccessful: editCountryResponse?.unsuccessful,
          responseMessage: editCountryResponse?.message,
        });
      }
    }
    if (newcountryData.isCountryEdited && newcountryData.active) {
      //activate country
      const editCountryResponse = await ReferenceFileAPI.ActivateCountry(newcountryData.countryUniqueIdentifier);
      if (editCountryResponse?.successful) {
        this.setState({ showCreateSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to activate country record",
          apiName: "ActivateCountry",
          responseUnsuccessful: editCountryResponse?.unsuccessful,
          responseMessage: editCountryResponse?.message,
        });
      }
    }
    let recordIndex = countryData.findIndex(
      (a) => a.countryUniqueIdentifier === newcountryData.countryUniqueIdentifier
    );
    countryData[recordIndex] = newcountryData;
    this.setState({ countryData: countryData });
    await this.updateCountryData();
  }

  onModalSubmit = async (newcountryData, mode) => {
    let { countryData } = this.state;
    if (mode === "Create") {
      let countryObj = countryData?.find(
        (country) => country.country_name === newcountryData.country_name.toUpperCase()
      );
      if (countryObj) {
        ErrorHandler.showError(countryObj.country_name + " already exists in Country list");
        return;
      } else {
        const createBody = {
          active: true,
          country_code: newcountryData.country_code.toUpperCase(),
          country_name: newcountryData.country_name.toUpperCase(),
        };

        const createCountryResponse = await ReferenceFileAPI.CreateCountry(createBody);
        if (createCountryResponse?.successful) {
          this.setState({ countryData: countryData, showCreateSuccessModal: true });
          await this.updateCountryData();
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to create country record",
            apiName: "CreateCountry",
            responseUnsuccessful: createCountryResponse?.unsuccessful,
            responseMessage: createCountryResponse?.message,
          });
        }
      }
    } else if (mode === "Edit") {
      await this.onModalEdit(newcountryData);
    }
  };

  renderCreateModal() {
    return (
      <CreateEditCountryModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode={"Create"}
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditCountryModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode={"Edit"}
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  render() {
    return (
      <ToolkitProvider
        keyField="CountryUniqueIdentifier"
        data={this.state.countryData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "Country-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Country"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleCreateCountry}
                  recordCount={this.state.countryData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="CountryUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.state.showCreateModal ? this.renderCreateModal() : null}
            {this.state.showEditModal ? this.renderEditModal() : null}

            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="country"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="country"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default CountryTab;
