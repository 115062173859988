import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { withRouter } from "react-router-dom";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CustomTextFilter from "../common/customTextFilter";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CreateEditTopicModal from "../ReferenceFilesCreatePages/CreateEditTopicModal";
import ConfigureTopicModal from "../ReferenceFilesEditModals/ConfigureTopicModal";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class TopicsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topicData: [],
      defaultSort: [{ dataField: "name", order: "asc" }],
      columns: [
        {
          dataField: "topicUniqueIdentifier",
          hidden: true,
          csvText: "topic Unique Id",
        },
        {
          dataField: "name",
          text: "Name",
          headerAttrs: { id: "topicNameHeader" },
          attrs: { headers: "topicNameHeader", tabIndex: "0" },
        },
      ],
      showCreateModal: false,
      showConfigureModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
      currentUsaState: props.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentUsaState?.stateUniqueIdentifier !== this.state.currentUsaState?.stateUniqueIdentifier) {
      await this.updatetopicData();
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "topicsTab") {
      await this.updatetopicData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "topicsTab") {
      await this.updatetopicData();
    }
  }

  async updatetopicData() {
    this.props.setIsLoading(true);
    const topicData = await this.getDataFromAPI();
    const columns = this.gettopicColumns();
    this.setState({ topicData, columns }, () => {
      this.props.setIsLoading(false);
    });
    window.scrollTo(0, 0);
  }

  async getDataFromAPI() {
    let topicResults = [];
    let gettopicResponse;

    if (this.props.useGlobalData) {
      gettopicResponse = await ReferenceFileAPI.GetAllTopics();
      if (gettopicResponse?.successful) {
        topicResults = gettopicResponse.data.results ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get topics data",
          apiName: "GetAllTopics",
          responseUnsuccessful: gettopicResponse?.unsuccessful,
          responseMessage: gettopicResponse?.message,
        });
      }
    } else {
      gettopicResponse = await ReferenceFileAPI.GetTopicsForState(this.state.currentUsaState?.stateGuid);
      if (gettopicResponse?.successful) {
        topicResults = gettopicResponse.data?.results?.filter((d) => d?.isAllowedGlobally) ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get topics data in state",
          apiName: "GetAllTopics",
          responseUnsuccessful: gettopicResponse?.unsuccessful,
          responseMessage: gettopicResponse?.message,
        });
      }
    }

    topicResults.forEach((result) => (result.useGlobalData = this.props.useGlobalData));
    return topicResults;
  }

  gettopicColumns() {
    return [
      {
        dataField: "topicUniqueIdentifier",
        hidden: true,
        csvText: "topic Unique Id",
      },
      {
        dataField: "name",
        text: "Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="topic Name" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "topicNameHeader" },
        attrs: { headers: "topicNameHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "isAllowedGlobally" : "isAllowedInState",
        text: this.props.useGlobalData ? "Status" : "Allowed",
        formatter: (cell, row, rowIndex) => {
          if (this.props.useGlobalData) {
            return row.isAllowedGlobally ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          } else {
            return row.isAllowedInState ? <span style={{ color: "#008767" }}>Allowed</span> : "Not Allowed";
          }
        },
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === "asc") {
            return b - a;
          }
          return a - b; // desc
        },
        align: "left",
        headerAlign: "left",
        style: this.tableTextStyle,
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        headerAttrs: { id: "activityStatusHeader" },
        attrs: { headers: "activityStatusHeader", tabIndex: "0" },
      },
      {
        dataField: "lastModifiedDateTimeUTC",
        text: this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return new Date(cell).toLocaleDateString("en-US");
        },
        sort: true,
        sortFunc: (a, b, order) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          if (order === "asc") {
            return dateB - dateA;
          }
          return dateA - dateB; // desc
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "topicDateHeader" },
        attrs: { headers: "topicDateHeader", tabIndex: "0" },
      },
      {
        dataField: "topicActions",
        text: "Actions",
        csvExport: false,
        editable: false,
        formatter: (cell, row) => {
          if (row.useGlobalData) {
            return (
              <DropdownButton id="dropdown-topic-actions" title="Actions" variant="link" className={styles.buttonLink}>
                <Dropdown.Item
                  eventKey="topicEdit"
                  onSelect={() => this.handletopicEdit(row)}
                  hidden={!this.props.permissions.canIEditReferenceData()}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item eventKey="topicHistoryTracking" onSelect={() => this.handletopicHistory(row)}>
                  History Tracking
                </Dropdown.Item>
              </DropdownButton>
            );
          } else {
            return (
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton
                  id="dropdown-topic-actions"
                  title="Actions"
                  variant="link"
                  className={styles.buttonLink}
                >
                  <Dropdown.Item
                    eventKey="topicConfigure"
                    onSelect={() => this.handletopicConfigure(row)}
                    hidden={!this.props.permissions.canIConfigureRefData()}
                  >
                    Configure
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="topicHistoryTracking" onSelect={() => this.handletopicHistory(row)}>
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            );
          }
        },
        headerStyle: () => {
          return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
        },
        headerAlign: "right",
        align: "right",
        headerAttrs: {
          id: "topicActionsHeader",
          title: "Actions Column Header",
        },
        attrs: { headers: "topicActionsHeader" },
      },
    ];
  }

  async onModalCreate(newOrgData) {
    const createtopicBody = {
      name: newOrgData.name,
      isAllowedGlobally: true,
    };

    const createtopicResponse = await ReferenceFileAPI.CreateTopic(createtopicBody);
    if (createtopicResponse?.successful) {
      this.setState({ showCreateSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create topic record",
        apiName: "CreateTopic",
        responseUnsuccessful: createtopicResponse?.unsuccessful,
        responseMessage: createtopicResponse?.message,
      });
    }
  }

  async onModalEdit(newOrgData) {
    const { modalData } = this.state;
    let isEditNameSuccesful = true;
    let updatetopicResponse;
    if (newOrgData.name !== modalData.name) {
      const edittopicBody = {
        name: newOrgData.name,
      };
      updatetopicResponse = await ReferenceFileAPI.UpdateTopic(newOrgData.topicUniqueIdentifier, edittopicBody);
      if (!updatetopicResponse?.successful) {
        isEditNameSuccesful = false;
      }
    }

    let isEditStatusSuccesful = true;
    if (newOrgData.isAllowedGlobally !== modalData.isAllowedGlobally) {
      const updatetopicStatusResponse = newOrgData.isAllowedGlobally
        ? await ReferenceFileAPI.AllowTopicGlobally(newOrgData.topicUniqueIdentifier)
        : await ReferenceFileAPI.DisallowTopicGlobally(newOrgData.topicUniqueIdentifier);
      if (!updatetopicStatusResponse?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set topic allowed status",
          apiName: newOrgData.isAllowedGlobally ? "AllowTopicGlobally" : "DisallowTopicGlobally",
          responseUnsuccessful: updatetopicStatusResponse?.unsuccessful,
          responseMessage: updatetopicStatusResponse?.message,
        });
      }
    }

    if (isEditNameSuccesful && isEditStatusSuccesful) {
      this.setState({ showEditSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to update topic record",
        apiName: "UpdateTopic",
        responseUnsuccessful: updatetopicResponse?.unsuccessful,
        responseMessage: updatetopicResponse?.message,
      });
    }
  }

  onModalSubmit = async (newOrgData, mode) => {
    if (mode === "Create") {
      await this.onModalCreate(newOrgData);
    } else if (mode === "Edit") {
      await this.onModalEdit(newOrgData);
    } else if (mode === "Configure") {
      const { modalData } = this.state;

      let isEditStatusSuccesful = true;

      if (newOrgData.isAllowedInState !== modalData.isAllowedInState) {
        let allowed = true;
        if (newOrgData.isAllowedInState === "No") {
          allowed = false;
        }
        const updatetopicStatusResponse = allowed
          ? await ReferenceFileAPI.AllowTopicInState(
              newOrgData.topicUniqueIdentifier,
              this.state.currentUsaState?.stateGuid
            )
          : await ReferenceFileAPI.DisallowTopicInState(
              newOrgData.topicUniqueIdentifier,
              this.state.currentUsaState?.stateGuid
            );

        if (!updatetopicStatusResponse.successful) {
          isEditStatusSuccesful = false;
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to set topic allowed status",
            apiName: updatetopicStatusResponse ? "AllowTopicInState" : "DisallowTopicInState",
            responseUnsuccessful: updatetopicStatusResponse?.unsuccessful,
            responseMessage: updatetopicStatusResponse?.message,
          });
        }
      }

      if (isEditStatusSuccesful) {
        this.setState({ showEditSuccessModal: true });
      } else {
        ErrorHandler.showErrorWithMessage("Configure Topic failed.");
      }
    }
    await this.updatetopicData();
  };

  handletopicCreate = () => {
    this.setState({ showCreateModal: true });
  };

  handletopicConfigure = (row) => {
    this.setState({ modalData: row, showConfigureModal: true });
  };

  handletopicEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handletopicHistory = (row) => {};

  closeAllModals = () => {
    this.setState({ showCreateModal: false, showEditModal: false, showConfigureModal: false, modalData: null });
  };

  renderCreateModal() {
    return (
      <CreateEditTopicModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode="Create"
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditTopicModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode="Edit"
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderConfigureModal() {
    return (
      <ConfigureTopicModal
        show={this.state.showConfigureModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode="Configure"
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  render() {
    return (
      <ToolkitProvider
        keyField="topicUniqueIdentifier"
        data={this.state.topicData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "topic-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Topics"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handletopicCreate}
                  useGlobalData={this.props.useGlobalData}
                  recordCount={this.state.topicData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="topicUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.state.showCreateModal ? this.renderCreateModal() : null}
            {this.state.showEditModal ? this.renderEditModal() : null}
            {this.state.showConfigureModal ? this.renderConfigureModal() : null}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="topic"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="topic"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(TopicsTab);
