import React from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { components } from "react-select";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import { toast } from "react-toastify";
import Select from "react-select";
import CustomForm from "../../../common/form";
import searchIcon from "../../../../assets/search.svg";
import ConflictsAPI from "../../../../api/Conflicts/ConflictsAPI";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class AssociateResourceInventoryModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        quantity: "",
        totalValue: "",
        year: "",
        threatType: "",
        threats: [],
      },
      errors: {},
    };
  }

  async componentDidUpdate(prevProps) {
    const { data } = this.state;
    if (prevProps.show !== this.props.show && this.props.show) {
      data.threats = await this.getThreatTypesFromAPI();
      this.setState({ data });
    }
  }

  async getThreatTypesFromAPI() {
    const threatTypes = await ConflictsAPI.GetAllThreatTypeOptions();
    if (threatTypes?.successful) {
      return threatTypes.data?.map((type) => {
        type.value = type.id;
        type.label = type.displayText;
        return type;
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to update property",
        apiName: "GetAllThreatTypeOptions",
        responseUnsuccessful: threatTypes?.unsuccessful,
        responseMessage: threatTypes?.message,
      });
    }
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  handleSpecificChanges(input) {
    //do nothing
  }

  isFormDataValid() {
    const { quantity, totalValue, threatType } = this.state.data;
    const speciesOnConflicts = this.props.propertyConflicts?.find(
      (c) => c.targetedSpeciesUniqueReference === this.props.data.selectedSpecies?.value
    );
    if (speciesOnConflicts) {
      return quantity && totalValue && threatType;
    } else {
      return quantity && totalValue;
    }
  }

  doSubmit() {
    const { data } = this.state;
    data.resourcePropertyAssociationData = this.props.data;
    this.props.onSubmitResourceAssociation({ ...data });
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  }

  handleModalCancel = () => {
    const { data } = this.state;

    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  handleThreatSelection = (e) => {
    const { data } = this.state;
    data.threatType = e;
    this.setState({ data });
  };

  clearData(data) {
    data.quantity = "";
    data.totalValue = "";
  }

  render() {
    const { data } = this.state;
    const { lossUom, selectedResource, unitValue } = this.props.data;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.resourcePropertyAssociationModalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>Associate with Property</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mx-0 mb-2">
              <Col>
                <span className={styles.smallComments}>
                  <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                  {" Enter Quantity and Total Value."}
                </span>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderLabel("year", "Year", new Date().getFullYear())}</Col>
              <Col>{this.renderLabel("protectedResource", "Protected Resource", selectedResource?.label)}</Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderLabel("resourceUom", "UOM", lossUom?.label)}</Col>
              <Col>{this.renderLabel("unitValue", "Unit Value", unitValue)}</Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderInput("quantity", "Quantity", "number", "Enter Qty.", "required")}</Col>
              <Col>{this.renderInput("totalValue", "Total Value (Dollar)", "number", "Enter value", "required")}</Col>
            </Row>
            <div
              hidden={
                !this.props.propertyConflicts?.find(
                  (c) => c.targetedSpeciesUniqueReference === this.props.data.selectedSpecies?.value
                )
              }
            >
              <Row className="mx-0">
                <Col>
                  <span className={styles.smallComments}>
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                    {this.props.data.selectedSpecies && this.props.propertyName
                      ? " Resource will be added to the " +
                        this.props.data.selectedSpecies.label +
                        " conflict on " +
                        this.props.propertyName +
                        ". Enter threat type for the resource"
                      : ""}
                  </span>
                </Col>
              </Row>
              <Row className="mx-0">
                <Col md={6} className={styles.methodActionRow}>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Threat Type</Form.Label>
                    <Select
                      aria-label="Threat Selection"
                      value={data.threatType}
                      placeholder="Select"
                      styles={this.customSelectStyles}
                      options={data.threats}
                      onChange={(e) => this.handleThreatSelection(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit" disabled={!this.isFormDataValid()}>
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AssociateResourceInventoryModal;
