import React from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import globalStyles from "../../../OARS.module.scss";
import { toast } from "react-toastify";
import CustomForm from "../../common/form";
import UtilityFunctions from "../../common/UtilityFunctions";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";

class CreateUserModal extends CustomForm {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        userType: "",
      },
      states: [],
      errors: {},
    };
  }

  async componentDidMount() {
    if (this.state.states.length === 0) {
      const getStatesCall = await ReferenceFileAPI.GetUSStates();
      const states = getStatesCall?.map((state) => {
        return { id: state.stateUniqueIdentifier, name: state.state_code };
      });
      this.setState({ states });
    }
  }

  modalCleanUp = () => {
    let { data } = this.state;
    data = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      userType: "",
    };
    this.setState({ data });
    this.props.onHide();
  };

  handleSpecificChanges(input) {
    if (input.name === "phoneNumber") {
      let { data } = this.state;
      data.phoneNumber = UtilityFunctions.formatPhoneNumber(input.value);
      this.setState({ data });
    }
  }

  async doSubmit(e) {
    if (!this.isFormDataValid()) {
      toast.warning("All the field must be filled out before submitting the form");
      return;
    }

    const { data } = this.state;
    let extraValidations =
      (UtilityFunctions.validateEmail(data.emailAddress) || Number.parseInt(data.userType) !== 1) &&
      (UtilityFunctions.validatePhoneNumber(data.phoneNumber) || data.phoneNumber === "");
    if (e.currentTarget.checkValidity() && extraValidations) {
      this.props.onUserCreate(data);
      this.modalCleanUp();
    } else if (!extraValidations) {
      toast.warning("Email and Phone Number must be in correct format.");
    } else {
      toast.warning("Please fill out all required fields.");
    }
  }

  isFormDataValid() {
    const { data } = this.state;
    return (
      data.firstName &&
      data.lastName &&
      data.userType &&
      (data.emailAddress || Number.parseInt(data.userType) !== 1) &&
      data.phoneNumber &&
      data.state
    );
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Modal.Header className={globalStyles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>Create New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>{this.renderSelect("userType", "User Type", this.props.userTypes, "Select", "required")}</Col>
              </Row>
              <Row>
                <Col>{this.renderInput("firstName", "First Name", "text", "Enter name", "required")}</Col>
                <Col>{this.renderInput("lastName", "Last Name", "text", "Enter name", "required")}</Col>
              </Row>
              <Row>
                <Col>
                  {this.renderInput(
                    "emailAddress",
                    "Email",
                    "text",
                    "Enter email",
                    `${Number.parseInt(this.state.data.userType) === 1 ? "required" : "readOnly"}`
                  )}
                </Col>
                <Col>{this.renderInput("phoneNumber", "Phone Number", "text", "Enter number", "required")}</Col>
              </Row>
              <Row>
                <Col>{this.renderInput("streetAddress", "Home Address", "text", "Enter address")}</Col>
              </Row>
              <Row>
                <Col lg={6}>{this.renderInput("city", "City", "text", "Enter city")}</Col>
                <Col>{this.renderSelect("state", "State", this.state.states, "Select", "required")}</Col>
                <Col>{this.renderInput("zipCode", "Zip", "text", "Enter zip")}</Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className={globalStyles.modalFooter}>
              <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <Button type="submit" className={globalStyles.modalSubmitButton}>
                <span className={globalStyles.modalSubmitButtonText}>Create</span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default CreateUserModal;
