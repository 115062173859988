import React from "react";
import styles from "./LoginPage.module.scss";
import Login from "../Login/Login";
import FAQ from "../FAQ/FAQ";

export class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.onFAQLinkClick = this.onFAQLinkClick.bind(this);
    this.state = { mode: props.mode, faqShowing: props.faqShowing };
  }

  onFAQLinkClick() {
    this.setState({ faqShowing: !this.state.faqShowing });
  }
  getFAQVisibility() {
    if (this.state.faqShowing) {
      return "visible";
    } else {
      return "hidden";
    }
  }

  renderFAQElement() {
    let faqComp = <FAQ />;
    if (this.state.faqShowing) {
      faqComp = <FAQ />;
    }
    return faqComp;
  }

  render() {
    return (
      <div className={styles.LoginBackground} data-testid="LoginPage">
        <div>
          <h1>OARS</h1>
          <br></br>
          <p className={styles.pageLabels}>
            Welcome to OARS! This site is for USDA APHIS WS employees only. Please log in to continue.
          </p>
          <Login mode={this.state.mode} faqShowing={this.onFAQLinkClick} />
        </div>
      </div>
    );
  }
}

export default LoginPage;
