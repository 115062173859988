import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { customFilter, textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CreateEditTAActivityModal from "../ReferenceFilesCreatePages/CreateEditTAActivityModal";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CustomTextFilter from "../common/customTextFilter";
import ConfigureTAActivityModal from "../ReferenceFilesEditModals/ConfigureTAActivityModal";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class TAActivityTabTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activityData: [],
      currentUsaState: props.currentUsaState,
      defaultSort: [{ dataField: "activity", order: "asc" }],
      columns: [
        {
          dataField: "technicalAssistActivityUniqueIdentifier",
          hidden: true,
          csvText: "Activity Unique Id",
        },
        {
          dataField: "activityName",
          text: "Activity",
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter
              onFilter={onFilter}
              column={column}
              title="Activity Search"
              labelledBy="activityNameHeader"
            />
          ),
          sort: true,
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3" };
          },
          headerAttrs: { id: "activityNameHeader" },
          attrs: { headers: "activityNameHeader", tabIndex: "0" },
        },
        {
          dataField: "technicalAssistTypesForActivity",
          text: "TA Type",
          formatter: (cell, row, rowIndex) => {
            if (row.technicalAssistTypesForActivity?.length > 0) {
              let taTypes = "";
              row.technicalAssistTypesForActivity.forEach((t) => {
                const typeName = t.displayText ? t.displayText : t.name;
                taTypes += typeName + ", ";
              });

              return taTypes.slice(0, -2);
            }
            return "N/A";
          },
          csvFormatter: (cell, row, rowIndex) => {
            if (row.technicalAssistTypesForActivity?.length > 0) {
              let taTypes = "";
              row.technicalAssistTypesForActivity.forEach((t) => {
                const typeName = t.displayText ? t.displayText : t.name;
                taTypes += typeName + ", ";
              });
              return taTypes.slice(0, -2).toString();
            }
            return "N/A";
          },
          filter: textFilter({
            onFilter: this.filterActivitiesType,
          }),
          sort: true,
          sortFunc: (a, b, order) => {
            let aList = a?.map((x) => x.name).join(", ");
            let bList = b?.map((x) => x.name).join(", ");
            if (order === "asc") {
              return bList > aList ? 1 : -1;
            } else {
              return bList > aList ? -1 : 1;
            }
          },
          align: "left",
          headerAlign: "left",
          style: this.tableTextStyle,
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          headerAttrs: { id: "activityAllowedHeader" },
          attrs: { headers: "activityAllowedHeader", tabIndex: "0" },
        },
        {
          dataField: "lastModifiedDateTimeUTC",
          text: "Last Modified Date",
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return this.formatDate(cell);
          },
          sort: true,
          sortFunc: (a, b, order) => {
            let dateA = new Date(a);
            let dateB = new Date(b);
            if (order === "asc") {
              return dateB - dateA;
            }
            return dateA - dateB; // desc
          },
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          style: this.tableTextStyle,
          headerAttrs: { id: "activityLastUpdatedHeader" },
          attrs: { headers: "activityLastUpdatedHeader", tabIndex: "0" },
        },
        {
          dataField: "activityActions",
          text: "Actions",
          csvExport: false,
          editable: false,
          formatter: (cell, row) => {
            return (
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton
                  id="dropdown-activity-actions"
                  title="Actions"
                  variant="link"
                  className={styles.buttonLink}
                >
                  <Dropdown.Item
                    eventKey="activityEdit"
                    onSelect={() => this.handleActivityEdit(row)}
                    hidden={!this.props.permissions.canIEditReferenceData()}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="activityHistoryTracking" onSelect={() => this.handleActivityHistory(row)}>
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            );
          },
          headerStyle: () => {
            return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
          },
          headerAlign: "right",
          align: "right",
          headerAttrs: { id: "activityActionsHeader" },
          attrs: { headers: "activityActionsHeader", tabIndex: "0" },
        },
      ],
      showCreateModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
    };
  }

  tableTextStyle = {};

  filterActivitiesType = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) =>
        entry.technicalAssistTypesForActivity?.some((type) =>
          type?.displayText?.toLowerCase().includes(filterValue?.toLowerCase())
        )
      );
    }
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      currentUsaState: nextProps.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentUsaState?.stateUniqueIdentifier !== this.state.currentUsaState?.stateUniqueIdentifier) {
      await this.updateActivityData();
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "taActivityTab") {
      await this.updateActivityData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "taActivityTab") {
      await this.updateActivityData();
    }
  }

  async updateActivityData() {
    this.props.setIsLoading(true);
    const activityData = await this.getDataFromAPI();
    this.setState({ activityData }, () => {
      this.props.setIsLoading(false);
    });
    window.scrollTo(0, 0);
  }

  async getDataFromAPI() {
    let taActivitiesResults = [];
    const getTaActivitiesResponse = await ReferenceFileAPI.GetAllTechnicalAssistActivities();
    if (getTaActivitiesResponse?.successful) {
      taActivitiesResults = getTaActivitiesResponse.data?.results ?? [];
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve technical assist activities",
        apiName: "GetAllTechnicalAssistActivities",
        responseUnsuccessful: getTaActivitiesResponse?.unsuccessful,
        responseMessage: getTaActivitiesResponse?.message,
      });
    }
    return taActivitiesResults;
  }

  onModalSubmit = async (newActivityData, mode) => {
    const messageyBody = {
      activityName: newActivityData.activityName,
      technicalAssistTypeEnumIds: newActivityData.selectedActivityTypes.map((t) => t.value),
    };
    if (mode === "Create") {
      const createActivityResponse = await ReferenceFileAPI.CreateTechnicalAssistActivity(messageyBody);
      if (createActivityResponse?.successful) {
        this.setState({ showCreateSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create technical assist activity",
          apiName: "CreateTechnicalAssistActivity",
          responseUnsuccessful: createActivityResponse?.unsuccessful,
          responseMessage: createActivityResponse?.message,
        });
      }
    } else if (mode === "Edit") {
      const editActivityResponse = await ReferenceFileAPI.EditTechnicalAssistActivity(
        newActivityData.technicalAssistActivityUniqueIdentifier,
        messageyBody
      );
      if (editActivityResponse?.successful) {
        this.setState({ showEditSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update technical assist activity",
          apiName: "EditTechnicalAssistActivity",
          responseUnsuccessful: editActivityResponse?.unsuccessful,
          responseMessage: editActivityResponse?.message,
        });
      }
    }
    await this.updateActivityData();
  };

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleDateString("en-US");
  }

  closeAllModals = () => {
    this.setState({ showCreateModal: false, showEditModal: false, modalData: null });
  };

  handleCreateActivity = () => {
    this.setState({ showCreateModal: true });
  };

  handleActivityEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleConfigureSubmit = (updatedData) => {
    const { activityData } = this.state;

    updatedData.lastModifiedDateTimeUTC = new Date().toLocaleDateString("en-US");
    let recordIndex = activityData.findIndex(
      (a) => a.technicalAssistActivityUniqueIdentifier === updatedData.technicalAssistActivityUniqueIdentifier
    );
    activityData[recordIndex] = updatedData;
    this.setState({ activityData });
  };

  handleActivityHistory = (row) => {
    //do nothing
  };

  renderCreateModal() {
    return (
      <CreateEditTAActivityModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode={"Create"}
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditTAActivityModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode={"Edit"}
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  render() {
    return (
      <ToolkitProvider
        keyField="technicalAssistActivityUniqueIdentifier"
        data={this.state.activityData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "TA-Activity-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="TA Activity"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleCreateActivity}
                  recordCount={this.state.activityData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="technicalAssistActivityUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.state.showCreateModal ? this.renderCreateModal() : null}
            {this.state.showEditModal ? this.renderEditModal() : null}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="TA Activity"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="TA Activity"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default TAActivityTabTab;
