import React from "react";
import { Row, Col, Button, Card, Tabs, Tab, Image, Badge } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import styles from "../Agreements/Agreement.module.scss";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import CustomTextFilter from "../common/customTextFilter";
import { toast } from "react-toastify";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import { withRouter } from "react-router-dom";
import UtilityFunctions from "../common/UtilityFunctions";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ApprovalRequestWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgreementsDataLoading: false,
      approveAgreements: [],
      flagAgreements: [],
      agreementColumns: [],
      flaggedColumns: [],
      currentLoadingNotification: "",
    };
    this.controller = new AbortController();
  }

  async componentDidMount() {
    const currentState = JSON.parse(localStorage.getItem("currentState"));
    const agreementColumns = this.getMyAgreementsColumns();
    const flaggedColumns = this.getFlagAgreementsColumns();
    this.setState({ agreementColumns, flaggedColumns });
    let flagAgreements = [];
    let flagAgreementsData = [];
    const flagAgreementsResponse = await AgreementAPI.GetAllActiveFlagsInState(
      currentState.stateGuid,
      this.controller.signal
    );
    if (flagAgreementsResponse?.successful) {
      flagAgreements = flagAgreementsResponse?.data;
    } else {
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ isAgreementsDataLoading: false });
      ErrorHandler.handleApiErrorMessage({
        signal: this.controller.signal,
        errorContextMessage: "Unable to load Flagged Agreements",
        apiName: "GetAllActiveFlagsInState",
        responseUnsuccessful: flagAgreementsResponse?.unsuccessful,
        responseMessage: flagAgreementsResponse?.message,
      });
    }

    const toApproveAgreementsResponse = await AgreementAPI.GetAgreementsForApprovalInState(
      currentState.stateGuid,
      this.controller.signal
    );

    if (toApproveAgreementsResponse?.successful) {
      let agreementdData = [];
      agreementdData = toApproveAgreementsResponse?.data.results;
      const today = new Date();
      for (let flag of flagAgreements) {
        var result = new Date(flag.startDayOfFlag);
        result.setDate(result.getDate() + 30);

        let daysResolved = UtilityFunctions.daysDifference(today, result);
        const agreementRecord = agreementdData.find(
          (r) => r.agreementUniqueIdentifier === flag.agreementThatHasBeenFlagged
        );
        if (agreementRecord) {
          const agreementFlagRecord = flagAgreementsData.find(
            (r) => r.agreementUniqueIdentifier === agreementRecord.agreementUniqueIdentifier
          );
          if (!agreementFlagRecord) {
            flagAgreementsData.push({
              agreementUniqueIdentifier: agreementRecord.agreementUniqueIdentifier,
              agreementNumber: agreementRecord.agreementNumber,
              commonName: agreementRecord.commonName,
              primaryFieldAgent: agreementRecord.primaryFieldAgent.fieldAgentName,
              agreementRenewalStatus: agreementRecord.isRenewal === "true" ? "Renewal" : "New",
              agreementSubmittedForReviewDateTime: agreementRecord.agreementSubmittedForReviewDateTime,
              response: flag.wasLastMessageFromNonFlagger === "true" ? "Yes" : "No",
              daysToResolve: daysResolved,
            });
          }
        }
      }

      this.setState({
        approveAgreements: toApproveAgreementsResponse?.data.results,
        flagAgreements: flagAgreementsData,
      });
    } else {
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ isAgreementsDataLoading: false });
      ErrorHandler.handleApiErrorMessage({
        signal: this.controller.signal,
        errorContextMessage: "Unable to load Agreements for Approval",
        apiName: "GetAgreementsForApprovalInState",
        responseUnsuccessful: toApproveAgreementsResponse?.unsuccessful,
        responseMessage: toApproveAgreementsResponse?.message,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isExpanded !== this.props.isExpanded) {
      const agreementColumns = this.getMyAgreementsColumns();
      this.setState({ agreementColumns });
    }
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("ApprovalRequestWidget.jsx got unmounted");
    }
  }

  handleAgreementDetailsNavigation = (row) => {
    let pathname = "/agreement/details";
    this.props.history.push({
      pathname: pathname,
      state: {
        entityData: row,
      },
    });
  };
  async getPrimaryWSpecialist(fieldAgentWsUserProfileUniqueReference) {
    let primaryWSpecialist = "";
    const getFieldAgentResponse = await RoleAuthorizationAPI.GetUserWhoOwnsProfile(
      fieldAgentWsUserProfileUniqueReference
    );
    if (getFieldAgentResponse?.successful) {
      const { data: fieldAgentData } = getFieldAgentResponse;
      primaryWSpecialist = fieldAgentData.firstName.concat(" ", fieldAgentData.lastName);
    }
    return primaryWSpecialist;
  }

  getMyAgreementsColumns() {
    let agreementColumns = [
      {
        dataField: "agreementUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "commonName",
        text: "Agreement Name",
        formatter: (cell, row) => {
          return (
            <Button variant="link" onClick={() => this.handleAgreementDetailsNavigation(row)}>
              {cell}
            </Button>
          );
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        style: () => {
          return { paddingLeft: "0px" };
        },
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "agreementNameHeader" },
        attrs: { headers: "agreementNameHeader", tabIndex: "0" },
      },
      {
        dataField: "agreementNumber",
        text: "Agreement Number",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "agreementNumberHeader" },
        attrs: { headers: "agreementNumberHeader", tabIndex: "0" },
      },
      {
        dataField: "primaryFieldAgent.fieldAgentName",
        text: "Primary WS Field Specialist",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "fieldAgentNameHeader" },
        attrs: { headers: "fieldAgentNameHeader", tabIndex: "0" },
      },
      {
        dataField: "agreementRenewalStatus",
        text: "Approval Type",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "approvalTypeHeader" },
        attrs: { headers: "approvalTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "agreementSubmittedForReviewDateTime",
        text: "Date Submitted",
        formatter: (cell, row) => {
          var dateTime = new Date(cell);
          return dateTime.toLocaleDateString("en-US");
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Submitted Date" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "submittedDateHeader" },
        attrs: { headers: "submittedDateHeader", tabIndex: "0" },
      },
      {
        dataField: "expirationDate",
        text: "Expiration Date",
        formatter: (cell, row) => {
          var dateTime = new Date(cell);
          return dateTime.toLocaleDateString("en-US");
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Expiration Date" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "expirationDateHeader" },
        attrs: { headers: "expirationDateHeader", tabIndex: "0" },
      },
    ];
    return agreementColumns;
  }

  getFlagAgreementsColumns() {
    let agreementColumns = [
      {
        dataField: "agreementUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "commonName",
        text: "Common Name",
        formatter: (cell, row) => {
          return (
            <Button variant="link" onClick={() => this.handleAgreementDetailsNavigation(row)}>
              {cell}
            </Button>
          );
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        style: () => {
          return { paddingLeft: "0px" };
        },
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "agreementNameHeader" },
        attrs: { headers: "agreementNameHeader", tabIndex: "0" },
      },
      {
        dataField: "agreementNumber",
        text: "Agreement Number",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "agreementNumberHeader" },
        attrs: { headers: "agreementNumberHeader", tabIndex: "0" },
      },
      {
        dataField: "primaryFieldAgent",
        text: "Primary WS Field Specialist",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "fieldAgentNameHeader" },
        attrs: { headers: "fieldAgentNameHeader", tabIndex: "0" },
      },
      {
        dataField: "agreementRenewalStatus",
        text: "Approval Type",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "approvalTypeHeader" },
        attrs: { headers: "approvalTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "agreementSubmittedForReviewDateTime",
        text: "Date Submitted",
        formatter: (cell, row) => {
          var dateTime = new Date(cell);
          return dateTime.toLocaleDateString("en-US");
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Submitted Date" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "submittedDateHeader" },
        attrs: { headers: "submittedDateHeader", tabIndex: "0" },
      },
      {
        dataField: "response",
        text: "Response",

        formatter: (cell, row) => {
          if (cell === "Yes") {
            return <span className={styles.responseText}>{cell}</span>;
          } else {
            return <span>{cell}</span>;
          }
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "responseHeader" },
        attrs: { headers: "responseHeader", tabIndex: "0" },
      },
      {
        dataField: "daysToResolve",
        text: "Days to Resolve",
        formatter: (cell, row) => {
          //508 compliant custom colors
          let variant = "success";
          let bgColor = "##008767";
          let color = "white";
          if (cell < 10) {
            variant = "danger";
            bgColor = "#b60202";
          } else if (cell <= 20) {
            variant = "warning";
            bgColor = "#9b6e00";
          }
          return (
            <Badge pill variant={variant} style={{ color: color, backgroundColor: bgColor }}>
              {cell + " days"}
            </Badge>
          );
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "daysResolveHeader" },
        attrs: { headers: "daysResolveHeader", tabIndex: "0" },
      },
    ];
    return agreementColumns;
  }

  renderapproveAgreementsTab() {
    if (this.state.approveAgreements.length > 0) {
      return (
        <BootstrapTable
          keyField="agreementUniqueIdentifier"
          data={this.state.approveAgreements}
          columns={this.state.agreementColumns}
          filter={filterFactory()}
          bootstrap4={true}
          hover={true}
          bordered={false}
        />
      );
    } else {
      return (
        <Row>
          <Col className="mt-4 text-center">{`There are no Agreements waiting for Approval.`}</Col>
        </Row>
      );
    }
  }

  renderflagAgreementsTab() {
    if (this.state.flagAgreements.length > 0) {
      return (
        <BootstrapTable
          keyField="agreementUniqueIdentifier"
          data={this.state.flagAgreements}
          columns={this.state.flaggedColumns}
          filter={filterFactory()}
          bootstrap4={true}
          hover={true}
          bordered={false}
        />
      );
    } else {
      return (
        <Row>
          <Col className="mt-4 text-center">{`There are no Agreements that are flagged.`}</Col>
        </Row>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card className={this.props.isExpanded ? styles.expandedCards : styles.nonExpandedNotifCard}>
          <Card.Body>
            <LoadingOverlay
              active={this.state.isAgreementsDataLoading}
              spinner
              text="Loading Agreements..."
              styles={{
                wrapper: (base) => ({
                  ...base,
                  height: "100%",
                }),
                overlay: (base) => ({
                  ...base,
                  width: "100%",
                  height: "100%",
                }),
              }}
            >
              <Row>
                <Col className={`text-left pl-0 ${this.props.isExpanded ? "pr-0" : ""}`}>
                  <span className={styles.widgetTitleText}>Approval Request</span>
                </Col>

                <Col className="text-right pr-2">
                  <Image
                    name="approvalRequest"
                    aria-label="Expand My Approval Request Card"
                    style={{ cursor: "pointer" }}
                    src={this.props.isExpanded ? this.props.collapseIcon : this.props.expandIcon}
                    onClick={(e) => this.props.onExpand(e)}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-left">
                  <Tabs
                    id="myAgreementsTabs"
                    onSelect={(e) => { }}
                    className={styles.myAGreementsTabs}
                    style={{ overflow: "auto" }}
                  >
                    <Tab
                      eventKey="1"
                      title={"To Approve (" + this.state.approveAgreements.length + ")"}
                      className="mt-4"
                    >
                      {this.renderapproveAgreementsTab()}
                    </Tab>
                    <Tab eventKey="2" title={"Flagged (" + this.state.flagAgreements.length + ")"} className="mt-4">
                      {this.renderflagAgreementsTab()}
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </LoadingOverlay>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}
export default withRouter(ApprovalRequestWidget);
