import React, { Component } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import styles from "./agreements.module.scss";
import globalStyles from "../../OARS.module.scss";
import BootstrapTable from "react-bootstrap-table-next";
import AsyncSelect from "react-select/async";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { toast } from "react-toastify";
import UtilityFunctions from "../common/UtilityFunctions";

class AddHistoricalLossModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialConfiguration: true,
      data: {
        speciesSearch: "",
        lossQuantity: "",
        lossPercentage: "",
        lossTotalValue: "",
      },
      historicalLossData: [],
      historicalResourceColumns: [
        { dataField: "resourceTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      historicalLossColumns: [
        { dataField: "lossTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
    };
  }

  componentDidMount() {
    this.setState({
      historicalResourceColumns: this.getHistoricalResourceColumns(),
      historicalLossColumns: this.getHistoricalLossColumns(),
    });
  }

  getHistoricalResourceColumns() {
    return [
      {
        dataField: "resourceTableId",
        hidden: true,
      },
      {
        dataField: "resourceName",
        text: "Resource",
        headerStyle: () => {
          return { width: "20%" };
        },
      },
      { dataField: "quantity", text: "Quantity" },
      {
        dataField: "unitOfMeasureName",
        text: "UOMs",
      },
      {
        dataField: "unitValue",
        text: "Unit Value",
        formatter: (cell, row) => {
          if (row.stateValuationSetting?.id != 1) {
            return UtilityFunctions.getValueAsCurrency(row.unitValue);
          }
        },
      },
      {
        dataField: "totalValue",
        text: "Total Value",
        formatter: (cell, row) => {
          if (row.stateValuationSetting?.id != 1) {
            return UtilityFunctions.getValueAsCurrency(row.totalValue);
          }
        },
      },
      {
        dataField: "year",
        text: "Year",
      },
    ];
  }

  getHistoricalLossColumns() {
    return [
      {
        dataField: "lossTableId",
        hidden: true,
      },
      {
        dataField: "resourceName",
        text: "Resource",
        headerStyle: () => {
          return { width: "20%" };
        },
      },
      { dataField: "quantity", text: "Loss Quantity" },
      { dataField: "unitOfMeasureName", text: "UOMs" },
      { dataField: "percentage", text: "Percentage" },
      {
        dataField: "totalValue",
        text: "Loss Value",
        formatter: (cell, row) => {
          if (row.stateValuationSetting?.id !== 1) {
            return UtilityFunctions.getValueAsCurrency(row.totalValue);
          }
        },
      },
      {
        dataField: "species",
        text: "Damage Agent",
        formatter: (cell, row) => {
          return row.species.label;
        },
      },
      {
        dataField: "removeLoss",
        text: "",
        formatter: (cell, row) => {
          return (
            <Button variant="link" onClick={() => this.removeLoss(row)}>
              Remove
            </Button>
          );
        },
        headerStyle: () => {
          return { width: "15%" };
        },
      },
    ];
  }

  handleSpeciesSearch = async (inputValue) => {
    return await this.getSpeciesFromAPI(inputValue);
  };

  async getSpeciesFromAPI(searchValue) {
    const { currentUsaState } = this.props;
    if (currentUsaState && currentUsaState.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );

      if (speciesData.data.results) {
        let results = speciesData.data.results;
        return results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return "";
  }

  getFilterReferenceFilesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
    };
  }

  handleSpeciesSelection = (input) => {
    const { data } = this.state;
    data.speciesSearch = input;

    this.setState({ data });
    return input;
  };

  handleLossChanges = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  addHistoricalLoss = (historicalResourceData) => {
    let { data, historicalLossData, isInitialConfiguration } = this.state;
    if (!data.speciesSearch) {
      toast.warning("Please select the damage agents associated to this loss.");
      return;
    }

    if (isInitialConfiguration) {
      isInitialConfiguration = false;

      if (historicalResourceData.historicalLosses)
        historicalLossData = JSON.parse(JSON.stringify(historicalResourceData.historicalLosses));
      else historicalLossData = [];
    }

    if (
      Number.parseFloat(data.lossQuantity) > 0 ||
      Number.parseFloat(data.lossPercentage) > 0 ||
      Number.parseFloat(data.lossTotalValue) > 0
    ) {
      const lossTableId = data.speciesSearch
        ? `${historicalResourceData.resourceUniqueIdentifier}|${data.speciesSearch.value}`
        : historicalResourceData.resourceUniqueIdentifier;
      if (historicalLossData.find((loss) => loss.lossTableId === lossTableId)) {
        toast.warning("There is already a loss associated to this combination of Resource/Species");
        return;
      }

      const newLoss = {
        lossTableId: lossTableId,
        resourceName: historicalResourceData.resourceName,
        quantity: this.getLossQuantity(data, historicalResourceData),
        unitOfMeasureName: historicalResourceData.unitOfMeasureName,
        percentage: this.getLossPercentage(data, historicalResourceData),
        totalValue: this.getLossTotalValue(data, historicalResourceData),
        species: data.speciesSearch,
        providedLossValuation: this.getProvidedLossValuation(data),
        isNewLoss: true,
        stateValuationSetting: historicalResourceData?.stateValuationSetting,
      };
      historicalLossData.push(newLoss);

      if (!this.isValidLossQuantity(historicalLossData, historicalResourceData)) {
        toast.error("The provided loss exceeds the total quantity of the resource in inventory.");
        historicalLossData.pop(newLoss);
        return;
      }

      this.clearData(data);
      this.setState({ historicalLossData, isInitialConfiguration, data });
    } else {
      toast.warning(
        "Please fill out one of the valuation fields for the new loss to be linked to historical resource inventory."
      );
    }
  };

  removeLoss = (row) => {
    let { historicalLossData, isInitialConfiguration } = this.state;
    const historicalResourceData = JSON.parse(JSON.stringify(this.props.historicalResourceData));

    if (isInitialConfiguration) {
      isInitialConfiguration = false;

      if (historicalResourceData.historicalLosses)
        historicalLossData = JSON.parse(JSON.stringify(historicalResourceData.historicalLosses));
    }

    const newLossData = historicalLossData.filter((loss) => loss.lossTableId !== row.lossTableId);
    this.setState({ historicalLossData: newLossData, isInitialConfiguration });
  };

  isValidLossQuantity(historicalLossData, historicalResourceData) {
    const sumOfQuantities = historicalLossData.reduce(
      (acumulator, currentLoss) => acumulator + Number.parseFloat(currentLoss.quantity),
      0
    );
    const sumOfPercentages = historicalLossData.reduce(
      (acumulator, currentLoss) => acumulator + Number.parseFloat(currentLoss.percentage),
      0
    );
    const sumOfTotalValues = historicalLossData.reduce(
      (acumulator, currentLoss) => acumulator + Number.parseFloat(currentLoss.totalValue),
      0
    );

    const isValidLossQuantity =
      sumOfQuantities <= Number.parseFloat(historicalResourceData.quantity) &&
      (sumOfTotalValues <= historicalResourceData.totalValue ||
        historicalResourceData.stateValuationSetting?.id == 1) &&
      sumOfPercentages <= 100;
    return isValidLossQuantity;
  }

  getProvidedLossValuation(data) {
    if (data.lossQuantity) return "quantity";
    if (data.lossPercentage) return "percentage";
    if (data.lossTotalValue) return "totalValue";
  }

  getLossQuantity(data, historicalResourceData) {
    let lossQuantity = "";
    if (data.lossQuantity) lossQuantity = data.lossQuantity;
    else if (data.lossTotalValue) lossQuantity = data.lossTotalValue / historicalResourceData.unitValue;
    else lossQuantity = (data.lossPercentage / 100) * historicalResourceData.quantity;

    if (historicalResourceData.unitOfMeasureId === 6 && Math.round(Number.parseFloat(lossQuantity)) === 0) {
      lossQuantity = 1;
    }

    return historicalResourceData.unitOfMeasureId === 6
      ? Math.round(Number.parseFloat(lossQuantity))
      : Number.parseFloat(Number.parseFloat(lossQuantity).toFixed(2));
  }

  getLossPercentage(data, historicalResourceData) {
    let lossPercentage = "";
    if (historicalResourceData.unitOfMeasureId === 6) {
      let quantity = "";
      if (data.lossPercentage) {
        quantity = (data.lossPercentage / 100) * historicalResourceData.quantity;
      } else if (data.lossQuantity) {
        quantity = data.lossQuantity;
      } else {
        quantity = data.lossTotalValue / historicalResourceData.unitValue;
      }

      if (Math.round(Number.parseFloat(quantity)) === 0) {
        const totalValue = this.getLossTotalValue(data, historicalResourceData);
        lossPercentage = (totalValue / historicalResourceData.totalValue) * 100;
      } else {
        lossPercentage = (Math.round(quantity) / historicalResourceData.quantity) * 100;
      }
    } else {
      if (data.lossPercentage) {
        lossPercentage = data.lossPercentage;
      } else if (data.lossQuantity) {
        lossPercentage = (data.lossQuantity / historicalResourceData.quantity) * 100;
      } else {
        lossPercentage = (data.lossTotalValue / historicalResourceData.totalValue) * 100;
      }
    }

    return Number.parseFloat(Number.parseFloat(lossPercentage).toFixed(2));
  }

  getLossTotalValue(data, historicalResourceData) {
    let lossTotalValue = "";
    if (historicalResourceData.unitOfMeasureId === 6) {
      let quantity = "";
      if (data.lossPercentage) {
        quantity = (data.lossPercentage / 100) * historicalResourceData.quantity;
      } else if (data.lossQuantity) {
        quantity = data.lossQuantity;
      } else {
        quantity = data.lossTotalValue / historicalResourceData.unitValue;
      }

      if (Math.round(Number.parseFloat(quantity)) === 0) {
        lossTotalValue = data.lossPercentage
          ? (data.lossPercentage / 100) * historicalResourceData.totalValue
          : data.lossTotalValue;
      } else {
        lossTotalValue = Math.round(quantity) * historicalResourceData.unitValue;
      }

      if (data.lossTotalValue) {
        lossTotalValue = data.lossTotalValue;
      } else if (data.lossQuantity) {
        lossTotalValue = (Math.round(quantity) || 1) * historicalResourceData.unitValue;
      } else {
        lossTotalValue = (data.lossPercentage / 100) * historicalResourceData.totalValue;
      }
    } else {
      if (data.lossTotalValue) {
        lossTotalValue = data.lossTotalValue;
      } else if (data.lossQuantity) {
        lossTotalValue = data.lossQuantity * historicalResourceData.unitValue;
      } else {
        lossTotalValue = (data.lossPercentage / 100) * historicalResourceData.totalValue;
      }
    }

    return Number.parseFloat(Number.parseFloat(lossTotalValue).toFixed(2));
  }

  handleSave = () => {
    this.props.onSaveHistoricalLoss(this.state.historicalLossData);
    this.props.onHide();
    this.setState({ historicalLossData: [], isInitialConfiguration: true });
  };

  handleCancel = () => {
    let { data } = this.state;
    this.clearData(data);
    this.props.onHide();
    this.setState({ historicalLossData: [], data, isInitialConfiguration: true });
  };

  clearData(data) {
    data.speciesSearch = "";
    data.lossQuantity = "";
    data.lossPercentage = "";
    data.lossTotalValue = "";
  }

  getSelectStyles() {
    return {
      indicatorSeparator: () => {
        //do nothing
      },
      placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
      option: (styles, { isFocused }) => ({
        ...styles,
        fontSize: "14px",
        fontWeight: "normal",
        color: "black",
        backgroundColor: isFocused ? "lightgray" : "white",
      }),
      singleValue: (styles) => ({
        ...styles,
        fontSize: "14px",
        fontWeight: "normal",
        color: "black",
      }),
      menuList: (styles) => ({ ...styles, textAlign: "left" }),
    };
  }

  render() {
    const historicalResourceData = JSON.parse(JSON.stringify(this.props.historicalResourceData));
    const { data, isInitialConfiguration } = this.state;

    let historicalLossData = [];
    if (!isInitialConfiguration) historicalLossData = JSON.parse(JSON.stringify(this.state.historicalLossData));
    else if (historicalResourceData.historicalLosses)
      historicalLossData = JSON.parse(JSON.stringify(historicalResourceData.historicalLosses));
    else historicalLossData = [];
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.wideModalSize}
      >
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Add a Loss</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0">
            <Col>
              <span className={styles.smallComments}>
                <i className="fa fa-exclamation-circle " style={{ color: "gray" }} aria-hidden="true"></i> Add one or
                multiple losses to resources.
              </span>
            </Col>
          </Row>
          <Row className="mt-3 mb-2 mx-0">
            <Col>
              <Modal.Title>
                {historicalResourceData ? historicalResourceData.checkedProperties[0].propertyName : ""}
              </Modal.Title>
            </Col>
          </Row>
          <Row className="mx-0">
            <BootstrapTable
              keyField="resourceTableId"
              data={[historicalResourceData]}
              columns={this.state.historicalResourceColumns}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
          <Row className="my-2 mx-0">
            <Col>
              <Modal.Title>Add a Loss</Modal.Title>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md={2}>
              <Form.Control
                name="lossQuantity"
                type="number"
                placeholder="Loss Quantity"
                value={data.lossQuantity}
                onChange={this.handleLossChanges}
                disabled={data.lossPercentage || data.lossTotalValue}
              />
            </Col>
            <Col>
              <Form.Control type="text" placeholder="UOM" value={historicalResourceData.unitOfMeasureName} disabled />
            </Col>
            <Col>
              <Form.Control
                name="lossPercentage"
                type="number"
                placeholder="Percentage"
                value={data.lossPercentage}
                onChange={this.handleLossChanges}
                disabled={data.lossQuantity || data.lossTotalValue}
              />
            </Col>
            <Col>
              {historicalResourceData.stateValuationSetting?.id != 1 && (
                <Form.Control
                  name="lossTotalValue"
                  type="number"
                  placeholder="Loss Value (USD)"
                  value={data.lossTotalValue}
                  onChange={this.handleLossChanges}
                  disabled={data.lossQuantity || data.lossPercentage}
                />
              )}
            </Col>
            <Col md={3}>
              <AsyncSelect
                value={data.speciesSearch}
                openMenuOnClick={false}
                placeholder="Search for Damage Agents"
                loadOptions={this.handleSpeciesSearch}
                onChange={this.handleSpeciesSelection}
                styles={this.getSelectStyles()}
              />
            </Col>
            <Col md={1}>
              <Button variant="link" onClick={() => this.addHistoricalLoss(historicalResourceData)}>
                Add
              </Button>
            </Col>
          </Row>
          <Row className="my-2 mx-0" hidden={historicalLossData.length === 0}>
            <BootstrapTable
              keyField="lossTableId"
              data={historicalLossData}
              columns={this.state.historicalLossColumns}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button variant="primary" onClick={this.handleSave} disabled={isInitialConfiguration}>
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default AddHistoricalLossModal;
