import API from "../API";
import { APIRoutes } from "../APIRoutes";

class NotificationAPI {
  static async GetReceivedNotificationsForUserProfile(propertyUniqueIdentifier, pageSize, pageNumber, signal) {
    let url =
      "/Notification/GetReceivedNotificationsForUserProfile/" +
      propertyUniqueIdentifier +
      "/" +
      pageSize +
      "/" +
      pageNumber;
    let api = new API(url, APIRoutes.AuditAndNotifications);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async SetNotificationArchiveStatus(notificationUniqueIdentifier, isArchived) {
    let url = "/Notification/SetNotificationArchiveStatus/" + notificationUniqueIdentifier + "/" + isArchived;
    let api = new API(url, APIRoutes.AuditAndNotifications);
    let result = await api.post();
    return result;
  }

  static async MarkNotificationRead(notificationUniqueIdentifier) {
    let url = "/Notification/MarkNotificationRead/" + notificationUniqueIdentifier;
    let api = new API(url, APIRoutes.AuditAndNotifications);
    let result = await api.post();
    return result;
  }
}

export default NotificationAPI;
