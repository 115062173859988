import React from "react";
import styles from "../AgreementEntities/Contact/Contact.module.scss";
import { Row, Col, Form } from "react-bootstrap";

const AddressDisplay = ({ addresses }) => {
	return (
		<div className="mx-2">
			{addresses.map((address, index) => {
				return (
					<div key={index}>
						<Row>
							<Col>
								<Form.Group controlId={"street" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>Street Address or PO Box</h6>
									<p className={styles.dataFields}>
										{address.mailingStreet || address.street || address.streetAddress}
									</p>
								</Form.Group>
							</Col>
						</Row>
						<Row xs={1} sm={2} md={4}>
							<Col>
								<Form.Group controlId={"city" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>City</h6>
									<p className={styles.dataFields}>{address.mailingCity || address.city}</p>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId={"state" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>State</h6>
									<p className={styles.dataFields}>{address.mailingState || address.state}</p>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId={"country" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>Country</h6>
									<p className={styles.dataFields}>{address.mailingCountry || address.country}</p>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId={"zipCode" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>ZIP Code</h6>
									<p className={styles.dataFields}>{address.mailingZip || address.zipCode}</p>
								</Form.Group>
							</Col>
						</Row>
					</div>
				);
			})}
		</div>
	);
};

export default AddressDisplay;
