import React from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";

class CreateMethodPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: this.props.history,
      allowedRadioYes: true,
      allowedRadioNo: false,
      methodName: "",
      methodTypes: [],
      methodType: "",
      methodSubTypes: [],
      validated: false,
      allowedUsesList: [],
      allowedUOMsList: [],
      allowedUsesData: [],
      allowedUOMsData: [],
      description: "",
      isCMITSMethod: false,
      onlyTransfertoPersonalWarehouse: false,
      isSerialNumberRequired: false,
      isMixingMethod: false,
    };
  }

  handleRadioYesClick = () => {
    this.setState({ allowedRadioNo: false });
    this.setState({ allowedRadioYes: true });
  };
  handleRadioNoClick = () => {
    this.setState({ allowedRadioYes: false });
    this.setState({ allowedRadioNo: true });
  };

  handleMethodTypeChange = async (event) => {
    if (event.target.value) {
      if (event.target.value !== "") {
        var methodType = event.target.value;
        const typeEnumId = this.state.methodTypes.find((x) => x.displayText === methodType)?.id;
        const newSubTypeList = await ReferenceFileAPI.GetMethodSubTypesEnumForType(typeEnumId);
        if (newSubTypeList.successful) {
          this.setState({
            methodSubTypes: newSubTypeList.data,
            methodType: methodType,
            subType: newSubTypeList.data[0].displayText,
          });
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to get method sub types",
            apiName: "GetMethodSubTypesEnumForType",
            responseUnsuccessful: newSubTypeList?.unsuccessful,
            responseMessage: newSubTypeList?.message,
          });
        }
      }
    }
  };

  handleCreateMethod = async () => {};

  extractObjectIdValuesToList(objectList) {
    let returnArray = [];
    for (let i = 0; i < objectList.length; i++) {
      returnArray.push(objectList[i].id);
    }
    return returnArray;
  }

  turnArrayIntoEnum(array) {
    let result = {};
    for (var i = 0; i < array.length; i++) {
      var current = array[i];
      result[current] = current;
    }
    return result;
  }

  async componentDidMount() {
    const types = await ReferenceFileAPI.GetMethodTypesEnum();
    const methodSubTypes = await ReferenceFileAPI.GetMethodSubTypesEnumForType(types?.data[0].id);
    let uses = await ReferenceFileAPI.GetAllMethodUses();
    let UOMs = await ReferenceFileAPI.GetAllMethodUOMs();
    if (types && uses && UOMs) {
      this.setState({
        methodTypes: types.data,
        allowedUsesList: uses.data,
        allowedUOMsList: UOMs.data,
        methodType: types.data[0].displayText,
        subType: methodSubTypes.data[0].displayText,
        methodSubTypes: methodSubTypes.data,
      });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let form = e.currentTarget;
    let validated;
    if (form.checkValidity() === false) {
      this.setState({ validated: false });
      validated = false;
    } else {
      this.setState({ validated: true });
      validated = true;
    }
    if (!validated) {
      return;
    }

    let dateTime = new Date();
    var methodRecord = {
      methodName: this.state.methodName,
      abbreviation: "",
      methodTypeEnumId: this.state.methodTypes.find((x) => x.displayText === this.state.methodType)?.id ?? -1,
      methodSubTypeEnumId: this.state.methodSubTypes.find((x) => x.displayText === this.state.subType)?.id ?? -1,
      description: "",
      regulatoryNumber: "",
      manufacturer: "",
      purpose: "",
      hazards: "",
      storage: "",
      dateAdded: dateTime.toISOString(),
      allowMethodUsesIDs: this.extractObjectIdValuesToList(this.state.allowedUsesData),
      allowMethodUOMIDs: this.extractObjectIdValuesToList(this.state.allowedUOMsData),
      comments: this.state.description,
      isCMITSMethod: this.state.isCMITSMethod,
      isMixingMethod: this.state.isMixingMethod,
      isSerialNumberRequired: this.state.isSerialNumberRequired,
      onlyTransferToPersonalWarehouse: this.state.onlyTransfertoPersonalWarehouse,
    };
    const result = await ReferenceFileAPI.CreateMethod(methodRecord);
    if (result?.successful) {
      toast.success("Method creation successful.");
      this.props.history.push({
        pathname: "/referencefiles",
        state: {
          tab: "methodTab",
          globalData: this.props.history.location.state.globalData,
          methodCreateResponseData: result.data,
        },
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create method",
        apiName: "CreateMethod",
        responseUnsuccessful: result?.unsuccessful,
        responseMessage: result?.message,
      });
    }
  };

  handleUsesCheckClick(item) {
    if (document.getElementById(item.name + item.id).checked) {
      this.state.allowedUsesData.push(item);
    } else {
      const index = this.state.allowedUsesData.indexOf(item);
      if (index > -1) {
        this.state.allowedUsesData.splice(index, 1);
      }
    }
  }

  handleUOMsCheckClick(item) {
    if (document.getElementById(item.name + item.id).checked) {
      this.state.allowedUOMsData.push(item);
    } else {
      const index = this.state.allowedUOMsData.indexOf(item);
      if (index > -1) {
        this.state.allowedUOMsData.splice(index, 1);
      }
    }
  }

  handleDropdownOnSelect(e, item, type) {
    let checkbox = document.getElementById(e);
    let listToChange = [];
    if (type === "UOM") {
      listToChange = this.state.allowedUOMsData;
    } else {
      listToChange = this.state.allowedUsesData;
    }
    if (checkbox.checked) {
      checkbox.checked = false;
      const index = listToChange.indexOf(item);
      if (index > -1) {
        listToChange.splice(index, 1);
      }
    } else {
      checkbox.checked = true;
      listToChange.push(item);
    }
  }

  handleCMITSSetupChange = ({ currentTarget: input }) => {
    const data = this.state;
    data[input.name] = input.checked;
    this.setState(data);
  };

  render() {
    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        <div className={styles.createPageContainer}>
          <h5>Create Method</h5>
          <Card className={styles.createPageCards}>
            <Card.Header className={styles.createPageCardHeader}>Method Information</Card.Header>
            <Card.Body>
              <Form.Group controlid="formType">
                <Form.Label className={globalStyles.modalFormLabel} htmlFor="methodTypesDropdown">
                  Method Type<span className={globalStyles.asterisk508}>*</span>
                </Form.Label>
                <Form.Control
                  required
                  id="methodTypesDropdown"
                  data-testid="methodTypesDropdown"
                  as="select"
                  onChange={this.handleMethodTypeChange}
                >
                  {this.state.methodTypes.map((type) => (
                    <option key={`option-${type.displayText}`}>{type.displayText}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Row>
                <Col xs={6}>
                  <Form.Group title="Enter Method Name" required controlid="formMethodName">
                    <Form.Label className={globalStyles.modalFormLabel} htmlFor="methodNameTextField">
                      Method Name<span className={globalStyles.asterisk508}>*</span>
                    </Form.Label>
                    <Form.Control
                      id="methodNameTextField"
                      data-testid="methodNameTextField"
                      type="text"
                      placeholder="Enter Name"
                      onChange={(e) => {
                        this.setState({ methodName: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlid="formMethodSubType">
                    <Form.Label className={globalStyles.modalFormLabel} htmlFor="methodSubTypesDropdown">
                      Method Sub Type
                    </Form.Label>
                    <div className={styles.createPageSelectColumn}>
                      <Form.Control
                        title="Select Method Sub Type"
                        id="methodSubTypesDropdown"
                        data-testid="methodSubTypesDropdown"
                        as="select"
                        onChange={(e) => {
                          this.setState({ subType: e.target.value });
                        }}
                      >
                        {this.state.methodSubTypes.map((subType) => (
                          <option key={`option-${subType.displayText}`}>{subType.displayText}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={6}>
                  <Form.Group controlid="allowedUsesList">
                    <h4 className={globalStyles.modalFormLabel}>Allowed Uses</h4>
                    <div className={styles.createPageMethodVariableSelectColumn}>
                      <Dropdown data-testid="UsesDropdown">
                        <Dropdown.Toggle className={styles.dropdownToggle}>Change Allowed Uses</Dropdown.Toggle>
                        <Dropdown.Menu className={styles.dropdownToggle}>
                          {this.state.allowedUsesList.map((item, index) => (
                            <div>
                              <input
                                key={index}
                                tabIndex="0"
                                title={item.name + " checkbox"}
                                label={item.name}
                                type="checkbox"
                                className={styles.dropdownCheckbox}
                                id={item.name + item.id}
                                data-toggle="button"
                                onClick={() => this.handleUsesCheckClick(item)}
                              />
                              <Dropdown.Item
                                eventKey={item.name + item.id}
                                key={`formChecklistContainerUses-${item.name}`}
                                className={styles.formCheckContainer}
                                onSelect={(e) => this.handleDropdownOnSelect(e, item, "Uses")}
                              >
                                <p
                                  className="form-check-label"
                                  style={{ color: "black", paddingLeft: "8px" }}
                                  htmlFor="itemChecked"
                                >
                                  {item.name}
                                </p>
                              </Dropdown.Item>
                            </div>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group controlid="allowedUOMList">
                    <h4 className={globalStyles.modalFormLabel}>Allowed UOMs</h4>
                    <div className={styles.createPageMethodVariableSelectColumn}>
                      <Dropdown data-testid="UOMsDropdown">
                        <Dropdown.Toggle className={styles.dropdownToggle}>Change Allowed UOMs</Dropdown.Toggle>
                        <Dropdown.Menu className={styles.dropdownToggle}>
                          {this.state.allowedUOMsList.map((item, index) => (
                            <div>
                              <input
                                key={index}
                                tabIndex="0"
                                type="checkbox"
                                className={styles.dropdownCheckbox}
                                id={item.name + item.id}
                                data-toggle="button"
                                onClick={() => this.handleUOMsCheckClick(item)}
                              />
                              <Dropdown.Item
                                eventKey={item.name + item.id}
                                key={`formChecklistContainerUOMs-${item.name}`}
                                className={styles.formCheckContainer}
                                onSelect={(e) => this.handleDropdownOnSelect(e, item, "UOM")}
                              >
                                <p
                                  className="form-check-label"
                                  style={{ color: "black", paddingLeft: "8px" }}
                                  htmlFor="itemChecked"
                                >
                                  {item.name}
                                </p>
                              </Dropdown.Item>
                            </div>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Row className="mt-3 mb-4">
                <Col>
                  <Form.Check type="checkbox" id="isCMITSMethod" inline>
                    <Form.Check.Input
                      name="isCMITSMethod"
                      type="checkbox"
                      checked={this.state.isCMITSMethod}
                      onChange={this.handleCMITSSetupChange}
                    />
                    <Form.Check.Label>
                      <span className={globalStyles.formLabel}>Track in CMITS</span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check type="checkbox" id="onlyTransfertoPersonalWarehouse" inline>
                    <Form.Check.Input
                      name="onlyTransfertoPersonalWarehouse"
                      type="checkbox"
                      checked={this.state.onlyTransfertoPersonalWarehouse}
                      onChange={this.handleCMITSSetupChange}
                    />
                    <Form.Check.Label>
                      <span className={globalStyles.formLabel}>Move to Personal Inventory</span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check type="checkbox" id="isSerialNumberRequired" inline>
                    <Form.Check.Input
                      name="isSerialNumberRequired"
                      type="checkbox"
                      checked={this.state.isSerialNumberRequired}
                      onChange={this.handleCMITSSetupChange}
                    />
                    <Form.Check.Label>
                      <span className={globalStyles.formLabel}>Require Serial Number</span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check type="checkbox" id="isMixingMethod" inline>
                    <Form.Check.Input
                      name="isMixingMethod"
                      type="checkbox"
                      checked={this.state.isMixingMethod}
                      onChange={this.handleCMITSSetupChange}
                    />
                    <Form.Check.Label>
                      <span className={globalStyles.formLabel}>Mixable</span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group title="Enter Method Description" required controlid="formMethodDescription">
                    <Form.Label className={globalStyles.modalFormLabel} htmlFor="methodDescription">
                      Description
                    </Form.Label>
                    <Form.Control
                      id="methodDescription"
                      title="Method Description"
                      as="textarea"
                      maxLength="155"
                      placeholder="Enter Description"
                      name="description"
                      onChange={(e) => {
                        this.setState({ description: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className={styles.createPageButtonContainer}>
            <Button
              className={globalStyles.modalCancelButton}
              variant="secondary"
              onClick={() => this.props.history.push({ pathname: "/referencefiles", state: { tab: "methodTab" } })}
            >
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className={globalStyles.modalSubmitButton} type="submit" variant="primary">
              <span className={globalStyles.modalSubmitButtonText}>Create</span>
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export default withRouter(CreateMethodPage);
