import "./map.css";
import React, { useEffect } from "react";
import { loadModules } from "esri-loader";
import { esriPortalConfig, config } from "../../../package.json";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

function AuthMap(property) {
  useEffect(() => {
    loadModules([
      "esri/identity/OAuthInfo",
      "esri/identity/IdentityManager",
      "esri/portal/PortalItem",
      "esri/portal/Portal",
    ]).then(([OAuthInfo, esriId, esriConfig, Portal]) => {
      let portalUrl, appId;
      if (process.env.NODE_ENV === "production" && config.environment.toLowerCase() === "production") {
        //prod mapserver
        esriConfig.portalUrl = esriPortalConfig.production.portalUrl;
        appId = esriPortalConfig.production.appId;
      } else {
        //QA - staging mapserver
        esriConfig.portalUrl = esriPortalConfig.staging.portalUrl;
        appId = esriPortalConfig.staging.appId;
      }

      // esriConfig.portalUrl = portalUrl;

      const info = new OAuthInfo({
        appId: appId,
        portalUrl: esriConfig.portalUrl,
        popup: true,
        flowType: "authorization-code",
        popupCallbackUrl: window.location.origin + "/oauth-callback",
      });
      const portal = new Portal(portalUrl);
      portal
        .load()
        .then(function () {
          sessionStorage.setItem("mapAccess", "Yes");
        })
        .catch((error) => {
          ErrorHandler.sendToAppInsights(error);
        });

      esriId.registerOAuthInfos([info]);

      esriId
        .checkSignInStatus(info.portalUrl + "/sharing")
        .then((res) => {
          sessionStorage.setItem("mapAccess", "Yes");
          property.history.push("/home");
        })
        .catch((err) => {
          ErrorHandler.sendToAppInsights("error while connecting to map server " + err);
        });

      esriId
        .getCredential(info.portalUrl + "/sharing", {
          oAuthPopupConfirmation: false,
        })
        .then((creds) => {
          sessionStorage.setItem("mapAccess", "Yes");

          if (creds) {
            toast.success("connected to ESRI");
          }
          property.history.push("/home");
        })
        .catch((err) => {
          ErrorHandler.sendToAppInsights(err);
          sessionStorage.setItem("mapAccess", "No");
          property.history.push("/home");
          return;
        });
    });
  });

  return <div></div>;
}
const AuthMapRouter = withRouter(AuthMap);
export default AuthMapRouter;
