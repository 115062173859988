import React from "react";
import CustomForm from "../../../common/form";
import { Modal, Row, Col, Form, Button, Tabs, Tab, Image } from "react-bootstrap";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import { components } from "react-select";
import Select from "react-select";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import Input from "../../../common/input";
import { toast } from "react-toastify";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";

class DiseaseShippingModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
      diseaseOptions: [],
      shipmentDetailsOptions: [],
      diseaseMonitoringAnimalSampleTable: [],
      diseaseShippingData: [],
      errors: {},
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const propsShowModalChange = prevProps.show !== this.props.show;
    const propsDiseaseMonitoringDataChange =
      prevProps.diseaseMonitoringAnimalSampleTable !== this.props.diseaseMonitoringAnimalSampleTable;
    if (propsShowModalChange && this.props.show) {
      this.setState({
        diseaseOptions: await this.getDiseasesFromAPI(this.state.currentUsaState),
        shipmentDetailsOptions: await this.getShipmentDetailsOptionsFromAPI(),
      });
    }
    if (propsDiseaseMonitoringDataChange) {
      this.setState({ diseaseMonitoringAnimalSampleTable: this.props.diseaseMonitoringAnimalSampleTable }, () => {
        this.mapDiseaseMonitoringDataToDiseaseShipping();
      });
    }
  }

  async getDiseasesFromAPI(currentUsaState) {
    let diseases = [];
    if (currentUsaState?.stateGuid) {
      const diseasesData = await ReferenceFileAPI.GetDiseasesForState(currentUsaState.stateGuid);

      if (diseasesData?.data?.results) {
        diseases = diseasesData.data.results
          .filter((d) => d?.isAllowed)
          .map((result) => ({
            value: result.diseaseUniqueIdentifier,
            label: result.name,
          }));
      }
    }
    return diseases;
  }

  async getShipmentDetailsOptionsFromAPI() {
    let shipmentDetailsOptions = [];
    const shipmentDetailsOptionsData = await WorkTaskAPI.GetLabShipmentOptions();
    if (shipmentDetailsOptionsData?.data) {
      shipmentDetailsOptions = shipmentDetailsOptionsData.data.map((result) => ({
        value: result.id,
        label: result.displayText,
      }));
    } else if (shipmentDetailsOptionsData?.unsuccessful) {
      toast.warn(shipmentDetailsOptionsData?.message);
    } else {
      toast.warn("Could not retreive Genders");
    }
    return shipmentDetailsOptions;
  }

  mapDiseaseMonitoringDataToDiseaseShipping() {
    if (this.state.diseaseMonitoringAnimalSampleTable) {
      let shippingData = [];
      this.state.diseaseMonitoringAnimalSampleTable.forEach((item) => {
        item.diseaseAndSampleRows.forEach((diseaseAndSampleItem) => {
          let foundItem = shippingData.find((shippingItem) => shippingItem.disease === diseaseAndSampleItem.disease);
          if (foundItem) {
            foundItem.diseaseQuantity = foundItem.diseaseQuantity + 1;
          } else {
            shippingData.push({
              disease: diseaseAndSampleItem.disease,
              diseaseQuantity: 1,
              shipmentDetails: 1,
              shipmentDate: "",
            });
          }
        });
      });
      this.setState({ diseaseShippingData: shippingData });
    }
  }

  handleShipmentDetailsSelection = (e, i) => {
    this.setState({
      diseaseShippingData: this.state.diseaseShippingData.map((item, index) => {
        if (index === i) {
          item.shipmentDetails = e.value;
        }
        return item;
      }),
    });
  };

  handleShipmentDateChange = (e, i) => {
    this.setState({
      diseaseShippingData: this.state.diseaseShippingData.map((item, index) => {
        if (index === i) {
          item.shipmentDate = e.target.value;
        }
        return item;
      }),
    });
  };

  handleApplyDateToAll = () => {
    if (this.state.diseaseShippingData[0]?.shipmentDate) {
      const newDate = this.state.diseaseShippingData[0]?.shipmentDate;
      this.setState({
        diseaseShippingData: this.state.diseaseShippingData.map((item, index) => {
          if (index !== 0) {
            item.shipmentDate = newDate;
          }
          return item;
        }),
      });
    } else {
      toast.warn("Please enter a date");
    }
  };

  validateData() {
    let shippingTable = this.state.diseaseShippingData;
    for (let i = 0; i < shippingTable.length; i++) {
      if (shippingTable[i].shipmentDate && shippingTable[i].shipmentDetails) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  }

  handleSave = async () => {
    if (this.validateData()) {
      let obj = {
        date: new Date(this.props.diseaseMonitoringData?.date)?.toISOString(),
        employeeUserUniqueReference: this.props.diseaseMonitoringData?.employeeId,
        propertyUniqueReference: this.props.diseaseMonitoringData?.selectedProperty?.propertyUniqueIdentifier,
        locTypeEnumId: this.props.diseaseMonitoringData?.locTypeEnumId,
        latitude: this.props.diseaseMonitoringData?.latitude,
        longitude: this.props.diseaseMonitoringData?.longitude,
        isFromTake: this.props.diseaseMonitoringData?.WSTake,
        samplesRecorded: this.state.diseaseMonitoringAnimalSampleTable.map((item) => {
          return {
            subjectId: item.subjectId,
            speciesUniqueReference: item.speciesUniqueIdentifier,
            genderEnumId: item.gender,
            ageEnumId: item.age,
            diseases: item.diseaseAndSampleRows.map((row) => {
              return {
                diseaseUniqueReference: row.disease,
                sampleTypeEnumId: row.sample,
              };
            }),
          };
        }),
        diseaseSamplesShipping: this.state.diseaseShippingData.map((item) => {
          return {
            diseaseUniqueReference: item.disease,
            quantity: item.diseaseQuantity,
            labShipmentDetailsEnumId: item.shipmentDetails,
            shipDate: new Date(item.shipmentDate).toISOString(),
          };
        }),
        takeUniqueReference:
          this.props.diseaseMonitoringData?.WSTake && this.props.takeData?.takeUniqueIdentifier
            ? this.props.takeData.takeUniqueIdentifier
            : null,
      };
      const createDiseaseMonitoringCall = await WorkTaskAPI.CreateDiseaseMonitoringEntry(obj);
      if (createDiseaseMonitoringCall?.successful) {
        toast.success("Sucessfully recorded Disease Monitoring and Shipping Details");
        this.props.save();
        this.setState({ diseaseShippingData: [] });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to save Disease Monitoring and Shipping Details",
          apiName: "CreateDiseaseMonitoringEntry",
          responseUnsuccessful: createDiseaseMonitoringCall?.unsuccessful,
          responseMessage: createDiseaseMonitoringCall?.message,
        });
      }
    } else {
      toast.error("Please fill out all required fields");
    }
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered size="xl" scrollable>
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Disease Monitoring - Shipping</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "32px" }}>
          {this.state.diseaseShippingData.map((item, index, array) => {
            let applyDate = [];
            if (index === 0 && array.length > 1) {
              applyDate.push(
                <Row>
                  <Col align="right">
                    <Button
                      variant="link"
                      onClick={this.handleApplyDateToAll}
                      className={styles.diseaseMonitoringTableText}
                    >
                      <span>Apply Date to All Samples</span>
                    </Button>
                  </Col>
                </Row>
              );
            }
            return (
              <div className={styles.diseaseShippingRowContainer} key={"diseaseShipping" + index}>
                <Row className={styles.diseaseShippingRow}>
                  <Col>
                    <Input
                      type="text"
                      name={"disease" + item.disease}
                      value={this.state.diseaseOptions.filter((disease) => disease.value === item.disease)[0].label}
                      label="Disease"
                      error={this.state.errors["disease" + item.disease]}
                      isReadOnly="true"
                    />
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      name={"diseaseQty" + item.disease}
                      value={item.diseaseQuantity}
                      label="Quantity"
                      error={this.state.errors["diseaseQty" + item.disease]}
                      isReadOnly="true"
                    />
                  </Col>
                  <Col>
                    <Form.Label className={globalStyles.formLabel}>
                      Lab Shipment Details<span className={globalStyles.asterisk508}>{" *"}</span>
                    </Form.Label>
                    <Select
                      value={this.state.shipmentDetailsOptions.filter(
                        (option) => option.value === item.shipmentDetails
                      )}
                      aria-label="Shipment Details Selection"
                      placeholder="Select"
                      styles={{
                        control: (styles) => ({ ...styles, fontSize: "14px", height: "35px" }),
                        menu: (styles) => ({ ...styles, marginTop: "0px" }),
                        noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          const color = "#EAF3F1";
                          return {
                            ...styles,
                            fontSize: "12px",
                            textAlign: "left",
                            borderBottom: "1px solid #f2f2f2",
                            backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                            color: data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor: !isDisabled && (isSelected ? color : null),
                            },
                          };
                        },
                        indicatorSeparator: () => {
                          //do nothing
                        },
                        placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                        input: (provided) => ({
                          ...provided,
                          input: {
                            opacity: "1 !important",
                          },
                        }),
                      }}
                      options={this.state.shipmentDetailsOptions}
                      onChange={(e) => this.handleShipmentDetailsSelection(e, index)}
                    />
                  </Col>
                  <Col>
                    <Input
                      type="date"
                      name={"shipmentDate" + item.shipmentDate}
                      value={item.shipmentDate}
                      label="Ship Date"
                      error={this.state.errors["shipmentDate" + item.shipmentDate]}
                      isRequired={true}
                      onChange={(e) => this.handleShipmentDateChange(e, index)}
                    />
                  </Col>
                </Row>
                {applyDate}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className={globalStyles.modalFooter} style={{ display: "block" }}>
          <Row>
            <Col align="left">
              <Button className={globalStyles.modalCancelButton} onClick={this.props.back}>
                <span className={globalStyles.modalCancelButtonText}>Back</span>
              </Button>
            </Col>
            <Col align="right">
              <Button className={globalStyles.ModalCreateButton} onClick={this.handleSave}>
                <span className={globalStyles.ModalCreateButtonText}>Submit</span>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DiseaseShippingModal;
