import React from "react";
import CustomForm from "../common/form";
import styles from "./Warehouse.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Form, Button, Row, Col, CloseButton } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import UtilityFunctions from "../common/UtilityFunctions";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class AddWarehouseMethodsModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        selectedMethods: [],
        methodNameSearch: "",
        methodTypeSearch: "",
        methodSubTypeSearch: "",
      },
      allMethods: [],
      allMethodsOriginalData: [],
      methodMainCategories: [],
      methodSubCategories1: [],
      isInitialPage: true,
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState?.stateGuid) {
      toast.error("Error loading current State Id on Add Method Modal");
      return;
    }

    let { allMethods, allMethodsOriginalData, methodMainCategories } = this.state;
    const allMethodsResponse = await ReferenceFileAPI.GetCMITSMethodsForState(currentUsaState.stateGuid);
    if (allMethodsResponse?.successful) {
      if (allMethodsResponse?.data?.results?.length > 0) {
        allMethods = allMethodsResponse.data.results
          .filter((m) => m.isCMITSMethodGlobally && m.isAllowedInState)
          .map((m) => ({ ...m, isSelected: false }));
        allMethodsOriginalData = JSON.parse(JSON.stringify(allMethods));

        const methodTypesResponse = await ReferenceFileAPI.GetMethodTypesEnum();
        if (methodTypesResponse?.successful) {
          if (methodTypesResponse?.data?.length > 0) {
            methodMainCategories = methodTypesResponse?.data?.map((type) => {
              return { value: type.id, label: type.displayText };
            });
          }
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to retrieve method type data",
            apiName: "GetMethodTypesEnum",
            responseUnsuccessful: methodTypesResponse?.unsuccessful,
            responseMessage: methodTypesResponse?.message,
          });
        }

        this.setState({
          allMethods,
          allMethodsOriginalData,
          methodMainCategories,
        });
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve method data",
        apiName: "GetCMITSMethodsForState",
        responseUnsuccessful: allMethodsResponse?.unsuccessful,
        responseMessage: allMethodsResponse?.message,
      });
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  doSubmit = async () => {
    if (this.state.isInitialPage) {
      if (!(this.state.data.selectedMethods?.length > 0)) {
        toast.warning("At least one method must be selected.");
        return;
      }
      this.setState({ isInitialPage: false });
    } else {
      if (!this.isFormDataValid()) {
        toast.warning("Please define a quantity and UOM for each selected method.");
        return;
      }
      if (!this.isFormQuantityDataValid()) {
        toast.warning("Each quantity must be equal to or greater than zero.");
        return;
      }
      this.props.onHide();
      this.props.onSave([...this.state.data.selectedMethods]);
      this.clearData();
    }
  };

  isFormDataValid() {
    let isDataValid = true;
    this.state.data.selectedMethods.forEach((method) => {
      if (!method.quantity || !method.warehouseUom?.value) {
        isDataValid = false;
      }
    });

    return isDataValid;
  }

  isFormQuantityDataValid() {
    let isDataValid = true;
    this.state.data.selectedMethods.forEach((method) => {
      if (Number.parseInt(method.quantity) < 0) {
        isDataValid = false;
      }
    });

    return isDataValid;
  }
  handleCancelButton = () => {
    if (this.state.isInitialPage) {
      this.handleModalCancel();
    } else {
      this.setState({ isInitialPage: true });
    }
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data, allMethodsOriginalData } = this.state;
    data.selectedMethods = [];
    data.methodNameSearch = "";
    data.methodTypeSearch = "";
    data.methodSubTypeSearch = "";

    this.clearFilters();
    this.setState({
      data,
      isInitialPage: true,
      allMethods: JSON.parse(JSON.stringify(allMethodsOriginalData)),
    });
  }

  handleMethodSelection = (selectedMethod) => {
    const { data, allMethods } = this.state;

    const regularMethod = allMethods.find((m) => m.methodUniqueIdentifier === selectedMethod.methodUniqueIdentifier);
    if (regularMethod) {
      regularMethod.isSelected = !regularMethod.isSelected;
    }

    if (data.selectedMethods.find((m) => m.methodUniqueIdentifier === selectedMethod.methodUniqueIdentifier)) {
      data.selectedMethods = data.selectedMethods.filter(
        (m) => m.methodUniqueIdentifier !== selectedMethod.methodUniqueIdentifier
      );
    } else {
      data.selectedMethods.push(selectedMethod);
    }

    this.setState({ data, allMethods });
  };

  handleMethodFilterSearch = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleMethodFilterSelect = async (input, selectionField) => {
    const data = { ...this.state.data };
    if (!input) {
      data[selectionField] = "";
      if (selectionField === "resourceMainCategorySearch") {
        data.methodSubTypeSearch = "";
      }
    } else {
      data[selectionField] = input;
      if (selectionField === "methodTypeSearch") {
        const newSubTypeList = await ReferenceFileAPI.GetMethodSubTypesEnumForType(input.value);
        if (newSubTypeList && newSubTypeList?.data?.length > 0) {
          this.setState({
            methodSubCategories1: newSubTypeList?.data?.map((subType) => {
              return { value: subType.id, label: subType.displayText };
            }),
          });
          data.methodSubTypeSearch = "";
        }
      }
    }
    this.setState({ data });
  };

  handleMethodQuantityChange = ({ currentTarget: input }, index) => {
    const { data } = this.state;
    if (index > -1) {
      data.selectedMethods[index].quantity = input.value;
      this.setState({ data });
    }
  };

  handleMethodUomChange = (e, index) => {
    const { data } = this.state;
    if (index > -1) {
      data.selectedMethods[index].warehouseUom = e;
      this.setState({ data });
    }
  };

  onMethodSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data, allMethods } = this.state;

      const removedMethodIndex = allMethods.findIndex((m) => m.methodUniqueIdentifier === removedValue.value);
      if (removedMethodIndex !== -1) {
        allMethods[removedMethodIndex].isSelected = false;
      }

      data.selectedMethods = data.selectedMethods.filter((m) => m.methodUniqueIdentifier !== removedValue.value);
      this.setState({ data, allMethods });
    }
  };

  clearFilters = () => {
    const { data } = this.state;
    data.methodNameSearch = "";
    data.methodTypeSearch = "";
    data.methodSubTypeSearch = "";
    this.setState({ data });
  };

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "solid 0.5px #D8DDE6",
      };
    },
  };

  renderMethodsSelectionPage = () => {
    const { data } = this.state;
    return (
      <React.Fragment>
        <Row className="mx-0 mt-4">
          <Col>
            <span className={styles.refFilesModalSubTitle}>Search for Methods</span>
          </Col>
        </Row>
        <Row className="mx-0 mt-2">
          <Col className="pr-0">
            <Form.Control
              name="methodNameSearch"
              aria-label="Method Name Search Input"
              placeholder="Enter Method Name"
              value={data.methodNameSearch}
              onChange={this.handleMethodFilterSearch}
            />
          </Col>
          <Col className="pr-0">
            <Select
              aria-label="Method Type Select"
              name="methodTypeSearch"
              placeholder="Type"
              isClearable
              value={data.methodTypeSearch}
              onChange={(e) => this.handleMethodFilterSelect(e, "methodTypeSearch")}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                  backgroundColor: isFocused ? "lightgray" : "white",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                }),
                menuList: (styles) => ({ ...styles, textAlign: "left" }),
              }}
              options={this.state.methodMainCategories}
            />
          </Col>
          <Col>
            <Select
              aria-label="Method Sub Type Select"
              name="methodSubTypeSearch"
              placeholder="Sub Type"
              isClearable
              value={data.methodSubTypeSearch}
              onChange={(e) => this.handleMethodFilterSelect(e, "methodSubTypeSearch")}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                  backgroundColor: isFocused ? "lightgray" : "white",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                }),
                menuList: (styles) => ({ ...styles, textAlign: "left" }),
              }}
              options={this.state.methodSubCategories1}
            />
          </Col>
        </Row>
        <Row>
          <Col align="right">
            <Button variant="link" onClick={this.clearFilters} style={{ fontSize: "14px" }}>
              Clear All Filters
            </Button>
          </Col>
        </Row>
        <Row className={styles.resultsBox}>{this.renderSearchResults()}</Row>
        <Row className="mx-0 mt-4">
          <Col>
            <Form.Group>
              <Form.Label className={styles.refFilesModalSubTitle} htmlFor="methodsSelected">
                Methods Selected
              </Form.Label>
              <Select
                aria-label="Methods Selected"
                name="methodsSelected"
                id="methodsSelected"
                placeholder="Methods Selected"
                isMulti
                menuIsOpen={false}
                isClearable={false}
                isSearchable={false}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: "white",
                      border: "solid 0.5px #D8DDE6",
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: "#0070D2",
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: "#53698D",
                    ":hover": {
                      backgroundColor: "white",
                    },
                  }),
                }}
                components={{
                  DropdownIndicator: (props) => {
                    return "";
                  },
                }}
                value={this.state.data?.selectedMethods?.map((m) => ({
                  value: m.methodUniqueIdentifier,
                  label: m.methodName,
                }))}
                onChange={this.onMethodSelectionChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderMethodsDetailsPage = () => {
    return (
      <React.Fragment>
        <Row className="mr-0 ml-4 px-0">
          <Col>
            <span className={globalStyles.formLabel}>Method</span>
          </Col>
          <Col>
            <span className={globalStyles.formLabel}>Quantity</span>
          </Col>
          <Col>
            <span className={globalStyles.formLabel}>UOM</span>
          </Col>
        </Row>
        {this.state.data.selectedMethods?.map((method, index) => {
          return (
            <div key={index}>
              <Row className="mr-0 ml-4 my-3 px-0">
                <Col>
                  <Form.Label className={globalStyles.formData}>{method.methodName}</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    className={globalStyles.formData}
                    type="number"
                    value={method.quantity}
                    onChange={(e) => this.handleMethodQuantityChange(e, index)}
                  />
                </Col>
                <Col>
                  <Select
                    aria-label="UOM Select"
                    placeholder="Select"
                    value={method.warehouseUom}
                    styles={this.customSelectStyles}
                    options={
                      method.allowedUOMsInState?.length > 0
                        ? method.allowedUOMsInState.map((m) => ({
                          value: m.id,
                          label: UtilityFunctions.getDisplayTextFromFieldObject(m),
                        }))
                        : []
                    }
                    onChange={(e) => this.handleMethodUomChange(e, index)}
                  />
                </Col>
              </Row>
              <Row className={styles.propertyAssociationGreySeparatorRow}></Row>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  renderSearchResults = () => {
    let results = null;
    const { data, allMethods } = this.state;
    if (data.methodNameSearch || data.methodTypeSearch || data.methodSubTypeSearch) {
      results = (
        <React.Fragment>
          {allMethods
            .filter((method) => {
              let isMatch = true;
              if (data.methodNameSearch) {
                isMatch = isMatch && method.methodName.toLowerCase().includes(data.methodNameSearch);
              }
              if (data.methodTypeSearch) {
                isMatch = isMatch && method.methodType?.id === data.methodTypeSearch.value;
              }
              if (data.methodSubTypeSearch) {
                isMatch = isMatch && method.methodSubType?.id === data.methodSubTypeSearch.value;
              }

              return isMatch;
            })
            .map((result, index) => {
              return (
                <div key={index}>
                  <Row className={`mb-2 mx-0 ${index === 0 ? "mt-3" : "mt-2"}`}>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        inline
                        checked={result.isSelected}
                        onChange={() => this.handleMethodSelection(result)}
                        title={result.methodName}
                      />
                      <span className={globalStyles.formData}>{result.methodName}</span>
                    </Col>
                  </Row>
                  <Row className={styles.greySeparatorRow}></Row>
                </div>
              );
            })}
        </React.Fragment>
      );
    }

    return results;
  };

  render() {
    let bodyContent = null;
    let cancelButtonText = "Cancel";
    let submitButtonText = "Next";

    if (!this.state.isInitialPage) {
      cancelButtonText = "Back";
      submitButtonText = "Save";
      bodyContent = this.renderMethodsDetailsPage();
    } else {
      bodyContent = this.renderMethodsSelectionPage();
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.refFilesAssociationModal}
      >
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Add Methods</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>{bodyContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleCancelButton}>
            <span className={globalStyles.modalCancelButtonText}>{cancelButtonText}</span>
          </Button>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>{submitButtonText}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddWarehouseMethodsModal;
