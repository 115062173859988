import React, { Component } from "react";
import Input from "./input";
import Select from "./select";
import CheckBox from "./checkBoxSelect";
import Label from "./label";
import { min } from "moment";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const { data, errors } = this.state;
    if (data["propertyName"] && errors["propertyName"]) return true;
    return null;
  };

  validateProperty = ({ name, value }) => {
    if (name === "propertyName" && value.length > 50) {
      return "Property Names must have a maximum of 50 characters.";
    }
    if (name === "adminWorkTaskComments" && value.length > 40) {
      return "Comments must have a maximum of 40 characters.";
    }
    if (name === "aerialWorkTaskDate" && Date.parse(value) > new Date()) {
      return "Entered date must not be in the future.";
    }
    return null;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (e.nativeEvent?.submitter?.title === "Collapse" || e.nativeEvent?.submitter?.title === "Expand") {
      e.stopPropagation();
      return;
    }
    await this.doSubmit(e);
  };

  handleChange = ({ currentTarget: input }) => {
    this.handleErrors(input);
    this.handleSpecificChanges(input);
  };

  handleSelectChange = (input, action) => {
    const selectedInput = { name: action?.name, value: input?.value };
    this.handleErrors(selectedInput);
    this.handleSpecificChanges(selectedInput);
  };

  handleCancel = (isCancel, cancelRoute, cancelTab) => {
    if (isCancel) {
      if (cancelRoute === "") this.props.history.goBack();
      else
        this.props.history.push({
          pathname: cancelRoute,
          state: { tab: cancelTab },
        });
    }
  };

  handleErrors(input) {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }

    const data = { ...this.state.data };
    if (input.type === "checkbox") {
      data[input.name] = input.checked;
    } else {
      data[input.name] = input.value;
    }
    this.setState({ data, errors });
  }

  renderButton(label, type = "button", cancelRoute = "", cancelTab = "") {
    return (
      <button
        disabled={type === "submit" && this.validate()}
        type={type === "cancel" ? "button" : type}
        onClick={() => this.handleCancel(type === "cancel", cancelRoute, cancelTab)}
        className={type === "submit" ? "btn btn-primary" : "btn btn-outline-primary"}
      >
        {label}
      </button>
    );
  }

  renderSelect(name, label, options, placeHolder = "", extraProperties = []) {
    const isRequired = extraProperties.includes("required");
    const isDisabled = extraProperties.includes("disabled");
    const isClearable = extraProperties.includes("clearable");
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        aria-label={label + " Select"}
        options={options}
        onChange={this.handleSelectChange}
        error={errors[name]}
        isRequired={isRequired}
        isClearable={isClearable}
        isDisabled={isDisabled}
        placeHolder={placeHolder}
      />
    );
  }

  renderInput(name, label, type = "text", placeHolder = "", extraProperties = []) {
    const { data, errors } = this.state;

    const isReadOnly = extraProperties.includes("readOnly");
    const isRequired = extraProperties.includes("required");


    let maxLengthIndex = -1;
    let maxLength = 524288; // default HTML maxLength
    if (Array.isArray(extraProperties)) {
      maxLengthIndex = extraProperties.findIndex((x) => x.includes("maxLength"));
      if (maxLengthIndex !== -1) {
        let lengthValue = extraProperties[maxLengthIndex].split("=")[1];
        maxLength = parseInt(lengthValue);
      }
    }

    let stepIndex = -1;
    if (Array.isArray(extraProperties)) {
      stepIndex = extraProperties.findIndex((x) => x.includes("step"));
      if (stepIndex !== -1) {
        let lengthValue = extraProperties[stepIndex].split("=")[1];
        stepIndex = parseFloat(lengthValue);
      }
    }
    if (type == 'number') {
      return (
        <Input
          type={type}
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          placeholder={placeHolder}
          maxLength={maxLength}
          step={stepIndex > -1 ? stepIndex : 0}
        />
      );
    }
    else {
      return (
        <Input
          type={type}
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          placeholder={placeHolder}
          maxLength={maxLength}
        />
      );
    }

  }

  renderInlineCheckBoxSet(name, label, options, type = "radio", defaultValue = "", extraProperties = []) {
    const isRequired = extraProperties.includes("required");
    const { data, errors } = this.state;

    return (
      <CheckBox
        name={name}
        value={data[name]}
        type={type}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        defaultValue={defaultValue}
        isRequired={isRequired}
      />
    );
  }

  renderLabel(name, label, value, type = "text") {
    return <Label name={name} value={value} label={label} onChange={this.handleChange} />;
  }

  renderEditableField(
    conditionalEditField,
    fieldName,
    fieldLabel,
    fieldValue,
    fieldType = "text",
    fieldOptions = [],
    extraProperties = []
  ) {
    let editableField = "";
    if (!conditionalEditField) {
      const displayedValue = this.getEditableLabelValue(fieldValue, fieldType, fieldOptions);
      editableField = this.renderLabel(fieldName, fieldLabel, displayedValue);
    } else if (fieldType === "select") {
      const displayedValue = fieldValue?.displayText ? fieldValue.displayText : "Select";
      editableField = this.renderSelect(fieldName, fieldLabel, fieldOptions, displayedValue, extraProperties);
    } else {
      editableField = this.renderInput(fieldName, fieldLabel, fieldType, fieldValue || "Enter", extraProperties);
    }

    return editableField;
  }

  getEditableLabelValue(fieldValue, fieldType, fieldOptions) {
    let displayedValue = fieldValue;
    if (fieldType === "select") {
      const selectedOption = fieldOptions.find((x) => x?.id === fieldValue || x?.id === parseInt(fieldValue));
      if (selectedOption) {
        if (selectedOption.displayText) {
          displayedValue = selectedOption.displayText;
        } else if (selectedOption.name) {
          displayedValue = selectedOption.name;
        } else {
          displayedValue = "";
        }
      }
    }
    return displayedValue;
  }
}

export default Form;
