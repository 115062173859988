import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import CustomForm from "../../../common/form";
import { toast } from "react-toastify";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";

class ManageProjectsModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      availableProjects: props.availableProjects,
      selectedProjects: [],
      columns: [
        {
          dataField: "projectUniqueIdentifier",
          hidden: true,
        },
        {
          dataField: "projectName",
          text: "Name",
          sort: true,
          align: "left",
          headerAlign: "left",
          filter: textFilter({
            style: {
              fontSize: "14px",
            },
          }),
          headerStyle: () => {
            return { fontSize: "14px" };
          },
          style: { fontSize: "14px" },
        },
      ],
    };
  }

  componentDidMount() {
    this.setAlreadySelectedProjects();
  }

  setAlreadySelectedProjects() {
    this.setState({ selectedProjects: this.props.manageProjectsData?.projects?.map((p) => p.projectUniqueReference) });
  }

  addOrRemoveProjectsToWorkPerformed = async () => {
    let addProjectsResponse;
    let removeProjectsResponse;
    let addSuccess = true;
    let removeSuccess = true;
    const currentDifference = this.state.selectedProjects?.filter(
      (currentProject) =>
        this.props.manageProjectsData?.projects
          ?.map((previousProject) => previousProject.projectUniqueReference)
          .indexOf(currentProject) === -1
    );
    const previousDifference = this.props.manageProjectsData?.projects?.filter(
      (currentProject) => this.state.selectedProjects?.indexOf(currentProject.projectUniqueReference) === -1
    );
    if (currentDifference?.length === 0 && previousDifference?.length === 0) {
      return;
    }
    if (currentDifference?.length > 0) {
      //add
      addProjectsResponse = await WorkTaskAPI.AddProjectsToWorkPerformed(
        this.props.manageProjectsData?.workPerformedUniqueIdentifier,
        currentDifference
      );
      addSuccess = addProjectsResponse?.successful;
    }
    if (previousDifference?.length > 0) {
      //remove
      removeProjectsResponse = await WorkTaskAPI.RemoveProjectsFromWorkPerformed(
        this.props.manageProjectsData?.workPerformedUniqueIdentifier,
        previousDifference.map((project) => project.projectUniqueReference)
      );
      removeSuccess = removeProjectsResponse?.successful;
    }
    if (addSuccess && removeSuccess && (addProjectsResponse || removeProjectsResponse)) {
      toast.success("Successfully updated Projects on Work Performed");
      this.props.refreshPVSObject();
      return true;
    } else {
      ErrorHandler.showErrorWithMessage(
        "Unable to add or remove some projects to Work Performed. " +
          (addProjectsResponse?.message ?? "") +
          " " +
          (removeProjectsResponse?.message ?? "")
      );
      return false;
    }
  };

  doSubmit = async () => {
    let success = await this.addOrRemoveProjectsToWorkPerformed();
    if (success) {
      this.props.onHide();
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.modalDialog}
      >
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Manage Projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="pl-3">
            <Col>
              {this.renderLabel("Method", "Method", this.props.manageProjectsData?.methodName ?? "No Name", "text")}
            </Col>
          </Row>
          <Row className="pl-3">
            <Col>
              <ToolkitProvider
                keyField="projectUniqueIdentifier"
                data={this.state.availableProjects}
                columns={this.state.columns}
                bootstrap4={true}
                hover={true}
              >
                {(props) => (
                  <div>
                    <BootstrapTable
                      keyField="projectUniqueIdentifier"
                      bordered={false}
                      condensed
                      filter={filterFactory()}
                      filterPosition="inline"
                      selectRow={{
                        mode: "checkbox",
                        clickToSelect: true,
                        selected: this.state.selectedProjects,
                        onSelect: (row, isSelect) => {
                          this.setState({
                            selectedProjects: isSelect
                              ? [...this.state.selectedProjects, row.projectUniqueIdentifier]
                              : this.state.selectedProjects.filter((p) => p !== row.projectUniqueIdentifier),
                          });
                        },
                        onSelectAll: (isSelect, row) => {
                          this.setState({
                            selectedProjects: isSelect
                              ? this.state.availableProjects.map((p) => p.projectUniqueIdentifier)
                              : [],
                          });
                        },
                        style: (row) => {
                          const style = {};
                          if (row.projectUniqueIdentifier === this.state.selectedProjects?.projectUniqueIdentifier) {
                            style.backgroundColor = "#ADD8E6";
                          } else {
                            style.backgroundColor = "#DAE9E5";
                          }
                          return style;
                        },
                        headerColumnStyle: () => {
                          return {
                            verticalAlign: "text-top",
                          };
                        },
                      }}
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="ml-2" variant="outline-primary" onClick={this.props.onHide}>
            Cancel
          </Button>
          <Button className="ml-2" variant="primary" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default ManageProjectsModal;
