import axios from "axios";
import { config } from "../../package.json";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import lsm from "./lsm";

axios.interceptors.response.use(
  (response) => {
    try {
      let isUserAuthenticated = lsm.IsUserAuthenticated() === true;
      let anyUserInfoChanges = lsm.UpdateProfileInfo(response) === true;

      if (anyUserInfoChanges === true && isUserAuthenticated === true) {
        console.warn("The user is authenticated, but a response header had different values to the local storage profile. So forcing page refresh.");
        window.location.reload();
      }
    } catch (error) {
      ErrorHandler.logError(error);
    }

    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        lsm.SetUserNotAuthenticated();
        console.log("API call 401 caused us to clear Token from local storage.");
        lsm.RedirectToLogin("API failure event reacts to 401 response")
      } else {
        if (error?.response?.data?.message) {
          ErrorHandler.showError(error.response.data.message);
        }
        else {
          console.error(`Server Error ${error.response.status}`)
        }


      }
    }
    return error;
  }
);

class API {
  constructor(url, microservice) {
    const token = localStorage.getItem("token");
    if (microservice === undefined) {
      this.url = url;
      return;
    }
    this.url = "/" + microservice + "/api/v0.1" + url;
    this.options = { headers: { Authorization: `Bearer ${token}` } };
    axios.defaults.withCredentials = true;
  }

  async get(additionalOptions) {
    let result = null;
    if (additionalOptions) {
      additionalOptions.forEach((option) => (this.options[option.optionName] = option.optionContent));
    }
    await axios
      .get(this.url, this.options)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("axios request cancelled " + err.message);
        }
        if (config.environment.toLowerCase() !== "production") {
          console.log(err);
        }
        ErrorHandler.sendToAppInsights(err, "API get call failure for " + this.url);
      });
    return result;
  }

  async post(body) {
    let result = null;
    await axios
      .post(this.url, body, this.options)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => {
        ErrorHandler.sendToAppInsights(err, "API post call failure for " + this.url);
      });
    return result;
  }

  async put(body, additionalOptions) {
    let result = null;
    if (additionalOptions) {
      additionalOptions.forEach((option) => (this.options[option.optionName] = option.optionContent));
    }
    await axios
      .put(this.url, body, this.options)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => {
        ErrorHandler.sendToAppInsights(err, "API put call failure for " + this.url);
      });
    return result;
  }

  async delete() {
    let result = null;
    await axios
      .delete(this.url, this.options)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => {
        ErrorHandler.sendToAppInsights(err, "API delete call failure for " + this.url);
      });
    return result;
  }
}

export default API;
