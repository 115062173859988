import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Select from "react-select";

class CreateSpecies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
      types: [],
      subTypes: [],
      class: "",
      type: "",
      order: "",
      family: "",
      subType: "",
      name: "",
      inverted_name: "",
      scientific_name: "",
      endangeredStatusId: 3,
      endangeredStatuses: [],
      taxonomy_number: 0,
      code4: "",
      code6: "",
      t_e: "",
      country: "United States of America",
      base: "",
      da_id: 0,
      da_name: "",
      description: "",
      validated: false,
      allowedUOMsList: [],
      selectedUOMs: [],
    };
  }

  handleFieldChange = (e) => {
    let state = this.state;
    let value = e.target.value;
    if (e.target.name === "code4" || e.target.name === "code6") {
      value = value.replace(/[^A-Za-z]/gi, "");
    }
    state[e.target.name] = value;
    this.setState({ state });
  };

  handleSpeciesTypeChange = async (e) => {
    const incomingType = e.target.value;
    if (incomingType) {
      this.setState({
        type: incomingType,
      });
    }
  };

  async componentDidMount() {
    let state = this.state;

    if (this.state.types.length === 0) {
      const typesCall = await ReferenceFileAPI.GetSpeciesTypes();
      if (typesCall?.successful) {
        state.types = typesCall.data;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get damage agent types",
          apiName: "GetSpeciesTypes",
          responseUnsuccessful: typesCall?.unsuccessful,
          responseMessage: typesCall?.message,
        });
      }
    }
    if (this.state.subTypes.length === 0) {
      const subTypesCall = await ReferenceFileAPI.GetAllSpeciesCategorizationInfo(this.state.currentUsaState.stateGuid);
      if (subTypesCall?.successful) {
        state.subTypes = subTypesCall.data?.allSubTypes;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get damage agent sub types",
          apiName: "GetAllSpeciesCategorizationInfo",
          responseUnsuccessful: subTypesCall?.unsuccessful,
          responseMessage: subTypesCall?.message,
        });
      }
    }

    if (this.state.endangeredStatuses.length === 0) {
      const endangeredTypesCall = await ReferenceFileAPI.GetAllSpeciesEndangeredStatuses();
      if (endangeredTypesCall?.successful) {
        state.endangeredStatuses = endangeredTypesCall?.data?.reverse();
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get damage agent endangered statuses",
          apiName: "GetAllSpeciesEndangeredStatuses",
          responseUnsuccessful: endangeredTypesCall?.unsuccessful,
          responseMessage: endangeredTypesCall?.message,
        });
      }
    }

    state.allowedUOMsList = await this.getSpeciesUOMs();
    this.setState({ state });
  }

  async getSpeciesUOMs() {
    let result = [];
    let getAllSpeciesUOMsCall = await ReferenceFileAPI.GetAllSpeciesUOMs();
    if (getAllSpeciesUOMsCall?.successful) {
      result = getAllSpeciesUOMsCall.data.map((species) => ({
        value: species.id,
        label: species.displayText,
      }));
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get damage agent units of measures",
        apiName: "GetAllSpeciesUOMs",
        responseUnsuccessful: getAllSpeciesUOMsCall?.unsuccessful,
        responseMessage: getAllSpeciesUOMsCall?.message,
      });
    }

    return result;
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let form = e.currentTarget;

    if (!form.checkValidity()) {
      toast.warning("Please fill out all required fields. Taxonomy Number must be less than 2147483647.");
      return;
    }

    if (this.state.selectedUOMs?.length === 0) {
      toast.warning("Species UOM must be selected");
      return;
    }
    this.setState({ validated: true });

    var speciesRecord = {
      class: this.state.class,
      type: this.state.type,
      order: this.state.order,
      family: this.state.family,
      subType: this.state.subType,
      name: this.state.name,
      inverted_name: this.state.inverted_name,
      scientific_name: this.state.scientific_name,
      endangeredStatusId: parseInt(this.state.endangeredStatusId),
      taxonomy_number: parseInt(this.state.taxonomy_number.replace("-", "")),
      code4: this.state.code4,
      code6: this.state.code6,
      t_e: "",
      country: this.state.country,
      base: this.state.base,
      da_id: 0,
      da_name: "",
      comments: this.state.description,
      allowedSpeciesUOMIds: this.extractObjectIdValuesToList(this.state.selectedUOMs),
    };

    const createNewSpeciesCall = await ReferenceFileAPI.CreateNewSpecies(speciesRecord);
    if (createNewSpeciesCall?.message) {
      if (createNewSpeciesCall.message.includes("existing")) {
        toast.warning("Damage Agents creation failed. Cannot create duplicate damage agents.");
      }
    } else if (createNewSpeciesCall?.successful) {
      toast.success("Damage Agents creation successful.");
      this.props.history.push({
        pathname: "/referencefiles",
        state: {
          speciesCreateResponseData: createNewSpeciesCall.data,
          tab: "speciesTab",
          globalData: this.props.history.location.state.globalData,
        },
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create damage agent",
        apiName: "CreateNewSpecies",
        responseUnsuccessful: createNewSpeciesCall?.unsuccessful,
        responseMessage: createNewSpeciesCall?.message,
      });
    }
  };

  handleUOMSelection = (e) => {
    this.setState({ selectedUOMs: e });
  };

  extractObjectIdValuesToList(objectList) {
    if (objectList) {
      let returnArray = [];
      for (let i = 0; i < objectList.length; i++) {
        returnArray.push(objectList[i].value);
      }
      return returnArray;
    }
  }

  render() {
    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        <div className={styles.createPageContainer}>
          <Row className="mt-4">
            <Col className="pl-0">
              <h1 className={globalStyles.pageTitle}>Create Damage Agent</h1>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="px-0">
              <Card>
                <Card.Header className={globalStyles.cardTitles}>
                  <span className={globalStyles.cardTitleText}>Damage Agents Information</span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>Damage Agent Name</h5>
                      <Form.Control
                        id="speciesNameInput"
                        data-testid="speciesName"
                        type="text"
                        title="Damage Agents Name Input"
                        placeholder="Enter Name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Common Name<span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        required
                        id="commonNameInput"
                        title="Common Name Input"
                        data-testid="commonSpeciesName"
                        type="text"
                        placeholder="Enter Name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Inverted Common Name<span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        required
                        aria-required="true"
                        title="Inverted Common Name Input"
                        id="invertedCommonNameInput"
                        data-testid="invertedCommonSpeciesName"
                        type="text"
                        placeholder="Enter Name"
                        name="inverted_name"
                        value={this.state.inverted_name}
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Scientific Name<span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        required
                        id="scientificNameInput"
                        title="Scientific Name Input"
                        data-testid="scientificNameInput"
                        type="text"
                        placeholder="Enter Name"
                        name="scientific_name"
                        value={this.state.scientific_name}
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>Threatened/Endangered Status</h5>
                      <Form.Control
                        id="endangeredStatus"
                        data-testid="endangeredStatus"
                        as="select"
                        name="endangeredStatusId"
                        value={this.state.endangeredStatusId}
                        onChange={this.handleFieldChange}
                        title="Threatened/Endangered Status Select"
                      >
                        {this.state.endangeredStatuses.map((status) => {
                          return (
                            <option key={status.name} value={status.id}>
                              {status.displayText}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="px-0">
              <Card>
                <Card.Header className={globalStyles.cardTitles}>
                  <span className={globalStyles.cardTitleText}>Damage Agent Categorization</span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Damage Agent Type<span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        required
                        id="speciesTypesDropdown"
                        data-testid="speciesTypesDropdown"
                        as="select"
                        onChange={this.handleSpeciesTypeChange}
                        title="Damage Agent Type Select"
                      >
                        <option></option>
                        {this.state.types.map((type) => (
                          <option key={type}>{type}</option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Damage Agent Class<span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        required
                        id="speciesClassInput"
                        title="Damage Agents Class Input"
                        data-testid="speciesClassInput"
                        type="text"
                        placeholder="Enter Class"
                        name="class"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Damage Agent Category
                        <span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        id="speciesSubTypesDropdown"
                        data-testid="speciesSubTypesDropdown"
                        as="select"
                        name="subType"
                        onChange={this.handleFieldChange}
                        title="Damage Agent Sub-Type"
                      >
                        <option></option>
                        {this.state.subTypes.map((subType) => (
                          <option key={subType}>{subType}</option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Damage Agent Family<span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        required
                        id="speciesFamilyInput"
                        title="Damage Agents Family Input"
                        data-testid="speciesFamilyInput"
                        type="text"
                        placeholder="Enter Family"
                        name="family"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Damage Agent Order<span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        required
                        id="speciesOrderInput"
                        title="Damage Agents Order Input"
                        data-testid="speciesOrderInput"
                        type="text"
                        placeholder="Enter Order"
                        name="order"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel}>
                          UOM<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Select
                          value={this.state.selectedUOMs}
                          aria-label="UOMs Selection"
                          isMulti="true"
                          placeholder="Select UOM"
                          styles={this.customSelectStyles}
                          options={this.state.allowedUOMsList}
                          onChange={(e) => this.handleUOMSelection(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="px-0">
              <Card>
                <Card.Header className={globalStyles.cardTitles}>
                  <span className={globalStyles.cardTitleText}>Additional Information</span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>In Country</h5>
                      <Form.Control
                        id="inCountryInput"
                        title="In Country Input"
                        defaultValue="United States of America"
                        data-testid="inCountryInput"
                        type="text"
                        placeholder="Enter Country"
                        name="country"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>In Base</h5>
                      <Form.Control
                        id="inBaseInput"
                        title="In Base Input"
                        data-testid="inBaseInput"
                        type="text"
                        placeholder="Enter Base"
                        name="base"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>Code 4</h5>
                      <Form.Control
                        value={this.state.code4}
                        id="code4Input"
                        title="Code 4 Input"
                        data-testid="code4Input"
                        type="text"
                        maxLength="4"
                        placeholder="xxxx"
                        name="code4"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>Code 6</h5>
                      <Form.Control
                        value={this.state.code6}
                        id="code6Input"
                        title="Code 6 Input"
                        data-testid="code6Input"
                        maxLength="6"
                        type="text"
                        placeholder="xxxxxx"
                        name="code6"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>
                        Taxonomy Number<span className={globalStyles.asterisk508}>*</span>
                      </h5>
                      <Form.Control
                        required
                        id="taxNumInput"
                        title="Taxonomy Number Input"
                        data-testid="taxNumInput"
                        type="number"
                        min="0"
                        max="2147483647"
                        placeholder="Enter Taxonomy Number"
                        name="taxonomy_number"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>Description</h5>
                      <Form.Control
                        id="speciesDescription"
                        title="Damage Agents Description"
                        as="textarea"
                        maxLength="155"
                        placeholder="Enter Description"
                        name="description"
                        onChange={this.handleFieldChange}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className={styles.createPageButtonContainer}>
            <Button
              className={globalStyles.modalCancelButton}
              variant="secondary"
              onClick={() =>
                this.props.history.push({
                  pathname: "/referencefiles",
                  state: { tab: "speciesTab" },
                })
              }
            >
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className={globalStyles.modalSubmitButton} type="submit" variant="primary">
              <span className={globalStyles.modalSubmitButtonText}>Create</span>
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}
export default withRouter(CreateSpecies);
