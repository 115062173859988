import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Card, Badge, Form, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import CustomTextFilter from "../../common/customTextFilter";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import globalStyles from "../../../OARS.module.scss";
import styles from "./Users.module.scss";
import { toast } from "react-toastify";
import CustomForm from "../../common/form";
import SuccessfulRecordModifiedModal from "../../common/create-updateOkMessageModal";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import UtilityFunctions from "../../common/UtilityFunctions";
import CreateProfileModal from "../CreateProfileModal";
import RoleAuthorizationAPI from "../../../api/RoleAuthorization/RoleAuthorizationAPI";
import Select from "react-select";
import ChevronDown from "../../../assets/chevron-down.svg";
import ChevronRight from "../../../assets/chevron-right.svg";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

class UserRecord extends CustomForm {
  state = {
    data: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address: "",
      city: "",
      currentState: "",
      zipCode: "",
      userType: "",
      userProfiles: [],
      filteredState: "",
    },
    isUserActive: true,
    states: [],
    userTypes: [],
    isFormEditable: false,
    showSuccessfulUpdateModal: false,
    showCreateProfileModal: false,
    editProfileModalData: null,
    areAllProfilesSelected: false,
    showEditProfileModal: false,
    availableRoles: [],
    errors: {},
  };

  async componentDidMount() {
    if (this.props.location.state?.userData?.wsUserUniqueIdentifier) {
      const { data } = this.state;
      data.userUniqueIdentifier = this.props.location.state.userData.wsUserUniqueIdentifier;
      this.setState({ data });
      await this.updateUserData();
    }

    this.setState({
      states: await this.getAvailableStates(),
      userTypes: await this.getUserTypes(),
      availableRoles: this.props.history.location?.state?.availableRoles
        ? this.props.history.location.state.availableRoles
        : [],
    });
  }

  async getUserTypes() {
    return this.props.history?.location?.state?.userTypes
      ? this.props.history.location.state.userTypes
      : await this.getUsersTypesFromAPI();
  }

  async getUsersTypesFromAPI() {
    let usersTypes = [];
    const userTypesResponse = await RoleAuthorizationAPI.GetAllUserTypes();
    if (userTypesResponse?.successful && userTypesResponse.data?.length > 0) {
      usersTypes = userTypesResponse.data;
    }

    return usersTypes;
  }

  async getAvailableStates() {
    const availableStates = this.props.history.location?.state?.states
      ? this.props.history.location.state.states
      : await this.getAllStates();

    return availableStates.map((state) => ({
      id: state.stateUniqueIdentifier || state.id,
      name: state.state_name || state.name,
      state_code: state.state_code,
    }));
  }

  async getAllStates() {
    let usaStates = [];
    const usaStatesFromAPI = await ReferenceFileAPI.GetAllStates();
    if (usaStatesFromAPI?.length > 0) {
      usaStates = usaStatesFromAPI;
    }

    return usaStates;
  }

  getUserProfilesColumns() {
    return [
      {
        dataField: "wsUserProfileUniqueIdentifier",
        hidden: true,
        csvText: "Profile Unique Id",
      },
      {
        dataField: "profileSelect",
        text: "Profile Select",
        csvExport: false,
        headerFormatter: () => {
          return "";
        },
        formatter: (cell, row) => {
          let index = this.state.data.userProfiles.findIndex(
            (profile) => profile.wsUserProfileUniqueIdentifier === row.wsUserProfileUniqueIdentifier
          );
          return (
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={row.isSelected}
                onChange={() => this.handleProfileSelect(index)}
              />
              <Form.Check.Label className={globalStyles.formDataLinks}>{row.profileName}</Form.Check.Label>
            </Form.Check>
          );
        },
        headerStyle: () => {
          return {
            paddingLeft: "0px",
            paddingBottom: "0px",
            verticalAlign: "text-top",
            align: "left",
            borderBottom: "0px",
          };
        },
        style: { paddingLeft: "0px", paddingBottom: "0px" },
        align: "left",
        headerAlign: "left",
        headerAttrs: {
          id: "profileSelectionHeader",
          title: "Profile Select Header",
        },
        attrs: { headers: "profileSelectionHeader" },
      },
      {
        dataField: "profileStates",
        text: "Profile States",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Profile States" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let profileStates = [];
          if (row.profileAssociatedStates?.length > 0) {
            for (let profileStateId of row.profileAssociatedStates) {
              const currentState = formatExtraData.states.find((s) => s.id === profileStateId);
              if (currentState && !profileStates.includes(currentState.state_code)) {
                profileStates.push(currentState.state_code);
              }
            }
          }

          return (
            <p
              className={globalStyles.formData}
              style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", marginBotton: "0px" }}
            >
              {profileStates.length > 0 ? profileStates.join(", ") : ""}
            </p>
          );
        },
        formatExtraData: this.state,
        style: { paddingBottom: "0px" },
        headerAttrs: { id: "userRoleHeader", hidden: true },
        attrs: { headers: "userRoleHeader", tabIndex: "0" },
      },
      {
        dataField: "addRemovePermissions",
        text: "Add Remove Permissions",
        csvExport: false,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <Dropdown>
              <Dropdown.Toggle variant="link" drop="down">
                <span className={styles.profileActionsDropdown}>Actions</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.props.permissions.canIEditUserRecordForAssignedStates() && (
                  <Dropdown.Item eventKey="EditProfileStates" onSelect={() => this.showEditProfileModal(row)}>
                    Edit State(s)
                  </Dropdown.Item>
                )}
                {this.props.permissions.canIEditSpecialPermissionsAssignedStates() && (
                  <Dropdown.Item
                    eventKey="AddPermissionsToProfile"
                    onSelect={() => this.handleAdditionalPermissionsNavigation(row)}
                  >
                    Add Permissions
                  </Dropdown.Item>
                )}
                {this.props.permissions.canIEditGlobalUserRecord() && (
                  <Dropdown.Item eventKey="RemoveProfile" onSelect={() => this.handleRemoveProfile(row, rowIndex)}>
                    Remove
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          );
        },
        formatExtraData: this.state,
        headerStyle: () => {
          return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
        },
        style: { paddingBottom: "0px" },
        headerAlign: "right",
        align: "right",
        headerAttrs: {
          id: "addRemovePermissionsHeader",
          title: "Add/RemovePermissions Header",
          hidden: "true",
        },
        attrs: { headers: "addRemovePermissionsHeader" },
      },
    ];
  }

  handleAdditionalPermissionsNavigation = (data) => {
    this.props.history.push({
      pathname: "/additionalpermissions",
      state: {
        userData: this.props.history.location.state.userData,
        userProfileData: data,
        states: this.state.states,
        userName: `${this.state.data.firstName} ${this.state.data.lastName}`,
        availableRoles: this.props.history.location.state.availableRoles,
        userTypes: this.state.userTypes,
      },
    });
  };

  selectAllProfiles = () => {
    let { areAllProfilesSelected } = this.state;
    const data = JSON.parse(JSON.stringify(this.state.data));
    if (areAllProfilesSelected) {
      areAllProfilesSelected = false;
      data.userProfiles.forEach((profile) => {
        profile.isSelected = false;
      });
    } else {
      areAllProfilesSelected = true;
      data.userProfiles.forEach((profile) => {
        profile.isSelected = true;
      });
    }

    this.setState({ data, areAllProfilesSelected });
  };

  handleProfileSelect = (selectedProfileIndex) => {
    const data = JSON.parse(JSON.stringify(this.state.data));
    if (selectedProfileIndex !== -1) {
      const currentProfile = data.userProfiles[selectedProfileIndex];
      currentProfile.isSelected = !currentProfile.isSelected;
    }
    this.setState({ data });
  };

  handleRemoveProfile = async (row) => {
    const removeUserProfileResponse = await RoleAuthorizationAPI.RemoveUserProfile(row.wsUserProfileUniqueIdentifier);
    if (removeUserProfileResponse?.successful) {
      await this.updateUserData();
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to remove user profile",
        apiName: "RemoveUserProfile",
        responseMessage: removeUserProfileResponse?.message,
        responseUnsuccessful: removeUserProfileResponse?.successful,
      });
    }
  };

  handleSpecificChanges(input) {
    if (input.name === "phoneNumber") {
      let { data } = this.state;
      data.phoneNumber = UtilityFunctions.formatPhoneNumber(input.value);
      this.setState({ data });
    }
  }

  editUser = async () => {
    if (!this.state.isFormEditable) {
      this.setState({
        isFormEditable: true,
      });
    } else {
      await this.applyChanges();
    }
  };

  handleUserStatusChange = () => {
    this.setState({ isUserActive: !this.state.isUserActive });
  };

  async applyChanges() {
    if (!this.isFormDataValid()) {
      toast.warning("Please fill out all the required fields before submitting the form.");
      return;
    }

    const { data, isUserActive } = this.state;
    if (
      (Number.parseInt(data.userType) !== 1 || UtilityFunctions.validateEmail(data.email)) &&
      (data.phoneNumber === "" || UtilityFunctions.validatePhoneNumber(data.phoneNumber))
    ) {
      const updateUserBody = {
        firstName: data.firstName,
        lastName: data.lastName,
        userPrincipleName: data.email,
        userTypeEnumId: data.userType,
        streetAddress: data.address,
        city: data.city,
        stateOfAddressUniqueReference: data.currentState,
        zip: data.zipCode,
        primaryPhoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(data.phoneNumber),
        isActiveUser: isUserActive,
      };

      const updateUserResponse = await RoleAuthorizationAPI.UpdateUser(updateUserBody, data.userUniqueIdentifier);
      if (updateUserResponse?.successful) {
        this.setState({ showSuccessfulUpdateModal: true, isFormEditable: false });
        await this.updateUserData();
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update user details",
          apiName: "UpdateUser",
          responseUnsuccessful: updateUserResponse?.unsuccessful,
          responseMessage: updateUserResponse?.message,
        });
      }
    } else {
      toast.warning("Phone Number and Email fields must be in the correct format.");
    }
  }

  isFormDataValid() {
    const { data } = this.state;
    return (
      data.firstName &&
      data.lastName &&
      (data.email || Number.parseInt(data.userType) !== 1) &&
      data.phoneNumber &&
      data.currentState
    );
  }

  showCreateProfileModal = () => {
    this.setState({ showCreateProfileModal: true });
  };

  showEditProfileModal = (row) => {
    this.setState({ showEditProfileModal: true, editProfileModalData: row });
  };

  handleProfileCreation = async (profileData) => {
    const profileRole = this.state.availableRoles.find((r) => r.wsRoleUniqueIdentifier === profileData.roleId);
    const profileName = profileRole ? profileRole.roleName : profileData.roleId;
    const createProfileBody = {
      wsUserUniqueIdentifier: this.state.data.userUniqueIdentifier,
      roleUniqueReference: profileData.roleId,
      profileName: profileName,
    };

    const createProfileresponse = await RoleAuthorizationAPI.CreateUserProfile(createProfileBody);
    if (createProfileresponse?.successful && createProfileresponse.data?.userPrinciple) {
      const profileStatesAssociationResponse = await RoleAuthorizationAPI.AssociateUserProfileWithStates(
        createProfileresponse.data.userPrinciple,
        profileName,
        profileData.selectedStates.map((s) => s.id)
      );
      if (profileStatesAssociationResponse?.successful) {
        toast.success("User Profile was successfully created.");
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to associate states with profile",
          apiName: "AssociateUserProfileWithStates",
          responseUnsuccessful: profileStatesAssociationResponse?.unsuccessful,
          responseMessage: profileStatesAssociationResponse?.message,
        });
      }
      await this.updateUserData();
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create profile",
        apiName: "CreateUserProfile",
        responseUnsuccessful: createProfileresponse?.unsuccessful,
        responseMessage: createProfileresponse?.message,
      });
    }
  };

  handleProfileEdit = async (profileData) => {
    var profileBeingEdited = this.state.data.userProfiles.find(
      (p) => p.wsUserProfileUniqueIdentifier === profileData.wsUserProfileUniqueIdentifier
    );
    var associateBody = [];
    var disassociateBody = [];
    profileData.selectedStates.forEach((state) => {
      if (!profileBeingEdited.profileAssociatedStates.includes(state.id)) {
        associateBody.push(state.id);
      }
    });
    profileBeingEdited.profileAssociatedStates.forEach((state) => {
      if (!profileData.selectedStates.find((s) => s.id === state)) {
        disassociateBody.push(state);
      }
    });

    var associateSuccess = true;
    var disassociateSuccess = true;
    if (associateBody.length > 0) {
      var associateAction = await RoleAuthorizationAPI.AssociateUserProfileWithStates(
        this.state.data.email,
        profileBeingEdited.profileName,
        associateBody
      );
      if (!associateAction.successful) {
        associateSuccess = false;
      }
    }
    if (disassociateBody.length > 0) {
      var disassociateAction = await RoleAuthorizationAPI.DisassociateStatesFromUserProfile(
        this.state.data.email,
        profileBeingEdited.profileName,
        disassociateBody
      );
      if (!disassociateAction.successful) {
        disassociateSuccess = false;
      }
    }

    if (associateSuccess && disassociateSuccess) {
      toast.success("User Profile edited.");
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to edit user profile",
        apiName: "DisassociateStatesFromUserProfile",
        responseUnsuccessful: disassociateAction?.unsuccessful,
        responseMessage: disassociateAction?.message,
      });
    }
    await this.updateUserData();
  };

  async updateUserData() {
    let data = { ...this.state.data };
    let isUserActive = { ...this.state.isUserActive };
    const getUserResponse = await RoleAuthorizationAPI.GetUser(this.state.data?.userUniqueIdentifier);
    if (getUserResponse?.successful && getUserResponse.data) {
      const { data: userData } = getUserResponse;
      data.firstName = userData.firstName;
      data.lastName = userData.lastName;
      data.phoneNumber = UtilityFunctions.formatPhoneNumber(userData.primaryPhoneNumber);
      data.email = userData.userPrinciple;
      data.address = userData.streetAddress;
      data.city = userData.city;
      data.currentState = userData.stateOfAddressUniqueReference;
      data.zipCode = userData.zip;
      data.userType = userData.userType?.id;
      data.userUniqueIdentifier = userData.wsUserUniqueIdentifier;
      data.userName = userData.userPrinciple;
      data.userProfiles =
        userData.userProfiles?.length > 0
          ? userData.userProfiles.map((profile) => ({ ...profile, isSelected: false }))
          : [];
      isUserActive = userData.isActive;
    }

    this.setState({ data, isUserActive });
  }

  handleProfilesFilterByState = (input) => {
    const data = { ...this.state.data };
    data.filteredState = input;
    this.setState({ data });
  };

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontSize: "14px", fontStyle: "normal !important" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      textAlign: "left",
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  renderPageHeader() {
    return (
      <div>
        <Row className="mx-0 mt-3">
          <Col className="pl-0" align="left">
            <span>
              <h1 className={globalStyles.pageTitle} style={{ display: "inline" }}>
                User Record Page
              </h1>
            </span>
          </Col>
          <Col className="pr-0">
            <div className="form-inline justify-content-end">
              {this.props.permissions.canIDeactivateUser() && this.state.isFormEditable && (
                <Button variant="primary" className="mr-2" onClick={this.handleUserStatusChange}>
                  <span className={globalStyles.modalSubmitButtonText}>
                    {this.state.isUserActive ? "Deactivate" : "Activate"}
                  </span>
                </Button>
              )}
              <Button variant="primary" onClick={this.showCreateProfileModal}>
                <span className={globalStyles.modalSubmitButtonText}>Create New Profile</span>
              </Button>
              {this.props.permissions.canIEditGlobalUserRecord() && (
                <Button variant="primary" className="ml-2" onClick={this.editUser}>
                  <span className={globalStyles.modalSubmitButtonText}>
                    {this.state.isFormEditable ? "Apply Changes" : "Edit"}
                  </span>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderPersonalInfoCard() {
    const { data, isFormEditable, isUserActive } = this.state;
    return (
      <Card>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <Row>
            <Col className="pr-0" md={8}>
              <span className={globalStyles.cardTitleText}>Personal Information</span>
            </Col>
            <Col className="form-inline justify-content-end">
              <Badge pill variant={isUserActive ? "success" : "secondary"}>
                {isUserActive ? "Active" : "Inactive"}
              </Badge>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className={styles.personalInfoCardBody}>
          <Row>
            <Col sm={6}>
              {this.renderEditableField(
                isFormEditable,
                "firstName",
                "First Name",
                data.firstName,
                "text",
                [],
                "required"
              )}
            </Col>
            <Col className="pl-0">
              {this.renderEditableField(isFormEditable, "lastName", "Last Name", data.lastName, "text", [], "required")}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              {this.renderEditableField(
                isFormEditable,
                "phoneNumber",
                "Phone Number",
                UtilityFunctions.formatPhoneNumber(data.phoneNumber),
                "text",
                "",
                "required"
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderEditableField(
                false,
                "email",
                "Email",
                data.email,
                "text",
                [],
                Number.parseInt(data.userType) === 1 ? "required" : "readOnly"
              )}
            </Col>
          </Row>
          <Row>
            <Col>{this.renderEditableField(isFormEditable, "address", "Address", data.address)}</Col>
          </Row>
          <Row>
            <Col sm={6}>{this.renderEditableField(isFormEditable, "city", "City", data.city)}</Col>
            <Col className="pl-0">
              {this.renderEditableField(
                isFormEditable,
                "currentState",
                "State",
                data.currentState,
                "select",
                this.state.states,
                "required"
              )}
            </Col>
            <Col className="pl-0">{this.renderEditableField(isFormEditable, "zipCode", "Zip", data.zipCode)}</Col>
          </Row>
          <Row>
            <Col>
              {this.renderEditableField(
                isFormEditable,
                "userType",
                "User Type",
                data.userType,
                "select",
                this.state.userTypes
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderUserRolesCard() {
    const { data } = this.state;
    let profileStates = [];
    for (let profile of data.userProfiles) {
      profile.profileAssociatedStates.forEach((profileStateId) => {
        const currentState = this.state.states.find((s) => s.id === profileStateId);
        if (currentState && !profileStates.some((state) => state.value === currentState.id)) {
          profileStates.push({ value: currentState.id, label: currentState.state_code });
        }
      });
    }
    const expandRow = {
      parentClassName: "selectParentRow",
      renderer: (row) => {
        return row.specialPermissionsForProfile.length > 0 ? (
          row.specialPermissionsForProfile.map((permission) => (
            <Row key={row?.profileName + permission?.specialPermission?.name} className={styles.profilePermissionRow}>
              <Col align="left" md={4}>
                {permission?.specialPermission?.name ? permission.specialPermission.name : ""}
              </Col>
              <Col align="left">
                {permission?.specialPermissionAssociatedStates?.length > 0
                  ? permission.specialPermissionAssociatedStates.map((state, index) => {
                      if (index === permission?.specialPermissionAssociatedStates?.length - 1) {
                        return this.state.states.find((s) => s.id === state)?.state_code;
                      } else {
                        return this.state.states.find((s) => s.id === state)?.state_code + ", ";
                      }
                    })
                  : ""}
              </Col>
            </Row>
          ))
        ) : (
          <div className={styles.noPermissionsDiv}>No Special Permissions</div>
        );
      },
      showExpandColumn: true,
      expandByColumnOnly: true,
      expanded: this.state.propertyMethodInventoryRowExpanded,
      onExpand: this.handlePropertyMethodInventoryRowOnExpand,
      nonExpandable: this.state.propertyMethodInventoryRowNotExpandable,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b></b>;
        }
        return <b></b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <img className={styles.chevron} src={ChevronDown} alt="down arrow" />;
        }
        return <img className={styles.chevron} src={ChevronRight} alt="right arrow" />;
      },
    };
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header as="h4" className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>User Profiles</span>
        </Card.Header>
        <Card.Body style={{ overflow: "auto" }}>
          <Row className="align-items-center mb-1">
            <Col>
              <Form.Group className="mb-0 text-left">
                <Form.Check
                  type="checkbox"
                  label="Select All"
                  checked={this.state.areAllProfilesSelected}
                  onChange={this.selectAllProfiles}
                />
              </Form.Group>
            </Col>
            <Col md={{ span: 2, offset: 3 }}>
              <Select
                value={data.filteredState}
                placeholder="Filter By State"
                styles={this.customSelectStyles}
                options={profileStates}
                onChange={this.handleProfilesFilterByState}
              />
            </Col>
          </Row>
          <BootstrapTable
            keyField="wsUserProfileUniqueIdentifier"
            data={
              data.filteredState
                ? data.userProfiles.filter((profile) =>
                    profile.profileAssociatedStates?.includes(data.filteredState.value)
                  )
                : data.userProfiles
            }
            wrapperClasses="user-record-profile-table"
            columns={this.getUserProfilesColumns()}
            filter={filterFactory()}
            expandRow={expandRow}
            bootstrap4={true}
            bordered={false}
            defaultSorted
            defaultSortDirection="asc"
          />
        </Card.Body>
      </Card>
    );
  }

  render() {
    return (
      <div className={styles.userPage}>
        {this.renderPageHeader()}
        <Row className="mt-3">
          <Col md={4}>{this.renderPersonalInfoCard()}</Col>
          <Col>{this.renderUserRolesCard()}</Col>
        </Row>
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfulUpdateModal}
          onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
          routeData=""
          messageType="update"
          objectName="User Record"
        />
        {this.state.showCreateProfileModal && (
          <CreateProfileModal
            show={this.state.showCreateProfileModal}
            onHide={() => this.setState({ showCreateProfileModal: false })}
            availableStates={this.state.states}
            availableRoles={this.state.availableRoles}
            onSave={this.handleProfileCreation}
          />
        )}
        {this.state.showEditProfileModal && (
          <CreateProfileModal
            show={this.state.showEditProfileModal}
            onHide={() => this.setState({ showEditProfileModal: false })}
            availableStates={this.state.states}
            availableRoles={this.state.availableRoles}
            onSave={this.handleProfileEdit}
            editData={this.state.editProfileModalData}
          />
        )}
      </div>
    );
  }
}

export default withRouter(UserRecord);
