import React, { Component } from "react";
import { withRouter } from "react-router";
import styles from "../common/common.scss";
import { Button, Modal, Row, Col } from "react-bootstrap";

class SuccessfulRecordModifiedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.okButton = React.createRef();
  }

  componentDidMount() {
    if (this.okButton.current) {
      this.okButton.current.focus();
    }
  }

  handleOk = () => {
    this.props.onHide();
    const { onAcknowledgeRoute, routeData } = this.props;

    if (onAcknowledgeRoute) {
      if (routeData) {
        this.props.history.push({
          pathname: onAcknowledgeRoute,
          state: this.props.routeData,
        });
      } else {
        this.props.history.push(onAcknowledgeRoute);
      }
    }
  };

  render() {
    let status = "created.";
    if (this.props.messageType === "update") {
      status = "updated.";
    } else if (this.props.messageType === "save") {
      status = "saved.";
    }
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered size="sm">
        <div className="d-flex justify-content-center mt-2">
          <i className="fa fa-check-circle fa-5x" style={{ color: "gray" }} aria-hidden="true"></i>
        </div>
        <Modal.Header>
          <div className="container ">
            <Row className="justify-content-center">
              <Col sm="auto">
                <h3>Success!</h3>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <p className="modalCenteredData">
                Your {this.props.objectName ? this.props.objectName : "record"} has been {status}
              </p>
            </Row>
            <Row className="justify-content-center">
              <Button ref={this.okButton} variant="primary" onClick={this.handleOk} title="OK">
                OK
              </Button>
            </Row>
          </div>
        </Modal.Header>
      </Modal>
    );
  }
}

export default withRouter(SuccessfulRecordModifiedModal);
