import React from "react";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import CustomForm from "../../../common/form";
import WebMap from "../../../ESRIIntegration/EsriMap";
import { toast } from "react-toastify";
import UtilityFunctions from "../../../common/UtilityFunctions";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { components } from "react-select";
import searchIcon from "../../../../assets/search.svg";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let option = props.data;
  return (
    <components.Option {...props} className={styles.informationRow}>
      <Row align="left">
        <Col lg={2}>
          <p style={{ color: "#0071bc" }}>{option.permitName}</p>
        </Col>
        <Col align="left">
          <p>
            {"  (" +
              new Date(option.permitStartDate).toDateString() +
              " - " +
              new Date(option.permitExpirationDate).toDateString() +
              ")"}
          </p>
        </Col>
      </Row>
      <Row>
        <Col align="left">
          <p>{option.permitNumber}</p>
        </Col>
        <Col align="left">
          <p>{option.permitIssuedBy?.issuingAgencyName ? option.permitIssuedBy.issuingAgencyName : "No Agency Name"}</p>
        </Col>
        <Col align="left">
          <p>
            {option.speciesOfPermit.length > 0
              ? option.speciesOfPermit.map((species, index) => {
                  return index === option.speciesOfPermit.length - 1 ? species.speciesName : species.speciesName + ", ";
                })
              : "No Damage Agent"}
          </p>
        </Col>
      </Row>
      <Row>
        <Col align="left">
          <p>{"Take Cap: " + option.totalTakeCap}</p>
        </Col>
        <Col align="left">
          <p>{"Current Take: " + (option.totalTakeCap - option.remainingBalance).toString()}</p>
        </Col>
        <Col align="left">
          <p>{"Balance: " + option.remainingBalance}</p>
        </Col>
      </Row>
    </components.Option>
  );
};

class EnterTakeModal extends CustomForm {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        intentionalSpecies: "",
        unintentionalSpecies: "",
        quantity: "",
        selectedUom: "",
        actualCount: "",
        selectedFate: "",
        selectedIntermediateFates: [],
        isUnintentionalTake: false,
        selectByPermit: "",
        latitude: "",
        longitude: "",
        conflictId: "",
        workPerformedId: "",
        methodUniqueId: "",
        bands: [],
        isOtherSampling: false,
        diseaseSamplingQty: "",
        rabiesSamplingQty: "",
        totalAnimalsSampled: "",
        date: this.props.pvsDate ? this.props.pvsDate.split("-")[0].trim() : this.props.workTaskDate,
        takeUniqueIdentifier: "",
        isExistingTake: true,
        selectedTake: "",
      },
      isInitialConfiguration: true,
      currentUsaState: "",
      availableFates: [],
      availableIntermediateFates: [],
      availableUOMs: [],
      availableCountTypes: [],
      availableTakes: [],
      bandingTypes: [],
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    this.validateStateData(currentUsaState);
    this.setState({ currentUsaState });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    const { workItem } = this.props.data;

    const propsShowModalChange = prevProps.show !== this.props.show && this.props.show;
    if (propsShowModalChange) {
      if (this.props.data?.isEditForm) {
        const { workItem: takeData } = this.props.data;
        data.takeUniqueIdentifier = workItem.takeUniqueIdentifier;
        data.isUnintentionalTake = !takeData.wasIntentional;
        if (takeData.wasIntentional) {
          data.intentionalSpecies = takeData.speciesUniqueReference;
        } else if (takeData.speciesUniqueReference) {
          const speciesName = await this.getSpeciesName(takeData.speciesUniqueReference);
          data.unintentionalSpecies = { value: takeData.speciesUniqueReference, label: speciesName };
        }
        data.quantity = takeData.quantity;
        data.actualCount = takeData.countType?.id;
        data.selectedUom = takeData.unitOfMeasure?.id;
        data.selectedFate = takeData.fateUniqueReference;
        data.selectedIntermediateFates =
          takeData.intermediateFates?.length > 0
            ? takeData.intermediateFates.map((fate) => ({
                value: fate.intermediateFateUniqueReference,
                label: fate.fateName,
              }))
            : [];
        data.latitude = takeData.latitude;
        data.longitude = takeData.longitude;
        data.conflictId = takeData.conflictUniqueReference;
        data.workPerformedId = takeData.workPerformedUniqueReference;
        data.aerialWorkPerformedId = takeData.aerialWorkPerformedUniqueReference;
        data.methodUniqueId = takeData.methodUniqueReference;
        data.date = takeData.createdDateTimeUTC ? new Date(takeData.createdDateTimeUTC).toLocaleDateString() : null;
        data.totalAnimalsSampled = takeData.numberOfAnimalsSampled;
        data.diseaseSamplingQty = takeData.numberOfDiseaseMonitoringTaken;
        data.rabiesSamplingQty = takeData.numberOfNRMPTaken;
        data.isOtherSampling = takeData.isOther;
        data.selectByPermit = { value: takeData.permitUniqueReference, label: takeData.permitName };
        data.bands = takeData.bands?.map((band) => ({
          type: {
            value: band.bandingType?.id,
            label: UtilityFunctions.getDisplayTextFromFieldObject(band.bandingType),
          },
          referenceNumber: band.referenceNumber,
        }));
      }

      this.setState({
        availableFates: await this.getAvailableFates(),
        availableIntermediateFates: await this.getAvailableIntermediateFates(),
        availableUOMs: await this.getAvailableUOMs(),
        availableCountTypes: await this.getAvailableCountTypes(),
        availableTakes: this.getAvailableTakes(),
        bandingTypes: await this.getBandingTypes(),
      });
    }

    let propsDateChange = false;
    if (this.props.pvsDate) {
      propsDateChange = prevProps.pvsDate !== this.props.pvsDate;
    } else if (this.props.workTaskDate) {
      propsDateChange = prevProps.workTaskDate !== this.props.workTaskDate;
    }

    if (propsDateChange) {
      data.date = this.props.pvsDate ? this.props.pvsDate.split("-")[0].trim() : this.props.workTaskDate;
      this.setState({ data });
    }

    const propsLatChange = prevProps.data.workItem?.inventoryItemLatitude !== workItem.inventoryItemLatitude;
    if (propsLatChange) {
      data.latitude = workItem.inventoryItemLatitude;
      this.setState({ data });
    }
    const propsLongChange = prevProps.data.workItem?.inventoryItemLongitude !== workItem.inventoryItemLongitude;
    if (propsLongChange) {
      data.longitude = workItem.inventoryItemLongitude;
      this.setState({ data });
    }
  }

  async getAvailableFates() {
    let availableFates = [];
    const fatesResponse = await ReferenceFileAPI.GetAllFates();
    if (fatesResponse?.successful && fatesResponse.data?.results) {
      availableFates = fatesResponse.data.results.map((result) => ({
        id: result.fateUniqueIdentifier,
        name: result.name,
      }));
    }

    return availableFates;
  }

  async getAvailableIntermediateFates() {
    let availableIntermediateFates = [];
    if (this.state.currentUsaState?.stateGuid) {
      const intermediateFatesResponse = await ReferenceFileAPI.GetIntermediateFatesForState(
        this.state.currentUsaState.stateGuid
      );
      if (!intermediateFatesResponse?.successful) {
        ErrorHandler.showErrorWithMessage(
          intermediateFatesResponse?.message
            ? intermediateFatesResponse.message
            : "Intermediate Fates Data could not me retrieved."
        );
      } else if (intermediateFatesResponse.data?.length > 0) {
        availableIntermediateFates = intermediateFatesResponse.data.map((f) => ({
          value: f.intermediateFateUniqueIdentifier,
          label: f.name,
        }));
      }
    }

    return availableIntermediateFates;
  }

  async getAvailableUOMs() {
    let availableUOMs = [];
    const uomResponse = await WorkTaskAPI.GetAllTakeUnitsOfMeasure();
    if (uomResponse?.successful && uomResponse.data?.length > 0) {
      availableUOMs = uomResponse.data.map((uom) => ({
        id: uom.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(uom),
      }));
    }

    return availableUOMs;
  }

  async getAvailableCountTypes() {
    let availableCountTypess = [];
    const countTypesResponse = await WorkTaskAPI.GetAllCountTakeTypes();
    if (countTypesResponse?.successful && countTypesResponse.data?.length > 0) {
      availableCountTypess = countTypesResponse.data.map((countType) => ({
        id: countType.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(countType),
      }));
    }

    return availableCountTypess;
  }

  async getBandingTypes() {
    let availableBandingTypes = [];
    const bandingTypesResponse = await WorkTaskAPI.GetAllBandingTypes();
    if (bandingTypesResponse?.successful && bandingTypesResponse.data?.length > 0) {
      availableBandingTypes = bandingTypesResponse.data.map((type) => ({
        value: type.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(type),
      }));
    }

    return availableBandingTypes;
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  handleUomSelection = (input) => {
    const { data } = this.state;
    data.selectedUom = input;
    this.setState({ data });
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
    this.setState({ isInitialConfiguration: true });
  };

  isFormDataValid() {
    const {
      intentionalSpecies,
      unintentionalSpecies,
      quantity,
      selectedUom,
      actualCount,
      selectedFate,
      isUnintentionalTake,
      bands,
    } = this.state.data;
    return (
      (isUnintentionalTake ? unintentionalSpecies : intentionalSpecies) &&
      Number.parseInt(quantity) > 0 &&
      selectedUom &&
      actualCount &&
      selectedFate &&
      (!(bands?.length > 0) || !bands.some((b) => !b.type))
    );
  }

  doSubmit = () => {
    const { data } = this.state;
    let isValidate = true;
    if (data.isExistingTake) {
      this.props.onAttachTake({ ...data });
      this.clearData();
      this.props.onHide();
      this.setState({ isInitialConfiguration: true });
    } else {
      if (!this.isFormDataValid()) {
        toast.warning("Please fill out all the required fields before submitting the form.");
        isValidate = false;
      } else if (this.props.pvsDate) {
        if (
          Number.parseInt(data.rabiesSamplingQty) === 0 &&
          Number.parseInt(data.diseaseSamplingQty) === 0 &&
          !data.isOtherSampling
        ) {
          toast.warning("Select Other or Enter quantity for NRMP or Disease Sampling");
          isValidate = false;
        } else if (
          data.diseaseSamplingQty > data.totalAnimalsSampled ||
          data.rabiesSamplingQty > data.totalAnimalsSampled
        ) {
          toast.warning("Sample Quantity must be less than or equal to Total Animals Sampled");
          isValidate = false;
        } else if (data.isOtherSampling && Number.parseInt(data.totalAnimalsSampled) === 0) {
          toast.warning("Total Animals Sampled must be greater than zero");
          isValidate = false;
        }
      }

      const { workItem } = this.props.data;
      if (
        this.props.data?.isEditForm &&
        (Number.parseInt(data.totalAnimalsSampled) < workItem.numberOfAnimalsSampled ||
          Number.parseInt(data.diseaseSamplingQty) < workItem.numberOfDiseaseMonitoringTaken ||
          Number.parseInt(data.rabiesSamplingQty) < workItem.numberOfNRMPTaken)
      ) {
        toast.warning("Modified samples quantities cannot be smaller than existing ones.");
        isValidate = false;
      }

      if (isValidate) {
        data.conflictId = this.getConflictId();
        const { workPerformedId, methodUniqueId } = this.getWorkPerformedData();
        data.workPerformedId = workPerformedId;
        data.methodUniqueId = methodUniqueId;
        if (this.state.data.isUnintentionalTake) {
          data.conflictId = null;
        }
        if (this.props.data?.isEditForm) {
          this.props.onEditTake({ ...data });
        } else {
          this.props.onAddTake({ ...data });
        }
        this.clearData();
        this.props.onHide();
        this.setState({ isInitialConfiguration: true });
      }
    }
  };

  getConflictId() {
    let conflictId = "";
    if (!this.state.data.isUnintentionalTake && this.props.data?.propertyConflicts?.length > 0) {
      const conflictData = this.props.data.propertyConflicts.find(
        (conflict) => conflict.targetedSpeciesUniqueReference === this.state.data.intentionalSpecies
      );
      conflictId = conflictData?.conflictUniqueIdentifier;
    }
    return conflictId;
  }

  getWorkPerformedData() {
    const { workItem } = this.props.data;
    const workPerformedId = workItem.workPerformedUniqueIdentifier
      ? workItem.workPerformedUniqueIdentifier
      : workItem.workPerformedUniqueReference
      ? workItem.workPerformedUniqueReference
      : workItem.aerialWorkPerformedUniqueIdentifier
      ? workItem.aerialWorkPerformedUniqueIdentifier
      : "";
    const methodUniqueId = workItem.methodUniqueReference ? workItem.methodUniqueReference : "";
    return { workPerformedId, methodUniqueId };
  }

  clearData() {
    const { data } = this.state;
    data.intentionalSpecies = "";
    data.unintentionalSpecies = "";
    data.quantity = "";
    data.selectedUom = "";
    data.selectedTake = "";
    data.actualCount = "";
    data.selectedFate = "";
    data.selectedIntermediateFates = [];
    data.isUnintentionalTake = false;
    data.selectByPermit = "";
    data.latitude = "";
    data.longitude = "";
    data.conflictId = "";
    data.workPerformedId = "";
    data.methodUniqueId = "";
    data.isOtherSampling = false;
    data.rabiesSamplingQty = "";
    data.diseaseSamplingQty = "";
    data.bands = [];

    this.setState({ data });
  }

  handleUnintentionalSelection = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isUnintentionalTake = input.checked;
    this.setState({ data });
  };

  handleExistingTakeSelection = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isExistingTake = input.checked;
    this.setState({ data });
  };

  async handleSpecificChanges(input) {
    if (input.name === "intentionalSpecies") {
      const { data } = this.state;
      data.intentionalSpecies = input.value;
      this.setState({ data });
    }
  }

  async getSpeciesName(id) {
    const res = await ReferenceFileAPI.GetSpecies(id);
    if (res?.data) {
      return res.data.name;
    }
  }

  getAvailableSpecies() {
    const { propertyConflicts, workItem } = this.props.data;
    let availableSpecies = [];

    if (workItem?.workPerformedSpecies?.length > 0) {
      workItem.workPerformedSpecies.forEach((s) => {
        if (!availableSpecies.find((species) => species.id === s.targetedSpeciesUniqueReference)) {
          availableSpecies.push({
            id: s.targetedSpeciesUniqueReference,
            name: s.speciesName,
          });
        }
      });
    } else if (propertyConflicts?.length > 0) {
      propertyConflicts.forEach((conflict) => {
        if (!availableSpecies.find((species) => species.id === conflict.targetedSpeciesUniqueReference)) {
          availableSpecies.push({
            id: conflict.targetedSpeciesUniqueReference,
            name: conflict.targetedSpeciesName,
          });
        }
      });
    }
    return availableSpecies;
  }

  loadAvailableSpecies = async (inputValue) => {
    return this.getSpeciesForState(inputValue);
  };

  addBandEntry = () => {
    const { data } = this.state;
    data.bands.push({ type: "", referenceNumber: "" });
    this.setState({ data });
  };

  handleSpeciesSelection = (input) => {
    const { data } = this.state;
    data.unintentionalSpecies = input;
    this.setState({ data });
  };

  handleTakesSelection = (input) => {
    const { data } = this.state;

    data.selectedTake = input;

    this.setState({ data });
  };

  async getSpeciesForState(searchValue) {
    const { currentUsaState } = this.state;
    let searchResults = "";
    if (currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(
        currentUsaState.stateGuid,
        UtilityFunctions.getFilterReferenceFilesCallBody(searchValue)
      );

      if (speciesData?.data?.results) {
        let results = speciesData.data.results;
        searchResults = results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return searchResults;
  }

  handlePermitSearch = async (inputValue) => {
    const { data } = this.state;
    let searchObj = {
      stateUniqueReference: this.state.currentUsaState ? this.state.currentUsaState.stateGuid : null,
      permitNameNumberSearchFreetext: inputValue ? inputValue : "",
      speciesUniqueReference: data.isUnintentionalTake
        ? data.unintentionalSpecies?.value
        : data.intentionalSpecies
        ? data.intentionalSpecies
        : null,
    };
    const permitsData = await WorkTaskAPI.SearchForAnyPermits(searchObj);
    if (permitsData?.successful) {
      let returnObj = permitsData.data;
      return returnObj.map((item) => {
        item.value = item.permitUniqueIdentifier;
        item.label = item.permitName;
        return item;
      });
    }
  };

  handlePermitSelection = (e) => {
    const { data } = this.state;
    data.selectByPermit = e;
    this.setState({ data });
  };

  renderSpeciesSelectionDropdown() {
    const availableSpecies = this.getAvailableSpecies();

    return this.state.data.isUnintentionalTake > 0 ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>
          Damage Agent<span className={globalStyles.asterisk508}> *</span>
        </Form.Label>
        <AsyncSelect
          aria-label="Select Species Bar"
          value={this.state.data.unintentionalSpecies}
          openMenuOnClick={false}
          placeholder="Select Damage Agent"
          components={{ DropdownIndicator }}
          styles={{
            indicatorSeparator: () => {
              //do nothing
            },
            placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
            option: (styles) => ({ ...styles, fontSize: "14px", fontWeight: "normal", color: "black" }),
            singleValue: (styles) => ({
              ...styles,
              fontSize: "14px",
              fontWeight: "normal",
              color: "black",
            }),
            menuList: (styles) => ({ ...styles, textAlign: "left" }),
          }}
          loadOptions={this.loadAvailableSpecies}
          onChange={this.handleSpeciesSelection}
        />
      </Form.Group>
    ) : (
      this.renderSelect("intentionalSpecies", "Damage Agent", availableSpecies, "Select Damage Agent", "required")
    );
  }

  getAvailableTakes() {
    const takesData = this.props.availableTakes;
    const selectedMethod = this.props.data.workItem.methodUniqueReference;
    const availableTakes = takesData
      ?.filter((w) => w.methodUniqueReference !== selectedMethod)
      ?.map((t) => ({
        value: t.takeUniqueIdentifier,
        label: "Take:  " + t.takeNumberForSiteVisit + ",  " + t.speciesName + ", " + t.quantity,
      }));

    return availableTakes ?? [];
  }

  handleOtherSelection = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isOtherSampling = input.checked;
    this.setState({ data });
  };

  handleSelectChanges = (e, fieldName) => {
    const { data } = this.state;
    data[fieldName] = e;
    this.setState({ data });
  };

  handleBandTypeSelect = (e, index) => {
    const { data } = this.state;
    data.bands[index].type = e;
    this.setState({ data });
  };

  handleBandRefNumberChange = ({ currentTarget: input }, index) => {
    const { data } = this.state;
    data.bands[index].referenceNumber = input.value;
    this.setState({ data });
  };

  handleDeleteBand = (index) => {
    const { data } = this.state;
    data.bands.splice(index, 1);
    this.setState({ data });
  };

  selectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "black",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "black",
    }),
    menuList: (styles) => ({ ...styles, textAlign: "left" }),
    control: (base) => ({
      ...base,
      minHeight: "35px",
    }),
    container: (base) => ({
      ...base,
      minHeight: "35px",
    }),
  };

  render() {
    const { workItem, propertyData } = this.props.data;
    const { availableUOMs, availableCountTypes, data, availableTakes } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.modalDialog}
      >
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>{`${
            this.props.data.isEditForm ? "Edit" : "Enter/Attach"
          } Take`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row className="mt-3" hidden={this.props.data.isEditForm}>
              <Col>
                <Form.Group controlId="existingTakeCheckbox">
                  <Form.Check
                    name="isExistingTake"
                    label="Select Existing Take to Attach"
                    onChange={this.handleExistingTakeSelection}
                    checked={data.isExistingTake}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row hidden={!data.isExistingTake || this.props.data.isEditForm}>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Select Take based on Take Name, Species, Quantity
                    <span className={globalStyles.asterisk508}> *</span>
                  </Form.Label>
                  <Select
                    aria-label="existingTake"
                    name="selectedTake"
                    id="selectedTake"
                    placeholder="Select Take"
                    value={data.selectedTake}
                    styles={this.selectStyles}
                    options={availableTakes}
                    onChange={(e) => this.handleTakesSelection(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <br></br>
              </Col>
            </Row>
          </div>
          <div className="container" hidden={data.isExistingTake && !this.props.data.isEditForm}>
            <Row hidden={this.props.data.isEditForm}>
              <Col>Enter Take</Col>
            </Row>
            <Row hidden={this.props.data.isEditForm}>
              <Col>
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col>{this.renderInput("date", "Date of Take", "text", "Enter", "readOnly")}</Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className={globalStyles.formLabel}>Method Name</Form.Label>
                  <Form.Control
                    className={globalStyles.formData}
                    type="text"
                    defaultValue={workItem?.methodName}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>{this.renderSpeciesSelectionDropdown()}</Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="unintentionalTakeCheckbox">
                  <Form.Check
                    name="isUnintentionalTake"
                    label="Unintentional"
                    onChange={this.handleUnintentionalSelection}
                    checked={data.isUnintentionalTake}
                  />
                </Form.Group>
              </Col>
            </Row>
            {data.bands?.length > 0 && (
              <Row className="mx-0 my-2">
                <Col className={styles.dashedBorderSeparatorRow}></Col>
              </Row>
            )}
            {data.bands.map((band, index) => (
              <Row key={index}>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      Band Type<span className={globalStyles.asterisk508}> *</span>
                    </Form.Label>
                    <Select
                      aria-label="Band Type"
                      name="bandType"
                      id={`bandType-${index}`}
                      placeholder="Select Type"
                      value={band.type}
                      isClearable
                      styles={this.selectStyles}
                      options={this.state.bandingTypes}
                      onChange={(e) => this.handleBandTypeSelect(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Identification No.</Form.Label>
                    <Form.Control
                      aria-label="Reference Number"
                      name="referenceNumber"
                      id={`referenceNumber-${index}`}
                      placeholder="Enter"
                      className={globalStyles.formData}
                      value={band.referenceNumber}
                      onChange={(e) => this.handleBandRefNumberChange(e, index)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1} className="pt-3">
                  <i
                    className="fa fa-times-circle-o pt-4"
                    aria-hidden="true"
                    onClick={() => this.handleDeleteBand(index)}
                  ></i>
                </Col>
              </Row>
            ))}
            <Row className="pb-5">
              <Col className="pl-0">
                <Button variant="link" className={globalStyles.formDataLinks} onClick={this.addBandEntry}>
                  + Add Tag/Band/Transmitter
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={3}>{this.renderInput("quantity", "Quantity", "number", "Qty", "required")}</Col>

              <Col sm={3}>{this.renderSelect("selectedUom", "UOM", availableUOMs, "Select", "required")}</Col>
              <Col sm={6}>
                {this.renderSelect("actualCount", "Actual Count", availableCountTypes, "Select", "required")}
              </Col>
            </Row>
            <Row>
              <Col>
                {this.renderSelect("selectedFate", "Fate", this.state.availableFates, "Select Fate", "required")}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="text-left">
                  <Form.Label className={globalStyles.formLabel}>Intermediate Fates</Form.Label>
                  <Select
                    aria-label="Intermediate Fates"
                    name="selectedIntermediateFates"
                    id="selectedIntermediateFates"
                    placeholder="Select Fates"
                    value={data.selectedIntermediateFates}
                    isClearable
                    isMulti
                    styles={this.selectStyles}
                    options={this.state.availableIntermediateFates}
                    onChange={(e) => this.handleSelectChanges(e, "selectedIntermediateFates")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Permit</Form.Label>
                  <AsyncSelect
                    value={data.selectByPermit}
                    openMenuOnClick={false}
                    aria-label="Permit Search"
                    placeholder="Search"
                    components={{ DropdownIndicator, Option }}
                    styles={this.selectStyles}
                    loadOptions={this.handlePermitSearch}
                    onChange={(e) => this.handlePermitSelection(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>{this.renderInput("latitude", "Latitude", "number", "Enter here")}</Col>
              <Col>{this.renderInput("longitude", "Longitude", "number", "Enter here")}</Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <div className={styles.mapContainer}>
                  <WebMap
                    address={
                      propertyData?.additionalStreetAddresses?.length > 0
                        ? UtilityFunctions.getPropertyPrimaryAddress(propertyData.additionalStreetAddresses[0])
                        : ""
                    }
                    name={propertyData?.propertyName}
                    location={propertyData?.mapPoint}
                  />
                </div>
              </Col>
            </Row>
            {this.props.pvsDate && (
              <div>
                <Row>
                  <hr></hr>
                </Row>
                <Row className={globalStyles.modalTitleText}>Enter Sample</Row>
                <Row>
                  <br></br>
                </Row>
                <Row>
                  <Col>
                    {this.renderInput("totalAnimalsSampled", "Number of Animals Sampled", "text", "Enter Quantity")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Other"
                      onChange={this.handleOtherSelection}
                      checked={data.isOtherSampling}
                    />
                  </Col>
                </Row>
                <Row>
                  <br></br>
                </Row>
                <Row>
                  <Col>{this.renderInput("rabiesSamplingQty", "NRMP", "text", "Enter Quantity")}</Col>
                  <Col>{this.renderInput("diseaseSamplingQty", "WS Disease Monitoring", "text", "Enter Quantity")}</Col>
                </Row>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button className="ml-2" variant="outline-primary" onClick={() => this.clearData()}>
            Clear
          </Button>
          <Button className="ml-2" variant="primary" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>Submit</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EnterTakeModal;
