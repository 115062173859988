import React from "react";
import styles from "./ReferenceFilesEditPages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Card, Button, Row, Col, Form, Dropdown } from "react-bootstrap";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class EditSpecies extends React.Component {
  constructor(props) {
    super(props);
    var speciesData = this.props.location.state?.rowData;
    var usaStateData = this.props.location.state?.usaStateData;
    if (speciesData) {
      this.state = {
        types: [],
        subTypes: [],
        currentTypeIndex: 0,
        currentSubTypeIndex: 0,
        class: speciesData.class,
        type: speciesData.type,
        order: speciesData.order,
        family: speciesData.family,
        subType: speciesData.subType,
        name: speciesData.name,
        inverted_name: speciesData.inverted_name,
        scientific_name: speciesData.scientific_name,
        endangeredStatusId: 3, //TODO: 12/9/2020 Backend has not implemented this functionality yet -- speciesData?.endangeredStatus?.id,
        taxonomy_number: speciesData.taxonomy_number,
        code4: speciesData.code4,
        code6: speciesData.code6,
        t_e: speciesData.t_e,
        country: speciesData.country,
        base: speciesData.base,
        da_id: speciesData.da_id,
        da_name: speciesData.da_name,
        createdDateTimeUTC: speciesData.createdDateTimeUTC,
        lastModifiedDateTimeUTC: speciesData.lastModifiedDateTimeUTC,
        createdBy: speciesData.createdBy,
        lastModifiedBy: speciesData.lastModifiedBy,
        speciesUniqueIdentifier: speciesData.speciesUniqueIdentifier,
        isAllowed: true,
        stateCode: null,
        stateName: usaStateData.stateName,
        stateUniqueIdentifier: usaStateData.stateGuid,
        isAllowedGlobally: speciesData.isAllowedGlobally,
        validated: false,
        description: speciesData.comments,
        allowedUOMsList: [],
        allowedUOMsData: [],
        removedUOMsData: [],
        globalAllowedUOMs: [],
        errors: {},
      };
    } else {
      this.props.history.push("/referencefiles");
      this.state = { types: [], subTypes: [] };
    }
  }

  handleFieldChange = (e) => {
    let state = this.state;
    let value = e.target.value;
    if (e.target.name === "code4" || e.target.name === "code6") {
      value = value.replace(/[^A-Za-z]/gi, "");
    }
    state[e.target.name] = value;
    this.setState({ state });
  };

  handleSpeciesNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleCommonNameChange = (e) => {
    //this.setState({: e.target.value});
  };

  handleInvertedNameChange = (e) => {
    this.setState({ inverted_name: e.target.value });
  };

  handleScientificNameChange = (e) => {
    this.setState({ scientific_name: e.target.value });
  };

  handleEndangeredStatusChange = (e) => {
    this.setState({ endangeredStatusId: e.target.value });
  };

  handleSpeciesTypeChange = (e) => {
    if (e.target.value) {
      if (e.target.value !== "") {
        var type = e.target.value;
        ReferenceFileAPI.GetSpeciesSubTypes(type).then((result) => {
          if (result) {
            this.setState({ subTypes: result.data, type: type, subType: result.data[0] });
          }
        });
      }
    }
  };

  handleSpeciesSubTypeChange = (e) => {
    this.setState({ subType: e.target.value });
  };

  handleSpeciesClassChange = (e) => {
    this.setState({ class: e.target.value });
  };

  handleSpeciesFamilyChange = (e) => {
    this.setState({ family: e.target.value });
  };

  handleSpeciesOrderChange = (e) => {
    this.setState({ order: e.target.value });
  };

  handleGuildGroupChange = (e) => {
    //this.setState({: e.target.value});
  };

  handleTaxonomyNumberChange = (e) => {
    const { errors } = this.state;
    if (!/^\d{0,10}$/.test(e.target.value)) {
      errors.taxonomy_number = "Only numbers should be included up to a maximun of 10 digits.";
    } else {
      delete errors.taxonomy_number;
    }
    this.setState({ taxonomy_number: e.target.value, errors });
  };

  handleCountryChange = (e) => {
    this.setState({ country: e.target.value });
  };

  handleBaseChange = (e) => {
    this.setState({ base: e.target.value });
  };

  handleCode4Change = (e) => {
    let newCode4 = e.target.value.replace(/[^A-Za-z]/gi, "");
    this.setState({ code4: newCode4 });
  };

  handleCode6Change = (e) => {
    let newCode6 = e.target.value.replace(/[^A-Za-z]/gi, "");
    this.setState({ code6: newCode6 });
  };

  getEndangeredStatusName(id) {
    var statusId = parseInt(id);
    if (statusId === 1) {
      return "Endangered";
    } else if (statusId === 2) {
      return "Threatened";
    } else if (statusId === 3) {
      return "Neither";
    }
  }

  async componentDidMount() {
    let state = this.state;

    var speciesTypes = await ReferenceFileAPI.GetSpeciesTypes();
    if (this.state.type) {
      const speciesSubTypes = await ReferenceFileAPI.GetAllSpeciesCategorizationInfo(this.state.stateUniqueIdentifier);
      if (speciesSubTypes?.successful) {
        state.subTypes = speciesSubTypes.data?.allSubTypes;
      }

      var stateCode = await ReferenceFileAPI.GetStateCodeByName(this.state.stateName);
      this.setState({
        currentTypeIndex: speciesTypes.data.indexOf(this.state.type),
        currentSubTypeIndex: speciesSubTypes?.data?.allSubTypes?.indexOf(this.state.subType),
        types: speciesTypes.data,
        subTypes: speciesSubTypes?.data?.allSubTypes,
        stateCode: stateCode,
      });
    }
    let globalAllowedUOMs = [];
    let allowedUOMsData = [];
    const speciesDataResponse = await ReferenceFileAPI.GetSpecies(this.state.speciesUniqueIdentifier);
    if (speciesDataResponse?.data) {
      const speciesData = speciesDataResponse.data;

      if (speciesData.globalAllowedUOMs?.length > 0) {
        globalAllowedUOMs = [...speciesData.globalAllowedUOMs];
        allowedUOMsData = [...speciesData.globalAllowedUOMs];
      }
    }

    let endangeredStatuses = await ReferenceFileAPI.GetAllSpeciesEndangeredStatuses();
    if (endangeredStatuses) {
      let data = this.state;
      data.endangeredStatuses = endangeredStatuses?.data;
      this.setState({ data });
    }

    let result = await this.getSpeciesUOMs();
    this.setState({ allowedUOMsList: result, globalAllowedUOMs: globalAllowedUOMs, allowedUOMsData: allowedUOMsData });
  }

  async getSpeciesUOMs() {
    let result = [];
    let getAllSpeciesUOMsCall = await ReferenceFileAPI.GetAllSpeciesUOMs();
    if (getAllSpeciesUOMsCall?.successful) {
      result = getAllSpeciesUOMsCall.data;
    }

    return result;
  }

  activateRadio = () => {
    this.setState({ isAllowedGlobally: true });
  };

  deactivateRadio = () => {
    this.setState({ isAllowedGlobally: false });
  };

  handleUOMsCheckClick(item) {
    let { allowedUOMsData, removedUOMsData } = this.state;
    if (document.getElementById(item.name + item.id).checked) {
      allowedUOMsData.push(item);
    } else {
      allowedUOMsData = allowedUOMsData.filter((i) => i.id !== item.id);
      removedUOMsData.push(item);
    }

    this.setState({ allowedUOMsData, removedUOMsData });
  }

  handleSubmit = async (e) => {
    let form = e.currentTarget;
    e.preventDefault();
    const { errors } = this.state;
    if (errors.taxonomy_number) {
      toast.warning("Please address all the errors before submitting the form");
      return;
    }

    if (form.checkValidity() === false) {
      e.stopPropagation();
      toast.warning("Please fill out all required fields.");
      return;
    }

    this.setState({ validated: true });
    var createDateTime = new Date(this.state.createdDateTimeUTC);
    var lastModifiedDateTime = new Date();
    let speciesRecord = {
      createdBy: this.state.createdBy,
      createdDateTimeUTC: createDateTime,
      lastModifiedBy: this.state.lastModifiedBy,
      lastModifiedDateTimeUTC: lastModifiedDateTime,
      speciesUniqueIdentifier: this.state.speciesUniqueIdentifier,
      class: this.state.class,
      type: this.state.type,
      order: this.state.order,
      family: this.state.family,
      subType: this.state.subType,
      name: this.state.name,
      inverted_name: this.state.inverted_name,
      scientific_name: this.state.scientific_name,
      taxonomy_number:
        this.state.taxonomy_number === null || isNaN(this.state.taxonomy_number)
          ? 0
          : parseInt(this.state.taxonomy_number),
      code4: this.state.code4,
      code6: this.state.code6,
      t_e: this.state.t_e === null ? "" : this.state.t_e,
      country: this.state.country,
      base: this.state.base,
      da_id: this.state.da_id === null ? 0 : parseInt(this.state.da_id),
      da_name: this.state.da_name === null ? "" : this.state.da_name,
      endangeredStatusId: parseInt(this.state.endangeredStatusId),
      isAllowedGlobally: this.state.isAllowedGlobally,
      comments: this.state.description ? this.state.description : "",
    };

    let addResponse = await this.addAllowedUOMsToSpecies();

    let removeResponse = await this.removeAllowedUOMsFromSpecies();

    const updateSpeciesResponse = await ReferenceFileAPI.UpdateSpecies(speciesRecord);

    if (updateSpeciesResponse?.successful && addResponse && removeResponse) {
      toast.success("Damage Agent updated successfully.");
      this.props.history.push({
        pathname: "/referencefiles",
        state: {
          tab: "speciesTab",
          globalData: this.props.history.location.state.globalData,
          speciesEditResponseData: updateSpeciesResponse.data,
        },
      });
    } else {
      ErrorHandler.showError("Error updating Damage Agent: " + this.state.name);
    }
  };

  async addAllowedUOMsToSpecies() {
    let response = true;
    const allowedUOMsToAdd = this.state.allowedUOMsData.filter(
      (i) => !this.state.globalAllowedUOMs.find((globalUom) => globalUom.id === i.id)
    );
    if (allowedUOMsToAdd?.length > 0) {
      const addAllowedUOMsResponse = await ReferenceFileAPI.AddAllowedSpeciesUOMs(
        this.state.speciesUniqueIdentifier,
        allowedUOMsToAdd.map((i) => i.id)
      );
      if (!addAllowedUOMsResponse.successful) {
        response = false;
        ErrorHandler.showError("Error updating allowed UOMs for damage agents: " + this.state.name);
      }
    }

    return response;
  }

  async removeAllowedUOMsFromSpecies() {
    let response = true;
    const allowedUOMsToRemove = this.state.globalAllowedUOMs.filter(
      (globalUUom) => !this.state.allowedUOMsData.find((i) => i.id === globalUUom.id)
    );
    if (allowedUOMsToRemove?.length > 0) {
      const removeAllowedUOMsResponse = await ReferenceFileAPI.RemoveAllowedSpeciesUOMs(
        this.state.speciesUniqueIdentifier,
        allowedUOMsToRemove.map((i) => i.id)
      );
      if (!removeAllowedUOMsResponse.successful) {
        response = false;
        ErrorHandler.showError("Error updating allowed UOMs for Damage Agent: " + this.state.name);
      }
    }
    return response;
  }

  formatDate(date) {
    date = new Date(date);
    return new Date(date.getTime() - date.getTimezoneOffset() * -60000).toLocaleDateString();
  }

  render() {
    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        <div className={styles.editPageContainer}>
          <Row className="mt-4">
            <Col className="pl-0">
              <h5>Edit Damage Agent</h5>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="px-0">
              <Card>
                <Card.Header className={globalStyles.cardTitles}>
                  <span className={globalStyles.cardTitleText}>Damage Agent Information</span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="speciesNameInput">
                          Damage Agent Name
                        </Form.Label>
                        <Form.Control
                          defaultValue={this.state.name}
                          id="speciesNameInput"
                          data-testid="speciesName"
                          type="text"
                          placeholder="Enter Name"
                          onChange={this.handleSpeciesNameChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="commonNameInput">
                          Common Name<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          defaultValue={this.state.name}
                          id="commonNameInput"
                          data-testid="commonSpeciesName"
                          type="text"
                          placeholder="Enter Name"
                          onChange={this.handleCommonNameChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="invertedCommonNameInput">
                          Inverted Common Name<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          defaultValue={this.state.inverted_name}
                          id="invertedCommonNameInput"
                          data-testid="invertedCommonSpeciesName"
                          type="text"
                          placeholder="Enter Name"
                          onChange={this.handleInvertedNameChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="scientificNameInput">
                          Scientific Name<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          defaultValue={this.state.scientific_name}
                          id="scientificNameInput"
                          data-testid="scientificNameInput"
                          type="text"
                          placeholder="Enter Name"
                          onChange={this.handleScientificNameChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="endangeredStatus">
                          Threatened/Endangered Status
                        </Form.Label>
                        <Form.Control
                          value={this.state.endangeredStatusId}
                          id="endangeredStatus"
                          data-testid="endangeredStatus"
                          as="select"
                          onChange={this.handleEndangeredStatusChange}
                        >
                          {this.state.endangeredStatuses?.map((status) => {
                            return (
                              <option key={status.name} value={status.id}>
                                {status.displayText}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="px-0">
              <Card>
                <Card.Header className={globalStyles.cardTitles}>
                  <span className={globalStyles.cardTitleText}>Damage Agent Categorization</span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="speciesTypesDropdown">
                          Damage Agent Type<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          value={this.state.type}
                          id="speciesTypesDropdown"
                          data-testid="speciesTypesDropdown"
                          as="select"
                          onChange={this.handleSpeciesTypeChange}
                        >
                          {this.state.types.map((type) => (
                            <option key={type}>{type}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="speciesClassInput">
                          Damage Agent Class<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          defaultValue={this.state.class}
                          id="speciesClassInput"
                          data-testid="speciesClassInput"
                          type="text"
                          placeholder="Enter Class"
                          onChange={this.handleSpeciesClassChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="speciesSubTypesDropdown">
                          Damage Agent Category
                        </Form.Label>
                        <Form.Control
                          defaultValue={this.state.subtype}
                          id="speciesSubTypesDropdown"
                          data-testid="speciesSubTypesDropdown"
                          as="select"
                          onChange={this.handleSpeciesSubTypeChange}
                        >
                          {this.state.subTypes.map((subType) => (
                            <option key={subType}>{subType}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="speciesFamilyInput">
                          Damage Agent Family<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          defaultValue={this.state.family}
                          id="speciesFamilyInput"
                          data-testid="speciesFamilyInput"
                          type="text"
                          placeholder="Enter Family"
                          onChange={this.handleSpeciesFamilyChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="speciesOrderInput">
                          Damage Agent Order<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          defaultValue={this.state.order}
                          id="speciesOrderInput"
                          data-testid="speciesOrderInput"
                          type="text"
                          placeholder="Enter Order"
                          onChange={this.handleSpeciesOrderChange}
                        />{" "}
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlid="allowedUOMList">
                        <h4 className={globalStyles.modalFormLabel}>State Allowed UOMs</h4>
                        <div className={styles.editPageMethodVariableSelectColumn}>
                          <Dropdown data-testid="UOMsDropdown">
                            <Dropdown.Toggle variant="light">Change State Allowed UOMs</Dropdown.Toggle>
                            <Dropdown.Menu flip="false" className={styles.dropdownToggle}>
                              <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                                {this.state.allowedUOMsList.map((item) => (
                                  <div
                                    key={`formChecklistContainerUOMs-${item.name}`}
                                    className={styles.formCheckContainer}
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input ml-0"
                                      id={`${item.name}${item.id}`}
                                      key={`inline-${item.name}${item.id}`}
                                      data-toggle="button"
                                      onClick={() => this.handleUOMsCheckClick(item)}
                                      checked={this.state.allowedUOMsData?.find((uom) => uom.id === item.id)}
                                    />
                                    <p className="form-check-label ml-4" htmlFor="itemChecked">
                                      {item.name}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="px-0">
              <Card>
                <Card.Header className={globalStyles.cardTitles}>
                  <span className={globalStyles.cardTitleText}>Additional Information</span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="inCountryInput">
                          In Country
                        </Form.Label>
                        <Form.Control
                          defaultValue={this.state.country}
                          id="inCountryInput"
                          data-testid="inCountryInput"
                          type="text"
                          placeholder="Enter Country"
                          onChange={this.handleCountryChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="inBaseInput">
                          In Base
                        </Form.Label>
                        <Form.Control
                          defaultValue={this.state.base}
                          id="inBaseInput"
                          data-testid="inBaseInput"
                          type="text"
                          placeholder="Enter Base"
                          onChange={this.handleBaseChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="code4Input">
                          Code 4
                        </Form.Label>
                        <Form.Control
                          value={this.state.code4}
                          id="code4Input"
                          data-testid="code4Input"
                          type="text"
                          maxLength="4"
                          placeholder="xxxx"
                          onChange={this.handleCode4Change}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="code6Input">
                          Code 6
                        </Form.Label>
                        <Form.Control
                          value={this.state.code6}
                          id="code6Input"
                          data-testid="code6Input"
                          type="text"
                          maxLength="6"
                          placeholder="xxxxxx"
                          onChange={this.handleCode6Change}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="taxNumInput">
                          Taxonomy Number<span className={globalStyles.asterisk508}>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          defaultValue={this.state.taxonomy_number}
                          id="taxNumInput"
                          data-testid="taxNumInput"
                          type="text"
                          placeholder="xxxx-xxxx"
                          onChange={this.handleTaxonomyNumberChange}
                        />
                        {this.state.errors?.taxonomy_number && (
                          <div className="alert alert-danger">{this.state.errors.taxonomy_number}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <h5 className={globalStyles.modalFormLabel}>Status</h5>
                      <Form.Group>
                        <Form.Check
                          checked={this.state.isAllowedGlobally}
                          type="radio"
                          name="activeRadios"
                          inline
                          label="Active"
                          id="activeRadio"
                          onChange={this.activateRadio}
                        />
                        <Form.Check
                          checked={!this.state.isAllowedGlobally}
                          type="radio"
                          name="activeRadios"
                          inline
                          label="Inactive"
                          id="inactiveRadio"
                          onChange={this.deactivateRadio}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="speciesDescription">
                          Description
                        </Form.Label>
                        <Form.Control
                          value={this.state.description}
                          id="speciesDescription"
                          title="Damage Agent Description"
                          as="textarea"
                          maxLength="155"
                          placeholder="Enter Description"
                          name="description"
                          onChange={this.handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="px-0">
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="createdDate">
                          Date Added
                        </Form.Label>
                        <Form.Control
                          defaultValue={this.formatDate(this.state.createdDateTimeUTC)}
                          id="createdDate"
                          data-testid="createdDate"
                          type="text"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.modalFormLabel} htmlFor="updatedDate">
                          Last Updated Date
                        </Form.Label>
                        <Form.Control
                          defaultValue={this.formatDate(this.state.lastModifiedDateTimeUTC)}
                          id="updatedDate"
                          data-testid="updatedDate"
                          type="text"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className={styles.editPageButtonContainer}>
            <Button
              className={styles.buttons}
              variant="outline-primary"
              onClick={() =>
                this.props.history.push({
                  pathname: "/referencefiles",
                  state: { tab: "speciesTab", globalData: this.props.history.location.state.globalData },
                })
              }
            >
              Cancel
            </Button>
            <Button className={styles.buttons} type="submit" variant="primary">
              Update
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}
export default withRouter(EditSpecies);
