import React from "react";
import { Form, Row, Col, Button, Image, Modal } from "react-bootstrap";
import xCircle from "../../assets/x-circle.svg";
import styles from "../Agreements/Agreement.module.scss";

class FileUploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFileSelected: false,
      isMulitpleFileSelected: false,
      signatureDate: new Date().toLocaleDateString("en-CA"),
    };
  }

  handleSignatureDateChange = (input) => {
    const signatureDate = input.currentTarget.value;
    this.props.onSignatureDateChange(signatureDate);
    this.setState({ signatureDate });
  };

  handleSave = () => {
    this.props.onSave();
    this.props.onHide();
    this.setState({ signatureDate: new Date().toLocaleDateString("en-CA") });
  };

  handleCancel = () => {
    this.props.onSignatureDateChange("");
    this.props.onHide();
    this.setState({ signatureDate: new Date().toLocaleDateString("en-CA") });
  };

  handleFileSelection = (e) => {
    var file = e.currentTarget.files[0];
    this.props.handleFileSelected(file);

    if (file) this.setState({ isFileSelected: true });
  };

  handleMultipleFileSelection = (e) => {
    var files = e.currentTarget.files;
    this.props.handleMultipleFilesSelected(files);

    if (files) this.setState({ isMulitpleFileSelected: true, isFileSelected: true });
  };

  getButtonHeader() {
    if (this.props.isFLA) {
      return "Upload and Submit";
    } else {
      return "Accept and Sign";
    }
  }

  getModalTitle() {
    if (this.props.isFLA) {
      return "Agreement Files";
    } else {
      return "Agreement Signature";
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered size="lg">
        <Modal.Header>
          <div className="container">
            <Modal.Title>{this.getModalTitle()}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div id="divSignature" hidden={this.props.isFLA}>
              <Row>
                <Col>
                  <i className="fa fa-exclamation-circle " style={{ color: "gray" }} aria-hidden="true"></i> Attach
                  signature from 12A or mobile.
                </Col>
              </Row>
              <Row className="my-2">
                <Col xs={4}>
                  <Form.Group>
                    <Form.Label className="formLabel">Signature Date</Form.Label>
                    <Form.Control
                      placeholder="Enter Signature Date"
                      type="date"
                      value={this.state.signatureDate}
                      onChange={this.handleSignatureDateChange}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="formLabel">12a Signature Upload</Form.Label>
                </Col>
              </Row>
              <Row className="pb-3">
                <Col>
                  <input onChange={this.handleFileSelection} type="file" accept=".pdf" />
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Form.Label className="formLabel">Other Documents Upload</Form.Label>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <input onChange={this.handleMultipleFileSelection} type="file" accept=".pdf" multiple />
              </Col>
            </Row>
            {this.props.uploadedOtherDocuments?.map((file, index) => {
              return (
                <Row key={file?.name + index}>
                  <Col xs={5} align="left">
                    <Button
                      className={styles.PDFLinkButton}
                      variant="link"
                      onClick={() => {
                        let url = window.URL.createObjectURL(file);
                        window.open(url, "_blank");
                      }}
                    >
                      <Row>
                        <Col>
                          <span className={styles.actionButtonsText}>{file?.name ? file.name : "No Name"}</span>
                        </Col>
                      </Row>
                    </Button>
                  </Col>
                  <Col xs={1} align="right">
                    <Image
                      hidden={this.state.isDetailsPage}
                      style={{ width: "15px", cursor: "pointer" }}
                      src={xCircle}
                      onClick={() => this.props.removeFileFromOtherAttachments(index)}
                      tabIndex="0"
                      alt={"Remove file named: " + file?.name}
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={this.handleSave}
            hidden={!this.props.isFLA}
            disabled={this.state.isFileSelected}
          >
            Submit
          </Button>
          <Button variant="primary" onClick={this.handleSave} disabled={!this.state.isFileSelected}>
            {this.getButtonHeader()}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FileUploadModal;
