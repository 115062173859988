import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./HomePage.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Row, DropdownButton, Col, Dropdown, Button, Card, Tabs, Tab, Image, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { customFilter, selectFilter } from "react-bootstrap-table2-filter";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import AdminWorkTaskModal from "../AdminWorkTask/AdminWorkTaskModal";
import CreateConflictModal from "../Conflicts/CreateConflictModal";
import TAWorkTaskModalWithTopics from "../TechnicalAssists/TAWorkTaskModal/TAWorkTaskModalWithTopics";
import expandIcon from "../../assets/dashboard-expand-icon.svg";
import collapseIcon from "../../assets/dashboard-collapse-icon.svg";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import CustomTextFilter from "../common/customTextFilter";
import UtilityFunctions from "../common/UtilityFunctions";
import WorkTaskAPI from "../../api/WorkTask/WorkTaskAPI";
import LoadingOverlay from "react-loading-overlay";
import PrintAgreementForm from "../PDFHandling/PrintAgreementForm";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CreatePermitModal from "../PermitModals/CreatePermitModal/CreatePermitModal";
import CreateProjectModal from "../Projects/CreateProjectModal";
import { toast } from "react-toastify";
import CreateDistrictModal from "../Districts/CreateDistrictModal";
import NotificationWidget from "../NotificationWidget/NotificationWidget";
import CreateWarehouseModal from "../Warehouse/CreateWarehouseModal";
import InventoryAPI from "../../api/Inventory/InventoryAPI";
import ApprovalRequestWidget from "../Agreements/ApprovalRequestWidget";
import RecentPropertiesWidget from "../AgreementEntities/Properties/RecentPropertiesWidget";

LoadingOverlay.propTypes = "";

const MyAgreementsTabs = {
  Active: "activeAgreementsTab",
  Pending: "pendingAgreementsTab",
  Draft: "draftAgreementsTab",
};

const AgreementsStatuses = {
  Draft: 1,
  Pending: 2,
  Active: 3,
  Expired: 4,
  Cancelled: 5,
  Inactive: 6,
};

export class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetNum: 15,
      showUserRolesModal: false,
      showAdminWorkTaskModal: false,
      showCreateConflictModal: false,
      showCreateDistrictModal: false,
      showTAWorkTaskModal: false,
      showHotlineTAWorkTaskModal: false,
      showOutreachTAWorkTaskModal: false,
      showCreatePermitModal: false,
      showCreateProjectModal: false,
      showCreateWarehouseModal: false,
      currentAgreementsTab: MyAgreementsTabs.Active,
      isSomeWidgetExpanded: false,
      expandedWidgets: {
        myAgreements: false,
        myNotifications: false,
        approvalRequest: false,
        recentProperties: false,
      },
      agreements: [],
      activeAgreementsColumns: [
        { dataField: "agreementUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      pendingAgreementsColumns: [
        { dataField: "agreementUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      draftAgreementsColumns: [
        { dataField: "agreementUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      isAgreementDataLoading: true,
      agreementFlagsFilter: null,
      agreementData: "",
      cooperatorData: "",
      propertiesData: [],
      associatedContactsData: [],
      usaStates: [],
      countries: [],
      showHappyModal: false,
      happyModalMessageType: "",
      happyModalObjectType: "",
      happyModalOnAcknowledgeRoute: "",
      happyModalRouteData: "",
      currentUserProfile: "",
      availableStatesForProfile: [],
    };
  }

  async componentDidMount() {
    const isUserAuthenticated = localStorage.getItem("isUserAuthenticated");

    if (isUserAuthenticated) {
      this.setState(
        {
          agreements: await this.getAgreementsData(),
          activeAgreementsColumns: this.getMyAgreementsColumns(AgreementsStatuses.Active),
          pendingAgreementsColumns: this.getMyAgreementsColumns(AgreementsStatuses.Pending),
          draftAgreementsColumns: this.getMyAgreementsColumns(AgreementsStatuses.Draft),
          isAgreementDataLoading: false,
          usaStates: await this.getAllStatesFromAPI(),
          countries: await this.getAllCountriesFromAPI(),
        },
        async () => {
          this.setState({ availableStatesForProfile: await this.getAvailableStates(this.state.usaStates) });
        }
      );
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.currentUserProfile?.wsUserProfileUniqueIdentifier !==
      this.props?.currentUserProfile?.wsUserProfileUniqueIdentifier ||
      prevState.currentAgreementsTab !== this.state.currentAgreementsTab
    ) {
      this.setState({ isAgreementDataLoading: true });
      const agreementsData = await this.getAgreementsData();
      this.setState({ agreements: agreementsData }, () => {
        this.setState({ isAgreementDataLoading: false });
      });
    }
  }

  async getAllStatesFromAPI() {
    let usaStates = [];

    const usaStatesResponse = await ReferenceFileAPI.GetAllStates();
    if (usaStatesResponse?.length > 0) {
      usaStates = usaStatesResponse;
    }
    return usaStates;
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  async getAgreementsData() {
    let agreementsData = [];
    if (this.props.currentUserProfile?.wsUserProfileUniqueIdentifier) {
      const getMyAGreementsResponse = await AgreementAPI.GetMyAgreementsInState(
        this.props.userData.currentState.stateGuid,
        this.props.currentUserProfile.wsUserProfileUniqueIdentifier,
        this.getCurrentAgreementStatusTab()
      );
      if (getMyAGreementsResponse?.successful && getMyAGreementsResponse.data?.length > 0) {
        agreementsData = getMyAGreementsResponse.data;
      }
    }

    return agreementsData;
  }

  getCurrentAgreementStatusTab() {
    let currentAgreementStatus;
    switch (this.state.currentAgreementsTab) {
      case MyAgreementsTabs.Pending:
        currentAgreementStatus = AgreementsStatuses.Pending;
        break;
      case MyAgreementsTabs.Draft:
        currentAgreementStatus = AgreementsStatuses.Draft;
        break;
      default:
        currentAgreementStatus = AgreementsStatuses.Active;
        break;
    }
    return currentAgreementStatus;
  }

  getMyAgreementsColumns(agreementStatus) {
    let agreementColumns = [
      {
        dataField: "agreementUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "commonName",
        text: "Agreement Name",
        formatter: (cell, row) => {
          return (
            <Button variant="link" onClick={() => this.handleAgreementDetailsNavigation(row)}>
              {cell}
            </Button>
          );
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        style: () => {
          return { paddingLeft: "0px" };
        },
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "agreementNameHeader" },
        attrs: { headers: "agreementNameHeader", tabIndex: "0" },
      },
      {
        dataField: "agreementNumber",
        text: "Agreement Number",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
        sort: true,
        align: "left",
        headerAlign: "left",
        headerAttrs: { id: "agreementNumberHeader" },
        attrs: { headers: "agreementNumberHeader", tabIndex: "0" },
      },
    ];

    switch (agreementStatus) {
      case AgreementsStatuses.Active:
        agreementColumns.push(
          {
            dataField: "cooperatorName",
            text: "Cooperator Name",
            filter: customFilter({
              onFilter: this.filterCooperatorColumn,
            }),
            filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
            sort: true,
            align: "left",
            headerAlign: "left",
            headerAttrs: { id: "cooperatorNameHeader" },
            attrs: { headers: "cooperatorNameHeader", tabIndex: "0" },
          },
          {
            dataField: "expirationDate",
            text: "Expiration Date",
            formatter: (cell, row) => {
              var dateTime = new Date(cell);
              return dateTime.toLocaleDateString("en-US");
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
              <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Expiration Date" />
            ),
            sort: true,
            align: "left",
            headerAlign: "left",
            headerAttrs: { id: "expirationDateHeader" },
            attrs: { headers: "expirationDateHeader", tabIndex: "0" },
          },
          {
            dataField: "lastSiteVisitDate",
            text: "Last Site Visit",
            formatter: (cell, row) => {
              var dateTime = new Date(cell);
              return dateTime.toLocaleDateString("en-US");
            },
            filter: customFilter({
              onFilter: this.filterLastSiteVisitColumn,
            }),
            filterRenderer: (onFilter, column) => (
              <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Last Site Visit" />
            ),
            sort: true,
            align: "left",
            headerAlign: "left",
            headerAttrs: { id: "lastSiteVisitHeader" },
            attrs: { headers: "lastSiteVisitHeader", tabIndex: "0" },
          }
        );
        break;
      case AgreementsStatuses.Pending:
        agreementColumns.push(
          {
            dataField: "agreementFlags",
            text: "Flags",
            formatter: (cell, row) => {
              return row.isFlagged ? "Yes" : "No";
            },
            filter: selectFilter({
              options: { true: "Yes", false: "No" },
              onFilter: this.filterAgreementFlagsColumn,
            }),
            sort: true,
            align: "left",
            headerAlign: "left",
            headerAttrs: { id: "agreementFlagsHeader" },
            attrs: { headers: "agreementFlagsHeader", tabIndex: "0" },
          },
          {
            dataField: "daysRemaining",
            text: "Days Remaining",
            formatter: (cell, row) => {
              return "N/A";
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} />,
            sort: true,
            align: "left",
            headerAlign: "left",
            headerAttrs: { id: "daysRemainingHeader" },
            attrs: { headers: "daysRemainingHeader", tabIndex: "0" },
          }
        );
        break;
      case AgreementsStatuses.Draft:
        agreementColumns.push({
          dataField: "createdDateTimeUTC",
          text: "Date Created",
          formatter: (cell, row) => {
            var dateTime = new Date(cell);
            return dateTime.toLocaleDateString("en-US");
          },
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Date Created" />
          ),
          sort: true,
          align: "left",
          headerAlign: "left",
          headerAttrs: { id: "dateCreatedHeader" },
          attrs: { headers: "dateCreatedHeader", tabIndex: "0" },
        });
        break;
      default:
        break;
    }

    agreementColumns.push({
      dataField: "download",
      text: "Download",
      formatter: (cell, row) => {
        return (
          <Button variant="link" onClick={() => this.handleAgreementPDFDownload(row)}>
            Download
          </Button>
        );
      },
      headerStyle: () => {
        return {
          width: agreementStatus === AgreementsStatuses.Active ? "15%" : "20%",
          paddingRight: "1rem",
          paddingBottom: "4.5rem",
        };
      },
      style: () => {
        return { paddingRight: "0px" };
      },
      headerAlign: "right",
      align: "right",
      headerAttrs: {
        id: "downloadHeader",
        title: "Download Column Header",
      },
      attrs: { headers: "downloadHeader" },
    });

    return agreementColumns;
  }

  handleAgreementPDFDownload = async (row) => {
    if (row?.agreementUniqueIdentifier) {
      const agreementDataResponse = await AgreementAPI.GetAgreement(row.agreementUniqueIdentifier);
      if (agreementDataResponse?.successful) {
        let agreementData = agreementDataResponse.data;
        const cooperatorData = agreementData.cooperator
          ? await this.getContactData(agreementData.cooperator.contactUniqueReference)
          : "";
        if (agreementData.primaryFieldAgent) {
          agreementData.primaryFieldAgent = await this.getUserData(
            agreementData.primaryFieldAgent.fieldAgentWsUserProfileUniqueReference
          );
        }
        agreementData.additionalFieldAgents = await Promise.all(
          agreementData.additionalFieldAgents.map(async (agent) => {
            return await this.getUserData(agent.contactUniqueReference);
          })
        );
        const propertiesData = await this.getPropertiesData(agreementData);
        this.getMappedSpeciesData(agreementData, propertiesData);
        this.getMappedMethodsData(agreementData, propertiesData);
        this.getSpecialInstructionsData(agreementData);
        await this.getAgreementAssociatedContactsData(agreementData);
        const associatedContactsData = await this.getPropertyAssociatedContactsData(agreementData);

        this.setState({
          agreementData,
          cooperatorData,
          propertiesData,
          associatedContactsData,
        });

        this.downloadAgreementPDF();
      }
    }
  };

  async getMappedSpeciesData(agreementData, propertiesData) {
    let targetedSpeciesData = [];
    await Promise.all(
      agreementData.agreementProperties.map(async (property) => {
        const propertyData = propertiesData.find(
          (p) => p.propertyUniqueIdentifier === property.propertyUniqueReference
        );
        property.targetedSpecies.forEach((species) => {
          if (propertyData) {
            let checkedParcels = [];
            let parcelNumber = 1;
            if (propertyData.parcels && propertyData.parcels.length > 0) {
              checkedParcels = propertyData.parcels.map((parcel) => ({
                parcelUniqueIdentifier: parcel.parcelUniqueIdentifier,
                parcelName: "Parcel " + parcelNumber++,
              }));
            }

            species.speciesTableId = `${species.speciesUniqueReference}|${property.propertyUniqueReference}`;
            species.speciesUniqueIdentifier = species.speciesUniqueReference;
            species.checkedProperties = [
              {
                propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
                propertyName: propertyData.propertyName,
                checkedParcels: checkedParcels,
                parcels: checkedParcels,
              },
            ];
          }
          targetedSpeciesData.push(species);
        });

        await Promise.all(
          property.parcels.map(async (parcel, index) => {
            await Promise.all(
              parcel.targetedSpecies.map(async (species) => {
                if (
                  !property.targetedSpecies.find(
                    (propertySpecies) => propertySpecies.speciesUniqueReference === species.speciesUniqueReference
                  )
                ) {
                  species.speciesTableId = `${species.speciesUniqueReference}|${propertyData.propertyUniqueIdentifier}`;
                  species.speciesUniqueIdentifier = species.speciesUniqueReference;
                  let findSpeciesName = targetedSpeciesData.find(
                    (targetedSpecies) => targetedSpecies.speciesUniqueReference === species.speciesUniqueReference
                  )?.speciesName;

                  findSpeciesName
                    ? (species.speciesName = findSpeciesName)
                    : (species.speciesName = await this.getSpeciesName(species.speciesUniqueReference));
                  species.checkedProperties = [
                    {
                      propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
                      propertyName: propertyData.propertyName,
                      checkedParcels: [
                        {
                          parcelUniqueIdentifier: parcel.parcelUniqueReference,
                          parcelName: "Parcel " + (index + 1),
                        },
                      ],
                      parcels: [
                        {
                          parcelUniqueIdentifier: parcel.parcelUniqueReference,
                          parcelName: "Parcel " + (index + 1),
                        },
                      ],
                    },
                  ];
                  let foundSpeciesOnPropertyParcel = false;
                  targetedSpeciesData.forEach((targetedSpecies) => {
                    targetedSpecies.checkedProperties.forEach((targetedSpeciesCheckedProperty) => {
                      const speciesMatch = targetedSpecies.speciesUniqueReference === species.speciesUniqueReference;
                      const propertyMatch =
                        targetedSpeciesCheckedProperty.propertyUniqueIdentifier ===
                        propertyData.propertyUniqueIdentifier;
                      const parcelNotIncluded = !targetedSpeciesCheckedProperty.checkedParcels.find(
                        (p) => p.parcelUniqueIdentifier === parcel.parcelUniqueIdentifier
                      );
                      if (speciesMatch && propertyMatch && parcelNotIncluded) {
                        targetedSpeciesCheckedProperty.checkedParcels.push({
                          parcelUniqueIdentifier: parcel.parcelUniqueReference,
                          parcelName: "Parcel " + (index + 1),
                        });
                        targetedSpeciesCheckedProperty.parcels.push({
                          parcelUniqueIdentifier: parcel.parcelUniqueReference,
                          parcelName: "Parcel " + (index + 1),
                        });
                        foundSpeciesOnPropertyParcel = true;
                      }
                    });
                  });
                  if (!foundSpeciesOnPropertyParcel) {
                    targetedSpeciesData.push(species);
                  }
                }
              })
            );
          })
        );
      })
    );
    return targetedSpeciesData;
  }

  async getMappedMethodsData(agreementData, propertiesData) {
    let methodsData = [];
    await Promise.all(
      agreementData.agreementProperties.map(async (property) => {
        const propertyData = propertiesData.find(
          (p) => p.propertyUniqueIdentifier === property.propertyUniqueReference
        );
        property.allowedMethods.forEach((method) => {
          if (propertyData) {
            let checkedParcels = [];
            let parcelNumber = 1;
            if (propertyData.parcels && propertyData.parcels.length > 0) {
              checkedParcels = propertyData.parcels?.map((parcel) => ({
                parcelUniqueIdentifier: parcel.parcelUniqueIdentifier,
                parcelName: "Parcel " + parcelNumber++,
              }));
            }

            method.methodTableId = `${method.methodUniqueReference}|${property.propertyUniqueReference}`;
            method.methodUniqueIdentifier = method.methodUniqueReference;
            method.checkedProperties = [
              {
                propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
                propertyName: propertyData.propertyName,
                checkedParcels: checkedParcels,
                parcels: checkedParcels,
              },
            ];
          }

          methodsData.push(method);
        });

        await Promise.all(
          property.parcels.map(async (parcel, index) => {
            await Promise.all(
              parcel.allowedMethods.map(async (method) => {
                if (
                  !property.allowedMethods.find(
                    (propertyMethod) => propertyMethod.methodUniqueReference === method.methodUniqueReference
                  )
                ) {
                  method.methodTableId = `${method.methodUniqueReference}|${propertyData.propertyUniqueIdentifier}`;
                  method.methodUniqueIdentifier = method.methodUniqueReference;
                  let findMethodName = methodsData.find(
                    (allowedMethod) => allowedMethod.methodUniqueReference === method.methodUniqueReference
                  )?.methodName;
                  findMethodName
                    ? (method.methodName = findMethodName)
                    : (method.methodName = await this.getMethodName(method.methodUniqueReference));

                  method.checkedProperties = [
                    {
                      propertyUniqueIdentifier: propertyData.propertyUniqueIdentifier,
                      propertyName: propertyData.propertyName,
                      checkedParcels: [
                        {
                          parcelUniqueIdentifier: parcel.parcelUniqueReference,
                          parcelName: "Parcel " + (index + 1),
                        },
                      ],
                      parcels: [
                        {
                          parcelUniqueIdentifier: parcel.parcelUniqueReference,
                          parcelName: "Parcel " + (index + 1),
                        },
                      ],
                    },
                  ];
                  let foundMethodOnPropertyParcel = false;
                  methodsData.forEach((allowedMethod) => {
                    allowedMethod.checkedProperties.forEach((allowedMethodCheckedProperty) => {
                      const methodMatch = allowedMethod.methodUniqueReference === method.methodUniqueReference;
                      const propertyMatch =
                        allowedMethodCheckedProperty.propertyUniqueIdentifier === propertyData.propertyUniqueIdentifier;
                      const parcelNotIncluded = !allowedMethodCheckedProperty.checkedParcels.find(
                        (p) => p.parcelUniqueIdentifier === parcel.parcelUniqueIdentifier
                      );
                      if (methodMatch && propertyMatch && parcelNotIncluded) {
                        allowedMethodCheckedProperty.checkedParcels.push({
                          parcelUniqueIdentifier: parcel.parcelUniqueReference,
                          parcelName: "Parcel " + (index + 1),
                        });
                        allowedMethodCheckedProperty.parcels.push({
                          parcelUniqueIdentifier: parcel.parcelUniqueReference,
                          parcelName: "Parcel " + (index + 1),
                        });
                        foundMethodOnPropertyParcel = true;
                      }
                    });
                  });
                  if (!foundMethodOnPropertyParcel) {
                    methodsData.push(method);
                  }
                }
              })
            );
          })
        );
      })
    );
    return methodsData;
  }

  getSpecialInstructionsData(agreementData) {
    const specialInstructionsData = [];

    agreementData.agreementProperties.forEach((property) => {
      if (property.specialInstructions && property.specialInstructions.length > 0) {
        for (const specialInstruction of property.specialInstructions) {
          specialInstruction.propertyUniqueIdentifier = property.propertyUniqueReference;
          specialInstructionsData.push(specialInstruction);
        }
      }
    });

    return specialInstructionsData;
  }

  async getAgreementAssociatedContactsData(agreementData) {
    let associatedAgreementContactsData = [];

    for (const contact of agreementData.additionalContacts) {
      let contactData = await this.getContactData(contact.contactUniqueReference);
      if (contactData) {
        contactData.contactRole = UtilityFunctions.getDisplayTextFromFieldObject(contact.contactRoleOnAgreement);
        associatedAgreementContactsData.push(contactData);
      }
    }

    return associatedAgreementContactsData;
  }

  async getContactData(contactUniqueId) {
    const contactDataResponse = await AgreementEntitiesAPI.GetContact(contactUniqueId);
    if (!contactDataResponse?.successful) {
      return null;
    }

    let contactData = contactDataResponse.data;
    contactData.primaryPhoneNumber = UtilityFunctions.getPrimaryPhoneNumber(contactData.phoneNumbers);
    contactData.fullName = UtilityFunctions.getContactNameFromDataObject(contactData);
    contactData.fullMailingAddress = this.getPrimaryAddress(contactData.primaryMailingAddress, "contact");
    return contactData;
  }

  async getUserData(userProfileId) {
    const userDataResponse = await RoleAuthorizationAPI.GetUserWhoOwnsProfile(userProfileId);
    if (userDataResponse?.successful) {
      let userData = userDataResponse.data;
      userData.fullName = userData.firstName + " " + userData.lastName;
      userData.fullMailingAddress = this.getPrimaryAddress(
        {
          mailingStreet: userData.streetAddress,
          mailingCity: userData.city,
          mailingZip: userData.zip,
          mailingStateUniqueId: userData.stateOfAddressUniqueReference,
          mailingCountryUniqueId: "",
        },
        "contact"
      );
      userData.fieldAgentWsUserProfileUniqueReference = userProfileId;
      return userData;
    }
  }

  getStateNameFromId(stateId) {
    const { usaStates } = this.state;
    const currentState = usaStates.find((state) => state.stateUniqueIdentifier === stateId);
    return currentState ? currentState.state_name : "";
  }

  getCountryNameFromId(countryId) {
    const { countries } = this.state;
    const country = countries.find((country) => country.id === countryId);
    return country ? country.name : "";
  }

  getPrimaryAddress(providedAddress, entityType) {
    let primaryAddress = "";

    if (providedAddress) {
      switch (entityType) {
        case "contact":
          const { mailingStreet, mailingCity, mailingStateUniqueId, mailingZip, mailingCountryUniqueId } =
            providedAddress;
          primaryAddress = `${mailingStreet ? mailingStreet + ", " : ""}${mailingCity ? mailingCity + ", " : ""}${mailingStateUniqueId ? this.getStateNameFromId(mailingStateUniqueId) + ", " : ""
            }${mailingZip ? mailingZip + ", " : ""}${mailingCountryUniqueId ? this.getCountryNameFromId(mailingCountryUniqueId) : ""
            }`;
          break;
        case "property":
          const { streetAddress, city, zipCode } = providedAddress;
          primaryAddress = streetAddress.concat(", ", city, ", ", zipCode);
          break;
        default:
          break;
      }
    }

    return primaryAddress;
  }

  async getPropertiesData(agreementData) {
    let propertiesData = [];
    for (const property of agreementData.agreementProperties) {
      const propertyResponse = await AgreementEntitiesAPI.GetProperty(property.propertyUniqueReference);
      if (propertyResponse?.successful) {
        propertyResponse.data.checkedParcels = propertyResponse.data.parcels;
        propertiesData.push(propertyResponse.data);
      }
    }

    return propertiesData;
  }

  async getPropertyAssociatedContactsData(agreementData) {
    let associatedPropertyContactsData = [];

    for (const property of agreementData.agreementProperties) {
      if (property.additionalContacts && property.additionalContacts.length > 0) {
        for (const contact of property.additionalContacts) {
          let contactData = await this.getContactData(contact.contactUniqueReference);
          if (contactData) {
            contactData.propertyUniqueIdentifier = property.propertyUniqueReference;
            contactData.contactRole = UtilityFunctions.getDisplayTextFromFieldObject(contact.contactRoleOnAgreement);
            associatedPropertyContactsData.push(contactData);
          }
        }
      }
    }

    return associatedPropertyContactsData;
  }

  downloadAgreementPDF = () => {
    const element = document.getElementById("pdfDocument").innerHTML;
    const fileName = this.props.agreementData?.agreementNumber
      ? `AgreementDetails_${this.props.agreementData.agreementNumber}`
      : "AgreementDetails";
    UtilityFunctions.downloadPDF(element, fileName);
  };

  filterCooperatorColumn = (filterValue, data) => {
    if (filterValue) {
      const lowercaseFilteredValue = filterValue.toLowerCase();
      return data.filter(
        (entry) =>
          entry.cooperatorName?.firstName?.toLowerCase().includes(lowercaseFilteredValue) ||
          entry.cooperatorName?.lastName?.toLowerCase().includes(lowercaseFilteredValue) ||
          entry.cooperatorName?.middleName?.toLowerCase().includes(lowercaseFilteredValue) ||
          entry.cooperatorName?.suffix?.toLowerCase().includes(lowercaseFilteredValue)
      );
    }
  };

  filterLastSiteVisitColumn = (filterValue, data) => {
    return data.filter((entry) => {
      const sortedPvsDates = entry.associatedPVS?.sort((a, b) => b - a);
      return sortedPvsDates?.length > 0 && sortedPvsDates[0]?.toISOString()?.includes(filterValue);
    });
  };

  handleAgreementDetailsNavigation = (row) => {
    const pathname =
      this.state.currentAgreementsTab === MyAgreementsTabs.Draft ? "/agreement/create" : "/agreement/details";
    this.props.history.push({
      pathname: pathname,
      state: {
        entityData: row,
      },
    });
  };

  async getAvailableStates(usaStates) {
    let availableStates = [];
    if (this.props.userData.currentUserProfile?.profileAssociatedStates?.length > 0) {
      if (usaStates?.length > 0) {
        for (const associatedStateId of this.props.userData.currentUserProfile.profileAssociatedStates) {
          const associatedState = usaStates.find((state) => state.stateUniqueIdentifier === associatedStateId);
          if (associatedState) {
            availableStates.push({
              value: associatedStateId,
              label: associatedState.state_name,
              code: associatedState.state_code,
            });
          }
        }
      }
    } else {
      const userPermissionsResponse = await RoleAuthorizationAPI.MyPermissions();
      if (userPermissionsResponse?.successful && userPermissionsResponse.data?.associatedStates?.length > 0) {
        if (usaStates?.length > 0) {
          for (const associatedStateId of userPermissionsResponse.data.associatedStates) {
            const associatedState = usaStates.find((state) => state.stateUniqueIdentifier === associatedStateId);
            if (associatedState) {
              availableStates.push({
                value: associatedStateId,
                label: associatedState.state_name,
                code: associatedState.state_code,
              });
            }
          }
        }
      }
    }

    return availableStates;
  }

  async getUserRoles() {
    let userRoles = [];
    const userRolesResponse = await RoleAuthorizationAPI.GetAllRoles();
    if (userRolesResponse?.successful) {
      if (userRolesResponse.data.results.length > 0) {
        userRoles = userRolesResponse.data.results;
      } else {
        const dummySystemRolesToMake = ["OARS User", "Data Tech", "WS Field Specialist", "System Admin"];
        await Promise.all(
          dummySystemRolesToMake.map(async (r) => {
            const createRoleCall = await RoleAuthorizationAPI.CreateRole(r);
          })
        );
        const newUserRoleCall = await RoleAuthorizationAPI.GetAllRoles();
        userRoles = newUserRoleCall.data.results;
      }
    } else {
      // ErrorHandler.showError(
      //   `System failed to get user roles. ${userRolesResponse?.message ? userRolesResponse.message : ""}`
      // );
    }
    return userRoles;
  }

  closeUserRolesModal = () => {
    this.setState({ showUserRolesModal: false });
  };

  closeAdminWorkTaskModal = () => {
    this.setState({ showAdminWorkTaskModal: false });
  };

  onCreateConflictModalClose = () => {
    this.setState({ showCreateConflictModal: false });
  };

  onCreateDistrictModalClose = () => {
    this.setState({ showCreateDistrictModal: false });
  };

  closeTAWorkTaskModal = () => {
    this.setState({ showTAWorkTaskModal: false });
  };

  closeHotlineTAWorkTaskModal = () => {
    this.setState({ showHotlineTAWorkTaskModal: false });
  };

  closeOutreachTAWorkTaskModal = () => {
    this.setState({ showOutreachTAWorkTaskModal: false });
  };

  closeCreatePermitModal = () => {
    this.setState({ showCreatePermitModal: false });
  };

  closeCreateProjectModal = () => {
    this.setState({ showCreateProjectModal: false });
  };

  closeCreateWarehouseModal = () => {
    this.setState({ showCreateWarehouseModal: false });
  };

  saveAdminWorkTask = async (taskData) => {
    const createAdminWorkTaskMessageBody = {
      datePerformed: new Date(taskData.taskDate).toISOString(),
      adminActivities: taskData.selectedActivity?.map((item) => {
        return { adminActivityUniqueReference: item.value };
      }),
      agreementUniqueReference: taskData.selectedAgreement?.value,
      hoursWorked: taskData.timeHours ? Number.parseInt(taskData.timeHours) : 0,
      minutesWorked: taskData.timeMinutes ? Number.parseInt(taskData.timeMinutes) : 0,
      comments: taskData.adminWorkTaskComments,
    };
    const createWorkTaskResponse = await WorkTaskAPI.CreateAdminWorkTask(createAdminWorkTaskMessageBody);
    if (createWorkTaskResponse?.successful) {
      this.setState({ happyModalMessageType: "create", happyModalObjectType: "Admin Work Task", showHappyModal: true });
    } else {
      ErrorHandler.showErrorWithDetails(
        { errorDetails: createWorkTaskResponse },
        "Admin Work Task could not be created. Please contact your system admin for more details"
      );
    }
  };

  handleCreateDistrict = async (districtData) => {
    if (this.props.userData?.currentState?.stateGuid) {
      const createDistrictResponse = await RoleAuthorizationAPI.CreateDistrict({
        districtName: districtData.districtName,
        stateUniqueReference: this.props.userData.currentState.stateGuid,
      });
      if (createDistrictResponse?.successful) {
        const setDistrictUsersResponse = await RoleAuthorizationAPI.SetDistrictUsers(
          createDistrictResponse.data?.districtUniqueIdentifier,
          this.getSetDistrictUsersBody(districtData)
        );
        if (setDistrictUsersResponse?.successful) {
          this.setState({
            happyModalMessageType: "create",
            happyModalObjectType: "District",
            showHappyModal: true,
            happyModalOnAcknowledgeRoute: "/district/details",
            happyModalRouteData: {
              entityData: setDistrictUsersResponse.data,
              isDistrictCreation: true,
            },
          });
        } else {
          toast.error("System failed to set district users.");
        }
      } else {
        toast.error(
          createDistrictResponse.message ? createDistrictResponse.message : "System failed to create district."
        );
      }
    }
  };

  getSetDistrictUsersBody(districtData) {
    return {
      newDistrictSupervisorWsUserProfileUniqueReferenceOrNullIfNotChanging: districtData.supervisor?.value,
      addTheseAdditionalSupervisorWsUserProfileUniqueReferences:
        districtData.assistantSupervisors?.length > 0 ? districtData.assistantSupervisors.map((s) => s.value) : [],
      removeTheseAdditionalSupervisorWsUserProfileUniqueReferences: [],
      addTheseEmployeeWsUserProfileUniqueReferences: [],
      removeTheseEmployeeWsUserProfileUniqueReferences: [],
    };
  }

  handleCreateProject = async (projectData) => {
    const createProjectResponse = await AgreementEntitiesAPI.CreateProject(this.getCreateProjectBody(projectData));
    if (createProjectResponse?.successful) {
      this.setState({
        happyModalMessageType: "create",
        happyModalObjectType: "Project",
        showHappyModal: true,
        happyModalOnAcknowledgeRoute: "/project/details",
        happyModalRouteData: {
          entityData: createProjectResponse.data,
          availableStates: this.state.usaStates,
          isProjectCreation: true,
        },
      });
    } else {
      ErrorHandler.showErrorWithDetails(
        createProjectResponse,
        "Project creation failed. Please contact system admin for more details."
      );
    }
  };

  handleCreateWarehouse = async (warehouseData) => {
    let entityData = null;
    const createWarehouseResponse = await InventoryAPI.CreateWarehouse(this.getCreateWarehouseBody(warehouseData));
    if (createWarehouseResponse?.successful) {
      entityData = createWarehouseResponse.data;
      if (!warehouseData.isActive && createWarehouseResponse.data?.warehouseUniqueIdentifier) {
        const deactivateWarehouseResponse = await this.deactivateWarehouse(
          createWarehouseResponse.data.warehouseUniqueIdentifier
        );
        if (deactivateWarehouseResponse?.successful) {
          entityData = deactivateWarehouseResponse.data;
        }
      }
      this.setState({
        happyModalMessageType: "create",
        happyModalObjectType: "Warehouse",
        showHappyModal: true,
        happyModalOnAcknowledgeRoute: "/warehouse/details",
        happyModalRouteData: {
          entityData: entityData,
          availableStates: this.state.availableStatesForProfile,
          isWarehouseCreation: true,
        },
      });
    } else if (createWarehouseResponse?.message) {
      toast.error(`Warehouse creation failed: ${createWarehouseResponse?.message}`);
    } else {
      ErrorHandler.showErrorWithDetails(
        createWarehouseResponse,
        "Warehouse creation failed. Please contact system admin for more details."
      );
    }
  };

  async deactivateWarehouse(warehouseUniqueId) {
    const deactivateWarehouseResponse = await InventoryAPI.DeactivateWarehouse(warehouseUniqueId);
    if (!deactivateWarehouseResponse?.successful) {
      ErrorHandler.showErrorWithDetails(
        deactivateWarehouseResponse,
        "System failed to deactivate warehouse. Please contact system admin for more details."
      );
    }
    return deactivateWarehouseResponse;
  }

  getCreateProjectBody(projectData) {
    return {
      projectName: projectData.projectName,
      startDate: new Date(projectData.startDate).toISOString(),
      endDate: new Date(projectData.endDate).toISOString(),
      parentProjectUniqueReferences: projectData.parentProjects.map((s) => s.value),
      stateUniqueReferences: projectData.selectedStates.map((s) => s.value),
    };
  }

  getCreateWarehouseBody(warehouseData) {
    return {
      warehouseTypeEnumId: warehouseData.warehouseType,
      warehouseName: warehouseData.warehouseName,
      warehouseStreetAddress: warehouseData.streetAddress,
      warehouseStateUniqueReference: warehouseData.warehouseState,
      warehouseCountryUniqueReference: warehouseData.country,
      warehouseCity: warehouseData.city,
      warehouseZipcode: warehouseData.zipCode,
      description: warehouseData.description,
      warehouseManagerWSUserProfileUniqueReference: warehouseData.warehouseManager?.value,
      warehouseInventory: [],
      warehouseMembers: [],
    };
  }

  handleTabSelect(tab) {
    this.setState({ currentAgreementsTab: tab });
  }

  handleWidgetExpand = ({ currentTarget: input }) => {
    const { expandedWidgets, isSomeWidgetExpanded } = this.state;

    expandedWidgets[input.name] = !expandedWidgets[input.name];
    this.setState({
      expandedWidgets,
      isSomeWidgetExpanded: !isSomeWidgetExpanded,
    });
  };

  renderAgreementsTab(agreementsData, agreementStatus) {
    let statusName = "";
    let agreementColumns = [];
    switch (agreementStatus) {
      case AgreementsStatuses.Active:
        statusName = "Active";
        agreementColumns = this.state.activeAgreementsColumns;
        break;
      case AgreementsStatuses.Pending:
        statusName = "Pending";
        agreementColumns = this.state.pendingAgreementsColumns;
        break;
      case AgreementsStatuses.Draft:
        statusName = "Draft";
        agreementColumns = this.state.draftAgreementsColumns;
        break;
      default: {
        statusName = "Active";
        agreementColumns = this.state.activeAgreementsColumns;
        break;
      }
    }

    if (agreementsData?.length > 0) {
      return (
        <BootstrapTable
          keyField="agreementUniqueIdentifier"
          data={agreementsData}
          columns={agreementColumns}
          filter={filterFactory()}
          bootstrap4={true}
          hover={true}
          bordered={false}
        />
      );
    } else {
      return (
        <Row>
          <Col className="mt-2 text-center">{`There are no ${statusName} Agreements assigned to this user.`}</Col>
        </Row>
      );
    }
  }

  render() {
    // if (!localStorage.getItem("currentState")) {
    //   console.log("HomePage");
    //   window.location = "/login";
    //   return;
    // }
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    let dodState = false;
    if (currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE") {
      dodState = true;
    }
    const { expandedWidgets, isSomeWidgetExpanded, agreements, currentAgreementsTab } = this.state;
    const { permissions } = this.props;
    return (
      <div className={styles.HomePage} data-testid="HomePage">
        <Row className="mt-4">
          <Col>
            <p className={globalStyles.pageTitle} data-testid="dashboardTitle">
              Dashboard
            </p>
          </Col>

          <Col>
            <DropdownButton className={styles.createEntityLinks} title="Create">
              {permissions.canICreateAgreements() && (
                <Dropdown.Item
                  eventKey="Agreement"
                  onClick={() =>
                    this.props.history.push({ pathname: "/agreement/create", state: { entityType: "Agreement" } })
                  }
                >
                  Agreement
                </Dropdown.Item>
              )}
              {permissions.canICreateAdminWork() && (
                <Dropdown.Item
                  eventKey="AdminWorkTask"
                  onClick={() => {
                    this.setState({ showAdminWorkTaskModal: true });
                  }}
                >
                  Admin Work Task
                </Dropdown.Item>
              )}
              {permissions.canICreateAerialWorkTask() && (
                <Dropdown.Item
                  eventKey="AerialWorkTasks"
                  onClick={() => this.props.history.push({ pathname: "/aerialworktask/create" })}
                >
                  Aerial Work Task
                </Dropdown.Item>
              )}
              {permissions.canICreateContacts() && (
                <Dropdown.Item onClick={() => this.props.history.push("/contact/create")}>Contact</Dropdown.Item>
              )}
              {permissions.canICreateConflicts() && (
                <Dropdown.Item onClick={() => this.setState({ showCreateConflictModal: true })}>Conflict</Dropdown.Item>
              )}
              {permissions.canICreateDistrictsForAssignedStates() &&
                permissions.canIAssignDistrictSupervisorsForAssignedStates() && (
                  <Dropdown.Item onClick={() => this.setState({ showCreateDistrictModal: true })}>
                    District
                  </Dropdown.Item>
                )}
              {permissions.canICreateAgreements() && (
                <Dropdown.Item
                  eventKey="Agreement"
                  onClick={() =>
                    this.props.history.push({ pathname: "/agreement/create", state: { entityType: "FLA" } })
                  }
                >
                  Federal Land
                </Dropdown.Item>
              )}
              {permissions.canICreateProperties() && !dodState && (
                <Dropdown.Item onClick={() => this.props.history.push("/property/createparcel")}>
                  Parcel Property
                </Dropdown.Item>
              )}
              {permissions.canICreateProperties() && (
                <Dropdown.Item onClick={() => this.props.history.push("/property/create")}>
                  Non-Parcel Property
                </Dropdown.Item>
              )}
              {permissions.canICreateProjectsAnyStates() && (
                <Dropdown.Item onClick={() => this.setState({ showCreateProjectModal: true })}>Project</Dropdown.Item>
              )}
              {permissions.canICreateTaWorkTasks() && (
                <Dropdown.Item onClick={() => this.setState({ showOutreachTAWorkTaskModal: true })}>
                  Public Outreach TA Work Task
                </Dropdown.Item>
              )}
              {permissions.canICreateTaWorkTasks() && (
                <Dropdown.Item onClick={() => this.setState({ showTAWorkTaskModal: true })}>TA Work Task</Dropdown.Item>
              )}
              {permissions.canICreateTaWorkTasks() && (
                <Dropdown.Item onClick={() => this.setState({ showHotlineTAWorkTaskModal: true })}>
                  Hotline TA Work Task
                </Dropdown.Item>
              )}
              {permissions.canICreatePermitsAssignedStates() && (
                <Dropdown.Item onClick={() => this.setState({ showCreatePermitModal: true })}>Permit</Dropdown.Item>
              )}
              {permissions.canICreateWarehouses() && (
                <Dropdown.Item onClick={() => this.setState({ showCreateWarehouseModal: true })}>
                  Warehouse
                </Dropdown.Item>
              )}
            </DropdownButton>
          </Col>
        </Row>
        <Row className="mt-3">
          {permissions.canICreateAgreements() ? (
            <Col
              md={expandedWidgets["myAgreements"] ? 12 : 8}
              hidden={(!expandedWidgets["myAgreements"] && isSomeWidgetExpanded) || !permissions.canIViewAgreements()}
            >
              <Card className={expandedWidgets["myAgreements"] ? styles.expandedCards : styles.bigCards}>
                <Card.Body style={{ overflow: "auto" }}>
                  <div>
                    <Row>
                      <Col className={`text-left pl-0 ${expandedWidgets["myAgreements"] ? "pr-0" : ""}`}>
                        <span className={styles.cardTitleText}>My Agreements</span>
                      </Col>
                      <Col className="text-right pr-0">
                        <Image
                          name="myAgreements"
                          aria-label="Expand My Agreements Card"
                          src={expandedWidgets["myAgreements"] ? collapseIcon : expandIcon}
                          onClick={(e) => this.handleWidgetExpand(e)}
                        />
                      </Col>
                    </Row>
                    {permissions.canIViewAgreements() && (
                      <Row className="mt-3">
                        <Col className="text-left">
                          <Tabs
                            id="myAgreementsTabs"
                            onSelect={(e) => this.handleTabSelect(e)}
                            className={styles.myAGreementsTabs}
                            defaultActiveKey={this.state.currentAgreementsTab}
                          >
                            <Tab
                              eventKey={MyAgreementsTabs.Active}
                              title={`Active${currentAgreementsTab === MyAgreementsTabs.Active ? ` (${agreements.length})` : ""
                                }`}
                              className="mt-4"
                            >
                              {this.state.isAgreementDataLoading ? (
                                <div style={{ textAlign: "center" }}>
                                  <Spinner animation="border" className="mt-4" role="status">
                                    <span className="visually-hidden" aria-label="Loading..."></span>
                                  </Spinner>
                                </div>
                              ) : (
                                this.renderAgreementsTab(agreements, AgreementsStatuses.Active)
                              )}
                            </Tab>
                            <Tab
                              eventKey={MyAgreementsTabs.Pending}
                              title={`Pending${currentAgreementsTab === MyAgreementsTabs.Pending ? ` (${agreements.length})` : ""
                                }`}
                              className="mt-4"
                            >
                              {this.state.isAgreementDataLoading ? (
                                <div style={{ textAlign: "center" }}>
                                  <Spinner animation="border" className="mt-4" role="status">
                                    <span className="visually-hidden" aria-label="Loading..."></span>
                                  </Spinner>
                                </div>
                              ) : (
                                this.renderAgreementsTab(agreements, AgreementsStatuses.Pending)
                              )}
                            </Tab>
                            <Tab
                              eventKey={MyAgreementsTabs.Draft}
                              title={`Draft${currentAgreementsTab === MyAgreementsTabs.Draft ? ` (${agreements.length})` : ""
                                }`}
                              className="mt-4"
                            >
                              {this.state.isAgreementDataLoading ? (
                                <div style={{ textAlign: "center" }}>
                                  <Spinner animation="border" className="mt-4" role="status">
                                    <span className="visually-hidden" aria-label="Loading..."></span>
                                  </Spinner>
                                </div>
                              ) : (
                                this.renderAgreementsTab(agreements, AgreementsStatuses.Draft)
                              )}
                            </Tab>
                          </Tabs>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ) : permissions.canICreatePVS() ? (
            <Col hidden={!expandedWidgets["recentProperties"] && isSomeWidgetExpanded}>
              <RecentPropertiesWidget
                isExpanded={expandedWidgets["recentProperties"]}
                collapseIcon={collapseIcon}
                expandIcon={expandIcon}
                onExpand={this.handleWidgetExpand}
                usaStates={this.state.usaStates}
                countries={this.state.countries}
              />
            </Col>
          ) : (
            []
          )}
          <Col
            className={expandedWidgets["myNotifications"]}
            md={expandedWidgets["myNotifications"] ? 12 : 4}
            hidden={!expandedWidgets["myNotifications"] && isSomeWidgetExpanded}
          >
            <NotificationWidget
              isExpanded={expandedWidgets["myNotifications"]}
              collapseIcon={collapseIcon}
              expandIcon={expandIcon}
              onExpand={this.handleWidgetExpand}
              onNavigate={(linkedAgreementUniqueReference) => {
                const navigationObject = {
                  pathname: "/agreement/details",
                  state: {
                    states: this.state.usaStates,
                    countries: this.state.countries,
                    entityData: { agreementUniqueIdentifier: linkedAgreementUniqueReference },
                  },
                };
                this.props.history.push(navigationObject);
              }}
            />
          </Col>
        </Row>
        {permissions.canICreateAgreements() && permissions.canICreatePVS() && (
          <Row className={expandedWidgets["recentProperties"] ? "" : "mt-4"}>
            <Col hidden={!expandedWidgets["recentProperties"] && isSomeWidgetExpanded}>
              <RecentPropertiesWidget
                isExpanded={expandedWidgets["recentProperties"]}
                collapseIcon={collapseIcon}
                expandIcon={expandIcon}
                onExpand={this.handleWidgetExpand}
                usaStates={this.state.usaStates}
                countries={this.state.countries}
              />
            </Col>
          </Row>
        )}
        {permissions.canIApproveAgreementsForAssignedStates() && (
          <Row className={expandedWidgets["approvalRequest"] ? "" : "mt-4"}>
            <Col hidden={!expandedWidgets["approvalRequest"] && isSomeWidgetExpanded}>
              <ApprovalRequestWidget
                isExpanded={expandedWidgets["approvalRequest"]}
                collapseIcon={collapseIcon}
                expandIcon={expandIcon}
                onExpand={this.handleWidgetExpand}
              />
            </Col>
          </Row>
        )}
        {this.state.showAdminWorkTaskModal && (
          <AdminWorkTaskModal
            show={this.state.showAdminWorkTaskModal}
            onHide={this.closeAdminWorkTaskModal}
            onSubmitTask={this.saveAdminWorkTask}
          />
        )}
        <CreateConflictModal
          isModal={true}
          show={this.state.showCreateConflictModal}
          onHide={this.onCreateConflictModalClose}
        />
        <CreateDistrictModal
          show={this.state.showCreateDistrictModal}
          onHide={this.onCreateDistrictModalClose}
          onCreateDistrict={this.handleCreateDistrict}
        />
        <TAWorkTaskModalWithTopics
          show={this.state.showTAWorkTaskModal}
          onHide={this.closeTAWorkTaskModal}
          isCooperator={true}
          states={this.state.usaStates}
          currentUserProfile={this.props.currentUserProfile}
          permissions={permissions}
        />
        <TAWorkTaskModalWithTopics
          show={this.state.showHotlineTAWorkTaskModal}
          onHide={this.closeHotlineTAWorkTaskModal}
          isHotline={true}
          states={this.state.usaStates}
          permissions={permissions}
        />
        <TAWorkTaskModalWithTopics
          show={this.state.showOutreachTAWorkTaskModal}
          onHide={this.closeOutreachTAWorkTaskModal}
          states={this.state.usaStates}
          permissions={permissions}
        />
        <CreatePermitModal
          show={this.state.showCreatePermitModal}
          onHide={this.closeCreatePermitModal}
          states={this.state.usaStates}
          currentUsaState={JSON.parse(localStorage.getItem("currentState"))}
        />
        <SuccessfulRecordModifiedModal
          show={this.state.showHappyModal}
          onHide={() => {
            this.setState({ showHappyModal: false });
          }}
          messageType={this.state.happyModalMessageType}
          objectName={this.state.happyModalObjectType}
          onAcknowledgeRoute={this.state.happyModalOnAcknowledgeRoute}
          routeData={this.state.happyModalRouteData}
        />
        <CreateProjectModal
          show={this.state.showCreateProjectModal}
          onHide={this.closeCreateProjectModal}
          onCreateProject={this.handleCreateProject}
          availableStates={this.state.availableStatesForProfile}
        />
        <CreateWarehouseModal
          show={this.state.showCreateWarehouseModal}
          onHide={this.closeCreateWarehouseModal}
          onCreateWarehouse={this.handleCreateWarehouse}
          availableStates={this.state.availableStatesForProfile}
        />
        <div id="pdfDocument" className={styles.contentToPrint}>
          <PrintAgreementForm
            agreementData={this.state.agreementData}
            cooperatorData={this.state.cooperatorData}
            primaryFieldAgentData={this.state.agreementData?.primaryFieldAgent}
            propertiesData={this.state.propertiesData}
            associatedContactsData={this.state.associatedContactsData}
            states={this.state.usaStates}
            countries={this.state.countries}
            ref={(el) => (this.componentRef = el)}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(HomePage);
