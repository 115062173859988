import React from "react";
import styles from "./TAWorkTaskModal.module.scss";
import globalStyles from "../../../OARS.module.scss";
import { Modal, Row, Col, Form, Button, Tabs, Tab } from "react-bootstrap";
import CustomForm from "../../common/form";
import UtilityFunctions from "../../common/UtilityFunctions";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { components } from "react-select";
import searchIcon from "../../../assets/search.svg";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../../api/AgreementEntities/AgreementAPI";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import ConflictsAPI from "../../../api/Conflicts/ConflictsAPI";
import WorkTaskAPI from "../../../api/WorkTask/WorkTaskAPI";
import RoleAuthorizationAPI from "../../../api/RoleAuthorization/RoleAuthorizationAPI";
import InventoryAPI from "../../../api/Inventory/InventoryAPI";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let phoneNumber = "No Phone Number";
  let option = props.data;
  if (option.phoneNumbers?.length > 0) {
    phoneNumber = option.phoneNumbers[0]?.phoneNumber;
  }
  if (props.selectProps["aria-label"] === "Cooperator Search") {
    return (
      <components.Option {...props} className={styles.informationRow}>
        <Row align="left">
          <Col>
            <p className={styles.informationTextTypeahead} style={{ fontWeight: "600" }}>
              {option.label}
            </p>
          </Col>
        </Row>
        <Row align="left">
          <Col>
            <p className={styles.informationTextTypeahead}>{phoneNumber}</p>
          </Col>
        </Row>
        <Row align="left">
          <Col>
            <p className={styles.informationTextTypeahead}>{option.emailAddress ? option.emailAddress : "No Email"}</p>
          </Col>
        </Row>
        <Row align="left">
          <Col>
            <p className={styles.informationTextTypeahead}>{option.formattedAddress}</p>
          </Col>
        </Row>
      </components.Option>
    );
  } else if (props.selectProps["aria-label"] === "Search For User Profiles") {
    return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
  }
};

const TechnicalAssistActivityTypes = {
  Cooperator: 1,
  PublicOutreach: 2,
  Hotline: 3,
};

const WarehouseTransactionTypes = {
  IncomingTransfer: 1,
  OutgoingTransfer: 2,
  Receive: 3,
  Sell: 4,
  Loss: 5,
  Mixing: 7,
  Reconciliation: 9,
};

class TAWorkTaskModalWithTopics extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        date: "",
        agreement: "",
        agreementName: "",
        city: "",
        county: "",
        countyName: "",
        stateName: "",
        state: "",
        hours: "0",
        minutes: "0",
        propertyName: "",
        property: "",
        miles: "",
        numLeaflets: "",
        numParticipants: "",
        selectedActivity: [],
        comments: "",
        selectedRecommendations: [],
        selectedCooperatorEmployedWork: [],
        selectedMethod: "",
        selectedUom: "",
        selectedConflict: "",
        methodQuantity: "",
        referredFromSelection: "",
        referredToSelection: "",
        selectedProjects: [],
        permitActionsRadio: "No",
        permitAction: "",
        permitRecommendationType: "",
        permitNumberFreeText: "",
        methodTable: [],
        propertyVisitSummary: "",
        selectedSpecies: [],
        speciesNameSearch: "",
        speciesClassesSearch: "",
        speciesOrdersSearch: "",
        speciesFamiliesSearch: "",
        speciesCategoriesSearch: "",
        selectedResources: [],
        resourceNameSearch: "",
        resourceMainCategorySearch: "",
        resourceSubCategory1Search: "",
        resourceSubCategory2Search: "",
        selectedGeneralTopics: [],
        performedBy: "",
        selectedWarehouse: "",
      },
      allResources: [],
      allResourcesOriginalData: [],
      resourceMainCategories: [],
      resourceSubCategories1: [],
      resourceSubCategories2: [],
      allSpecies: [],
      allSpeciesOriginalData: [],
      speciesClasses: [],
      speciesOrders: [],
      speciesFamilies: [],
      speciesCategories: [],
      allGeneralTopics: [],
      isInitialPage: true,
      isAdditionalSpeciesSearch: false,
      states: [],
      counties: [],
      activities: [],
      conflicts: [],
      recommendations: [],
      methods: [],
      methodUoms: [],
      referredList: [],
      projects: [],
      permitRecommendationTypes: [],
      permitActions: [],
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
      availableWarehouses: [],
      errors: {},
    };
  }

  checkIfModalIsNotHotlineAndNotCooperator() {
    return !this.props.isHotline && !this.props.isCooperator;
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    const showingModal = this.props.show && prevProps.show !== this.props.show;
    if (showingModal && this.checkIfModalIsNotHotlineAndNotCooperator()) {
      data.state = this.state.currentUsaState.stateGuid;
      this.setState({
        data,
        activities: await this.getAllTechnicalAssistActivitesForType(TechnicalAssistActivityTypes.PublicOutreach),
        counties: await this.getCountyListbyState(this.state.currentUsaState.stateGuid),
        recommendations: await this.getRecCemsForState(this.state.currentUsaState.stateGuid),
        referredList: await this.getExternalOrganizationsForState(this.state.currentUsaState.stateGuid),
        projects: await this.getAllProjectsDataFromAPI(this.state.currentUsaState.stateGuid),
      });
    } else if (showingModal && this.props.isHotline) {
      data.state = this.state.currentUsaState.stateGuid;
      this.setState({
        data,
        activities: await this.getAllTechnicalAssistActivitesForType(TechnicalAssistActivityTypes.Hotline),
        counties: await this.getCountyListbyState(this.state.currentUsaState.stateGuid),
        recommendations: await this.getRecCemsForState(this.state.currentUsaState.stateGuid),
        referredList: await this.getExternalOrganizationsForState(this.state.currentUsaState.stateGuid),
        projects: await this.getAllProjectsDataFromAPI(this.state.currentUsaState.stateGuid),
        permitRecommendationTypes: await this.getPermitRecommendationTypes(TechnicalAssistActivityTypes.Hotline),
        permitActions: await this.getPermitActions(TechnicalAssistActivityTypes.Hotline),
      });
    } else if (showingModal && this.props.isCooperator) {
      if (this.props.fromSiteVisit) {
        data.agreement = this.props.agreementData;
        data.agreementName = this.props.agreementData?.commonName;
        data.city = this.props.propertyData?.additionalStreetAddresses[0]?.city;
        data.county = this.props.propertyData?.county;
        data.countyName = this.props.propertyData?.county;
        let state = this.mapStateDataFromProperty();
        data.stateName = state.name;
        data.state = state.stateUniqueIdentifier ?? "";
        data.propertyName = this.props.propertyData?.propertyName;
        data.property = this.props.propertyData;
        data.propertyVisitSummary = this.props.propertyVisitSummary;
        if (this.props.propertyVisitSummary) {
          data.date = this.props.propertyVisitSummary.dateEntered.split("T")[0];
        }
        this.setState({
          conflicts: await this.getConflicts("property", this.props.propertyData.propertyUniqueIdentifier),
        });
      }
      data.state = this.state.currentUsaState.stateGuid;

      let { availableWarehouses } = this.state;
      if (data.state) {
        availableWarehouses = await this.getWarehousesData(data.state);
        if (availableWarehouses?.length === 1) {
          data.selectedWarehouse = availableWarehouses[0].warehouseUniqueIdentifier;
        }
      }

      this.setState({
        data,
        activities: await this.getAllTechnicalAssistActivitesForType(TechnicalAssistActivityTypes.Cooperator),
        counties: await this.getCountyListbyState(this.state.currentUsaState.stateGuid),
        recommendations: await this.getRecCemsForState(this.state.currentUsaState.stateGuid),
        methods: await this.getMethodsForState(this.state.currentUsaState.stateGuid),
        referredList: await this.getExternalOrganizationsForState(this.state.currentUsaState.stateGuid),
        projects: await this.getAllProjectsDataFromAPI(this.state.currentUsaState.stateGuid),
        permitRecommendationTypes: await this.getPermitRecommendationTypes(TechnicalAssistActivityTypes.Cooperator),
        permitActions: await this.getPermitActions(TechnicalAssistActivityTypes.Cooperator),
        availableWarehouses,
      });
    }
    if (showingModal) {
      await this.setGeneralTopics();
      await this.setSpecies();
      await this.setResources();
    }
  }

  mapStateDataFromProperty() {
    let foundState = this.props.states?.find(
      (state) =>
        state.id === this.props.propertyData?.state || state.stateUniqueIdentifier === this.props.propertyData?.state
    );
    if (foundState && !foundState?.name) {
      foundState.name = foundState.state_name;
    }
    return foundState;
  }

  async setGeneralTopics() {
    let { allGeneralTopics } = this.state;
    const allGeneralTopicsResponse = await ReferenceFileAPI.GetTopicsForState(this.state.currentUsaState?.stateGuid);
    if (allGeneralTopicsResponse?.successful) {
      allGeneralTopics = allGeneralTopicsResponse.data?.results?.filter((s) => s.isAllowedGlobally) ?? [];
      this.setState({ allGeneralTopics });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get general topics in state",
        apiName: "GetTopicsForState",
        responseUnsuccessful: allGeneralTopicsResponse?.unsuccessful,
        responseMessage: allGeneralTopicsResponse?.message,
      });
    }
  }

  async setSpecies() {
    let { allSpecies, allSpeciesOriginalData, speciesClasses, speciesOrders, speciesFamilies, speciesCategories } =
      this.state;
    const allSpeciesResponse = await ReferenceFileAPI.GetAllSpeciesByState(this.state.currentUsaState?.stateGuid);
    if (allSpeciesResponse?.successful) {
      allSpecies =
        allSpeciesResponse.data?.results
          ?.filter((s) => s.isAllowedForState)
          ?.map((s) => ({ ...s, isSelected: false })) ?? [];
      allSpeciesOriginalData = JSON.parse(JSON.stringify(allSpecies));
      const speciesAllCategorizationResponse = await ReferenceFileAPI.GetAllSpeciesCategorizationInfo(
        this.state.currentUsaState?.stateGuid
      );
      if (speciesAllCategorizationResponse?.successful) {
        speciesClasses = speciesAllCategorizationResponse.data?.allClasses?.map((item) => {
          return { value: item, label: item };
        });
        speciesOrders = speciesAllCategorizationResponse.data?.allOrders?.map((item) => {
          return { value: item, label: item };
        });
        speciesFamilies = speciesAllCategorizationResponse.data?.allFamilies?.map((item) => {
          return { value: item, label: item };
        });
        speciesCategories = speciesAllCategorizationResponse.data?.allSubTypes?.map((item) => {
          return { value: item, label: item };
        });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve damage agent categorization info",
          apiName: "GetAllSpeciesCategorizationInfo",
          responseUnsuccessful: speciesAllCategorizationResponse?.unsuccessful,
          responseMessage: speciesAllCategorizationResponse?.message,
        });
      }
      this.setState({
        allSpecies,
        allSpeciesOriginalData,
        speciesClasses,
        speciesOrders,
        speciesFamilies,
        speciesCategories,
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get damage agents in state",
        apiName: "GetAllSpeciesByState",
        responseUnsuccessful: allSpeciesResponse?.unsuccessful,
        responseMessage: allSpeciesResponse?.message,
      });
    }
  }

  async setResources() {
    let { allResources, allResourcesOriginalData, resourceMainCategories } = this.state;
    const allResourcesResponse = await ReferenceFileAPI.GetResourcesForState(this.state.currentUsaState?.stateGuid);
    if (allResourcesResponse?.successful && allResourcesResponse?.data?.results?.length > 0) {
      allResources = allResourcesResponse.data.results
        .filter((r) => r.isAllowed)
        .map((r) => ({ ...r, isSelected: false }));
      allResourcesOriginalData = JSON.parse(JSON.stringify(allResources));
      const resourceTypesResponse = await ReferenceFileAPI.GetResourceTypes();
      if (resourceTypesResponse?.successful && resourceTypesResponse?.data?.length > 0) {
        resourceMainCategories = resourceTypesResponse?.data?.map((type) => {
          return { value: type, label: type };
        });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve resource types",
          apiName: "GetResourceTypes",
          responseUnsuccessful: resourceTypesResponse?.unsuccessful,
          responseMessage: resourceTypesResponse?.message,
        });
      }
      this.setState({
        allResources,
        allResourcesOriginalData,
        resourceMainCategories,
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get resources in state",
        apiName: "GetResourcesForState",
        responseUnsuccessful: allResourcesResponse?.unsuccessful,
        responseMessage: allResourcesResponse?.message,
      });
    }
  }

  async getWarehousesData(stateId) {
    let warehousesData = [];
    if (this.props.currentUserProfile?.wsUserProfileUniqueIdentifier) {
      const getWarehousesResponse = await InventoryAPI.GetMyWarehousesByState(
        this.props.currentUserProfile.wsUserProfileUniqueIdentifier,
        stateId
      );
      if (!getWarehousesResponse?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve warehouses for user",
          apiName: "GetMyWarehousesByState",
          responseUnsuccessful: getWarehousesResponse?.unsuccessful,
          responseMessage: getWarehousesResponse?.message,
        });
      } else if (getWarehousesResponse.data?.results?.length > 0) {
        warehousesData = getWarehousesResponse.data.results
          .filter((w) => w.isActive)
          ?.map((w) => ({
            value: w.warehouseUniqueIdentifier,
            label: w.warehouseName,
            ...w,
          }));
      }
    }
    return warehousesData;
  }

  async getAllTechnicalAssistActivitesForType(type) {
    const activities = await ReferenceFileAPI.GetAllTechnicalAssistActivitiesForTaType(type);
    let activityList = [];
    if (activities?.successful) {
      const activitiesData = activities.data;
      for (let i = 0; i < activitiesData.length; i++) {
        activityList.push({
          value: activitiesData[i].technicalAssistActivityUniqueIdentifier,
          label: activitiesData[i].activityName,
        });
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve technical assist activities",
        apiName: "GetAllTechnicalAssistActivitiesForTaType",
        responseUnsuccessful: activities?.unsuccessful,
        responseMessage: activities?.message,
      });
    }
    return activityList;
  }

  async getConflicts(type, guid) {
    const conflicts =
      type === "state"
        ? await ConflictsAPI.GetConflictsForState(guid)
        : await ConflictsAPI.GetConflictsByProperty(guid);
    let conflictList = [];
    if (conflicts?.successful) {
      const conflictsData = conflicts.data.results;
      for (let i = 0; i < conflictsData.length; i++) {
        conflictList.push({
          value: conflictsData[i].conflictUniqueIdentifier,
          label: conflictsData[i].targetedSpeciesName,
        });
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve conflicts",
        apiName: type === "state" ? "GetConflictsForState" : "GetConflictsByProperty",
        responseUnsuccessful: conflicts?.unsuccessful,
        responseMessage: conflicts?.message,
      });
    }
    return conflictList;
  }

  async getRecCemsForState(stateGuid) {
    const recCems = await ReferenceFileAPI.GetAllRecCems(stateGuid);
    let recCemList = [];
    if (recCems?.successful) {
      const recCemsData = recCems.data?.results;
      for (let i = 0; i < recCemsData.length; i++) {
        if (recCemsData[i].isAllowedInState) {
          recCemList.push({
            value: recCemsData[i].recCemUniqueIdentifier,
            label: recCemsData[i].recCemName,
          });
        }
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve RecCems",
        apiName: "GetAllRecCems",
        responseUnsuccessful: recCems?.unsuccessful,
        responseMessage: recCems?.message,
      });
    }
    return recCemList;
  }

  handleCooperatorSearch = async (inputValue) => {
    return await this.getContactsFromAPI(inputValue);
  };

  async getContactsFromAPI(searchValue) {
    const { currentUsaState } = this.state;
    let outputContacts = [];
    let searchObj = {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    };
    if (currentUsaState?.stateGuid) {
      const contactsData = await AgreementEntitiesAPI.SearchContactFreeText(searchObj);
      if (contactsData?.data?.results) {
        let results = contactsData.data.results;
        let address = "No Address";
        outputContacts = results.map((result) => {
          if (
            result.primaryMailingAddress &&
            (result.primaryMailingAddress.mailingStreet ||
              result.primaryMailingAddress.mailingCity ||
              result.primaryMailingAddress.mailingZip)
          ) {
            let state = "";
            let states = this.props.states;
            for (let i = 0; i < states.length; i++) {
              if (states[i].id === result.primaryMailingAddress.mailingStateUniqueId) {
                state = states[i].name;
              }
            }
            address =
              result.primaryMailingAddress.mailingStreet +
              ", " +
              result.primaryMailingAddress.mailingCity +
              " " +
              state +
              " " +
              result.primaryMailingAddress.mailingZip;
          }

          return {
            value: result.contactUniqueId,
            label: result.firstName + " " + result.middleName + " " + result.lastName,
            firstName: result.firstName,
            middleName: result.middleName,
            lastName: result.lastName,
            contactUniqueIdentifier: result.contactUniqueId,
            contactGuid: result.contactUniqueId,
            emailAddress: result.emailAddress,
            phoneNumbers: result.phoneNumbers.map((phoneNumber) => {
              phoneNumber.phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber);
              return phoneNumber;
            }),
            primaryMailingAddress: result.primaryMailingAddress,
            formattedAddress: address,
          };
        });
      }
    }

    return outputContacts;
  }

  async getExternalOrganizationsForState(stateGuid) {
    const orgs = await ReferenceFileAPI.GetAllExternalOrganizationsInState(stateGuid);
    let orgsList = [];
    if (orgs?.successful) {
      const orgssData = orgs.data?.results;
      for (let i = 0; i < orgssData.length; i++) {
        if (orgssData[i].isAllowedInState) {
          orgsList.push({
            id: orgssData[i].externalOrganizationUniqueIdentifier,
            name: orgssData[i].externalOrganizationName,
          });
        }
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve external organizations",
        apiName: "GetAllExternalOrganizationsInState",
        responseUnsuccessful: orgs?.unsuccessful,
        responseMessage: orgs?.message,
      });
    }
    return orgsList;
  }

  async getMethodsForState(stateGuid) {
    const allMethodsResponse = await ReferenceFileAPI.GetAllMethodsByState(stateGuid);
    let methodList = [];
    if (allMethodsResponse?.successful) {
      const methodData = allMethodsResponse.data?.results;
      for (let i = 0; i < methodData.length; i++) {
        if (methodData[i].isAllowedInState) {
          methodList.push({
            value: methodData[i].methodUniqueIdentifier,
            label: methodData[i].methodName,
            ...methodData[i],
          });
        }
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve methods",
        apiName: "GetAllMethodsByState",
        responseUnsuccessful: allMethodsResponse?.unsuccessful,
        responseMessage: allMethodsResponse?.message,
      });
    }
    return methodList;
  }

  async getCountyListbyState(stateGuid) {
    const counties = await ReferenceFileAPI.GetCountiesByState(stateGuid);
    let countyList = [];
    if (counties?.length > 0) {
      countyList = counties
        .map((county) => ({
          id: county.countyUniqueIdentifier,
          name: county.county_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    } else {
      toast.error("Could not retrieve Counties");
    }
    return countyList;
  }

  async getAllProjectsDataFromAPI(stateGuid) {
    let projectsData = [];
    const projectsDataResponse = await AgreementEntitiesAPI.GetMyAssociatedProjectsForState(stateGuid);
    if (projectsDataResponse?.successful) {
      projectsData = projectsDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve user's associated projects",
        apiName: "GetMyAssociatedProjectsForState",
        responseUnsuccessful: projectsDataResponse?.unsuccessful,
        responseMessage: projectsDataResponse?.message,
      });
    }
    let projectsArray = [];
    projectsData.forEach((project) => {
      let startDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.startDate);
      let endDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.endDate);
      let currentDate = new Date();
      if (currentDate >= startDate && currentDate < endDate) {
        projectsArray.push({ value: project.projectUniqueIdentifier, label: project.projectName });
      }
    });
    return projectsArray;
  }

  async getPermitRecommendationTypes(type) {
    let permitRecommendationTypesData = [];

    const permitRecommendationTypesDataResponse =
      type === TechnicalAssistActivityTypes.Hotline
        ? await WorkTaskAPI.GetHotlinePermitRecommendationTypes()
        : await WorkTaskAPI.GetIndividualCooperatorPermitRecommendationTypes();
    if (permitRecommendationTypesDataResponse?.successful) {
      permitRecommendationTypesData = permitRecommendationTypesDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve permit recommendation types",
        apiName:
          type === TechnicalAssistActivityTypes.Hotline
            ? "GetHotlinePermitRecommendationTypes"
            : "GetIndividualCooperatorPermitRecommendationTypes",
        responseUnsuccessful: permitRecommendationTypesDataResponse?.unsuccessful,
        responseMessage: permitRecommendationTypesDataResponse?.message,
      });
    }
    return permitRecommendationTypesData.map((permitType) => {
      return { id: permitType.id, name: permitType.displayText };
    });
  }

  async getPermitActions(type) {
    let permitActionsData = [];

    const permitActionsDataResponse =
      type === TechnicalAssistActivityTypes.Hotline
        ? await WorkTaskAPI.GetHotlinePermitActions()
        : await WorkTaskAPI.GetIndividualCooperatorPermitActions();
    if (permitActionsDataResponse?.successful) {
      permitActionsData = permitActionsDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve permit actions",
        apiName:
          type === TechnicalAssistActivityTypes.Hotline
            ? "GetHotlinePermitActions"
            : "GetIndividualCooperatorPermitActions",
        responseUnsuccessful: permitActionsDataResponse?.unsuccessful,
        responseMessage: permitActionsDataResponse?.message,
      });
    }
    return permitActionsData.map((permitType) => {
      return { id: permitType.id, name: permitType.displayText };
    });
  }

  handleAgreementsSearch = async (inputValue) => {
    return await this.getAgreementsFromAPI(inputValue);
  };

  async getAgreementsFromAPI(searchValue) {
    const { currentUsaState } = this.state;
    let searchResults = "";

    if (currentUsaState?.stateGuid) {
      const agreementsDataResponse = await AgreementAPI.SearchAgreementFreeText(
        this.getSearchEntitiesBody(searchValue),
        currentUsaState.stateGuid
      );

      if (agreementsDataResponse?.data?.results) {
        searchResults = agreementsDataResponse.data.results.map((result) => ({
          value: result.agreementUniqueIdentifier,
          label: result.commonName,
        }));
      }
    }

    return searchResults;
  }

  getSearchEntitiesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  getPropertiesFromAPI = async (searchValue) => {
    let searchObj = {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };

    if (this.state.currentUsaState?.stateGuid) {
      const propertiesData = await AgreementEntitiesAPI.SearchPropertiesFreeText(
        searchObj,
        this.state.currentUsaState.stateGuid
      );

      if (propertiesData?.data?.results) {
        let results = propertiesData.data.results;
        return results.map((result) => ({
          value: result.propertyUniqueIdentifier,
          label: result.propertyName,
        }));
      }
    }
    return "";
  };

  searchMatchingUsers = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
    const searchUserResponse = await RoleAuthorizationAPI.SearchUsersFreeText(searchObj);
    let outputUsers = [];
    if (searchUserResponse?.data?.results?.length > 0) {
      let results = searchUserResponse.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
            };
            outputUsers.push(userObject);
          }
        }
      }
    }
    return outputUsers;
  };

  handleSpecificChanges = async (input) => {
    const { data } = this.state;
    if (input.name === "state") {
      data.county = "";
      data.countyName = "";
      data.state = input.value;
      this.setState({ data, counties: await this.getCountyListbyState(input.value) });
    }
    if (input.name === "county") {
      data.countyName = this.state.counties.find((c) => c.id === input.value)?.name;
      data.county = input.value;
      this.setState({ data });
    }
  };

  handleSelectChanges = async (e, type) => {
    const { data } = this.state;
    data[type] = e ? e : "";
    if (type === "property" && this.props.isCooperator) {
      this.setState({ conflicts: e ? await this.getConflicts("property", e?.value) : "" });
    }
    if (type === "agreement" && !e) {
      data.property = "";
      data.selectedConflict = "";
    }
    if (type === "property" && !e) {
      data.selectedConflict = "";
    }
    this.setState({ data });
  };

  handleActivitySelection = (e) => {
    const { data } = this.state;
    data.selectedActivity = e;
    this.setState({ data });
  };

  handleConflictSelection = (e) => {
    const { data } = this.state;
    data.selectedConflict = e;
    this.setState({ data });
  };

  handleCommentsChange = (e) => {
    const { data } = this.state;
    data.comments = e.target.value;
    this.setState({ data });
  };

  handleRecommendationSelection = (e) => {
    const { data } = this.state;
    data.selectedRecommendations = e;
    this.setState({ data });
  };

  handleCooperatorEmployedWorkSelection = (e) => {
    const { data } = this.state;
    data.selectedCooperatorEmployedWork = e;
    this.setState({ data });
  };

  handleMethodSelection = (e) => {
    const { data } = this.state;
    if (
      (e?.isCMITSMethod || e?.isCMITSMethodGlobally) &&
      !this.props.permissions.canIWorktaskInteractionAssignedWarehouse()
    ) {
      toast.warning(
        "This is CMITS method and your role does not have permission to make warehouse transactions from work tasks"
      );
      return;
    }
    data.selectedMethod = e;
    data.selectedWarehouse = "";
    data.selectedUom = "";
    this.setState({ data }, () => {
      this.setState({
        methodUoms: e?.allowedUOMsInState?.map((uom) => {
          return { value: uom.id, label: uom.displayText };
        }),
      });
    });
  };

  handleUomSelection = (e) => {
    const { data } = this.state;
    let isUomValidInWarehouse = true;
    if (data.selectedWarehouse) {
      const warehouseMethod = data.selectedWarehouse?.warehouseInventory?.find(
        (m) => m.methodUniqueReference === data.selectedMethod?.methodUniqueIdentifier
      );
      if (warehouseMethod?.methodUOM?.id !== e.value) {
        toast.warning("This UOM is not valid for the selected method in the specified warehouse.");
        isUomValidInWarehouse = false;
      }
    }

    if (isUomValidInWarehouse) {
      data.selectedUom = e;
      this.setState({ data });
    }
  };

  handleCooperatorSelection = (e) => {
    const { data } = this.state;
    data.selectedCooperator = e;
    this.setState({ data });
  };

  handleProjectSelection = (e) => {
    const { data } = this.state;
    data.selectedProjects = e;
    this.setState({ data });
  };

  handleAddClick = () => {
    const { data } = this.state;
    if (this.isMethodDataValid()) {
      data.methodTable.push({
        method: data.selectedMethod,
        uom: data.selectedUom,
        quantity: data.methodQuantity,
        warehouse: data.selectedWarehouse,
      });
      data.selectedMethod = "";
      data.selectedWarehouse = "";
      data.selectedUom = "";
      data.methodQuantity = "";
      this.setState({ data });
    } else if (!(data.selectedMethod && data.selectedUom && data.methodQuantity)) {
      toast.warning("A method, quantity and UOM must be selected.");
    } else {
      toast.warning("A warehouse must be selected for CMITS methods.");
    }
  };

  handleModalCancel = (success) => {
    let clearedData = {
      date: "",
      agreement: "",
      agreementName: "",
      city: "",
      county: "",
      countyName: "",
      stateName: "",
      state: "",
      hours: "0",
      minutes: "0",
      propertyName: "",
      property: "",
      miles: "",
      selectedActivity: [],
      selectedRecommendations: [],
      selectedCooperatorEmployedWork: [],
      selectedMethod: "",
      selectedUom: "",
      methodQuantity: "",
      referredFromSelection: "",
      referredToSelection: "",
      selectedProjects: [],
      permitActionsRadio: "No",
      permitAction: "",
      permitRecommendationType: "",
      permitNumberFreeText: "",
      methodTable: [],
      numLeaflets: "",
      numParticipants: "",
      selectedGeneralTopics: [],
      selectedResources: [],
      selectedSpecies: [],
    };
    this.setState({ data: clearedData });
    this.props.onHide(success);
  };

  validateData() {
    const { data } = this.state;
    if (
      data.permitActionsRadio === "No" &&
      data.date &&
      data.county &&
      data.countyName &&
      data.selectedActivity.length > 0
    ) {
      return true;
    } else if (
      data.permitActionsRadio === "Yes" &&
      data.date &&
      data.county &&
      data.countyName &&
      data.selectedActivity.length > 0 &&
      data.permitNumberFreeText &&
      data.permitRecommendationType &&
      data.permitAction
    ) {
      return true;
    } else {
      return false;
    }
  }

  constructPOCreateObject() {
    const { data } = this.state;
    return {
      datePerformed: new Date(data.date.split(" ")[0]).toISOString(),
      stateUniqueReference: data.state,
      city: data.city,
      county: data.countyName,
      hoursWorked: parseInt(data.hours),
      minutesWorked: parseInt(data.minutes),
      numberOfParticipants: parseInt(data.numParticipants),
      workTaskActivities: data.selectedActivity?.map((activity) => {
        return { technicalAssistActivityUniqueReference: activity.value };
      }),
      referredFromExternalOrganizationUniqueReference: data.referredFromSelection ? data.referredFromSelection : null,
      referredToExternalOrganizationUniqueReference: data.referredToSelection ? data.referredToSelection : null,
      recommendations: data.selectedRecommendations?.map((rec) => {
        return { recommendationUniqueReference: rec.value };
      }),
      projectUniqueReferences: data.selectedProjects.map((project) => {
        return project.value;
      }),
      projects: data.selectedProjects.map((project) => {
        return {
          projectUniqueReference: project.value,
        };
      }),
      generalTopics: data.selectedGeneralTopics.map((topic) => {
        return { topicUniqueReference: topic.topicUniqueIdentifier };
      }),
      speciesAddressed: data.selectedSpecies.map((s) => {
        return { speciesUniqueReference: s.speciesUniqueIdentifier };
      }),
      resourcesAddressed: data.selectedResources.map((r) => {
        return { resourceUniqueReference: r.resourceUniqueIdentifier };
      }),
      performedByWSUserProfileUniqueReference: data.performedBy ? data.performedBy.value : null,
    };
  }

  validatePOSubmit() {
    const { data } = this.state;
    return data.county && data.date && data.hours && data.selectedActivity.length > 0 && data.numParticipants;
  }

  handlePOSubmit = async () => {
    const { data } = this.state;
    if (this.validatePOSubmit()) {
      let createObject = this.constructPOCreateObject();
      if (data.numLeaflets) {
        createObject.numberOfLeafletsDistributed = parseInt(data.numLeaflets);
      }
      const createOutreachTA = await WorkTaskAPI.CreateOutreachTaWorkTask(createObject);
      if (createOutreachTA?.successful) {
        toast.success("Successfully created Public Outreach TA Work Task");
        this.handleModalCancel();
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create ta work task",
          apiName: "CreateOutreachTaWorkTask",
          responseUnsuccessful: createOutreachTA?.unsuccessful,
          responseMessage: createOutreachTA?.message,
        });
      }
    } else {
      let date = data.date ? "" : "Date, ";
      let time = (data.hours === "" || data.hours === "0") && data.minutes === "0" ? "" : "Hours or Minutes, ";
      let participants = data.numParticipants ? "" : "# of Participants, ";
      let act = data.selectedActivity.length > 0 ? "" : "Activities, ";
      if (data.county) {
        toast.error(("Missing required fields: " + date + time + participants + act).slice(0, -2));
      } else {
        toast.error(("Missing required fields: County, " + date + time + participants + act).slice(0, -2));
      }
    }
  };

  constructSubmitTAObject() {
    const { data } = this.state;
    return {
      datePerformed: new Date(data.date.split(" ")[0]).toISOString(),
      cooperatorContactUniqueReference: data.selectedCooperator?.contactUniqueIdentifier,
      agreementUniqueReference: data.agreement?.agreementUniqueIdentifier
        ? data.agreement?.agreementUniqueIdentifier
        : data.agreement?.value,
      city: data.city,
      county: data.countyName,
      stateUniqueReference: data.state,
      workTaskActivities: data.selectedActivity?.map((act) => {
        return this.props.isHotline
          ? { technicalAssistActivityUniqueReference: act.value }
          : { workTaskActivityUniqueReference: act.value };
      }),
      recommendations: data.selectedRecommendations?.map((rec) => {
        return { recommendationUniqueReference: rec.value };
      }),
      cooperatorEmployedWork: data.selectedCooperatorEmployedWork?.map((rec) => {
        return { recommendationUniqueReference: rec.value };
      }),
      projectUniqueReferences: data.selectedProjects.map((project) => {
        return project.value;
      }),
      projects: data.selectedProjects.map((project) => {
        return {
          projectUniqueReference: project.value,
        };
      }),
      generalTopics: data.selectedGeneralTopics.map((topic) => {
        return { topicUniqueReference: topic.topicUniqueIdentifier };
      }),
      speciesAddressed: data.selectedSpecies.map((s) => {
        return { speciesUniqueReference: s.speciesUniqueIdentifier };
      }),
      resourcesAddressed: data.selectedResources.map((r) => {
        return { resourceUniqueReference: r.resourceUniqueIdentifier };
      }),
      comments: data.comments,
      performedByWSUserProfileUniqueReference: data.performedBy ? data.performedBy.value : null,
    };
  }

  addAdditionalFieldsToSubmitTAObject(obj) {
    const { data } = this.state;
    if (data.methodTable.length > 0) {
      obj.itemsSuppliedToCooperator = data.methodTable?.map((item) => {
        return {
          methodUniqueReference: item.method.value,
          unitOfMeasureEnumId: item.uom.value,
          quantity: parseInt(item.quantity),
        };
      });
    }
    if (data.property) {
      obj.propertyUniqueReference = data.property?.propertyUniqueIdentifier
        ? data.property?.propertyUniqueIdentifier
        : data.property?.value;
    }
    if (data.selectedConflict) {
      obj.conflictUniqueReference = data.selectedConflict?.value;
    }
    if (data.propertyVisitSummary) {
      obj.pvsUniqueReference = data.propertyVisitSummary?.propertyVisitSummaryUniqueIdentifier;
    }
    if (data.hours) {
      obj.hoursWorked = parseInt(data.hours);
    }
    if (data.minutes) {
      obj.minutesWorked = parseInt(data.minutes);
    }
    if (data.miles) {
      obj.milesTraveled = parseInt(data.miles);
    }
    if (data.referredFromSelection) {
      obj.referredFromExternalOrganizationUniqueReference = data.referredFromSelection;
    }
    if (data.referredToSelection) {
      obj.referredToExternalOrganizationUniqueReference = data.referredToSelection;
    }
    if (data.permitActionsRadio === "Yes") {
      obj.permitRecommendationTypeEnumId = data.permitRecommendationType;
      obj.permitActionEnumId = data.permitAction;
      obj.permitNumberFreeText = data.permitNumberFreeText;
    }
  }

  handleSubmit = async () => {
    if (!this.validateData()) {
      toast.warn("Please fill out all required fields");
      return;
    }
    let obj = this.constructSubmitTAObject();
    this.addAdditionalFieldsToSubmitTAObject(obj);
    const createTAWorkTask = this.props.isHotline
      ? await WorkTaskAPI.CreateHotlineTAWorkTask(obj)
      : await WorkTaskAPI.CreateCoopTAWorkTask(obj);
    if (createTAWorkTask?.successful) {
      toast.success("Successfully created TA Work Task");
      if (this.props.isCooperator && createTAWorkTask?.data?.cooperatorTechnicalAssistWorkTaskUniqueIdentifier) {
        await this.recordWarehouseTransactions(createTAWorkTask.data.cooperatorTechnicalAssistWorkTaskUniqueIdentifier);
      }
      this.handleModalCancel(createTAWorkTask?.successful);
    } else {
      toast.error("Could not create TA Work Task");
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create ta work task",
        apiName: this.props.isHotline ? "CreateHotlineTAWorkTask" : "CreateCoopTAWorkTask",
        responseUnsuccessful: createTAWorkTask?.unsuccessful,
        responseMessage: createTAWorkTask?.message,
      });
    }
  };

  async recordWarehouseTransactions(workTaskId) {
    let areWarehouseTransactionsSuccessful = true;
    await Promise.all(
      this.state.data.methodTable.map(async (method) => {
        if (method.warehouse) {
          const warehouseTransactionResponse = await InventoryAPI.PerformWarehouseTransaction(
            this.getSellTransactionBody(method, workTaskId)
          );
          if (!warehouseTransactionResponse.successful) {
            areWarehouseTransactionsSuccessful = false;
          }
        }
      })
    );
    if (!areWarehouseTransactionsSuccessful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "One or more CMITS transactions could not be recorded.",
        apiName: "PerformWarehouseTransaction",
        responseUnsuccessful: !areWarehouseTransactionsSuccessful,
      });
    }
  }

  getSellTransactionBody(warehouseMethod, taWorkTaskId) {
    return {
      cooperaterWorkTaskUniqueReference: taWorkTaskId,
      methodUniquereference: warehouseMethod.method?.methodUniqueIdentifier,
      quantity: Number.parseInt(warehouseMethod.quantity),
      methodUOMEnumId: warehouseMethod.uom?.value,
      transactionTypeEnumId: WarehouseTransactionTypes.Sell,
      enteredByUserProfileUniqueReference: this.props.currentUserProfile?.wsUserProfileUniqueIdentifier,
      warehouseTransferFromUniqueReference: warehouseMethod.warehouse?.warehouseUniqueIdentifier,
      warehouseTransferFromStateUniqueReference: warehouseMethod.warehouse?.warehouseStateUniqueReference,
      inventoryAdjustmentDate: new Date().toISOString(),
    };
  }

  getSearchSelectedSelectStyles() {
    return {
      indicatorSeparator: () => {
        //do nothing
      },
      placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "white",
          border: "solid 0.5px #D8DDE6",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "#0070D2",
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "#53698D",
        ":hover": {
          backgroundColor: "white",
        },
      }),
    };
  }

  renderGeneralSelectionPage = () => {
    return (
      <React.Fragment>
        <Row className={styles.resultsBox}>{this.renderGeneralSearchResults()}</Row>
        <Row className="mx-0 mt-4">
          <Col>
            <Form.Group>
              <Form.Label className={styles.refFilesModalSubTitle} htmlFor="speciesSelected">
                General Selected
              </Form.Label>
              <Select
                aria-label="General Selected"
                name="generalSelected"
                id="generalSelected"
                placeholder="General Selected"
                isMulti
                isSearchable={false}
                menuIsOpen={false}
                isClearable={false}
                styles={this.getSearchSelectedSelectStyles()}
                components={{
                  DropdownIndicator: (props) => {
                    return "";
                  },
                }}
                value={this.state.data?.selectedGeneralTopics?.map((s) => ({
                  value: s.topicUniqueIdentifier,
                  label: s.name,
                }))}
                onChange={this.onGeneralSelectionChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderGeneralSearchResults = () => {
    let results = null;
    const { allGeneralTopics } = this.state;
    results = (
      <React.Fragment>
        {allGeneralTopics.map((result, index) => {
          return (
            <div key={index}>
              <Row className={`mb-2 mx-0 ${index === 0 ? "mt-3" : "mt-2"}`}>
                <Col>
                  <Form.Check
                    type="checkbox"
                    inline
                    checked={result.isSelected}
                    onChange={() => this.handleGeneralSelection(result)}
                    title={result.name}
                  />
                  <span className={globalStyles.formData}>{result.name}</span>
                </Col>
              </Row>
              <Row className={styles.greySeparatorRow}></Row>
            </div>
          );
        })}
      </React.Fragment>
    );
    return results;
  };

  handleGeneralSelection = (selectedGeneralTopic) => {
    const { data, allGeneralTopics } = this.state;

    const regularGeneral = allGeneralTopics.find(
      (s) => s.topicUniqueIdentifier === selectedGeneralTopic.topicUniqueIdentifier
    );
    if (regularGeneral) {
      regularGeneral.isSelected = !regularGeneral.isSelected;
    }

    if (
      data.selectedGeneralTopics.find((s) => s.topicUniqueIdentifier === selectedGeneralTopic.topicUniqueIdentifier)
    ) {
      data.selectedGeneralTopics = data.selectedGeneralTopics.filter(
        (s) => s.topicUniqueIdentifier !== selectedGeneralTopic.topicUniqueIdentifier
      );
    } else {
      data.selectedGeneralTopics.push(selectedGeneralTopic);
    }

    this.setState({ data, allGeneralTopics });
  };

  onGeneralSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data, allGeneralTopics } = this.state;

      const removedGeneralIndex = allGeneralTopics.findIndex((s) => s.topicUniqueIdentifier === removedValue.value);
      if (removedGeneralIndex !== -1) {
        allGeneralTopics[removedGeneralIndex].isSelected = false;
      }

      data.selectedGeneralTopics = data.selectedSpecies.filter((s) => s.topicUniqueIdentifier !== removedValue.value);
      this.setState({ data, allGeneralTopics });
    }
  };

  renderSpeciesSelectionPage = () => {
    const { data } = this.state;
    return (
      <React.Fragment>
        <div>
          <Row className="mx-0 mt-4">
            <Col>
              <span className={styles.refFilesModalSubTitle}>Search Damage Agents</span>
            </Col>
          </Row>
          <Row className="mx-0 mt-2">
            <Col className="pr-0">
              <Form.Control
                name="speciesNameSearch"
                placeholder="Enter Damage Agent Name"
                value={data.speciesNameSearch}
                onChange={this.handleSpeciesFilterSearch}
              />
            </Col>
            <Col className="pr-0">
              <Select
                aria-label="Species Class"
                name="speciesClassesSearch"
                placeholder="Class"
                value={data.speciesClassesSearch}
                onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesClassesSearch")}
                isClearable
                styles={this.customSelectStyles}
                options={this.state.speciesClasses}
              />
            </Col>
            <Col className="pr-0">
              <Select
                aria-label="Species Order"
                name="speciesOrdersSearch"
                placeholder="Order"
                value={data.speciesOrdersSearch}
                onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesOrdersSearch")}
                isClearable
                styles={this.customSelectStyles}
                options={this.state.speciesOrders}
              />
            </Col>
            <Col className="pr-0">
              <Select
                aria-label="Species Family"
                name="speciesFamiliesSearch"
                placeholder="Family"
                value={data.speciesFamiliesSearch}
                onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesFamiliesSearch")}
                isClearable
                styles={this.customSelectStyles}
                options={this.state.speciesFamilies}
              />
            </Col>
            <Col>
              <Select
                aria-label="Species Category"
                name="speciesCategoriesSearch"
                placeholder="Category"
                value={data.speciesCategoriesSearch}
                onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesCategoriesSearch")}
                isClearable
                styles={this.customSelectStyles}
                options={this.state.speciesCategories}
              />
            </Col>
          </Row>
          <Row>
            <Col align="right">
              <Button variant="link" onClick={this.clearSpeciesFilters} style={{ fontSize: "14px" }}>
                Clear All Filters
              </Button>
            </Col>
          </Row>
          <Row className={styles.resultsBox}>{this.renderSpeciesSearchResults()}</Row>
        </div>
        <Row className="mx-0 mt-4">
          <Col>
            <Form.Group>
              <Form.Label className={styles.refFilesModalSubTitle} htmlFor="speciesSelected">
                Damage Agents Selected
              </Form.Label>
              <Select
                aria-label="Damage Agents Selected"
                name="speciesSelected"
                id="speciesSelected"
                placeholder="Damage Agents Selected"
                isMulti
                isSearchable={false}
                menuIsOpen={false}
                isClearable={false}
                styles={this.getSearchSelectedSelectStyles()}
                components={{
                  DropdownIndicator: (props) => {
                    return "";
                  },
                }}
                value={this.state.data?.selectedSpecies?.map((s) => ({
                  value: s.speciesUniqueIdentifier,
                  label: s.name,
                }))}
                onChange={this.onSpeciesSelectionChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  clearSpeciesFilters = () => {
    const { data } = this.state;
    data.speciesNameSearch = "";
    data.speciesClassesSearch = "";
    data.speciesOrdersSearch = "";
    data.speciesFamiliesSearch = "";
    data.speciesCategoriesSearch = "";
    this.setState({ data });
  };

  checkForSpeciesSearchFilterChange() {
    const { data } = this.state;
    return (
      data.speciesNameSearch ||
      data.speciesClassesSearch ||
      data.speciesOrdersSearch ||
      data.speciesFamiliesSearch ||
      data.speciesCategoriesSearch
    );
  }

  renderSpeciesSearchResults = () => {
    let results = null;
    if (this.checkForSpeciesSearchFilterChange()) {
      results = (
        <React.Fragment>
          {this.filterAllSpeciesForSearchResults()?.map((result, index) => {
            return (
              <div key={index}>
                <Row className={`mb-2 mx-0 ${index === 0 ? "mt-3" : "mt-2"}`}>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      inline
                      checked={result.isSelected}
                      onChange={() => this.handleSpeciesSelection(result)}
                      title={result.name}
                    />
                    <span className={globalStyles.formData}>{result.name}</span>
                  </Col>
                </Row>
                <Row className={styles.greySeparatorRow}></Row>
              </div>
            );
          })}
        </React.Fragment>
      );
    }
    return results;
  };

  filterAllSpeciesForSearchResults() {
    const { data, allSpecies } = this.state;
    return allSpecies?.filter((species) => {
      let isMatch = true;
      if (data.speciesNameSearch) {
        isMatch = isMatch && species.name.toLowerCase().includes(data.speciesNameSearch);
      }
      if (data.speciesClassesSearch) {
        isMatch = isMatch && species.class === data.speciesClassesSearch.value;
      }
      if (data.speciesOrdersSearch) {
        isMatch = isMatch && species.order === data.speciesOrdersSearch.value;
      }
      if (data.speciesFamiliesSearch) {
        isMatch = isMatch && species.family === data.speciesFamiliesSearch.value;
      }
      if (data.speciesCategoriesSearch) {
        isMatch = isMatch && species.subType === data.speciesCategoriesSearch.value;
      }
      return isMatch;
    });
  }

  handleSpeciesSelection = (selectedSpecies) => {
    const { data, allSpecies } = this.state;

    const regularSpecies = allSpecies.find(
      (s) => s.speciesUniqueIdentifier === selectedSpecies.speciesUniqueIdentifier
    );
    if (regularSpecies) {
      regularSpecies.isSelected = !regularSpecies.isSelected;
    }

    if (data.selectedSpecies.find((s) => s.speciesUniqueIdentifier === selectedSpecies.speciesUniqueIdentifier)) {
      data.selectedSpecies = data.selectedSpecies.filter(
        (s) => s.speciesUniqueIdentifier !== selectedSpecies.speciesUniqueIdentifier
      );
    } else {
      data.selectedSpecies.push(selectedSpecies);
    }

    this.setState({ data, allSpecies });
  };

  handleSpeciesFilterSearch = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleSpeciesFilterSelect = (input, selectionField) => {
    const data = { ...this.state.data };
    if (!input) {
      data[selectionField] = "";
    } else {
      data[selectionField] = input;
    }
    this.setState({ data });
  };

  onSpeciesSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data, allSpecies } = this.state;

      const removedSpeciesIndex = allSpecies.findIndex((s) => s.speciesUniqueIdentifier === removedValue.value);
      if (removedSpeciesIndex !== -1) {
        allSpecies[removedSpeciesIndex].isSelected = false;
      }

      data.selectedSpecies = data.selectedSpecies.filter((s) => s.speciesUniqueIdentifier !== removedValue.value);
      this.setState({ data, allSpecies });
    }
  };

  renderResourceSelectionPage = () => {
    const { data } = this.state;
    return (
      <React.Fragment>
        <Row className="mx-0 mt-4">
          <Col>
            <span className={styles.refFilesModalSubTitle}>Search Resources</span>
          </Col>
        </Row>
        <Row className="mx-0 mt-2">
          <Col className="pr-0">
            <Form.Control
              aria-label="Resource Name Search Input"
              name="resourceNameSearch"
              placeholder="Enter Resource Name"
              value={data.resourceNameSearch}
              onChange={this.handleResourceFilterSearch}
            />
          </Col>
          <Col className="pr-0">
            <Select
              aria-label="Resource Type Select"
              name="resourceTypeSearch"
              placeholder="Type"
              value={data.resourceMainCategorySearch}
              onChange={async (e) => await this.handleResourceFilterSelect(e, "resourceMainCategorySearch")}
              isClearable
              styles={this.customSelectStyles}
              options={this.state.resourceMainCategories}
            />
          </Col>
          <Col>
            <Select
              aria-label="Resource Sub Type Select"
              name="resourceSubTypeSearch"
              placeholder="Sub Type"
              value={data.resourceSubCategory1Search}
              onChange={async (e) => await this.handleResourceFilterSelect(e, "resourceSubCategory1Search")}
              isClearable
              styles={this.customSelectStyles}
              options={this.state.resourceSubCategories1}
            />
          </Col>
        </Row>
        <Row>
          <Col align="right">
            <Button variant="link" onClick={this.clearResourceFilters} style={{ fontSize: "14px" }}>
              Clear All Filters
            </Button>
          </Col>
        </Row>
        <Row className={styles.resultsBox}>{this.renderResourceSearchResults()}</Row>
        <Row className="mx-0 mt-4">
          <Col>
            <Form.Group>
              <Form.Label className={styles.refFilesModalSubTitle} htmlFor="resourcesSelected">
                Resources Selected
              </Form.Label>
              <Select
                aria-label="Resources Selected"
                name="resourcesSelected"
                id="resourcesSelected"
                placeholder="Resources Selected"
                isMulti
                menuIsOpen={false}
                isClearable={false}
                isSearchable={false}
                styles={this.getSearchSelectedSelectStyles()}
                components={{
                  DropdownIndicator: (props) => {
                    return "";
                  },
                }}
                value={this.state.data?.selectedResources?.map((r) => ({
                  value: r.resourceUniqueIdentifier,
                  label: r.name,
                }))}
                onChange={this.onResourceSelectionChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  checkForResourceSearchFilterChange() {
    const { data } = this.state;
    return (
      data.resourceNameSearch ||
      data.resourceMainCategorySearch ||
      data.resourceSubCategory1Search ||
      data.resourceSubCategory2Search
    );
  }

  renderResourceSearchResults = () => {
    let results = null;
    if (this.checkForResourceSearchFilterChange()) {
      results = (
        <React.Fragment>
          {this.filterAllResourcesForSearchResults()?.map((result, index) => {
            return (
              <div key={index}>
                <Row className={`mb-2 mx-0 ${index === 0 ? "mt-3" : "mt-2"}`}>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      inline
                      checked={result.isSelected}
                      onChange={() => this.handleResourceSelection(result)}
                      title={result.name}
                    />
                    <span className={globalStyles.formData}>{result.name}</span>
                  </Col>
                </Row>
                <Row className={styles.greySeparatorRow}></Row>
              </div>
            );
          })}
        </React.Fragment>
      );
    }
    return results;
  };

  filterAllResourcesForSearchResults() {
    const { data, allResources } = this.state;
    return allResources?.filter((resource) => {
      let isMatch = true;
      if (data.resourceNameSearch) {
        isMatch = isMatch && resource.name.toLowerCase().includes(data.resourceNameSearch);
      }
      if (data.resourceMainCategorySearch) {
        isMatch = isMatch && resource.type === data.resourceMainCategorySearch.value;
      }
      if (data.resourceSubCategory1Search) {
        isMatch = isMatch && resource.subType === data.resourceSubCategory1Search.value;
      }
      return isMatch;
    });
  }

  clearResourceFilters = () => {
    const { data } = this.state;
    data.resourceNameSearch = "";
    data.resourceMainCategorySearch = "";
    data.resourceSubCategory1Search = "";
    data.resourceSubCategory2Search = "";
    this.setState({ data });
  };

  handleResourceSelection = (selectedResource) => {
    const { data, allResources } = this.state;

    const regularResource = allResources.find(
      (r) => r.resourceUniqueIdentifier === selectedResource.resourceUniqueIdentifier
    );
    if (regularResource) {
      regularResource.isSelected = !regularResource.isSelected;
    }

    if (data.selectedResources.find((r) => r.resourceUniqueIdentifier === selectedResource.resourceUniqueIdentifier)) {
      data.selectedResources = data.selectedResources.filter(
        (r) => r.resourceUniqueIdentifier !== selectedResource.resourceUniqueIdentifier
      );
    } else {
      data.selectedResources.push(selectedResource);
    }

    this.setState({ data, allResources });
  };

  handleResourceFilterSearch = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleResourceFilterSelect = async (input, selectionField) => {
    const data = { ...this.state.data };
    if (!input) {
      data[selectionField] = "";
      if (selectionField === "resourceMainCategorySearch") {
        data.resourceSubCategory1Search = "";
      }
    } else {
      data[selectionField] = input;
      if (selectionField === "resourceMainCategorySearch") {
        const newSubTypeList = await ReferenceFileAPI.GetResourceSubTypes(input.value);
        if (newSubTypeList && newSubTypeList?.data?.length > 0) {
          this.setState({
            resourceSubCategories1: newSubTypeList?.data?.map((subType) => {
              if (!subType) {
                return { value: subType, label: "No Type" };
              }
              return { value: subType, label: subType };
            }),
          });
          data.resourceSubCategory1Search = "";
        }
      }
    }
    this.setState({ data });
  };

  onResourceSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data, allResources } = this.state;

      const removedResourceIndex = allResources.findIndex((r) => r.resourceUniqueIdentifier === removedValue.value);
      if (removedResourceIndex !== -1) {
        allResources[removedResourceIndex].isSelected = false;
      }

      data.selectedResources = data.selectedResources.filter((r) => r.resourceUniqueIdentifier !== removedValue.value);
      this.setState({ data, allResources });
    }
  };

  handleWarehouseSelection = async (input) => {
    const { data } = this.state;
    if (input?.warehouseUniqueIdentifier) {
      const getWarehouseResponse = await InventoryAPI.GetWarehouse(input.warehouseUniqueIdentifier);
      if (!getWarehouseResponse?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve warehouse",
          apiName: "GetWarehouse",
          responseUnsuccessful: getWarehouseResponse?.unsuccessful,
          responseMessage: getWarehouseResponse?.message,
        });
      } else if (
        getWarehouseResponse.data?.warehouseInventory?.find(
          (m) => m.methodUniqueReference === data.selectedMethod?.methodUniqueIdentifier
        )
      ) {
        data.selectedWarehouse = { value: input.value, label: input.label, ...getWarehouseResponse.data };
        this.setState({ data });
      } else {
        toast.warning("Selected method is not defined in this warehouse.");
      }
    }
  };

  isMethodDataValid() {
    const { data } = this.state;
    return (
      data.selectedMethod &&
      data.selectedUom &&
      data.methodQuantity &&
      ((!data.selectedMethod?.isCMITSMethodGlobally && !data.selectedMethod?.isCMITSMethod) || data.selectedWarehouse)
    );
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
      minHeight: "35px",
    }),
    container: (base) => ({
      ...base,
      minHeight: "35px",
    }),
  };

  render() {
    const { data } = this.state;
    let agreementField = [];
    let countyField = [];
    let stateField = [];
    let propertyField = [];
    let conflictField = [];
    let permitActionsFields = [];
    let methodTableSection = [];
    if (this.props.fromSiteVisit) {
      agreementField.push(<Col>{this.renderInput("agreementName", "Identify Agreement", "text", "", "readOnly")}</Col>);
      countyField.push(this.renderInput("county", "County", "text", "", "readOnly"));
      stateField.push(this.renderInput("stateName", "State", "text", "", "readOnly"));
      if (data.agreement && !this.props.isHotline) {
        propertyField.push(this.renderInput("propertyName", "Property", "text", "", "readOnly"));
      }
      if (data.property && data.agreement && this.props.isCooperator) {
        conflictField.push(
          <Form.Group>
            <Form.Label className={globalStyles.formLabel}>Conflict</Form.Label>
            <Select
              value={data.selectedConflict}
              aria-label="Conflict Selection"
              placeholder="Select"
              isClearable={!this.props.fromSiteVisit}
              styles={this.customSelectStyles}
              options={this.state.conflicts}
              onChange={(e) => this.handleConflictSelection(e)}
            />
          </Form.Group>
        );
      }
    } else {
      this.props.isHotline || this.props.isCooperator
        ? agreementField.push(
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Identify Agreement</Form.Label>
                <AsyncSelect
                  value={data.agreement}
                  aria-label="Search For Agreements"
                  isClearable={true}
                  openMenuOnClick={false}
                  placeholder="Search"
                  components={{ DropdownIndicator }}
                  styles={this.customSelectStyles}
                  loadOptions={this.handleAgreementsSearch}
                  onChange={(e) => this.handleSelectChanges(e, "agreement")}
                />
              </Form.Group>
            </Col>
          )
        : agreementField.push(
            <React.Fragment>
              <Col md={3}>{this.renderInput("numLeaflets", "# of Leaflets", "number", "Enter Number")}</Col>
              <Col md={3}>
                {this.renderInput("numParticipants", "# of Participants", "number", "Enter Number", "required")}
              </Col>
            </React.Fragment>
          );
      countyField.push(this.renderSelect("county", "County", this.state.counties, "Select", "required"));
      stateField.push(
        this.renderSelect(
          "state",
          "State",
          this.props.states.map((state) => {
            return { id: state.stateUniqueIdentifier, name: state.state_code };
          }),
          "Select"
        )
      );
      if (data.agreement && this.props.isCooperator) {
        propertyField.push(
          <Form.Group>
            <Form.Label className={globalStyles.formLabel}>Property Name</Form.Label>
            <AsyncSelect
              value={data.property}
              aria-label="Search For Properties"
              isClearable={true}
              openMenuOnClick={false}
              placeholder="Search"
              components={{ DropdownIndicator }}
              styles={this.customSelectStyles}
              loadOptions={this.getPropertiesFromAPI}
              onChange={(e) => this.handleSelectChanges(e, "property")}
            />
          </Form.Group>
        );
      }
      if (data.property && data.agreement && this.props.isCooperator) {
        conflictField.push(
          <Form.Group>
            <Form.Label className={globalStyles.formLabel}>Conflict</Form.Label>
            <Select
              value={data.selectedConflict}
              aria-label="Conflict Selection"
              placeholder="Select"
              isClearable
              styles={this.customSelectStyles}
              options={this.state.conflicts}
              onChange={(e) => this.handleConflictSelection(e)}
            />
          </Form.Group>
        );
      }
    }
    if (data.permitActionsRadio === "Yes") {
      permitActionsFields.push(
        <React.Fragment>
          <Row>
            <Col>
              {this.renderSelect(
                "permitRecommendationType",
                "Permit Recommendation Type",
                this.state.permitRecommendationTypes,
                "Select",
                "required"
              )}
            </Col>
            <Col>
              {this.renderSelect("permitAction", "Permit Action", this.state.permitActions, "Select", "required")}
            </Col>
          </Row>
          <Row>
            <Col xs={6}>{this.renderInput("permitNumberFreeText", "Permit Number", "text", "Enter", "required")}</Col>
          </Row>
        </React.Fragment>
      );
    }
    if (data.methodTable.length > 0 && this.props.isCooperator) {
      methodTableSection.push(
        <div className={styles.methodTableContainer}>
          <Row className={styles.workPerformedLabelRow}>
            <Col>Method</Col>
            <Col>UOM</Col>
            <Col>Quantity</Col>
            <Col>Warehouse</Col>
          </Row>
          {data.methodTable.map((item, index) => {
            return (
              <Row className={styles.workPerformedRow} key={index}>
                <Col>{item.method.label}</Col>
                <Col>{item.uom.label}</Col>
                <Col>{item.quantity}</Col>
                <Col>{item.warehouse ? item.warehouse.label : "N/A"}</Col>
              </Row>
            );
          })}
        </div>
      );
    }
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.handleModalCancel();
        }}
        backdrop="static"
        centered
        size="xl"
      >
        <Form noValidate>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>
              {this.props.isHotline
                ? "Create Hotline TA"
                : this.props.isCooperator
                ? "Create TA Work Task"
                : "Create Public Outreach TA"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <h5 className={styles.modalSectionHeader}>Public Information</h5>
            <Row>
              <Col lg={6}>
                {this.renderInput(
                  "date",
                  this.props.isHotline ? "Work Date" : "Date",
                  "date",
                  "Select Date",
                  "required"
                )}
              </Col>
              <Col lg={3}>{this.renderInput("hours", "Hours", "number", "Enter")}</Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Minutes</Form.Label>
                  <Form.Control
                    aria-label="Minutes Selection"
                    as="select"
                    type="number"
                    onChange={this.handleChange}
                    value={data.minutes}
                    style={{ height: "35px" }}
                    name="minutes"
                  >
                    <option value="0" key="pvsMinutes0">
                      0
                    </option>
                    <option value="15" key="pvsMinutes15">
                      15
                    </option>
                    <option value="30" key="pvsMinutes30">
                      30
                    </option>
                    <option value="45" key="pvsMinutes45">
                      45
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                {this.renderInput("city", "City", "text", "Enter", this.props.fromSiteVisit ? "readOnly" : "")}
              </Col>
              <Col lg={3}>{stateField}</Col>
              <Col lg={6}>{countyField}</Col>
            </Row>
            <Row hidden={!this.props.isHotline && !this.props.isCooperator}>
              <Col lg={6}>{this.renderInput("miles", "Miles", "number", "Enter")}</Col>
            </Row>
            <Row>
              {agreementField}
              <Col align="left">
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Activity<span className={globalStyles.asterisk508}>{" *"}</span>
                  </Form.Label>
                  <Select
                    value={data.selectedActivity}
                    aria-label="Activities Selection"
                    placeholder="Select"
                    isMulti={true}
                    styles={this.customSelectStyles}
                    options={this.state.activities}
                    onChange={(e) => this.handleActivitySelection(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>{propertyField}</Col>
              <Col>{conflictField}</Col>
            </Row>
            <Row hidden={!this.props.isHotline && !this.props.isCooperator}>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Comments</Form.Label>
                  <Form.Control
                    name="comments"
                    aria-label="Comments text box"
                    placeholder="Enter Comments"
                    type="text"
                    as="textarea"
                    rows={2}
                    className={globalStyles.formData}
                    value={data.comments}
                    onChange={(e) => this.handleCommentsChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Performed By</Form.Label>
                  <AsyncSelect
                    value={data.performedBy}
                    aria-label="Search For User Profiles"
                    isClearable={true}
                    openMenuOnClick={false}
                    placeholder="Search"
                    components={{ DropdownIndicator, Option }}
                    styles={this.customSelectStyles}
                    loadOptions={this.searchMatchingUsers}
                    onChange={(e) => this.handleSelectChanges(e, "performedBy")}
                    isDisabled={this.props.data}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <span className={styles.userSearchInfoText}>
                <i>If Performed By is left blank, the logged in user will be recorded as the TA Performer.</i>
              </span>
            </Row>
            <h5 className={styles.modalSectionHeader}>Additional Information</h5>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Projects</Form.Label>
                  <Select
                    value={data.selectedProjects}
                    aria-label="Projects Selection"
                    isMulti="true"
                    placeholder="Select"
                    styles={this.customSelectStyles}
                    options={this.state.projects}
                    onChange={(e) => this.handleProjectSelection(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Recommendations</Form.Label>
                  <Select
                    value={data.selectedRecommendations}
                    aria-label="Recommendations Selection"
                    isMulti="true"
                    placeholder="Select"
                    styles={this.customSelectStyles}
                    options={this.state.recommendations}
                    onChange={(e) => this.handleRecommendationSelection(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row hidden={!this.props.isHotline && !this.props.isCooperator}>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Cooperator Employed Work</Form.Label>
                  <Select
                    value={data.selectedCooperatorEmployedWork}
                    aria-label="Cooperator Employed Work Selection"
                    isMulti="true"
                    placeholder="Select"
                    styles={this.customSelectStyles}
                    options={this.state.recommendations}
                    onChange={(e) => this.handleCooperatorEmployedWorkSelection(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Cooperator</Form.Label>
                  <AsyncSelect
                    value={data.selectedCooperator}
                    openMenuOnClick={false}
                    aria-label="Cooperator Search"
                    placeholder="Search for a Contact"
                    components={{ DropdownIndicator, Option }}
                    styles={this.customSelectStyles}
                    loadOptions={this.handleCooperatorSearch}
                    onChange={(e) => this.handleCooperatorSelection(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div hidden={!this.props.isCooperator} className="mt-3">
              <Row>
                <Col>
                  <Form.Label style={{ fontSize: "18px" }}>Loaned/Sold/Distributed Equipment</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Method</Form.Label>
                    <Select
                      value={data.selectedMethod}
                      aria-label="Method Selection"
                      placeholder="Select"
                      isClearable={true}
                      styles={this.customSelectStyles}
                      options={this.state.methods}
                      onChange={(e) => this.handleMethodSelection(e)}
                    />
                  </Form.Group>
                </Col>
                {(data.selectedMethod?.isCMITSMethodGlobally || data.selectedMethod?.isCMITSMethod) &&
                  this.props.permissions.canIWorktaskInteractionAssignedWarehouse() && (
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>Warehouse</Form.Label>
                        <Select
                          value={data.selectedWarehouse}
                          aria-label="Warehouse Selection"
                          placeholder="Select"
                          isClearable={true}
                          styles={this.customSelectStyles}
                          options={this.state.availableWarehouses}
                          onChange={(e) => this.handleWarehouseSelection(e)}
                        />
                      </Form.Group>
                    </Col>
                  )}
                <Col>
                  <Form.Label className={globalStyles.formLabel}>UOM</Form.Label>
                  <Select
                    value={data.selectedUom}
                    aria-label="Method UOM Selection"
                    placeholder="Select"
                    isClearable={true}
                    styles={this.customSelectStyles}
                    options={this.state.methodUoms}
                    onChange={(e) => this.handleUomSelection(e)}
                  />
                </Col>
                <Col>{this.renderInput("methodQuantity", "Quantity", "number", "Enter")}</Col>
              </Row>
              <Row>
                <Col xs={{ offset: 9, span: 3 }} align="right">
                  <Button
                    variant="primary"
                    onClick={this.handleAddClick}
                    className={this.isMethodDataValid() ? "" : styles.greyedOutModalSaveButton}
                  >
                    <span
                      className={
                        this.isMethodDataValid() ? globalStyles.modalSubmitButtonText : styles.greyedOutButtonText
                      }
                    >
                      Add
                    </span>
                  </Button>
                </Col>
              </Row>
            </div>
            {methodTableSection}
            <Row>
              <Col lg={6}>
                {this.renderSelect("referredFromSelection", "Referred From:", this.state.referredList, "Select")}
              </Col>
              <Col lg={6}>
                {this.renderSelect("referredToSelection", "Referred To:", this.state.referredList, "Select")}
              </Col>
            </Row>
            <Row hidden={!this.props.isHotline && !this.props.isCooperator}>
              <Col>
                <Form.Group className="form-group">
                  {this.renderInlineCheckBoxSet("permitActionsRadio", "Permit Actions", ["Yes", "No"], "radio", "")}
                </Form.Group>
              </Col>
            </Row>
            {permitActionsFields}
            <h5 className={styles.modalSectionHeader}>Topic Information</h5>
            <div className="TAWorkTaskModalTopicTabs">
              <Tabs
                onSelect={(e) => {
                  this.setState({ activeTopicTab: e });
                }}
              >
                <Tab eventKey="1" title="General" id="generalTab">
                  {this.renderGeneralSelectionPage()}
                </Tab>
                <Tab eventKey="2" title="Damage Agents" id="speciesTab">
                  {this.renderSpeciesSelectionPage()}
                </Tab>
                <Tab eventKey="3" title="Resources" id="resourcesTab">
                  {this.renderResourceSelectionPage()}
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              className="ml-2"
              variant="primary"
              onClick={this.props.isHotline || this.props.isCooperator ? this.handleSubmit : this.handlePOSubmit}
            >
              <span className={globalStyles.modalSubmitButtonText}>Submit</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
export default TAWorkTaskModalWithTopics;
