import React from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { textFilter, selectFilter, customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import EditResourceModal from "../ReferenceFilesEditModals/editResourceModal";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CustomTextFilter from "../common/customTextFilter";
import { toast } from "react-toastify";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import moment from "moment";

class ResourcesTab extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      resources: [],
      showModal: false,
      modalData: {},
      isDataManager: true,
      history: this.props.history,
      currentUsaState: this.props.currentUsaState,
      defaultSort: [{ dataField: "name", order: "asc" }],
      resourceUOMs: [],
      nameFilter: null,
      typesFilterValues: {},
      typeFilter: null,
      subTypeFilter: null,
      subTypeFilteredBy: "",
      subTypeFilterValues: [],
      allowedFilter: null,
      allowedUOMFilter: null,
      dateFilter: null,
      apiValuationSettings: [],
      columns: [],

      typeFilterValue: "",
      subTypeFilterValue: "",
      nameFilterValue: "",
      allowedUOMsFilterValue: "",
      allowedFilterValue: "",
      lastUpdatedDateFilterValue: "",
      sortColumn: "name",
      isAscending: true,
      waitingForAPIData: false,

      resourceTypes: [],
      resourceSubTypes: [],
    };
  }

  getColumns() {
    let columnArray = [
      {
        dataField: "resourceUniqueIdentifier",
        hidden: true,
        csvText: "Resource Unique Id",
      },
      {
        dataField: "type",
        text: "Type",
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "resourceTypeHeader" },
        attrs: { headers: "resourceTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "subType",
        text: "Sub Type",
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "resourceSubTypeHeader" },
        attrs: { headers: "resourceSubTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "name",
        text: "Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            setColumnFilterValue={this}
            columnName="name"
            title="Resource Name Search"
          />
        ),
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "resourceNameHeader" },
        attrs: { headers: "resourceNameHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "globalAllowedUnitOfMeasures" : "stateAllowedUnitOfMeasures",
        text: "Allowed UOMs",
        csvFormatter: (cell, row) => {
          return this.getUOMString(row);
        },
        filter: customFilter({
          onFilter: (filterValue, data) => {
            const results = data.filter((x) => this.getUOMString(x).toLowerCase().includes(filterValue.toLowerCase()));
            return results;
          },
        }),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            setColumnFilterValue={this}
            columnName="allowedUOMs"
            title="Method Allowed UOMs Filter"
          />
        ),
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        formatter: (cell, row) => {
          return this.getUOMString(row);
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "resourceAllowedUomHeader" },
        attrs: { headers: "resourceAllowedUomHeader", tabIndex: "0" },
      },
      {
        dataField: "lastModifiedDateTimeUTC",
        text: this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            dataType="date"
            setColumnFilterValue={this}
            columnName="lastUpdatedDate"
            title="Last Updated Date"
          />
        ),
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },

        formatter: (cell, row, rowIndex, formatExtraData) => {
          return this.formatDate(cell);
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "resourceLastModifiedDateHeader" },
        attrs: { headers: "resourceLastModifiedDateHeader", tabIndex: "0" },
      },
    ];

    if (!this.props.useGlobalData) {
      columnArray.push({
        dataField: "isAllowed",
        text: "Allowed",
        filter: selectFilter({
          options: { true: "Allowed", false: "Not Allowed" },
          getFilter: (filter) => {
            this.state.allowedFilter = filter;
          },
          onInput: (e) => {
            let textValue = "";
            if (e.currentTarget.value === "true") {
              textValue = "Allowed";
            } else if (e.currentTarget.value === "false") {
              textValue = "Not Allowed";
            }
            this.setState({ allowedFilterValue: textValue });
          },
          onFilter: (filterValue, data) => {
            if (filterValue === "") {
              return data;
            }

            return data.filter((x) => x.isAllowed?.toString() === filterValue);
          },
        }),
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },

        formatter: (cell, row, rowIndex) => {
          return row.isAllowed ? <span style={{ color: "#008767" }}>Allowed</span> : "Not Allowed";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "resourceAllowedHeader" },
        attrs: { headers: "resourceAllowedHeader", tabIndex: "0" },
      });
    }
    columnArray.push({
      dataField: "edit",
      text: "Actions",
      csvExport: false,
      formatter: (cell, row) => {
        let configureDropdownItem = [];
        if (!this.props.useGlobalData) {
          configureDropdownItem.push(
            <Dropdown.Item
              eventKey="resourceConfigure"
              onSelect={() => this.openEditResourceModal(row)}
              hidden={!this.props.permissions.canIConfigureRefData()}
            >
              Configure
            </Dropdown.Item>
          );
        } else {
          configureDropdownItem.push(
            <React.Fragment>
              <Dropdown.Item
                eventKey="favoriteResourcesAdd"
                onSelect={() => this.addFavoriteResource(row)}
                hidden={!row.isAllowedGlobally}
              >
                Add to Favorites
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="damageEdit"
                onSelect={() => this.handleEditResourceClick(row)}
                hidden={!this.props.permissions.canIEditReferenceData()}
              >
                Edit
              </Dropdown.Item>
            </React.Fragment>
          );
        }
        let infoIcon = null;
        if (row.comments) {
          infoIcon = (
            <div className={styles.descriptionInfoIcon} title={row.comments}>
              <i className="fa fa-info" aria-hidden="true" title={row.comments}></i>
            </div>
          );
        }
        return (
          <div>
            {infoIcon}
            <div className={styles.refFilesActionButtonContainer}>
              <DropdownButton
                id="dropdown-species-actions"
                title="Actions"
                variant="link"
                className={styles.buttonLink}
              >
                {configureDropdownItem}
                <Dropdown.Item eventKey="damageHistoryTracking" onSelect={() => this.handleResourceHistory(row)}>
                  History Tracking
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "20%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
      },
      headerAlign: "right",
      align: "right",
      headerAttrs: {
        id: "resourceActionsHeader",
        title: "Actions Column Header",
      },
      attrs: { headers: "resourceActionsHeader" },
    });

    return columnArray;
  }

  getUOMString(row) {
    let uomList = row?.globalAllowedUnitOfMeasures ? row?.globalAllowedUnitOfMeasures : row?.stateAllowedUnitOfMeasures;
    uomList.sort((a, b) =>
      a.unitOfMeasure.displayText.localeCompare(b.unitOfMeasure.displayText, undefined, { sensitivity: "base" })
    );
    let uomString = "No UOMs";

    if (uomList && row) {
      let newAllowedUOMsInState = "";
      for (let j = 0; j < uomList.length; j++) {
        newAllowedUOMsInState += uomList[j]?.unitOfMeasure?.displayText + ", ";
      }
      uomString = newAllowedUOMsInState.slice(0, -2);
    }

    return uomString;
  }

  addFavoriteResource = async (resource) => {
    if (this.props.favoriteResources.find((r) => r.resourceUniqueIdentifier === resource.resourceUniqueIdentifier)) {
      toast.success("This Resource is already a favorite.");
    } else {
      if (this.props.favoriteResources.length === 15) {
        toast.success("You already have 15 favorite Resources.");
      } else {
        this.props.favoriteResources.push(resource);

        const userData = this.props.userData;
        const saveFavoritesResponse = await RoleAuthorizationAPI.SetUserFavoriteReferenceFileData(
          userData?.userPrinciple,
          {
            speciesUniqueReferences: userData.preferredSpeciesUniqueReferences,
            methodUniqueReferences: userData.preferredMethodsUniqueReferences,
            resourceUniqueReferences: this.props.favoriteResources.map((f) => f.resourceUniqueIdentifier),
          }
        );
        if (saveFavoritesResponse?.successful && saveFavoritesResponse?.data) {
          const updatedUserData = saveFavoritesResponse.data;
          userData.preferredSpeciesUniqueReferences = updatedUserData.preferredSpeciesUniqueReferences;
          userData.preferredMethodsUniqueReferences = updatedUserData.preferredMethodsUniqueReferences;
          userData.preferredResourcesUniqueReferences = updatedUserData.preferredResourcesUniqueReferences;
          toast.success("Resource added to favorites.");
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to set user's favorite records",
            apiName: "SetUserFavoriteReferenceFileData",
            responseUnsuccessful: saveFavoritesResponse?.unsuccessful,
            responseMessage: saveFavoritesResponse?.message,
          });
        }
      }
    }
  };

  tableTextStyle = {};

  filterAllowedUOMInState = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) =>
        entry.stateAllowedUnitOfMeasures?.some((uom) => uom.unitOfMeasure?.name.includes(filterValue?.toLowerCase()))
      );
    }
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      currentUsaState: nextProps.currentUsaState,
    };
  }

  getConditionsForAPIUpdate(prevState) {
    return (
      prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier ||
      prevState.typeFilterValue !== this.state.typeFilterValue ||
      prevState.subTypeFilterValue !== this.state.subTypeFilterValue ||
      prevState.allowedFilterValue !== this.state.allowedFilterValue ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.isAscending !== this.state.isAscending
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.getConditionsForAPIUpdate(prevState)) {
      await this.getDataFromAPI();
      this.setState({ waitingForAPIData: false });
    }

    if (prevState.typeFilterValue !== this.state.typeFilterValue) {
      let subTypeFilterValues = {};
      if (this.state.typeFilterValue) {
        const typeEnumId = this.state.resourceTypes.find((x) => x.displayText === this.state.typeFilterValue)?.id;
        const newSubTypeList = await ReferenceFileAPI.GetResourceSubTypesEnumForType(typeEnumId);
        if (newSubTypeList?.successful) {
          subTypeFilterValues = this.turnArrayIntoEnum(newSubTypeList?.data);
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to retrieve resources sub types",
            apiName: "GetResourceSubTypesEnumForType",
            responseUnsuccessful: newSubTypeList?.unsuccessful,
            responseMessage: newSubTypeList?.message,
          });
        }
      }
      this.setState({ subTypeFilterValues: subTypeFilterValues }, () => {
        let currentColumns = this.state.columns;
        let subTypeColumn = currentColumns.find((col) => col.dataField === "subType");
        subTypeColumn.filter = selectFilter({
          options: this.subTypeOptions,
          getFilter: (filter) => {
            this.setState({ subTypeFilter: filter });
          },
        });
        this.setState({ columns: currentColumns });
      });
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "resourcesTab") {
      this.setState({
        columns: this.getColumns(),
      });
      if (this.props.useGlobalData) {
        this.state.allowedFilter("");
      }
      this.setColumnHeadersBasedOnGlobalData();
      await this.getDataFromAPI();
      this.setState({ waitingForAPIData: false });
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "resourcesTab") {
      this.setState({
        columns: this.getColumns(),
      });

      await this.getEnumsFromAPI();
      await this.getDataFromAPI();
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ waitingForAPIData: false });

      if (this.props.history.location.state?.resourceCreateResponseData) {
        this.insertNewResourceFromResponse(this.props.history.location.state?.resourceCreateResponseData);
      }
      if (this.props.history.location.state?.resourceEditResponseData) {
        this.updateResourceFromResponse(this.props.history.location.state?.resourceEditResponseData);
      }
    }
  }

  async getEnumsFromAPI() {
    let typesFilterValues = {};
    let resourceUOMs = [];
    let apiValuationSettings = [];

    const getResourceTypesAction = await ReferenceFileAPI.GetResourceTypesEnum(this.controller.signal);
    if (getResourceTypesAction?.successful) {
      typesFilterValues = this.turnArrayIntoEnum(getResourceTypesAction?.data);
    }

    const resourceUomsResult = await ReferenceFileAPI.GetAllResourceUOMs(this.controller.signal);
    if (resourceUomsResult?.successful) {
      resourceUOMs = resourceUomsResult.data;
    }

    const getSettingsAction = await ReferenceFileAPI.GetAllResourceValuationSettings(this.controller.signal);
    if (getSettingsAction?.successful) {
      apiValuationSettings = getSettingsAction.data;
    }

    if (this.controller.signal.aborted) {
      return;
    }

    this.setState({
      typesFilterValues,
      resourceUOMs,
      apiValuationSettings,
      resourceTypes: getResourceTypesAction?.data,
    });
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("ResourcesTab.jsx got unmounted");
    }
  }

  async getDataFromAPI() {
    if (!this.state.currentUsaState || this.state.waitingForAPIData) {
      return;
    }

    this.setState({ waitingForAPIData: true });
    this.props.setIsLoading(true);

    let resources = [];
    let usaStateValue = null;
    let dateValue = "";

    if (!this.props?.useGlobalData && this.state?.currentUsaState?.stateGuid) {
      usaStateValue = this.state.currentUsaState.stateGuid;
    }
    if (this.state.lastUpdatedDateFilterValue !== "") {
      dateValue = moment(this.state.lastUpdatedDateFilterValue, "YYYY-MM-DD").utc().format("MM/DD/YYYY");
    }

    const getResourcesAction = await ReferenceFileAPI.GetFilteredResourcesTableData(
      this.state.typeFilterValue,
      this.state.subTypeFilterValue,
      this.state.nameFilterValue,
      this.state.allowedUOMsFilterValue,
      this.state.allowedFilterValue,
      dateValue,
      this.state.sortColumn,
      this.state.isAscending,
      usaStateValue,
      this.controller.signal
    );

    if (getResourcesAction?.unsuccessful || !getResourcesAction) {
      if (this.controller.signal.aborted) {
        return;
      }
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get filtered resources table data",
        apiName: "GetFilteredResourcesTableData",
        responseUnsuccessful: getResourcesAction?.unsuccessful,
        responseMessage: getResourcesAction?.message,
        signal: this.controller.signal,
      });
      this.props.setIsLoading(false);
      return;
    } else {
      if (this.props.useGlobalData) {
        resources = getResourcesAction.data.results;
      } else {
        resources = getResourcesAction.data.results?.filter((t) => t.isAllowedGlobally);
      }
    }

    let currentColumns = this.state.columns;
    let typeColumn = currentColumns.find((col) => col.dataField === "type");
    let subTypeColumn = currentColumns.find((col) => col.dataField === "subType");

    typeColumn.filter = selectFilter({
      options: this.state.typesFilterValues,
      defaultValue: 0,
      getFilter: (filter) => {
        this.state.typeFilter = filter;
      },
      onFilter: (value, data) => {
        if (value && this.state.subTypeFilteredBy !== value) {
          this.setState({ subTypeFilteredBy: value });
          return data.filter((resource) => resource.type === value);
        } else if (!value) {
          return data;
        }
      },
      onInput: (e) => {
        this.setState({ typeFilterValue: e.currentTarget.value, subTypeFilterValue: "" });
      },
    });
    subTypeColumn.filter = selectFilter({
      options: this.subTypeOptions,
      defaultValue: 0,
      getFilter: (filter) => {
        this.setState({ subTypeFilter: filter });
      },
      onInput: (e) => {
        this.setState({ subTypeFilterValue: e.currentTarget.value });
      },
    });

    this.setState(
      {
        resources: resources,
        columns: currentColumns,
      },
      () => {
        this.props.setIsLoading(false);
      }
    );
    window.scrollTo(0, 0);
  }

  setColumnHeadersBasedOnGlobalData() {
    let currentColumns = this.state.columns;
    let allowedColumn;
    let dateColumn;
    currentColumns.forEach((col) => {
      if (col.dataField === "isAllowedGlobally") {
        allowedColumn = col;
      }
      if (col.dataField === "isAllowed") {
        allowedColumn = col;
      }
      if (col.dataField === "lastModifiedDateTimeUTC") {
        dateColumn = col;
      }
    });
    if (allowedColumn) {
      allowedColumn.text = this.props.useGlobalData ? "Status" : "Allowed";
    }
    if (dateColumn) {
      dateColumn.text = this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date";
    }
  }

  insertNewResourceFromResponse = (createResourceData) => {
    let resourceTable = [...this.state.resources];
    if (resourceTable.some((m) => m.resourceUniqueIdentifier === createResourceData.resourceUniqueIdentifier)) {
      return;
    }
    resourceTable.push(createResourceData);
    this.setState({ resources: resourceTable });
  };

  updateResourceFromResponse = (updateResourceData) => {
    this.setState({
      resources: this.state.resources.map((s) => {
        if (s.resourceUniqueIdentifier === updateResourceData?.resourceUniqueIdentifier) {
          s = updateResourceData;
        }
        return s;
      }),
    });
  };

  turnArrayIntoEnum(array) {
    let result = {};
    array.sort((a, b) => a.displayText.localeCompare(b, undefined, { sensitivity: "base" }));
    for (var i = 0; i < array?.length; i++) {
      var current = array[i];
      result[current.displayText] = current.displayText;
    }
    return result;
  }

  handleCreateResourceClick = () => {
    this.state.history.push({
      pathname: "/referencefiles/resources/new",
      state: { globalData: this.props.useGlobalData },
    });
  };

  handleEditResourceClick = async (row) => {
    row.globalData = this.props.useGlobalData;
    var getGlobalResource = await ReferenceFileAPI.GetGlobalResource(row.resourceUniqueIdentifier);
    if (getGlobalResource?.successful) {
      this.state.history.push({
        pathname: "/referencefiles/resources/" + row.resourceUniqueIdentifier,
        state: { ...getGlobalResource.data, globalData: this.props.useGlobalData },
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get resources record",
        apiName: "GetGlobalResource",
        responseUnsuccessful: getGlobalResource?.unsuccessful,
        responseMessage: getGlobalResource?.message,
      });
    }
  };

  handleResourceHistory = (row) => {};

  closeEditResourceModal = () => {
    this.refreshSpecificResourceEntry(this.state.modalData.stateResource);
    this.setState({ showModal: false, modalData: null });
  };

  refreshSpecificResourceEntry(resourceChanged) {
    let resources = this.state.resources;
    let changedResource = resources.find(
      (x) => x.resourceUniqueIdentifier === resourceChanged.resourceUniqueIdentifier
    );
    let changedResourceIndex = resources.indexOf(changedResource);
    resources[changedResourceIndex] = resourceChanged;
    this.setState({ resources });
  }

  openEditResourceModal = async (row) => {
    let getGlobalResourceCall = await ReferenceFileAPI.GetGlobalResource(row.resourceUniqueIdentifier);

    if (getGlobalResourceCall?.successful) {
      let fullResourceObj = { globalResource: getGlobalResourceCall?.data, stateResource: row };
      this.setState({ showModal: true, modalData: fullResourceObj });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get resources record",
        apiName: "GetGlobalResource",
        responseUnsuccessful: getGlobalResourceCall?.unsuccessful,
        responseMessage: getGlobalResourceCall?.message,
      });
    }
  };

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleDateString("en-US");
  }

  getAllowedUOMs(row) {
    if (!row || !row.stateResourceValuations) {
      return "Not Available";
    } else {
      const allowedUOMs = row.stateResourceValuations.map((valuation) => valuation.unitOfMeasure.name);
      return allowedUOMs.join(",");
    }
  }

  handleClearFilters = () => {
    this.state.nameFilter("");
    this.state.subTypeFilter("");
    this.state.dateFilter("");
    this.state.typeFilter("");
    this.state.allowedFilter("");
    this.state.allowedUOMFilter("");
  };

  subTypeOptions = (e) => {
    return this.state.subTypeFilterValues;
  };

  render() {
    return (
      <ToolkitProvider
        keyField="resourceUniqueIdentifier"
        data={this.state.resources}
        columns={this.state.columns.length === 0 ? this.getColumns() : this.state.columns}
        bootstrap4={true}
        striped="true"
        columnToggle
        exportCSV={{
          fileName: "resource-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div data-testid="resourcesTab">
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Resource"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleCreateResourceClick}
                  useGlobalData={this.props.useGlobalData}
                  recordCount={this.state.resources.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="id"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.state.showModal && (
              <EditResourceModal
                show={this.state.showModal}
                onHide={this.closeEditResourceModal}
                resourceData={this.state.modalData}
                usaStateData={this.state.currentUsaState}
                apiValuationSettings={this.state.apiValuationSettings}
                updateResourceFromResponse={this.updateResourceFromResponse}
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(ResourcesTab);
