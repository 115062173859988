import React from "react";
import CustomForm from "../common/form";
import styles from "./Warehouse.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Form, Button, Row, Col, CloseButton } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import UtilityFunctions from "../common/UtilityFunctions";
import InventoryAPI from "../../api/Inventory/InventoryAPI";

const WarehouseTransactionTypes = {
  IncomingTransfer: 1,
  OutgoingTransfer: 2,
  Receive: 3,
  Loss: 5,
  Mixing: 7,
  Reconciliation: 8,
  Spent: 9,
};

class TransferInventoryModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        transferQuantity: "",
        transferState: "",
        transferWarehouse: "",
        transferWarehouseType: "",
        lossReason: "",
        reconciliationReason: "",
      },
      currentUsaState: "",
      availableWarehousesToTransfer: [],
      isInitialConfiguration: true,
      lossTypes: [],
      errors: {},
    };
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show && this.state.isInitialConfiguration) {
      const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
      if (!currentUsaState?.stateGuid) {
        toast.error("Error loading current State Id on Add Method Modal");
        return;
      }

      this.setState({ currentUsaState, isInitialConfiguration: false, lossTypes: await this.getLossTypes() });
    }
  }

  async getLossTypes() {
    let lossTypes = [];
    const lossTypesResponse = await InventoryAPI.GetAllCMITSLossTypes();
    if (lossTypesResponse?.successful && lossTypesResponse.data?.length > 0) {
      lossTypes = lossTypesResponse.data.map((t) => ({
        value: t.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(t),
      }));
    }

    return lossTypes;
  }

  async handleSpecificChanges(input) {
    if (["transferState", "transferWarehouseType"].includes(input.name) && input.value) {
      const { data } = this.state;
      const warehouseState = input.name === "transferState" ? input.value : data.transferState;
      const warehouseType = input.name === "transferWarehouseType" ? input.value : data.transferWarehouseType;

      if (warehouseState && warehouseType) {
        const warehousesResponse = await InventoryAPI.GetStateWarehousesByType(warehouseState, warehouseType);
        if (warehousesResponse?.successful) {
          const availableWarehousesToTransfer = warehousesResponse.data.results
            ?.filter((w) => w.warehouseUniqueIdentifier !== this.props.warehouseData?.warehouseUniqueIdentifier)
            .map((w) => ({
              id: w.warehouseUniqueIdentifier,
              name: w.warehouseName,
            }));
          this.setState({ availableWarehousesToTransfer });
        }
      }
    }
  }

  doSubmit = async () => {
    if (!this.isFormDataValid()) {
      const { transactionType } = this.props;
      if (
        transactionType === WarehouseTransactionTypes.IncomingTransfer ||
        transactionType === WarehouseTransactionTypes.OutgoingTransfer
      ) {
        toast.warning("Please select a quantity and state for the transfer.");
      } else if (transactionType === WarehouseTransactionTypes.Loss) {
        toast.warning("Please select a quantity and Reason for the loss.");
      } else if (transactionType === WarehouseTransactionTypes.Reconciliation) {
        toast.warning("Please select a quantity and Reason for the transaction.");
      } else {
        toast.warning("Please enter a valid quantity for the transaction.");
      }

      return;
    } else {
      this.props.onHide();
      this.props.onSave({ ...this.props.transferInventoryData, ...this.state.data });
      this.clearData();
    }
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.transferQuantity = "";
    data.transferState = "";
    data.transferWarehouseType = "";
    data.transferWarehouse = "";
    data.lossReason = "";
    data.reconciliationReason = "";
    this.setState({
      data,
      availableWarehousesToTransfer: [],
    });
  }

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    delete errors[fieldName];
    this.setState({ data, errors });
  };

  isFormDataValid() {
    const { data } = this.state;
    const { transactionType } = this.props;
    let isFormDataValid = true;
    if (
      transactionType === WarehouseTransactionTypes.IncomingTransfer ||
      transactionType === WarehouseTransactionTypes.OutgoingTransfer
    ) {
      isFormDataValid = data.transferQuantity && data.transferState && data.transferWarehouseType;
    } else if (transactionType == WarehouseTransactionTypes.Loss) {
      isFormDataValid = data.transferQuantity && data.lossReason;
    } else if (transactionType == WarehouseTransactionTypes.Reconciliation) {
      isFormDataValid = data.transferQuantity && data.reconciliationReason;
    } else {
      isFormDataValid = data.transferQuantity;
    }

    return isFormDataValid;
  }

  getModalTitle(transactionType) {
    let transactionTitle = "";
    switch (transactionType) {
      case WarehouseTransactionTypes.Receive:
        transactionTitle = "Receive";
        break;
      case WarehouseTransactionTypes.Loss:
        transactionTitle = "Enter Loss";
        break;
      default:
        transactionTitle = "Transfer";
        break;
    }
    return transactionTitle;
  }

  render() {
    const { transferInventoryData, transactionType } = this.props;
    const { data } = this.state;
    const isWarehouseTypeRequired =
      transactionType === WarehouseTransactionTypes.IncomingTransfer ||
      transactionType === WarehouseTransactionTypes.OutgoingTransfer;

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>{this.getModalTitle(transactionType)}</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0">
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Method Name</Form.Label>
                <Form.Control className={globalStyles.formData} value={transferInventoryData.methodName} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>UOM</Form.Label>
                <Form.Control
                  className={globalStyles.formData}
                  value={UtilityFunctions.getDisplayTextFromFieldObject(transferInventoryData.methodUOM)}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col>{this.renderInput("transferQuantity", "Qty", "number", "Enter", "required")}</Col>
          </Row>
          {isWarehouseTypeRequired && (
            <div>
              <Row className="mx-0 my-2">
                <Col>
                  <span className={styles.transferModalSubtitle}>
                    {transactionType === WarehouseTransactionTypes.IncomingTransfer ? "Transfer From:" : "Transfer To:"}
                  </span>
                </Col>
              </Row>
              <Row className="mx-0">
                <Col>
                  {this.renderSelect("transferState", "State", this.props.availableStates, "Select", "required")}
                </Col>
                <Col>
                  {this.renderSelect(
                    "transferWarehouseType",
                    "Type",
                    this.props.warehouseTypes,
                    "Select",
                    isWarehouseTypeRequired ? "required" : ""
                  )}
                </Col>
              </Row>
              <Row className="mx-0">
                <Col>
                  {data.transferState && data.transferWarehouseType ? (
                    this.renderSelect(
                      "transferWarehouse",
                      "Warehouse",
                      this.state.availableWarehousesToTransfer,
                      "Select",
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>
                        Warehouse
                        {data.transferState && data.transferWarehouseType ? (
                          <span className={globalStyles.asterisk508}>*</span>
                        ) : (
                          ""
                        )}
                      </Form.Label>
                      <Form.Control placeholder="Select" className={globalStyles.formData} value="" readOnly />
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </div>
          )}
          <Row className="mx-0">
            <Col>
              <Form.Group>
                {transactionType === WarehouseTransactionTypes.Loss && (
                  <React.Fragment>
                    <Form.Label className={globalStyles.formLabel}>
                      Reason<span className={globalStyles.asterisk508}>*</span>
                    </Form.Label>
                    <Select
                      aria-label="Loss Reason Select"
                      name="lossReason"
                      placeholder="Select"
                      isClearable
                      value={data.lossReason}
                      onChange={(e) => this.handleSelectChanges(e, "lossReason")}
                      styles={{
                        indicatorSeparator: () => {
                          //do nothing
                        },
                        placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                        option: (styles, { isFocused }) => ({
                          ...styles,
                          fontSize: "14px",
                          fontWeight: "normal",
                          color: "black",
                          backgroundColor: isFocused ? "lightgray" : "white",
                        }),
                        singleValue: (styles) => ({
                          ...styles,
                          fontSize: "14px",
                          fontWeight: "normal",
                          color: "black",
                        }),
                        menuList: (styles) => ({ ...styles, textAlign: "left" }),
                      }}
                      options={this.state.lossTypes}
                    />
                  </React.Fragment>
                )}
                {transactionType === WarehouseTransactionTypes.Reconciliation && (
                  <Form.Control
                    aria-label="Reconciliation Reason"
                    name="reconciliationReason"
                    placeholder="Enter"
                    value={data.reconciliationReason}
                    onChange={this.handleChange}
                    as="textarea"
                    rows={3}
                  />
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button
            className={this.isFormDataValid() ? "ml-2" : styles.greyedOutModalSaveButton}
            variant="primary"
            type="submit"
            onClick={this.doSubmit}
          >
            <span className={this.isFormDataValid() ? globalStyles.modalSubmitButtonText : styles.greyedOutButtonText}>
              Submit
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default TransferInventoryModal;
