import React from "react";
import styles from "./Warehouse.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Row, Col, Form, Button, CloseButton } from "react-bootstrap";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import CustomForm from "../common/form";
import InventoryAPI from "../../api/Inventory/InventoryAPI";
import UtilityFunctions from "../common/UtilityFunctions";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import searchIcon from "../../assets/search.svg";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";

const WarehouseTypes = {
  State: 1,
  Property: 2,
  Cooperator: 3,
  Employee: 4,
};

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class CreateWarehouseModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        warehouseName: "",
        warehouseType: "",
        streetAddress: "",
        city: "",
        warehouseState: "",
        zipCode: "",
        country: "",
        isActive: true,
        description: "",
        warehouseManager: "",
      },
      isModalInitialized: false,
      availableWarehouseTypes: [],
      availableStates: [],
      availableCountries: [],
      errors: {},
    };
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show && !this.state.isModalInitialized) {
      const data = { ...this.state.data };
      const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
      this.validateStateData(currentUsaState);
      if (currentUsaState?.stateGuid) {
        data.warehouseState = currentUsaState.stateGuid;
      }
      const availableStates =
        this.props.availableStates?.length > 0
          ? this.props.availableStates.map((s) => ({ id: s.value, name: s.label }))
          : [];
      const availableCountries = await this.getAllCountriesFromAPI();
      data.country =
        availableCountries?.length > 0
          ? availableCountries.find((country) => country.name === "United States of America")?.id
          : "";

      this.setState({
        data,
        availableWarehouseTypes: await this.getAvailableWarehouseTypes(),
        currentUsaState,
        availableStates,
        availableCountries,
        isModalInitialized: true,
      });
    }
  }

  async getAvailableWarehouseTypes() {
    let availableWarehouseTypes = [];
    const warehouseTypesResponse = await InventoryAPI.GetAllWarehouseTypes();
    if (warehouseTypesResponse?.successful && warehouseTypesResponse.data?.length > 0) {
      availableWarehouseTypes = warehouseTypesResponse.data.map((wh) => ({
        id: wh.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(wh),
      }));
    }
    return availableWarehouseTypes;
  }

  async getAllCountriesFromAPI() {
    let countriesData = [];
    const allCountriesData = await ReferenceFileAPI.GetAllCountries();
    if (allCountriesData?.length > 0) {
      countriesData = allCountriesData
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return countriesData;
  }

  loadAvailableUserProfiles = async (searchValue) => {
    let userProfiles = [];
    const usersSearchResponse = await RoleAuthorizationAPI.SearchUsersFreeText(this.getSearchUsersBody(searchValue));
    if (usersSearchResponse?.successful && usersSearchResponse.data?.results?.length > 0) {
      for (let user of usersSearchResponse.data.results) {
        if (user.userProfiles?.length > 0) {
          userProfiles = [...userProfiles, ...user.userProfiles];
        }
      }
    }
    return userProfiles?.length > 0
      ? userProfiles.map((profile) => ({
          value: profile.wsUserProfileUniqueIdentifier,
          label: `${profile.profileName} - ${profile.firstName} ${profile.lastName}`,
        }))
      : [];
  };

  getSearchUsersBody(inputValue) {
    return {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Warehouse modal from there.",
        {
          autoClose: 8000,
        }
      );
    }
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "solid 0.5px #D8DDE6",
      };
    },
  };

  handleSpecificChanges(input) {
    //do nothing
  }

  handleWhStatusChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isActive = input.checked;
    this.setState({ data });
  };

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    delete errors[fieldName];
    this.setState({ data, errors });
  };

  async isFormDataValid() {
    const { warehouseName, warehouseType, warehouseState, warehouseManager } = this.state.data;
    let isValidData = warehouseName && warehouseType && warehouseState && warehouseManager?.value;
    let isDuplicatedManager = true;
    if (Number.parseInt(warehouseType) === WarehouseTypes.Employee) {
      const employeeWarehousesResponse = await InventoryAPI.GetStateWarehousesByType(warehouseState, warehouseType);
      if (employeeWarehousesResponse?.successful && employeeWarehousesResponse.data?.results?.length > 0) {
        isDuplicatedManager = employeeWarehousesResponse.data.results.find(
          (wh) => wh.warehouseManagerWSUserProfileUniqueReference === warehouseManager?.value
        );
        if (isDuplicatedManager) {
          toast.warning(
            "The selected Manager is already associated to another Employee Warehouse in the state. Please select a new one."
          );
        }
        isValidData = isValidData && !isDuplicatedManager;
      }
    }

    return isValidData;
  }

  checkForInvalidData() {
    const { data, errors } = this.state;
    if (!data.warehouseName) {
      errors.warehouseName = "You must enter a valid Warehouse name.";
    }
    if (!data.warehouseType) {
      errors.warehouseType = "You must select a valid Warehouse type.";
    }
    if (!data.warehouseState) {
      errors.warehouseState = "You must select a State.";
    }
    if (!data.warehouseManager?.value) {
      errors.warehouseManager = "You must select a manager for the warehouse.";
    }
    this.setState(errors);
  }

  async doSubmit() {
    const { data } = this.state;
    const isDataValid = await this.isFormDataValid();
    if (!isDataValid) {
      this.checkForInvalidData();
    } else {
      this.props.onCreateWarehouse({ ...data });
      this.clearData(data);
      this.props.onHide();
      this.setState({ data });
    }
  }

  handleModalCancel = () => {
    const { data } = this.state;
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  clearData(data) {
    data.warehouseName = "";
    data.warehouseType = "";
    data.streetAddress = "";
    data.city = "";
    data.warehouseState = "";
    data.zipCode = "";
    data.country = "";
    data.description = "";
    data.warehouseManager = "";
  }

  render() {
    const { data, errors } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.modalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader}>
            <Row className={globalStyles.modalHeaderRow}>
              <Col className="px-0">
                <Modal.Title className={globalStyles.modalTitleText}>Create Warehouse</Modal.Title>
              </Col>
              <Col>
                <CloseButton onClick={this.handleModalCancel} />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <Row className="mx-0">
              <Col>{this.renderInput("warehouseName", "Warehouse Name", "text", "Enter Name", "required")}</Col>
            </Row>
            <Row className="mx-0">
              <Col>
                {this.renderSelect(
                  "warehouseType",
                  "Warehouse Type",
                  this.state.availableWarehouseTypes,
                  "Select",
                  "required"
                )}
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Warehouse Manager<span className={globalStyles.asterisk508}> *</span>
                  </Form.Label>
                  <AsyncSelect
                    placeholder="Search"
                    aria-label="Manager Search"
                    value={data.warehouseManager}
                    styles={this.customSelectStyles}
                    components={{ DropdownIndicator }}
                    loadOptions={this.loadAvailableUserProfiles}
                    onChange={(e) => this.handleSelectChanges(e, "warehouseManager")}
                  />
                  {errors.warehouseManager && <div className="alert alert-danger">{errors.warehouseManager}</div>}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderInput("streetAddress", "Address", "text", "Enter Address")}</Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderInput("city", "City", "text", "Enter City")}</Col>
              <Col>
                {this.renderSelect("warehouseState", "State", this.state.availableStates, "Select", "required")}
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderInput("zipCode", "Zipcode", "number", "Enter ZIP")}</Col>{" "}
              <Col>{this.renderSelect("country", "Country", this.state.availableCountries, "Select")}</Col>
            </Row>
            <Row className="mx-0 mb-2">
              <Col>
                <Form.Check type="checkbox" id="isWarehouseActive">
                  <Form.Check.Input type="checkbox" checked={data.isActive} onChange={this.handleWhStatusChange} />
                  <Form.Check.Label>
                    <span className={globalStyles.formLabel}>Active</span>
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Description</Form.Label>
                  <Form.Control
                    name="description"
                    placeholder="Enter Description"
                    type="text"
                    as="textarea"
                    rows={3}
                    className={globalStyles.formData}
                    value={data.description}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit">
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default CreateWarehouseModal;
