import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { selectFilter, customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { withRouter } from "react-router-dom";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CustomTextFilter from "../common/customTextFilter";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CreateEditAircraftModal from "../ReferenceFilesCreatePages/CreateEditAircraftModal";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import UtilityFunctions from "../common/UtilityFunctions";

class AircraftTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aircraftData: [],
      defaultSort: [{ dataField: "aircraftTailNumber", order: "asc" }],
      columns: [
        {
          dataField: "aircraftUniqueIdentifier",
          hidden: true,
          csvText: "Aircraft Unique Id",
        },
        {
          dataField: "aircraftTailNumber",
          text: "Tail Number",
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter onFilter={onFilter} column={column} title="Tail Number" />
          ),
          sort: true,
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3" };
          },
          headerAttrs: { id: "tailNumberHeader" },
          attrs: { headers: "tailNumberHeader", tabIndex: "0" },
        },
        {
          dataField: "aircraftType",
          text: "Type",
          formatter: (cell, row, rowIndex) => {
            let typeName = "";
            if (row.aircraftType) {
              typeName = UtilityFunctions.getDisplayTextFromFieldObject(row.aircraftType);
            }
            return typeName;
          },
          csvFormatter: (cell) => {
            return cell?.displayText;
          },
          sort: true,
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3" };
          },
          style: this.tableTextStyle,
          headerAttrs: { id: "aircraftTypeHeader" },
          attrs: { headers: "aircraftTypeHeader", tabIndex: "0" },
        },
        {
          dataField: "isAllowedInState",
          text: "Status",
          formatter: (cell, row, rowIndex) => {
            return row.isAllowedInState ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          },
          csvFomatter: (cell, row, rowIndex) => {
            return row.isAllowedInState ? "Active" : "Inactive";
          },
          filter: selectFilter({
            options: { 1: "Active", 2: "Inactive" },
            onFilter: this.filterAircraftStatusColumn,
          }),
          sort: true,
          sortFunc: (a, b, order) => {
            if (order === "asc") {
              return b - a;
            }
            return a - b; // desc
          },
          align: "left",
          headerAlign: "left",
          style: this.tableTextStyle,
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          headerAttrs: { id: "aircraftStatusHeader" },
          attrs: { headers: "aircraftStatusHeader", tabIndex: "0" },
        },
        {
          dataField: "lastModifiedDateTimeUTC",
          text: "Last Updated Date",
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return new Date(cell).toLocaleDateString("en-US");
          },
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Date Last Updated" />
          ),
          sort: true,
          sortFunc: (a, b, order) => {
            let dateA = new Date(a);
            let dateB = new Date(b);
            if (order === "asc") {
              return dateB - dateA;
            }
            return dateA - dateB; // desc
          },
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          style: this.tableTextStyle,
          headerAttrs: { id: "lastModifiedDateHeader" },
          attrs: { headers: "lastModifiedDateHeader", tabIndex: "0" },
        },
        {
          dataField: "aircraftActions",
          text: "Actions",
          csvExport: false,
          editable: false,
          formatter: (cell, row) => {
            return (
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton
                  id="dropdown-aircraft-actions"
                  title="Actions"
                  variant="link"
                  className={styles.buttonLink}
                >
                  <Dropdown.Item
                    eventKey="aircraftEdit"
                    onSelect={() => this.handleAircraftEdit(row)}
                    hidden={!this.props.permissions.canIEditReferenceData()}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="aircraftHistoryTracking" onSelect={() => this.handleAircraftHistory(row)}>
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            );
          },
          headerStyle: () => {
            return {
              width: "40%",
              backgroundColor: "#f3f3f3",
              verticalAlign: "text-top",
              paddingRight: "2rem",
            };
          },
          align: "right",
          headerAlign: "right",
          headerAttrs: {
            id: "aircraftActionsHeader",
            title: "Actions Column Header",
          },
          attrs: { headers: "aircraftActionsHeader" },
        },
      ],
      showCreateModal: false,
      showConfigureModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
      currentUsaState: props.currentUsaState,
      aircraftTypes: [],
      aircraftTypeFilterArray: [],
      typeFilter: null,
    };
  }

  async componentDidMount() {
    if (this.props.currentTab === "aircraftTab") {
      await this.updateAircraftData();
    }

    let aircraftTypes = await this.getAircraftTypes();
    if (!aircraftTypes || aircraftTypes?.unsuccessful) {
      aircraftTypes = [];
    }
    this.setState({
      aircraftTypes,
      columns: this.getUpdatedColumns(aircraftTypes),
    });
  }

  getUpdatedColumns(aircraftTypes) {
    let aircraftTypeObject = {};
    aircraftTypes.forEach((t) => {
      const value = t.displayText ? t.displayText : t.name;
      aircraftTypeObject[t.id] = value;
    });

    const { columns } = this.state;
    let typeColumn = columns.find((col) => col.dataField === "aircraftType");
    let typesFilterValues = aircraftTypeObject;
    typeColumn.filter = selectFilter({
      options: typesFilterValues,
      onFilter: this.filterAircraftTypeColumn,
    });

    return columns;
  }

  async getAircraftTypes() {
    let aircraftTypes = [];
    const aircraftTypesResponse = await ReferenceFileAPI.GetAllAircraftType();
    if (aircraftTypesResponse?.successful && aircraftTypesResponse.data?.length > 0) {
      aircraftTypes = aircraftTypesResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve aircraft types",
        apiName: "GetAllAircraftType",
        responseUnsuccessful: aircraftTypesResponse?.unsuccessful,
        responseMessage: aircraftTypesResponse?.message,
      });
    }
    return aircraftTypes;
  }

  async componentDidUpdate(prevProps, prevState) {
    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;

    if (
      prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier ||
      (propsChange && this.props.currentTab === "aircraftTab")
    ) {
      await this.updateAircraftData();
    }
  }

  async updateAircraftData() {
    this.props.setIsLoading(true);
    const aircraftData = await this.getDataFromAPI();
    this.setState({ aircraftData }, () => {
      this.props.setIsLoading(false);
    });
    window.scrollTo(0, 0);
  }

  async getDataFromAPI() {
    let aircraftResults = [];
    if (this.state.currentUsaState?.stateGuid) {
      const getAircraftsResponse = await ReferenceFileAPI.GetAircraftsByState(this.state.currentUsaState.stateGuid);
      if (getAircraftsResponse?.successful) {
        aircraftResults = getAircraftsResponse.data ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve aircrafts list",
          apiName: "GetAircraftsByState",
          responseUnsuccessful: getAircraftsResponse?.unsuccessful,
          responseMessage: getAircraftsResponse?.message,
        });
      }
    }
    return aircraftResults;
  }

  insertNewAircraftFromResponse(createAircraftData) {
    let aircraftTable = this.state.aircraftData;
    aircraftTable.push(createAircraftData);
    this.setState({ aircraftData: aircraftTable });
  }

  updateAircraftFromResponse(updateAircraftData) {
    let aircraftTable = this.state.aircraftData;
    for (let i = 0; i < aircraftTable.length; i++) {
      if (aircraftTable[i].aircraftUniqueIdentifier === updateAircraftData?.aircraftUniqueIdentifier) {
        aircraftTable[i] = updateAircraftData;
        break;
      }
    }
    this.setState({ aircraftData: aircraftTable });
  }

  onModalSubmit = async (newAircraftData, mode) => {
    if (mode === "Create") {
      const createAircraftBody = {
        aircraftTailNumber: newAircraftData.aircraftTailNumber,
        aircraftTypeEnumId: newAircraftData.selectedAircraftType,
        aircraftStateUniqueReference: this.state.currentUsaState?.stateGuid,
        isAllowedInState: true,
      };
      const createAircraftResponse = await ReferenceFileAPI.CreateAircraft(createAircraftBody);
      if (createAircraftResponse?.successful) {
        this.insertNewAircraftFromResponse(createAircraftResponse.data);
        this.setState({ showCreateSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create aircraft",
          apiName: "CreateAircraft",
          responseUnsuccessful: createAircraftResponse?.unsuccessful,
          responseMessage: createAircraftResponse?.message,
        });
      }
    } else if (mode === "Edit") {
      const editAircraftBody = {
        aircraftTailNumber: newAircraftData.aircraftTailNumber,
        aircraftTypeEnumId: newAircraftData.selectedAircraftType,
        aircraftStateUniqueReference: this.state.currentUsaState?.stateGuid,
        isAllowedInState: newAircraftData.isAllowedInState,
      };
      const editAircraftResponse = await ReferenceFileAPI.UpdateAircraft(
        newAircraftData.aircraftUniqueIdentifier,
        editAircraftBody
      );
      if (editAircraftResponse?.successful) {
        this.updateAircraftFromResponse(editAircraftResponse.data);
        this.setState({ showEditSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve aircraft types",
          apiName: "GetAllAircraftType",
          responseUnsuccessful: editAircraftResponse?.unsuccessful,
          responseMessage: editAircraftResponse?.message,
        });
      }
    }
  };

  handleAircraftCreate = () => {
    this.setState({ showCreateModal: true });
  };

  handleAircraftConfigure = (row) => {
    this.setState({ modalData: row, showConfigureModal: true });
  };

  handleAircraftEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleAircraftHistory = (row) => {};

  closeAllModals = () => {
    this.setState({ showCreateModal: false, showEditModal: false, showConfigureModal: false, modalData: null });
  };

  renderCreateModal() {
    return (
      <CreateEditAircraftModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode="Create"
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
        onSubmit={this.onModalSubmit}
        aircraftTypes={this.state.aircraftTypes}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditAircraftModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode="Edit"
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        onSubmit={this.onModalSubmit}
        aircraftTypes={this.state.aircraftTypes}
      />
    );
  }

  renderConfigureModal() {
    return <div></div>;
  }

  filterAircraftTypeColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) => entry.aircraftType?.id === Number.parseInt(filterValue));
    } else {
      return data;
    }
  };

  filterAircraftStatusColumn = (filterValue, data) => {
    if (filterValue === "1") {
      return data.filter((entry) => entry.isAllowedInState);
    } else if (filterValue === "2") {
      return data.filter((entry) => !entry.isAllowedInState);
    } else {
      return data;
    }
  };

  renderModals = () => {
    return (
      <React.Fragment>
        {this.state.showCreateModal ? this.renderCreateModal() : null}
        {this.state.showEditModal ? this.renderEditModal() : null}
        {this.state.showConfigureModal ? this.renderConfigureModal() : null}
      </React.Fragment>
    );
  };

  render() {
    return (
      <ToolkitProvider
        keyField="aircraftUniqueIdentifier"
        data={this.state.aircraftData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "aircraft-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Aircraft"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleAircraftCreate}
                  recordCount={this.state.aircraftData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="aircraftUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.renderModals()}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="Aircraft"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="Aircraft"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(AircraftTab);
