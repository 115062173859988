import React from "react";
import styles from "./Login.module.scss";
import { Form, Button, Image, Card, Row, Col } from "react-bootstrap";
import LoginArt from "../../assets/loginArt.png";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import CustomForm from "../common/form";
import UserRolesModal from "../UserRoles/UserRolesModal";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import lsm from "../../api/lsm";

export const LoginFormModes = {
  Login: "Log In",
  HelpDesk: "Help Desk Request",
  RecoverUserID: "Recover User ID",
  RecoverPassword: "Recover Password",
  PasswordReset: "Password Reset",
};

class Login extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode,
      history: this.props.history,
      showUserRolesModal: false,
      authWithCookies: false,
      authProvider: "",
      user: "",
      userProfile: "",
      usaStates: [],
    };
  }
  async componentDidMount() {
    this.setState({ authProvider: (await this.getAuthConfig())?.data });
    this.setState({ authWithCookies: (await this.getAuthConfig())?.data === "LocalCookie" });
    if (this.state.authWithCookies === true) {
      this.setState({ email: "test@usda.gov", displayName: "Test User" })
    }
    else {
      await this.authenticateWithToken();
    }
    // const token = await this.getToken();
    // if (token?.successful) {
    //   sessionStorage.setItem("isUserAuthenticated", true);
    //   sessionStorage.setItem("token", token?.data?.token);
    //   console.log("Set stuff");
    // }
    // else {
    //   sessionStorage.removeItem("isUserAuthenticated");
    //   sessionStorage.removeItem("token");
    //   console.log("Clear Token from local storage");
    // }
    // if (!sessionStorage.getItem("isUserAuthenticated")) {
    //   sessionStorage.setItem("logonLoad", true);
    // } else {
    //   await this.authenticateWithToken();
    // }
  }

  componentWillUnmount() {
    this.setState({ showUserRolesModal: false });
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  async getAvailableStates(countryId) {
    const results = await ReferenceFileAPI.GetAllStates();
    let returnStates = [];

    if (results?.length > 0) {
      returnStates = results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_name,
        }))
        ?.sort((a, b) => (a.state_name < b.state_name ? -1 : 1));
    }
    return returnStates;
  }


  authenticateWithToken = async () => {
    const token = await this.getToken();
    if (token?.successful) {
      console.log("We got a token from the Role Auth service. We can store this in local storage and then prompt for the profile selection.");
      lsm.SetUserAuthenticated(token?.data?.token);
      await this.checkAndSetUserProfiles();
    } // it will wait here untill function a finishes
    else if (token?.unsuccessful === true) {
      lsm.SetUserNotAuthenticated();
      ErrorHandler.showErrorWithDetails(
        "Could not retrieve token for current user. GetTokenForCurrentUser call failed. " + token?.message,
        "Could not retrieve token for current user. " + token?.message
      );
    } else {
      lsm.SetUserNotAuthenticated();
      // ErrorHandler.showErrorWithDetails(
      //   "Could not retrieve token for current user. GetTokenForCurrentUser call failed.",
      //   "Could not retrieve token for current user. Please try again later or if the issue persists contact your system admin."
      // );
    }
  };

  getAuthConfig = async () => {
    return (await RoleAuthorizationAPI.GetAuthConfig());
  };

  getToken = async () => {
    return await RoleAuthorizationAPI.GetTokenForCurrentUser();
  };

  getTokenWithContext = async (profileId, stateId) => {
    return await RoleAuthorizationAPI.GetTokenWithUserContext(profileId, stateId);
  };

  renderLoginForm() {
    let element;
    switch (this.state.mode) {
      case LoginFormModes.HelpDesk:
        element = this.renderHelpDeskForm();
        break;
      case LoginFormModes.RecoverUserID:
        element = this.renderUserIDRecoveryForm();
        break;
      case LoginFormModes.RecoverPassword:
        element = this.renderPasswordRecoveryForm();
        break;
      case LoginFormModes.PasswordReset:
        element = this.renderPasswordResetForm();
        break;
      default:
        element = this.renderBaseLoginForm();
        break;
    }
    return element;
  }

  loginFormState(newMode) {
    this.setState({ mode: newMode });
  }

  handleCookieLoginClick = async () => {
    const { email, displayName } = this.state;
    if (email && displayName) {
      const cookieSigninResponse = await RoleAuthorizationAPI.LocalCookieSignin(email, email, displayName);
      if (cookieSigninResponse?.successful) {
        await this.authenticateWithToken();
      } else {
        lsm.SetUserNotAuthenticated();
        ErrorHandler.showError("LocalCookie signin failed: " + cookieSigninResponse?.message);
      }
    } else {
      ErrorHandler.showError("Please log in with valid credentials.");
    }
  };
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleGoToHomepage = () => {
    window.location = "/home";
    this.state.history.push({
      pathname: "/home",
      state: {},
    });
  };

  handleDisplayNameChange = (e) => {
    this.setState({ displayName: e.target.value });
  };

  checkAndSetUserProfiles = async () => {
    const user = await this.getUser();
    if (!user) {
      return;
    } else {
      const countries = await this.getAllCountriesFromAPI();
      if (countries?.length < 1) {
        toast.error("Could not retreive countries from database.");
        return;
      }
      const usaObject = countries.find((country) => country?.name === "United States of America");
      const states = await this.getAvailableStates(usaObject?.id);
      if (states?.length < 1) {
        toast.error("Could not retreive states from database.");
        return;
      }
      this.setState({ usaStates: states });
    }
    const userProfile = await RoleAuthorizationAPI.GetUsersCurrentProfile();
    this.setState({ user: user, userProfile: userProfile });
    const userProfiles = user?.userProfiles;
    if (userProfiles?.length > 1) {
      this.setState({ showUserRolesModal: true });
    } else if (userProfiles?.length === 1) {
      if (userProfiles[0]) {
        const states = userProfiles[0]?.profileAssociatedStates;
        if (states?.length > 1) {
          this.setState({ showUserRolesModal: true });
        } else {
          const r = await this.getTokenWithContext(
            user?.userProfiles[0]?.wsUserProfileUniqueIdentifier,
            user?.userProfiles[0]?.profileAssociatedStates[0]
          );
          if (r?.successful) {
            lsm.SetUserAuthenticated(r.data?.token);
            localStorage.setItem(
              "currentState",
              JSON.stringify({
                stateGuid: user?.userProfiles[0]?.profileAssociatedStates[0],
                stateName: this.state.usaStates?.find(
                  (usaState) => usaState?.id === user?.userProfiles[0]?.profileAssociatedStates[0]
                )?.name,
              })
            );
            localStorage.setItem(
              "currentUserRole",
              JSON.stringify({
                profileName: user?.userProfiles[0]?.profileName,
                roleUniqueReference: user?.userProfiles[0]?.roleUniqueReference,
                userName: user?.firstName + " " + user?.lastName,
                wsUserProfileUniqueIdentifier: user?.userProfiles[0]?.wsUserProfileUniqueIdentifier,
                wsUserUniqueIdentifier: user?.wsUserUniqueIdentifier,
                mailingAddress: {
                  mailingStreet: user?.streetAddress,
                  mailingCity: user?.city,
                  mailingZip: user?.zip,
                  mailingStateUniqueId: user?.stateOfAddressUniqueReference,
                },
                phoneNumber: user?.primaryPhoneNumber,
              })
            );

            this.handleGoToHomepage();
          } else if (r?.unsuccessful) {
            toast.error(r?.message);
          } else {
            toast.error("Failed to set user profile.");
          }
        }
      } else {
        this.setState({ showUserRolesModal: true });
      }
    } else {
      ErrorHandler.showError(`User has no profiles. Please contact your System Admin to add profiles to your account.`);
      return false;
    }
  };

  async getUser() {
    let user = "";
    const userResponse = await RoleAuthorizationAPI.GetCurrentUser();
    if (userResponse?.successful) {
      user = userResponse.data;
    } else {
      ErrorHandler.showError(
        `System failed to get logged in user. ${userResponse?.message ? userResponse.message : ""}`
      );
    }
    return user;
  }
  redirectToEAuthPage = async () => {
    console.log("redirect");
    window.location = window.location.origin + "/roleauth/Login?returnUrl=/";
    // const isUserAuthenticated = JSON.parse(localStorage.getItem("isUserAuthenticated"));
    // if (!isUserAuthenticated) {

    // } else {
    //   const currentState = JSON.parse(localStorage.getItem("currentState"));
    //   if (!currentState) {
    //     //show user profile
    //     this.setState({ showUserRolesModal: true });
    //   }
    // }
  };

  renderBaseLoginForm() {
    return (
      <Form className="page-center">
        <div style={{ backgroundColor: 'red', padding: '20px', color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
          This is the legacy UI. <br /> <a href="#" style={{ color: 'white' }} onClick={() => {
            localStorage.clear();
            window.location.href = "/next/login";
          }}>Click here to go to the NextJS UI
          </a>
        </div>
        <Row className={styles.helpGroup} hidden={!this.state.authWithCookies}>
          <Col className="px-0 text-left">
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                data-testid="loginEmail"
                type="email"
                aria-label="Enter Email text input"
                placeholder="Enter your email"
                defaultValue={"test@usda.gov"}
                onChange={this.handleEmailChange}
              />
              <Button
                data-testid="recoverUserId"
                className={styles.loginLink}
                variant="link"
                onClick={() => this.loginFormState(LoginFormModes.RecoverUserID)}
              >
                Forgot User ID
              </Button>
            </Form.Group>
            <Form.Group controlId="formBasicDisplayName">
              <Form.Control
                data-testid="loginPassword"
                aria-label="Enter Name text input"
                type="text"
                placeholder="Enter Display Name"
                defaultValue={"Test user"}
                onChange={this.handleDisplayNameChange}
              />
            </Form.Group>
            <Button
              data-testid="loginButton"
              className={styles.submitButton}
              variant="primary"
              type="button"
              onClick={this.handleCookieLoginClick}
            >
              Log In
            </Button>
          </Col>
        </Row>
        <Row className={styles.helpGroup} hidden={this.state.authWithCookies}>
          <Col className="px-0 text-left">
            <Button variant="primary" className={styles.submitButton} type="button" onClick={this.redirectToEAuthPage}>
              {this.state.authProvider} Login
            </Button>
          </Col>
        </Row>
        <Row className={styles.helpGroup}>
          <Col className="px-0 text-left">
            <Button data-testid="faqLink" variant="link" onClick={this.props.faqShowing}>
              FAQ
            </Button>
          </Col>
          <Col className="px-0 text-right">
            <Button
              data-testid="helpDeskLink"
              variant="link"
              onClick={() => this.loginFormState(LoginFormModes.HelpDesk)}
            >
              Help Desk
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  renderHelpDeskForm() {
    return (
      <Form className="page-center">
        <Form.Group controlId="formBasicEmail">
          <Form.Control data-testid="helpDeskEmail" type="email" placeholder="Enter your email" />
        </Form.Group>
        <Form.Group controlId="formBasicDescription">
          <Form.Control data-testid="helpDeskDescription" as="textarea" placeholder="Description" />
        </Form.Group>
        <Button data-testid="helpDeskSubmit" className={styles.submitButton} variant="primary" type="submit">
          Submit Help Request
        </Button>
        <div className={styles.helpGroup}>
          <Button
            data-testid="helpDeskBackToLogin"
            variant="link"
            onClick={() => this.loginFormState(LoginFormModes.Login)}
          >
            Login
          </Button>
        </div>
      </Form>
    );
  }

  renderUserIDRecoveryForm() {
    return (
      <Form className="page-center">
        <Form.Group controlId="formBasicEmail">
          <Form.Control data-testid="useridRecoverEmail" type="email" placeholder="Enter your email" />
        </Form.Group>
        <Button data-testid="useridRecoverSubmit" className={styles.submitButton} variant="primary" type="submit">
          Submit Request
        </Button>
        <div className={styles.helpGroup}>
          <Button
            data-testid="useridRecoverBackToLogin"
            variant="link"
            onClick={() => this.loginFormState(LoginFormModes.Login)}
          >
            Login
          </Button>
        </div>
      </Form>
    );
  }

  renderPasswordRecoveryForm() {
    return (
      <Form className="page-center">
        <Form.Group controlId="formBasicEmail">
          <Form.Control data-testid="passwordRecoverEmail" type="email" placeholder="Enter your email" />
        </Form.Group>
        <Button data-testid="passwordRecoverSubmit" className={styles.submitButton} variant="primary" type="submit">
          Submit Request
        </Button>
        <div className={styles.helpGroup}>
          <Button
            data-testid="passwordRecoverBackToLogin"
            variant="link"
            onClick={() => this.loginFormState(LoginFormModes.Login)}
          >
            Login
          </Button>
        </div>
      </Form>
    );
  }

  renderPasswordResetForm() {
    return (
      <Form className="page-center">
        <Form.Group controlId="formBasicNewPassword">
          <Form.Control data-testid="newPassword" type="password" placeholder="Enter New Password" />
        </Form.Group>
        <Form.Group controlId="formBasicVerifyPassword">
          <Form.Control data-testid="newPasswordCheck" type="password" placeholder="Verify New Password" />
        </Form.Group>
        <Button data-testid="passwordChangeSubmit" className={styles.submitButton} variant="primary" type="submit">
          Change password
        </Button>
        <div className={styles.helpGroup}>
          <Button
            data-testid="passwordResetBackToLogin"
            variant="link"
            onClick={() => this.loginFormState(LoginFormModes.Login)}
          >
            Login
          </Button>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <div className={styles.Login} data-testid="Login">
        <Card bg="light">
          <Card.Header data-testid="cardTitle">{this.state.mode}</Card.Header>
          <Card.Body>
            <Image alt="usda logo" src={LoginArt} thumbnail />
            {this.renderLoginForm()}
          </Card.Body>
        </Card>
        <UserRolesModal
          show={this.state.showUserRolesModal}
          onHide={() => {
            this.setState({ showUserRolesModal: false });
          }}
          usaStates={this.state.usaStates}
          user={this.state.user}
          userProfile={this.state.userProfile}
          handleGoToHomepage={this.handleGoToHomepage}
        />
      </div>
    );
  }
}

export default withRouter(Login);
