import React from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import CustomForm from "../../../common/form";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import UtilityFunctions from "../../../common/UtilityFunctions";
import { toast } from "react-toastify";
import searchIcon from "../../../../assets/search.svg";

const SearchIcon = () => {
  return <img src={searchIcon} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class EnterLossModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        lossDate: "",
        lossQuantity: "",
        lossAmount: "",
        lossUom: "",
        selectedDamage: "",
        selectedDisease: "",
        selectedSpecies: "",
        reportedOrVerified: "",
      },
      resourceUnitValue: "",
      resourceUOMs: [],
      damages: [],
      diseases: [],
      errors: {},
    };
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
      this.validateStateData(currentUsaState);

      const resourceId = this.props.resourceData?.resourceTableId
        ? this.props.resourceData.resourceTableId
        : this.props.resourceData?.resourceUniqueReference;

      let resourceData;
      if (resourceId && currentUsaState?.stateGuid) {
        const resourceDataResponse = await ReferenceFileAPI.GetResourceForState(resourceId, currentUsaState.stateGuid);
        if (resourceDataResponse?.successful) {
          resourceData = resourceDataResponse.data;
        }
      }

      this.setState({
        resourceUOMs: this.getResourceUOMs(resourceData),
        damages: await this.getDamages(currentUsaState),
        diseases: await this.getDiseases(currentUsaState),
        resourceUnitValue: this.getResourceValuation(resourceData),
        currentUsaState,
      });
    }
  }

  getResourceUOMs(resourceData) {
    let result = [];
    if (resourceData?.stateAllowedUnitOfMeasures?.length > 0) {
      result = resourceData.stateAllowedUnitOfMeasures.map((uom) => ({
        value: uom.unitOfMeasure?.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(uom?.unitOfMeasure),
      }));
    }

    return result;
  }

  getResourceValuation(resourceData) {
    let unitValuation;
    if (resourceData?.stateResourceValuations?.length > 0) {
      unitValuation = resourceData.stateResourceValuations.sort((a, b) =>
        a.valuationYear > b.valuationYear ? -1 : 1
      )[0]?.valuePerUnitOfMeasure;
    }

    return unitValuation;
  }

  async getDamages(currentUsaState) {
    let damages = [];
    if (currentUsaState?.stateGuid) {
      const damagesData = await ReferenceFileAPI.GetDamagesForState(currentUsaState.stateGuid);

      if (damagesData?.data?.results) {
        damages = damagesData.data.results.map((result) => ({
          value: result.damageUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return damages;
  }

  async getDiseases(currentUsaState) {
    let diseases = [];
    if (currentUsaState?.stateGuid) {
      const diseasesData = await ReferenceFileAPI.GetDiseasesForState(currentUsaState.stateGuid);

      if (diseasesData?.data?.results) {
        diseases = diseasesData.data.results.map((result) => ({
          value: result.diseaseUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return diseases;
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  handleSelectChanges = (e, fieldName) => {
    const { data } = this.state;
    if (fieldName === "lossUom" && data.lossQuantity) {
      if (e.value === 6) {
        data.lossQuantity = Math.round(data.lossQuantity);
      }
      if (this.state.resourceUnitValue) {
        if (e.value === this.props.resourceData?.valuationUnitOfMeasure?.id) {
          data.lossAmount = Number.parseFloat(data.lossQuantity) * this.state.resourceUnitValue;
        }
      }
    }

    data[fieldName] = e;
    this.setState({ data });
  };

  handleSpeciesSearch = async (inputValue) => {
    return await this.getSpeciesFromAPI(inputValue);
  };

  async getSpeciesFromAPI(searchValue) {
    const { currentUsaState } = this.state;
    let searchResults = "";

    if (currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );

      if (speciesData.data?.results) {
        let results = speciesData.data.results;
        searchResults = results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return searchResults;
  }

  getFilterReferenceFilesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  handleSpecificChanges(input) {
    if (input.name === "lossQuantity" && this.state.resourceUnitValue) {
      const { data, resourceUOMs } = this.state;
      const selectedUom = data.lossUom
        ? data.lossUom
        : resourceUOMs.find((uom) => uom.value === this.props.resourceData?.valuationUnitOfMeasure?.id);
      if (selectedUom?.value === this.props.resourceData?.valuationUnitOfMeasure?.id) {
        data.lossQuantity = input.value;
        data.lossAmount = input.value ? Number.parseFloat(input.value) * this.state.resourceUnitValue : 0;
        this.setState({ data });
      }
    }
  }

  isFormDataValid() {
    const {
      lossDate,
      lossQuantity,
      lossUom: stateUom,
      selectedDamage,
      selectedDisease,
      selectedSpecies,
      reportedOrVerified,
    } = this.state.data;
    const lossUom = stateUom
      ? stateUom
      : this.state.resourceUOMs?.find((uom) => uom.value === this.props.resourceData?.valuationUnitOfMeasure?.id);

    return (
      lossDate &&
      lossQuantity &&
      lossUom &&
      selectedDamage &&
      selectedSpecies &&
      reportedOrVerified &&
      (selectedDisease || selectedDamage.label !== "Disease")
    );
  }

  doSubmit() {
    const { data, resourceUOMs } = this.state;
    if (!data.lossUom) {
      data.lossUom = resourceUOMs.find((uom) => uom.value === this.props.resourceData?.valuationUnitOfMeasure?.id);
    }
    if (data.lossUom?.value === 6) {
      data.lossQuantity = Math.round(data.lossQuantity);
    }

    const resourceId = this.props.resourceData?.resourceTableId
      ? this.props.resourceData.resourceTableId
      : this.props.resourceData?.resourceUniqueReference;

    this.props.onSubmitLoss({ ...data, resourceUniqueId: resourceId });
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  }

  handleModalCancel = () => {
    const { data } = this.state;

    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  clearData(data) {
    data.lossDate = "";
    data.lossQuantity = "";
    data.lossUom = "";
    data.lossAmount = "";
    data.selectedDamage = "";
    data.selectedDisease = "";
    data.selectedSpecies = "";
    data.reportedOrVerified = "";
  }

  render() {
    const { data, resourceUOMs } = this.state;
    const lossUom = data.lossUom
      ? data.lossUom
      : resourceUOMs.find((uom) => uom.value === this.props.resourceData?.valuationUnitOfMeasure?.id);
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.modalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>Enter Loss</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mx-0">
              <Col>{this.renderInput("lossDate", "Date of Loss", "date", "Select Date", "required")}</Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Protected Resource</Form.Label>
                  <Form.Control
                    value={this.props.resourceData?.resourceName}
                    type="text"
                    readOnly
                    className={globalStyles.formData}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col sm={4} className="pr-1">
                {this.renderInput("lossQuantity", "Quantity", "number", "Qty.", "required")}
              </Col>
              <Col sm={3} className="px-1">
                {resourceUOMs?.length === 1 && (
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      UOM<span className={globalStyles.asterisk508}> *</span>
                    </Form.Label>
                    <Form.Control value={lossUom?.label} readOnly />
                  </Form.Group>
                )}
                {resourceUOMs?.length !== 1 && (
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      UOM<span className={globalStyles.asterisk508}> *</span>
                    </Form.Label>
                    <Select
                      value={lossUom}
                      placeholder="UOM"
                      styles={this.customSelectStyles}
                      options={resourceUOMs}
                      onChange={(e) => this.handleSelectChanges(e, "lossUom")}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col className="pl-1">
                {this.renderInput("lossAmount", "Total Amount (Dollar)", "number", "Enter Amount")}
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Damage<span className={globalStyles.asterisk508}> *</span>
                  </Form.Label>
                  <Select
                    value={data.selectedDamage}
                    placeholder="Select Damage"
                    styles={this.customSelectStyles}
                    options={this.state.damages}
                    onChange={(e) => this.handleSelectChanges(e, "selectedDamage")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0" hidden={this.state.data.selectedDamage?.label !== "Disease"}>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Disease<span className={globalStyles.asterisk508}> *</span>
                  </Form.Label>
                  <Select
                    aria-label="Disease Selection"
                    value={data.selectedDisease}
                    placeholder="Select Disease"
                    styles={this.customSelectStyles}
                    options={this.state.diseases}
                    onChange={(e) => this.handleSelectChanges(e, "selectedDisease")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    Damage Agent<span className={globalStyles.asterisk508}> *</span>
                  </Form.Label>
                  <AsyncSelect
                    value={data.selectedSpecies}
                    openMenuOnClick={false}
                    placeholder="Search for Damage Agents"
                    components={{ DropdownIndicator }}
                    styles={this.customSelectStyles}
                    loadOptions={this.handleSpeciesSearch}
                    onChange={(e) => this.handleSelectChanges(e, "selectedSpecies")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>
                {this.renderInlineCheckBoxSet(
                  "reportedOrVerified",
                  "Reported or Verified",
                  ["Reported", "Verified"],
                  "radio",
                  "",
                  "required"
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit" disabled={!this.isFormDataValid()}>
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EnterLossModal;

