import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { withRouter } from "react-router-dom";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CustomTextFilter from "../common/customTextFilter";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CreateEditAdminWorkTaskActivityModal from "../ReferenceFilesCreatePages/CreateEditAdminWorkTaskActivityModal";
import ConfigureAdminworkTaskActivityModal from "../ReferenceFilesEditModals/ConfigureAdminworkTaskActivityModal";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class AdminWorkTaskActivityTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activityData: [],
      defaultSort: [{ dataField: "name", order: "asc" }],
      columns: this.setColumnsDefinition(),
      showCreateModal: false,
      showConfigureModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
      currentUsaState: props.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    const onThisTab = this.props.currentTab === "adminWorkTaskActivityTab";
    const stateIdChanged =
      prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier;
    if ((propsChange && onThisTab) || stateIdChanged) {
      await this.updateActivityData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "adminWorkTaskActivityTab") {
      await this.updateActivityData();
    }
  }

  async updateActivityData() {
    this.props.setIsLoading(true);
    this.setState({ columns: this.setColumnsDefinition() });
    const activityData = await this.getDataFromAPI();
    this.setState({ activityData }, () => {
      this.props.setIsLoading(false);
    });
    window.scrollTo(0, 0);
  }

  setColumnsDefinition() {
    let columns = [
      {
        dataField: "adminActivityUniqueIdentifier",
        hidden: true,
        csvText: "Activity Unique Id",
      },
      {
        dataField: "activityName",
        text: "Activity",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Activity Name" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "activityNameHeader" },
        attrs: { headers: "activityNameHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "isEnabledGlobally" : "isEnabledInState",
        text: this.props.useGlobalData ? "Status" : "Allowed",
        formatter: (cell, row, rowIndex) => {
          if (this.props.useGlobalData) {
            return row.isEnabledGlobally ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          } else {
            return row.isEnabledInState ? <span style={{ color: "#008767" }}>Allowed</span> : "Not Allowed";
          }
        },
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === "asc") {
            return b - a;
          }
          return a - b; // desc
        },
        align: "left",
        headerAlign: "left",
        style: this.tableTextStyle,
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        headerAttrs: { id: "activityStatusHeader" },
        attrs: { headers: "activityStatusHeader", tabIndex: "0" },
      },
      {
        dataField: "lastModifiedDateTimeUTC",
        text: this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return new Date(cell).toLocaleDateString("en-US");
        },
        sort: true,
        sortFunc: (a, b, order) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          if (order === "asc") {
            return dateB - dateA;
          }
          return dateA - dateB; // desc
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "activityDateHeader" },
        attrs: { headers: "activityDateHeader", tabIndex: "0" },
      },
      {
        dataField: "activityActions",
        text: "Actions",
        csvExport: false,
        editable: false,
        formatter: (cell, row) => {
          let configureDropdownItem = [];
          if (!this.props.useGlobalData) {
            configureDropdownItem.push(
              <Dropdown.Item
                eventKey="activityConfigure"
                onSelect={() => this.handleActivityConfigure(row)}
                hidden={!this.props.permissions.canIConfigureRefData()}
              >
                Configure
              </Dropdown.Item>
            );
          } else {
            configureDropdownItem.push(
              <Dropdown.Item
                eventKey="activityEdit"
                onSelect={() => this.handleActivityEdit(row)}
                hidden={!this.props.permissions.canIEditReferenceData()}
              >
                Edit
              </Dropdown.Item>
            );
          }
          return (
            <div className={styles.refFilesActionButtonContainer}>
              <DropdownButton
                id="dropdown-activity-actions"
                title="Actions"
                variant="link"
                className={styles.buttonLink}
              >
                {configureDropdownItem}

                <Dropdown.Item eventKey="activityHistoryTracking" onSelect={() => this.handleActivityHistory(row)}>
                  History Tracking
                </Dropdown.Item>
              </DropdownButton>
            </div>
          );
        },
        headerStyle: () => {
          return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
        },
        headerAlign: "right",
        align: "right",
        headerAttrs: {
          id: "activityActionsHeader",
          title: "Actions Column Header",
        },
        attrs: { headers: "activityActionsHeader" },
      },
    ];

    return columns;
  }
  async getDataFromAPI() {
    let awtResults = [];

    if (this.props.useGlobalData) {
      const getAwtResponse = await ReferenceFileAPI.GetAllAdminActivities();
      if (getAwtResponse?.successful) {
        awtResults = getAwtResponse?.data?.results;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve admin activities",
          apiName: "GetAllAdminActivities",
          responseUnsuccessful: getAwtResponse?.unsuccessful,
          responseMessage: getAwtResponse?.message,
        });
      }
    } else {
      if (this.state.currentUsaState?.stateGuid) {
        const getAwtStatesResponse = await ReferenceFileAPI.GetAdminActivitiesForState(
          this.state.currentUsaState.stateGuid
        );

        if (getAwtStatesResponse?.successful) {
          awtResults = getAwtStatesResponse?.data?.results.filter((t) => t.isEnabledGlobally);
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to retrieve admin activities",
            apiName: "GetAdminActivitiesForState",
            responseUnsuccessful: getAwtStatesResponse?.unsuccessful,
            responseMessage: getAwtStatesResponse?.message,
          });
        }
      }
    }

    return awtResults;
  }

  async onModalCreate(newAwtData) {
    const createAwtBody = {
      activityName: newAwtData.activityName,
      description: newAwtData.description,
      isEnabledGlobally: true,
    };
    const createAwtResponse = await ReferenceFileAPI.CreateAdminActivity(createAwtBody);
    if (createAwtResponse?.successful) {
      this.setState({ showCreateSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create admin activity",
        apiName: "CreateAdminActivity",
        responseUnsuccessful: createAwtResponse?.unsuccessful,
        responseMessage: createAwtResponse?.message,
      });
    }
  }

  async onModalEdit(newAwtData) {
    let isAllowed = false;
    if (newAwtData.isEnabledGlobally === "Active" || newAwtData.isEnabledGlobally === true) {
      isAllowed = true;
    }
    const editAwtBody = {
      activityName: newAwtData.activityName,
      description: newAwtData.description,
      isEnabledGlobally: isAllowed,
    };
    const editAircraftResponse = await ReferenceFileAPI.UpdateAdminActivity(
      newAwtData.adminActivityUniqueIdentifier,
      editAwtBody
    );
    if (editAircraftResponse?.successful) {
      this.setState({ showEditSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to edit admin activity",
        apiName: "UpdateAdminActivity",
        responseUnsuccessful: editAircraftResponse?.unsuccessful,
        responseMessage: editAircraftResponse?.message,
      });
    }
  }

  async onModalConfigure(newAwtData) {
    const editAwtBody = {
      activityName: newAwtData.activityName,
      description: newAwtData.description,
      isEnabledInState: newAwtData.isEnabledInState === "Yes",
    };

    if (newAwtData.isEnabledInState === "Yes" || newAwtData.isEnabledInState === true) {
      const editAircraftResponse = await ReferenceFileAPI.EnableAdminActivityInState(
        newAwtData.adminActivityUniqueIdentifier,
        this.state.currentUsaState.stateGuid
      );
      if (editAircraftResponse?.successful) {
        this.setState({ showEditSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to configure admin activity",
          apiName: "EnableAdminActivityInState",
          responseUnsuccessful: editAircraftResponse?.unsuccessful,
          responseMessage: editAircraftResponse?.message,
        });
      }
    } else {
      const editAircraftResponse = await ReferenceFileAPI.DisableAdminActivityInState(
        newAwtData.adminActivityUniqueIdentifier,
        this.state.currentUsaState.stateGuid,
        editAwtBody
      );
      if (editAircraftResponse?.successful) {
        this.setState({ showEditSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to configure admin activity",
          apiName: "DisableAdminActivityInState",
          responseUnsuccessful: editAircraftResponse?.unsuccessful,
          responseMessage: editAircraftResponse?.message,
        });
      }
    }
  }

  onModalSubmit = async (newAwtData, mode) => {
    newAwtData.lastModifiedDateTimeUTC = new Date().toLocaleDateString("en-US");
    if (mode === "Create") {
      await this.onModalCreate(newAwtData);
    } else if (mode === "Edit") {
      await this.onModalEdit(newAwtData);
    } else if (mode === "Configure") {
      await this.onModalConfigure(newAwtData);
    }
    await this.updateActivityData();
  };

  handleActivityCreate = () => {
    this.setState({ showCreateModal: true });
  };

  handleActivityConfigure = (row) => {
    this.setState({ modalData: row, showConfigureModal: true });
  };

  handleActivityEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleActivityHistory = (row) => {};

  closeAllModals = () => {
    this.setState({ showCreateModal: false, showEditModal: false, showConfigureModal: false, modalData: null });
  };

  renderCreateModal() {
    return (
      <CreateEditAdminWorkTaskActivityModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode="Create"
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditAdminWorkTaskActivityModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode="Edit"
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderConfigureModal() {
    return (
      <ConfigureAdminworkTaskActivityModal
        show={this.state.showConfigureModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode="Configure"
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        usaStateGuid={this.props.currentUsaState?.stateGuid}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderModals = () => {
    return (
      <React.Fragment>
        {this.state.showCreateModal ? this.renderCreateModal() : null}
        {this.state.showEditModal ? this.renderEditModal() : null}
        {this.state.showConfigureModal ? this.renderConfigureModal() : null}
      </React.Fragment>
    );
  };

  render() {
    return (
      <ToolkitProvider
        keyField="adminActivityUniqueIdentifier"
        data={this.state.activityData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "activity-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Admin Activities"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleActivityCreate}
                  useGlobalData={this.props.useGlobalData}
                  recordCount={this.state.activityData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="adminActivityUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.renderModals()}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="Activity"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="Activity"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(AdminWorkTaskActivityTab);
