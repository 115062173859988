import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const OauthCallback = (props) => {
  useEffect(() => {
    let opener = window.opener;
    if (opener) {
      if (window.location.hash) {
        if (typeof opener.require === "function" && opener.require("esri/kernel")) {
          opener.require("esri/kernel").id.setOAuthResponseHash(window.location.hash);
        } else {
          opener.dispatchEvent(new CustomEvent("arcgis:auth:hash", { detail: window.location.hash }));
        }

        window.close();
      } else if (window.location.search) {
        opener.dispatchEvent(new CustomEvent("arcgis:auth:window.location:search", { detail: window.location.search }));
        window.close();
      }
    } else {
      window.close();
    }
  });
  return <div></div>;
};

export default withRouter(OauthCallback);
