import React from "react";
import CustomForm from "../common/form";
import styles from "./UserRoles.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import Select, { components } from "react-select";
import searchIcon from "../../assets/search.svg";
import { toast } from "react-toastify";

class CreateProfileModal extends CustomForm {
  constructor(props) {
    super(props);
    if (this.props.editData) {
      this.state = {
        data: { selectedRole: this.props.editData.roleUniqueReference },
        availableStates: this.props.availableStates.map((s) => {
          s.isSelected = this.props.editData.profileAssociatedStates.includes(s.id);
          return s;
        }),
        isInitialConfiguration: true,
        isStatesMenuOpen: false,
        stateSearchText: "",
        errors: {},
      };
    } else {
      this.state = {
        data: { selectedRole: "" },
        availableStates: [],
        isInitialConfiguration: true,
        isStatesMenuOpen: false,
        stateSearchText: "",
        errors: {},
      };
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  doSubmit() {
    if (!this.isFormDataValid()) {
      toast.warning("Please fill out all the required fields before submitting the form.");
      return;
    }

    const { data, availableStates } = this.state;
    this.props.onHide();
    if (this.props.editData) {
      this.props.onSave({
        wsUserProfileUniqueIdentifier: this.props.editData.wsUserProfileUniqueIdentifier,
        selectedStates: availableStates.filter((s) => s.isSelected),
      });
    } else {
      this.props.onSave({ roleId: data.selectedRole, selectedStates: availableStates.filter((s) => s.isSelected) });
    }

    this.clearData();
  }

  isFormDataValid() {
    const { data, availableStates } = this.state;
    return data.selectedRole && availableStates?.some((s) => s.isSelected);
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.selectedRole = "";

    this.setState({ data, isInitialConfiguration: true });
  }

  SearchIcon = () => {
    return <img src={searchIcon} />;
  };

  handleStateSelection = (stateData) => {
    let { availableStates, isInitialConfiguration } = this.state;
    if (isInitialConfiguration) {
      isInitialConfiguration = false;
      availableStates = this.props.availableStates;
    }

    let selectedState = availableStates.find((state) => state.id === stateData.id);
    if (selectedState) {
      selectedState.isSelected = !selectedState.isSelected;
      this.setState({ availableStates, isInitialConfiguration });
    }
  };

  render() {
    let availableStates = [];
    if (!this.state.isInitialConfiguration) {
      availableStates = JSON.parse(JSON.stringify(this.state.availableStates));
    } else {
      availableStates = this.props.availableStates;
    }

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>
              {this.props.editData ? "Edit" : "Create New"} Profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.createProfileModalBody}>
            <Row className="mt-3 mx-0">
              <Col>
                {!this.props.editData &&
                  this.renderSelect(
                    "selectedRole",
                    "Available Roles",
                    this.props.availableRoles?.length > 0
                      ? this.props.availableRoles?.map((r) => ({ id: r.wsRoleUniqueIdentifier, name: r.roleName }))
                      : [],
                    "Select"
                  )}
                {this.props.editData &&
                  this.renderLabel(
                    "selectedRole",
                    "Role",
                    this.props.availableRoles.find(
                      (r) => r.wsRoleUniqueIdentifier == this.props.editData.roleUniqueReference
                    )?.roleName
                  )}
              </Col>
            </Row>
            <Row className="mx-0 my-2">
              <Col>
                <Form.Group>
                  <Form.Label htmlFor="statesSelectionDropdown">
                    <span className={globalStyles.formLabel}>State(s)</span>
                  </Form.Label>
                  <Select
                    aria-label="Select State"
                    placeholder={this.state.isStatesMenuOpen ? "Search here" : "Select States"}
                    name="statesSelectionDropdown"
                    value=""
                    styles={{
                      indicatorSeparator: () => {
                        //do nothing
                      },
                      placeholder: (styles) => ({ ...styles, fontSize: "14px" }),
                      menuList: (styles) => ({
                        ...styles,
                        paddingLeft: "1rem",
                        overflowX: "hidden",
                        overflowY: "scroll",
                      }),
                    }}
                    menuIsOpen={this.state.isStatesMenuOpen}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    inputValue={this.state.stateSearchText}
                    onMenuClose={() => {
                      this.setState({ isStatesMenuOpen: false });
                    }}
                    onFocus={() => {
                      this.setState({ isStatesMenuOpen: true });
                    }}
                    onBlur={() => {
                      this.setState({ isStatesMenuOpen: false });
                    }}
                    onInputChange={(query, { action }) => {
                      if (action !== "set-value") this.setState({ stateSearchText: query });
                    }}
                    filterOption={(option, inputValue) => {
                      return option?.data?.name?.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                    components={{
                      Option: (props) => {
                        const { data, innerProps, innerRef } = props;
                        return (
                          <Row className="my-1" ref={innerRef} {...innerProps}>
                            <Col>
                              <Form.Check
                                type="checkbox"
                                inline
                                defaultChecked={data.isSelected}
                                onChange={() => this.handleStateSelection(data)}
                                title={data?.name}
                              />
                              <span className={globalStyles.formData}>{data?.name}</span>
                            </Col>
                          </Row>
                        );
                      },
                      DropdownIndicator: (props) => {
                        return this.state.isStatesMenuOpen ? (
                          <components.DropdownIndicator {...props}>
                            <this.SearchIcon />
                          </components.DropdownIndicator>
                        ) : (
                          <components.DropdownIndicator {...props}></components.DropdownIndicator>
                        );
                      },
                    }}
                    options={availableStates}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit">
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default CreateProfileModal;
