import "./map.css";
import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";
import { esriPortalConfig, config } from "../../../package.json";
import ActiveIcon from "../../assets/Active-trap-icon.svg";
import InactiveIcon from "../../assets/Inactive-trap-icon.svg";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

let previousGraphic;
let originalGraphic;

function SiteVisitParcelMap(property) {
  const mapDiv = useRef(null);

  //default map values
  let longitude = -77.62544;
  let latitude = 39.52566;

  let methodInactiveSymbol = {
    type: "picture-marker", // autocasts as new PictureMarkerSymbol()
    url: InactiveIcon,
    width: "32px",
    height: "32px",
  };
  let methodActiveSymbol = {
    type: "picture-marker", // autocasts as new PictureMarkerSymbol()
    url: ActiveIcon,
    width: "32px",
    height: "32px",
  };
  const methodSymbol = {
    type: "simple-marker",
    style: "diamond",
    color: "blue",
    size: "14px",
    outline: {
      color: [255, 255, 255],
      width: 2,
    },
  };

  // if (property.location !== undefined && property.location && property.location !== "") {
  if (property.location) {
    let mapLoc = JSON.parse(property.location);
    longitude = mapLoc[1];
    latitude = mapLoc[0];
  }
  useEffect(() => {
    if (mapDiv.current) {
      loadModules([
        "esri/identity/OAuthInfo",
        "esri/identity/IdentityManager",
        "esri/portal/PortalItem",
        "esri/WebMap",
        "esri/views/MapView",
        "esri/config",
        "esri/layers/GraphicsLayer",
        "esri/Graphic",
        "esri/layers/FeatureLayer",
        "esri/renderers/SimpleRenderer",
      ]).then(
        ([
          OAuthInfo,
          esriId,
          PortalItem,
          WebMap,
          MapView,
          esriConfig,
          GraphicsLayer,
          Graphic,
          FeatureLayer,
          SimpleRenderer,
        ]) => {
          let appId, featureSvcUrl;
          let featureSvcName = sessionStorage.getItem("featureService");
          if (featureSvcName === "undefined") {
            //default Texas map, till all states has maps
            featureSvcName = esriPortalConfig.featureSvcName;
          }

          if (process.env.NODE_ENV === "production" && config.environment.toLowerCase() === "production") {
            //prod mapserver
            esriConfig.portalUrl = esriPortalConfig.production.portalUrl;
            appId = esriPortalConfig.production.appId;
            featureSvcUrl = esriPortalConfig.production.featureSvcs + featureSvcName + "/MapServer/0";
          } else {
            //QA - staging mapserver
            esriConfig.portalUrl = esriPortalConfig.staging.portalUrl;
            appId = esriPortalConfig.staging.appId;
            featureSvcUrl = esriPortalConfig.staging.featureSvcs + featureSvcName + "/FeatureServer/0";
          }

          const info = new OAuthInfo({
            appId: appId,
            portalUrl: esriConfig.portalUrl,
          });
          esriId.registerOAuthInfos([info]);
          esriId
            .checkSignInStatus(info.portalUrl + "/sharing")
            .then(() => {
              //connected to map server
            })
            .catch((err) => {
              ErrorHandler.sendToAppInsights("error while connecting to map server " + err);
              sessionStorage.setItem("mapAccess", "No");
              return;
            });

          esriId
            .getCredential(info.portalUrl + "/sharing")
            .then((creds) => {
              //user credentials
            })
            .catch((err) => {
              ErrorHandler.sendToAppInsights("error while getting credentials " + err);
              sessionStorage.setItem("mapAccess", "No");
              return;
            });

          var resultsLayer = new GraphicsLayer();
          const initialGraphics = property.inventoryMapItems.map((method) => {
            let symbol = methodInactiveSymbol;
            if (method.status === "Live") {
              symbol = methodActiveSymbol;
            }
            return new Graphic({
              geometry: {
                type: "point",
                latitude: method.latitude,
                longitude: method.longitude,
              },
              symbol: symbol,
            });
          });

          const graphicsLayer = new GraphicsLayer({
            graphics: initialGraphics,
          });

          //todo - revisit later, removed default pop up to show deployed method info. removed popupTemplate: template from below.
          var featureLayer = new FeatureLayer({
            url: featureSvcUrl,
            outFields: ["*"], // used by queryFeatures
            overwriteActions: true,
          });
          featureLayer.renderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            color: null,
            symbol: {
              type: "simple-fill",
              color: null,
              outline: {
                width: 0.5,
                color: null,
              },
            },
          };
          let mapId = sessionStorage.getItem("mapId");
          if (mapId === "undefined") {
            //default Texas map, till all states has maps
            mapId = esriPortalConfig.mapId;
          }
          let item = new PortalItem({
            id: mapId,
          });
          item.load();

          const webmap = new WebMap({
            portalItem: item,
            layers: [featureLayer, resultsLayer, graphicsLayer],
          });

          const view = new MapView({
            container: mapDiv.current,
            center: [longitude, latitude],
            map: webmap,
            zoom: 12,
            popup: {
              defaultPopupTemplateEnabled: false,
              dockEnabled: true,
              dockOptions: {
                buttonEnabled: false,
                breakpoint: false,
                position: "bottom-left",
              },
            },
          });
          let state = featureSvcName?.slice(featureSvcName.length - 2, featureSvcName.length);

          view.when(() => {
            let canvas = document.getElementsByTagName("CANVAS")[0];
            if (canvas) {
              canvas.title = "Search Map Canvas";
            }
            let where = "STATE  = " + state;

            queryFeatureLayer(view.extent, where);
          });

          function queryFeatureLayer(extent, whereClause) {
            var query = featureLayer.createQuery();

            query.where = getWhereClause();
            query.geometry = extent;
            query.attributes = ["*"];
            query.spatialRelationship = "intersects";
            featureLayer
              .queryFeatures(query)

              .then((results) => {
                displayResults(results);
              })
              .catch((error) => {
                ErrorHandler.sendToAppInsights(error);
              });
          }

          function getWhereClause() {
            const savedparcels = property.parcelDetails;

            let whereClause = "";
            let str = "";
            if (savedparcels?.length > 0) {
              savedparcels.forEach((parcel) => {
                whereClause += "'" + parcel.parcelId + "',";
              });
            }

            str = whereClause.substring(0, whereClause.length - 1);

            let query = "APHIS_ID IN (" + str + ")";

            return query;
          }

          let popupTemplate;

          if (property.agreementData) {
            popupTemplate = {
              title: "{Name}",
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    { fieldName: "Address", label: "Address" },
                    { fieldName: "AgreementName", label: "Agreement Name" },
                    { fieldName: "AgreementStatus", label: "Agreement Status" },
                    { fieldName: "PrimaryFieldAgent", label: "Primary WS Specialist" },
                    { fieldName: "ExpirationDate", label: "Expiration Date" },
                  ],
                },
              ],
            };
          } else {
            popupTemplate = {
              title: "{Name}",
              content: "{Description}",
            };
          }

          const attributes = {
            Name: property.name,
            Description: property.address,
            Address: property.address,
            AgreementName: property.agreementData?.commonName,
            AgreementStatus: getAgreementStatus(property),
            PrimaryFieldAgent: getPrimaryFieldAgent(property),
            ExpirationDate: new Date(property.agreementData?.expirationDate).toLocaleDateString("en-US"),
          };

          function displayResults(results) {
            const symbol = {
              type: "simple-fill",
              color: null,
              outline: {
                color: "blue",
                width: 3,
              },
            };
            // Assign styles and popup to features
            results.features.map((feature) => {
              feature.symbol = symbol;
              feature.popupTemplate = popupTemplate;
              feature.attributes = attributes;
              return feature;
            });

            // Clear display
            view.popup.close();
            view.graphics.removeAll();

            // Add features to graphics layer
            view.graphics.addMany(results.features);

            view.goTo(results.features[0].geometry.extent.expand(6));
          }

          if (property?.refreshMapItems?.length > 0) {
            let item = property.refreshMapItems[0];
            const lat = item.latitude;
            const lon = item.longitude;

            const tempPoint = {
              type: "point",
              longitude: lon,
              latitude: lat,
            };
            const newGraphic = new Graphic({
              geometry: tempPoint,
              symbol: methodSymbol,
            });
            if (previousGraphic) {
              graphicsLayer.remove(previousGraphic);
            }
            graphicsLayer.add(newGraphic);
            previousGraphic = newGraphic;

            webmap.layers.addMany([graphicsLayer]);
          }

          view.on("click", (e) => {
            let screenPoint = {
              x: e.x,
              y: e.y,
            };
            view.hitTest(screenPoint).then((response) => {
              if (response.results.length) {
                var graphic = response.results.filter((result) => {
                  return result.graphic.layer === graphicsLayer;
                })[0]?.graphic;
                if (graphic) {
                  let methodClicked = property.inventoryMapItems.find(
                    (i) => i.latitude === graphic.geometry.latitude && i.longitude === graphic.geometry.longitude
                  );
                  if (methodClicked) {
                    let actionsArray = [];
                    if (!property.isViewMap) {
                      actionsArray = methodClicked.configureActions?.map((action) => {
                        return {
                          title: action.configurationAction,
                          id: `${JSON.stringify(methodClicked)}****${action.configurationAction}`,
                        };
                      });
                      actionsArray.push({
                        title: "Remove Method",
                        id: `${JSON.stringify(methodClicked)}****Remove`,
                        className: "esri-icon-close",
                      });
                    }

                    let row = {};
                    row.deployedItemUniqueIdentifier = methodClicked.deployedItemUniqueIdentifier;
                    row.methodUniqueReference = methodClicked.methodUniqueReference;
                    const methodPoint = {
                      type: "point",
                      latitude: methodClicked.latitude,
                      longitude: methodClicked.longitude,
                    };

                    const methodMarkerPopupGraphic = new Graphic({
                      geometry: methodPoint,
                      symbol: methodSymbol,
                      attributes: {
                        Name: methodClicked.methodName,
                        Label: methodClicked.locationAlias,
                        Latitude: methodClicked.latitude,
                        Longitude: methodClicked.longitude,
                        Status: methodClicked.status,
                        CreateDate: methodClicked.createdDate,
                        LastModified: methodClicked.lastModifiedDate,
                      },
                      popupTemplate: {
                        title: "{Name}",
                        content: [
                          {
                            type: "fields",
                            fieldInfos: [
                              { fieldName: "Label", label: "Equipment Label" },
                              { fieldName: "Latitude", label: "Latitude" },
                              { fieldName: "Longitude", label: "Longitude" },
                              { fieldName: "Status", label: "Status" },
                              { fieldName: "CreateDate", label: "Original Set Date" },
                              { fieldName: "LastModified", label: "Date of Last Work Task" },
                            ],
                          },
                        ],
                        actions: actionsArray,
                      },
                    });

                    originalGraphic = methodMarkerPopupGraphic;
                    graphicsLayer.add(methodMarkerPopupGraphic);
                    property.setHighlightedMapPointMethod(methodClicked);
                    view.popup.open({
                      location: e.mapPoint,
                      features: [methodMarkerPopupGraphic],
                    });
                  } else {
                    view.popup.open({
                      title: property.name,
                      content: property.address,
                      location: e.mapPoint,
                    });
                  }
                } else {
                  //add geo location for only associated parcel

                  var selectedParcel = response?.results[2]?.graphic?.attributes?.APHIS_ID;
                  const savedparcels = property.parcelDetails;

                  let propertyParcel = false;

                  if (savedparcels?.length > 0) {
                    for (let i = 0; i < savedparcels.length; i++) {
                      if (Number.parseInt(savedparcels[i].parcelId) === selectedParcel) {
                        propertyParcel = true;
                        break;
                      }
                    }
                  }

                  if (propertyParcel) {
                    view.popup.close();
                    property.setHighlightedMapPointMethod({});

                    if (originalGraphic) {
                      originalGraphic.symbol = methodSymbol;
                    }

                    if (!property.isViewMap) {
                      const lat = Math.round(e.mapPoint.latitude * 100000) / 100000;
                      const lon = Math.round(e.mapPoint.longitude * 100000) / 100000;

                      const tempPoint = {
                        type: "point",
                        longitude: lon,
                        latitude: lat,
                      };
                      const newGraphic = new Graphic({
                        geometry: tempPoint,
                        symbol: methodSymbol,
                      });
                      if (previousGraphic) {
                        graphicsLayer.remove(previousGraphic);
                      }
                      graphicsLayer.add(newGraphic);
                      previousGraphic = newGraphic;

                      property.setSelectPointLatitude(lat);
                      property.setSelectPointLongitude(lon);
                      webmap.layers.addMany([graphicsLayer]);
                    }
                  }
                }
              } else {
                view.popup.close();
                property.setHighlightedMapPointMethod({});
                if (originalGraphic) {
                  originalGraphic.symbol = methodSymbol;
                }
                if (!property.isViewMap) {
                  const lat = Math.round(e.mapPoint.latitude * 100000) / 100000;
                  const lon = Math.round(e.mapPoint.longitude * 100000) / 100000;
                  const tempPoint = {
                    type: "point",
                    longitude: lon,
                    latitude: lat,
                  };
                  const newGraphic = new Graphic({
                    geometry: tempPoint,
                    symbol: methodSymbol,
                  });
                  if (previousGraphic) {
                    graphicsLayer.remove(previousGraphic);
                  }
                  graphicsLayer.add(newGraphic);
                  previousGraphic = newGraphic;

                  property.setSelectPointLatitude(lat);
                  property.setSelectPointLongitude(lon);
                }
              }
            });
          });

          let element = document.querySelector(".esri-view-surface");
          if (element) {
            element.title = "Search Map";
          }
          view.popup.on("trigger-action", (e) => {
            if (e.action?.id?.split("****")) {
              let method = JSON.parse(e.action.id.split("****")[0]);
              let configureAction = e.action.id.split("****")[1];
              if (configureAction === "Remove") {
                property.siteVisitInstance.handleRemoveItems(method);
              } else {
                let configureActionParamObj = method.configureActions?.find(
                  (c) => c.configurationAction === configureAction
                );
                property.siteVisitInstance.handleGeolocatedAddItems(method, configureActionParamObj);
              }
            }
          });
        }
      );
    }
  }, [property.inventoryMapItems, property.refreshMapItems]);

  return <div className="mapDiv" ref={mapDiv}></div>;
}

function getPrimaryFieldAgent(property) {
  let primaryFieldAgent = "";
  if (property.agreementData?.primaryFieldAgentName) {
    const { firstName, middleName, lastName, suffix } = property.agreementData?.primaryFieldAgentName;
    primaryFieldAgent = [firstName, middleName, lastName, suffix].join(" ");
  }
  return primaryFieldAgent;
}

function getAgreementStatus(property) {
  let agreementStatus = "";
  if (property.agreementData?.agreementStatus) {
    if (property.agreementData.agreementStatus.displayText) {
      agreementStatus = property.agreementData.agreementStatus.displayText;
    } else if (property.agreementData.agreementStatus.name) {
      agreementStatus = property.agreementData.agreementStatus.name;
    }
  }
  return agreementStatus;
}
export default SiteVisitParcelMap;
