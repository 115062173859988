import React, { Fragment } from "react";
import CustomForm from "../common/form";
import styles from "./Agreement.module.scss";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { Modal, Button, ModalBody, Row, Col, Form, Card } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Addresses from "./contactAddresses";
import PhoneNumber from "./contactModalPhoneNumber";
import DuplicateContactModal from "../AgreementEntities/Contact/DuplicateContactModal";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import ContactOrgAddressDisplay from "../common/ContactOrgAddressDisplay";
import { toast } from "react-toastify";
import UtilityFunctions from "../common/UtilityFunctions";

class EditAgreementContactModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      addressValidated: {},
      phoneValidated: false,
      defaultCountry: "",
      data: {
        phoneNumbers: [
          {
            phoneNumber: "",
            phoneTypeEnumId: "-1",
          },
        ],
        addresses: [
          {
            streetAddress: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
        ],
      },
    };
  }

  componentDidMount() {
    const countries = this.props.countries;
    if (countries) {
      for (let i = 0; i < countries.length; i++) {
        const country = countries[i];
        if (country.name === "United States of America") {
          let { data } = this.state;
          data.addresses[0]["country"] = country.id;
          this.setState({ data });
          this.setState({ defaultCountry: country.id });
        }
      }
    }
  }

  getMailingAddress(reactStateData) {
    const primaryAddress = reactStateData.addresses[0];
    const primaryAddressObject = {
      mailingStreet: primaryAddress.streetAddress,
      mailingCity: primaryAddress.city,
      mailingStateUniqueId: primaryAddress.state,
      mailingZip: primaryAddress.zipCode,
      mailingCountryUniqueId: primaryAddress.country,
      addressPurpose: "mailing",
    };
    return primaryAddressObject;
  }

  handlePhoneNumberChange = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    let { data } = this.state;
    data.phoneNumbers[index][fieldName] = UtilityFunctions.formatPhoneNumber(input.value);
    this.setState({ data });
  };

  handleExtraPhoneNumber = ({ currentTarget: input }) => {
    let { data } = this.state;
    if (input.value === "add") {
      data.phoneNumbers.push({ phoneNumber: "", phoneTypeEnumId: "-1" });
    } else {
      data.phoneNumbers.splice(input.value, 1);
    }
    this.setState({ data });
  };
  dummyHandler = () => {
    //do nothing
  };

  handleUpdate = () => {
    let address = this.state.data.addresses;
    let phone = this.state.data.phoneNumbers;
    let contact = this.props.contactData;
    var updateContactObj = {
      contactUniqueId: contact.contactUniqueId || contact.primaryCooperatorUniqueIdentifier || contact.contactGuid,
      firstName: contact.firstName,
      middleName: contact.middleName,
      lastName: contact.lastName,
      typeOfContactEnumId: 2,
      primaryMailingAddress: this.getMailingAddress(this.state.data),
    };

    if (!contact.primaryMailingAddress && contact.phoneNumbers?.length < 1) {
      if (
        address[0].streetAddress === "" ||
        address[0].city === "" ||
        address[0].state === "" ||
        address[0].country === "" ||
        address[0].zipCode === ""
      ) {
        toast.warning("Please fill out an address.");
        return [];
      }
      if (phone[0].phoneNumber === "" || phone[0].phoneTypeEnumId === "-1" || phone[0].phoneTypeEnumId === "") {
        toast.warning("Please fill out a phone number.");
        return [];
      }
      this.updateContactPhoneNumberAndAddress(updateContactObj);
    } else if (contact.primaryMailingAddress && contact.phoneNumbers?.length < 1) {
      if (phone[0].phoneNumber === "" || phone[0].phoneTypeEnumId === "-1" || phone[0].phoneTypeEnumId === "") {
        toast.warning("Please fill out a phone number.");
        return [];
      }
      this.updateContactPhoneNumber(updateContactObj);
    } else {
      if (
        address[0].streetAddress === "" ||
        address[0].city === "" ||
        address[0].state === "" ||
        address[0].country === "" ||
        address[0].zipCode === ""
      ) {
        toast.warning("Please fill out an address.");
        return [];
      }
      this.updateContactAddress(updateContactObj);
    }
    this.clearData();
    this.props.onHide();
  };

  handleContinue = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    let { data } = this.state;
    this.setState({
      data: {
        phoneNumbers: [
          {
            phoneNumber: "",
            phoneTypeEnumId: "-1",
          },
        ],
        addresses: [
          {
            streetAddress: "",
            city: "",
            state: "",
            country: data.addresses[0]["country"],
            zipCode: "",
          },
        ],
      },
    });
  }

  async updateContactPhoneNumberAndAddress(obj) {
    let updateAPIResult = await AgreementEntitiesAPI.UpdateContact(obj);
    let otherAddresses = this.getOtherAddresses(this.state.data);
    if (otherAddresses.length > 0) {
      let updateOtherAddressesAPIResult = await AgreementEntitiesAPI.ReplaceOtherAddressesOnContact(
        obj.contactUniqueId,
        otherAddresses
      );
    }
    let phoneValidated = true;

    for (var i = 0; i < this.state.data.phoneNumbers.length; i++) {
      var currentPhone = this.state.data.phoneNumbers[i];
      if (currentPhone.phoneNumber !== "" && currentPhone.phoneTypeEnumId === "-1") {
        phoneValidated = false;
      }
      if (currentPhone.phoneNumber !== "") {
        phoneValidated = UtilityFunctions.validatePhoneNumber(currentPhone.phoneNumber);
      }
    }

    let verifiedPhones = [];
    if (this.state.data.phoneNumbers.length > 0) {
      for (let i = 0; i < this.state.data.phoneNumbers.length; i++) {
        var phone = this.state.data.phoneNumbers[i];
        if (phone.phoneNumber !== "" && phone.phoneTypeEnumId !== "-1" && phone.phoneTypeEnumId !== "") {
          verifiedPhones.push({
            phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(phone.phoneNumber),
            phoneTypeEnumId: parseInt(phone.phoneTypeEnumId),
          });
        }
      }
    }
    let updatePhoneNumbersAPIResult = await AgreementEntitiesAPI.ReplacePhoneNumbersOnContact(
      obj.contactUniqueId,
      verifiedPhones
    );
  }

  async updateContactAddress(obj) {
    let updateAPIResult = await AgreementEntitiesAPI.UpdateContact(obj);
    let otherAddresses = this.getOtherAddresses(this.state.data);
    if (otherAddresses.length > 0) {
      let updateOtherAddressesAPIResult = await AgreementEntitiesAPI.ReplaceOtherAddressesOnContact(
        obj.contactUniqueId,
        otherAddresses
      );
    }
  }

  async updateContactPhoneNumber(obj) {
    let phoneValidated = true;

    for (var i = 0; i < this.state.data.phoneNumbers.length; i++) {
      var currentPhone = this.state.data.phoneNumbers[i];
      if (currentPhone.phoneNumber !== "" && currentPhone.phoneTypeEnumId === "-1") {
        phoneValidated = false;
      }
      if (currentPhone.phoneNumber !== "") {
        phoneValidated = UtilityFunctions.validatePhoneNumber(currentPhone.phoneNumber);
      }
    }

    let verifiedPhones = [];
    if (this.state.data.phoneNumbers.length > 0) {
      for (let i = 0; i < this.state.data.phoneNumbers.length; i++) {
        var phone = this.state.data.phoneNumbers[i];
        if (phone.phoneNumber !== "" && phone.phoneTypeEnumId !== "-1" && phone.phoneTypeEnumId !== "") {
          verifiedPhones.push({
            phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(phone.phoneNumber),
            phoneTypeEnumId: parseInt(phone.phoneTypeEnumId),
          });
        }
      }
    }
    let updatePhoneNumbersAPIResult = await AgreementEntitiesAPI.ReplacePhoneNumbersOnContact(
      obj.contactUniqueId,
      verifiedPhones
    );
  }

  handleAddressChanges = async ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];
    let { data } = this.state;

    switch (fieldName) {
      case "country":
        this.updatePropertyCountry(data, input.value);
        await this.updateAvailableStates(input.value);
        break;
      case "state":
        this.updatePropertyState(data, input.value);
        break;
      default:
        data.addresses[index][fieldName] = input.value;
    }

    this.setState({ data });
  };

  handleExtraAddress = ({ currentTarget: input }) => {
    let { data } = this.state;

    if (input.value === "add") {
      if (data.addresses[data.addresses.length - 1].state)
        data.addresses.push({
          streetAddress: "",
          city: "",
          state: data.addresses[0].state,
          country: data.addresses[0].country,
          zipCode: "",
        });
      else toast.warning("Please fill out all the required fields on previous address record.");
    } else {
      data.addresses.splice(input.value, 1);
    }

    this.setState({ data });
  };

  getOtherAddresses(data) {
    if (data.addresses.length <= 1) return [];

    const otherAddresses = [...data.addresses];
    otherAddresses.shift();
    return otherAddresses
      .filter(
        (address) =>
          address.streetAddress !== "" &&
          address.city !== "" &&
          address.state !== "" &&
          address.zipCode !== "" &&
          address.country !== ""
      )
      .map((address) => ({
        mailingStreet: address.streetAddress,
        mailingCity: address.city,
        mailingStateUniqueId: address.state,
        mailingZip: address.zipCode,
        mailingCountryUniqueId: address.country,
        addressPurpose: "other",
      }));
  }

  updatePropertyCountry(data, value) {
    data.addresses = data.addresses.map((address) => {
      address.country = value;
      return address;
    });
  }

  updatePropertyState(data, value) {
    data.addresses = data.addresses.map((address) => {
      address.state = value;
      return address;
    });
  }

  async updateAvailableStates(countryId) {
    const states = await this.getAvailableStates(countryId);
    if (states) this.setState({ states });
  }

  async getAvailableStates(countryId) {
    return await this.getStatesFromAPI(countryId);
  }

  async getStatesFromAPI(countryId) {
    const results = await ReferenceFileAPI.GetStatesByCountry(countryId);

    if (results) {
      return results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  render() {
    const { data } = this.state;
    let addressComponent;
    let phoneNumberComponent;

    if (!this.props.contactData?.primaryMailingAddress) {
      addressComponent = (
        <Card className={styles.my3}>
          <Card.Body>
            <Row>
              <Col>
                <Addresses
                  addresses={data.addresses}
                  countries={this.props.countries}
                  states={this.state.states || this.props.states}
                  onAddressChange={this.handleAddressChanges}
                  onExtraAddress={this.handleExtraAddress}
                  contactType={this.props.contactType}
                  onDummyChange={this.dummyHandler}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    } else {
      addressComponent = [];
    }

    if (this.props.contactData?.phoneNumbers) {
      if (this.props.contactData?.phoneNumbers.length < 1) {
        phoneNumberComponent = (
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <PhoneNumber
                    phoneNumbers={data.phoneNumbers}
                    phoneNumberTypes={this.props.phoneNumberTypes}
                    onPhoneNumberChange={this.handlePhoneNumberChange}
                    onExtraPhoneNumber={this.handleExtraPhoneNumber}
                    contactType={this.props.contactType}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        );
      }
    } else {
      phoneNumberComponent = [];
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered size="lg">
          <ModalHeader style={{ display: "block" }}>
            <div className="d-flex justify-content-center">
              <i className="fa fa-exclamation-circle fa-5x" style={{ color: "#FDB81E" }} aria-hidden="true"></i>
            </div>
            <h4 style={{ textAlign: "center" }}>Warning!</h4>
          </ModalHeader>
          <ModalBody>
            <p style={{ textAlign: "center" }}>
              Cooperators must have a phone number and address. The contact you have selected is missing at least one of
              these fields - please add the missing information or select a new contact.
            </p>
            {phoneNumberComponent}
            {addressComponent}
          </ModalBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleContinue}>
              Continue
            </Button>
            <Button variant="primary" onClick={this.handleUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <SuccessfulRecordModifiedModal
          show={this.state.showHappyModal}
          onHide={this.closeHappyModal}
          messageType="create"
          objectName="Contact"
        />
      </div>
    );
  }
}

export default EditAgreementContactModal;
