import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { toast } from "react-toastify";
import CustomForm from "../../../common/form";
import globalStyles from "../../../../OARS.module.scss";
import styles from "./PropertyVisitSummary.module.scss";
import SuccessfulRecordModifiedModal from "../../../common/create-updateOkMessageModal";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import searchIcon from "../../../../assets/search.svg";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import UtilityFunctions from "../../../common/UtilityFunctions";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import RoleAuthorizationAPI from "../../../../api/RoleAuthorization/RoleAuthorizationAPI";
import InventoryAPI from "../../../../api/Inventory/InventoryAPI";

const CollectorOptions = {
  WS: 1,
  NWCO_ACO: 2,
  Cooperator: 3,
  Other: 4,
};

const CollectionMethodOptions = {
  Cage_Trap: 1,
  Firearms_Shot: 2,
  Handcaught_Gathered: 3,
  LegFoot_Hold_Trap: 4,
  NonWS_Carcass_Collection: 5,
  NonWS_Euthanized: 6,
  Road_Kill: 7,
  WS_Incidental_Take: 8,
};

const CollectionActivitiesOptions = {
  Coordinated_TVR: 1,
  Shooting: 2,
  Surveillance_Enhanced: 3,
  Surveillance_Public_Health: 4,
  Trapping_ORVNaive: 5,
  Trapping_ORV_PostBait: 6,
};

const YesNoOptions = {
  Yes: 1,
  No: 2,
};

const NrmpTargetSpeciesOptions = {
  Yes: 1,
  No: 2,
  NoCapture: 3,
};

const BaitTypes = {
  CS: 1,
  Dog_Food_Polymer: 2,
  FMP: 3,
  None_Naive: 4,
  ONRAB: 5,
  Other: 6,
};

const SexOptions = {
  Female: 1,
  Male: 2,
  NotRecorded: 3,
};

const FateTypes = {
  DiedUnderCare: 1,
  Euthanized: 2,
  FoundDead: 3,
  NoFate: 4,
  Other: 5,
  Released: 6,
  Sampled_NonWSTake: 7,
  Sampled_WSTake: 8,
};

const YesNoNotRecordedOptions = {
  Yes: 1,
  No: 2,
  NoRecorded: 3,
};

const RabiesBrainLabOptions = {
  CDC: 1,
  LocalPublicHealthLab: 2,
  WS_dRIT: 3,
};

const RabiesBrainstemResultsOptions = {
  Positive: 1,
  Negative: 2,
  Indeterminate: 3,
};

const ToothLabOptions = {
  Johnstons: 1,
  Matsons: 2,
  OMNR: 3,
  None: 4,
};

const RabiesBloodLabOptions = {
  CDC: 1,
  GARC: 2,
  KSU: 3,
  None: 4,
  Wadsworth: 5,
};

const WarehouseTypes = {
  State: 1,
  Property: 2,
  Cooperator: 3,
  Employee: 4,
};

const WarehouseTransactionTypes = {
  IncomingTransfer: 1,
  OutgoingTransfer: 2,
  Receive: 3,
  Loss: 5,
  Mixing: 7,
  Reconciliation: 8,
  Spent: 9,
};

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

class RabiesDetailsPage extends CustomForm {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      data: {
        sampleDate: "",
        town: "",
        collector: "",
        wsName: "",
        otherCollector: "",
        selectedCollectionActivity: "",
        selectedCollectionMethod: "",
        densityStudy: "",
        densityStudyId: "",
        dateOfLastOrv: "",
        orvNative: "",
        baitType: "",
        otherBait: "",
        waypointNumber: "",
        latLongRecorded: "",
        latitude: "",
        longitude: "",
        nrmpTargetSpecies: "",
        misTargetSpecies: "",
        selectedSpecies: "",
        fateOfTrap: "",
        recaptureSelected: "",
        processedLessThan30DaysAgo: null,
        drugsAndVaccinesData: [
          { ieDrugOrVaccine: "", vialNumber: "", totalAmount: "", warehouse: "", availableDrugsOrVaccines: [] },
        ],
        animalId: "",
        sex: "",
        lactation: "",
        relativeAge: "",
        weightRecorded: "",
        weight: "",
        handVaccinated: "",
        selectedOtherSample: "",
        bloodCheckbox: false,
        pm1Checkbox: false,
        pm2Checkbox: false,
        k9Checkbox: false,
        jawCheckbox: false,
        dnaCheckbox: false,
        brainstemCheckbox: false,
        otherCheckbox: false,
        selectedFateForWs: "",
        otherFate: "",
        euthanizedReason: "",
        comments: "",
        freeText: "",
        selectedErsCategory: "",
        rabiesCheckbox: false,
        canineDistemperCheckbox: false,
        other3Checkbox: false,
        other4Checkbox: false,
        noneCheckbox: false,
        rabiesBrainstemTest: "",
        rabiesBrainLab: "",
        rabiesBrainTestDate: "",
        rabiesLabSpecimenNumber: "",
        rabiesBrainstemCondition: "",
        stainingIntensity: "",
        antigenDistribution: "",
        rabiesBrainstemResults: "",
        rabiesVariant: "",
        rabiesTestComments: "",
        toothLab: "",
        age: "",
        ageRecorded: "",
        ttccPresent: "",
        ageTtccComments: "",
        rabiesBloodLab: "",
        rabiesVnaIu: "",
        vnaInterpretation: "",
        vnaComments: "",
        distemperVnaInterpretation: "",
        distemperVnaDilution: "",
        distemperVnaComments: "",
        adenoVnaDilution: "",
        adenoVnaInterpretation: "",
        adenoVnaComments: "",
        stateId: "",
        countyId: "",
      },
      collectorOptions: [],
      collectionActivities: [],
      collectionMethods: [],
      filteredCollectionMethods: [],
      baitTypes: [],
      fateOfTrapOptions: [],
      otherSampleTypes: [],
      fateTypes: [],
      filteredFateTypes: [],
      euthanizedByWsReasons: [],
      ersCategoryOptions: [],
      rabiesBrainLabOptions: [],
      brainstemConditions: [],
      stainingIntensityOptions: [],
      antigenDistributionOptions: [],
      rabiesBrainstemResultsOptions: [],
      rabiesVariants: [],
      toothLabOptions: [],
      rabiesBloodLabOptions: [],
      interpretationOptions: [],
      rabiesVnaInterpretationOptions: [],
      nrmpTargetSpeciesOptions: [],
      misTargetOptions: [],
      yesNoOptions: [],
      yesNoNotRecordedOptions: [],
      yesNoNotTestedOptions: [],
      sexOptions: [],
      ageOptions: [],
      states: props.history.location.state?.states.map((state) => {
        state.id = state.stateUniqueIdentifier || state.id;
        state.name = state.state_name || state.name;
        return state;
      }),
      countries: props.history.location.state?.countries,
      rabiesData: "",
      isFormLoading: true,
      isEditMode: false,
      showSuccessfulUpdateModal: false,
      stateCounties: [],
      availableWarehouses: [],
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
    }

    const stainingIntensityOptions = await this.getEntityOptions("ritOptions");
    const yesNoNoCaptureOptions = await this.getEntityOptions("yesNoNoCapture");
    const nrmpTargetSpeciesOptions = yesNoNoCaptureOptions.map((option) => ({
      id: option.id,
      name: UtilityFunctions.getDisplayTextFromFieldObject(option),
    }));

    const otherSampleResults = await this.getEntityOptions("otherSample");
    const otherSampleTypes =
      otherSampleResults?.length > 0
        ? otherSampleResults.map((sample) => ({
            id: sample.id,
            name: UtilityFunctions.getDisplayTextFromFieldObject(sample),
          }))
        : [];

    const rabiesData = await this.getRabiesData();
    const data = rabiesData ? await this.updatePageData({ ...rabiesData }) : { ...this.state.data };

    const stateCounties = data.stateId ? await this.getCountyListbyState(data.stateId) : [];
    const fateTypes = await this.getEntityOptions("animalFate");

    const allCollectionMethods = await this.getEntityOptions("collectionMethod");

    this.setState({
      collectorOptions: await this.getEntityOptions("collector"),
      collectionActivities: await this.getEntityOptions("collectionActivity"),
      collectionMethods: allCollectionMethods,
      filteredCollectionMethods: [
        CollectorOptions.NWCO_ACO,
        CollectorOptions.Cooperator,
        CollectorOptions.Other,
      ].includes(Number.parseInt(data.collector))
        ? allCollectionMethods.filter(
            (m) =>
              m.id === CollectionMethodOptions.NonWS_Carcass_Collection ||
              m.id === CollectionMethodOptions.NonWS_Euthanized
          )
        : allCollectionMethods,
      baitTypes: await this.getEntityOptions("orvBaitType"),
      fateOfTrapOptions: await this.getEntityOptions("fateOfTrap"),
      otherSampleTypes,
      fateTypes,
      filteredFateTypes: fateTypes,
      euthanizedByWsReasons: await this.getEntityOptions("euthanizationReason"),
      ersCategoryOptions: await this.getEntityOptions("ersCategory"),
      rabiesBrainLabOptions: await this.getEntityOptions("labsForBrainSample"),
      brainstemConditions: await this.getEntityOptions("brainstemConditions"),
      stainingIntensityOptions,
      antigenDistributionOptions: stainingIntensityOptions,
      rabiesBrainstemResultsOptions: await this.getEntityOptions("labResults"),
      rabiesVariants: await this.getEntityOptions("rabiesVariants"),
      toothLabOptions: await this.getEntityOptions("labsForTooth"),
      rabiesBloodLabOptions: await this.getEntityOptions("labsForBlood"),
      interpretationOptions: await this.getEntityOptions("interpretation"),
      rabiesVnaInterpretationOptions: await this.getEntityOptions("rabiesVnaInterpretation"),
      yesNoOptions: await this.getEntityOptions("yesNo"),
      yesNoNotRecordedOptions: await this.getEntityOptions("yesNoNotRecorded"),
      yesNoNotTestedOptions: await this.getEntityOptions("yesNoNotTested"),
      yesNoNoCaptureOptions,
      nrmpTargetSpeciesOptions,
      sexOptions: await this.getEntityOptions("sexOptions"),
      ageOptions: await this.getEntityOptions("ageOptions"),
      misTargetOptions: await this.getEntityOptions("misTargetSpecies"),
      availableWarehouses: await this.getWarehousesData(rabiesData?.stateUniqueReference),
      data,
      rabiesData,
      stateCounties,
      isFormLoading: false,
      currentUsaState,
    });
  }

  async getEntityOptions(optionsType) {
    let options = [];
    const optionsResponse = await this.getApiOptionsResponse(optionsType);
    if (optionsResponse?.successful && optionsResponse.data?.length > 0) {
      options = optionsResponse.data;
      if (optionsType === "ersCategory") {
        options = options.map((e) => {
          e.displayText = e.id + " " + e.displayText.replace("-", " ");
          return e;
        });
      } else if (optionsType === "collector") {
        options = options.map((e) => {
          e.displayText = e.displayText === "Current User" ? "WS" : e.displayText;
          return e;
        });
      } else if (optionsType === "collectionActivity") {
        options = options.map((e) => {
          e.displayText = e.displayText.replace(" - ", "-");
          return e;
        });
      }
    }
    return options.map((o) => ({ id: o.id, name: UtilityFunctions.getDisplayTextFromFieldObject(o) }));
  }

  async getApiOptionsResponse(optionsType) {
    let optionsResponse = "";
    switch (optionsType) {
      case "collector":
        optionsResponse = await WorkTaskAPI.GetCollectorOptions();
        break;
      case "collectionActivity":
        optionsResponse = await WorkTaskAPI.GetCollectionActivityOptions();
        break;
      case "collectionMethod":
        optionsResponse = await WorkTaskAPI.GetCollectionMethodOptions();
        break;
      case "orvBaitType":
        optionsResponse = await WorkTaskAPI.GetOrvBaitTypeOptions();
        break;
      case "fateOfTrap":
        optionsResponse = await WorkTaskAPI.GetFateOfTrapOptions();
        break;
      case "drugsAndVaccines":
        optionsResponse = await WorkTaskAPI.GetDrugOrVaccineOptions();
        break;
      case "otherSample":
        optionsResponse = await WorkTaskAPI.GetOtherSampleOptions();
        break;
      case "animalFate":
        optionsResponse = await WorkTaskAPI.GetAnimalFateOptions();
        break;
      case "euthanizationReason":
        optionsResponse = await WorkTaskAPI.GetEuthanizationReasonOptions();
        break;
      case "ersCategory":
        optionsResponse = await WorkTaskAPI.GetErsCategoryOptions();
        break;
      case "labsForBrainSample":
        optionsResponse = await WorkTaskAPI.GetLabsForBrainSampleOptions();
        break;
      case "brainstemConditions":
        optionsResponse = await WorkTaskAPI.GetSampleConditionOptions();
        break;
      case "rabiesVariants":
        optionsResponse = await WorkTaskAPI.GetRabiesVariantOptions();
        break;
      case "labsForTooth":
        optionsResponse = await WorkTaskAPI.GetLabsForToothOptions();
        break;
      case "labsForBlood":
        optionsResponse = await WorkTaskAPI.GetLabsForBloodOptions();
        break;
      case "interpretation":
        optionsResponse = await WorkTaskAPI.GetInterpretationOptions();
        break;
      case "rabiesVnaInterpretation":
        optionsResponse = await WorkTaskAPI.GetRabiesVNAInterpretationOptions();
        break;
      case "labResults":
        optionsResponse = await WorkTaskAPI.GetLabResultsPosNegIndOptions();
        break;
      case "yesNo":
        optionsResponse = await WorkTaskAPI.GetYesNoOptions();
        break;
      case "yesNoNotRecorded":
        optionsResponse = await WorkTaskAPI.GetYesNoNotRecordedOptions();
        break;
      case "yesNoNotTested":
        optionsResponse = await WorkTaskAPI.GetYesNoNotTestedOptions();
        break;
      case "yesNoNoCapture":
        optionsResponse = await WorkTaskAPI.GetYesNoNoCaptureOptions();
        break;
      case "sexOptions":
        optionsResponse = await WorkTaskAPI.GetSexOptions();
        break;
      case "ageOptions":
        optionsResponse = await WorkTaskAPI.GetRabiesAgeOptions();
        break;
      case "ritOptions":
        optionsResponse = await WorkTaskAPI.GetdRitOptions();
        break;
      case "misTargetSpecies":
        optionsResponse = await WorkTaskAPI.GetdMISTargetOptions();
        break;
      default:
        break;
    }

    return optionsResponse;
  }

  async getRabiesData() {
    const rabiesData = await this.getRabiesDataFromAPI();
    return rabiesData;
  }

  async getRabiesDataFromAPI() {
    let rabiesData = "";
    const entityData = this.props.history.location.state?.entityData;
    if (entityData?.nationalRabiesMonitoringProgramUniqueIdentifier) {
      const getNRMPResponse = await WorkTaskAPI.GetNRMP(entityData.nationalRabiesMonitoringProgramUniqueIdentifier);
      if (getNRMPResponse?.successful) {
        rabiesData = getNRMPResponse.data;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve NRMP data",
          apiName: "GetNRMP",
          responseUnsuccessful: getNRMPResponse?.unsuccessful,
          responseMessage: getNRMPResponse?.message,
        });
      }
    }

    return rabiesData;
  }

  async getCountyListbyState(stateGuid) {
    let countyList = [];
    if (stateGuid) {
      const countyResults = await ReferenceFileAPI.GetCountiesByState(stateGuid);
      if (countyResults?.length > 0) {
        countyList = countyResults
          .map((county) => ({
            id: county.countyUniqueIdentifier,
            name: county.county_name,
          }))
          .sort((a, b) => (a.name < b.name ? -1 : 1));
      }
    }

    return countyList;
  }

  async updatePageData(rabiesData) {
    const data = { ...this.state.data };

    const { currentUserProfile } = this.props;
    if (currentUserProfile) {
      data.wsName = {
        label: `${currentUserProfile?.firstName} ${currentUserProfile?.lastName}`,
        profileName: currentUserProfile?.profileName,
        value: currentUserProfile?.wsUserProfileUniqueIdentifier,
      };
    }

    data.sampleDate = rabiesData.date ? new Date(rabiesData.date).toLocaleDateString("en-CA", { timeZone: "UTC" }) : "";
    data.stateId = rabiesData.stateUniqueReference;
    data.countyId = rabiesData.countyUniqueReference;
    data.town = rabiesData.townOrCity;
    data.collector = rabiesData.collector?.id;

    if (rabiesData.otherCollector) {
      const userData = await this.getUserDataFromProfile(rabiesData.otherCollector);
      data.otherCollector = {
        value: rabiesData.otherCollector,
        profileName: rabiesData.otherCollectorProfileName,
        label: userData.firstName + " " + userData.lastName,
        wsUserUniqueIdentifier: userData.wsUserUniqueIdentifier,
      };
    }

    data.selectedCollectionActivity = rabiesData.collectionActivity?.id;
    data.selectedCollectionMethod = rabiesData.collectionMethod?.id;
    data.densityStudy = rabiesData.densityStudy?.id;
    data.densityStudyId = rabiesData.densityStudyId;
    data.dateOfLastOrv = rabiesData.dateOfLastOrv
      ? new Date(rabiesData.dateOfLastOrv).toLocaleDateString("en-CA", { timeZone: "UTC" })
      : "";
    data.orvNative = rabiesData.isOrvNative?.id;
    data.baitType = rabiesData.orvBaitType?.id;
    data.nrmpTargetSpecies = rabiesData.isNrmpTarget?.id;
    data.otherBait = rabiesData.otherBait;
    data.waypointNumber = rabiesData.waypointNumber;
    data.latLongRecorded = rabiesData.isLatLongRecorded?.id;
    data.latitude = rabiesData.latitude;
    data.longitude = rabiesData.longitude;
    data.misTargetSpecies = rabiesData.isMisTarget?.id;

    if (rabiesData.speciesBeingTestedUniqueReference) {
      const getSpeciesResponse = await ReferenceFileAPI.GetSpecies(rabiesData.speciesBeingTestedUniqueReference);
      if (getSpeciesResponse?.successful) {
        data.selectedSpecies = {
          value: rabiesData.speciesBeingTestedUniqueReference,
          label: getSpeciesResponse.data?.name,
        };
      }
    }

    data.fateOfTrap = rabiesData.fateOfTrap?.id;
    data.recaptureSelected = rabiesData.isRecapture?.id;
    data.processedLessThan30DaysAgo = rabiesData.wasProcessedLessThanThirtyDaysAgo?.id;
    data.drugsAndVaccinesData = await Promise.all(
      rabiesData.drugsAndVaccines?.map(async (d) => {
        return {
          ieDrugOrVaccine: {
            value: d.substanceWarehouseItemUniqueReference,
            label: d.methodName,
            methodUOM: d.unitOfMeasure,
            methodCategory: "IE DRUGS",
            methodUniqueReference: d.methodUniqueReference,
          },
          vialNumber: d.vialNumber,
          totalAmount: d.totalAmountInCc,
          warehouse: { label: d.warehouseName, value: d.warehouseUniqueIdentifier },
          availableDrugsOrVaccines: await this.getAvailableDrugsAndVaccinesPerWarehouse(d.warehouseUniqueIdentifier),
        };
      })
    );
    data.animalId = rabiesData.animalId;
    data.sex = rabiesData.animalSex?.id;
    data.lactation = rabiesData.isLactating?.id;
    data.relativeAge = rabiesData.age?.id;
    data.weightRecorded = rabiesData.isWeightRecorded?.id;
    data.weight = rabiesData.weightKg;
    data.handVaccinated = rabiesData.isHandVaccinated?.id;
    data.bloodCheckbox = rabiesData.isBloodTested;
    data.pm1Checkbox = rabiesData.isPm1Tested;
    data.pm2Checkbox = rabiesData.isPm2Tested;
    data.k9Checkbox = rabiesData.isK9Tested;
    data.jawCheckbox = rabiesData.isJawTested;
    data.dnaCheckbox = rabiesData.isDnaTested;
    data.brainstemCheckbox = rabiesData.isBrainstemTested;
    data.otherCheckbox = rabiesData.areOtherSamplesTested;
    data.selectedFateForWs = rabiesData.fate?.id;
    data.otherFate = rabiesData.otherFate;
    data.euthanizedReason = rabiesData.euthanizedReason?.id;
    data.selectedErsCategory = rabiesData.ersCategory?.id;
    data.comments = rabiesData.comments;
    data.freeText = rabiesData.freeText || "";
    data.rabiesCheckbox = rabiesData.areTestingForRabies;
    data.canineDistemperCheckbox = rabiesData.areTestingForCanineDistemper;
    data.other3Checkbox = rabiesData.areTestingForOtherThird;
    data.other4Checkbox = rabiesData.areTestingForOtherFourth;
    data.noneCheckbox = rabiesData.areTestingForNothing;
    data.rabiesBrainstemTest = rabiesData.isRabiesBrainstemTest?.id;
    data.rabiesBrainLab = rabiesData.labBrainSentTo?.id;
    data.rabiesBrainTestDate = rabiesData.dateOfTest
      ? new Date(rabiesData.dateOfTest).toLocaleDateString("en-CA", { timeZone: "UTC" })
      : "";
    data.rabiesLabSpecimenNumber = rabiesData.rabiesLabSpecimenNumber;
    data.rabiesBrainstemCondition = rabiesData.conditionOfBrainstem?.id;
    data.stainingIntensity = rabiesData.dritStainingIntensity?.id;
    data.antigenDistribution = rabiesData.dritAntigenDistribution?.id;
    data.rabiesBrainstemResults = rabiesData.brainstemResults?.id;
    data.rabiesVariant = rabiesData.rabiesVariant?.id;
    data.rabiesTestComments = rabiesData.rabiesTestComments || "";
    data.toothLab = rabiesData.labToothSentTo?.id;
    data.ageRecorded = rabiesData.isAgeRecorded?.id;
    data.age = rabiesData.years;
    data.ttccPresent = rabiesData.isTTCCPresent?.id;
    data.ageTtccComments = rabiesData.ageTTCCComments || "";
    data.rabiesBloodLab = rabiesData.labBloodSentTo?.id;
    data.rabiesVnaIu = rabiesData.rabiesVna;
    data.vnaInterpretation = rabiesData.rabiesInterpretation?.id;
    data.vnaComments = rabiesData.rabiesVnaComments || "";
    data.distemperVnaDilution = rabiesData.distemperVna;
    data.distemperVnaInterpretation = rabiesData.distemperInterpretation?.id;
    data.distemperVnaComments = rabiesData.distemperVnaComments || "";
    data.adenoVnaDilution = rabiesData.adenoVna;
    data.adenoVnaInterpretation = rabiesData.adenoInterpretation?.id;
    data.adenoVnaComments = rabiesData.adenoVnaComments || "";

    return data;
  }

  async getWarehousesData(stateId) {
    let warehousesData = [];
    if (this.props.currentUserProfile?.wsUserProfileUniqueIdentifier && stateId) {
      const getWarehousesResponse = await InventoryAPI.GetMyWarehousesForStateByType(
        this.props.currentUserProfile.wsUserProfileUniqueIdentifier,
        stateId,
        WarehouseTypes.Employee
      );
      if (getWarehousesResponse?.successful && getWarehousesResponse.data?.results?.length > 0) {
        warehousesData = getWarehousesResponse.data.results
          .filter((w) => w.isActive)
          ?.map((w) => ({ label: w.warehouseName, value: w.warehouseUniqueIdentifier }));
      }
    }
    return warehousesData;
  }

  async getUnavailableAnimalIDs() {
    let unavailableIDs = [];
    if (this.state.currentUsaState?.stateGuid && this.state.data.sampleDate) {
      const nrmpDataResponse = await WorkTaskAPI.NRMPStructuredSearch(
        this.getSearchNRMPBody(this.state.currentUsaState.stateGuid),
        this.controller.signal
      );
      if (!nrmpDataResponse?.successful) {
        ErrorHandler.showErrorWithMessage(
          nrmpDataResponse?.message ? nrmpDataResponse.message : "Existing NRMP Data could not be retrieved."
        );
      } else if (nrmpDataResponse.data?.results?.length > 0) {
        unavailableIDs = nrmpDataResponse.data.results
          .filter((s) => s.animalId !== this.state.rabiesData?.animalId)
          .map((sample) => sample.animalId);
      }
      if (this.controller.signal.aborted) {
        return [];
      }
    }

    return unavailableIDs;
  }

  getSearchNRMPBody(stateId) {
    const { data } = this.state;
    return {
      stateUniqueReference: stateId,
      dateNRMPStartedStart: new Date(data.sampleDate).toISOString(),
      dateNRMPStartedEnd: new Date(data.sampleDate).toISOString(),
      countyUniqueReference: null,
      townOrCity: null,
      userProfileWhoEnteredNRMP: null,
      speciesBeingTestedUniqueReference: null,
      waypointNumber: null,
      animalId: null,
      collectionActivityEnumId: null,
    };
  }

  handleEditRabies = async () => {
    if (!this.state.isEditMode) {
      this.setState({ isEditMode: true });
    } else {
      this.setState({ isFormLoading: true });
      let { rabiesData } = this.state;
      if (!this.isFormDataValid()) {
        toast.warning("Please fill out all the required fields.");
        this.checkForInvalidData();
      } else if (rabiesData?.nationalRabiesMonitoringProgramUniqueIdentifier) {
        const unavailableIDs = await this.getUnavailableAnimalIDs();
        if (
          unavailableIDs?.length > 0 &&
          this.state.data.animalId &&
          unavailableIDs.includes(this.state.data.animalId)
        ) {
          toast.warning(
            "The Animal ID entered was already used in another Rabies Data Sheet today. Please select a different one."
          );
        } else {
          const editNRMPResponse = await WorkTaskAPI.EditNRMP(
            rabiesData.nationalRabiesMonitoringProgramUniqueIdentifier,
            this.getEditNRMPBody()
          );
          if (editNRMPResponse?.successful) {
            await this.performWarehouseTransactions();
            rabiesData = editNRMPResponse.data;
            const data = rabiesData ? await this.updatePageData({ ...rabiesData }) : { ...this.state.data };
            this.setState({ data, rabiesData, showSuccessfulUpdateModal: true, isEditMode: false });
          } else {
            ErrorHandler.handleApiErrorMessage({
              errorContextMessage: "Unable to update sample",
              apiName: "EditNRMP",
              responseUnsuccessful: editNRMPResponse?.unsuccessful,
              responseMessage: editNRMPResponse?.message,
            });
          }
        }
      }
      this.setState({ isFormLoading: false });
    }
  };

  isFormDataValid() {
    const { data } = this.state;
    return (
      data.sampleDate &&
      data.collector &&
      (data.otherCollector || Number.parseInt(data.collector) !== CollectorOptions.Other) &&
      data.selectedCollectionActivity &&
      data.selectedCollectionMethod &&
      data.densityStudy &&
      (data.densityStudyId || Number.parseInt(data.densityStudy) !== YesNoOptions.Yes) &&
      (Number.parseInt(data.orvNative) === YesNoOptions.No ? data.dateOfLastOrv : true) &&
      data.nrmpTargetSpecies &&
      (data.baitType || Number.parseInt(data.nrmpTargetSpecies) !== NrmpTargetSpeciesOptions.Yes) &&
      data.latLongRecorded &&
      ((data.latitude?.toString()?.split(".")[1]?.length >= 5 &&
        data.longitude?.toString()?.split(".")[1]?.length >= 5) ||
        Number.parseInt(data.latLongRecorded) !== YesNoOptions.Yes) &&
      (data.misTargetSpecies || Number.parseInt(data.nrmpTargetSpecies) !== NrmpTargetSpeciesOptions.No) &&
      (data.selectedSpecies || Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.NoCapture) &&
      (data.fateOfTrap || Number.parseInt(data.nrmpTargetSpecies) !== NrmpTargetSpeciesOptions.NoCapture) &&
      (data.recaptureSelected || Number.parseInt(data.nrmpTargetSpecies) !== NrmpTargetSpeciesOptions.Yes) &&
      (data.processedLessThan30DaysAgo ||
        Number.parseInt(data.recaptureSelected) !== YesNoOptions.Yes ||
        Number.parseInt(data.nrmpTargetSpecies) !== NrmpTargetSpeciesOptions.Yes) &&
      (data.lactation || Number.parseInt(data.sex) !== SexOptions.Female) &&
      (Number.parseInt(data.nrmpTargetSpecies) !== NrmpTargetSpeciesOptions.Yes ||
        data.processedLessThan30DaysAgo !== YesNoOptions.No ||
        (data.animalId && data.sex && data.relativeAge && data.weightRecorded)) &&
      (Number.parseInt(data.weightRecorded) !== YesNoOptions.Yes || (data.weight && this.isWeightValid(data.weight))) &&
      (data.handVaccinated ||
        (data.processedLessThan30DaysAgo === YesNoOptions.Yes &&
          !data.drugsAndVaccinesData.some(
            (item) =>
              item.ieDrugOrVaccine?.methodName?.toLowerCase().includes("imrab3") ||
              item.ieDrugOrVaccine?.methodName?.toLowerCase().includes("rabies vaccine")
          ))) &&
      !data.drugsAndVaccinesData?.some((d) => d.totalAmountError) &&
      data.selectedFateForWs &&
      (data.otherFate || Number.parseInt(data.selectedFateForWs) !== FateTypes.Other) &&
      (data.euthanizedReason || Number.parseInt(data.selectedFateForWs) !== FateTypes.Euthanized) &&
      (data.selectedErsCategory || !data.brainstemCheckbox) &&
      (data.rabiesBrainstemTest || !data.brainstemCheckbox) &&
      (data.rabiesBrainLab || Number.parseInt(data.rabiesBrainstemTest) !== YesNoNotRecordedOptions.Yes) &&
      (Number.parseInt(data.rabiesBrainstemTest) !== YesNoNotRecordedOptions.Yes ||
        Number.parseInt(data.rabiesBrainLab) !== RabiesBrainLabOptions.WS_dRIT ||
        (data.rabiesBrainTestDate &&
          data.rabiesLabSpecimenNumber &&
          data.rabiesBrainstemCondition &&
          data.stainingIntensity &&
          data.antigenDistribution)) &&
      (data.rabiesBrainstemResults || Number.parseInt(data.rabiesBrainstemTest) !== YesNoNotRecordedOptions.Yes) &&
      (data.rabiesVariant ||
        Number.parseInt(data.rabiesBrainstemResults) !== RabiesBrainstemResultsOptions.Positive ||
        Number.parseInt(data.rabiesBrainstemTest) !== YesNoNotRecordedOptions.Yes) &&
      (data.rabiesTestComments ||
        data.noneCheckbox ||
        Number.parseInt(data.rabiesBrainstemTest) !== YesNoNotRecordedOptions.Yes ||
        Number.parseInt(data.rabiesBrainstemResults) !== RabiesBrainstemResultsOptions.Indeterminate) &&
      (data.toothLab || !(data.pm1Checkbox || data.pm2Checkbox || data.k9Checkbox || data.jawCheckbox)) &&
      (data.age || Number.parseInt(data.ageRecorded) !== YesNoOptions.Yes) &&
      (data.rabiesBloodLab || !data.rabiesCheckbox) &&
      (data.distemperVnaInterpretation ||
        !data.canineDistemperCheckbox ||
        data.noneCheckbox ||
        !data.distemperVnaDilution) &&
      (data.adenoVnaInterpretation ||
        !data.adenoVnaDilution ||
        data.noneCheckbox ||
        (!data.other3Checkbox && !data.other4Checkbox))
    );
  }

  checkForInvalidData() {
    const { data, errors } = this.state;

    if (!data.sampleDate) {
      errors.sampleDate = "A valid Date is required";
    }
    if (!data.collector) {
      errors.collector = "Collector is required";
    }
    if (Number.parseInt(data.collector) === CollectorOptions.Other && !data.otherCollector) {
      errors.otherCollector = "Other Collector is required";
    }
    if (!data.selectedCollectionActivity) {
      errors.selectedCollectionActivity = "Collection Activity is required";
    }
    if (!data.selectedCollectionMethod) {
      errors.selectedCollectionMethod = "Collection Method is required";
    }
    if (!data.densityStudy) {
      errors.densityStudy = "Density Study is required";
    }
    if (!data.densityStudyId && Number.parseInt(data.densityStudy) === YesNoOptions.Yes) {
      errors.densityStudyId = "Density Study ID is required";
    }
    if (Number.parseInt(data.orvNative) === YesNoOptions.No && !data.dateOfLastOrv) {
      errors.dateOfLastOrv = "Date Of Last ORV is required";
    }
    if (!data.nrmpTargetSpecies) {
      errors.nrmpTargetSpecies = "NRMP Target Species is required";
    }
    if (Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes && !data.baitType) {
      errors.baitType = "Bait Type is required";
    }
    if (!data.latLongRecorded) {
      errors.latLongRecorded = "Lat/Long Recorded? is required";
    }
    if (Number.parseInt(data.latLongRecorded) === YesNoOptions.Yes) {
      if (!data.latitude) {
        errors.latitude = "Latitude is required";
      }
      if (!data.longitude) {
        errors.longitude = "Longitude is required";
      }
      if (data.latitude.split(".")[1].length < 5) {
        errors.latitude = "Please specify at least 5 decimal places for latitude";
      }
      if (data.latitude.split(".")[1].length < 5) {
        errors.latitude = "Please specify at least 5 decimal places for longitude";
      }
    }
    if (Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.No && !data.misTargetSpecies) {
      errors.misTargetSpecies = "MIS Target Species is required.";
    }
    if (Number.parseInt(data.nrmpTargetSpecies) !== NrmpTargetSpeciesOptions.NoCapture) {
      if (!data.selectedSpecies) {
        errors.selectedSpecies = "Damage Agent is required.";
      }
      if (Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes && !data.recaptureSelected) {
        errors.recaptureSelected = "Recapture option is required";
      }
    }
    if (this.isProcessedLessThan30DaysAgoRequired(data) && !data.processedLessThan30DaysAgo) {
      errors.processedLessThan30DaysAgo = "Selection required";
    }
    if (this.areAnimalDetailsFieldsRequired()) {
      if (!data.sex) {
        errors.sex = "Sex is required";
      }
      if (!data.animalId) {
        errors.animalId = "Animal ID is required";
      }
      if (!data.relativeAge) {
        errors.relativeAge = "Relative Age is required";
      }
    }
    if (Number.parseInt(data.sex) === SexOptions.Female && !data.lactation) {
      errors.lactation = "Lactation is required";
    }
    if (this.isWeightRecordedRequired() && !data.weightRecorded) {
      errors.weightRecorded = "Please select an option";
    }
    if (Number.parseInt(data.weightRecorded) === YesNoOptions.Yes) {
      if (!data.weight) {
        errors.weight = "Please enter Weight";
      } else if (!this.isWeightValid(data.weight)) {
        errors.weight = "Weight should be a number in the range of 1-99 Kg.";
      }
    }
    if (Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.NoCapture && !data.fateOfTrap) {
      errors.fateOfTrap = "Fate Of Trap is required";
    }
    if (this.isHandVaccinatedRequired() && !data.handVaccinated) {
      errors.handVaccinated = "Please select an option";
    }
    if (!data.selectedFateForWs) {
      errors.selectedFateForWs = "Please select an option";
    }
    if (Number.parseInt(data.selectedFateForWs) === FateTypes.Other && !data.otherFate) {
      errors.otherFate = "Other Fate is required";
    }
    if (Number.parseInt(data.selectedFateForWs) === FateTypes.Euthanized && !data.euthanizedReason) {
      errors.euthanizedReason = "Euthanized Reason is required";
    }
    if (data.brainstemCheckbox) {
      if (!data.selectedErsCategory) {
        errors.selectedErsCategory = "Please select an option";
      }
      if (!data.rabiesBrainstemTest) {
        errors.rabiesBrainstemTest = "Please select an option";
      }
    }
    if (Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes && !data.rabiesBrainLab) {
      errors.rabiesBrainLab = "Please select an option";
    }
    if (
      Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes &&
      Number.parseInt(data.rabiesBrainLab) === RabiesBrainLabOptions.WS_dRIT
    ) {
      if (!data.antigenDistribution) {
        errors.antigenDistribution = "Please select an option";
      }
      if (!data.stainingIntensity) {
        errors.stainingIntensity = "Please select an option";
      }
    }
    if (
      Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes &&
      Number.parseInt(data.rabiesBrainLab) === RabiesBrainLabOptions.WS_dRIT
    ) {
      if (!data.rabiesLabSpecimenNumber) {
        errors.rabiesLabSpecimenNumber = "Specimen Number is required";
      }
      if (!data.rabiesBrainstemCondition) {
        errors.rabiesBrainstemCondition = "Brainstem Condition is required";
      }
    }
    if (Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes && !data.rabiesBrainstemResults) {
      errors.rabiesBrainstemResults = "Brainstem Results are required";
    }
    if (
      Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes &&
      Number.parseInt(data.rabiesBrainstemResults) === RabiesBrainstemResultsOptions.Positive &&
      !data.rabiesVariant
    ) {
      errors.rabiesVariant = "Rabies Variant are required";
    }
    if (
      !data.noneCheckbox &&
      Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes &&
      Number.parseInt(data.rabiesBrainstemResults) === RabiesBrainstemResultsOptions.Indeterminate &&
      !data.rabiesTestComments
    ) {
      errors.rabiesTestComments = "Comments are required";
    }
    if (this.isToothLabRequired() && !data.toothLab) {
      errors.toothLab = "Tooth Lab is required";
    }
    if (Number.parseInt(data.ageRecorded) === YesNoOptions.Yes && !data.age) {
      errors.age = "Age is required";
    }
    if (data.rabiesCheckbox && !data.rabiesBloodLab) {
      errors.rabiesBloodLab = "Blood Lab is required";
    }
    if (
      data.canineDistemperCheckbox &&
      !data.noneCheckbox &&
      data.distemperVnaDilution &&
      !data.distemperVnaInterpretation
    ) {
      errors.distemperVnaInterpretation = "Distemper VNA Interpretation is required";
    }
    if (
      (data.other3Checkbox || data.other4Checkbox) &&
      !data.noneCheckbox &&
      data.adenoVnaDilution &&
      !data.adenoVnaInterpretation
    ) {
      errors.adenoVnaInterpretation = "Adeno VNA Interpretation is required";
    }

    this.setState(errors);
  }

  isProcessedLessThan30DaysAgoRequired(data) {
    return (
      Number.parseInt(data.recaptureSelected) === YesNoOptions.Yes &&
      Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes
    );
  }

  areAnimalDetailsFieldsRequired() {
    const { data } = this.state;
    return (
      Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes &&
      data.processedLessThan30DaysAgo === YesNoOptions.No
    );
  }

  isWeightRecordedRequired() {
    const { data } = this.state;
    return (
      Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes &&
      data.processedLessThan30DaysAgo === YesNoOptions.No
    );
  }

  isWeightValid(weight) {
    return Number.parseFloat(weight) > 0 && Number.parseFloat(weight) < 100;
  }

  isHandVaccinatedRequired() {
    const { data } = this.state;
    return (
      data.processedLessThan30DaysAgo !== YesNoOptions.Yes ||
      data.drugsAndVaccinesData.some(
        (item) =>
          item.ieDrugOrVaccine?.methodName?.toLowerCase().includes("imrab3") ||
          item.ieDrugOrVaccine?.methodName?.toLowerCase().includes("rabies vaccine")
      )
    );
  }

  isToothLabRequired() {
    const { data } = this.state;
    return data.pm1Checkbox || data.pm2Checkbox || data.k9Checkbox || data.jawCheckbox;
  }

  isBloodLabAvailable() {
    const { data } = this.state;
    return (
      data.rabiesCheckbox ||
      data.canineDistemperCheckbox ||
      data.other3Checkbox ||
      data.other4Checkbox ||
      data.noneCheckbox
    );
  }

  getEditNRMPBody() {
    const { data } = this.state;
    const editNRMPBody = {
      date: data.sampleDate ? new Date(data.sampleDate).toISOString() : null,
      dateOfTest: data.rabiesBrainTestDate ? new Date(data.rabiesBrainTestDate).toISOString() : null,
      stateUniqueReference: data.stateId,
      countyUniqueReference: data.countyId,
      townOrCity: data.town,
      collectorEnumId: data.collector ? data.collector : null,
      wsNameUserUniqueReference: data.wsName?.value ? data.wsName.value : null,
      otherCollector: data.otherCollector?.value ? data.otherCollector.value : null,
      collectionActivityEnumId: data.selectedCollectionActivity ? data.selectedCollectionActivity : null,
      collectionMethodEnumId: data.selectedCollectionMethod ? data.selectedCollectionMethod : null,
      densityStudyYesNoEnumId: data.densityStudy ? data.densityStudy : null,
      densityStudyId: data.densityStudyId,
      waypointNumber: data.waypointNumber,
      isLatLongRecordedYesNoEnumId: data.latLongRecorded ? data.latLongRecorded : null,
      latitude: data.latitude,
      longitude: data.longitude,
      isOrvNativeYesNoEnumId: data.orvNative ? data.orvNative : null,
      orvBaitTypeEnumId: data.baitType ? data.baitType : null,
      otherBait: data.otherBait,
      isNrmpTargetYesNoNoCaptureEnumId: data.nrmpTargetSpecies ? data.nrmpTargetSpecies : null,
      isMisTargetEnumId: data.misTargetSpecies ? data.misTargetSpecies : null,
      densityStudy2YesNoEnumId: null,
      speciesBeingTestedUniqueReference: data.selectedSpecies?.value ? data.selectedSpecies.value : null,
      fateOfTrapEnumId: data.fateOfTrap ? data.fateOfTrap : null,
      isRecaptureYesNoEnumId: data.recaptureSelected ? data.recaptureSelected : null,
      wasProcessedLessThanThirtyDaysAgoYesNoEnumId: data.processedLessThan30DaysAgo
        ? data.processedLessThan30DaysAgo
        : null,
      animalId: data.animalId,
      animalSexEnumId: data.sex ? data.sex : null,
      ageEnumId: data.relativeAge ? data.relativeAge : null,
      isWeightRecordedYesNoEnumId: data.weightRecorded ? data.weightRecorded : null,
      weightKg: data.weight ? Number.parseFloat(data.weight) : null,
      isLactatingYesNoNotRecordedEnumId: data.lactation ? data.lactation : null,
      isHandVaccinatedYesNoNotRecordedEnumId: data.handVaccinated ? data.handVaccinated : null,
      otherSampleEnumId: data.selectedOtherSample?.value ? data.selectedOtherSample.value : null,
      fateEnumId: data.selectedFateForWs ? data.selectedFateForWs : null,
      otherFate: data.otherFate,
      euthanizedReasonEnumId: data.euthanizedReason ? data.euthanizedReason : null,
      comments: data.comments,
      freeText: data.freeText,
      isBloodTested: data.bloodCheckbox,
      isPm1Tested: data.pm1Checkbox,
      isPm2Tested: data.pm2Checkbox,
      isK9Tested: data.k9Checkbox,
      isJawTested: data.jawCheckbox,
      isDnaTested: data.dnaCheckbox,
      isBrainstemTested: data.brainstemCheckbox,
      areOtherSamplesTested: data.otherCheckbox,
      ersCategoryEnumId: data.selectedErsCategory ? data.selectedErsCategory : null,
      areTestingForRabies: data.rabiesCheckbox,
      areTestingForCanineDistemper: data.canineDistemperCheckbox,
      areTestingForOtherThird: data.other3Checkbox,
      areTestingForOtherFourth: data.other4Checkbox,
      areTestingForNothing: data.noneCheckbox,
      isRabiesBrainstemTestYesNoNotRecordedEnumId: data.rabiesBrainstemTest ? data.rabiesBrainstemTest : null,
      labBrainSentToEnumId: data.rabiesBrainLab ? data.rabiesBrainLab : null,
      rabiesLabSpecimenNumber: data.rabiesLabSpecimenNumber ? data.rabiesLabSpecimenNumber : data.animalId,
      conditionOfBrainstemEnumId: data.rabiesBrainstemCondition ? data.rabiesBrainstemCondition : null,
      stainingIntensityDRITEnumId: data.stainingIntensity ? data.stainingIntensity : null,
      antigenDistributionDRITEnumId: data.antigenDistribution ? data.antigenDistribution : null,
      brainstemResultsEnumId: data.rabiesBrainstemResults ? data.rabiesBrainstemResults : null,
      rabiesVariantEnumId: data.rabiesVariant ? data.rabiesVariant : null,
      rabiesTestComments:
        !data.noneCheckbox && Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes
          ? data.rabiesTestComments
          : "",
      labToothSentToEnumId: this.isToothLabRequired() && data.toothLab ? data.toothLab : null,
      isAgeRecordedYesNoEnumId: this.isToothLabRequired() && data.ageRecorded ? data.ageRecorded : null,
      years: this.isToothLabRequired() && data.age ? Number.parseInt(data.age) : null,
      isTTCCPresentYesNoNotTestedEnumId: this.isToothLabRequired() && data.ttccPresent ? data.ttccPresent : null,
      ageTTCCComments: this.isToothLabRequired() && data.ageTtccComments ? data.ageTtccComments : "",
      labBloodSentToEnumId: this.isBloodLabAvailable() && data.rabiesBloodLab ? data.rabiesBloodLab : null,
      rabiesVna:
        !data.noneCheckbox &&
        (!this.isBloodLabAvailable() || Number.parseInt(data.rabiesBloodLab) !== RabiesBloodLabOptions.None) &&
        data.rabiesVnaIu
          ? data.rabiesVnaIu
          : null,
      rabiesInterpretationEnumId:
        !data.noneCheckbox &&
        (!this.isBloodLabAvailable() || Number.parseInt(data.rabiesBloodLab) !== RabiesBloodLabOptions.None) &&
        data.vnaInterpretation
          ? data.vnaInterpretation
          : null,
      rabiesVnaComments:
        !data.noneCheckbox && Number.parseInt(data.rabiesBloodLab) !== RabiesBloodLabOptions.None && data.vnaComments
          ? data.vnaComments
          : "",
      distemperVna: data.canineDistemperCheckbox && !data.noneCheckbox ? data.distemperVnaDilution : null,
      distemperInterpretationEnumId:
        data.canineDistemperCheckbox && !data.noneCheckbox && data.distemperVnaInterpretation
          ? data.distemperVnaInterpretation
          : null,
      distemperVnaComments:
        !data.noneCheckbox &&
        (!this.isBloodLabAvailable() || Number.parseInt(data.rabiesBloodLab) !== RabiesBloodLabOptions.None) &&
        data.distemperVnaComments
          ? data.distemperVnaComments
          : "",
      adenoVna: (data.other3Checkbox || data.other4Checkbox) && !data.noneCheckbox ? data.adenoVnaDilution : null,
      adenoInterpretationEnumId:
        (data.other3Checkbox || data.other4Checkbox) && !data.noneCheckbox && data.adenoVnaInterpretation
          ? data.adenoVnaInterpretation
          : null,
      adenoVnaComments: !data.noneCheckbox ? data.adenoVnaComments : "",
      drugsAndVaccines: data.drugsAndVaccinesData.some((entry) => entry.ieDrugOrVaccine?.value && entry.totalAmount)
        ? data.drugsAndVaccinesData
            .filter((d) => d.ieDrugOrVaccine?.value && d.totalAmount)
            ?.map((entry) => ({
              vialNumber: entry.vialNumber,
              totalAmountInCc: Number.parseFloat(entry.totalAmount),
              substanceWarehouseItemUniqueReference: entry.ieDrugOrVaccine?.value ? entry.ieDrugOrVaccine.value : null,
              itemUnitOfMeasureEnumId: entry.ieDrugOrVaccine?.methodUOM?.id ? entry.ieDrugOrVaccine.methodUOM.id : null,
            }))
        : [],
    };

    if (Number.parseInt(data.orvNative) !== YesNoOptions.Yes && data.dateOfLastOrv) {
      editNRMPBody["dateOfLastOrv"] = new Date(data.dateOfLastOrv).toISOString();
    }

    return editNRMPBody;
  }

  async performWarehouseTransactions() {
    const warehouseTransactionsResponse = await InventoryAPI.BulkPerformWarehouseTransactions(
      this.getWarehouseTransactionsBody()
    );
    if (!warehouseTransactionsResponse?.successful) {
      ErrorHandler.showErrorWithDetails(
        "One or more warehouse transactions could not be recorded.",
        warehouseTransactionsResponse.message
          ? warehouseTransactionsResponse.message
          : "Adding warehouses transaction failed."
      );
    }
  }

  getWarehouseTransactionsBody() {
    const { data, rabiesData } = this.state;
    let warehouseTransactionsBody = [];
    const validDrugsAndVaccines = data.drugsAndVaccinesData.filter((d) => d.ieDrugOrVaccine?.value && d.totalAmount);

    validDrugsAndVaccines.forEach((drugData) => {
      const existingDrug = rabiesData.drugsAndVaccines.find(
        (d) =>
          d.warehouseUniqueIdentifier === drugData.warehouse?.value &&
          d.methodUniqueReference === drugData.ieDrugOrVaccine?.methodUniqueReference &&
          d.vialNumber === drugData.vialNumber
      );
      if (!existingDrug) {
        warehouseTransactionsBody.push(this.getTransactionBody(drugData, WarehouseTransactionTypes.Spent));
      } else if (existingDrug.totalAmountInCc !== Number.parseInt(drugData.totalAmount)) {
        let transactionType;
        let transactionQuantity;
        if (Number.parseInt(drugData.totalAmount) > existingDrug.totalAmountInCc) {
          transactionType = WarehouseTransactionTypes.Spent;
          transactionQuantity = Number.parseInt(drugData.totalAmount) - existingDrug.totalAmountInCc;
        } else {
          transactionType = WarehouseTransactionTypes.Reconciliation;
          transactionQuantity = existingDrug.totalAmountInCc - Number.parseInt(drugData.totalAmount);
        }
        warehouseTransactionsBody.push(this.getTransactionBody(drugData, transactionType, transactionQuantity));
      }
    });

    rabiesData.drugsAndVaccines.forEach((existingDrug) => {
      if (
        !validDrugsAndVaccines?.some(
          (drugData) =>
            existingDrug.warehouseUniqueIdentifier === drugData.warehouse?.value &&
            existingDrug.methodUniqueReference === drugData.ieDrugOrVaccine?.methodUniqueReference &&
            existingDrug.vialNumber === drugData.vialNumber
        )
      ) {
        warehouseTransactionsBody.push(
          this.getTransactionBody(existingDrug, WarehouseTransactionTypes.Reconciliation, existingDrug.totalAmountInCc)
        );
      }
    });

    return warehouseTransactionsBody;
  }

  getTransactionBody(drugData, transactionType, transactionAmount = null) {
    const { data, currentUsaState } = this.state;
    const warehouseId = drugData.warehouse?.value || drugData.warehouseUniqueIdentifier;
    let transactionBody = {
      methodUOMEnumId: drugData.ieDrugOrVaccine?.methodUOM?.id || drugData.unitOfMeasure?.id,
      transactionTypeEnumId: transactionType,
      enteredByUserProfileUniqueReference: data.wsName?.value ? data.wsName.value : null,
      warehouseTransferToUniqueReference: warehouseId,
      warehouseTransferToStateUniqueReference: currentUsaState?.stateGuid,
      inventoryAdjustmentDate: new Date().toISOString(),
      methodUniquereference: drugData.ieDrugOrVaccine?.methodUniqueReference || drugData.methodUniqueReference,
      quantity: transactionAmount || Number.parseInt(drugData.totalAmount) || drugData.totalAmountInCc,
      warehouseTransferFromUniqueReference: warehouseId,
      warehouseTransferFromStateUniqueReference: currentUsaState?.stateGuid,
    };
    if ((transactionType = WarehouseTransactionTypes.Reconciliation)) {
      transactionBody.reason = "Correction to existing amount on Rabies Sample.";
    }

    return transactionBody;
  }

  handleSpeciesSearch = async (searchValue) => {
    let speciesResult = [];

    if (this.state.data.stateId) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(this.state.data.stateId, {
        textToSearchFor: searchValue,
        pageSize: 10,
        pageNumber: 1,
      });
      if (speciesData?.data?.results?.length > 0) {
        speciesResult = speciesData.data.results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return speciesResult;
  };

  handleWsSearch = async (inputValue) => {
    let outputContacts = [];

    if (this.state.data.stateId) {
      const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText({
        textToSearchFor: inputValue,
        pageSize: 25,
        pageNumber: 1,
        stateUniqueReference: this.state.data.stateId,
      });
      if (contactsData?.data?.results?.length > 0) {
        let results = contactsData.data.results;
        for (let i = 0; i < results.length; i++) {
          let userObject;
          let user = results[i];
          for (let j = 0; j < user.userProfiles.length; j++) {
            let userProfile = user.userProfiles[j];
            if (userProfile) {
              userObject = {
                value: userProfile.wsUserProfileUniqueIdentifier,
                label: user.firstName + " " + user.lastName,
                profileName: userProfile.profileName,
                wsUserUniqueIdentifier: user.wsUserUniqueIdentifier,
              };
              outputContacts.push(userObject);
            }
          }
        }
      }
    }
    return outputContacts;
  };

  getUserDataFromProfile = async (profileId) => {
    let userData = "";
    const getUserResponse = await RoleAuthorizationAPI.GetUserWhoOwnsProfile(profileId);
    if (getUserResponse?.successful) {
      userData = getUserResponse.data;
    }
    return userData;
  };

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    if (e?.value) {
      delete errors[fieldName];
    }

    this.setState({ data, errors });
  };

  handleDrugsAndVaccinesSelectChanges = (input, index) => {
    const data = JSON.parse(JSON.stringify(this.state.data));
    data.drugsAndVaccinesData[index]["ieDrugOrVaccine"] = input;

    if (this.isDuplicatedVaccineEntry(data, index)) {
      toast.warning(
        "There is already an entry for this combination of drug/warehouse/vial#. Please select a different value or update the existing entry instead."
      );
    } else {
      this.setState({ data });
    }
  };

  handleDrugsAndVaccinesWarehouseSelection = async (input, index) => {
    if (this.state.data.drugsAndVaccinesData[index].warehouse?.value !== input.value) {
      const data = JSON.parse(JSON.stringify(this.state.data));
      data.drugsAndVaccinesData[index]["warehouse"] = input;
      data.drugsAndVaccinesData[index]["availableDrugsOrVaccines"] =
        await this.getAvailableDrugsAndVaccinesPerWarehouse(input.value);
      data.drugsAndVaccinesData[index]["ieDrugOrVaccine"] = "";

      if (this.isDuplicatedVaccineEntry(data, index)) {
        toast.warning(
          "There is already an entry for this combination of drug/warehouse/vial#. Please select a different value or update the existing entry instead."
        );
      } else {
        this.setState({ data });
      }
    }
  };

  handleDrugsAndVaccinesChanges = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    const data = JSON.parse(JSON.stringify(this.state.data));
    data.drugsAndVaccinesData[index][fieldName] = input.value;

    if (fieldName === "totalAmount") {
      const amount = Number.parseFloat(input.value);
      if (amount < 0 || amount >= 5) {
        data.drugsAndVaccinesData[index].totalAmountError = "Drug amount values should be in the range of 0-5.";
      } else {
        delete data.drugsAndVaccinesData[index].totalAmountError;
      }
    }

    if (fieldName === "vialNumber" && this.isDuplicatedVaccineEntry(data, index)) {
      toast.warning(
        "There is already an entry for this combination of drug/warehouse/vial#. Please enter a different value or update the existing entry instead."
      );
    } else {
      this.setState({ data });
    }
  };

  handleDeleteDrug = (index) => {
    const { data } = this.state;
    data.drugsAndVaccinesData.splice(index, 1);
    this.setState({ data });
  };

  handleExtraDrug = async () => {
    const { data, availableWarehouses } = this.state;
    const defaultWarehouse = availableWarehouses?.length === 1 ? availableWarehouses[0] : "";
    data.drugsAndVaccinesData.push({
      ieDrugOrVaccine: "",
      vialNumber: "",
      totalAmount: "",
      warehouse: defaultWarehouse,
      availableDrugsOrVaccines: defaultWarehouse
        ? await this.getAvailableDrugsAndVaccinesPerWarehouse(defaultWarehouse.value)
        : [],
    });
    this.setState({ data });
  };

  handleCheckboxChanges = ({ currentTarget: input }) => {
    const { data, errors } = this.state;
    data[input.name] = !data[input.name];
    if (input.name === "noneCheckbox") {
      data.rabiesBloodLab = 4;
    } else if (input.name === "brainstemCheckbox") {
      data.brainstemCheckbox
        ? (data.rabiesBrainstemTest = YesNoNotRecordedOptions.NoRecorded)
        : (data.selectedErsCategory = "");
    } else if (input.name === "canineDistemperCheckbox" && !data.canineDistemperCheckbox) {
      errors.distemperVnaInterpretation = "";
    } else if (
      (input.name === "other3Checkbox" || input.name === "other4Checkbox") &&
      !data.other3Checkbox &&
      !data.other4Checkbox
    ) {
      errors.adenoVnaInterpretation = "";
    }

    this.setState({ data, errors });
  };

  handleSpecificChanges = (input) => {
    const { data, errors } = this.state;
    if (input.name === "orvNative") {
      if (input.value === 1) {
        data.dateOfLastOrv = "";
        data.baitType = BaitTypes.None_Naive;
      } else if (Number.parseInt(data.baitType) === BaitTypes.None_Naive) {
        data.baitType = "";
      }
      data.orvNative = input.value;
      errors.orvNative = "";
    } else if (input.name === "nrmpTargetSpecies") {
      if (input.value === NrmpTargetSpeciesOptions.NoCapture) {
        data.misTargetSpecies = "";
        data.selectedSpecies = {
          label: "No Damage Agents",
          value: null,
        };
      } else {
        data.fateOfTrap = this.state.fateOfTrapOptions?.find((o) => o.name === "Animal Captured")?.id;
      }

      data.nrmpTargetSpecies = input.value;
      errors.nrmpTargetSpecies = "";
    } else if (input.name === "collector") {
      this.setState({ filteredCollectionMethods: this.filterCollectionMethodBasedOnCollector(input.value) });
      data.collector = input.value;
      errors.collector = "";
    } else if (input.name === "selectedCollectionMethod") {
      const filteredFateTypes = this.filterFateTypesBasedOnCollectionMethod(input.value);
      if (filteredFateTypes.length === 1) {
        data.selectedFateForWs = filteredFateTypes[0]?.id;
      }
      data.selectedCollectionMethod = input.value;
      errors.selectedCollectionMethod = "";
      this.setState({ filteredFateTypes });
    } else if (input.name === "selectedCollectionActivity") {
      if (input.value === CollectionActivitiesOptions.Trapping_ORVNaive) {
        data.orvNative = YesNoOptions.Yes;
        data.dateOfLastOrv = "";
        data.baitType = BaitTypes.None_Naive;
      }
      data.selectedCollectionActivity = input.value;
      errors.selectedCollectionActivity = "";
    } else if (input.name === "distemperVnaDilution") {
      if (!input.value) {
        errors.distemperVnaInterpretation = "";
      }
      data.distemperVnaDilution = input.value;
      errors.distemperVnaDilution = "";
    } else if (input.name === "adenoVnaDilution") {
      if (!input.value) {
        errors.adenoVnaInterpretation = "";
      }
      data.adenoVnaDilution = input.value;
      errors.adenoVnaDilution = "";
    } else if (input.name === "latitude" || input.name === "longitude") {
      if (input.value.includes(".")) {
        const decimals = input.value.split(".")[1];
        if (decimals.length < 5) {
          input.name === "latitude"
            ? (errors.latitude = "Please specify at least 5 decimal places for latitude")
            : (errors.longitude = "Please specify at least 5 decimal places for longitude");
        } else {
          input.name === "latitude" ? (errors.latitude = "") : (errors.longitude = "");
        }
        data[input.name] = input.value;
      } else {
        input.name === "latitude"
          ? (errors.latitude = "Please specify at least 5 decimal places for latitude")
          : (errors.longitude = "Please specify at least 5 decimal places for longitude");
        data[input.name] = input.value;
      }

      if (input.name === "longitude" && Number.parseFloat(data[input.name]) > 0) {
        data[input.name] = -1 * data[input.name];
      }
    } else if (input.name === "weight" && (Number.parseInt(input.value) < 1 || Number.parseInt(input.value) > 99)) {
      data.weight = input.value;
      errors.weight = "Weight should be a number in the range of 1-99 Kg.";
    } else {
      data[input.name] = input.value;
      errors[input.name] = "";
    }

    this.setState({ data, errors });
  };

  isDuplicatedVaccineEntry(data, index) {
    return this.state.data.drugsAndVaccinesData.some(
      (d) =>
        d.warehouse?.value === data.drugsAndVaccinesData[index].warehouse.value &&
        d.ieDrugOrVaccine?.value === data.drugsAndVaccinesData[index].ieDrugOrVaccine.value &&
        d.vialNumber === data.drugsAndVaccinesData[index].vialNumber
    );
  }

  async getAvailableDrugsAndVaccinesPerWarehouse(warehouseId) {
    let availableDrugsOrVaccines = [];
    if (warehouseId) {
      const warehouseDataResponse = await InventoryAPI.GetWarehouse(warehouseId);
      if (warehouseDataResponse?.successful && warehouseDataResponse.data?.warehouseInventory?.length > 0) {
        availableDrugsOrVaccines = warehouseDataResponse.data.warehouseInventory
          .filter(
            (d) =>
              d.methodCategory === "IE DRUGS" || d.methodCategory === "I/E DRUGS" || d.methodCategory === "BIOLOGICS"
          )
          ?.map((d) => ({ label: d.methodName, value: d.warehouseInventoryUniqueIdentifer, ...d }));
      }
    }
    return availableDrugsOrVaccines;
  }

  filterFateTypesBasedOnCollectionMethod(selectedCollectionMethod) {
    let { fateTypes } = this.state;
    let newfilteredFateTypes = [];
    if (selectedCollectionMethod) {
      switch (selectedCollectionMethod) {
        case 1:
        case 3:
        case 4:
          newfilteredFateTypes = fateTypes.filter((fate) => [1, 2, 3, 5, 6].includes(fate.id));
          break;
        case 2:
          newfilteredFateTypes = fateTypes.filter((fate) => fate.id === 2);
          break;
        case 5:
        case 6:
          newfilteredFateTypes = fateTypes.filter((fate) => fate.id === 7);
          break;
        case 7:
          newfilteredFateTypes = fateTypes.filter((fate) => fate.id === 3);
          break;
        case 8:
          newfilteredFateTypes = fateTypes.filter((fate) => fate.id === 8);
          break;
        default:
          newfilteredFateTypes = fateTypes;
          break;
      }
    } else {
      newfilteredFateTypes = fateTypes;
    }
    return newfilteredFateTypes;
  }

  filterCollectionMethodBasedOnCollector(selectedCollector) {
    let { collectionMethods } = this.state;
    return selectedCollector === CollectorOptions.NWCO_ACO ||
      selectedCollector === CollectorOptions.Cooperator ||
      selectedCollector === CollectorOptions.Other
      ? collectionMethods.filter(
          (m) =>
            m.id === CollectionMethodOptions.NonWS_Carcass_Collection ||
            m.id === CollectionMethodOptions.NonWS_Euthanized
        )
      : collectionMethods;
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  renderPageHeader() {
    return (
      <Row className="mt-4">
        <Col className="pl-0">
          <h1 className={globalStyles.pageTitle}>Rabies Record Page</h1>
        </Col>
        <Col className="text-right pr-0">
          {this.props.permissions.canIEditRabiesAssignedStates() && (
            <Button variant="primary" onClick={this.handleEditRabies}>
              <span className={globalStyles.modalSubmitButtonText}>
                {this.state.isEditMode ? "Apply Changes" : "Edit"}
              </span>
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  renderRabiesGeneralSection() {
    const { data, isEditMode, errors } = this.state;
    return (
      <Row className="mt-3 text-left">
        <Col className="px-0">
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  {this.renderEditableField(isEditMode, "sampleDate", "Date", data.sampleDate, "date", [], "required")}
                </Col>
                <Col>
                  {this.renderEditableField(isEditMode, "stateId", "State", data.stateId, "select", this.state.states, [
                    "required",
                    "disabled",
                  ])}
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "countyId",
                    "County",
                    data.countyId,
                    "select",
                    this.state.stateCounties,
                    ["required", "disabled"]
                  )}
                </Col>
                <Col>{this.renderEditableField(isEditMode, "town", "Town", data.town)}</Col>
              </Row>
              <Row>
                <Col md={3}>
                  {this.renderEditableField(
                    isEditMode,
                    "collector",
                    "Collector",
                    data.collector,
                    "select",
                    this.state.collectorOptions,
                    "required"
                  )}
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>WS Name</Form.Label>
                    {isEditMode ? (
                      <AsyncSelect
                        aria-label="WS Name Search"
                        value={data.wsName}
                        openMenuOnClick={false}
                        placeholder="Search for WS"
                        styles={this.customSelectStyles}
                        loadOptions={this.handleWsSearch}
                        onChange={(e) => this.handleSelectChanges(e, "wsName")}
                        components={{ DropdownIndicator, Option }}
                      />
                    ) : (
                      <Form.Text className={globalStyles.formData}>{data.wsName?.label}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                {Number.parseInt(data.collector) === CollectorOptions.Other && (
                  <Col>
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>
                        Other Collector{isEditMode ? <span className={globalStyles.asterisk508}> *</span> : ""}
                      </Form.Label>
                      {isEditMode ? (
                        <React.Fragment>
                          <AsyncSelect
                            aria-label="Other Collector Search"
                            value={data.otherCollector}
                            openMenuOnClick={false}
                            placeholder="Search for Collector"
                            styles={this.customSelectStyles}
                            loadOptions={this.handleWsSearch}
                            onChange={(e) => this.handleSelectChanges(e, "otherCollector")}
                            components={{ DropdownIndicator, Option }}
                            className={errors.otherCollector ? "is-invalid" : ""}
                          />
                          <span className="invalid-feedback">{errors.otherCollector}</span>
                        </React.Fragment>
                      ) : (
                        <Form.Text className={globalStyles.formData}>{data.otherCollector?.label}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "selectedCollectionActivity",
                    "Collection Activity",
                    data.selectedCollectionActivity,
                    "select",
                    this.state.collectionActivities,
                    "required"
                  )}
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "selectedCollectionMethod",
                    "Collection Method",
                    data.selectedCollectionMethod,
                    "select",
                    this.state.filteredCollectionMethods,
                    "required"
                  )}
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "densityStudy",
                    "Density Study",
                    data.densityStudy,
                    "select",
                    this.state.yesNoOptions,
                    "required"
                  )}
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "densityStudyId",
                    "Density Study ID",
                    data.densityStudyId,
                    "text",
                    [],
                    Number.parseInt(data.densityStudy) === YesNoOptions.Yes ? "required" : "readOnly"
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "dateOfLastOrv",
                    "Date of Last ORV",
                    data.dateOfLastOrv,
                    "date",
                    [],
                    Number.parseInt(data.orvNative) === YesNoOptions.Yes ? "readOnly" : "required"
                  )}
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "orvNative",
                    "ORV Naive",
                    data.orvNative,
                    "select",
                    this.state.yesNoOptions
                  )}
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "baitType",
                    "ORV Bait Type",
                    data.baitType,
                    "select",
                    this.state.baitTypes,
                    Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes ? "required" : ""
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.baitType) === BaitTypes.Other &&
                    this.renderEditableField(isEditMode, "otherBait", "Other Bait", data.otherBait)}
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "waypointNumber",
                    "Waypoint #",
                    data.waypointNumber,
                    "text",
                    []
                  )}
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "latLongRecorded",
                    "Lat/Long Recorded?",
                    data.latLongRecorded,
                    "select",
                    this.state.yesNoOptions,
                    "required"
                  )}
                </Col>
                <Col>
                  {isEditMode ? (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>
                        Latitude
                        {Number.parseInt(data.latLongRecorded) === YesNoOptions.Yes ? (
                          <span className={globalStyles.asterisk508}> *</span>
                        ) : (
                          ""
                        )}
                      </Form.Label>
                      <Form.Control
                        name="latitude"
                        placeholder={
                          Number.parseInt(data.latLongRecorded) === YesNoOptions.Yes ? "Enter latitude" : "N/A"
                        }
                        type="number"
                        step="0.00001"
                        className={globalStyles.formData}
                        value={data.latitude}
                        onChange={this.handleChange}
                        readOnly={Number.parseInt(data.latLongRecorded) !== YesNoOptions.Yes}
                        isInvalid={errors.latitude}
                      />
                      <Form.Control.Feedback type="invalid">{errors.latitude}</Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    this.renderLabel("latitude", "Latitude", data.latitude)
                  )}
                </Col>
                <Col>
                  {isEditMode ? (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>
                        Longitude
                        {Number.parseInt(data.latLongRecorded) === YesNoOptions.Yes ? (
                          <span className={globalStyles.asterisk508}> *</span>
                        ) : (
                          ""
                        )}
                      </Form.Label>
                      <Form.Control
                        name="longitude"
                        placeholder={
                          Number.parseInt(data.latLongRecorded) === YesNoOptions.Yes ? "Enter longitude" : "N/A"
                        }
                        type="number"
                        step="0.00001"
                        className={globalStyles.formData}
                        value={data.longitude}
                        onChange={this.handleChange}
                        readOnly={Number.parseInt(data.latLongRecorded) !== YesNoOptions.Yes}
                        isInvalid={errors.longitude}
                      />
                      <Form.Control.Feedback type="invalid">{errors.longitude}</Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    this.renderLabel("longitude", "Longitude", data.longitude)
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  {this.renderEditableField(
                    isEditMode,
                    "nrmpTargetSpecies",
                    "NRMP Target",
                    data.nrmpTargetSpecies,
                    "select",
                    this.state.nrmpTargetSpeciesOptions,
                    "required"
                  )}
                </Col>
                <Col md={3}>
                  {Number.parseInt(data.nrmpTargetSpecies) !== NrmpTargetSpeciesOptions.NoCapture ? (
                    this.renderEditableField(
                      isEditMode,
                      "misTargetSpecies",
                      "MIS Target",
                      data.misTargetSpecies,
                      "select",
                      this.state.misTargetOptions,
                      Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.No ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>MIS Target</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="misTargetSpecies"
                          placeholder="Select"
                          type="text"
                          className={globalStyles.formData}
                          defaultValue="N/A"
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      Damage Agent
                      {isEditMode &&
                        (Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes ||
                          Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.No) && (
                          <span className={globalStyles.asterisk508}> *</span>
                        )}
                    </Form.Label>
                    {isEditMode ? (
                      <AsyncSelect
                        aria-label="Damage Agent Selection"
                        value={data.selectedSpecies}
                        placeholder="Select"
                        styles={{
                          ...this.customSelectStyles,
                          control: (styles) => ({
                            ...styles,
                            borderColor: errors.selectedSpecies ? "#dc3545" : "#ced4da",
                          }),
                        }}
                        className={errors.selectedSpecies ? "is-invalid" : ""}
                        onChange={(e) => this.handleSelectChanges(e, "selectedSpecies")}
                        loadOptions={this.handleSpeciesSearch}
                        components={{ DropdownIndicator }}
                      />
                    ) : (
                      <Form.Text className={globalStyles.formData}>{data.selectedSpecies?.label}</Form.Text>
                    )}
                    <span className="invalid-feedback">{errors.selectedSpecies}</span>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  {this.renderEditableField(
                    isEditMode,
                    "fateOfTrap",
                    "Fate Of Trap",
                    data.fateOfTrap,
                    "select",
                    this.state.fateOfTrapOptions,
                    Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.NoCapture ? "required" : ""
                  )}
                </Col>
                <Col md={3}>
                  {this.renderEditableField(
                    isEditMode,
                    "recaptureSelected",
                    "Recapture?",
                    data.recaptureSelected,
                    "select",
                    this.state.yesNoOptions,
                    Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes ? "required" : ""
                  )}
                </Col>
                <Col md={3}>
                  {Number.parseInt(data.recaptureSelected) === YesNoOptions.Yes ? (
                    this.renderEditableField(
                      isEditMode,
                      "processedLessThan30DaysAgo",
                      "Processed <30 Days Ago?",
                      data.processedLessThan30DaysAgo,
                      "select",
                      this.state.yesNoOptions,
                      Number.parseInt(data.nrmpTargetSpecies) === NrmpTargetSpeciesOptions.Yes ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>{"Processed <30 Days Ago?"}</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="processedLessThan30DaysAgo"
                          placeholder="Select"
                          type="text"
                          className={globalStyles.formData}
                          defaultValue={
                            Number.parseInt(data.recaptureSelected) === YesNoOptions.No
                              ? this.state.yesNoOptions.find((o) => o.id === data.processedLessThan30DaysAgo)?.name
                              : ""
                          }
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>
                          {Number.parseInt(data.recaptureSelected) === YesNoOptions.No
                            ? this.state.yesNoOptions.find((o) => o.id === data.processedLessThan30DaysAgo)?.name
                            : ""}
                        </Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderDrugsAndVaccinesSection() {
    const { data, isEditMode, errors } = this.state;
    return (
      <Row className="mt-4 text-left">
        <Col className="px-0">
          <Card>
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Drugs and Vaccines</span>
            </Card.Header>
            <Card.Body>
              {data.drugsAndVaccinesData?.map((item, index) => {
                return (
                  <Row key={index}>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>Warehouse</Form.Label>
                        {isEditMode ? (
                          <Select
                            aria-label="Warehouse Selection for Drug and/or Vaccine"
                            value={item.warehouse}
                            placeholder="Select"
                            styles={this.customSelectStyles}
                            onChange={(e) => this.handleDrugsAndVaccinesWarehouseSelection(e, index)}
                            options={this.state.availableWarehouses}
                          />
                        ) : (
                          <Form.Text className={globalStyles.formData}>{item.warehouse?.label}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>I/E Drug or Vaccine</Form.Label>
                        {isEditMode ? (
                          <Select
                            aria-label="I/E Drug or Vaccine Selection"
                            value={item.ieDrugOrVaccine}
                            placeholder="Select"
                            styles={this.customSelectStyles}
                            onChange={(e) => this.handleDrugsAndVaccinesSelectChanges(e, index)}
                            options={item.availableDrugsOrVaccines}
                          />
                        ) : (
                          <Form.Text className={globalStyles.formData}>{item.ieDrugOrVaccine?.label}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>UOM</Form.Label>
                        {isEditMode ? (
                          <Form.Control
                            type="text"
                            placeholder="UOM"
                            value={
                              item.ieDrugOrVaccine?.methodUOM
                                ? UtilityFunctions.getDisplayTextFromFieldObject(item.ieDrugOrVaccine.methodUOM)
                                : ""
                            }
                            onChange={this.handleDrugsAndVaccinesChanges}
                            readOnly
                          />
                        ) : (
                          <Form.Text className={globalStyles.formData}>
                            {item.ieDrugOrVaccine?.methodUOM
                              ? UtilityFunctions.getDisplayTextFromFieldObject(item.ieDrugOrVaccine.methodUOM)
                              : ""}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`vialNumber-${index}`}>
                        <Form.Label className={globalStyles.formLabel}>Vial#</Form.Label>
                        {isEditMode ? (
                          <Form.Control
                            type="text"
                            placeholder="Enter Vial No."
                            value={item.vialNumber}
                            onChange={this.handleDrugsAndVaccinesChanges}
                          />
                        ) : (
                          <Form.Text className={globalStyles.formData}>{item.vialNumber}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`totalAmount-${index}`}>
                        <Form.Label className={globalStyles.formLabel}>Total Amount</Form.Label>
                        {isEditMode ? (
                          <React.Fragment>
                            <Form.Control
                              type="number"
                              placeholder="Enter Total Amount"
                              value={item.totalAmount}
                              onChange={this.handleDrugsAndVaccinesChanges}
                              isInvalid={item.totalAmountError}
                            />
                            <Form.Control.Feedback type="invalid">{item.totalAmountError}</Form.Control.Feedback>
                          </React.Fragment>
                        ) : (
                          <Form.Text className={globalStyles.formData}>{item.totalAmount}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    {isEditMode && (
                      <Col sm={1} className="pt-4">
                        <i
                          className="fa fa-times-circle-o pt-3"
                          aria-hidden="true"
                          onClick={() => this.handleDeleteDrug(index)}
                        ></i>
                      </Col>
                    )}
                    {isEditMode && (
                      <Col className="pt-4">
                        {data.drugsAndVaccinesData.length === index + 1 && (
                          <Button variant="link" className={globalStyles.formDataLinks} onClick={this.handleExtraDrug}>
                            + Add another drug
                          </Button>
                        )}
                      </Col>
                    )}
                  </Row>
                );
              })}
              {data.drugsAndVaccinesData?.length === 0 && isEditMode && (
                <Row>
                  <Col>
                    <Button variant="link" className="pl-0" onClick={this.handleExtraDrug}>
                      <span className={globalStyles.formDataLinks}>+ Add a drug/vaccine</span>
                    </Button>
                  </Col>
                </Row>
              )}
              {(data.drugsAndVaccinesData?.length > 0 || isEditMode) && this.renderDashedSeparatorRow()}
              <Row>
                <Col md={3}>
                  {this.renderEditableField(
                    isEditMode,
                    "animalId",
                    "ID #",
                    data.animalId,
                    "text",
                    [],
                    this.areAnimalDetailsFieldsRequired() ? "required" : ""
                  )}
                </Col>
                <Col md={3}>
                  {this.renderEditableField(
                    isEditMode,
                    "sex",
                    "Sex",
                    data.sex,
                    "select",
                    this.state.sexOptions,
                    this.areAnimalDetailsFieldsRequired() ? "required" : ""
                  )}
                </Col>
                <Col md={3}>
                  {Number.parseInt(data.sex) === SexOptions.Female ? (
                    this.renderEditableField(
                      isEditMode,
                      "lactation",
                      "Lactation?",
                      data.lactation,
                      "select",
                      this.state.yesNoNotRecordedOptions,
                      Number.parseInt(data.sex) === SexOptions.Female ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Lactation?</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="lactation"
                          placeholder="Select"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>
                          {this.state.yesNoNotRecordedOptions.find((o) => o.id === data.lactation)?.name}
                        </Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "relativeAge",
                    "Relative Age",
                    data.relativeAge,
                    "select",
                    this.state.ageOptions,
                    this.areAnimalDetailsFieldsRequired() ? "required" : ""
                  )}
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "weightRecorded",
                    "Weight Recorded",
                    data.weightRecorded,
                    "select",
                    this.state.yesNoOptions,
                    this.isWeightRecordedRequired() ? "required" : ""
                  )}
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Weight (Kg)</Form.Label>
                    {isEditMode ? (
                      <React.Fragment>
                        <Form.Control
                          className={globalStyles.formData}
                          name="weight"
                          placeholder="Enter Weight"
                          type="number"
                          min={1}
                          max={99}
                          step={1}
                          value={
                            Number.parseInt(data.weightRecorded) === YesNoOptions.Yes ? parseFloat(data.weight) : ""
                          }
                          onChange={this.handleChange}
                          readOnly={Number.parseInt(data.weightRecorded) !== YesNoOptions.Yes}
                          isInvalid={errors.weight}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weight}</Form.Control.Feedback>
                      </React.Fragment>
                    ) : (
                      <Form.Text className={globalStyles.formData}>
                        {Number.parseInt(data.weightRecorded) === YesNoOptions.Yes ? parseFloat(data.weight) : "N/A"}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "handVaccinated",
                    "Hand Vaccinated",
                    data.handVaccinated,
                    "select",
                    this.state.yesNoNotRecordedOptions,
                    this.isHandVaccinatedRequired() ? "required" : ""
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderSamplesSection() {
    const { data, errors, isEditMode } = this.state;

    return (
      <Row className="mt-4 text-left">
        <Col className="px-0">
          <Card>
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Samples</span>
            </Card.Header>
            <Card.Body>
              {isEditMode && (
                <Row>
                  <Col>
                    <span className={styles.samplesModalRedComments}>(Check all that apply)</span>
                  </Col>
                </Row>
              )}
              <div className="px-3">
                <Row style={isEditMode ? { backgroundColor: "#E1E1E1" } : {}}>
                  <Col className={isEditMode ? "pt-3" : "pt-2"} md={8}>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          label="Blood"
                          name="bloodCheckbox"
                          type="checkbox"
                          id="bloodCheckbox"
                          value={data.bloodCheckbox}
                          onChange={this.handleCheckboxChanges}
                          checked={data.bloodCheckbox}
                          disabled={!isEditMode}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          label="PM1"
                          name="pm1Checkbox"
                          type="checkbox"
                          id="pm1Checkbox"
                          value={data.pm1Checkbox}
                          onChange={this.handleCheckboxChanges}
                          checked={data.pm1Checkbox}
                          disabled={!isEditMode}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          label="PM2"
                          name="pm2Checkbox"
                          type="checkbox"
                          id="pm2Checkbox"
                          value={data.pm2Checkbox}
                          onChange={this.handleCheckboxChanges}
                          checked={data.pm2Checkbox}
                          disabled={!isEditMode}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          label="K-9"
                          name="k9Checkbox"
                          type="checkbox"
                          id="k9Checkbox"
                          value={data.k9Checkbox}
                          onChange={this.handleCheckboxChanges}
                          checked={data.k9Checkbox}
                          disabled={!isEditMode}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          label="Jaw"
                          name="jawCheckbox"
                          type="checkbox"
                          id="jawCheckbox"
                          value={data.jawCheckbox}
                          onChange={this.handleCheckboxChanges}
                          checked={data.jawCheckbox}
                          disabled={!isEditMode}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          label="DNA"
                          name="dnaCheckbox"
                          type="checkbox"
                          id="dnaCheckbox"
                          value={data.dnaCheckbox}
                          onChange={this.handleCheckboxChanges}
                          checked={data.dnaCheckbox}
                          disabled={!isEditMode}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          label="Brainstem"
                          name="brainstemCheckbox"
                          type="checkbox"
                          id="brainstemCheckbox"
                          value={data.brainstemCheckbox}
                          onChange={this.handleCheckboxChanges}
                          checked={data.brainstemCheckbox}
                          disabled={!isEditMode}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          label="Other"
                          name="otherCheckbox"
                          type="checkbox"
                          id="otherCheckbox"
                          value={data.otherCheckbox}
                          onChange={this.handleCheckboxChanges}
                          checked={data.otherCheckbox}
                          disabled={!isEditMode}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="pl-0">
                    {data.otherCheckbox ? (
                      this.renderEditableField(
                        isEditMode,
                        "selectedOtherSample",
                        "Other Sample",
                        data.selectedOtherSample,
                        "select",
                        this.state.otherSampleTypes
                      )
                    ) : (
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>Other Sample</Form.Label>
                        {isEditMode ? (
                          <Form.Control
                            name="selectedOtherSample"
                            placeholder="N/A"
                            type="text"
                            className={globalStyles.formData}
                            readOnly
                          />
                        ) : (
                          <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                        )}
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </div>
              <Row className="mt-3">
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "selectedFateForWs",
                    "Fate (WS Only)",
                    data.selectedFateForWs,
                    "select",
                    this.state.filteredFateTypes,
                    "required"
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.selectedFateForWs) === FateTypes.Other ? (
                    this.renderEditableField(
                      isEditMode,
                      "otherFate",
                      "Other Fate",
                      data.otherFate,
                      "text",
                      [],
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Other Fate</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="otherFate"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.selectedFateForWs) === FateTypes.Euthanized ? (
                    this.renderEditableField(
                      isEditMode,
                      "euthanizedReason",
                      "If Euthanized by WS, why?",
                      data.euthanizedReason,
                      "select",
                      this.state.euthanizedByWsReasons,
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>If Euthanized by WS, why?</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="euthanizedReason"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {data.brainstemCheckbox ? (
                    this.renderEditableField(
                      isEditMode,
                      "selectedErsCategory",
                      "ERS Category",
                      data.selectedErsCategory,
                      "select",
                      this.state.ersCategoryOptions,
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>ERS Category</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="selectedErsCategory"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Comments</Form.Label>
                    {isEditMode ? (
                      <React.Fragment>
                        <Form.Control
                          name="comments"
                          placeholder="Enter Comments"
                          type="text"
                          as="textarea"
                          rows={3}
                          className={globalStyles.formData}
                          value={data.comments}
                          onChange={this.handleChange}
                          isInvalid={errors.comments}
                        />
                        <Form.Control.Feedback type="invalid">{errors.comments}</Form.Control.Feedback>
                      </React.Fragment>
                    ) : (
                      <Form.Text className={globalStyles.formData}>{data.comments}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Free Text</Form.Label>
                    {isEditMode ? (
                      <React.Fragment>
                        <Form.Control
                          name="freeText"
                          placeholder="Enter Free Text"
                          type="text"
                          as="textarea"
                          rows={3}
                          className={globalStyles.formData}
                          value={data.freeText}
                          onChange={this.handleChange}
                          isInvalid={errors.freeText}
                        />
                        <Form.Control.Feedback type="invalid">{errors.freeText}</Form.Control.Feedback>
                      </React.Fragment>
                    ) : (
                      <Form.Text className={globalStyles.formData}>{data.freeText}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderLaboratoryProcessingSection() {
    const { data, errors, isEditMode } = this.state;
    return (
      <Row className="mt-4 text-left">
        <Col className="px-0">
          <Card>
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Laboratory Processing</span>
            </Card.Header>
            <Card.Body>
              {isEditMode && (
                <Row>
                  <Col>
                    <span className={styles.samplesModalRedComments}>Serum Samples (Check all that apply)</span>
                  </Col>
                </Row>
              )}
              <div className="px-3">
                <Row style={isEditMode ? { backgroundColor: "#E1E1E1" } : {}}>
                  <Col>
                    <Form.Check
                      inline
                      label="Rabies"
                      name="rabiesCheckbox"
                      type="checkbox"
                      id="rabiesCheckbox"
                      value={data.rabiesCheckbox}
                      onChange={this.handleCheckboxChanges}
                      checked={data.rabiesCheckbox}
                      disabled={!data.bloodCheckbox || !isEditMode}
                    />
                  </Col>
                  <Col className="pl-0">
                    <Form.Check
                      inline
                      label="Canine Distemper"
                      name="canineDistemperCheckbox"
                      type="checkbox"
                      id="canineDistemperCheckbox"
                      value={data.canineDistemperCheckbox}
                      onChange={this.handleCheckboxChanges}
                      checked={data.canineDistemperCheckbox}
                      disabled={!data.bloodCheckbox || !isEditMode}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      inline
                      label="Other (3rd)"
                      name="other3Checkbox"
                      type="checkbox"
                      id="other3Checkbox"
                      value={data.other3Checkbox}
                      onChange={this.handleCheckboxChanges}
                      checked={data.other3Checkbox}
                      disabled={!data.bloodCheckbox || !isEditMode}
                    />
                  </Col>
                  <Col className="pl-0">
                    <Form.Check
                      inline
                      label="Other (4th)"
                      name="other4Checkbox"
                      type="checkbox"
                      id="other4Checkbox"
                      value={data.other4Checkbox}
                      onChange={this.handleCheckboxChanges}
                      checked={data.other4Checkbox}
                      disabled={!data.bloodCheckbox || !isEditMode}
                    />
                  </Col>
                  <Col className="px-0">
                    <Form.Check
                      inline
                      label="None"
                      name="noneCheckbox"
                      type="checkbox"
                      id="noneCheckbox"
                      value={data.noneCheckbox}
                      onChange={this.handleCheckboxChanges}
                      checked={data.noneCheckbox}
                      disabled={!data.bloodCheckbox || !isEditMode}
                    />
                  </Col>
                </Row>
              </div>
              <Row className="mt-3">
                <Col>
                  {this.renderEditableField(
                    isEditMode,
                    "rabiesBrainstemTest",
                    "Rabies Brainstem Test",
                    data.rabiesBrainstemTest,
                    "select",
                    this.state.yesNoNotRecordedOptions,
                    data.brainstemCheckbox ? "required" : ""
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes ? (
                    this.renderEditableField(
                      isEditMode,
                      "rabiesBrainLab",
                      "Rabies Brain Lab",
                      data.rabiesBrainLab,
                      "select",
                      this.state.rabiesBrainLabOptions,
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Rabies Brain Lab</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="rabiesBrainLab"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes ? (
                    this.renderEditableField(
                      isEditMode,
                      "rabiesBrainTestDate",
                      "Date of Test",
                      data.rabiesBrainTestDate,
                      "date",
                      [],
                      Number.parseInt(data.rabiesBrainLab) === RabiesBrainLabOptions.WS_dRIT ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Date of Test</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="rabiesBrainTestDate"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes ? (
                    this.renderEditableField(
                      isEditMode,
                      "rabiesLabSpecimenNumber",
                      "Rabies Lab Specimen #",
                      data.rabiesLabSpecimenNumber,
                      "text",
                      [],
                      Number.parseInt(data.rabiesBrainLab) === RabiesBrainLabOptions.WS_dRIT ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Rabies Lab Specimen #</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="rabiesLabSpecimenNumber"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes &&
                  Number.parseInt(data.rabiesBrainLab) === RabiesBrainLabOptions.WS_dRIT ? (
                    this.renderEditableField(
                      isEditMode,
                      "rabiesBrainstemCondition",
                      "Brainstem Condition",
                      data.rabiesBrainstemCondition,
                      "select",
                      this.state.brainstemConditions,
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Brainstem Condition</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="rabiesBrainstemCondition"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes &&
                  Number.parseInt(data.rabiesBrainLab) === RabiesBrainLabOptions.WS_dRIT ? (
                    this.renderEditableField(
                      isEditMode,
                      "stainingIntensity",
                      "Staining Intensity (dRIT)",
                      data.stainingIntensity,
                      "select",
                      this.state.stainingIntensityOptions,
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Staining Intensity (dRIT)</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="stainingIntensity"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes &&
                  Number.parseInt(data.rabiesBrainLab) === RabiesBrainLabOptions.WS_dRIT ? (
                    this.renderEditableField(
                      isEditMode,
                      "antigenDistribution",
                      "Antigen Distribution (dRIT)",
                      data.antigenDistribution,
                      "select",
                      this.state.antigenDistributionOptions,
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Antigen Distribution (dRIT)</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="antigenDistribution"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes ? (
                    this.renderEditableField(
                      isEditMode,
                      "rabiesBrainstemResults",
                      "Rabies Brainstem Results",
                      data.rabiesBrainstemResults,
                      "select",
                      this.state.rabiesBrainstemResultsOptions,
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Rabies Brainstem Results</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="rabiesBrainstemResults"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  {Number.parseInt(data.rabiesBrainstemResults) !== RabiesBrainstemResultsOptions.Negative &&
                  Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes ? (
                    this.renderEditableField(
                      isEditMode,
                      "rabiesVariant",
                      "Rabies Variant",
                      data.rabiesVariant,
                      "select",
                      this.state.rabiesVariants,
                      Number.parseInt(data.rabiesBrainstemResults) === RabiesBrainstemResultsOptions.Positive
                        ? "required"
                        : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Rabies Variant</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="rabiesVariant"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      Rabies Test Comments
                      {isEditMode &&
                        !data.noneCheckbox &&
                        Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes &&
                        Number.parseInt(data.rabiesBrainstemResults) ===
                          RabiesBrainstemResultsOptions.Indeterminate && (
                          <span className={globalStyles.asterisk508}> *</span>
                        )}
                    </Form.Label>
                    {isEditMode ? (
                      <Form.Control
                        name="rabiesTestComments"
                        placeholder="Enter Test Comments"
                        type="text"
                        as="textarea"
                        rows={3}
                        className={globalStyles.formData}
                        value={
                          !data.noneCheckbox &&
                          Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes
                            ? data.rabiesTestComments
                            : "N/A"
                        }
                        onChange={this.handleChange}
                        isInvalid={errors.rabiesTestComments}
                        readOnly={
                          data.noneCheckbox || Number.parseInt(data.rabiesBrainstemTest) !== YesNoNotRecordedOptions.Yes
                        }
                      />
                    ) : (
                      <Form.Text className={globalStyles.formData}>
                        {Number.parseInt(data.rabiesBrainstemTest) === YesNoNotRecordedOptions.Yes
                          ? data.rabiesTestComments
                          : "N/A"}
                      </Form.Text>
                    )}
                    <Form.Control.Feedback type="invalid">{errors.rabiesTestComments}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderLaboratoryResultsSection() {
    const { data, errors, isEditMode } = this.state;
    return (
      <Row className="mt-4 text-left">
        <Col className="px-0">
          <Card>
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Laboratory Results</span>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  {this.isToothLabRequired() ? (
                    this.renderEditableField(
                      isEditMode,
                      "toothLab",
                      "Tooth Lab",
                      data.toothLab,
                      "select",
                      this.state.toothLabOptions,
                      "required"
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Tooth Lab</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="toothLab"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col>
                  {!this.isToothLabRequired() || Number.parseInt(data.toothLab) === ToothLabOptions.None ? (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Age Recorded?</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="ageRecorded"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  ) : (
                    this.renderEditableField(
                      isEditMode,
                      "ageRecorded",
                      "Age Recorded?",
                      data.ageRecorded,
                      "select",
                      this.state.yesNoOptions
                    )
                  )}
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      Years
                      {isEditMode &&
                        this.isToothLabRequired() &&
                        Number.parseInt(data.ageRecorded) === YesNoOptions.Yes && (
                          <span className={globalStyles.asterisk508}> *</span>
                        )}
                    </Form.Label>
                    {isEditMode ? (
                      <React.Fragment>
                        <Form.Control
                          name="age"
                          placeholder="N/A"
                          type="number"
                          min="1"
                          max="29"
                          step="1"
                          className={globalStyles.formData}
                          value={
                            this.isToothLabRequired() &&
                            Number.parseInt(data.toothLab) !== ToothLabOptions.None &&
                            Number.parseInt(data.ageRecorded) === YesNoOptions.Yes
                              ? data.age
                              : ""
                          }
                          onChange={this.handleChange}
                          readOnly={
                            !this.isToothLabRequired() ||
                            Number.parseInt(data.toothLab) === ToothLabOptions.None ||
                            Number.parseInt(data.ageRecorded) === YesNoOptions.No
                          }
                          isInvalid={errors.age}
                        />
                        <Form.Control.Feedback type="invalid">{errors.age}</Form.Control.Feedback>
                      </React.Fragment>
                    ) : (
                      <Form.Text className={globalStyles.formData}>
                        {Number.parseInt(data.toothLab) !== ToothLabOptions.None &&
                        Number.parseInt(data.ageRecorded) === YesNoOptions.Yes
                          ? data.age
                          : "N/A"}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  {this.isToothLabRequired() && Number.parseInt(data.toothLab) !== ToothLabOptions.None ? (
                    this.renderEditableField(
                      isEditMode,
                      "ttccPresent",
                      "TTCC Present",
                      data.ttccPresent,
                      "select",
                      this.state.yesNoNotTestedOptions
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>TTCC Present</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="ttccPresent"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Age/TTCC Comments</Form.Label>
                    {isEditMode ? (
                      <React.Fragment>
                        <Form.Control
                          name="ageTtccComments"
                          placeholder="Enter Age/TTCC Comments"
                          type="text"
                          as="textarea"
                          rows={3}
                          className={globalStyles.formData}
                          value={Number.parseInt(data.toothLab) !== ToothLabOptions.None ? data.ageTtccComments : "N/A"}
                          onChange={this.handleChange}
                          isInvalid={errors.ageTtccComments}
                          readOnly={
                            !this.isToothLabRequired() || Number.parseInt(data.toothLab) === ToothLabOptions.None
                          }
                        />
                        <Form.Control.Feedback type="invalid">{errors.ageTtccComments}</Form.Control.Feedback>
                      </React.Fragment>
                    ) : (
                      <Form.Text className={globalStyles.formData}>
                        {this.isToothLabRequired() && Number.parseInt(data.toothLab) !== ToothLabOptions.None
                          ? data.ageTtccComments
                          : "N/A"}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  {this.isBloodLabAvailable() ? (
                    this.renderEditableField(
                      isEditMode,
                      "rabiesBloodLab",
                      "Rabies Blood Lab",
                      data.rabiesBloodLab,
                      "select",
                      this.state.rabiesBloodLabOptions,
                      data.rabiesCheckbox ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Rabies Blood Lab</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="rabiesBloodLab"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col md={3}>
                  {!data.noneCheckbox &&
                  (!this.isBloodLabAvailable() ||
                    Number.parseInt(data.rabiesBloodLab) !== RabiesBloodLabOptions.None) ? (
                    this.renderEditableField(isEditMode, "rabiesVnaIu", "Rabies VNA IU/ml", data.rabiesVnaIu)
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Rabies VNA IU/ml</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="rabiesVnaIu"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col md={3}>
                  {!data.noneCheckbox &&
                  (!this.isBloodLabAvailable() ||
                    Number.parseInt(data.rabiesBloodLab) !== RabiesBloodLabOptions.None) ? (
                    this.renderEditableField(
                      isEditMode,
                      "vnaInterpretation",
                      "Rabies VNA Interpretation",
                      data.vnaInterpretation,
                      "select",
                      this.state.rabiesVnaInterpretationOptions,
                      data.rabiesVnaIu ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Rabies VNA Interpretation</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="vnaInterpretation"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Rabies VNA Comments</Form.Label>
                    {isEditMode ? (
                      <React.Fragment>
                        <Form.Control
                          name="vnaComments"
                          placeholder="Enter Rabies VNA Comments"
                          type="text"
                          as="textarea"
                          rows={3}
                          className={globalStyles.formData}
                          value={
                            this.isBloodLabAvailable() &&
                            Number.parseInt(data.rabiesBloodLab) === RabiesBloodLabOptions.None
                              ? "N/A"
                              : data.vnaComments
                          }
                          onChange={this.handleChange}
                          isInvalid={errors.vnaComments}
                          readOnly={
                            data.noneCheckbox ||
                            (this.isBloodLabAvailable() &&
                              Number.parseInt(data.rabiesBloodLab) === RabiesBloodLabOptions.None)
                          }
                        />
                        <Form.Control.Feedback type="invalid">{errors.vnaComments}</Form.Control.Feedback>
                      </React.Fragment>
                    ) : (
                      <Form.Text className={globalStyles.formData}>
                        {this.isBloodLabAvailable() &&
                        Number.parseInt(data.rabiesBloodLab) === RabiesBloodLabOptions.None
                          ? "N/A"
                          : data.vnaComments}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  {data.canineDistemperCheckbox && !data.noneCheckbox ? (
                    this.renderEditableField(
                      isEditMode,
                      "distemperVnaDilution",
                      "Distemper VNA Dilution",
                      data.distemperVnaDilution
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Distemper VNA Dilution</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="distemperVnaDilution"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col md={3} className="pr-0">
                  {data.canineDistemperCheckbox && !data.noneCheckbox ? (
                    this.renderEditableField(
                      isEditMode,
                      "distemperVnaInterpretation",
                      "Distemper VNA Interpretation",
                      data.distemperVnaInterpretation,
                      "select",
                      this.state.interpretationOptions,
                      data.distemperVnaDilution ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Distemper VNA Interpretation</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="distemperVnaInterpretation"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Distemper VNA Comments</Form.Label>
                    {isEditMode ? (
                      <React.Fragment>
                        <Form.Control
                          name="distemperVnaComments"
                          placeholder="Enter Distemper VNA Comments"
                          type="text"
                          as="textarea"
                          rows={3}
                          className={globalStyles.formData}
                          value={
                            data.noneCheckbox ||
                            (this.isBloodLabAvailable() &&
                              Number.parseInt(data.rabiesBloodLab) === RabiesBloodLabOptions.None)
                              ? "N/A"
                              : data.distemperVnaComments
                          }
                          onChange={this.handleChange}
                          isInvalid={errors.distemperVnaComments}
                          readOnly={
                            data.noneCheckbox ||
                            (this.isBloodLabAvailable() &&
                              Number.parseInt(data.rabiesBloodLab) === RabiesBloodLabOptions.None)
                          }
                        />
                        <Form.Control.Feedback type="invalid">{errors.distemperVnaComments}</Form.Control.Feedback>
                      </React.Fragment>
                    ) : (
                      <Form.Text className={globalStyles.formData}>
                        {Number.parseInt(data.rabiesBloodLab) !== RabiesBloodLabOptions.None
                          ? data.distemperVnaComments
                          : "N/A"}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  {(data.other3Checkbox || data.other4Checkbox) && !data.noneCheckbox ? (
                    this.renderEditableField(
                      isEditMode,
                      "adenoVnaDilution",
                      "Adeno VNA Dilution",
                      data.adenoVnaDilution
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Adeno VNA Dilution</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="adenoVnaDilution"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
                <Col md={3}>
                  {(data.other3Checkbox || data.other4Checkbox) && !data.noneCheckbox ? (
                    this.renderEditableField(
                      isEditMode,
                      "adenoVnaInterpretation",
                      "Adeno VNA Interpretation",
                      data.adenoVnaInterpretation,
                      "select",
                      this.state.interpretationOptions,
                      data.adenoVnaDilution ? "required" : ""
                    )
                  ) : (
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Adeno VNA Interpretation</Form.Label>
                      {isEditMode ? (
                        <Form.Control
                          name="adenoVnaInterpretation"
                          placeholder="N/A"
                          type="text"
                          className={globalStyles.formData}
                          readOnly
                        />
                      ) : (
                        <Form.Text className={globalStyles.formData}>N/A</Form.Text>
                      )}
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Adeno VNA Comments</Form.Label>
                    {isEditMode ? (
                      <React.Fragment>
                        <Form.Control
                          name="adenoVnaComments"
                          placeholder="Enter Adeno VNA Comments"
                          type="text"
                          as="textarea"
                          rows={3}
                          className={globalStyles.formData}
                          value={
                            this.isBloodLabAvailable() &&
                            Number.parseInt(data.rabiesBloodLab) === RabiesBloodLabOptions.None
                              ? "N/A"
                              : data.adenoVnaComments
                          }
                          onChange={this.handleChange}
                          isInvalid={errors.adenoVnaComments}
                          readOnly={
                            data.noneCheckbox ||
                            (this.isBloodLabAvailable() &&
                              Number.parseInt(data.rabiesBloodLab) === RabiesBloodLabOptions.None)
                          }
                        />
                        <Form.Control.Feedback type="invalid">{errors.adenoVnaComments}</Form.Control.Feedback>
                      </React.Fragment>
                    ) : (
                      <Form.Text className={globalStyles.formData}>
                        {Number.parseInt(data.rabiesBloodLab) !== RabiesBloodLabOptions.None
                          ? data.adenoVnaComments
                          : "N/A"}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderDashedSeparatorRow() {
    return (
      <Row className="px-3 my-4">
        <Col className={styles.dashedBorderSeparatorRow}></Col>
      </Row>
    );
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading Rabies Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.samplesRecordPage}>
          <Form onSubmit={this.handleSubmit}>
            {this.renderPageHeader()}
            {this.renderRabiesGeneralSection()}
            {this.renderDrugsAndVaccinesSection()}
            {this.renderSamplesSection()}
            {this.renderLaboratoryProcessingSection()}
            {this.renderLaboratoryResultsSection()}
          </Form>
        </div>
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfulUpdateModal}
          onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
          routeData=""
          messageType="update"
          objectName="NRMP Sample"
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(RabiesDetailsPage);
