import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Card, Form, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { toast } from "react-toastify";
import CustomForm from "../../../common/form";
import globalStyles from "../../../../OARS.module.scss";
import styles from "./PropertyVisitSummary.module.scss";
import SuccessfulRecordModifiedModal from "../../../common/create-updateOkMessageModal";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import searchIcon from "../../../../assets/search.svg";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import UtilityFunctions from "../../../common/UtilityFunctions";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import RoleAuthorizationAPI from "../../../../api/RoleAuthorization/RoleAuthorizationAPI";
import AgreementEntitiesAPI from "../../../../api/AgreementEntities/AgreementEntitiesAPI";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

class DiseaseMonitoringDetailsPage extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        date: "",
        employeeName: "",
        employeeId: "",
        selectedProperty: "",
        propertyName: "",
        locationType: "",
        latitude: "",
        longitude: "",
        isFromTake: "",
        samplesRecorded: [],
        diseaseSamplesShipping: [],
      },
      states: props.history.location.state?.states.map((state) => {
        state.id = state.stateUniqueIdentifier || state.id;
        state.name = state.state_name || state.name;
        return state;
      }),
      countries: props.history.location.state?.countries,
      locationTypes: [],
      genderOptions: [],
      ageOptions: [],
      sampleTypes: [],
      diseases: [],
      animalTableRowsSelected: [],
      shipmentDetailsOptions: [],
      currentUsaState: "",
      diseaseMonitoringData: "",
      isFormLoading: true,
      isEditMode: false,
      showSuccessfulUpdateModal: false,
      samplesActiveTab: "",
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
    }

    const diseaseMonitoringData = await this.getDiseaseMonitoringData();
    const data = diseaseMonitoringData ? this.updatePageData({ ...diseaseMonitoringData }) : { ...this.state.data };

    this.setState({
      currentUsaState,
      diseaseMonitoringData,
      locationTypes: await this.getLocationTypesFromAPI(),
      genderOptions: await this.getGenderOptionsFromAPI(),
      ageOptions: await this.getAgeOptionsFromAPI(),
      sampleTypes: await this.getSampleTypesFromAPI(),
      diseases: await this.getDiseasesFromAPI(currentUsaState?.stateGuid),
      shipmentDetailsOptions: await this.getShipmentDetailsOptionsFromAPI(),
      samplesActiveTab: data.samplesRecorded?.length > 0 ? "sampleTab-0" : "",
      data,
      isFormLoading: false,
    });
  }

  async getDiseaseMonitoringData() {
    let diseaseMonitoringData = "";
    const entityData = this.props.history.location.state?.entityData;
    if (entityData?.diseaseMonitoringUniqueIdentifier) {
      const getDiseaseMonitoringResponse = await WorkTaskAPI.GetDiseaseMonitoringEntry(
        entityData.diseaseMonitoringUniqueIdentifier
      );
      if (getDiseaseMonitoringResponse?.successful) {
        diseaseMonitoringData = getDiseaseMonitoringResponse.data;
      } else {
        ErrorHandler.showErrorWithMessage(
          getDiseaseMonitoringResponse?.message
            ? getDiseaseMonitoringResponse.message
            : "Disease Monitoring Data could not be retrieved"
        );
      }
    }

    return diseaseMonitoringData;
  }

  async getLocationTypesFromAPI() {
    let locationTypes = [];
    const locationTypesResponse = await WorkTaskAPI.GetLocTypeOptions();
    if (locationTypesResponse?.successful && locationTypesResponse.data?.length > 0) {
      locationTypes = locationTypesResponse.data.map((location) => ({
        id: location.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(location),
      }));
    }
    return locationTypes;
  }

  async getGenderOptionsFromAPI() {
    let genderOptions = [];
    const genderOptionsResponse = await WorkTaskAPI.GetGenderOptions();
    if (genderOptionsResponse?.successful && genderOptionsResponse?.data?.length > 0) {
      genderOptions = genderOptionsResponse.data.map((option) => ({
        value: option.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(option),
      }));
    }
    return genderOptions;
  }

  async getAgeOptionsFromAPI() {
    let ageOptions = [];
    const ageOptionsResponse = await WorkTaskAPI.GetAgeOptions();
    if (ageOptionsResponse?.successful && ageOptionsResponse.data?.length > 0) {
      ageOptions = ageOptionsResponse.data.map((option) => ({
        value: option.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(option),
      }));
    }
    return ageOptions;
  }

  async getSampleTypesFromAPI() {
    let sampleTypeOptions = [];
    const sampleTypesresponse = await WorkTaskAPI.GetSampleOptions();
    if (sampleTypesresponse?.successful && sampleTypesresponse.data?.length > 0) {
      sampleTypeOptions = sampleTypesresponse.data.map((type) => ({
        value: type.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(type),
      }));
    }
    return sampleTypeOptions;
  }

  async getDiseasesFromAPI(stateId) {
    let diseases = [];

    if (stateId) {
      const diseasesResponse = await ReferenceFileAPI.GetDiseasesForState(stateId);
      if (diseasesResponse?.successful && diseasesResponse.data?.results?.length > 0) {
        diseases = diseasesResponse.data.results
          .filter((d) => d?.isAllowed)
          ?.map((result) => ({
            value: result.diseaseUniqueIdentifier,
            label: result.name,
          }));
      }
    }
    return diseases;
  }

  async getShipmentDetailsOptionsFromAPI() {
    let labShipmentOptions = [];
    const labShipmentOptionsResponse = await WorkTaskAPI.GetLabShipmentOptions();
    if (labShipmentOptionsResponse?.successful && labShipmentOptionsResponse.data?.length > 0) {
      labShipmentOptions = labShipmentOptionsResponse.data.map((option) => ({
        value: option.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(option),
      }));
    }
    return labShipmentOptions;
  }

  updatePageData(diseaseMonitoringData) {
    const data = { ...this.state.data };

    data.date = diseaseMonitoringData.date
      ? new Date(diseaseMonitoringData.date).toLocaleDateString("en-CA", { timeZone: "UTC" })
      : "";
    data.employeeName = {
      label: diseaseMonitoringData.employee?.userFullName,
      profileName: diseaseMonitoringData.employee?.profileName,
      value: diseaseMonitoringData.employee?.wsUserProfileUniqueReference,
    };
    data.selectedProperty = {
      value: diseaseMonitoringData.property?.propertyUniqueReference,
      label: diseaseMonitoringData.property?.propertyName,
    };
    data.locationType = diseaseMonitoringData.locType?.id;
    data.latitude = diseaseMonitoringData.latitude || "";
    data.longitude = diseaseMonitoringData.longitude || "";
    data.isFromTake = diseaseMonitoringData.isFromTake;
    data.samplesRecorded =
      diseaseMonitoringData.samplesRecorded?.length > 0
        ? diseaseMonitoringData.samplesRecorded.map((sample) => ({
            subjectId: sample.subjectId,
            age: sample.age?.id,
            gender: sample.gender?.id,
            species: { value: sample.species?.speciesUniqueReference, label: sample.species?.speciesName },
            diseases:
              sample.diseases?.length > 0
                ? sample.diseases.map((d) => ({
                    disease: { value: d.diseaseUniqueReference, label: d.diseaseName },
                    sampleType: d.sampleType?.id,
                  }))
                : [],
          }))
        : [];
    data.diseaseSamplesShipping = diseaseMonitoringData.diseaseSamplesShipping.map((sample) => ({
      quantity: sample.quantity,
      shipDate: sample.shipDate ? new Date(sample.shipDate).toLocaleDateString("en-CA", { timeZone: "UTC" }) : "",
      disease: { value: sample.disease?.diseaseUniqueReference, label: sample.disease?.diseaseName },
      labShipmentDetails: {
        value: sample.labShipmentDetails?.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(sample.labShipmentDetails),
      },
    }));

    return data;
  }

  handleSpecificChanges = () => {
    // do something
  };

  saveDiseaseSample = () => {
    const { data } = this.state;

    let diseaseSampleData = {
      latitude: data.latitude?.toString(),
      longitude: data.longitude?.toString(),
      locTypeEnumId: data.locationType,
      samplesRecorded: data.samplesRecorded.map((s) => ({
        subjectId: s.subjectId,
        speciesUniqueReference: s.species.value,
        genderEnumId: s.gender,
        ageEnumId: s.age,

        diseases:
          s.diseases?.length > 0
            ? s.diseases.map((d) => ({
                diseaseUniqueReference: d.disease.value,
                sampleTypeEnumId: d.sampleType,
              }))
            : [],
      })),
    };

    return diseaseSampleData;
  };

  handleWsSearch = async (inputValue) => {
    let profilesResults = [];

    if (this.state.currentUsaState?.stateGuid) {
      const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText({
        textToSearchFor: inputValue,
        pageSize: 25,
        pageNumber: 1,
        stateUniqueReference: this.state.currentUsaState.stateGuid,
      });
      if (contactsData?.data?.results?.length > 0) {
        for (let user of contactsData.data.results) {
          let userObject;
          for (let j = 0; j < user.userProfiles.length; j++) {
            let userProfile = user.userProfiles[j];
            if (userProfile) {
              userObject = {
                value: userProfile.wsUserProfileUniqueIdentifier,
                label: user.firstName + " " + user.lastName,
                profileName: userProfile.profileName,
                wsUserUniqueIdentifier: user.wsUserUniqueIdentifier,
              };
              profilesResults.push(userObject);
            }
          }
        }
      }
    }
    return profilesResults;
  };

  handleSelectChanges = (e, fieldName) => {
    const { data } = this.state;
    data[fieldName] = e;
    this.setState({ data });
  };

  handlePropertiesSearch = async (inputValue) => {
    let propertiesResult = [];
    if (this.state.currentUsaState?.stateGuid) {
      const getPropertiesResponse = await AgreementEntitiesAPI.SearchPropertiesFreeText(
        this.getSearchPropertiesBody(inputValue),
        this.state.currentUsaState.stateGuid
      );
      if (!getPropertiesResponse.successful) {
        ErrorHandler.showErrorWithMessage(
          getPropertiesResponse?.message ? getPropertiesResponse.message : "Properties data could not be retrieved."
        );
      } else if (getPropertiesResponse.data?.results?.length > 0) {
        propertiesResult = getPropertiesResponse.data.results.map((result) => ({
          value: result.propertyUniqueIdentifier,
          label: result.propertyName,
        }));
      }
    }

    return propertiesResult;
  };

  getSearchPropertiesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 0,
    };
  }

  handleSampleSelection = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    if (input.name === "selectAllSamplesCheckbox") {
      data.samplesRecorded.forEach((sample) => {
        sample.isSelected = input.checked ? true : false;
      });
    } else {
      const sampleIndex = input.name.split("-")[1];
      data.samplesRecorded[sampleIndex].isSelected = input.checked;
      if (!input.checked) {
        document.getElementById("selectAllSamplesCheckbox").checked = false;
      }
    }

    this.setState({ data });
  };

  handleSamplesTabSelect(tab) {
    this.setState({ samplesActiveTab: tab });
  }

  handleSamplesChanges = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    const { data } = this.state;
    data.samplesRecorded[index][fieldName] = input.value;

    this.setState({ data });
  };

  handleSamplesShippingChanges = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    const { data } = this.state;
    data.diseaseSamplesShipping[index][fieldName] = input.value;

    this.setState({ data });
  };

  handleSpeciesSearch = async (searchValue) => {
    let speciesResult = [];

    if (this.state.currentUsaState?.stateGuid) {
      const speciesDataResponse = await ReferenceFileAPI.FilterSpecies(this.state.currentUsaState.stateGuid, {
        textToSearchFor: searchValue,
        pageSize: 10,
        pageNumber: 1,
      });
      if (!speciesDataResponse.successful) {
        ErrorHandler.showErrorWithMessage(
          speciesDataResponse.message ? speciesDataResponse.message : "Species results could not be retrieved."
        );
      } else if (speciesDataResponse.data?.results?.length > 0) {
        speciesResult = speciesDataResponse.data.results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return speciesResult;
  };

  handleSamplesSpeciesChanges = (input, index) => {
    const { data } = this.state;
    data.samplesRecorded[index]["species"] = input;
    this.setState({ data });
  };

  handleSamplesGenderChanges = (input, index) => {
    const { data } = this.state;
    data.samplesRecorded[index]["gender"] = input.value;
    this.setState({ data });
  };

  handleSamplesAgeChanges = (input, index) => {
    const { data } = this.state;
    data.samplesRecorded[index]["age"] = input.value;
    this.setState({ data });
  };

  handleSamplesDiseaseChanges = (input, sampleIndex, diseaseRecordIndex) => {
    const { data } = this.state;
    data.samplesRecorded[sampleIndex]["diseases"][diseaseRecordIndex].disease = input;
    this.setState({ data });
  };

  handleLabShipmentDetailsChanges = (input, index) => {
    const { data } = this.state;
    data.diseaseSamplesShipping[index]["labShipmentDetails"] = input;
    this.setState({ data });
  };

  handleSamplesTypesChanges = (input, sampleIndex, diseaseRecordIndex) => {
    const { data } = this.state;
    data.samplesRecorded[sampleIndex]["diseases"][diseaseRecordIndex].sampleType = input.value;
    this.setState({ data });
  };

  handleDeleteSampleDisease = (sampleIndex, diseaseRecordIndex) => {
    const { data } = this.state;
    data.samplesRecorded[sampleIndex].diseases.splice(diseaseRecordIndex, 1);
    this.setState({ data });
  };

  handleAddAnimalSample = () => {
    const data = { ...this.state.data };
    data.samplesRecorded.push({
      age: "",
      gender: "",
      species: "",
      subjectId: "",
      diseases: [{ disease: "", sampleType: "" }],
    });

    this.setState({ data }, () => this.setState({ samplesActiveTab: `sampleTab-${data.samplesRecorded.length - 1}}` }));
  };

  handleRemoveAnimalSample = () => {
    const data = { ...this.state.data };

    if (!data.samplesRecorded.some((s) => s.isSelected)) {
      toast.warning("Please select the samples you want to remove.");
    } else {
      data.samplesRecorded = data.samplesRecorded.filter((sample) => !sample.isSelected);
    }

    this.setState({ data });
  };

  handleEditDiseaseMonitoring = async () => {
    const { data } = this.state;
    if (!this.state.isEditMode) {
      data.samplesRecorded.forEach((sample) => {
        if (!(sample.diseases?.length > 0)) {
          sample.diseases = [{ sampleType: "", disease: "" }];
        }
      });
      this.setState({ data, isEditMode: true });
    } else {
      this.setState({ isFormLoading: true });
      let { diseaseMonitoringData } = this.state;
      if (!this.isFormDataValid()) {
        if (!(data.samplesRecorded?.length > 0)) {
          toast.warning("At least one sample must be recorded.");
        } else {
          toast.warning("Please fill out all the required fields.");
        }
      } else if (diseaseMonitoringData?.diseaseMonitoringUniqueIdentifier) {
        const editDiseaseResponse = await WorkTaskAPI.UpdateDiseaseMonitoringEntry(
          diseaseMonitoringData.diseaseMonitoringUniqueIdentifier,
          this.saveDiseaseSample()
        );

        if (editDiseaseResponse?.successful) {
          diseaseMonitoringData = editDiseaseResponse.data;

          const data = diseaseMonitoringData
            ? await this.updatePageData({ ...diseaseMonitoringData }, this.state.organizations)
            : { ...this.state.data };

          this.setState({ data, diseaseMonitoringData, showSuccessfulUpdateModal: true, isEditMode: false });
        } else if (editDiseaseResponse?.message) {
          ErrorHandler.showError(editDiseaseResponse.message);
        } else {
          ErrorHandler.showError("Sample update failed. Contact system admin for more details.");
        }

        this.setState({ showSuccessfulUpdateModal: true, isEditMode: false });
      }
      this.setState({ isFormLoading: false });
    }
  };

  isFormDataValid() {
    const { data } = this.state;
    return data.samplesRecorded?.length > 0;
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  renderPageHeader() {
    return (
      <Row className="mt-4">
        <Col className="pl-0">
          <h1 className={globalStyles.pageTitle}>Disease Monitoring Record Page</h1>
        </Col>
        {
          <Col className="text-right pr-0">
            {this.props.permissions.canIEditSamplesAssignedStates() && (
              <Button variant="primary" onClick={this.handleEditDiseaseMonitoring}>
                <span className={globalStyles.modalSubmitButtonText}>
                  {this.state.isEditMode ? "Apply Changes" : "Edit"}
                </span>
              </Button>
            )}
          </Col>
        }
      </Row>
    );
  }

  renderDiseaseOverviewTableCard() {
    const { data, isEditMode } = this.state;
    return (
      <Card className={styles.diseaseMonitoringOverviewCard}>
        <Card.Body>
          <Row className={styles.diseaseMonitoringTableRow}>
            {isEditMode && (
              <Col sm={1}>
                <Form.Check
                  name={"selectAllSamplesCheckbox"}
                  id="selectAllSamplesCheckbox"
                  type="checkbox"
                  title="selectAllSamplesCheckbox"
                  label=""
                  onChange={this.handleSampleSelection}
                />
              </Col>
            )}
            <Col sm={3} className={globalStyles.formLabel}>
              Subject ID
            </Col>
            <Col sm={3} className={globalStyles.formLabel}>
              Damage Agent
            </Col>
            <Col sm={isEditMode ? 2 : 3} className={globalStyles.formLabel}>
              Age
            </Col>
            <Col sm={3} className={globalStyles.formLabel}>
              Gender
            </Col>
          </Row>
          {data.samplesRecorded?.map((sample, index) => {
            return (
              <Row
                style={sample.isSelected ? { backgroundColor: "#DAE9E5" } : {}}
                className={styles.diseaseMonitoringTableRow}
                key={index}
              >
                {isEditMode && (
                  <Col sm={1}>
                    <Form.Check
                      name={`sampleSelectionCheckbox-${index}`}
                      type="checkbox"
                      title={`sampleSelectionCheckbox-${index}`}
                      label=""
                      id={`sampleSelectionCheckbox-${index}`}
                      onChange={this.handleSampleSelection}
                      checked={sample.isSelected}
                    />
                  </Col>
                )}
                <Col
                  sm={3}
                  className={
                    sample.subjectId ? styles.diseaseMonitoringTableText : styles.diseaseMonitoringTableTextGray
                  }
                >
                  {sample.subjectId ? sample.subjectId : `Animal ${index + 1}`}
                </Col>
                <Col sm={3} className={styles.diseaseMonitoringTableText}>
                  {sample.species?.label ? sample.species.label : ""}
                </Col>
                <Col sm={isEditMode ? 2 : 3} className={styles.diseaseMonitoringTableText}>
                  {sample.age ? this.state.ageOptions?.find((age) => age.value === sample.age)?.label : ""}
                </Col>
                <Col sm={3} className={styles.diseaseMonitoringTableText}>
                  {sample.gender
                    ? this.state.genderOptions?.find((gender) => gender.value === sample.gender)?.label
                    : ""}
                </Col>
              </Row>
            );
          })}
          {isEditMode && (
            <Row className={styles.addDeleteDiseaseSamplesRow}>
              <Col className="text-left px-0">
                <Button variant="link" onClick={this.handleAddAnimalSample} className="pl-0">
                  <span className={globalStyles.formDataLinks}>+ Add Another Animal</span>
                </Button>
              </Col>
              <Col className="text-right px-0">
                <Button variant="link" onClick={this.handleRemoveAnimalSample} className="pr-0">
                  <span className={globalStyles.formDataLinks}>Remove</span>
                </Button>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    );
  }

  renderGeneralInfoCard() {
    const { data, isEditMode } = this.state;
    return (
      <Card className="text-left">
        <Card.Body>
          <Row>
            <Col>{this.renderEditableField(!isEditMode, "date", "Date", data.date, "date", [], "required")}</Col>
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>
                  Employee Name{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                </Form.Label>
                <Form.Text className={globalStyles.formData}>{data.employeeName?.label}</Form.Text>
              </Form.Group>
            </Col>
            <Col align="left" style={{ marginBottom: "16px" }}>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Property Name</Form.Label>
                <Form.Text className={globalStyles.formData}>{data.selectedProperty?.label}</Form.Text>
              </Form.Group>
            </Col>
            <Col>
              {this.renderEditableField(
                isEditMode,
                "locationType",
                "Location Type",
                data.locationType,
                "select",
                this.state.locationTypes
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Latitude</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    name="latitude"
                    placeholder="Enter latitude"
                    type="number"
                    step="0.00001"
                    className={globalStyles.formData}
                    value={data.latitude}
                    onChange={this.handleChange}
                  />
                ) : (
                  <Form.Text className={globalStyles.formData}>{data.latitude || "N/A"}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Longitude</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    name="longitude"
                    placeholder="Enter longitude"
                    type="number"
                    step="0.00001"
                    className={globalStyles.formData}
                    value={data.longitude}
                    onChange={this.handleChange}
                  />
                ) : (
                  <Form.Text className={globalStyles.formData}>{data.longitude || "N/A"}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={3} className="pt-4">
              <Form.Check type="checkbox" id="WSTakeCheckbox" inline>
                <Form.Check.Input
                  className="mr-2"
                  name="isFromTake"
                  type="checkbox"
                  value={data.isFromTake}
                  onChange={this.handleCheckboxChanges}
                  checked={data.isFromTake}
                  disabled={!isEditMode}
                />
                <Form.Check.Label>
                  <span className={globalStyles.formLabel}>WS Take</span>
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderAnimalDetailsSection() {
    const { data, isEditMode } = this.state;
    return (
      <Card className="text-left mt-4">
        <Card.Header className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>Samples Recorded</span>
        </Card.Header>
        <Card.Body>
          {data.samplesRecorded?.length > 0 ? (
            <Row>
              <Col>
                <Tabs
                  id="sampleDetailsTabs"
                  onSelect={(e) => this.handleSamplesTabSelect(e)}
                  className={styles.diseaseSamplesDetailsTabs}
                  activeKey={this.state.samplesActiveTab}
                >
                  {data.samplesRecorded.map((sample, index) => (
                    <Tab
                      key={index}
                      eventKey={`sampleTab-${index}`}
                      title={sample.subjectId ? sample.subjectId : `Animal ${index + 1}`}
                    >
                      <Row className="mt-3">
                        <Col md={6}>
                          <Form.Group controlId={`subjectId-${index}`}>
                            <Form.Label className={globalStyles.formLabel}>Subject ID</Form.Label>
                            {isEditMode ? (
                              <Form.Control
                                name="subjectId"
                                type="text"
                                placeholder="Enter Subject Id."
                                aria-label={`Subject ID Input ${index}`}
                                value={sample.subjectId}
                                onChange={this.handleSamplesChanges}
                              />
                            ) : (
                              <Form.Text className={globalStyles.formData}>{sample.subjectId}</Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label className={globalStyles.formLabel}>
                              Damage Agent{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                            </Form.Label>
                            {isEditMode ? (
                              <AsyncSelect
                                aria-label="Sample Damage Agent Search"
                                name="species"
                                value={sample.species}
                                placeholder="Search here..."
                                styles={this.customSelectStyles}
                                loadOptions={this.handleSpeciesSearch}
                                onChange={(e) => this.handleSamplesSpeciesChanges(e, index)}
                                components={{ DropdownIndicator, Option }}
                              />
                            ) : (
                              <Form.Text className={globalStyles.formData}>{sample.species?.label}</Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className={globalStyles.formLabel}>
                              Gender{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                            </Form.Label>
                            {isEditMode ? (
                              <Select
                                aria-label="Sample Gender Selection"
                                name="gender"
                                value={this.state.genderOptions?.find((o) => o.value === sample.gender)}
                                placeholder="Select"
                                styles={this.customSelectStyles}
                                onChange={(e) => this.handleSamplesGenderChanges(e, index)}
                                options={this.state.genderOptions}
                              />
                            ) : (
                              <Form.Text className={globalStyles.formData}>
                                {this.state.genderOptions?.find((o) => o.value === sample.gender)?.label}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className={globalStyles.formLabel}>
                              Age{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                            </Form.Label>
                            {isEditMode ? (
                              <Select
                                aria-label="Sample Gender Selection"
                                name="gender"
                                value={this.state.ageOptions?.find((o) => o.value === sample.age)}
                                placeholder="Select"
                                styles={this.customSelectStyles}
                                onChange={(e) => this.handleSamplesAgeChanges(e, index)}
                                options={this.state.ageOptions}
                              />
                            ) : (
                              <Form.Text className={globalStyles.formData}>
                                {this.state.ageOptions?.find((o) => o.value === sample.age)?.label}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      {this.renderDashedSeparatorRow()}
                      {sample.diseases?.length > 0 ? (
                        sample.diseases.map((diseaseRecord, dIndex) => (
                          <Row
                            key={`diseaseAndSampleRow-${dIndex}`}
                            className={
                              dIndex !== sample.diseases.length - 1 ? styles.diseaseSamplesRecordedRow : "mt-2 mx-0"
                            }
                          >
                            <Col className="px-0">
                              <Form.Group>
                                <Form.Label className={globalStyles.formLabel}>
                                  Disease{isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                                </Form.Label>
                                {isEditMode ? (
                                  <Select
                                    aria-label="Sample Disease Selection"
                                    name="disease"
                                    value={diseaseRecord.disease}
                                    placeholder="Select"
                                    styles={this.customSelectStyles}
                                    onChange={(e) => this.handleSamplesDiseaseChanges(e, index, dIndex)}
                                    options={this.state.diseases}
                                  />
                                ) : (
                                  <Form.Text className={globalStyles.formData}>
                                    {diseaseRecord.disease?.label}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={isEditMode ? 5 : 6} className="pr-0">
                              <Form.Group>
                                <Form.Label className={globalStyles.formLabel}>
                                  Sample Type
                                  {isEditMode ? <span className={globalStyles.asterisk508}>{" *"}</span> : ""}
                                </Form.Label>
                                {isEditMode ? (
                                  <Select
                                    aria-label="Sample Type Selection"
                                    name="sampleType"
                                    value={this.state.sampleTypes.find((t) => t.value === diseaseRecord.sampleType)}
                                    placeholder="Select"
                                    styles={this.customSelectStyles}
                                    onChange={(e) => this.handleSamplesTypesChanges(e, index, dIndex)}
                                    options={this.state.sampleTypes}
                                  />
                                ) : (
                                  <Form.Text className={globalStyles.formData}>
                                    {this.state.sampleTypes.find((t) => t.value === diseaseRecord.sampleType)?.label}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                            {isEditMode && (
                              <Col sm={1} className="pt-4">
                                {sample.diseases?.length > 1 && (
                                  <i
                                    className="fa fa-times-circle-o pt-3"
                                    aria-hidden="true"
                                    onClick={() => this.handleDeleteSampleDisease(index, dIndex)}
                                  ></i>
                                )}
                              </Col>
                            )}
                          </Row>
                        ))
                      ) : (
                        <Row className="mt-2">
                          <Col>
                            <span className={globalStyles.formData}>There are no diseases available</span>
                          </Col>
                        </Row>
                      )}
                    </Tab>
                  ))}
                </Tabs>
              </Col>
            </Row>
          ) : (
            <Row className="text-center">
              <Col>
                <span className={globalStyles.formData}>There are no samples available</span>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    );
  }

  renderDiseaseSamplesShippingCard() {
    const { data, isEditMode } = this.state;
    return (
      <Card className="text-left">
        <Card.Header className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>Disease Samples - Shipping</span>
        </Card.Header>
        <Card.Body>
          <Row className={styles.diseaseSampleShippingTableHeader}>
            <Col className="pl-0">
              <span className={globalStyles.formLabel}>Disease</span>
            </Col>
            <Col>
              <span className={globalStyles.formLabel}>Quantity</span>
            </Col>
            <Col>
              <span className={globalStyles.formLabel}>Lab Shipment Details</span>
            </Col>
            <Col className="pr-0">
              <span className={globalStyles.formLabel}>Ship Date</span>
            </Col>
          </Row>
          {data.diseaseSamplesShipping?.length > 0 ? (
            data.diseaseSamplesShipping.map((sample, index) => (
              <Row key={index} className={styles.diseaseSampleShippingRow}>
                <Col className="pl-0">
                  {isEditMode ? (
                    <Form.Control
                      type="text"
                      defaultValue={sample.disease?.label ? sample.disease.label : "N/A"}
                      className={globalStyles.formData}
                      readOnly
                    />
                  ) : (
                    <Form.Text className={globalStyles.formData}>
                      {sample.disease?.label ? sample.disease.label : "N/A"}
                    </Form.Text>
                  )}
                </Col>
                <Col>
                  {isEditMode ? (
                    <Form.Control
                      type="text"
                      defaultValue={sample.quantity ? sample.quantity : "N/A"}
                      className={globalStyles.formData}
                      readOnly
                    />
                  ) : (
                    <Form.Text className={globalStyles.formData}>{sample.quantity ? sample.quantity : "N/A"}</Form.Text>
                  )}
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Text className={globalStyles.formData}>{sample.labShipmentDetails?.label}</Form.Text>
                  </Form.Group>
                </Col>
                <Col className="pr-0">
                  <Form.Group controlId={`shipDate-${index}`}>
                    <Form.Text className={globalStyles.formData}>{sample.shipDate}</Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            ))
          ) : (
            <Row className="mt-2">
              <Col>
                <span className={globalStyles.formData}>There are no records available.</span>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    );
  }

  renderDashedSeparatorRow() {
    return (
      <Row className="px-3 my-4">
        <Col className={styles.dashedBorderSeparatorRow}></Col>
      </Row>
    );
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading Samples Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.samplesRecordPage}>
          <Form className="text-left" onSubmit={this.handleSubmit}>
            {this.renderPageHeader()}
            <Row className="mt-3">
              <Col md={4} className="pl-0">
                {this.renderDiseaseOverviewTableCard()}
              </Col>
              <Col className="pr-0">
                {this.renderGeneralInfoCard()}
                {this.renderAnimalDetailsSection()}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="px-0">{this.renderDiseaseSamplesShippingCard()}</Col>
            </Row>
          </Form>
        </div>
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfulUpdateModal}
          onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
          routeData=""
          messageType="update"
          objectName="Disease Monitoring Entry"
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(DiseaseMonitoringDetailsPage);
