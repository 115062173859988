import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Button, Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter, customFilter } from "react-bootstrap-table2-filter";
import styles from "./AdminCenter.module.scss";
import globalStyles from "../../OARS.module.scss";
import CustomForm from "../common/form";
import CustomTextFilter from "../common/customTextFilter";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import { toast } from "react-toastify";
import ReferenceFileCardHeader from "../ReferenceFiles/ReferenceFileCardHeader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class DistrictsTable extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      districtsTableColumns: [
        { dataField: "districtUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      defaultSort: [{ dataField: "name", order: "asc" }],
      availableDistricts: [],
      isFormLoading: true,
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
      return;
    }

    this.setState({
      districtsTableColumns: this.getUserDistrictsTableColumns(),
      currentUsaState,
      isFormLoading: false,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentTab !== this.props.currentTab && this.props.currentTab === "districtsTab") {
      this.setState({ availableDistricts: await this.getAvailableDistricts() });
    }
  }

  getUserDistrictsTableColumns() {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
      paddingRight: "1.5rem",
    };
    return [
      {
        dataField: "districtUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "name",
        text: "District Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="District Name Search" />
        ),
        formatter: (cell, row) => {
          return (
            <Button variant="link" onClick={() => this.handleUserDetailsNavigation(row)}>
              <span className={globalStyles.formDataLinks}>{cell}</span>
            </Button>
          );
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px", color: "#0071BC" },
        headerAlign: "left",
        headerStyle: { fontWeight: 700, backgroundColor: "#f3f3f3" },
      },
      {
        dataField: "districtSupervisor",
        text: "Supervisor",
        filter: textFilter({
          onFilter: this.filterDistrictsBySupervisor,
        }),
        formatter: (cell, row) => {
          return cell?.profileName ? cell.profileName : "None";
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { fontWeight: 700, backgroundColor: "#f3f3f3" },
      },
      {
        dataField: "usersAssigned",
        text: "# of Users Assigned",
        filter: textFilter({
          onFilter: this.filterDistrictsByUsersAssigned,
        }),
        formatter: (cell, row) => {
          let assignedUsers = 0;
          if (row.districtSupervisor) {
            assignedUsers += 1;
          }
          if (row.additionalSupervisors?.length > 0) {
            assignedUsers += row.additionalSupervisors.length;
          }
          if (row.employees?.length > 0) {
            assignedUsers += row.employees.length;
          }

          return assignedUsers;
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { fontWeight: 700, backgroundColor: "#f3f3f3" },
      },
    ];
  }

  filterDistrictsBySupervisor = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (entry) =>
          entry.districtSupervisor?.firstName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          entry.districtSupervisor?.lastName?.toLowerCase().includes(filterValue?.toLowerCase())
      );
    }
  };

  filterDistrictsByUsersAssigned = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) => {
        let assignedUsers = 0;
        if (entry.districtSupervisor) {
          assignedUsers += 1;
        }
        if (entry.additionalSupervisors?.length > 0) {
          assignedUsers += entry.additionalSupervisors.length;
        }
        if (entry.employees?.length > 0) {
          assignedUsers += entry.employees.length;
        }

        return Number.parseInt(filterValue) === assignedUsers;
      });
    }
  };

  async getAvailableDistricts() {
    let availableDistricts = [];
    if (this.state.currentUsaState?.stateGuid) {
      const districtsSearchResponse = await RoleAuthorizationAPI.SearchDistrictFreetext(
        this.state.currentUsaState.stateGuid,
        this.getSearchDistrictsBody()
      );
      if (districtsSearchResponse?.successful) {
        availableDistricts = districtsSearchResponse.data?.length > 0 ? districtsSearchResponse.data : [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to load Districs data",
          apiName: "getAvailableDistricts",
          responseUnsuccessful: districtsSearchResponse?.unsuccessful,
          responseMessage: districtsSearchResponse?.message,
        });
      }
    }
    return availableDistricts;
  }

  getSearchDistrictsBody(inputValue = "") {
    return {
      searchFreetext: inputValue,
    };
  }

  handleUserDetailsNavigation = (row) => {
    this.props.history.push({
      pathname: "/district/details",
      state: {
        entityData: row,
      },
    });
  };

  render() {
    const districtsData = this.state.availableDistricts.length > 0 ? this.state.availableDistricts : [];
    return districtsData.length > 0 ? (
      <ToolkitProvider
        keyField="districtUniqueIdentifier"
        data={districtsData}
        columns={this.state.districtsTableColumns}
        bootstrap4={true}
        exportCSV={{
          fileName: "districts-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
        hover={true}
      >
        {(props) => (
          <div data-testid="DistrictsTab">
            <Card className={styles.adminCenterContainerCard}>
              <Card.Body className={styles.userTableContainer}>
                <ReferenceFileCardHeader
                  referenceFileType="Districts"
                  createButtonEvent={null}
                  extraHeaderComponents={null}
                  permissions={this.props.permissions}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="districtUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                  {...props.baseProps}
                />
              </Card.Body>
            </Card>
          </div>
        )}
      </ToolkitProvider>
    ) : (
      <Row className="mt-4 text-left">
        <Col>
          <span className={globalStyles.formLabel}>There are no Districts available in this state.</span>
        </Col>
      </Row>
    );
  }
}

export default withRouter(DistrictsTable);
