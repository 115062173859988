import React from "react";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import { Modal, Row, Col, Form, Button, CloseButton } from "react-bootstrap";
import CustomForm from "../../../common/form";
import { toast } from "react-toastify";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import searchIcon from "../../../../assets/search.svg";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import UtilityFunctions from "../../../common/UtilityFunctions";
import RoleAuthorizationAPI from "../../../../api/RoleAuthorization/RoleAuthorizationAPI";
import InventoryAPI from "../../../../api/Inventory/InventoryAPI";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";

const SearchIcon = () => {
  return <img src={searchIcon} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

const WarehouseTypes = {
  State: 1,
  Property: 2,
  Cooperator: 3,
  Employee: 4,
};

class RabiesSamplesModal extends CustomForm {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      data: {
        sampleDate: "",
        town: "",
        collector: 1,
        wsName: "",
        otherCollector: "",
        selectedCollectionActivity: "",
        selectedCollectionMethod: "",
        densityStudyId: "",
        densityStudy: "",
        dateOfLastOrv: "",
        orvNaive: "",
        baitType: "",
        otherBait: "",
        waypointNumber: "",
        latLongRecorded: "",
        latitude: "",
        longitude: "",
        nrmpTargetSpecies: "",
        misTargetSpecies: "",
        selectedSpecies: "",
        fateOfTrap: "",
        recaptureSelected: "",
        processedLessThan30DaysAgo: null,
        drugsAndVaccinesData: [
          { ieDrugOrVaccine: "", vialNumber: "", totalAmount: "", warehouse: "", availableDrugsOrVaccines: [] },
        ],
        animalId: "",
        sex: "",
        lactation: "",
        relativeAge: "",
        weightRecorded: "",
        weight: "",
        handVaccinated: "",
        selectedOtherSample: "",
        bloodCheckbox: false,
        pm1Checkbox: false,
        pm2Checkbox: false,
        k9Checkbox: false,
        jawCheckbox: false,
        dnaCheckbox: false,
        brainstemCheckbox: false,
        otherCheckbox: false,
        selectedFateForWs: "",
        otherFate: "",
        euthanizedReason: "",
        comments: "",
        freeText: "",
        selectedErsCategory: "",
        rabiesCheckbox: false,
        canineDistemperCheckbox: false,
        other3Checkbox: false,
        other4Checkbox: false,
        noneCheckbox: false,
        rabiesBrainstemTest: "",
        rabiesBrainLab: "",
        rabiesBrainTestDate: "",
        rabiesLabSpecimenNumber: "",
        rabiesBrainstemCondition: "",
        stainingIntensity: "",
        antigenDistribution: "",
        rabiesBrainstemResults: "",
        rabiesVariant: "",
        rabiesTestComments: "",
        toothLab: "",
        age: "",
        ageRecorded: "",
        ttccPresent: "",
        ageTtccComments: "",
        rabiesBloodLab: "",
        rabiesVnaIu: "",
        vnaInterpretation: "",
        distemperVnaInterpretation: "",
        distemperVnaDilution: "",
        distemperVnaComments: "",
        adenoVnaDilution: "",
        adenoVnaInterpretation: "",
      },
      currentRabiesCount: 1,
      isInitialConfiguration: true,
      completedSampleForms: [],
      collectorOptions: [],
      collectionActivity: [],
      collectionMethod: [],
      filteredCollectionMethod: [],
      baitTypes: [],
      fateOfTrapOptions: [],
      drugsAndVaccinesTypes: [],
      otherSampleTypes: [],
      fateTypes: [],
      filteredFateTypes: [],
      euthanizedByWsReasons: [],
      ersCategoryOptions: [],
      rabiesBrainLabOptions: [],
      brainstemConditions: [],
      stainingIntensityOptions: [],
      antigenDistributionOptions: [],
      rabiesBrainstemResultsOptions: [],
      rabiesVariants: [],
      toothLabOptions: [],
      rabiesBloodLabOptions: [],
      interpretationOptions: [],
      rabiesVnaInterpretationOptions: [],
      nrmpTargetSpeciesOptions: [],
      misTargetOptions: [],
      yesNoOptions: [],
      yesNoNotRecordedOptions: [],
      yesNoNotTestedOptions: [],
      sexOptions: [],
      ageOptions: [],
      defaultTargetSpeciesOption: "",
      availableWarehouses: [],
      errors: {},
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    let { data } = this.state;
    if (prevProps.show !== this.props.show && this.props.show === true) {
      const currentUserRole = JSON.parse(localStorage.getItem("currentUserRole"));
      data.wsName = {
        label: currentUserRole?.userName,
        profileName: currentUserRole?.profileName,
        value: currentUserRole?.wsUserProfileUniqueIdentifier,
        wsUserUniqueIdentifier: currentUserRole?.wsUserUniqueIdentifier,
      };
      const stainingIntensityOptions = await this.getEntityOptions("ritOptions");
      const yesNoNoCaptureOptions = await this.getEntityOptions("yesNoNoCapture");
      const nrmpTargetSpeciesOptions = yesNoNoCaptureOptions.map((option) => ({
        value: option.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(option),
      }));
      const fateOfTrapOptions = await this.getEntityOptions("fateOfTrap");

      let defaultTargetSpeciesOption = "";
      if (nrmpTargetSpeciesOptions?.length > 0) {
        defaultTargetSpeciesOption = this.props.data?.isUnintentionalTake
          ? nrmpTargetSpeciesOptions.find((option) => option.value === 2)
          : nrmpTargetSpeciesOptions.find((option) => option.value === 1);
      }
      if (!data.nrmpTargetSpecies) {
        data.nrmpTargetSpecies = defaultTargetSpeciesOption;
        data.fateOfTrap = defaultTargetSpeciesOption
          ? fateOfTrapOptions?.find((o) => o.name === "animalcaptured")?.id
          : "";
      }
      if (!data.misTargetSpecies) {
        data.misTargetSpecies = defaultTargetSpeciesOption;
      }

      const availableWarehouses = await this.getWarehousesData(currentUserRole?.wsUserProfileUniqueIdentifier);
      const defaultWarehouse = availableWarehouses?.length === 1 ? availableWarehouses[0] : "";
      data.drugsAndVaccinesData = [
        {
          ieDrugOrVaccine: "",
          vialNumber: "",
          totalAmount: "",
          warehouse: defaultWarehouse,
          availableDrugsOrVaccines: defaultWarehouse
            ? await this.getAvailableDrugsAndVaccinesPerWarehouse(defaultWarehouse.value)
            : [],
        },
      ];

      const otherSampleResults = await this.getEntityOptions("otherSample");
      const otherSampleTypes =
        otherSampleResults?.length > 0
          ? otherSampleResults.map((sample) => ({
              value: sample.id,
              label: UtilityFunctions.getDisplayTextFromFieldObject(sample),
            }))
          : [];

      const allCollectionMethods = await this.getEntityOptions("collectionMethod");

      this.setState({
        collectorOptions: await this.getEntityOptions("collector"),
        collectionActivity: await this.getEntityOptions("collectionActivity"),
        collectionMethod: allCollectionMethods,
        filteredCollectionMethod: allCollectionMethods,
        baitTypes: await this.getEntityOptions("orvBaitType"),
        fateOfTrapOptions,
        otherSampleTypes,
        fateTypes: await this.getEntityOptions("animalFate"),
        euthanizedByWsReasons: await this.getEntityOptions("euthanizationReason"),
        ersCategoryOptions: await this.getEntityOptions("ersCategory"),
        rabiesBrainLabOptions: await this.getEntityOptions("labsForBrainSample"),
        brainstemConditions: await this.getEntityOptions("brainstemConditions"),
        stainingIntensityOptions,
        antigenDistributionOptions: stainingIntensityOptions,
        rabiesBrainstemResultsOptions: await this.getEntityOptions("labResults"),
        rabiesVariants: await this.getEntityOptions("rabiesVariants"),
        toothLabOptions: await this.getEntityOptions("labsForTooth"),
        rabiesBloodLabOptions: await this.getEntityOptions("labsForBlood"),
        interpretationOptions: await this.getEntityOptions("interpretation"),
        rabiesVnaInterpretationOptions: await this.getEntityOptions("rabiesVnaInterpretation"),
        yesNoOptions: await this.getEntityOptions("yesNo"),
        yesNoNotRecordedOptions: await this.getEntityOptions("yesNoNotRecorded"),
        yesNoNotTestedOptions: await this.getEntityOptions("yesNoNotTested"),
        yesNoNoCaptureOptions,
        nrmpTargetSpeciesOptions,
        sexOptions: await this.getEntityOptions("sexOptions"),
        ageOptions: await this.getEntityOptions("ageOptions"),
        misTargetOptions: await this.getEntityOptions("misTargetSpecies"),
        defaultTargetSpeciesOption,
        availableWarehouses: availableWarehouses,
        data,
      });
    }
  }

  async getEntityOptions(optionsType) {
    let options = [];
    const optionsResponse = await this.getApiOptionsResponse(optionsType);
    if (optionsResponse?.successful && optionsResponse.data?.length > 0) {
      options = optionsResponse.data;
      if (optionsType === "ersCategory") {
        options = options.map((e) => {
          e.displayText = e.id + " " + e.displayText.replace("-", " ");
          return e;
        });
      }
      if (optionsType === "collector") {
        options = options.map((e) => {
          e.displayText = e.displayText === "Current User" ? "WS" : e.displayText;
          return e;
        });
      }
      if (optionsType === "collectionActivity") {
        options = options.map((e) => {
          e.displayText = e.displayText.replace(" - ", "-");
          return e;
        });
      }
    }
    return options;
  }

  async getApiOptionsResponse(optionsType) {
    let optionsResponse = "";
    switch (optionsType) {
      case "collector":
        optionsResponse = await WorkTaskAPI.GetCollectorOptions();
        break;
      case "collectionActivity":
        optionsResponse = await WorkTaskAPI.GetCollectionActivityOptions();
        break;
      case "collectionMethod":
        optionsResponse = await WorkTaskAPI.GetCollectionMethodOptions();
        break;
      case "orvBaitType":
        optionsResponse = await WorkTaskAPI.GetOrvBaitTypeOptions();
        break;
      case "fateOfTrap":
        optionsResponse = await WorkTaskAPI.GetFateOfTrapOptions();
        break;
      case "drugsAndVaccines":
        optionsResponse = await WorkTaskAPI.GetDrugOrVaccineOptions();
        break;
      case "otherSample":
        optionsResponse = await WorkTaskAPI.GetOtherSampleOptions();
        break;
      case "animalFate":
        optionsResponse = await WorkTaskAPI.GetAnimalFateOptions();
        break;
      case "euthanizationReason":
        optionsResponse = await WorkTaskAPI.GetEuthanizationReasonOptions();
        break;
      case "ersCategory":
        optionsResponse = await WorkTaskAPI.GetErsCategoryOptions();
        break;
      case "labsForBrainSample":
        optionsResponse = await WorkTaskAPI.GetLabsForBrainSampleOptions();
        break;
      case "brainstemConditions":
        optionsResponse = await WorkTaskAPI.GetSampleConditionOptions();
        break;
      case "rabiesVariants":
        optionsResponse = await WorkTaskAPI.GetRabiesVariantOptions();
        break;
      case "labsForTooth":
        optionsResponse = await WorkTaskAPI.GetLabsForToothOptions();
        break;
      case "labsForBlood":
        optionsResponse = await WorkTaskAPI.GetLabsForBloodOptions();
        break;
      case "interpretation":
        optionsResponse = await WorkTaskAPI.GetInterpretationOptions();
        break;
      case "rabiesVnaInterpretation":
        optionsResponse = await WorkTaskAPI.GetRabiesVNAInterpretationOptions();
        break;
      case "labResults":
        optionsResponse = await WorkTaskAPI.GetLabResultsPosNegIndOptions();
        break;
      case "yesNo":
        optionsResponse = await WorkTaskAPI.GetYesNoOptions();
        break;
      case "yesNoNotRecorded":
        optionsResponse = await WorkTaskAPI.GetYesNoNotRecordedOptions();
        break;
      case "yesNoNotTested":
        optionsResponse = await WorkTaskAPI.GetYesNoNotTestedOptions();
        break;
      case "yesNoNoCapture":
        optionsResponse = await WorkTaskAPI.GetYesNoNoCaptureOptions();
        break;
      case "sexOptions":
        optionsResponse = await WorkTaskAPI.GetSexOptions();
        break;
      case "ageOptions":
        optionsResponse = await WorkTaskAPI.GetRabiesAgeOptions();
        break;
      case "ritOptions":
        optionsResponse = await WorkTaskAPI.GetdRitOptions();
        break;
      case "misTargetSpecies":
        optionsResponse = await WorkTaskAPI.GetdMISTargetOptions();
        break;
      default:
        break;
    }

    return optionsResponse;
  }

  async getWarehousesData(userProfileId) {
    let warehousesData = [];
    if (userProfileId && this.props.data?.currentStateId) {
      const getWarehousesResponse = await InventoryAPI.GetMyWarehousesForStateByType(
        userProfileId,
        this.props.data.currentStateId,
        WarehouseTypes.Employee
      );
      if (getWarehousesResponse?.successful && getWarehousesResponse.data?.results?.length > 0) {
        warehousesData = getWarehousesResponse.data.results
          .filter((w) => w.isActive)
          ?.map((w) => ({ label: w.warehouseName, value: w.warehouseUniqueIdentifier }));
      }
    }
    return warehousesData;
  }

  async getAvailableDrugsAndVaccinesPerWarehouse(warehouseId) {
    let availableDrugsOrVaccines = [];
    if (warehouseId) {
      const warehouseDataResponse = await InventoryAPI.GetWarehouse(warehouseId);
      if (warehouseDataResponse?.successful && warehouseDataResponse.data?.warehouseInventory?.length > 0) {
        availableDrugsOrVaccines = warehouseDataResponse.data.warehouseInventory
          .filter(
            (d) =>
              d.methodCategory === "IE DRUGS" || d.methodCategory === "I/E DRUGS" || d.methodCategory === "BIOLOGICS"
          )
          ?.map((d) => ({ label: d.methodName, value: d.warehouseInventoryUniqueIdentifer, ...d }));
      }
    }
    return availableDrugsOrVaccines;
  }

  async getUnavailableAnimalIDs() {
    let unavailableIDs = [];
    if (this.props.data?.currentStateId && (this.state.data.sampleDate || this.props.data.workItemDate)) {
      const nrmpDataResponse = await WorkTaskAPI.NRMPStructuredSearch(
        this.getSearchNRMPBody(this.props.data.currentStateId),
        this.controller.signal
      );
      if (!nrmpDataResponse?.successful) {
        ErrorHandler.showErrorWithMessage(
          nrmpDataResponse?.message ? nrmpDataResponse.message : "Existing NRMP Data could not be retrieved."
        );
      } else if (nrmpDataResponse.data?.results?.length > 0) {
        unavailableIDs = nrmpDataResponse.data.results.map((sample) => sample.animalId);
      }
      if (this.controller.signal.aborted) {
        return [];
      }
    }

    return unavailableIDs;
  }

  getSearchNRMPBody(stateId) {
    const sampleDate = this.state.data.sampleDate || this.props.data.workItemDate;
    const dateObjectWithoutTime = new Date(
      new Date(sampleDate).toLocaleDateString("en-CA", { timeZone: "UTC" })
    ).toISOString();
    return {
      stateUniqueReference: stateId,
      dateNRMPStartedStart: dateObjectWithoutTime,
      dateNRMPStartedEnd: dateObjectWithoutTime,
      countyUniqueReference: null,
      townOrCity: null,
      userProfileWhoEnteredNRMP: null,
      speciesBeingTestedUniqueReference: null,
      waypointNumber: null,
      animalId: null,
      collectionActivityEnumId: null,
    };
  }

  handleSpecificChanges = (input) => {
    const { data, errors } = this.state;
    if (input.name === "orvNaive") {
      if (input.value === 1) {
        data.dateOfLastOrv = "";
        data.baitType = this.state.baitTypes?.find((b) => b.name === "nonenaive")?.id;
      } else if (data.baitType === this.state.baitTypes?.find((b) => b.name === "nonenaive")?.id) {
        data.baitType = "";
      }
      data.orvNaive = input.value;
      errors.orvNaive = "";
    } else if (input.name === "collector") {
      this.setState({ filteredCollectionMethod: this.filterCollectionMethodBasedOnCollector(input.value) });
      data.collector = input.value;
      errors.collector = "";
    } else if (input.name === "selectedCollectionMethod") {
      this.setState({ filteredFateTypes: this.filterFateTypesBasedOnCollectionMethod(input.value) }, () => {
        if (this.state.filteredFateTypes.length === 1) {
          data.selectedFateForWs = this.state.filteredFateTypes[0]?.id;
        }
      });
      data.selectedCollectionMethod = input.value;
      errors.selectedCollectionMethod = "";
    } else if (input.name === "selectedCollectionActivity") {
      if (input.value === 5) {
        data.orvNaive = 1;
        data.dateOfLastOrv = "";
        data.baitType = this.state.baitTypes?.find((b) => b.name === "nonenaive")?.id;
      }
      data.selectedCollectionActivity = input.value;
      errors.selectedCollectionActivity = "";
    } else if (input.name === "recaptureSelected") {
      if (input.value === 2) {
        data.processedLessThan30DaysAgo = 2;
      }
      data.recaptureSelected = input.value;
      errors.recaptureSelected = "";
    } else if (input.name === "rabiesBrainstemTest") {
      if (input.value === 1) {
        data.rabiesBrainLab = 3;
        data.rabiesBrainstemResults = 1;
      } else {
        data.rabiesBrainLab = "";
        data.rabiesBrainstemResults = "";
      }
      data.rabiesBrainstemTest = input.value;
      errors.rabiesBrainstemTest = "";
    } else if (input.name === "densityStudy") {
      data.densityStudy = input.value;
      errors.densityStudy = "";
      if (Number.parseInt(input.value) === 2) {
        errors.densityStudyId = "";
      }
    } else {
      data[input.name] = input.value;
      errors[input.name] = "";
    }

    this.setState({ data, errors });
  };

  doSubmit = async () => {
    if (!this.isFormDataValid()) {
      toast.warning("Please fill out all the required fields.");
      this.checkForInvalidData();
      return;
    } else {
      const unavailableIDs = await this.getUnavailableAnimalIDs();
      if (unavailableIDs?.length > 0 && this.state.data.animalId && unavailableIDs.includes(this.state.data.animalId)) {
        toast.warning(
          "The Animal ID entered was already used in another Rabies Data Sheet today. Please select a different one."
        );
        return;
      } else {
        const { currentRabiesCount } = this.state;
        const completedSampleForms = [...this.state.completedSampleForms];
        completedSampleForms[currentRabiesCount - 1] = this.getCompletedDataObject();

        if (currentRabiesCount < Number.parseInt(this.props.data?.rabiesSamplingQty)) {
          if (completedSampleForms.length > currentRabiesCount) {
            this.setState({ data: { ...completedSampleForms[currentRabiesCount] } });
          } else {
            this.clearData();
          }

          this.setState({ currentRabiesCount: currentRabiesCount + 1, completedSampleForms });
          this.messagesStart.scrollIntoView({ behavior: "auto" });
          return;
        } else {
          this.props.onSubmitSamples(JSON.parse(JSON.stringify(completedSampleForms)));
          this.clearData();
          this.props.onHide();
          this.setState({ currentRabiesCount: 1, isInitialConfiguration: true, completedSampleForms: [] });
        }
      }
    }
  };

  handlePreviousStepNavigation = () => {
    const { completedSampleForms } = this.state;
    const currentRabiesCount = this.state.currentRabiesCount - 1;
    completedSampleForms[currentRabiesCount] = this.getCompletedDataObject();
    const data = { ...completedSampleForms[currentRabiesCount - 1] };
    this.setState({ currentRabiesCount, data, completedSampleForms });
  };

  isFormDataValid() {
    const { data } = this.state;
    return (
      (data.sampleDate || this.props.data?.workItemDate) &&
      data.collector &&
      (data.otherCollector || Number.parseInt(data.collector) !== 4) &&
      data.selectedCollectionActivity &&
      data.selectedCollectionMethod &&
      data.densityStudy &&
      (data.densityStudyId || Number.parseInt(data.densityStudy) !== 1) &&
      (Number.parseInt(data.orvNaive) === 2 ? data.dateOfLastOrv : true) &&
      (data.baitType ||
        data.nrmpTargetSpecies?.value === 2 ||
        (!data.nrmpTargetSpecies && this.props.data?.isUnintentionalTake)) &&
      data.latLongRecorded &&
      ((data.latitude?.toString()?.split(".")[1]?.length >= 5 &&
        data.longitude?.toString()?.split(".")[1]?.length >= 5) ||
        Number.parseInt(data.latLongRecorded) !== 1) &&
      data.nrmpTargetSpecies &&
      (data.misTargetSpecies || !this.isMISTargetRequired()) &&
      (data.selectedSpecies?.value || this.props.data.speciesFromTake || !this.isSelectedSpeciesRequired()) &&
      (data.fateOfTrap || !this.isFateOfTrapRequired()) &&
      (data.recaptureSelected || !this.isRecaptureSelectionRequired()) &&
      (data.processedLessThan30DaysAgo || Number.parseInt(data.recaptureSelected) !== 1) &&
      (data.lactation || Number.parseInt(data.sex) !== 1) &&
      (Number.parseInt(data.weightRecorded) !== 1 || (data.weight && this.isWeightValid(data.weight))) &&
      this.areNRMPTargetFieldsValid() &&
      (data.handVaccinated ||
        (data.processedLessThan30DaysAgo === 1 &&
          !data.drugsAndVaccinesData.some(
            (item) =>
              item.ieDrugOrVaccine?.methodName?.toLowerCase().includes("imrab3") ||
              item.ieDrugOrVaccine?.methodName?.toLowerCase().includes("rabies vaccine")
          ))) &&
      !data.drugsAndVaccinesData?.some((d) => d.totalAmountError) &&
      data.selectedFateForWs &&
      (Number.parseInt(data.selectedFateForWs) === 5 ? data.otherFate : true) &&
      (data.euthanizedReason || Number.parseInt(data.selectedFateForWs) !== 2) &&
      (data.selectedErsCategory || !data.brainstemCheckbox) &&
      (data.rabiesBrainstemTest || !data.brainstemCheckbox) &&
      (data.rabiesBrainLab || Number.parseInt(data.rabiesBrainstemTest) !== 1) &&
      (Number.parseInt(data.rabiesBrainLab) === 3 && Number.parseInt(data.rabiesBrainstemTest) === 1
        ? data.antigenDistribution && data.stainingIntensity
        : true) &&
      (data.rabiesLabSpecimenNumber ||
        data.animalId ||
        Number.parseInt(data.rabiesBrainstemTest) !== 1 ||
        Number.parseInt(data.rabiesBrainLab) !== 3) &&
      (data.rabiesBrainstemCondition ||
        Number.parseInt(data.rabiesBrainstemTest) !== 1 ||
        Number.parseInt(data.rabiesBrainLab) !== 3) &&
      (data.rabiesBrainstemResults || Number.parseInt(data.rabiesBrainstemTest) !== 1) &&
      (data.rabiesVariant ||
        Number.parseInt(data.rabiesBrainstemResults) !== 1 ||
        Number.parseInt(data.rabiesBrainstemTest) !== 1) &&
      (data.rabiesTestComments ||
        data.noneCheckbox ||
        Number.parseInt(data.rabiesBrainstemTest) !== 1 ||
        Number.parseInt(data.rabiesBrainstemResults) !== 3) &&
      (data.toothLab || (!data.pm1Checkbox && !data.pm2Checkbox && !data.k9Checkbox && !data.jawCheckbox)) &&
      (data.age || Number.parseInt(data.ageRecorded) !== 1) &&
      (data.rabiesBloodLab || !data.rabiesCheckbox) &&
      (data.distemperVnaInterpretation ||
        !data.canineDistemperCheckbox ||
        data.noneCheckbox ||
        !data.distemperVnaDilution) &&
      (data.adenoVnaInterpretation ||
        !data.adenoVnaDilution ||
        data.noneCheckbox ||
        (!data.other3Checkbox && !data.other4Checkbox))
    );
  }

  checkForInvalidData() {
    const { data, errors } = this.state;
    let isBaitTypeRequired = false;
    if (data.nrmpTargetSpecies) {
      isBaitTypeRequired = data.nrmpTargetSpecies?.value === 1 ? true : false;
    } else {
      isBaitTypeRequired = this.state.defaultTargetSpeciesOption?.value === 1 ? true : false;
    }

    if (!(data.sampleDate || this.props.data?.workItemDate)) {
      errors.sampleDate = "A valid Date is required";
    }
    if (!data.collector) {
      errors.collector = "Collector is required";
    }
    if (Number.parseInt(data.collector) === 4 && !data.otherCollector) {
      errors.otherCollector = "Other Collector is required";
    }
    if (!data.selectedCollectionActivity) {
      errors.selectedCollectionActivity = "Collection Activity is required";
    }
    if (!data.selectedCollectionMethod) {
      errors.selectedCollectionMethod = "Collection Method is required";
    }
    if (!data.densityStudy) {
      errors.densityStudy = "Density Study is required";
    }
    if (!data.densityStudyId && Number.parseInt(data.densityStudy) === 1) {
      errors.densityStudyId = "Density Study ID is required";
    }
    if (Number.parseInt(data.orvNaive) === 2 && !data.dateOfLastOrv) {
      errors.dateOfLastOrv = "Date Of Last ORV is required";
    }
    if (!data.nrmpTargetSpecies) {
      errors.nrmpTargetSpecies = "NRMP Target Species is required";
    }
    if (isBaitTypeRequired && !data.baitType) {
      errors.baitType = "Bait Type is required";
    }
    if (!data.latLongRecorded) {
      errors.latLongRecorded = "Lat/Long Recorded? is required";
    }
    if (Number.parseInt(data.latLongRecorded) === 1) {
      if (!data.latitude) {
        errors.latitude = "Latitude is required";
      }
      if (!data.longitude) {
        errors.longitude = "Longitude is required";
      }
      if (data.latitude.split(".")[1].length < 5) {
        errors.latitude = "Please specify at least 5 decimal places for latitude";
      }
      if (data.latitude.split(".")[1].length < 5) {
        errors.latitude = "Please specify at least 5 decimal places for longitude";
      }
    }
    if (this.isMISTargetRequired() && !data.misTargetSpecies) {
      errors.misTargetSpecies = "MIS Target Species is required.";
    }
    if (this.isSelectedSpeciesRequired() && !(data.selectedSpecies?.value || this.props.data.speciesFromTake)) {
      errors.selectedSpecies = "Damage Agent is required.";
    }
    if (this.isRecaptureSelectionRequired() && !data.recaptureSelected) {
      errors.recaptureSelected = "Recapture option is required";
    }
    if (this.isProcessedLessThan30DaysAgorequired(data) && !data.processedLessThan30DaysAgo) {
      errors.processedLessThan30DaysAgo = "Selection required";
    }
    if (Number.parseInt(data.sex) === 1 && !data.lactation) {
      errors.lactation = "Lactation is required";
    }
    if (this.isWeightRecordedRequired() && !data.weightRecorded) {
      errors.weightRecorded = "Please select an option";
    }
    if (Number.parseInt(data.weightRecorded) === 1) {
      if (!data.weight) {
        errors.weight = "Please enter Weight";
      } else if (!this.isWeightValid(data.weight)) {
        errors.weight = "Weight should be a number in the range of 1-99 Kg.";
      }
    }
    if (this.areAnimalDetailsFieldsRequired()) {
      if (!data.sex) {
        errors.sex = "Sex is required";
      }
      if (!data.animalId) {
        errors.animalId = "Animal ID is required";
      }
      if (!data.relativeAge) {
        errors.relativeAge = "Relative Age is required";
      }
    }
    if (this.isFateOfTrapRequired() && !data.fateOfTrap) {
      errors.fateOfTrap = "Fate Of Trap is required";
    }
    if (this.isHandVaccinatedRequired() && !data.handVaccinated) {
      errors.handVaccinated = "Please select an option";
    }
    if (!data.selectedFateForWs) {
      errors.selectedFateForWs = "Please select an option";
    }
    if (Number.parseInt(data.selectedFateForWs) === 5 && !data.otherFate) {
      errors.otherFate = "Other Fate is required";
    }
    if (Number.parseInt(data.selectedFateForWs) === 2 && !data.euthanizedReason) {
      errors.euthanizedReason = "Euthanized Reason is required";
    }
    if (data.brainstemCheckbox) {
      if (!data.selectedErsCategory) {
        errors.selectedErsCategory = "Please select an option";
      }
      if (!data.rabiesBrainstemTest) {
        errors.rabiesBrainstemTest = "Please select an option";
      }
    }
    if (Number.parseInt(data.rabiesBrainstemTest) === 1 && !data.rabiesBrainLab) {
      errors.rabiesBrainLab = "Please select an option";
    }
    if (Number.parseInt(data.rabiesBrainLab) === 3 && Number.parseInt(data.rabiesBrainstemTest) === 1) {
      if (!data.antigenDistribution) {
        errors.antigenDistribution = "Please select an option";
      }
      if (!data.stainingIntensity) {
        errors.stainingIntensity = "Please select an option";
      }
    }
    if (Number.parseInt(data.rabiesBrainstemTest) === 1 && Number.parseInt(data.rabiesBrainLab) === 3) {
      if (!data.rabiesLabSpecimenNumber) {
        errors.rabiesLabSpecimenNumber = "Specimen Number is required";
      }
      if (!data.rabiesBrainstemCondition) {
        errors.rabiesBrainstemCondition = "Brainstem Condition is required";
      }
    }
    if (Number.parseInt(data.rabiesBrainstemTest) === 1 && !data.rabiesBrainstemResults) {
      errors.rabiesBrainstemResults = "Brainstem Results are required";
    }
    if (
      Number.parseInt(data.rabiesBrainstemTest) === 1 &&
      Number.parseInt(data.rabiesBrainstemResults) === 1 &&
      !data.rabiesVariant
    ) {
      errors.rabiesVariant = "Rabies Variant are required";
    }
    if (
      !data.noneCheckbox &&
      Number.parseInt(data.rabiesBrainstemTest) === 1 &&
      Number.parseInt(data.rabiesBrainstemResults) === 3 &&
      !data.rabiesTestComments
    ) {
      errors.rabiesTestComments = "Comments are required";
    }
    if (this.isToothLabRequired() && !data.toothLab) {
      errors.toothLab = "Tooth Lab is required";
    }
    if (Number.parseInt(data.ageRecorded) === 1 && !data.age) {
      errors.age = "Age is required";
    }
    if (data.rabiesCheckbox && !data.rabiesBloodLab) {
      errors.rabiesBloodLab = "Blood Lab is required";
    }
    if (
      data.canineDistemperCheckbox &&
      !data.noneCheckbox &&
      data.distemperVnaDilution &&
      !data.distemperVnaInterpretation
    ) {
      errors.distemperVnaInterpretation = "Distemper VNA Interpretation is required";
    }
    if (
      (data.other3Checkbox || data.other4Checkbox) &&
      !data.noneCheckbox &&
      data.adenoVnaDilution &&
      !data.adenoVnaInterpretation
    ) {
      errors.adenoVnaInterpretation = "Adeno VNA Interpretation is required";
    }

    this.setState(errors);
  }

  areAnimalDetailsFieldsRequired() {
    const { data } = this.state;
    return (
      (data.nrmpTargetSpecies?.value === 1 ||
        (!data.nrmpTargetSpecies && this.state.defaultTargetSpeciesOption?.value === 1)) &&
      data.processedLessThan30DaysAgo === 2
    );
  }

  isFateOfTrapRequired() {
    const { data } = this.state;
    return (
      data.nrmpTargetSpecies?.value === 3 ||
      (!data.nrmpTargetSpecies && this.state.defaultTargetSpeciesOption?.value === 3)
    );
  }

  isMISTargetRequired() {
    const { data } = this.state;
    return data.nrmpTargetSpecies?.value === 2 || (!data.nrmpTargetSpecies && this.props.data?.isUnintentionalTake);
  }

  isWeightRecordedRequired() {
    const { data } = this.state;
    return (
      data.processedLessThan30DaysAgo === 2 &&
      ((data.nrmpTargetSpecies && data.nrmpTargetSpecies.value === 1) ||
        (!data.nrmpTargetSpecies && this.state.defaultTargetSpeciesOption?.value === 1))
    );
  }

  isWeightValid(weight) {
    return Number.parseFloat(weight) > 0 && Number.parseFloat(weight) < 100;
  }

  isProcessedLessThan30DaysAgorequired(data) {
    return (
      Number.parseInt(data.recaptureSelected) === 1 &&
      (data.nrmpTargetSpecies?.value === 1 ||
        (!data.nrmpTargetSpecies && this.state.defaultTargetSpeciesOption?.value === 1))
    );
  }

  isSelectedSpeciesRequired() {
    const { data } = this.state;
    return (
      data.nrmpTargetSpecies?.value === 1 ||
      data.nrmpTargetSpecies?.value === 2 ||
      (!data.nrmpTargetSpecies && !this.props.data?.isUnintentionalTake)
    );
  }

  isHandVaccinatedRequired() {
    const { data } = this.state;
    return (
      data.processedLessThan30DaysAgo !== 1 ||
      data.drugsAndVaccinesData.some(
        (item) =>
          item.ieDrugOrVaccine?.methodName?.toLowerCase().includes("imrab3") ||
          item.ieDrugOrVaccine?.methodName?.toLowerCase().includes("rabies vaccine")
      )
    );
  }

  isToothLabRequired() {
    const { data } = this.state;
    return data.pm1Checkbox || data.pm2Checkbox || data.k9Checkbox || data.jawCheckbox;
  }

  isBloodLabAvailable() {
    const { data } = this.state;
    return (
      data.rabiesCheckbox ||
      data.canineDistemperCheckbox ||
      data.other3Checkbox ||
      data.other4Checkbox ||
      data.noneCheckbox
    );
  }

  isRecaptureSelectionRequired() {
    const { data } = this.state;
    return (
      data.nrmpTargetSpecies?.value === 1 ||
      (!data.nrmpTargetSpecies && this.state.defaultTargetSpeciesOption?.value === 1)
    );
  }

  areNRMPTargetFieldsValid() {
    const { data } = this.state;
    let isValid = true;
    switch (data.nrmpTargetSpecies.value) {
      case 1:
        if (data.processedLessThan30DaysAgo === 2) {
          isValid = data.sex && data.animalId && data.relativeAge && data.weightRecorded;
        }
        break;

      case 3:
        isValid = data.nrmpTargetSpecies && data.fateOfTrap;
        break;

      default:
        break;
    }
    return isValid;
  }

  getCompletedDataObject() {
    const data = { ...this.state.data };
    if (!data.sampleDate) {
      data.sampleDate = this.props.data?.workItemDate
        ? new Date(this.props.data.workItemDate).toLocaleDateString("en-CA")
        : new Date().toLocaleDateString("en-CA");
    }

    const defaultTargetSpeciesOption = this.props.data?.isUnintentionalTake
      ? this.state.nrmpTargetSpeciesOptions.find((option) => option.value === 2)
      : this.state.nrmpTargetSpeciesOptions.find((option) => option.value === 1);
    if (!data.nrmpTargetSpecies) {
      data.nrmpTargetSpecies = defaultTargetSpeciesOption;
    }
    if (!data.misTargetSpecies) {
      data.misTargetSpecies = defaultTargetSpeciesOption;
    }

    if (!data.selectedSpecies) {
      data.selectedSpecies = this.props.data.speciesFromTake;
    }

    return data;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
    this.setState({ isInitialConfiguration: true, completedSampleForms: [], currentRabiesCount: 1 });
  };

  clearData() {
    const data = {
      sampleDate: "",
      town: "",
      collector: 1,
      wsName: "",
      otherCollector: "",
      selectedCollectionActivity: "",
      selectedCollectionMethod: "",
      densityStudyId: "",
      dateOfLastOrv: "",
      orvNaive: "",
      baitType: "",
      otherBait: "",
      waypointNumber: "",
      latLongRecorded: "",
      latitude: "",
      longitude: "",
      nrmpTargetSpecies: "",
      misTargetSpecies: "",
      selectedSpecies: "",
      fateOfTrap: "",
      recaptureSelected: "",
      processedLessThan30DaysAgo: "",
      drugsAndVaccinesData: [
        { ieDrugOrVaccine: "", vialNumber: "", totalAmount: "", warehouse: "", availableDrugsOrVaccines: [] },
      ],
      animalId: "",
      sex: "",
      lactation: "",
      relativeAge: "",
      weightRecorded: "",
      weight: "",
      handVaccinated: "",
      selectedOtherSample: "",
      bloodCheckbox: false,
      pm1Checkbox: false,
      pm2Checkbox: false,
      k9Checkbox: false,
      jawCheckbox: false,
      dnaCheckbox: false,
      brainstemCheckbox: false,
      otherCheckbox: false,
      selectedFateForWs: "",
      otherFate: "",
      euthanizedReason: "",
      comments: "",
      freeText: "",
      selectedErsCategory: "",
      rabiesCheckbox: false,
      canineDistemperCheckbox: false,
      other3Checkbox: false,
      other4Checkbox: false,
      noneCheckbox: false,
      rabiesBrainstemTest: "",
      rabiesBrainLab: "",
      rabiesBrainTestDate: "",
      rabiesLabSpecimenNumber: "",
      rabiesBrainstemCondition: "",
      stainingIntensity: "",
      antigenDistribution: "",
      rabiesBrainstemResults: "",
      rabiesVariant: "",
      rabiesTestComments: "",
      toothLab: "",
      age: "",
      ageRecorded: "",
      ttccPresent: "",
      ageTtccComments: "",
      rabiesBloodLab: "",
      rabiesVnaIu: "",
      vnaInterpretation: "",
      distemperVnaInterpretation: "",
      distemperVnaDilution: "",
      distemperVnaComments: "",
      adenoVnaDilution: "",
      adenoVnaInterpretation: "",
    };

    this.setState({ data, errors: {} });
  }

  handleFieldsChanges = ({ currentTarget: input }) => {
    const { data, errors } = this.state;
    if (
      (input.name === "dateOfLastOrv" || input.name === "rabiesBrainTestDate") &&
      Date.parse(input.value) > new Date()
    ) {
      toast.warning("Entered date must not be in the future.");
    } else if (
      (input.name === "comments" ||
        input.name === "freeText" ||
        input.name === "rabiesTestComments" ||
        input.name === "ageTtccComments" ||
        input.name === "vnaComments" ||
        input.name === "distemperVnaComments" ||
        input.name === "adenoVnaComments") &&
      input.value.length > 250
    ) {
      errors[input.name] = "Comments should not exceed 250 characters.";
      this.setState({ errors });
    } else if (input.name === "distemperVnaDilution") {
      if (!input.value) {
        errors.distemperVnaInterpretation = "";
      }
      data[input.name] = input.value;
      this.setState({ data });
    } else if (input.name === "adenoVnaDilution") {
      if (!input.value) {
        errors.adenoVnaInterpretation = "";
      }
      data[input.name] = input.value;
      this.setState({ data });
    } else if (
      (input.name === "rabiesVnaIu" || input.name === "distemperVnaDilution" || input.name === "adenoVnaDilution") &&
      input.value.length > 10
    ) {
      errors[input.name] = "Value should not exceed 10 characters.";
      this.setState({ errors });
    } else if (input.name === "age" && (Number.parseInt(input.value) < 0 || Number.parseInt(input.value) > 30)) {
      errors[input.name] = "Age should be in the range of 0-30 years.";
      this.setState({ errors });
    } else if (input.name === "latitude" || input.name === "longitude") {
      if (input.value.includes(".")) {
        const decimals = input.value.split(".")[1];
        if (decimals.length < 5) {
          input.name === "latitude"
            ? (errors.latitude = "Please specify at least 5 decimal places for latitude")
            : (errors.longitude = "Please specify at least 5 decimal places for longitude");
        } else {
          input.name === "latitude" ? (errors.latitude = "") : (errors.longitude = "");
        }
        data[input.name] = input.value;
      } else {
        input.name === "latitude"
          ? (errors.latitude = "Please specify at least 5 decimal places for latitude")
          : (errors.longitude = "Please specify at least 5 decimal places for longitude");
        data[input.name] = input.value;
      }

      if (input.name === "longitude" && Number.parseFloat(data[input.name]) > 0) {
        data[input.name] = -1 * data[input.name];
      }

      this.setState({ data });
    } else if (input.name === "weight" && (Number.parseFloat(input.value) < 1 || Number.parseFloat(input.value) > 99)) {
      data[input.name] = input.value;
      errors[input.name] = "Weight should be a number in the range of 1-99 Kg.";
      this.setState({ data, errors });
    } else {
      if (errors[input.name]) {
        errors[input.name] = "";
      }

      data[input.name] = input.value;
      this.setState({ data, errors });
    }
  };

  handleWsSearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.props.data.currentStateId,
    };
    const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText(searchObj);
    let outputContacts = [];
    if (contactsData?.data?.results?.length > 0) {
      let results = contactsData.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
              wsUserUniqueIdentifier: user.wsUserUniqueIdentifier,
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    return outputContacts;
  };

  handleSpeciesSearch = async (searchValue) => {
    let speciesResult = [];

    const speciesData = await ReferenceFileAPI.FilterSpecies(this.props.data.currentStateId, {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    });

    if (speciesData?.data?.results) {
      speciesResult = speciesData.data.results.map((result) => ({
        value: result.speciesUniqueIdentifier,
        label: result.name,
      }));
    }

    return speciesResult;
  };

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    if (fieldName === "nrmpTargetSpecies") {
      if (e?.value === 3) {
        data.misTargetSpecies = "";
        data.selectedSpecies = {
          label: "No Damage Agents",
          value: null,
        };
      } else {
        data.fateOfTrap = this.state.fateOfTrapOptions?.find((o) => o.name === "animalcaptured")?.id;
      }
    }
    if (e?.value) {
      delete errors[fieldName];
    }
    this.setState({ data, errors });
  };

  handleDrugsAndVaccinesChanges = ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    const fieldName = controlId[0];
    const index = controlId[1];

    const data = JSON.parse(JSON.stringify(this.state.data));
    data.drugsAndVaccinesData[index][fieldName] = input.value;

    if (fieldName === "totalAmount") {
      const amount = Number.parseFloat(input.value);
      if (amount < 0 || amount >= 5) {
        data.drugsAndVaccinesData[index].totalAmountError = "Drug amount values should be in the range of 0-5.";
      } else {
        delete data.drugsAndVaccinesData[index].totalAmountError;
      }
    }

    if (fieldName === "vialNumber" && this.isDuplicatedVaccineEntry(data, index)) {
      toast.warning(
        "There is already an entry for this combination of drug/warehouse/vial#. Please enter a different value or update the existing entry instead."
      );
    } else {
      this.setState({ data });
    }
  };

  handleDrugsAndVaccinesSelectChanges = (input, index) => {
    const data = JSON.parse(JSON.stringify(this.state.data));
    data.drugsAndVaccinesData[index]["ieDrugOrVaccine"] = input;

    if (this.isDuplicatedVaccineEntry(data, index)) {
      toast.warning(
        "There is already an entry for this combination of drug/warehouse/vial#. Please select a different value or update the existing entry instead."
      );
    } else {
      this.setState({ data });
    }
  };

  handleDrugsAndVaccinesWarehouseSelection = async (input, index) => {
    if (this.state.data.drugsAndVaccinesData[index].warehouse?.value !== input.value) {
      const data = JSON.parse(JSON.stringify(this.state.data));
      data.drugsAndVaccinesData[index]["warehouse"] = input;
      data.drugsAndVaccinesData[index]["availableDrugsOrVaccines"] =
        await this.getAvailableDrugsAndVaccinesPerWarehouse(input.value);
      data.drugsAndVaccinesData[index]["ieDrugOrVaccine"] = "";

      if (this.isDuplicatedVaccineEntry(data, index)) {
        toast.warning(
          "There is already an entry for this combination of drug/warehouse/vial#. Please select a different value or update the existing entry instead."
        );
      } else {
        this.setState({ data });
      }
    }
  };

  handleCheckboxChanges = ({ currentTarget: input }) => {
    const { data, errors } = this.state;
    data[input.name] = !data[input.name];
    if (input.name === "noneCheckbox" && data[input.name]) {
      data.rabiesBloodLab = 4;
    } else if (input.name === "brainstemCheckbox") {
      data.brainstemCheckbox ? (data.rabiesBrainstemTest = 3) : (data.selectedErsCategory = "");
    } else if (input.name === "canineDistemperCheckbox" && !data.canineDistemperCheckbox) {
      errors.distemperVnaInterpretation = "";
    } else if (
      (input.name === "other3Checkbox" || input.name === "other4Checkbox") &&
      !data.other3Checkbox &&
      !data.other4Checkbox
    ) {
      errors.adenoVnaInterpretation = "";
    }
    this.setState({ data, errors });
  };

  handleExtraDrug = async () => {
    const { data, availableWarehouses } = this.state;
    const defaultWarehouse = availableWarehouses?.length === 1 ? availableWarehouses[0] : "";
    data.drugsAndVaccinesData.push({
      ieDrugOrVaccine: "",
      vialNumber: "",
      totalAmount: "",
      warehouse: defaultWarehouse,
      availableDrugsOrVaccines: defaultWarehouse
        ? await this.getAvailableDrugsAndVaccinesPerWarehouse(defaultWarehouse.value)
        : [],
    });
    this.setState({ data });
  };

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#e9ecef" : styles.backgroundColor,
      border: isDisabled ? "1px solid #ced4da" : styles.border,
    }),
    placeholder: (styles) => ({ ...styles, fontSize: "14px", fontStyle: "normal !important" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
  };

  isDuplicatedVaccineEntry(data, index) {
    return this.state.data.drugsAndVaccinesData.some(
      (d) =>
        d.warehouse?.value === data.drugsAndVaccinesData[index].warehouse.value &&
        d.ieDrugOrVaccine?.value === data.drugsAndVaccinesData[index].ieDrugOrVaccine.value &&
        d.vialNumber === data.drugsAndVaccinesData[index].vialNumber
    );
  }

  renderRabiesGeneralSection() {
    const { data, errors } = this.state;

    let isBaitTypeRequired = false;
    if (data.nrmpTargetSpecies) {
      isBaitTypeRequired = data.nrmpTargetSpecies?.value === 1 ? true : false;
    } else {
      isBaitTypeRequired = this.state.defaultTargetSpeciesOption?.value === 1 ? true : false;
    }

    return (
      <React.Fragment>
        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Date<span className={globalStyles.asterisk508}> *</span>
              </Form.Label>
              <Form.Control
                name="sampleDate"
                type="date"
                placeholder="Enter Date"
                value={
                  this.state.data?.sampleDate
                    ? this.state.data.sampleDate
                    : new Date(this.props.data?.workItemDate).toLocaleDateString("en-CA")
                }
                className={globalStyles.formData}
                onChange={this.handleFieldsChanges}
                isInvalid={errors.sampleDate}
              />
              <Form.Control.Feedback type="invalid">{errors.sampleDate}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                State<span className={globalStyles.asterisk508}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={this.props.data?.propertyStateName}
                className={globalStyles.formData}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                County<span className={globalStyles.asterisk508}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={this.props.data?.propertyCounty}
                className={globalStyles.formData}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Town</Form.Label>
              <Form.Control
                name="town"
                type="text"
                className={globalStyles.formData}
                value={this.state.data.town ? this.state.data.town : this.props.data?.town}
                onChange={this.handleFieldsChanges}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {this.renderSelect("collector", "Collector", this.state.collectorOptions, "Select", "required")}
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>WS Name</Form.Label>
              <AsyncSelect
                aria-label="WS Name Search"
                value={data.wsName}
                openMenuOnClick={false}
                placeholder="Search for WS"
                styles={this.customSelectStyles}
                loadOptions={this.handleWsSearch}
                onChange={(e) => this.handleSelectChanges(e, "wsName")}
                components={{ DropdownIndicator, Option }}
              />
            </Form.Group>
          </Col>

          {Number.parseInt(data.collector) === 4 && (
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>
                  Other Collector<span className={globalStyles.asterisk508}> *</span>
                </Form.Label>
                <AsyncSelect
                  aria-label="Other Collector Search"
                  value={data.otherCollector}
                  openMenuOnClick={false}
                  placeholder="Search for Collector"
                  styles={this.customSelectStyles}
                  loadOptions={this.handleWsSearch}
                  onChange={(e) => this.handleSelectChanges(e, "otherCollector")}
                  components={{ DropdownIndicator, Option }}
                  className={errors.otherCollector ? "is-invalid" : ""}
                />
                <span className="invalid-feedback">{errors.otherCollector}</span>
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            {this.renderSelect(
              "selectedCollectionActivity",
              "Collection Activity",
              this.state.collectionActivity,
              "Select",
              "required"
            )}
          </Col>
          <Col>
            {this.renderSelect(
              "selectedCollectionMethod",
              "Collection Method",
              this.state.filteredCollectionMethod,
              "Select",
              "required"
            )}
          </Col>
        </Row>
        <Row>
          <Col>{this.renderSelect("densityStudy", "Density Study", this.state.yesNoOptions, "Select", "required")}</Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Density Study ID
                {Number.parseInt(data.densityStudy) === 1 && <span className={globalStyles.asterisk508}> *</span>}
              </Form.Label>
              <Form.Control
                name="densityStudyId"
                placeholder="Enter Density ID"
                type="text"
                className={globalStyles.formData}
                value={Number.parseInt(data.densityStudy) === 1 ? data.densityStudyId : ""}
                onChange={this.handleFieldsChanges}
                readOnly={Number.parseInt(data.densityStudy) !== 1}
                isInvalid={errors.densityStudyId}
              />
              <Form.Control.Feedback type="invalid">{errors.densityStudyId}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Date of Last ORV
                <span className={globalStyles.asterisk508} hidden={Number.parseInt(data.orvNaive) !== 2}>
                  {" "}
                  *
                </span>
              </Form.Label>
              <Form.Control
                name="dateOfLastOrv"
                placeholder="Enter Date of Last ORV"
                type="date"
                className={globalStyles.formData}
                value={data.dateOfLastOrv}
                onChange={this.handleFieldsChanges}
                readOnly={Number.parseInt(data.orvNaive) === 1}
                isInvalid={errors.dateOfLastOrv}
              />
              <Form.Control.Feedback type="invalid">{errors.dateOfLastOrv}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>{this.renderSelect("orvNaive", "ORV Naive", this.state.yesNoOptions, "Select")}</Col>
          <Col>
            {this.renderSelect(
              "baitType",
              "ORV Bait Type",
              Number.parseInt(data.orvNaive) !== 2
                ? this.state.baitTypes
                : this.state.baitTypes.filter((b) => b.name !== "nonenaive"),
              "Select",
              isBaitTypeRequired ? "required" : ""
            )}
          </Col>
          <Col>
            {Number.parseInt(data.baitType) === 6 &&
              this.renderInput("otherBait", "Other Bait", "text", "Enter Other Bait")}
          </Col>
        </Row>
        <Row>
          <Col>{this.renderInput("waypointNumber", "Waypoint #", "text", "Enter Waypoint No.")}</Col>
          <Col>
            {this.renderSelect("latLongRecorded", "Lat/Long Recorded?", this.state.yesNoOptions, "Select", "required")}
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Latitude
                {Number.parseInt(data.latLongRecorded) === 1 ? (
                  <span className={globalStyles.asterisk508}> *</span>
                ) : (
                  ""
                )}
              </Form.Label>
              <Form.Control
                name="latitude"
                placeholder="Enter latitude"
                type="number"
                step="0.00001"
                className={globalStyles.formData}
                value={this.state.data.latitude}
                onChange={this.handleFieldsChanges}
                readOnly={Number.parseInt(data.latLongRecorded) !== 1}
                isInvalid={errors.latitude}
              />
              <Form.Control.Feedback type="invalid">{errors.latitude}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Longitude
                {Number.parseInt(data.latLongRecorded) === 1 ? (
                  <span className={globalStyles.asterisk508}> *</span>
                ) : (
                  ""
                )}
              </Form.Label>
              <Form.Control
                name="longitude"
                placeholder="Enter longitude"
                type="number"
                step="0.00001"
                className={globalStyles.formData}
                value={this.state.data.longitude}
                onChange={this.handleFieldsChanges}
                readOnly={Number.parseInt(data.latLongRecorded) !== 1}
                isInvalid={errors.longitude}
              />
              <Form.Control.Feedback type="invalid">{errors.longitude}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                NRMP Target<span className={globalStyles.asterisk508}> *</span>
              </Form.Label>
              <Select
                aria-label="NRMP Target Damage Agents Selection"
                value={data.nrmpTargetSpecies}
                placeholder="Select"
                styles={{
                  ...this.customSelectStyles,
                  control: (styles) => ({ ...styles, borderColor: errors.nrmpTargetSpecies ? "#dc3545" : "#ced4da" }),
                }}
                onChange={(e) => this.handleSelectChanges(e, "nrmpTargetSpecies")}
                options={this.state.nrmpTargetSpeciesOptions}
                className={errors.nrmpTargetSpecies ? "is-invalid" : ""}
              />
              <span className="invalid-feedback">{errors.nrmpTargetSpecies}</span>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                MIS Target
                {this.isMISTargetRequired() && <span className={globalStyles.asterisk508}> *</span>}
              </Form.Label>
              <Select
                aria-label="MIS Target Species Selection"
                value={data.misTargetSpecies}
                placeholder="Select"
                styles={{
                  ...this.customSelectStyles,
                  control: (styles) => ({ ...styles, borderColor: errors.misTargetSpecies ? "#dc3545" : "#ced4da" }),
                }}
                onChange={(e) => this.handleSelectChanges(e, "misTargetSpecies")}
                options={this.state.misTargetOptions.map((option) => {
                  return { value: option.id, label: option.displayText };
                })}
                isDisabled={data.nrmpTargetSpecies?.value === 3}
                className={errors.misTargetSpecies ? "is-invalid" : ""}
              />
              <span className="invalid-feedback">{errors.misTargetSpecies}</span>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Damage Agent
                {this.isSelectedSpeciesRequired() && <span className={globalStyles.asterisk508}> *</span>}
              </Form.Label>
              <AsyncSelect
                aria-label="Damage Agent Selection"
                value={!data.selectedSpecies ? this.props.data.speciesFromTake : data.selectedSpecies}
                placeholder="Select"
                styles={{
                  ...this.customSelectStyles,
                  control: (styles) => ({ ...styles, borderColor: errors.selectedSpecies ? "#dc3545" : "#ced4da" }),
                }}
                onChange={(e) => this.handleSelectChanges(e, "selectedSpecies")}
                loadOptions={this.handleSpeciesSearch}
                className={errors.selectedSpecies ? "is-invalid" : ""}
                components={{ DropdownIndicator }}
              />
              <span className="invalid-feedback">{errors.selectedSpecies}</span>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            {this.renderSelect(
              "fateOfTrap",
              "Fate Of Trap",
              this.state.fateOfTrapOptions,
              "Select",
              this.isFateOfTrapRequired() ? "required" : ""
            )}
          </Col>
          <Col md={3}>
            {this.renderSelect(
              "recaptureSelected",
              "Recapture?",
              this.state.yesNoOptions,
              "Select",
              this.isRecaptureSelectionRequired() ? "required" : ""
            )}
          </Col>
          <Col md={3}>
            {Number.parseInt(data.recaptureSelected) !== 1 && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>{"Processed <30 Days Ago?"}</Form.Label>
                <Form.Control
                  name="processedLessThan30DaysAgo"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  value={Number.parseInt(data.recaptureSelected) === 2 ? this.state.yesNoOptions[1]?.displayText : ""}
                  readOnly
                />
              </Form.Group>
            )}
            {Number.parseInt(data.recaptureSelected) === 1 &&
              this.renderSelect(
                "processedLessThan30DaysAgo",
                "Processed <30 Days Ago?",
                this.state.yesNoOptions,
                "Select",
                (data.nrmpTargetSpecies && data.nrmpTargetSpecies.value === 1) ||
                  (!data.nrmpTargetSpecies && this.state.defaultTargetSpeciesOption?.value === 1)
                  ? "required"
                  : ""
              )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderDrugsAndVaccinesSection() {
    const { data, errors } = this.state;
    return (
      <React.Fragment>
        <Row className="mb-3">
          <Col>
            <span className={styles.samplesModalFormSubTitles}>Drugs and Vaccines</span>
          </Col>
        </Row>
        {data.drugsAndVaccinesData.map((item, index) => {
          return (
            <Row key={index}>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Warehouse</Form.Label>
                  <Select
                    aria-label="Warehouse Selection for Drug and/or Vaccine"
                    value={item.warehouse}
                    placeholder="Select"
                    styles={this.customSelectStyles}
                    onChange={(e) => this.handleDrugsAndVaccinesWarehouseSelection(e, index)}
                    options={this.state.availableWarehouses}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>I/E Drug or Vaccine</Form.Label>
                  <Select
                    aria-label="I/E Drug or Vaccine Selection"
                    value={item.ieDrugOrVaccine}
                    placeholder="Select"
                    styles={this.customSelectStyles}
                    onChange={(e) => this.handleDrugsAndVaccinesSelectChanges(e, index)}
                    options={item.availableDrugsOrVaccines}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>UOM</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="UOM"
                    value={
                      item.ieDrugOrVaccine?.methodUOM
                        ? UtilityFunctions.getDisplayTextFromFieldObject(item.ieDrugOrVaccine.methodUOM)
                        : ""
                    }
                    onChange={this.handleDrugsAndVaccinesChanges}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"vialNumber" + "-" + index}>
                  <Form.Label className={globalStyles.formLabel}>Vial#</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Vial No."
                    value={item.vialNumber}
                    onChange={this.handleDrugsAndVaccinesChanges}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"totalAmount" + "-" + index}>
                  <Form.Label className={globalStyles.formLabel}>Total Amount (cc)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Total Amount"
                    value={item.totalAmount}
                    onChange={this.handleDrugsAndVaccinesChanges}
                    isInvalid={item.totalAmountError}
                  />
                  <Form.Control.Feedback type="invalid">{item.totalAmountError}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="pt-4">
                {data.drugsAndVaccinesData.length === index + 1 && (
                  <Button variant="link" className={globalStyles.formDataLinks} onClick={this.handleExtraDrug}>
                    + Add another drug
                  </Button>
                )}
              </Col>
            </Row>
          );
        })}
        {this.renderDashedSeparatorRow()}
        <Row>
          <Col>
            {this.renderInput(
              "animalId",
              "ID #",
              "text",
              "Enter Id",
              this.areAnimalDetailsFieldsRequired() ? "required" : ""
            )}
          </Col>
          <Col>
            {this.renderSelect(
              "sex",
              "Sex",
              this.state.sexOptions,
              "Select",
              this.areAnimalDetailsFieldsRequired() ? "required" : ""
            )}
          </Col>
          <Col>
            {Number.parseInt(data.sex) === 1 &&
              this.renderSelect(
                "lactation",
                "Lactation?",
                this.state.yesNoNotRecordedOptions,
                "Select",
                `${Number.parseInt(data.sex) === 1 ? "required" : ""}`
              )}
            {Number.parseInt(data.sex) !== 1 && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Lactation?</Form.Label>
                <Form.Control
                  name="lactation"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.renderSelect(
              "relativeAge",
              "Relative Age",
              this.state.ageOptions,
              "Select",
              this.areAnimalDetailsFieldsRequired() ? "required" : ""
            )}
          </Col>
          <Col>
            {this.renderSelect(
              "weightRecorded",
              "Weight Recorded",
              this.state.yesNoOptions,
              "Select",
              data.processedLessThan30DaysAgo === 2 &&
                ((data.nrmpTargetSpecies && data.nrmpTargetSpecies.value === 1) ||
                  (!data.nrmpTargetSpecies && this.state.defaultTargetSpeciesOption?.value === 1))
                ? "required"
                : ""
            )}
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Weight (Kg)
                {Number.parseFloat(data.weightRecorded) === 1 && <span className={globalStyles.asterisk508}> *</span>}
              </Form.Label>
              <Form.Control
                name="weight"
                placeholder="Enter Weight"
                type="number"
                min={1}
                max={99}
                step={1}
                className={globalStyles.formData}
                value={Number.parseInt(data.weightRecorded) === 1 ? parseFloat(data.weight) : ""}
                onChange={this.handleFieldsChanges}
                readOnly={Number.parseInt(data.weightRecorded) !== 1}
                isInvalid={errors.weight}
              />
              <Form.Control.Feedback type="invalid">{errors.weight}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            {this.renderSelect(
              "handVaccinated",
              "Hand Vaccinated",
              this.state.yesNoNotRecordedOptions,
              "Select",
              this.isHandVaccinatedRequired() ? "required" : ""
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  filterFateTypesBasedOnCollectionMethod(selectedCollectionMethod) {
    let { fateTypes } = this.state;
    let newfilteredFateTypes = [];
    if (selectedCollectionMethod) {
      switch (selectedCollectionMethod) {
        case 1:
        case 3:
        case 4:
          newfilteredFateTypes = fateTypes.filter((fate) => [1, 2, 3, 5, 6].includes(fate.id));
          break;
        case 2:
          newfilteredFateTypes = fateTypes.filter((fate) => fate.id === 2);
          break;
        case 5:
        case 6:
          newfilteredFateTypes = fateTypes.filter((fate) => fate.id === 7);
          break;
        case 7:
          newfilteredFateTypes = fateTypes.filter((fate) => fate.id === 3);
          break;
        case 8:
          newfilteredFateTypes = fateTypes.filter((fate) => fate.id === 8);
          break;
        default:
          newfilteredFateTypes = fateTypes;
          break;
      }
    } else {
      newfilteredFateTypes = fateTypes;
    }
    return newfilteredFateTypes;
  }

  filterCollectionMethodBasedOnCollector(selectedCollector) {
    let { collectionMethod } = this.state;
    let newfilteredCollectionMethods = [];
    if ([2, 3, 4].includes(selectedCollector)) {
      newfilteredCollectionMethods = collectionMethod.filter((method) => method.id === 5 || method.id === 6);
    } else {
      newfilteredCollectionMethods = collectionMethod;
    }
    return newfilteredCollectionMethods;
  }

  renderSamplesSection() {
    const { data, errors } = this.state;

    return (
      <React.Fragment>
        <Row className="mt-4">
          <Col>
            <span className={styles.samplesModalFormSubTitles}>Samples</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className={styles.samplesModalRedComments}>(Check all that apply)</span>
          </Col>
        </Row>
        <div className="px-3">
          <Row style={{ backgroundColor: "#E1E1E1" }}>
            <Col className="pt-3" md={8}>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Blood"
                    name="bloodCheckbox"
                    type="checkbox"
                    id="bloodCheckbox"
                    value={data.bloodCheckbox}
                    onChange={this.handleCheckboxChanges}
                    checked={data.bloodCheckbox}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="PM1"
                    name="pm1Checkbox"
                    type="checkbox"
                    id="pm1Checkbox"
                    value={data.pm1Checkbox}
                    onChange={this.handleCheckboxChanges}
                    checked={data.pm1Checkbox}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="PM2"
                    name="pm2Checkbox"
                    type="checkbox"
                    id="pm2Checkbox"
                    value={data.pm2Checkbox}
                    onChange={this.handleCheckboxChanges}
                    checked={data.pm2Checkbox}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="K-9"
                    name="k9Checkbox"
                    type="checkbox"
                    id="k9Checkbox"
                    value={data.k9Checkbox}
                    onChange={this.handleCheckboxChanges}
                    checked={data.k9Checkbox}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Jaw"
                    name="jawCheckbox"
                    type="checkbox"
                    id="jawCheckbox"
                    value={data.jawCheckbox}
                    onChange={this.handleCheckboxChanges}
                    checked={data.jawCheckbox}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="DNA"
                    name="dnaCheckbox"
                    type="checkbox"
                    id="dnaCheckbox"
                    value={data.dnaCheckbox}
                    onChange={this.handleCheckboxChanges}
                    checked={data.dnaCheckbox}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Brainstem"
                    name="brainstemCheckbox"
                    type="checkbox"
                    id="pm1ChebrainstemCheckboxckbox"
                    value={data.brainstemCheckbox}
                    onChange={this.handleCheckboxChanges}
                    checked={data.brainstemCheckbox}
                  />
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Other"
                    name="otherCheckbox"
                    type="checkbox"
                    id="otherCheckbox"
                    value={data.otherCheckbox}
                    onChange={this.handleCheckboxChanges}
                    checked={data.otherCheckbox}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="pl-0">
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Other Sample</Form.Label>
                {data.otherCheckbox && (
                  <Select
                    aria-label="Other Sample Selection"
                    value={data.selectedOtherSample}
                    placeholder="Select"
                    styles={this.customSelectStyles}
                    onChange={(e) => this.handleSelectChanges(e, "selectedOtherSample")}
                    options={this.state.otherSampleTypes}
                  />
                )}
                {!data.otherCheckbox && (
                  <Form.Control
                    name="selectedOtherSample"
                    placeholder="Select"
                    type="text"
                    className={globalStyles.formData}
                    readOnly
                  />
                )}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Row className="mt-3">
          <Col md={3}>
            {this.renderSelect(
              "selectedFateForWs",
              "Fate (WS Only)",
              this.state.filteredFateTypes,
              "Select",
              "required"
            )}
          </Col>
          <Col md={3}>
            {this.renderInput(
              "otherFate",
              "Other Fate",
              "text",
              "Enter other fate",
              `${Number.parseInt(data.selectedFateForWs) !== 5 ? "readOnly" : "required"}`
            )}
          </Col>
          <Col>
            {Number.parseInt(data.selectedFateForWs) === 2 &&
              this.renderSelect(
                "euthanizedReason",
                "If Euthanized by WS, why?",
                this.state.euthanizedByWsReasons,
                "Select",
                "required"
              )}
            {Number.parseInt(data.selectedFateForWs) !== 2 && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>If Euthanized by WS, why?</Form.Label>
                <Form.Control
                  name="euthanizedReason"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Comments</Form.Label>
              <Form.Control
                name="comments"
                placeholder="Enter Comments"
                type="text"
                as="textarea"
                rows={3}
                className={globalStyles.formData}
                value={data.comments}
                onChange={this.handleFieldsChanges}
                isInvalid={errors.comments}
              />
              <Form.Control.Feedback type="invalid">{errors.comments}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Free Text</Form.Label>
              <Form.Control
                name="freeText"
                placeholder="Enter Free Text"
                type="text"
                as="textarea"
                rows={3}
                className={globalStyles.formData}
                value={data.freeText}
                onChange={this.handleFieldsChanges}
                isInvalid={errors.freeText}
              />
              <Form.Control.Feedback type="invalid">{errors.freeText}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row hidden={!data.brainstemCheckbox}>
          <Col>
            {this.renderSelect(
              "selectedErsCategory",
              "ERS Category",
              this.state.ersCategoryOptions,
              "Select",
              data.brainstemCheckbox ? "required" : ""
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderLaboratoryProcessingSection() {
    const { data, errors } = this.state;
    let rabiesLabExtraProperties = "";
    if (Number.parseInt(data.rabiesBrainstemTest) === 1) {
      if (Number.parseInt(data.rabiesBrainLab) === 3) {
        rabiesLabExtraProperties = "required";
      }
    } else {
      rabiesLabExtraProperties = "readOnly";
    }
    return (
      <React.Fragment>
        <Row className="mt-4">
          <Col>
            <span className={styles.samplesModalFormSubTitles}>Laboratory Processing</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className={styles.samplesModalRedComments}>Serum Samples (Check all that apply)</span>
          </Col>
        </Row>
        <div className="px-3">
          <Row style={{ backgroundColor: "#E1E1E1" }}>
            <Col>
              <Form.Check
                inline
                label="Rabies"
                name="rabiesCheckbox"
                type="checkbox"
                id="rabiesCheckbox"
                value={data.rabiesCheckbox}
                onChange={this.handleCheckboxChanges}
                checked={data.rabiesCheckbox}
                disabled={!data.bloodCheckbox}
              />
            </Col>
            <Col className="pl-0">
              <Form.Check
                inline
                label="Canine Distemper"
                name="canineDistemperCheckbox"
                type="checkbox"
                id="canineDistemperCheckbox"
                value={data.canineDistemperCheckbox}
                onChange={this.handleCheckboxChanges}
                checked={data.canineDistemperCheckbox}
                disabled={!data.bloodCheckbox}
              />
            </Col>
            <Col>
              <Form.Check
                inline
                label="Other (3rd)"
                name="other3Checkbox"
                type="checkbox"
                id="other3Checkbox"
                value={data.other3Checkbox}
                onChange={this.handleCheckboxChanges}
                checked={data.other3Checkbox}
                disabled={!data.bloodCheckbox}
              />
            </Col>
            <Col className="pl-0">
              <Form.Check
                inline
                label="Other (4th)"
                name="other4Checkbox"
                type="checkbox"
                id="other4Checkbox"
                value={data.other4Checkbox}
                onChange={this.handleCheckboxChanges}
                checked={data.other4Checkbox}
                disabled={!data.bloodCheckbox}
              />
            </Col>
            <Col className="px-0">
              <Form.Check
                inline
                label="None"
                name="noneCheckbox"
                type="checkbox"
                id="noneCheckbox"
                value={data.noneCheckbox}
                onChange={this.handleCheckboxChanges}
                checked={data.noneCheckbox}
                disabled={!data.bloodCheckbox}
              />
            </Col>
          </Row>
        </div>
        <Row className="mt-3">
          <Col>
            {this.renderSelect(
              "rabiesBrainstemTest",
              "Rabies Brainstem Test",
              this.state.yesNoNotRecordedOptions,
              "Select",
              data.brainstemCheckbox ? "required" : ""
            )}
          </Col>
          <Col>
            {Number.parseInt(data.rabiesBrainstemTest) === 1 &&
              this.renderSelect(
                "rabiesBrainLab",
                "Rabies Brain Lab",
                this.state.rabiesBrainLabOptions,
                "Select",
                "required"
              )}
            {Number.parseInt(data.rabiesBrainstemTest) !== 1 && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Rabies Brain Lab</Form.Label>
                <Form.Control
                  name="rabiesBrainLab"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Date of Test
                {Number.parseInt(data.rabiesBrainstemTest) === 1 && Number.parseInt(data.rabiesBrainLab) === 3 && (
                  <span className={globalStyles.asterisk508}> *</span>
                )}
              </Form.Label>
              <Form.Control
                name="rabiesBrainTestDate"
                placeholder="Enter Date"
                type="date"
                className={globalStyles.formData}
                value={this.state.data.rabiesBrainTestDate}
                onChange={this.handleFieldsChanges}
                readOnly={Number.parseInt(data.rabiesBrainstemTest) !== 1}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Rabies Lab Specimen #
                {Number.parseInt(data.rabiesBrainstemTest) === 1 && Number.parseInt(data.rabiesBrainLab) === 3 && (
                  <span className={globalStyles.asterisk508}> *</span>
                )}
              </Form.Label>
              <Form.Control
                name="rabiesLabSpecimenNumber"
                placeholder="Enter Lab Specimen No."
                type="text"
                className={globalStyles.formData}
                value={data.rabiesLabSpecimenNumber ? data.rabiesLabSpecimenNumber : data.animalId}
                onChange={this.handleFieldsChanges}
                readOnly={Number.parseInt(data.rabiesBrainstemTest) !== 1}
                isInvalid={errors.rabiesLabSpecimenNumber}
              />
              <Form.Control.Feedback type="invalid">{errors.rabiesLabSpecimenNumber}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            {(Number.parseInt(data.rabiesBrainstemTest) !== 1 || Number.parseInt(data.rabiesBrainLab) !== 3) && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Brainstem Condition</Form.Label>
                <Form.Control
                  name="rabiesBrainstemCondition"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
            {Number.parseInt(data.rabiesBrainstemTest) === 1 &&
              data.rabiesBrainLab == 3 &&
              this.renderSelect(
                "rabiesBrainstemCondition",
                "Brainstem Condition",
                this.state.brainstemConditions,
                "Select",
                rabiesLabExtraProperties
              )}
          </Col>
          <Col>
            {(Number.parseInt(data.rabiesBrainstemTest) !== 1 || Number.parseInt(data.rabiesBrainLab) !== 3) && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Staining Intensity (dRIT)</Form.Label>
                <Form.Control
                  name="stainingIntensity"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
            {Number.parseInt(data.rabiesBrainstemTest) === 1 &&
              Number.parseInt(data.rabiesBrainLab) === 3 &&
              this.renderSelect(
                "stainingIntensity",
                "Staining Intensity (dRIT)",
                this.state.stainingIntensityOptions,
                "Select",
                Number.parseInt(data.rabiesBrainLab) === 3 && Number.parseInt(data.rabiesBrainstemResults) === 1
                  ? "required"
                  : ""
              )}
          </Col>
          <Col>
            {(Number.parseInt(data.rabiesBrainstemTest) !== 1 || Number.parseInt(data.rabiesBrainLab) !== 3) && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Antigen Distribution (dRIT)</Form.Label>
                <Form.Control
                  name="antigenDistribution"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
            {Number.parseInt(data.rabiesBrainstemTest) === 1 &&
              Number.parseInt(data.rabiesBrainLab) === 3 &&
              this.renderSelect(
                "antigenDistribution",
                "Antigen Distribution (dRIT)",
                this.state.antigenDistributionOptions,
                "Select",
                Number.parseInt(data.rabiesBrainLab) === 3 && Number.parseInt(data.rabiesBrainstemResults) === 1
                  ? "required"
                  : ""
              )}
          </Col>
          <Col>
            {Number.parseInt(data.rabiesBrainstemTest) !== 1 && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Rabies Brainstem Results</Form.Label>
                <Form.Control
                  name="rabiesBrainstemResults"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
            {Number.parseInt(data.rabiesBrainstemTest) === 1 &&
              this.renderSelect(
                "rabiesBrainstemResults",
                "Rabies Brainstem Results",
                this.state.rabiesBrainstemResultsOptions,
                "Select",
                "required"
              )}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            {Number.parseInt(data.rabiesBrainstemResults) === 2 && (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Rabies Variant</Form.Label>
                <Form.Control
                  name="rabiesVariant"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
            {Number.parseInt(data.rabiesBrainstemResults) !== 2 &&
              this.renderSelect(
                "rabiesVariant",
                "Rabies Variant",
                this.state.rabiesVariants,
                "Select",
                Number.parseInt(data.rabiesBrainstemTest) === 1 && Number.parseInt(data.rabiesBrainstemResults) === 1
                  ? "required"
                  : ""
              )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Rabies Comments
                {!data.noneCheckbox &&
                  Number.parseInt(data.rabiesBrainstemTest) === 1 &&
                  Number.parseInt(data.rabiesBrainstemResults) === 3 && (
                    <span className={globalStyles.asterisk508}> *</span>
                  )}
              </Form.Label>
              <Form.Control
                name="rabiesTestComments"
                placeholder="Enter Test Comments"
                type="text"
                as="textarea"
                rows={3}
                className={globalStyles.formData}
                value={
                  data.noneCheckbox || Number.parseInt(data.rabiesBrainstemTest) !== 1 ? "N/A" : data.rabiesTestComments
                }
                onChange={this.handleFieldsChanges}
                isInvalid={errors.rabiesTestComments}
                readOnly={data.noneCheckbox || Number.parseInt(data.rabiesBrainstemTest) !== 1}
              />
              <Form.Control.Feedback type="invalid">{errors.rabiesTestComments}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderLaboratoryResultsSection() {
    const { data, errors } = this.state;
    return (
      <React.Fragment>
        <Row className="mt-4">
          <Col>
            <span className={styles.samplesModalFormSubTitles}>Laboratory Results</span>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.isToothLabRequired() ? (
              this.renderSelect("toothLab", "Tooth Lab", this.state.toothLabOptions, "Select", "required")
            ) : (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Tooth Lab</Form.Label>
                <Form.Control
                  name="toothLab"
                  placeholder="N/A"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
          </Col>
          <Col>
            {!this.isToothLabRequired() || Number.parseInt(data.toothLab) === 4 ? (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Age Recorded?</Form.Label>
                <Form.Control
                  name="ageRecorded"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            ) : (
              this.renderSelect("ageRecorded", "Age Recorded?", this.state.yesNoOptions, "Select")
            )}
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>
                Years
                {this.isToothLabRequired() && Number.parseInt(data.ageRecorded) === 1 && (
                  <span className={globalStyles.asterisk508}> *</span>
                )}
              </Form.Label>
              <Form.Control
                name="age"
                placeholder="Enter Age"
                type="number"
                min="1"
                max="29"
                step="1"
                className={globalStyles.formData}
                value={Number.parseInt(data.toothLab) !== 4 && Number.parseInt(data.ageRecorded) !== 2 ? data.age : ""}
                onChange={this.handleFieldsChanges}
                readOnly={!this.isToothLabRequired() || Number.parseInt(data.toothLab) === 4 || data.ageRecorded == 2}
                isInvalid={errors.age}
              />
              <Form.Control.Feedback type="invalid">{errors.age}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            {!this.isToothLabRequired() || Number.parseInt(data.toothLab) === 4 ? (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>TTCC Present</Form.Label>
                <Form.Control
                  name="ttccPresent"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            ) : (
              this.renderSelect("ttccPresent", "TTCC Present", this.state.yesNoNotTestedOptions, "Select")
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Age/TTCC Comments</Form.Label>
              <Form.Control
                name="ageTtccComments"
                placeholder="Enter Age/TTCC Comments"
                type="text"
                as="textarea"
                rows={3}
                className={globalStyles.formData}
                value={data.ageTtccComments}
                onChange={this.handleFieldsChanges}
                isInvalid={errors.ageTtccComments}
                readOnly={!this.isToothLabRequired() || Number.parseInt(data.toothLab) === 4}
              />
              <Form.Control.Feedback type="invalid">{errors.ageTtccComments}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            {this.isBloodLabAvailable() ? (
              this.renderSelect(
                "rabiesBloodLab",
                "Rabies Blood Lab",
                this.state.rabiesBloodLabOptions,
                "Select",
                data.rabiesCheckbox ? "required" : ""
              )
            ) : (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Rabies Blood Lab</Form.Label>
                <Form.Control
                  name="rabiesBloodLab"
                  placeholder="N/A"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Rabies VNA IU/ml</Form.Label>
              <Form.Control
                name="rabiesVnaIu"
                placeholder="Enter Rabies VNA IU/ml"
                type="text"
                className={globalStyles.formData}
                value={
                  data.noneCheckbox || (this.isBloodLabAvailable() && Number.parseInt(data.rabiesBloodLab) === 4)
                    ? "N/A"
                    : data.rabiesVnaIu
                }
                onChange={this.handleFieldsChanges}
                readOnly={
                  data.noneCheckbox || (this.isBloodLabAvailable() && Number.parseInt(data.rabiesBloodLab) === 4)
                }
                isInvalid={errors.rabiesVnaIu}
              />
              <Form.Control.Feedback type="invalid">{errors.rabiesVnaIu}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            {data.noneCheckbox || (this.isBloodLabAvailable() && Number.parseInt(data.rabiesBloodLab) === 4) ? (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Rabies VNA Interpretation</Form.Label>
                <Form.Control
                  name="vnaInterpretation"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            ) : (
              this.renderSelect(
                "vnaInterpretation",
                "Rabies VNA Interpretation",
                this.state.rabiesVnaInterpretationOptions,
                "Select",
                data.rabiesVnaIu ? "required" : ""
              )
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Rabies VNA Comments</Form.Label>
              <Form.Control
                name="vnaComments"
                placeholder="Enter Rabies VNA Comments"
                type="text"
                as="textarea"
                rows={3}
                className={globalStyles.formData}
                value={
                  this.isBloodLabAvailable() && Number.parseInt(data.rabiesBloodLab) === 4 ? "N/A" : data.vnaComments
                }
                onChange={this.handleFieldsChanges}
                isInvalid={errors.vnaComments}
                readOnly={
                  data.noneCheckbox || (this.isBloodLabAvailable() && Number.parseInt(data.rabiesBloodLab) === 4)
                }
              />
              <Form.Control.Feedback type="invalid">{errors.vnaComments}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Distemper VNA Dilution</Form.Label>
              <Form.Control
                name="distemperVnaDilution"
                placeholder="Enter Dilution"
                type="text"
                className={globalStyles.formData}
                value={data.canineDistemperCheckbox ? data.distemperVnaDilution : "N/A"}
                onChange={this.handleFieldsChanges}
                readOnly={!data.canineDistemperCheckbox || data.noneCheckbox}
                isInvalid={errors.distemperVnaDilution}
              />
              <Form.Control.Feedback type="invalid">{errors.distemperVnaDilution}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3} className="pr-0">
            {data.canineDistemperCheckbox && !data.noneCheckbox ? (
              this.renderSelect(
                "distemperVnaInterpretation",
                "Distemper VNA Interpretation",
                this.state.interpretationOptions,
                "Select",
                data.distemperVnaDilution ? "required" : ""
              )
            ) : (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Distemper VNA Interpretation</Form.Label>
                <Form.Control
                  name="distemperVnaInterpretation"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Distemper VNA Comments</Form.Label>
              <Form.Control
                name="distemperVnaComments"
                placeholder="Enter Distemper VNA Comments"
                type="text"
                as="textarea"
                rows={3}
                className={globalStyles.formData}
                value={
                  data.noneCheckbox || (this.isBloodLabAvailable() && Number.parseInt(data.rabiesBloodLab) === 4)
                    ? "N/A"
                    : data.distemperVnaComments
                }
                onChange={this.handleFieldsChanges}
                isInvalid={errors.distemperVnaComments}
                readOnly={data.noneCheckbox || Number.parseInt(data.rabiesBloodLab) === 4}
              />
              <Form.Control.Feedback type="invalid">{errors.distemperVnaComments}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Adeno VNA Dilution</Form.Label>
              <Form.Control
                name="adenoVnaDilution"
                placeholder="Enter Dilution"
                type="text"
                className={globalStyles.formData}
                value={data.other3Checkbox || data.other4Checkbox ? data.adenoVnaDilution : ""}
                onChange={this.handleFieldsChanges}
                readOnly={(!data.other3Checkbox && !data.other4Checkbox) || data.noneCheckbox}
                isInvalid={errors.adenoVnaDilution}
              />
              <Form.Control.Feedback type="invalid">{errors.adenoVnaDilution}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            {(data.other3Checkbox || data.other4Checkbox) && !data.noneCheckbox ? (
              this.renderSelect(
                "adenoVnaInterpretation",
                "Adeno VNA Interpretation",
                this.state.interpretationOptions,
                "Select",
                (data.other3Checkbox || data.other4Checkbox) && data.adenoVnaDilution ? "required" : ""
              )
            ) : (
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Adeno VNA Interpretation</Form.Label>
                <Form.Control
                  name="adenoVnaInterpretation"
                  placeholder="Select"
                  type="text"
                  className={globalStyles.formData}
                  readOnly
                />
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Adeno VNA Comments</Form.Label>
              <Form.Control
                name="adenoVnaComments"
                placeholder="Enter Adeno VNA Comments"
                type="text"
                as="textarea"
                rows={3}
                className={globalStyles.formData}
                value={data.adenoVnaComments}
                onChange={this.handleFieldsChanges}
                isInvalid={errors.adenoVnaComments}
                readOnly={
                  data.noneCheckbox || (this.isBloodLabAvailable() && Number.parseInt(data.rabiesBloodLab) === 4)
                }
              />
              <Form.Control.Feedback type="invalid">{errors.adenoVnaComments}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderDashedSeparatorRow() {
    return (
      <Row className="px-3 my-4">
        <Col className={styles.dashedBorderSeparatorRow}></Col>
      </Row>
    );
  }

  render() {
    const submitButtonText = this.props.diseaseSamplingQty > 0 ? "Save and Continue" : "Submit";
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.rabiesModalDialog}
      >
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Rabies List</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col>
                <span className={styles.samplesModalFormTitle} ref={(el) => (this.messagesStart = el)}>
                  Rabies {("00" + this.state.currentRabiesCount).slice(-3)}
                </span>
              </Col>
            </Row>
            {this.renderRabiesGeneralSection()}
            {this.renderDashedSeparatorRow()}
            {this.renderDrugsAndVaccinesSection()}
            {this.renderSamplesSection()}
            {this.renderLaboratoryProcessingSection()}
            {this.renderLaboratoryResultsSection()}
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.samplesModalFooter}>
          <Row noGutters="true">
            <Col className="text-left">
              {this.state.currentRabiesCount > 1 && (
                <Button variant="outline-primary" onClick={this.handlePreviousStepNavigation}>
                  <span className={globalStyles.modalCancelButtonText}>Back</span>
                </Button>
              )}
            </Col>
            <Col className="text-right">
              <Button className="ml-2" variant="primary" onClick={this.doSubmit}>
                <span className={globalStyles.modalSubmitButtonText}>
                  {this.state.currentRabiesCount < Number.parseInt(this.props.data?.rabiesSamplingQty)
                    ? "Next"
                    : submitButtonText}
                </span>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RabiesSamplesModal;
