import React from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";

class PermitFileUploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFileSelected: false,
    };
  }

  handleSave = () => {
    this.props.onHide();
  };

  handleCancel = () => {
    this.props.handleFileSelected(null);
    this.props.onHide();
  };

  handleFileSelection = (e) => {
    var file = e.currentTarget.files;
    this.props.handleFileSelected(Array.from(file));

    if (file) this.setState({ isFileSelected: true });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered size="md">
        <Modal.Header>
          <div className="container">
            <Modal.Title>Attach PDF To Permit Record</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row className="my-2">
              <Col>
                <input onChange={this.handleFileSelection} type="file" accept=".pdf" multiple />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleSave} disabled={!this.state.isFileSelected}>
            Attach
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PermitFileUploadModal;
