import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Form, Row, Col, Button, Image, Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter, customFilter } from "react-bootstrap-table2-filter";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import CustomForm from "../common/form";
import CustomTextFilter from "../common/customTextFilter";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import styles from "./Districts.module.scss";
import globalStyles from "../../OARS.module.scss";
import searchIcon from "../../assets/search.svg";
import projectIcon from "../../assets/projectIcon.svg";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import { toast } from "react-toastify";
import AddDistrictUsersModal from "./AddDistrictUsersModal";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class DistrictDetailsPage extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        districtName: "",
        supervisor: "",
        assistantSupervisors: [],
        createdDate: "",
        createdBy: "",
      },
      assignedUsersTableColumns: [
        { dataField: "wsUserProfileUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      originalData: "",
      districtData: "",
      isFormLoading: true,
      isEditMode: false,
      showSuccessfulUpdateModal: false,
      showAddUsersModal: false,
      userTableRowsSelected: [],
      states: [],
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
    }

    const districtData = await this.getDistrictDataFromAPI();
    const data = districtData ? this.updatePageData(districtData) : { ...this.state.data };

    this.setState({
      data,
      originalData: JSON.parse(JSON.stringify(data)),
      assignedUsersTableColumns: this.getUserProfilesTableColumns(),
      districtData,
      currentUsaState,
      states: await this.getStatesData(),
      isFormLoading: false,
    });
  }

  async getDistrictDataFromAPI() {
    let districtData = "";
    if (this.props.history.location.state?.isDistrictCreation) {
      districtData = this.props.history.location.state.entityData;
    } else if (this.props.history.location?.state?.entityData?.districtUniqueIdentifier) {
      const getDistrictResponse = await RoleAuthorizationAPI.GetDistrict(
        this.props.history.location.state.entityData.districtUniqueIdentifier
      );
      if (getDistrictResponse?.successful) {
        districtData = getDistrictResponse.data;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to to retrieve District Data",
          apiName: "GetDistrict",
          responseUnsuccessful: getDistrictResponse?.unsuccessful,
          responseMessage: getDistrictResponse?.message,
        });
      }
    }
    await this.updateProfilesDataInDistrict(districtData);
    return districtData;
  }

  updatePageData(districtData) {
    const data = { ...this.state.data };
    if (districtData) {
      data.districtName = districtData.name;
      data.supervisor = districtData.districtSupervisor?.wsUserProfileUniqueReference
        ? {
            value: districtData.districtSupervisor.wsUserProfileUniqueReference,
            label: districtData.districtSupervisor?.profileName,
          }
        : "";
      data.assistantSupervisors =
        districtData.additionalSupervisors?.length > 0
          ? districtData.additionalSupervisors.map((s) => ({
              value: s.wsUserProfileUniqueReference,
              label: s.profileName,
            }))
          : [];
      data.createdDate = districtData.createdDateTimeUTC
        ? new Date(districtData.createdDateTimeUTC).toLocaleDateString("en-CA", { timeZone: "UTC" })
        : "";
      data.createdBy = districtData.createdBy;
    }

    return data;
  }

  getUserProfilesTableColumns() {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
      paddingRight: "1.5rem",
    };
    return [
      {
        dataField: "wsUserProfileUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "userName",
        text: "User Name",
        filter: textFilter({
          onFilter: this.filterUserNameColumn,
        }),
        formatter: (cell, row) => {
          return (
            <span>
              <Button variant="link" onClick={() => this.handleUserDetailsNavigation(row)}>
                <span className={globalStyles.formDataLinks}>{`${row.firstName} ${row.lastName}`}</span>
              </Button>
            </span>
          );
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px", color: "#0071BC" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700, width: "30%" },
      },
      {
        dataField: "profileName",
        text: "Profile Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Profile Name Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "profileAssociatedStates",
        text: "States",
        filter: textFilter({
          onFilter: this.filterProfileStates,
        }),
        formatter: (cell, row) => {
          let stateNamesArray =
            cell?.length > 0
              ? cell.map((stateId) => {
                  return this.state.states?.find((s) => s.stateUniqueIdentifier === stateId)?.state_code;
                })
              : "";

          let stateNames = "";
          if (stateNamesArray?.length > 0) {
            stateNames = stateNamesArray.join(", ");
          }
          if (stateNames?.length > 30) {
            stateNames = stateNames.substring(0, 29) + "...";
          }

          return stateNames;
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
    ];
  }

  filterUserNameColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (entry) =>
          entry.firstName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          entry.lastName?.toLowerCase().includes(filterValue?.toLowerCase())
      );
    }
  };

  filterProfileStates = (filterValue, data) => {
    if (filterValue) {
      const filteredStateIds = this.state.states
        ?.filter(
          (state) =>
            state.state_code?.toLowerCase().includes(filterValue?.toLowerCase()) ||
            state.state_name?.toLowerCase().includes(filterValue?.toLowerCase())
        )
        ?.map((s) => s.stateUniqueIdentifier);
      return data.filter((entry) => filteredStateIds.some((id) => entry.profileAssociatedStates.includes(id)));
    }
  };

  async getStatesData() {
    let statesData = [];
    const usaStatesResponse = await ReferenceFileAPI.GetUSStates();
    if (usaStatesResponse?.length > 0) {
      statesData = usaStatesResponse;
    }

    return statesData;
  }

  handleSpecificChanges = () => {
    //do something
  };

  handleUserDetailsNavigation = (row) => {
    if (this.props.permissions.canIViewGlobalUserRecord()) {
      //Navigation to User Details Page (Pending to confirm requirement)
    } else {
      toast.warning("User does not have permission to view record details.");
    }
  };

  handleEditDistrict = async () => {
    if (!this.state.isEditMode) {
      this.setState({ isEditMode: true });
    } else if (!this.isFormDataValid()) {
      this.checkForInvalidData();
    } else {
      await this.proceedWithDistrictUpdate();
    }
  };

  async proceedWithDistrictUpdate() {
    let { data, originalData, districtData } = this.state;
    let isEditRequired = false;
    let isUpdateNameSuccessful = true;
    let isUpdateUsersSuccessful = true;
    let updatedData = JSON.parse(JSON.stringify(data));
    if (data.districtName !== originalData?.districtName && districtData?.districtUniqueIdentifier) {
      isEditRequired = true;
      const updateDistrictResponse = await RoleAuthorizationAPI.UpdateDistrict(districtData.districtUniqueIdentifier, {
        districtName: data.districtName,
      });
      if (updateDistrictResponse?.successful) {
        isUpdateNameSuccessful = true;
        districtData = updateDistrictResponse.data;
        updatedData = districtData ? this.updatePageData(districtData) : updatedData;
      } else {
        isUpdateNameSuccessful = false;
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to to edit District name",
          apiName: "UpdateDistrict",
          responseUnsuccessful: updateDistrictResponse?.unsuccessful,
          responseMessage: updateDistrictResponse?.message,
        });
      }
    }

    const assistantSupervisorsToAdd = this.getAssistantSupervisorsToAdd(originalData, data);
    const assistantSupervisorsToRemove = this.getAssistantSupervisorsToRemove(data, originalData);
    const newSupervisorValue =
      data.supervisor?.value !== originalData?.supervisor?.value ? data.supervisor?.value : null;

    if (
      districtData?.districtUniqueIdentifier &&
      (newSupervisorValue || assistantSupervisorsToAdd?.length > 0 || assistantSupervisorsToRemove?.length > 0)
    ) {
      isEditRequired = true;
      const setDistrictUsersResponse = await RoleAuthorizationAPI.SetDistrictUsers(
        districtData.districtUniqueIdentifier,
        {
          newDistrictSupervisorWsUserProfileUniqueReferenceOrNullIfNotChanging: newSupervisorValue,
          addTheseAdditionalSupervisorWsUserProfileUniqueReferences:
            assistantSupervisorsToAdd?.length > 0 ? assistantSupervisorsToAdd.map((s) => s.value) : [],
          removeTheseAdditionalSupervisorWsUserProfileUniqueReferences:
            assistantSupervisorsToRemove?.length > 0 ? assistantSupervisorsToRemove.map((s) => s.value) : [],
          addTheseEmployeeWsUserProfileUniqueReferences: [],
          removeTheseEmployeeWsUserProfileUniqueReferences: [],
        }
      );
      if (setDistrictUsersResponse?.successful) {
        isUpdateUsersSuccessful = true;
        districtData = setDistrictUsersResponse.data;
        updatedData = districtData ? this.updatePageData(districtData) : updatedData;
      } else {
        isUpdateNameSuccessful = false;
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to to apply changes to District Users",
          apiName: "SetDistrictUsers",
          responseUnsuccessful: setDistrictUsersResponse?.unsuccessful,
          responseMessage: setDistrictUsersResponse?.message,
        });
      }
    }

    if (isEditRequired && isUpdateNameSuccessful && isUpdateUsersSuccessful) {
      this.setState({
        showSuccessfulUpdateModal: true,
        districtData,
        data: updatedData,
        originalData: JSON.parse(JSON.stringify(data)),
        isEditMode: false,
      });
    }
  }

  getAssistantSupervisorsToAdd(originalData, data) {
    return originalData?.assistantSupervisors?.length > 0
      ? data.assistantSupervisors?.filter(
          (supervisor) => !originalData.assistantSupervisors.find((s) => s.value === supervisor.value)
        )
      : data.assistantSupervisors;
  }

  getAssistantSupervisorsToRemove(data, originalData) {
    return data.assistantSupervisors?.length > 0
      ? originalData?.assistantSupervisors?.filter(
          (supervisor) => !data.assistantSupervisors.find((s) => s.value === supervisor.value)
        )
      : originalData?.assistantSupervisors;
  }

  isFormDataValid() {
    const { data } = this.state;
    return data.districtName && data.supervisor;
  }

  checkForInvalidData() {
    const { data, errors } = this.state;
    if (!data.districtName) {
      errors.districtName = "You must enter a valid District Name.";
    }
    if (!data.supervisor) {
      errors.supervisor = "You must select a Supervisor.";
    }

    this.setState(errors);
  }

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    delete errors[fieldName];
    this.setState({ data, errors });
  };

  handleAddUser = () => {
    this.setState({ showAddUsersModal: true });
  };

  handleUsersRemove = async () => {
    const { districtData: originalDistrictData, userTableRowsSelected } = this.state;
    if (userTableRowsSelected.length > 0) {
      if (originalDistrictData.districtUniqueIdentifier) {
        const removeUsersResponse = await RoleAuthorizationAPI.SetDistrictUsers(
          originalDistrictData.districtUniqueIdentifier,
          {
            newDistrictSupervisorWsUserProfileUniqueReferenceOrNullIfNotChanging: null,
            addTheseAdditionalSupervisorWsUserProfileUniqueReferences: [],
            removeTheseAdditionalSupervisorWsUserProfileUniqueReferences: [],
            addTheseEmployeeWsUserProfileUniqueReferences: [],
            removeTheseEmployeeWsUserProfileUniqueReferences: userTableRowsSelected,
          }
        );
        if (removeUsersResponse?.successful) {
          toast.success("Users were successfully removed from district.");
          const districtData = removeUsersResponse.data;
          await this.updateProfilesDataInDistrict(districtData);
          const data = districtData ? this.updatePageData(districtData) : { ...this.state.data };
          this.setState({ data, districtData });
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to remove all the selected users from the district",
            apiName: "SetDistrictUsers",
            responseUnsuccessful: removeUsersResponse?.unsuccessful,
            responseMessage: removeUsersResponse?.message,
          });
        }
      }
    } else {
      toast.warning("Please select the rows you want to remove.");
    }
  };

  handleUsersRowOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        userTableRowsSelected: [...this.state.userTableRowsSelected, row?.wsUserProfileUniqueIdentifier],
      });
    } else {
      this.setState({
        userTableRowsSelected: this.state.userTableRowsSelected.filter((x) => x !== row?.wsUserProfileUniqueIdentifier),
      });
    }
  };

  handleOnSelectAllUsers = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ userTableRowsSelected: rows.map((r) => r.wsUserProfileUniqueIdentifier) });
    } else {
      this.setState({ userTableRowsSelected: [] });
    }
  };

  loadAvailableUserProfiles = async (inputValue) => {
    let outputContacts = [];
    const contactsDataResponse = await RoleAuthorizationAPI.SearchUsersFreeText(this.getSearchUsersBody(inputValue));
    if (contactsDataResponse?.successful && contactsDataResponse.data?.results?.length > 0) {
      let results = contactsDataResponse.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    return outputContacts;
  };

  getSearchUsersBody(inputValue) {
    return {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
  }

  addUsersSelection = async (usersData) => {
    const { districtData: originalDistrictData } = this.state;
    const userProfilesIds = usersData
      .map((user) => user.wsUserProfileUniqueIdentifier)
      ?.filter((id) => !originalDistrictData.employees?.some((p) => p.wsUserProfileUniqueIdentifier === id));
    if (userProfilesIds?.length > 0 && originalDistrictData.districtUniqueIdentifier) {
      const addUsersResponse = await RoleAuthorizationAPI.SetDistrictUsers(
        originalDistrictData.districtUniqueIdentifier,
        {
          newDistrictSupervisorWsUserProfileUniqueReferenceOrNullIfNotChanging: null,
          addTheseAdditionalSupervisorWsUserProfileUniqueReferences: [],
          removeTheseAdditionalSupervisorWsUserProfileUniqueReferences: [],
          addTheseEmployeeWsUserProfileUniqueReferences: userProfilesIds,
          removeTheseEmployeeWsUserProfileUniqueReferences: [],
        }
      );
      if (addUsersResponse?.successful) {
        toast.success("Users were successfully added to district.");
        const districtData = addUsersResponse.data;
        await this.updateProfilesDataInDistrict(districtData);
        const data = districtData ? this.updatePageData(districtData) : { ...this.state.data };
        this.setState({ data, districtData });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to add all the new users to the district",
          apiName: "SetDistrictUsers",
          responseUnsuccessful: addUsersResponse?.unsuccessful,
          responseMessage: addUsersResponse?.message,
        });
      }
    }
  };

  async updateProfilesDataInDistrict(districtData) {
    if (districtData.employees?.length > 0) {
      const resolveProfilesResponse = await RoleAuthorizationAPI.ResolveUserProfiles(
        districtData.employees.map((p) => p.wsUserProfileUniqueReference)
      );
      if (resolveProfilesResponse?.successful && resolveProfilesResponse.data?.length > 0) {
        districtData.employees = resolveProfilesResponse.data;
      }
    }
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "solid 0.5px #D8DDE6",
      };
    },
  };

  canEditDistrict() {
    const { permissions } = this.props;
    let canEditDistrict = false;
    if (
      permissions.canIEditDistrictsForAssignedStates() ||
      permissions.canIAssignDistrictSupervisorsForAssignedStates() ||
      (this.profileAssociatedDistricts() && permissions.canIAssignDistrictSupervisorsForAssignedDistricts()) ||
      permissions.canIAssignDistrictAdditionalSupervisorsForAssignedStates() ||
      (this.profileAssociatedDistricts() && permissions.canIAssignDistrictAdditionalSupervisorsForAssignedDistricts())
    ) {
      canEditDistrict = true;
    }

    return canEditDistrict;
  }
  renderPageHeader() {
    return (
      <Row className="mt-4 mx-0">
        <Col className="pl-0">
          <h1 className={globalStyles.pageTitle}>District Record Page</h1>
        </Col>
        <Col className="text-right pr-0">
          {this.canEditDistrict() && (
            <Button variant="primary" onClick={this.handleEditDistrict}>
              <span className={globalStyles.modalSubmitButtonText}>
                {this.state.isEditMode ? "Apply Changes" : "Edit"}
              </span>
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  renderInformationCard() {
    const { isEditMode, data } = this.state;
    return (
      <Card>
        <Card.Header className={globalStyles.cardTitles}>
          <Image src={projectIcon} alt="Info Icon" className="mr-2" />
          <span className={globalStyles.cardTitleText}>District Information</span>
        </Card.Header>
        <Card.Body>
          <Row className="text-left mt-3">
            <Col>
              {this.renderEditableField(
                isEditMode,
                "districtName",
                "District Name",
                data.districtName,
                "text",
                [],
                this.props.permissions.canIEditDistrictsForAssignedStates() ? "required" : "readOnly"
              )}
            </Col>
            <Col>{this.renderSupervisorField()}</Col>
            <Col>{this.renderAssistantSupervisorsField()}</Col>
            <Col>
              {this.renderEditableField(
                isEditMode,
                "createdDate",
                "Created Date",
                data.createdDate,
                "date",
                [],
                "readOnly"
              )}
            </Col>
            <Col>
              {this.renderEditableField(isEditMode, "createdBy", "Created By", data.createdBy, "text", [], "readOnly")}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  profileAssociatedDistricts() {
    let associatedWithDistrict = false;
    //check if the user is supervisor or not
    const currentUserRole = JSON.parse(localStorage.getItem("currentUserRole"));
    if (currentUserRole?.wsUserProfileUniqueIdentifier) {
      const wsUserProfileUniqueIdentifier = currentUserRole.wsUserProfileUniqueIdentifier;

      const SupervisorID = this.state.data?.supervisor?.value;
      if (wsUserProfileUniqueIdentifier === SupervisorID) {
        associatedWithDistrict = true;
      }
      if (!associatedWithDistrict) {
        if (this.state.data?.assistantSupervisors?.find((s) => s.value === wsUserProfileUniqueIdentifier)) {
          associatedWithDistrict = true;
        }
      }
      if (!associatedWithDistrict) {
        if (
          this.state.districtData?.employees?.find(
            (s) => s.wsUserProfileUniqueIdentifier === wsUserProfileUniqueIdentifier
          )
        ) {
          associatedWithDistrict = true;
        }
      }
    }
    return associatedWithDistrict;
  }

  renderUsersAssignedCard() {
    const areDataRowsSelected = this.state.userTableRowsSelected?.length > 0;

    const showAddUsersModal =
      (this.profileAssociatedDistricts() && this.props.permissions.canIAssignEmployeeForAssignedDistricts()) ||
      this.props.permissions.canIAssignEmployeeForAssignedStates();

    return (
      <Card className={styles.usersCard}>
        <Card.Header className={globalStyles.cardTitles}>
          <Row>
            <Col>
              <span className={globalStyles.cardTitleText}>Users Assigned to District</span>
            </Col>
            <Col className="text-right" hidden={!showAddUsersModal}>
              <Button variant="link" className="px-0" onClick={this.handleAddUser}>
                <span className={globalStyles.formDataLinks}>+ Add User</span>
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {this.state.districtData?.employees?.length > 0 && (
            <Row className="text-right">
              <Col className="pr-0">
                <Button
                  className={areDataRowsSelected ? "" : styles.greyedOutRemoveButton}
                  onClick={this.handleUsersRemove}
                >
                  <span className={areDataRowsSelected ? "" : styles.greyedOutButtonText}>Remove</span>
                </Button>
              </Col>
            </Row>
          )}
          <Row className="text-left mt-3">
            <Col>{this.renderUsersTable()}</Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderUsersTable() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.userTableRowsSelected,
      onSelect: this.handleUsersRowOnSelect,
      onSelectAll: this.handleOnSelectAllUsers,
      style: { backgroundColor: "#f5f5f5" },
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "1px solid #e8e8e8",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #dee2e6",
      },
    };

    const usersData = this.state.districtData?.employees?.length > 0 ? this.state.districtData.employees : [];

    return usersData?.length > 0 ? (
      <ToolkitProvider
        keyField="wsUserProfileUniqueIdentifier"
        data={usersData}
        columns={this.state.assignedUsersTableColumns}
        bootstrap4={true}
        hover={true}
      >
        {(props) => (
          <div>
            <BootstrapTable
              keyField="wsUserProfileUniqueIdentifier"
              filter={filterFactory()}
              bordered={false}
              condensed
              selectRow={selectRow}
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    ) : (
      <div className="text-center">
        <span className={globalStyles.formLabel}>No users are linked to this agreement.</span>
      </div>
    );
  }

  renderSupervisorField() {
    const { data, errors, isEditMode } = this.state;

    let canEditSupervisor = false;
    if (
      this.props.permissions.canIAssignDistrictSupervisorsForAssignedStates() ||
      (this.profileAssociatedDistricts() && this.props.permissions.canIAssignDistrictSupervisorsForAssignedDistricts())
    ) {
      canEditSupervisor = true;
    }
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>
          Supervisor<span className={globalStyles.asterisk508}> *</span>
        </Form.Label>
        <AsyncSelect
          placeholder="Search for Users"
          aria-label="Supervisor Search"
          isClearable
          value={data.supervisor}
          styles={this.customSelectStyles}
          components={{ DropdownIndicator, Option }}
          loadOptions={this.loadAvailableUserProfiles}
          onChange={(e) => this.handleSelectChanges(e, "supervisor")}
          isDisabled={!canEditSupervisor}
        />
        {errors.supervisor && <div className="alert alert-danger">{errors.supervisor}</div>}
      </Form.Group>
    ) : (
      this.renderLabel("supervisor", "Supervisor", data.supervisor?.label)
    );
  }

  renderAssistantSupervisorsField() {
    const { data, isEditMode } = this.state;

    let supervisorNames = data.assistantSupervisors?.map((s) => s.label)?.join(", ");
    if (supervisorNames?.length > 20) {
      supervisorNames = supervisorNames.substring(0, 19) + "...";
    }
    let canEditAdditionalSupervisor = false;
    if (
      (this.profileAssociatedDistricts() &&
        this.props.permissions.canIAssignDistrictAdditionalSupervisorsForAssignedDistricts()) ||
      this.props.permissions.canIAssignDistrictAdditionalSupervisorsForAssignedStates()
    ) {
      canEditAdditionalSupervisor = true;
    }
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Assistant Supervisor(s)</Form.Label>
        <AsyncSelect
          placeholder="Search for Users"
          aria-label="Assistant Supervisors Search"
          isClearable
          value={data.assistantSupervisors}
          styles={this.customSelectStyles}
          components={{ DropdownIndicator, Option }}
          isMulti
          loadOptions={this.loadAvailableUserProfiles}
          onChange={(e) => this.handleSelectChanges(e, "assistantSupervisors")}
          isDisabled={!canEditAdditionalSupervisor}
        />
      </Form.Group>
    ) : (
      this.renderLabel("assistantSupervisors", "Assistant Supervisor(s)", supervisorNames)
    );
  }

  render() {
    const existingUsersIds =
      this.state.districtData?.employees?.length > 0
        ? this.state.districtData.employees.map((e) => e.wsUserProfileUniqueIdentifier)
        : [];
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading District Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.districtRecordPage}>
          <Form onSubmit={this.handleSubmit}>
            {this.renderPageHeader()}
            <Row className="mt-3">
              <Col>
                {this.renderInformationCard()}
                {this.renderUsersAssignedCard()}
              </Col>
            </Row>
          </Form>
        </div>
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfulUpdateModal}
          onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
          routeData=""
          messageType="update"
          objectName="District"
        />
        <AddDistrictUsersModal
          show={this.state.showAddUsersModal}
          onHide={() => {
            this.setState({ showAddUsersModal: false });
          }}
          onAddUsers={this.addUsersSelection}
          states={this.state.states}
          existingUserIds={existingUsersIds}
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(DistrictDetailsPage);
