import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { textFilter, selectFilter, dateFilter, customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { withRouter } from "react-router-dom";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CustomTextFilter from "../common/customTextFilter";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CreateEditOrganizationModal from "../ReferenceFilesCreatePages/CreateEditOrganizationModal";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import UtilityFunctions from "../common/UtilityFunctions";

class OrganizationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationData: [],
      defaultSort: [{ dataField: "name", order: "asc" }],
      columns: [
        {
          dataField: "externalOrganizationUniqueIdentifier",
          hidden: true,
          csvText: "Organization Unique Id",
        },
        {
          dataField: "externalOrganizationName",
          text: "Name",
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter onFilter={onFilter} column={column} title="Organization Name" />
          ),
          sort: true,
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3" };
          },
          headerAttrs: { id: "organizationNameHeader" },
          attrs: { headers: "organizationNameHeader", tabIndex: "0" },
        },
        {
          dataField: "isAllowedInState",
          text: "Status",
          formatter: (cell, row, rowIndex) => {
            return row.isAllowedInState ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          },
          sort: true,
          sortFunc: (a, b, order) => {
            if (order === "asc") {
              return b - a;
            }
            return a - b; // desc
          },
          align: "left",
          headerAlign: "left",
          style: this.tableTextStyle,
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          headerAttrs: { id: "organizationStatusHeader" },
          attrs: { headers: "organizationStatusHeader", tabIndex: "0" },
        },
        {
          dataField: "lastModifiedDateTimeUTC",
          text: "Last Updated Date",
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell).toLocaleDateString("en-US");
          },
          sort: true,
          sortFunc: (a, b, order) => {
            let dateA = new Date(a);
            let dateB = new Date(b);
            if (order === "asc") {
              return dateB - dateA;
            }
            return dateA - dateB; // desc
          },
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          style: this.tableTextStyle,
          headerAttrs: { id: "organizationDateHeader" },
          attrs: { headers: "organizationDateHeader", tabIndex: "0" },
        },
        {
          dataField: "organizationActions",
          text: "Actions",
          csvExport: false,
          editable: false,
          formatter: (cell, row) => {
            return (
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton
                  id="dropdown-organization-actions"
                  title="Actions"
                  variant="link"
                  className={styles.buttonLink}
                >
                  <Dropdown.Item
                    eventKey="organizationEdit"
                    onSelect={() => this.handleOrganizationEdit(row)}
                    hidden={!this.props.permissions.canIEditReferenceData()}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="organizationHistoryTracking"
                    onSelect={() => this.handleOrganizationHistory(row)}
                  >
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            );
          },
          headerStyle: () => {
            return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
          },
          headerAlign: "right",
          align: "right",
          headerAttrs: {
            id: "organizationActionsHeader",
            title: "Actions Column Header",
          },
          attrs: { headers: "organizationActionsHeader" },
        },
      ],
      showCreateModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
      currentUsaState: props.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier) {
      await this.updateOrganizationData();
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "organizationTab") {
      await this.updateOrganizationData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "organizationTab") {
      await this.updateOrganizationData();
    }
  }

  async updateOrganizationData() {
    this.props.setIsLoading(true);
    const organizationData = await this.getDataFromAPI();
    this.setState({ organizationData }, () => {
      this.props.setIsLoading(false);
    });
    window.scrollTo(0, 0);
  }

  async getDataFromAPI() {
    let organizationResults = [];
    if (this.state.currentUsaState?.stateGuid) {
      const getOrganizationsResponse = await ReferenceFileAPI.GetAllExternalOrganizationsInState(
        this.state.currentUsaState.stateGuid
      );
      if (getOrganizationsResponse?.successful) {
        organizationResults = getOrganizationsResponse.data.results ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get external organization data",
          apiName: "GetAllExternalOrganizationsInState",
          responseUnsuccessful: getOrganizationsResponse?.unsuccessful,
          responseMessage: getOrganizationsResponse?.message,
        });
      }
    }
    return organizationResults;
  }

  onModalSubmit = async (newOrgData, mode) => {
    if (mode === "Create") {
      const createOrganizationBody = {
        externalOrganizationName: newOrgData.externalOrganizationName,
        stateUniqueReference: this.state.currentUsaState?.stateGuid,
        isAllowedInState: true,
      };
      const createOrganizationResponse = await ReferenceFileAPI.CreateExternalOrganization(createOrganizationBody);
      if (createOrganizationResponse?.successful) {
        this.setState({ showCreateSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create external organization",
          apiName: "CreateExternalOrganization",
          responseUnsuccessful: createOrganizationResponse?.unsuccessful,
          responseMessage: createOrganizationResponse?.message,
        });
      }
    } else if (mode === "Edit") {
      let isEditSuccessful = true;
      let editErrorMessage = "";

      if (newOrgData.externalOrganizationName !== this.state.modalData?.externalOrganizationName) {
        const editOrganizationBody = {
          externalOrganizationName: newOrgData.externalOrganizationName,
        };
        const editOrganizationResponse = await ReferenceFileAPI.UpdateExternalOrganization(
          newOrgData.externalOrganizationUniqueIdentifier,
          editOrganizationBody
        );
        if (!editOrganizationResponse?.successful) {
          isEditSuccessful = false;
          editErrorMessage = editOrganizationResponse?.message;
        }
      }
      if (newOrgData.isAllowedInState !== this.state.modalData?.isAllowedInState) {
        const editOrganizationResponse = newOrgData.isAllowedInState
          ? await ReferenceFileAPI.EnableExternalOrganization(newOrgData.externalOrganizationUniqueIdentifier)
          : await ReferenceFileAPI.DisableExternalOrganization(newOrgData.externalOrganizationUniqueIdentifier);
        if (!editOrganizationResponse?.successful) {
          isEditSuccessful = false;
          editErrorMessage = editOrganizationResponse?.message;
        }
      }

      if (isEditSuccessful) {
        this.setState({ showEditSuccessModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get external organization data",
          apiName: "GetAllExternalOrganizationsInState",
          responseUnsuccessful: !isEditSuccessful,
          responseMessage: editErrorMessage,
        });
      }
    }
    await this.updateOrganizationData();
  };

  handleOrganizationCreate = () => {
    this.setState({ showCreateModal: true });
  };

  handleOrganizationEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleOrganizationHistory = (row) => {
    //do nothing
  };

  closeAllModals = () => {
    this.setState({ showCreateModal: false, showEditModal: false });
  };

  renderCreateModal() {
    return (
      <CreateEditOrganizationModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode="Create"
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditOrganizationModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode="Edit"
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  render() {
    return (
      <ToolkitProvider
        keyField="externalOrganizationUniqueIdentifier"
        data={this.state.organizationData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "organization-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Organization"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleOrganizationCreate}
                  recordCount={this.state.organizationData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="externalOrganizationUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.state.showCreateModal ? this.renderCreateModal() : null}
            {this.state.showEditModal ? this.renderEditModal() : null}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="Organization"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="Organization"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(OrganizationTab);
