import React from "react";
import styles from "./EditModalPages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Image, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ConfigureDiseaseModal extends CustomForm {
  constructor(props) {
    super(props);
    if (!this.props.modalData) {
      this.props.onHide();
    }
    this.state = {
      validated: false,
      data: this.props.modalData,
      errors: {},
    };
  }

  async doSubmit(e) {
    let form = e.currentTarget;
    if (form.checkValidity()) {
      this.setState({ validated: true });
      this.configureDisease();
    }
  }

  async configureDisease() {
    try {
      let configureResult = null;
      if (this.state.data.isAllowed === "Yes" || this.state.data.isAllowed === true) {
        configureResult = await ReferenceFileAPI.AllowDiseaseForState(
          this.state.data.diseaseUniqueIdentifier,
          this.props.usaStateGuid
        );
      } else if (this.state.data.isAllowed === "No" || this.state.data.isAllowed === false) {
        configureResult = await ReferenceFileAPI.DisallowDiseaseForState(
          this.state.data.diseaseUniqueIdentifier,
          this.props.usaStateGuid
        );
      }
      if (configureResult.successful || configureResult.message.includes("already")) {
        this.props.showSuccessModal();
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set disease allowed status",
          apiName:
            this.state.data.isAllowed === "Yes" || this.state.data.isAllowed === true
              ? "AllowDiseaseForState"
              : "DisallowDiseaseForState",
          responseUnsuccessful: configureResult?.unsuccessful,
          responseMessage: configureResult?.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.modalCleanUp();
  }

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Modal.Header className={globalStyles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>Configure Disease</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={styles.configureModalBody}>
                <Row>
                  <Col className={styles.configureModalItem}>
                    <label htmlFor="ConfigureDiseaseModalGuid" className={globalStyles.modalFormLabel}>
                      ID
                    </label>
                    <p id="ConfigureDiseaseModalGuid">{this.state.data.diseaseUniqueIdentifier}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalItem}>
                    <label htmlFor="ConfigureDiseaseModalName" className={globalStyles.modalFormLabel}>
                      Disease
                    </label>
                    <p id="ConfigureDiseaseModalName">{this.state.data.name}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalItem}>
                    <label htmlFor="ConfigureDiseaseModalLastModified" className={globalStyles.modalFormLabel}>
                      Last Modified
                    </label>
                    <p id="ConfigureDiseaseModalLastModified">
                      {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalAllowed}>
                    {this.renderInlineCheckBoxSet(
                      "isAllowed",
                      "Allowed",
                      ["Yes", "No"],
                      "radio",
                      this.state.data.isAllowed ? "Yes" : "No"
                    )}
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className={globalStyles.modalFooter}>
              <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <Button type="submit" className={globalStyles.modalSubmitButton}>
                <span className={globalStyles.modalSubmitButtonText}>Save</span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ConfigureDiseaseModal;
