import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Row, Col, Button, Modal, CloseButton, Image } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter, customFilter } from "react-bootstrap-table2-filter";
import { toast } from "react-toastify";
import CustomForm from "../common/form";
import globalStyles from "../../OARS.module.scss";
import styles from "./Districts.module.scss";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import CustomTextFilter from "../common/customTextFilter";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class AddDistrictUsersModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      stateUsersList: [],
      usersTableData: [],
      userProfilesTableColumns: [
        { dataField: "wsUserProfileUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      userProfilesRowSelected: [],
      isModalInitialized: false,
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    this.validateStateData(currentUsaState);

    this.setState({
      currentUsaState,
      userProfilesTableColumns: this.getUserProfilesTableColumns(),
    });
  }

  async componentDidUpdate(prevProps) {
    let { usersTableData, isModalInitialized, stateUsersList } = this.state;
    if (prevProps.show !== this.props.show && this.props.show) {
      if (!this.state.isModalInitialized) {
        stateUsersList = await this.getUsersTableData();
        isModalInitialized = true;
      }
      usersTableData = stateUsersList.filter(
        (u) => !this.props.existingUserIds?.includes(u.wsUserProfileUniqueIdentifier)
      );

      this.setState({
        stateUsersList,
        usersTableData,
        isModalInitialized,
      });
    }
  }

  async getUsersTableData() {
    let userProfiles = [];
    const usersSearchResponse = await RoleAuthorizationAPI.SearchUsersFreeText(this.getSearchUsersBody());
    if (usersSearchResponse?.successful) {
      if (usersSearchResponse.data?.results?.length > 0) {
        for (let user of usersSearchResponse.data.results) {
          const userProfilesInCurrentState = user.userProfiles?.filter((p) =>
            p.profileAssociatedStates?.includes(this.state.currentUsaState?.stateGuid)
          );
          if (userProfilesInCurrentState?.length > 0) {
            userProfiles = [...userProfiles, ...userProfilesInCurrentState];
          }
        }
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve state users data",
        apiName: "SearchUsersFreeText",
        responseUnsuccessful: usersSearchResponse?.unsuccessful,
        responseMessage: usersSearchResponse?.message,
      });
    }

    return userProfiles;
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  getUserProfilesTableColumns() {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
      paddingRight: "1.5rem",
    };
    return [
      {
        dataField: "wsUserProfileUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "userName",
        text: "User Name",
        filter: textFilter({
          onFilter: this.filterUserNameColumn,
        }),
        formatter: (cell, row) => {
          return (
            <span>
              <Button variant="link" onClick={() => this.handleUserDetailsNavigation(row)}>
                <span className={globalStyles.formDataLinks}>{`${row.firstName} ${row.lastName}`}</span>
              </Button>
            </span>
          );
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px", color: "#0071BC" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700, width: "30%" },
      },
      {
        dataField: "profileName",
        text: "User Profile",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Profile Name Search" />
        ),
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
      {
        dataField: "profileAssociatedStates",
        text: "States",
        filter: textFilter({
          onFilter: this.filterProfileStates,
        }),
        formatter: (cell, row) => {
          let stateNamesArray =
            cell?.length > 0
              ? cell.map((stateId) => {
                  return this.props.states?.find((s) => s.stateUniqueIdentifier === stateId)?.state_code;
                })
              : "";

          let stateNames = "";
          if (stateNamesArray?.length > 0) {
            stateNames = stateNamesArray.join(", ");
          }
          if (stateNames?.length > 30) {
            stateNames = stateNames.substring(0, 29) + "...";
          }

          return stateNames;
        },
        sort: true,
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 700 },
      },
    ];
  }

  filterUserNameColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (entry) =>
          entry.firstName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          entry.lastName?.toLowerCase().includes(filterValue?.toLowerCase())
      );
    }
  };

  filterProfileStates = (filterValue, data) => {
    if (filterValue) {
      const filteredStateIds = this.props.states
        ?.filter(
          (state) =>
            state.state_code?.toLowerCase().includes(filterValue?.toLowerCase()) ||
            state.state_name?.toLowerCase().includes(filterValue?.toLowerCase())
        )
        ?.map((s) => s.stateUniqueIdentifier);
      return data.filter((entry) => filteredStateIds.some((id) => entry.profileAssociatedStates.includes(id)));
    }
  };

  handleSpecificChanges() {
    // do something
  }

  handleUserDetailsNavigation(userData) {
    // Future Implementation of User Navigation
  }

  isFormDataValid() {
    return this.state.userProfilesRowSelected?.length > 0;
  }

  doSubmit() {
    if (!this.isFormDataValid()) {
      toast.warning("At least one profile should be selected.");
      return;
    }

    this.props.onHide();
    const { usersTableData, userProfilesRowSelected } = this.state;
    this.props.onAddUsers(
      usersTableData.filter((userProfile) =>
        userProfilesRowSelected.includes(userProfile.wsUserProfileUniqueIdentifier)
      )
    );
    this.setState({ userProfilesRowSelected: [] });
  }

  handleModalCancel = () => {
    this.props.onHide();
    this.setState({ userProfilesRowSelected: [] });
  };

  getSearchUsersBody(inputValue = "") {
    return {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
  }

  handleUserProfilesRowOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        userProfilesRowSelected: [...this.state.userProfilesRowSelected, row?.wsUserProfileUniqueIdentifier],
      });
    } else {
      this.setState({
        userProfilesRowSelected: this.state.userProfilesRowSelected.filter(
          (x) => x !== row?.wsUserProfileUniqueIdentifier
        ),
      });
    }
  };

  handleOnSelectAllUserProfiles = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ userProfilesRowSelected: rows.map((r) => r.wsUserProfileUniqueIdentifier) });
    } else {
      this.setState({ userProfilesRowSelected: [] });
    }
  };

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "solid 0.5px #D8DDE6",
      };
    },
  };

  renderUserProfilesTable() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.userProfilesRowSelected,
      onSelect: this.handleUserProfilesRowOnSelect,
      onSelectAll: this.handleOnSelectAllUserProfiles,
      style: { backgroundColor: "#f5f5f5" },
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "1px solid #e8e8e8",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #dee2e6",
      },
    };

    const userProfilesData = this.state.usersTableData?.length > 0 ? this.state.usersTableData : [];

    return userProfilesData.length > 0 ? (
      <ToolkitProvider
        keyField="wsUserProfileUniqueIdentifier"
        data={userProfilesData}
        columns={this.state.userProfilesTableColumns}
        bootstrap4={true}
        hover={true}
      >
        {(props) => (
          <div>
            <BootstrapTable
              keyField="wsUserProfileUniqueIdentifier"
              filter={filterFactory()}
              bordered={false}
              condensed
              selectRow={selectRow}
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    ) : (
      ""
    );
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.addUsersModalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader}>
            <Row className={globalStyles.modalHeaderRow}>
              <Col className="px-0">
                <Modal.Title className={globalStyles.modalTitleText}>Add User</Modal.Title>
              </Col>
              <Col>
                <CloseButton onClick={this.handleModalCancel} />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <Row className="mx-0 my-3">
              <Col>{this.renderUserProfilesTable()}</Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className={this.isFormDataValid() ? "ml-2" : styles.greyedOutModalSaveButton} type="submit">
              <span
                className={this.isFormDataValid() ? globalStyles.modalSubmitButtonText : styles.greyedOutButtonText}
              >
                Save
              </span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default withRouter(AddDistrictUsersModal);
