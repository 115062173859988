import API from "../API";
import { APIRoutes } from "../APIRoutes";

class InventoryAPI {
  static async GetDeployedItemsOnProperty(propertyUniqueIdentifier) {
    const api = new API("/Inventory/GetDeployedItemsOnProperty/" + propertyUniqueIdentifier, APIRoutes.Inventory);
    const result = await api.get();
    return result;
  }

  static async GetAllWarehouseTypes() {
    const api = new API("/CMITS/GetAllWarehouseTypes/", APIRoutes.Inventory);
    const result = await api.get();
    return result;
  }

  static async GetWarehouse(warehouseId) {
    const api = new API(`/CMITS/GetWarehouse/${warehouseId}/`, APIRoutes.Inventory);
    const result = await api.get();
    return result;
  }

  static async CreateWarehouse(body) {
    let url = "/CMITS/CreateWarehouse";
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.post(body);
    return result;
  }

  static async UpdateWarehouse(warehouseId, body) {
    let url = `/CMITS/UpdateWarehouse/${warehouseId}`;
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.post(body);
    return result;
  }

  static async GetStateWarehousesByType(stateId, warehouseType) {
    let url = `/CMITS/GetStateWarehousesByType/${stateId}/${warehouseType}/0/0`;
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/CMITS/GetStateWarehousesByType/${stateId}/${warehouseType}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.Inventory);
      result = await api.get();
    }
    return result;
  }

  static async GetAllWarehousesByState(stateId) {
    let url = `/CMITS/GetAllWarehousesByState/${stateId}/0/0`;
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/CMITS/GetAllWarehousesByState/${stateId}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.Inventory);
      result = await api.get();
    }
    return result;
  }

  static async GetMyWarehousesByState(profileId, warehouseStateId) {
    let url = `/CMITS/GetMyWarehousesByState/${profileId}/${warehouseStateId}/0/0`;
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url = `/CMITS/GetMyWarehousesByState/${profileId}/${warehouseStateId}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.Inventory);
      result = await api.get();
    }
    return result;
  }

  static async GetMyWarehousesForStateByType(profileId, warehouseStateId, warehouseType) {
    const api = new API(
      `/CMITS/GetMyWarehousesForStateByType/${profileId}/${warehouseStateId}/${warehouseType}/100/1`,
      APIRoutes.Inventory
    );
    const result = await api.get();
    return result;
  }

  static async ActivateWarehouse(warehouseId) {
    let url = `/CMITS/ActivateWarehouse/${warehouseId}`;
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.post();
    return result;
  }

  static async DeactivateWarehouse(warehouseId) {
    let url = `/CMITS/DeactivateWarehouse/${warehouseId}`;
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.post();
    return result;
  }

  static async AddMethodsToWarehouse(warehouseId, body) {
    let url = `/CMITS/AddMethodsToWarehouse/${warehouseId}`;
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.post(body);
    return result;
  }

  static async GetAllTransactionTypes() {
    const api = new API(`/CMITS/GetAllTransactionTypes`, APIRoutes.Inventory);
    const result = await api.get();
    return result;
  }

  static async PerformWarehouseTransaction(body) {
    let url = "/CMITS/PerformWarehouseTransaction";
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.post(body);
    return result;
  }

  static async BulkPerformWarehouseTransactions(body) {
    const api = new API("/CMITS/BulkPerformWarehouseTransactions", APIRoutes.Inventory);
    const result = await api.post(body);
    return result;
  }

  static async GetAllCMITSLossTypes() {
    const api = new API(`/CMITS/GetAllCMITSLossTypes`, APIRoutes.Inventory);
    const result = await api.get();
    return result;
  }

  static async GetTransactionsForWarehouseForDateRange(body) {
    let url = "/CMITS/GetTransactionsForWarehouseForDateRange";
    let api = new API(url, APIRoutes.Inventory);
    let result = await api.put(body);
    return result;
  }
}

export default InventoryAPI;
