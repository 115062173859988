import React from "react";
import { withRouter } from "react-router-dom";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Dropdown,
  Accordion,
  AccordionContext,
  DropdownButton,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import CustomForm from "../common/form";
import styles from "./AerialWorkTask.module.scss";
import globalStyles from "../../OARS.module.scss";
import WebMap from "../ESRIIntegration/EsriMap";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import searchIcon from "../../assets/search.svg";
import chevronDownIcon from "../../assets/chevron-down.svg";
import ChevronRightAccordion from "../../assets/chevron-right.svg";
import ChevronDownAccordion from "../../assets/chevron-down.svg";
import { toast } from "react-toastify";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import UtilityFunctions from "../common/UtilityFunctions";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CreateConflictModal from "../Conflicts/CreateConflictModal";
import ConflictsAPI from "../../api/Conflicts/ConflictsAPI";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import EnterTakeModal from "../AgreementEntities/Properties/PropertyVisitSummary/EnterTakeModal";
import WorkTaskAPI from "../../api/WorkTask/WorkTaskAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import InventoryAPI from "../../api/Inventory/InventoryAPI";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const ChevronDown = () => {
  return <img src={chevronDownIcon} alt="down arrow" />;
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button ref={ref} iconafter={searchIcon} onClick={onClick} className={styles.crewDropdownMenuButton} variant="light">
    <Row>
      <Col className="text-left">
        <span className={styles.fieldsPlaceholder}>{children}</span>
      </Col>
      <Col className="text-right">
        <ChevronDown />
      </Col>
    </Row>
  </Button>
));

const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-label="Crew Menu">
      <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
    </div>
  );
});

class AerialWorkTask extends CustomForm {
  state = {
    data: {
      aerialWorkTaskDate: new Date().toLocaleDateString("en-CA"),
      aircraftType: "",
      aircraftId: "",
      selectedPilot: "",
      selectedActivity: "",
      totalFlightTime: 0.0,
      ferryTime: 0.0,
      isTrainingWorkTask: false,
      selectedProperty: "",
      properties: [],
      selectedCrewMembers: [],
      methodEntrySelectedMethod: "",
      methodEntrySelectedUse: "",
      methodEntrySelectedUom: "",
      methodEntryQuantity: "",
      methodEntrySelectedProperties: [],
      chosenMethodForProperty: "",
      propertyMethodSearchSelection: "",
      propertyMethodQuantity: "",
      propertyMethodChosenUse: "",
      propertyMethodChosenUOM: "",
      propertyMethodChosenProtections: [],
      propertyMethodChosenTargets: [],
      propertyMethodTargets: [],
      allowedMethodsForPropertyAgreement: [],
      workPerformedMethods: [],
    },
    isDetailsPage: this.props.isDetailsPage,
    isCompleteAWT: false,
    createdAerialWorkTask: null,
    canEditAerialInformation: false,
    currentUsaState: "",
    aircraftTypes: [],
    aircraftIDs: [],
    activityTypes: [],
    isCrewMenuOpen: false,
    currentCrewMember: "",
    showCreateSuccessModal: false,
    isMainPage: true,
    showCreateConflictModal: false,
    availableMethodsToSelect: [],
    availableUsesToSelect: [],
    availableUOMsToSelect: [],
    allMethodUOMs: [],
    allMethodUses: [],
    workPerformedTableColumns: [
      { dataField: "methodUniqueIdentifier", hidden: true },
      { dataField: "test", text: "Loading" },
    ],
    showEnterTakeModal: false,
    enterTakeData: { workItem: "" },
    isLoading: false,
    errors: {},
  };

  async componentDidMount() {
    let { data } = this.state;
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    this.validateStateData(currentUsaState);
    this.setState({ isLoading: true });
    if (this.state.isDetailsPage) {
      let aerialStructuredSearchData = this.props.location.state?.entityData;
      if (aerialStructuredSearchData?.aerialWorkTaskUniqueIdentifier) {
        const getAerialWorkTaskResponse = await WorkTaskAPI.GetAerialWorkTask(
          aerialStructuredSearchData.aerialWorkTaskUniqueIdentifier
        );
        if (getAerialWorkTaskResponse?.successful) {
          this.setState(
            {
              currentUsaState,
              createdAerialWorkTask: getAerialWorkTaskResponse.data,
              isCompleteAWT: UtilityFunctions.getDisplayTextFromFieldObject(
                getAerialWorkTaskResponse.data?.aerialWorkTaskStatus
              )
                .toLowerCase()
                .includes("complete"),
            },
            async () => await this.mapAerialWorkTaskEntityData()
          );
        } else if (getAerialWorkTaskResponse?.unsuccessful) {
          ErrorHandler.showErrorWithDetails(
            "Unable to retrieve Aerial Work Task. " + getAerialWorkTaskResponse?.message ?? "",
            "Unable to retrieve Aerial Work Task. " + getAerialWorkTaskResponse?.message ?? ""
          );
        } else {
          ErrorHandler.showErrorWithMessage(
            "Unable to retrieve Aerial Work Task. Please try again later or contact your system admin if the issue persists."
          );
        }
      }
    }
    this.setState(
      {
        data,
        currentUsaState,
        aircraftTypes: await this.getAircraftTypes(),
        aircraftIDs: await this.getAircraftIDs(currentUsaState),
        activityTypes: await this.getActivityTypes(),
        availableMethodsToSelect: await this.getAvailableMethods(),
        workPerformedTableColumns: this.getWorkPerformedColumns(),
        allMethodUOMs: await this.getAllMethodUOMs(),
        allMethodUses: await this.getAllMethodUses(),
      },
      () => {
        this.setState({ isLoading: false });
      }
    );

    let element = document.getElementById("aircraftTypeCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "aircraft type");
    element = document.getElementById("aircraftIdCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "aircraft id");
    element = document.getElementById("selectedPilotCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "selected pilot");
    element = document.getElementById("selectedActivityCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "selected activity");
  }

  mapAerialWorkTaskEntityData = async () => {
    let { data, createdAerialWorkTask } = this.state;

    data.aircraftId = createdAerialWorkTask?.airCraftUniqueIdentifier;
    data.selectedActivity = createdAerialWorkTask?.activityTypeEnum?.id;
    data.totalFlightTime = createdAerialWorkTask?.totalHobbsHours;
    data.ferryTime = createdAerialWorkTask?.totalFerryHours;
    data.isTrainingWorkTask = createdAerialWorkTask?.isTraining;
    const getAircraftData = await this.getAircraft(createdAerialWorkTask?.airCraftUniqueIdentifier);
    data.aircraftType = getAircraftData ? getAircraftData.aircraftType?.id : "";

    const datePerformed = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(
      createdAerialWorkTask.datePerformed
    ).toLocaleDateString("en-CA");
    data.aerialWorkTaskDate = datePerformed;

    const pilot = createdAerialWorkTask?.pilot;
    pilot.label = pilot.userName;
    pilot.value = pilot.userUniqueReference;
    data.selectedPilot = pilot;

    const crewMembers = createdAerialWorkTask?.airCraftCrew.map((c) => {
      c.label = c.userName;
      c.value = c.userUniqueReference;
      return c;
    });
    data.selectedCrewMembers = crewMembers;

    const visitedProperties = await createdAerialWorkTask?.propertiesVisitedOnAerialWorkTask?.map(async (property) => {
      const conflicts = await this.getConflictsForGivenProperty(data, property);
      if (conflicts) {
        let selectedConflicts = property.propertyConflicts?.map((propertyConflict) => {
          property.propertyAssociatedConflicts.forEach((aerialConflict) => {
            if (aerialConflict.conflictUniqueReference === propertyConflict.conflictUniqueIdentifier) {
              propertyConflict.isSelected = true;
              if (
                !data.propertyMethodTargets?.find(
                  (t) => t.targetedSpeciesUniqueReference === propertyConflict.targetedSpeciesUniqueReference
                )
              ) {
                data.propertyMethodTargets.push({
                  targetedSpeciesUniqueReference: propertyConflict.targetedSpeciesUniqueReference,
                  targetedSpeciesName: propertyConflict.targetedSpeciesName,
                });
              }
              aerialConflict.targetProtectedResources.forEach((r) => {
                let foundResource = propertyConflict.protectedResources.find(
                  (pR) => pR.protectedResourceUniqueReference === r.protectedResourceUniqueReference
                );
                if (foundResource) {
                  r.valuePerUnitOfMeasure = foundResource.valuePerUnitOfMeasure;
                  r.effortPercentage = parseFloat(r.protectedResourceEffortRatio);
                  r.hobbsHours = parseFloat(
                    ((parseFloat(r.effortPercentage) / 100) * property.propertyHobbsHours).toFixed(2)
                  );
                  property.resourcesEffort.push(r);
                }
              });
            }
          });
          return propertyConflict;
        });
      }

      property.totalFlightTime = property.propertyHobbsHours ?? 0.0;
      property.ferryTime = property.propertyFerryTime ?? 0.0;
      property.aerialPropertyObject = JSON.parse(JSON.stringify(property));
      property.selectedCrewMembers = JSON.parse(JSON.stringify(property.aerialPropertyCrewMembers));
      property.propertyUniqueIdentifier = property.propertyUniqueReference;
      return property;
    });
    await Promise.all(visitedProperties).then((responses) => {
      data.properties = responses;
    });
    this.setState({ data });
  };

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  async getAircraft(airCraftUniqueIdentifier) {
    const getAircraftResponse = await ReferenceFileAPI.GetAircraft(airCraftUniqueIdentifier);
    if (getAircraftResponse?.successful) {
      return getAircraftResponse.data;
    } else {
      ErrorHandler.showErrorWithDetails(
        "Unable to retrieve Aircraft. " + getAircraftResponse?.message ?? "",
        "Unable to retrieve Aircraft. " + getAircraftResponse?.message ?? ""
      );
      return;
    }
  }

  async getAircraftTypes() {
    let aircraftTypes = [];
    const aircrftTypesResponse = await ReferenceFileAPI.GetAllAircraftType();
    if (aircrftTypesResponse?.successful && aircrftTypesResponse.data?.length > 0) {
      aircraftTypes = aircrftTypesResponse.data.map((t) => ({
        id: t.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(t),
      }));
    }
    return aircraftTypes;
  }

  async getAircraftIDs(currentUsaState) {
    let aircraftIDs = [];
    if (currentUsaState.stateGuid) {
      const aircraftsByStateResponse = await ReferenceFileAPI.GetAircraftsByState(currentUsaState.stateGuid);
      if (aircraftsByStateResponse?.successful && aircraftsByStateResponse.data?.length > 0) {
        aircraftIDs = aircraftsByStateResponse.data.map((a) => ({
          id: a.aircraftUniqueIdentifier,
          name: a.aircraftTailNumber,
        }));
      }
    }
    return aircraftIDs;
  }

  async getActivityTypes() {
    const getAerialWorkTaskActivityTypesResponse = await WorkTaskAPI.GetAerialWorkTaskActivityTypes();
    if (getAerialWorkTaskActivityTypesResponse?.data) {
      return (
        getAerialWorkTaskActivityTypesResponse?.data.map((type) => {
          type.name = type.displayText;
          return type;
        }) ?? []
      );
    } else {
      ErrorHandler.showErrorWithDetails(
        "Unable to retrieve Aerial Work Task activity types. " + getAerialWorkTaskActivityTypesResponse?.message ?? ""
      );
      return [];
    }
  }

  async getAllMethodUOMs() {
    const getAllMethodUOMsResponse = await ReferenceFileAPI.GetAllMethodUOMs();
    if (getAllMethodUOMsResponse?.successful) {
      return getAllMethodUOMsResponse.data;
    } else {
      ErrorHandler.showErrorWithDetails(
        "Unable to retrieve all Method UOMs. " + getAllMethodUOMsResponse?.message ?? ""
      );
      return [];
    }
  }

  async getAllMethodUses() {
    const getAllMethodUOMsResponse = await ReferenceFileAPI.GetAllMethodUses();
    if (getAllMethodUOMsResponse?.successful) {
      return getAllMethodUOMsResponse.data;
    } else {
      ErrorHandler.showErrorWithDetails(
        "Unable to retrieve all Method Uses. " + getAllMethodUOMsResponse?.message ?? ""
      );
      return [];
    }
  }

  async getAvailableMethods() {
    let availableMethods = [];
    const methodsByTypeResponse = await ReferenceFileAPI.GetAllMethodsByType("SUPPORT VEHICLE");
    if (methodsByTypeResponse?.successful && methodsByTypeResponse.data?.results?.length > 0) {
      availableMethods = methodsByTypeResponse.data.results
        .filter((m) => m.methodName === "HELICOPTER" || m.methodName === "FIXED WING")
        .map((method) => ({
          label: method.methodName,
          value: method.methodUniqueIdentifier,
        }));
    }

    return availableMethods;
  }

  async updateAllowedMethodsOnProperty(data) {
    if (data.selectedProperty.agreementData) {
      let agreementProperty = data.selectedProperty.agreementData?.agreementProperties?.find(
        (property) => property.propertyUniqueReference === data.propertyData.propertyUniqueIdentifier
      );
      data.allowedMethods = [...data.allowedMethods, ...agreementProperty?.allowedMethods];
    }
  }

  getWorkPerformedColumns() {
    return [
      {
        dataField: "methodUniqueIdentifier",
        hidden: true,
      },
      this.getWorkPerformedMethodNameColumn(),
      this.getWorkPerformedUomColumn(),
      this.getWorkPerformedActionQtyColumn(),
      this.getWorkPerformedActionTakenColumn(),
      this.getWorkPerformedActionsColumn(),
    ];
  }

  getWorkPerformedMethodNameColumn() {
    return {
      dataField: "methodName",
      text: "Method",
      headerStyle: () => {
        return {
          width: "15%",
          fontSize: "14px",
          fontWeight: "600",
          borderBottom: "0.8px solid #313131",
          textAlign: "left",
          paddingLeft: "0",
          paddingBottom: "0.5rem",
        };
      },
      style: () => {
        return {
          fontSize: "14px",
          fontWeight: "400",
          borderBottom: "1px solid #E8E8E8",
          textAlign: "left",
          paddingLeft: "0",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
        };
      },
      align: "left",
      headerAlign: "left",
      headerAttrs: { id: "methodNameHeader" },
      attrs: { headers: "methodNameHeader", tabIndex: "0" },
    };
  }

  getWorkPerformedUomColumn() {
    return {
      dataField: "methodAllowedUOMId",
      text: "UOM",
      headerStyle: () => {
        return {
          width: "10%",
          fontSize: "14px",
          fontWeight: "600",
          borderBottom: "0.8px solid #313131",
          textAlign: "left",
          paddingBottom: "0.5rem",
        };
      },
      style: () => {
        return {
          fontSize: "14px",
          fontWeight: "400",
          borderBottom: "1px solid #E8E8E8",
          textAlign: "left",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
        };
      },
      formatter: (cell, row) => {
        let uom = this.state.allMethodUOMs.find((uom) => uom.id === cell);
        return uom?.displayText ? uom?.displayText : "";
      },
      align: "left",
      headerAlign: "left",
      headerAttrs: { id: "methodUomHeader" },
      attrs: { headers: "methodUomHeader", tabIndex: "0" },
    };
  }

  getWorkPerformedActionQtyColumn() {
    return {
      dataField: "methodQtyAffected",
      text: "Action Qty",
      headerStyle: () => {
        return {
          width: "10%",
          fontSize: "14px",
          fontWeight: "600",
          borderBottom: "0.8px solid #313131",
          textAlign: "left",
          paddingBottom: "0.5rem",
        };
      },
      style: () => {
        return {
          fontSize: "14px",
          fontWeight: "400",
          borderBottom: "1px solid #E8E8E8",
          textAlign: "left",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
        };
      },
      align: "left",
      headerAlign: "left",
      headerAttrs: { id: "actionQuantityHeader" },
      attrs: { headers: "actionQuantityHeader", tabIndex: "0" },
    };
  }

  getWorkPerformedActionTakenColumn() {
    return {
      dataField: "methodAllowedUseEnumId",
      text: "Action Taken",
      headerStyle: () => {
        return {
          width: "15%",
          fontSize: "14px",
          fontWeight: "600",
          borderBottom: "0.8px solid #313131",
          textAlign: "left",
          paddingBottom: "0.5rem",
        };
      },
      style: () => {
        return {
          fontSize: "14px",
          fontWeight: "400",
          borderBottom: "1px solid #E8E8E8",
          textAlign: "left",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
        };
      },
      formatter: (cell, row) => {
        let use = this.state.allMethodUses.find((use) => use.id === cell);
        return use?.displayText ? use?.displayText : "";
      },
      align: "left",
      headerAlign: "left",
      headerAttrs: { id: "actionTakenHeader" },
      attrs: { headers: "actionTakenHeader", tabIndex: "0" },
    };
  }

  getWorkPerformedActionsColumn() {
    return {
      dataField: "workPerformedActions",
      text: "Actions",
      headerStyle: () => {
        return {
          fontSize: "14px",
          fontWeight: "600",
          borderBottom: "0.8px solid #313131",
          paddingBottom: "0.5rem",
        };
      },
      style: () => {
        return {
          fontSize: "14px",
          fontWeight: "400",
          borderBottom: "1px solid #E8E8E8",
          paddingRight: "0",
          paddingBottom: "0",
          paddingTop: "0",
        };
      },
      formatter: (cell, row) => {
        return (
          <DropdownButton
            id="dropdown-work-performed-actions"
            title={<span className={globalStyles.formDataLinks}>Actions</span>}
            variant="link"
            className={styles.workPerformedActionsDropdown}
          >
            <Dropdown.Item eventKey="workPerformedAction1Field" onSelect={() => this.showEnterTakeModal(row)}>
              <span className={styles.dropdownActionItems}>Enter Take</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="workPerformedAction2Field" onSelect={() => this.deleteWorkPerformed(row)}>
              <span className={styles.dropdownActionItems}>Remove</span>
            </Dropdown.Item>
          </DropdownButton>
        );
      },
      align: "right",
      headerAlign: "right",
      headerAttrs: { id: "workPerformedActionsHeader" },
      attrs: { headers: "workPerformedActionsHeader", tabIndex: "0" },
    };
  }

  deleteWorkPerformed = (input) => {
    const { data } = this.state;
    const inputAerialWorkPerformedUniqueIdentifier = input?.aerialWorkPerformedUniqueIdentifier;
    if (inputAerialWorkPerformedUniqueIdentifier) {
      data.selectedProperty.aerialPropertyObject.workPerformed =
        data.selectedProperty.aerialPropertyObject.workPerformed.filter(
          (w) => w?.aerialWorkPerformedUniqueIdentifier !== inputAerialWorkPerformedUniqueIdentifier
        );
      if (data.selectedProperty.workPerformedToRemove?.length > 0) {
        data.selectedProperty.workPerformedToRemove.push(inputAerialWorkPerformedUniqueIdentifier);
      } else {
        data.selectedProperty.workPerformedToRemove = [inputAerialWorkPerformedUniqueIdentifier];
      }
    }
    this.setState({ data });
  };

  handleTrainingSelection = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isTrainingWorkTask = input.checked;
    this.setState({ data });
  };

  loadAvailableProperties = async (inputValue) => {
    const { currentUsaState } = this.state;
    let propertiesResult = [];
    if (currentUsaState?.stateGuid) {
      const propertiesData = await AgreementEntitiesAPI.SearchPropertiesFreeText(
        this.getSearchPropertiesBody(inputValue),
        currentUsaState.stateGuid
      );

      if (propertiesData?.data?.results) {
        propertiesResult = propertiesData.data.results.map((result) => ({
          value: result.propertyUniqueIdentifier,
          label: result.propertyName,
        }));
      }
    }

    return propertiesResult;
  };

  loadAvailableCrewMembers = async (inputValue) => {
    let crewMembersResult = await this.getUsersSearchResults(inputValue);
    return crewMembersResult.filter(
      (u) =>
        !this.state.data.selectedCrewMembers.some(
          (c) => c.wsUserUniqueIdentifier === u.value || c.userUniqueReference === u.value
        )
    );
  };

  loadAvailablePilots = async (inputValue) => {
    return await this.getUsersSearchResults(inputValue);
  };

  async getUsersSearchResults(inputValue) {
    let usersSearchResult = [];
    if (this.state.currentUsaState?.stateGuid) {
      const usersSearchResponse = await RoleAuthorizationAPI.SearchUsersFreeText({
        textToSearchFor: inputValue,
        pageSize: 10,
        pageNumber: 1,
        stateUniqueReference: this.state.currentUsaState.stateGuid,
      });
      if (usersSearchResponse?.successful && usersSearchResponse.data?.results?.length > 0) {
        let results = usersSearchResponse.data.results;
        for (let i = 0; i < results.length; i++) {
          let userObject;
          let user = results[i];
          for (let j = 0; j < user.userProfiles.length; j++) {
            let userProfile = user.userProfiles[j];
            if (userProfile) {
              userObject = {
                value: userProfile.wsUserProfileUniqueIdentifier,
                label: user.firstName + " " + user.lastName,
                profileName: userProfile.profileName,
                wsUserUniqueIdentifier: userProfile.wsUserProfileUniqueIdentifier,
                wsUserName: user.firstName + " " + user.lastName,
              };
              usersSearchResult.push(userObject);
            }
          }
        }
      }
    }
    return usersSearchResult;
  }

  getSearchPropertiesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 0,
    };
  }

  handlePropertySelection = async (input) => {
    const { data } = this.state;
    this.setState({ isPropertySelectionLoading: true });
    const responseData = await AgreementEntitiesAPI.GetProperty(input.value);
    const propertyData = responseData?.data;
    if (propertyData) {
      if (propertyData.propertyUniqueIdentifier) {
        await this.getConflictsForGivenProperty(data, propertyData);
      }
      data.selectedProperty = propertyData;
    }
    this.setState({ data, isPropertySelectionLoading: false });
  };

  async getConflictsForGivenProperty(data, property) {
    const conflictsForPropertyResponse = await ConflictsAPI.GetConflictsByProperty(
      property.propertyUniqueIdentifier || property.propertyUniqueReference
    );
    if (conflictsForPropertyResponse?.successful && conflictsForPropertyResponse.data?.results?.length > 0) {
      property.propertyConflicts = conflictsForPropertyResponse.data.results.map((conflict) => {
        const conflictProperty = conflict.properties.find(
          (p) =>
            p.propertyUniqueReference === property.propertyUniqueIdentifier ||
            p.propertyUniqueReference === property.propertyUniqueReference
        );
        return {
          conflictUniqueIdentifier: conflict.conflictUniqueIdentifier,
          targetedSpeciesUniqueReference: conflict.targetedSpeciesUniqueReference,
          targetedSpeciesName: conflict.targetedSpeciesName,
          conflictPropertyUniqueIdentifier: conflictProperty?.conflictPropertyUniqueIdentifier,
          protectedResources: conflictProperty?.protectedResources,
        };
      });
      property.resourcesEffort = [];
      return true;
    }
    return false;
  }

  handleCrewMemberSelection = async (input) => {
    const { data } = this.state;
    if (!input) {
      data.selectedCrewMembers = [];
    } else {
      input.wsUserUniqueIdentifier = input.value;
      input.wsUserName = input.label;
      data.selectedCrewMembers = input;
      data.properties.forEach((property) => {
        property.selectedCrewMembers.push({
          ...input,
          hobbsTime: property.totalFlightTime,
          hobbsHours: property.totalFlightTime,
        });
      });
    }
    this.setState({ data });
  };

  handlePilotSelection = (input) => {
    const { data } = this.state;
    data.selectedPilot = input;
    this.setState({ data });
  };

  handlePropertyAddition = () => {
    const { data } = this.state;
    if (
      data.properties.find(
        (property) => property.propertyUniqueIdentifier === data.selectedProperty.propertyUniqueIdentifier
      )
    ) {
      toast.warning("This property was already added to the Aerial Work Task");
    } else {
      data.selectedProperty.selectedCrewMembers = JSON.parse(JSON.stringify(data.selectedCrewMembers));
      data.selectedProperty.workPerformedMethods =
        data.workPerformedMethods?.length > 0 ? JSON.parse(JSON.stringify(data.workPerformedMethods)) : [];
      data.properties.push(data.selectedProperty);
      data.selectedProperty = "";
      this.setState({ data });
    }
  };

  toggleCrewMenuOpen = () => {
    this.setState((state) => ({ isCrewMenuOpen: !state.isCrewMenuOpen }));
  };

  handlePropertyHobbsTimeChange = ({ currentTarget: input }, property) => {
    const data = { ...this.state.data };
    const currentProperty = data.properties.find(
      (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
    );
    const oldTimeValue = currentProperty[input.name];
    currentProperty[input.name] = Number.parseFloat(input.value);

    const isValidTimeValue =
      input.name === "totalFlightTime" ? this.isTotalFlightTimeValid(data) : this.isFerryTimeValid(data);
    //Hack commentted this out to resolve the bug related to not being able to remove the 0 in ferry and total hobbs hours
    // if (!isValidTimeValue) {
    //   currentProperty[input.name] = oldTimeValue;
    //} else 
    if (input.name === "totalFlightTime") {
      if (currentProperty.selectedCrewMembers?.length > 0 && input.value > 0) {
        currentProperty.selectedCrewMembers.forEach((member) => {
          member.hobbsTime = input.value;
          member.hobbsHours = input.value;
        });
      }
      if (currentProperty.resourcesEffort?.length > 0) {
        this.updateResourceEffortListOnProperty(currentProperty);
      }
    }

    this.setState({ data });
  };

  isTotalFlightTimeValid(data) {
    let isTotalTimeValid = true;

    let totalFlightTimeInProperties = this.getTotalFlightTimeForProperties(data);
    if (totalFlightTimeInProperties > Number.parseFloat(data.totalFlightTime)) {
      toast.warning(
        "The sum of total flight times in the properties cannot be greater than the Total Flight Time on the Work task."
      );
      isTotalTimeValid = false;
    } else {
      data.ferryTime = Number.parseFloat(data.totalFlightTime) - totalFlightTimeInProperties;
    }

    return isTotalTimeValid;
  }

  isFerryTimeValid(data) {
    let isFerryTimeValid = true;

    let totalFerryTimeInProperties = this.getTotalFerryTimeInProperties(data);

    if (totalFerryTimeInProperties > Number.parseFloat(data.ferryTime)) {
      toast.warning(
        "The sum of total ferry times in the properties cannot be greater than the Total Ferry Time on the Work task."
      );
      isFerryTimeValid = false;
    }

    return isFerryTimeValid;
  }

  getTotalFlightTimeForProperties(data) {
    let totalFlightTimeInProperties = 0.0;
    data.properties.forEach((p) => {
      if (p.totalFlightTime) {
        totalFlightTimeInProperties += Number.parseFloat(p.totalFlightTime);
      }
    });
    return totalFlightTimeInProperties;
  }

  getTotalFerryTimeInProperties(data) {
    let totalFerryTimeInProperties = 0;
    data.properties.forEach((p) => {
      if (p.ferryTime) {
        totalFerryTimeInProperties += Number.parseFloat(p.ferryTime);
      }
    });
    return totalFerryTimeInProperties;
  }

  addPropertyToAerialWorkTask = async (property) => {
    let { data, createdAerialWorkTask } = this.state;
    let postArray = [{
      propertyUniqueReference: property.propertyUniqueIdentifier,
      hobbsHours: property.totalFlightTime,
      projectUniqueReferences: [],
      propertyFerryTime: property.ferryTime,
    }];
    const addPropertyToAerialWorkTaskResponse = await WorkTaskAPI.AddPropertiesToAerialWorkTask(
      createdAerialWorkTask.aerialWorkTaskUniqueIdentifier,
      { propertiesVisited: postArray }
    );
    if (addPropertyToAerialWorkTaskResponse?.successful) {
      this.mapAerialPropertiesToPropertyList(
        data,
        addPropertyToAerialWorkTaskResponse.data.propertiesVisitedOnAerialWorkTask ?? []
      );
      this.setState({ data, createdAerialWorkTask: addPropertyToAerialWorkTaskResponse.data });
    } else {
      ErrorHandler.showErrorWithMessage("Unable to add property to Aerial Work Task.");
    }
  }

  addPropertiesToAerialWorkTask = async () => {
    let { data, createdAerialWorkTask } = this.state;
    if (createdAerialWorkTask) {
      if (createdAerialWorkTask.totalFerryHours !== parseFloat(data.ferryTime)) {
        await this.editAerialInformation({ fromAddPropertyMethod: true });
      }
      let postArray = [];
      data.properties.forEach((property) => {
        if (!property.aerialPropertyObject) {
          let postObj = {
            propertyUniqueReference: property.propertyUniqueIdentifier,
            hobbsHours: property.totalFlightTime,
            projectUniqueReferences: [],
            propertyFerryTime: property.ferryTime,
          };
          postArray.push(postObj);
        }
      });
      if (postArray.length > 0) {
        const addPropertiesToAerialWorkTaskResponse = await WorkTaskAPI.AddPropertiesToAerialWorkTask(
          this.state.createdAerialWorkTask.aerialWorkTaskUniqueIdentifier,
          { propertiesVisited: postArray }
        );
        if (addPropertiesToAerialWorkTaskResponse?.successful) {
          toast.success("Added Property to Aerial Work Task");
          this.mapAerialPropertiesToPropertyList(
            data,
            addPropertiesToAerialWorkTaskResponse.data.propertiesVisitedOnAerialWorkTask ?? []
          );
          this.setState({ data, createdAerialWorkTask: addPropertiesToAerialWorkTaskResponse.data });
        } else if (addPropertiesToAerialWorkTaskResponse?.unsuccessful) {
          ErrorHandler.showErrorWithDetails(
            "Unable to add property to Aerial Work Task.",
            addPropertiesToAerialWorkTaskResponse.message
          );
        } else {
          ErrorHandler.showErrorWithMessage("Unable to add property to Aerial Work Task.");
        }
      } else {
        toast.warning("No new properties to add.");
      }
    }
  };

  mapAerialPropertiesToPropertyList(data, aerialProperties) {
    aerialProperties.forEach((aerialProperty) => {
      let matchingProperty = data.properties.find(
        (property) => property.propertyUniqueIdentifier === aerialProperty.propertyUniqueReference
      );
      if (matchingProperty) {
        matchingProperty.aerialPropertyObject = aerialProperty;
      }
    });
  }

  async getActiveAgreementByProperty(propertyGuid) {
    const getActiveAgreementByPropertyResponse = await AgreementAPI.GetActiveAgreementByProperty(propertyGuid);
    if (getActiveAgreementByPropertyResponse?.successful) {
      return getActiveAgreementByPropertyResponse?.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve Agreement for this propery",
        apiName: "GetActiveAgreementByProperty",
        responseMessage: getActiveAgreementByPropertyResponse?.message,
        responseUnsuccessful: getActiveAgreementByPropertyResponse?.unsuccessful,
      });
    }
  }

  async getWarehousesData(stateId) {
    let warehousesData = [];
    const userId = JSON.parse(localStorage.getItem("currentUserRole"))?.wsUserProfileUniqueIdentifier;
    if (userId) {
      const getWarehousesResponse = await InventoryAPI.GetMyWarehousesByState(userId, stateId);
      if (getWarehousesResponse?.successful) {
        warehousesData = getWarehousesResponse.data.results.filter((w) => w.isActive) ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve warehouses in state",
          apiName: "GetMyWarehousesByState",
          responseUnsuccessful: getWarehousesResponse?.unsuccessful,
          responseMessage: getWarehousesResponse?.message,
        });
      }
    }
    return warehousesData;
  }

  enterPropertyData = async (property) => {
    const { data } = this.state;
    if (Number.parseFloat(property.totalFlightTime) > 0) {

      if (!property.aerialPropertyObject) {
        await this.addPropertyToAerialWorkTask(property);
      }

      data.selectedProperty = JSON.parse(JSON.stringify(property));
      let agreementData = await this.getActiveAgreementByProperty(
        property.propertyUniqueIdentifier || property.propertyUniqueReference
      );
      let availableWarehouses = await this.getWarehousesData(this.state.currentUsaState.stateGuid);
      data.selectedProperty.availableWarehouses = availableWarehouses;
      data.selectedProperty.agreementData = agreementData;
      let agreementProperty = agreementData?.agreementProperties?.find(
        (p) =>
          p.propertyUniqueReference === property.propertyUniqueIdentifier ||
          p.propertyUniqueReference === property.propertyUniqueReference
      );
      data.selectedProperty.allowedMethods = agreementProperty?.allowedMethods;
      this.setState({ data, isMainPage: false });
    } else {
      toast.warning("Total Flight Time for the property must be provided.");
    }
  };

  removePropertyData = async (property) => {
    const { data } = this.state;

    if (!property.aerialPropertyObject) {
      toast.success("Property removed from Aerial Work Task.");
      data.properties = data.properties.filter((p) => p.propertyUniqueIdentifier !== property.propertyUniqueIdentifier);
      data.selectedProperty = "";
      this.setState({ data });
      return;
    }

    let postArray = [property.propertyUniqueIdentifier];
    const removePropertiesFromAerialWorkTaskResponse = await WorkTaskAPI.RemovePropertiesFromAerialWorkTask(
      this.state.createdAerialWorkTask?.aerialWorkTaskUniqueIdentifier,
      postArray
    );
    if (removePropertiesFromAerialWorkTaskResponse?.successful) {
      toast.success("Property removed from Aerial Work Task.");
      data.properties = data.properties.filter((p) => p.propertyUniqueIdentifier !== property.propertyUniqueIdentifier);
      this.setState({ createdAerialWorkTask: removePropertiesFromAerialWorkTaskResponse.data, data });
    } else if (removePropertiesFromAerialWorkTaskResponse?.unsuccessful) {
      ErrorHandler.showErrorWithDetails(
        "Unable to Remove Property from Aerial Work Task. " + removePropertiesFromAerialWorkTaskResponse?.message,
        "Unable to Remove Property from Aerial Work Task. " + removePropertiesFromAerialWorkTaskResponse?.message
      );
    } else {
      ErrorHandler.showErrorWithDetails(
        "Unable to Remove Property from Aerial Work Task. RemovePropertiesFromAerialWorkTask failed.",
        "Unable to Remove Property from Aerial Work Task."
      );
    }
  };

  handleCrewHobbsTimeChange = ({ currentTarget: input }, crewMember) => {
    const { data } = this.state;
    if (
      data.selectedProperty?.totalFlightTime &&
      Number.parseFloat(input.value) > Number.parseFloat(data.selectedProperty.totalFlightTime)
    ) {
      toast.warning("Hobbs Time for a crew member can not be greater than the Property total flight time.");
      return;
    }

    const currentCrewMember = data.selectedProperty?.selectedCrewMembers?.find((c) => {
      if (crewMember.wsUserUniqueIdentifier) {
        return c.wsUserUniqueIdentifier === crewMember.wsUserUniqueIdentifier;
      }
      if (crewMember.userUniqueReference) {
        return c.userUniqueReference === crewMember.userUniqueReference;
      }
      return false;
    });
    if (currentCrewMember) {
      currentCrewMember.hobbsTime = input.value;
      currentCrewMember.hobbsHours = input.value;
    }

    this.setState({ data });
  };

  removeCrewMember = (crewMember) => {
    const { data } = this.state;
    data.selectedProperty.selectedCrewMembers = data.selectedProperty?.selectedCrewMembers?.filter(
      (c) =>
        c.wsUserUniqueIdentifier !== crewMember.wsUserUniqueIdentifier ||
        c.userUniqueReference !== crewMember.userUniqueReference
    );
    this.setState({ data });
  };

  onCreateConflictModalClose = (conflict) => {
    const { data } = this.state;
    if (conflict) {
      const conflictProperty = conflict.properties.find(
        (p) =>
          p.propertyUniqueReference === data.selectedProperty?.propertyUniqueIdentifier ||
          p.propertyUniqueReference === data.selectedProperty?.propertyUniqueReference
      );
      const mainProperty = data.properties.find(
        (p) =>
          p.propertyUniqueReference === data.selectedProperty?.propertyUniqueIdentifier ||
          p.propertyUniqueReference === data.selectedProperty?.propertyUniqueReference
      );
      const conflictToAdd = {
        conflictUniqueIdentifier: conflict.conflictUniqueIdentifier,
        targetedSpeciesUniqueReference: conflict.targetedSpeciesUniqueReference,
        targetedSpeciesName: conflict.targetedSpeciesName,
        conflictPropertyUniqueIdentifier: conflictProperty?.conflictPropertyUniqueIdentifier,
        protectedResources: conflictProperty?.protectedResources,
      };
      if (data.selectedProperty.propertyConflicts?.length > 0) {
        data.selectedProperty.propertyConflicts.push(conflictToAdd);
      } else {
        data.selectedProperty.propertyConflicts = [conflictToAdd];
        data.selectedProperty.resourcesEffort = [];
        mainProperty.propertyConflicts = [conflictToAdd];
        mainProperty.resourcesEffort = [];
      }
      if (mainProperty.propertyConflicts?.length > 0) {
        mainProperty.propertyConflicts.push(conflictToAdd);
      } else {
        mainProperty.propertyConflicts = [conflictToAdd];
        mainProperty.resourcesEffort = [];
      }
    }
    this.setState({ data, showCreateConflictModal: false });
  };

  createConflict = () => {
    this.setState({ showCreateConflictModal: true });
  };

  selectConflict = async (e, conflict) => {
    const { data, createdAerialWorkTask } = this.state;
    const conflictToUpdate = data.selectedProperty.propertyConflicts.find(
      (c) => c.conflictPropertyUniqueIdentifier === conflict.conflictPropertyUniqueIdentifier
    );
    if (conflictToUpdate && createdAerialWorkTask?.propertiesVisitedOnAerialWorkTask?.length > 0) {
      if (e.target.checked) {
        conflictToUpdate.isSelected = true;
        if (
          !data.propertyMethodTargets?.find(
            (t) => t.targetedSpeciesUniqueReference === conflictToUpdate.targetedSpeciesUniqueReference
          )
        ) {
          data.propertyMethodTargets.push({
            targetedSpeciesUniqueReference: conflict.targetedSpeciesUniqueReference,
            targetedSpeciesName: conflict.targetedSpeciesName,
          });
        }
        conflictToUpdate.protectedResources.forEach((resource) => {
          if (
            !data.selectedProperty.resourcesEffort?.some(
              (r) =>
                r.resourceUniqueReference === resource.resourceUniqueReference &&
                r.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure
            )
          ) {
            data.selectedProperty.resourcesEffort.push({
              protectedResourceName: resource.protectedResourceName,
              protectedResourceUniqueReference: resource.protectedResourceUniqueReference,
              resourceUniqueReference: resource.resourceUniqueReference,
              valuePerUnitOfMeasure: resource.valuePerUnitOfMeasure,
              hobbsHours: "",
              effortPercentage: "",
            });
          }
        });
      } else {
        conflictToUpdate.isSelected = false;
        data.selectedProperty.resourcesEffort = data.selectedProperty.resourcesEffort.filter(
          (resource) =>
            !conflictToUpdate.protectedResources.some(
              (r) =>
                r.protectedResourceUniqueReference === resource.protectedResourceUniqueReference &&
                r.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure
            )
        );
        data.propertyMethodChosenProtections = data.propertyMethodChosenProtections.filter(
          (resource) =>
            !conflictToUpdate.protectedResources.some(
              (r) =>
                r.protectedResourceUniqueReference === resource.protectedResourceUniqueReference &&
                r.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure
            )
        );
        data.propertyMethodTargets = data.propertyMethodTargets.filter(
          (t) => t.targetedSpeciesUniqueReference !== conflictToUpdate.targetedSpeciesUniqueReference
        );
        data.propertyMethodChosenTargets = data.propertyMethodChosenTargets.filter(
          (t) => t.targetedSpeciesUniqueReference !== conflictToUpdate.targetedSpeciesUniqueReference
        );
      }

      this.updateResourceEffortListOnProperty(data.selectedProperty);
      this.setState({ data });
    }
  };

  handleProtectsSelection = (e, resource, index) => {
    let propertyMethodChosenProtections = this.state.data.propertyMethodChosenProtections;
    if (e.target.checked) {
      propertyMethodChosenProtections.push(resource);
    } else {
      propertyMethodChosenProtections.splice(index, 1);
    }
    this.setState({ propertyMethodChosenProtections });
  };

  handleTargetsSelection = (e, target, index) => {
    let propertyMethodChosenTargets = this.state.data.propertyMethodChosenTargets;
    if (e.target.checked) {
      propertyMethodChosenTargets.push(target);
    } else {
      propertyMethodChosenTargets.splice(index, 1);
    }
    this.setState({ propertyMethodChosenTargets });
  };

  async updateCrewMemberHobbsTimes(data, createdAerialWorkTask) {
    let needToUpdate = false;
    let postArray = data.selectedProperty?.selectedCrewMembers?.map((crew) => {
      const crewMember = data.selectedProperty.aerialPropertyObject?.aerialPropertyCrewMembers?.find(
        (cr) => cr.userUniqueReference
      );
      if (!(parseFloat(crewMember?.hobbsHours) === parseFloat(crew.hobbsTime))) {
        needToUpdate = true;
      }
      return {
        airCraftCrewUserUniqueReference: crew.wsUserUniqueIdentifier || crew.userUniqueReference,
        hobbsHours: parseFloat(crew.hobbsTime) || parseFloat(crew.hobbsHours),
      };
    });
    if (needToUpdate) {
      const editCrewOnAerialPropertyResponse = await WorkTaskAPI.EditCrewOnAerialProperty(
        data.selectedProperty.aerialPropertyObject?.aerialPropertyUniqueIdentifier,
        {
          crewMembers: postArray,
        }
      );
      if (editCrewOnAerialPropertyResponse?.successful) {
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask =
          createdAerialWorkTask.propertiesVisitedOnAerialWorkTask?.filter(
            (property) =>
              property.aerialPropertyUniqueIdentifier !==
              editCrewOnAerialPropertyResponse?.data?.aerialPropertyUniqueIdentifier
          );
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask.push(editCrewOnAerialPropertyResponse?.data);
        this.mapAerialPropertiesToPropertyList(data, [editCrewOnAerialPropertyResponse?.data]);
        return true;
      } else if (editCrewOnAerialPropertyResponse?.unsuccessful) {
        ErrorHandler.showErrorWithDetails(
          "Unable to update crew member's time.",
          editCrewOnAerialPropertyResponse.message
        );
      } else {
        ErrorHandler.showErrorWithMessage("Unable to update crew member's time.");
      }
      return false;
    }
    return true;
  }

  async updateConflictsOnAerialWorkTask(data, createdAerialWorkTask) {
    if (
      !data.selectedProperty?.propertyConflicts ||
      !data.selectedProperty?.propertyConflicts?.some((conflict) => conflict.isSelected)
    ) {
      return true;
    }
    //check if needed
    let addConflictsToAerialPropertyPromises = data.selectedProperty.propertyConflicts
      .filter((conflict) => {
        if (conflict.isSelected) {
          let alreadyAssociatedToProperty =
            data.selectedProperty.aerialPropertyObject?.propertyAssociatedConflicts.some(
              (associatedConflict) => associatedConflict.conflictUniqueReference === conflict.conflictUniqueIdentifier
            );
          if (!alreadyAssociatedToProperty) {
            return true;
          }
        }
        return false;
      })
      ?.map(async (conflict) => {
        if (conflict.isSelected) {
          let alreadyAssociatedToProperty =
            data.selectedProperty.aerialPropertyObject?.propertyAssociatedConflicts.some(
              (associatedConflict) => associatedConflict.conflictUniqueReference === conflict.conflictUniqueIdentifier
            );
          if (!alreadyAssociatedToProperty) {
            let resourcesToSend = [];
            conflict.protectedResources.forEach((pR) => {
              let foundResource = data.selectedProperty.resourcesEffort.find(
                (r) => r.protectedResourceUniqueReference === pR.protectedResourceUniqueReference
              );
              if (foundResource) {
                resourcesToSend.push(foundResource);
              }
            });
            const addConflictsToAerialPropertyResponse = await WorkTaskAPI.AddConflictsToAerialProperty(
              createdAerialWorkTask?.propertiesVisitedOnAerialWorkTask?.find(
                (property) => property.propertyUniqueReference === data.selectedProperty.propertyUniqueIdentifier
              )?.aerialPropertyUniqueIdentifier,
              {
                conflictUniqueReference: conflict.conflictUniqueIdentifier,
                targetSpeciesUniqueReference: conflict.targetedSpeciesUniqueReference,
                targetProtectedResources: resourcesToSend?.map((resource) => ({
                  protectedResourceUniqueReference: resource.protectedResourceUniqueReference,
                  protectedResourceEffortRatio: parseFloat(resource.effortPercentage) ?? 0,
                })),
              }
            );
            if (addConflictsToAerialPropertyResponse) {
              addConflictsToAerialPropertyResponse.conflict = conflict;
            }
            return addConflictsToAerialPropertyResponse;
          }
        }
      });
    if (addConflictsToAerialPropertyPromises?.length === 0) {
      return true;
    }
    let success = true;
    if (addConflictsToAerialPropertyPromises?.length > 0) {
      let responses = await Promise.all(addConflictsToAerialPropertyPromises ?? []).then(async (responses) => {
        responses.forEach((response) => {
          if (!response?.successful) {
            success = false;
          }
        });
        return responses;
      });
      if (success && responses?.length > 0) {
        toast.success("Added Conflict to Aerial Work Task");
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask =
          createdAerialWorkTask.propertiesVisitedOnAerialWorkTask?.filter(
            (property) =>
              property.aerialPropertyUniqueIdentifier !==
              responses[responses.length - 1]?.data?.aerialPropertyUniqueIdentifier
          );
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask.push(responses[responses.length - 1].data);
        this.mapAerialPropertiesToPropertyList(data, [responses[responses.length - 1].data]);
        return true;
      } else {
        ErrorHandler.showErrorWithDetails(
          "Unable to add one or more conflicts to Aerial Work Task.",
          responses
            .map(
              (err) =>
                err?.message ?? "Failed to add conflict for " + JSON.stringify(err?.conflict?.conflictUniqueIdentifier)
            )
            .join(", ")
        );
      }
    }
    return false;
  }

  async removeWorkPerformedOnAerialWorkTask(data, createdAerialWorkTask) {
    if (!(data.selectedProperty?.workPerformedToRemove?.length > 0)) {
      return true;
    }
    let removeWorkPerformedOnAerialPropertyPromises = data.selectedProperty?.workPerformedToRemove?.map(
      async (workPerformedId) => {
        const removeWorkPerformedOnAerialPropertyResponse = await WorkTaskAPI.RemoveWorkPerformedOnAerialProperty(
          workPerformedId
        );
        if (removeWorkPerformedOnAerialPropertyResponse) {
          removeWorkPerformedOnAerialPropertyResponse.workPerformedId = workPerformedId;
        }
        return removeWorkPerformedOnAerialPropertyResponse;
      }
    );
    let success = true;
    if (removeWorkPerformedOnAerialPropertyPromises?.length > 0) {
      let responses = await Promise.all(removeWorkPerformedOnAerialPropertyPromises ?? []).then(async (responses) => {
        responses.forEach((response) => {
          if (!response?.successful) {
            success = false;
          }
        });
        return responses;
      });
      if (success && responses?.length > 0) {
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask =
          createdAerialWorkTask.propertiesVisitedOnAerialWorkTask?.filter(
            (property) =>
              property.aerialPropertyUniqueIdentifier !==
              responses[responses.length - 1]?.data?.aerialPropertyUniqueIdentifier
          );
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask.push(responses[responses.length - 1].data);
        this.mapAerialPropertiesToPropertyList(data, [responses[responses.length - 1].data]);
        return true;
      } else {
        ErrorHandler.showErrorWithDetails(
          "Unable to remove one or more work performed item from Aerial Work Task property.",
          responses
            .map(
              (err) =>
                err?.message ??
                "Failed to remove work performed for " + JSON.stringify(err.conflict?.conflictUniqueIdentifier)
            )
            .join(", ")
        );
      }
    }
    return false;
  }

  handleResourceEffortChange = ({ currentTarget: input }, resource) => {
    const data = { ...this.state.data };
    const resourceEffortToUpdate = data.selectedProperty.resourcesEffort.find(
      (r) =>
        r.resourceUniqueReference === resource.resourceUniqueReference &&
        r.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure
    );
    if (resourceEffortToUpdate) {
      const oldResourceEffort = JSON.parse(JSON.stringify(resourceEffortToUpdate));
      resourceEffortToUpdate[input.name] = input.value;
      if (input.name === "hobbsHours") {
        resourceEffortToUpdate.effortPercentage =
          parseFloat((parseFloat(input.value) / data.selectedProperty.totalFlightTime).toFixed(2)) * 100;
      } else if (input.name === "effortPercentage") {
        resourceEffortToUpdate.hobbsHours = parseFloat(
          ((parseFloat(input.value) / 100) * data.selectedProperty.totalFlightTime).toFixed(2)
        );
      }
      resourceEffortToUpdate.isManuallyUpdated = true;

      if (this.getConsumedHobbsHours(data.selectedProperty) > data.selectedProperty.totalFlightTime) {
        toast.warning("Resource Effort time connot be greater than the total time for the property");
        resourceEffortToUpdate.hobbsHours = oldResourceEffort.hobbsHours;
        resourceEffortToUpdate.effortPercentage = oldResourceEffort.effortPercentage;
      } else {
        this.updateResourceEffortListOnProperty(data.selectedProperty);
      }
    }
    this.setState({ data });
  };

  updateResourceEffortListOnProperty(property) {
    const resourceEffortEntriesToUpdate = property?.resourcesEffort?.filter((r) => !r.isManuallyUpdated);
    if (resourceEffortEntriesToUpdate?.length > 0) {
      const availableHobbsHours = parseFloat(property.totalFlightTime) - this.getConsumedHobbsHours(property);
      const calculatedHobbsTime = parseFloat((availableHobbsHours / resourceEffortEntriesToUpdate?.length).toFixed(2));
      const calculatedEffortPercentage = (calculatedHobbsTime / parseFloat(property.totalFlightTime)) * 100;

      resourceEffortEntriesToUpdate.forEach((resource) => {
        const resourceEffortEntry = property.resourcesEffort.find(
          (r) =>
            r.resourceUniqueReference === resource.resourceUniqueReference &&
            r.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure
        );
        if (resourceEffortEntry) {
          resourceEffortEntry.hobbsHours = calculatedHobbsTime;
          resourceEffortEntry.effortPercentage = calculatedEffortPercentage;
        }
      });
    }
  }

  getConsumedHobbsHours(property) {
    let consumedHobbsHours = 0;
    const entriesManuallyUpdated = property.resourcesEffort.filter((r) => r.isManuallyUpdated);
    entriesManuallyUpdated.forEach((r) => {
      if (r.hobbsHours) {
        consumedHobbsHours += parseFloat(r.hobbsHours);
      }
    });
    return consumedHobbsHours;
  }

  validateAerialWorkTaskData() {
    const { data } = this.state;
    let missingeFieldsValidation = [];
    if (!data.aircraftId) {
      missingeFieldsValidation.push("Aircraft ID");
    }
    if (!data.selectedPilot) {
      missingeFieldsValidation.push("Pilot");
    }
    if (!data.selectedActivity) {
      missingeFieldsValidation.push("Activity Type");
    }
    if (!(data.totalFlightTime > 0)) {
      missingeFieldsValidation.push("Total Flight Time");
    }
    if (this.state.createdAerialWorkTask && data.selectedActivity === "1" && !(data.properties?.length > 0)) {
      missingeFieldsValidation.push("Properties");
    }
    return missingeFieldsValidation;
  }

  async doSubmit() {
    const { data, createdAerialWorkTask } = this.state;
    if (!this.state.isMainPage) {
      let conflictSuccess = await this.updateConflictsOnAerialWorkTask(data, createdAerialWorkTask);
      let crewSuccess = await this.updateCrewMemberHobbsTimes(data, createdAerialWorkTask);
      let workPerformedRemovedSuccess = await this.removeWorkPerformedOnAerialWorkTask(data, createdAerialWorkTask);
      let propertyToUpdate = data.properties.find(
        (p) => p.propertyUniqueIdentifier === data.selectedProperty.propertyUniqueIdentifier
      );
      if (propertyToUpdate) {
        propertyToUpdate.propertyConflicts = JSON.parse(JSON.stringify(data.selectedProperty?.propertyConflicts ?? []));
        propertyToUpdate.resourcesEffort = JSON.parse(JSON.stringify(data.selectedProperty?.resourcesEffort ?? []));
        propertyToUpdate.selectedCrewMembers = JSON.parse(
          JSON.stringify(data.selectedProperty?.selectedCrewMembers ?? [])
        );
      }
      if (crewSuccess && conflictSuccess && workPerformedRemovedSuccess) {
        toast.success("Updated Property data.");
        data.selectedProperty = "";
      }
      this.setState({
        data,
        createdAerialWorkTask,
        isMainPage: crewSuccess && conflictSuccess && workPerformedRemovedSuccess,
      });
      return;
    } else if (!createdAerialWorkTask) {
      let validations = this.validateAerialWorkTaskData();
      if (validations.length > 0) {
        toast.warning("Please fill out the missing fields: " + validations.join(", "));
        return;
      }
      const createAerialWorkTaskResponse = await WorkTaskAPI.CreateAerialWorkTask(this.getAerialWorkTaskPostBody(data));
      if (createAerialWorkTaskResponse?.successful) {
        toast.success("Created Draft Aerial Work Task");
        this.setState({ createdAerialWorkTask: createAerialWorkTaskResponse.data });
      } else if (createAerialWorkTaskResponse?.unsuccessful) {
        ErrorHandler.showErrorWithDetails("Unable to create Aerial Work Task.", createAerialWorkTaskResponse.message);
      } else {
        ErrorHandler.showErrorWithMessage("Unable to create Aerial Work Task.");
      }
    } else {
      const completeAerialWorkTaskResponse = await WorkTaskAPI.CompleteAerialWorkTask(
        createdAerialWorkTask.aerialWorkTaskUniqueIdentifier
      );
      if (completeAerialWorkTaskResponse?.successful) {
        toast.success("Completed Aerial Work Task");
        this.setState({ createdAerialWorkTask: completeAerialWorkTaskResponse.data, showCreateSuccessModal: true });
      } else if (completeAerialWorkTaskResponse?.unsuccessful) {
        ErrorHandler.showErrorWithDetails(
          "Unable to complete Aerial Work Task.",
          completeAerialWorkTaskResponse.message
        );
      } else {
        ErrorHandler.showErrorWithMessage("Unable to complete Aerial Work Task.");
      }
    }
  }

  getAerialWorkTaskPostBody(data, isEdit) {
    let postBody = {
      datePerformed: data.aerialWorkTaskDate
        ? new Date(data.aerialWorkTaskDate)?.toISOString()
        : new Date()?.toISOString(),
      activityTypeEnumId: parseInt(data.selectedActivity),
      hobbsHours: data.totalFlightTime ? parseFloat(data.totalFlightTime) : 0.0,
      ferryHours: data.ferryTime ? parseFloat(data.ferryTime) : 0.0,
      isTraining: data.isTrainingWorkTask ?? false,
    };
    if (data.selectedPilot && !isEdit) {
      postBody.pilotWsUserProfileUniqueReference = data.selectedPilot.value;
    } else if (data.selectedPilot && isEdit) {
      postBody.pilotUserUniqueReference = data.selectedPilot.value;
    }
    if (data.selectedCrewMembers && !isEdit) {
      postBody.airCraftCrewWsUserProfileUniqueReferences = data.selectedCrewMembers?.map((crew) => crew.value) ?? [];
    } else if (data.selectedCrewMembers && isEdit) {
      postBody.airCraftCrewUserUniqueReferences = data.selectedCrewMembers?.map((crew) => crew.value) ?? [];
    }
    if (data.aircraftId) {
      postBody.airCraftUniqueIdentifier = data.aircraftId;
    }
    return postBody;
  }

  handleCancel = async () => {
    if (this.state.isMainPage) {
      if (this.state.createdAerialWorkTask) {
        const cancelAerialWorkTaskResponse = await WorkTaskAPI.CancelAerialWorkTask(
          this.state.createdAerialWorkTask?.aerialWorkTaskUniqueIdentifier
        );
        if (cancelAerialWorkTaskResponse?.successful) {
          toast.success("Aerial Work Task Deleted.");
        } else if (cancelAerialWorkTaskResponse?.unsuccessful) {
          ErrorHandler.showErrorWithDetails("Unable to cancel Aerial Work Task.", cancelAerialWorkTaskResponse.message);
        } else {
          ErrorHandler.showErrorWithDetails(
            "Unable to cancel Aerial Work Task. CancelAerialWorkTask failed.",
            "Unable to cancel Aerial Work Task."
          );
        }
      }
      this.props.history.push("/home");
    } else {
      this.setState({ isMainPage: true });
    }
  };

  handleMethodEntrySelect = async (selectedValue, fieldName) => {
    let { data, currentUsaState, availableUOMsToSelect, availableUsesToSelect } = this.state;
    if (!selectedValue) {
      data[fieldName] = null;
    } else {
      data[fieldName] = selectedValue;
    }

    if (fieldName === "methodEntrySelectedMethod" && currentUsaState?.stateGuid) {
      const getMethodForStateResponse = await ReferenceFileAPI.GetMethodForState(
        selectedValue.value,
        currentUsaState.stateGuid
      );
      if (getMethodForStateResponse?.successful) {
        availableUOMsToSelect = this.getAvailableUOMsForMethod(getMethodForStateResponse.data);
        availableUsesToSelect = this.getAvailableUsesForMethod(getMethodForStateResponse.data);
      }
    }

    this.setState({ data, availableUOMsToSelect, availableUsesToSelect });
  };

  validateMethodEntryData() {
    const { data } = this.state;
    let missingeFieldsValidation = [];
    if (!data.methodEntrySelectedMethod) {
      missingeFieldsValidation.push("Method");
    }
    if (!data.methodEntrySelectedUse) {
      missingeFieldsValidation.push("Use");
    }
    if (!data.methodEntrySelectedUom) {
      missingeFieldsValidation.push("Unit of Measure");
    }
    if (!(data.methodEntryQuantity > 0)) {
      missingeFieldsValidation.push("Quantity");
    }
    if (!(data.methodEntrySelectedProperties?.length > 0)) {
      missingeFieldsValidation.push("Properties");
    }
    return missingeFieldsValidation;
  }

  addMethodToInventory = async () => {
    const { data, createdAerialWorkTask } = this.state;
    let validations = this.validateMethodEntryData();
    if (data.workPerformedMethods.find((m) => m?.method?.value === data.methodEntrySelectedMethod?.value)) {
      toast.warning("The same method already exists in the Aerial Work Task");
    } else if (
      this.state.createdAerialWorkTask?.propertiesVisitedOnAerialWorkTask?.length > 0 &&
      !validations?.length > 0
    ) {
      const workPerformedMethodObject = this.constructWorkPerformedPostObject();
      let addWorkPerformedPromises = data.methodEntrySelectedProperties.map(async (property) => {
        const addWorkPerformedOnAerialPropertyResponse = await WorkTaskAPI.AddWorkPerformedOnAerialProperty(
          property?.aerialPropertyUniqueIdentifier || property?.aerialPropertyObject?.aerialPropertyUniqueIdentifier,
          {
            methodUniqueReference: workPerformedMethodObject.methodUniqueIdentifier,
            methodAllowedUseEnumId: workPerformedMethodObject.use?.value,
            methodAllowedUOMId: workPerformedMethodObject.uom?.value,
            workPerformedType: "Consume",
            methodQtyAffect: workPerformedMethodObject.quantity,
            conflictUniqueReferences: property.propertyAssociatedConflicts?.map(
              (conflict) => conflict.conflictUniqueReference
            ),
            conflictTargetedSpeciesUniqueReferences: property.propertyAssociatedConflicts?.map(
              (conflict) => conflict.targetedSpeciesUniqueReference
            ),
            conflictProtectedResourceUniqueReferences: property.propertyAssociatedConflicts?.flatMap((conflict) =>
              conflict.targetProtectedResources.map((resource) => resource.protectedResourceUniqueReference)
            ),
          }
        );
        if (addWorkPerformedOnAerialPropertyResponse) {
          addWorkPerformedOnAerialPropertyResponse.property = property;
        }
        return addWorkPerformedOnAerialPropertyResponse;
      });
      let success = true;
      let responses = await Promise.all(addWorkPerformedPromises ?? []).then(async (responses) => {
        responses.forEach((response) => {
          if (!response?.successful) {
            success = false;
          } else {
            this.mapAerialPropertiesToPropertyList(data, [response.data]);
          }
        });
        return responses;
      });
      if (success && responses) {
        data.methodEntrySelectedMethod = "";
        data.methodEntrySelectedUom = "";
        data.methodEntryQuantity = "";
        data.methodEntrySelectedUse = "";
        data.methodEntrySelectedProperties = [];
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask =
          createdAerialWorkTask.propertiesVisitedOnAerialWorkTask?.filter(
            (property) =>
              property.aerialPropertyUniqueIdentifier !==
              responses[responses.length - 1].data.aerialPropertyUniqueIdentifier
          );
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask.push(responses[responses.length - 1].data);
        toast.success("Added Work Performed.");
        this.setState({ data, createdAerialWorkTask });
      } else {
        ErrorHandler.showErrorWithDetails(
          "Unable to add one or more work performed to Aerial Work Task.",
          responses
            .map(
              (err) =>
                err?.message ??
                "Failed to add work performed for " + JSON.stringify(err.property?.aerialPropertyUniqueIdentifier)
            )
            .join(", ")
        );
      }
    } else {
      toast.warning("Please fill out the missing fields: " + validations.join(", "));
      return;
    }
  };

  constructWorkPerformedPostObject({ fromProperty }) {
    let { data } = this.state;
    let obj = {};
    if (fromProperty) {
      const useId = this.state.data.chosenMethodForProperty?.allowedUsesInState?.find(
        (use) => use.displayText === data.propertyMethodChosenUse
      )?.id;
      const uomId = this.state.data.chosenMethodForProperty?.allowedUOMsInState?.find(
        (uom) => uom.displayText === data.propertyMethodChosenUOM
      )?.id;
      obj = {
        methodUniqueReference: data.propertyMethodSearchSelection,
        methodAllowedUseEnumId: useId,
        methodAllowedUOMId: uomId,
        workPerformedType: "Consume",
        methodQtyAffect: Number.parseInt(data.propertyMethodQuantity),
        conflictUniqueReferences:
          data.selectedProperty.propertyAssociatedConflicts?.map((conflict) => conflict.conflictUniqueReference) ||
          data.selectedProperty.aerialPropertyObject?.propertyAssociatedConflicts?.map(
            (conflict) => conflict.conflictUniqueReference
          ),
        conflictTargetedSpeciesUniqueReferences: data.propertyMethodChosenTargets?.map(
          (conflict) => conflict.targetedSpeciesUniqueReference
        ),
        conflictProtectedResourceUniqueReferences: data.propertyMethodChosenProtections?.map(
          (resource) => resource.protectedResourceUniqueReference
        ),
        warehouseUniqueReference: data.propertyMethodWarehouseSelection ?? null,
      };
    } else {
      obj = {
        methodUniqueIdentifier: data.methodEntrySelectedMethod?.value,
        uom: data.methodEntrySelectedUom,
        quantity: Number.parseInt(data.methodEntryQuantity),
        use: data.methodEntrySelectedUse,
      };
    }
    return obj;
  }

  validatePropertyMethodEntryData() {
    const { data } = this.state;
    let missingeFieldsValidation = [];
    if (!data.propertyMethodSearchSelection) {
      missingeFieldsValidation.push("Method");
    }
    if (!data.propertyMethodChosenUse) {
      missingeFieldsValidation.push("Use");
    }
    if (!data.propertyMethodChosenUOM) {
      missingeFieldsValidation.push("Unit of Measure");
    }
    if (!(data.propertyMethodQuantity > 0)) {
      missingeFieldsValidation.push("Quantity");
    }
    return missingeFieldsValidation;
  }

  addPropertyMethodToInventory = async () => {
    const { data, createdAerialWorkTask } = this.state;
    let validations = this.validatePropertyMethodEntryData();
    if (data.workPerformedMethods.find((m) => m?.method?.value === data.propertyMethodSearchSelection)) {
      toast.warning("The same method already exists in the Aerial Work Task");
    } else if (!validations?.length > 0) {
      let property = data.selectedProperty;
      const workPerformedMethodObject = this.constructWorkPerformedPostObject({ fromProperty: true });
      const addWorkPerformedOnAerialPropertyResponse = await WorkTaskAPI.AddWorkPerformedOnAerialProperty(
        property?.aerialPropertyUniqueIdentifier || property?.aerialPropertyObject?.aerialPropertyUniqueIdentifier,
        workPerformedMethodObject
      );
      if (addWorkPerformedOnAerialPropertyResponse?.successful) {
        data.chosenMethodForProperty = "";
        data.propertyMethodSearchSelection = "";
        data.propertyMethodQuantity = "";
        data.propertyMethodChosenUse = "";
        data.propertyMethodChosenUOM = "";
        data.propertyMethodChosenProtections = [];
        data.propertyMethodChosenTargets = [];
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask =
          createdAerialWorkTask.propertiesVisitedOnAerialWorkTask?.filter(
            (property) =>
              property.aerialPropertyUniqueIdentifier !==
              addWorkPerformedOnAerialPropertyResponse.data.aerialPropertyUniqueIdentifier
          );
        createdAerialWorkTask.propertiesVisitedOnAerialWorkTask.push(addWorkPerformedOnAerialPropertyResponse.data);
        data.selectedProperty.aerialPropertyObject = addWorkPerformedOnAerialPropertyResponse.data;
        toast.success("Added Work Performed.");
        this.setState({ data, createdAerialWorkTask });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to add Method to Aerial Work Task",
          apiName: "AddWorkPerformedOnAerialProperty",
          responseMessage: addWorkPerformedOnAerialPropertyResponse?.message,
          responseUnsuccessful: addWorkPerformedOnAerialPropertyResponse?.unsuccessful,
        });
      }
    } else {
      toast.warning("Please fill out the missing fields on Method Entry: " + validations.join(", "));
      return;
    }
  };

  getAvailableUOMsForMethod(methodData) {
    let availableUOMsToSelect = [];
    if (methodData?.allowedUOMsInState?.length > 0) {
      availableUOMsToSelect = methodData.allowedUOMsInState.map((uom) => ({
        value: uom.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(uom),
      }));
    }

    return availableUOMsToSelect;
  }

  getAvailableUsesForMethod(methodData) {
    let availableUsesToSelect = [];
    if (methodData?.allowedUsesInState?.length > 0) {
      availableUsesToSelect = methodData.allowedUsesInState.map((use) => ({
        value: use.id,
        label: UtilityFunctions.getDisplayTextFromFieldObject(use),
      }));
    }

    return availableUsesToSelect;
  }

  async handleSpecificChanges(input) {
    const { data } = this.state;

    if (input?.name === "aerialWorkTaskDate" && Date.parse(input.value) > new Date()) {
      data.aerialWorkTaskDate = JSON.parse(JSON.stringify(data.aerialWorkTaskDate));
      this.setState({ data });
    } else if (input.name === "totalFlightTime") {
      let totalFlightTimeInProperties = this.getTotalFlightTimeForProperties(data);
      if (totalFlightTimeInProperties <= Number.parseFloat(input.value)) {
        data.totalFlightTime = input.value;
      } else {
        data.totalFlightTime = input.value;
        toast.warning(
          "Total Flight Time on the Work task cannot be smaller than the sum of total flight times in the properties"
        );
      }
      this.setState({ data });
    } else if (input.name === "propertyMethodSearchSelection") {
      data.propertyMethodSearchSelection = input?.value;
      let methodData = {};
      if (input.value) {
        const fullMethodObject = await ReferenceFileAPI.GetMethodForState(
          input.value,
          this.state.currentUsaState.stateGuid
        );
        if (fullMethodObject?.successful) {
          methodData = fullMethodObject?.data;
        }
      }
      data.chosenMethodForProperty = methodData;
      if (methodData?.allowedUsesInState.length === 1) {
        data.propertyMethodChosenUse = methodData?.allowedUsesInState[0]?.displayText;
      }
      if (methodData?.allowedUOMsInState.length === 1) {
        data.propertyMethodChosenUOM = methodData?.allowedUOMsInState[0]?.displayText;
      }
      this.setState({ data });
    }
  }

  saveTakeCapture = async (takeData) => {
    const createTakeResponse = await WorkTaskAPI.CreateTake(this.getTakeCreationBody(takeData));
    if (createTakeResponse?.successful) {
      toast.success("Take was successfully added to the work task.");
      await this.addBandsToTake(takeData, createTakeResponse.data?.takeUniqueIdentifier);
    } else {
      ErrorHandler.showErrorWithDetails(
        { errorDetails: createTakeResponse },
        "Take could not be added to the work task. Contact your system admin for more details"
      );
    }
    this.setState({ enterTakeData: { workItem: "" } });
  };

  async addBandsToTake(takeData, takeUniqueIdentifier) {
    if (takeUniqueIdentifier && takeData.bands?.length > 0) {
      const addBandsResponse = await WorkTaskAPI.AddBandsToTake(
        takeUniqueIdentifier,
        takeData.bands.map((b) => ({ bandingTypeEnumId: b.type?.value, referenceNumber: b.referenceNumber }))
      );
      if (!addBandsResponse?.successful) {
        ErrorHandler.showErrorWithMessage(
          addBandsResponse?.message ? addBandsResponse.message : "Banding data could not be added to the Take."
        );
      }
    }
  }

  getTakeCreationBody(takeData) {
    const callBody = {
      speciesTakenUniqueReference: takeData.isUnintentionalTake
        ? takeData.unintentionalSpecies?.value
        : takeData.intentionalSpecies,
      conflictUniqueReference: takeData.conflictId,
      fateUniqueReference: takeData.selectedFate,
      aerialWorkPerformedUniqueReference: takeData.workPerformedId,
      methodUniqueReference: takeData.methodUniqueId,
      quantity: parseInt(takeData.quantity),
      countTypeEnumId: takeData.actualCount,
      unitOfMeasureEnumId: takeData.selectedUom,
      remarks: "",
      tags: takeData.tags,
      wasIntentional: !takeData.isUnintentionalTake,
      numberOfAnimalsSampled: takeData.totalAnimalsSampled ? parseInt(takeData.totalAnimalsSampled) : 0,
      numberOfNRMPTaken: takeData.rabiesSamplingQty ? parseInt(takeData.rabiesSamplingQty) : 0,
      numberOfDiseaseMonitoringTaken: takeData.diseaseSamplingQty ? parseInt(takeData.diseaseSamplingQty) : 0,
      isOther: takeData.isOtherSampling,
    };

    if (takeData.latitude && takeData.longitude) {
      callBody.latitude = parseFloat(takeData.latitude);
      callBody.longitude = parseFloat(takeData.longitude);
    }

    if (takeData.selectByPermit) {
      callBody.permitUniqueReference = takeData.selectByPermit.permitUniqueIdentifier;
    }

    if (takeData.selectedIntermediateFates?.length > 0) {
      callBody.intermediateFateUniqueReferences = takeData.selectedIntermediateFates.map((f) => f.value);
    }

    return callBody;
  }

  showEnterTakeModal = (workItemRow) => {
    const { data } = this.state;
    const enterTakeData = {
      workItem: this.getWorkItemObjectForTake(workItemRow),
      propertyData: {
        mapPoint: data.selectedProperty
          ? JSON.stringify([data.selectedProperty?.latitude, data?.selectedProperty.longitude])
          : "",
        ...data.selectedProperty,
      },
      propertyConflicts: data.selectedProperty.propertyConflicts,
    };
    this.setState({ enterTakeData, showEnterTakeModal: true });
  };

  getWorkItemObjectForTake(workItemRow) {
    const { selectedProperty } = this.state.data;
    const workItem = {
      inventoryItemLatitude: selectedProperty?.latitude,
      inventoryItemLongitude: selectedProperty?.longitude,
      aerialWorkPerformedUniqueIdentifier: workItemRow?.aerialWorkPerformedUniqueIdentifier,
      methodUniqueReference: workItemRow?.methodUniqueReference,
      methodName: workItemRow?.methodName,
    };
    return workItem;
  }

  contextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = React.useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    if (isCurrentEventKey) {
      return (
        <img className={styles.headerChevron} src={ChevronDownAccordion} onClick={decoratedOnClick} alt="down arrow" />
      );
    } else {
      return (
        <img
          className={styles.headerChevron}
          src={ChevronRightAccordion}
          onClick={decoratedOnClick}
          alt="right arrow"
        />
      );
    }
  }

  editAerialInformation = async ({ fromAddPropertyMethod }) => {
    let { data, createdAerialWorkTask, canEditAerialInformation } = this.state;
    if (canEditAerialInformation || fromAddPropertyMethod) {
      const editAerialWorkTaskResponse = await WorkTaskAPI.EditAerialWorkTask(
        createdAerialWorkTask?.aerialWorkTaskUniqueIdentifier,
        this.getAerialWorkTaskPostBody(data, true)
      );
      if (editAerialWorkTaskResponse?.successful) {
        toast.success("Updated Draft Aerial Work Task");
        this.setState({ createdAerialWorkTask: editAerialWorkTaskResponse.data });
      } else if (editAerialWorkTaskResponse?.unsuccessful) {
        ErrorHandler.showErrorWithDetails("Unable to update Aerial Work Task.", editAerialWorkTaskResponse.message);
      } else {
        ErrorHandler.showErrorWithMessage("Unable to update Aerial Work Task.");
      }
      this.setState({ canEditAerialInformation: false });
    } else {
      this.setState({ canEditAerialInformation: true });
    }
  };

  renderAerialInformationCard() {
    let { data, canEditAerialInformation, isCompleteAWT } = this.state;
    return this.state.isLoading ? (
      <Spinner animation="border" role="status">
        <span className="visually-hidden" aria-label="Loading..."></span>
      </Spinner>
    ) : (
      <Card className={styles.viewPageCards}>
        <Card.Header className={globalStyles.cardTitles}>
          <Row>
            <Col>
              <span className={globalStyles.cardTitleText}>Aerial Information</span>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="text-left">
          <Row>
            <Col sm={6}>
              {this.renderEditableField(
                !isCompleteAWT || canEditAerialInformation,
                "aerialWorkTaskDate",
                "Date",
                data.aerialWorkTaskDate,
                "date",
                "Select Date",
                this.state.createdAerialWorkTask ? "readOnly" : "required",
                "required"
              )}
            </Col>
            <Col sm={3} id="aircraftTypeCol">
              {this.renderEditableField(
                !isCompleteAWT || canEditAerialInformation,
                "aircraftType",
                "Aircraft Type",
                data.aircraftType,
                "select",
                this.state.aircraftTypes,
                this.state.createdAerialWorkTask && !this.state.canEditAerialInformation ? "disabled" : ""
              )}
            </Col>
            <Col sm={3} id="aircraftIdCol">
              {this.renderEditableField(
                !isCompleteAWT || canEditAerialInformation,
                "aircraftId",
                "Aircraft ID",
                data.aircraftId,
                "select",
                this.state.aircraftIDs,
                this.state.createdAerialWorkTask && !this.state.canEditAerialInformation ? "disabled" : "required"
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6} id="selectedPilotCol">
              <Form.Group>
                {isCompleteAWT && !canEditAerialInformation ? (
                  this.renderLabel("selectedPilot", "Pilot", data.selectedPilot?.label)
                ) : (
                  <React.Fragment>
                    <Form.Label className={globalStyles.formLabel}>
                      Pilot<span className={globalStyles.asterisk508}>{" *"}</span>
                    </Form.Label>
                    <AsyncSelect
                      placeholder="Select Pilot"
                      aria-label="Pilot Search"
                      value={this.state.data.selectedPilot}
                      styles={this.getSelectStyles()}
                      components={{ DropdownIndicator, Option }}
                      loadOptions={this.loadAvailablePilots}
                      onChange={this.handlePilotSelection}
                      isDisabled={this.state.createdAerialWorkTask && !this.state.canEditAerialInformation}
                    />
                  </React.Fragment>
                )}
              </Form.Group>
            </Col>
            <Col sm={6}>{this.renderSelectCrewDropdown()}</Col>
          </Row>
          <Row>
            <Col sm={6} id="selectedActivityCol">
              {this.renderEditableField(
                !isCompleteAWT || canEditAerialInformation,
                "selectedActivity",
                "Activity Type",
                data.selectedActivity,
                "select",
                this.state.activityTypes,
                this.state.createdAerialWorkTask && !this.state.canEditAerialInformation ? "disabled" : "required"
              )}
            </Col>
            <Col sm={3}>

              {this.renderEditableField(
                !isCompleteAWT || canEditAerialInformation,
                "totalFlightTime",
                "Total Flight Time",
                data.totalFlightTime,
                "number",
                0.0,
                this.state.createdAerialWorkTask && !this.state.canEditAerialInformation ? "readOnly" : ["required", "step=0.1"]
              )}
            </Col>
            <Col sm={3}>
              {this.renderEditableField(
                !isCompleteAWT || canEditAerialInformation,
                "ferryTime",
                "Ferry Time",
                data.ferryTime,
                "number",
                "Enter Ferry Time",
                this.state.createdAerialWorkTask && !this.state.canEditAerialInformation ? "readOnly" : ["step=0.1"]
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-left">
              <Form.Group controlId="trainingCheckBox">
                <Form.Check
                  type="checkbox"
                  disabled={this.state.createdAerialWorkTask && !this.state.canEditAerialInformation}
                  label="Training"
                  onChange={this.handleTrainingSelection}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row hidden={!this.state.createdAerialWorkTask}>
            <Col align="right">
              <Button
                variant="primary"
                className={globalStyles.modalSubmitButton + " mt-2"}
                onClick={this.editAerialInformation}
              >
                <span className={globalStyles.modalSubmitButtonText}>
                  {this.state.canEditAerialInformation ? "Save Aerial Information" : "Edit Aerial Information"}
                </span>
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderMethodEntryCard() {
    const { data } = this.state;
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>Method Entry</span>
        </Card.Header>
        <Card.Body className={styles.methodEntryContainer}>
          <Row className={styles.methodEntryLabelRow}>
            <Col>
              <span id="methodEntryMethodSelectLabel">Method</span>
            </Col>
            <Col>
              <span id="methodEntryUseSelectLabel">Use</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                aria-label="methodEntrySelectedMethod"
                name="methodEntrySelectedMethod"
                id="methodEntrySelectedMethod"
                placeholder="Select"
                value={data.methodEntrySelectedMethod}
                styles={this.getSelectStyles()}
                options={this.state.availableMethodsToSelect}
                onChange={(e) => this.handleMethodEntrySelect(e, "methodEntrySelectedMethod")}
              />
            </Col>
            <Col>
              <Select
                aria-label="methodEntrySelectedUse"
                name="methodEntrySelectedUse"
                id="methodEntrySelectedUse"
                placeholder="Select"
                value={data.methodEntrySelectedUse}
                styles={this.getSelectStyles()}
                options={this.state.availableUsesToSelect}
                onChange={(e) => this.handleMethodEntrySelect(e, "methodEntrySelectedUse")}
              />
            </Col>
          </Row>
          <Row className={styles.methodEntryLabelRow}>
            <Col>
              <span id="methodEntryQuantityLabel">Quantity</span>
            </Col>
            <Col>
              <span id="methodEntryUOMSelectLabel">UOM</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                aria-labelledby="methodEntryQuantityLabel"
                name="methodEntryQuantity"
                as="input"
                type="number"
                placeholder="Enter Qty"
                onChange={this.handleChange}
                value={data.methodEntryQuantity}
                min="1"
              />
            </Col>
            <Col>
              <Select
                aria-label="methodEntrySelectedUom"
                name="methodEntrySelectedUom"
                id="methodEntrySelectedUom"
                placeholder="Select"
                value={data.methodEntrySelectedUom}
                styles={this.getSelectStyles()}
                options={this.state.availableUOMsToSelect}
                onChange={(e) => this.handleMethodEntrySelect(e, "methodEntrySelectedUom")}
              />
            </Col>
          </Row>
          <Row className={styles.methodEntryLabelRow}>
            <Col>
              <span id="methodEntryUOMSelectLabel">Properties</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Select
                aria-label="methodEntrySelectedProperties"
                name="methodEntrySelectedProperties"
                id="methodEntrySelectedProperties"
                isMulti
                placeholder="Select Properties"
                value={data.methodEntrySelectedProperties}
                styles={this.getSelectStyles()}
                options={this.state.createdAerialWorkTask?.propertiesVisitedOnAerialWorkTask?.map((property) => {
                  property.value = property.aerialPropertyUniqueIdentifier;
                  property.label = property.propertyName;
                  return property;
                })}
                onChange={(e) => this.handleMethodEntrySelect(e, "methodEntrySelectedProperties")}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={9}></Col>
            <Col>
              <Button
                variant="secondary"
                className={styles.methodEntryAddButton}
                onClick={this.addMethodToInventory}
                disabled={
                  !data.methodEntrySelectedMethod ||
                  !data.methodEntrySelectedUse ||
                  !data.methodEntryQuantity ||
                  !data.methodEntrySelectedUom
                }
              >
                Add Method
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderPropertiesCard() {
    const { data, isCompleteAWT } = this.state;
    return (
      <Card className={styles.viewPageCards}>
        <Card.Header className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>Properties</span>
          {data.selectedActivity === "1" && <span className={globalStyles.asterisk508}>{" *"}</span>}
        </Card.Header>
        <Card.Body className="pt-0">
          <div className={styles.propertiesMapSection}>
            <Row className={styles.searchPropertiesRow} hidden={isCompleteAWT}>
              <Col sm={{ offset: 2, span: 8 }} align="center">
                <AsyncSelect
                  aria-label="Property Search"
                  value=""
                  openMenuOnClick={false}
                  placeholder="Search for properties "
                  components={{ DropdownIndicator }}
                  styles={this.getSelectStyles()}
                  loadOptions={this.loadAvailableProperties}
                  onChange={this.handlePropertySelection}
                  className={styles.searchPropertiesBox}
                />
              </Col>
              <Col sm={2} className={styles.growSpinnerColumnContainer}>
                {this.state.isPropertySelectionLoading && <Spinner animation="border" />}
              </Col>
            </Row>
            <Row className="mx-0">
              <Col className="px-0">
                <div className={styles.mapContainer}>
                  <WebMap
                    address={
                      data.selectedProperty.additionalStreetAddresses?.length > 0
                        ? UtilityFunctions.getPropertyPrimaryAddress(data.selectedProperty.additionalStreetAddresses[0])
                        : ""
                    }
                    name={data.selectedProperty?.propertyName}
                    location={
                      data.selectedProperty
                        ? JSON.stringify([data.selectedProperty?.latitude, data?.selectedProperty.longitude])
                        : ""
                    }
                    isAddPropertyAvailable={true}
                    onAddProperty={this.handlePropertyAddition}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {this.renderSelectedProperties()}
          <Row hidden={data.properties?.length < 1 || isCompleteAWT}>
            <Col align="right">
              <Button
                variant="primary"
                className={globalStyles.modalSubmitButton + " mt-2"}
                onClick={this.addPropertiesToAerialWorkTask}
              >
                <span className={globalStyles.modalSubmitButtonText}>Add Properties to AWT</span>
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderSelectedProperties() {
    return (
      <div className="mt-5 mb-3">
        {this.state.data.properties?.map((property, index) => {
          return (
            <React.Fragment key={index}>
              <div className={styles.propertyDetailsContainer}>
                <Row className={styles.propertyDetailsTitleRow}>
                  <Col>
                    <span className={styles.propertyCardTitles}>{property.propertyName}</span>
                  </Col>
                </Row>
                <Row className={styles.propertyDetailsDataRow + " align-items-center"}>
                  <Col>
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Total Flight Time over Property</Form.Label>
                      <Form.Control
                        name="totalFlightTime"
                        aria-label={"property" + index + "toalFlightTime"}
                        value={property.totalFlightTime}
                        type="number"
                        placeholder="Enter Hobbs Time"
                        className={styles.propertyHobbsTimeInput}
                        onChange={(e) => this.handlePropertyHobbsTimeChange(e, property)}
                        min="0"
                        step="0.1"
                        readOnly={property.aerialPropertyObject}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Ferry Time (To/From Property)</Form.Label>
                      <Form.Control
                        name="ferryTime"
                        aria-label={"property" + index + "ferryTime"}
                        value={property.ferryTime}
                        type="number"
                        placeholder="Enter Hobbs Time"
                        className={styles.propertyHobbsTimeInput}
                        onChange={(e) => this.handlePropertyHobbsTimeChange(e, property)}
                        min="0"
                        step="0.1"
                        readOnly={property.aerialPropertyObject}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col align="right" lg={2} hidden={this.state.isCompleteAWT}>
                    <Button
                      variant="secondary"
                      className={globalStyles.modalSubmitButton + " mt-2"}
                      onClick={() => this.removePropertyData(property)}
                    >
                      <span className={globalStyles.modalSubmitButtonText}>{"Remove"}</span>
                    </Button>
                  </Col>
                  <Col align="right" lg={2}>
                    <Button
                      variant="primary"
                      className={globalStyles.modalSubmitButton + " mt-2"}
                      onClick={() => this.enterPropertyData(property)}
                    >
                      <span className={globalStyles.modalSubmitButtonText}>
                        {this.state.isCompleteAWT ? "View Data" : "Enter Data"}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  renderEditCrewCard() {
    const { selectedProperty } = this.state.data;
    return (
      <React.Fragment>
        <Card className={styles.viewBigPageCards}>
          <Card.Header className={globalStyles.cardTitles}>
            <span className={globalStyles.cardTitleText}>Edit Crew</span>
          </Card.Header>
          <Card.Body>
            {selectedProperty?.selectedCrewMembers?.length > 0 && (
              <Row className={styles.crewDetailsTitleRow}>
                <Col sm={3} className="pl-0">
                  <span className={globalStyles.formLabel}>Crew Name</span>
                </Col>
                <Col sm={3}>
                  <span className={globalStyles.formLabel}>Hobbs Time</span>
                </Col>
              </Row>
            )}
            {selectedProperty?.selectedCrewMembers?.length > 0 &&
              selectedProperty.selectedCrewMembers.map((crewMember, index) => {
                return (
                  <Row key={index} className={styles.crewDetailsDataRow}>
                    <Col sm={3} className="pl-0">
                      <span className={globalStyles.formData}>{crewMember?.wsUserName || crewMember?.userName}</span>
                    </Col>
                    <Col sm={3}>
                      <span className={globalStyles.formLabel}>
                        <Form.Control
                          value={crewMember.hobbsTime || crewMember.hobbsHours}
                          type="number"
                          placeholder="Enter Hobbs Time"
                          className={styles.crewHobbsTimeInput}
                          onChange={(e) => this.handleCrewHobbsTimeChange(e, crewMember)}
                          readOnly={this.state.isCompleteAWT}
                        ></Form.Control>
                      </span>
                    </Col>
                  </Row>
                );
              })}
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }

  renderConflictList() {
    return (
      <React.Fragment>
        {this.state.data.selectedProperty?.propertyConflicts?.length > 0 &&
          this.state.data.selectedProperty.propertyConflicts.map((conflict, index) => {
            const fullString = `${conflict.targetedSpeciesName} - ${conflict.protectedResources
              .map((r) => r.protectedResourceName)
              .join(" + ")}`;
            return (
              <Row key={index} className={styles.conflictCardRow}>
                <Form.Check
                  className={styles.conflictCheckbox}
                  onChange={(e) => this.selectConflict(e, conflict)}
                  checked={conflict.isSelected}
                />
                <Button className={styles.conflictListingIdentifier} variant="link" alt={fullString}>
                  <span className={globalStyles.formDataLinks}>{fullString}</span>
                </Button>
              </Row>
            );
          })}
      </React.Fragment>
    );
  }

  renderResourceEffortList() {
    const { selectedProperty } = this.state.data;
    return (
      <React.Fragment>
        {selectedProperty?.resourcesEffort?.length > 0 &&
          selectedProperty.resourcesEffort.map((resource, index) => {
            return (
              <Row key={index} className={styles.conflictCardRow}>
                <Col className="text-left pl-0" md={4}>
                  <span className={globalStyles.formData}>
                    {resource.protectedResourceName + " | Unit Value: " + resource.valuePerUnitOfMeasure}
                  </span>
                </Col>
                <Col className="text-right pr-0">
                  <div className="form-inline justify-content-end">
                    <InputGroup>
                      <Form.Control
                        name="effortPercentage"
                        value={resource.effortPercentage}
                        type="number"
                        placeholder="% Effort"
                        className={styles.resourceEffortPercentHoursInput}
                        onChange={(e) => this.handleResourceEffortChange(e, resource)}
                        min="0"
                        max="100"
                      ></Form.Control>
                      <div className={styles.percentageSign}>
                        <span>%</span>
                      </div>
                    </InputGroup>
                    <Form.Control
                      name="hobbsHours"
                      value={resource.hobbsHours}
                      type="number"
                      placeholder="Hobbs Hours"
                      className={styles.resourceEffortHobbsHoursInput}
                      onChange={(e) => this.handleResourceEffortChange(e, resource)}
                      min="0.0"
                      max={selectedProperty?.totalFlightTime ? selectedProperty.totalFlightTime : 0.0}
                      step="0.1"
                    ></Form.Control>
                  </div>
                </Col>
              </Row>
            );
          })}
      </React.Fragment>
    );
  }

  renderSelectCrewDropdown() {
    return (
      <Form.Group className="text-left">
        {this.state.isCompleteAWT && !this.state.canEditAerialInformation ? (
          this.renderLabel(
            "selectedCrewMembers",
            "Crew",
            this.state.data.selectedCrewMembers?.map((c) => c.label).join(", ")
          )
        ) : (
          <React.Fragment>
            <Form.Label className={globalStyles.formLabel}>Crew</Form.Label>
            <AsyncSelect
              placeholder="Search Here"
              aria-label="Crew Search"
              value={this.state.data.selectedCrewMembers}
              isMulti
              styles={this.getSelectStyles()}
              components={{ DropdownIndicator, Option }}
              loadOptions={this.loadAvailableCrewMembers}
              onChange={this.handleCrewMemberSelection}
              isDisabled={this.state.createdAerialWorkTask && !this.state.canEditAerialInformation}
            />
          </React.Fragment>
        )}
      </Form.Group>
    );
  }

  renderCreateConflictModal() {
    let modal = null;
    modal = (
      <CreateConflictModal
        isModal={true}
        show={this.state.showCreateConflictModal}
        onHide={this.onCreateConflictModalClose}
        propertyGuid={this.state.data.selectedProperty?.propertyUniqueIdentifier}
        propertyData={this.state.data.selectedProperty}
        existingConflicts={this.state.data.selectedProperty.propertyConflicts}
      />
    );
    return modal;
  }

  renderConflictInformationCard() {
    return (
      <Accordion defaultActiveKey="0">
        <Accordion.Toggle as="p" eventKey="0">
          <div className={styles.conflictsAccordionToggle}>
            <this.contextAwareToggle eventKey="0" />
            <span className={styles.conflictInfoText}>Conflict Information</span>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Row noGutters="true">
            <Col lg={6}>
              <Card className={styles.conflictsCard}>
                <Card.Header className={globalStyles.cardTitles}>
                  <Row>
                    <Col className="pl-0">
                      <span className={globalStyles.cardTitleText}>{`Conflict(s) - ${this.state.propertyConflicts?.length || 0
                        }`}</span>
                    </Col>
                    <Col className="pr-0" hidden={this.state.isCompleteAWT}>
                      <Button variant="link" className={styles.conflictHeaderLink} onClick={this.createConflict}>
                        + Create New Conflict
                      </Button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className={styles.conflictsCardBody}>{this.renderConflictList()}</Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className={styles.resourceEffortCard}>
                <Card.Header className={globalStyles.cardTitles}>
                  <Row>
                    <Col className="pl-0">
                      <span className={globalStyles.cardTitleText}>Resource Effort</span>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className={styles.conflictsCardBody}>{this.renderResourceEffortList()}</Card.Body>
              </Card>
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
    );
  }

  renderDirectWorkTaskTab() {
    const { data } = this.state;
    return (
      <Card className="mt-4">
        <Card.Header className={globalStyles.cardTitles}>
          <span className={globalStyles.cardTitleText}>Method Entry</span>
        </Card.Header>
        <Card.Body className={styles.methodEntryContainer}>
          <Row>
            <Col className={styles.methodEntryContainer}>
              <div className={styles.methodEntryInnerContainer}>
                <Row>
                  <Col>
                    <div>
                      <span className={styles.tabTitle}>Method Entry</span>
                    </div>
                  </Col>
                </Row>
                <Row className={styles.methodEntryLabelRow}>
                  <Col>
                    <span id="methodEntryMethodSelectLabel">Method{" *"}</span>
                  </Col>
                  {(data.chosenMethodForProperty?.isCMITSMethodGlobally ||
                    data.chosenMethodForProperty?.isCMITSMethod) && (
                      <Col>
                        <span id="methodEntryWarehouseSelectLabel">
                          Warehouse
                          {data.chosenMethodForProperty?.isCMITSMethodGlobally ||
                            data.chosenMethodForProperty?.isCMITSMethod
                            ? " *"
                            : ""}
                        </span>
                      </Col>
                    )}
                  <Col>
                    <span id="methodEntryUseSelectLabel">Use{" *"}</span>
                  </Col>
                  <Col>
                    <span id="methodEntryQuantityLabel">Quantity{" *"}</span>
                  </Col>
                  <Col>
                    <span id="methodEntryUOMSelectLabel">UOM{" *"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      aria-labelledby="methodEntryMethodSelectLabel"
                      name="propertyMethodSearchSelection"
                      as="select"
                      placeholder="Select Method"
                      onChange={this.handleChange}
                      value={data?.propertyMethodSearchSelection}
                    >
                      <option></option>
                      {data.selectedProperty?.allowedMethods?.map((method) => {
                        return (
                          <option key={method.methodName} value={method.methodUniqueReference}>
                            {method.methodName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  {(data.chosenMethodForProperty?.isCMITSMethodGlobally ||
                    data.chosenMethodForProperty?.isCMITSMethod) && (
                      <Col>
                        <Form.Control
                          aria-labelledby="methodEntryWarehouseSelectLabel"
                          name="propertyMethodWarehouseSelection"
                          as="select"
                          placeholder="Select Warehouse"
                          onChange={this.handleChange}
                          value={data.propertyMethodWarehouseSelection}
                        >
                          <option></option>
                          {data.selectedProperty.availableWarehouses?.map((warehouse) => {
                            return (
                              <option
                                key={warehouse.warehouseUniqueIdentifier}
                                value={warehouse.warehouseUniqueIdentifier}
                              >
                                {warehouse.warehouseName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    )}
                  <Col>
                    <Form.Control
                      aria-labelledby="methodEntryUseSelectLabel"
                      name="propertyMethodChosenUse"
                      as="select"
                      disabled={!data.propertyMethodSearchSelection}
                      placeholder="Select use"
                      onChange={this.handleChange}
                    >
                      {data.chosenMethodForProperty?.allowedUsesInState?.map((use, index) => {
                        if (index === 0 && data.chosenMethodForProperty?.allowedUsesInState?.length > 1) {
                          return [
                            <option key="default-use"></option>,
                            <option key={use.name}>{use.displayText}</option>,
                          ];
                        } else {
                          return <option key={use.name}>{use.displayText}</option>;
                        }
                      })}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Form.Control
                      aria-labelledby="methodEntryQuantityLabel"
                      name="propertyMethodQuantity"
                      as="input"
                      type="number"
                      placeholder="Enter Qty"
                      onChange={this.handleChange}
                      value={data.propertyMethodQuantity}
                      min="1"
                    ></Form.Control>
                  </Col>
                  <Col>
                    <Form.Control
                      aria-labelledby="methodEntryUOMSelectLabel"
                      name="propertyMethodChosenUOM"
                      as="select"
                      disabled={!data.propertyMethodSearchSelection}
                      placeholder="Select UOM"
                      onChange={this.handleChange}
                      value={data.propertyMethodChosenUOM}
                    >
                      {data.chosenMethodForProperty?.allowedUOMsInState?.map((uom, index) => {
                        if (index === 0 && data.chosenMethodForProperty?.allowedUOMsInState?.length > 1) {
                          return [
                            <option key="default-uom"></option>,
                            <option key={uom.name}>{uom.displayText}</option>,
                          ];
                        } else {
                          return <option key={uom.name}>{uom.displayText}</option>;
                        }
                      })}
                    </Form.Control>
                  </Col>
                </Row>

                <Row className={styles.rowSpacing}>
                  <Col lg={3}>
                    <span id="methodEntryTargetSelectLabel">Target(s)</span>
                    <Dropdown>
                      <Dropdown.Toggle
                        disabled={this.state.methodTargets?.length === 0}
                        className={styles.selectProtectsDropdown}
                      >
                        Select Damage Agents
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                          {data.propertyMethodTargets?.map((target, index) => (
                            <div
                              key={`formChecklistContainerProtections-${target.targetedSpeciesName}`}
                              className={styles.formCheckContainer}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input ml-0"
                                id={`${target.targetedSpeciesName}${target.targetedSpeciesUniqueReference}`}
                                key={`inline-${target.targetedSpeciesName}${target.targetedSpeciesUniqueReference}`}
                                onChange={(e) => this.handleTargetsSelection(e, target, index)}
                                checked={data.propertyMethodChosenTargets?.find(
                                  (t) => t.targetedSpeciesUniqueReference === target.targetedSpeciesUniqueReference
                                )}
                                data-toggle="button"
                              />
                              <p className="form-check-label ml-4" htmlFor="itemChecked">
                                {target.targetedSpeciesName}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col lg={3}>
                    <span id="methodEntryProtectSelectLabel">Protecting</span>
                    <Dropdown>
                      <Dropdown.Toggle
                        disabled={this.state.methodProtects?.length === 0}
                        className={styles.selectProtectsDropdown}
                      >
                        Select Resources
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                          {data.selectedProperty.resourcesEffort?.map((resource, index) => (
                            <div
                              key={`formChecklistContainerProtections-${resource.protectedResourceName}`}
                              className={styles.formCheckContainer}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input ml-0"
                                id={`${resource.protectedResourceName}${resource.protectedResourceUniqueReference}`}
                                key={`inline-${resource.protectedResourceName}${resource.protectedResourceUniqueReference}`}
                                onChange={(e) => this.handleProtectsSelection(e, resource, index)}
                                checked={data.selectedProperty.propertyMethodChosenProtections?.find(
                                  (p) =>
                                    p.protectedResourceUniqueReference === resource.protectedResourceUniqueReference
                                )}
                                data-toggle="button"
                              />
                              <p className="form-check-label ml-4" htmlFor="itemChecked">
                                {resource.protectedResourceName}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                <Row className={styles.methodEntryLabelRow}>
                  <Col lg={9}></Col>
                  <Col lg={3}>
                    <Button
                      variant="secondary"
                      className={styles.methodEntryAddButton}
                      onClick={this.addPropertyMethodToInventory}
                      disabled={
                        data.propertyMethodSearchSelection === "" ||
                        ((data.chosenMethodForProperty?.isCMITSMethodGlobally ||
                          data.chosenMethodForProperty?.isCMITSMethod) &&
                          !data.propertyMethodWarehouseSelection) ||
                        data.methodChosenUse === "" ||
                        data.methodChosenUOM === "" ||
                        data.methodQuantity === ""
                      }
                    >
                      Add Method
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  getSelectStyles() {
    return {
      indicatorSeparator: () => {
        //do nothing
      },
      control: (styles, { isDisabled }) => {
        return {
          ...styles,
          backgroundColor: isDisabled ? "#e9ecef" : "white",
          color: "black",
          border: "1px solid #ced4da",
          borderRadius: "0.25rem",
        };
      },
      placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
      option: (styles, { isFocused }) => ({
        ...styles,
        fontSize: "14px",
        fontWeight: "normal",
        color: "black",
        backgroundColor: isFocused ? "lightgray" : "white",
      }),
      multiValue: (styles) => ({
        ...styles,
        fontSize: "14px",
        fontWeight: "normal",
        color: "black",
      }),
      singleValue: (styles, { isDisabled }) => ({
        ...styles,
        fontSize: "14px",
        fontWeight: "normal",
        color: isDisabled ? "#495057" : "black",
      }),
      menuList: (styles) => ({ ...styles, textAlign: "left" }),
    };
  }

  renderMainPageFooterButtoms() {
    return (
      <div className={styles.viewPageCards} hidden={this.state.isCompleteAWT}>
        <Row className="mx-0 mt-4">
          <Col className="pl-0 text-left">
            <Button variant="outline-primary" onClick={this.handleCancel}>
              <span>Cancel</span>
            </Button>
          </Col>
          <Col className="pr-0 text-right">
            <Button className={styles.submitFormButton} variant="primary" type="submit">
              <span className={globalStyles.modalSubmitButtonText}>
                {this.state.createdAerialWorkTask ? "Complete AWT" : "Create Draft AWT"}
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  renderWorkPerformedCard() {
    const { selectedProperty } = this.state.data;
    return (
      <React.Fragment>
        <Card className={styles.viewBigPageCards}>
          <Card.Header className={globalStyles.cardTitles}>
            <span className={globalStyles.cardTitleText}>Work Performed</span>
          </Card.Header>
          <Card.Body>
            <BootstrapTable
              keyField="aerialWorkPerformedUniqueIdentifier"
              data={selectedProperty.aerialPropertyObject?.workPerformed ?? []}
              columns={this.state.workPerformedTableColumns}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }

  renderSecondaryPageFooterButtoms() {
    const { isCompleteAWT } = this.state;
    return (
      <div className={styles.viewBigPageCards}>
        <Row className="mx-0 mt-4">
          <Col className="pr-0 text-right">
            <Button variant="outline-primary" onClick={this.handleCancel}>
              <span className={globalStyles.modalCancelButtonText}>{isCompleteAWT ? "Back" : "Cancel"}</span>
            </Button>
            <Button hidden={isCompleteAWT} className="ml-2" variant="primary" type="submit">
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    let pageTitle = "";
    let pageContent = null;
    const { isCompleteAWT } = this.state;
    if (this.state.isMainPage) {
      pageTitle = "Aerial Work Task";
      pageContent = (
        <React.Fragment>
          {this.renderAerialInformationCard()}
          {this.state.createdAerialWorkTask && this.renderPropertiesCard()}
          {this.state.createdAerialWorkTask && !isCompleteAWT && this.renderMethodEntryCard()}
        </React.Fragment>
      );
    } else {
      pageTitle = `Aerial Work Task - ${this.state.data.selectedProperty?.propertyName}`;
      pageContent = (
        <div>
          {this.renderEditCrewCard()}
          {this.renderConflictInformationCard()}
          {this.renderDirectWorkTaskTab()}
          {this.renderWorkPerformedCard()}
        </div>
      );
    }

    return (
      <Form className={styles.pageForm} onSubmit={this.handleSubmit}>
        <div className={styles.viewPageCards}>
          <h1 className={globalStyles.pageTitle}>{pageTitle}</h1>
        </div>
        {pageContent}
        {this.state.isMainPage ? this.renderMainPageFooterButtoms() : this.renderSecondaryPageFooterButtoms()}
        <EnterTakeModal
          show={this.state.showEnterTakeModal}
          onHide={() => {
            this.setState({ showEnterTakeModal: false });
          }}
          data={this.state.enterTakeData}
          onAddTake={this.saveTakeCapture}
          workTaskDate={this.state.data.aerialWorkTaskDate}
        />
        <SuccessfulRecordModifiedModal
          show={this.state.showCreateSuccessModal}
          onHide={() => {
            this.setState({ showCreateSuccessModal: false });
          }}
          onAcknowledgeRoute={"/home"}
          objectName="Aerial Work Task"
        />
        {this.renderCreateConflictModal()}
      </Form>
    );
  }
}

export default withRouter(AerialWorkTask);
