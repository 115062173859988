import React from "react";
import CustomForm from "../../../common/form";
import { Modal, Row, Col, Form, Button, Tabs, Tab, Image } from "react-bootstrap";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import searchIcon from "../../../../assets/search.svg";
import AgreementEntitiesAPI from "../../../../api/AgreementEntities/AgreementEntitiesAPI";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import { toast } from "react-toastify";
import xCircle from "../../../../assets/x-circle.svg";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class DiseaseMonitoringModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        date: "",
        employeeName: "",
        employeeId: "",
        selectedProperty: "",
        propertyName: "",
        locTypeName: "",
        locTypeEnumId: "",
        latitude: "",
        longitude: "",
        WSTake: true,
      },
      locTypes: [],
      genderOptions: [],
      ageOptions: [],
      sampleTypeOptions: [],
      diseaseOptions: [],
      animalTableRowsSelected: [],
      diseaseSamplingQty: 0,
      animalTableColumns: [
        {
          dataField: "index",
          hidden: true,
        },
        {
          dataField: "subjectId",
          text: "Subject ID",
          style: () => {
            return { fontSize: "14px" };
          },
          headerStyle: () => {
            return { borderTop: "hidden", borderBottom: "hidden", fontSize: "14px" };
          },
        },
        {
          dataField: "speciesName",
          text: "Damage Agent",
          style: () => {
            return { fontSize: "14px" };
          },
          headerStyle: () => {
            return { borderTop: "hidden", borderBottom: "hidden", fontSize: "14px" };
          },
        },
        {
          dataField: "age",
          text: "Age",
          style: () => {
            return { fontSize: "14px" };
          },
          headerStyle: () => {
            return { borderTop: "hidden", borderBottom: "hidden", fontSize: "14px", width: "15%" };
          },
        },
        {
          dataField: "gender",
          text: "Gender",
          style: () => {
            return { fontSize: "14px" };
          },
          headerStyle: () => {
            return { borderTop: "hidden", borderBottom: "hidden", fontSize: "14px" };
          },
        },
      ],
      animalTableData: [],
      errors: {},
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
    };
  }

  async componentDidMount() {
    const { data } = this.state;
    let propertyData = this.props.propertyData;
    data.propertyName = propertyData?.propertyName;
    data.selectedProperty = this.props.propertyData;

    if (this.props.fromSiteVisit) {
      data.locTypeName = "Property";
      data.locTypeEnumId = 1;
    }
    this.setState({ data });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    const propsDiseaseSamplingQtyChange =
      prevProps.takeData.diseaseSamplingQty !== this.props.takeData.diseaseSamplingQty;
    const propsShowModalChange = prevProps.show !== this.props.show;
    const propsPvsDateChange = prevProps.pvsDate !== this.props.pvsDate;
    const propsEmployeeNameChange = prevProps.employee !== this.props.employee;
    const propsLatitudeChange = prevProps.takeData.latitude !== this.props.takeData.latitude;
    const propsLongitudeChange = prevProps.takeData.longitude !== this.props.takeData.longitude;
    if (propsDiseaseSamplingQtyChange && this.props.takeData?.diseaseSamplingQty > 0) {
      const array = Array(parseInt(this.props.takeData?.diseaseSamplingQty))
        .fill()
        .map((x, i) => {
          return {
            index: i,
            subjectId: "",
            speciesName: this.props.takeData.selectedSpeciesName ? this.props.takeData.selectedSpeciesName : "",
            checked: false,
            speciesUniqueIdentifier: this.props.takeData.unintentionalSpecies
              ? this.props.takeData.unintentionalSpecies
              : this.props.takeData.intentionalSpecies,
            diseaseAndSampleRows: [{ disease: "", sample: "" }],
          };
        });
      this.setState({ animalTableData: array, diseaseSamplingQty: parseInt(this.props.takeData.diseaseSamplingQty) });
    }
    if (propsShowModalChange && this.props.show) {
      this.setState({
        diseaseOptions: await this.getDiseasesFromAPI(this.state.currentUsaState),
        locTypes: await this.getLocTypesFromAPI(),
        genderOptions: await this.getGenderOptionsFromAPI(),
        ageOptions: await this.getAgeOptionsFromAPI(),
        sampleTypeOptions: await this.getsampleTypeOptionsFromAPI(),
      });
    }
    if (propsPvsDateChange) {
      data.date = this.props.pvsDate.split("-")[0].trim();
      this.setState({ data });
    }
    if (propsEmployeeNameChange) {
      data.employeeName = this.props.employee.label;
      data.employeeId = this.props.employee.value;
      this.setState({ data });
    }
    if (propsLatitudeChange) {
      data.latitude = this.props.takeData.latitude;
      this.setState({ data });
    }
    if (propsLongitudeChange) {
      data.longitude = this.props.takeData.longitude;
      this.setState({ data });
    }
  }

  async handleSpecificChanges(input) {
    //do nothing
  }

  handleTakeChanges = () => {
    const { data } = this.state;
    data.WSTake = !data.WSTake;
    this.setState({ data });
  };

  getPropertiesFromAPI = async (searchValue) => {
    let searchObj = {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
    };

    if (this.state.currentUsaState?.stateGuid) {
      const propertiesData = await AgreementEntitiesAPI.SearchPropertiesFreeText(
        searchObj,
        this.state.currentUsaState.stateGuid
      );

      if (propertiesData?.data?.results) {
        let results = propertiesData.data.results;
        return results.map((result) => ({
          value: result.propertyUniqueIdentifier,
          label: result.propertyName,
        }));
      }
    }
    return "";
  };

  getSpeciesFromAPI = async (searchValue) => {
    let searchObj = {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
    };

    if (this.state.currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(this.state.currentUsaState.stateGuid, searchObj);

      if (speciesData?.data?.results) {
        let results = speciesData.data.results;
        return results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
        }));
      }
    }
    return "";
  };

  async getDiseasesFromAPI(currentUsaState) {
    let diseases = [];
    if (currentUsaState?.stateGuid) {
      const diseasesData = await ReferenceFileAPI.GetDiseasesForState(currentUsaState.stateGuid);

      if (diseasesData?.data?.results) {
        diseases = diseasesData.data.results
          .filter((d) => d?.isAllowed)
          .map((result) => ({
            value: result.diseaseUniqueIdentifier,
            label: result.name,
          }));
      }
    }
    return diseases;
  }

  async getLocTypesFromAPI() {
    let locTypes = [];
    const locTypesData = await WorkTaskAPI.GetLocTypeOptions();
    if (locTypesData?.data) {
      locTypes = locTypesData.data.map((result) => ({
        id: result.id,
        name: result.displayText,
      }));
    } else if (locTypesData?.unsuccessful) {
      toast.warn(locTypesData?.message);
    } else {
      toast.warn("Could not retreive Location Types");
    }
    return locTypes;
  }

  async getGenderOptionsFromAPI() {
    let genderOptions = [];
    const genderOptionsData = await WorkTaskAPI.GetGenderOptions();
    if (genderOptionsData?.data) {
      genderOptions = genderOptionsData.data.map((result) => ({
        value: result.id,
        label: result.displayText,
      }));
    } else if (genderOptionsData?.unsuccessful) {
      toast.warn(genderOptionsData?.message);
    } else {
      toast.warn("Could not retreive Genders");
    }
    return genderOptions;
  }

  async getAgeOptionsFromAPI() {
    let ageOptions = [];
    const ageOptionsData = await WorkTaskAPI.GetAgeOptions();
    if (ageOptionsData?.data) {
      ageOptions = ageOptionsData.data.map((result) => ({
        value: result.id,
        label: result.displayText,
      }));
    } else if (ageOptionsData?.unsuccessful) {
      toast.warn(ageOptionsData?.message);
    } else {
      toast.warn("Could not retreive Age options");
    }
    return ageOptions;
  }

  async getsampleTypeOptionsFromAPI() {
    let sampleTypeOptions = [];
    const sampleTypeOptionsData = await WorkTaskAPI.GetSampleOptions();
    if (sampleTypeOptionsData?.data) {
      sampleTypeOptions = sampleTypeOptionsData.data.map((result) => ({
        value: result.id,
        label: result.displayText,
      }));
    } else if (sampleTypeOptionsData?.unsuccessful) {
      toast.warn(sampleTypeOptionsData?.message);
    } else {
      toast.warn("Could not retreive sample types");
    }
    return sampleTypeOptions;
  }

  handlePropertySelection = async (input) => {
    const { data } = this.state;
    const responseData = await AgreementEntitiesAPI.GetProperty(input.value);
    if (responseData?.data) {
      const propertyData = responseData.data;
      data.selectedProperty = propertyData;
      data.selectedProperty.value = propertyData.propertyUniqueIdentifier;
      data.selectedProperty.label = propertyData.propertyName;
    }

    this.setState({ data });
  };

  handleSpeciesSelection = (input, i) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        if (item.index !== i) {
          return item;
        } else {
          item.speciesName = input.label;
          item.speciesInputValue = input.label;
          item.speciesUniqueIdentifier = input.value;
          return item;
        }
      }),
    });
  };

  handleSpeciesInputChange = (e, action, i) => {
    if (action === "input-change") {
      this.setState({
        animalTableData: this.state.animalTableData.map((item) => {
          if (item.index !== i) {
            return item;
          } else {
            item.speciesInputValue = e;
            return item;
          }
        }),
      });
      return e;
    }
    if (action === "input-blur" || action === "menu-close") {
      this.setState({
        animalTableData: this.state.animalTableData.map((item) => {
          if (item.index !== i) {
            return item;
          } else {
            item.speciesInputValue = item.speciesName;
            return item;
          }
        }),
      });
      return this.state.animalTableData[i].speciesName;
    }
  };

  handleMenuClose = () => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        item.speciesInputValue = item.speciesName ? item.speciesName : "test";
        return item;
      }),
    });
  };

  handleGenderSelection = (input, i) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        if (item.index !== i) {
          return item;
        } else {
          item.gender = input.value;
          return item;
        }
      }),
    });
  };

  handleAgeSelection = (input, i) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        if (item.index !== i) {
          return item;
        } else {
          item.age = input.value;
          return item;
        }
      }),
    });
  };

  handleDiseaseSelection = (input, i, row, rowIndex) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        if (item.index !== i) {
          return item;
        } else {
          item.diseaseAndSampleRows = item.diseaseAndSampleRows.map((r, rIndex) => {
            if (rowIndex === rIndex) {
              r.disease = input.value;
            }
            return r;
          });
          return item;
        }
      }),
    });
  };

  handleSampleTypeSelection = (input, i, row, rowIndex) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        if (item.index !== i) {
          return item;
        } else {
          item.diseaseAndSampleRows = item.diseaseAndSampleRows.map((r, rIndex) => {
            if (rowIndex === rIndex) {
              r.sample = input.value;
            }
            return r;
          });
          return item;
        }
      }),
    });
  };

  handleSubjectIdChange = (e, i) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        if (item.index !== i) {
          return item;
        } else {
          item.subjectId = e.target.value;
          return item;
        }
      }),
    });
  };

  handleCopyFromPrevious = (e, i) => {
    if (i - 1 < 0) {
      toast.error("Please clear your data and try again.");
    } else {
      this.setState({
        animalTableData: this.state.animalTableData.map((item) => {
          if (item.index !== i) {
            return item;
          } else {
            let previousItem = this.state.animalTableData[i - 1];
            item.speciesName = previousItem.speciesName;
            item.speciesInputValue = previousItem.speciesName;
            item.speciesUniqueIdentifier = previousItem.speciesUniqueIdentifier;
            item.diseaseAndSampleRows = JSON.parse(JSON.stringify(previousItem.diseaseAndSampleRows));
            return item;
          }
        }),
      });
    }
  };

  handleAddAnotherSample = (e, i) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        if (item.index === i) {
          item.diseaseAndSampleRows.push({ disease: "", sample: "" });
        }
        return item;
      }),
    });
  };

  handleRemoveSample = (e, i, row, rowIndex) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((item) => {
        if (item.index === i) {
          item.diseaseAndSampleRows = item.diseaseAndSampleRows.filter((r, index) => index !== rowIndex);
        }
        return item;
      }),
    });
  };

  selectStyles = {
    control: (styles) => ({ ...styles, fontSize: "14px" }),
    menu: (styles) => ({ ...styles, marginTop: "0px" }),
    noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "#EAF3F1";
      return {
        ...styles,
        fontSize: "12px",
        textAlign: "left",
        borderBottom: "1px solid #f2f2f2",
        backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
        color: data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? color : null),
        },
      };
    },
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    input: (provided) => ({
      ...provided,
      input: {
        opacity: "1 !important",
      },
    }),
  };

  renderAnimalCards() {
    return this.state.animalTableData
      .filter((x) => x?.checked)
      .map((item, i) => (
        <Row key={i + "animalSampleDiseaseMonitoring"}>
          <Col>
            <Tabs>
              <Tab
                key={item.index}
                eventKey={item.index}
                title={item.subjectId ? item.subjectId : `Animal ${item.index + 1}`}
                className={styles.diseaseMonitoringAnimalBox}
              >
                <Form>
                  <Row>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>Subject ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter"
                          aria-label={"Subject ID Input " + i}
                          value={item.subjectId}
                          onChange={(e) => this.handleSubjectIdChange(e, item.index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col align="left" hidden={item.index === 0} className="align-middle">
                      <Button
                        variant="link"
                        onClick={(e) => this.handleCopyFromPrevious(e, item.index)}
                        style={{ marginTop: "26px" }}
                        className={styles.diseaseMonitoringTableText}
                      >
                        <span>{"\u21BB"} Copy Disease/Sample Type from Previous</span>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>
                          Damage Agent<span className={globalStyles.asterisk508}>{" *"}</span>
                        </Form.Label>
                        <AsyncSelect
                          defaultInputValue={
                            this.props.takeData.selectedSpeciesName ? this.props.takeData.selectedSpeciesName : null
                          }
                          inputValue={item.speciesInputValue}
                          aria-label="Search For Damage Agents"
                          placeholder="Search here.."
                          components={{ DropdownIndicator }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "",
                              primary: "#ced4da",
                            },
                          })}
                          styles={this.selectStyles}
                          loadOptions={this.getSpeciesFromAPI}
                          onChange={(e) => this.handleSpeciesSelection(e, item.index)}
                          onInputChange={(e, { action }) => this.handleSpeciesInputChange(e, action, item.index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>
                          Gender<span className={globalStyles.asterisk508}>{" *"}</span>
                        </Form.Label>
                        <Select
                          value={this.state.genderOptions.filter((option) => option.value === item.gender)}
                          aria-label="Gender Selection"
                          placeholder="Select"
                          styles={this.selectStyles}
                          options={this.state.genderOptions}
                          onChange={(e) => this.handleGenderSelection(e, item.index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>
                          Age<span className={globalStyles.asterisk508}>{" *"}</span>
                        </Form.Label>
                        <Select
                          value={this.state.ageOptions.filter((option) => option.value === item.age)}
                          aria-label="Age Selection"
                          placeholder="Select"
                          styles={this.selectStyles}
                          options={this.state.ageOptions}
                          onChange={(e) => this.handleAgeSelection(e, item.index)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {item.diseaseAndSampleRows.map((row, rowIndex) => {
                    return (
                      <Row key={rowIndex + "diseaseAndSampleRow"}>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label className={globalStyles.formLabel}>
                              Disease<span className={globalStyles.asterisk508}>{" *"}</span>
                            </Form.Label>
                            <Select
                              id={"diseaseAndSampleRowDisease" + rowIndex}
                              value={this.state.diseaseOptions.filter((option) => option.value === row.disease)}
                              aria-label="Disease Selection"
                              placeholder="Select"
                              styles={this.selectStyles}
                              options={this.state.diseaseOptions}
                              onChange={(e) => this.handleDiseaseSelection(e, item.index, row, rowIndex)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={item.diseaseAndSampleRows.length < 2 ? 6 : 5}>
                          <Form.Group>
                            <Form.Label className={globalStyles.formLabel}>
                              Sample Type<span className={globalStyles.asterisk508}>{" *"}</span>
                            </Form.Label>
                            <Select
                              id={"diseaseAndSampleRowSample" + rowIndex}
                              value={this.state.sampleTypeOptions.filter((option) => option.value === row.sample)}
                              aria-label="Sample Type Selection"
                              placeholder="Select"
                              styles={this.selectStyles}
                              options={this.state.sampleTypeOptions}
                              onChange={(e) => this.handleSampleTypeSelection(e, item.index, row, rowIndex)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={1} hidden={item.diseaseAndSampleRows.length < 2} className={styles.xCircleCol}>
                          <Image
                            className={styles.xCircle}
                            src={xCircle}
                            onClick={(e) => this.handleRemoveSample(e, item.index, row, rowIndex)}
                            tabIndex="-1"
                            alt="Remove Sample"
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <Col align="right">
                      <Button
                        variant="link"
                        onClick={(e) => this.handleAddAnotherSample(e, item.index)}
                        className={styles.diseaseMonitoringTableText}
                      >
                        <span>+ Add another Sample</span>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      ));
  }

  handleSelection = ({ currentTarget: input }) => {
    this.setState({
      animalTableData: this.state.animalTableData.map((i, index) => {
        if (input.name === "allCheckbox") {
          let element = document.getElementById(index + "checkboxId");
          element.checked = input.checked;
          i.checked = input.checked;
          return i;
        }
        if (!input.name.includes(i.index)) {
          return i;
        } else {
          i.checked = input.checked;
          return i;
        }
      }),
    });
  };

  handleAddAnimal = () => {
    if (this.state.diseaseSamplingQty < parseInt(this.props.takeData.totalAnimalsSampled)) {
      const newIndex = this.state.animalTableData.length;
      this.setState({
        animalTableData: [
          ...this.state.animalTableData,
          {
            index: newIndex,
            subjectId: "",
            speciesName: this.props.takeData.selectedSpeciesName ? this.props.takeData.selectedSpeciesName : "",
            checked: false,
            speciesUniqueIdentifier: this.props.takeData.unintentionalSpecies
              ? this.props.takeData.unintentionalSpecies
              : this.props.takeData.intentionalSpecies,
            diseaseAndSampleRows: [{ disease: "", sample: "" }],
          },
        ],
        diseaseSamplingQty: this.state.diseaseSamplingQty + 1,
      });
    } else {
      toast.error("Cannot add more than Total Animal Sampled");
    }
  };

  handleRemoveAnimal = () => {
    if (this.state.animalTableData.length < 2) {
      toast.warn("Cannot remove last record");
    } else {
      let tableData = this.state.animalTableData
        .filter((item) => !item.checked)
        .map((item, i) => {
          item.index = i;
          return item;
        });
      if (tableData.length === 0) {
        toast.warn("Please select a record to remove. You cannot remove the last record.");
        return;
      }
      this.setState(
        {
          animalTableData: tableData,
        },
        () => {
          this.setState({ diseaseSamplingQty: this.state.animalTableData.length });
          let element = document.getElementById("diseaseMonitoringAllCheckbox");
          if (element) {
            element.checked = false;
          }
        }
      );
    }
  };

  getTableRowStyle = (row) => {
    if (row.checked) {
      return { backgroundColor: "#DAE9E5" };
    }
  };

  validateData() {
    let animalTable = this.state.animalTableData;
    for (let i = 0; i < animalTable.length; i++) {
      const item = animalTable[i];
      const diseaseAndSamples = item.diseaseAndSampleRows;
      for (let j = 0; j < diseaseAndSamples?.length; j++) {
        let row = diseaseAndSamples[j];
        if (row.disease && row.sample) {
          continue;
        } else {
          return false;
        }
      }
      if (item.gender && item.age && item.speciesUniqueIdentifier && item.speciesName) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  }

  handleNext = () => {
    if (this.validateData()) {
      this.props.save(this.state.data, this.state.animalTableData, this.props.takeData);
    } else {
      toast.error("Please fill out all required fields");
    }
  };

  clearData = () => {
    if (this.props.fromSiteVisit) {
      this.setState({ animalTableData: [] });
    } else {
      const { data } = this.state;
      data.date = "";
      data.employeeName = "";
      data.employeeId = "";
      data.selectedProperty = "";
      data.propertyName = "";
      data.locTypeName = "";
      data.locTypeEnumId = "";
      data.latitude = "";
      data.longitude = "";
      data.WSTake = true;
      this.setState({ data, diseaseSamplingQty: 0, animalTableData: [] });
    }
  };

  renderPropertyField() {
    if (this.props.fromSiteVisit) {
      return this.renderInput("propertyName", "Property Name", "text", "Enter", "readOnly");
    }
    return (
      <React.Fragment>
        <Form.Label className={globalStyles.formLabel}>Property Name</Form.Label>
        <AsyncSelect
          value={this.state.data.selectedProperty}
          defaultInputValue={this.props.propertyData.propertyName}
          aria-label="Search For Properties"
          openMenuOnClick={false}
          placeholder="Search here.."
          components={{ DropdownIndicator }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "",
              primary: "#ced4da",
            },
          })}
          styles={{
            control: (styles) => ({ ...styles, fontSize: "14px" }),
            menu: (styles) => ({ ...styles, marginTop: "0px" }),
            noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              const color = "#EAF3F1";
              return {
                ...styles,
                fontSize: "12px",
                textAlign: "left",
                borderBottom: "1px solid #f2f2f2",
                backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                color: data.color,
                cursor: isDisabled ? "not-allowed" : "default",

                ":active": {
                  ...styles[":active"],
                  backgroundColor: !isDisabled && (isSelected ? color : null),
                },
              };
            },
            indicatorSeparator: () => {
              //do nothing
            },
            placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
          }}
          loadOptions={this.getPropertiesFromAPI}
          onChange={this.handlePropertySelection}
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.clearData();
          this.props.onHide();
        }}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.diseaseMonitoringModalDialog}
      >
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Disease Monitoring</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "hidden" }}>
          <Row>
            <Col lg={4} className={styles.diseaseMonitoringTableColumn}>
              <Row className={styles.diseaseMonitoringTableRow}>
                <Col sm={1}>
                  <Form.Check
                    name={"allCheckbox"}
                    id="diseaseMonitoringAllCheckbox"
                    type="checkbox"
                    title="allCheckbox"
                    label=""
                    onChange={this.handleSelection}
                  />
                </Col>
                <Col sm={3} className={styles.diseaseMonitoringTableText}>
                  Subject ID
                </Col>
                <Col sm={4} className={styles.diseaseMonitoringTableText}>
                  Damage Agent
                </Col>
                <Col sm={2} className={styles.diseaseMonitoringTableText}>
                  Age
                </Col>
                <Col sm={2} className={styles.diseaseMonitoringTableText}>
                  Gender
                </Col>
              </Row>
              {this.state.animalTableData.map((item, index) => {
                return (
                  <Row
                    style={this.getTableRowStyle(item)}
                    className={styles.diseaseMonitoringTableRow}
                    key={index + "diseaseMonitoringTable"}
                  >
                    <Col sm={1}>
                      <Form.Check
                        name={item.index + "checkbox"}
                        type="checkbox"
                        title={"checkbox number " + item.index}
                        label=""
                        id={item.index + "checkboxId"}
                        onChange={this.handleSelection}
                        checked={item.checked}
                      />
                    </Col>
                    <Col
                      sm={3}
                      className={
                        item.subjectId ? styles.diseaseMonitoringTableText : styles.diseaseMonitoringTableTextGray
                      }
                    >
                      {item.subjectId ? item.subjectId : `Animal ${item.index + 1}`}
                    </Col>
                    <Col sm={4} className={styles.diseaseMonitoringTableText}>
                      {item.speciesName ? item.speciesName : ""}
                    </Col>
                    <Col sm={2} className={styles.diseaseMonitoringTableText}>
                      {item.age ? this.state.ageOptions?.find((age) => age.value === item.age)?.label : ""}
                    </Col>
                    <Col sm={2} className={styles.diseaseMonitoringTableText}>
                      {item.gender
                        ? this.state.genderOptions?.find((gender) => gender.value === item.gender)?.label
                        : ""}
                    </Col>
                  </Row>
                );
              })}
              <Row>
                <Col align="left">
                  <Button variant="link" onClick={this.handleAddAnimal} className={styles.diseaseMonitoringTableText}>
                    <span>+ Add Another Animal</span>
                  </Button>
                </Col>
                <Col align="right">
                  <Button
                    variant="link"
                    onClick={this.handleRemoveAnimal}
                    className={styles.diseaseMonitoringTableText}
                  >
                    <span>Remove</span>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col className={styles.diseaseMonitoringRightSideColumn}>
              <Row>
                <Col lg={3}>
                  {this.props.fromSiteVisit
                    ? this.renderInput("date", "Date", "text", "Enter", "readOnly")
                    : this.renderInput("date", "Date", "date", "Select Date", "required")}
                </Col>
                <Col lg={3}>
                  {this.renderInput(
                    "employeeName",
                    "Employee Name",
                    "text",
                    "Enter",
                    this.props.fromSiteVisit ? "readOnly" : "",
                    this.props.fromSiteVisit ? "required" : ""
                  )}
                </Col>
                <Col lg={3} align="left" style={{ marginBottom: "16px" }}>
                  {this.renderPropertyField()}
                </Col>
                <Col lg={3}>
                  {this.props.fromSiteVisit
                    ? this.renderInput("locTypeName", "Loc Type", "text", "Enter", "readOnly")
                    : this.renderSelect("locTypeEnumId", "Loc Type", this.state.locTypes, "Select")}
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Label className={globalStyles.formLabel}>Latitude</Form.Label>
                  <Form.Control
                    aria-labelledby="diseaseMonitorLatitudeLabel"
                    name="diseaseMonitorLatitude"
                    type="number"
                    step="0.00001"
                    className={globalStyles.formData}
                    placeholder={this.props.fromSiteVisit ? "" : "Enter Latitude"}
                    onChange={this.handleChange}
                    value={this.state.data.latitude ? parseFloat(this.state.data.latitude).toFixed(5) : ""}
                    readOnly={this.props.fromSiteVisit}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label className={globalStyles.formLabel}>Longitude</Form.Label>
                  <Form.Control
                    aria-labelledby="diseaseMonitorLongitudeLabel"
                    name="diseaseMonitorLongitude"
                    type="number"
                    step="0.00001"
                    className={globalStyles.formData}
                    placeholder={this.props.fromSiteVisit ? "" : "Enter Longitude"}
                    onChange={this.handleChange}
                    value={this.state.data.longitude ? parseFloat(this.state.data.longitude).toFixed(5) : ""}
                    readOnly={this.props.fromSiteVisit}
                  />
                </Col>
                <Col>
                  <Form.Group controlId="WSTakeCheckbox">
                    <Form.Check
                      name="WS Take"
                      type="checkbox"
                      label="WS Take"
                      style={{ paddingTop: "36px" }}
                      checked={this.state.data.WSTake}
                      onChange={this.handleTakeChanges}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {this.renderAnimalCards()}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className={globalStyles.modalFooter} style={{ display: "block" }}>
          <Row>
            <Col align="left">
              <Button className={globalStyles.modalCancelButton} onClick={this.props.back}>
                <span className={globalStyles.modalCancelButtonText}>Back</span>
              </Button>
            </Col>
            <Col align="right">
              <Button className={globalStyles.ModalCreateButton} onClick={this.handleNext}>
                <span className={globalStyles.ModalCreateButtonText}>
                  {this.state.animalTableData.length > 0 ? "Next" : "Submit"}
                </span>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DiseaseMonitoringModal;
