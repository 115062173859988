import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { toast } from "react-toastify";
import Select from "react-select";

class CreateEditTAActivityModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: this.props?.modalData
        ? {
            ...this.props.modalData,
            selectedActivityTypes: this.props.modalData.technicalAssistTypesForActivity.map((t) => ({
              label: t.displayText ? t.displayText : t.name,
              value: t.id,
            })),
          }
        : {
            activityName: "",
            createdDateTimeUTC: "",
            lastModifiedDateTimeUTC: "",
            selectedActivityTypes: [],
          },
      errors: {},
      activityTypes: [],
    };
  }

  async componentDidMount() {
    let activityTypes = [];
    const activityTypesResponse = await ReferenceFileAPI.GetTechnicalAssistTypes();
    if (activityTypesResponse?.successful && activityTypesResponse.data?.length > 0) {
      activityTypes = activityTypesResponse.data.map((t) => ({
        value: t.id,
        label: t.displayText ? t.displayText : t.name,
      }));
    }

    this.setState({ activityTypes });
  }

  submitModal = () => {
    if (!this.isFormDataValid()) {
      return;
    }

    let { data } = this.state;
    this.props.onSubmit({ ...data }, this.state.modalMode);
    this.clearData();
    this.props.onHide();
  };

  isFormDataValid() {
    const { activityName, selectedActivityTypes } = this.state.data;
    let isFormDataValid = true;

    const isFormDataCompleted = activityName && selectedActivityTypes?.length > 0;
    if (isFormDataCompleted) {
      if (selectedActivityTypes.length > 1 && selectedActivityTypes.some((type) => type.value === 4)) {
        isFormDataValid = false;
        toast.warning("Activity cannot be set to None in addition to Cooperator, Hotline, or Outreach.");
      }
    } else {
      toast.warning("Please enter valid values for Activity Name and Activity Type");
    }

    return isFormDataCompleted && isFormDataValid;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.activityName = "";
    data.createdDateTimeUTC = "";
    data.lastModifiedDateTimeUTC = "";
    data.selectedActivityTypes = [];

    this.setState({ data });
  }

  onActivityTypeChange = (value, { action, removedValue }) => {
    const { data } = this.state;
    if (action === "select-option") {
      data.selectedActivityTypes = [...value];
    } else if (action === "remove-value") {
      data.selectedActivityTypes = data.selectedActivityTypes.filter((t) => t !== removedValue);
    }

    this.setState({ data });
  };

  createActivitytModalUI() {
    return (
      <div className={styles.createDamageModalBody}>
        <Row>
          <Col>{this.renderInput("activityName", "Activity Name", "text", "Enter Activity Name", "required")}</Col>
        </Row>
        <Row>
          <Col>{this.renderActivitiesTypeDropdown()}</Col>
        </Row>
      </div>
    );
  }

  editActivityModalUI() {
    return (
      <div className={styles.editDamageModalBody}>
        <Row>
          <Col>{this.renderInput("activityName", "Activity Name", "text", "Enter Activity Name", "required")}</Col>
        </Row>
        <Row>
          <Col className={styles.editDamageModalStatus}>{this.renderActivitiesTypeDropdown()}</Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editDamageModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editDamageModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  renderActivitiesTypeDropdown() {
    return (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>
          Activity Types<span className={globalStyles.asterisk508}> *</span>
        </Form.Label>
        <Select
          aria-label="Activity Types"
          name="activityTypes"
          id="activityTypes"
          placeholder="Select Activity Types"
          isMulti
          isClearable={false}
          styles={{
            indicatorSeparator: () => {
              //do nothing
            },
            placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
            option: (styles, { isFocused }) => ({
              ...styles,
              fontSize: "14px",
              fontWeight: "normal",
              color: "black",
              backgroundColor: isFocused ? "lightgray" : "white",
            }),
            multiValue: (styles, { data }) => {
              return {
                ...styles,
                backgroundColor: "white",
                border: "solid 0.5px #D8DDE6",
              };
            },
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              color: "#0070D2",
            }),
            multiValueRemove: (styles, { data }) => ({
              ...styles,
              color: "#53698D",
              ":hover": {
                backgroundColor: "white",
              },
            }),
          }}
          value={this.state.data.selectedActivityTypes}
          onChange={this.onActivityTypeChange}
          options={this.state.activityTypes}
        />
      </Form.Group>
    );
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createActivitytModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editActivityModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} TA Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>{bodyContent}</Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button type="submit" className={globalStyles.ModalCreateButton} onClick={this.submitModal}>
              <span className={globalStyles.ModalCreateButtonText}>{submitButtonText}</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default CreateEditTAActivityModal;
