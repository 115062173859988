import React from "react";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import styles from "./Agreement.module.scss";
import { withRouter } from "react-router-dom";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step1Empty from "../../assets/Agreement-Step1-empty.svg";
import Step1Fill from "../../assets/Agreement-Step1-fill.svg";
import Step2Empty from "../../assets/Agreement-Step2-empty.svg";
import Step2Fill from "../../assets/Agreement-Step2-fill.svg";
import Step2Gray from "../../assets/Agreement-Step2-gray.svg";
import Step3Empty from "../../assets/Agreement-Step3-empty.svg";
import Step3Fill from "../../assets/Agreement-Step3-fill.svg";
import Step3Gray from "../../assets/Agreement-Step3-gray.svg";
import Step4Empty from "../../assets/Agreement-Step4-empty.svg";
import Step4Gray from "../../assets/Agreement-Step4-gray.svg";
import Step4Fill from "../../assets/Agreement-Step4-fill.svg";
import FileUploadModal from "../common/FileUploadModal";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import PrintAgreementModal from "../PDFHandling/PrintAgreementModal";
import UtilityFunctions from "../common/UtilityFunctions";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import LoadingOverlay from "react-loading-overlay";
import ConflictingAgreementParcelModal from "../AgreementEntities/Properties/ConflictingAgreementParcelModal";

class Agreement extends React.Component {
  constructor(props) {
    super(props);

    let agreementTypeEnumId = -1;
    if (this.props?.location?.state?.entityType === "FLA") {
      agreementTypeEnumId = 2;
    }

    const isExistingAgreement = this.props.history.location?.state?.entityData?.agreementUniqueIdentifier
      ? true
      : false;
    const currentUserRole = JSON.parse(localStorage.getItem("currentUserRole"));
    this.state = {
      validated: false,
      agreement: {},
      step: 1,
      highestStepVisited: 1,
      agreementNumber: "",
      commonName: "",
      agreementTypeEnumId: agreementTypeEnumId,
      specialInstructions: "",
      primaryCooperatorUniqueIdentifier: "",
      primaryCooperatorName: "",
      primaryCooperatorEmail: "",
      primaryCooperatorRoleEnumId: "",
      primaryCooperatorStreet: "",
      primaryCooperatorCity: "",
      primaryCooperatorZip: "",
      primaryCooperatorStateUniqueIdentifier: "",
      primaryCooperatorCountryUniqueIdentifier: "",
      primaryCooperatorPhoneNumbers: "",
      primaryFieldAgentUniqueIdentifier:
        !isExistingAgreement && currentUserRole ? currentUserRole.wsUserProfileUniqueIdentifier : "",
      primaryFieldAgentName: !isExistingAgreement && currentUserRole ? currentUserRole.userName : "",
      primaryFieldAgentEmail: "",
      primaryFieldAgentPhoneNumber: !isExistingAgreement && currentUserRole ? currentUserRole.phoneNumber : "",
      primaryFieldAgentMailingAddress: !isExistingAgreement && currentUserRole ? currentUserRole.mailingAddress : "",
      fieldAgentAdditionalContacts: [],
      showHappyModal: false,
      showSaveModal: false,
      agreementUniqueIdentifier: "",
      draftSaveSuccess: false,
      protectedResources: [],
      historicalResourcesInventory: [],
      species: [],
      methods: [],
      recommendations: [],
      cooperatorEmployedMethods: [],
      initialRecs: [],
      initialCems: [],
      contactTypes: [],
      phoneNumberTypes: [],
      usaCountryId: "",
      agreementTypes: [],
      agreementContactRoles: [],
      resourceUOMs: [],
      additionalContacts: [],
      initialMapComplete: false,
      agreementProperties: [],
      propertySpecialInstructions: [],
      show12AUploadModal: false,
      uploaded12ADocument: null,
      uploadedOtherDocuments: [],
      form12ASignatureDate: "",
      states: [],
      statesData: [],
      countries: [],
      currentUsaState: "",
      agreementObject: null,
      pageHeader: "",
      showPrintAgreementModal: false,
      agreementData: "",
      cooperatorData: "",
      primaryFieldAgentData: "",
      propertiesData: [],
      associatedContactsData: [],
      noProtectedResources: false,
      noHistoricalResources: false,
      noLosses: false,
      isExistingAgreement,
      isLoading: false,
      formLoadingMessage: "",
      isSignatureRequired: true,
      agreementProjects: [],
      isPropertyInActiveAgreement: false,
      conflictingAgreementUniqueIdentifiers: [],
      showConflictingAgreementParcelModal: false,
      dismissedConflictingAgreementParcelModal: false,
      isFLA: false,
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning(
        "Failed to load USA State info. Please go back to the home page and re-visit the Agreement Intake from there."
      );
    }
    let {
      contactTypes,
      phoneNumberTypes,
      usaCountryId,
      agreementTypes,
      agreementContactRoles,
      resourceUOMs,
      protectedResources,
      species,
      methods,
      historicalResourcesInventory,
      noProtectedResources,
      noHistoricalResources,
      noLosses,
      recommendations,
      cooperatorEmployedMethods,
      draftSaveSuccess,
      agreementUniqueIdentifier,
      agreementObject,
      additionalContacts,
      fieldAgentAdditionalContacts,
      agreementNumber,
      commonName,
      agreementTypeEnumId,
      agreementProperties,
      propertySpecialInstructions,
    } = this.state;

    if (this.props.history.location?.state?.entityData?.agreementUniqueIdentifier) {
      this.setState({ formLoadingMessage: "Loading Agreement Data...", isLoading: true });
      agreementUniqueIdentifier = this.props.history.location.state.entityData.agreementUniqueIdentifier;
      const getAgreementResponse = await AgreementAPI.GetAgreement(agreementUniqueIdentifier);
      if (getAgreementResponse?.successful) {
        draftSaveSuccess = true;
        agreementObject = getAgreementResponse;

        const agreementData = agreementObject.data;
        this.updateCooperatorData(agreementData);
        await this.updateProjectData(agreementData);
        additionalContacts = await this.getAdditionalContacts(agreementData);
        fieldAgentAdditionalContacts = await this.getAdditionalFieldAgents(agreementData);
        agreementNumber = agreementData.agreementNumber;
        commonName = agreementData.commonName;
        agreementTypeEnumId = agreementData.agreementType?.id ? agreementData.agreementType.id : "-1";
        agreementProperties = await this.getUpdatedPropertiesData(agreementData);
        const { protectedResourcesData, historicalResourcesData } = this.getResourcesData(agreementData);
        protectedResources = protectedResourcesData;
        historicalResourcesInventory = historicalResourcesData;
        noProtectedResources = !agreementData.isResourceRequired;
        noHistoricalResources = !agreementData.isHistoricalResourceRequired;
        noLosses = !agreementData.isHistoricalLossRequired;
        species = this.getSpecies(agreementData);
        methods = this.getMethods(agreementData);
        propertySpecialInstructions = this.getSpecialInstructions(agreementData);
        recommendations = await this.getRecommendations(agreementData);
        cooperatorEmployedMethods = await this.getCEMs(agreementData);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve in progress Agreement.",
          apiName: "GetAgreement",
          responseUnsuccessful: getAgreementResponse?.unsuccessful,
          responseMessage: getAgreementResponse?.message,
        });
      }
      this.setState({ isLoading: false });
    }

    agreementTypes = await this.getAgreementTypes(agreementTypes);
    agreementContactRoles = await this.getAgreementContactRoles(agreementContactRoles);
    contactTypes = await this.getContactTypes();
    phoneNumberTypes = await this.getPhoneNumberTypes();

    resourceUOMs = await this.getResourceUOMs();

    const countries = await this.getAllCountriesFromAPI();
    const usaObject = countries.find((country) => country.name === "United States of America");
    if (usaObject) usaCountryId = usaObject.id;

    const statesData = await this.getStatesFromAPI(usaObject.id);
    const states = statesData ? this.getStatesDataToSelect(statesData) : [];

    const primaryWSpecialist = this.state.primaryFieldAgentUniqueIdentifier
      ? JSON.parse(localStorage.getItem("currentUserRole"))
      : await this.getPrimaryWSpecialist(agreementObject);

    this.setState({
      contactTypes,
      phoneNumberTypes,
      states,
      statesData,
      countries,
      usaCountryId,
      agreementTypes,
      agreementContactRoles,
      resourceUOMs,
      protectedResources,
      species,
      methods,
      historicalResourcesInventory,
      noProtectedResources,
      noHistoricalResources,
      noLosses,
      currentUsaState,
      recommendations,
      initialRecs: recommendations,
      initialCems: cooperatorEmployedMethods,
      cooperatorEmployedMethods,
      draftSaveSuccess,
      agreementUniqueIdentifier,
      agreementObject,
      additionalContacts,
      primaryFieldAgentUniqueIdentifier: primaryWSpecialist.wsUserProfileUniqueIdentifier,
      primaryFieldAgentName: primaryWSpecialist.userName,
      primaryFieldAgentEmail: "",
      primaryFieldAgentPhoneNumber: primaryWSpecialist.phoneNumber,
      primaryFieldAgentMailingAddress: primaryWSpecialist.mailingAddress,
      fieldAgentAdditionalContacts,
      agreementNumber,
      commonName,
      agreementTypeEnumId,
      agreementProperties,
      propertySpecialInstructions,
      isSignatureRequired: !(this.props.history.location?.state?.signatureNotRequired === true),
    });
    window.scrollTo(0, 0);
  }

  async getAgreementTypes(currentAgreementTypes) {
    let agreementTypes = currentAgreementTypes;
    if (currentAgreementTypes?.length === 0) {
      let result = await AgreementAPI.GetAllAgreementTypes();
      if (result?.successful) {
        if (result.data) {
          agreementTypes = result.data;
          //remove FLA option
          agreementTypes.splice(1, 1);
          agreementTypes.sort((a, b) => (a.name < b.name ? -1 : 1));
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve Agreement Types",
          apiName: "GetAllAgreementTypes",
          responseUnsuccessful: result?.unsuccessful,
          responseMessage: result?.message,
        });
      }
    }
    return agreementTypes;
  }

  async getAgreementContactRoles(currentAgreementContactRoles) {
    let agreementContactRoles = currentAgreementContactRoles;
    if (agreementContactRoles?.length === 0) {
      let result = await AgreementAPI.GetAllAgreementContactRoles();
      if (result?.successful) {
        agreementContactRoles = result.data;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve Agreement Contact Roles",
          apiName: "GetAllAgreementContactRoles",
          responseUnsuccessful: result?.unsuccessful,
          responseMessage: result?.message,
        });
      }
    }
    return agreementContactRoles;
  }

  async getPrimaryWSpecialist(agreementObject) {
    let primaryWSpecialist = "";
    const profileId = agreementObject?.data?.primaryFieldAgent?.fieldAgentWsUserProfileUniqueReference;
    if (profileId) {
      const getFieldAgentResponse = await RoleAuthorizationAPI.GetUserWhoOwnsProfile(profileId);
      if (getFieldAgentResponse?.successful) {
        const { data: fieldAgentData } = getFieldAgentResponse;
        primaryWSpecialist = {
          wsUserProfileUniqueIdentifier: profileId,
          userName: fieldAgentData.firstName.concat(" ", fieldAgentData.lastName),
          phoneNumber: UtilityFunctions.formatPhoneNumber(fieldAgentData.primaryPhoneNumber),
          mailingAddress: {
            mailingStreet: fieldAgentData.streetAddress,
            mailingCity: fieldAgentData.city,
            mailingStateUniqueId: fieldAgentData.stateOfAddressUniqueReference,
            mailingZip: fieldAgentData.zip,
          },
        };
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve user's profile",
          apiName: "GetUserWhoOwnsProfile",
          responseUnsuccessful: getFieldAgentResponse?.unsuccessful,
          responseMessage: getFieldAgentResponse?.message,
        });
      }
    }

    return primaryWSpecialist;
  }

  async updateProjectData(agreementData) {
    let allProjects = await this.getProjectsDataFromAPI();

    if (allProjects) {
      const res = allProjects.filter((f) =>
        agreementData.projectUniqueReferences.some((item) => item.projectUniqueReference === f.value)
      );
      this.setState({ agreementProjects: res });
    }
  }

  async getProjectsDataFromAPI() {
    let projectsData = [];
    const projectsDataResponse = await AgreementEntitiesAPI.GetMyAssociatedProjectsForState(
      JSON.parse(localStorage.getItem("currentState")).stateGuid
    );
    if (projectsDataResponse?.successful) {
      projectsData = projectsDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve user's associated projects",
        apiName: "GetMyAssociatedProjectsForState",
        responseUnsuccessful: projectsDataResponse?.unsuccessful,
        responseMessage: projectsDataResponse?.message,
      });
    }
    let projectsArray = [];
    projectsData.forEach((project) => {
      let startDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.startDate);
      let endDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.endDate);
      let currentDate = new Date();
      if (currentDate >= startDate && currentDate < endDate) {
        projectsArray.push({ value: project.projectUniqueIdentifier, label: project.projectName });
      }
    });
    return projectsArray;
  }

  updateCooperatorData(agreementData) {
    const cooperatorData = agreementData.cooperatorName;
    if (cooperatorData) {
      this.setState({
        primaryCooperatorUniqueIdentifier: cooperatorData.contactUniqueIdentifier,
        primaryCooperatorName: cooperatorData.firstName.concat(
          " ",
          cooperatorData.middleName,
          " ",
          cooperatorData.lastName
        ),
        primaryCooperatorphoneNumbers: cooperatorData.phoneNumbers?.map((phoneNumber) => {
          phoneNumber.phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber);
          return phoneNumber;
        }),
        primaryCooperatorStateUniqueIdentifier: cooperatorData.primaryStateUniqueReference,
        primaryCooperatorCountryUniqueIdentifier: cooperatorData.primaryCountryUniqueReference,
        primaryCooperatorStreet: cooperatorData.primaryStreet,
        primaryCooperatorCity: cooperatorData.primaryCity,
        primaryCooperatorZip: cooperatorData.primaryZip,
        primaryCooperatorRoleEnumId: agreementData.cooperator?.cooperatorRole?.id,
      });
    }
  }

  async getAdditionalContacts(agreementData) {
    let additionalContacts = [];

    let agreementContacts = [];
    for (let property of agreementData?.agreementProperties) {
      property.additionalContacts.forEach((contact) => {
        const existingAgreementContact = agreementContacts.find(
          (c) => c.contactUniqueReference === contact.contactUniqueReference
        );
        if (!existingAgreementContact) {
          agreementContacts.push({ ...contact, checkedProperties: [property] });
        } else if (
          !existingAgreementContact.checkedProperties?.some(
            (p) => p.propertyUniqueReference === property.propertyUniqueReference
          )
        ) {
          existingAgreementContact.checkedProperties = [...existingAgreementContact.checkedProperties, property];
        }
      });
    }

    if (agreementContacts.length > 0) {
      await Promise.all(
        agreementContacts.map(async (contact) => {
          if (contact.contactUniqueReference) {
            const getContactResponse = await AgreementEntitiesAPI.GetContact(contact.contactUniqueReference);
            if (getContactResponse?.successful) {
              const { data: contactData } = getContactResponse;
              additionalContacts.push({
                contactGuid: contactData.contactUniqueId,
                contactName: contactData.firstName.concat(" ", contactData.middleName, " ", contactData.lastName),
                contactRoleEnumId: contact.contactRoleOnAgreement?.id,
                contactRoleName: UtilityFunctions.getDisplayTextFromFieldObject(contact.contactRoleOnAgreement),
                emailAddress: contactData.emailAddress,
                firstName: contactData.firstName,
                lastName: contactData.lastName,
                middleName: contactData.middleName,
                phoneNumbers: contactData.phoneNumbers?.map((p) => {
                  p.phoneNumber = UtilityFunctions.formatPhoneNumber(p.phoneNumber);
                  return p;
                }),
                primaryMailingAddress: contactData.primaryMailingAddress,
                ...contact,
              });
            }
          }
        })
      );
    }

    return additionalContacts;
  }

  async getAdditionalFieldAgents(agreementData) {
    let additionalFieldAgents = [];
    if (agreementData?.additionalFieldAgents?.length > 0) {
      await Promise.all(
        agreementData.additionalFieldAgents.map(async (fieldAgent) => {
          if (fieldAgent.fieldAgentWsUserProfileUniqueReference) {
            const getFieldAgentResponse = await RoleAuthorizationAPI.GetUserWhoOwnsProfile(
              fieldAgent.fieldAgentWsUserProfileUniqueReference
            );
            if (getFieldAgentResponse?.successful) {
              const { data: fieldAgentData } = getFieldAgentResponse;
              additionalFieldAgents.push({
                contactGuid: fieldAgentData.wsUserUniqueIdentifier,
                contactName: fieldAgentData.firstName.concat(" ", fieldAgentData.lastName),
                contactUniqueIdentifier: fieldAgent.fieldAgentWsUserProfileUniqueReference,
                emailAddress: fieldAgentData.userPrinciple,
                firstName: fieldAgentData.firstName,
                lastName: fieldAgentData.lastName,
                phoneNumber: UtilityFunctions.formatPhoneNumber(fieldAgentData.primaryPhoneNumber),
                primaryMailingAddress: {
                  mailingStreet: fieldAgentData.streetAddress,
                  mailingCity: fieldAgentData.city,
                  mailingStateUniqueId: fieldAgentData.stateOfAddressUniqueReference,
                  mailingZip: fieldAgentData.zip,
                },
              });
            }
          }
        })
      );
    }

    return additionalFieldAgents;
  }

  async getUpdatedPropertiesData(agreementData) {
    const agreementProperties = JSON.parse(JSON.stringify(agreementData.agreementProperties));
    agreementProperties.forEach((property) => {
      property.propertyUniqueIdentifier = property.propertyUniqueReference;
    });
    if (agreementProperties?.length > 0 && agreementProperties.some((p) => p.landOwnersContact)) {
      await Promise.all(
        agreementProperties.map(async (property) => {
          if (property.landOwnersContact) {
            const getLandOwnerContactResponse = await AgreementEntitiesAPI.GetContact(property.landOwnersContact);
            if (getLandOwnerContactResponse?.successful) {
              const { data: contactData } = getLandOwnerContactResponse;
              property.landOwnersContactName = contactData.firstName.concat(
                " ",
                contactData.middleName,
                " ",
                contactData.lastName
              );
            }
          }
        })
      );
    }

    return agreementProperties;
  }

  getPageHeader(step) {
    let pageHeader = "Create an Agreement: ";

    if (this.state.agreementTypeEnumId === 2) {
      pageHeader = "Create a Federal Land Agreement: ";
    }
    switch (step) {
      case 1:
        pageHeader += "Initial Set up";
        break;
      case 2:
        pageHeader += "Property Identification";
        break;
      case 3:
        pageHeader += "Property Information";
        break;
      case 4: {
        pageHeader += "Review and Finalize";
        break;
      }
      default:
        break;
    }
    return pageHeader;
  }

  getResourcesData(agreementData) {
    let protectedResourcesData = [];
    let historicalResourcesData = [];

    for (let property of agreementData.agreementProperties) {
      if (property?.agreementResources?.length > 0) {
        property.agreementResources.forEach((resource) => {
          if (resource.isHistorical) {
            const newTableId = `${resource.resourceUniqueReference}|${property.propertyUniqueReference}|${resource.valuePerUnitOfMeasure}|${resource.valuationYear}`;
            if (!historicalResourcesData.find((r) => r.resourceTableId === newTableId)) {
              historicalResourcesData.push({
                resourceUniqueIdentifier: resource.resourceUniqueReference,
                resourceName: resource.resourceName,
                resourceTableId: newTableId,
                quantity: resource.quantity,
                unitOfMeasureId: resource.valuationUnitOfMeasure?.id,
                unitOfMeasureName: UtilityFunctions.getDisplayTextFromFieldObject(resource.valuationUnitOfMeasure),
                unitValue: resource.valuePerUnitOfMeasure,
                totalValue: resource.totalValuation,
                year: resource.valuationYear,
                checkedProperties: [property],
                historicalLosses: resource.historicalLossRecords?.map((loss) => ({
                  lossTableId: `${resource.resourceUniqueReference}|${loss.causingSpeciesUniqueReference}`,
                  percentage: loss.lossPercentage,
                  quantity: loss.lossQuantity,
                  resourceName: resource.resourceName,
                  species: {
                    label: loss.causingSpeciesName,
                    value: loss.causingSpeciesUniqueReference,
                  },
                  totalValue: loss.totalValuation,
                  unitOfMeasureName: UtilityFunctions.getDisplayTextFromFieldObject(resource.valuationUnitOfMeasure),
                  historicalLossUniqueIdentifier: loss.historicalLossUniqueIdentifier,
                })),
              });
            }
          } else {
            const newTableId = `${resource.resourceUniqueReference}|${property.propertyUniqueReference}|${resource.valuePerUnitOfMeasure}`;
            if (!protectedResourcesData.find((r) => r.resourceTableId === newTableId)) {
              protectedResourcesData.push({
                resourceUniqueIdentifier: resource.resourceUniqueReference,
                resourceName: resource.resourceName,
                resourceTableId: newTableId,
                quantity: resource.quantity,
                unitOfMeasureId: resource.valuationUnitOfMeasure?.id,
                unitOfMeasureName: UtilityFunctions.getDisplayTextFromFieldObject(resource.valuationUnitOfMeasure),
                unitValue: resource.valuePerUnitOfMeasure,
                totalValue: resource.totalValuation,
                year: resource.valuationYear,
                checkedProperties: [property],
              });
            }
          }
        });
      }
    }

    return { protectedResourcesData, historicalResourcesData };
  }

  getSpecies(agreementData) {
    let speciesData = [];

    for (let property of agreementData.agreementProperties) {
      if (property?.targetedSpecies?.length > 0) {
        property.targetedSpecies.forEach((species) => {
          const existingSpecies = speciesData.find((s) => s.speciesUniqueIdentifier === species.speciesUniqueReference);
          if (!existingSpecies) {
            speciesData.push({
              name: species.speciesName,
              speciesUniqueIdentifier: species.speciesUniqueReference,
              checkedProperties: [property],
            });
          } else if (
            !existingSpecies.checkedProperties.find(
              (p) => p.propertyUniqueReference === property.propertyUniqueReference
            )
          ) {
            existingSpecies.checkedProperties = [...existingSpecies.checkedProperties, property];
          }
        });
      }
    }

    return speciesData;
  }

  getMethods(agreementData) {
    let methodsData = [];

    for (let property of agreementData.agreementProperties) {
      if (property?.allowedMethods?.length > 0) {
        property.allowedMethods.forEach((method) => {
          const existingMethod = methodsData.find((m) => m.methodUniqueIdentifier === method.methodUniqueReference);
          if (!existingMethod) {
            methodsData.push({
              methodName: method.methodName,
              methodUniqueIdentifier: method.methodUniqueReference,
              checkedProperties: [property],
            });
          } else if (
            !existingMethod.checkedProperties.find(
              (p) => p.propertyUniqueReference === property.propertyUniqueReference
            )
          ) {
            existingMethod.checkedProperties = [...existingMethod.checkedProperties, property];
          }
        });
      }
    }

    return methodsData;
  }

  getSpecialInstructions(agreementData) {
    let specialInstructionsData = [];

    for (let property of agreementData.agreementProperties) {
      if (property?.specialInstructions?.length > 0) {
        property.specialInstructions.forEach((instruction) => {
          const existingInstruction = specialInstructionsData.find((i) => i.text === instruction.specialInstruction);
          if (!existingInstruction) {
            specialInstructionsData.push({
              text: instruction.specialInstruction,
              combinedPropertyName: property.propertyName,
              checkedProperties: [property],
            });
          } else if (
            !existingInstruction.checkedProperties.find(
              (p) => p.propertyUniqueReference === property.propertyUniqueReference
            )
          ) {
            existingInstruction.combinedPropertyName = existingInstruction.combinedPropertyName.concat(
              ", ",
              property.propertyName
            );
            existingInstruction.checkedProperties = [...existingInstruction.checkedProperties, property];
          }
        });
      }
    }

    return specialInstructionsData;
  }

  async getRecommendations(agreementData) {
    let recommendationsData = [];

    for (let property of agreementData.agreementProperties) {
      if (property?.recommendations?.length > 0) {
        property.recommendations.forEach((rec) => {
          const existingRecs = recommendationsData.find((r) => r.recCemId === rec.recCemUniqueReference);
          if (!existingRecs) {
            recommendationsData.push({
              recCemName: rec.recCemName,
              recCemId: rec.recCemUniqueReference,
              checkedProperties: [property],
            });
          } else if (
            !existingRecs.checkedProperties.find((p) => p.propertyUniqueReference === property.propertyUniqueReference)
          ) {
            existingRecs.checkedProperties = [...existingRecs.checkedProperties, property];
          }
        });
      }
    }

    return recommendationsData;
  }

  async getCEMs(agreementData) {
    let cemsData = [];

    for (let property of agreementData.agreementProperties) {
      if (property?.cems?.length > 0) {
        property.cems.forEach((cem) => {
          const existingRecs = cemsData.find((r) => r.recCemId === cem.recCemUniqueReference);
          if (!existingRecs) {
            cemsData.push({
              recCemName: cem.recCemName,
              recCemId: cem.recCemUniqueReference,
              checkedProperties: [property],
            });
          } else if (
            !existingRecs.checkedProperties.find((p) => p.propertyUniqueReference === property.propertyUniqueReference)
          ) {
            existingRecs.checkedProperties = [...existingRecs.checkedProperties, property];
          }
        });
      }
    }

    return cemsData;
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  async getStatesFromAPI(countryId) {
    const results = await ReferenceFileAPI.GetStatesByCountry(countryId);
    return results?.length > 0 ? results : [];
  }

  getStatesDataToSelect(statesData) {
    return statesData
      .map((state) => ({
        id: state.stateUniqueIdentifier,
        name: state.state_code,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  async getResourceUOMs() {
    let result = [];
    let resourceUomData = await ReferenceFileAPI.GetAllResourceUOMs();
    if (resourceUomData?.successful) {
      result = resourceUomData.data?.map((resource) => ({ value: resource.id, label: resource.name })) ?? [];
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve resource units of measure",
        apiName: "GetAllResourceUOMs",
        responseUnsuccessful: resourceUomData?.unsuccessful,
        responseMessage: resourceUomData?.message,
      });
    }
    return result;
  }

  async getContactTypes() {
    let result = [];
    const contactTypesData = await AgreementEntitiesAPI.GetContactTypes();
    if (contactTypesData?.successful) {
      result = contactTypesData.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve contact types",
        apiName: "GetContactTypes",
        responseUnsuccessful: contactTypesData?.unsuccessful,
        responseMessage: contactTypesData?.message,
      });
    }
    return result;
  }

  async getPhoneNumberTypes() {
    let result = [];
    const phoneTypesData = await AgreementEntitiesAPI.GetPhoneNumberTypes();
    if (phoneTypesData?.successful) {
      result = phoneTypesData.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve phone number types",
        apiName: "GetPhoneNumberTypes",
        responseUnsuccessful: phoneTypesData?.unsuccessful,
        responseMessage: phoneTypesData?.message,
      });
    }
    return result;
  }

  setStateValue = (fieldName, value) => {
    let data = this.state;
    data[fieldName] = value;
    this.setState({ data });
  };

  handleSubmitWrapper = (e) => {
    e.preventDefault();
    this.handleSubmit(e);
  };

  async handleSubmit(e) {
    if (e.nativeEvent?.submitter?.title === "Search in") {
      e.stopPropagation();
      return;
    }
    if (!this.isAgreementValid(e)) {
      toast.warning("Please fill out all required fields.");
      return;
    }
    //check  if there is an Active Agrrement exists with this property
    let agreementProperties = [];
    if (this.state.isExistingAgreement) {
      agreementProperties = this.state.agreementObject?.data?.agreementProperties;
    } else {
      agreementProperties = this.state.agreementProperties;
    }

    await this.getActiveAgreementByProperty(agreementProperties);
    if (this.state.isPropertyInActiveAgreement) {
      ErrorHandler.showError(
        "Agreement draft can be created or updated but cannot be signed, Existing Agreement must be expired before submitting for Approval"
      );
      this.setState({ isSignatureRequired: false });
    }

    const parcelAgreementCheck = await this.canTheseParcelsMakeUpANewAgreement();

    if (!parcelAgreementCheck && this.state.dismissedConflictingAgreementParcelModal) {
      this.setState({ isSignatureRequired: false });
    }

    if (!parcelAgreementCheck && !this.state.dismissedConflictingAgreementParcelModal) {
      this.setState({ showConflictingAgreementParcelModal: true });
    } else if (this.shouldShow12AUploadModal()) {
      this.setState({ show12AUploadModal: true });
    } else {
      await this.proceedWithAgreementSubmit();
    }
  }

  shouldShow12AUploadModal() {
    return this.state.step === 4 && this.state.isSignatureRequired;
  }

  async canTheseParcelsMakeUpANewAgreement() {
    let result = true;
    let esriParcelIds = [];
    this.state.agreementProperties.forEach((x) => x.parcels.forEach((y) => esriParcelIds.push(y.parcelId)));

    if (esriParcelIds.length > 0) {
      const parcelAgreementCheckAction = await AgreementAPI.CanTheseParcelsMakeUpANewAgreement(esriParcelIds);
      if (parcelAgreementCheckAction?.successful) {
        result = parcelAgreementCheckAction.data.canTheseParcelsMakeANewAgreement;
        this.setState({
          conflictingAgreementUniqueIdentifiers: parcelAgreementCheckAction.data.conflictingAgreementIdentifiers,
        });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to check parcels",
          apiName: "CanTheseParcelsMakeUpANewAgreement",
          responseUnsuccessful: parcelAgreementCheckAction?.unsuccessful,
          responseMessage: parcelAgreementCheckAction?.message,
        });
      }
    }

    return result;
  }

  isAgreementValid(e) {
    let isAgreementValid = false;
    if (e.currentTarget.checkValidity() && this.isAgreementDataValid()) {
      isAgreementValid = true;
    }

    return isAgreementValid;
  }

  isAgreementDataValid() {
    const {
      commonName,
      primaryFieldAgentUniqueIdentifier,
      primaryFieldAgentName,
      primaryCooperatorUniqueIdentifier,
      primaryCooperatorName,
      agreementTypeEnumId,
    } = this.state;

    const isFieldAgentValid = primaryFieldAgentUniqueIdentifier && primaryFieldAgentName;
    const isCooperatorValid = primaryCooperatorUniqueIdentifier && primaryCooperatorName;
    const isValidAgreementType = agreementTypeEnumId && parseInt(agreementTypeEnumId) !== -1;
    const isCommonInfoValid = commonName && isValidAgreementType && isFieldAgentValid;

    return parseInt(agreementTypeEnumId) !== 2 ? isCommonInfoValid && isCooperatorValid : isCommonInfoValid;
  }

  handleFileSelection = (file) => {
    if (file) {
      this.setState({ uploaded12ADocument: file });
    }
  };

  handleMultipleFilesSelection = (files) => {
    let filesToAttach = [...this.state.uploadedOtherDocuments, ...Array.from(files)];
    if (files) {
      this.setState({ uploadedOtherDocuments: filesToAttach });
    }
  };

  removeFileFromOtherAttachments = (index) => {
    let { uploadedOtherDocuments } = this.state;
    uploadedOtherDocuments.splice(index, 1);
    this.setState({ uploadedOtherDocuments });
  };

  proceedWithAgreementSubmit = async () => {
    let createAgreementResult;
    this.setState({ formLoadingMessage: "Saving Agreement...", isLoading: true });

    if (this.state.isExistingAgreement) {
      createAgreementResult = JSON.parse(JSON.stringify(this.state.agreementObject));
    } else {
      let agreementRecord = {
        agreementStateUniqueReference: this.state.currentUsaState ? this.state.currentUsaState.stateGuid : null,
        agreementTypeEnumId: parseInt(this.state.agreementTypeEnumId),
        commonName: this.state.commonName,
        agreementNumberSuffix: this.state.agreementNumber !== "" ? this.state.agreementNumber : null,
        primaryFieldAgentWsUserProfileUniqueReference: this.state.primaryFieldAgentUniqueIdentifier,
        additionalFieldAgents: this.constructAdditionalFieldAgentsOject(this.state.fieldAgentAdditionalContacts),
        cooperatorContactUniqueReference:
          this.state.primaryCooperatorUniqueIdentifier !== "" ? this.state.primaryCooperatorUniqueIdentifier : null,
        enforceDetailedWorkPerformedEnumId: 2,
        projectUniqueReferences: this.state.agreementProjects?.map((p) => p.value),
        cooperatorsRoleOnAgreementEnumId:
          parseInt(this.state.agreementTypeEnumId) === 2 ? null : this.state.primaryCooperatorRoleEnumId,
      };
      createAgreementResult = await AgreementAPI.CreateAgreement(agreementRecord);
    }

    if (createAgreementResult?.successful) {
      this.setState({
        agreementUniqueIdentifier: createAgreementResult.data.agreementUniqueIdentifier,
        draftSaveSuccess: true,
      });
      let agreementObject = createAgreementResult;

      const { success: updateGeneralInfoCall, updatedAgreementObject } = await this.updateAgreementDetails(
        agreementObject
      );
      agreementObject = updatedAgreementObject;

      const { success: addPropertiesCall, propertiesToAgreementResponse } = await this.addPropertiesToAgreement(
        agreementObject
      );
      if (propertiesToAgreementResponse?.successful) {
        agreementObject = propertiesToAgreementResponse;
      }
      const { success: removePropertiesCall, removePropertiesResponse } = await this.removePropertiesFromAgreement(
        agreementObject
      );
      if (removePropertiesResponse?.successful) {
        agreementObject = removePropertiesResponse;
      }

      const { success: specialInstructionsCall, specialInstructionsToAgreementResponse } =
        await this.addSpecialInstructionsToAgreement();
      const { success: specialInstructionsToPropertiesCall, specialInstructionsToAgreementPropResponse } =
        await this.addSpecialInstructionsToAgreementProperties(agreementObject);
      const { success: removeSpecialInstructionsFromPropCall, removeSpecialInstructionsFromPropResponse } =
        await this.removeSpecialInstructionsFromAgreementProperties(agreementObject);
      const { success: recCemsToPropertiesCall, recCemsToAgreementPropResponse } =
        await this.addRecCemsToAgreementProperties();
      let recCemsRemoveAgreementPropCall = await this.removeRecCemsFromAgreementProperties();

      const { success: methodsToAgreementCall, methodsToAgreementResponse } = await this.addMethodsToAgreement(
        agreementObject
      );
      const { success: removeMethodsCall, removeMethodsResponse } = await this.removeMethodsFromAgreement(
        agreementObject
      );
      const { success: speciesToAgreementCall, speciesToAgreementResponse } = await this.addSpeciesToAgreement(
        agreementObject
      );
      const { success: removeSpeciesCall, removeSpeciesResponse } = await this.removeSpeciesFromAgreement(
        agreementObject
      );

      let creationSuccessful = true;
      if (this.state.noProtectedResources && agreementObject.data?.isResourceRequired) {
        const setResourcesNotRequiredResponse = await this.setProtectedResourcesNotRequiredForAgreement();
        creationSuccessful = creationSuccessful && setResourcesNotRequiredResponse?.successful;
        if (setResourcesNotRequiredResponse?.successful) {
          agreementObject = setResourcesNotRequiredResponse;
        }
      } else if (
        !agreementObject.data?.isResourceRequired &&
        (!this.state.noProtectedResources || this.state.protectedResources?.length > 0)
      ) {
        const setResourcesRequiredResponse = await this.setProtectedResourcesRequiredForAgreement(agreementObject);
        creationSuccessful = creationSuccessful && setResourcesRequiredResponse?.successful;
        if (setResourcesRequiredResponse?.successful) {
          agreementObject = setResourcesRequiredResponse;
        }
      }

      if (this.state.noHistoricalResources && agreementObject.data?.isHistoricalResourceRequired) {
        const setHistoricalResourcesNotRequiredResponse = await this.setHistoricalResourcesNotRequiredForAgreement();
        creationSuccessful = creationSuccessful && setHistoricalResourcesNotRequiredResponse?.successful;
        if (setHistoricalResourcesNotRequiredResponse?.successful) {
          agreementObject = setHistoricalResourcesNotRequiredResponse;
        }
      } else if (
        !agreementObject.data?.isHistoricalResourceRequired &&
        (!this.state.noHistoricalResources || this.state.historicalResourcesInventory?.length > 0)
      ) {
        const setHistoricalResourcesRequiredResponse = await this.setHistoricalResourcesRequiredForAgreement();
        creationSuccessful = creationSuccessful && setHistoricalResourcesRequiredResponse?.successful;
        if (setHistoricalResourcesRequiredResponse?.successful) {
          agreementObject = setHistoricalResourcesRequiredResponse;
        }
      }

      const { success: resourcesToAgreementCall, resourcesToAgreementResponse } = await this.addResourcesToAgreement(
        agreementObject
      );
      if (resourcesToAgreementResponse?.successful) {
        agreementObject = resourcesToAgreementResponse;
      }
      const { success: removeResourcesFromAgreementCall, removeResourcesResponse } =
        await this.removeResourcesFromAgreement(agreementObject);
      if (removeResourcesResponse?.successful) {
        agreementObject = removeResourcesResponse;
      }

      if (this.state.noLosses && agreementObject.data?.isHistoricalLossRequired) {
        creationSuccessful = creationSuccessful && (await this.setHistoricalLossesNotRequiredForAgreement());
      } else if (
        !agreementObject.data?.isHistoricalLossRequired &&
        (!this.state.noLosses || this.state.historicalResourcesInventory?.some((r) => r.historicalLosses?.length > 0))
      ) {
        creationSuccessful = creationSuccessful && (await this.setHistoricalLossesRequiredForAgreement());
      }

      const { success: historicalLossesToAgreementCall, historicalLossesToAgreementResponse } =
        await this.addHistoricalLossesToAgreement(agreementObject);
      const { success: removeHistoricalLossesCall, removeHistoricalLossesResponse } =
        await this.removeHistoricalLossesFromAgreement(agreementObject);

      // //Non-FLA Agreement
      if (this.state.agreementTypeEnumId !== 2) {
        const { success: addCooperatorRoleCall, addCooperatorRoleToAgreementResponse } =
          await this.addCooperatorRoleToAgreementProperties();
        if (addCooperatorRoleToAgreementResponse?.successful) {
          agreementObject = addCooperatorRoleToAgreementResponse;
        }

        const { success: contactsToPropertiesCall, contactsToAgreementResponse } = await this.addContactsToAgreement(
          agreementObject
        );
        const { success: removeContactsCall, removeContactsResponse } = await this.removeContactsFromAgreement(
          agreementObject
        );

        creationSuccessful =
          creationSuccessful &&
          updateGeneralInfoCall &&
          removePropertiesCall &&
          addPropertiesCall &&
          specialInstructionsCall &&
          contactsToPropertiesCall &&
          removeContactsCall &&
          specialInstructionsToPropertiesCall &&
          removeSpecialInstructionsFromPropCall &&
          methodsToAgreementCall &&
          removeMethodsCall &&
          speciesToAgreementCall &&
          removeSpeciesCall &&
          resourcesToAgreementCall &&
          removeResourcesFromAgreementCall &&
          historicalLossesToAgreementCall &&
          removeHistoricalLossesCall &&
          recCemsToPropertiesCall &&
          recCemsRemoveAgreementPropCall &&
          addCooperatorRoleCall;

        if (creationSuccessful) {
          const agreementCreationResponses = [
            updatedAgreementObject,
            removePropertiesResponse,
            propertiesToAgreementResponse,
            addCooperatorRoleToAgreementResponse,
            specialInstructionsToAgreementResponse,
            contactsToAgreementResponse,
            removeContactsResponse,
            specialInstructionsToAgreementPropResponse,
            removeSpecialInstructionsFromPropResponse,
            recCemsToAgreementPropResponse,
            methodsToAgreementResponse,
            removeMethodsResponse,
            speciesToAgreementResponse,
            removeSpeciesResponse,
            resourcesToAgreementResponse,
            removeResourcesResponse,
            historicalLossesToAgreementResponse,
            removeHistoricalLossesResponse,
          ];
          agreementObject = this.updateAgreementObject(agreementObject, agreementCreationResponses);

          if (this.state.step === 4 && this.state.isSignatureRequired) {
            let parentAgreementExpirationDate;
            if (agreementObject?.data?.parentAgreement) {
              parentAgreementExpirationDate = await this.getParentAgreementExpirationDate(
                agreementObject.data.parentAgreement
              );
              await this.expireParentAgreement(agreementObject);
            }

            const { success: signatureToAgreementCall, agreementSignatureResponse } = await this.signAgreement12A();
            if (signatureToAgreementCall) {
              agreementObject = agreementSignatureResponse;
              if (this.state.uploadedOtherDocuments.length > 0) {
                await this.uploadOtherAgreementAttachments(agreementObject?.data?.agreementUniqueIdentifier);
              }
              const sendAgreementForApprovalCall = await AgreementAPI.SendAgreementForApproval(
                agreementObject?.data?.agreementUniqueIdentifier
              );

              if (sendAgreementForApprovalCall?.successful) {
                agreementObject = sendAgreementForApprovalCall;
                toast.success("Agreement signed and submitted.");
              } else {
                ErrorHandler.handleApiErrorMessage({
                  errorContextMessage: "Agreement signed but unable to send for approval automatically",
                  apiName: "GetMyAssociatedProjectsForState",
                  responseUnsuccessful: sendAgreementForApprovalCall?.unsuccessful,
                  responseMessage: sendAgreementForApprovalCall?.message,
                });

                if (parentAgreementExpirationDate) {
                  await this.setAgreementExpirationDate(
                    agreementObject.data.parentAgreement,
                    parentAgreementExpirationDate
                  );
                }
              }
            } else {
              ErrorHandler.showErrorWithDetails(
                agreementSignatureResponse,
                agreementSignatureResponse?.message
                  ? agreementSignatureResponse.message
                  : "Agreement signature was not completed, please contact your system admin."
              );

              if (parentAgreementExpirationDate) {
                await this.setAgreementExpirationDate(
                  agreementObject.data.parentAgreement,
                  parentAgreementExpirationDate
                );
              }
            }
          }
          this.setState({ isLoading: false, agreementObject, showHappyModal: true, show12AUploadModal: false });
        } else {
          ErrorHandler.showErrorWithMessage(
            `Agreement ${this.state.isExistingAgreement ? "update" : "creation"} failed.`
          );
        }
      } else {
        //FLA
        const creationSuccessful =
          updateGeneralInfoCall &&
          removePropertiesCall &&
          addPropertiesCall &&
          specialInstructionsCall &&
          specialInstructionsToPropertiesCall &&
          removeSpecialInstructionsFromPropCall &&
          methodsToAgreementCall &&
          removeMethodsCall &&
          speciesToAgreementCall &&
          removeSpeciesCall &&
          resourcesToAgreementCall &&
          removeResourcesFromAgreementCall &&
          historicalLossesToAgreementCall &&
          removeHistoricalLossesCall;

        if (creationSuccessful) {
          const agreementCreationResponses = [
            updatedAgreementObject,
            removePropertiesResponse,
            propertiesToAgreementResponse,
            specialInstructionsToAgreementResponse,
            specialInstructionsToAgreementPropResponse,
            removeSpecialInstructionsFromPropResponse,
            methodsToAgreementResponse,
            removeMethodsResponse,
            speciesToAgreementResponse,
            removeSpeciesResponse,
          ];
          agreementObject = this.updateAgreementObject(agreementObject, agreementCreationResponses);
          if (this.state.uploadedOtherDocuments.length > 0) {
            let uploadSuccess = await this.uploadOtherAgreementAttachments(
              agreementObject?.data?.agreementUniqueIdentifier
            );
            if (uploadSuccess) {
              toast.success("Files uploaded");
            }
          }
          this.setState({ isLoading: false, agreementObject, showHappyModal: true, show12AUploadModal: false });
        } else {
          ErrorHandler.showError(`Agreement ${this.state.isExistingAgreement ? "update" : "creation"} failed.`, 5000);
        }
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create agreement",
        apiName: "CreateAgreement",
        responseUnsuccessful: createAgreementResult?.unsuccessful,
        responseMessage: createAgreementResult?.message,
      });
    }
    this.setState({ isLoading: false });
  };

  async expireParentAgreement(agreementObject) {
    await this.setAgreementExpirationDate(agreementObject.data.parentAgreement, new Date().toISOString());
  }

  async setAgreementExpirationDate(agreementUniqueId, expirationDate) {
    const setExpirationDateResponse = await AgreementAPI.SetAgreementExpirationDate(agreementUniqueId, expirationDate);
    if (!setExpirationDateResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set agreement expiration date for parent agreement",
        apiName: "SetAgreementExpirationDate",
        responseUnsuccessful: setExpirationDateResponse?.unsuccessful,
        responseMessage: setExpirationDateResponse?.message,
      });
    }
  }

  async getParentAgreementExpirationDate(parentAgreementUniqueId) {
    let parentAgreementExpirationDate = "";
    const getParentAgreementResponse = await AgreementAPI.GetAgreement(parentAgreementUniqueId);
    if (!getParentAgreementResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Parent Agreement Info could not be retrieved",
        apiName: "GetAgreement",
        responseUnsuccessful: getParentAgreementResponse?.unsuccessful,
        responseMessage: getParentAgreementResponse?.message,
      });
    } else if (getParentAgreementResponse.data?.expirationDate) {
      parentAgreementExpirationDate = getParentAgreementResponse.data.expirationDate;
    }

    return parentAgreementExpirationDate;
  }

  updateAgreementObject(agreementObject, agreementCreationResponses) {
    agreementCreationResponses.forEach((response) => {
      if (response?.successful) {
        agreementObject = response;
      }
    });
    return agreementObject;
  }

  async addSpecialInstructionsToAgreement() {
    let success = false;
    let specialInstructionsToAgreementResponse = "";

    if (this.state.specialInstructions) {
      let result = await AgreementAPI.AddSpecialInstructionsToAgreement(this.state.agreementUniqueIdentifier, [
        { specialInstruction: this.state.specialInstructions },
      ]);
      if (result?.successful) {
        success = true;
        specialInstructionsToAgreementResponse = result;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding Special Instructions to the agreement",
          apiName: "AddSpecialInstructionsToAgreement",
          responseUnsuccessful: result?.unsuccessful,
          responseMessage: result?.message,
        });
      }
    } else {
      success = true;
    }

    return { success, specialInstructionsToAgreementResponse };
  }

  async addContactsToAgreement(agreementObject) {
    const { additionalContacts, agreementUniqueIdentifier } = this.state;
    let success = true;
    let contactsToPropertiesArray = [];
    let contactsToAgreementArray = [];
    let contactsToAgreementResponse = "";

    additionalContacts
      .filter((c) => c.isNewContact)
      .forEach((contact) => {
        if (contact.checkedProperties?.length > 0) {
          if (contact.contactInheritedFrom === "Agreement") {
            if (this.isNewContactToAdd(contact, agreementObject))
              contactsToAgreementArray.push(this.getContactsToAgreementObject(contact));
          } else {
            let addToProperties = this.getPropertiesToAddToContact(contact, agreementObject);
            if (addToProperties?.length > 0) {
              contactsToPropertiesArray.push(this.getContactsToAgreementPropertiesObject(contact, addToProperties));
            }
          }
        }
      });

    if (contactsToAgreementArray.length > 0) {
      let result = await AgreementAPI.AddContactsToAgreement(agreementUniqueIdentifier, contactsToAgreementArray);
      if (result?.successful) {
        contactsToAgreementResponse = result;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding Contacts to the agreement",
          apiName: "AddContactsToAgreement",
          responseUnsuccessful: result?.unsuccessful,
          responseMessage: result?.message,
        });
        success = false;
      }
    }

    if (contactsToPropertiesArray.length > 0) {
      let result = await AgreementAPI.AddContactsToAgreementProperties(
        agreementUniqueIdentifier,
        contactsToPropertiesArray
      );
      if (result?.successful) {
        contactsToAgreementResponse = result;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding Contacts to the agreement properties",
          apiName: "AddContactsToAgreementProperties",
          responseUnsuccessful: result?.unsuccessful,
          responseMessage: result?.message,
        });
        success = false;
      }
    }

    return { success, contactsToAgreementResponse };
  }

  async removeContactsFromAgreement(agreementObject) {
    let success = false;
    let removeContactsResponse = "";
    let removeContactsFromAgreementResponse = JSON.parse(JSON.stringify(agreementObject));
    let removeContactsFromPropertiesResponse = JSON.parse(JSON.stringify(agreementObject));

    const contactsToBeRemovedFromAgreements = this.getContactsToBeRemovedFromAgreements(agreementObject);
    if (contactsToBeRemovedFromAgreements?.length > 0) {
      removeContactsFromAgreementResponse = await AgreementAPI.RemoveContactsFromAgreement(
        this.state.agreementUniqueIdentifier,
        contactsToBeRemovedFromAgreements
      );
      if (removeContactsFromAgreementResponse?.successful) {
        success = true;
        removeContactsResponse = removeContactsFromAgreementResponse;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error removing Contacts from agreement",
          apiName: "RemoveContactsFromAgreement",
          responseUnsuccessful: removeContactsFromAgreementResponse?.unsuccessful,
          responseMessage: removeContactsFromAgreementResponse?.message,
        });
      }
    }

    const contactsToBeRemovedFromProperties = this.getContactsToBeRemovedFromProperties(agreementObject);
    if (contactsToBeRemovedFromProperties?.length > 0) {
      removeContactsFromPropertiesResponse = await AgreementAPI.RemoveContactsFromAgreementProperties(
        this.state.agreementUniqueIdentifier,
        contactsToBeRemovedFromProperties
      );
      if (removeContactsFromPropertiesResponse?.successful) {
        success = true;
        removeContactsResponse = removeContactsFromPropertiesResponse;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error removing Contacts from agreement properties",
          apiName: "RemoveContactsFromAgreementProperties",
          responseUnsuccessful: removeContactsFromPropertiesResponse?.unsuccessful,
          responseMessage: removeContactsFromPropertiesResponse?.message,
        });
      }
    }

    success = this.isSuccessfulAPIResponse(removeContactsFromAgreementResponse, removeContactsFromPropertiesResponse);

    return { success, removeContactsResponse };
  }

  getPropertiesToAddToContact(contact, agreementObject) {
    let addToProperties = [];
    contact.checkedProperties.forEach((property) => {
      const propertyId = property.propertyUniqueIdentifier || property.propertyUniqueReference;
      const existingProperty = agreementObject.data.agreementProperties?.find(
        (p) => p.propertyUniqueReference === propertyId
      );
      if (!existingProperty?.additionalContacts?.find((c) => c.contactUniqueReference === contact.contactGuid)) {
        addToProperties.push(property);
      }
    });
    return addToProperties;
  }

  isNewContactToAdd(contact, agreementObject) {
    return (
      contact.contactGuid &&
      !agreementObject?.data?.additionalContacts.find((c) => c.contactUniqueReference === contact.contactGuid)
    );
  }

  getContactsToAgreementObject(contact) {
    return {
      contactUniqueReference: contact.contactGuid,
      contactRoleOnAgreementEnumId: parseInt(contact.contactRoleEnumId),
    };
  }

  getContactsToAgreementPropertiesObject(contact, addToProperties) {
    let propertyObjectList = [];
    addToProperties.forEach((property) => {
      propertyObjectList.push({
        propertyUniqueReference: property.propertyUniqueIdentifier
          ? property.propertyUniqueIdentifier
          : property.propertyUniqueReference,
      });
    });

    return {
      contactUniqueReference: contact.contactGuid,
      contactRoleOnAgreementEnumId: parseInt(contact.contactRoleEnumId),
      addToProperties: propertyObjectList,
    };
  }

  findRecommendationsToDelete(recommendationsToDelete) {
    this.state.initialRecs.forEach((rec) => {
      const currentResourceRecord = this.state.recommendations.find((r) => r.recCemId === rec.recCemId);
      if (!currentResourceRecord) {
        recommendationsToDelete.push(rec);
      }
    });
  }

  filterRecommendationsByProperty(recommendationsToDelete, propertyID) {
    return (
      recommendationsToDelete?.filter((rec) =>
        rec.checkedProperties.some(
          (p) => (p.propertyUniqueReference ? p.propertyUniqueReference : p.propertyUniqueIdentifier) === propertyID
        )
      ) ?? []
    );
  }

  findCemsToDelete(cemsToDelete) {
    this.state.initialCems.forEach((rec) => {
      const currentResourceRecord = this.state.cooperatorEmployedMethods.find((r) => r.recCemId === rec.recCemId);
      if (!currentResourceRecord) {
        cemsToDelete.push(rec);
      }
    });
  }

  filterCemsByProperty(cemsToDelete, propertyID) {
    return (
      cemsToDelete?.filter((rec) =>
        rec.checkedProperties.some(
          (p) => (p.propertyUniqueReference ? p.propertyUniqueReference : p.propertyUniqueIdentifier) === propertyID
        )
      ) ?? []
    );
  }

  async removeRecCemsFromAgreementProperties() {
    let removeRecCallSuccess = true;
    let removeCemCallSuccess = true;
    let recommendationsToDelete = [];
    let cemsToDelete = [];

    this.findRecommendationsToDelete(recommendationsToDelete);
    this.findCemsToDelete(cemsToDelete);

    let removeRecsBody = [];
    let removeCemsBody = [];
    if (recommendationsToDelete.length > 0 || cemsToDelete.length > 0) {
      this.state.agreementProperties.forEach((property) => {
        let propertyID = property.propertyUniqueReference
          ? property.propertyUniqueReference
          : property.propertyUniqueIdentifier;

        const removeRecs = this.filterRecommendationsByProperty(recommendationsToDelete, propertyID);
        const removeCems = this.filterCemsByProperty(cemsToDelete, propertyID);

        let recForThisProperty = [];
        let cemForThisProperty = [];
        removeRecs.forEach((rec) => {
          recForThisProperty.push({ recCemUniqueReference: rec.recCemId });
        });
        removeCems.forEach((rec) => {
          cemForThisProperty.push({ recCemUniqueReference: rec.recCemId });
        });
        let removeRecRecord = {
          recCemsToRemove: recForThisProperty,
          propertyUniqueReference: propertyID,
        };
        removeRecsBody.push(removeRecRecord);

        let removeCemRecord = {
          recCemsToRemove: recForThisProperty,
          propertyUniqueReference: propertyID,
        };
        removeCemsBody.push(removeCemRecord);
      });

      if (removeRecsBody.length > 0) {
        var removeRecAction = await AgreementAPI.RemoveRecommendationsFromAgreementProperties(
          this.state.agreementUniqueIdentifier,
          removeRecsBody
        );
        if (!removeRecAction?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error removing Recommendations from agreement property",
            apiName: "RemoveRecommendationsFromAgreementProperties",
            responseUnsuccessful: removeRecAction?.unsuccessful,
            responseMessage: removeRecAction?.message,
          });
        }
        removeRecCallSuccess = removeRecAction?.successful ?? false;
      }

      if (removeRecsBody.length > 0) {
        var removeCemAction = await AgreementAPI.RemoveCooperatorEmployedMethodsFromAgreementProperties(
          this.state.agreementUniqueIdentifier,
          removeCemsBody
        );
        if (!removeCemAction?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error removing Cooperator Employed Methods from agreement property",
            apiName: "RemoveCooperatorEmployedMethodsFromAgreementProperties",
            responseUnsuccessful: removeCemAction?.unsuccessful,
            responseMessage: removeCemAction?.message,
          });
        }
        removeCemCallSuccess = removeCemAction?.successful ?? false;
      }
    }
    return removeRecCallSuccess && removeCemCallSuccess;
  }

  setRecommendationsToAddToAgreementProperties(
    recommendations,
    recommendationReferencesToAdd,
    addToProperties,
    recArray,
    sendArray
  ) {
    recommendations.forEach((recCem) => {
      if (recCem.isNewRecCem) {
        recommendationReferencesToAdd.push(recCem.recCemId);

        recCem.checkedProperties.forEach((property) => {
          let propertyID = property.propertyUniqueIdentifier
            ? property.propertyUniqueIdentifier
            : property.propertyUniqueReference;
          addToProperties.push(propertyID);
        });
        let recDataObj = {
          recommendationReferencesToAdd: recommendationReferencesToAdd,
          cemReferencesToAdd: [],
          addToProperties: addToProperties,
        };
        recArray.push(recDataObj);
        sendArray.push(recDataObj);
        addToProperties = [];
        recommendationReferencesToAdd = [];
      }
    });
  }

  setCemsToAddToAgreementProperties(
    cooperatorEmployedMethods,
    cemReferencesToAdd,
    addToProperties,
    cemsArray,
    sendArray
  ) {
    cooperatorEmployedMethods.forEach((recCem) => {
      if (recCem.isNewRecCem) {
        cemReferencesToAdd.push(recCem.recCemId);

        recCem.checkedProperties.forEach((property) => {
          let propertyID = property.propertyUniqueIdentifier
            ? property.propertyUniqueIdentifier
            : property.propertyUniqueReference;
          addToProperties.push(propertyID);
        });
        let recDataObj = {
          recommendationReferencesToAdd: [],
          cemReferencesToAdd: cemReferencesToAdd,
          addToProperties: addToProperties,
        };
        cemsArray.push(recDataObj);
        sendArray.push(recDataObj);
        addToProperties = [];
        cemReferencesToAdd = [];
      }
    });
  }

  async addRecCemsToAgreementProperties() {
    const { recommendations, cooperatorEmployedMethods } = this.state;

    let recCemsToAgreementPropResponse = "";
    let success = false;
    let recommendationReferencesToAdd = [];
    let cemReferencesToAdd = [];
    let sendArray = [];
    let recArray = [];
    let cemsArray = [];
    let addToProperties = [];
    if (recommendations) {
      this.setRecommendationsToAddToAgreementProperties(
        recommendations,
        recommendationReferencesToAdd,
        addToProperties,
        recArray,
        sendArray
      );
    }

    if (cooperatorEmployedMethods) {
      this.setCemsToAddToAgreementProperties(
        cooperatorEmployedMethods,
        cemReferencesToAdd,
        addToProperties,
        cemsArray,
        sendArray
      );
    }

    if (sendArray.length > 0) {
      let addResult = await AgreementAPI.AddRecCemsToAgreementProperties(
        this.state.agreementUniqueIdentifier,
        sendArray
      );
      if (addResult?.successful) {
        success = true;
        recCemsToAgreementPropResponse = addResult;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding Rec/Cems to the agreement properties",
          apiName: "AddRecCemsToAgreementProperties",
          responseUnsuccessful: addResult?.unsuccessful,
          responseMessage: addResult?.message,
        });
      }
    } else {
      success = true;
    }
    return { success, recCemsToAgreementPropResponse };
  }

  filterSpecialInstructionsByIfUpdated() {
    return this.state.propertySpecialInstructions.filter((i) => i.isUpdatedInstruction);
  }

  async addSpecialInstructionsToAgreementProperties(agreementObject) {
    let success = false;
    let sendArray = [];
    let specialInstructionsToAgreementPropResponse = "";

    this.filterSpecialInstructionsByIfUpdated().forEach(async (instruction) => {
      let propertyGuids = instruction.checkedProperties.map((property) => {
        return {
          propertyUniqueReference: property.propertyUniqueIdentifier
            ? property.propertyUniqueIdentifier
            : property.propertyUniqueReference,
        };
      });
      const filteredPropertyGuids = propertyGuids.filter(
        (id) =>
          !agreementObject.data?.agreementProperties?.some(
            (p) =>
              p.propertyUniqueReference === id.propertyUniqueReference &&
              p.specialInstructions?.find((i) => i.specialInstruction?.trim() === instruction.text?.trim())
          )
      );
      if (filteredPropertyGuids?.length > 0) {
        let sendObj = {
          specialInstructions: instruction.text,
          addToProperties: filteredPropertyGuids,
        };
        sendArray.push(sendObj);
      }
    });
    if (sendArray.length > 0) {
      let addResult = await AgreementAPI.AddSpecialInstructionsToAgreementProperties(
        this.state.agreementUniqueIdentifier,
        sendArray
      );
      if (addResult?.successful) {
        success = true;
        specialInstructionsToAgreementPropResponse = addResult;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding Special Instructions to the agreement properties",
          apiName: "AddSpecialInstructionsToAgreementProperties",
          responseUnsuccessful: addResult?.unsuccessful,
          responseMessage: addResult?.message,
        });
      }
    } else {
      success = true;
    }

    return { success, specialInstructionsToAgreementPropResponse };
  }

  async removeSpecialInstructionsFromAgreementProperties(agreementObject) {
    let success = false;
    let removeSpecialInstructionsFromPropResponse = "";
    const specialInstructionsToBeRemovedFromProperties =
      this.getSpecialInstrictionsToBeRemovedFromProperties(agreementObject);
    if (specialInstructionsToBeRemovedFromProperties?.length > 0) {
      const removeSpecialInstructionsResponse = await AgreementAPI.RemoveSpecialInstructionsFromAgreementProperties(
        this.state.agreementUniqueIdentifier,
        specialInstructionsToBeRemovedFromProperties
      );
      if (removeSpecialInstructionsResponse?.successful) {
        success = true;
        removeSpecialInstructionsFromPropResponse = removeSpecialInstructionsResponse;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error removing Special Instructions from agreement properties",
          apiName: "RemoveSpecialInstructionsFromAgreementProperties",
          responseUnsuccessful: removeSpecialInstructionsResponse?.unsuccessful,
          responseMessage: removeSpecialInstructionsResponse?.message,
        });
      }
    } else {
      success = true;
    }

    return { success, removeSpecialInstructionsFromPropResponse };
  }

  getSpecialInstrictionsToBeRemovedFromProperties(agreementObject) {
    let specialInstructionsToBeRemovedFromProperties = [];
    const { propertySpecialInstructions } = this.state;
    for (let property of agreementObject?.data?.agreementProperties) {
      property.specialInstructions.forEach((existingInstruction) => {
        const updatedInstruction = propertySpecialInstructions.find(
          (i) => i?.text && i.text.trim() === existingInstruction?.specialInstruction?.trim()
        );
        if (!updatedInstruction) {
          this.addSpecialInstructionToBeRemovedFromProperty(
            specialInstructionsToBeRemovedFromProperties,
            property,
            existingInstruction
          );
        } else if (
          !updatedInstruction.checkedProperties?.some(
            (p) =>
              p.propertyUniqueReference === property.propertyUniqueReference ||
              p.propertyUniqueIdentifier === property.propertyUniqueReference
          )
        ) {
          this.addSpecialInstructionToBeRemovedFromProperty(
            specialInstructionsToBeRemovedFromProperties,
            property,
            existingInstruction
          );
        }
      });
    }

    return specialInstructionsToBeRemovedFromProperties;
  }

  addSpecialInstructionToBeRemovedFromProperty(
    specialInstructionsToBeRemovedFromProperties,
    property,
    existingInstruction
  ) {
    const existingInstructionEntryToBeRemoved = specialInstructionsToBeRemovedFromProperties?.find(
      (i) => i.propertyUniqueReference === property.propertyUniqueReference
    );
    if (!existingInstructionEntryToBeRemoved) {
      specialInstructionsToBeRemovedFromProperties.push({
        propertyUniqueReference: property.propertyUniqueReference,
        specialInstructionUniqueIdentifiersToRemove: [
          {
            specialInstructionUniqueIdentifier: existingInstruction.specialInstructionUniqueIdentifier,
          },
        ],
      });
    } else {
      existingInstructionEntryToBeRemoved.specialInstructionUniqueIdentifiersToRemove.push({
        specialInstructionUniqueIdentifier: existingInstruction.specialInstructionUniqueIdentifier,
      });
    }
  }

  constructAdditionalFieldAgentsOject(additionalFieldAgents) {
    if (additionalFieldAgents) {
      let array = [];
      for (let i = 0; i < additionalFieldAgents.length; i++) {
        array.push({ fieldAgentWsUserProfileUniqueReference: additionalFieldAgents[i].contactGuid });
      }
      return array;
    }
  }

  goBackAStep = (e) => {
    e.preventDefault();
    let data = this.state;
    data.step = data.step - 1;
    this.setState({ data });
  };

  continueToNextStep = () => {
    let data = this.state;
    if (data.step === 1) {
      if (!this.isAgreementDataValid()) {
        toast.warning(
          `Please fill out Agreement Name, Primary WS Specialist${
            data.agreementTypeEnumId !== 2 ? "Agreement Type and Cooperator" : ""
          } to proceed to Step 2.`
        );
        return;
      }
    }

    if (data.step === 2 && data.agreementProperties.length === 0) {
      toast.warning("Please associate at least one property to proceed to Step 3.");
      return;
    }

    if (data.step === 3) {
      if (
        this.state.protectedResources?.length === 0 &&
        !this.state.noProtectedResources &&
        data.agreementTypeEnumId !== 2
      ) {
        toast.warning(
          'Please add at least one Protected Resource or select the option for "No Protected Resources" before continuing to Step 4'
        );
        return;
      }

      if (this.state.species?.length === 0) {
        toast.warning("Please add at least one Damage Agent before continuing to Step 4");
        return;
      }

      if (this.state.methods?.length === 0) {
        toast.warning("Please add at least one Method before continuing to Step 4");
        return;
      }
      if (
        Number.parseInt(this.state.primaryCooperatorRoleEnumId) !==
        this.state.agreementContactRoles.find((role) => role.name === "owner")?.id
      ) {
        let noCooperatorPropertyRole = false;
        this.state.agreementProperties.forEach((property) => {
          if (!property.cooperatorsRoleOnPropertyEnumId) {
            noCooperatorPropertyRole = true;
          }
        });
        if (noCooperatorPropertyRole && data.agreementTypeEnumId !== 2) {
          toast.warning("Please add the cooperator's role for each property before continuing to Step 4");
          return;
        }
      }

      if (
        this.state.historicalResourcesInventory?.length === 0 &&
        !this.state.noHistoricalResources &&
        data.agreementTypeEnumId !== 2
      ) {
        toast.warning(
          'Please add at least one Historical Resource or select the option for "No Historical Resources" before continuing to Step 4'
        );
        return;
      }

      if (
        !this.state.historicalResourcesInventory?.some((r) => r.historicalLosses?.length > 0) &&
        !this.state.noLosses &&
        data.agreementTypeEnumId !== 2
      ) {
        toast.warning(
          'Please add at least one year\'s worth of data for historical losses or select the option for "No Losses" before continuing to Step 4'
        );
        return;
      }
    }

    data.step = data.step + 1;
    if (data.step > data.highestStepVisited) {
      data.highestStepVisited = data.step;
    }
    this.setState({ data });
  };

  closeHappyModal = () => {
    this.setState({ showHappyModal: false });
  };

  closeSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  closePrintAgreementModal = () => {
    this.setState({ showPrintAgreementModal: false });
  };

  viewPrintAgreementModal = () => {
    this.setState({
      agreementData: this.getAgreementData(),
      associatedContactsData: this.getAssociatedContactsData(),
      cooperatorData: this.getCooperatorData(),
      primaryFieldAgentData: this.getPrimaryFieldAgentData(),
      propertiesData: this.state.agreementProperties?.map((p) => p.propertyObject),
      showPrintAgreementModal: true,
    });
  };

  getAgreementData() {
    const { data } = this.state;
    let agreementProperties = [];
    this.state.agreementProperties.forEach((property) => {
      const agreementSpecies = data.species.filter((species) =>
        species.checkedProperties.some((p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier)
      );
      const agreementMethods = data.methods.filter((method) =>
        method.checkedProperties.some((p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier)
      );

      agreementProperties.push({
        propertyUniqueReference: property.propertyUniqueIdentifier,
        mapPoint: JSON.stringify([property.latitude?.toString(), property.longitude?.toString()]),
        latitude: property.latitude,
        longitude: property.longitude,
        address: property.additionalAddresses[0],
        targetedSpecies: agreementSpecies.map((species) => ({
          speciesName: species.speciesName,
          checkedProperties: species.checkedProperties
            .filter((p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier)
            .map((speciesProperty) => ({
              checkedParcels: speciesProperty.parcels,
            })),
        })),
        allowedMethods: agreementMethods.map((method) => ({
          methodName: method.methodName,
          checkedProperties: method.checkedProperties
            .filter((p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier)
            .map((methodProperty) => ({
              checkedParcels: methodProperty.parcels,
            })),
        })),
      });
    });

    let agreementData;
    if (data) {
      agreementData = {
        agreementType: { id: data.agreementTypeEnumId !== "-1" ? parseInt(data.agreementTypeEnumId) : "" },
        agreementNumber: data.agreementNumber,
        commonName: data.commonName,
        agreementProperties: agreementProperties,
        specialInstructions: [{ specialInstruction: data.specialInstructions }],
      };
    }
    return agreementData;
  }

  getAssociatedContactsData() {
    let associatedContactsData = [];
    if (this.state.data?.additionalContacts) {
      for (const contact of this.state.data.additionalContacts) {
        if (contact.checkedProperties?.length > 0) {
          contact.checkedProperties.forEach((property) => {
            associatedContactsData.push({
              propertyUniqueIdentifier: property.propertyUniqueIdentifier,
              fullName: contact.contactName,
              primaryPhoneNumber: this.getPrimaryPhoneNumber(contact.phoneNumbers),
              emailAddress: contact.emailAddress,
              fullMailingAddress: this.getPrimaryAddress(contact.primaryMailingAddress, "contact"),
              contactRole: contact.contactRoleName,
            });
          });
        }
      }
    }
    return associatedContactsData;
  }

  getPrimaryFieldAgentData() {
    return {
      fullName: this.state.primaryFieldAgentName,
      primaryPhoneNumber: this.getPrimaryPhoneNumber(this.state.primaryFieldAgentPhoneNumbers),
      emailAddress: this.state.primaryFieldAgentEmail,
      fullMailingAddress: this.getPrimaryAddress(this.state.primaryFieldAgentMailingAddress, "contact"),
    };
  }

  getCooperatorData() {
    const data = this.state;
    const cooperatorAddress = {
      mailingStreet: data.primaryCooperatorStreet,
      mailingCity: data.primaryCooperatorCity,
      mailingStateUniqueId: data.primaryCooperatorStateUniqueIdentifier,
      mailingZip: data.primaryCooperatorZip,
      mailingCountryUniqueId: data.primaryCooperatorCountryUniqueIdentifier,
    };

    return {
      fullName: data.primaryCooperatorName,
      primaryPhoneNumber: this.getPrimaryPhoneNumber(data.primaryCooperatorPhoneNumbers),
      emailAddress: data.primaryCooperatorEmail,
      fullMailingAddress: this.getPrimaryAddress(cooperatorAddress, "contact"),
    };
  }

  getPrimaryPhoneNumber(phoneNumbers) {
    let phoneNumber = "";
    if (phoneNumbers && phoneNumbers.length > 0) {
      phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumbers[0].phoneNumber);
    }
    return phoneNumber;
  }

  getPrimaryAddress(providedAddress, entityType) {
    let primaryAddress = "";

    if (providedAddress) {
      switch (entityType) {
        case "contact":
          const { mailingStreet, mailingCity, mailingStateUniqueId, mailingZip, mailingCountryUniqueId } =
            providedAddress;
          primaryAddress = mailingStreet.concat(
            ", ",
            mailingCity,
            ", ",
            this.getStateNameFromId(mailingStateUniqueId),
            " ",
            mailingZip,
            ", " + this.getCountryNameFromId(mailingCountryUniqueId)
          );
          break;
        case "property":
          const { streetAddress, city, zipCode } = providedAddress;
          primaryAddress = streetAddress.concat(", ", city, ", ", zipCode);
          break;
        default:
          break;
      }
    }

    return primaryAddress;
  }

  getStateNameFromId(stateId) {
    const { states } = this.state;
    const currentState = states.find((state) => state.stateUniqueIdentifier === stateId);
    return currentState ? currentState.state_name : "";
  }

  getCountryNameFromId(countryId) {
    const { countries } = this.state;
    const country = countries.find((country) => country.id === countryId);
    return country ? country.name : "";
  }

  getCancelButton = () => {
    return (
      <Button
        className={styles.cancelButton}
        variant="outline-primary"
        onClick={() => this.props.history.push("/home")}
      >
        Cancel
      </Button>
    );
  };

  getBackButton = () => {
    return (
      <Button className={styles.buttons} variant="outline-primary" onClick={this.goBackAStep}>
        Back
      </Button>
    );
  };

  getContinueButton = () => {
    return (
      <Button className={styles.buttons} variant="primary" onClick={this.continueToNextStep}>
        Continue
      </Button>
    );
  };
  getSaveButton = () => {
    let buttonText = "Save Draft & Close";
    let variant = "outline-primary";
    let buttonType = "button";
    if (this.state.step === 4) {
      buttonText = "Continue";
      variant = "primary";
      buttonType = "submit";
    }

    return (
      <Button className={styles.buttons} type={buttonType} variant={variant} onClick={this.handleSubmitWrapper}>
        {buttonText}
      </Button>
    );
  };

  getPrintButton() {
    return (
      <Button className={styles.buttons} variant="primary" onClick={this.viewPrintAgreementModal}>
        Print
      </Button>
    );
  }

  getStep1Buttons = () => {
    return (
      <Row noGutters="true">
        <Col align="left" lg={6}>
          {this.getCancelButton()}
        </Col>
        <Col align="right" lg={6}>
          {this.getPrintButton()}
          {this.getSaveButton()}
          {this.getContinueButton()}
        </Col>
      </Row>
    );
  };

  getMiddleStepsButtons = () => {
    return (
      <Row noGutters="true">
        <Col align="left" lg={6}>
          {this.getCancelButton()}
        </Col>
        <Col align="right" lg={6}>
          {this.getPrintButton()}
          {this.getSaveButton()}
          {this.getBackButton()}
          {this.getContinueButton()}
        </Col>
      </Row>
    );
  };

  getStep4Buttons = () => {
    return (
      <Row noGutters="true">
        <Col align="left" lg={6}>
          {this.getCancelButton()}
        </Col>
        <Col align="right" lg={6}>
          {this.getBackButton()}
          {this.getSaveButton()}
        </Col>
      </Row>
    );
  };

  close12AModal = () => {
    this.setState({ show12AUploadModal: false });
  };

  getNavigationStepper() {
    let step1 = null;
    let step2 = null;
    let step3 = null;
    let step4 = null;

    if (this.state.step === 1) {
      step1 = (
        <div className={styles.navStepperSection}>
          <Image src={Step1Empty} alt="On Step 1 page indicator" />
        </div>
      );
      step2 = (
        <div className={styles.navStepperSection}>
          <Image src={Step2Gray} alt="Navigate to Step 2" />
        </div>
      );
    }
    if (this.state.step > 1) {
      step1 = (
        <div className={styles.navStepperSectionClickable} onClick={() => this.setState({ step: 1 })}>
          <Image src={Step1Fill} alt="Navigate to Step 1" />
        </div>
      );
    }
    if (this.state.step === 2) {
      step2 = (
        <div className={styles.navStepperSection}>
          <Image src={Step2Empty} alt="On Step 2 page indicator" />
        </div>
      );
    }
    if (this.state.step > 2 || (this.state.step === 1 && this.state.highestStepVisited >= 2)) {
      step2 = (
        <div className={styles.navStepperSectionClickable} onClick={() => this.setState({ step: 2 })}>
          <Image src={Step2Fill} alt="Navigate to Step 2" />
        </div>
      );
    }
    if (this.state.step === 3) {
      step3 = (
        <div className={styles.navStepperSection}>
          <Image src={Step3Empty} alt="On Step 3 page indicator" />
        </div>
      );
    }
    if (this.state.step < 3) {
      step3 = (
        <div className={styles.navStepperSection}>
          <Image src={Step3Gray} alt="Navigate to Step 3" />
        </div>
      );
    }
    if (this.state.step === 4 || (this.state.step < 3 && this.state.highestStepVisited >= 3)) {
      step3 = (
        <div className={styles.navStepperSectionClickable} onClick={() => this.setState({ step: 3 })}>
          <Image src={Step3Fill} alt="Navigate to Step 3" />
        </div>
      );
    }
    if (this.state.step === 4) {
      step4 = (
        <div className={styles.navStepperSection}>
          <Image src={Step4Empty} alt="On Step 4 page indicator" />
        </div>
      );
    }
    if (this.state.step < 4) {
      step4 = (
        <div className={styles.navStepperSection}>
          <Image src={Step4Gray} alt="Navigate to Step 4" />
        </div>
      );
    }
    if (this.state.step < 4 && this.state.highestStepVisited >= 4) {
      step4 = (
        <div className={styles.navStepperSectionClickable} onClick={() => this.setState({ step: 4 })}>
          <Image src={Step4Fill} alt="Navigate to Step 2" />
        </div>
      );
    }

    const stepper = (
      <div className={styles.stickyTop}>
        {step1}
        {step2}
        {step3}
        {step4}
      </div>
    );
    return stepper;
  }

  async updateAgreementDetails(agreementObject) {
    let success = true;
    let updatedAgreementObject = JSON.parse(JSON.stringify(agreementObject));

    if (this.isAgreementDetailsUpdateRequired(updatedAgreementObject)) {
      const updateAgreementResponse = await AgreementAPI.UpdateAgreement(
        this.state.agreementUniqueIdentifier,
        this.getUpdateAgreementBody()
      );
      if (updateAgreementResponse?.successful) {
        updatedAgreementObject = updateAgreementResponse;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to Update Agreement",
          apiName: "UpdateAgreement",
          responseUnsuccessful: updateAgreementResponse?.unsuccessful,
          responseMessage: updateAgreementResponse?.message,
        });
        success = false;
      }
    }

    return { success: success, updatedAgreementObject };
  }

  isAgreementDetailsUpdateRequired(existingAgreementObject) {
    let isUpdateRequired = false;
    const {
      isExistingAgreement,
      agreementTypeEnumId,
      commonName,
      agreementNumber,
      primaryFieldAgentUniqueIdentifier,
      primaryCooperatorUniqueIdentifier,
      primaryCooperatorRoleEnumId,
      agreementProjects,
    } = this.state;
    const existingAgreementData = existingAgreementObject?.data;
    if (existingAgreementData) {
      isUpdateRequired =
        isExistingAgreement &&
        (parseInt(agreementTypeEnumId) !== existingAgreementData.agreementType?.id ||
          commonName !== existingAgreementData.commonName ||
          agreementNumber !== existingAgreementData.agreementNumber ||
          primaryFieldAgentUniqueIdentifier !==
            existingAgreementData.primaryFieldAgent?.fieldAgentWsUserProfileUniqueReference ||
          primaryCooperatorUniqueIdentifier !== existingAgreementData.cooperator?.contactUniqueReference ||
          primaryCooperatorRoleEnumId !== existingAgreementData.cooperator?.cooperatorRole?.id ||
          agreementProjects !== existingAgreementData.projectUniqueReferences);
    }

    return isUpdateRequired;
  }

  getUpdateAgreementBody() {
    return {
      agreementTypeEnumId: parseInt(this.state.agreementTypeEnumId),
      commonName: this.state.commonName,
      agreementNumberSuffix: this.state.agreementNumber !== "" ? this.state.agreementNumber?.slice(-8) : null,
      primaryFieldAgentWsUserProfileUniqueReference: this.state.primaryFieldAgentUniqueIdentifier,
      cooperatorContactUniqueReference:
        this.state.primaryCooperatorUniqueIdentifier !== "" ? this.state.primaryCooperatorUniqueIdentifier : null,
      organizationUniqueReferences: [],
      projectUniqueReferences: this.state.agreementProjects?.map((p) => p.value),
      enforceDetailedWorkPerformedEnumId: 2,
      cooperatorsRoleOnAgreementEnumId:
        parseInt(this.state.agreementTypeEnumId) === 2 ? null : this.state.primaryCooperatorRoleEnumId,
    };
  }

  async addPropertiesToAgreement(agreementObject) {
    let success = false;
    let propertiesToAgreementResponse = "";
    const newProperties = this.getNewPropertiesToBeAdded(agreementObject);
    if (newProperties?.length > 0) {
      const addPropertiesToAgreementBody = this.getProperties(newProperties);
      let addPropertyResult = await AgreementAPI.AddPropertiesToAgreement(
        this.state.agreementUniqueIdentifier,
        addPropertiesToAgreementBody
      );
      if (addPropertyResult?.successful) {
        success = true;
        propertiesToAgreementResponse = addPropertyResult;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding Properties to the agreement",
          apiName: "AddPropertiesToAgreement",
          responseUnsuccessful: addPropertyResult?.unsuccessful,
          responseMessage: addPropertyResult?.message,
        });
      }
    } else {
      success = true;
    }

    return { success, propertiesToAgreementResponse };
  }

  async removePropertiesFromAgreement(agreementObject) {
    let success = false;
    let removePropertiesResponse = "";
    const removedProperties = this.getPropertiesToBeDeleted(agreementObject);
    if (removedProperties?.length > 0) {
      const removePropertiesFromAgreementBody = this.getProperties(removedProperties);
      let removePropertiesResult = await AgreementAPI.RemovePropertiesFromAgreement(
        this.state.agreementUniqueIdentifier,
        removePropertiesFromAgreementBody
      );
      if (removePropertiesResult?.successful) {
        success = true;
        removePropertiesResponse = removePropertiesResult;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error removing Properties to the agreement",
          apiName: "RemovePropertiesFromAgreement",
          responseUnsuccessful: removePropertiesResult?.unsuccessful,
          responseMessage: removePropertiesResult?.message,
        });
      }
    } else {
      success = true;
    }

    return { success, removePropertiesResponse };
  }

  async addCooperatorRoleToAgreementProperties() {
    let success = false;
    let addCooperatorRoleToAgreementResponse = "";
    const cooperatorRolesToAdd = this.state.agreementProperties.map((property) => {
      return {
        propertyUniqueReference: property.propertyUniqueIdentifier
          ? property.propertyUniqueIdentifier
          : property.propertyUniqueReference,
        cooperatorRoleOnAgreementPropertyEnumId: property.cooperatorsRoleOnPropertyEnumId
          ? property.cooperatorsRoleOnPropertyEnumId
          : this.state.primaryCooperatorRoleEnumId,
        landOwnersContact: property.landOwnersContact ? property.landOwnersContact : null,
      };
    });
    if (cooperatorRolesToAdd.length > 0) {
      let addCooperatorRolesResult = await AgreementAPI.AddCooperatorRoleToAgreementProperties(
        this.state.agreementUniqueIdentifier,
        cooperatorRolesToAdd
      );
      if (addCooperatorRolesResult?.successful) {
        success = true;
        addCooperatorRoleToAgreementResponse = addCooperatorRolesResult;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding cooperator roles to agreement properties",
          apiName: "AddCooperatorRoleToAgreementProperties",
          responseUnsuccessful: addCooperatorRolesResult?.unsuccessful,
          responseMessage: addCooperatorRolesResult?.message,
        });
      }
    } else {
      success = true;
    }
    return { success, addCooperatorRoleToAgreementResponse };
  }

  getNewPropertiesToBeAdded(agreementObject) {
    const newProperties = this.state.agreementProperties?.filter(
      (p) =>
        !agreementObject?.data?.agreementProperties.some(
          (existingProperty) =>
            existingProperty.propertyUniqueReference &&
            (existingProperty.propertyUniqueReference === p.propertyUniqueReference ||
              existingProperty.propertyUniqueReference === p.propertyUniqueIdentifier)
        )
    );

    return newProperties?.length > 0 ? newProperties : [];
  }

  getPropertiesToBeDeleted(agreementObject) {
    const removedProperties = agreementObject?.data?.agreementProperties?.filter(
      (p) =>
        !this.state.agreementProperties.some(
          (existingProperty) =>
            existingProperty.propertyUniqueReference === p.propertyUniqueReference ||
            existingProperty.propertyUniqueIdentifier === p.propertyUniqueReference
        )
    );

    return removedProperties?.length > 0 ? removedProperties : [];
  }

  getProperties(agreementProperties) {
    const addToProperties = agreementProperties.map((property) => ({
      propertyUniqueReference: property.propertyUniqueIdentifier || property.propertyUniqueReference,
    }));

    return addToProperties;
  }

  async addMethodsToAgreement(agreementObject) {
    let success = false;
    let methodsToAgreementResponse = "";

    if (this.state.methods?.some((m) => m.isNewMethod)) {
      let methodsToBeAddedToProperties = [];
      let methodsToBeAddedToParcels = [];
      let methodsToAgreementPropertiesResponse = JSON.parse(JSON.stringify(agreementObject));
      let methodsToAgreementParcelsResponse = JSON.parse(JSON.stringify(agreementObject));
      const { agreementUniqueIdentifier } = this.state;

      this.mapMethodsToAgreementCallBodies(methodsToBeAddedToProperties, methodsToBeAddedToParcels, agreementObject);

      if (methodsToBeAddedToProperties?.length > 0) {
        methodsToAgreementPropertiesResponse = await AgreementAPI.AddAllowedMethodsToAgreementProperties(
          agreementUniqueIdentifier,
          methodsToBeAddedToProperties
        );
        if (methodsToAgreementPropertiesResponse?.successful) {
          methodsToAgreementResponse = methodsToAgreementPropertiesResponse;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error adding Methods to the agreement properties",
            apiName: "AddAllowedMethodsToAgreementProperties",
            responseUnsuccessful: methodsToAgreementPropertiesResponse?.unsuccessful,
            responseMessage: methodsToAgreementPropertiesResponse?.message,
          });
        }
      }

      if (methodsToBeAddedToParcels?.length > 0) {
        methodsToAgreementParcelsResponse = await AgreementAPI.AddAllowedMethodsToAgreementParcels(
          agreementUniqueIdentifier,
          methodsToBeAddedToParcels
        );
        if (methodsToAgreementParcelsResponse?.successful) {
          methodsToAgreementResponse = methodsToAgreementParcelsResponse;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error adding Methods to the agreement parcels",
            apiName: "AddAllowedMethodsToAgreementParcels",
            responseUnsuccessful: methodsToAgreementParcelsResponse?.unsuccessful,
            responseMessage: methodsToAgreementParcelsResponse?.message,
          });
        }
      }

      success = this.isSuccessfulAPIResponse(methodsToAgreementPropertiesResponse, methodsToAgreementParcelsResponse);
    } else {
      success = true;
    }

    return { success, methodsToAgreementResponse };
  }

  async getActiveAgreementByProperty(agreementProperties) {
    let isPropertyInActiveAgreement = false;
    const { agreementObject } = this.state;

    for (let property of agreementProperties) {
      let propertyID = property.propertyUniqueReference
        ? property.propertyUniqueReference
        : property.propertyUniqueIdentifier;
      let agreementResponse = await AgreementAPI.GetActiveAgreementByProperty(propertyID);
      if (
        agreementResponse?.successful &&
        agreementResponse.data &&
        (!agreementObject?.data?.parentAgreement ||
          agreementObject.data.parentAgreement !== agreementResponse.data.agreementUniqueIdentifier)
      ) {
        ErrorHandler.showError(
          " The property " +
            property.propertyName +
            " is already associated with a " +
            agreementResponse?.data?.agreementStatus.displayText +
            " Agreement " +
            agreementResponse?.data.commonName
        );
        isPropertyInActiveAgreement = true;
      }
    }
    if (isPropertyInActiveAgreement) {
      this.setState({ isPropertyInActiveAgreement: true });
    }
  }

  async removeMethodsFromAgreement(agreementObject) {
    let success = false;
    let removeMethodsResponse = "";

    const methodsToBeRemovedFromProperties = this.getMethodsToBeRemovedFromProperties(agreementObject);
    if (methodsToBeRemovedFromProperties?.length > 0) {
      const removeMethodsFromAgreementResponse = await AgreementAPI.RemoveAllowedMethodsFromAgreementProperties(
        this.state.agreementUniqueIdentifier,
        methodsToBeRemovedFromProperties
      );
      if (removeMethodsFromAgreementResponse?.successful) {
        success = true;
        removeMethodsResponse = removeMethodsFromAgreementResponse;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error removing Allowed Methods from agreement properties",
          apiName: "RemoveAllowedMethodsFromAgreementProperties",
          responseUnsuccessful: removeMethodsFromAgreementResponse?.unsuccessful,
          responseMessage: removeMethodsFromAgreementResponse?.message,
        });
      }
    } else {
      success = true;
    }

    return { success, removeMethodsResponse };
  }

  getMethodsToBeRemovedFromProperties(agreementObject) {
    let methodsToBeRemovedFromProperties = [];
    const { methods } = this.state;
    for (let property of agreementObject?.data?.agreementProperties) {
      property.allowedMethods.forEach((existingMethod) => {
        const updatedMethod = methods.find((m) => m.methodUniqueIdentifier === existingMethod?.methodUniqueReference);
        if (!updatedMethod) {
          this.addMethodsToBeRemovedFromProperty(methodsToBeRemovedFromProperties, property, existingMethod);
        } else if (
          !updatedMethod.checkedProperties?.some(
            (p) =>
              p.propertyUniqueReference === property.propertyUniqueReference ||
              p.propertyUniqueIdentifier === property.propertyUniqueReference
          )
        ) {
          this.addMethodsToBeRemovedFromProperty(methodsToBeRemovedFromProperties, property, existingMethod);
        }
      });
    }

    return methodsToBeRemovedFromProperties;
  }

  addMethodsToBeRemovedFromProperty(methodsToBeRemovedFromProperties, property, existingMethod) {
    const existingMethodEntryToBeRemoved = methodsToBeRemovedFromProperties?.find(
      (m) => m.methodUniqueReference === existingMethod.methodUniqueReference
    );
    if (!existingMethodEntryToBeRemoved) {
      methodsToBeRemovedFromProperties.push({
        methodUniqueReference: existingMethod.methodUniqueReference,
        removeFromProperties: [
          {
            propertyUniqueReference: property.propertyUniqueReference,
          },
        ],
      });
    } else {
      existingMethodEntryToBeRemoved.removeFromProperties.push({
        propertyUniqueReference: property.propertyUniqueReference,
      });
    }
  }

  async addSpeciesToAgreement(agreementObject) {
    let success = false;
    let speciesToAgreementResponse = "";

    if (this.state.species?.some((s) => s.isNewSpecies)) {
      let speciesToBeAddedToProperties = [];
      let speciesToBeAddedToParcels = [];
      let speciesToAgreementPropertiesResponse = JSON.parse(JSON.stringify(agreementObject));
      let speciesToAgreementParcelsResponse = JSON.parse(JSON.stringify(agreementObject));
      this.mapSpeciesToAgreementCallBodies(speciesToBeAddedToProperties, speciesToBeAddedToParcels, agreementObject);
      const { agreementUniqueIdentifier } = this.state;

      if (speciesToBeAddedToProperties?.length > 0) {
        speciesToAgreementPropertiesResponse = await AgreementAPI.AddTargetedSpeciesToAgreementProperties(
          agreementUniqueIdentifier,
          speciesToBeAddedToProperties
        );
        if (speciesToAgreementPropertiesResponse?.successful) {
          speciesToAgreementResponse = speciesToAgreementPropertiesResponse;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error adding Damage Agent to the agreement properties",
            apiName: "AddTargetedSpeciesToAgreementProperties",
            responseUnsuccessful: speciesToAgreementPropertiesResponse?.unsuccessful,
            responseMessage: speciesToAgreementPropertiesResponse?.message,
          });
        }
      }

      if (speciesToBeAddedToParcels?.length > 0) {
        speciesToAgreementParcelsResponse = await AgreementAPI.AddTargetedSpeciesToAgreementParcels(
          agreementUniqueIdentifier,
          speciesToBeAddedToParcels
        );
        if (speciesToAgreementParcelsResponse?.successful) {
          speciesToAgreementResponse = speciesToAgreementParcelsResponse;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error adding Damage Agent to the agreement parcels",
            apiName: "AddTargetedSpeciesToAgreementParcels",
            responseUnsuccessful: speciesToAgreementParcelsResponse?.unsuccessful,
            responseMessage: speciesToAgreementParcelsResponse?.message,
          });
        }
      }

      success = this.isSuccessfulAPIResponse(speciesToAgreementPropertiesResponse, speciesToAgreementParcelsResponse);
    } else {
      success = true;
    }

    return { success, speciesToAgreementResponse };
  }

  async removeSpeciesFromAgreement(agreementObject) {
    let success = false;
    let removeSpeciesResponse = "";

    const speciesToBeRemovedFromProperties = this.getSpeciesToBeRemovedFromProperties(agreementObject);
    if (speciesToBeRemovedFromProperties?.length > 0) {
      const removeSpeciesFromAgreementResponse = await AgreementAPI.RemoveTargetedSpeciesFromAgreementProperties(
        this.state.agreementUniqueIdentifier,
        speciesToBeRemovedFromProperties
      );
      if (removeSpeciesFromAgreementResponse?.successful) {
        success = true;
        removeSpeciesResponse = removeSpeciesFromAgreementResponse;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error removing Targeted Damage Agent from agreement properties",
          apiName: "RemoveTargetedSpeciesFromAgreementProperties",
          responseUnsuccessful: removeSpeciesFromAgreementResponse?.unsuccessful,
          responseMessage: removeSpeciesFromAgreementResponse?.message,
        });
      }
    } else {
      success = true;
    }

    return { success, removeSpeciesResponse };
  }

  getSpeciesToBeRemovedFromProperties(agreementObject) {
    let speciesToBeRemovedFromProperties = [];
    const { species } = this.state;
    for (let property of agreementObject?.data?.agreementProperties) {
      property.targetedSpecies.forEach((existingSpecies) => {
        const updatedSpecies = species.find(
          (s) => s.speciesUniqueIdentifier === existingSpecies?.speciesUniqueReference
        );
        if (!updatedSpecies) {
          this.addSpeciesToBeRemovedFromProperty(speciesToBeRemovedFromProperties, property, existingSpecies);
        } else if (
          !updatedSpecies.checkedProperties?.some(
            (p) =>
              p.propertyUniqueReference === property.propertyUniqueReference ||
              p.propertyUniqueIdentifier === property.propertyUniqueReference
          )
        ) {
          this.addSpeciesToBeRemovedFromProperty(speciesToBeRemovedFromProperties, property, existingSpecies);
        }
      });
    }

    return speciesToBeRemovedFromProperties;
  }

  getContactsToBeRemovedFromAgreements(agreementObject) {
    let contactsToBeRemovedFromAgreements = [];
    agreementObject.data.additionalContacts.forEach((existingContact) => {
      if (
        !this.state.additionalContacts?.find(
          (c) => c.contactInheritedFrom === "Agreement" && c.contactGuid === existingContact.contactUniqueReference
        )
      ) {
        contactsToBeRemovedFromAgreements.push({
          contactUniqueReference: existingContact.contactUniqueReference,
        });
      }
    });
    return contactsToBeRemovedFromAgreements;
  }

  getContactsToBeRemovedFromProperties(agreementObject) {
    let contactsToBeRemovedFromProperties = [];
    for (let property of agreementObject?.data?.agreementProperties) {
      property.additionalContacts
        .filter((c) => !(c.contactInheritedFrom === "Agreement"))
        .forEach((existingContact) => {
          const updatedContact = this.state.additionalContacts.find(
            (c) => c.contactGuid === existingContact?.contactUniqueReference
          );
          if (
            !updatedContact.checkedProperties?.find(
              (p) =>
                p.propertyUniqueReference === property.propertyUniqueReference ||
                p.propertyUniqueIdentifier === property.propertyUniqueReference
            )
          ) {
            this.addContactsToBeRemovedFromProperty(contactsToBeRemovedFromProperties, property, existingContact);
          }
        });
    }

    return contactsToBeRemovedFromProperties;
  }

  addSpeciesToBeRemovedFromProperty(speciesToBeRemovedFromProperties, property, existingSpecies) {
    const existingSpeciesEntryToBeRemoved = speciesToBeRemovedFromProperties?.find(
      (s) => s.propertyUniqueReference === property.propertyUniqueReference
    );
    if (!existingSpeciesEntryToBeRemoved) {
      speciesToBeRemovedFromProperties.push({
        propertyUniqueReference: property.propertyUniqueReference,
        speciesToRemove: [
          {
            speciesUniqueReference: existingSpecies.speciesUniqueReference,
          },
        ],
      });
    } else {
      existingSpeciesEntryToBeRemoved.speciesToRemove.push({
        speciesUniqueReference: existingSpecies.speciesUniqueReference,
      });
    }
  }

  addContactsToBeRemovedFromProperty(contactsToBeRemovedFromProperties, property, existingContact) {
    const existingContactEntryToBeRemoved = contactsToBeRemovedFromProperties?.find(
      (c) => c.propertyUniqueReference === property.propertyUniqueReference
    );
    if (!existingContactEntryToBeRemoved) {
      contactsToBeRemovedFromProperties.push({
        propertyUniqueReference: property.propertyUniqueReference,
        contactsToRemove: [
          {
            contactUniqueReference: existingContact.contactUniqueReference,
          },
        ],
      });
    } else {
      existingContactEntryToBeRemoved.contactsToRemove.push({
        contactUniqueReference: existingContact.contactUniqueReference,
      });
    }
  }

  async addResourcesToAgreement(agreementObject) {
    const { protectedResources, historicalResourcesInventory, agreementUniqueIdentifier } = this.state;
    let success = false;
    let resourcesToAgreementResponse = JSON.parse(JSON.stringify(agreementObject));
    let addResourcesToAgreementResponse = JSON.parse(JSON.stringify(agreementObject));
    let updateResourcesOnAgreementResponse = JSON.parse(JSON.stringify(agreementObject));

    const allResources = [...protectedResources, ...historicalResourcesInventory].filter((r) => r.isNewResource);
    if (allResources?.length > 0) {
      const { newResourcesToBeAdded, existingResourcesToBeUpdated } = this.getResourcesToAgreementBody(
        allResources,
        agreementObject
      );

      if (newResourcesToBeAdded?.length > 0) {
        addResourcesToAgreementResponse = await AgreementAPI.AddResourcesToAgreementProperties(
          agreementUniqueIdentifier,
          newResourcesToBeAdded
        );
        if (addResourcesToAgreementResponse?.successful) {
          resourcesToAgreementResponse = addResourcesToAgreementResponse;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error adding Resources to agreement properties",
            apiName: "AddResourcesToAgreementProperties",
            responseUnsuccessful: addResourcesToAgreementResponse?.unsuccessful,
            responseMessage: addResourcesToAgreementResponse?.message,
          });
        }
      }

      if (existingResourcesToBeUpdated?.length > 0) {
        updateResourcesOnAgreementResponse = await AgreementAPI.UpdateResourcesOnAgreementProperties(
          agreementUniqueIdentifier,
          existingResourcesToBeUpdated
        );
        if (updateResourcesOnAgreementResponse?.successful) {
          resourcesToAgreementResponse = updateResourcesOnAgreementResponse;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error updating Resources on agreement properties",
            apiName: "UpdateResourcesOnAgreementProperties",
            responseUnsuccessful: updateResourcesOnAgreementResponse?.unsuccessful,
            responseMessage: updateResourcesOnAgreementResponse?.message,
          });
        }
      }

      success = this.isSuccessfulAPIResponse(addResourcesToAgreementResponse, updateResourcesOnAgreementResponse);
    } else {
      success = true;
    }
    return { success: success, resourcesToAgreementResponse };
  }

  async removeResourcesFromAgreement(agreementObject) {
    const { protectedResources, historicalResourcesInventory } = this.state;
    let success = false;
    let removeResourcesResponse = JSON.parse(JSON.stringify(agreementObject));
    let removeResourcesFromAgreementResponse = JSON.parse(JSON.stringify(agreementObject));

    const allResources = [...protectedResources, ...historicalResourcesInventory];
    if (allResources?.length > 0) {
      const resourcesToBeRemoved = this.getRemoveResourcesBody(allResources, agreementObject);
      if (resourcesToBeRemoved?.length > 0) {
        removeResourcesFromAgreementResponse = await AgreementAPI.RemoveResourcesFromAgreementProperties(
          resourcesToBeRemoved
        );
        if (removeResourcesFromAgreementResponse?.successful) {
          removeResourcesResponse = removeResourcesFromAgreementResponse;
          success = true;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Error removing Resources from agreement properties",
            apiName: "RemoveResourcesFromAgreementProperties",
            responseUnsuccessful: removeResourcesFromAgreementResponse?.unsuccessful,
            responseMessage: removeResourcesFromAgreementResponse?.message,
          });
        }
      } else {
        success = true;
      }
    } else {
      success = true;
    }
    return { success: success, removeResourcesResponse };
  }

  async addHistoricalLossesToAgreement(agreementObject) {
    const { historicalResourcesInventory, agreementUniqueIdentifier } = this.state;
    let success = true;
    let historicalLossesToAgreementResponse = JSON.parse(JSON.stringify(agreementObject));

    const historicalLosses = await this.getHistoricalLosses(historicalResourcesInventory, agreementObject);
    if (historicalLosses?.length > 0) {
      const lossesToResourcesResponse = await AgreementAPI.AddHistoricalLossesToAgreementProperties(
        agreementUniqueIdentifier,
        historicalLosses
      );
      if (lossesToResourcesResponse?.successful) {
        success = true;
        historicalLossesToAgreementResponse = lossesToResourcesResponse;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error adding Resources to the agreement parcels",
          apiName: "AddHistoricalLossesToAgreementProperties",
          responseUnsuccessful: lossesToResourcesResponse?.unsuccessful,
          responseMessage: lossesToResourcesResponse?.message,
        });
      }
      success = this.isSuccessfulAPIResponse(lossesToResourcesResponse);
    }

    return { success, historicalLossesToAgreementResponse };
  }

  async removeHistoricalLossesFromAgreement(agreementObject) {
    const { agreementUniqueIdentifier } = this.state;
    let success = true;
    let removeHistoricalLossesResponse = JSON.parse(JSON.stringify(agreementObject));

    const historicalLossesToRemove = this.getHistoricalLossesToRemove(agreementObject);
    if (historicalLossesToRemove?.length > 0) {
      const removeLossesResponse = await AgreementAPI.RemoveHistoricalLossesFromAgreementProperties(
        agreementUniqueIdentifier,
        historicalLossesToRemove
      );
      if (removeLossesResponse?.successful) {
        removeHistoricalLossesResponse = removeLossesResponse;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Error removing Resources to the agreement parcels",
          apiName: "RemoveHistoricalLossesFromAgreementProperties",
          responseUnsuccessful: removeLossesResponse?.unsuccessful,
          responseMessage: removeLossesResponse?.message,
        });
      }
      success = removeLossesResponse?.successful === true;
    }

    return { success, removeHistoricalLossesResponse };
  }

  async setProtectedResourcesNotRequiredForAgreement() {
    const { agreementUniqueIdentifier } = this.state;
    const setResourcesNotRequiredResponse = await AgreementAPI.ResourcesNotRequiredForAgreement(
      agreementUniqueIdentifier
    );
    if (!setResourcesNotRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set resources as not required for this agreement",
        apiName: "ResourcesNotRequiredForAgreement",
        responseUnsuccessful: setResourcesNotRequiredResponse?.unsuccessful,
        responseMessage: setResourcesNotRequiredResponse?.message,
      });
    }
    return setResourcesNotRequiredResponse;
  }

  async setProtectedResourcesRequiredForAgreement() {
    const { agreementUniqueIdentifier } = this.state;
    const setResourcesRequiredResponse = await AgreementAPI.ResourcesRequiredForAgreement(agreementUniqueIdentifier);
    if (!setResourcesRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set resources as required for this agreement",
        apiName: "ResourcesRequiredForAgreement",
        responseUnsuccessful: setResourcesRequiredResponse?.unsuccessful,
        responseMessage: setResourcesRequiredResponse?.message,
      });
    }
    return setResourcesRequiredResponse;
  }

  async setHistoricalResourcesNotRequiredForAgreement() {
    const { agreementUniqueIdentifier } = this.state;
    const setHistoricalResourcesNotRequiredResponse = await AgreementAPI.HistoricalResourcesNotRequiredForAgreement(
      agreementUniqueIdentifier
    );
    if (!setHistoricalResourcesNotRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set historical resources as not required for this agreement",
        apiName: "HistoricalResourcesNotRequiredForAgreement",
        responseUnsuccessful: setHistoricalResourcesNotRequiredResponse?.unsuccessful,
        responseMessage: setHistoricalResourcesNotRequiredResponse?.message,
      });
    }
    return setHistoricalResourcesNotRequiredResponse;
  }

  async setHistoricalResourcesRequiredForAgreement() {
    const { agreementUniqueIdentifier } = this.state;
    const setHistoricalResourcesRequiredResponse = await AgreementAPI.HistoricalResourcesRequiredForAgreement(
      agreementUniqueIdentifier
    );
    if (!setHistoricalResourcesRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set historical resources as required for this agreement",
        apiName: "HistoricalResourcesRequiredForAgreement",
        responseUnsuccessful: setHistoricalResourcesRequiredResponse?.unsuccessful,
        responseMessage: setHistoricalResourcesRequiredResponse?.message,
      });
    }
    return setHistoricalResourcesRequiredResponse;
  }

  async setHistoricalLossesNotRequiredForAgreement() {
    const { agreementUniqueIdentifier } = this.state;
    const setLossesNotRequiredResponse = await AgreementAPI.HistoricalLossesNotRequiredForAgreement(
      agreementUniqueIdentifier
    );
    if (!setLossesNotRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set historical losses as not required for this agreement",
        apiName: "HistoricalLossesNotRequiredForAgreement",
        responseUnsuccessful: setLossesNotRequiredResponse?.unsuccessful,
        responseMessage: setLossesNotRequiredResponse?.message,
      });
    }
    return setLossesNotRequiredResponse?.successful;
  }

  async setHistoricalLossesRequiredForAgreement() {
    const { agreementUniqueIdentifier } = this.state;
    const setLossesRequiredResponse = await AgreementAPI.HistoricalLossesRequiredForAgreement(
      agreementUniqueIdentifier
    );
    if (!setLossesRequiredResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set historical losses as required for this agreement",
        apiName: "HistoricalLossesRequiredForAgreement",
        responseUnsuccessful: setLossesRequiredResponse?.unsuccessful,
        responseMessage: setLossesRequiredResponse?.message,
      });
    }
    return setLossesRequiredResponse?.successful;
  }

  async signAgreement12A() {
    const signatureDate =
      this.state.form12ASignatureDate === "" ? new Date().toLocaleDateString("en-CA") : this.state.form12ASignatureDate;
    const expirationDate = this.addYearsToDate(new Date(signatureDate), 5).toLocaleDateString("en-CA");

    let data = new FormData();
    data.append("file12A", this.state.uploaded12ADocument, this.state.uploaded12ADocument.name);
    const agreementSignatureResponse = await AgreementAPI.SignAgreement12a(
      this.state.agreementUniqueIdentifier,
      expirationDate,
      signatureDate,
      data
    );

    let success = this.isSuccessfulAPIResponse(agreementSignatureResponse);
    return { success: success, agreementSignatureResponse: agreementSignatureResponse };
  }

  async uploadOtherAgreementAttachments(agreementGuid) {
    let formData = new FormData();

    this.state.uploadedOtherDocuments.forEach((file) => {
      formData.append("files", file, file?.name);
    });
    const response = await AgreementAPI.UploadOtherAgreementAttachments(agreementGuid, formData);
    if (response?.successful) {
      return true;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "There was a problem attaching other Agreement documents",
        apiName: "UploadOtherAgreementAttachments",
        responseUnsuccessful: response?.unsuccessful,
        responseMessage: response?.message,
      });
    }
  }

  filterMethodsByNew() {
    const { methods } = this.state;
    return methods?.filter((m) => m.isNewMethod) ?? [];
  }

  mapMethodsToAgreementCallBodies(methodsToBeAddedToProperties, methodsToBeAddedToParcels, agreementObject) {
    this.filterMethodsByNew().forEach((method) => {
      const propertiesWithAllParcelsSelected = method.checkedProperties;
      const propertiesWithoutAllParcelsSelected = method.checkedProperties.filter(
        (property) => !propertiesWithAllParcelsSelected.includes(property)
      );

      if (propertiesWithAllParcelsSelected.length > 0) {
        const filteredProperties = propertiesWithAllParcelsSelected.filter(
          (p) =>
            !agreementObject.data?.agreementProperties?.some(
              (exixtingProperty) =>
                (exixtingProperty.propertyUniqueReference === p.propertyUniqueIdentifier ||
                  exixtingProperty.propertyUniqueReference === p.propertyUniqueReference) &&
                exixtingProperty.allowedMethods?.find((m) => m.methodUniqueReference === method.methodUniqueIdentifier)
            )
        );
        if (filteredProperties?.length > 0) {
          methodsToBeAddedToProperties.push({
            methodUniqueReference: method.methodUniqueIdentifier,
            addToProperties: filteredProperties.map((p) => ({
              propertyUniqueReference: p.propertyUniqueIdentifier
                ? p.propertyUniqueIdentifier
                : p.propertyUniqueReference,
            })),
          });
        }
      }

      if (propertiesWithoutAllParcelsSelected.length > 0) {
        let addToParcels = [];
        propertiesWithoutAllParcelsSelected.forEach((property) => {
          addToParcels = [
            ...addToParcels,
            ...property.parcels.map((p) => ({ parcelUniqueReference: p.parcelUniqueIdentifier })),
          ];
        });
        methodsToBeAddedToParcels.push({
          methodUniqueReference: method.methodUniqueIdentifier,
          addToParcels: addToParcels,
        });
      }
    });
  }

  filterSpeciesByNew() {
    const { species: existingSpecies } = this.state;
    return existingSpecies.filter((s) => s.isNewSpecies) ?? [];
  }

  mapSpeciesToAgreementCallBodies(speciesToBeAddedToProperties, speciesToBeAddedToParcels, agreementObject) {
    this.filterSpeciesByNew().forEach((species) => {
      const propertiesWithAllParcelsSelected = species.checkedProperties;
      const propertiesWithoutAllParcelsSelected = species.checkedProperties.filter(
        (property) => !propertiesWithAllParcelsSelected.includes(property)
      );

      if (propertiesWithAllParcelsSelected.length > 0) {
        const filteredProperties = propertiesWithAllParcelsSelected.filter(
          (p) =>
            !agreementObject.data?.agreementProperties?.some(
              (exixtingProperty) =>
                (exixtingProperty.propertyUniqueReference === p.propertyUniqueIdentifier ||
                  exixtingProperty.propertyUniqueReference === p.propertyUniqueReference) &&
                exixtingProperty.targetedSpecies?.find(
                  (s) => s.speciesUniqueReference === species.speciesUniqueIdentifier
                )
            )
        );
        if (filteredProperties?.length > 0) {
          speciesToBeAddedToProperties.push({
            speciesUniqueReference: species.speciesUniqueIdentifier,
            addToProperties: filteredProperties.map((p) => ({
              propertyUniqueReference: p.propertyUniqueIdentifier
                ? p.propertyUniqueIdentifier
                : p.propertyUniqueReference,
            })),
          });
        }
      }

      if (propertiesWithoutAllParcelsSelected.length > 0) {
        let addToParcels = [];
        propertiesWithoutAllParcelsSelected.forEach((property) => {
          addToParcels = [
            ...addToParcels,
            ...property.parcels.map((p) => ({ parcelUniqueReference: p.parcelUniqueIdentifier })),
          ];
        });
        speciesToBeAddedToParcels.push({
          speciesUniqueReference: species.speciesUniqueIdentifier,
          addToParcels: addToParcels,
        });
      }
    });
  }

  getResourcesToAgreementBody(allResources, agreementObject) {
    let newResourcesToBeAdded = [];
    let existingResourcesToBeUpdated = [];

    const currentYear = new Date().getFullYear();
    allResources.forEach((resource) => {
      if (resource.year === currentYear) {
        const exixtingResource = this.getExixtingProtectedResource(agreementObject, resource);
        if (!exixtingResource) {
          newResourcesToBeAdded.push(this.getNewResourceObject(resource));
        } else {
          existingResourcesToBeUpdated.push(this.getResourceToUpdateObject(exixtingResource, resource));
        }
      } else {
        const exixtingHistoricalResource = this.getExixtingHistoricalResource(agreementObject, resource);
        if (!exixtingHistoricalResource) {
          newResourcesToBeAdded.push(this.getNewResourceObject(resource));
        } else {
          existingResourcesToBeUpdated.push(this.getResourceToUpdateObject(exixtingHistoricalResource, resource));
        }
      }
    });

    return { newResourcesToBeAdded, existingResourcesToBeUpdated };
  }

  getRemoveResourcesBody(allResources, agreementObject) {
    let resourcesToBeRemoved = [];

    for (let property of agreementObject?.data?.agreementProperties) {
      property.agreementResources.forEach((existingResource) => {
        const updatedResource = allResources.find(
          (r) =>
            r.resourceUniqueIdentifier === existingResource?.resourceUniqueReference &&
            existingResource.valuationYear === r.year &&
            existingResource.valuePerUnitOfMeasure === r.unitValue &&
            (property.propertyUniqueReference === r.checkedProperties[0]?.propertyUniqueReference ||
              property.propertyUniqueReference === r.checkedProperties[0]?.propertyUniqueIdentifier)
        );

        if (!updatedResource) {
          resourcesToBeRemoved.push({
            agreementResourceUniqueIdentifier: existingResource.agreementResourceUniqueIdentifier,
          });
        }
      });
    }
    return resourcesToBeRemoved;
  }

  getExixtingProtectedResource(agreementObject, resource) {
    return agreementObject.data.agreementProperties
      ?.find(
        (exixtingProperty) =>
          exixtingProperty.propertyUniqueReference === resource.checkedProperties[0]?.propertyUniqueIdentifier ||
          exixtingProperty.propertyUniqueReference === resource.checkedProperties[0]?.propertyUniqueReference
      )
      ?.agreementResources?.find(
        (r) =>
          !r.isHistorical &&
          r.resourceUniqueReference === resource.resourceUniqueIdentifier &&
          r.valuePerUnitOfMeasure === resource.unitValue
      );
  }

  getExixtingHistoricalResource(agreementObject, resource) {
    return agreementObject.data.agreementProperties
      ?.find(
        (exixtingProperty) =>
          exixtingProperty.propertyUniqueReference === resource.checkedProperties[0]?.propertyUniqueIdentifier ||
          exixtingProperty.propertyUniqueReference === resource.checkedProperties[0]?.propertyUniqueReference
      )
      ?.agreementResources?.find(
        (r) =>
          r.isHistorical &&
          r.resourceUniqueReference === resource.resourceUniqueIdentifier &&
          r.valuationYear === resource.year &&
          r.valuePerUnitOfMeasure === resource.unitValue
      );
  }

  getNewResourceObject(resource) {
    return {
      resourceUniqueReference: resource.resourceUniqueIdentifier,
      unitOfMeasureEnumId: resource.unitOfMeasureId,
      currencyCodeEnumId: 0,
      valuationPerUnitOfMeasure: resource.unitValue ? resource.unitValue : null,
      quantity: resource.quantity,
      totalValuation: resource.totalValue ? resource.totalValue : null,
      valuationYear: resource.year,
      addToProperties: resource.checkedProperties.map((property) => ({
        propertyUniqueReference: property.propertyUniqueIdentifier
          ? property.propertyUniqueIdentifier
          : property.propertyUniqueReference,
      })),
    };
  }

  getResourceToUpdateObject(exixtingResource, resource) {
    return {
      agreementResourceUniqueIdentifier: exixtingResource.agreementResourceUniqueIdentifier,
      unitOfMeasureEnumId: resource.unitOfMeasureId,
      currencyCodeEnumId: 0,
      quantity: resource.quantity,
      valuationPerUnitOfMeasure: resource.unitValue,
      totalValuation: resource.totalValue,
      propertyUniqueReference:
        resource.checkedProperties[0]?.propertyUniqueIdentifier ||
        resource.checkedProperties[0]?.propertyUniqueReference,
    };
  }

  async getHistoricalLosses(historicalResourcesInventory, agreementObject) {
    let historicalLosses = [];
    const agreementResponse = agreementObject;

    if (agreementResponse?.successful && agreementResponse.data) {
      const agreementProperties = agreementResponse.data.agreementProperties;
      let agreementResources = [];
      agreementProperties.forEach((property) => {
        agreementResources = agreementResources.concat(property.agreementResources);
      });

      historicalResourcesInventory.forEach((resource) => {
        if (
          resource.historicalLosses?.length > 0 &&
          (resource.isNewResource || resource.historicalLosses.some((l) => l.isNewLoss))
        ) {
          let parcelsIdentifiers = [];
          if (resource?.checkedProperties[0]?.parcels?.length > 0) {
            let parcelsToCheck = resource?.checkedProperties[0]?.parcels;
            parcelsToCheck.forEach((parcel) => {
              if (parcel?.parcelUniqueIdentifier) {
                parcelsIdentifiers.push({
                  parcelUniqueReference: parcel.parcelUniqueIdentifier,
                });
              }
            });
          }

          const agreementResource = agreementResources.find(
            (r) => r.resourceUniqueReference === resource.resourceUniqueIdentifier && r.valuationYear === resource.year
          );
          if (agreementResource) {
            const historicalLossesToBeAdded = agreementResource.isNewResource
              ? resource.historicalLosses
              : resource.historicalLosses.filter((l) => l.isNewLoss);
            if (historicalLossesToBeAdded?.length > 0) {
              historicalLosses = historicalLosses.concat(
                historicalLossesToBeAdded.map((loss) => ({
                  agreementResourceUniqueIdentifier: agreementResource.agreementResourceUniqueIdentifier,
                  causingSpeciesUniqueReference: loss.species.value,
                  lossAssociatedWithParcels: parcelsIdentifiers,
                  lossQuantity: loss.quantity,
                  lossPercentage: loss.percentage,
                  lossTotalValuation: loss.totalValue,
                }))
              );
            }
          }
        }
      });
    }
    return historicalLosses;
  }

  getHistoricalLossesToRemove(agreementObject) {
    let historicalLosses = [];
    const { historicalResourcesInventory } = this.state;

    if (agreementObject.data?.agreementProperties?.length > 0) {
      let agreementResources = [];
      agreementObject.data.agreementProperties.forEach((property) => {
        agreementResources = agreementResources.concat(
          property.agreementResources.filter((r) => r.isHistorical && r.historicalLossRecords?.length > 0)
        );
      });

      for (let resource of agreementResources) {
        resource.historicalLossRecords.forEach((exixtingLoss) => {
          if (
            !historicalResourcesInventory.some((r) =>
              r.historicalLosses.find(
                (l) => l.historicalLossUniqueIdentifier === exixtingLoss.historicalLossUniqueIdentifier
              )
            )
          ) {
            historicalLosses.push({
              historicalLossUniqueReference: exixtingLoss.historicalLossUniqueIdentifier,
            });
          }
        });
      }
    }
    return historicalLosses;
  }

  handle12ASignatureDateChange = (signatureDate) => {
    this.setState({ form12ASignatureDate: signatureDate });
  };

  addYearsToDate(date, years) {
    return new Date(date.setFullYear(date.getFullYear() + years));
  }

  isSuccessfulAPIResponse(...responses) {
    let success = true;
    responses.forEach((response) => {
      if (!response || response.unsuccessful) success = false;
    });
    return success;
  }

  closeConflictingAgreementParcelModal = () => {
    this.setState({ showConflictingAgreementParcelModal: false, dismissedConflictingAgreementParcelModal: true });
  };

  render() {
    let currentStep = null;
    let agreementButtons = null;

    let step1Data = {
      pageHeader: this.getPageHeader(1),
      agreementNumber: this.state.agreementNumber,
      commonName: this.state.commonName,
      specialInstructions: this.state.specialInstructions,
      agreementTypeEnumId: this.state.agreementTypeEnumId,
      contactTypes: this.state.contactTypes,
      phoneNumberTypes: this.state.phoneNumberTypes,
      states: this.state.states,
      countries: this.state.countries,
      usaCountryId: this.state.usaCountryId,
      agreementTypes: this.state.agreementTypes,
      agreementContactRoles: this.state.agreementContactRoles,
      additionalContacts: this.state.additionalContacts,
      primaryCooperatorUniqueIdentifier: this.state.primaryCooperatorUniqueIdentifier,
      primaryCooperatorName: this.state.primaryCooperatorName,
      primaryCooperatorRoleName: this.state.primaryCooperatorRoleName,
      primaryFieldAgentName: this.state.primaryFieldAgentName,
      primaryFieldAgentUniqueIdentifier: this.state.primaryFieldAgentUniqueIdentifier,
      primaryFieldAgentEmail: this.state.primaryFieldAgentEmail,
      primaryFieldAgentPhoneNumber: this.state.primaryFieldAgentPhoneNumber,
      primaryFieldAgentMailingAddress: this.state.primaryFieldAgentMailingAddress,
      fieldAgentAdditionalContacts: this.state.fieldAgentAdditionalContacts,
      currentUsaState: this.state.currentUsaState,
      agreementProjects: this.state.agreementProjects,
    };
    let step2Data = {
      pageHeader: this.getPageHeader(2),
      agreementTypeEnumId: this.state.agreementTypeEnumId,
      agreementProperties: this.state.agreementProperties,
      primaryCooperatorUniqueIdentifier: this.state.primaryCooperatorUniqueIdentifier,
      primaryCooperatorName: this.state.primaryCooperatorName,
      primaryCooperatorStateUniqueIdentifier: this.state.primaryCooperatorStateUniqueIdentifier,
      primaryCooperatorCountryUniqueIdentifier: this.state.primaryCooperatorCountryUniqueIdentifier,
      primaryCooperatorStreet: this.state.primaryCooperatorStreet,
      primaryCooperatorCity: this.state.primaryCooperatorCity,
      primaryCooperatorZip: this.state.primaryCooperatorZip,
      currentUsaState: this.state.currentUsaState,
    };
    if (!this.state.initialMapComplete && this.state.step === 3 && this.state.additionalContacts.length > 0) {
      let data = this.state;
      data.additionalContacts.forEach((contact) => {
        if (contact.contactInheritedFrom === "Agreement") {
          contact.checkedProperties = data.agreementProperties;
        }
      });
      data.initialMapComplete = true;
      this.setState({ data });
    }
    let step3Data = {
      pageHeader: this.getPageHeader(3),
      agreementTypeEnumId: this.state.agreementTypeEnumId,
      contacts: this.state.additionalContacts,
      properties: this.state.agreementProperties,
      contactTypes: this.state.contactTypes,
      phoneNumberTypes: this.state.phoneNumberTypes,
      states: this.state.states,
      countries: this.state.countries,
      usaCountryId: this.state.usaCountryId,
      agreementContactRoles: this.state.agreementContactRoles,
      resourceUOMs: this.state.resourceUOMs,
      protectedResources: this.state.protectedResources,
      species: this.state.species,
      methods: this.state.methods,
      propertySpecialInstructions: this.state.propertySpecialInstructions,
      historicalResourcesInventory: this.state.historicalResourcesInventory,
      currentUsaState: this.state.currentUsaState,
      noProtectedResources: this.state.noProtectedResources,
      noHistoricalResources: this.state.noHistoricalResources,
      noLosses: this.state.noLosses,
      recommendations: this.state.recommendations,
      cooperatorEmployedMethods: this.state.cooperatorEmployedMethods,
      primaryCooperatorName: this.state.primaryCooperatorName,
      primaryCooperatorRoleEnumId: this.state.primaryCooperatorRoleEnumId,
    };
    let step4Data = {
      pageHeader: this.getPageHeader(4),
      agreementTypeEnumId: this.state.agreementTypeEnumId,
      primaryCooperatorName: this.state.primaryCooperatorName,
      primaryCooperatorUniqueIdentifier: this.state.primaryCooperatorUniqueIdentifier,
      primaryFieldAgentName: this.state.primaryFieldAgentName,
      primaryFieldAgentUniqueIdentifier: this.state.primaryFieldAgentUniqueIdentifier,
      primaryFieldAgentEmail: this.state.primaryFieldAgentEmail,
      primaryFieldAgentPhoneNumber: this.state.primaryFieldAgentPhoneNumber,
      primaryFieldAgentMailingAddress: this.state.primaryFieldAgentMailingAddress,
      agreementProperties: this.state.agreementProperties,
      protectedResources: this.state.protectedResources,
      species: this.state.species,
      methods: this.state.methods,
      historicalResourcesInventory: this.state.historicalResourcesInventory,
      contacts: this.state.additionalContacts,
      agreementTypes: this.state.agreementTypes,
      agreementNumber: this.state.agreementNumber,
      agreementCommonName: this.state.commonName,
      agreementSpecialInstructions: this.state.specialInstructions,
      specialInstructions: this.state.propertySpecialInstructions,
      agreementContactRoles: this.state.agreementContactRoles,
      states: this.state.states,
      recommendations: this.state.recommendations,
      cooperatorEmployedMethods: this.state.cooperatorEmployedMethods,
      agreementProjects: this.state.agreementProjects,
    };

    //We don't want anything to render until the async calls are done. If you're having issues with data not loading in any child components,
    // include a check for its validity in the below if statement.
    if (step1Data.agreementTypes?.length > 0) {
      switch (this.state.step) {
        case 1:
          currentStep = <Step1 passDataUp={this.setStateValue} step1Data={step1Data} />;
          agreementButtons = this.getStep1Buttons();
          break;
        case 2:
          currentStep = <Step2 passDataUp={this.setStateValue} step2Data={step2Data} />;
          agreementButtons = this.getMiddleStepsButtons();
          break;
        case 3:
          currentStep = <Step3 passDataUp={this.setStateValue} step3Data={step3Data} userData={this.props.userData} />;
          agreementButtons = this.getMiddleStepsButtons();
          break;
        case 4:
          currentStep = <Step4 passDataUp={this.setStateValue} step4Data={step4Data} />;
          agreementButtons = this.getStep4Buttons();
          break;
        default:
          currentStep = <Step1 passDataUp={this.setStateValue} step1Data={step1Data} />;
          break;
      }
    }

    return (
      <div className={styles.Agreement} data-testid="Agreement">
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text={this.state.formLoadingMessage}
          styles={{
            wrapper: (base) => ({
              ...base,
              height: "100%",
            }),
            overlay: (base) => ({
              ...base,
              width: "100%",
              height: "100%",
            }),
            content: (base) => ({
              ...base,
              position: "fixed",
              top: "70%",
              left: "49%",
            }),
            spinner: (base) => ({
              ...base,
              position: "fixed",
              top: "50%",
              left: "50%",
              width: "100px",
              marginBottom: "99%",
              "& svg circle": {
                stroke: "#007bff",
              },
            }),
          }}
        >
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmitWrapper}
            className={styles.agreementForm}
          >
            <Row>
              <Col md={2} className="ml-5 mt-3">
                {this.getNavigationStepper()}
              </Col>
              <Col>
                {currentStep}
                <div className={styles.Step1}>{agreementButtons}</div>
              </Col>
            </Row>
          </Form>
        </LoadingOverlay>
        <SuccessfulRecordModifiedModal
          show={this.state.showHappyModal}
          onHide={this.closeHappyModal}
          messageType={`${this.state.isExistingAgreement ? "update" : "create"}`}
          onAcknowledgeRoute="/agreement/details"
          routeData={{
            entityData: this.state.agreementObject,
            isEntityDataCompleted: true,
            states: this.state.statesData,
            countries: this.state.countries,
          }}
          objectName="Agreement"
        />
        <SuccessfulRecordModifiedModal
          show={this.state.showSaveModal}
          onHide={this.closeSaveModal}
          messageType="save"
          onAcknowledgeRoute="/home"
          objectName="Agreement"
        />
        <FileUploadModal
          show={this.state.show12AUploadModal}
          onHide={this.close12AModal}
          handleFileSelected={this.handleFileSelection}
          handleMultipleFilesSelected={this.handleMultipleFilesSelection}
          onSave={this.proceedWithAgreementSubmit}
          onSignatureDateChange={this.handle12ASignatureDateChange}
          removeFileFromOtherAttachments={this.removeFileFromOtherAttachments}
          uploadedOtherDocuments={this.state.uploadedOtherDocuments}
          isFLA={this.state.agreementTypeEnumId === 2}
        />
        <PrintAgreementModal
          show={this.state.showPrintAgreementModal}
          onHide={this.closePrintAgreementModal}
          isCurrentAgreement={true}
          agreementData={this.state.agreementData}
          cooperatorData={this.state.cooperatorData}
          primaryFieldAgentData={this.state.primaryFieldAgentData}
          propertiesData={this.state.propertiesData}
          associatedContactsData={this.state.associatedContactsData}
          states={this.state.states}
          countries={this.state.countries}
        />
        <ConflictingAgreementParcelModal
          show={this.state.showConflictingAgreementParcelModal}
          onHide={this.closeConflictingAgreementParcelModal}
          usedOnAgreementSignature={true}
          conflictingAgreementUniqueIdentifiers={this.state.conflictingAgreementUniqueIdentifiers}
        />
      </div>
    );
  }
}

export default withRouter(Agreement);
