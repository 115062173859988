import React from "react";
import styles from "./common.scss";

const Label = ({ name, label, value, ...rest }) => {
  return (
    <div className="form-group text-left">
      <label htmlFor={name} className="formLabel">
        {label}
      </label>
      <input
        {...rest}
        name={name}
        id={name}
        className="form-control-plaintext formData pb-2"
        value={value}
        readOnly
      />
    </div>
  );
};

export default Label;
