import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";

class CreateEditOrganizationModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: {
        externalOrganizationUniqueIdentifier: this.props.modalData?.externalOrganizationUniqueIdentifier,
        externalOrganizationName: this.props.modalData?.externalOrganizationName,
        globalStatus: true,
        isAllowedInState: this.props.modalData?.isAllowedInState,
        createdDateTimeUTC: this.props.modalData?.createdDateTimeUTC,
        lastModifiedDateTimeUTC: this.props.modalData?.lastModifiedDateTimeUTC,
      },
      errors: {},
    };
  }

  handleStatusChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isAllowedInState = input.id === "active" ? true : false;
    this.setState({ data });
  };

  submitModal = () => {
    this.props.onSubmit(this.state.data, this.state.modalMode);
    this.props.onHide();
  };

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  createOrganizationModalUI() {
    return (
      <div className={styles.createDamageModalBody}>
        <Row>
          <Col>
            {this.renderInput("externalOrganizationName", "Organization Name", "text", "Enter name", "required")}
          </Col>
        </Row>
      </div>
    );
  }

  editOrganizationModalUI() {
    return (
      <div className={styles.editDamageModalBody}>
        <Row>
          <Col className={styles.editDamageModalGuid}>
            {this.renderLabel(
              "externalOrganizationUniqueIdentifier",
              "ID",
              this.state.data.externalOrganizationUniqueIdentifier
            )}
          </Col>
        </Row>
        <Row>
          <Col className={styles.editDamageModalDamageName}>
            {this.renderInput("externalOrganizationName", "Organization Name", "text", "Enter name", "required")}
          </Col>
        </Row>
        <Row>
          <Col className={styles.editDamageModalStatus}>
            <Form.Label className={globalStyles.formLabel}>Status</Form.Label>
            <Form.Group>
              <Form.Check
                type="radio"
                name="stateStatus"
                inline
                label="Active"
                id="active"
                onChange={this.handleStatusChange}
                defaultChecked={this.state.data.isAllowedInState}
              />
              <Form.Check
                type="radio"
                name="stateStatus"
                inline
                label="Inactive"
                id="inactive"
                onChange={this.handleStatusChange}
                defaultChecked={!this.state.data.isAllowedInState}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editDamageModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editDamageModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createOrganizationModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editOrganizationModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} Organization</Modal.Title>
          </Modal.Header>
          <Modal.Body>{bodyContent}</Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button type="submit" className={globalStyles.ModalCreateButton} onClick={this.submitModal}>
              <span className={globalStyles.ModalCreateButtonText}>{submitButtonText}</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CreateEditOrganizationModal;
