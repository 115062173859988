import React from "react";
import styles from "./EditModalPages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";

class ConfigureFateModal extends CustomForm {
  constructor(props) {
    super(props);
    if (!this.props.modalData) {
      this.props.onHide();
    }
    this.state = {
      validated: false,
      data: this.props.modalData,
      errors: {},
    };
  }

  async doSubmit(e) {
    let form = e.currentTarget;
    if (form.checkValidity()) {
      this.setState({ validated: true });
      this.props.onSubmit(this.state.data);
    }
  }

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Modal.Header className={globalStyles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>Configure Fate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={styles.configureModalBody}>
                <Row>
                  <Col className={styles.configureModalItem}>
                    <label htmlFor="ConfigureFateModalGuid" className={globalStyles.modalFormLabel}>
                      ID
                    </label>
                    <p id="ConfigureFateModalGuid">{this.state.data.fateUniqueIdentifier}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalItem}>
                    <label htmlFor="ConfigureFateModalName" className={globalStyles.modalFormLabel}>
                      Fate
                    </label>
                    <p id="ConfigureFateModalName">{this.state.data.name}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalItem}>
                    <label htmlFor="ConfigureFateModalLastModified" className={globalStyles.modalFormLabel}>
                      Last Modified
                    </label>
                    <p id="ConfigureFateModalLastModified">
                      {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.configureModalAllowed}>
                    {this.renderInlineCheckBoxSet(
                      "isAllowed",
                      "Allowed",
                      ["Yes", "No"],
                      "radio",
                      this.state.data.isAllowedInState ? "Yes" : "No"
                    )}
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className={globalStyles.modalFooter}>
              <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <Button type="submit" className={globalStyles.modalSubmitButton}>
                <span className={globalStyles.modalSubmitButtonText}>Save</span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ConfigureFateModal;
