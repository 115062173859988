import React from "react";
import CustomForm from "../common/form";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { withRouter } from "react-router";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import styles from "./dashboard.module.scss";
import globalStyles from "../../OARS.module.scss";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import searchIcon from "../../assets/search.svg";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import UtilityFunctions from "../common/UtilityFunctions";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import WorkTaskAPI from "../../api/WorkTask/WorkTaskAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const SearchTypes = {
  AdminWorkTask: "adminWorkTask",
  Aerials: "aerials",
  Agreements: "agreement",
  Contacts: "contact",
  Permits: "permit",
  Project: "project",
  Property: "property",
  Samples: "sample",
  SiteVisit: "siteVisit",
  TAWorkTask: "taWorkTask",
  District: "district",
};

const searchTypesSelectOptions = [
  { value: "adminWorkTask", label: "Admin Work Task" },
  { value: "aerials", label: "Aerial Work Task" },
  { value: "agreement", label: "Agreements" },
  { value: "contact", label: "Contacts" },
  { value: "permit", label: "Permits" },
  { value: "project", label: "Projects" },
  { value: "property", label: "Property" },
  { value: "sample", label: "Samples" },
  { value: "siteVisit", label: "Site Visit" },
  { value: "taWorkTask", label: "TA Work Task" },
  { value: "district", label: "Districts" },
];

const SampleTypes = {
  NRMP: "nrmp",
  DiseaseMonitoring: "diseaseMonitoring",
  Other: "other",
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={searchIcon} alt="" />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

class StructuredSearchCard extends CustomForm {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      states: props.states,
      currentState: props.currentState,
      data: {
        selectedSearchType: "",
        propertyName: "",
        propertyStreet: "",
        propertyCity: "",
        propertyZip: "",
        propertyAgreementNumber: "",
        propertyCounty: "",
        siteVisitEnteredStartDate: "",
        siteVisitEnteredEndDate: "",
        datePerformedStartDate: "",
        datePerformedEndDate: "",
        siteVisitPropertyName: "",
        siteVisitCounty: "",
        siteVisitPerformedBy: "",
        siteVisitTakesRecorded: "",
        siteVisitSamplesTaken: "",
        taWorkTaskEnteredStartDate: "",
        taWorkTaskEnteredEndDate: "",
        taWorkTaskDatePerformedStart: "",
        taWorkTaskDatePerformedEnd: "",
        taWorkTaskCounty: "",
        taWorkTaskType: "",
        taWorkTaskTopic: "",
        taWorkTaskPerformedBy: "",
        aerialEnteredDateStart: "",
        aerialEnteredDateEnd: "",
        aerialPropertyName: "",
        aerialAircraftNumber: "",
        aerialCountyName: "",
        aerialPilot: "",
        aerialCrewMember: "",
        agreementName: "",
        agreementNumber: "",
        agreementCounty: "",
        agreementPropertyAddress: "",
        agreementPropertyName: "",
        agreementStatus: "",
        agreementPrimaryWsSpecialist: "",
        agreementFirstNameOfCooperator: "",
        agreementLastNameOfCooperator: "",
        adminWorkTaskDistrict: "",
        adminWorkTaskPerformedBy: "",
        adminWorkTaskEnteredDate: "",
        adminWorkTaskDatePerformedStart: "",
        adminWorkTaskDatePerformedEnd: "",
        contactFirstName: "",
        contactLastName: "",
        contactEmail: "",
        contactPhoneNumber: "",
        contactStreetAddress: "",
        contactCity: "",
        contactState: "",
        contactZip: "",
        permitName: "",
        permitNumber: "",
        permitSpecies: "",
        permitIsValid: "",
        sampleType: "",
        sampleDateTaken: "",
        sampleDateStart: "",
        sampleDateEnd: "",
        sampleProfile: "",
        sampleSpecies: "",
        sampleIsFromTake: "",
        sampleDisease: "",
        sampleProperty: "",
        sampleCounty: "",
        sampleTownOrCity: "",
        diseaseSampleType: "",
        subjectId: "",
        waypointNumber: "",
        animalId: "",
        collectionActivityId: "",
        projectName: "",
        districtName: "",
      },
      availableTopics: [],
      availableCounties: [],
      agreementStatuses: [],
      workTaskTypes: [],
      sampleTypes: [],
      availableDiseases: [],
      diseaseSampleTypes: [],
      availableCollectionActivities: [],
      isInitialConfiguration: true,
      errors: {},
    };
  }

  async componentDidMount() {
    const data = { ...this.state.data };

    if (this.props.currentState?.stateGuid) {
      await this.updateDefaultStateValues(data);
    }
    if (this.controller.signal.aborted) {
      return;
    }

    this.setState({
      workTaskTypes: this.getWorkTaskTypes(),
      sampleTypes: this.getSampleTypes(),
      data,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    let countiesListRequiresUpdate = false;
    let availableDiseasesRequiresUpdate = false;
    let agreementStatusesRequiresUpdate = false;
    let availableTopicsRequiresUpdate = false;
    let diseaseSampleTypesRequiresUpdate = false;
    let availableCollectionActivitiesRequiresUpdate = false;

    if (prevProps.entityType !== this.props.entityType) {
      if (this.isCountiesListUpdateRequired(prevProps)) {
        countiesListRequiresUpdate = true;
      }
      if (this.props.entityType === "sample") {
        availableDiseasesRequiresUpdate = true;
        diseaseSampleTypesRequiresUpdate = true;
        availableCollectionActivitiesRequiresUpdate = true;
      }
      if (this.props.entityType === "agreement") {
        agreementStatusesRequiresUpdate = true;
      }
      if (this.props.entityType === "taWorkTask") {
        availableTopicsRequiresUpdate = true;
      }

      if (prevProps.searchData !== this.props.searchData) {
        data.selectedSearchType = searchTypesSelectOptions.find((t) => t.value === this.props.entityType);
        if (this.props.searchData) {
          const { searchData } = this.props;
          const searchDataKeys = Object.keys(searchData);
          if (searchDataKeys?.length > 0) {
            searchDataKeys.forEach((key) => {
              data[key] = searchData[key];
            });
          }
        }
        if (this.controller.signal.aborted) {
          return;
        }
        this.setState({ data, isInitialConfiguration: false });
      }
    }

    if (prevProps.currentState !== this.props.currentState || prevState.currentState !== this.state.currentState) {
      await this.updateDefaultStateValues(data);
      if (this.isCountiesListUpdateRequired(prevProps)) {
        countiesListRequiresUpdate = true;
      }
      if (this.props.entityType === "sample") {
        availableDiseasesRequiresUpdate = true;
        diseaseSampleTypesRequiresUpdate = true;
        availableCollectionActivitiesRequiresUpdate = true;
      }
      if (this.props.entityType === "agreement") {
        agreementStatusesRequiresUpdate = true;
      }
      if (this.props.entityType === "taWorkTask") {
        availableTopicsRequiresUpdate = true;
      }

      this.setState({
        data,
      });
    }

    if (
      countiesListRequiresUpdate ||
      availableDiseasesRequiresUpdate ||
      agreementStatusesRequiresUpdate ||
      availableTopicsRequiresUpdate ||
      diseaseSampleTypesRequiresUpdate ||
      availableCollectionActivitiesRequiresUpdate
    ) {
      let {
        availableCounties,
        availableDiseases,
        agreementStatuses,
        availableTopics,
        diseaseSampleTypes,
        availableCollectionActivities,
      } = this.state;

      if (countiesListRequiresUpdate) {
        availableCounties = await this.getCountyListbyState(this.props.currentState?.stateGuid);
      }
      if (availableDiseasesRequiresUpdate) {
        availableDiseases = await this.getAvailableDiseases();
      }
      if (agreementStatusesRequiresUpdate) {
        agreementStatuses = await this.getAllAgreementStatuses();
      }
      if (availableTopicsRequiresUpdate) {
        availableTopics = await this.getTopicsForState(this.props.currentState?.stateGuid);
      }
      if (diseaseSampleTypesRequiresUpdate) {
        diseaseSampleTypes = await this.getDiseaseSampleTypesFromAPI();
      }
      if (availableCollectionActivitiesRequiresUpdate) {
        availableCollectionActivities = await this.getCollectionActivitiesFromAPI();
      }

      this.setState({
        availableCounties,
        availableDiseases,
        agreementStatuses,
        availableTopics,
        diseaseSampleTypes,
        availableCollectionActivities,
      });
    }
  }

  isCountiesListUpdateRequired(prevProps) {
    const entitiesRequiringCounties = ["property", "siteVisit", "aerials", "agreement", "sample"];
    return (
      entitiesRequiringCounties.includes(this.props.entityType) &&
      !entitiesRequiringCounties.includes(prevProps.entityType)
    );
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("StructuredSearchCard.jsx got unmounted");
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      states: nextProps.states,
      currentState: nextProps.currentState,
    };
  }

  async getAllAgreementStatuses() {
    let agreementStatuses = [];
    const agreementStatusesResponse = await AgreementAPI.GetAllAgreementStatuses();
    if (agreementStatusesResponse?.successful && agreementStatusesResponse.data?.length > 0) {
      agreementStatuses = agreementStatusesResponse.data;
    }
    return agreementStatuses;
  }

  async getAvailableDiseases() {
    let diseasesData = [];
    const diseasesDataResponse = await ReferenceFileAPI.GetDiseasesForState(this.props.currentState.stateGuid);
    if (diseasesDataResponse?.successful && diseasesDataResponse.data?.results?.length > 0) {
      diseasesData = diseasesDataResponse.data.results.map((d) => ({
        id: d.diseaseUniqueIdentifier,
        name: d.name,
      }));
    }
    return diseasesData;
  }

  async getTopicsForState(stateId) {
    let topicsForState = [];
    if (stateId) {
      const topicsDataResponse = await ReferenceFileAPI.GetTopicsForState(stateId);
      if (topicsDataResponse?.successful && topicsDataResponse.data?.results?.length > 0) {
        topicsForState = topicsDataResponse.data.results
          .filter((t) => t.isAllowedGlobally)
          .map((topic) => ({
            id: topic.topicUniqueIdentifier,
            name: topic.name,
          }));
      }
    }
    return topicsForState;
  }

  async updateDefaultStateValues(data) {
    data.permitIsValid = true;
    data.sampleIsFromTake = true;
    data.siteVisitTakesRecorded = true;
  }

  getWorkTaskTypes() {
    return [
      { id: "hotline", name: "Hotline" },
      { id: "publicOutreach", name: "Public Outreach" },
      { id: "individualCooperator", name: "Individual Cooperator" },
    ];
  }

  getSampleTypes() {
    return [
      { id: "nrmp", name: "NRMP" },
      { id: "diseaseMonitoring", name: "Disease Monitoring" },
      { id: "other", name: "Other" },
    ];
  }

  async getDiseaseSampleTypesFromAPI() {
    let sampleTypeOptions = [];
    const sampleTypeOptionsResponse = await WorkTaskAPI.GetSampleOptions();
    if (sampleTypeOptionsResponse?.successful) {
      sampleTypeOptions = sampleTypeOptionsResponse.data?.map((result) => ({
        id: result.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(result),
      }));
    } else {
      ErrorHandler.showErrorWithMessage(
        sampleTypeOptionsResponse?.message
          ? sampleTypeOptionsResponse.message
          : "Could not retrieve Disease Sample Types."
      );
    }
    return sampleTypeOptions;
  }

  async getCollectionActivitiesFromAPI() {
    let availableCollectionActivities = [];
    const collectionActivitiesResponse = await WorkTaskAPI.GetCollectionActivityOptions();
    if (collectionActivitiesResponse?.successful) {
      availableCollectionActivities = collectionActivitiesResponse.data?.map((result) => ({
        id: result.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(result),
      }));
    } else {
      ErrorHandler.showErrorWithMessage(
        collectionActivitiesResponse?.message
          ? collectionActivitiesResponse.message
          : "Could not retrieve Collection Activities."
      );
    }
    return availableCollectionActivities;
  }

  async getCountyListbyState(stateGuid) {
    let countyList = [];
    if (stateGuid) {
      const countyResults = await ReferenceFileAPI.GetCountiesByState(stateGuid);
      if (countyResults?.length > 0) {
        countyList = countyResults
          .map((county) => ({
            value: county.countyUniqueIdentifier,
            label: county.county_name,
          }))
          .sort((a, b) => (a.name < b.name ? -1 : 1));
      }
    }

    return countyList;
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({
      ...styles,
      fontStyle: "normal !important",
      fontSize: "14px",
      fontWeight: "600 !important",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
    menuList: (styles) => ({ ...styles, textAlign: "left" }),
  };

  loadAvailableUserProfiles = async (inputValue) => {
    let outputContacts = [];
    const contactsDataResponse = await RoleAuthorizationAPI.SearchUsersFreeText(this.getSearchUsersBody(inputValue));
    if (contactsDataResponse?.successful && contactsDataResponse.data?.results?.length > 0) {
      let results = contactsDataResponse.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    return outputContacts;
  };

  loadAvailableUsers = async (inputValue) => {
    let usersData = [];
    const usersDataResponse = await RoleAuthorizationAPI.SearchUsersFreeText(this.getSearchUsersBody(inputValue));
    if (usersDataResponse?.successful && usersDataResponse.data?.results?.length > 0) {
      usersData = usersDataResponse.data.results.map((u) => ({
        value: u.wsUserUniqueIdentifier,
        label: u.firstName + " " + u.lastName,
        ...u,
      }));
    }
    return usersData;
  };

  loadAvailableProfiles = async (inputValue) => {
    let profilessData = [];
    const usersData = await this.loadAvailableUsers(inputValue);
    if (usersData?.length > 0) {
      usersData.forEach((user) => {
        if (user.userProfiles?.length > 0) {
          profilessData = [
            ...profilessData,
            ...user.userProfiles.map((p) => ({
              value: p.wsUserProfileUniqueIdentifier,
              label: `${user.firstName + " " + user.lastName} - ${p.profileName}`,
            })),
          ];
        }
      });
    }
    return profilessData;
  };

  loadAvailableSpecies = async (inputValue) => {
    let speciesData = [];
    const speciesDataResponse = await ReferenceFileAPI.SpeciesStructuredSearch(this.getSearchSpeciesBody(inputValue));
    if (speciesDataResponse?.successful && speciesDataResponse.data?.results?.length > 0) {
      speciesData = speciesDataResponse.data?.results.map((s) => ({
        value: s.speciesUniqueIdentifier,
        label: s.name,
      }));
    }
    return speciesData;
  };

  loadAvailableProperties = async (inputValue) => {
    let propertiesData = [];
    const propertiesDataResponse = await AgreementEntitiesAPI.PropertiesStructuredSearch(
      this.getSearchPropertiesBody(inputValue)
    );
    if (propertiesDataResponse?.successful && propertiesDataResponse.data?.results?.length > 0) {
      propertiesData = propertiesDataResponse.data?.results.map((s) => ({
        value: s.propertyUniqueIdentifier,
        label: s.propertyName,
      }));
    }
    return propertiesData;
  };

  handleSearchTypeSelect = (input) => {
    const { data, errors } = this.state;
    data.selectedSearchType = input;

    delete errors.selectedSearchType;
    this.setState({ data, errors, isInitialConfiguration: false });
    this.props.onEntityTypeChange(input.value);
  };

  handleSelectChanges = (input, fieldName) => {
    const { data } = this.state;
    data[fieldName] = input;
    this.setState({ data });
  };

  handleSearch = () => {
    const { data } = this.state;

    if (!data.selectedSearchType) {
      const errors = { ...this.state.errors };
      errors.selectedSearchType = "Please select a search type.";
      this.setState({ errors });
    } else if (data.selectedSearchType?.value === SearchTypes.TAWorkTask && !data.taWorkTaskType) {
      const errors = { ...this.state.errors };
      errors.taWorkTaskType = "Please select a type.";
      this.setState({ errors });
    } else if (
      data.selectedSearchType?.value === SearchTypes.SiteVisit &&
      (data.siteVisitTakesRecorded === "" || data.siteVisitTakesRecorded === undefined)
    ) {
      const errors = { ...this.state.errors };
      errors.siteVisitTakesRecorded = "Please select a value.";
      this.setState({ errors });
    } else if (data.selectedSearchType?.value === SearchTypes.Samples && !data.sampleType) {
      const errors = { ...this.state.errors };
      errors.sampleType = "Please select a type.";
      this.setState({ errors });
    } else {
      this.props.onSearch(data.selectedSearchType?.value, this.getSearchData());
    }
  };

  getSearchData() {
    let searchData;

    switch (this.state.data.selectedSearchType?.value) {
      case SearchTypes.Property:
        searchData = this.getSearchDataForProperty();
        break;
      case SearchTypes.SiteVisit:
        searchData = this.getSearchDataForSiteVisit();
        break;
      case SearchTypes.TAWorkTask:
        searchData = this.getSearchDataForTAWorkTask();
        break;
      case SearchTypes.Aerials:
        searchData = this.getSearchDatForAerialWorkTask();
        break;
      case SearchTypes.AdminWorkTask:
        searchData = this.getSearchDataForAdminWorkTask();
        break;
      case SearchTypes.Agreements:
        searchData = this.getSearchDataForAgreement();
        break;
      case SearchTypes.Contacts:
        searchData = this.getSearchDataForContacts();
        break;
      case SearchTypes.Permits:
        searchData = this.getSearchDataForPermits();
        break;
      case SearchTypes.Samples:
        searchData = this.getSearchDataForSamples();
        break;
      case SearchTypes.Project:
        searchData = this.getSearchDataForProjects();
        break;
      case SearchTypes.District:
        searchData = this.getSearchDataForDistricts();
        break;
      default:
        break;
    }

    return searchData;
  }

  getSearchDataForSamples() {
    const {
      sampleType,
      sampleDateTaken,
      sampleDateStart,
      sampleDateEnd,
      sampleProfile,
      sampleSpecies,
      sampleIsFromTake,
      sampleDisease,
      sampleProperty,
      sampleCounty,
      sampleTownOrCity,
      diseaseSampleType,
      subjectId,
      waypointNumber,
      animalId,
      collectionActivityId,
    } = this.state.data;
    return {
      sampleType,
      sampleDateTaken,
      sampleDateStart,
      sampleDateEnd,
      sampleProfile,
      sampleSpecies,
      sampleIsFromTake,
      sampleDisease,
      sampleProperty,
      sampleCounty,
      sampleTownOrCity,
      diseaseSampleType,
      subjectId,
      waypointNumber,
      animalId,
      collectionActivityId,
    };
  }

  getSearchDataForPermits() {
    const { permitName, permitNumber, permitSpecies, permitIsValid } = this.state.data;
    return { permitName, permitNumber, permitSpecies, permitIsValid };
  }

  getSearchDataForContacts() {
    const {
      contactFirstName,
      contactLastName,
      contactPhoneNumber,
      contactEmail,
      contactStreetAddress,
      contactCity,
      contactState,
      contactZip,
    } = this.state.data;
    return {
      contactFirstName,
      contactLastName,
      contactPhoneNumber,
      contactEmail,
      contactStreetAddress,
      contactCity,
      contactState,
      contactZip,
    };
  }

  getSearchDataForAgreement() {
    const {
      agreementName,
      agreementNumber,
      agreementCounty,
      agreementPropertyAddress,
      agreementPrimaryWsSpecialist,
      agreementPropertyName,
      agreementStatus,
      agreementFirstNameOfCooperator,
      agreementLastNameOfCooperator,
    } = this.state.data;
    return {
      agreementName,
      agreementNumber,
      agreementCounty,
      agreementPropertyAddress,
      agreementPropertyName,
      agreementStatus,
      agreementPrimaryWsSpecialist,
      agreementFirstNameOfCooperator,
      agreementLastNameOfCooperator,
    };
  }

  getSearchDataForAdminWorkTask() {
    const {
      adminWorkTaskDistrict,
      adminWorkTaskPerformedBy,
      adminWorkTaskEnteredDate,
      adminWorkTaskDatePerformedStart,
      adminWorkTaskDatePerformedEnd,
    } = this.state.data;
    return {
      adminWorkTaskDistrict,
      adminWorkTaskPerformedBy,
      adminWorkTaskEnteredDate,
      adminWorkTaskDatePerformedStart,
      adminWorkTaskDatePerformedEnd,
    };
  }

  getSearchDatForAerialWorkTask() {
    const {
      aerialEnteredDateStart,
      aerialEnteredDateEnd,
      aerialPropertyName,
      aerialAircraftNumber,
      aerialCountyName,
      aerialPilot,
      aerialCrewMember,
    } = this.state.data;
    return {
      aerialEnteredDateStart,
      aerialEnteredDateEnd,
      aerialPropertyName,
      aerialAircraftNumber,
      aerialCountyName,
      aerialPilot,
      aerialCrewMember,
    };
  }

  getSearchDataForTAWorkTask() {
    const {
      taWorkTaskEnteredStartDate,
      taWorkTaskEnteredEndDate,
      taWorkTaskType,
      taWorkTaskTopic,
      taWorkTaskPerformedBy,
      taWorkTaskCounty,
      taWorkTaskDatePerformedStart,
      taWorkTaskDatePerformedEnd,
    } = this.state.data;
    return {
      taWorkTaskEnteredStartDate,
      taWorkTaskEnteredEndDate,
      taWorkTaskType,
      taWorkTaskTopic,
      taWorkTaskPerformedBy,
      taWorkTaskCounty,
      taWorkTaskDatePerformedStart,
      taWorkTaskDatePerformedEnd,
    };
  }

  getSearchDataForSiteVisit() {
    const {
      siteVisitEnteredStartDate,
      siteVisitEnteredEndDate,
      datePerformedStartDate,
      datePerformedEndDate,
      siteVisitPropertyName,
      siteVisitCounty,
      siteVisitPerformedBy,
      siteVisitTakesRecorded,
      siteVisitSamplesTaken,
    } = this.state.data;
    return {
      siteVisitEnteredStartDate,
      siteVisitEnteredEndDate,
      datePerformedStartDate,
      datePerformedEndDate,
      siteVisitPropertyName,
      siteVisitCounty,
      siteVisitPerformedBy,
      siteVisitTakesRecorded,
      siteVisitSamplesTaken,
    };
  }

  getSearchDataForProperty() {
    const { propertyName, propertyStreet, propertyCity, propertyZip, propertyAgreementNumber } = this.state.data;
    return {
      propertyName,
      propertyStreet,
      propertyCity,
      propertyZip,
      propertyAgreementNumber,
    };
  }

  getSearchDataForProjects() {
    const { projectName } = this.state.data;
    return {
      projectName,
    };
  }
  getSearchDataForDistricts() {
    const { districtName } = this.state.data;
    return {
      districtName,
    };
  }
  getSearchUsersBody(inputValue) {
    return {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentState?.stateGuid,
    };
  }

  getSearchSpeciesBody(inputValue) {
    const currentStateId = this.state.currentState?.stateGuid
      ? this.state.currentState.stateGuid
      : JSON.parse(localStorage.getItem("currentState"))?.stateGuid;
    return {
      stateUniqueReference: currentStateId,
      class: "",
      order: "",
      family: "",
      subType: "",
      name: inputValue,
    };
  }

  getSearchPropertiesBody(inputValue) {
    const currentStateId = this.state.currentState?.stateGuid
      ? this.state.currentState.stateGuid
      : JSON.parse(localStorage.getItem("currentState"))?.stateGuid;
    return {
      stateUniqueReference: currentStateId,
      propertyName: inputValue,
      street: "",
      city: "",
      zip: "",
    };
  }

  async handleSpecificChanges(input) {
    //do nothing
  }

  renderSearchFields() {
    let searchFieldsBody;

    switch (this.state.data.selectedSearchType?.value) {
      case SearchTypes.Property:
        searchFieldsBody = this.renderPropertySearchFields();
        break;
      case SearchTypes.SiteVisit:
        searchFieldsBody = this.renderSiteVisitSearchFields();
        break;
      case SearchTypes.TAWorkTask:
        searchFieldsBody = this.renderTaWorkTaskSearchFields();
        break;
      case SearchTypes.Aerials:
        searchFieldsBody = this.renderAerialSearchFields();
        break;
      case SearchTypes.Agreements:
        searchFieldsBody = this.renderAgreementSearchFields();
        break;
      case SearchTypes.AdminWorkTask:
        searchFieldsBody = this.renderAdminWorkTaskSearchFields();
        break;
      case SearchTypes.Contacts:
        searchFieldsBody = this.renderContactSearchFields();
        break;
      case SearchTypes.Permits:
        searchFieldsBody = this.renderPermitSearchFields();
        break;
      case SearchTypes.Samples:
        searchFieldsBody = this.renderSampleSearchFields();
        break;
      case SearchTypes.Project:
        searchFieldsBody = this.renderProjectSearchFields();
        break;
      case SearchTypes.District:
        searchFieldsBody = this.renderDistrictSearchFields();
        break;
      default:
        searchFieldsBody = null;
    }

    return searchFieldsBody;
  }

  renderPropertySearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("propertyName", "Property Name", "text", "Enter")}</Col>
          <Col>{this.renderInput("propertyStreet", "Street", "text", "Enter")}</Col>
          <Col>{this.renderInput("propertyCity", "City", "text", "Enter")}</Col>
          <Col>{this.renderInput("propertyZip", "ZIP Code", "text", "Enter")}</Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>County</Form.Label>
              <Select
                aria-label="County Search"
                value={this.state.data.propertyCounty}
                openMenuOnClick={true}
                placeholder="Select"
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                options={this.state.availableCounties}
                onChange={(e) => this.handleSelectChanges(e, "propertyCounty")}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderSiteVisitSearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("siteVisitEnteredStartDate", "Date Entered Start", "date", "Enter")}</Col>
          <Col>{this.renderInput("siteVisitEnteredEndDate", "Date Entered End", "date", "Enter")}</Col>
          <Col>{this.renderInput("datePerformedStartDate", "Date Performed Start", "date", "Enter")}</Col>
          <Col>{this.renderInput("datePerformedEndDate", "Date Performed End", "date", "Enter")}</Col>
        </Row>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("siteVisitPropertyName", "Property Name", "text", "Enter")}</Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>County</Form.Label>
              <Select
                aria-label="County Search"
                value={this.state.data.siteVisitCounty}
                openMenuOnClick={true}
                placeholder="Select"
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                options={this.state.availableCounties}
                onChange={(e) => this.handleSelectChanges(e, "siteVisitCounty")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Performed By</Form.Label>
              <AsyncSelect
                aria-label="Site Visit Performed By Search"
                value={this.state.data.siteVisitPerformedBy}
                openMenuOnClick={false}
                placeholder="Search"
                components={{ DropdownIndicator, Option }}
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.loadAvailableUserProfiles}
                onChange={(e) => this.handleSelectChanges(e, "siteVisitPerformedBy")}
              />
            </Form.Group>
          </Col>
          <Col>
            {this.renderSelect(
              "siteVisitTakesRecorded",
              "Takes Recorded",
              [
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ],
              "Select"
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderTaWorkTaskSearchFields() {
    return (
      <React.Fragment>
        <Row className="text-left pr-3">
          <Col lg={3}>{this.renderSelect("taWorkTaskType", "Work Task Type", this.state.workTaskTypes, "Select")}</Col>
          <Col lg={3}>{this.renderInput("taWorkTaskCounty", "County", "text", "Enter")}</Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Performed By</Form.Label>
              <AsyncSelect
                aria-label="TA Work Task Performed By Search"
                value={this.state.data.taWorkTaskPerformedBy}
                openMenuOnClick={false}
                placeholder="Search"
                components={{ DropdownIndicator, Option }}
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.loadAvailableUserProfiles}
                onChange={(e) => this.handleSelectChanges(e, "taWorkTaskPerformedBy")}
              />
            </Form.Group>
          </Col>
          <Col lg={3}>{this.renderSelect("taWorkTaskTopic", "Topic", this.state.availableTopics, "Select")}</Col>
        </Row>
        <Row className="text-left pr-3">
          <Col lg={3}>{this.renderInput("taWorkTaskEnteredStartDate", "Created Date Start", "date", "Enter")}</Col>
          <Col lg={3}>{this.renderInput("taWorkTaskEnteredEndDate", "Created Date End", "date", "Enter")}</Col>
          <Col lg={3}>{this.renderInput("taWorkTaskDatePerformedStart", "Date Performed Start", "date", "Enter")}</Col>
          <Col lg={3}>{this.renderInput("taWorkTaskDatePerformedEnd", "Date Performed End", "date", "Enter")}</Col>
        </Row>
      </React.Fragment>
    );
  }

  renderAerialSearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("aerialPropertyName", "Property", "text", "Enter")}</Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>County</Form.Label>
              <Select
                aria-label="County Search"
                value={this.state.data.aerialCountyName}
                openMenuOnClick={true}
                placeholder="Select"
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                options={this.state.availableCounties}
                onChange={(e) => this.handleSelectChanges(e, "aerialCountyName")}
              />
            </Form.Group>
          </Col>
          <Col>{this.renderInput("aerialEnteredDateStart", "Date Performed Start", "date", "Enter")}</Col>
          <Col>{this.renderInput("aerialEnteredDateEnd", "Date Performed End", "date", "Enter")}</Col>
        </Row>
        <Row className="mr-0 text-left">
          <Col md={3}>{this.renderInput("aerialAircraftNumber", "Aircraft Number", "text", "Enter")}</Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Pilot</Form.Label>
              <AsyncSelect
                aria-label="Aerial Work Task Pilot Search"
                value={this.state.data.aerialPilot}
                openMenuOnClick={false}
                placeholder="Search"
                components={{ DropdownIndicator, Option }}
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.loadAvailableUserProfiles}
                onChange={(e) => this.handleSelectChanges(e, "aerialPilot")}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Crew Member</Form.Label>
              <AsyncSelect
                aria-label="Aerial Work Task Performed By Search"
                value={this.state.data.aerialCrewMember}
                openMenuOnClick={false}
                placeholder="Search"
                components={{ DropdownIndicator, Option }}
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.loadAvailableUserProfiles}
                onChange={(e) => this.handleSelectChanges(e, "aerialCrewMember")}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderAdminWorkTaskSearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("adminWorkTaskDistrict", "District", "text", "Enter")}</Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Performed By</Form.Label>
              <AsyncSelect
                aria-label="Admin Work Task Performed By Search"
                value={this.state.data.adminWorkTaskPerformedBy}
                openMenuOnClick={false}
                placeholder="Search"
                components={{ DropdownIndicator, Option }}
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.loadAvailableUserProfiles}
                onChange={(e) => this.handleSelectChanges(e, "adminWorkTaskPerformedBy")}
              />
            </Form.Group>
          </Col>
          <Col>{this.renderInput("adminWorkTaskEnteredDate", "Entered Date", "date", "Enter")}</Col>
          <Col>{this.renderInput("adminWorkTaskDatePerformedStart", "Date Performed Start", "date", "Enter")}</Col>
          <Col>{this.renderInput("adminWorkTaskDatePerformedEnd", "Date Performed End", "date", "Enter")}</Col>
        </Row>
      </React.Fragment>
    );
  }

  renderAgreementSearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("agreementName", "Agreement Name", "text", "Enter")}</Col>
          <Col>{this.renderInput("agreementNumber", "Agreement Number", "text", "Enter")}</Col>
          <Col>{this.renderInput("agreementPropertyName", "Property Name", "text", "Enter")}</Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>County</Form.Label>
              <Select
                aria-label="County Search"
                value={this.state.data.agreementCounty}
                openMenuOnClick={true}
                placeholder="Select"
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                options={this.state.availableCounties}
                onChange={(e) => this.handleSelectChanges(e, "agreementCounty")}
              />
            </Form.Group>
          </Col>
          <Col>{this.renderInput("agreementPropertyAddress", "Property Address", "text", "Enter")}</Col>
        </Row>
        <Row className="mr-0 text-left">
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Primary WS Specialist</Form.Label>
              <AsyncSelect
                aria-label="Agrements WS Specialist Search"
                value={this.state.data.agreementPrimaryWsSpecialist}
                openMenuOnClick={false}
                placeholder="Search"
                components={{ DropdownIndicator, Option }}
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.loadAvailableUserProfiles}
                onChange={(e) => this.handleSelectChanges(e, "agreementPrimaryWsSpecialist")}
              />
            </Form.Group>
          </Col>
          <Col>{this.renderInput("agreementFirstNameOfCooperator", "Cooperator First Name", "text", "Enter")}</Col>
          <Col>{this.renderInput("agreementLastNameOfCooperator", "Cooperator Last Name", "text", "Enter")}</Col>
          <Col>{this.renderSelect("agreementStatus", "Agreement Status", this.state.agreementStatuses, "Select")}</Col>
        </Row>
      </React.Fragment>
    );
  }

  renderContactSearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("contactFirstName", "First Name", "text", "Enter")}</Col>
          <Col>{this.renderInput("contactLastName", "Last Name", "text", "Enter")}</Col>
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Phone Number</Form.Label>
              <Form.Control
                name="contactPhoneNumber"
                placeholder="Enter"
                as="input"
                type="text"
                onChange={this.handleChange}
                value={UtilityFunctions.formatPhoneNumber(this.state.data.contactPhoneNumber)}
                className={globalStyles.formData}
              />
            </Form.Group>
          </Col>
          <Col>{this.renderInput("contactEmail", "Email", "text", "Enter")}</Col>
        </Row>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("contactStreetAddress", "Mailing Street", "text", "Enter")}</Col>
          <Col>{this.renderInput("contactCity", "Mailing City", "text", "Enter")}</Col>
          <Col>{this.renderSelect("contactState", "State", this.state.states, "Select", "clearable")}</Col>
          <Col>{this.renderInput("contactZip", "Mailing Zip", "text", "Enter")}</Col>
        </Row>
      </React.Fragment>
    );
  }

  renderPermitSearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col>{this.renderInput("permitName", "Name", "text", "Enter")}</Col>
          <Col>{this.renderInput("permitNumber", "Permit Number", "text", "Enter")}</Col>
          <Col md={5}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Damage Agent</Form.Label>
              <AsyncSelect
                aria-label="Permit Damage Agent Search"
                value={this.state.data.permitSpecies}
                openMenuOnClick={false}
                placeholder="Search"
                components={{ DropdownIndicator }}
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.loadAvailableSpecies}
                onChange={(e) => this.handleSelectChanges(e, "permitSpecies")}
              />
            </Form.Group>
          </Col>
          <Col>
            {this.renderSelect(
              "permitIsValid",
              "Is Currently Valid?",
              [
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ],
              "Select"
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderSampleSearchFields() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col>{this.renderSelect("sampleType", "Sample Type", this.state.sampleTypes, "Select")}</Col>
          {data.sampleType === SampleTypes.Other ? (
            <Col>{this.renderInput("sampleDateTaken", "Date Taken", "date", "Enter")}</Col>
          ) : (
            <React.Fragment>
              <Col>{this.renderInput("sampleDateStart", "Date Start", "date", "Enter")}</Col>
              <Col>{this.renderInput("sampleDateEnd", "Date End", "date", "Enter")}</Col>
            </React.Fragment>
          )}
          <Col>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Species</Form.Label>
              <AsyncSelect
                aria-label="Sample Species Performed By Search"
                value={data.sampleSpecies}
                openMenuOnClick={false}
                placeholder="Search"
                components={{ DropdownIndicator }}
                styles={this.customSelectStyles}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.loadAvailableSpecies}
                onChange={(e) => this.handleSelectChanges(e, "sampleSpecies")}
              />
            </Form.Group>
          </Col>
          {(data.sampleType === SampleTypes.NRMP || data.sampleType === SampleTypes.Other) && (
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>User Profile</Form.Label>
                <AsyncSelect
                  aria-label="Sample Profile Performed By Search"
                  value={data.sampleProfile}
                  openMenuOnClick={false}
                  placeholder="Search"
                  components={{ DropdownIndicator }}
                  styles={this.customSelectStyles}
                  theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                  loadOptions={this.loadAvailableProfiles}
                  onChange={(e) => this.handleSelectChanges(e, "sampleProfile")}
                />
              </Form.Group>
            </Col>
          )}
          {data.sampleType === SampleTypes.DiseaseMonitoring && (
            <Col>
              {this.renderSelect("diseaseSampleType", "Type of Sample", this.state.diseaseSampleTypes, "Select")}
            </Col>
          )}
          {data.sampleType === SampleTypes.Other && (
            <Col className={styles.searchFieldColumns}>
              {this.renderSelect(
                "sampleIsFromTake",
                "Is From Take?",
                [
                  { id: true, name: "Yes" },
                  { id: false, name: "No" },
                ],
                "Select"
              )}
            </Col>
          )}
        </Row>
        <Row className="mr-0 text-left">
          {(data.sampleType === SampleTypes.DiseaseMonitoring || data.sampleType === SampleTypes.Other) && (
            <React.Fragment>
              <Col className={styles.searchFieldColumns}>
                {this.renderSelect("sampleDisease", "Disease", this.state.availableDiseases, "Select")}
              </Col>
              <Col className={styles.searchFieldColumns}>
                <Form.Group className="text-left">
                  <Form.Label className={globalStyles.formLabel}>Property</Form.Label>
                  <AsyncSelect
                    aria-label="Sample Property Search"
                    value={this.state.data.sampleProperty}
                    openMenuOnClick={false}
                    placeholder="Search"
                    components={{ DropdownIndicator }}
                    styles={this.customSelectStyles}
                    theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                    loadOptions={this.loadAvailableProperties}
                    onChange={(e) => this.handleSelectChanges(e, "sampleProperty")}
                  />
                </Form.Group>
              </Col>
            </React.Fragment>
          )}
          {data.sampleType === SampleTypes.DiseaseMonitoring && (
            <Col className={styles.searchFieldColumns}>
              {this.renderInput("subjectId", "Subject ID", "text", "Enter")}
            </Col>
          )}
          {data.sampleType === SampleTypes.NRMP && (
            <React.Fragment>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>County</Form.Label>
                  <Select
                    aria-label="Samples County Search"
                    value={data.sampleCounty}
                    openMenuOnClick={true}
                    placeholder="Select"
                    styles={this.customSelectStyles}
                    theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                    options={this.state.availableCounties}
                    onChange={(e) => this.handleSelectChanges(e, "sampleCounty")}
                  />
                </Form.Group>
              </Col>
              <Col>{this.renderInput("sampleTownOrCity", "Town or City", "text", "Enter")}</Col>
              <Col>{this.renderInput("waypointNumber", "Waypoint No.", "text", "Enter")}</Col>
              <Col>{this.renderInput("animalId", "Animal ID", "text", "Enter")}</Col>
              <Col>
                {this.renderSelect(
                  "collectionActivityId",
                  "Collection Activity",
                  this.state.availableCollectionActivities,
                  "Select"
                )}
              </Col>
            </React.Fragment>
          )}
        </Row>
      </React.Fragment>
    );
  }

  renderProjectSearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col md={6}>{this.renderInput("projectName", "Project Name", "text", "Enter")}</Col>
        </Row>
      </React.Fragment>
    );
  }

  renderDistrictSearchFields() {
    return (
      <React.Fragment>
        <Row className="mr-0 text-left">
          <Col md={6}>{this.renderInput("districtName", "District Name", "text", "Enter")}</Col>
        </Row>
      </React.Fragment>
    );
  }

  clearSearchFields = () => {
    let { data } = this.state;
    data = {
      selectedSearchType: this.state.data.selectedSearchType,
      propertyName: "",
      propertyStreet: "",
      propertyCity: "",
      propertyZip: "",
      propertyAgreementNumber: "",
      propertyCounty: "",
      siteVisitEnteredStartDate: "",
      siteVisitEnteredEndDate: "",
      datePerformedStartDate: "",
      datePerformedEndDate: "",
      siteVisitPropertyName: "",
      siteVisitCounty: "",
      siteVisitPerformedBy: "",
      siteVisitTakesRecorded: "",
      siteVisitSamplesTaken: "",
      taWorkTaskEnteredStartDate: "",
      taWorkTaskEnteredEndDate: "",
      taWorkTaskDatePerformedStart: "",
      taWorkTaskDatePerformedEnd: "",
      taWorkTaskCounty: "",
      taWorkTaskType: "",
      taWorkTaskTopic: "",
      taWorkTaskPerformedBy: "",
      aerialEnteredDateStart: "",
      aerialEnteredDateEnd: "",
      aerialPropertyName: "",
      aerialAircraftNumber: "",
      aerialCountyName: "",
      aerialPilot: "",
      aerialCrewMember: "",
      agreementName: "",
      agreementNumber: "",
      agreementCounty: "",
      agreementPropertyAddress: "",
      agreementPropertyName: "",
      agreementStatus: "",
      agreementPrimaryWsSpecialist: "",
      agreementFirstNameOfCooperator: "",
      agreementLastNameOfCooperator: "",
      adminWorkTaskDistrict: "",
      adminWorkTaskPerformedBy: "",
      adminWorkTaskEnteredDate: "",
      adminWorkTaskDatePerformedStart: "",
      adminWorkTaskDatePerformedEnd: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhoneNumber: "",
      contactStreetAddress: "",
      contactCity: "",
      contactState: "",
      contactZip: "",
      permitName: "",
      permitNumber: "",
      permitSpecies: "",
      permitIsValid: "",
      sampleType: "",
      sampleDateTaken: "",
      sampleDateStart: "",
      sampleDateEnd: "",
      sampleProfile: "",
      sampleSpecies: "",
      sampleIsFromTake: "",
      sampleDisease: "",
      sampleProperty: "",
      sampleCounty: "",
      sampleTownOrCity: "",
      diseaseSampleType: "",
      subjectId: "",
      waypointNumber: "",
      animalId: "",
      collectionActivityId: "",
      projectName: "",
      districtName: "",
    };
    this.setState({ data });
  };

  render() {
    return (
      <Card>
        <Card.Body className="px-0 py-0">
          {this.props.isGlobalSearch && <div className={styles.searchBoxHeader}></div>}
          <Row className="py-4">
            <Col sm={3} className={styles.searchBoxSelectionColumn}>
              <Select
                aria-label="Select Search Type"
                value={this.state.data.selectedSearchType}
                placeholder="Select Search Type"
                styles={this.customSelectStyles}
                options={searchTypesSelectOptions}
                onChange={this.handleSearchTypeSelect}
              />
              {this.state.errors["selectedSearchType"] && (
                <div className="alert alert-danger">{this.state.errors["selectedSearchType"]}</div>
              )}
            </Col>
            <Col>{this.renderSearchFields()}</Col>
          </Row>
        </Card.Body>
        <Card.Footer className={styles.serachCardFooter}>
          <Button className={styles.clearAllButton} onClick={this.clearSearchFields}>
            <span className={styles.clearAllButtonText}>Clear All Filters</span>
          </Button>
          <Button type="submit" className={styles.searchButton} onClick={this.handleSearch}>
            <span className={globalStyles.modalSubmitButtonText}>Search</span>
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

export default withRouter(StructuredSearchCard);
