import React from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import CustomForm from "../../../common/form";
import Select from "react-select";
import { toast } from "react-toastify";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";

class ManageWorkPerformedModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      inventoryItemLatitude: props.manageWorkPerformedData?.inventoryItemLatitude,
      inventoryItemLongitude: props.manageWorkPerformedData?.inventoryItemLongitude,
      selectedTargets: props.manageWorkPerformedData?.workPerformedSpecies?.map((w) => {
        w.label = w.speciesName;
        w.value = w.targetedSpeciesUniqueReference;
        return w;
      }),
      selectedProtecting: props.manageWorkPerformedData?.workPerformedResources?.map((w) => {
        w.label = w.resourceName;
        w.value = w.protectedResourceUniqueReference;
        return w;
      }),
    };
  }

  componentDidMount() {
    this.props.handleManageWorkPerformedTargetsSelection(this.state.selectedTargets);
    this.props.handleManageWorkPerformedProtectingSelection(this.state.selectedProtecting);
  }

  handleLatitudeChange = (e) => {
    this.setState({
      inventoryItemLatitude: e.target.value,
    });
  };

  handleLongitudeChange = (e) => {
    this.setState({
      inventoryItemLongitude: e.target.value,
    });
  };

  doSubmit = async () => {
    let success;
    const conflictTargetedSpeciesUniqueReferences = this.props.methodChosenTargets?.map((target) => {
      return target.targetedSpeciesUniqueReference;
    });
    const conflictProtectedResourceUniqueReferences = this.props.methodChosenProtections?.map((p) => {
      return p.protectedResourceUniqueReference;
    });
    let postBody = [
      {
        workPerformedUniqueIdentifier: this.props.manageWorkPerformedData?.workPerformedUniqueIdentifier,
        conflictUniqueReferences: this.props.getConflictIds(),
        methodQtyAffect: this.props.manageWorkPerformedData?.methodQtyAffected,
        inventoryItemNewNickname: this.props.manageWorkPerformedData?.inventoryItemNickname ?? "",
        inventoryItemNewLatitude: parseFloat(this.state.inventoryItemLatitude),
        inventoryItemNewLongitude: parseFloat(this.state.inventoryItemLongitude),
        cooperatorOrVolunteerCheck: this.props.manageWorkPerformedData?.cooperatorOrVolunteerCheck
          ? this.props.manageWorkPerformedData?.cooperatorOrVolunteerCheck
          : false,
        projectUniqueReferences:
          this.props.manageWorkPerformedData?.projects?.map((p) => p.projectUniqueReference) ?? [],
        warehouseUniqueReference: this.props.manageWorkPerformedData?.warehouseUniqueReference
          ? this.props.manageWorkPerformedData?.warehouseUniqueReference
          : null,
      },
    ];
    if (conflictTargetedSpeciesUniqueReferences?.length > 0) {
      postBody[0].conflictTargetedSpeciesUniqueReferences = conflictTargetedSpeciesUniqueReferences;
    }

    if (conflictProtectedResourceUniqueReferences?.length > 0) {
      postBody[0].conflictProtectedResourceUniqueReferences = conflictProtectedResourceUniqueReferences;
    }
    const updateWorkPerformedOnWorkTaskResponse = await WorkTaskAPI.UpdateWorkPerformedOnWorkTask(
      this.props.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workTaskUniqueIdentifier,
      postBody
    );
    if (updateWorkPerformedOnWorkTaskResponse?.successful) {
      this.props.handleManageWorkPerformedTargetsSelection([]);
      this.props.handleManageWorkPerformedProtectingSelection([]);
      this.props.refreshPVSObject();
      toast.success("Updated work performed.");
      this.props.onHide();
    } else if (updateWorkPerformedOnWorkTaskResponse?.unsuccessful) {
      ErrorHandler.showErrorWithDetails(
        "Unable to Update work performed. UpdateWorkPerformedOnWorkTask failed." +
          updateWorkPerformedOnWorkTaskResponse?.message ?? "",
        "Unable to Update work performed. " + updateWorkPerformedOnWorkTaskResponse?.message ?? ""
      );
    } else {
      ErrorHandler.showErrorWithDetails(
        "Unable to Update work performed. UpdateWorkPerformedOnWorkTask failed.",
        "Unable to Update work performed."
      );
    }
    if (success) {
      this.props.onHide();
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.modalDialog}
      >
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Manage Work Performed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="pl-3">
            <Col>
              {this.renderLabel(
                "Method",
                "Method",
                this.props.manageWorkPerformedData?.methodName ?? "No Name",
                "text"
              )}
            </Col>
          </Row>
          <Row className="pl-3">
            <Col>
              <Form.Label className={globalStyles.formLabel}>Latitude</Form.Label>
              <Form.Control
                aria-labelledby="methodEntryLatitudeLabel"
                name="methodLatitude"
                type="number"
                step="0.00001"
                placeholder="Enter Latitude"
                onChange={this.handleLatitudeChange}
                value={this.state.inventoryItemLatitude}
              />
            </Col>
            <Col>
              <Form.Label className={globalStyles.formLabel}>Longitude</Form.Label>
              <Form.Control
                aria-labelledby="methodEntryLongitudeLabel"
                name="methodLongitude"
                type="number"
                step="0.00001"
                placeholder="Enter Longitude"
                onChange={this.handleLongitudeChange}
                value={this.state.inventoryItemLongitude}
              />
            </Col>
          </Row>
          <Row className="pl-3 mt-3">
            <Col>
              <Form.Label className={globalStyles.formLabel}>Target(s)</Form.Label>
              <Select
                placeholder="Select"
                aria-labelledby="manageWorkPerformedAvailableTargets"
                value={this.props.methodChosenTargets}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                  option: (provided) => ({ ...provided, textAlign: "left", fontSize: "14px" }),
                  singleValue: (provided) => ({ ...provided, fontSize: "14px" }),
                }}
                isMulti
                options={this.props.methodTargets?.map((t) => {
                  t.label = t.targetedSpeciesName;
                  t.value = t.targetedSpeciesUniqueReference;
                  return t;
                })}
                onChange={this.props.handleManageWorkPerformedTargetsSelection}
              />
            </Col>
          </Row>
          <Row className="pl-3 mt-3">
            <Col>
              <Form.Label className={globalStyles.formLabel}>Protecting</Form.Label>
              <Select
                placeholder="Select"
                aria-labelledby="manageWorkPerformedAvailableProtecting"
                value={this.props.methodChosenProtections}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                  option: (provided) => ({ ...provided, textAlign: "left", fontSize: "14px" }),
                  singleValue: (provided) => ({ ...provided, fontSize: "14px" }),
                }}
                isMulti
                options={this.props.methodProtects?.map((t) => {
                  t.label = t.protectedResourceName + " | Unit Value: " + t.valuePerUnitOfMeasure;
                  t.value = t.protectedResourceUniqueReference;
                  return t;
                })}
                onChange={this.props.handleManageWorkPerformedProtectingSelection}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="ml-2" variant="outline-primary" onClick={this.props.onHide}>
            Cancel
          </Button>
          <Button className="ml-2" variant="primary" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default ManageWorkPerformedModal;
