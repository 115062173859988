import React from "react";
import styles from "./ReferenceFilesEditPages.module.scss";
import globalStyles from "../../../src/OARS.module.scss";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { withRouter } from "react-router-dom";
import { Card, Button, Form, Col, Row, Dropdown } from "react-bootstrap";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { toast } from "react-toastify";

class EditMethodPage extends React.Component {
  constructor(props) {
    super(props);
    var methodData = this.props.history.location.state.methodData;
    if (methodData) {
      this.state = {
        history: this.props.history,
        methodObj: methodData,
        allowedRadio: false,
        requiresGeolocationRadio: false,
        requiresLabelRadio: false,
        methodTypes: [],
        subTypes: [],
        allowedUsesList: [],
        allowedUOMsList: [],
        allowedUsesData: [],
        allowedUOMsData: [],
        abbreviation: methodData.abbreviation,
        createdBy: methodData.createdBy,
        createdDateTimeUTC: methodData.createdDateTimeUTC,
        description: methodData.description,
        hazards: methodData.hazards,
        isAllowedGlobally: methodData.isAllowedGlobally,
        isAllowedInState: methodData.isAllowedInState,
        lastModifiedBy: methodData.lastModifiedBy,
        lastModifiedDateTimeUTC: methodData.lastModifiedDateTimeUTC,
        manufacturer: methodData.manufacturer,
        methodName: methodData.methodName,
        methodUniqueIdentifier: methodData.methodUniqueIdentifier,
        purpose: methodData.purpose,
        regulatoryNumber: methodData.regulatoryNumber,
        retiredDate: methodData.retiredDate,
        storage: methodData.storage,
        methodType: methodData.methodType?.id,
        methodSubType: methodData.methodSubType?.id,
        globalAllowedUOMs: [],
        globalAllowedUses: [],
        comments: methodData.comments,
        isCMITSMethod: methodData.isCMITSMethod,
        onlyTransfertoPersonalWarehouse: methodData.onlyTransferToPersonalWarehouse,
        isSerialNumberRequired: methodData.isSerialNumberRequired,
        isMixingMethod: methodData.isMixingMethod,
      };
    }
  }

  handleAllowedRadioClick = () => {
    this.setState({ allowedRadio: !this.state.allowedRadio });
  };

  handleRequiresGeolocationRadioClick = () => {
    this.setState({ requiresGeolocationRadio: !this.state.requiresGeolocationRadio });
  };

  handleRequiresLabelRadioClick = () => {
    this.setState({ requiresLabelRadio: !this.state.requiresLabelRadio });
  };

  handleMethodTypeChange = async (event) => {
    if (event.target.value) {
      if (event.target.value !== "") {
        var methodType = parseInt(event.target.value);
        const getSubTypes = await ReferenceFileAPI.GetMethodSubTypesEnumForType(methodType);
        if (getSubTypes && getSubTypes.successful) {
          this.setState({ subTypes: getSubTypes.data, methodType, methodSubType: getSubTypes.data[0].id });
        }
      }
    }
  };

  async componentDidMount() {
    const stateData = this.state;
    const types = await ReferenceFileAPI.GetMethodTypesEnum();
    if (types?.data) {
      stateData.methodTypes = types.data;
    }

    const methodData = this.props.history.location.state.methodData;
    if (methodData) {
      const subTypes = methodData.methodType?.id
        ? await ReferenceFileAPI.GetMethodSubTypesEnumForType(methodData.methodType.id)
        : null;
      if (subTypes?.data) {
        stateData.subTypes = subTypes.data;
      }
      if (methodData.globalAllowedUses?.length > 0) {
        stateData.globalAllowedUses = [...methodData.globalAllowedUses];
        stateData.allowedUsesData = [...methodData.globalAllowedUses];
      }
      if (methodData.globalAllowedUOMs?.length > 0) {
        stateData.globalAllowedUOMs = [...methodData.globalAllowedUOMs];
        stateData.allowedUOMsData = [...methodData.globalAllowedUOMs];
      }
      stateData.allowedRadio = methodData.isAllowedGlobally;
      stateData.requiresGeolocationRadio = methodData.isGeolocationRequiredGlobally;
      stateData.requiresLabelRadio = methodData.isMethodLabelRequiredGlobally;
    }

    await ReferenceFileAPI.GetAllMethodUses().then((result) => {
      if (result) {
        stateData.allowedUsesList = result.data;
      }
    });
    await ReferenceFileAPI.GetAllMethodUOMs().then((result) => {
      if (result) {
        stateData.allowedUOMsList = result.data;
      }
    });

    this.setState(stateData);
  }

  handleEditMethod = async () => {
    let dateTime = new Date();
    var methodRecord = {
      createdBy: this.state.createdBy,
      createdDateTimeUTC: this.createdDateTimeUTC,
      lastModifiedBy: this.state.lastModifiedBy,
      lastModifiedDateTimeUTC: dateTime.toISOString(),
      abbreviation: this.state.abbreviation,
      description: this.state.description,
      hazards: this.state.hazards,
      manufacturer: this.state.manufacturer,
      methodName: this.state.methodName,
      methodTypeEnumId: this.state.methodType,
      methodSubTypeEnumId: this.state.methodSubType,
      methodUniqueIdentifier: this.state.methodUniqueIdentifier,
      purpose: this.state.purpose,
      regulatoryNumber: this.state.regulatoryNumber,
      retiredDate: this.state.retiredDate,
      comments: this.state.comments,
      isCMITSMethod: this.state.isCMITSMethod,
      isMixingMethod: this.state.isMixingMethod,
      isSerialNumberRequired: this.state.isSerialNumberRequired,
      onlyTransferToPersonalWarehouse: this.state.onlyTransfertoPersonalWarehouse,
    };

    let configureCall;
    let requireGeolocationCall;
    let requireLabelCall;
    let methodBody = {};
    let methodGuid = methodRecord.methodUniqueIdentifier;
    const intialAllowedRadioChange = this.state.methodObj.isAllowedGlobally !== this.state.allowedRadio;
    const intialRequiredGeolocationRadioChange =
      this.state.methodObj.isGeolocationRequiredGlobally !== this.state.requiresGeolocationRadio;
    const intialRequiredLabelRadioChange =
      this.state.methodObj.isMethodLabelRequiredGlobally !== this.state.requiresLabelRadio;
    if (!this.state.allowedRadio && intialAllowedRadioChange) {
      configureCall = await ReferenceFileAPI.DisallowMethodGlobally(methodBody, methodGuid);
    } else if (this.state.allowedRadio && intialAllowedRadioChange) {
      configureCall = await ReferenceFileAPI.AllowMethodGlobally(methodBody, methodGuid);
    }

    if (!this.state.requiresGeolocationRadio && intialRequiredGeolocationRadioChange) {
      requireGeolocationCall = await ReferenceFileAPI.SetGeoLocationOptionalGlobally(methodGuid);
    } else if (this.state.requiresGeolocationRadio && intialRequiredGeolocationRadioChange) {
      requireGeolocationCall = await ReferenceFileAPI.SetGeoLocationRequiredGlobally(methodGuid);
    }

    if (!this.state.requiresLabelRadio && intialRequiredLabelRadioChange) {
      requireLabelCall = await ReferenceFileAPI.SetMethodLabelToOptionalGlobally(methodGuid);
    } else if (this.state.requiresLabelRadio && intialRequiredLabelRadioChange) {
      requireLabelCall = await ReferenceFileAPI.SetMethodLabelToRequiredGlobally(methodGuid);
    }

    if (configureCall?.unsuccessful) {
      toast.warning(configureCall.message);
    }

    if (requireGeolocationCall?.unsuccessful) {
      toast.warning(requireGeolocationCall.message);
    }

    if (requireLabelCall?.unsuccessful) {
      toast.warning(requireLabelCall.message);
    }

    await this.updateAllowedUses();
    await this.updateAllowedUOMs();

    const methodEditResponse = await ReferenceFileAPI.UpdateMethod(methodRecord);
    if (methodEditResponse?.successful) {
      toast.success("Method successfully updated.");
      this.props.history.push({
        pathname: "/referencefiles",
        state: {
          tab: "methodTab",
          globalData: this.props.history.location.state.globalData,
          methodEditResponseData: methodEditResponse.data,
        },
      });
    } else {
      ErrorHandler.showErrorWithMessage("Error updating method. Please try again later.");
    }
  };

  async updateAllowedUses() {
    await this.addAllowedUsesToMethod();
    await this.removeAllowedUsesFromMethod();
  }

  async updateAllowedUOMs() {
    await this.addAllowedUOMsToMethod();
    await this.removeAllowedUOMsFromMethod();
  }

  async addAllowedUsesToMethod() {
    const allowedUsesToAdd = this.state.allowedUsesData.filter(
      (i) => !this.state.globalAllowedUses.find((globalUse) => globalUse.id === i.id)
    );
    if (allowedUsesToAdd?.length > 0) {
      const addAllowedUsesResponse = await ReferenceFileAPI.AddAllowedMethodUses(
        this.state.methodUniqueIdentifier,
        allowedUsesToAdd.map((i) => i.id)
      );
      if (!addAllowedUsesResponse.successful) {
        ErrorHandler.showError(
          `Error adding allowed uses for method: ${this.state.methodUniqueIdentifier}. ${
            addAllowedUsesResponse?.message ? addAllowedUsesResponse.message : ""
          }`
        );
      }
    }
  }

  async removeAllowedUsesFromMethod() {
    const allowedUsesToRemove = this.state.globalAllowedUses.filter(
      (globalUse) => !this.state.allowedUsesData.find((i) => i.id === globalUse.id)
    );
    if (allowedUsesToRemove?.length > 0) {
      const removeAllowedUsesResponse = await ReferenceFileAPI.RemoveAllowedMethodUses(
        this.state.methodUniqueIdentifier,
        allowedUsesToRemove.map((i) => i.id)
      );
      if (!removeAllowedUsesResponse.successful) {
        ErrorHandler.showError(
          `Error deleting allowed uses for method: ${this.state.methodUniqueIdentifier}. ${
            removeAllowedUsesResponse?.message ? removeAllowedUsesResponse.message : ""
          }`
        );
      }
    }
  }

  async addAllowedUOMsToMethod() {
    const allowedUOMsToAdd = this.state.allowedUOMsData.filter(
      (i) => !this.state.globalAllowedUOMs.find((globalUom) => globalUom.id === i.id)
    );
    if (allowedUOMsToAdd?.length > 0) {
      const addAllowedUOMsResponse = await ReferenceFileAPI.AddAllowedMethodUOMs(
        this.state.methodUniqueIdentifier,
        allowedUOMsToAdd.map((i) => i.id)
      );
      if (!addAllowedUOMsResponse.successful) {
        ErrorHandler.showError(
          `Error adding allowed UOMs for method: ${this.state.methodUniqueIdentifier}. ${
            addAllowedUOMsResponse?.message ? addAllowedUOMsResponse.message : ""
          }`
        );
      }
    }
  }

  async removeAllowedUOMsFromMethod() {
    const allowedUOMsToRemove = this.state.globalAllowedUOMs.filter(
      (globalUUom) => !this.state.allowedUOMsData.find((i) => i.id === globalUUom.id)
    );
    if (allowedUOMsToRemove?.length > 0) {
      const removeAllowedUOMsResponse = await ReferenceFileAPI.RemoveAllowedMethodUOMs(
        this.state.methodUniqueIdentifier,
        allowedUOMsToRemove.map((i) => i.id)
      );
      if (!removeAllowedUOMsResponse.successful) {
        ErrorHandler.showError(
          `Error deleting allowed UOMs for method: ${this.state.methodUniqueIdentifier}. ${
            removeAllowedUOMsResponse?.message ? removeAllowedUOMsResponse.message : ""
          }`
        );
      }
    }
  }

  handleUsesCheckClick(item) {
    let { allowedUsesData } = this.state;
    if (document.getElementById(item.name + item.id).checked) {
      allowedUsesData.push(item);
    } else {
      allowedUsesData = allowedUsesData.filter((i) => i.id !== item.id);
    }

    this.setState({ allowedUsesData });
  }

  handleUOMsCheckClick(item) {
    let { allowedUOMsData } = this.state;
    if (document.getElementById(item.name + item.id).checked) {
      allowedUOMsData.push(item);
    } else {
      allowedUOMsData = allowedUOMsData.filter((i) => i.id !== item.id);
    }

    this.setState({ allowedUOMsData });
  }

  handleCMITSSetupChange = ({ currentTarget: input }) => {
    const data = this.state;
    data[input.name] = input.checked;
    this.setState(data);
  };

  formatDate(date) {
    date = new Date(date);
    return new Date(date.getTime() - date.getTimezoneOffset() * -60000).toLocaleDateString();
  }

  render() {
    return (
      <div className={styles.editPageContainer}>
        <h5>Edit Method</h5>
        <Card className={styles.editPageCards}>
          <Card.Header className={globalStyles.cardTitles}>
            <span className={globalStyles.cardTitleText}>Method Information</span>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlid="formType">
                <Form.Label className={globalStyles.modalFormLabel} htmlFor="methodTypesDropdown">
                  Method Type
                </Form.Label>
                <Form.Control
                  id="methodTypesDropdown"
                  data-testid="methodTypesDropdown"
                  as="select"
                  value={this.state.methodType}
                  onChange={this.handleMethodTypeChange}
                >
                  {this.state.methodTypes.map((type, index) => (
                    <option key={type.displayText + index} value={type.id}>
                      {type.displayText}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlid="formMethodName">
                <Form.Label className={globalStyles.modalFormLabel} htmlFor="methodNameTextField">
                  Method Name
                </Form.Label>
                <Form.Control
                  id="methodNameTextField"
                  data-testid="methodNameTextField"
                  type="text"
                  defaultValue={this.state.methodName}
                  placeholder="Enter Name"
                  onChange={(e) => {
                    this.setState({ methodName: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group controlid="formMethodSubType">
                <Form.Label className={globalStyles.modalFormLabel} htmlFor="methodSubTypesDropdown">
                  Method Sub Type
                </Form.Label>
                <div className={styles.editPageSelectColumn}>
                  <Form.Control
                    id="methodSubTypesDropdown"
                    data-testid="methodSubTypesDropdown"
                    as="select"
                    value={this.state.methodSubType}
                    onChange={(e) => {
                      this.setState({ methodSubType: parseInt(e.target.value) });
                    }}
                  >
                    {this.state.subTypes.map((subType, index) => (
                      <option value={subType.id} key={subType.displayText + index}>
                        {subType.displayText}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
            </Form>
            <Form.Row>
              <Col xs={6}>
                <Form.Group controlid="allowedUsesList">
                  <h4 className={globalStyles.modalFormLabel}>Allowed Uses</h4>
                  <div className={styles.editPageMethodVariableSelectColumn}>
                    <Dropdown data-testid="UsesDropdown">
                      <Dropdown.Toggle className={styles.dropdownToggle}>Change Allowed Uses</Dropdown.Toggle>
                      <Dropdown.Menu flip="false" className={styles.dropdownToggle}>
                        <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                          {this.state.allowedUsesList.map((item) => (
                            <div key={`formChecklistContainerUses-${item.name}`} className={styles.formCheckContainer}>
                              <input
                                type="checkbox"
                                className="form-check-input ml-0"
                                id={`${item.name}${item.id}`}
                                key={`inline-${item.name}${item.id}`}
                                data-toggle="button"
                                onClick={() => this.handleUsesCheckClick(item)}
                                checked={this.state.allowedUsesData?.find((use) => use.id === item.id)}
                              />
                              <p className="form-check-label ml-4" htmlFor="itemChecked">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlid="allowedUOMList">
                  <h4 className={globalStyles.modalFormLabel}>Allowed UOMs</h4>
                  <div className={styles.editPageMethodVariableSelectColumn}>
                    <Dropdown data-testid="UOMsDropdown">
                      <Dropdown.Toggle className={styles.dropdownToggle}>Change Allowed UOMs</Dropdown.Toggle>
                      <Dropdown.Menu flip="false" className={styles.dropdownToggle}>
                        <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                          {this.state.allowedUOMsList.map((item) => (
                            <div key={`formChecklistContainerUOMs-${item.name}`} className={styles.formCheckContainer}>
                              <input
                                type="checkbox"
                                className="form-check-input ml-0"
                                id={`${item.name}${item.id}`}
                                key={`inline-${item.name}${item.id}`}
                                data-toggle="button"
                                onClick={() => this.handleUOMsCheckClick(item)}
                                checked={this.state.allowedUOMsData?.find((uom) => uom.id === item.id)}
                              />
                              <p className="form-check-label ml-4" htmlFor="itemChecked">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group controlid="allowedRadio">
                  <h4 className={globalStyles.modalFormLabel}>Status</h4>
                  <Form.Check
                    inline
                    label="Active"
                    type="radio"
                    id="yes"
                    checked={this.state.allowedRadio}
                    onClick={this.handleAllowedRadioClick}
                  />
                  <Form.Check
                    inline
                    label="Inactive"
                    type="radio"
                    id="no"
                    checked={!this.state.allowedRadio}
                    onClick={this.handleAllowedRadioClick}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Row>
              <Col>
                <Form.Group controlid="requiredGeolocationRadio">
                  <h4 className={globalStyles.modalFormLabel}>Requires Geolocation?</h4>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    id="yes-geo"
                    checked={this.state.requiresGeolocationRadio}
                    onClick={this.handleRequiresGeolocationRadioClick}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    id="no-geo"
                    checked={!this.state.requiresGeolocationRadio}
                    onClick={this.handleRequiresGeolocationRadioClick}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlid="requiredLabelRadio">
                  <h4 className={globalStyles.modalFormLabel}>Requires Label?</h4>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    id="yes-label"
                    checked={this.state.requiresLabelRadio}
                    onClick={this.handleRequiresLabelRadioClick}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    id="no-label"
                    checked={!this.state.requiresLabelRadio}
                    onClick={this.handleRequiresLabelRadioClick}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 mb-4">
              <Col>
                <Form.Check type="checkbox" id="isCMITSMethod" inline>
                  <Form.Check.Input
                    name="isCMITSMethod"
                    type="checkbox"
                    checked={this.state.isCMITSMethod}
                    onChange={this.handleCMITSSetupChange}
                  />
                  <Form.Check.Label>
                    <span className={globalStyles.formLabel}>Track in CMITS</span>
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col>
                <Form.Check type="checkbox" id="onlyTransfertoPersonalWarehouse" inline>
                  <Form.Check.Input
                    name="onlyTransfertoPersonalWarehouse"
                    type="checkbox"
                    checked={this.state.onlyTransfertoPersonalWarehouse}
                    onChange={this.handleCMITSSetupChange}
                  />
                  <Form.Check.Label>
                    <span className={globalStyles.formLabel}>Move to Personal Inventory</span>
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col>
                <Form.Check type="checkbox" id="isSerialNumberRequired" inline>
                  <Form.Check.Input
                    name="isSerialNumberRequired"
                    type="checkbox"
                    checked={this.state.isSerialNumberRequired}
                    onChange={this.handleCMITSSetupChange}
                  />
                  <Form.Check.Label>
                    <span className={globalStyles.formLabel}>Require Serial Number</span>
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col>
                <Form.Check type="checkbox" id="isMixingMethod" inline>
                  <Form.Check.Input
                    name="isMixingMethod"
                    type="checkbox"
                    checked={this.state.isMixingMethod}
                    onChange={this.handleCMITSSetupChange}
                  />
                  <Form.Check.Label>
                    <span className={globalStyles.formLabel}>Mixable</span>
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
            <Form.Row>
              <Col>
                <Form.Group title="Enter Method Description" required controlid="formMethodDescription">
                  <Form.Label className={globalStyles.modalFormLabel} htmlFor="methodDescription">
                    Description
                  </Form.Label>
                  <Form.Control
                    value={this.state.comments}
                    id="methodDescription"
                    title="Method Description"
                    as="textarea"
                    maxLength="155"
                    placeholder="Enter Description"
                    name="description"
                    onChange={(e) => {
                      this.setState({ comments: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Card.Body>
        </Card>
        <Card className={styles.editPageCards}>
          <Card.Body>
            <Form.Row>
              <Col xs={6}>
                <Form.Group controlid="submittedDateField">
                  <Form.Label className={globalStyles.modalFormLabel} htmlFor="submittedDate">
                    Date Added
                  </Form.Label>
                  <Form.Control
                    id="submittedDate"
                    data-testid="submittedDate"
                    type="text"
                    defaultValue={this.formatDate(this.state.createdDateTimeUTC)}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlid="submittedDateField">
                  <Form.Label className={globalStyles.modalFormLabel} htmlFor="lastUpdatedDate">
                    Last Updated
                  </Form.Label>
                  <Form.Control
                    id="lastUpdatedDate"
                    data-testid="lastUpdatedDate"
                    type="text"
                    defaultValue={this.formatDate(this.state.lastModifiedDateTimeUTC)}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Card.Body>
        </Card>
        <div className={styles.editPageButtonContainer}>
          <Button
            className={globalStyles.modalSubmitButton}
            variant="outline-primary"
            onClick={() =>
              this.props.history.push({
                pathname: "/referencefiles",
                state: { tab: "methodTab", globalData: this.props.history.location.state.globalData },
              })
            }
          >
            Cancel
          </Button>

          <Button className={globalStyles.modalSubmitButton} onClick={this.handleEditMethod} variant="primary">
            <span className={globalStyles.modalSubmitButtonText}>Update</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(EditMethodPage);
