import React from "react";
import { withRouter } from "react-router";
import styles from "../agreementEntities.module.scss";
import CustomForm from "../../common/form";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import { Button, Modal, Row, Col, Card } from "react-bootstrap";

class DuplicatedPropertyModal extends CustomForm {
  state = {};

  handleCreate = async () => {
    const { createMethodBody } = this.props;

    const serverResponse = await AgreementEntitiesAPI.CreateProperty(
      createMethodBody
    );

    if (serverResponse.successful) {
      this.props.onHide();
      this.props.onForceCreation();
    }
  };

  handleUpdate = () => {
    this.props.history.push({
      pathname: "/property/edit",
      state: { entityData: this.props.duplicatedRecordData },
    });
  };

  render() {
    const { duplicatedRecordData } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        size="lg"
        data-testid="duplicatePropertyModal"
      >
        <div className="d-flex justify-content-center">
          <i
            className="fa fa-exclamation-circle fa-5x"
            style={{ color: "gray" }}
            aria-hidden="true"
          ></i>
        </div>
        <Modal.Header>
          <div className="container ">
            <Row className="justify-content-center">
              <Col sm="auto">
                <h3>Warning!</h3>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <p className={styles.modalCenteredData}>
                It looks like the record you're creating might already exist in
                the system. Please review and confirm that you'd like to create
                a new record.
              </p>
            </Row>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Card data-testid="additionalInfoCard">
            <Card.Header>Additional Information</Card.Header>
            <Card.Body>
              <Row xs={1}>
                <Col>
                  {this.renderLabel(
                    "propertyName",
                    "Property Name",
                    duplicatedRecordData.propertyName
                  )}
                </Col>
              </Row>
              <Row xs={1}>
                <Col>
                  {this.renderLabel(
                    "acres",
                    "Area in Acres",
                    duplicatedRecordData.acres
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card data-testid="streetInfoCard">
            <Card.Header>Street Information</Card.Header>
            <Card.Body>
              {duplicatedRecordData.additionalStreetAddresses &&
                duplicatedRecordData.additionalStreetAddresses.map(
                  (address, index) => {
                    const streetAddress = address.streetAddress.concat(
                      ", ",
                      address.city,
                      ", ",
                      duplicatedRecordData.stateName,
                      " ",
                      address.zipCode,
                      ", " + duplicatedRecordData.countryName
                    );

                    return (
                      <Row xs={1} key={index}>
                        <Col>
                          {this.renderLabel(
                            "streetAddress" + index,
                            "Address " + (index + 1),
                            streetAddress
                          )}
                        </Col>
                      </Row>
                    );
                  }
                )}
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleUpdate}>
            Update
          </Button>
          <Button variant="primary" onClick={this.handleCreate}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(DuplicatedPropertyModal);
