import React, { Fragment } from "react";
import styles from "./Properties.module.scss";
import { Row, Col, Form, Button } from "react-bootstrap";

const Addresses = ({ addresses, countries, states, onAddressChange, onExtraAddress, dataTestid = "" }) => {
  return (
    <div className="mx-2">
      {addresses.map((address, index) => {
        return (
          <div key={index} data-testid={dataTestid}>
            <Row>
              <Col>
                <Form.Group controlId={"street" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">Street Address or PO Box</Form.Label>
                  <Form.Control
                    data-testid="streetAddress"
                    type="text"
                    placeholder="Enter Address"
                    value={address.street || address.streetAddress}
                    onChange={onAddressChange}
                    className={styles.editFields}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row xs={1} sm={2} md={4}>
              <Col>
                <Form.Group controlId={"city" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">City</Form.Label>
                  <Form.Control
                    data-testid="city"
                    type="text"
                    placeholder="Enter City"
                    value={address.city}
                    onChange={onAddressChange}
                    className={styles.editFields}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"state" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">
                    State
                    {index === 0 ? <span className="required"> *</span> : ""}
                  </Form.Label>
                  <Fragment>
                    <Form.Control
                      data-testid="state"
                      as="select"
                      type="text"
                      onChange={onAddressChange}
                      value={address.state}
                      disabled={index > 0}
                      required
                      className={styles.editFields}
                    >
                      <option>Select State</option>
                      {states.map((state) => (
                        <option value={state.id} key={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Fragment>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"country" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">Country</Form.Label>
                  <Form.Control
                    data-testid="country"
                    as="select"
                    type="text"
                    onChange={onAddressChange}
                    value={address.country}
                    disabled={index > 0}
                    className={styles.editFields}
                  >
                    <option>Select Country</option>
                    {countries.map((country) => (
                      <option value={country.id} key={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"zipCode" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">ZIP Code</Form.Label>
                  <Form.Control
                    data-testid="zipCode"
                    type="text"
                    placeholder="Enter ZIP Code"
                    value={address.zipCode}
                    onChange={onAddressChange}
                    className={styles.editFields}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="link"
                  onClick={onExtraAddress}
                  value={addresses.length > index + 1 ? index + 1 : "add"}
                  style={addresses.length > index + 1 ? { color: "red" } : {}}
                  className="float-right"
                >
                  <span className={styles.addAddressLink}>
                    {addresses.length > index + 1 ? "- Remove Address" : "+ Add another Address"}
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Addresses;
