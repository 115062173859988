import React from "react";
import CustomForm from "../common/form";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Form, Button, Row, Col, Image, Dropdown, CloseButton } from "react-bootstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import nav1 from "../../assets/methodsSelectionModalNav1.png";
import nav2 from "../../assets/methodsSelectionModalNav2.png";
import chevronDownIcon from "../../assets/chevron-down.svg";
import searchIcon from "../../assets/search.svg";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const ChevronDown = () => {
  return <img src={chevronDownIcon} alt="down arrow" />;
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    iconafter={searchIcon}
    onClick={onClick}
    className={styles.propertyAssociationDropdownMenuButton}
    variant="light"
  >
    <Row className="mx-0">
      <Col className="text-left pr-1 pl-0">
        <span className={styles.propertyAssocDropdownPlaceholder}>{children}</span>
      </Col>
      <Col sm={1} className="text-right px-0 pb-2">
        <ChevronDown />
      </Col>
    </Row>
  </Button>
));

const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-label="Crew Menu">
      <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
    </div>
  );
});

const tableStyles = {
  columnHeaderStyle1: {
    width: "20%",
    fontSize: "14px",
    borderBottom: "0.8px solid #008767",
    textAlign: "left",
  },
  columnHeaderStyle2: {
    width: "35%",
    fontSize: "14px",
    borderBottom: "0.8px solid #008767",
    textAlign: "left",
  },
  columnHeaderStyle3: { width: "15%", borderBottom: "0.8px solid #008767" },
  columnBodyStyle1: { width: "25%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "left" },
  columnBodyStyle2: { width: "35%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "left" },
  columnBodyStyle3: { width: "15%", fontSize: "14px", borderBottom: "1px solid #e8e8e8", textAlign: "right" },
};

class MethodsSelectionModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        selectedMethods: [],
        selectedMethodsForAssociation: [],
        methodNameSearch: "",
        methodTypeSearch: "",
        methodSubTypeSearch: "",
      },
      favoriteMethods: [],
      allMethods: [],
      allMethodsOriginalData: [],
      methodMainCategories: [],
      methodSubCategories1: [],
      selectAllMethods: false,
      isInitialPage: true,
      showPropertyAssociationDropdownMenu: false,
      checkedPropertiesInDropdown: [],
      selectedMethodsTableData: [],
      selectedMethodsColumns: [
        {
          dataField: "propertyUniqueIdentifier",
          hidden: true,
        },
        {
          dataField: "propertyName",
          text: "Property Name",
          headerStyle: () => {
            return tableStyles.columnHeaderStyle2;
          },
          style: tableStyles.columnBodyStyle1,
        },
        {
          dataField: "associatedMethods",
          text: "Associated Methods",
          formatter: (cell, row) => {
            return cell.map((m, index) => {
              if (index === cell.length - 1) {
                return m.methodName;
              } else {
                return m.methodName + ", ";
              }
            });
          },
          headerStyle: () => {
            return tableStyles.columnHeaderStyle1;
          },
          style: tableStyles.columnBodyStyle1,
        },
        {
          dataField: "methodsEditAssociations",
          text: "",
          formatter: (cell, row) => {
            return (
              <Button variant="link" className="px-0" onClick={() => this.deletePropertyMethodAssociation(row)}>
                <span className={styles.smallActionButtonsText}>Remove</span>
              </Button>
            );
          },
          headerStyle: () => {
            return tableStyles.columnHeaderStyle3;
          },
          style: tableStyles.columnBodyStyle3,
        },
      ],
      errors: {},
    };
  }

  async componentDidMount() {
    if (!this.props.currentUsaState?.stateGuid) {
      toast.error("Error loading current State Id on Method Selection Modal");
      return;
    }

    let { allMethods, allMethodsOriginalData, methodMainCategories } = this.state;
    const allMethodsResponse = await ReferenceFileAPI.GetAllMethodsByState(this.props.currentUsaState.stateGuid);
    if (allMethodsResponse?.successful && allMethodsResponse?.data?.results?.length > 0) {
      allMethods = allMethodsResponse.data.results
        .filter((m) => m.isAllowedInState)
        .map((m) => ({ ...m, isSelected: false }));
      allMethodsOriginalData = JSON.parse(
        JSON.stringify(
          allMethods.sort((a, b) => a.methodName.localeCompare(b.methodName, undefined, { sensitivity: "base" }))
        )
      );

      const methodTypesResponse = await ReferenceFileAPI.GetMethodTypesEnum();
      if (methodTypesResponse?.successful && methodTypesResponse?.data?.length > 0) {
        methodMainCategories = methodTypesResponse?.data?.map((type) => {
          if (!type) {
            return { value: type.displayText, label: "No Type" };
          }
          return { value: type.displayText, label: type.displayText };
        });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get method types",
          apiName: "GetMethodTypesEnum",
          responseUnsuccessful: methodTypesResponse?.unsuccessful,
          responseMessage: methodTypesResponse?.message,
        });
      }

      this.setState({
        allMethods,
        allMethodsOriginalData,
        favoriteMethods: this.getFavoriteMethods(allMethods),
        methodMainCategories,
        methodTypes: methodTypesResponse?.data,
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve method data",
        apiName: "GetAllMethodsByState",
        responseUnsuccessful: allMethodsResponse?.unsuccessful,
        responseMessage: allMethodsResponse?.message,
      });
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  getFavoriteMethods(allMethods) {
    let favoriteMethods = [];
    if (this.props.favoriteMethodIds?.length > 0 && allMethods?.length > 0) {
      this.props.favoriteMethodIds.forEach((id) => {
        const matchingMethod = allMethods.find((m) => m.methodUniqueIdentifier === id);
        if (matchingMethod) {
          favoriteMethods.push({ methodUniqueIdentifier: id, methodName: matchingMethod.methodName, isFavorite: true });
        }
      });
    }

    return favoriteMethods;
  }

  doSubmit = async () => {
    if (this.state.isInitialPage) {
      if (!(this.state.data.selectedMethods?.length > 0)) {
        toast.warning("At least one method must be selected.");
        return;
      }

      if (this.props.properties?.length === 1) {
        this.state.data.selectedMethods.forEach((method) => method.checkedProperties.push(this.props.properties[0]));
        this.saveSelection();
      } else {
        this.setState({ isInitialPage: false });
      }
    } else {
      if (!this.isFormDataValid()) {
        toast.warning("Please associate each selected method with one property at least.");
        return;
      }

      this.saveSelection();
    }
  };

  saveSelection() {
    this.props.onHide();
    this.props.onSave([...this.state.data.selectedMethods]);
    this.clearData();
  }

  isFormDataValid() {
    let isDataCompleted = true;
    this.state.data.selectedMethods.forEach((method) => {
      if (!(method.checkedProperties?.length > 0)) {
        isDataCompleted = false;
      }
    });
    return isDataCompleted;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data, allMethodsOriginalData } = this.state;
    data.selectedMethods = [];
    data.selectedMethodsForAssociation = [];
    data.methodNameSearch = "";
    data.methodTypeSearch = "";
    data.methodSubTypeSearch = "";

    this.clearFilters();
    this.setState({
      data,
      isInitialPage: true,
      allMethods: JSON.parse(JSON.stringify(allMethodsOriginalData)),
      favoriteMethods: this.getFavoriteMethods(allMethodsOriginalData),
      selectedMethodsTableData: [],
      checkedPropertiesInDropdown: [],
      selectAllMethods: false,
    });
  }

  handleMethodSelection = (selectedMethod) => {
    const { data, favoriteMethods, allMethods } = this.state;

    const favoriteMethod = favoriteMethods.find(
      (m) => m.methodUniqueIdentifier === selectedMethod.methodUniqueIdentifier
    );
    if (favoriteMethod) {
      favoriteMethod.isSelected = !favoriteMethod.isSelected;
    }

    const regularMethod = allMethods.find((m) => m.methodUniqueIdentifier === selectedMethod.methodUniqueIdentifier);
    if (regularMethod) {
      regularMethod.isSelected = !regularMethod.isSelected;
    }

    if (data.selectedMethods.find((m) => m.methodUniqueIdentifier === selectedMethod.methodUniqueIdentifier)) {
      data.selectedMethods = data.selectedMethods.filter(
        (m) => m.methodUniqueIdentifier !== selectedMethod.methodUniqueIdentifier
      );
    } else {
      selectedMethod.checkedProperties = [];
      data.selectedMethods.push(selectedMethod);
    }

    this.setState({ data, favoriteMethods, allMethods });
  };

  handleMethodFilterSearch = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleMethodFilterSelect = async (input, selectionField) => {
    const data = { ...this.state.data };
    if (!input) {
      data[selectionField] = "";
      if (selectionField === "resourceMainCategorySearch") {
        data.methodSubTypeSearch = "";
      }
    } else {
      data[selectionField] = input;
      if (selectionField === "methodTypeSearch") {
        const typeEnumId = this.state.methodTypes.find((x) => x.displayText === input.value)?.id;
        const newSubTypeList = await ReferenceFileAPI.GetMethodSubTypesEnumForType(typeEnumId);
        if (newSubTypeList && newSubTypeList?.data?.length > 0) {
          this.setState({
            methodSubCategories1: newSubTypeList?.data?.map((subType) => {
              if (!subType) {
                return { value: subType.displayText, label: "No Sub Type" };
              }
              return { value: subType.displayText, label: subType.displayText };
            }),
          });
          data.methodSubTypeSearch = "";
        }
      }
    }
    this.setState({ data });
  };

  onMethodSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data, favoriteMethods, allMethods } = this.state;

      const removedFavoriteMethodIndex = favoriteMethods.findIndex(
        (m) => m.methodUniqueIdentifier === removedValue.value
      );
      if (removedFavoriteMethodIndex !== -1) {
        favoriteMethods[removedFavoriteMethodIndex].isSelected = false;
      }

      const removedMethodIndex = allMethods.findIndex((m) => m.methodUniqueIdentifier === removedValue.value);
      if (removedMethodIndex !== -1) {
        allMethods[removedMethodIndex].isSelected = false;
      }

      data.selectedMethods = data.selectedMethods.filter((m) => m.methodUniqueIdentifier !== removedValue.value);
      this.setState({ data, allMethods, favoriteMethods });
    }
  };

  handlePageNavigation = (step) => {
    switch (step) {
      case 1:
        this.setState({ isInitialPage: true });
        break;
      case 2:
        if (this.state.data.selectedMethods?.length > 0) {
          this.setState({ isInitialPage: false });
        } else {
          toast.warning("Please select at least one method.");
        }
        break;
    }
  };

  handleDeleteMethod = (methodToDelete) => {
    const { data, allMethods, favoriteMethods } = this.state;
    if (methodToDelete.isFavorite) {
      const favoriteMethod = favoriteMethods.find(
        (m) => m.methodUniqueIdentifier === methodToDelete.methodUniqueIdentifier
      );
      favoriteMethod.isSelected = false;
    } else {
      const regularMethod = allMethods.find((m) => m.methodUniqueIdentifier === methodToDelete.methodUniqueIdentifier);
      regularMethod.isSelected = false;
    }

    data.selectedMethods = data.selectedMethods.filter(
      (m) => m.methodUniqueIdentifier !== methodToDelete.methodUniqueIdentifier
    );
    this.setState({ data });
  };

  handlePropertySelection = (property, { currentTarget: input }) => {
    const { data } = this.state;
    if (input.checked) {
      this.state.checkedPropertiesInDropdown.push(property.propertyUniqueIdentifier);
    } else {
      this.setState({
        checkedPropertiesInDropdown: this.state.checkedPropertiesInDropdown.filter(
          (p) => p !== property.propertyUniqueIdentifier
        ),
      });
    }
    data.selectedMethods.forEach((methodToUpdate) => {
      if (methodToUpdate.isCheckedForAssociation) {
        if (
          !methodToUpdate.checkedProperties?.find(
            (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
          )
        ) {
          methodToUpdate.checkedProperties.push(property);
        }
      }
    });
    this.setState({ data });
  };

  handleSelectAllProperties = () => {
    const { data } = this.state;
    data.selectedMethods.forEach((m) => {
      if (m.isCheckedForAssociation) {
        m.checkedProperties = this.props.properties;
      }
    });
    this.setState({ data, checkedPropertiesInDropdown: this.props.properties.map((p) => p.propertyUniqueIdentifier) });
  };

  handleUnselectAllProperties = () => {
    const { data } = this.state;
    data.selectedMethods.forEach((m) => {
      if (m.isCheckedForAssociation) {
        m.checkedProperties = [];
      }
    });
    this.setState({ data, checkedPropertiesInDropdown: [] });
  };

  handleSelectAllMethods = ({ currentTarget: input }) => {
    const { data, checkedPropertiesInDropdown } = this.state;
    data.selectedMethods.forEach((m) => {
      m.isCheckedForAssociation = input.checked;
      if (input.checked && checkedPropertiesInDropdown?.length > 0) {
        data.selectedMethods.forEach((methodToUpdate) => {
          methodToUpdate.checkedProperties = this.props.properties.filter(
            (p) =>
              (p.propertyUniqueIdentifier && checkedPropertiesInDropdown.includes(p.propertyUniqueIdentifier)) ||
              (p.propertyUniqueReference && checkedPropertiesInDropdown.includes(p.propertyUniqueReference))
          );
        });
      }
    });

    this.setState({ data, selectAllMethods: input.checked });
  };

  handleAssociateProperties = () => {
    const { data } = this.state;
    data.selectedMethods.forEach((method) => {
      if (
        method.isCheckedForAssociation &&
        method.checkedProperties.length > 0 &&
        !data.selectedMethodsForAssociation.find((m) => m.methodUniqueIdentifier === method.methodUniqueIdentifier)
      ) {
        data.selectedMethodsForAssociation.push(method);
      }
    });
    this.setState(
      { data, checkedPropertiesInDropdown: [], showPropertyAssociationDropdownMenu: false },
      this.mapSelectedMethodsTableData
    );
  };

  mapSelectedMethodsTableData = () => {
    const { data } = this.state;
    let tableData = [];
    data.selectedMethodsForAssociation.forEach((m) => {
      m.isCheckedForAssociation = false;
      if (m.checkedProperties.length > 0) {
        m.checkedProperties.forEach((p) => {
          let currentTableProperty = tableData.find(
            (tableProperty) => tableProperty.propertyUniqueIdentifier === p.propertyUniqueIdentifier
          );
          if (currentTableProperty) {
            currentTableProperty.associatedMethods.push(m);
          } else {
            let newTableProperty = JSON.parse(JSON.stringify(p));
            newTableProperty.associatedMethods = [m];
            tableData.push(newTableProperty);
          }
        });
      }
    });

    this.setState({ selectAllMethods: false, selectedMethodsTableData: tableData });
  };

  selectMethodsForAssociation = (method) => {
    const { data, checkedPropertiesInDropdown } = this.state;
    let methodSelectedForAssociation = data.selectedMethods.find(
      (m) => m.methodUniqueIdentifier === method?.methodUniqueIdentifier
    );
    if (methodSelectedForAssociation?.isCheckedForAssociation) {
      methodSelectedForAssociation.isCheckedForAssociation = false;
    } else {
      methodSelectedForAssociation.isCheckedForAssociation = true;
      if (checkedPropertiesInDropdown?.length > 0) {
        methodSelectedForAssociation.checkedProperties = this.props.properties.filter(
          (p) =>
            (p.propertyUniqueIdentifier && checkedPropertiesInDropdown.includes(p.propertyUniqueIdentifier)) ||
            (p.propertyUniqueReference && checkedPropertiesInDropdown.includes(p.propertyUniqueReference))
        );
      }
    }
    this.setState({ data });
  };

  deletePropertyMethodAssociation = (row) => {
    let { data, selectedMethodsTableData } = this.state;

    const updatedTableData = selectedMethodsTableData.filter(
      (property) => property.propertyUniqueIdentifier !== row.propertyUniqueIdentifier
    );

    data.selectedMethods.forEach((method) => {
      method.checkedProperties = method.checkedProperties.filter(
        (property) => property.propertyUniqueIdentifier !== row.propertyUniqueIdentifier
      );
    });

    this.setState({ selectedMethodsTableData: updatedTableData, data });
  };

  clearFilters = () => {
    const { data } = this.state;
    data.methodNameSearch = "";
    data.methodTypeSearch = "";
    data.methodSubTypeSearch = "";
    this.setState({ data });
  };

  renderMethodsSelectionPage = () => {
    const { data } = this.state;
    return (
      <React.Fragment>
        <Row className="mx-0 mt-4">
          <Col>
            <span className={styles.refFilesModalSubTitle}>Favorite Methods</span>
          </Col>
        </Row>
        <Row className="mx-0 my-2">
          <Col>
            <span className={styles.refFilesModalSubTitle}>
              <span className={styles.refFilesModalBoldListing}>Methods Name</span>
            </span>
          </Col>
        </Row>
        <div className={styles.favoriteResourcesContainer}>
          {this.state.favoriteMethods.map((method, index) => {
            return (
              <div key={index}>
                {index === 0 && <Row className={styles.greySeparatorRow}></Row>}
                <Row className="my-2 mx-0">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      inline
                      checked={method.isSelected}
                      onChange={() => this.handleMethodSelection(method)}
                      title={method.methodName}
                    />
                    <span className={globalStyles.formData}>{method.methodName}</span>
                  </Col>
                </Row>
                <Row className={styles.greySeparatorRow}></Row>
              </div>
            );
          })}
        </div>
        <Row className="mx-0 mt-4">
          <Col>
            <span className={styles.refFilesModalSubTitle}>Search Additional Methods</span>
          </Col>
        </Row>
        <Row className="mx-0 mt-2">
          <Col className="pr-0">
            <Form.Control
              name="methodNameSearch"
              aria-label="Method Name Search Input"
              placeholder="Enter Method Name"
              value={data.methodNameSearch}
              onChange={this.handleMethodFilterSearch}
            />
          </Col>
          <Col className="pr-0">
            <Select
              aria-label="Method Type Select"
              name="methodTypeSearch"
              placeholder="Type"
              isClearable
              value={data.methodTypeSearch}
              onChange={(e) => this.handleMethodFilterSelect(e, "methodTypeSearch")}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                  backgroundColor: isFocused ? "lightgray" : "white",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                }),
                menuList: (styles) => ({ ...styles, textAlign: "left" }),
              }}
              options={this.state.methodMainCategories}
            />
          </Col>
          <Col>
            <Select
              aria-label="Method Sub Type Select"
              name="methodSubTypeSearch"
              placeholder="Sub Type"
              isClearable
              value={data.methodSubTypeSearch}
              onChange={(e) => this.handleMethodFilterSelect(e, "methodSubTypeSearch")}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                  backgroundColor: isFocused ? "lightgray" : "white",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                }),
                menuList: (styles) => ({ ...styles, textAlign: "left" }),
              }}
              options={this.state.methodSubCategories1}
            />
          </Col>
        </Row>
        <Row>
          <Col align="right">
            <Button variant="link" onClick={this.clearFilters} style={{ fontSize: "14px" }}>
              Clear All Filters
            </Button>
          </Col>
        </Row>
        <Row className={styles.resultsBox}>{this.renderSearchResults()}</Row>
        <Row className="mx-0 mt-4">
          <Col>
            <Form.Group>
              <Form.Label className={styles.refFilesModalSubTitle} htmlFor="methodsSelected">
                Methods Selected
              </Form.Label>
              <Select
                aria-label="Methods Selected"
                name="methodsSelected"
                id="methodsSelected"
                placeholder="Methods Selected"
                isMulti
                menuIsOpen={false}
                isClearable={false}
                isSearchable={false}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: "white",
                      border: "solid 0.5px #D8DDE6",
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: "#0070D2",
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: "#53698D",
                    ":hover": {
                      backgroundColor: "white",
                    },
                  }),
                }}
                components={{
                  DropdownIndicator: (props) => {
                    return "";
                  },
                }}
                value={this.state.data?.selectedMethods?.map((m) => ({
                  value: m.methodUniqueIdentifier,
                  label: m.methodName,
                }))}
                onChange={this.onMethodSelectionChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderSearchResults = () => {
    let results = null;
    const { data, allMethods } = this.state;
    if (data.methodNameSearch || data.methodTypeSearch || data.methodSubTypeSearch) {
      results = (
        <React.Fragment>
          {allMethods
            .filter((method) => {
              let isMatch = true;
              if (data.methodNameSearch) {
                isMatch = isMatch && method.methodName.toLowerCase().includes(data.methodNameSearch.toLowerCase());
              }
              if (data.methodTypeSearch) {
                isMatch = isMatch && method.type === data.methodTypeSearch.value;
              }
              if (data.methodSubTypeSearch) {
                isMatch = isMatch && method.subType === data.methodSubTypeSearch.value;
              }

              return isMatch;
            })
            .map((result, index) => {
              return (
                <div key={index}>
                  <Row className={`mb-2 mx-0 ${index === 0 ? "mt-3" : "mt-2"}`}>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        inline
                        checked={result.isSelected}
                        onChange={() => this.handleMethodSelection(result)}
                        title={result.methodName}
                      />
                      <span className={globalStyles.formData}>{result.methodName}</span>
                    </Col>
                  </Row>
                  <Row className={styles.greySeparatorRow}></Row>
                </div>
              );
            })}
        </React.Fragment>
      );
    }

    return results;
  };

  renderMethodsAssociationPage = () => {
    return (
      <React.Fragment>
        <Row className="mx-4 mt-5 mb-3 px-0">
          <Col>
            <Form.Check type="checkbox" id="selectAllMethods" inline>
              <Form.Check.Input
                className="mr-2"
                name="selectAllMethods"
                type="checkbox"
                checked={this.state.selectAllMethods}
                onChange={this.handleSelectAllMethods}
              />
              <Form.Check.Label>
                <span className={globalStyles.formLabel}>Select All</span>
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col className="pl-2 pr-0 text-right">
            <Dropdown
              onToggle={(isOpen) => {
                if (!isOpen) {
                  this.setState({ showPropertyAssociationDropdownMenu: false });
                } else {
                  this.setState({ showPropertyAssociationDropdownMenu: true });
                }
              }}
              show={this.state.showPropertyAssociationDropdownMenu}
            >
              <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                <span className={styles.smallFormDataText}>Associate w/ Property</span>
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu} className={styles.propertyAssociationDropdownMenu}>
                <Dropdown.Header className="px-1 py-0 mb-2">
                  <Row className="mx-0">
                    <Col className="text-left px-1">
                      <Button variant="link" className="px-0" onClick={() => this.handleSelectAllProperties()}>
                        <span className={styles.smallActionButtonsText}>Check All</span>
                      </Button>
                    </Col>
                    <Col className="text-right px-1">
                      <Button variant="link" className="px-0" onClick={() => this.handleUnselectAllProperties()}>
                        <span className={styles.smallActionButtonsText}>Uncheck All</span>
                      </Button>
                    </Col>
                  </Row>
                </Dropdown.Header>
                {this.props.properties?.map((property, index) => {
                  return (
                    <Row key={index} className="px-3 mx-0 mb-2">
                      <Form.Check
                        type="checkbox"
                        inline
                        checked={this.state.checkedPropertiesInDropdown?.includes(property.propertyUniqueIdentifier)}
                        onChange={(e) => this.handlePropertySelection(property, e)}
                        title={property.propertyName}
                      />
                      <span className={styles.smallFormDataText}>{property.propertyName}</span>
                    </Row>
                  );
                })}
                <Row>
                  <Col align="center" className="mt-2">
                    <Button variant="primary" style={{ width: "75%" }} onClick={() => this.handleAssociateProperties()}>
                      <span>Associate</span>
                    </Button>
                  </Col>
                </Row>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className={styles.propertyAssociationGreySeparatorRow}></Row>
        {this.state.data.selectedMethods?.map((method, index) => {
          return (
            <div key={index}>
              <Row className="mr-0 ml-4 my-3 px-0">
                <Col>
                  <Form.Check type="checkbox" id={"selectCheckboxForMethods" + index} inline>
                    <Form.Check.Input
                      className="mr-2"
                      name={"selectCheckboxForMethods" + index}
                      type="checkbox"
                      checked={method.isCheckedForAssociation}
                      onChange={() => this.selectMethodsForAssociation(method)}
                    />
                    <Form.Check.Label>
                      <span className={globalStyles.formData}>{method.methodName}</span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
              <Row className={styles.propertyAssociationGreySeparatorRow}></Row>
            </div>
          );
        })}
        {this.state.selectedMethodsTableData?.length > 0 && (
          <div>
            <Row className="mx-4 my-3 px-0">
              <Col className={styles.speciesSelectionModalTableHeader}>
                <span className={styles.speciesSelectionModalTableHeaderText}>Association w/ Property</span>
              </Col>
            </Row>
            <Row className="mr-0 ml-4 my-3 px-0">
              <BootstrapTable
                keyField="propertyUniqueIdentifier"
                data={this.state.selectedMethodsTableData}
                columns={this.state.selectedMethodsColumns}
                bootstrap4={true}
                hover={true}
                bordered={false}
              />
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    let bodyContent = null;
    let submitButtonText = this.props.properties?.length < 2 ? "Save" : "Next";
    let navImage = nav1;

    if (!this.state.isInitialPage) {
      submitButtonText = "Save";
      navImage = nav2;
      bodyContent = this.renderMethodsAssociationPage();
    } else {
      bodyContent = this.renderMethodsSelectionPage();
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.refFilesAssociationModal}
      >
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Add Methods</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 my-3">
            <Col className="text-center">
              <Image src={navImage} useMap="#methodsSelectionStepperMap" hidden={this.props?.properties?.length < 2} />
              <map name="methodsSelectionStepperMap" className={styles.refFileStepper}>
                <area
                  alt="Add Methods"
                  title="Add Methods"
                  onClick={() => this.handlePageNavigation(1)}
                  coords="0,2,343,37"
                  shape="rect"
                />
                <area
                  alt="Add Property Selection"
                  title="Add Property Selection"
                  onClick={() => this.handlePageNavigation(2)}
                  coords="345,2,691,37"
                  shape="rect"
                />
              </map>
            </Col>
          </Row>
          {bodyContent}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span>Cancel</span>
          </Button>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>{submitButtonText}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MethodsSelectionModal;
