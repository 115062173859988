import htmlToPDF from "html2pdf.js";

class UtilityFunctions {
  static capitalizeString(input) {
    var output = "";
    if (input !== "" && input !== null) {
      output = input.charAt(0).toUpperCase() + input.slice(1);
    }
    return output;
  }

  static daysDifference(d0, d1) {
    var diff = new Date(+d1).setHours(12) - new Date(+d0).setHours(12);
    return Math.round(diff / 8.64e7);
  }

  static validateEmail(email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(email).toLowerCase()
    );
  }

  static validatePhoneNumber(phoneNumber) {
    return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phoneNumber);
  }

  static formatPhoneNumber(phoneNumber) {
    var match = ("" + phoneNumber).replace(/\D/g, "").match(/^(\d{3})(\d{3})(\d{4})$/);
    let result = phoneNumber;
    if (match) {
      result = "" + match[1] + "-" + match[2] + "-" + match[3];
    }
    return result;
  }

  static formatPhoneNumberWithoutDashes(phoneNumber) {
    var match = ("" + phoneNumber).replace(/\D/g, "").match(/^(\d{3})(\d{3})(\d{4})$/);
    let result = phoneNumber;
    if (match) {
      result = match[1] + match[2] + match[3];
    }
    return result;
  }

  static downloadPDF(element, fileName) {
    const options = {
      margin: 0.15,
      filename: `${fileName}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    htmlToPDF().from(element).set(options).save();
  }

  static getWeekDayFromDate(date) {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return weekday[date.getDay()];
  }

  static getDisplayTextFromFieldObject(object) {
    let objectName = "";
    if (object?.displayText) objectName = object.displayText;
    else if (object?.name) objectName = object.name;

    return objectName;
  }

  static arraysEqualIgnoreOrder = (a, b) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
      const aCount = a.filter((e) => e === v).length;
      const bCount = b.filter((e) => e === v).length;
      if (aCount !== bCount) return false;
    }
    return true;
  };

  static getPropertyPrimaryAddress(providedAddress) {
    let primaryAddress = "";
    if (providedAddress) {
      const { streetAddress, city, zipCode, stateCode } = providedAddress;
      primaryAddress = streetAddress.concat(", ", city, ", ", stateCode ? stateCode : "", " ", zipCode);
    }
    return primaryAddress;
  }

  static getFilterReferenceFilesCallBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  static getValueAsCurrency(value) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);
  }

  static getCorrectTimeZoneDateFromISO8601DateString(date) {
    return date ? new Date(date.replace(/-/g, "/")?.replace(/T.+/, "")) : "";
  }

  static getContactNameFromDataObject(contactData) {
    let name = "";
    if (contactData) {
      const { firstName, middleName, lastName, suffix } = contactData;
      name = [firstName, middleName, lastName, suffix].join(" ");
    }

    return name;
  }

  static getPrimaryPhoneNumber(phoneNumbers) {
    let phoneNumber = "";
    if (phoneNumbers?.length > 0) {
      phoneNumber = phoneNumbers[0].phoneNumber;
    }
    return phoneNumber;
  }

  static agreementEntityTableSortFunc = (fieldToSortBy) => {
    return (a, b) => {
      const nameA = a[fieldToSortBy]?.toUpperCase();
      const nameB = b[fieldToSortBy]?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    };
  };
}

export default UtilityFunctions;
