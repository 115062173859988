import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter, customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import CustomForm from "../common/form";
import globalStyles from "../../OARS.module.scss";
import styles from "./Warehouse.module.scss";
import CustomTextFilter from "../common/customTextFilter";
import UtilityFunctions from "../common/UtilityFunctions";
import InventoryAPI from "../../api/Inventory/InventoryAPI";
import ExportCSVButton from "../ReferenceFiles/ExportCSVButton";

class TransactionHistoryPage extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      tableColumns: [
        { dataField: "warehouseTransactionUniqueIdentifer", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      transactionsData: [],
      errors: {},
    };
  }

  async componentDidMount() {
    const transactionsData =
      this.props.history.location?.state?.transactionsData?.length > 0
        ? this.props.history.location.state.transactionsData
        : [];
    const transactionTypes = await this.getAvailableTransactionTypes();

    this.setState({
      transactionsData,
      transactionTypes,
      tableColumns: this.getTableColumns(transactionTypes),
      isFormLoading: false,
    });
  }

  async getAvailableTransactionTypes() {
    let availableTransactionTypes = [];
    const transactionTypesResponse = await InventoryAPI.GetAllTransactionTypes();
    if (transactionTypesResponse?.successful && transactionTypesResponse.data?.length > 0) {
      availableTransactionTypes = transactionTypesResponse.data.map((t) => ({
        id: t.id,
        name: UtilityFunctions.getDisplayTextFromFieldObject(t),
      }));
    }
    return availableTransactionTypes;
  }

  getTableColumns(transactionTypes) {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
      paddingRight: "1.5rem",
    };
    let typesToFilter = {};
    transactionTypes.forEach((t) => {
      typesToFilter[t.id] = t.name;
    });
    return [
      {
        dataField: "warehouseTransactionUniqueIdentifer",
        hidden: true,
        csvText: "Warehouse Unique Id",
      },
      {
        dataField: "methodName",
        text: "Method Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Method Name" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "methodNameHeader" },
        attrs: { headers: "methodNameHeader", tabIndex: "0" },
      },
      {
        dataField: "methodCategory",
        text: "Category",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Method Name" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "methodCategoryHeader" },
        attrs: { headers: "methodCategoryHeader", tabIndex: "0" },
      },
      {
        dataField: "methodSubCategory",
        text: "Subcategory",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Method Name" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "methodSubcategoryHeader" },
        attrs: { headers: "methodSubcategoryHeader", tabIndex: "0" },
      },
      {
        dataField: "transactionType",
        text: "Transaction",
        filter: selectFilter({
          options: typesToFilter,
          onFilter: this.filterTransactionTypeColumn,
        }),
        formatter: (cell, row, rowIndex) => {
          let typeName = "";
          if (cell) {
            typeName = UtilityFunctions.getDisplayTextFromFieldObject(cell);
          }
          return typeName;
        },
        csvFormatter: (cell, row) => {
          let typeName = "";
          if (cell) {
            typeName = UtilityFunctions.getDisplayTextFromFieldObject(cell);
          }
          return typeName;
        },
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "transactionTypeHeader" },
        attrs: { headers: "transactionTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "quantity",
        text: "Qty",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Method Qty" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "methodQtyHeader" },
        attrs: { headers: "methodQtyHeader", tabIndex: "0" },
      },
      {
        dataField: "warehouseTransferToName",
        text: "Transfer To",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Transfer To" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "warehouseTransferToHeader" },
        attrs: { headers: "warehouseTransferToHeader", tabIndex: "0" },
      },
      {
        dataField: "warehouseTransferToStateName",
        text: "To State",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="From State" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "warehouseTransferToStateHeader" },
        attrs: { headers: "warehouseTransferToStateHeader", tabIndex: "0" },
      },
      {
        dataField: "warehouseTransferFromName",
        text: "Transfer From",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Transfer From" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "warehouseTransferFromHeader" },
        attrs: { headers: "warehouseTransferFromHeader", tabIndex: "0" },
      },
      {
        dataField: "warehouseTransferFromStateName",
        text: "From State",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="From State" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "warehouseTransferFromStateHeader" },
        attrs: { headers: "warehouseTransferFromStateHeader", tabIndex: "0" },
      },
      {
        dataField: "soldTo",
        text: "Sold To",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} title="Sold To" />,
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "warehousesoldToStateHeader" },
        attrs: { headers: "warehousesoldToStateHeader", tabIndex: "0" },
      },
      {
        dataField: "lastModifiedBy",
        text: "Performed By",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Performed By" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "performedByHeader" },
        attrs: { headers: "performedByHeader", tabIndex: "0" },
      },
      {
        dataField: "createdDateTimeUTC",
        text: "Date",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => <CustomTextFilter onFilter={onFilter} column={column} title="Date" />,
        formatter: (cell, row, rowIndex) => {
          let formatedDate = "";
          if (cell) {
            formatedDate =
              UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell).toLocaleDateString("en-US");
          }
          return formatedDate;
        },
        csvFormatter: (cell, row) => {
          let formatedDate = "";
          if (cell) {
            formatedDate =
              UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell).toLocaleDateString("en-US");
          }
          return formatedDate;
        },
        sort: true,
        align: "left",
        headerAlign: "left",
        style: { ...rowsStyle, fontWeight: 400 },
        headerStyle: { ...rowsStyle, fontWeight: 700 },
        headerAttrs: { id: "transactionDateHeader" },
        attrs: { headers: "transactionDateHeader", tabIndex: "0" },
      },
    ];
  }

  filterTransactionTypeColumn = (filterValue, data) => {
    if (filterValue) {
      return data.filter((entry) => entry.transactionType?.id === parseInt(filterValue));
    }
  };

  render() {
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading Transactions Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.cmitsListingPage}>
          <Form onSubmit={this.handleSubmit}>
            <ToolkitProvider
              keyField="warehouseTransactionUniqueIdentifer"
              data={this.state.transactionsData}
              columns={this.state.tableColumns}
              filter={filterFactory()}
              bootstrap4={true}
              hover={true}
              exportCSV={{
                fileName: "transaction-history.csv",
                ignoreHeader: false,
                noAutoBOM: false,
                onlyExportFiltered: true,
                exportAll: false,
              }}
            >
              {(props) => (
                <div>
                  <Card className="mt-5">
                    <Card.Body>
                      <Row className={styles.transactionHistoryHeaderRow}>
                        <Col className="text-left pl-0" lg={5}>
                          <span className={styles.transactionHistoryTableTitle}>Transaction History</span>
                        </Col>
                        <Col className="text-right pr-0">
                          <ExportCSVButton {...props.csvProps} />
                        </Col>
                      </Row>
                      {this.state.transactionsData.length > 0 ? (
                        <BootstrapTable
                          keyField="warehouseTransactionUniqueIdentifer"
                          filter={filterFactory()}
                          bordered={false}
                          {...props.baseProps}
                          defaultSorted={this.state.defaultSort}
                          defaultSortDirection="asc"
                        />
                      ) : (
                        <span className={globalStyles.formLabel}>There are no transactions available.</span>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              )}
            </ToolkitProvider>
            <div className="text-right">
              <Button className="mt-4" variant="primary" onClick={() => this.props.history.goBack()}>
                <span className={globalStyles.modalSubmitButtonText}>Return to Warehouse Page</span>
              </Button>
            </div>
          </Form>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(TransactionHistoryPage);
