import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import { toast } from "react-toastify";

class CreateEditAircraftModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: this.props?.modalData
        ? { ...this.props?.modalData, selectedAircraftType: this.props.modalData?.aircraftType?.id }
        : {
            aircraftTailNumber: "",
            selectedAircraftType: "",
            isAllowedInState: true,
            createdDateTimeUTC: "",
            lastModifiedDateTimeUTC: "",
          },
      errors: {},
    };
  }

  submitModal = () => {
    if (!this.isFormDataValid()) {
      toast.warning("Please provide valid values for Tail Number and Aircraft Type");
      return;
    }

    const { data } = this.state;
    this.props.onSubmit({ ...data }, this.state.modalMode);
    this.clearData();
    this.props.onHide();
  };

  isFormDataValid() {
    const { aircraftTailNumber, selectedAircraftType } = this.state.data;
    return aircraftTailNumber && selectedAircraftType;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.aircraftTailNumber = "";
    data.selectedAircraftType = "";
    data.createdDateTimeUTC = "";
    data.lastModifiedDateTimeUTC = "";

    this.setState({ data });
  }

  handleStateStatusChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isAllowedInState = input.id === "active" ? true : false;
    this.setState({ data });
  };

  createAircraftModalUI() {
    return (
      <div className={styles.createDamageModalBody}>
        <Row>
          <Col>{this.renderInput("aircraftTailNumber", "Tail Number", "text", "Enter Tail Number", "required")}</Col>
        </Row>
        <Row>
          <Col>
            {this.renderSelect(
              "selectedAircraftType",
              "Aircraft Type",
              this.props.aircraftTypes,
              "Select Type",
              "required"
            )}
          </Col>
        </Row>
      </div>
    );
  }

  editAircraftModalUI() {
    const { data } = this.state;
    return (
      <div className={styles.editDamageModalBody}>
        <Row>
          <Col>{this.renderInput("aircraftTailNumber", "Tail Number", "text", "Enter Tail Number", "required")}</Col>
        </Row>
        <Row>
          <Col>
            {this.renderSelect(
              "selectedAircraftType",
              "Aircraft Type",
              this.props.aircraftTypes,
              "Select Type",
              "required"
            )}
          </Col>
        </Row>
        <Row>
          <Col className={styles.editDamageModalStatus}>
            <Form.Group>
              <Form.Check
                type="radio"
                name="isAllowedInState"
                inline
                label="Active"
                id="active"
                onChange={this.handleStateStatusChange}
                defaultChecked={data.isAllowedInState}
              />
              <Form.Check
                type="radio"
                name="isAllowedInState"
                inline
                label="Inactive"
                id="inactive"
                onChange={this.handleStateStatusChange}
                defaultChecked={!data.isAllowedInState}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editDamageModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editDamageModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createAircraftModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editAircraftModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} Aircraft</Modal.Title>
          </Modal.Header>
          <Modal.Body>{bodyContent}</Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button type="submit" className={globalStyles.ModalCreateButton} onClick={this.submitModal}>
              <span className={globalStyles.ModalCreateButtonText}>{submitButtonText}</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CreateEditAircraftModal;
