import React, { Component } from "react";
import { withRouter } from "react-router";
import styles from "../common/common.scss";
import { Button, Modal, Row, Col } from "react-bootstrap";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import { toast } from "react-toastify";

class DeleteAcknowledgeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.okButton = React.createRef();
  }

  componentDidMount() {
    if (this.okButton.current) {
      this.okButton.current.focus();
    }
  }

  handleOk = async () => {
    this.props.onHide();
    const { objectName, onAcknowledgeRoute, objectUniqueId } = this.props;

    let deleteObjectResponse;
    if (objectUniqueId) {
      if (objectName === "Agreement") {
        deleteObjectResponse = await AgreementAPI.RemoveDraftAgreement(objectUniqueId);
      }

      if (deleteObjectResponse?.successful) {
        toast.success(`${objectName} was successfully removed.`);
        this.props.history.push(onAcknowledgeRoute);
      } else {
        toast.error(`${objectName} delete failed. See console log for more details.`);
      }
    }
  };

  handleCancel = () => {
    this.props.onHide();
  };

  render() {
    let status = "created.";
    if (this.props.messageType === "update") {
      status = "updated.";
    } else if (this.props.messageType === "save") {
      status = "saved.";
    }
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered size="sm">
        <div className="d-flex justify-content-center mt-2">
          <i className="fa fa-exclamation-circle fa-5x" style={{ color: "gray" }} aria-hidden="true"></i>
        </div>
        <Modal.Header>
          <div className="container ">
            <Row className="justify-content-center">
              <Col sm="auto">
                <p className="text-center">Are you you sure you want to delete this {this.props.objectName}?</p>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Button
                className="mr-1"
                ref={this.cancelButton}
                variant="primary"
                onClick={this.handleCancel}
                title="Cancel"
              >
                Cancel
              </Button>
              <Button className="ml-1" ref={this.okButton} variant="primary" onClick={this.handleOk} title="OK">
                OK
              </Button>
            </Row>
          </div>
        </Modal.Header>
      </Modal>
    );
  }
}

export default withRouter(DeleteAcknowledgeModal);
