import "./map.css";
import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";
import { esriPortalConfig } from "../../../package.json";

function SearchMap(props) {
  const mapDiv = useRef(null);

  let longitude = parseFloat(props.longitude);
  let latitude = parseFloat(props.latitude);
  let address = props.address;

  if (!(latitude && longitude) || !props.shouldIRecalculate) {
    return <div className="mapDiv" ref={mapDiv}></div>;
  }

  loadModules([
    "esri/widgets/Track",
    "esri/Map",
    "esri/views/MapView",
    "esri/config",
    "esri/Graphic",
    "esri/widgets/BasemapToggle",
  ]).then(([Track, Map, MapView, esriConfig, Graphic, BasemapToggle]) => {
    esriConfig.url = esriPortalConfig.url;

    const map = new Map({ basemap: "hybrid" });

    const view = new MapView({
      map: map,
      center: [longitude, latitude],
      container: mapDiv.current,
      zoom: 18,
      title: "Map Canvas",
    });
    if (longitude && latitude) {
      var point = {
        type: "point",
        longitude: longitude,
        latitude: latitude,
      };

      var simpleMarkerSymbol = {
        type: "simple-marker",
        color: [226, 119, 40],
        outline: {
          color: [255, 255, 255],
          width: 1,
        },
      };

      var pointGraphic = new Graphic({
        geometry: point,
        symbol: simpleMarkerSymbol,
        attributes: { Name: "New Property", Description: address },
      });

      view.graphics.add(pointGraphic);
      view.popup.open({
        title: address ? address : "New Property",
        location: { latitude: latitude, longitude: longitude },
        content: "[" + latitude + ", " + longitude + "]",
      });
    }

    view.popup.autoOpenEnabled = false; // 1 - Create the widget
    var toggle = new BasemapToggle({
      // 2 - Set properties
      view: view, // view that provides access to the map's 'topo-vector' basemap
      nextBasemap: "topo", // allows for toggling to the 'hybrid' basemap
    });

    // Add widget to the top right corner of the view
    view.ui.add(toggle, "bottom-right");

    view.on("click", function (event) {
      view.popup.open({
        title:
          "Location GeoCode: [" + event.mapPoint.latitude.toFixed(5) + ", " + event.mapPoint.longitude.toFixed(5) + "]",
        location: event.mapPoint,
        content: "You clicked here. Way to go!",
      });
    });
  });
  return <div className="mapDiv" ref={mapDiv}></div>;
}

export default SearchMap;
