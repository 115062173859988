import React from "react";
import { Card, Form, Row, Col, Button, Image, Modal, Tabs, Tab, Spinner } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import styles from "./Properties.module.scss";
import globalStyles from "../../../OARS.module.scss";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ChevronDown from "../../../assets/chevron-down.svg";
import ChevronRight from "../../../assets/chevron-right.svg";
import CooperatorIcon from "../../../assets/cooperator.svg";
import PropertyIcon from "../../../assets/property.svg";
import AcresIcon from "../../../assets/acres.svg";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import PropertiesAddressesDisplay from "./propertyAddressDisplay";
import AddressDisplay from "../../common/AddressDisplay";
import AsyncSelect from "react-select/async";
import SuccessfulRecordModifiedModal from "../../common/create-updateOkMessageModal";
import AgreementAPI from "../../../api/AgreementEntities/AgreementAPI";
import ConflictsAPI from "../../../api/Conflicts/ConflictsAPI";
import InventoryAPI from "../../../api/Inventory/InventoryAPI";
import menuIcon from "../../../assets/menu.svg";
import CreateConflictModal from "../../Conflicts/CreateConflictModal";
import { toast } from "react-toastify";
import WebMap from "../../ESRIIntegration/SiteVisitMap";
import ParcelMapView from "../../ESRIIntegration/SiteVisitParcelMap";
import GeoCoding from "../../common/GeoCoding";
import UtilityFunctions from "../../common/UtilityFunctions";
import EnterLossModal from "./PropertyVisitSummary/EnterLossModal";
import WorkTaskAPI from "../../../api/WorkTask/WorkTaskAPI";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

const WorkRecordTabs = {
  Conflicts: "conflictsTab",
  WorkTasks: "workTasksTab",
  DirectControl: "directControlTab",
};

const PermissionsTabs = {
  Species: "speciesTab",
  Methods: "methodsTab",
  SpecialInstructions: "specialInstructionsTab",
};

class DisplayEditProperty extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      isDetailsPage: this.props.mode === "details",
      validated: false,
      showContactAddModal: false,
      propertyUniqueIdentifier: this.props.history.location?.state?.entityData?.propertyUniqueIdentifier,
      propertyName: "",
      propertyType: "",
      parcelCount: 0,
      landClass: "",
      district: "",
      countyName: "",
      county: "",
      // TODO - After API Integration
      // county: propertyData.county.displayText,
      mapPoint: "",
      acres: 0,
      calculatedAcres: 0,
      landClassEnumId: "",
      defaultCooperatorUniqueIdentifier: "",
      countryUniqueIdentifier: "",
      stateGuid: "",
      propertyAddress: "",
      additionalStreetAddresses: [],
      addresses: [],
      contacts: [],
      contactsToRemove: [],
      usaStates: [],
      countries: [],
      landClasses: [],
      errors: {},
      displayedAgreement: null,
      nonActiveAgreements: [],
      checkedForAgreements: false,
      primaryCooperator: "",
      conflicts: [],
      currentWorkRecordsTab: WorkRecordTabs.DirectControl,
      showCreateConflictModal: false,
      parcels: [],
      propertyVisitSummaryObj: [],
      propertyMethodInventory: [],
      propertyMethodInventoryRowSelected: [],
      propertyMethodInventoryRowExpanded: [],
      propertyMethodInventoryRowNotExpandable: [],
      highlightedMapPointMethod: {},
      allMethodUses: [],
      allMethodUOMs: [],
      activeInformationTab: 1,
      inventoryMapItems: [],
      protectedResourcesData: [],
      coopTAWorkTasks: [],
      currentResourceRecord: "",
      showLossModal: false,
      showBadWeatherCheckModal: false,
      submitBadWeatherCheckButtonDisabled: false,
      badWeatherCheckDate: "",
      isFormLoading: true,
      protectedResourcesDataColumns: [
        { dataField: "resourceTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      propertyMethodInventoryColumns: [
        {
          dataField: "methodUniqueIdentifier",
          hidden: true,
        },
        {
          dataField: "methodName",
          text: "",
          formatter: (cell, row) => {
            return (
              <Row noGutters="true">
                <Col>
                  <p className={styles.methodNameCells}>{cell + " "}</p>
                  <p className="quantity-measurement" style={{ marginBottom: "0", textIndent: "1em" }}>
                    {"(" + row.methodQuantity + " " + row.methodUOM + ")"}
                  </p>
                </Col>
              </Row>
            );
          },
          align: "left",
          style: {
            color: "#222222",
            fontWeight: 500,
            fontSize: "14px",
            padding: "4.8px",
            verticalAlign: "middle",
            borderBottom: "1px solid #dee2e6",
          },
          headerStyle: () => {
            return { borderTop: "hidden", borderBottom: "hidden" };
          },
        },
      ],
      propertyMethodInventorySubTableColumns: [
        {
          dataField: "deployedItemUniqueIdentifier",
          hidden: true,
        },
        {
          dataField: "methodName",
          text: "Method",
          formatter: (cell) => {
            return (
              <Row>
                <Col>{cell}</Col>
              </Row>
            );
          },
          align: "left",
          style: { color: "#222222", fontWeight: 500, fontSize: "12px", padding: "4.8px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
            };
          },
        },
        {
          dataField: "displayQuantity",
          text: "Quantity",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "deployedWithUnitOfMeasureEnumId",
          text: "UOM",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          formatter: (cell) => {
            let UOM = this.state.allMethodUOMs.find((UOM) => UOM.id === cell);
            return UOM.displayText;
          },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "status",
          text: "Status",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "targets",
          text: "Target",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "protects",
          text: "Protecting",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "latitude",
          text: "Latitude",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          formatter: (cell) => {
            if (!cell) {
              return "";
            } else {
              return cell;
            }
          },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "longitude",
          text: "Longitude",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          formatter: (cell) => {
            if (!cell) {
              return "";
            } else {
              return cell;
            }
          },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
      ],
    };
  }

  async componentDidMount() {
    if (this.state.propertyUniqueIdentifier) {
      let contacts = "";
      let getPropertyCall = await AgreementEntitiesAPI.GetProperty(
        this.state.propertyUniqueIdentifier,
        this.controller.signal
      );
      if (getPropertyCall?.successful) {
        if (getPropertyCall?.data?.contacts) {
          contacts = getPropertyCall.data.contacts;
        }
        let propertyData = getPropertyCall.data;

        if (propertyData.propertyType?.id) {
          propertyData.propertyType = UtilityFunctions.getDisplayTextFromFieldObject(propertyData.propertyType);
        }

        let addressArray = [];
        let propertyAddress = "";
        if (propertyData.additionalStreetAddresses != null) {
          for (var i = 0; i < propertyData.additionalStreetAddresses.length; i++) {
            addressArray.push({
              street: propertyData.additionalStreetAddresses[i].streetAddress,
              city: propertyData.additionalStreetAddresses[i].city,
              zipCode: propertyData.additionalStreetAddresses[i].zipCode,
              country: propertyData.countryUniqueIdentifier,
              state: propertyData.state,
            });
          }
          if (propertyData.additionalStreetAddresses.length > 0) {
            propertyAddress = this.getPropertyAddress(
              propertyData.state,
              this.props.history.location.state.states,
              propertyData.additionalStreetAddresses[0]
            );
          }
        }

        let countyName = propertyData.county;

        let parcels = getPropertyCall.data.parcels;

        let totalAcres = 0;
        parcels.forEach((parcel) => {
          totalAcres += parcel.acres;
        });

        await this.findMostRecentPVS(getPropertyCall?.data?.propertyUniqueIdentifier);

        this.setState({
          contacts: contacts,
          parcels: parcels,
          calculatedAcres: totalAcres,
          protectedResourcesDataColumns: this.getResourceDataColumns(),
          protectedResourcesData: await this.getProtectedResourcesData(getPropertyCall?.data?.propertyUniqueIdentifier),
          coopTAWorkTasks: await this.getTAWorkTasksOnProperty(getPropertyCall?.data?.propertyUniqueIdentifier),
          propertyName: propertyData.propertyName,
          propertyType: propertyData.propertyType,
          parcelCount: propertyData.parcelCount,
          landClass: propertyData.landClass?.displayText,
          district: propertyData.district,
          countyName: countyName,
          county: propertyData.county,
          // TODO - After API Integration
          // county: propertyData.county.displayText,
          mapPoint: JSON.stringify([propertyData.latitude?.toString(), propertyData.longitude?.toString()]),
          acres: propertyData.acres,
          landClassEnumId: propertyData.landClass?.id,
          defaultCooperatorUniqueIdentifier: propertyData.defaultCooperatorUniqueIdentifier,
          countryUniqueIdentifier: propertyData.countryUniqueIdentifier,
          stateGuid: propertyData.state,
          propertyAddress: propertyAddress,
          additionalStreetAddresses: propertyData.additionalStreetAddresses,
          addresses: addressArray,
        });
      } else {
        if (this.controller.signal.aborted) {
          return;
        }
        ErrorHandler.showError("Unable to retrieve Property Record.");
      }
    }
    await this.getDataFromAPI();

    if (this.state.contacts !== undefined) {
      for (let i = 0; i < this.state.contacts.length; i++) {
        let tempContacts = this.state.contacts;
        if (!tempContacts[i].firstName) {
          let contact = await this.getContactFromIdReturnName(tempContacts[i].contactUniqueIdentifier);
          tempContacts[i].firstName = contact.firstName;
          tempContacts[i].lastName = contact.lastName;
          tempContacts[i].contactRole = contact.typeOfContact;
        }
        this.setState({ contacts: tempContacts });
      }
    }
    if (this.state.addresses.length < 1) {
      const usaObject = this.state.countries.find((country) => country.name === "United States of America");
      this.setState({ countryUniqueIdentifier: usaObject.id });
    }

    window.scrollTo(0, 0);
    this.setState({ isFormLoading: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    let changeToConflictsTab =
      prevState.currentWorkRecordsTab !== this.state.currentWorkRecordsTab &&
      this.state.currentWorkRecordsTab === WorkRecordTabs.Conflicts;
    if (changeToConflictsTab) {
      await this.getConflictsData();
    }
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("DisplayEditProperty.jsx got unmounted");
    }
  }

  fixAddressStateAndCountry() {
    let addressArray = [];
    if (this.state.addresses != null) {
      for (var i = 0; i < this.state.addresses.length; i++) {
        addressArray.push({
          street: this.state.addresses[i].street,
          city: this.state.addresses[i].city,
          zipCode: this.state.addresses[i].zipCode,
          country: this.state.countries.find((country) => country.id === this.state.countryUniqueIdentifier)?.name,
          state: this.state.usaStates.find((state) => state.id === this.state.stateGuid)?.name,
        });
      }
    }
    this.setState({ addresses: addressArray });
  }

  async findMostRecentPVS(propertyGuid) {
    const getAllPVSCall = await WorkTaskAPI.GetAllPVSForSingleProperty(propertyGuid);
    if (getAllPVSCall?.successful) {
      let sortedPVS = getAllPVSCall?.data?.results?.sort(
        (a, b) => new Date(a.dateEntered).getTime() - new Date(b.dateEntered).getTime()
      );
      let mostRecentPVS = sortedPVS[sortedPVS.length - 1];
      if (mostRecentPVS) {
        const getPVSCall = await WorkTaskAPI.GetPVS(mostRecentPVS.propertyVisitSummaryUniqueIdentifier);
        if (getPVSCall?.successful) {
          const fullPVSObj = getPVSCall?.data;
          this.setState({
            propertyVisitSummaryObj: fullPVSObj,
          });
        } else if (getPVSCall?.unsuccessful) {
          toast.warning(getPVSCall?.message);
        } else {
          toast.warning("Error getting Site Visit.");
        }
      }
    }
  }

  async getDataFromAPI() {
    //Get States data
    if (this.state.usaStates.length === 0) {
      let result = await ReferenceFileAPI.GetAllStates();
      let states = result
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      this.setState({ usaStates: states });
    }
    //Get Countries data
    if (this.state.countries.length === 0) {
      let result = await ReferenceFileAPI.GetAllCountries();
      let countries = result
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      this.setState({ countries: countries });
    }
    this.fixAddressStateAndCountry();

    await this.getConflictsData();

    let counties = "";
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (currentUsaState?.stateGuid) {
      counties = await this.getCountyListbyState(currentUsaState.stateGuid);
    }

    //get landclasses
    const landClasses = await this.getLandClasses();
    this.setState({
      landClasses: landClasses,
      counties: counties,
    });

    if (!this.state.checkedForAgreements) {
      let data = this.state;
      let agreementSearch = await AgreementAPI.GetAgreementsByProperty(data.propertyUniqueIdentifier);
      if (agreementSearch?.successful) {
        let allFoundAgreements = agreementSearch.data;
        let displayedAgreementIndex = this.findAgreementToDisplay(allFoundAgreements);
        if (displayedAgreementIndex !== -1) {
          data.displayedAgreement = allFoundAgreements.splice(displayedAgreementIndex, 1)[0];
          let cooperatorNameCall = await AgreementEntitiesAPI.GetContact(
            data.displayedAgreement?.cooperator?.contactUniqueReference
          );
          const activeAgreementData = await AgreementAPI.GetAgreement(
            data.displayedAgreement.agreementUniqueIdentifier
          );
          if (activeAgreementData?.successful) {
            data.displayedAgreement.additionalContacts = await this.getAgreementAssociatedContactsData(
              activeAgreementData.data
            );
            data.displayedAgreement.specialInstructions = activeAgreementData.data.specialInstructions;
            data.displayedAgreement.agreementProperty = activeAgreementData.data?.agreementProperties?.find(
              (property) => property.propertyUniqueReference === this.state.propertyUniqueIdentifier
            );
          }
          let cooperatorFullName = "";
          let cooperatorFullAddress = "";
          if (cooperatorNameCall?.data) {
            cooperatorFullName =
              cooperatorNameCall.data.firstName +
              " " +
              cooperatorNameCall.data.middleName +
              " " +
              cooperatorNameCall.data.lastName;
          }
          if (cooperatorNameCall?.data?.primaryMailingAddress) {
            let addressObj = cooperatorNameCall.data.primaryMailingAddress;
            let state = await ReferenceFileAPI.GetState(addressObj.mailingStateUniqueId);
            let stateCode = state.state_code;
            cooperatorFullAddress =
              addressObj.mailingStreet +
              ", " +
              addressObj.mailingCity +
              ", " +
              stateCode +
              ", " +
              addressObj.mailingZip;
          }
          if (data.displayedAgreement.cooperatorName) {
            data.displayedAgreement.cooperatorName.cooperatorFullName = cooperatorFullName;
            data.displayedAgreement.cooperatorName.cooperatorFullAddress = cooperatorFullAddress;
            data.displayedAgreement.cooperatorName.phoneNumbers = cooperatorNameCall?.data?.phoneNumbers;
            data.displayedAgreement.cooperatorName.emailAddress = cooperatorNameCall?.data?.emailAddress;
          }
        }
        data.nonActiveAgreements = allFoundAgreements;
      }
      data.checkedForAgreements = true;
      this.setState({ data });

      if (this.state.allMethodUses.length === 0) {
        const usesGetCall = await ReferenceFileAPI.GetAllMethodUses();
        this.setState({ allMethodUses: usesGetCall?.data });
      }
      if (this.state.allMethodUOMs.length === 0) {
        const uomsGetCall = await ReferenceFileAPI.GetAllMethodUOMs();
        this.setState({ allMethodUOMs: uomsGetCall?.data });
      }
      await this.refreshPropertyMethodInventory();
    }

    if (this.state.defaultCooperatorUniqueIdentifier) {
      let cooperatorDetails = await AgreementEntitiesAPI.GetContact(this.state.defaultCooperatorUniqueIdentifier);
      if (cooperatorDetails?.successful) {
        let cooperatorFullName = cooperatorDetails.data.firstName + " " + cooperatorDetails.data.lastName;
        this.setState({
          primaryCooperator: { value: this.state.defaultCooperatorUniqueIdentifier, label: cooperatorFullName },
        });
      }
    }
  }

  async getAgreementAssociatedContactsData(agreementData) {
    let associatedAgreementContactsData = [];

    for (const contact of agreementData?.additionalContacts) {
      let contactData = await this.getContactData(contact.contactUniqueReference);
      if (contactData) {
        contactData.contactRole = UtilityFunctions.getDisplayTextFromFieldObject(contact.contactRoleOnAgreement);
        associatedAgreementContactsData.push(contactData);
      }
    }

    return associatedAgreementContactsData;
  }

  async getContactData(contactUniqueId) {
    const contactDataResponse = await AgreementEntitiesAPI.GetContact(contactUniqueId);
    if (!contactDataResponse?.successful) {
      return null;
    }
    let contactData = contactDataResponse.data;
    contactData.fullName = UtilityFunctions.getContactNameFromDataObject(contactData);
    return contactData;
  }

  async getProtectedResourcesData(propertyGuid) {
    let resources = [];
    if (propertyGuid) {
      const activeAgreementData = await AgreementAPI.GetAgreementPropertyFromActiveAgreement(propertyGuid);
      if (activeAgreementData?.successful) {
        resources = activeAgreementData?.data?.agreementResources
          ? activeAgreementData?.data?.agreementResources.filter((agreementResource) => !agreementResource.isHistorical)
          : [];
      } else if (!activeAgreementData?.unsuccessful) {
        toast.warn("Could not retreive Property Resources");
      }
    }
    return resources;
  }

  async getTAWorkTasksOnProperty(propertyGuid) {
    let workTasks = [];
    if (propertyGuid) {
      const workTaskData = await WorkTaskAPI.GetCoopWorkTasksOnProperty(propertyGuid);
      if (workTaskData?.successful) {
        workTasks = workTaskData.data;
      } else if (workTaskData?.unsuccessful) {
        toast.warn("Could not retreive TA Work Tasks: " + workTaskData.message);
      } else {
        toast.warn("Could not retreive TA Work Tasks");
      }
    }
    return workTasks;
  }

  getResourceDataColumns() {
    return [
      {
        dataField: "resourceUniqueReference",
        hidden: true,
      },
      {
        dataField: "valuationYear",
        text: "Year",
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { width: "10%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "resourceName",
        text: "Resource",
        headerStyle: () => {
          return { width: "25%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "quantity",
        text: "Quantity",
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { width: "10%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "valuationUnitOfMeasure.name",
        text: "UOMs",
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { width: "10%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        editable: false,
      },
      {
        dataField: "valuePerUnitOfMeasure",
        text: "Unit Value",
        formatter: (cell, row) => {
          return UtilityFunctions.getValueAsCurrency(row.valuePerUnitOfMeasure);
        },
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { width: "15%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "totalValuation",
        text: "Total Value",
        formatter: (cell, row) => {
          return UtilityFunctions.getValueAsCurrency(row.totalValuation);
        },
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { width: "40%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "enterLossButton",
        text: "",
        formatter: (cell, row) => {
          return (
            <Button variant="link" onClick={() => this.enterLossModal(row)} className="text-right py-0 my-0">
              <span className={styles.tableLinksText}>Enter Loss</span>
            </Button>
          );
        },
        headerStyle: () => {
          return { width: "30%", borderBottom: "0.8px solid #008767" };
        },
        style: () => {
          return { paddingRight: "0px", paddingLeft: "0px" };
        },
        headerAlign: "right",
        align: "right",
      },
    ];
  }

  enterLossModal = (row) => {
    this.setState({ currentResourceRecord: row, showLossModal: true });
  };

  closeLossModal = (row) => {
    this.setState({ showLossModal: false });
  };

  saveInventoryLoss = async (lossData) => {
    const createLossResponse = await WorkTaskAPI.CreateLoss(this.getCreateLossBody(lossData));
    if (createLossResponse?.successful) {
      toast.success("Loss was successfully recorded.");
    } else {
      ErrorHandler.showErrorWithDetails(
        { error: createLossResponse.message },
        "Loss could not be added to the Protected Resource Inventory."
      );
    }
  };

  getCreateLossBody(lossData) {
    const totalLossValue = Number.parseFloat(lossData.lossAmount);
    const lossQuantity = Number.parseInt(lossData.lossQuantity);
    const createLossBody = {
      propertyUniqueReference: this.state.data.propertyUniqueIdentifier,
      dateLossOccurred: new Date(lossData.lossDate).toISOString(),
      resourceUniqueReferece: lossData.resourceUniqueId,
      resourceUOMEnumId: lossData.lossUom?.value,
      unitsLostQuantity: lossQuantity,
      monetaryLossUnitValue: totalLossValue ? totalLossValue / lossQuantity : null,
      monetaryLossTotalValue: totalLossValue ? totalLossValue : null,
      speciesUniqueReference: lossData.selectedSpecies?.value,
      damageUniqueReference: lossData.selectedDamage?.value,
      diseaseUniqueReference: lossData.selectedDisease?.value ? lossData.selectedDisease.value : null,
      isVerified: lossData.reportedOrVerified === "Verified",
      isInventoryLoss: true,
    };
    return createLossBody;
  }

  async refreshPropertyMethodInventory() {
    const deployedItems = await InventoryAPI.GetDeployedItemsOnProperty(this.state.propertyUniqueIdentifier);
    if (deployedItems?.unsuccessful) {
      toast.warning(deployedItems.message);
    }
    const deployedItemsData = deployedItems?.data;
    let propertyMethodInventory = [];
    for (let i = 0; i < deployedItemsData?.length; i++) {
      let item = deployedItemsData[i];
      let UOM = this.state.allMethodUOMs.find((UOM) => UOM.id === item.deployedWithUnitOfMeasureEnumId);
      let parentRowObject = {
        methodUniqueIdentifier: item.methodUniqueReference,
        methodName: item.methodName,
        methodQuantity: 0,
        methodUOM: UOM?.displayText,
        propertyMethodInventorySubTable: [],
        propertyMethodInventorySubTableSelected: [],
      };
      let isDuplicateParent = false;
      propertyMethodInventory = propertyMethodInventory.filter(function (row) {
        if (row.methodUniqueIdentifier === parentRowObject.methodUniqueIdentifier) {
          isDuplicateParent = true;
        }
        return true;
      });
      if (!isDuplicateParent) {
        propertyMethodInventory.push(parentRowObject);
      }
      for (let i = 0; i < propertyMethodInventory.length; i++) {
        const row = propertyMethodInventory[i];
        if (row.methodUniqueIdentifier === item.methodUniqueReference) {
          let duplicateItem = false;
          for (let j = 0; j < row.propertyMethodInventorySubTable.length; j++) {
            let currentItem = row.propertyMethodInventorySubTable[j];
            currentItem.targets = this.getSpeciesStringForInventoryItem(currentItem.speciesItemIsTargetedBy);
            currentItem.protects = this.getResourceStringForInventoryItem(currentItem.resourcesItemIsProtecting);
            if (
              currentItem.deployedWithUnitOfMeasureEnumId === item.deployedWithUnitOfMeasureEnumId &&
              currentItem.deployedWithMethodAllowedUseEnumId === item.deployedWithMethodAllowedUseEnumId &&
              currentItem.status === item.status &&
              !currentItem.latitude &&
              !currentItem.longitude &&
              !item.latitude &&
              !item.longitude
            ) {
              duplicateItem = true;
              item.quantityDeployed = 1;
              currentItem.aggregateDeployedItems.push({
                deployedItemUniqueIdentifier: item.deployedItemUniqueIdentifier,
                inventoryItemUniqueReference: item.inventoryItemUniqueReference,
                quantityDeployed: item.quantityDeployed,
              });
              currentItem.displayQuantity += item.quantityDeployed;
              row.methodQuantity += item.quantityDeployed;
            }
          }
          if (!duplicateItem) {
            item.quantityDeployed = 1;
            item.aggregateDeployedItems = [
              {
                deployedItemUniqueIdentifier: item.deployedItemUniqueIdentifier,
                inventoryItemUniqueReference: item.inventoryItemUniqueReference,
                quantityDeployed: item.quantityDeployed,
              },
            ];
            item.displayQuantity = item.quantityDeployed;
            item.targets = this.getSpeciesStringForInventoryItem(item.speciesItemIsTargetedBy);
            item.protects = this.getResourceStringForInventoryItem(item.resourcesItemIsProtecting);
            row.propertyMethodInventorySubTable.push(item);
            row.methodQuantity += item.displayQuantity;
          }
        }
      }
    }
    this.setState({ propertyMethodInventoryRowSelected: [], propertyMethodInventory: propertyMethodInventory });
  }

  buildMapPointsFromInventory(deployedItems) {
    let mapPoints = [];
    deployedItems.forEach((item) => {
      if (item.latitude && item.longitude) {
        mapPoints = [...mapPoints, item];
      }
    });
    this.setState({ inventoryMapItems: mapPoints });
  }

  getSpeciesStringForInventoryItem(speciesItemIsTargetedBy) {
    let result = "";
    let buildArray = speciesItemIsTargetedBy.map((guid) => {
      return this.state.conflicts.find((c) => c.targetedSpeciesUniqueReference === guid)?.targetedSpeciesName;
    });
    if (buildArray.length > 0) {
      result = buildArray.join(", ");
    }
    return result;
  }

  getResourceStringForInventoryItem(resourcesItemIsProtecting) {
    let result = "";
    //possible fix coming from backend so conflict call from properties has resources
    let buildArray = resourcesItemIsProtecting.map((guid) => {
      return this.state.conflicts.map((conflict) => {
        return conflict.properties.map((property) => {
          if (property.propertyUniqueReference === this.state.propertyUniqueIdentifier) {
            return property.protectedResources.map((resource) => {
              if (resource.protectedResourceUniqueReference === guid) {
                return resource.protectedResourceName;
              }
            });
          }
        });
      });
    });
    if (buildArray.length > 0) {
      result = buildArray.join(", ");
    }
    return result;
  }

  handlePropertyMethodInventoryRowOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      this.setState(() => ({
        propertyMethodInventoryRowExpanded: [
          ...this.state.propertyMethodInventoryRowExpanded,
          row?.methodUniqueIdentifier,
        ],
      }));
    } else {
      this.setState(() => ({
        propertyMethodInventoryRowExpanded: this.state.propertyMethodInventoryRowExpanded.filter(
          (x) => x !== row?.methodUniqueIdentifier
        ),
      }));
    }
  };

  handlePropertyMethodInventoryRowOnSelect = (row, isSelect) => {
    if (isSelect) {
      for (let i = 0; i < row.propertyMethodInventorySubTable.length; i++) {
        row.propertyMethodInventorySubTableSelected.push(
          row?.propertyMethodInventorySubTable[i]?.deployedItemUniqueIdentifier
        );
      }
      this.setState({
        propertyMethodInventoryRowSelected: [
          ...this.state.propertyMethodInventoryRowSelected,
          row?.methodUniqueIdentifier,
        ],
      });
    } else {
      row.propertyMethodInventorySubTableSelected = [];
      this.setState({
        propertyMethodInventoryRowSelected: this.state.propertyMethodInventoryRowSelected.filter(
          (x) => x !== row?.methodUniqueIdentifier
        ),
      });
    }
    this.updateInventoryMapItems();
  };

  handleSubRowOnSelect = (row, isSelect) => {
    let propertyMethodInventory = this.state.propertyMethodInventory;
    let deployedItemUniqueIdentifier = row.deployedItemUniqueIdentifier;
    let methodUniqueIdentifier = row.methodUniqueReference;
    let removeParentSelect = false;
    if (isSelect) {
      if (!this.state.propertyMethodInventoryRowSelected.includes(methodUniqueIdentifier)) {
        this.setState({
          propertyMethodInventoryRowSelected: [
            ...this.state.propertyMethodInventoryRowSelected,
            methodUniqueIdentifier,
          ],
        });
      }
      propertyMethodInventory.filter(function (item) {
        if (item.methodUniqueIdentifier === row.methodUniqueReference) {
          item.propertyMethodInventorySubTableSelected.push(deployedItemUniqueIdentifier);
        }
        return item;
      });
      this.setState({ propertyMethodInventory: propertyMethodInventory });
    } else {
      propertyMethodInventory.filter(function (item) {
        if (item.methodUniqueIdentifier === row.methodUniqueReference) {
          item.propertyMethodInventorySubTableSelected = item.propertyMethodInventorySubTableSelected.filter(
            (x) => x !== row?.deployedItemUniqueIdentifier
          );
        }
        if (item.propertyMethodInventorySubTableSelected.length < 1) {
          removeParentSelect = true;
        }
        return item;
      });
      if (removeParentSelect) {
        this.setState({
          propertyMethodInventoryRowSelected: this.state.propertyMethodInventoryRowSelected.filter(
            (x) => x !== row?.methodUniqueReference
          ),
        });
      }
      this.setState({ propertyMethodInventory: propertyMethodInventory });
    }
    this.updateInventoryMapItems();
  };

  updateInventoryMapItems() {
    let selectedInventoryItems = [];
    for (let method of this.state.propertyMethodInventory) {
      if (method.propertyMethodInventorySubTableSelected?.length > 0) {
        method.propertyMethodInventorySubTable.forEach((item) => {
          if (
            method.propertyMethodInventorySubTableSelected.includes(item.deployedItemUniqueIdentifier) &&
            item.latitude &&
            item.longitude
          ) {
            selectedInventoryItems.push(item);
          }
        });
      }
    }
    this.setState({ inventoryMapItems: selectedInventoryItems });
  }

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const parentIds = rows.map((row) => {
        row.propertyMethodInventorySubTableSelected = [];
        row.propertyMethodInventorySubTable.forEach((subRow) => {
          row.propertyMethodInventorySubTableSelected.push(subRow.deployedItemUniqueIdentifier);
        });
        return row.methodUniqueIdentifier;
      });
      this.setState({ propertyMethodInventoryRowSelected: parentIds });
    } else {
      rows.forEach((row) => {
        row.propertyMethodInventorySubTableSelected = [];
      });
      this.setState({ propertyMethodInventoryRowSelected: [] });
    }
    this.updateInventoryMapItems();
  };

  async getCountyListbyState(stateGuid) {
    const counties = await ReferenceFileAPI.GetCountiesByState(stateGuid);
    let countyList = [];
    if (counties) {
      countyList = counties
        .map((county) => ({
          id: county.countyUniqueIdentifier,
          name: county.county_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    return countyList;
  }

  findAgreementToDisplay(agreements) {
    let result = -1;
    for (var i = 4; i > 0; i--) {
      let agreementObj = agreements?.find((agreement) => parseInt(agreement?.agreementStatus?.id) === i);
      if (agreementObj) {
        result = agreements.indexOf(agreementObj);
        break;
      }
    }
    return result;
  }

  async getConflictsData() {
    if (this.state.propertyUniqueIdentifier) {
      this.setState({ isConflictsListLoading: true });
      const conflictsResponse = await ConflictsAPI.GetConflictsByProperty(this.state.propertyUniqueIdentifier);
      if (conflictsResponse?.successful) {
        const conflictResults = conflictsResponse?.data?.results;
        this.setState({ conflicts: conflictResults });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve property's conflicts",
          apiName: "GetConflictsByProperty",
          responseUnsuccessful: conflictsResponse?.unsuccessful,
          responseMessage: conflictsResponse?.message,
        });
      }
    }
    this.setState({ isConflictsListLoading: false });
  }

  async getLandClasses() {
    const landClasses = await AgreementEntitiesAPI.GetAllPropertyLandClasses();
    if (!landClasses) return [];
    return landClasses.data;
  }

  handleSubmitWrapper = async (e) => {
    e.preventDefault();
    await this.handleSubmit(e);
  };

  async handleSubmit(e) {
    if (
      this.state.addresses[0].street === "" &&
      this.state.addresses[0].city === "" &&
      this.state.addresses[0].state === ""
    ) {
      toast.warning("Property address must be provided");
      return;
    }
    let propertyAddress = "";
    let mapPoint = "";
    if (this.state.addresses != null) {
      let address = this.state.addresses[0];
      propertyAddress = address.street.concat(", ", address.city, ", ", address.state, ", ", address.zipCode);

      if (this.state.mapPoint !== undefined) {
        mapPoint = await GeoCoding.getLocationByAddress(propertyAddress);
      }
    }

    let updatePropertyBody = {
      propertyName: this.state.propertyName,
      propertyAbbreviation: "",
      landClassEnumId: parseInt(this.state.landClassEnumId, 10),
      acres: parseFloat(this.state.acres, 10),
      district: this.state.district,
      county: this.state.county,
      state: this.state.addresses[0]?.state
        ? this.state.usaStates.find((states) => states.name === this.state.addresses[0]?.state)?.id
        : "",
      country: this.state.countryUniqueIdentifier,
      latitude: mapPoint[0] ? parseFloat(mapPoint[0]) : null,
      longitude: mapPoint[1] ? parseFloat(mapPoint[1]) : null,
      defaultCooperatorUniqueIdentifier: this.state.primaryCooperator.value,
      countryUniqueIdentifier: this.state.addresses[0]?.country
        ? this.state.countries.find((country) => country.name === this.state.addresses[0]?.country)?.id
        : "",
      parcelCount: this.state.parcelCount,
    };

    let addressUpdate = false;

    let additionalAddresses = [];
    if (this.state.addresses != null) {
      for (var j = 0; j < this.state.addresses.length; j++) {
        additionalAddresses.push({
          streetAddress: this.state.addresses[j].street,
          city: this.state.addresses[j].city,
          zipCode: this.state.addresses[j].zipCode,
        });
      }

      let updateAddressAPIResult = await AgreementEntitiesAPI.ReplaceAdditionalAddressesOnProperty(
        this.state.propertyUniqueIdentifier,
        additionalAddresses
      );
      if (updateAddressAPIResult) {
        if (updateAddressAPIResult.successful) {
          addressUpdate = true;
        }
      }
    }
    if (this.state.contacts != null) {
      for (let i = 0; i < this.state.contacts.length; i++) {
        let updatePropertyAssociatedContact = await AgreementEntitiesAPI.AddContactToProperty(
          this.state.propertyUniqueIdentifier,
          this.state.contacts[i].contactUniqueIdentifier,
          this.state.contacts[i].contactRole.id
        );
        if (updatePropertyAssociatedContact) {
          if (updatePropertyAssociatedContact.successful) {
            console.log("success");
          }
        } else {
          console.log("failure");
        }
      }
    }

    if (this.state.contactsToRemove != null) {
      for (let k = 0; k < this.state.contactsToRemove.length; k++) {
        await AgreementEntitiesAPI.RemoveContactFromProperty(
          this.state.propertyUniqueIdentifier,
          this.state.contactsToRemove[k].contactUniqueIdentifier
        );
      }
    }

    let updateAPIResult = await AgreementEntitiesAPI.UpdateProperty(
      this.state.propertyUniqueIdentifier,
      updatePropertyBody
    );
    if (updateAPIResult) {
      if (updateAPIResult.successful || addressUpdate) {
        this.setState({ showSuccessfullCreateModal: true });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update property",
          apiName: "UpdateProperty",
          responseUnsuccessful: updateAPIResult?.unsuccessful,
          responseMessage: updateAPIResult?.message,
        });
      }
    }
  }

  getPropertyAddress(stateGuid, usaStates, address) {
    let stateName = usaStates?.find((state) => state.stateUniqueIdentifier === stateGuid)?.state_name;
    const propertyAddress = address.streetAddress.concat(", ", address.city, ", ", stateName, ", ", address.zipCode);
    return propertyAddress;
  }

  handleCooperatorSearch = async (inputValue) => {
    return await this.getCooperatorsFromAPI(inputValue);
  };

  getCooperatorBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    };
  }

  async getCooperatorsFromAPI(searchValue) {
    const contactsData = await AgreementEntitiesAPI.SearchContactFreeText(this.getCooperatorBody(searchValue));

    if (contactsData.data.results) {
      let results = contactsData.data.results;
      return results.map((result) => ({
        value: result.contactUniqueId,
        label: result.firstName.concat(" ", result.middleName, " ", result.lastName),
      }));
    }

    return "";
  }

  handleStateChange = ({ currentTarget: input }) => {
    let propertyName = input.id;

    let data = this.state;
    data[propertyName] = input.value;
    if (input.id === "landClassEnumId") {
      let newLandClass = this.state.landClasses?.find((landClass) => parseInt(landClass.id) === parseInt(input.value));
      data.landClass = newLandClass.displayText;
    }
    this.setState({ data });
  };

  propertyName = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.propertyName}</span>;
    } else {
      return (
        <Form.Control
          id="propertyName"
          defaultValue={this.state.propertyName}
          aria-label="Property Name"
          required
          type="text"
          onChange={this.handleStateChange}
        />
      );
    }
  };

  acres = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.acres}</span>;
    } else {
      return (
        <Form.Control
          id="acres"
          defaultValue={this.state.acres}
          required
          aria-label="acres"
          type="text"
          onChange={this.handleStateChange}
        />
      );
    }
  };

  calculatedAcres = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.calculatedAcres}</span>;
    } else {
      return (
        <Form.Control
          id="calculatedAcres"
          readonly="readonly"
          defaultValue={this.state.calculatedAcres}
          required
          aria-label="calculated acres"
          type="text"
          onChange={this.handleStateChange}
        />
      );
    }
  };

  landClass = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.landClass}</span>;
    } else {
      return (
        <Form.Control
          id="landClassEnumId"
          value={this.state.landClassEnumId}
          required
          aria-label="land class"
          as="select"
          type="text"
          onChange={this.handleStateChange}
        >
          {this.state.landClasses.map((type) => (
            <option value={type.id} key={type.id}>
              {type.displayText}
            </option>
          ))}
        </Form.Control>
      );
    }
  };

  county = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.countyName}</span>;
    } else {
      return (
        <Form.Control
          id="county"
          value={this.state.county}
          required
          aria-label="county"
          as="select"
          type="text"
          onChange={this.handleStateChange}
        >
          <option>Select County</option>
          {this.state.counties?.map((type) => (
            <option value={type.name} key={type.id}>
              {type.name}
            </option>
          ))}
        </Form.Control>
      );
    }
  };

  district = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.district}</span>;
    } else {
      return (
        <Form.Control
          id="district"
          defaultValue={this.state.district}
          required
          aria-label="district"
          type="text"
          onChange={this.handleStateChange}
        />
      );
    }
  };
  mapPoint = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.mapPoint}</span>;
    } else {
      return (
        <Form.Control
          id="mapPoint"
          defaultValue={this.state.mapPoint}
          required
          aria-label="map point"
          type="text"
          onChange={this.handleStateChange}
        />
      );
    }
  };
  primaryCooperator = () => {
    if (this.state.isDetailsPage) {
      return <span className={styles.dataFields}>{this.state.primaryCooperator.label}</span>;
    } else {
      return (
        <AsyncSelect
          value={this.state.primaryCooperator}
          openMenuOnClick={false}
          placeholder='"Enter Name..."'
          loadOptions={this.handleCooperatorSearch}
          onChange={this.handleCooperatorChange}
          aria-label="Primary Cooperator Search"
        />
      );
    }
  };

  handleCooperatorChange = (input) => {
    this.setState({ primaryCooperator: input });
    return input;
  };

  addressFields = () => {
    let data = this.state;
    if (this.state.isDetailsPage) {
      return <AddressDisplay addresses={data.addresses} />;
    } else {
      if (data.addresses.length === 0) {
        let addresses = [{ street: "", city: "", state: "", country: "United States of America", zipCode: "" }];
        data.addresses = addresses;
      }

      return (
        <PropertiesAddressesDisplay
          addresses={data.addresses}
          countries={this.state.countries}
          states={this.state.usaStates}
          onAddressChange={this.handleAddressChanges}
          onExtraAddress={this.handleExtraAddress}
        />
      );
    }
  };

  pageActionButtons = () => {
    let mainButtons = null;
    let editPadding = null;
    if (this.state.isDetailsPage) {
      if (this.props.permissions.canIEditProperties()) {
        mainButtons = (
          <Button className={globalStyles.modalSubmitButton} variant="primary" onClick={this.changeModes}>
            <span className={globalStyles.modalSubmitButtonText}>Edit</span>
          </Button>
        );
      }
    } else {
      editPadding = { marginRight: "28px" };
      mainButtons = (
        <span>
          <Button className={globalStyles.modalCancelButton} variant="secondary" onClick={this.changeModes}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button className={globalStyles.modalSubmitButton} type="submit" variant="primary">
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </span>
      );
    }
    let siteVisitButton = "";
    if (this.state.displayedAgreement?.agreementStatus?.id > 1) {
      siteVisitButton = (
        <Button
          className={globalStyles.modalSubmitButton}
          style={editPadding}
          variant="primary"
          onClick={this.handleCreatePVS}
        >
          <span className={globalStyles.modalSubmitButtonText}>Create Site Visit</span>
        </Button>
      );
    }

    let actionButtons = (
      <span className={styles.topPageButtons}>
        {siteVisitButton}
        {mainButtons}
      </span>
    );
    return actionButtons;
  };

  handleTabClick(tab) {
    this.setState({ currentWorkRecordsTab: tab });
  }

  handleConflictDetails = (conflictId) => {
    this.props.history.push({
      pathname: "/conflict/edit",
      state: { conflictId: conflictId, propertyData: this.props.history.location?.state?.entityData },
    });
  };

  bottomPageCards = (title) => {
    let button = null;
    let removeButton = () => { };
    let card = null;
    if (title === "Associated Contacts") {
      // ({ button, removeButton, card } = this.associatedContactsCard(button, removeButton, card, title));
    } else if (title === "Permissions") {
      ({ button, card } = this.permissionsCard(button, card, title));
    } else if (title === "Work Records") {
      card = this.workRecordsCard(title);
    }
    return card;
  };

  handleCreateConflict = () => {
    this.setState({ showCreateConflictModal: true });
  };

  handleCreatePVS = () => {
    this.props.history.push({
      pathname: "/sitevisit",
      state: {
        propertyData: this.props.history.location?.state?.entityData,
        states: this.state.usaStates,
        countries: this.state.countries,
        parcels: this.state.parcels,
        agreementInfo: this.state?.data?.displayedAgreement,
      },
    });
  };

  workRecordsCard(title) {
    return (
      <Card className={styles.bottomPageCards}>
        <Card.Body>
          <Row>
            <Col className="text-left">
              <span className={styles.cardTitleText}>{title}</span>
            </Col>
            <Col className="text-right">
              <span style={{ float: "right" }}>
                <span>
                  {this.props.permissions.canICreateConflicts() &&
                    this.state.currentWorkRecordsTab === WorkRecordTabs.Conflicts && (
                      <Button variant="link" onClick={this.handleCreateConflict}>
                        + Create Conflict
                      </Button>
                    )}
                </span>
                <Image src={menuIcon} alt="menu icon" className="pl-2" />
                <Button variant="link" className={styles.viewAllConflictsButton}>
                  View All
                </Button>
              </span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="ml-2">
              <Tabs id="conflictsFilesTabs" onSelect={(e) => this.handleTabClick(e)} className={styles.bottomPageTabs}>
                <Tab eventKey={WorkRecordTabs.DirectControl} title="Direct Control" className="mt-4">
                  <Row className={styles.bottomPageCardsTextHeader}>
                    <Col align="left">
                      <span>Date</span>
                    </Col>
                    <Col align="right">
                      <span>Performed By</span>
                    </Col>
                    <Col align="right">
                      <span>Type</span>
                    </Col>
                  </Row>
                  {this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workPerformed?.map((workTask) => {
                    return (
                      <Row className={styles.bottomPageCardsText}>
                        <Col align="left">
                          <span>{new Date(workTask?.createdDateTimeUTC).toLocaleDateString("en-US")}</span>
                        </Col>
                        <Col align="right">
                          <span>{workTask?.createdBy}</span>
                        </Col>
                        <Col align="right">
                          <span>{workTask?.workPerformedAircraft?.length > 0 ? "Aerial" : "Direct"}</span>
                        </Col>
                      </Row>
                    );
                  })}
                </Tab>
                <Tab eventKey={WorkRecordTabs.WorkTasks} title="TA Work Task" className="mt-4">
                  <Row className={styles.bottomPageCardsTextHeader}>
                    <Col align="left">
                      <span>Date Performed</span>
                    </Col>
                    <Col align="right">
                      <span>County</span>
                    </Col>
                  </Row>
                  {this.state.coopTAWorkTasks?.map((workTask) => {
                    let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(workTask?.datePerformed);
                    return (
                      <Row className={styles.bottomPageCardsText}>
                        <Col align="left">
                          <span>{date ? date.toLocaleDateString("en-US") : ""}</span>
                        </Col>
                        <Col align="right">
                          <span>{workTask?.county}</span>
                        </Col>
                      </Row>
                    );
                  })}
                </Tab>
                <Tab eventKey={WorkRecordTabs.Conflicts} title="Conflicts" className="mt-4">
                  {this.state.isConflictsListLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden" aria-label="Loading..."></span>
                    </Spinner>
                  ) : (
                    this.state.conflicts?.length > 0 &&
                    this.state.conflicts.map((conflict, index) => (
                      <div key={index}>
                        <Row className={index !== this.state.conflicts.length - 1 ? "mb-2" : ""}>
                          <Col className="text-left">
                            <Button
                              variant="link"
                              onClick={() => {
                                this.props.permissions.canIViewConflicts()
                                  ? this.handleConflictDetails(conflict.conflictUniqueIdentifier)
                                  : toast.warning("User does not have permission to view Conflict details.");
                              }}
                            >
                              <span className={globalStyles.formDataLinks}>{conflict.targetedSpeciesName}</span>
                            </Button>
                          </Col>
                        </Row>
                        <div
                          hidden={index === this.state.conflicts.length - 1}
                          className={styles.bottomPageListingRowSeparator}
                        ></div>
                      </div>
                    ))
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  permissionsCard(button, card, title) {
    let permissionsCard = (
      <Card className={styles.bottomPageCards}>
        <Card.Body>
          <Row>
            <Col className="text-left">
              <span className={styles.cardTitleText}>{title}</span>
            </Col>
            <Col className="text-right">
              <span style={{ float: "right" }}>
                <Image src={menuIcon} alt="menu icon" className="pl-2" />
                <Button variant="link" className={styles.viewAllConflictsButton}>
                  View All
                </Button>
              </span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="ml-2">
              <Tabs id="permissionsTabs" className={styles.bottomPageTabs}>
                <Tab eventKey={PermissionsTabs.Species} title="Targeted Damage Agents" className="mt-4">
                  {this.state.data?.displayedAgreement?.agreementProperty?.targetedSpecies?.map((species) => {
                    return (
                      <Row>
                        <Col align="left" className={styles.bottomPageCardsText}>
                          <span>{species.speciesName}</span>
                        </Col>
                      </Row>
                    );
                  })}
                </Tab>
                <Tab eventKey={PermissionsTabs.Methods} title="Methods" className="mt-4">
                  {this.state.data?.displayedAgreement?.agreementProperty?.allowedMethods?.map((method) => {
                    return (
                      <Row>
                        <Col align="left" className={styles.bottomPageCardsText}>
                          <span>{method.methodName}</span>
                        </Col>
                      </Row>
                    );
                  })}
                </Tab>
                <Tab eventKey={PermissionsTabs.SpecialInstructions} title="Special Instructions" className="mt-4">
                  {this.state.data?.displayedAgreement?.specialInstructions?.map((inst) => {
                    return (
                      <Row>
                        <Col align="left" className={styles.bottomPageCardsText}>
                          <span>{inst}</span>
                        </Col>
                      </Row>
                    );
                  })}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
    if (permissionsCard) {
      card = permissionsCard;
      return { button, card };
    }
  }

  associatedContactsCard(button, removeButton, card, title) {
    if (!this.state.isDetailsPage) {
      button = (
        <Button
          variant="link"
          onClick={() => {
            this.setState({ showContactAddModal: true });
          }}
          className={styles.editPropertyHeaderLinks}
        >
          Add
        </Button>
      );
      removeButton = (index) => (
        <Col lg={1} className={styles.associationCardRowItem}>
          <i
            class="fa fa-times-circle-o"
            style={{ cursor: "pointer", padding: "6px" }}
            aria-hidden="true"
            onClick={(e) => this.handleContactRemove(e, index)}
          ></i>
        </Col>
      );
    }
    let contactArray = [];
    if (this.state.contacts) {
      contactArray = this.state.contacts;
    }
    let associateCard = (
      <Card className={styles.bottomPageCards}>
        <Card.Header className={styles.cardHeader}>
          <span className={styles.cardTitleText}>{title}</span> {button}
        </Card.Header>
        <Card.Body>
          {contactArray.map((item, index) => (
            <Row>
              <Col>
                <Button
                  variant="link"
                  key={`button-${item.contactUniqueIdentifier}`}
                  onClick={() => this.handleContactClick(item.contactUniqueIdentifier)}
                >
                  <p>{item.firstName}</p>
                </Button>
              </Col>
              <Col>
                <p style={{ padding: "6px" }}>{item.lastName}</p>
              </Col>
              <Col>
                <p style={{ padding: "6px" }}>{item.contactRole.name}</p>
              </Col>
              {removeButton(index)}
            </Row>
          ))}
        </Card.Body>
      </Card>
    );
    if (associateCard) {
      card = associateCard;
      return { button, removeButton, card };
    }
  }

  async getContactFromIdReturnName(id) {
    let getContact = await AgreementEntitiesAPI.GetContact(id);
    return getContact.data;
  }

  async handleAgreementClick(agreement) {
    this.props.history.push({
      pathname: "/agreement/details",
      state: {
        entityData: agreement,
        states: this.state.usaStates.map((state) => {
          return { stateUniqueIdentifier: state.id, state_name: state.name };
        }),
        countries: this.state.countries,
      },
    });
  }

  async handleContactClick(contactGuid) {
    let contactData = await AgreementEntitiesAPI.GetContact(contactGuid);
    this.props.history.push({
      pathname: "/contact/details",
      state: { entityData: contactData.data },
    });
  }

  handleAddressChanges = async ({ currentTarget: input }) => {
    const controlId = input.id.split("-");
    let fieldName = controlId[0];
    const index = controlId[1];

    let data = this.state;
    switch (fieldName) {
      case "country":
        let countyId = this.state.countries.find((country) => country.name === input.value)?.id;
        this.updatePropertyCountry(data, input.value);
        await this.updateAvailableStates(countyId);
        break;
      case "state":
        let stateId = this.state.usaStates.find((states) => states.name === input.value)?.id;
        this.updatePropertyState(data, input.value);
        await this.updateCountiesByState(stateId);
        break;
      default:
        data.addresses[index][fieldName] = input.value;
    }

    this.setState({ data });
  };

  updatePropertyCountry(data, value) {
    data.addresses = data.addresses.map((address) => {
      address.country = value;
      return address;
    });
  }

  updatePropertyState(data, value) {
    data.addresses = data.addresses.map((address) => {
      address.state = value;
      return address;
    });
  }

  async updateAvailableStates(countryId) {
    const states = await this.getAvailableStates(countryId);
    if (states) this.setState({ usaStates: states });
  }

  async getAvailableStates(countryId) {
    return await this.getStatesFromAPI(countryId);
  }

  async getStatesFromAPI(countryId) {
    const results = await ReferenceFileAPI.GetStatesByCountry(countryId);

    if (results) {
      return results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  async updateCountiesByState(stateId) {
    //TO DO - After API Integration change this code
    const counties = await this.getCountyListbyState(stateId);
    if (counties) this.setState({ counties });
  }

  handleExtraAddress = async ({ currentTarget: input }) => {
    let data = this.state;
    if (input.value === "add") {
      if (data.addresses[data.addresses.length - 1].state)
        data.addresses.push({
          street: "",
          city: "",
          state: data.addresses[0].state,
          country: data.addresses[0].country,
          zipCode: "",
        });
      else {
        toast.warning("Please fill out all the required fields on previous address record.");
      }
    } else {
      data.addresses.splice(input.value, 1);
    }

    this.setState({ data });
  };

  handleContactSearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    };
    const contactsData = await AgreementEntitiesAPI.SearchContactFreeText(searchObj);
    var outputContacts = "";
    if (contactsData.data.results) {
      let results = contactsData.data.results;
      outputContacts = results.map((result) => ({
        value: result.contactUniqueId,
        label: result.firstName + " " + result.lastName,
        firstName: result.firstName,
        lastName: result.lastName,
        contactUniqueIdentifier: result.contactUniqueId,
        contactRole: result.typeOfContact,
        typeOfContact: result.typeOfContact,
      }));
    }
    return outputContacts;
  };

  handleContactChange = (input) => {
    const data = this.state;
    if (!data.contacts) {
      let array = [];
      array.push(input);
      this.setState({ contacts: array });
    } else {
      for (let i = 0; i < this.state.contactsToRemove.length; i++) {
        if (this.state.contactsToRemove[i].contactUniqueIdentifier === input.contactUniqueId) {
          this.setState({ contactsToRemove: this.state.contactsToRemove.splice(i, 1) });
        }
      }
      data.contacts.push(input);
      this.setState({ data });
    }
    return input;
  };

  handleContactRemove = (e, index) => {
    let data = this.state;
    data.contactsToRemove.push(data.contacts[index]);
    data.contacts.splice(index, 1);
    this.setState({ data });
  };

  changeModes = () => {
    this.setState({ isDetailsPage: !this.state.isDetailsPage });
  };

  closeSuccessfulCreateModal = () => {
    this.setState({ showSuccessfullCreateModal: false });
    this.changeModes();
  };

  onCreateConflictModalClose = () => {
    this.setState({ showCreateConflictModal: false });
  };

  getAgreementInfoCard() {
    return (
      <Card className={styles.createPageCards} style={{ boxShadow: "0 0 0 0 #000000" }}>
        <Card.Header as="h5" className={styles.cardHeader}>
          <span className={styles.cardTitleText}>Agreement Information</span>
        </Card.Header>
        <Card.Body>
          <Row className={styles.cardRows}>
            <Col>
              <h6 className={styles.fieldLabels}>Agreement Common Name</h6>
              <span
                className={styles.agreementCardLinks}
                onClick={() => this.handleAgreementClick(this.state.displayedAgreement)}
              >
                {this.state.displayedAgreement.commonName}
              </span>
            </Col>
            <Col>
              <h6 className={styles.fieldLabels}>Cooperator Name</h6>
              <span
                className={styles.agreementCardLinks}
                onClick={() =>
                  this.handleContactClick(this.state.displayedAgreement?.cooperatorName?.contactUniqueIdentifier)
                }
              >
                {this.state.displayedAgreement?.cooperatorName?.cooperatorFullName}
              </span>
            </Col>
            <Col>
              <h6 className={styles.fieldLabels}>Phone Number</h6>
              <span className={styles.dataFields}>
                {UtilityFunctions.formatPhoneNumber(
                  this.state.displayedAgreement?.cooperatorName?.phoneNumbers[0]?.phoneNumber
                )}
              </span>
            </Col>
            <Col>
              <h6 className={styles.fieldLabels}>Phone Type</h6>
              <span className={styles.dataFields}>
                {this.state.displayedAgreement?.cooperatorName?.phoneNumbers[0]?.phoneType?.name}
              </span>
            </Col>
          </Row>
          <Row className={styles.cardRows}>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Address</h6>
              <span className={styles.dataFields}>
                {this.state.displayedAgreement?.cooperatorName?.cooperatorFullAddress}
              </span>
            </Col>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Email Address</h6>
              <span className={styles.dataFields}>{this.state.displayedAgreement?.cooperatorName?.emailAddress}</span>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  setActiveInformationTab = (e) => {
    this.setState({ activeInformationTab: e });
  };

  getAgreementBeacon() {
    let beaconText = "";
    let beaconClass = null;
    let beacon = null;

    if (this.state.displayedAgreement.agreementStatus.id === 3) {
      beaconText = "Active Agreement";
      beaconClass = styles.activeAgreementBeacon;
    } else if (this.state.displayedAgreement.agreementStatus.id === 2) {
      beaconText = "Pending Agreement";
      beaconClass = styles.pendingAgreementBeacon;
    } else if (this.state.displayedAgreement.agreementStatus.id === 1) {
      beaconText = "Draft Agreement";
      beaconClass = styles.draftAgreementBeacon;
    }

    if (beaconClass) {
      beacon = (
        <p className={beaconClass}>
          <i className="fa fa-circle" style={{ paddingTop: "1.7%", paddingRight: "1%" }} aria-hidden="true"></i>
          {beaconText}
        </p>
      );
    }

    return beacon;
  }

  setHighlightedMapPointMethod = (method) => {
    this.setState({ highlightedMapPointMethod: method });
  };

  showParcelMap = () => {
    if (sessionStorage.getItem("mapAccess") === "Yes") {
      return (
        <ParcelMapView
          parcelDetails={this.state.parcels}
          address={this.state.propertyAddress}
          name={this.state.propertyName}
          location={this.state.mapPoint}
          agreementData={this.state.data?.displayedAgreement}
          inventoryMapItems={this.state.inventoryMapItems}
          setHighlightedMapPointMethod={this.setHighlightedMapPointMethod}
          isViewMap={true}
        />
      );
    } else {
      return (
        <WebMap
          address={this.state.propertyAddress}
          name={this.state.propertyName}
          location={this.state.mapPoint}
          agreementData={this.state.data?.displayedAgreement}
          inventoryMapItems={this.state.inventoryMapItems}
          setHighlightedMapPointMethod={this.setHighlightedMapPointMethod}
          isViewMap={true}
        />
      );
    }
  };

  submitBadWeatherCheck = async () => {
    this.setState({ submitBadWeatherCheckButtonDisabled: true });
    const createBadWeatherCheckResponse = await WorkTaskAPI.CreateBadWeatherCheckPVS({
      propertyVisitedUniqueReference: this.state.propertyUniqueIdentifier,
      dateEntered: new Date(this.state.badWeatherCheckDate).toISOString(),
    });
    if (createBadWeatherCheckResponse?.successful) {
      toast.success("Successfully recorded Bad Weather Check");
      this.setState(
        { showBadWeatherCheckModal: false, badWeatherCheckDate: "", submitBadWeatherCheckButtonDisabled: false },
        () => {
          this.refreshPropertyMethodInventory();
        }
      );
      return;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to record Bad Weather Check",
        apiName: "CreateBadWeatherCheckPVS",
        responseUnsuccessful: createBadWeatherCheckResponse?.unsuccessful,
        responseMessage: createBadWeatherCheckResponse?.message,
      });
    }
    this.setState({ submitBadWeatherCheckButtonDisabled: false });
  };

  render() {
    let agreementInformationCard = null;
    let activeAgreementBeacon = null;
    if (this.state.displayedAgreement) {
      agreementInformationCard = this.getAgreementInfoCard();
      activeAgreementBeacon = this.getAgreementBeacon();
    }
    const expandRow = {
      parentClassName: "selectParentRow",
      renderer: (row) => {
        const selectSubRow = {
          mode: "checkbox",
          clickToSelect: false,
          hideSelectAll: true,
          selected: row.propertyMethodInventorySubTableSelected,
          onSelect: this.handleSubRowOnSelect,
          style: (row) => {
            const style = {};
            if (
              row.deployedItemUniqueIdentifier === this.state.highlightedMapPointMethod?.deployedItemUniqueIdentifier
            ) {
              style.backgroundColor = "#ADD8E6";
            } else {
              style.backgroundColor = "#DAE9E5";
            }
            return style;
          },
          headerColumnStyle: {
            borderTop: "hidden",
            // border: "none",
            borderBottom: "2px solid #008767",
            width: "60px",
          },
          selectColumnStyle: {
            verticalAlign: "middle",
          },
        };
        const dummyExpandRow = {
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
              return <b></b>;
            }
            return <b></b>;
          },
        };
        return (
          <div className={styles.subTableContainer}>
            <ToolkitProvider
              keyField="deployedItemUniqueIdentifier"
              data={row.propertyMethodInventorySubTable}
              columns={this.state.propertyMethodInventorySubTableColumns}
              bootstrap4={true}
              hover={true}
            >
              {(props) => (
                <div>
                  <BootstrapTable
                    keyField="deployedItemUniqueIdentifier"
                    bordered={false}
                    condensed
                    expandRow={dummyExpandRow}
                    selectRow={selectSubRow}
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        );
      },
      showExpandColumn: true,
      expandByColumnOnly: true,
      expanded: this.state.propertyMethodInventoryRowExpanded,
      onExpand: this.handlePropertyMethodInventoryRowOnExpand,
      nonExpandable: this.state.propertyMethodInventoryRowNotExpandable,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b></b>;
        }
        return <b></b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <img className={styles.chevron} src={ChevronDown} alt="down arrow" />;
        }
        return <img className={styles.chevron} src={ChevronRight} alt="right arrow" />;
      },
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.propertyMethodInventoryRowSelected,
      onSelect: this.handlePropertyMethodInventoryRowOnSelect,
      onSelectAll: this.handleOnSelectAll,
      style: { backgroundColor: "#f5f5f5" },
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "hidden",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #dee2e6",
      },
      hideSelectAll: false,
    };
    let showMap = this.showParcelMap();
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading Property Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        {" "}
        <div>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmitWrapper}>
            <Row className={styles.pageRow}>
              <Col>
                <h5 className={styles.pageTitle}>Property Record Page</h5>
              </Col>
              <Col>{this.pageActionButtons()}</Col>
            </Row>
            <Row className={styles.pageRow}>
              <Col>
                <Card>
                  <Card.Header className={styles.cardHeader}>
                    <Row>
                      <Col lg={0.3}>
                        <i className="fa fa-home fa-2x" aria-hidden="true"></i>
                      </Col>
                      <Col lg={2.5}>
                        <span className={styles.propertyInfoTitle}>Property Information</span>
                      </Col>
                      <Col lg={2}>
                        <Form.Control
                          style={{ height: "75%" }}
                          aria-label="Select Parcel"
                          placeholder="Select Parcel"
                          disabled
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row className={styles.mapContainer} hidden={this.state.propertyType === "Non-Parcel"}>
                      {this.state.checkedForAgreements && showMap}
                    </Row>
                    <Row className={styles.mapContainer} hidden={this.state.propertyType === "Parcel"}>
                      {this.state.checkedForAgreements && (
                        <WebMap
                          address={this.state.propertyAddress}
                          name={this.state.propertyName}
                          location={this.state.mapPoint}
                          agreementData={this.state.data?.displayedAgreement}
                          inventoryMapItems={this.state.inventoryMapItems}
                          handleOnMapClickSelectInventoryRow={() => { }}
                          setHighlightedMapPointMethod={this.setHighlightedMapPointMethod}
                          isViewMap={true}
                        />
                      )}
                    </Row>
                    <Tabs
                      variant="tabs"
                      onSelect={this.setActiveInformationTab}
                      className={styles.activeInformationTabs}
                    >
                      <Tab eventKey="1" title="Agreement Information" id="agreementInformationTab">
                        <Row className={styles.informationTabRow}>
                          <Col lg={4} className={styles.propertyDataFields}>
                            {activeAgreementBeacon}
                            <Row className={styles.cardRows}>
                              <Col xs={2}>
                                <img className={styles.circleIcons} src={PropertyIcon} alt="House icon" />
                              </Col>
                              <Col className={styles.circleIconAdjascentColumn}>
                                <h6 className={styles.fieldLabels}>
                                  Property Name
                                  <span className={globalStyles.asterisk508}>
                                    {!this.state.isDetailsPage ? "*" : ""}
                                  </span>
                                </h6>
                                {this.propertyName()}
                              </Col>
                            </Row>
                            <Row className={styles.cardRows}>
                              <Col xs={2}>
                                <img className={styles.circleIcons} src={AcresIcon} alt="Acres icon" />
                              </Col>
                              <Col>
                                <h6 className={styles.fieldLabels}>
                                  Area in Acres
                                  <span className={globalStyles.asterisk508}>
                                    {!this.state.isDetailsPage ? "*" : ""}
                                  </span>
                                </h6>
                                {this.acres()}
                              </Col>
                            </Row>
                            <Row className={styles.cardRows}>
                              <Col xs={2}>
                                <img className={styles.circleIcons} src={CooperatorIcon} alt="People icon" />
                              </Col>
                              <Col>
                                <h6 className={styles.fieldLabels}>Primary Cooperator</h6>
                                {this.primaryCooperator()}
                              </Col>
                            </Row>
                            <Row className={styles.cardRows}>
                              <Col xs={2}></Col>
                              <Col>
                                <h6 className={styles.altFieldLabels}>Additional Contacts</h6>
                                {this.state.data?.displayedAgreement?.additionalContacts?.map((contact) => {
                                  return (
                                    <Row>
                                      <Col>
                                        <span className={styles.dataFields}>{contact.fullName}</span>
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8} style={{ borderLeft: "1px solid #E1E1E1" }}>
                            <Row>
                              <Card
                                border="none"
                                className={styles.createPageCards}
                                style={{ boxShadow: "0 0 0 0 #000000" }}
                              >
                                <Card.Header as="h5" className={styles.cardHeader}>
                                  <span className={styles.addressCardTitle}>Address Information</span>
                                </Card.Header>
                                <Card.Body>
                                  <Row>
                                    <Col md={8}>{this.addressFields()}</Col>
                                    <Col
                                      style={this.state.isDetailsPage ? { marginTop: "60px" } : { marginTop: "85px" }}
                                    >
                                      <h6 className={styles.fieldLabels}>
                                        County
                                        <span className={globalStyles.asterisk508}>
                                          {!this.state.isDetailsPage ? "*" : ""}
                                        </span>
                                      </h6>
                                      {this.county()}
                                    </Col>
                                  </Row>
                                  <Row hidden={this.state.propertyType === "Parcel"}>
                                    {/* <Col>
                                  <h6 className={styles.fieldLabels}>
                                    District<span className={globalStyles.asterisk508}>*</span>
                                  </h6>
                                  {this.district()}
                                </Col> */}
                                  </Row>
                                  <Row hidden={this.state.propertyType === "Parcel"}>
                                    <Col md={2} style={{ paddingLeft: "20px" }}>
                                      <h6 className={styles.fieldLabels}>
                                        Land Class
                                        <span className={globalStyles.asterisk508}>
                                          {!this.state.isDetailsPage ? "*" : ""}
                                        </span>
                                      </h6>
                                      {this.landClass()}
                                    </Col>
                                    <Col style={{ paddingLeft: "20px" }}>
                                      <h6 className={styles.fieldLabels}>
                                        Map Point (Coordinates)
                                        <span className={globalStyles.asterisk508}>
                                          {!this.state.isDetailsPage ? "*" : ""}
                                        </span>
                                      </h6>
                                      {this.mapPoint()}
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Row>
                            <Row>{agreementInformationCard}</Row>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="2" title="Property Method Inventory" id="propertyMethodInventoryTab">
                        <Row className={styles.informationTabRow}>
                          <Col align="left">
                            <h5 className={styles.pmiHeader}>
                              <span className={styles.cardTitleText}>Property Method Inventory</span>
                            </h5>
                          </Col>
                          <Col align="right" className="pt-4">
                            <Button variant="primary" onClick={() => this.setState({ showBadWeatherCheckModal: true })}>
                              Bad Weather Check
                            </Button>
                          </Col>
                          <ToolkitProvider
                            keyField="methodUniqueIdentifier"
                            data={this.state.propertyMethodInventory}
                            columns={this.state.propertyMethodInventoryColumns}
                            bootstrap4={true}
                            hover={true}
                          >
                            {(props) => (
                              <div>
                                <BootstrapTable
                                  keyField="methodUniqueIdentifier"
                                  bordered={false}
                                  condensed
                                  expandRow={expandRow}
                                  selectRow={selectRow}
                                  {...props.baseProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </Row>
                      </Tab>
                      <Tab eventKey="3" title="Resource Inventory" id="resourceInventoryTab">
                        <Row className={styles.informationTabRow}>
                          <Col align="left">
                            <h5 className={styles.pmiHeader}>
                              <span className={styles.cardTitleText}>Protected Resource Inventory</span>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <BootstrapTable
                              keyField="protectedResourceId"
                              data={this.state.protectedResourcesData}
                              columns={this.state.protectedResourcesDataColumns}
                              bootstrap4={true}
                              hover={true}
                              bordered={false}
                            />
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className={styles.pageRow}>
              <Col>{this.bottomPageCards("Work Records")}</Col>
              <Col>{this.bottomPageCards("Permissions")}</Col>
            </Row>
            <SuccessfulRecordModifiedModal
              show={this.state.showSuccessfullCreateModal}
              onHide={this.closeSuccessfulCreateModal}
              messageType="update"
              objectName="Property"
            />
            <CreateConflictModal
              isModal={true}
              show={this.state.showCreateConflictModal}
              onHide={this.onCreateConflictModalClose}
              propertyGuid={this.state.propertyUniqueIdentifier}
              existingConflicts={this.state.conflicts}
              agreementData={this.state.displayedAgreement}
            />
            <EnterLossModal
              show={this.state.showLossModal}
              onHide={this.closeLossModal}
              resourceData={this.state.currentResourceRecord}
              onSubmitLoss={this.saveInventoryLoss}
            />
            <Modal
              show={this.state.showContactAddModal}
              onHide={() => this.setState({ showContactAddModal: false })}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <h5>
                  Please search and select a contact to associate to this property. Alternatively, create a new contact
                  for association.
                </h5>
              </Modal.Header>
              <Button variant="link" onClick={() => this.props.history.push("/contact/create")}>
                <p style={{ float: "right", marginBottom: "0" }}>Create Contact</p>
              </Button>
              <Modal.Body>
                <AsyncSelect
                  openMenuOnClick={false}
                  placeholder="Search"
                  loadOptions={this.handleContactSearch}
                  onChange={this.handleContactChange}
                />
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.showBadWeatherCheckModal}
              onHide={() => this.setState({ showBadWeatherCheckModal: false })}
              size="md"
              aria-label="Bad Weather Check Date Picker Modal"
              centered
            >
              <Modal.Header>
                <Modal.Title>
                  <h5>Bad Weather Check</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-4">
                <Row>
                  <Col>
                    <p>Please Select the date of the Bad Weather Check for this property.</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      type="date"
                      title="Bad Weather Check Date"
                      value={this.state.badWeatherCheckDate}
                      onChange={(e) => this.setState({ badWeatherCheckDate: e.target.value })}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={() => this.setState({ badWeatherCheckDate: "", showBadWeatherCheckModal: false })}
                >
                  <span className={globalStyles.modalCancelButtonText}>Cancel</span>
                </Button>
                <Button
                  className="ml-2"
                  variant="primary"
                  type="submit"
                  disabled={!this.state.badWeatherCheckDate || this.state.submitBadWeatherCheckButtonDisabled}
                  onClick={async () => await this.submitBadWeatherCheck()}
                >
                  <span className={globalStyles.modalSubmitButtonText}>Save</span>
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(DisplayEditProperty);
