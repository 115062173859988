import React from "react";
import CustomForm from "../common/form";
import styles from "./AdminCenter.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Row, Col, Button, Card, Image, Form, InputGroup, Modal, Dropdown } from "react-bootstrap";
import CustomTextFilter from "../common/customTextFilter";
import ReferenceFileCardHeader from "../ReferenceFiles/ReferenceFileCardHeader";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import searchIcon from "../../assets/search.svg";
import filterIcon from "../../assets/filterIcon.PNG";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import UtilityFunctions from "../common/UtilityFunctions";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class RolesTable extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      rolePermissions: [],
      masterRolePermissions: [],
      rolePermissionsToChange: { role: null, changes: [] },
      roles: [],
      roleEditingIndex: -1,
      columns: [],
      showEditConfirmationModal: false,
      roleSearchTerm: "",
      tableUpdated: true,
      showRoleCreateModal: false,
      roleNameToCreate: "",
      roleFilterList: [],
      extraHeaderComponents: [],
      showCreateSuccessModal: false,
      data: {
        roleToClone: "",
      },
      serviceGroupBannerIndexes: {},
      filterServiceTypes: [],
      bannerRowStyleUpToDate: true,
      errors: {},
    };
  }

  async componentDidMount() {
    if (this.state.rolePermissions.length === 0) {
      let rolePermissions = [];
      let getPermissionsCall = await RoleAuthorizationAPI.GetAllPermissions();
      let getRolesPermissionsCall = await RoleAuthorizationAPI.GetAllRolesWithPermissions();
      let getRolesCall = await RoleAuthorizationAPI.GetAllRoles();
      let permissions = getPermissionsCall?.data ? getPermissionsCall.data : [];
      permissions.forEach((permission) => {
        let newRP = {
          rolePermissionUniqueIdentifier: permission.id,
          permissionName: permission.displayText,
        };
        let i = getRolesCall?.data?.results.forEach((role) => {
          let currentRolePermissions = getRolesPermissionsCall?.data?.results?.find(
            (r) => r.wsRoleUniqueIdentifier === role.wsRoleUniqueIdentifier
          );
          newRP[role.roleName] = {
            selected: currentRolePermissions?.rolePermissions?.find((p) => p.id === permission.id),
          };
        });
        rolePermissions.push(newRP);
      });

      const existingRoles = getRolesCall?.data?.results ? getRolesCall.data.results : [];
      let roleFilterList = existingRoles.map((role) => {
        return { roleName: role.roleName, checked: true };
      });
      rolePermissions.sort(function (a, b) {
        var textA = a.permissionName.toLowerCase();
        var textB = b.permissionName.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      const masterRolePermissions = JSON.parse(JSON.stringify(rolePermissions));
      this.populateServiceGroupBannerIndexes(rolePermissions);
      this.populateFilterServiceTypes(masterRolePermissions);

      if (existingRoles?.length > 0) {
        this.props.passDataUp("availableRoles", existingRoles);
      }

      this.setState({ roles: existingRoles, roleFilterList });
      this.setState(
        {
          rolePermissions,
          masterRolePermissions,
          columns: this.getColumns(),
          extraHeaderComponents: this.getExtraHeaderComponents(),
          bannerRowStyleUpToDate: false,
        },
        () => this.markBannerRow()
      );
    }
  }

  async componentDidUpdate() {
    if (!this.state.tableUpdated) {
      let newRPs = this.getSortedRolePermissions();
      const newRFL = this.state.roleFilterList.map((rfl) => {
        return { ...rfl };
      });

      let serviceGroupBannerIndexes = {};
      newRPs.forEach((rp, index) => {
        let serviceName = rp.permissionName.split("_")[0];
        if (serviceGroupBannerIndexes[serviceName] === undefined) {
          serviceGroupBannerIndexes[serviceName] = index;
        }
      });
      const filterServiceTypes = this.state.filterServiceTypes.map((fst) => {
        return { ...fst };
      });

      this.setState(
        {
          rolePermissions: newRPs,
          tableUpdated: true,
          columns: this.getColumns(),
          roleFilterList: newRFL,
          extraHeaderComponents: this.getExtraHeaderComponents(),
          serviceGroupBannerIndexes,
          filterServiceTypes,
          bannerRowStyleUpToDate: false,
        },
        () => this.markBannerRow()
      );
    }
    if (!this.state.bannerRowStyleUpToDate) {
      this.markBannerRow();
      this.setState({ bannerRowStyleUpToDate: true });
    }
  }

  getSortedRolePermissions() {
    let newRPs = this.state.masterRolePermissions.map((rp) => {
      this.state.roles.forEach((role) => {
        if (!rp[role.roleName]) {
          rp[role.roleName] = {};
        }
        let existingPermissions = this.state.rolePermissions.find(
          (existingRP) => existingRP.permissionName === rp.permissionName
        );
        if (existingPermissions) {
          rp[role.roleName] = existingPermissions[role.roleName];
        }
      });
      return { ...rp };
    });

    newRPs = newRPs.filter(
      (rp) =>
        this.state.filterServiceTypes.find((f) => f.service.toLowerCase() === rp.permissionName.split("_")[0])?.checked
    );

    newRPs.sort(function (a, b) {
      var textA = a.permissionName.toLowerCase();
      var textB = b.permissionName.toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return newRPs;
  }

  populateServiceGroupBannerIndexes(rolePermissions) {
    let serviceGroupBannerIndexes = {};
    rolePermissions.forEach((rp, index) => {
      let serviceName = rp.permissionName.split("_")[0];
      if (serviceGroupBannerIndexes[serviceName] === undefined) {
        serviceGroupBannerIndexes[serviceName] = index;
      }
    });
    this.setState({ serviceGroupBannerIndexes });
  }

  populateFilterServiceTypes(masterRolePermissions) {
    let filterServiceTypes = [];
    masterRolePermissions.forEach((rp) => {
      let serviceName = rp.permissionName.split("_")[0];
      if (!filterServiceTypes.find((f) => f.service === UtilityFunctions.capitalizeString(serviceName))) {
        filterServiceTypes.push({ service: UtilityFunctions.capitalizeString(serviceName), checked: true });
      }
    });
    this.setState({ filterServiceTypes });
  }

  markBannerRow() {
    let elements = document.getElementsByClassName("serviceGroupBannerContainer");
    for (let e of elements) {
      let parentStyle = e.parentElement.style;
      parentStyle.paddingTop = "0px";
      parentStyle.paddingLeft = "0px";
      parentStyle.paddingRight = "0px";
    }

    elements = document.getElementsByClassName("serviceGroupBannerContainerCheckboxes");
    for (let e of elements) {
      let parentStyle = e.parentElement.style;
      parentStyle.paddingRight = "0px";
      parentStyle.paddingLeft = "0px";
      parentStyle.paddingTop = "0px";
    }
  }

  unmarkBannerRows() {
    let elements = document.getElementsByClassName("serviceGroupBannerContainer");
    for (let e of elements) {
      let parentStyle = e.parentElement.style;
      parentStyle.paddingTop = "35px";
      parentStyle.paddingLeft = "12px";
      parentStyle.paddingRight = "12px";
    }

    elements = document.getElementsByClassName("serviceGroupBannerContainerCheckboxes");
    for (let e of elements) {
      let parentStyle = e.parentElement.style;
      parentStyle.paddingRight = "35px";
      parentStyle.paddingLeft = "12px";
      parentStyle.paddingTop = "12px";
    }

    this.setState({ serviceGroupBannerIndexes: {} });
  }

  getColumns(roleSearchTerm, roleEditingIndex = -1) {
    let columnArray = [
      {
        dataField: "permissionName",
        text: "Permission Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Permission Name" extraRoleTableEvents={this} />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { width: "1%", backgroundColor: "#f3f3f3" };
        },
        style: { paddingTop: "35px" },
        headerAttrs: { id: "permissionNameHeader" },
        attrs: { headers: "permissionNameHeader", tabIndex: "0" },
        formatExtraData: this,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let serviceName = cell.split("_")[0];
          if (rowIndex === formatExtraData.state.serviceGroupBannerIndexes[serviceName]) {
            return (
              <div className="serviceGroupBannerContainer">
                <div className={styles.serviceGroupBanner}>{serviceName.toUpperCase()}</div>
                <div style={{ paddingLeft: "10px" }}>{cell}</div>
              </div>
            );
          } else {
            return cell;
          }
        },
      },
    ];
    this.state.roles.forEach((role, index) => {
      let actualColumnIndex = index + 1;
      let isSaveMode = roleEditingIndex === actualColumnIndex;
      let backgroundStyle = isSaveMode ? "#eaf3f1" : "";
      if (
        (!roleSearchTerm || role.roleName.toLowerCase().includes(roleSearchTerm.toLowerCase())) &&
        this.state.roleFilterList.find((rf) => rf.roleName === role.roleName)?.checked
      ) {
        columnArray.push({
          dataField: role.roleName + ".selected",
          text: role.roleName,
          csvExport: true,
          headerFormatter: (column, colIndex, components) => {
            let input = null;
            if (isSaveMode) {
              input = (
                <span>
                  <button
                    className={styles.columnButtonIconsX}
                    aria-label={`Cancel Editing`}
                    onClick={this.handleConfirmationCancel}
                  />
                  <button
                    className={styles.columnButtonIconsCheckmark}
                    aria-label={`Save Editing`}
                    onClick={() => column.formatExtraData.handleColumnHeaderButtonClick(actualColumnIndex, isSaveMode)}
                  />
                </span>
              );
            } else if (this.props.permissions.canIEditRole()) {
              input = (
                <Button
                  className={globalStyles.modalCancelButton}
                  onClick={() => column.formatExtraData.handleColumnHeaderButtonClick(actualColumnIndex, isSaveMode)}
                >
                  <span className={globalStyles.modalCancelButtonText}>Edit</span>
                </Button>
              );
            }
            return (
              <div>
                <div style={{ paddingBottom: "10px" }}>{role.roleName}</div>
                {input}
              </div>
            );
          },
          formatter: (cell, row, rowIndex, formatExtraData) => {
            let checkbox = (
              <input
                type="checkbox"
                checked={row[role.roleName] ? row[role.roleName].selected : false}
                style={{ marginRight: "45%" }}
                disabled={!isSaveMode}
                onClick={() => this.handleRolePermissionChange(row, role, formatExtraData)}
                aria-label={`Manage ${row.permissionName} for ${role.roleName}`}
              />
            );

            let serviceName = row.permissionName.split("_")[0];
            if (rowIndex === formatExtraData.state.serviceGroupBannerIndexes[serviceName]) {
              return (
                <div className="serviceGroupBannerContainerCheckboxes">
                  <div className={styles.serviceGroupBannerCheckboxes}></div>
                  <div>{checkbox}</div>
                </div>
              );
            } else {
              return <div style={{ paddingTop: "20px", paddingLeft: "5px" }}>{checkbox}</div>;
            }
          },
          formatExtraData: this,
          headerStyle: () => {
            return { width: ".5%", backgroundColor: "#f3f3f3", paddingBottom: "25px", paddingRight: "0px" };
          },
          style: { paddingLeft: "10px", paddingBottom: "10px", backgroundColor: backgroundStyle },
          align: "right",
          headerAttrs: {
            id: "userSelectionHeader",
            title: "User Select Header",
          },
          attrs: { headers: "userSelectionHeader" },
        });
      }
    });

    return columnArray;
  }

  handleRolePermissionChange = (row, role, formatExtraData) => {
    let rolePermissions = JSON.parse(JSON.stringify(formatExtraData.state.rolePermissions));
    let rolePermissionsToChange = formatExtraData.state.rolePermissionsToChange;
    let rolePresent = row[role.roleName].selected;
    rolePermissionsToChange.role = role;
    rolePermissionsToChange.changes.push({
      permissionName: row.permissionName,
      permissionEnumId: row.rolePermissionUniqueIdentifier,
      status: rolePresent ? "Removed" : "Added",
    });

    let currentRP = rolePermissions.find(
      (rp) => rp.rolePermissionUniqueIdentifier === row.rolePermissionUniqueIdentifier
    );
    currentRP[role.roleName].selected = !rolePresent;

    formatExtraData.setState({ rolePermissions, rolePermissionsToChange });
  };

  handleColumnHeaderButtonClick = (index, isSaveMode) => {
    if (isSaveMode) {
      if (this.state.rolePermissionsToChange.changes.length > 0) {
        this.setState({ showEditConfirmationModal: true });
      } else {
        toast.error("No changes detected.");
      }
    } else {
      let columns = this.getColumns(this.state.roleSearchTerm, index);
      this.setState({ roleEditingIndex: index, columns });
    }
  };

  handleConfirmationCancel = () => {
    let rolePermissionCopy = this.state.rolePermissions.map((rp) => {
      let rpCopy = { ...rp };
      let changeToUndo = this.state.rolePermissionsToChange.changes.find(
        (c) => c.permissionName === rpCopy.permissionName
      );
      if (changeToUndo) {
        rpCopy[this.state.rolePermissionsToChange.role.roleName].selected = changeToUndo.status === "Removed";
      }
      return rpCopy;
    });

    this.setState({
      showEditConfirmationModal: false,
      rolePermissions: rolePermissionCopy,
      rolePermissionsToChange: { role: null, changes: [] },
      roleEditingIndex: -1,
      columns: this.getColumns(),
      tableUpdated: false,
    });
  };

  handleConfirmationSave = async () => {
    let addPermissionsArray = [];
    let removePermissionsArray = [];
    this.state.rolePermissionsToChange.changes.forEach((change) => {
      if (change.status === "Added") {
        addPermissionsArray.push(change.permissionEnumId);
      } else if (change.status === "Removed") {
        removePermissionsArray.push(change.permissionEnumId);
      }
    });

    if (addPermissionsArray.length > 0) {
      const addPermissionsCall = await RoleAuthorizationAPI.AddPermissionsToRole(
        this.state.rolePermissionsToChange.role.wsRoleUniqueIdentifier,
        addPermissionsArray
      );
      if (addPermissionsCall?.successful) {
        toast.success(`Permissions added to ${this.state.rolePermissionsToChange.role.roleName}`);
      }
    }
    if (removePermissionsArray.length > 0) {
      const removePermissionsCall = await RoleAuthorizationAPI.RemovePermissionsFromRole(
        this.state.rolePermissionsToChange.role.wsRoleUniqueIdentifier,
        removePermissionsArray
      );
      if (removePermissionsCall?.successful) {
        toast.success(`Permissions removed from ${this.state.rolePermissionsToChange.role.roleName}`);
      }
    }

    this.setState({
      showEditConfirmationModal: false,
      rolePermissionsToChange: { role: null, changes: [] },
      roleEditingIndex: -1,
      tableUpdated: false,
      columns: this.getColumns(),
    });
  };

  getExtraHeaderComponents = () => {
    return [
      <Col lg={1} key="globalServicesFilter">
        <Dropdown>
          <Dropdown.Toggle className={styles.serviceTypeFilterButton}>View by Service type</Dropdown.Toggle>
          <Dropdown.Menu>
            <Row>
              <Col>
                <Button
                  variant="link"
                  onClick={this.selectAllServiceFilterCheckboxes}
                  className={styles.dropdownLinkButtons}
                >
                  Select All
                </Button>
                <Button
                  variant="link"
                  onClick={this.unselectAllServiceFilterCheckboxes}
                  className={styles.dropdownLinkButtonRight}
                >
                  Unselect All
                </Button>
              </Col>
            </Row>
            {this.state.filterServiceTypes.map((service, index) => {
              return (
                <Row className={styles.filterDropdownRow} key={index}>
                  <Col lg={1}>
                    <input
                      type="checkbox"
                      checked={service.checked}
                      onChange={(e) => this.handleServiceFilterCheckChange(e, index)}
                      title={`Filter ${service}`}
                    />
                  </Col>
                  <Col>{service.service}</Col>
                </Row>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Col>,
      <Col className="text-right" lg={8} style={{ marginLeft: "75px" }} key="globalRolesFilter">
        <Dropdown>
          <Dropdown.Toggle className={styles.filterToggleButton}>
            <Image src={filterIcon} alt="" aria-hidden="true" className={styles.filterIcon} />
            <span style={{ color: "#0071bc" }}>Filter</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.stateFilterItem}>
            <Row style={{ padding: "10px" }}>
              <Col>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Search Roles"
                    onChange={this.handleRoleSearch}
                    title="Search Roles"
                    aria-label="Search Roles"
                  ></Form.Control>
                  <div className="searchIcon">
                    <img src={searchIcon} aria-hidden="true" alt="" />
                  </div>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="link"
                  onClick={this.selectAllRoleFilterCheckboxes}
                  className={styles.dropdownLinkButtons}
                >
                  Select All
                </Button>
                <Button
                  variant="link"
                  onClick={this.unselectAllRoleFilterCheckboxes}
                  className={styles.dropdownLinkButtonRight}
                >
                  Unselect All
                </Button>
              </Col>
            </Row>
            {this.state.roleFilterList.map((role, index) => {
              return (
                <Row className={styles.filterDropdownRow} key={index}>
                  <Col lg={1}>
                    <input
                      type="checkbox"
                      checked={role.checked}
                      onChange={(e) => this.handleFilterCheckChange(e, index)}
                      title={`Filter ${role.roleName}`}
                    />
                  </Col>
                  <Col lg={10}>{role.roleName}</Col>
                </Row>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Col>,
    ];
  };

  selectAllRoleFilterCheckboxes = (e) => {
    e.stopPropagation();
    let roleFilterList = this.state.roleFilterList;
    roleFilterList.forEach((item) => (item.checked = true));
    this.setState({
      roleFilterList,
      tableUpdated: false,
      columns: this.getColumns(),
      extraHeaderComponents: this.getExtraHeaderComponents(),
    });
  };

  unselectAllRoleFilterCheckboxes = (e) => {
    e.stopPropagation();
    let roleFilterList = this.state.roleFilterList;
    roleFilterList.forEach((item) => (item.checked = false));
    this.setState({
      roleFilterList,
      tableUpdated: false,
      columns: this.getColumns(),
      extraHeaderComponents: this.getExtraHeaderComponents(),
    });
  };

  handleFilterCheckChange = (e, index) => {
    e.stopPropagation();
    let roleFilterList = this.state.roleFilterList;
    roleFilterList[index].checked = e.currentTarget.checked;
    this.setState({
      roleFilterList,
      tableUpdated: false,
      columns: this.getColumns(),
      extraHeaderComponents: this.getExtraHeaderComponents(),
    });
  };

  selectAllServiceFilterCheckboxes = (e) => {
    e.stopPropagation();
    let filterServiceTypes = this.state.filterServiceTypes;
    filterServiceTypes.forEach((item) => (item.checked = true));
    this.setState({
      filterServiceTypes,
      tableUpdated: false,
      columns: this.getColumns(),
      extraHeaderComponents: this.getExtraHeaderComponents(),
    });
  };

  unselectAllServiceFilterCheckboxes = (e) => {
    e.stopPropagation();
    let filterServiceTypes = this.state.filterServiceTypes;
    filterServiceTypes.forEach((item) => (item.checked = false));
    this.setState({
      filterServiceTypes,
      tableUpdated: false,
      columns: this.getColumns(),
      extraHeaderComponents: this.getExtraHeaderComponents(),
    });
  };

  handleServiceFilterCheckChange = (e, index) => {
    e.stopPropagation();
    let filterServiceTypes = this.state.filterServiceTypes;
    filterServiceTypes[index].checked = e.currentTarget.checked;
    this.setState({
      filterServiceTypes,
      tableUpdated: false,
      columns: this.getColumns(),
      extraHeaderComponents: this.getExtraHeaderComponents(),
    });
  };

  handleRoleSearch = (e) => {
    this.setState({ columns: this.getColumns(e.currentTarget.value), roleSearchTerm: e.currentTarget.value });
  };

  handleCreateRolePermissionsClick = () => {
    this.setState({ showRoleCreateModal: true });
  };

  renderEditConfirmationModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showEditConfirmationModal}
          onHide={this.handleConfirmationCancel}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>
              Edit {this.state.rolePermissionsToChange.role.roleName} - Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.confirmationModalBody}>
            {this.state.rolePermissionsToChange.changes.map((rp) => {
              return (
                <Row className={styles.confirmationBodyRow}>
                  <Col lg={7} className={styles.confirmationPermissionName}>
                    {rp.permissionName}
                  </Col>
                  <Col
                    className={
                      rp.status === "Added"
                        ? styles.confirmationPermissionStatusAdd
                        : styles.confirmationPermissionsStatusRemove
                    }
                    lg={3.5}
                  >
                    {rp.status}
                  </Col>
                </Row>
              );
            })}
          </Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.handleConfirmationCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button type="submit" className={globalStyles.modalSubmitButton} onClick={this.handleConfirmationSave}>
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  createRole = async () => {
    if (!this.state.roleNameToCreate) {
      toast.warning("Role Name is required to add a new role.");
      return;
    }

    const createRoleAPICall = await RoleAuthorizationAPI.CreateRole(this.state.roleNameToCreate);

    if (!createRoleAPICall?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create Role",
        apiName: "CreateRole",
        responseUnsuccessful: createRoleAPICall?.unsuccessful,
        responseMessage: createRoleAPICall?.message,
      });
    }

    let roles = this.state.roles;
    roles.push(createRoleAPICall?.data);
    this.props.passDataUp("availableRoles", roles);

    let roleToCloneName;
    const roleToClone = this.state.roles.find((r) => r.wsRoleUniqueIdentifier === this.state.data.roleToClone);
    if (roleToClone) {
      roleToCloneName = roleToClone.roleName;
    }
    let clonedPermissions = [];
    let roleMapping = this.state.rolePermissions?.map((rp) => {
      const isSelected = roleToCloneName ? rp[roleToCloneName]?.selected : false;
      rp[this.state.roleNameToCreate] = { selected: isSelected };
      if (rp[roleToCloneName]?.selected) {
        clonedPermissions.push(rp[roleToCloneName]?.selected?.id);
      }
      return rp;
    });

    if (clonedPermissions.length > 0) {
      const addPermissionsCall = await RoleAuthorizationAPI.AddPermissionsToRole(
        createRoleAPICall?.data.wsRoleUniqueIdentifier,
        clonedPermissions
      );

      if (!addPermissionsCall?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to add permissions",
          apiName: "AddPermissionsToRole",
          responseUnsuccessful: addPermissionsCall?.unsuccessful,
          responseMessage: addPermissionsCall?.message,
        });
      }
    }

    let roleFilterList = this.state.roleFilterList;
    roleFilterList.push({ roleName: this.state.roleNameToCreate, checked: true });

    this.setState({
      rolePermissions: roleMapping,
      roleNameToCreate: "",
      showRoleCreateModal: false,
      roles,
      columns: this.getColumns(),
      roleFilterList,
      extraHeaderComponents: this.getExtraHeaderComponents(),
      data: this.getClearData(),
      showCreateSuccessModal: true,
      bannerRowStyleUpToDate: false,
      tableUpdated: false,
    });
    this.markBannerRow();
  };

  handleSpecificChanges = () => {
    //do nothing
  };

  getClearData() {
    const { data } = this.state;
    data.roleToClone = "";
    return data;
  }

  handleCreateCancel = () => {
    const { data } = this.state;
    data.roleToClone = "";
    this.setState({ showRoleCreateModal: false, roleNameToCreate: "", data });
  };

  renderCreateRoleModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showRoleCreateModal}
          onHide={() => this.setState({ showRoleCreateModal: false, roleNameToCreate: "" })}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>Create Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.createRoleModalBody}>
              <Row>
                <Col>
                  <Form.Label for="roleNameModalInput" className={globalStyles.formLabel}>
                    Role Name <span className={globalStyles.asterisk508}>*</span>
                  </Form.Label>
                  <Form.Control
                    id="roleNameModalInput"
                    type="text"
                    name="Role Name"
                    placeholder="Enter role name"
                    value={this.state.roleNameToCreate}
                    onChange={(e) => this.setState({ roleNameToCreate: e.currentTarget.value })}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {this.renderSelect(
                    "roleToClone",
                    "Role to Clone",
                    this.state.roles?.map((r) => ({
                      id: r.wsRoleUniqueIdentifier,
                      name: r.roleName,
                    })),
                    "Select Role to Clone"
                  )}
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.handleCreateCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button type="submit" className={globalStyles.modalSubmitButton} onClick={this.createRole}>
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  render() {
    return (
      <div>
        <ToolkitProvider
          keyField="rolePermissionUniqueIdentifier"
          data={this.state.rolePermissions}
          columns={this.state.columns.length === 0 ? this.getColumns() : this.state.columns}
          bootstrap4={true}
          search={{ searchFormatted: true }}
          exportCSV={{
            fileName: "roles-data.csv",
            ignoreHeader: false,
            noAutoBOM: false,
            onlyExportFiltered: true,
            exportAll: false,
          }}
        >
          {(props) => (
            <div data-testid="RoleTab">
              <Card className={styles.adminCenterContainerCard}>
                <Card.Body className={styles.userTableContainer}>
                  <ReferenceFileCardHeader
                    referenceFileType="Role"
                    createButtonEvent={this.handleCreateRolePermissionsClick}
                    extraHeaderComponents={
                      this.state.extraHeaderComponents?.length === 0
                        ? this.getExtraHeaderComponents()
                        : this.state.extraHeaderComponents
                    }
                    permissions={this.props.permissions}
                    {...props.csvProps}
                  />
                  <BootstrapTable
                    keyField="rolePermissionUniqueIdentifier"
                    filter={filterFactory()}
                    bordered={false}
                    defaultSortDirection="asc"
                    {...props.baseProps}
                  />
                </Card.Body>
              </Card>
            </div>
          )}
        </ToolkitProvider>
        {this.state.showEditConfirmationModal && this.renderEditConfirmationModal()}
        {this.state.showRoleCreateModal && this.renderCreateRoleModal()}
        <SuccessfulRecordModifiedModal
          show={this.state.showCreateSuccessModal}
          onHide={() => {
            this.setState({ showCreateSuccessModal: false });
          }}
          objectName="Role"
        />
      </div>
    );
  }
}

export default RolesTable;
