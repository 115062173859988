import React from "react";
import styles from "./Projects.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Row, Col, Form, Button, CloseButton } from "react-bootstrap";
import CustomForm from "../common/form";
import { toast } from "react-toastify";
import Select from "react-select";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";

class CreateProjectModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        projectName: "",
        startDate: new Date().toLocaleDateString("en-CA"),
        endDate: new Date().toLocaleDateString("en-CA"),
        selectedStates: [],
        parentProjects: [],
      },
      isModalInitialized: false,
      availableProjects: [],
      errors: {},
    };
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show && !this.state.isModalInitialized) {
      const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
      this.validateStateData(currentUsaState);

      this.setState({
        availableProjects: await this.getAvailableProjects(),
        currentUsaState,
        isModalInitialized: true,
      });
    }
  }

  async getAvailableProjects() {
    let availableProjects = [];
    const projectsResponse = await AgreementEntitiesAPI.GetAllProjects();
    if (projectsResponse?.successful && projectsResponse.data?.results?.length > 0) {
      availableProjects = projectsResponse.data.results.map((project) => ({
        value: project.projectUniqueIdentifier,
        label: project.projectName,
      }));
    }
    return availableProjects;
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there.",
        {
          autoClose: 8000,
        }
      );
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  handleSelectChanges = (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    delete errors[fieldName];
    this.setState({ data, errors });
  };

  isFormDataValid() {
    const { projectName, startDate, endDate, selectedStates } = this.state.data;
    return projectName && startDate && endDate && selectedStates?.length > 0;
  }

  checkForInvalidData() {
    const { data, errors } = this.state;
    if (!data.projectName) {
      errors.projectName = "You must enter a valid Project name.";
    }
    if (!data.startDate) {
      errors.startDate = "You must select a valid date.";
    }
    if (!data.endDate) {
      errors.endDate = "You must select a valid date.";
    }
    if (!(data.selectedStates?.length > 0)) {
      errors.selectedStates = "You must select at least one state.";
    }
    this.setState(errors);
  }

  doSubmit() {
    const { data } = this.state;
    if (!this.isFormDataValid()) {
      this.checkForInvalidData();
    } else {
      this.props.onCreateProject({ ...data });
      this.clearData(data);
      this.props.onHide();
      this.setState({ data });
    }
  }

  handleModalCancel = () => {
    const { data } = this.state;
    this.clearData(data);
    this.props.onHide();
    this.setState({ data });
  };

  clearData(data) {
    data.projectName = "";
    data.startDate = new Date().toLocaleDateString("en-CA", { timeZone: "UTC" });
    data.endDate = new Date().toLocaleDateString("en-CA", { timeZone: "UTC" });
    data.selectedStates = [];
    data.parentProjects = [];
  }

  render() {
    const { data, errors } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.modalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader}>
            <Row className={globalStyles.modalHeaderRow}>
              <Col className="px-0">
                <Modal.Title className={globalStyles.modalTitleText}>Create Project</Modal.Title>
              </Col>
              <Col>
                <CloseButton onClick={this.handleModalCancel} />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <Row className="mx-0">
              <Col>{this.renderInput("projectName", "Project Name", "text", "Enter Name", "required")}</Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderInput("startDate", "Start Date", "date", "Select Date", "required")}</Col>
              <Col>{this.renderInput("endDate", "End Date", "date", "Select Date", "required")}</Col>
            </Row>
            <Row className="mx-0 mb-3">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>
                    State(s)<span className={globalStyles.asterisk508}>*</span>
                  </Form.Label>
                  <Select
                    value={data.selectedStates}
                    placeholder="Select"
                    isMulti={true}
                    styles={this.customSelectStyles}
                    options={this.props.availableStates}
                    onChange={(e) => this.handleSelectChanges(e, "selectedStates")}
                  />
                  {errors.selectedStates && <div className="alert alert-danger">{errors.selectedStates}</div>}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Parent Project(s)</Form.Label>
                  <Select
                    value={data.parentProjects}
                    placeholder="Select"
                    isMulti={true}
                    styles={this.customSelectStyles}
                    options={this.state.availableProjects}
                    onChange={(e) => this.handleSelectChanges(e, "parentProjects")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit">
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default CreateProjectModal;
