import React from "react";
import styles from "../AgreementEntities/Contact/Contact.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Card, Row, Col, Form, InputGroup, FormControl, Button } from "react-bootstrap";
import UtilityFunctions from "./UtilityFunctions";

const PhoneNumberDisplay = ({ phoneNumbers, phoneNumberTypes }) => {
  if (phoneNumberTypes.length > 0) {
    return (
      <div className="mx-2">
        {phoneNumbers.map((phoneNumber, index) => {
          let phoneType = phoneNumber.phoneType;
          if (phoneNumber.phoneTypeEnumId) {
            phoneType = phoneNumberTypes[parseInt(phoneNumber.phoneTypeEnumId) - 1];
          }
          return (
            <div key={index}>
              <Row>
                <Col>
                  <Form.Group controlId={"phoneNumber-" + index} className="text-left">
                    <h6 className={styles.fieldLabels}>Phone Number</h6>
                    <p className={styles.dataFields}>{UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber)}</p>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={"phoneTypeEnumId-" + index} className="text-left">
                    <h6 className={styles.fieldLabels}>
                      Phone Number Type<span className={globalStyles.asterisk508}>*</span>
                    </h6>
                    <p className={styles.dataFields}>{phoneType?.displayText}</p>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default PhoneNumberDisplay;
