import React from "react";
import { Row, Col, Tabs, Tab, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import UtilityFunctions from "../common/UtilityFunctions";
import styles from "./PDFHandling.module.scss";

const AgreementTypes = {
  PrivateProperty: 1,
  NonPrivateProperty: 2,
  TemporaryCivil: 3,
  AdjacentLandowner: 4,
  AmendmentToExistingAgreement: 5,
};

const AgreementContactRoles = {
  ResourceOwner: 1,
  DesignatedContact: 2,
  Owner: 3,
  Administrator: 4,
  Lessee: 5,
};

class PrintAgreementForm extends CustomForm {
  state = {};

  renderLabel(name, value) {
    return (
      <div className="text-left pb-1">
        <Form.Label className={styles.formLabel}>{name}</Form.Label>
        <Form.Text className={styles.formData}>{value}</Form.Text>
      </div>
    );
  }

  renderPageFooter(pageNumber) {
    return (
      <div>
        <Row className={styles.grayTopSeparatorLine}>
          <Col className="pl-0">
            <span className={styles.docTitle}>WS FORM 12A (JAN 21)</span>
          </Col>
          <Col className="text-right pr-0">
            <span className={styles.docTitle}>Page {pageNumber}</span>
          </Col>
        </Row>
      </div>
    );
  }

  renderAgreementTypeCheckBox(agreementTypeId, label) {
    return (
      <Form.Check type="checkbox" id={agreementTypeId} className="text-left">
        <Form.Check.Input
          type="checkbox"
          className={styles.checkBoxes}
          defaultChecked={this.props.agreementData?.agreementType?.id === agreementTypeId}
        />
        <Form.Check.Label className={styles.formLabel}>{label}</Form.Check.Label>
      </Form.Check>
    );
  }

  getContactName(contactData) {
    const { firstName, middleName, lastName, suffix } = contactData;
    return [firstName, middleName, lastName, suffix].join(" ");
  }

  getPrimaryPhoneNumber(phoneNumbers) {
    let phoneNumber = "";
    if (phoneNumbers && phoneNumbers.length > 0) {
      phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumbers[0].phoneNumber);
    }
    return phoneNumber;
  }

  getPrimaryAddress(providedAddress, entityType) {
    let primaryAddress = "";
    if (providedAddress) {
      switch (entityType) {
        case "contact":
          const { mailingStreet, mailingCity, mailingStateUniqueId, mailingZip, mailingCountryUniqueId } =
            providedAddress;
          primaryAddress = mailingStreet.concat(
            ", ",
            mailingCity,
            ", ",
            this.getStateNameFromId(mailingStateUniqueId),
            " ",
            mailingZip,
            ", " + this.getCountryNameFromId(mailingCountryUniqueId)
          );
          break;
        case "property":
          const { streetAddress, city, zipCode } = providedAddress;
          primaryAddress = streetAddress.concat(", ", city, ", ", zipCode);
          break;
        default:
          break;
      }
    }

    return primaryAddress;
  }

  getStateNameFromId(stateId) {
    const { states } = this.props;
    const currentState = states.find((state) => state.stateUniqueIdentifier === stateId);
    return currentState ? currentState.state_name : "state name";
  }

  getCountryNameFromId(countryId) {
    const { countries } = this.props;
    const country = countries.find((country) => country.id === countryId);
    return country ? country.name : "country name";
  }

  getDisplayTextFromFieldObject(object) {
    let objectName = "";
    if (object?.displayText) objectName = object.displayText;
    else if (object.name) objectName = object.name;

    return objectName;
  }

  handleAgreementTypeSelection = ({ currentTarget }) => {
    console.log(currentTarget);
  };

  renderFirstLegalPage(propertiesCount) {
    const pageNumber = propertiesCount > 1 ? propertiesCount * 2 + 3 : 4;
    return (
      <React.Fragment>
        <div className={styles.printingPage}>
          <div className="px-3 text-left">
            <p className={styles.legalPageTitle}>PRIVACY ACT NOTICE</p>
            <p className={styles.legalPageText}>
              5 U.S.C. 552a(e)(3) requires that each agency that maintains a system of records provide each individual
              from whom the agency solicits information with the following information.
            </p>
            <p className={styles.legalPageSubTitle}>AUTHORITY FOR REQUESTING INFORMATION</p>
            <p className={styles.legalPageText}>
              7 U.S.C. 8351 to 8353, and 16 U.S.C. 667, authorizes officers, agents, and employees of the United States
              Department of Agriculture (USDA), Animal and Plant Health Inspection Service (APHIS), Wildlife Services
              (WS) to conduct a program of wildlife services and to enter into agreements with States, local
              jurisdictions, individuals, and public and private agencies, organizations, and institutions for the
              purpose of conducting such services.
            </p>
            <p className={styles.legalPageSubTitle}>NATURE OF YOUR DISCLOSURE OF INFORMATION</p>
            <p className={styles.legalPageText}>
              Disclosure of information solicited by USDA, APHIS, Wildlife Services is voluntary.
            </p>
            <p className={styles.legalPageSubTitle}>PRINCIPLE PURPOSE FOR WHICH THE INFORMATION IS SOLICITED</p>
            <p className={styles.legalPageText}>
              Information is solicited from you for the purpose of executing and implementing agreements for control of
              wildlife damage.
            </p>
            <p className={styles.legalPageSubTitle}>ROUTINE USES WHICH MAY BE MADE OF THE INFORMATION</p>
            <p className={styles.legalPageText}>
              (1) To cooperative Federal, State, Tribal, and local government officials, employees, or contractors and
              other parties as necessary to carry out the program; and other parties engaged to assist in administering
              the program. Such contractors and other parties will be bound by the nondisclosure provisions of the
              Privacy Act. This routine use assists the agency in carrying out the program, and thus is compatible with
              the purpose for which the records are created and maintained;
            </p>
            <p className={styles.legalPageText}>
              (2) To the appropriate agency, whether Federal, State, local, Tribal, or foreign, charged with
              responsibility of investigating or prosecuting a violation of law or of enforcing, implementing, or
              complying with a statute, rule, regulation, or order issued pursuant thereto, of any record within this
              system when information available indicates a violation or potential violation of law, whether civil,
              criminal, or regulatory in nature, and either arising by general statute or particular program statute, or
              by rule, regulation, or court order issued pursuant thereto;
            </p>
            <p className={styles.legalPageText}>
              (3) To the Department of Justice when the agency, or any component thereof, or any employee of the agency
              in his or her official capacity, or any employee of the agency in his or her individual capacity where the
              Department of Justice has agreed to represent the employee, or the United States, in litigation, where the
              agency determines that litigation is likely to affect the agency or any of its components, is a party to
              litigation or has an interest in such litigation, and the use of such records by the Department of Justice
              is deemed by the agency to be relevant and necessary to the litigation; provided, however, that in each
              case, the agency determines that disclosure of the records to the Department of Justice is a use of the
              information contained in the records that is compatible with the purpose for which the records were
              collected;
            </p>
            <p className={styles.legalPageText}>
              (4) For use in a proceeding before a court or adjudicative body before which the agency is authorized to
              appear, when the agency, or any component thereof, or any employee of the agency in his or her official
              capacity, or any employee of the agency in his or her individual capacity where the agency has agreed to
              represent the employee, or the United States, where the agency determines that litigation is likely to
              affect the agency or any of its components, is a party to litigation or has an interest in such
              litigation, and the agency determines that use of such records is relevant and necessary to the
              litigation; provided, however, that in each case, the agency determines that disclosure of the records to
              the court is a use of the information contained in the records that is compatible with the purpose for
              which the records were collected;
            </p>
            <p className={styles.legalPageText}>
              (5) To appropriate agencies, entities, and persons when the agency suspects or has confirmed that the
              security or confidentiality of information in the system of records has been compromised; the agency has
              determined that as a result of the suspected or confirmed compromise, there is a risk of harm to economic
              or property interests, a risk of identity theft or fraud, or a risk of harm to the security of integrity
              of this system or other systems or programs (whether maintained by the agency or another agency or entity)
              that rely upon the compromised information; and the disclosure made to such agencies, entities, and
              persons is reasonably necessary to assist in connection with the agency’s efforts to respond to the
              suspected or confirmed compromise and prevent, minimize, or remedy such harm;
            </p>
            <p className={styles.legalPageText}>
              (6) To USDA contractors, partner agency employee or contractors, or private industry employed to identify
              patterns, trends, or anomalies indicative of fraud, waste, or abuse;
            </p>
            <p className={styles.legalPageText}>
              (7) To land management agencies, such as the Bureau of Land Management and the U.S. Fish and Wildlife
              Service, relating to wildlife damage on grazing allotments;
            </p>
            <p className={styles.legalPageText}>
              (8) To consumer reporting agencies in accordance with 31 U.S.C. 3711(e);
            </p>
            <p className={styles.legalPageText}>
              (9) To Federal, State, Tribal, and local regulatory agencies and their employees and contractors who
              collaborate with Wildlife Services in implementation of, or agencies that regulate, wildlife management
              projects or programs, or who have an interest in, or regulate, animal or public health, or national
              security;
            </p>
            <p className={styles.legalPageText}>
              (10) To Federal or State Government-level representatives of the U.S. Environmental Protection Agency, in
              compliance with the Federal Insecticide, Fungicide, and Rodenticide Act (FIFRA) mandate (7 U.S.C. 136), of
              the location on a cooperator’s property where certain regulated pesticide devices are deployed or
              regulated pesticides are applied; and
            </p>
            <p className={styles.legalPageText}>
              (11) To the National Archives and Records Administration (NARA) or to the General Services Administration
              for records management inspections conducted under 44 U.S.C. 2904 and 2906.
            </p>
            <p className={styles.legalPageSubTitle}>EFFECTS OF FAILURE TO FURNISH INFORMATION</p>
            <p className={styles.legalPageText}>
              Failure to provide the solicited information will not subject you to penalties or adverse consequences.
            </p>
          </div>
          <div className={styles.pageFooterBlock}>
            <div className="px-3 text-left">{this.renderPageFooter(pageNumber)}</div>
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </React.Fragment>
    );
  }

  renderSecondLegalPage(propertiesCount) {
    const pageNumber = propertiesCount > 1 ? propertiesCount * 2 + 4 : 5;
    return (
      <React.Fragment>
        <div className={styles.printingPage}>
          <div className="px-3 text-left">
            <p className={styles.legalPageTitle}>
              Instructions for Completing WS Form 12A, Work Initiation Document For Wildlife Damage Management
            </p>
            <p className={styles.legalPageBoldText}>
              NOTE: A WS Form 12A Work Initiation Document (WID) is required to be signed by a Cooperator for all
              properties worked by Wildlife Services (WS). The WID and the WID agreement in WS Management Information
              System (MIS) expire 5 years after the Cooperator signature date. To renew a WID agreement for another 5
              years, a new WS Form 12A is required to be signed by the Cooperator, and{" "}
              <span style={{ textDecoration: "underline" }}>everything</span> on the WID agreement in MIS{" "}
              <span style={{ textDecoration: "underline" }}>must match</span> the corresponding items on the WS Form
              12A.
            </p>
            <p className={styles.legalPageBoldText}>
              NOTE: An expanded and detailed set of instructions for the WID is available in MIS. Employees should study
              the detailed version until familiar with completing the WID.
            </p>
            <p className={styles.legalPageBoldText}>SECTION 1 – Basic WID Identification and Type</p>
            <p className={styles.legalPageText}>
              1. Entering the WID document number is optional. Contact your MIS Data Technician to obtain this number if
              one is needed.
            </p>
            <p className={styles.legalPageText}>
              2. Mark only one box, selecting New (agreement) or Renewal (of existing agreement).
            </p>
            <p className={styles.legalPageText}>
              3. A WID may have more than one type of work characteristic. Mark all applicable boxes indicating this
              WID’s type of work.
            </p>
            <p className={styles.legalPageBoldText}>SECTION 2 – Cooperator Information</p>
            <p className={styles.legalPageText}>
              4. Enter the name of the Cooperator as it appears on the Cooperative Service Agreement, Cooperative
              Service Field Agreement, Memorandum of Agreement or Understanding, or as it appears in the Cooperator’s
              business references.
            </p>
            <p className={styles.legalPageText}>
              5. Enter the Cooperator’s mailing address with street or P.O. box, city, state, and ZIP code. This may be
              the permanent address, mailing address, home address, or business/office address.
            </p>
            <p className={styles.legalPageText}>
              6. Enter the common name of the Cooperator’s business, farm, or ranch, if applicable.
            </p>
            <p className={styles.legalPageText}>
              7. Enter the Cooperator’s telephone number including the area code. It may be a landline or mobile
              telephone number.
            </p>
            <p className={styles.legalPageText}>
              8. Enter the name of the owner or Cooperator’s representative if it is different from the Cooperator’s
              name.
            </p>
            <p className={styles.legalPageText}>
              9. Enter the property owner’s or property owner representative’s telephone number including area code.
            </p>
            <p className={styles.legalPageText}>
              10. Enter the property owner’s address (or property owner representative’s work address if this is a
              business or a non-private agreement), including the ZIP code. If the Cooperator’s address in Item 5 is
              also the owner’s address, skip this step.
            </p>
            <p className={styles.legalPageBoldText}>
              SECTION 3 – Property Information, Location of Work, and Damage Agent being Addressed
            </p>
            <p className={styles.legalPageText}>
              11. In this subsection, record the state and counties for the site(s) where the work is being performed
              for the cooperator. Include for each property the land class being worked on and the number of acres for
              the land class. Usually, only one land class can be recorded per property. If there is more than one land
              class on a property, identify each of them separately (e.g. Property: Smith #1, Land Class Private; Smith
              #2, Land Class County/City). Record the total acres by summing all entries in the “Acres” column.
            </p>
            <p className={styles.legalPageText}>
              12. If the WID allows work on an adjoining property as part of the project, additional WIDs signed by the
              adjoining landowners/ managers must be obtained. The WID document numbers for the adjoining properties go
              in this subsection. If WID numbers are not assigned, list the name of the other land owners/managers
              instead of numbers.
            </p>
            <p className={styles.legalPageText}>
              13. List the full common names of the targeted damage agents as found in MIS. Review MIS Reference Files
              for accuracy.
            </p>
            <p className={styles.legalPageText}>
              14. If more than 10 damage agents are targeted, mark this box and list the additional damage agents on WS
              Form 12 Addendum. The WS Form 12A also requires an original signature. Ensure the WS Form 12 Addendum is
              attached to this WS Form 12A.
            </p>
            <p className={styles.legalPageBoldText}>SECTION 4 – Component Use Information</p>
            <p className={styles.legalPageText}>
              15. List the components that will be used in the project. They must be annotated exactly as they appear in
              the MIS component list. Do not list any activities (e.g., shooting, trapping, etc.).
            </p>
            <p className={styles.legalPageText}>
              16. If more than six components are entered, mark this box and list the additional components on WS Form
              12 Addendum. The WS Form 12 Addendum also requires an original signature. Ensure the addendum is attached
              to this WS Form 12A.
            </p>
            <p className={styles.legalPageBoldText}>Section 5 – Work Initiation Considerations, WS Responsibilities</p>
            <p className={styles.legalPageText}>
              17. The Cooperator is required to read this section, or alternatively have it read to him/her, before
              signatures are affixed to the WID.
            </p>
            <p className={styles.legalPageBoldText}>
              Section 6 – Work Initiation Considerations, Cooperator Responsibilities
            </p>
            <p className={styles.legalPageText}>
              18. The Cooperator is required to read this section, or alternatively have it read to him/her, before
              signatures are affixed to the WID.
            </p>
            <p className={styles.legalPageText}>
              19. <span style={{ fontWeight: "bold" }}>Special Considerations -</span> If any special considerations are
              agreed to for this project, enter them in Item 19. They may also be entered in the MIS in the Agreement
              Remarks or the Property Comments fields.
            </p>
            <p className={styles.legalPageText}>
              20, 21. <span style={{ fontWeight: "bold" }}>Signatures/Dates –</span> To complete the form, both the
              Cooperator’s authorized signer and the APHIS Representative (WS employee) print their names, sign in ink,
              and date the WS Form 12A. The WS employee also enters a contact telephone number and the State Office’s
              address.
            </p>
            <p className={styles.legalPageText}>
              The WS employee is responsible for ensuring the Cooperator or authorized representative receives a copy of
              the WS Privacy Act Notice.
            </p>
          </div>
          <div className={styles.pageFooterBlock}>
            <div className="px-3 text-left">{this.renderPageFooter(pageNumber)}</div>
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </React.Fragment>
    );
  }

  renderInitialPageHeaderSection() {
    const { agreementData, cooperatorData } = this.props;
    return (
      <React.Fragment>
        <div className={styles.testInitialSection}>
          <Row className={styles.topHeaderRow}>
            <Col className={styles.topDisclaimerBlock}>
              <p className={styles.topDisclaimerText}>
                According to the Paperwork Reduction Act of 1995, an agency may not conduct or sponsor, and a person is
                not required to respond to, a collection of information unless it displays a valid OMB control number.
                The valid OMB control number for this information collection is 0579-0335. The time required to complete
                this information collection is estimated to average .083 hours per response, including the time for
                reviewing instructions, searching existing data sources, gathering and maintaining the data needed, and
                completing and reviewing the collection of information.
              </p>
            </Col>
            <Col sm={2}>
              <p className={styles.docNumberInfo}>OMB Approved</p>
              <p className={styles.docNumberInfo}>0579-0335</p>
              <p className={styles.docNumberInfo}>EXP. XX/XXXX</p>
            </Col>
          </Row>
          <Row className={styles.greenBottomLine}>
            <Col>
              <p className={styles.docTitle}>
                UNITED STATES DEPARTMENT OF AGRICULTURE ANIMAL AND PLANT HEALTH INSPECTION SERVICE WILDLIFE SERVICES
              </p>
              <p className={styles.docTitle}>WORK INITIATION DOCUMENT FOR WILDLIFE DAMAGE MANAGEMENT</p>
            </Col>
          </Row>
          <div className="px-3">
            <Row className={styles.grayBottomSeparatorLine}>
              <Col sm={4} className={styles.grayRightSeparatorLine}>
                <Row className={styles.grayBottomSeparatorLine}>
                  <Col>{this.renderLabel("Agreement Number", agreementData?.agreementNumber)}</Col>
                </Row>
                <Row>
                  <Col>{this.renderLabel("Common Name", agreementData?.commonName)}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="text-left">
                  <Col>
                    <Form.Label className={styles.formLabel}>Agreement Type</Form.Label>
                  </Col>
                </Row>
                <Form.Group>
                  <Row>
                    <Col>{this.renderAgreementTypeCheckBox(AgreementTypes.PrivateProperty, "Private Property")}</Col>
                    <Col>
                      {this.renderAgreementTypeCheckBox(AgreementTypes.NonPrivateProperty, "Non-Private Property")}
                    </Col>
                    <Col>{this.renderAgreementTypeCheckBox(AgreementTypes.TemporaryCivil, "Temporary/Civil")}</Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      {this.renderAgreementTypeCheckBox(AgreementTypes.AdjacentLandowner, "Adjacent Landowner")}
                    </Col>
                    <Col>
                      {this.renderAgreementTypeCheckBox(
                        AgreementTypes.AmendmentToExistingAgreement,
                        "Amendment to an Existing Agreement"
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className={styles.grayBottomSeparatorLine}>
              <Col sm={4} className={styles.grayRightSeparatorLine}>
                {this.renderLabel("Cooperator Name", cooperatorData?.fullName ? cooperatorData.fullName : "name")}
              </Col>
              <Col sm={2} className={styles.grayRightSeparatorLine}>
                {this.renderLabel(
                  "Phone Number",
                  cooperatorData?.primaryPhoneNumber
                    ? UtilityFunctions.formatPhoneNumber(cooperatorData.primaryPhoneNumber)
                    : "phone"
                )}
              </Col>
              <Col sm={6}>{this.renderLabel("Email", cooperatorData?.emailAddress)}</Col>
            </Row>
            <Row className={styles.grayBottomSeparatorLine}>
              <Col>
                {this.renderLabel(
                  "Cooperator Address",
                  cooperatorData?.fullMailingAddress ? cooperatorData.fullMailingAddress : "address"
                )}
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderAssociatedContactsTab(propertyIndex = 0) {
    return (
      <Tabs className={styles.greenTabs}>
        <Tab eventKey="associatedContacts" title="Associated Contacts">
          {this.renderContactsTabContent(propertyIndex)}
        </Tab>
      </Tabs>
    );
  }

  renderContactsTabContent(propertyIndex) {
    const { agreementData, associatedContactsData } = this.props;
    let contactsAmount = 0;
    let additionalContacts;
    if (agreementData?.agreementProperties?.length > propertyIndex) {
      const propertyUniqueId = agreementData.agreementProperties[propertyIndex]?.propertyUniqueReference;
      additionalContacts = associatedContactsData?.filter((c) => c.propertyUniqueIdentifier === propertyUniqueId);
      contactsAmount = additionalContacts?.length;
    }
    const tableLength = contactsAmount > 8 ? contactsAmount : 8;

    return (
      <div className="px-3 text-left">
        <Row className={styles.grayHeaderRow}>
          <Col sm={3} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Contacts</Form.Label>
          </Col>
          <Col sm={1} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Phone</Form.Label>
          </Col>
          <Col sm={3} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Email</Form.Label>
          </Col>
          <Col sm={3} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Address</Form.Label>
          </Col>
          <Col sm={2}>
            <Form.Label className={styles.formLabel}>Role</Form.Label>
          </Col>
        </Row>
        {[...Array(tableLength)].map((e, index) => (
          <Row key={index} className={styles.grayBottomSeparatorLine}>
            <Col sm={3} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index < contactsAmount ? additionalContacts[index]?.fullName : ""}
              </Form.Text>
            </Col>
            <Col sm={1} className={styles.smallColumnsWithGrayRightSeparator}>
              <Form.Text className={styles.formData}>
                {index < contactsAmount
                  ? UtilityFunctions.formatPhoneNumber(additionalContacts[index]?.primaryPhoneNumber)
                  : ""}
              </Form.Text>
            </Col>
            <Col sm={3} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index < contactsAmount ? additionalContacts[index]?.emailAddress : ""}
              </Form.Text>
            </Col>
            <Col sm={3} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index < contactsAmount ? additionalContacts[index]?.fullMailingAddress : ""}
              </Form.Text>
            </Col>
            <Col sm={2}>
              <Form.Text className={styles.formData}>
                {index < contactsAmount ? additionalContacts[index]?.contactRole : ""}
              </Form.Text>
            </Col>
          </Row>
        ))}
      </div>
    );
  }

  renderLegalSignaturePage(propertiesCount) {
    const { primaryFieldAgentData } = this.props;
    const isMultiProperty = propertiesCount > 1;
    const pageNumber = propertiesCount > 1 ? propertiesCount * 2 + 2 : 3;

    return (
      <React.Fragment>
        <div className={styles.printingPage}>
          <div className={styles.legalSignaturePageTopSection}>
            {!isMultiProperty && this.renderAssociatedContactsTab()}
            <div className={styles.associatedContactsNotesBlock}>
              <p className={styles.middlePageDisclaimerText}>
                Nam efficitur egestas gravida. Aenean bibendum ante sit amet metus pharetra, eu vehicula elit rhoncus.
                Aliquam eu tempus eros. Integer sed varius tortor. Nullam libero sem, bibendum consectetur laoreet sed,
                imperdiet non nisi. Aliquam ut scelerisque tellus. Nam auctor, libero ut efficitur egestas, odio dolor
                ullamcorper justo, at aliquet felis felis quis erat. Phasellus rutrum ligula ipsum. In hac habitasse
                platea dictumst. Maecenas mattis, tortor sed ultricies ultricies, leo orci bibendum odio, eu eleifend
                dui sapien vitae velit. Nam efficitur egestas gravida. Aenean bibendum ante sit amet metus pharetra, eu
                vehicula elit rhoncus. Aliquam eu tempus eros. Integer sed varius tortor. Nullam libero sem, bibendum
                consectetur laoreet sed, imperdiet non nisi. Aliquam ut scelerisque tellus. Nam auctor, libero ut
                efficitur egestas, odio dolor ullamcorper justo, at aliquet felis felis quis erat. Phasellus rutrum
                ligula ipsum. In hac habitasse platea dictumst. Maecenas mattis, tortor sed ultricies ultricies, leo
                orci bibendum odio, eu eleifend dui sapien vitae velit.
              </p>
              <p className={styles.middlePageDisclaimerText}>
                Nam efficitur egestas gravida. Aenean bibendum ante sit amet metus pharetra, eu vehicula elit rhoncus.
                Aliquam eu tempus eros. Integer sed varius tortor. Nullam libero sem, bibendum consectetur laoreet sed,
                imperdiet non nisi. Aliquam ut scelerisque tellus. Nam auctor, libero ut efficitur egestas, odio dolor
                ullamcorper justo, at aliquet felis felis quis erat. Phasellus rutrum ligula ipsum. In hac habitasse
                platea dictumst. Maecenas mattis, tortor sed ultricies ultricies, leo orci bibendum odio, eu eleifend
                dui sapien vitae velit. Nam efficitur egestas gravida. Aenean bibendum ante sit amet metus pharetra, eu
                vehicula elit rhoncus. Aliquam eu tempus eros. Integer sed varius tortor. Nullam libero sem, bibendum
                consectetur laoreet sed, imperdiet non nisi. Aliquam ut scelerisque tellus. Nam auctor, libero ut
                efficitur egestas, odio dolor ullamcorper justo, at aliquet felis felis quis erat. Phasellus rutrum
                ligula ipsum. In hac habitasse platea dictumst. Maecenas mattis, tortor sed ultricies ultricies, leo
                orci bibendum odio, eu eleifend dui sapien vitae velit. Nam efficitur egestas gravida. Aenean bibendum
                ante sit amet metus pharetra, eu vehicula elit rhoncus. Aliquam eu tempus eros. Integer sed varius
                tortor. Nullam libero sem, bibendum consectetur laoreet sed, imperdiet non nisi. Aliquam ut scelerisque
                tellus. Nam auctor, libero ut efficitur egestas, odio dolor ullamcorper justo, at aliquet felis felis
                quis erat. Phasellus rutrum ligula ipsum. In hac habitasse platea dictumst. Maecenas mattis, tortor sed
                ultricies ultricies, leo orci bibendum odio, eu eleifend dui sapien vitae velit.
              </p>
            </div>
          </div>
          <div className={styles.pageFooterBlock}>
            <div className="px-3 text-left">
              <Row className={styles.grayTopBottomSeparatorLines}>
                <Col sm={7} className={styles.grayRightSeparatorLine}>
                  {this.renderLabel("Landowner, Lessee, or Administrator Name and Title", this.getLandOwnerName())}
                </Col>
                <Col sm={3} className={styles.grayRightSeparatorLine}>
                  {this.renderLabel("Signature")}
                </Col>
                <Col sm={2}>{this.renderLabel("Date")}</Col>
              </Row>
              <Row className={styles.grayBottomSeparatorLine}>
                <Col sm={7} className={styles.grayRightSeparatorLine}>
                  {this.renderLabel(
                    "APHIS Representative Name",
                    primaryFieldAgentData?.fullName ? primaryFieldAgentData.fullName : ""
                  )}
                </Col>
                <Col sm={3} className={styles.grayRightSeparatorLine}>
                  {this.renderLabel("Signature")}
                </Col>
                <Col sm={2}>{this.renderLabel("Date")}</Col>
              </Row>
              <Row className={styles.grayBottomSeparatorLine}>
                <Col sm={7} className={styles.grayRightSeparatorLine}>
                  {this.renderLabel(
                    "APHIS Representative Phone Number",
                    primaryFieldAgentData?.primaryPhoneNumber
                      ? UtilityFunctions.formatPhoneNumber(primaryFieldAgentData.primaryPhoneNumber)
                      : ""
                  )}
                </Col>
                <Col>
                  {this.renderLabel(
                    "State Office Address",
                    primaryFieldAgentData?.fullMailingAddress
                      ? primaryFieldAgentData.fullMailingAddress
                      : "mailing address"
                  )}
                </Col>
              </Row>
              <Row>
                <div style={{ minHeight: "40px" }}></div>
              </Row>
              {this.renderPageFooter(pageNumber)}
            </div>
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </React.Fragment>
    );
  }

  getLandOwnerName() {
    const { agreementData, cooperatorData } = this.props;
    let landOwnerName = "";
    const cooperatorRoleId = agreementData.cooperator?.cooperatorRole?.id;
    if (
      (cooperatorRoleId && cooperatorRoleId === AgreementContactRoles.Owner) ||
      cooperatorRoleId === AgreementContactRoles.Administrator ||
      cooperatorRoleId === AgreementContactRoles.Lessee
    ) {
      landOwnerName = cooperatorData?.fullName;
    } else {
      const otherContactLandOwner = agreementData?.additionalContacts?.find(
        (c) =>
          c?.contactRoleOnAgreement?.id === AgreementContactRoles.Owner ||
          c?.contactRoleOnAgreement?.id === AgreementContactRoles.Administrator ||
          c?.contactRoleOnAgreement?.id === AgreementContactRoles.Lessee
      );
      if (otherContactLandOwner) {
        landOwnerName = this.props.associatedContactsData?.find(
          (c) => c.contactUniqueId === otherContactLandOwner.contactUniqueReference
        )?.fullName;
      }
    }
    return landOwnerName;
  }

  renderSpecificPropertySummary(propertyIndex = 0) {
    const { agreementData, propertiesData } = this.props;
    let propertyData;
    if (agreementData?.agreementProperties?.length > propertyIndex) {
      const agreementProperty = agreementData.agreementProperties[propertyIndex];
      propertyData = propertiesData?.find(
        (p) => p.propertyUniqueIdentifier === agreementProperty.propertyUniqueReference
      );
    }

    return (
      <React.Fragment>
        <div>
          <Row className={styles.grayBottomSeparatorLine}>
            <Col sm={4} className={styles.grayRightSeparatorLine}>
              {this.renderLabel(
                "Common Name",
                propertyData?.propertyName ? propertyData.propertyName : "property name"
              )}
            </Col>
            <Col sm={8}>
              {this.renderLabel(
                "Address",
                propertyData?.additionalStreetAddresses?.length > 0
                  ? this.getPrimaryAddress(propertyData.additionalStreetAddresses[0], "property")
                  : "street address"
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={4} className={styles.grayRightSeparatorLine}>
              {this.renderLabel("County", propertyData?.county)}
            </Col>
            <Col sm={2} className={styles.grayRightSeparatorLine}>
              {this.renderLabel("Acreage", propertyData?.acres)}
            </Col>
            <Col sm={3} className={styles.grayRightSeparatorLine}>
              {this.renderLabel(
                "Land Class",
                propertyData?.landClass ? this.getDisplayTextFromFieldObject(propertyData.landClass) : "land class"
              )}
            </Col>
            <Col sm={3}>{this.renderLabel("Coordinates (optional)", "coordiates")}</Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  renderPropertyExtraData(propertyIndex = 0) {
    return (
      <React.Fragment>
        <div className={styles.printingPage}>
          <div className="px-3">
            {this.renderResourcesTable(propertyIndex)}
            {this.renderResourcesTable(propertyIndex, true)}
            {this.renderSpecialInstructionsForProperty(propertyIndex)}
          </div>
          <div className={styles.pageFooterBlock}>
            <div className="px-3 text-left">{this.renderPageFooter(propertyIndex * 2 + 3)}</div>
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </React.Fragment>
    );
  }

  renderSinglePropertySpeciesTable() {
    const { agreementData } = this.props;
    let speciesAmount = 0;
    let targetedSpecies = [];
    if (
      agreementData?.agreementProperties?.length > 0 &&
      agreementData.agreementProperties[0].targetedSpecies?.length > 0
    ) {
      targetedSpecies = agreementData.agreementProperties[0].targetedSpecies;
      speciesAmount = targetedSpecies.length;
    }
    const tableLength = speciesAmount > 8 ? speciesAmount : 8;

    return (
      <React.Fragment>
        <Row className={styles.grayHeaderRow}>
          <Col sm={4} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Targeted Damage Agents</Form.Label>
          </Col>
          <Col sm={2} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Parcel (opt.)</Form.Label>
          </Col>
          <Col sm={4} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Targeted Damage Agents</Form.Label>
          </Col>
          <Col sm={2}>
            <Form.Label className={styles.formLabel}>Parcel (opt.)</Form.Label>
          </Col>
        </Row>
        {[...Array(tableLength)].map((e, index) => (
          <Row key={index} className={styles.grayBottomSeparatorLine}>
            <Col sm={4} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index < speciesAmount ? targetedSpecies[index]?.speciesName : ""}
              </Form.Text>
            </Col>
            <Col sm={2} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index < speciesAmount && targetedSpecies[index]?.checkedProperties[0]?.checkedParcels?.length > 0
                  ? targetedSpecies[index].checkedProperties[0].checkedParcels
                      .map((parcel) => parcel?.parcelName)
                      .join(", ")
                  : ""}
              </Form.Text>
            </Col>
            <Col sm={4} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index + 8 < speciesAmount ? targetedSpecies[index + 8]?.speciesName : ""}
              </Form.Text>
            </Col>
            <Col sm={2}>
              <Form.Text className={styles.formData}>
                {index + 8 < speciesAmount &&
                targetedSpecies[index + 8]?.checkedProperties[0]?.checkedParcels?.length > 0
                  ? targetedSpecies[index + 8].checkedProperties[0].checkedParcels
                      .map((parcel) => parcel?.parcelName)
                      .join(", ")
                  : ""}
              </Form.Text>
            </Col>
          </Row>
        ))}
      </React.Fragment>
    );
  }

  renderSinglePropertyMethodsTable() {
    const { agreementData } = this.props;
    let methodsAmount = 0;
    let allowedMethods = [];
    if (
      agreementData?.agreementProperties?.length > 0 &&
      agreementData.agreementProperties[0].allowedMethods?.length > 0
    ) {
      allowedMethods = agreementData.agreementProperties[0].allowedMethods;
      methodsAmount = allowedMethods.length;
    }
    const tableLength = methodsAmount > 8 ? methodsAmount : 8;

    return (
      <React.Fragment>
        <Row className={styles.grayHeaderRow}>
          <Col sm={4} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Methods</Form.Label>
          </Col>
          <Col sm={2} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Parcel</Form.Label>
          </Col>
          <Col sm={4} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Methods</Form.Label>
          </Col>
          <Col sm={2}>
            <Form.Label className={styles.formLabel}>Parcel</Form.Label>
          </Col>
        </Row>
        {[...Array(8)].map((e, index) => (
          <Row key={index} className={styles.grayBottomSeparatorLine}>
            <Col sm={4} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index < methodsAmount ? allowedMethods[index]?.methodName : ""}
              </Form.Text>
            </Col>
            <Col sm={2} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index < methodsAmount && allowedMethods[index]?.checkedProperties[0]?.checkedParcels?.length > 0
                  ? allowedMethods[index].checkedProperties[0].checkedParcels
                      .map((parcel) => parcel?.parcelName)
                      .join(", ")
                  : ""}
              </Form.Text>
            </Col>
            <Col sm={4} className={styles.grayRightSeparatorLine}>
              <Form.Text className={styles.formData}>
                {index + 8 < methodsAmount ? allowedMethods[index + 8]?.methodName : ""}
              </Form.Text>
            </Col>
            <Col sm={2}>
              <Form.Text className={styles.formData}>
                {index + 8 < methodsAmount &&
                allowedMethods[index + 8]?.checkedProperties[0]?.checkedParcels?.length > 0
                  ? allowedMethods[index + 8].checkedProperties[0].checkedParcels
                      .map((parcel) => parcel?.parcelName)
                      .join(", ")
                  : ""}
              </Form.Text>
            </Col>
          </Row>
        ))}
      </React.Fragment>
    );
  }

  renderResourcesTable(propertyIndex, isHistorical = false) {
    const { agreementData } = this.props;
    let resourcesAmount = 0;
    let resources;

    if (agreementData?.agreementProperties?.length > propertyIndex) {
      const filteredResources = agreementData.agreementProperties[propertyIndex].agreementResources?.filter(
        (r) => r.isHistorical === isHistorical
      );
      if (filteredResources?.length > 0) {
        resources = filteredResources;
        resourcesAmount = resources.length;
      }
    }
    const tableLength = resourcesAmount > 8 ? resourcesAmount : 8;

    return (
      <React.Fragment>
        <div className={isHistorical ? "mt-5" : ""}>
          <Row className={styles.grayHeaderRow}>
            <Col md={isHistorical ? 3 : 4} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formLabel}>{`${
                isHistorical ? "Historical" : "Protected"
              } Resources`}</Form.Label>
            </Col>
            <Col md={1} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formLabel}>Year</Form.Label>
            </Col>
            <Col md={1} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formLabel}>Quantity</Form.Label>
            </Col>
            <Col md={isHistorical ? 1 : 2} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formLabel}>UOM</Form.Label>
            </Col>
            <Col md={2} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formLabel}>Unit Value</Form.Label>
            </Col>
            <Col md={2} className={isHistorical ? styles.grayRightSeparatorLine : ""}>
              <Form.Label className={styles.formLabel}>Total Value</Form.Label>
            </Col>
            {isHistorical && (
              <Col md={2}>
                <Form.Label className={styles.formLabel}>Total Loss Value</Form.Label>
              </Col>
            )}
          </Row>
        </div>
        {[...Array(tableLength)].map((e, index) => (
          <Row key={index} className={styles.grayBottomSeparatorLine}>
            <Col md={isHistorical ? 3 : 4} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < resourcesAmount ? resources[index].resourceName : ""}
              </Form.Label>
            </Col>
            <Col md={1} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < resourcesAmount ? resources[index].valuationYear : ""}
              </Form.Label>
            </Col>
            <Col md={1} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < resourcesAmount ? resources[index].quantity : ""}
              </Form.Label>
            </Col>
            <Col md={isHistorical ? 1 : 2} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < resourcesAmount
                  ? UtilityFunctions.getDisplayTextFromFieldObject(resources[index].valuationUnitOfMeasure)
                  : ""}
              </Form.Label>
            </Col>
            <Col md={2} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < resourcesAmount
                  ? UtilityFunctions.getValueAsCurrency(resources[index].valuePerUnitOfMeasure)
                  : ""}
              </Form.Label>
            </Col>
            <Col md={2} className={isHistorical ? styles.grayRightSeparatorLine : ""}>
              <Form.Label className={styles.formData}>
                {index < resourcesAmount ? UtilityFunctions.getValueAsCurrency(resources[index].totalValuation) : ""}
              </Form.Label>
            </Col>
            {isHistorical && (
              <Col md={2}>
                <Form.Label className={styles.formData}>
                  {index < resourcesAmount ? this.getTotalLossValueForResource(resources[index]) : ""}
                </Form.Label>
              </Col>
            )}
          </Row>
        ))}
      </React.Fragment>
    );
  }

  getTotalLossValueForResource(resource) {
    let totalLossValue = "";
    if (resource.historicalLosses?.length > 0) {
      totalLossValue = resource.historicalLosses.reduce(
        (acumulator, currentLoss) => acumulator + currentLoss.totalValue,
        0
      );
    }
    return UtilityFunctions.getValueAsCurrency(totalLossValue);
  }

  renderSpecialInstructionsForProperty(propertyIndex = 0) {
    const { agreementData, propertiesData } = this.props;
    let propertyData;
    if (agreementData?.agreementProperties?.length > propertyIndex) {
      const agreementProperty = agreementData.agreementProperties[propertyIndex];
      propertyData = propertiesData?.find(
        (p) => p.propertyUniqueIdentifier === agreementProperty.propertyUniqueReference
      );
    }

    return (
      <Row className={styles.specialInstructionsBlock}>
        <div className="text-left pb-1 mt-5">
          <Form.Label className={styles.formLabel}>Special Instructions</Form.Label>
          {propertyData?.specialInstructions?.length > 0 &&
            propertyData?.specialInstructions.map((i) => {
              return <Form.Text className={styles.formData}>{i.specialInstruction}</Form.Text>;
            })}
        </div>
      </Row>
    );
  }

  renderMultiPropertiesSummaryTable() {
    const { propertiesData } = this.props;
    const propertiesAmount = propertiesData?.length > 0 ? propertiesData.length : 0;
    const tableLength = propertiesAmount > 8 ? propertiesAmount : 8;

    return (
      <React.Fragment>
        <Row className={styles.grayHeaderRow}>
          <Col sm={3} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Common Name</Form.Label>
          </Col>
          <Col sm={2} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Parcels (opt.)</Form.Label>
          </Col>
          <Col sm={1} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Size (Acres)</Form.Label>
          </Col>
          <Col sm={2} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Land Class</Form.Label>
          </Col>
          <Col sm={3} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>County</Form.Label>
          </Col>
          <Col sm={1}>
            <Form.Label className={styles.formLabel}>State</Form.Label>
          </Col>
        </Row>
        {[...Array(tableLength)].map((e, index) => (
          <Row key={index} className={styles.grayBottomSeparatorLine}>
            <Col sm={3} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < propertiesAmount ? propertiesData[index].propertyName : ""}
              </Form.Label>
            </Col>
            <Col sm={2} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < propertiesAmount && propertiesData[index].parcels?.length > 0
                  ? propertiesData[index].parcels.map((parcel) => parcel?.parcelName).join(", ")
                  : ""}
              </Form.Label>
            </Col>
            <Col sm={1} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < propertiesAmount ? propertiesData[index].acres : ""}
              </Form.Label>
            </Col>
            <Col sm={2} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < propertiesAmount ? this.getDisplayTextFromFieldObject(propertiesData[index].landClass) : ""}
              </Form.Label>
            </Col>
            <Col sm={3} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < propertiesAmount ? propertiesData[index].county : ""}
              </Form.Label>
            </Col>
            <Col sm={1}>
              <Form.Label className={styles.formData}>
                {index < propertiesAmount ? this.getStateNameFromId(propertiesData[index].state) : ""}
              </Form.Label>
            </Col>
          </Row>
        ))}
      </React.Fragment>
    );
  }

  renderMultiPropertiesSpeciesTable(propertyIndex = 0) {
    const { agreementData } = this.props;
    let speciesAmount = 0;
    let targetedSpecies;
    if (
      agreementData?.agreementProperties?.length > propertyIndex &&
      agreementData.agreementProperties[propertyIndex].targetedSpecies?.length > 0
    ) {
      targetedSpecies = agreementData.agreementProperties[propertyIndex].targetedSpecies;
      speciesAmount = targetedSpecies.length;
    }
    const tableLength = speciesAmount > 8 ? speciesAmount : 8;

    return (
      <React.Fragment>
        <Row className={styles.grayHeaderRow}>
          <Col sm={9} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Targeted Damage Agents</Form.Label>
          </Col>
          <Col>
            <Form.Label className={styles.formLabel}>Parcel</Form.Label>
          </Col>
        </Row>
        {[...Array(tableLength)].map((e, index) => (
          <Row key={index} className={styles.grayBottomSeparatorLine}>
            <Col sm={9} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < speciesAmount ? targetedSpecies[index]?.speciesName : ""}
              </Form.Label>
            </Col>
            <Col>
              <Form.Label className={styles.formData}>
                {index < speciesAmount && targetedSpecies[index]?.checkedProperties[0]?.checkedParcels?.length > 0
                  ? targetedSpecies[index].checkedProperties[0].checkedParcels
                      .map((parcel) => parcel?.parcelName)
                      .join(", ")
                  : ""}
              </Form.Label>
            </Col>
          </Row>
        ))}
      </React.Fragment>
    );
  }

  renderMultiPropertiesMethodsTable(propertyIndex = 0) {
    const { agreementData } = this.props;
    let methodsAmount = 0;
    let allowedMethods;
    if (
      agreementData?.agreementProperties?.length > propertyIndex &&
      agreementData.agreementProperties[propertyIndex].allowedMethods?.length > 0
    ) {
      allowedMethods = agreementData.agreementProperties[propertyIndex].allowedMethods;
      methodsAmount = allowedMethods.length;
    }
    const tableLength = methodsAmount > 8 ? methodsAmount : 8;
    return (
      <React.Fragment>
        <Row className={styles.grayHeaderRow}>
          <Col sm={9} className={styles.grayRightSeparatorLine}>
            <Form.Label className={styles.formLabel}>Methods</Form.Label>
          </Col>
          <Col sm={3}>
            <Form.Label className={styles.formLabel}>Parcel</Form.Label>
          </Col>
        </Row>
        {[...Array(tableLength)].map((e, index) => (
          <Row key={index} className={styles.grayBottomSeparatorLine}>
            <Col sm={9} className={styles.grayRightSeparatorLine}>
              <Form.Label className={styles.formData}>
                {index < methodsAmount ? allowedMethods[index]?.methodName : ""}
              </Form.Label>
            </Col>
            <Col sm={3}>
              <Form.Label className={styles.formData}>
                {index < methodsAmount && allowedMethods[index]?.checkedProperties[0]?.checkedParcels?.length > 0
                  ? allowedMethods[index].checkedProperties[0].checkedParcels
                      .map((parcel) => parcel?.parcelName)
                      .join(", ")
                  : ""}
              </Form.Label>
            </Col>
          </Row>
        ))}
      </React.Fragment>
    );
  }

  renderSinglePropertyPage1Content() {
    return (
      <Tabs className={styles.greenTabs}>
        <Tab eventKey="propertySummary" title="Property Summary">
          <div className="px-3">
            {this.renderSpecificPropertySummary()}
            {this.renderSinglePropertySpeciesTable()}
          </div>
          <p className={styles.middlePageDisclaimerText}>
            In consideration of the benefits to be derived from the proper management of damage caused by those damage
            agents listed above, I, the undersigned Cooperator or Cooperator’s representative, do hereby give my consent
            and concurrence to the Animal and Plant Health Inspection Service (APHIS) (to include its officials,
            employees, and agents) to use, upon lands owned, leased, or otherwise controlled by me, and identified by
            this Work Initiation Document, the following methods and devices (COMPONENTS):
          </p>
          <div className="px-3">{this.renderSinglePropertyMethodsTable()}</div>
        </Tab>
      </Tabs>
    );
  }

  renderMultiPropertyPage1Content() {
    const { agreementData } = this.props;
    const isEmptyAgreement = !(agreementData?.agreementProperties?.length > 1);
    return (
      <Tabs className={styles.greenTabs}>
        <Tab eventKey="propertiesSummary" title="Properties Summary">
          <div className="px-3">{this.renderMultiPropertiesSummaryTable()}</div>
        </Tab>
      </Tabs>
    );
  }

  renderAgreementSummaryPage(propertiesCount) {
    return (
      <React.Fragment>
        <div className={styles.printingPage}>
          {this.renderInitialPageHeaderSection()}
          <div className={styles.propertiesSummaryBlock}>
            {propertiesCount > 1 ? this.renderMultiPropertyPage1Content() : this.renderSinglePropertyPage1Content()}
          </div>
          <div className={styles.pageFooterBlock}>
            <div className="px-3 text-left">{this.renderPageFooter(1)}</div>
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </React.Fragment>
    );
  }

  renderSpecificPropertyPage(propertyIndex) {
    return (
      <React.Fragment key={propertyIndex}>
        <div className={styles.printingPage}>
          <Tabs className={styles.greenTabs}>
            <Tab eventKey="propertySummary" title="Property Summary">
              <div className="px-3">
                {this.renderSpecificPropertySummary(propertyIndex)}
                {this.renderMultiPropertiesSpeciesTable(propertyIndex)}
              </div>
              <p className={styles.middlePageDisclaimerText}>
                In consideration of the benefits to be derived from the proper management of damage caused by those
                damage agents listed above, I, the undersigned Cooperator or Cooperator’s representative, do hereby give
                my consent and concurrence to the Animal and Plant Health Inspection Service (APHIS) (to include its
                officials, employees, and agents) to use, upon lands owned, leased, or otherwise controlled by me, and
                identified by this Work Initiation Document, the following methods and devices (COMPONENTS):
              </p>
              <div className="px-3">{this.renderMultiPropertiesMethodsTable(propertyIndex)}</div>
            </Tab>
          </Tabs>
          <div className="mt-5">{this.renderContactsTabContent(propertyIndex)}</div>
          <div className={styles.pageFooterBlock}>
            <div className="px-3 text-left">{this.renderPageFooter(propertyIndex * 2 + 2)}</div>
          </div>
        </div>
        <div className="html2pdf__page-break"></div>
      </React.Fragment>
    );
  }

  render() {
    let propertiesCount = 1;
    if (this.props.agreementData?.agreementProperties?.length > 0) {
      propertiesCount = this.props.agreementData.agreementProperties.length;
    } else if (this.props.propertiesCount) {
      propertiesCount = this.props.propertiesCount;
    }

    return (
      <div>
        {this.renderAgreementSummaryPage(propertiesCount)}
        {propertiesCount > 1
          ? [...Array(propertiesCount)].map((e, index) => {
              return (
                <React.Fragment>
                  {this.renderSpecificPropertyPage(index)}
                  {this.renderPropertyExtraData(index)}
                </React.Fragment>
              );
            })
          : this.renderPropertyExtraData()}
        {this.renderLegalSignaturePage(propertiesCount)}
        {this.renderFirstLegalPage(propertiesCount)}
        {this.renderSecondLegalPage(propertiesCount)}
      </div>
    );
  }
}

export default PrintAgreementForm;

