import "./map.css";
import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";
import { esriPortalConfig, config } from "../../../package.json";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

function ParcelMap(property) {
  const mapDiv = useRef(null);

  useEffect(() => {
    if (mapDiv.current) {
      loadModules([
        "esri/identity/OAuthInfo",
        "esri/identity/IdentityManager",
        "esri/portal/PortalItem",
        "esri/WebMap",
        "esri/views/MapView",
        "esri/config",
        "esri/layers/FeatureLayer",
        "esri/widgets/Search",
        "esri/geometry/Extent",
        "esri/Graphic",
        "esri/symbols/SimpleFillSymbol",
        "esri/layers/GraphicsLayer",
        "esri/renderers/SimpleRenderer",
      ]).then(
        ([
          OAuthInfo,
          esriId,
          PortalItem,
          WebMap,
          MapView,
          esriConfig,
          FeatureLayer,
          Search,
          Extent,
          Graphic,
          SimpleFillSymbol,
          GraphicsLayer,
          SimpleRenderer,
        ]) => {
          let appId, featureSvcUrl;
          let featureSvcName = sessionStorage.getItem("featureService");
          if (featureSvcName === "undefined") {
            //default Maryland map, till all states has maps
            featureSvcName = esriPortalConfig.featureSvcName;
          }

          if (process.env.NODE_ENV === "production" && config.environment.toLowerCase() === "production") {
            //prod mapserver
            esriConfig.portalUrl = esriPortalConfig.production.portalUrl;
            appId = esriPortalConfig.production.appId;
            featureSvcUrl = esriPortalConfig.production.featureSvcs + featureSvcName + "/MapServer/0";
          } else {
            //QA - staging mapserver
            esriConfig.portalUrl = esriPortalConfig.staging.portalUrl;
            appId = esriPortalConfig.staging.appId;
            featureSvcUrl = esriPortalConfig.staging.featureSvcs + featureSvcName + "/FeatureServer/0";
          }

          const info = new OAuthInfo({
            appId: appId,
            portalUrl: esriConfig.portalUrl,
          });
          esriId.registerOAuthInfos([info]);
          esriId
            .checkSignInStatus(info.portalUrl + "/sharing")
            .then(() => {
              //connected to map server
            })
            .catch((err) => {
              ErrorHandler.sendToAppInsights("error while connecting to map server " + err);
              sessionStorage.setItem("mapAccess", "No");
              return;
            });

          esriId
            .getCredential(info.portalUrl + "/sharing")
            .then((creds) => {
              //get credentials
            })
            .catch((err) => {
              ErrorHandler.sendToAppInsights("error while getting credentials " + err);
              sessionStorage.setItem("mapAccess", "No");
              return;
            });

          let addParcelAction = {
            title: "Select",
            id: "add-property",
            image: "",
          };
          let removeParcelAction = {
            title: "Remove",
            id: "remove-property",
            image: "",
          };
          const template = {
            // autocasts as new PopupTemplate()
            title: "PARCEL ID {APHIS_ID}",
            actions: [addParcelAction, removeParcelAction],
            content: [
              {
                type: "fields",
                fieldInfos: [
                  {
                    fieldName: "OWN1_FRST",
                    label: "Owner First Name",
                  },
                  {
                    fieldName: "OWN1_LAST",
                    label: "Owner Last Name",
                  },
                  {
                    fieldName: "ADDR",
                    label: "Parcel Address",
                  },
                  {
                    fieldName: "CITY",
                    label: "City",
                  },
                  {
                    fieldName: "STATE",
                    label: "State",
                  },
                  {
                    fieldName: "COUNTY_NAME",
                    label: "County",
                  },
                  {
                    fieldName: "ZIP",
                    label: "Zip",
                  },
                  {
                    fieldName: "LAND_ACRES",
                    label: "Acres",
                  },
                  {
                    fieldName: "CENTROID_Y",
                    label: "Latitude",
                  },
                  {
                    fieldName: "CENTROID_X",
                    label: "Longitude",
                  },
                ],
              },
            ],
          };

          var resultsLayer = new GraphicsLayer();

          var featureLayer = new FeatureLayer({
            url: featureSvcUrl,
            outFields: ["*"], // used by queryFeatures
            popupTemplate: template,
            overwriteActions: true,
          });

          featureLayer.renderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "simple-fill",
              color: null,
              outline: {
                width: 0.5,
                color: null,
              },
            },
          };
          let mapId = sessionStorage.getItem("mapId");
          if (mapId === "undefined") {
            //default Texas map, till all states has maps
            mapId = esriPortalConfig.mapId;
          }

          let item = new PortalItem({
            id: mapId,
          });
          item.load();

          const webmap = new WebMap({
            portalItem: item,
            layers: [featureLayer, resultsLayer],
          });

          const view = new MapView({
            container: mapDiv.current,
            map: webmap,
            zoom: 15,
            popup: {
              defaultPopupTemplateEnabled: false,
              dockEnabled: true,
              dockOptions: {
                buttonEnabled: false,
                breakpoint: false,
                position: "bottom-left",
              },
            },
          });
          let extent = [];
          let searchExtent = [];
          // Once the layer loads, set the view's extent to the layer's fullextent
          featureLayer.when(function () {
            //view.extent = featureLayer.fullExtent;

            extent = featureLayer.fullExtent.extent;

            const extentLoc = new Extent({
              xmin: extent.xmin,
              ymin: extent.ymin,
              xmax: extent.xmax,
              ymax: extent.ymax,
              spatialReference: {
                wkid: extent.spatialReference.wkid,
              },
            });
            const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
            searchExtent = {
              geometry: extentLoc,
              where: "State = '" + currentUsaState.stateName + "'",
            };

            const search = new Search({
              view: view,
              allPlaceholder: "Select Search Criteria",
              includeDefaultSources: false,
              locationEnabled: false,
              sources: [
                {
                  name: "Property Address",
                  placeholder: "Address,City,Zip,Location",
                  singleLineFieldName: "address",
                  locator: config.geocodeserverurl,
                  filter: searchExtent,
                },
                {
                  layer: featureLayer,
                  searchFields: ["OWN1_FRST", "OWN1_LAST"],
                  displayField: "OWN1_FRST",
                  suggestionTemplate: "{OWN1_FRST}, {OWN1_LAST}",
                  exactMatch: false,
                  outFields: ["*"],
                  name: "Property Owner",
                  placeholder: "Owner First or Last Name",
                },
              ],
            });

            view.ui.add(search, "top-right");
            search.on("select-result", function (event) {
              const point = {
                //Create a point
                type: "point",
                longitude: event.result.feature.geometry.longitude,
                latitude: event.result.feature.geometry.latitude,
              };
              const simpleMarkerSymbol = {
                type: "simple-marker",
                color: "red",
                outline: {
                  color: [255, 255, 255], // White
                  width: 1,
                },
              };
              const pointGraphic = new Graphic({
                geometry: point,
                symbol: simpleMarkerSymbol,
                popupTemplate: template,
              });
              view.graphics.removeAll();
              view.graphics.add(pointGraphic);
            });
          });

          view.when(function () {
            let canvas = document.getElementsByTagName("CANVAS")[0];
            if (canvas) {
              canvas.title = "Parcel";
            }
          });

          let element = document.querySelector(".esri-view-surface");
          if (element) {
            element.title = "Search Map";
          }

          // Event handler that fires each time an action is clicked.

          view.popup.on("trigger-action", (e) => {
            if (e.action?.id === "add-property") {
              let parcelData = null;
              let parcelCounty = view.popup.selectedFeature.attributes.COUNTY_NAME;

              parcelData = {
                OwnerFirstName: view.popup.selectedFeature.attributes.OWN1_FRST,
                OwnerLastName: view.popup.selectedFeature.attributes.OWN1_LAST,
                acctid: `${view.popup.selectedFeature.attributes.APHIS_ID}`,
                latitude: view.popup.selectedFeature.attributes.CENTROID_Y,
                longitude: view.popup.selectedFeature.attributes.CENTROID_X,
                streetAddress: view.popup.selectedFeature.attributes.ADDR,
                city: view.popup.selectedFeature.attributes.CITY,
                zip: view.popup.selectedFeature.attributes.ZIP,
                state: view.popup.selectedFeature.attributes.STATE,
                acres: view.popup.selectedFeature.attributes.LAND_ACRES,
                county: parcelCounty.slice(0, parcelCounty.length - 7),
              };

              getGraphicsAdd(view.popup.selectedFeature.geometry);
              property.onAddProperty(parcelData);
            }
            if (e.action?.id === "remove-property") {
              let parcelData = null;

              parcelData = {
                acctid: `${view.popup.selectedFeature.attributes.APHIS_ID}`,
                acres: view.popup.selectedFeature.attributes.LAND_ACRES,
              };
              getGraphicsRemove(view.popup.selectedFeature.geometry);
              property.onRemoveParcel(parcelData);
            }
          });

          //let's get a popup graphic on click:'
          view.on("click", function (evt) {
            var screenPoint = {
              x: evt.x,
              y: evt.y,
            };

            view.hitTest(screenPoint).then(getGraphics);
          });

          function getGraphicsAdd(geometry) {
            const symbol = {
              type: "simple-fill",
              color: null,
              outline: {
                color: "blue",
                width: 3,
              },
            };
            var graphic1 = new Graphic({
              //gotta set a defined geometry for the symbol to draw
              geometry: geometry,
              symbol: symbol,
            });
            view.graphics.add(graphic1);
          }

          function getGraphicsRemove(geometry) {
            var graphic1 = new Graphic({
              //gotta set a defined geometry for the symbol to draw
              geometry: geometry,
              symbol: new SimpleFillSymbol({
                color: null,
                outline: {
                  color: "white",
                  width: 3,
                },
              }),
            });
            view.graphics.add(graphic1);
          }

          function getGraphics(response) {
            var graphic = new Graphic({
              //gotta set a defined geometry for the symbol to draw
              geometry: response?.results[0]?.graphic.geometry,
              symbol: new SimpleFillSymbol({
                color: null,
                outline: {
                  color: "white",
                  width: 3,
                },
              }),
            });
            view.graphics.add(graphic);
          }
        }
      );
    }
  }, []);

  return <div className="mapDiv" ref={mapDiv}></div>;
}

export default ParcelMap;
