import React from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import searchIcon from "../../assets/search.svg";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { withRouter } from "react-router-dom";
import AddContactModal from "./addContactModal";
import EditAgreementContactModal from "./EditAgreementContactModal";
import UtilityFunctions from "../common/UtilityFunctions";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import AddProjectModal from "./addProjectModal";
import { toast } from "react-toastify";
import CustomForm from "../common/form";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className={styles.noOptionsMessage}>
        <i className="fa fa-exclamation-circle " style={{ color: "gray", padding: "4px" }} aria-hidden="true"></i>
        Sorry, we couldn’t find a match for “{props.selectProps.inputValue}”. Please try another search
      </span>
    </components.NoOptionsMessage>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

class Step1 extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      agreementTypes: this.props.step1Data.agreementTypes,
      specialInstructions: this.props.step1Data.specialInstructions,
      typeaheadIsLoading: false,
      typeaheadOptions: [],
      showPrimaryFieldAgentSearch: true,
      primaryFieldAgent: {
        contactGuid: this.props.step1Data.primaryFieldAgentUniqueIdentifier,
        contactName: this.props.step1Data.primaryFieldAgentName,
      },
      cooperator: {
        contactGuid: this.props.step1Data.primaryCooperatorUniqueIdentifier,
        contactName: this.props.step1Data.primaryCooperatorName,
      },
      fieldAgentAdditionalContacts: this.props.step1Data.fieldAgentAdditionalContacts,
      showAdditionalFieldAgents: false,
      additionalFieldAgentSelect: "",
      additionalContacts: this.props.step1Data.additionalContacts,
      showAddContactModal: false,
      showAddCooperatorModal: false,
      showEditAgreementContactModal: false,
      showAddProjectModal: false,
      contactTypes: this.props.step1Data.contactTypes,
      phoneNumberTypes: this.props.step1Data.phoneNumberTypes,
      usaCountryId: this.props.step1Data.usaCountryId,
      states: this.props.step1Data.states,
      countries: this.props.step1Data.countries,
      agreementContactRoles: this.props.step1Data.agreementContactRoles,
      pageHeader: this.props.step1Data.pageHeader,
      projects: this.props.step1Data.agreementProjects,
      data: {
        agreementNumber: this.props.step1Data.agreementNumber,
        commonName: this.props.step1Data.commonName,
        agreementTypeEnumId: this.props.step1Data.agreementTypeEnumId,
      },
      errors: {},
    };
  }

  componentDidMount() {
    if (this.state.primaryFieldAgent.contactGuid === "") {
      this.setState({ showPrimaryFieldAgentSearch: true });
    } else {
      this.setState({ showPrimaryFieldAgentSearch: false });
    }
    if (this.state.fieldAgentAdditionalContacts.length > 0) {
      this.setState({ showAdditionalFieldAgents: true });
    } else {
      this.setState({ showAdditionalFieldAgents: false });
    }
    let element = document.getElementsByClassName("rbt-input-hint")[0];
    if (element) {
      element.setAttribute("aria-hidden", "false");
      element.setAttribute("aria-label", "hint");
    }
  }

  primaryFieldAgentComponent = () => {
    let renderArray = [];
    if (this.state.showPrimaryFieldAgentSearch) {
      renderArray.push(
        <div className={styles.asyncTypeaheadContainer}>
          <AsyncTypeahead
            id="asyncMultiColSelect"
            filterBy={["firstName", "lastName", "phoneNumber", "emailAddress"]}
            clearButton
            isLoading={this.state.typeaheadIsLoading}
            labelKey="firstName"
            minLength={1}
            onSearch={this.handleContactSearch}
            options={this.state.typeaheadOptions}
            placeholder="Search for a Specialist"
            onChange={this.handlePrimaryFieldAgentChange}
            renderMenuItemChildren={(option) => this.formatMenu(option)}
            inputProps={{ "aria-label": "Primary WS Specialist Search", "aria-hidden": "false" }}
          />
        </div>
      );
    } else if (this.state.primaryFieldAgent.contactUniqueId || this.state.primaryFieldAgent.contactGuid) {
      renderArray.push(
        <Row className={styles.informationRow} noGutters="true">
          <Col align="left">
            <p className={globalStyles.informationText}>{this.state.primaryFieldAgent.contactName}</p>
          </Col>
          <Col align="right">
            <span>
              <Button
                variant="link"
                style={{ cursor: "pointer" }}
                className={styles.removeButtonLinks}
                onClick={() => {
                  this.props.passDataUp("primaryFieldAgentUniqueIdentifier", "");
                  this.props.passDataUp("primaryFieldAgentName", "");
                  let { primaryFieldAgent } = this.state;
                  primaryFieldAgent.contactGuid = "";
                  primaryFieldAgent.contactName = "";
                  this.setState({ primaryFieldAgent, showPrimaryFieldAgentSearch: true });
                }}
              >
                Remove
              </Button>
            </span>
          </Col>
        </Row>
      );
    }

    return renderArray;
  };

  additionalFieldAgentContactsComponent = () => {
    let renderArray = [];
    if (this.state.showAdditionalFieldAgents) {
      renderArray.push(
        <div>
          <h5 className={styles.additionalFieldAgentsHeader}>Additional WS Specialists</h5>
          <div style={{ marginBottom: "20px" }}>
            <AsyncSelect
              value={this.state.additionalFieldAgentSelect}
              aria-label="Additional WS Specialists Search"
              openMenuOnClick={false}
              placeholder="Search for a Specialist"
              loadOptions={this.handleContactSearch}
              onChange={this.handleAddAFieldAgentContact}
              components={{ DropdownIndicator, NoOptionsMessage, Option }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "",
                  primary: "#ced4da",
                },
              })}
              styles={{
                control: (styles) => ({ ...styles, fontSize: "14px" }),
                menu: (styles) => ({ ...styles, marginTop: "0px" }),
                noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  const color = "#EAF3F1";
                  return {
                    ...styles,
                    fontSize: "12px",
                    textAlign: "left",
                    borderBottom: "1px solid #f2f2f2",
                    backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                    color: isDisabled ? "#ccc" : isSelected ? ("#ccc" ? "white" : "black") : data.color,
                    cursor: isDisabled ? "not-allowed" : "default",

                    ":active": {
                      ...styles[":active"],
                      backgroundColor: !isDisabled && (isSelected ? color : null),
                    },
                  };
                },
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
              }}
            />
          </div>
          {this.state.fieldAgentAdditionalContacts.map((item, index) => (
            <Row className={styles.informationRow} noGutters="true">
              <Col align="left">
                <p className={globalStyles.informationText}>{item.firstName + " " + item.lastName}</p>
              </Col>
              <Col align="right">
                <span>
                  <Button
                    variant="link"
                    style={{ cursor: "pointer" }}
                    className={styles.removeButtonLinks}
                    onClick={() => {
                      let array = this.state.fieldAgentAdditionalContacts;
                      array.splice(index, 1);
                      this.setState({ fieldAgentAdditionalContacts: array }, () => {
                        if (this.state.fieldAgentAdditionalContacts.length < 1) {
                          this.setState({ showAdditionalFieldAgents: false });
                          this.props.passDataUp(
                            "fieldAgentAdditionalContacts",
                            this.state.fieldAgentAdditionalContacts
                          );
                        }
                      });
                    }}
                  >
                    Remove
                  </Button>
                </span>
              </Col>
            </Row>
          ))}
        </div>
      );
    }
    return renderArray;
  };

  handleStateChange = ({ currentTarget: input }) => {
    if (input) {
      this.props.passDataUp(input.id, input.value);
    }
  };

  removeCooperator = () => {
    this.props.passDataUp("primaryCooperatorUniqueIdentifier", "");
    this.props.passDataUp("primaryCooperatorName", "");
    this.props.passDataUp("primaryCooperatorRoleEnumId", "");
    let { cooperator } = this.state;
    cooperator.contactName = "";
    cooperator.contactGuid = "";
    this.setState({ cooperator });
  };

  handleContactSearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.props.step1Data.currentUsaState.stateGuid,
    };

    this.setState({ typeaheadIsLoading: true });
    const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText(searchObj);
    let outputContacts = [];
    if (contactsData?.data?.results) {
      let results = contactsData.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
              firstName: user.firstName,
              lastName: user.lastName,
              contactUniqueIdentifier: userProfile.wsUserProfileUniqueIdentifier,
              contactGuid: userProfile.wsUserProfileUniqueIdentifier,
              contactName: user.firstName + " " + user.lastName,
              emailAddress: user.userPrinciple,
              phoneNumber: user.primaryPhoneNumber,
              primaryMailingAddress: {
                mailingStreet: user.streetAddress,
                mailingCity: user.city,
                mailingZip: user.zip,
                mailingStateUniqueId: user.stateOfAddressUniqueReference,
              },
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    this.setState({ typeaheadOptions: outputContacts, typeaheadIsLoading: false });

    return outputContacts;
  };

  handlePrimaryFieldAgentChange = (selectedOption) => {
    this.props.passDataUp("primaryFieldAgentUniqueIdentifier", selectedOption[0].contactGuid);
    this.props.passDataUp("primaryFieldAgentName", selectedOption[0].contactName);
    this.props.passDataUp("primaryFieldAgentEmail", selectedOption[0].emailAddress);
    this.props.passDataUp("primaryFieldAgentPhoneNumber", selectedOption[0].phoneNumber);
    this.props.passDataUp("primaryFieldAgentMailingAddress", selectedOption[0].primaryMailingAddress);
    let { primaryFieldAgent } = this.state;
    primaryFieldAgent.contactName = selectedOption[0].contactName;
    primaryFieldAgent.contactGuid = selectedOption[0].contactGuid;
    this.setState({ primaryFieldAgent, typeaheadIsLoading: false, showPrimaryFieldAgentSearch: false });
  };

  handlePrimaryFieldAgentContactChange = (input) => {
    this.setState({ primaryFieldAgent: input });
    return input;
  };

  formatMenu = (option) => {
    let phoneNumber = "No Phone Number";
    let address = "No Address";
    if (option?.phoneNumber) {
      phoneNumber = UtilityFunctions.formatPhoneNumber(option.phoneNumber);
    }
    if (option?.primaryMailingAddress) {
      if (
        option?.primaryMailingAddress.mailingStreet ||
        option?.primaryMailingAddress.mailingCity ||
        option?.primaryMailingAddress.mailingZip
      ) {
        let state = "";
        let states = this.state.states;
        for (let i = 0; i < this.state.states.length; i++) {
          if (states[i].id === option.primaryMailingAddress.mailingStateUniqueId) {
            state = states[i].name;
          }
        }
        address =
          option.primaryMailingAddress.mailingStreet +
          ", " +
          option.primaryMailingAddress.mailingCity +
          " " +
          state +
          " " +
          option.primaryMailingAddress.mailingZip;
      }
    }
    return (
      <Row className={styles.informationRow}>
        <Col xl={4} align="left">
          <p className={styles.informationTextTypeahead} style={{ fontWeight: "600" }}>
            {option.contactName + " - " + option.profileName}
          </p>
        </Col>
        <Col xl={2} align="left">
          <p className={styles.informationTextTypeahead}>{phoneNumber}</p>
        </Col>
        <Col xl={2} align="left">
          <p className={styles.informationTextTypeahead}>{option.emailAddress ? option.emailAddress : "No Email"}</p>
        </Col>
        <Col xl={4} align="left">
          <p className={styles.informationTextTypeahead}>{address}</p>
        </Col>
      </Row>
    );
  };

  handleAddAFieldAgentContact = (input) => {
    let array = this.state.fieldAgentAdditionalContacts;
    array.push(input);
    this.setState({ fieldAgentAdditionalContacts: array, additionalFieldAgentSelect: "" });
    this.props.passDataUp("fieldAgentAdditionalContacts", this.state.fieldAgentAdditionalContacts);

    return input;
  };

  showAddContactModal = () => {
    this.setState({ showAddContactModal: true });
  };

  showAddCooperatorModal = () => {
    this.setState({ showAddCooperatorModal: true });
  };

  closeAddContactModal = () => {
    this.setState({ showAddContactModal: false });
  };

  closeAddCooperatorModal = () => {
    this.setState({ showAddCooperatorModal: false });
  };

  closeEditAgreementContactModal = () => {
    this.setState({ showEditAgreementContactModal: false });
  };

  showAddProjectModal = () => {
    this.setState({ showAddProjectModal: true });
  };

  closeAddProjectModal = () => {
    this.setState({ showAddProjectModal: false });
  };

  handleExtraContactAssociation = (contactData) => {
    let { additionalContacts } = this.state;
    contactData.isNewContact = true;
    contactData.contactInheritedFrom = "Agreement";
    additionalContacts.push(contactData);
    this.props.passDataUp("additionalContacts", additionalContacts);
    this.setState({ additionalContacts });
  };

  handleProjectsAssociation = (projectData) => {
    let { projects } = this.state;

    for (let i = 0; i < projectData.selectedProjects.length; i++) {
      let recordExists = false;
      if (projects.find((p) => p.value === projectData.selectedProjects[i].value)) {
        toast.warning("Selected project is already associated");
        recordExists = true;
      }
      if (!recordExists) {
        projects.push(projectData.selectedProjects[i]);
      }
    }

    this.props.passDataUp("agreementProjects", projects);
    this.setState({ projects });
  };

  handleExtraCooperatorAssociation = (contactData) => {
    this.setState({ cooperator: contactData });
    this.props.passDataUp("primaryCooperatorUniqueIdentifier", contactData.contactGuid);
    this.props.passDataUp("primaryCooperatorName", contactData.contactName);
    this.props.passDataUp("primaryCooperatorEmail", contactData.emailAddress);
    this.props.passDataUp("primaryCooperatorRoleEnumId", contactData.contactRoleEnumId);
    this.props.passDataUp("primaryCooperatorRoleName", contactData.contactRoleName);
    this.props.passDataUp(
      "primaryCooperatorphoneNumbers",
      contactData.phoneNumbers.map((phoneNumber) => {
        phoneNumber.phoneNumber = UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber);
        return phoneNumber;
      })
    );
    if (contactData.primaryMailingAddress) {
      this.props.passDataUp(
        "primaryCooperatorStateUniqueIdentifier",
        contactData.primaryMailingAddress.mailingStateUniqueId
      );
      this.props.passDataUp("primaryCooperatorCity", contactData.primaryMailingAddress.mailingCity);
      this.props.passDataUp(
        "primaryCooperatorCountryUniqueIdentifier",
        contactData.primaryMailingAddress.mailingCountryUniqueId
      );
      this.props.passDataUp("primaryCooperatorStreet", contactData.primaryMailingAddress.mailingStreet);
      this.props.passDataUp("primaryCooperatorZip", contactData.primaryMailingAddress.mailingZip);
    }
    let { cooperator } = this.state;
    cooperator.contactName = contactData.contactName;
    cooperator.contactGuid = contactData.contactGuid;
    cooperator.firstName = contactData.firstName;
    cooperator.lastName = contactData.lastName;
    cooperator.middleName = contactData.middleName;

    this.setState({ cooperator });
    if (contactData.phoneNumbers?.length < 1 || !contactData?.primaryMailingAddress) {
      this.setState({ showEditAgreementContactModal: true });
    }
  };

  displayAgreementContactRole(contact) {
    const roles = this.state.agreementContactRoles;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].id === parseInt(contact.contactRoleEnumId)) {
        return roles[i].displayText;
      } else {
        continue;
      }
    }
    return "N/A";
  }

  projectsComponent = () => {
    let returnArray = [];
    if (this.state.projects?.length > 0) {
      let projects = this.state.projects;
      for (let i = 0; i < projects.length; i++) {
        returnArray.push(
          <Row className={styles.informationRow} noGutters="true">
            <Col align="left" lg={2}>
              <p className={globalStyles.informationText}>{projects[i].label}</p>
            </Col>
            <Col align="right">
              <Button
                variant="link"
                style={{ cursor: "pointer" }}
                className={styles.removeButtonLinks}
                onClick={() => {
                  projects.splice(i, 1);
                  this.setState({ projects: projects });
                  this.props.passDataUp("agreementProjects", projects);
                }}
              >
                Remove
              </Button>
            </Col>
          </Row>
        );
      }
    }
    return returnArray;
  };

  additionalContactsComponent = () => {
    let returnArray = [];
    if (this.state.additionalContacts?.length > 0) {
      let contacts = this.state.additionalContacts;
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i]?.contactInheritedFrom === "Agreement") {
          returnArray.push(
            <Row className={styles.informationRow} noGutters="true">
              <Col align="left" style={{ paddingRight: "15px" }} lg={3}>
                <p className={globalStyles.informationText}>{contacts[i].contactName}</p>
              </Col>
              <Col align="left" lg={2}>
                <p className={globalStyles.informationText}>{this.displayAgreementContactRole(contacts[i])}</p>
              </Col>
              <Col align="right" lg={7}>
                <Button
                  variant="link"
                  style={{ cursor: "pointer" }}
                  className={styles.removeButtonLinks}
                  onClick={() => {
                    contacts.splice(i, 1);
                    this.setState({ additionalContacts: contacts });
                    this.props.passDataUp("additionalContacts", contacts);
                  }}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          );
        }
      }
    }
    return returnArray;
  };

  cooperatorComponent = () => {
    let returnArray = [];
    if (this.state.cooperator.contactGuid || this.state.cooperator.contactUniqueId) {
      returnArray.push(
        <Row className={styles.informationRow} noGutters="true">
          <Col align="left" lg={3}>
            <p className={globalStyles.informationText}>{this.state.cooperator.contactName}</p>
          </Col>
          <Col align="left" lg={2}>
            <p className={globalStyles.informationText}>{this.props.step1Data.primaryCooperatorRoleName}</p>
          </Col>
          <Col align="right" lg={7}>
            <Button
              className={styles.removeButtonLinks}
              variant="link"
              style={{ cursor: "pointer" }}
              onClick={this.removeCooperator}
            >
              Remove
            </Button>
          </Col>
        </Row>
      );
    }
    return returnArray;
  };

  handleSpecificChanges(input) {
    if (input) {
      this.props.passDataUp(input.id ? input.id : input.name, input.value);
    }
  }

  render() {
    return (
      <div className={styles.Step1} data-testid="Step1">
        <h5 className={styles.pageTitles}>{this.state.pageHeader}</h5>
        <Card className={styles.createPageCards}>
          <Card.Header as="h4" className={styles.cardTitles}>
            Agreement Information
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>{this.renderInput("agreementNumber", "Agreement Number", "text", "xxxxxxxx", ["maxLength=8"])}</Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>{this.renderInput("commonName", "Agreement Name", "text", "Enter Name", "required")}</Col>
              <Col hidden={this.state.agreementTypeEnumId !== 2}></Col>
              <Col hidden={this.state.agreementTypeEnumId === 2}>
                {this.renderSelect(
                  "agreementTypeEnumId",
                  "Agreement Type",
                  this.state.agreementTypes,
                  "Select Type",
                  "required"
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards}>
          <Card.Header as="h4" className={styles.cardTitles}>
            Add WS Specialist
            <Button
              variant="link"
              className={styles.addButtonLinksInHeader}
              onClick={() => this.setState({ showAdditionalFieldAgents: true })}
              hidden={this.state.showAdditionalFieldAgents}
            >
              + Add another Specialist
            </Button>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <h6 className={styles.fieldLabels}>
                  Primary WS Specialist<span className={globalStyles.asterisk508}>{" *"}</span>
                </h6>
                <this.primaryFieldAgentComponent />
                <this.additionalFieldAgentContactsComponent />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards} hidden={this.state.agreementTypeEnumId === 2}>
          <Card.Header as="h4" className={styles.cardTitles}>
            Add Cooperator<span className={globalStyles.asterisk508}>{" *"}</span>
            <Button
              variant="link"
              className={styles.addButtonLinksInHeader}
              onClick={this.showAddCooperatorModal}
              hidden={this.state.cooperator.contactGuid || this.state.cooperator.contactUniqueId}
            >
              + Add Cooperator
            </Button>
          </Card.Header>
          <Card.Body className={styles.centerCardBody}>
            <Row>
              <Col>
                <this.cooperatorComponent />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards} hidden={this.state.agreementTypeEnumId === 2}>
          <Card.Header as="h4" className={styles.cardTitles}>
            Add Contacts
            <Button variant="link" className={styles.addButtonLinksInHeader} onClick={this.showAddContactModal}>
              + Add Contact
            </Button>
          </Card.Header>
          <Card.Body className={styles.centerCardBody}>
            <Row>
              <Col>
                <this.additionalContactsComponent />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards}>
          <Card.Header as="h4" className={styles.cardTitles}>
            Add Projects
            <Button variant="link" className={styles.addButtonLinksInHeader} onClick={this.showAddProjectModal}>
              + Add Project
            </Button>
          </Card.Header>
          <Card.Body className={styles.centerCardBody}>
            <Row>
              <Col>
                <this.projectsComponent />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <AddProjectModal
          show={this.state.showAddProjectModal}
          onHide={this.closeAddProjectModal}
          onSaveAssociation={this.handleProjectsAssociation}
        />
        <AddContactModal
          show={this.state.showAddCooperatorModal}
          onHide={this.closeAddCooperatorModal}
          contactTypes={this.state.contactTypes}
          phoneNumberTypes={this.state.phoneNumberTypes}
          states={this.state.states}
          countries={this.state.countries}
          usaCountryId={this.state.usaCountryId}
          contactType="cooperator"
          onSaveAssociation={this.handleExtraCooperatorAssociation}
          agreementContactRoles={this.state.agreementContactRoles}
        />
        <AddContactModal
          show={this.state.showAddContactModal}
          onHide={this.closeAddContactModal}
          contactTypes={this.state.contactTypes}
          phoneNumberTypes={this.state.phoneNumberTypes}
          states={this.state.states}
          countries={this.state.countries}
          usaCountryId={this.state.usaCountryId}
          contactType="contact"
          updatedContacts={this.state.additionalContacts}
          onSaveAssociation={this.handleExtraContactAssociation}
          agreementContactRoles={this.state.agreementContactRoles}
        />

        <EditAgreementContactModal
          show={this.state.showEditAgreementContactModal}
          onHide={this.closeEditAgreementContactModal}
          phoneNumberTypes={this.state.phoneNumberTypes}
          states={this.state.states}
          countries={this.state.countries}
          usaCountryId={this.state.usaCountryId}
          contactType="cooperator"
          contactData={this.state.cooperator}
        />
      </div>
    );
  }
}

export default withRouter(Step1);
