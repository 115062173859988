import React from "react";
import styles from "../AgreementEntities/Contact/Contact.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Card, Row, Col, Form, InputGroup, FormControl, Button } from "react-bootstrap";
import UtilityFunctions from "./UtilityFunctions";

const PhoneNumber = ({ phoneNumbers, phoneNumberTypes, onPhoneNumberChange, onExtraPhoneNumber }) => {
  return (
    <div className="mx-2">
      {phoneNumbers.map((phoneNumber, index) => {
        return (
          <div key={index}>
            <Row>
              <Col>
                <Form.Group controlId={"phoneNumber-" + index} className="text-left">
                  <h6 className={styles.fieldLabels}>Phone Number</h6>
                  <Form.Control
                    type="text"
                    placeholder="xxx-xxx-xxxx"
                    value={UtilityFunctions.formatPhoneNumber(phoneNumber.phoneNumber)}
                    onChange={onPhoneNumberChange}
                    title={`Phone Number ${index + 1}`}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"phoneTypeEnumId-" + index} className="text-left">
                  <h6 className={styles.fieldLabels}>
                    Phone Number Type<span className={globalStyles.asterisk508}>*</span>
                  </h6>
                  <Form.Control
                    value={phoneNumber.phoneTypeEnumId !== "-1" ? phoneNumber.phoneTypeEnumId : "1"}
                    as="select"
                    type="text"
                    onChange={onPhoneNumberChange}
                    title={`Phone Number Type ${index + 1}`}
                  >
                    {phoneNumberTypes.map((type) => (
                      <option value={type.id} key={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Button
                variant="link"
                onClick={onExtraPhoneNumber}
                value={phoneNumbers.length > index + 1 ? index + 1 : "add"}
                style={phoneNumbers.length > index + 1 ? { color: "red" } : {}}
                title={
                  phoneNumbers.length > index + 1 ? `Remove Phone Number ${index + 2}` : `Add Phone Number ${index + 2}`
                }
              >
                {phoneNumbers.length > index + 1 ? "- Remove Phone Number" : "+ Add Phone Number"}
              </Button>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default PhoneNumber;
