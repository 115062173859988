import API from "../API";
import { esriPortalConfig } from "../../../package.json";

class GeoCodingAPI {
  static async GetLocationByAddress(address) {
    const api = new API(
      `${esriPortalConfig.geocodeserverurl}/findAddressCandidates?address=${address}&outFields=*&forStorage=false&f=pjson`
    );
    const result = await api.get();
    return result;
  }
}

export default GeoCodingAPI;
