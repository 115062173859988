import React from "react";
import { Modal, Button, Row, Col, CloseButton, Form } from "react-bootstrap";
import CustomForm from "../../components/common/form";
import globalStyles from "../../OARS.module.scss";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Select from "react-select";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import UtilityFunctions from "../common/UtilityFunctions";

class addProjectModal extends CustomForm {
  constructor(props) {
    super(props);

    this.state = {
      selectedProjects: "",
      availableProjects: "",
      stateGuid: JSON.parse(localStorage.getItem("currentState")).stateGuid,
      errors: {},
    };
  }
  handleSpecificChanges = async (input) => {
    //do nothing
  };

  doSubmit = () => {
    if (!this.state.selectedProjects) {
      ErrorHandler.showError("Please select a project");
    } else {
      this.props.onHide();
      this.props.onSaveAssociation(this.state);
      this.clearData();
    }
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    this.setState({ availableProjects: "", selectedProjects: "" });
  }

  handleProjectSelection = (e) => {
    this.setState({ selectedProjects: e });
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      if (!this.state.availableProjects) {
        const projects = await this.getProjectsDataFromAPI();
        this.setState({ availableProjects: projects });
      }
    }
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  async getProjectsDataFromAPI() {
    let projectsData = [];
    const projectsDataResponse = await AgreementEntitiesAPI.GetMyAssociatedProjectsForState(this.state.stateGuid);
    if (projectsDataResponse?.successful) {
      projectsData = projectsDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve projects for this user.",
        apiName: "GetMyAssociatedProjectsForState",
        responseUnsuccessful: projectsDataResponse?.unsuccessful,
        responseMessage: projectsDataResponse?.message,
      });
    }
    let projectsArray = [];
    projectsData.forEach((project) => {
      let startDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.startDate);
      let endDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.endDate);
      let currentDate = new Date();
      if (currentDate >= startDate && currentDate < endDate) {
        projectsArray.push({ value: project.projectUniqueIdentifier, label: project.projectName });
      }
    });
    return projectsArray;
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Add Project</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 my-3 text-left">
            <Col>
              <Form.Group>
                <Form.Label className={globalStyles.formLabel}>Add Project</Form.Label>
                <Select
                  value={this.state.selectedProjects}
                  aria-label="Projects Selection"
                  placeholder="Select"
                  isMulti={true}
                  styles={this.customSelectStyles}
                  options={this.state.availableProjects}
                  onChange={(e) => this.handleProjectSelection(e)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span>Cancel</span>
          </Button>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>Submit</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default addProjectModal;
