export const APIRoutes = {
  ReferenceData: "refdata",
  OrganizationAndContact: "accounts",
  Properties: "properties",
  Agreements: "agreements",
  Conflicts: "conflicts",
  WorkTask: "worktasks",
  Inventory: "inventory",
  AuditAndNotifications: "auditnotifications",
  RoleAuthorization: "roleauth",
};
