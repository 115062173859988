import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class CreateEditDamageModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: this.props?.modalData
        ? this.props.modalData
        : {
            damageUniqueIdentifier: "",
            name: "",
            isAllowedGlobally: true,
            isAllowed: true,
            createdBy: "",
            createdDateTimeUTC: "",
            lastModifiedBy: "",
            lastModifiedDateTimeUTC: "",
          },
      errors: {},
    };
  }

  async doSubmit(e) {
    let form = e.currentTarget;
    if (form.checkValidity()) {
      this.setState({ validated: true });
      if (this.state.modalMode === "Create") {
        this.createDamage();
      } else if (this.state.modalMode === "Edit") {
        this.editDamage();
      }
    }
  }

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  async createDamage() {
    try {
      if (this.state.data.name === "") {
        throw new Error("Damage Name must be present.");
      }
      let createBody = { name: this.state.data.name };
      let createDamageResult = await ReferenceFileAPI.CreateDamage(createBody);
      let allowDamageResult = await ReferenceFileAPI.AllowDamageGlobally(
        createDamageResult.data.damageUniqueIdentifier
      );
      if (createDamageResult.successful && allowDamageResult.successful) {
        this.props.showSuccessModal();
      } else {
        if (!createDamageResult.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to create damage record",
            apiName: "CreateDamage",
            responseUnsuccessful: createDamageResult?.unsuccessful,
            responseMessage: createDamageResult?.message,
          });
        }
        if (!allowDamageResult.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to allow created damage record",
            apiName: "AllowDamageGlobally",
            responseUnsuccessful: createDamageResult?.unsuccessful,
            responseMessage: createDamageResult?.message,
          });
        }
      }
    } catch (error) {
      ErrorHandler.showError(error, error.message);
    }
    this.modalCleanUp();
  }

  async editDamage() {
    if (this.state.data.name === "") {
      throw new Error("Damage Name must be present");
    }

    let editBody = { name: this.state.data.name };
    let editDamageResult = await ReferenceFileAPI.UpdateDamage(this.state.data.damageUniqueIdentifier, editBody);
    let allowanceResult = await this.getAllowanceResult();

    if (this.isSuccessfulResult(editDamageResult) && this.isSuccessfulResult(allowanceResult)) {
      this.props.showSuccessModal();
    } else {
      if (!editDamageResult.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update damage record",
          apiName: "UpdateDamage",
          responseUnsuccessful: editDamageResult?.unsuccessful,
          responseMessage: editDamageResult?.message,
        });
      }
      if (!allowanceResult.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to allow updated create damage record",
          apiName:
            this.state.data.isAllowedGlobally === "Active" || this.state.data.isAllowedGlobally === true
              ? "AllowDamageGlobally"
              : "DisallowDamageGlobally",
          responseUnsuccessful: allowanceResult?.unsuccessful,
          responseMessage: allowanceResult?.message,
        });
      }
    }
    this.modalCleanUp();
  }

  async getAllowanceResult() {
    let allowanceResult = null;
    if (this.state.data.isAllowedGlobally === "Active" || this.state.data.isAllowedGlobally === true) {
      allowanceResult = await ReferenceFileAPI.AllowDamageGlobally(this.state.data.damageUniqueIdentifier);
    } else if (this.state.data.isAllowedGlobally === "Inactive" || this.state.data.isAllowedGlobally === false) {
      allowanceResult = await ReferenceFileAPI.DisallowDamageGlobally(this.state.data.damageUniqueIdentifier);
    }
    return allowanceResult;
  }

  isSuccessfulResult(result) {
    return result.successful || result.message?.includes("already") ? true : false;
  }

  createDamageModalUI() {
    return (
      <div className={styles.createDamageModalBody}>
        <Row>
          <Col>{this.renderInput("name", "Damage Name", "text", "Enter name", "required")}</Col>
        </Row>
      </div>
    );
  }

  editDamageModalUI() {
    return (
      <div className={styles.editDamageModalBody}>
        <Row>
          <Col className={styles.editDamageModalGuid}>
            <label className={globalStyles.modalFormLabel}>
              ID
              <p id="editDamageModalGuid">{this.state.data.damageUniqueIdentifier}</p>
            </label>
          </Col>
        </Row>
        <Row>
          <Col className={styles.editDamageModalDamageName}>
            {this.renderInput("name", "Damage Name", "text", "Enter name", "required")}
          </Col>
        </Row>
        <Row>
          <Col className={styles.editDamageModalStatus}>
            {this.renderInlineCheckBoxSet(
              "isAllowedGlobally",
              "Status",
              ["Active", "Inactive"],
              "radio",
              this.state.data.isAllowedGlobally ? "Active" : "Inactive"
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editDamageModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editDamageModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createDamageModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editDamageModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Modal.Header className={globalStyles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} Damage</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyContent}</Modal.Body>
            <Modal.Footer className={globalStyles.modalFooter}>
              <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <Button type="submit" className={globalStyles.ModalCreateButton} disabled={this.state.data.name === ""}>
                <span className={globalStyles.ModalCreateButtonText}>{submitButtonText}</span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default CreateEditDamageModal;
