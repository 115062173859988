import React from "react";
import CustomForm from "../common/form";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../sidebar/sidebar";
import styles from "./dashboard.module.scss";
import StructuredSearchCard from "./StructuredSearchCard";

class Dash extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      states: props.userData.states,
      currentState: props.userData.currentState,
      entityType: "",
      data: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      states: nextProps.userData.states.map((state) => ({ id: state.stateGuid, name: state.stateName })),
      currentState: nextProps.userData.currentState,
    };
  }

  async handleSpecificChanges(input) {
    // do something
  }

  handleSearch = (entityType, searchData) => {
    this.props.onSearchBoxToggled();
    this.props.history.push({
      pathname: "/agreemententities/search",
      state: { entityType: entityType, searchData: searchData },
    });
  };

  handleEntityTypeChange = (updatedEntityType) => {
    this.setState({ entityType: updatedEntityType });
  };

  render() {
    const { isSidebarDisplayed, pageComponent, currentUserProfile, isSearchBoxDisplayed, ...rest } = this.props;

    return (
      <Container fluid data-testid="dashBoard" className={styles.dashboard}>
        <Row>
          <Col id="sidebar-wrapper" className={styles.sidebarWrapper} xs="auto">
            <Sidebar
              isSidebarDisplayed={isSidebarDisplayed}
              closeMenu={this.props.closeMenu}
              currentUserProfile={currentUserProfile}
              {...rest}
            />
          </Col>
          <Col id="page-content-wrapper" className={styles.pageContentWrapper} onClick={this.props.closeMenu}>
            {isSearchBoxDisplayed && (
              <div className={styles.stickySearchBox}>
                <Row>
                  <Col>
                    <StructuredSearchCard
                      states={this.state.states}
                      currentState={this.state.currentState}
                      onSearch={this.handleSearch}
                      isGlobalSearch={true}
                      entityType={this.state.entityType}
                      onEntityTypeChange={this.handleEntityTypeChange}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {pageComponent}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Dash);

