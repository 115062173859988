import React from "react";
import styles from "./EditModalPages.module.scss";
import { Button, Modal, Form, Dropdown, Row, Col, CloseButton } from "react-bootstrap";
import { withRouter } from "react-router";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import globalStyles from "../../OARS.module.scss";
import CustomForm from "../common/form";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

//DO NOT MODIFY FORMATS
class ConfigureSpeciesModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      speciesData: this.props.speciesData,
      allow: null,
      isInitialConfiguration: true,
      allowedUOMsData: [],
      removedUOMsData: [],
      isAllowChanged: false,
      isUOMsChanged: false,
    };
  }

  async configureSpecies() {
    let configureCall;
    let isUpdateSuccess = true;
    let speciesGuid = this.props.speciesData.speciesUniqueIdentifier;
    let stateGuid = this.props.usaStateData.stateGuid;
    if (this.state.isAllowChanged) {
      if (this.state.allow) {
        configureCall = await ReferenceFileAPI.AllowSpeciesToBeTargettedInState(speciesGuid, stateGuid);
      } else if (!this.state.allow) {
        configureCall = await ReferenceFileAPI.DisallowSpeciesToBeTargettedInState(speciesGuid, stateGuid);
      }
      if (!configureCall.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set damage agent allowed status",
          apiName: this.state.allow ? "AllowSpeciesToBeTargettedInState" : "DisallowSpeciesToBeTargettedInState",
          responseUnsuccessful: configureCall?.unsuccessful,
          responseMessage: configureCall?.message,
        });
        isUpdateSuccess = false;
      } else if (configureCall?.successful) {
        this.props.updateSpeciesFromResponse(configureCall.data);
      }
    }

    if (this.state.isUOMsChanged) {
      isUpdateSuccess = await this.configureAllowedUOMs();
    }
    if (isUpdateSuccess && (this.state.isAllowChanged || this.state.isUOMsChanged)) {
      toast.success("Damage Agents configured Successfully");
    }

    this.setState({ allow: null });
    this.props.onHide();
  }

  activateRadio = () => {
    this.setState({ allow: true, isAllowChanged: true });
  };

  deactivateRadio = () => {
    this.setState({ allow: false, isAllowChanged: true });
  };

  handleUOMsCheckClick(item) {
    let { speciesData } = this.props;

    let removedUOMsData = [];
    if (this.state.removedUOMsData) {
      removedUOMsData = this.state.removedUOMsData;
    }

    let allowedUOMsData = this.state.isInitialConfiguration
      ? [...speciesData.stateSpecies?.allowedUOMsInState]
      : [...this.state.allowedUOMsData];

    if (document.getElementById(item.name + item.id).checked) {
      allowedUOMsData.push(item);
    } else {
      allowedUOMsData = allowedUOMsData.filter((i) => i.id !== item.id);
      removedUOMsData.push(item);
    }

    this.setState({ allowedUOMsData, removedUOMsData, isInitialConfiguration: false, isUOMsChanged: true });
  }

  handleModalCancel = () => {
    this.props.onHide();
  };

  async configureAllowedUOMs() {
    let isUpdateUOMSuccessful = true;

    if (this.state.allowedUOMsData?.length > 0) {
      isUpdateUOMSuccessful = await this.enableSpeciesUOMsInState();
    }
    if (this.state.removedUOMsData?.length > 0) {
      isUpdateUOMSuccessful = await this.disableSpeciesUOMsInState();
    }

    return isUpdateUOMSuccessful;
  }

  async enableSpeciesUOMsInState() {
    let isAddUOMSuccessful = true;

    let speciesGuid = this.props.speciesData.speciesUniqueIdentifier;
    let stateGuid = this.props.usaStateData.stateGuid;

    if (this.state.allowedUOMsData?.length > 0) {
      let addUOMResponse = await ReferenceFileAPI.EnableSpeciesUOMsInState(
        speciesGuid,
        stateGuid,
        this.state.allowedUOMsData.map((uom) => uom.id)
      );
      if (!addUOMResponse?.successful) {
        isAddUOMSuccessful = false;
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set damage agents allowed units of measure",
          apiName: "EnableSpeciesUOMsInState",
          responseUnsuccessful: addUOMResponse?.unsuccessful,
          responseMessage: addUOMResponse?.message,
        });
      } else if (addUOMResponse?.successful) {
        this.props.updateSpeciesFromResponse(addUOMResponse.data);
      }
    }

    return isAddUOMSuccessful;
  }

  async disableSpeciesUOMsInState() {
    let isRemoveUOMSuccessful = true;

    let speciesGuid = this.props.speciesData.speciesUniqueIdentifier;
    let stateGuid = this.props.usaStateData.stateGuid;

    if (this.state.removedUOMsData?.length > 0) {
      const removeUOMResponse = await ReferenceFileAPI.DisableSpeciesUOMsInState(
        speciesGuid,
        stateGuid,
        this.state.removedUOMsData.map((uom) => uom.id)
      );
      if (!removeUOMResponse?.successful) {
        isRemoveUOMSuccessful = false;
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to disable damage agents units of measure",
          apiName: "DisableSpeciesUOMsInState",
          responseUnsuccessful: removeUOMResponse?.unsuccessful,
          responseMessage: removeUOMResponse?.message,
        });
      } else if (removeUOMResponse?.successful) {
        this.props.updateSpeciesFromResponse(removeUOMResponse.data);
      }
    }

    return isRemoveUOMSuccessful;
  }

  render() {
    let { speciesData } = this.props;

    let dateLastUpdated = speciesData?.stateSpecies?.lastModifiedDateTimeUTC
      ? new Date(speciesData?.stateSpecies?.lastModifiedDateTimeUTC).toLocaleDateString("en-US")
      : speciesData?.stateSpecies?.lastModifiedDateTimeUTC;

    const globalAllowedUnitOfMeasures =
      speciesData?.globalSpecies?.globalAllowedUOMs?.length > 0 ? speciesData.globalSpecies.globalAllowedUOMs : [];
    const stateAllowedUnitOfMeasures =
      speciesData?.stateSpecies?.allowedUOMsInState?.length > 0 ? speciesData.stateSpecies.allowedUOMsInState : [];

    var radioButtons = (
      <Form.Group>
        <Form.Check
          checked={this.props.speciesData.stateSpecies?.isAllowedForState}
          type="radio"
          name="activeRadios"
          inline
          label="Yes"
          id="activeRadio"
          onChange={this.activateRadio}
        />
        <Form.Check
          checked={!this.props.speciesData.stateSpecies?.isAllowedForState}
          type="radio"
          name="activeRadios"
          inline
          label="No"
          id="inactiveRadio"
          onChange={this.deactivateRadio}
        />
      </Form.Group>
    );
    if (this.state.allow === true || this.state.allow === false) {
      radioButtons = (
        <Form.Group>
          <Form.Check
            checked={this.state.allow}
            type="radio"
            name="activeRadios"
            inline
            label="Yes"
            id="activeRadio"
            onChange={this.activateRadio}
          />
          <Form.Check
            checked={!this.state.allow}
            type="radio"
            name="activeRadios"
            inline
            label="No"
            id="inactiveRadio"
            onChange={this.deactivateRadio}
          />
        </Form.Group>
      );
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Configure Damage Agent</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.configureModalBody}>
            <Row className="mx-0">
              <Col>{this.renderLabel("type", "Type", this.props.speciesData.stateSpecies?.type)}</Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderLabel("name", "Name", this.props.speciesData.stateSpecies?.name)}</Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <Form.Group controlid="allowedUOMList">
                  <h4 className={globalStyles.formLabel}>Allowed UOMs</h4>
                  <div className={styles.createPageMethodVariableSelectColumn}>
                    <Dropdown data-testid="UOMsDropdown">
                      <Dropdown.Toggle variant="light">Change State Allowed UOMs</Dropdown.Toggle>
                      <Dropdown.Menu className={styles.dropdownToggle}>
                        <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                          {globalAllowedUnitOfMeasures?.map((item) => (
                            <div key={`formChecklistContainerUOMs-${item.name}`} className={styles.formCheckContainer}>
                              <input
                                type="checkbox"
                                className="form-check-input ml-0"
                                id={`${item.name}${item.id}`}
                                key={`inline-${item.name}${item.id}`}
                                data-toggle="button"
                                onClick={() => this.handleUOMsCheckClick(item)}
                                checked={
                                  this.state.isInitialConfiguration
                                    ? stateAllowedUnitOfMeasures?.find((i) => i.id === item.id)
                                    : this.state.allowedUOMsData.find((i) => i.id === item.id)
                                }
                              />
                              <p className="form-check-label ml-4" htmlFor="itemChecked">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col>{this.renderLabel("lastModifiedDate", "Last Modified Date", dateLastUpdated)}</Col>
            </Row>
            <Row className="mx-0">
              <Col>
                <h4 className={globalStyles.formLabel}>Allowed</h4>
                {radioButtons}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className={globalStyles.modalFooter}>
          <Button className={globalStyles.modalCancelButton} onClick={this.props.onHide}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button
            type="submit"
            className={globalStyles.modalSubmitButton}
            onClick={() => this.configureSpecies(this.value)}
          >
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(ConfigureSpeciesModal);
