import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { selectFilter, customFilter } from "react-bootstrap-table2-filter";
import EditMethodModal from "../ReferenceFilesEditModals/EditMethodModal";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CustomTextFilter from "../common/customTextFilter";
import { toast } from "react-toastify";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import moment from "moment";

class MethodTab extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      methods: [],
      showModal: false,
      modalData: [],
      isDataManager: true,
      clickEditPage: false,
      clickCreateMethod: false,
      isAllowedInState: false,
      requiresGeolocation: false,
      requiresLabel: false,
      history: this.props.history,
      currentUsaState: props.currentUsaState,
      defaultSort: [{ dataField: "name", order: "asc" }],
      subTypeFilter: null,
      subTypeFilterValues: {}, //[],

      typeFilterValue: "",
      subTypeFilterValue: "",
      nameFilterValue: "",
      allowedUsesFilterValue: "",
      allowedUOMsFilterValue: "",
      allowedFilterValue: "",
      cmitsFilterValue: "",
      lastUpdatedDateFilterValue: "",
      sortColumn: "name",
      isAscending: true,
      waitingForAPIData: false,

      methodTypes: [],
      methodSubTypes: [],
      columns: this.getColumns(),
    };
  }

  getColumns = () => {
    return [
      {
        dataField: "methodUniqueIdentifier",
        hidden: true,
        csvText: "Method Unique Id",
      },
      {
        dataField: "type",
        text: "Type",
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { verticalAlign: "text-top", backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "methodsTypeHeader" },
        attrs: { headers: "methodsTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "subType",
        text: "Sub Type",
        formatter: (cell) => {
          if (!cell) {
            return "None";
          } else {
            return cell;
          }
        },
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { verticalAlign: "text-top", backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "methodsSubTypeHeader" },
        attrs: { headers: "methodsSubTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "methodName",
        text: "Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            setColumnFilterValue={this}
            columnName="name"
            title="Method Name Filter"
          />
        ),
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { verticalAlign: "text-top", backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "methodsNameHeader" },
        attrs: { headers: "methodsNameHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "globalAllowedUses" : "allowedUsesInState",
        text: "Allowed Uses",
        csvFormatter: (cell, row) => {
          return this.getUseString(row);
        },
        filter: customFilter({
          onFilter: (filterValue, data) => {
            return data.filter((x) => this.getUseString(x).toLowerCase().includes(filterValue.toLowerCase()));
          },
        }),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            setColumnFilterValue={this}
            columnName="allowedUses"
            title="Method Allowed Uses Filter"
          />
        ),
        formatter: (cell, row) => {
          return this.getUseString(row);
        },
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "methodsUsesHeader" },
        attrs: { headers: "methodsUsesHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "globalAllowedUOMs" : "allowedUOMsInState",
        text: "Allowed UOMs",
        csvFormatter: (cell, row) => {
          return this.getUOMString(row);
        },
        filter: customFilter({
          onFilter: (filterValue, data) => {
            return data.filter((x) => this.getUOMString(x).toLowerCase().includes(filterValue.toLowerCase()));
          },
        }),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            setColumnFilterValue={this}
            columnName="allowedUOMs"
            title="Method Allowed UOMs Filter"
          />
        ),
        formatter: (cell, row) => {
          return this.getUOMString(row);
        },
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingBottom: "0px" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "methodsUOMsHeader" },
        attrs: { headers: "methodsUOMsHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "isAllowedGlobally" : "isAllowedInState",
        text: this.props.useGlobalData ? "Status" : "Allowed",

        filter: selectFilter({
          options: this.props.useGlobalData
            ? { true: "Active", false: "Inactive" }
            : { true: "Allowed", false: "Not Allowed" },
          getFilter: (filter) => {
            this.setState({ allowedFilter: filter });
          },
          onInput: (e) => {
            let textValue = "";
            if (e.currentTarget.value === "true") {
              textValue = "Allowed";
            } else if (e.currentTarget.value === "false") {
              textValue = "Not Allowed";
            }
            this.setState({ allowedFilterValue: textValue });
          },
          onFilter: (filterValue, data) => {
            if (filterValue === "") {
              return data;
            }

            if (this.props.useGlobalData) {
              return data.filter((x) => x.isAllowedGlobally.toString() == filterValue);
            } else {
              return data.filter((x) => x.isAllowedInState.toString() == filterValue);
            }
          },
        }),
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },

        formatter: (cell, row, rowIndex) => {
          if (this.props.useGlobalData) {
            return row.isAllowedGlobally ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          } else {
            return row.isAllowedInState ? <span style={{ color: "#008767" }}>Allowed</span> : "Not Allowed";
          }
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { verticalAlign: "text-top", backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "methodsAllowedHeader" },
        attrs: { headers: "methodsAllowedHeader", tabIndex: "0" },
      },
      {
        dataField: "cmits",
        text: "CMITS",
        csvFormatter: (cell, row) => {
          return this.getCmitsFields(row);
        },
        filter: customFilter({
          onFilter: (filterValue, data) => {
            return data.filter((x) => this.getCmitsFields(x).toLowerCase().includes(filterValue));
          },
        }),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            setColumnFilterValue={this}
            columnName="cmits"
            title="Method Allowed UOMs Filter"
          />
        ),
        formatter: (cell, row) => {
          return this.getCmitsFields(row);
        },
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingBottom: "0px" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "cmitsMethodsHeader" },
        attrs: { headers: "cmitsMethodsHeader", tabIndex: "0" },
      },
      {
        dataField: "lastModifiedDateTimeUTC",
        text: this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            dataType="date"
            setColumnFilterValue={this}
            columnName="lastUpdatedDate"
            title="Search by Last Updated Date"
          />
        ),
        formatter: (cell, row, rowIndex) => {
          return this.formatDate(cell);
        },
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },

        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { verticalAlign: "text-top", backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "methodsLastUpdatedHeader" },
        attrs: { headers: "methodsLastUpdatedHeader", tabIndex: "0" },
      },
      {
        datafield: "edit",
        text: "Actions",
        csvExport: false,
        formatter: (cell, row) => {
          let configureDropdownItem = [];
          if (!this.props.useGlobalData) {
            configureDropdownItem.push(
              <Dropdown.Item
                eventKey="methodConfigure"
                key={"methodConfigure" + row.methodUniqueIdentifier}
                onSelect={() => this.setModalData(row)}
                hidden={!this.props.permissions.canIConfigureRefData()}
              >
                Configure
              </Dropdown.Item>
            );
          } else {
            configureDropdownItem.push(
              <React.Fragment>
                <Dropdown.Item
                  eventKey="favoriteMethodsAdd"
                  key={"favoriteMethodsAdd" + row.methodUniqueIdentifier}
                  onSelect={() => this.addFavoriteMethod(row)}
                  hidden={!row.isAllowedGlobally}
                >
                  Add to Favorites
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="damageEdit"
                  onSelect={() => this.handleClickEditMethod(row)}
                  hidden={!this.props.permissions.canIEditReferenceData()}
                >
                  Edit
                </Dropdown.Item>
              </React.Fragment>
            );
          }
          let infoIcon = null;
          if (row.comments) {
            infoIcon = (
              <div className={styles.descriptionInfoIcon} title={row.comments}>
                <i className="fa fa-info" aria-hidden="true" title={row.comments}></i>
              </div>
            );
          }
          return (
            <div>
              {infoIcon}
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton
                  id="dropdown-method-actions"
                  title="Actions"
                  variant="link"
                  className={styles.buttonLink}
                >
                  {configureDropdownItem}
                  <Dropdown.Item eventKey="damageHistoryTracking" onSelect={() => this.handleMethodHistory(row)}>
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          );
        },
        headerStyle: () => {
          return { width: "15%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
        },
        headerAlign: "right",
        align: "right",
        headerAttrs: {
          id: "methodActionsHeader",
          title: "Actions Column Header",
        },
        attrs: { headers: "methodActionsHeader" },
      },
    ];
  };

  getUOMString(row) {
    let uomList = row?.globalAllowedUOMs ? row?.globalAllowedUOMs : row?.allowedUOMsInState;
    uomList.sort((a, b) => a.displayText.localeCompare(b.displayText, undefined, { sensitivity: "base" }));
    let uomString = "No UOMs";

    if (uomList?.length > 0) {
      let newAllowedUOMsInState = "";
      for (let j = 0; j < uomList.length; j++) {
        newAllowedUOMsInState += uomList[j]?.displayText + ", ";
      }
      uomString = newAllowedUOMsInState.slice(0, -2);
    }

    return uomString;
  }

  getUseString(row) {
    let useList = row?.globalAllowedUses ? row?.globalAllowedUses : row?.allowedUsesInState;
    useList.sort((a, b) => a.displayText.localeCompare(b.displayText, undefined, { sensitivity: "base" }));
    let useString = "No Uses";

    if (useList?.length > 0 && row) {
      let newAllowedUsesInState = "";
      for (let j = 0; j < useList.length; j++) {
        newAllowedUsesInState += useList[j]?.displayText + ", ";
      }
      useString = newAllowedUsesInState.slice(0, -2);
    }

    return useString;
  }

  addFavoriteMethod = async (method) => {
    if (this.props.favoriteMethods.find((m) => m.methodUniqueIdentifier === method.methodUniqueIdentifier)) {
      toast.success("This Method is already a favorite.");
    } else {
      if (this.props.favoriteMethods.length === 15) {
        toast.success("You already have 15 favorite Methods.");
      } else {
        this.props.favoriteMethods.push(method);

        const userData = this.props.userData;
        const saveFavoritesResponse = await RoleAuthorizationAPI.SetUserFavoriteReferenceFileData(
          userData?.userPrinciple,
          {
            speciesUniqueReferences: userData.preferredSpeciesUniqueReferences,
            methodUniqueReferences: this.props.favoriteMethods.map((f) => f.methodUniqueIdentifier),
            resourceUniqueReferences: userData.preferredResourcesUniqueReferences,
          }
        );
        if (saveFavoritesResponse?.successful && saveFavoritesResponse.data) {
          const updatedUserData = saveFavoritesResponse.data;
          userData.preferredSpeciesUniqueReferences = updatedUserData.preferredSpeciesUniqueReferences;
          userData.preferredMethodsUniqueReferences = updatedUserData.preferredMethodsUniqueReferences;
          userData.preferredResourcesUniqueReferences = updatedUserData.preferredResourcesUniqueReferences;
          toast.success("Method added to favorites.");
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to set user's favorites",
            apiName: "SetUserFavoriteReferenceFileData",
            responseUnsuccessful: saveFavoritesResponse?.unsuccessful,
            responseMessage: saveFavoritesResponse?.message,
          });
        }
      }
    }
  };

  tableTextStyle = {};

  getCmitsFields(row) {
    let cmitsFields = "";
    if (this.props.useGlobalData) {
      if (row.isCMITSMethod) {
        cmitsFields = cmitsFields + "Tracked";
      }
      if (row.isMixingMethod) {
        cmitsFields = cmitsFields + ", Mixable";
      }
      if (row.isSerialNumberRequired) {
        cmitsFields = cmitsFields + ", Serial Numbers";
      }
      if (row.onlyTransferToPersonalWarehouse) {
        cmitsFields = cmitsFields + ", Employee Warehouse Required";
      }
    } else {
      if (row.isCMITSMethodGlobally) {
        cmitsFields = cmitsFields + "Tracked";
      }
      if (row.isMixingMethodGlobally) {
        cmitsFields = cmitsFields + ", Mixable";
      }
      if (row.isSerialNumberRequiredGlobally) {
        cmitsFields = cmitsFields + ", Serial Numbers";
      }
      if (row.onlyTransferToPersonalWarehouseGlobally) {
        cmitsFields = cmitsFields + ", Employee Warehouse Required";
      }
    }

    return cmitsFields;
  }

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleDateString("en-US");
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      currentUsaState: nextProps.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier ||
      prevState.typeFilterValue !== this.state.typeFilterValue ||
      prevState.subTypeFilterValue !== this.state.subTypeFilterValue ||
      prevState.allowedFilterValue !== this.state.allowedFilterValue ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.isAscending !== this.state.isAscending
    ) {
      await this.getDataFromAPI();
      this.setState({ waitingForAPIData: false });
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "methodTab") {
      await this.getDataFromAPI();
      this.setState({ waitingForAPIData: false });
    }

    if (prevState.typeFilterValue !== this.state.typeFilterValue) {
      let subTypeFilterValues = {};
      if (this.state.typeFilterValue) {
        const typeEnumId = this.state.methodTypes.find((x) => x.displayText === this.state.typeFilterValue)?.id;
        const newSubTypeList = await ReferenceFileAPI.GetMethodSubTypesEnumForType(typeEnumId);
        if (newSubTypeList?.successful) {
          subTypeFilterValues = this.turnArrayIntoEnum(newSubTypeList?.data);
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to retrieve method sub types",
            apiName: "GetMethodSubTypesEnumForType",
            responseUnsuccessful: newSubTypeList?.unsuccessful,
            responseMessage: newSubTypeList?.message,
          });
        }
      }

      this.setState({ subTypeFilterValues: subTypeFilterValues }, () => {
        let currentColumns = this.state.columns;
        let subTypeColumn = currentColumns.find((col) => col.dataField === "subType");
        subTypeColumn.filter = selectFilter({
          options: this.subTypeOptions,
          getFilter: (filter) => {
            this.state.subTypeFilter = filter;
          },
        });
        this.setState({ columns: currentColumns });
      });
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "methodTab") {
      await this.getDataFromAPI();
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ waitingForAPIData: false });
      if (this.props.history.location.state?.methodCreateResponseData) {
        this.insertNewMethodFromResponse(this.props.history.location.state?.methodCreateResponseData);
      }
      if (this.props.history.location.state?.methodEditResponseData) {
        this.updateMethodFromResponse(this.props.history.location.state?.methodEditResponseData);
      }
    }
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("MethodTab.jsx got unmounted");
    }
  }

  async getDataFromAPI() {
    if (!this.state.currentUsaState || this.state.waitingForAPIData) {
      return;
    }

    this.setState({ waitingForAPIData: true });

    this.props.setIsLoading(true);
    await this.refreshData();
    let methodTypes = await ReferenceFileAPI.GetMethodTypesEnum(this.controller.signal);
    if (!methodTypes || methodTypes?.unsuccessful) {
      methodTypes = [];
      if (this.controller.signal.aborted) {
        return;
      }
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve method types",
        apiName: "GetMethodTypesEnum",
        responseUnsuccessful: methodTypes?.unsuccessful,
        responseMessage: methodTypes?.message,
      });
    }
    let currentColumns = this.state.columns;
    let typeColumn = this.findColumn(currentColumns, "type");
    let subTypeColumn = this.findColumn(currentColumns, "subType");
    let dateColumn = this.findColumn(currentColumns, "lastModifiedDateTimeUTC");
    let allowedColumn = currentColumns.find(
      (col) => col.dataField === "isAllowedGlobally" || col.dataField === "isAllowedInState"
    );

    if (allowedColumn) {
      allowedColumn.text = this.props.useGlobalData ? "Status" : "Allowed";
    }
    if (dateColumn) {
      dateColumn.text = this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date";
    }
    let typesFilterValues = this.turnArrayIntoEnum(methodTypes?.data);
    if (typeColumn) {
      this.typeColumnFilter(typeColumn, typesFilterValues);
    }

    if (subTypeColumn) {
      this.subTypeColumnFilter(subTypeColumn);
    }

    this.setState({ columns: currentColumns, methodTypes: methodTypes?.data }, () => {
      this.props.setIsLoading(false);
    });
  }

  findColumn(currentColumns, columnName) {
    return currentColumns.find((col) => col.dataField === columnName);
  }

  typeColumnFilter(typeColumn, typesFilterValues) {
    typeColumn.filter = selectFilter({
      options: typesFilterValues,
      "aria-labelledby": "methodsTypeHeader",
      onFilter: (value, data) => {
        if (value && this.state.typeFilterValue !== value) {
          this.setState({ typeFilterValue: value, subTypeFilterValue: "" });
          return data.filter((resource) => resource.type === value);
        } else if (!value) {
          return data;
        }
      },
      onInput: (e) => {
        this.setState({ typeFilterValue: e.currentTarget.value, subTypeFilterValue: "" });
      },
    });
  }

  subTypeColumnFilter(subTypeColumn) {
    subTypeColumn.filter = selectFilter({
      options: this.subTypeOptions,
      defaultValue: 0,
      getFilter: (filter) => {
        this.setState({ subTypeFilter: filter });
      },
      onInput: (e) => {
        this.setState({ subTypeFilterValue: e.currentTarget.value });
      },
    });
  }

  subTypeOptions = (e) => {
    return this.state.subTypeFilterValues;
  };

  async refreshData() {
    let usaStateValue = null;
    if (!this.props?.useGlobalData && this.state?.currentUsaState?.stateGuid) {
      usaStateValue = this.state.currentUsaState.stateGuid;
    }

    let dateValue = "";
    if (this.state.lastUpdatedDateFilterValue !== "") {
      dateValue = moment(this.state.lastUpdatedDateFilterValue, "YYYY-MM-DD").utc().format("MM/DD/YYYY");
    }

    const getMethodsAction = await ReferenceFileAPI.GetFilteredMethodsTableData(
      this.state.typeFilterValue,
      this.state.subTypeFilterValue,
      this.state.nameFilterValue,
      this.state.allowedUsesFilterValue,
      this.state.allowedUOMsFilterValue,
      this.state.allowedFilterValue,
      this.state.cmitsFilterValue,
      dateValue,
      this.state.sortColumn,
      this.state.isAscending,
      usaStateValue,
      this.controller.signal
    );

    if (getMethodsAction?.unsuccessful || !getMethodsAction) {
      if (this.controller.signal.aborted) {
        return;
      }
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve filtered method table data",
        apiName: "GetFilteredMethodsTableData",
        responseUnsuccessful: getMethodsAction?.unsuccessful,
        responseMessage: getMethodsAction?.message,
        signal: this.controller.signal,
      });
      this.props.setIsLoading(false);
    } else {
      if (this.props.useGlobalData) {
        this.setState({ methods: getMethodsAction?.data?.results });
      } else {
        this.setState({ methods: getMethodsAction?.data?.results?.filter((t) => t.isAllowedGlobally) });
      }
    }
  }

  insertNewMethodFromResponse = (createMethodData) => {
    let methodTable = this.state.methods;
    if (methodTable.some((m) => m.methodUniqueIdentifier === createMethodData.methodUniqueIdentifier)) {
      return;
    }
    methodTable.push(createMethodData);
    this.setState({ species: methodTable });
  };

  updateMethodFromResponse = (updateMethodData) => {
    this.setState(
      {
        methods: this.state.methods.map((s) => {
          if (s.methodUniqueIdentifier === updateMethodData?.methodUniqueIdentifier) {
            s = updateMethodData;
          }
          return s;
        }),
      },
      () => {
        this.props.history.push({ state: { tab: "methodTab", methodEditResponseData: null } });
      }
    );
  };

  turnArrayIntoEnum(array) {
    let result = {};
    array.sort((a, b) => a.displayText.localeCompare(b, undefined, { sensitivity: "base" }));
    for (var i = 0; i < array?.length; i++) {
      var current = array[i];
      result[current.displayText] = current.displayText;
    }
    return result;
  }

  setModalData = async (row) => {
    const modalData = { ...row };
    const methodDataResponse = await ReferenceFileAPI.GetMethod(row.methodUniqueIdentifier);
    if (methodDataResponse?.successful) {
      modalData.globalAllowedUses = methodDataResponse?.data?.globalAllowedUses;
      modalData.globalAllowedUOMs = methodDataResponse?.data?.globalAllowedUOMs;
      this.setState({
        showModal: true,
        modalData,
        requiresGeolocation: modalData.isGeolocationRequiredInState,
        requiresLabel: modalData.isMethodLabelRequiredInState,
        isAllowedInState: modalData.isAllowedInState,
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get method data for modal",
        apiName: "GetMethod",
        responseUnsuccessful: methodDataResponse?.unsuccessful,
        responseMessage: methodDataResponse?.message,
      });
    }
  };

  closeModal = async () => {
    this.setState({ showModal: false });
  };

  handleClickEditMethod = async (row) => {
    row.globalData = this.props.useGlobalData;
    let getGlobalMethod = await ReferenceFileAPI.GetMethod(row.methodUniqueIdentifier);
    if (getGlobalMethod?.successful) {
      this.props.history.push({
        pathname: "/method/edit",
        state: { globalData: this.props.useGlobalData, methodData: getGlobalMethod?.data },
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get method data",
        apiName: "GetMethod",
        responseUnsuccessful: getGlobalMethod?.unsuccessful,
        responseMessage: getGlobalMethod?.message,
      });
    }
  };

  handleClickCreateMethod = () =>
    this.props.history.push({ pathname: "/method/create", state: { globalData: this.props.useGlobalData } });

  handleMethodHistory = (row) => {
    //do nothing for now
  };

  render() {
    return (
      <ToolkitProvider
        keyField="methodUniqueIdentifier"
        data={this.state.methods}
        columns={this.state.columns}
        bootstrap4={true}
        striped="true"
        exportCSV={{
          fileName: "method-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Method"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleClickCreateMethod}
                  useGlobalData={this.props.useGlobalData}
                  recordCount={this.state.methods.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="methodUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  className={styles.refFilesTable}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            <EditMethodModal
              show={this.state.showModal}
              onHide={this.closeModal}
              data={this.state.modalData}
              usaStateData={this.state.currentUsaState}
              allowedRadio={this.state.isAllowedInState}
              requiresGeolocationRadio={this.state.requiresGeolocation}
              requiresLabelRadio={this.state.requiresLabel}
              handleRequiresGeolocationRadioClick={() => {
                this.setState({ requiresGeolocation: !this.state.requiresGeolocation });
              }}
              handleRequiresLabelRadioClick={() => {
                this.setState({ requiresLabel: !this.state.requiresLabel });
              }}
              handleAllowedRadioClick={() => {
                this.setState({ isAllowedInState: !this.state.isAllowedInState });
              }}
              updateMethodFromResponse={this.updateMethodFromResponse}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(MethodTab);
