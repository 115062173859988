import React from "react";
import styles from "./Conflicts.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import CustomForm from "../common/form";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import searchIcon from "../../assets/search.svg";
import CheckedPropertySelection from "../Agreements/checkedPropertySelection";
import { toast } from "react-toastify";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";

const SearchIcon = () => {
  return <img src={searchIcon} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className={styles.noOptionsMessage}>
        <i className="fa fa-exclamation-circle " style={{ color: "gray", padding: "4px" }} aria-hidden="true"></i>
        Sorry, we couldn’t find a match for “{props.selectProps.inputValue}”. Please try another search
      </span>
    </components.NoOptionsMessage>
  );
};

class CreateResourcesModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        protectedResources: [],
        protectedResourceId: "",
        resourceSearch: "",
        resourceQuantity: "",
        resourceUom: "",
        resourceUnitValue: "",
        resourceTotalValue: "",
        resourceYear: new Date().getFullYear(),
        resourceUOMs: this.props.resourceUOMs,
      },
      checkedProperties: [],
    };
  }

  getAvailableResources() {
    let availableResources = [];
    this.props.properties.forEach((property) => {
      availableResources = availableResources.concat(
        property.agreementResources
          .filter((r) => {
            if (property.protectedResources?.length > 0) {
              return !property.protectedResources.some(
                (protectedResource) => protectedResource.resourceUniqueReference === r.resourceUniqueReference
              );
            }

            return true;
          })
          .map((resource) => ({
            resourceUniqueReference: resource.resourceUniqueReference,
            resourceName: resource.resourceName,
            isSelected: false,
            propertyName: property.propertyName,
            propertyUniqueIdentifier: property.propertyUniqueIdentifier,
            damageTypeUniqueReference: "",
            diseaseTypeUniqueReference: "",
          }))
      );
    });

    return availableResources;
  }

  clearData(data) {
    data.protectedResources = [];
    data.resourceSearch = "";
    data.resourceQuantity = "";
    data.resourceUom = "";
    data.resourceUnitValue = "";
    data.resourceTotalValue = "";
    data.resourceYear = new Date().getFullYear();
    data.resourceUOMs = this.props.resourceUOMs;
  }

  handleResourceUomChange = (input) => {
    const { data } = this.state;
    data.resourceUom = input;
    this.setState({ data });
    return input;
  };

  handleResourceSearch = async (inputValue) => {
    return await this.getResourcesFromAPI(inputValue);
  };

  getFilterReferenceFilesBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };
  }

  async getResourcesFromAPI(searchValue) {
    const currentUsaState = this.props.currentUsaState;
    if (currentUsaState && currentUsaState.stateGuid) {
      const resourcesData = await ReferenceFileAPI.FilterResources(
        currentUsaState.stateGuid,
        this.getFilterReferenceFilesBody(searchValue)
      );
      if (resourcesData.data.results) {
        let results = resourcesData.data.results;

        return results.map((result) => ({
          value: result.resourceUniqueIdentifier,
          label: result.name,
        }));
      }
    }

    return "";
  }

  async addResourcesToAgreement() {
    const { data } = this.state;
    let success = false;
    let resourcesToAgreementResponse = "";
    if (
      data.resourceSearch &&
      data.resourceQuantity &&
      data.resourceUom &&
      (data.resourceUnitValue || data.resourceTotalValue) &&
      data.resourceYear
    ) {
      for (let i = 0; i < this.state.checkedProperties.length; i++) {
        const currentProperty = this.state.checkedProperties[i];
        let agreementResponse = await AgreementAPI.GetAgreementsByProperty(currentProperty.propertyUniqueIdentifier);
        if (agreementResponse?.successful) {
          let foundWorkableAgreement = false;
          for (let j = 0; j < agreementResponse.data.length; j++) {
            const current = agreementResponse.data[j];
            if (current.agreementStatus.name === "active" || current.agreementStatus.name === "pending") {
              foundWorkableAgreement = true;
              resourcesToAgreementResponse = await AgreementAPI.AddResourcesToAgreementProperties(
                current.agreementUniqueIdentifier,
                this.getResourcesToAgreementBody(data.protectedResources[0], currentProperty)
              );
              if (resourcesToAgreementResponse?.successful) {
                success = true;
                break;
              } else {
                toast.warning("Something went wrong, Resource not created.");
                return;
              }
            }
          }
          if (!foundWorkableAgreement) {
            toast.warning(
              "Resource not created, Property " + currentProperty.propertyName + " does not have a workable"
            );
            return;
          }
        } else {
          toast.warning("Something went wrong, could not retreive property agreement. " + agreementResponse.message);
        }
      }
      if (success) {
        toast.success("Resource created.");
        return success;
      }
    } else {
      toast
        .warning(
          "Please fill out all required fields. Missing fields: " +
            (data.resourceSearch ? "" : "Resource, ") +
            (data.resourceQuantity ? "" : "Quantity, ") +
            (data.resourceUom ? "" : "UOM, ") +
            (data.resourceUnitValue || data.resourceTotalValue ? "" : "Value, ")
        )
        .slice(0, -2);
    }
  }

  getResourcesToAgreementBody(resource, property) {
    const { data } = this.state;
    return [
      {
        resourceUniqueReference: data.protectedResourceId,
        unitOfMeasureEnumId: parseInt(data.resourceUom.value),
        currencyCodeEnumId: 0,
        valuationPerUnitOfMeasure: parseInt(data.resourceUnitValue),
        quantity: parseInt(data.resourceQuantity),
        totalValuation: parseInt(data.resourceTotalValue),
        valuationYear: parseInt(data.resourceYear),
        addToProperties: [
          {
            propertyUniqueReference: property.propertyUniqueIdentifier,
          },
        ],
      },
    ];
  }

  handleProtectedResourceSelection = async (input) => {
    return await this.handleResourceSelection(input);
  };

  async handleResourceSelection(input) {
    const { data } = this.state;
    data.resourceSearch = input;
    const resourceId = input.value;
    if (this.props.currentUsaState?.stateGuid) {
      const resourceServerResponse = await ReferenceFileAPI.GetResourceForState(
        resourceId,
        this.props.currentUsaState.stateGuid
      );
      if (resourceServerResponse?.successful) {
        const resourceData = resourceServerResponse.data;
        data.protectedResources.push(resourceData);
        data.protectedResourceId = resourceId;
        if (resourceData?.stateValuationUnitOfMeasure) {
          const { id, name } = resourceData.stateValuationUnitOfMeasure;
          data.resourceUom = { value: id, label: name };
        }
      }
    }
    this.setState({ data });
    return input;
  }

  handleResourceChanges = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleModalCancel = (createSuccess) => {
    const { data } = this.state;
    this.clearData(data);
    this.props.onHide(createSuccess);
    this.setState({ data, isInitialConfiguration: true, checkedProperties: [] });
  };

  handlePropertyCheck = ({ currentTarget: input }) => {
    let { checkedProperties } = this.state;

    let existingCheckedProperties = "";
    existingCheckedProperties = [...checkedProperties];

    const inputIdData = input.id.split("_");
    const modifiedField = inputIdData[0];
    const propertyId = inputIdData[1];

    checkedProperties =
      modifiedField === "property"
        ? this.getUpdatedPropertySelection(existingCheckedProperties, propertyId)
        : this.getUpdatedParcelSelection(existingCheckedProperties, propertyId, inputIdData[2]);

    this.setState({ checkedProperties });
  };

  getUpdatedPropertySelection(existingCheckedProperties, propertyId) {
    let checkedProperties = "";
    const originalPropery = JSON.parse(
      JSON.stringify(this.props.properties.find((p) => p.propertyUniqueIdentifier === propertyId))
    );

    if (
      existingCheckedProperties.length > 0 &&
      existingCheckedProperties.find((property) => property.propertyUniqueIdentifier === propertyId)
    ) {
      checkedProperties = existingCheckedProperties.filter(
        (property) => property.propertyUniqueIdentifier !== propertyId
      );
    } else if (existingCheckedProperties.length > 0) {
      checkedProperties = [...existingCheckedProperties, originalPropery];
    } else {
      checkedProperties = [originalPropery];
    }

    return checkedProperties;
  }

  getUpdatedParcelSelection(existingCheckedProperties, propertyId, parcelId) {
    let checkedParcels = "";
    let existingParcels = existingCheckedProperties.find(
      (property) => property.propertyUniqueIdentifier === propertyId
    ).parcels;

    if (existingParcels.length > 0 && existingParcels.find((parcel) => parcel.parcelUniqueIdentifier === parcelId)) {
      checkedParcels = existingParcels.filter((parcel) => parcel.parcelUniqueIdentifier !== parcelId);
    } else if (existingParcels.length > 0) {
      checkedParcels = [...existingParcels, { parcelUniqueIdentifier: parcelId }];
    } else {
      checkedParcels = [{ parcelUniqueIdentifier: parcelId }];
    }

    let checkedProperties = [...existingCheckedProperties];
    checkedProperties.find((property) => property.propertyUniqueIdentifier === propertyId).parcels = checkedParcels;

    return checkedProperties;
  }

  handleCreateResource = async () => {
    let success = await this.addResourcesToAgreement();
    if (success) {
      this.handleModalCancel(true);
    }
  };

  render() {
    const { data } = this.state;
    const { properties } = this.props;
    let checkedProperties = [];
    checkedProperties = JSON.parse(JSON.stringify(this.state.checkedProperties));

    const checkedPropertyIds = checkedProperties.map((property) => property.propertyUniqueIdentifier);
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.modalDialog}
      >
        <Modal.Header className={styles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Create Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBoby}>
          <Row className={styles.informationRow}>
            <Col>
              <h6 className={styles.fieldLabels}>Year</h6>
              <p className={globalStyles.informationText} tabIndex="0" title={`Resource Year ${data.resourceYear}`}>
                {data.resourceYear}
              </p>
            </Col>
          </Row>
          <Row className={styles.informationRow}>
            <Col>
              <h6 className={styles.fieldLabels}>
                Resources<span className={globalStyles.asterisk508}>{" *"}</span>
              </h6>
              <AsyncSelect
                aria-label="Resource Search Bar"
                value={data.resourceSearch}
                openMenuOnClick={false}
                placeholder="Search Resource"
                loadOptions={this.handleResourceSearch}
                onChange={this.handleProtectedResourceSelection}
                components={{ DropdownIndicator, NoOptionsMessage }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "",
                    primary: "#ced4da",
                  },
                })}
                styles={{
                  control: (styles) => ({ ...styles, fontSize: "12px" }),
                  menu: (styles) => ({ ...styles, marginTop: "0px" }),
                  noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
                  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    const color = "#EAF3F1";
                    return {
                      ...styles,
                      fontSize: "12px",
                      textAlign: "left",
                      borderBottom: "1px solid #f2f2f2",
                      backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                      color: isDisabled ? "#ccc" : isSelected ? ("#ccc" ? "white" : "black") : data.color,
                      cursor: isDisabled ? "not-allowed" : "default",
                      ":active": {
                        ...styles[":active"],
                        backgroundColor: !isDisabled && (isSelected ? color : null),
                      },
                    };
                  },
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                }}
              />
            </Col>
          </Row>
          <Row className={styles.informationRow}>
            <Col md={6}>
              <h6 className={styles.fieldLabels}>
                Quantity<span className={globalStyles.asterisk508}>{" *"}</span>
              </h6>
              <Form.Control
                name="resourceQuantity"
                type="number"
                placeholder="Enter Quantity"
                value={data.resourceQuantity}
                onChange={this.handleResourceChanges}
              />
            </Col>
            <Col md={6}>
              <h6 className={styles.fieldLabels}>
                UOM<span className={globalStyles.asterisk508}>{" *"}</span>
              </h6>
              <Select
                aria-label="Unit of Measure Selection"
                placeholder="UOM"
                options={this.props.resourceUOMs}
                onChange={this.handleResourceUomChange}
              />
            </Col>
          </Row>
          <Row className={styles.informationRow}>
            <Col md={6}>
              <h6 className={styles.fieldLabels}>
                Unit Value<span className={globalStyles.asterisk508}>{data.resourceTotalValue ? "" : " *"}</span>
              </h6>
              <Form.Control
                name="resourceUnitValue"
                type="number"
                placeholder="Enter Unit Value"
                value={data.resourceUnitValue}
                onChange={this.handleResourceChanges}
              />
            </Col>
            <Col md={6}>
              <h6 className={styles.fieldLabels}>
                Total Value<span className={globalStyles.asterisk508}>{data.resourceUnitValue ? "" : " *"}</span>
              </h6>
              <Form.Control
                name="resourceTotalValue"
                type="number"
                placeholder="Enter Total Value"
                value={data.resourceTotalValue}
                onChange={this.handleResourceChanges}
              />
            </Col>
          </Row>
          <Row className={styles.informationRow}>
            <h5 className={globalStyles.modalTitleText}>Associate with Property</h5>
          </Row>
          <div className={styles.informationRow} style={{ marginTop: "-25px" }}>
            <Row className="my-4">
              <Col sm={6}>
                <span className={styles.formLabel}>Property Selection</span>
              </Col>
              <Col sm={6}>
                <span className={styles.formLabel}>Parcel Selection</span>
              </Col>
            </Row>
            {properties
              .filter((x) => checkedPropertyIds.includes(x.propertyUniqueIdentifier))
              .map((property, index) => {
                return (
                  <CheckedPropertySelection
                    property={property}
                    checkedParcels={
                      checkedProperties.find((p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier)
                        .parcels
                    }
                    onPropertyChange={this.handlePropertyCheck}
                    key={index}
                    separator={!(properties.length === checkedProperties.length && index === properties.length - 1)}
                  />
                );
              })}
            {properties
              .filter((x) => !checkedPropertyIds.includes(x.propertyUniqueIdentifier))
              .map((property, index) => {
                return (
                  <Row className={index === 0 ? "mb-2 mt-4" : "my-2"} key={property.propertyUniqueIdentifier}>
                    <Col>
                      <Form.Group controlId={"property_" + property.propertyUniqueIdentifier}>
                        <Form.Check type="checkbox" label={property.propertyName} onClick={this.handlePropertyCheck} />
                      </Form.Group>
                    </Col>
                  </Row>
                );
              })}
          </div>
        </Modal.Body>
        <Modal.Footer className>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button className={styles.buttons} variant="primary" onClick={this.handleCreateResource}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateResourcesModal;
