import React from "react";
import { Nav } from "react-bootstrap";
import "./sidebar-item.css";

const SidebarItem = (props) => {
  const { itemClass, iconClass, label, onClick } = props;

  return (
    <Nav.Item className={itemClass} data-testid="sidebarItem">
      <Nav.Link onClick={onClick}>
        <i className={iconClass} aria-hidden="true" data-testid="sidebarIcon" title={label}></i>
        <span style={{ color: "white" }}>{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

export default SidebarItem;
