import React from "react";
import { Row, Col, Button, Card, Tabs, Tab, Image, Modal, CloseButton, Spinner } from "react-bootstrap";
import styles from "./NotificationWidget.module.scss";
import globalStyles from "../../OARS.module.scss";
import NotificationAPI from "../../api/Notification/NotificationAPI";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import CustomTextFilter from "../common/customTextFilter";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class NotificationWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotificationDataLoading: false,
      userNotifications: [],
      userArchivedNotifications: [],
      notificationsColumns: [],
      archivedNotificationsColumns: [],
      showNotificationDetailsModal: false,
      notificationDataForModal: "",
      currentLoadingNotification: "",
    };
    this.controller = new AbortController();
  }

  async componentDidMount() {
    const userProfile = JSON.parse(localStorage.getItem("currentUserRole"));
    this.setNotificationsColumns();
    let getNotificationsResponse;
    if (userProfile?.wsUserProfileUniqueIdentifier) {
      this.setState({ isNotificationDataLoading: true });
      const getNotificationsCountResponse = await NotificationAPI.GetReceivedNotificationsForUserProfile(
        userProfile.wsUserProfileUniqueIdentifier,
        0,
        0,
        this.controller.signal
      );
      if (getNotificationsCountResponse?.successful) {
        getNotificationsResponse = await NotificationAPI.GetReceivedNotificationsForUserProfile(
          userProfile?.wsUserProfileUniqueIdentifier,
          getNotificationsCountResponse?.data?.recordCount ? getNotificationsCountResponse?.data?.recordCount : 100,
          1,
          this.controller.signal
        );
      }
      if (getNotificationsResponse?.successful) {
        this.mapAndSetUserNotificationsAndArchivedNotifications(getNotificationsResponse?.data?.results);
      } else {
        if (this.controller.signal.aborted) {
          return;
        }
        this.setState({ isNotificationDataLoading: false });
        ErrorHandler.handleApiErrorMessage({
          signal: this.controller.signal,
          errorContextMessage: "Unable to load notifications",
          apiName: "GetReceivedNotificationsForUserProfile",
          responseUnsuccessful: getNotificationsResponse?.unsuccessful,
          responseMessage: getNotificationsResponse?.message,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isExpanded !== this.props.isExpanded) {
      this.setNotificationsColumns();
    }
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("NotificationWidget.jsx got unmounted");
    }
  }

  setNotificationsColumns() {
    if (this.props.isExpanded) {
      this.setState({
        notificationsColumns: [
          { dataField: "notificationUniqueIdentifier", hidden: true },
          {
            dataField: "notificationSubject",
            text: "Subject",
            sort: "true",
            align: "left",
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
              <CustomTextFilter onFilter={onFilter} column={column} title="Method Name Filter" />
            ),
            formatter: (cell, row) => {
              if (cell) {
                return (
                  <Row key={row?.notificationUniqueIdentifier}>
                    <Col align="left">
                      <Button
                        variant="link"
                        className="p-0"
                        onClick={() => {
                          this.setState({ showNotificationDetailsModal: true, notificationDataForModal: row });
                          this.setNotificationAsRead(row?.notificationUniqueIdentifier, row?.isArchived, row?.isRead);
                        }}
                      >
                        <p
                          className={"mb-1 " + styles.expandedSubject}
                          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", height: "auto" }}
                        >
                          {cell}
                        </p>
                      </Button>
                    </Col>
                  </Row>
                );
              }
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5" };
              } else {
                return {};
              }
            },
            headerStyle: () => {
              return { width: "20%", verticalAlign: "text-top" };
            },
          },
          {
            dataField: "notificationBody",
            text: "Message",
            sort: "true",
            align: "left",
            formatter: (cell, row) => {
              if (cell) {
                return (
                  <Row>
                    <Col align="left">
                      <p
                        className={globalStyles.formData + " m-0 pt-1"}
                        style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", height: "auto" }}
                      >
                        {row?.notificationBody}
                      </p>
                    </Col>
                  </Row>
                );
              }
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5" };
              } else {
                return {};
              }
            },
            headerStyle: () => {
              return { verticalAlign: "text-top" };
            },
          },
          {
            dataField: "createdDateTimeUTC",
            text: "Date",
            sort: "true",
            align: "left",
            formatter: (cell) => {
              if (cell) {
                return (
                  <Row>
                    <Col align="left">
                      <p className={globalStyles.formData + " m-0 pt-1"}>{new Date(cell).toLocaleString()}</p>
                    </Col>
                  </Row>
                );
              }
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5" };
              } else {
                return {};
              }
            },
            headerStyle: () => {
              return { width: "200px", verticalAlign: "text-top" };
            },
          },
          {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => {
              return (
                <span>
                  {row.notificationUniqueIdentifier !== this.state.currentLoadingNotification ? (
                    <i className="fa fa-trash" alt="archive" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  )}
                </span>
              );
            },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.changeNotificationArchiveStatus(row?.notificationUniqueIdentifier, row?.isArchived);
              },
            },
            headerStyle: () => {
              return { width: "100px", textAlign: "center", verticalAlign: "text-top" };
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5", color: "black", cursor: "pointer", textAlign: "center" };
              } else {
                return {
                  color: "black",
                  cursor: "pointer",
                  textAlign: "center",
                };
              }
            },
          },
        ],
        archivedNotificationsColumns: [
          { dataField: "notificationUniqueIdentifier", hidden: true },
          {
            dataField: "notificationSubject",
            text: "Subject",
            sort: "true",
            align: "left",
            filter: customFilter(),
            filterRenderer: (onFilter, column) => (
              <CustomTextFilter onFilter={onFilter} column={column} title="Method Name Filter" />
            ),
            formatter: (cell, row) => {
              if (cell) {
                return (
                  <Row>
                    <Col align="left">
                      <Button
                        variant="link"
                        className="p-0"
                        onClick={() => {
                          this.setState({ showNotificationDetailsModal: true, notificationDataForModal: row });
                          this.setNotificationAsRead(row?.notificationUniqueIdentifier, row?.isArchived, row?.isRead);
                        }}
                      >
                        <p
                          className={"mb-1 " + styles.expandedSubject}
                          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", height: "auto" }}
                        >
                          {cell}
                        </p>
                      </Button>
                    </Col>
                  </Row>
                );
              }
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5" };
              } else {
                return {};
              }
            },
            headerStyle: () => {
              return { width: "20%", verticalAlign: "text-top" };
            },
          },
          {
            dataField: "notificationBody",
            text: "Message",
            sort: "true",
            align: "left",
            formatter: (cell, row) => {
              if (cell) {
                return (
                  <Row>
                    <Col align="left">
                      <p
                        className={globalStyles.formData + " m-0 pt-1"}
                        style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", height: "auto" }}
                      >
                        {row?.notificationBody}
                      </p>
                    </Col>
                  </Row>
                );
              }
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5" };
              } else {
                return {};
              }
            },
            headerStyle: () => {
              return { verticalAlign: "text-top" };
            },
          },
          {
            dataField: "createdDateTimeUTC",
            text: "Date",
            sort: "true",
            align: "left",
            formatter: (cell) => {
              if (cell) {
                return (
                  <Row>
                    <Col align="left">
                      <p className={globalStyles.formData + " m-0 pt-1"}>{new Date(cell).toLocaleString()}</p>
                    </Col>
                  </Row>
                );
              }
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5" };
              } else {
                return {};
              }
            },
            headerStyle: () => {
              return { width: "200px", verticalAlign: "text-top" };
            },
          },
          {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => {
              return (
                <span>
                  {row.notificationUniqueIdentifier !== this.state.currentLoadingNotification ? (
                    <i className="fa fa-undo" alt="unarchive" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  )}
                </span>
              );
            },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.changeNotificationArchiveStatus(row?.notificationUniqueIdentifier, row?.isArchived);
              },
            },
            headerStyle: () => {
              return { width: "100px", textAlign: "center", verticalAlign: "text-top" };
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5", color: "black", cursor: "pointer", textAlign: "center" };
              } else {
                return {
                  color: "black",
                  cursor: "pointer",
                  textAlign: "center",
                };
              }
            },
          },
        ],
      });
    } else {
      this.setState({
        notificationsColumns: [
          { dataField: "notificationUniqueIdentifier", hidden: true },
          {
            dataField: "notificationSubject",
            align: "left",
            formatter: (cell, row) => {
              if (cell) {
                return (
                  <React.Fragment>
                    <Row>
                      <Col align="left">
                        <p className={"mb-1 " + styles.nonExpandedSubject}>{cell}</p>
                      </Col>
                      <Col align="right">
                        <p className={globalStyles.formData + " " + styles.shortDate + " m-0"}>
                          {new Date(row?.createdDateTimeUTC).toLocaleString("default", { month: "short" }) +
                            " " +
                            new Date(row?.createdDateTimeUTC).getDate()}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col align="left">
                        <p
                          className={globalStyles.formData + " m-0"}
                          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", height: "auto" }}
                        >
                          {row?.notificationBody}
                        </p>
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              }
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5" };
              } else {
                return {};
              }
            },
          },
          {
            dataField: "action",
            formatter: (cell, row) => {
              return (
                <span>
                  {row.notificationUniqueIdentifier !== this.state.currentLoadingNotification ? (
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                  )}
                </span>
              );
            },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                this.changeNotificationArchiveStatus(row.notificationUniqueIdentifier, row.isArchived);
              },
            },
            headerStyle: () => {
              return { width: "30px" };
            },
            style: (cell, row) => {
              let s = {
                backgroundColor: "#008767",
                color: "white",
                paddingLeft: "9px",
                paddingTop: "20px",
                cursor: "pointer",
              };
              if (row.notificationUniqueIdentifier === this.state.currentLoadingNotification) {
                s.paddingLeft = "7px";
              }
              return s;
            },
          },
        ],
        archivedNotificationsColumns: [
          { dataField: "notificationUniqueIdentifier", hidden: true },
          {
            dataField: "notificationSubject",
            align: "left",
            formatter: (cell, row) => {
              if (cell) {
                return (
                  <React.Fragment>
                    <Row>
                      <Col align="left">
                        <p className={"mb-1 " + styles.nonExpandedSubject}>{cell}</p>
                      </Col>
                      <Col align="right">
                        <p className={globalStyles.formData + " " + styles.shortDate + " m-0"}>
                          {new Date(row?.createdDateTimeUTC).toLocaleString("default", { month: "short" }) +
                            " " +
                            new Date(row?.createdDateTimeUTC).getDate()}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col align="left">
                        <p
                          className={globalStyles.formData + " m-0"}
                          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", height: "auto" }}
                        >
                          {row?.notificationBody}
                        </p>
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              }
            },
            style: (cell, row) => {
              if (row.isRead) {
                return { backgroundColor: "#f5f5f5" };
              } else {
                return {};
              }
            },
          },
        ],
      });
    }
  }

  changeNotificationArchiveStatus = async (notificationGuid, isAlreadyArchived) => {
    if (notificationGuid) {
      this.setState({ currentLoadingNotification: notificationGuid }, () => {
        this.setNotificationsColumns();
      });
      const response = await NotificationAPI.SetNotificationArchiveStatus(notificationGuid, !isAlreadyArchived);
      if (response?.successful && !isAlreadyArchived) {
        let newUserNotifications = this.state.userNotifications.filter(
          (notification) => notification.notificationUniqueIdentifier !== response.data?.notificationUniqueIdentifier
        );
        let { userArchivedNotifications } = this.state;
        userArchivedNotifications.push(response.data);
        this.setState({
          userNotifications: newUserNotifications,
          userArchivedNotifications: this.state.userArchivedNotifications.sort(function (a, b) {
            let dateA = new Date(a.createdDateTimeUTC);
            let dateB = new Date(b.createdDateTimeUTC);
            if (dateA < dateB) {
              return 1;
            }
            if (dateA > dateB) {
              return -1;
            }
            return 0;
          }),
          currentLoadingNotification: "",
        });
      } else if (response?.successful && isAlreadyArchived) {
        let newArchivedUserNotifications = this.state.userArchivedNotifications.filter(
          (notification) => notification.notificationUniqueIdentifier !== response.data?.notificationUniqueIdentifier
        );
        let { userNotifications } = this.state;
        userNotifications.push(response.data);
        this.setState({
          userArchivedNotifications: newArchivedUserNotifications,
          userNotifications: this.state.userNotifications.sort(function (a, b) {
            let dateA = new Date(a.createdDateTimeUTC);
            let dateB = new Date(b.createdDateTimeUTC);
            if (dateA < dateB) {
              return 1;
            }
            if (dateA > dateB) {
              return -1;
            }
            return 0;
          }),
          currentLoadingNotification: "",
        });
      } else {
        isAlreadyArchived
          ? toast.warning("Unable to archive notification. Please try again later.")
          : toast.warning("Unable to un-archive notification. Please try again later.");
      }
    }
  };

  setNotificationAsRead = async (notificationGuid, isAlreadyArchived, isAlreadyRead) => {
    if (notificationGuid && !isAlreadyRead) {
      const response = await NotificationAPI.MarkNotificationRead(notificationGuid);
      if (response?.successful && isAlreadyArchived) {
        let { userArchivedNotifications } = this.state;
        let notificationToEdit = userArchivedNotifications.find(
          (notification) => notification.notificationUniqueIdentifier === response.data?.notificationUniqueIdentifier
        );
        if (notificationToEdit) {
          notificationToEdit.isRead = true;
          this.setState({ userArchivedNotifications });
        }
      } else if (response?.successful && !isAlreadyArchived) {
        let { userNotifications } = this.state;
        let notificationToEdit = userNotifications.find(
          (notification) => notification.notificationUniqueIdentifier === response.data?.notificationUniqueIdentifier
        );
        if (notificationToEdit) {
          notificationToEdit.isRead = true;
          this.setState({ userNotifications });
        }
      }
      this.setNotificationsColumns();
    }
  };

  mapAndSetUserNotificationsAndArchivedNotifications(results) {
    let userNotifications = [];
    let userArchivedNotifications = [];
    results
      .sort(function (a, b) {
        let dateA = new Date(a.createdDateTimeUTC);
        let dateB = new Date(b.createdDateTimeUTC);
        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }
        return 0;
      })
      .forEach((notif) => {
        if (notif.isArchived) {
          userArchivedNotifications.push(notif);
        } else {
          userNotifications.push(notif);
        }
      });
    this.setState({
      userNotifications: userNotifications,
      userArchivedNotifications: userArchivedNotifications,
      isNotificationDataLoading: false,
    });
  }

  navigateToAgreementRecordPage = () => {
    const { notificationDataForModal } = this.state;
    if (notificationDataForModal?.linkedAgreementUniqueReference) {
      this.props.onNavigate(notificationDataForModal?.linkedAgreementUniqueReference);
    }
  };

  renderNotificationsTab() {
    if (this.state.userNotifications.length > 0) {
      return (
        <BootstrapTable
          keyField="agreementUniqueIdentifier"
          data={this.state.userNotifications}
          columns={this.state.notificationsColumns}
          filter={filterFactory()}
          bootstrap4={true}
          hover={true}
          bordered={false}
        />
      );
    } else {
      return (
        <Row>
          <Col className="mt-4 text-center">{`There are no notifications.`}</Col>
        </Row>
      );
    }
  }

  renderArchivedNotificationsTab() {
    if (this.state.userArchivedNotifications.length > 0) {
      return (
        <BootstrapTable
          keyField="agreementUniqueIdentifier"
          data={this.state.userArchivedNotifications}
          columns={this.state.archivedNotificationsColumns}
          filter={filterFactory()}
          bootstrap4={true}
          hover={true}
          bordered={false}
        />
      );
    } else {
      return (
        <Row>
          <Col className="mt-4 text-center">{`There are no notifications.`}</Col>
        </Row>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card className={this.props.isExpanded ? styles.expandedCards : styles.nonExpandedNotifCard}>
          <Card.Body>
            <Row>
              <Col className={`text-left pl-0 ${this.props.isExpanded ? "pr-0" : ""}`}>
                <span className={styles.cardTitleText}>My Notifications</span>
              </Col>
              <Col className="text-right pr-2">
                <Image
                  name="myNotifications"
                  aria-label="Expand My Notifications Card"
                  style={{ cursor: "pointer" }}
                  src={this.props.isExpanded ? this.props.collapseIcon : this.props.expandIcon}
                  onClick={(e) => this.props.onExpand(e)}
                />
              </Col>
            </Row>
            {this.state.isNotificationDataLoading ? (
              <Spinner animation="border" className="mt-4" role="status">
                <span className="visually-hidden" aria-label="Loading..."></span>
              </Spinner>
            ) : (
              <Row className="mt-3" noGutters>
                <Col className="text-left">
                  <Tabs
                    id="myNotificationsTabs"
                    onSelect={(e) => { }}
                    className={styles.myAGreementsTabs}
                    style={{ overflow: "auto" }}
                  >
                    <Tab
                      eventKey="1"
                      title={"Notifications (" + this.state.userNotifications.length + ")"}
                      className={this.props.isExpanded ? "mt-4" : ""}
                    >
                      {this.renderNotificationsTab()}
                    </Tab>
                    <Tab
                      eventKey="2"
                      title={"Archived (" + this.state.userArchivedNotifications.length + ")"}
                      className={this.props.isExpanded ? "mt-4" : ""}
                    >
                      {this.renderArchivedNotificationsTab()}
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
        <Modal
          show={this.state.showNotificationDetailsModal}
          onHide={() => this.setState({ showNotificationDetailsModal: false })}
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header className={globalStyles.modalHeader}>
            <Row className={globalStyles.modalHeaderRow}>
              <Col className="px-0">
                <Modal.Title className={globalStyles.modalTitleText}>
                  {this.state.notificationDataForModal ? this.state.notificationDataForModal.notificationSubject : ""}
                </Modal.Title>
              </Col>
              <Col>
                <CloseButton onClick={() => this.setState({ showNotificationDetailsModal: false })} />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body className={styles.modalBody + " p-4"} style={{ overflow: "auto" }}>
            <Row>
              <Col>
                <p className={" m-0 pt-1 pb-2"}>
                  {this.state.notificationDataForModal
                    ? new Date(this.state.notificationDataForModal.createdDateTimeUTC).toLocaleString()
                    : ""}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={" m-0 pt-1 pb-3"}>
                  {this.state.notificationDataForModal ? this.state.notificationDataForModal.notificationBody : ""}
                </p>
              </Col>
            </Row>
            <Row>
              <Col className={styles.buttonLinkColumn}>
                <Button variant="link" onClick={this.navigateToAgreementRecordPage}>
                  <p className={" m-0"}>
                    {this.state.notificationDataForModal
                      ? this.state.notificationDataForModal.linkedAgreementCommonName
                        ? "View Record page for " + this.state.notificationDataForModal.linkedAgreementCommonName
                        : "View Record page for agreement with id " +
                        this.state.notificationDataForModal.linkedAgreementUniqueReference ??
                        "No linked agreement found"
                      : "No linked agreement found"}
                  </p>
                </Button>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.setState({ showNotificationDetailsModal: false })}>
              <span>Close</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default NotificationWidget;
