import React from "react";
import "./horizontal-separator.css";

const HorizontalPipeSeparator = () => {
  return (
    <div className="my-3 mx-3 horizontalSeparator no-mobile-utilities"></div>
  );
};

export default HorizontalPipeSeparator;
