import React from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Image,
  Accordion,
  Spinner,
} from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import CustomForm from "../../../common/form";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { withRouter } from "react-router-dom";
import ChevronDown from "../../../../assets/chevron-down.svg";
import ChevronRight from "../../../../assets/chevron-right.svg";
import ReferenceFileAPI from "../../../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../../../api/AgreementEntities/AgreementAPI";
import WorkTaskAPI from "../../../../api/WorkTask/WorkTaskAPI";
import InventoryAPI from "../../../../api/Inventory/InventoryAPI";
import ConflictsAPI from "../../../../api/Conflicts/ConflictsAPI";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import $ from "jquery";
import "../../../../jquery-ui.css";
import datepickerFactory from "jquery-datepicker";
import UtilityFunctions from "../../../common/UtilityFunctions";
import searchIcon from "../../../../assets/search.svg";
import { components } from "react-select";
import EnterLossModal from "./EnterLossModal";
import CreateConflictModal from "../../../Conflicts/CreateConflictModal";
import WebMap from "../../../ESRIIntegration/SiteVisitMap";
import EnterTakeModal from "./EnterTakeModal";
import AdminWorkTaskModal from "../../../AdminWorkTask/AdminWorkTaskModal";
import NonInventoryLossModal from "./NonInventoryLossModal";
import AssociateResourceInventoryModal from "./AssociateResourceInventoryModal";
import SiteVisitParcelMap from "../../../ESRIIntegration/SiteVisitParcelMap";
import DiseaseMonitoringModal from "./DiseaseMonitoringModal";
import DiseaseShippingModal from "./DiseaseShippingModal";
import RabiesSamplesModal from "./RabiesSamplesModal";
import OtherSampleModal from "./OtherSampleModal";
import RoleAuthorizationAPI from "../../../../api/RoleAuthorization/RoleAuthorizationAPI";
import refreshIcon from "../../../../assets/Refresh-icon.svg";
import AviationGroundCrewingModal from "./AviationGroundCrewingModal";
import aircraftIcon from "../../../../assets/Aircraft-icon.svg";
import EnterSampleModal from "./EnterSampleModal";
import AgreementEntitiesAPI from "../../../../api/AgreementEntities/AgreementEntitiesAPI";
import TAWorkTaskModalWithTopics from "../../../TechnicalAssists/TAWorkTaskModal/TAWorkTaskModalWithTopics";
import ManageProjectsModal from "./ManageProjectsModal";
import ManageWorkPerformedModal from "./ManageWorkPerformedModal";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={searchIcon} alt="" />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

const workPerformedDataStyle = {
  color: "#313131",
  fontWeight: 400,
  fontSize: "12px",
  padding: "4.8px",
  verticalAlign: "middle",
  borderBottom: "1px solid #e8e8e8",
};

const WorkTaskTypes = {
  DirectWorkTask: 1,
  RecordLoss: 2,
  AdminWT: 3,
  RecordTA: 4,
  RecordSample: 5,
};

class PropertyVisitSummary extends CustomForm {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    const propertyData = this.props.history.location?.state?.propertyData;
    const countries = this.props.history.location?.state?.countries;
    const parcels = this.props.history.location?.state?.parcels;
    const agreementInfo = this.props.history.location?.state?.agreementInfo;

    let propertyAddress = "";
    if (propertyData?.additionalStreetAddresses?.length > 0) {
      propertyAddress = this.getPropertyAddress(
        currentUsaState.stateGuid,
        this.props.history.location.state.states,
        propertyData?.additionalStreetAddresses[0]
      );
    }

    const propertyType = this.props.history.location?.state?.isDetailsPage
      ? UtilityFunctions.getDisplayTextFromFieldObject(propertyData.propertyType)
      : propertyData?.propertyType;

    const isDetailsPage = this.props.history.location?.state?.isDetailsPage === true;

    this.state = {
      propertyVisitSummaryObj: null,
      isPMILoading: false,
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
      mostRecentPVS: null,
      previousPVS: null,
      ableToUsePreviousEffortRatio: false,
      validated: false,
      globalActionsDropdownDisabled: true,
      globalAddItemsDisabled: true,
      inventoryMapItems: [],
      refreshMapItems: [],
      highlightedMapPointMethod: {},
      data: {
        propertyData: propertyData,
        countries: countries,
        propertyCountryName: "",
        mapPoint: JSON.stringify([propertyData?.latitude?.toString(), propertyData?.longitude?.toString()]),
        propertyName: propertyData?.propertyName,
        propertyAddress: propertyAddress,
        parcels: parcels,
        parcelCount: propertyData?.parcelCount,
        propertyType: propertyType,
        agreementData: null,
        allowedMethods: [],
        methodSearchSelection: "",
        chosenMethod: null,
        methodQuantity: "",
        methodChosenUse: "",
        methodChosenUOM: "",
        validated: false,
        propertyMethodInventory: [],
        workPerformed: [],
        methodNickname: "",
        methodLongitude: "",
        methodLatitude: "",
        pvsHours: 0,
        pvsMinutes: 0,
        targets: "",
        protecting: "",
        resourceEffortList: [],
        geoRequired: false,
        labelRequired: false,
        agreementInfo: agreementInfo,
        selectedAircrafts: [],
        selectedProjects: [],
        previousSelectedProjects: [],
        methodWarehouseSelection: "",
      },
      fromEnterSample: false,
      updateMap: false,
      shouldRenderWorkPerformed: true,
      allMethodUses: [],
      allMethodUOMs: [],
      workTaskTypes: [],
      availableProjects: [],
      showManageProjectsModal: false,
      manageProjectsData: null,
      activeWorkTaskType: WorkTaskTypes.DirectWorkTask,
      performedBy: "",
      pvsDate: "",
      takesData: [],
      creationFieldsDisabled: false,
      personnelInfoClass: styles.staticPVSPersonnel,
      stickyCardHeaderStyle: styles.topCardNonStickyHeader,
      stickyCardTextStyle: styles.cardTitleText,
      stickyCardBodyStyle: "",
      checkedForConflicts: false,
      propertyConflicts: [],
      showCreateConflictModal: false,
      methodTargets: [],
      methodProtects: [],
      methodChosenTargets: [],
      methodChosenProtections: [],
      //showAllConflicts: false,
      showDiseaseMonitoringModal: false,
      diseaseMonitoringData: "",
      diseaseMonitoringAnimalSampleTable: [],
      showDiseaseShippingModal: false,
      showOtherSampleModal: false,
      otherSampleData: "",
      inProgressTakeData: "",
      errors: {},
      propertyMethodInventoryRowSelected: [],
      propertyMethodInventoryRowExpanded: [],
      propertyMethodInventoryRowNotExpandable: [],
      propertyMethodInventoryGlobalActions: [],
      propertyMethodInventorySubTableColumns: [
        {
          dataField: "deployedItemUniqueIdentifier",
          hidden: true,
        },
        {
          dataField: "methodName",
          text: "Method",
          formatter: (cell) => {
            return (
              <Row>
                <Col>{cell}</Col>
              </Row>
            );
          },
          align: "left",
          style: { color: "#222222", fontWeight: 500, fontSize: "12px", padding: "4.8px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "20%",
            };
          },
        },
        {
          dataField: "displayQuantity",
          text: "Quantity",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "5%",
            };
          },
        },
        {
          dataField: "deployedWithUnitOfMeasureEnumId",
          text: "UOM",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          formatter: (cell) => {
            let UOM = this.state.allMethodUOMs.find((UOM) => UOM.id === cell);
            return UOM.displayText;
          },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "5%",
            };
          },
        },
        {
          dataField: "status",
          text: "Status",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "5%",
            };
          },
        },
        {
          dataField: "targets",
          text: "Target",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "protects",
          text: "Protecting",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "latitude",
          text: "Latitude",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          formatter: (cell) => {
            if (!cell) {
              return "";
            } else {
              return cell;
            }
          },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "longitude",
          text: "Longitude",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          formatter: (cell) => {
            if (!cell) {
              return "";
            } else {
              return cell;
            }
          },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "10%",
            };
          },
        },
        {
          dataField: "checked",
          text: "Cooperator/Volunteer Checked",
          align: "left",
          style: { fontSize: "12px", verticalAlign: "middle" },
          formatter: (cell, row) => {
            return (
              <Form.Check onChange={(e) => this.selectCooperatorChecked(e, row)} checked={row?.cooperatorChecked} />
            );
          },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "5%",
            };
          },
        },
        {
          dataField: "actions",
          text: "",
          formatter: (cell, row, rowIndex) => {
            if ((row.latitude && row.longitude) || row.displayQuantity < 2) {
              return (
                <Row>
                  <Col>
                    <DropdownButton
                      title="Set actions"
                      id="dropdown-method-sub-actions"
                      variant="link"
                      className={styles.setActionsDropdown}
                    >
                      {row.configureActions?.map((item) => (
                        <Dropdown.Item
                          className={styles.methodSubUsesDropdownItem}
                          key={item.configurationActionUniqueIdentifier + rowIndex}
                          eventKey="methodActionChoose"
                          onSelect={() =>
                            item.resultingInventoryStatus
                              ? this.handleGeolocatedAddItems(row, item)
                              : this.handleRemoveItems(row, item)
                          }
                        >
                          {item.configurationAction}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </Col>
                </Row>
              );
            } else {
              let setObjects = [];
              return (
                <Row>
                  <Col>
                    <DropdownButton
                      id="dropdown-method-actions"
                      title="Set actions"
                      variant="link"
                      className={styles.setActionsDropdown}
                      renderMenuOnMount={true}
                      hidden={row.methodLongitude && row.methodLatitude}
                    >
                      {row.configureActions?.map((item, index) => (
                        <Row xs={2} noGutters="true" key={index}>
                          <Col align="center">
                            <Form.Control
                              required
                              id={item.configurationActionUniqueIdentifier}
                              ref={this.inputRef}
                              key={item.configurationActionUniqueIdentifier + rowIndex + "textFieldkey"}
                              defaultValue="0"
                              onChange={(e) => {
                                let setObject = {
                                  configurationActionUniqueIdentifier: item.configurationActionUniqueIdentifier,
                                  configureAction: item,
                                  setQuantity: parseInt(e.target.value),
                                  formRef: item.configurationActionUniqueIdentifier + "textFieldRef",
                                };
                                let needToAdd = true;
                                for (let i = 0; i < setObjects.length; i++) {
                                  if (
                                    setObjects[i].configurationActionUniqueIdentifier ===
                                    setObject.configurationActionUniqueIdentifier ||
                                    setObjects[i].configureAction === setObject.configureAction
                                  ) {
                                    setObjects[i] = setObject;
                                    needToAdd = false;
                                    break;
                                  }
                                }
                                if (needToAdd) {
                                  setObjects.push(setObject);
                                }
                              }}
                              as="input"
                              type="number"
                              className={styles.useControl}
                              min="0"
                              max={row.displayQuantity}
                            />
                          </Col>
                          <Col align="left">
                            <Dropdown.Item
                              disabled
                              eventKey={item.configurationActionUniqueIdentifier + item.configurationAction}
                              key={
                                item.configurationActionUniqueIdentifier +
                                item.configurationAction +
                                row.methodUniqueIdentifier
                              }
                              className="text-left"
                            >
                              <p className={styles.useDisplayText}>{item.configurationAction}</p>
                            </Dropdown.Item>
                          </Col>
                        </Row>
                      ))}
                      <Row className={styles.methodActionRow}>
                        <Col align="center">
                          <Dropdown.Item
                            key={row.methodUniqueIdentifier + "addButton"}
                            active={false}
                            onClick={() => {
                              this.handleNonGeolocatedAddItems(row, setObjects);
                            }}
                          >
                            <Button
                              variant="outline-primary"
                              key={row.methodUniqueIdentifier + "buttonAdd"}
                              disabled={false}
                              id={styles.addItemsInDropdownButton}
                            >
                              Confirm
                            </Button>
                          </Dropdown.Item>
                        </Col>
                      </Row>
                    </DropdownButton>
                  </Col>
                </Row>
              );
            }
          },
          align: "right",
          style: { fontSize: "12px", verticalAlign: "middle" },
          headerStyle: () => {
            return {
              borderBottom: "1px solid #008767",
              backgroundColor: "#F5F5F5",
              fontSize: "12px",
              textAlign: "left",
              width: "20%",
            };
          },
        },
      ],
      protectedResourcesDataColumns: [
        { dataField: "resourceTableId", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      cooperatorTAWorkTaskColumns: [
        { dataField: "cooperatorTechnicalAssistWorkTaskUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      adminWorkTaskDataColumns: [
        { dataField: "adminWorkTaskUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      workPerformedDataColumns: [
        { dataField: "workPerformedUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      workPerformedTakesColumns: [
        { dataField: "takeUniqueIdentifier", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      protectedResourcesData: [],
      cooperatorTAWorkTaskData: [],
      adminWorkTaskTableData: [],
      isTAWorkTaskDataLoading: false,
      isAdminWorkTaskDataLoading: false,
      currentResourceRecord: "",
      showLossModal: false,
      showEnterTakeModal: false,
      enterTakeData: { workItem: "" },
      adminWorkTaskData: "",
      technicalAssistWorkTaskData: "",
      showAdminWorkTaskModal: false,
      showTAWorkTaskModal: false,
      showNonInventoryLossModal: false,
      propertyAddress1: "MD",
      showAssociateResourceInventoryModal: false,
      resourcePropertyAssociationData: { lossUom: "", selectedResource: "", unitValue: "" },
      showRabiesSampleModal: false,
      rabiesSampleData: { rabiesSamplingQty: "" },
      isAviationCrewMethodSelected: false,
      showAviationGroundCrewModal: false,
      showEnterSampleModal: false,
      isDetailsPage,
      isPageEditable: !isDetailsPage,
      isDataLoading: true,
      dataLoadingText: "",
      displayResourceEffortWarning: false,
      states: this.props.history.location?.state?.states ? this.props.history.location.state.states : [],
      countries: this.props.history.location.state?.countries ? this.props.history.location.state.countries : [],
      availableWarehouses: [],
    };
  }

  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
    let { data, availableWarehouses } = this.state;

    if (this.props.history.location?.state?.isDetailsPage) {
      data.agreementInfo = await this.getAgreementInfoData();
      await this.updatePvsDataObject(data);
    } else {
      await this.findAndPopulatePVS(data.propertyData?.propertyUniqueIdentifier);
    }

    if (data.agreementData === null) {
      await this.setAgreementData(data);
    }
    await this.updateAllowedMethodsOnProperty(data);

    this.jqueryInit();

    if (this.state.data.countries) {
      this.state.data.propertyCountryName = this.state.data.countries.find(
        (country) => country.id === this.state.data.propertyData.countryUniqueIdentifier
      )?.name;
    }
    if (this.props.history.location.state?.states) {
      let stateObj = this.props.history.location.state?.states?.find(
        (state) => state.id === data.propertyData.state || state.stateUniqueIdentifier === data.propertyData.state
      );
      data.propertyStateName = stateObj?.name || stateObj?.state_name;
    }

    if (data.propertyData?.state) {
      availableWarehouses = await this.getWarehousesData(data.propertyData.state);
      if (availableWarehouses?.length === 1) {
        data.methodWarehouseSelection = availableWarehouses[0].warehouseUniqueIdentifier;
      }
    }

    this.checkForEnablePreviousEffortRatio(this.state.mostRecentPVS);
    await this.populateResourceEffort();
    this.setState(
      {
        data,
        availableWarehouses,
        protectedResourcesDataColumns: this.getResourceDataColumns(),
        cooperatorTAWorkTaskColumns: this.getCooperatorTAWorkTaskColumns(),
        adminWorkTaskDataColumns: this.getAdminWorkTaskDataColumns(),
        protectedResourcesData: this.getProtectedResourcesData(),
        workPerformedDataColumns: this.getWorkPerformedDataColumns(),
        workPerformedTakesColumns: this.getWorkPerformedTakesColumns(),
        availableProjects: await this.getProjectsFromAPI(this.state.currentUsaState?.stateGuid),
        workTaskTypes: await this.getWorkTaskTypes(),
        allMethodUses: await this.getAllMethodUses(),
        allMethodUOMs: await this.getAllMethodUOMs(),
        isDataLoading: false,
      },
      async () => {
        await this.refreshPropertyMethodInventory();
      }
    );
  }

  async setAgreementData(data) {
    let getAgreementResult = await AgreementAPI.GetAgreementsByProperty(data.propertyData?.propertyUniqueIdentifier);
    if (getAgreementResult?.successful) {
      let activeAgreement = getAgreementResult.data.find((agreement) => agreement.agreementStatus.id === 3);
      if (!activeAgreement) {
        let pendingAgreement = getAgreementResult.data.find((agreement) => agreement.agreementStatus.id === 2);
        if (pendingAgreement) {
          data.agreementData = await this.getFullAgreementObject(pendingAgreement.agreementUniqueIdentifier);
        } else {
          let expiredAgreement = getAgreementResult.data.find((agreement) => agreement.agreementStatus.id === 4);
          if (expiredAgreement) {
            data.agreementData = await this.getFullAgreementObject(expiredAgreement.agreementUniqueIdentifier);
            toast.warning(
              "The agreement on this property is expired, Expiration Date: " +
              UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(
                data?.agreementData?.expirationDate
              ).toDateString()
            );
          }
        }
      } else {
        data.agreementData = await this.getFullAgreementObject(activeAgreement.agreementUniqueIdentifier);
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve agreement data for site visit",
        apiName: "GetAgreementsByProperty",
        responseUnsuccessful: getAgreementResult?.unsuccessful,
        responseMessage: getAgreementResult?.message,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.activeWorkTaskType !== this.state.activeWorkTaskType &&
      this.state.activeWorkTaskType === WorkTaskTypes.RecordTA &&
      this.state.propertyVisitSummaryObj?.propertyVisitedUniqueReference
    ) {
      await this.updateTAWorkTaskData();
    }
    if (
      prevState.activeWorkTaskType !== this.state.activeWorkTaskType &&
      this.state.activeWorkTaskType === WorkTaskTypes.AdminWT &&
      this.state.propertyVisitSummaryObj?.propertyVisitedUniqueReference
    ) {
      await this.updateAdminWorkTaskData();
    }
  }

  async getWarehousesData(stateId) {
    let warehousesData = [];
    if (this.props.currentUserProfile?.wsUserProfileUniqueIdentifier) {
      const getWarehousesResponse = await InventoryAPI.GetMyWarehousesByState(
        this.props.currentUserProfile.wsUserProfileUniqueIdentifier,
        stateId
      );
      if (getWarehousesResponse?.successful) {
        warehousesData = getWarehousesResponse.data.results.filter((w) => w.isActive) ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve warehouses in state",
          apiName: "GetMyWarehousesByState",
          responseUnsuccessful: getWarehousesResponse?.unsuccessful,
          responseMessage: getWarehousesResponse?.message,
        });
      }
    }
    return warehousesData;
  }

  async updateTAWorkTaskData() {
    this.setState({ isTAWorkTaskDataLoading: true });
    if (this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier) {
      const cooperatorTAOnSiteVisitResponse = await WorkTaskAPI.GetCoopWorkTasksOnPropertyVisitSummary(
        this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier
      );
      if (cooperatorTAOnSiteVisitResponse?.successful) {
        this.setState({
          cooperatorTAWorkTaskData: cooperatorTAOnSiteVisitResponse.data,
          isTAWorkTaskDataLoading: false,
        });
      } else {
        this.setState({ isTAWorkTaskDataLoading: false });
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve TA Work Tasks for this Site Visit",
          apiName: "GetCoopWorkTasksOnPropertyVisitSummary",
          responseUnsuccessful: cooperatorTAOnSiteVisitResponse?.unsuccessful,
          responseMessage: cooperatorTAOnSiteVisitResponse?.message,
        });
      }
    }
  }

  async updateAdminWorkTaskData() {
    this.setState({ isAdminWorkTaskDataLoading: true });
    const adminWTOnSiteVisitResponse = await WorkTaskAPI.GetAdminWorkTasksOnPropertyVisitSummary(
      this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier
    );
    if (adminWTOnSiteVisitResponse?.successful) {
      this.setState({
        adminWorkTaskTableData: adminWTOnSiteVisitResponse.data,
        isAdminWorkTaskDataLoading: false,
      });
    } else {
      this.setState({ isTAWorkTaskDataLoading: false });
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve Admin Work Tasks for this Site Visit",
        apiName: "adminWTOnSiteVisitResponse",
        responseUnsuccessful: adminWTOnSiteVisitResponse?.unsuccessful,
        responseMessage: adminWTOnSiteVisitResponse?.message,
      });
    }
  }

  async getProjectsFromAPI(stateGuid) {
    let projectsData = [];
    const projectsDataResponse = await AgreementEntitiesAPI.GetMyAssociatedProjectsForState(stateGuid);
    if (projectsDataResponse?.successful) {
      projectsData = projectsDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve user's associated projects",
        apiName: "GetMyAssociatedProjectsForState",
        responseUnsuccessful: projectsDataResponse?.unsuccessful,
        responseMessage: projectsDataResponse?.message,
      });
    }
    let projectsArray = [];
    projectsData.forEach((project) => {
      let startDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.startDate);
      let endDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.endDate);
      let currentDate = new Date();
      if (currentDate >= startDate && currentDate < endDate) {
        project.value = project.projectUniqueIdentifier;
        project.label = project.projectName;
        projectsArray.push(project);
      }
    });
    return projectsArray;
  }

  async getWorkTaskTypes() {
    if (this.state.workTaskTypes.length === 0) {
      const typesGetCall = await WorkTaskAPI.GetAllWorkTaskTypes();
      if (typesGetCall?.successful) {
        return typesGetCall.data;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve all work task types",
          apiName: "GetAllWorkTaskTypes",
          responseUnsuccessful: typesGetCall?.unsuccessful,
          responseMessage: typesGetCall?.message,
        });
        return [];
      }
    }
  }

  async getAllMethodUses() {
    if (this.state.allMethodUses.length === 0) {
      const usesGetCall = await ReferenceFileAPI.GetAllMethodUses();
      if (usesGetCall?.successful) {
        return usesGetCall.data;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve all method uses",
          apiName: "GetAllMethodUses",
          responseUnsuccessful: usesGetCall?.unsuccessful,
          responseMessage: usesGetCall?.message,
        });
        return [];
      }
    }
  }

  async getAllMethodUOMs() {
    if (this.state.allMethodUOMs.length === 0) {
      const uomsGetCall = await ReferenceFileAPI.GetAllMethodUOMs();
      if (uomsGetCall?.successful) {
        return uomsGetCall.data;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve all method units of measure",
          apiName: "GetAllMethodUOMs",
          responseUnsuccessful: uomsGetCall?.unsuccessful,
          responseMessage: uomsGetCall?.message,
        });
        return [];
      }
    }
  }

  async getAgreementInfoData() {
    const siteVisitData = this.props.history.location?.state?.siteVisitData;
    let agreementInfoData = {};
    if (siteVisitData?.propertyVisitedUniqueReference) {
      const agreementSearchResponse = await AgreementAPI.GetAgreementsByProperty(
        siteVisitData?.propertyVisitedUniqueReference
      );
      if (agreementSearchResponse?.successful) {
        const allFoundAgreements = agreementSearchResponse.data;
        let displayedAgreementIndex = this.findAgreementToDisplay(allFoundAgreements);
        if (displayedAgreementIndex !== -1) {
          agreementInfoData = allFoundAgreements.splice(displayedAgreementIndex, 1)[0];
          let cooperatorNameCall = await AgreementEntitiesAPI.GetContact(
            agreementInfoData?.cooperator?.contactUniqueReference
          );
          if (!cooperatorNameCall?.successful) {
            ErrorHandler.handleApiErrorMessage({
              errorContextMessage: "Unable to retrieve contact data related to Site Visit agreement",
              apiName: "GetContact",
              responseUnsuccessful: cooperatorNameCall?.unsuccessful,
              responseMessage: cooperatorNameCall?.message,
            });
          }
          const activeAgreementData = await AgreementAPI.GetAgreement(agreementInfoData.agreementUniqueIdentifier);
          if (activeAgreementData?.successful) {
            agreementInfoData.additionalContacts = await this.getAgreementAssociatedContactsData(
              activeAgreementData.data
            );
            agreementInfoData.specialInstructions = activeAgreementData.data.specialInstructions;
            agreementInfoData.agreementProperty = activeAgreementData.data?.agreementProperties?.find(
              (property) => property.propertyUniqueReference === this.state.propertyUniqueIdentifier
            );
          } else {
            ErrorHandler.handleApiErrorMessage({
              errorContextMessage: "Unable to retrieve active agreement data for this Site Visit",
              apiName: "GetAgreement",
              responseUnsuccessful: activeAgreementData?.unsuccessful,
              responseMessage: activeAgreementData?.message,
            });
          }
          let cooperatorFullName = "";
          let cooperatorFullAddress = "";
          if (cooperatorNameCall?.data) {
            cooperatorFullName =
              cooperatorNameCall.data.firstName +
              " " +
              cooperatorNameCall.data.middleName +
              " " +
              cooperatorNameCall.data.lastName;
          }
          if (cooperatorNameCall?.data?.primaryMailingAddress) {
            let addressObj = cooperatorNameCall.data.primaryMailingAddress;
            let state = await ReferenceFileAPI.GetState(addressObj.mailingStateUniqueId);
            let stateCode = state.state_code;
            cooperatorFullAddress =
              addressObj.mailingStreet +
              ", " +
              addressObj.mailingCity +
              ", " +
              stateCode +
              ", " +
              addressObj.mailingZip;
          }
          if (agreementInfoData.cooperatorName) {
            agreementInfoData.cooperatorName.cooperatorFullName = cooperatorFullName;
            agreementInfoData.cooperatorName.cooperatorFullAddress = cooperatorFullAddress;
            agreementInfoData.cooperatorName.phoneNumbers = cooperatorNameCall?.data?.phoneNumbers;
            agreementInfoData.cooperatorName.emailAddress = cooperatorNameCall?.data?.emailAddress;
          }
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve agreements for this property",
          apiName: "GetAgreementsByProperty",
          responseUnsuccessful: agreementSearchResponse?.unsuccessful,
          responseMessage: agreementSearchResponse?.message,
        });
      }
    }

    return agreementInfoData;
  }

  findAgreementToDisplay(agreements) {
    let result = -1;
    for (var i = 3; i > 0; i--) {
      let agreementObj = agreements?.find((agreement) => parseInt(agreement?.agreementStatus?.id) === i);
      if (agreementObj) {
        result = agreements.indexOf(agreementObj);
        break;
      }
    }
    return result;
  }

  async getAgreementAssociatedContactsData(agreementData) {
    let associatedAgreementContactsData = [];

    for (const contact of agreementData?.additionalContacts) {
      let contactData = await this.getContactData(contact.contactUniqueReference);
      if (contactData) {
        contactData.contactRole = UtilityFunctions.getDisplayTextFromFieldObject(contact.contactRoleOnAgreement);
        associatedAgreementContactsData.push(contactData);
      }
    }

    return associatedAgreementContactsData;
  }

  async getContactData(contactUniqueId) {
    const contactDataResponse = await AgreementEntitiesAPI.GetContact(contactUniqueId);
    if (!contactDataResponse?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve contact information",
        apiName: "GetContact",
        responseUnsuccessful: contactDataResponse?.unsuccessful,
        responseMessage: contactDataResponse?.message,
      });
      return null;
    }

    let contactData = contactDataResponse.data;
    contactData.primaryPhoneNumber = UtilityFunctions.getPrimaryPhoneNumber(contactData.phoneNumbers);
    contactData.fullName = UtilityFunctions.getContactNameFromDataObject(contactData);
    contactData.fullMailingAddress = this.getPrimaryAddress(contactData.primaryMailingAddress, "contact");
    return contactData;
  }

  getPrimaryAddress(providedAddress, entityType) {
    let primaryAddress = "";

    switch (entityType) {
      case "contact":
        primaryAddress = this.getContactPrimaryAddress(providedAddress, primaryAddress);
        break;
      case "property":
        UtilityFunctions.getPropertyPrimaryAddress(providedAddress);
        break;
      default:
        break;
    }

    return primaryAddress;
  }

  getContactPrimaryAddress(providedAddress) {
    let primaryAddress = "";
    if (providedAddress) {
      const { mailingStreet, mailingCity, mailingStateUniqueId, mailingZip, mailingCountryUniqueId } = providedAddress;
      primaryAddress = `${mailingStreet ? mailingStreet + ", " : ""}${mailingCity ? mailingCity + ", " : ""}${mailingStateUniqueId ? this.getStateNameFromId(mailingStateUniqueId) + ", " : ""
        }${mailingZip ? mailingZip + ", " : ""}${mailingCountryUniqueId ? this.getCountryNameFromId(mailingCountryUniqueId) : ""
        }`;
    }

    return primaryAddress;
  }

  getStateNameFromId(stateId) {
    const { states } = this.state;
    const currentState = states.find((state) => state.stateUniqueIdentifier === stateId);
    return currentState ? currentState.state_name : "";
  }

  getCountryNameFromId(countryId) {
    const { countries } = this.state;
    const country = countries.find((country) => country.id === countryId);
    return country ? country.name : "";
  }

  async updateAllowedMethodsOnProperty(data) {
    const aviationGroundCrewMethod = await this.getAviationGroundCrewMethod();
    if (aviationGroundCrewMethod) {
      data.allowedMethods = [aviationGroundCrewMethod];
    }

    if (data.agreementData) {
      let agreementProperty = data.agreementData.agreementProperties.find(
        (property) => property.propertyUniqueReference === data.propertyData.propertyUniqueIdentifier
      );
      data.allowedMethods = [...data.allowedMethods, ...agreementProperty?.allowedMethods];
    }
  }

  async getAviationGroundCrewMethod() {
    let aviationGrounfCrewMethod;
    if (this.state.currentUsaState?.stateGuid) {
      const getAviationGroundMethodResponse = await ReferenceFileAPI.FilterMethods(
        this.state.currentUsaState.stateGuid,
        {
          textToSearchFor: "Aviation Ground Crewing",
          pageSize: 10,
          pageNumber: 1,
        }
      );
      if (getAviationGroundMethodResponse?.successful && getAviationGroundMethodResponse.data?.results?.length > 0) {
        const methodObject = getAviationGroundMethodResponse.data.results[0];
        aviationGrounfCrewMethod = {
          methodName: methodObject?.methodName,
          methodUniqueReference: methodObject?.methodUniqueIdentifier,
        };
      }
    }

    return aviationGrounfCrewMethod;
  }

  getResourceDataColumns() {
    return [
      {
        dataField: "resourceTableId",
        hidden: true,
      },
      {
        dataField: "valuationYear",
        text: "Year",
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "resourceName",
        text: "Resource",
        headerStyle: () => {
          return { width: "10%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "quantity",
        text: "Quantity",
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "valuationUnitOfMeasure",
        text: "UOMs",
        formatter: (cell, row) => {
          return UtilityFunctions.getDisplayTextFromFieldObject(cell);
        },
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        editable: false,
      },
      {
        dataField: "valuePerUnitOfMeasure",
        text: "Unit Value",
        formatter: (cell, row) => {
          return UtilityFunctions.getValueAsCurrency(row.valuePerUnitOfMeasure);
        },
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "totalValuation",
        text: "Total Value",
        formatter: (cell, row) => {
          return UtilityFunctions.getValueAsCurrency(row.totalValuation);
        },
        headerAlign: "left",
        align: "left",
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "property",
        text: "Property",
        headerStyle: () => {
          return { width: "10%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "parcels",
        text: "Parcel",
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "enterLossButton",
        text: "",
        formatter: (cell, row) => {
          return (
            <Button variant="link" onClick={() => this.enterLossModal(row)} className="text-right py-0 my-0">
              <span className={styles.tableLinksText}>Enter Loss</span>
            </Button>
          );
        },
        headerStyle: () => {
          return { width: "35%", borderBottom: "0.8px solid #008767" };
        },
        headerAlign: "right",
        align: "right",
      },
    ];
  }

  getAdminWorkTaskDataColumns() {
    return [
      {
        dataField: "adminWorkTaskUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "adminActivities",
        text: "Activities",
        headerAlign: "left",
        align: "left",
        formatter: (cell, row) => {
          return cell?.length > 0 ? cell.map((activity) => " " + activity.adminActivityName).toString() : "None";
        },
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767", width: "45%" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "timeSpent",
        text: "Time Spent",
        formatter: (cell, row) => {
          return row?.hoursWorked !== 0 || row.minutesWorked !== 0
            ? (row.hoursWorked === 1 ? row.hoursWorked + " hour " : row.hoursWorked + " hours ") +
            row.minutesWorked +
            " minutes"
            : "N/A";
        },
        headerStyle: () => {
          return { width: "10%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "projects",
        text: "Projects",
        formatter: (cell, row) => {
          return cell?.length > 0 ? cell.map((project) => " " + project.projectsName).toString() : "None";
        },
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "comments",
        text: "Comments",
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
    ];
  }

  getCooperatorTAWorkTaskColumns() {
    return [
      {
        dataField: "cooperatorTechnicalAssistWorkTaskUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "workTaskActivities",
        text: "Activities",
        headerAlign: "left",
        align: "left",
        formatter: (cell, row) => {
          return cell?.length > 0 ? cell.map((activity) => " " + activity.activityName).toString() : "None";
        },
        headerStyle: () => {
          return { fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
      },
      {
        dataField: "timeSpent",
        text: "Time Spent",
        formatter: (cell, row) => {
          return row?.hoursWorked !== 0 || row.minutesWorked !== 0
            ? (row.hoursWorked === 1 ? row.hoursWorked + " hour " : row.hoursWorked + " hours ") +
            row.minutesWorked +
            " minutes"
            : "N/A";
        },
        headerStyle: () => {
          return { width: "10%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "projectsUniqueReferences",
        text: "Projects",
        formatter: (cell, row) => {
          return cell?.length > 0 ? cell.map((project) => " " + project.projectsName).toString() : "None";
        },
        headerStyle: () => {
          return { width: "10%", fontSize: "12px", borderBottom: "0.8px solid #008767" };
        },
        style: { fontSize: "12px" },
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "removeButton",
        text: "",
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              onClick={() => {
                this.props.history.push({
                  pathname: "/taworktask/details",
                  state: {
                    states: this.state.states,
                    countries: this.state.countries,
                    entityType: "individualCooperator",
                    entityData: row,
                  },
                });
              }}
              className="text-right py-0 my-0"
            >
              <span className={styles.tableLinksText}>View</span>
            </Button>
          );
        },
        headerStyle: () => {
          return { width: "35%", borderBottom: "0.8px solid #008767" };
        },
        headerAlign: "right",
        align: "right",
      },
    ];
  }

  getPropertyMethodInventorySubTableColumns() {
    return [
      {
        dataField: "methodUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "methodName",
        text: "",
        formatter: (cell, row) => {
          return (
            <Row noGutters="true">
              <Col>
                <p className={styles.methodNameCells}>{cell + " "}</p>
                <p className="quantity-measurement" style={{ marginBottom: "0", textIndent: "1em" }}>
                  {"(" + row.methodQuantity + " " + row.methodUOM + ")"}
                </p>
              </Col>
            </Row>
          );
        },
        align: "left",
        style: {
          color: "#222222",
          fontWeight: 500,
          fontSize: "14px",
          padding: "4.8px",
          verticalAlign: "middle",
          borderBottom: "1px solid #dee2e6",
        },
        headerFormatter: () => {
          return !this.state.isDetailsPage || this.state.isPageEditable ? (
            <Row noGutters="false">
              <Col xl={{ span: 2 }} align="left">
                <DropdownButton
                  id="dropdown-global-method-actions"
                  title="Enter Bulk Actions"
                  variant="link"
                  drop="down"
                  disabled={this.state.globalActionsDropdownDisabled}
                  className={styles.setGlobalActionsDropdown}
                >
                  {this.state.propertyMethodInventoryGlobalActions?.map((item) => (
                    <Dropdown.Item eventKey={"globalAction" + item} onSelect={() => this.handleGlobalAddItems(item)}>
                      {item.configurationAction}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
              <Col xl={{ span: 1 }} align="left">
                <Button
                  variant="outline-primary"
                  disabled={this.state.globalAddItemsDisabled}
                  className={styles.globalAddItemsButton}
                >
                  Add Items
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          );
        },
        headerStyle: () => {
          return { borderTop: "hidden", borderBottom: "hidden" };
        },
      },
    ];
  }

  getWorkPerformedDataColumns() {
    const workPerformedTableHeaderStyle = {
      borderTop: "hidden",
      borderBottom: "2px solid #008767",
      fontSize: "12px",
      fontWeight: 600,
      verticalAlign: "middle",
    };
    return [
      {
        dataField: "workPerformedUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "methodName",
        text: "Method",
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "methodUom",
        text: "UOM",
        formatter: (cell, row) => {
          return UtilityFunctions.getDisplayTextFromFieldObject(
            this.state.allMethodUOMs.find((uom) => uom.id === row.methodAllowedUOMId)
          );
        },
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "5%" },
      },
      {
        dataField: "methodQtyAffected",
        text: "Action Qty",
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "5%" },
      },
      {
        dataField: "actionTaken",
        text: "Action Taken",
        formatter: (cell, row) => {
          let action = row.workPerformedType?.id === 3
            ? row.configurationActionName
            : this.state.allMethodUses?.length > 0
              ? UtilityFunctions.getDisplayTextFromFieldObject(
                this.state.allMethodUses.find((u) => u.id === row.methodAllowedUseEnumId)
              )
              : "";
          if (action.includes("Set -"))
            action = "Set";
          return action;
        },
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "5%" },
      },
      {
        dataField: "inventoryItemLatitude",
        text: "Latitude",
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "5%" },
      },
      {
        dataField: "inventoryItemLongitude",
        text: "Longitude",
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "5%" },
      },
      {
        dataField: "targets",
        text: "Target(s)",
        formatter: (cell, row) => {
          let targets = "";
          if (row.workPerformedSpecies?.length > 0) {
            targets = row.workPerformedSpecies.map((s) => s.speciesName).join(", ");
          }
          if (targets.length > 20) {
            targets = targets.substring(0, 19) + "...";
          }
          return targets;
        },
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "10%" },
      },
      {
        dataField: "protectedResources",
        text: "Protecting",
        formatter: (cell, row) => {
          let protectedResources = "";
          if (row.workPerformedResources?.length > 0) {
            protectedResources = row.workPerformedResources.map((r) => r.resourceName).join(", ");
          }
          if (protectedResources.length > 20) {
            protectedResources = protectedResources.substring(0, 19) + "...";
          }
          return protectedResources;
        },
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "10%" },
      },
      {
        dataField: "numberOfProjects",
        text: "Number of Projects",
        formatter: (cell, row) => {
          return row?.projects?.length ?? "N/A";
        },
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "5%" },
      },
      {
        dataField: "warehouseName",
        text: "Warehouse",
        formatter: (cell, row) => {
          return cell ? cell : "N/A";
        },
        align: "left",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "10%" },
      },
      {
        dataField: "enterTakeColumn",
        text: "Enter Take Column",
        headerFormatter: () => {
          return "";
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return !formatExtraData?.isDetailsPage || formatExtraData?.isPageEditable ? (
            <DropdownButton
              id="workPerformed-actions"
              title="Actions"
              variant="link"
              className={styles.actionDropdownButton}
            >
              <Dropdown.Item eventKey="manageProjects" onSelect={() => this.manageProjectsForWorkPerformed(row)}>
                Manage Projects
              </Dropdown.Item>
              <Dropdown.Item eventKey="manageWorkPerformed" onSelect={() => this.manageWorkPerformed(row)}>
                Manage Work Performed
              </Dropdown.Item>
              <Dropdown.Item eventKey="enterTake" onSelect={() => this.enterTake(row)}>
                Enter/Attach Take
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="undoWorkPerformed"
                onSelect={() => this.removeWorkPerformed(row.workPerformedUniqueIdentifier)}
              >
                Undo
              </Dropdown.Item>
            </DropdownButton>
          ) : (
            ""
          );
        },
        formatExtraData: this.state,
        align: "right",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "20%" },
      },
    ];
  }

  getWorkPerformedTakesColumns() {
    const workPerformedTableHeaderStyle = {
      borderTop: "hidden",
      borderBottom: "1px solid #008767",
      fontSize: "12px",
      fontWeight: 600,
      verticalAlign: "middle",
      paddingLeft: "0px",
    };
    return [
      {
        dataField: "takeUniqueIdentifier",
        hidden: true,
      },
      {
        dataField: "speciesName",
        text: "Damage Agent",
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "tags",
        text: "Tags",
        formatter: (cell, row) => {
          let tags = "";
          if (row.tags?.length > 0) {
            tags = row.tags.join(", ");
          }
          if (tags.length > 20) {
            tags = tags.substring(0, 19) + "...";
          }
          return tags;
        },
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "unitOfMeasure",
        text: "UOM",
        formatter: (cell, row) => {
          return UtilityFunctions.getDisplayTextFromFieldObject(cell);
        },
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "fateUniqueReference",
        text: "Fate",
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "wasIntentional",
        text: "Intentional",
        formatter: (cell, row) => {
          return cell === true ? "Yes" : "No";
        },
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "permitName",
        text: "Permit",
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "latitude",
        text: "Latitude",
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "longitude",
        text: "Longitude",
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "numberOfAnimalsSampled",
        text: "Number Of Animals Sampled",
        align: "left",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: workPerformedTableHeaderStyle,
      },
      {
        dataField: "RabiesDiseaseColumn",
        text: "Enter Rabies/Disease Column",
        headerFormatter: () => {
          return "";
        },
        formatter: (cell, row) => {
          return "";
        },
        align: "right",
        style: { ...workPerformedDataStyle, paddingLeft: "0px" },
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "25%" },
      },
      {
        dataField: "editTakeColumn",
        text: "Edit Take Column",
        headerFormatter: () => {
          return "";
        },
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return !formatExtraData?.isDetailsPage || formatExtraData?.isPageEditable ? (
            <span>
              <Button variant="link" className="px-0 text-right" onClick={() => this.editTake(row)}>
                <span className={styles.actionButtonsText}>Edit</span>
              </Button>
              <span className={styles.horizontalLinkSeparator}>|</span>
              <Button
                variant="link"
                className={styles.workPerformedUndoLink}
                onClick={() => this.deleteTake(row.takeUniqueIdentifier)}
              >
                <span className={styles.actionButtonsText}>Delete</span>
              </Button>
            </span>
          ) : (
            ""
          );
        },
        formatExtraData: this.state,
        align: "right",
        style: workPerformedDataStyle,
        headerAlign: "left",
        headerStyle: { ...workPerformedTableHeaderStyle, width: "20%" },
      },
    ];
  }

  getPropertyAddress(stateGuid, usaStates, address) {
    let stateName = usaStates?.find((state) => state.id === stateGuid)?.name;
    const propertyAddress = address.streetAddress.concat(", ", address.city, ", ", stateName, ", ", address.zipCode);

    return propertyAddress;
  }

  getProtectedResourcesData() {
    let protectedResources = [];
    let agreementProperties =
      this.state.data?.agreementData?.agreementProperties?.length > 0
        ? this.state.data.agreementData.agreementProperties
        : [];
    agreementProperties.map((property) => {
      property.agreementResources
        .filter((agreementResource) => !agreementResource.isHistorical)
        .map((resource) => {
          let resourceIsAlreadyListed = protectedResources.find(
            (r) =>
              r.resourceTableId === resource.resourceUniqueReference &&
              r.valuationYear === resource.valuationYear &&
              r.resourceName === resource.resourceName &&
              r.quantity === resource.quantity &&
              r.valuationUnitOfMeasure?.name === resource.valuationUnitOfMeasure?.name &&
              r.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure &&
              r.totalValuation === resource.totalValuation &&
              r.property === this.state.data.propertyData?.propertyName
          );
          if (!resourceIsAlreadyListed) {
            protectedResources.push({
              resourceTableId: resource.resourceUniqueReference,
              valuationYear: resource.valuationYear,
              resourceName: resource.resourceName,
              quantity: resource.quantity,
              valuationUnitOfMeasure: resource.valuationUnitOfMeasure,
              valuePerUnitOfMeasure: resource.valuePerUnitOfMeasure,
              totalValuation: resource.totalValuation,
              property: this.state.data.propertyData?.propertyName,
              parcels: "All",
            });
          }
        });
    });
    return protectedResources;
  }

  enterLossModal = (row) => {
    this.setState({ currentResourceRecord: row, showLossModal: true });
  };

  closeLossModal = (row) => {
    this.setState({ showLossModal: false });
  };

  closeEnterTakeModal = () => {
    this.setState({ showEnterTakeModal: false });
  };

  selectCooperatorChecked = (e, row) => {
    let { data } = this.state;
    let tableParentRow = data.propertyMethodInventory?.find(
      (item) => item.methodUniqueIdentifier === row?.methodUniqueReference
    );
    if (tableParentRow) {
      let subRow = tableParentRow.propertyMethodInventorySubTable?.find(
        (item) => item.deployedItemUniqueIdentifier === row?.deployedItemUniqueIdentifier
      );
      if (subRow) {
        subRow.cooperatorChecked = e.target.checked;
      }
    }
    this.setState({ data });
  };

  async populateConflicts() {
    if (!this.state.checkedForConflicts) {
      const getConflictsCall = await ConflictsAPI.GetConflictsByProperty(
        this.state.data.propertyData?.propertyUniqueIdentifier
      );
      if (getConflictsCall?.successful) {
        const fullConflictObjArray = getConflictsCall.data.results;
        const methodProtects = [];
        const methodTargets = [];
        if (fullConflictObjArray?.length > 0) {
          fullConflictObjArray.forEach((conflict) => {
            if (conflict) {
              if (
                !methodTargets.find((t) => t.targetedSpeciesUniqueReference === conflict.targetedSpeciesUniqueReference)
              ) {
                methodTargets.push({
                  targetedSpeciesUniqueReference: conflict.targetedSpeciesUniqueReference,
                  targetedSpeciesName: conflict.targetedSpeciesName,
                });
              }
            }
          });
        }
        this.setState({ propertyConflicts: fullConflictObjArray, methodTargets, methodProtects });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve conflicts for this property",
          apiName: "GetConflictsByProperty",
          responseUnsuccessful: getConflictsCall?.unsuccessful,
          responseMessage: getConflictsCall?.message,
        });
      }
      this.setState({ checkedForConflicts: true });
    }
    return true;
  }

  updateMethodProtectedResourcesDropdown = () => {
    let methodProtects = [];
    let methodChosenProtections = this.state.methodChosenProtections;
    this.state.propertyConflicts.forEach((conflict) => {
      let speciesFind = this.state.methodChosenTargets.find(
        (s) => s.targetedSpeciesUniqueReference === conflict.targetedSpeciesUniqueReference
      );
      if (speciesFind) {
        conflict.properties.forEach((property) => {
          property.protectedResources.forEach((resource) => {
            let resourceNotPresent = !methodProtects.find(
              (r) => r.protectedResourceUniqueReference === resource.protectedResourceUniqueReference
            );
            if (resourceNotPresent) {
              methodProtects.push(resource);
            }
          });
        });
      }
    });
    methodChosenProtections = methodChosenProtections.filter((cp) =>
      methodProtects.find((mp) => mp.protectedResourceUniqueReference === cp.protectedResourceUniqueReference)
    );
    this.setState({ methodProtects, methodChosenProtections });
  };

  async populateResourceEffort() {
    const populateConflicts = await this.populateConflicts();
    let { data, propertyConflicts } = this.state;
    if (this.state.propertyVisitSummaryObj && populateConflicts && propertyConflicts?.length > 0) {
      let resourcesFromPropertyConflicts = this.getResourceListFromPropertyConflicts(
        this.state.propertyVisitSummaryObj.associatedConflicts,
        true
      );
      data.resourceEffortList = JSON.parse(JSON.stringify(resourcesFromPropertyConflicts));
      this.setState({ data }, () => {
        this.equalizeResourceEffortValues();
      });
    }
  }

  getResourceListFromPropertyConflicts(associatedConflicts, checkIfResourceAlreadyExisitsInList) {
    const { propertyConflicts } = this.state;
    let associatedResources = associatedConflicts?.flatMap((c) =>
      c.targetProtectedResources.map((r) => r.protectedResourceUniqueReference)
    );
    let resourcesFromPropertyConflicts = [];
    propertyConflicts.forEach((conflict) => {
      conflict.properties.forEach((property) => {
        property.protectedResources.forEach((resource) => {
          if (
            associatedConflicts.some((aC) => aC.conflictUniqueReference === conflict.conflictUniqueIdentifier) &&
            associatedResources.some((aR) => aR === resource.protectedResourceUniqueReference)
          ) {
            if (!checkIfResourceAlreadyExisitsInList) {
              resourcesFromPropertyConflicts.push(resource);
            } else {
              const effortItem = resourcesFromPropertyConflicts.find(
                (resourceEffort) =>
                  resourceEffort.resourceUniqueReference === resource.resourceUniqueReference &&
                  resourceEffort.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure
              );
              if (!effortItem) {
                resourcesFromPropertyConflicts.push(resource);
              }
            }
          }
        });
      });
    });
    return resourcesFromPropertyConflicts;
  }

  async updatePvsDataObject(data) {
    const siteVisitData = this.props.history.location?.state?.siteVisitData;
    if (siteVisitData?.propertyVisitSummaryUniqueIdentifier) {
      const siteVisitDataResponse = await WorkTaskAPI.GetPVS(siteVisitData?.propertyVisitSummaryUniqueIdentifier);
      if (siteVisitDataResponse?.successful) {
        const fullPVSObj = siteVisitDataResponse?.data;
        data.pvsHours = parseInt(fullPVSObj.siteVisitHours);
        data.pvsMinutes = parseInt(fullPVSObj.siteVisitMinutes);
        const dateEntered = fullPVSObj?.dateEntered ? new Date(fullPVSObj.dateEntered) : "";
        const formattedDate = dateEntered
          ? `${dateEntered.getMonth() + 1
          }/${dateEntered.getDate()}/${dateEntered.getFullYear()} - ${UtilityFunctions.getWeekDayFromDate(
            dateEntered
          )}`
          : "";
        const contactName = await this.getUserData(
          siteVisitDataResponse?.data?.performedByWsUserProfileUniqueReference
        );
        const fullUserData = await this.getFullUserData(contactName?.wsUserUniqueIdentifier);
        await this.updateTakesDataOnWorkPerformed(fullPVSObj);
        //get takes data
        const takesData = await this.getTakesData(fullPVSObj?.dcWorkTaskPerformed?.workPerformed);

        const projects = fullPVSObj.projects?.map((project) => {
          return { value: project.projectUniqueReference, label: project.projectName };
        });
        data.selectedProjects = projects;
        data.previousSelectedProjects = projects;
        this.setState({
          propertyVisitSummaryObj: fullPVSObj,
          performedBy: {
            value: fullPVSObj?.performedByWsUserProfileUniqueReference,
            label: contactName?.fullName,
            wsUserUniqueIdentifier: contactName?.wsUserUniqueIdentifier,
            userProperties: fullUserData?.userProperties,
          },
          pvsDate: formattedDate,
          takesData: takesData,
          data,
        });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve Site Visit data",
          apiName: "getPVS",
          responseUnsuccessful: siteVisitDataResponse?.unsuccessful,
          responseMessage: siteVisitDataResponse?.message,
        });
      }
    }
  }

  async updateTakesDataOnWorkPerformed(fullPVSObj) {
    if (fullPVSObj?.dcWorkTaskPerformed?.workPerformed?.length > 0) {
      const dcWorkPerformedIds = fullPVSObj.dcWorkTaskPerformed.workPerformed.map(
        (w) => w.workPerformedUniqueIdentifier
      );
      const takesResponse = await WorkTaskAPI.GetTakesForWorkPerformed(dcWorkPerformedIds);
      if (takesResponse?.successful) {
        const takesData = takesResponse.data;
        fullPVSObj.dcWorkTaskPerformed.workPerformed.forEach((wp) => {
          let takeArray = [];

          const associatedTakes = takesData?.map((t) => ({ methodName: wp.methodName, ...t }));

          associatedTakes.forEach((take) => {
            take.workPerformedUniqueReferences.forEach((wpid) => {
              if (wpid.workPerformedUniqueReference === wp.workPerformedUniqueIdentifier) {
                takeArray.push(take);
                return;
              }
            });
          });
          if (takeArray?.length > 0) {
            wp.takesData = takeArray;
          }
        });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve takes",
          apiName: "GetTakesForWorkPerformed",
          responseUnsuccessful: takesResponse?.unsuccessful,
          responseMessage: takesResponse?.message,
        });
      }
    }
  }

  async findAndPopulatePVS(propertyGuid) {
    let { data } = this.state;
    const getAllPVSCall = await WorkTaskAPI.GetAllPVSForSingleProperty(propertyGuid);
    if (getAllPVSCall?.successful) {
      const draftPVS = getAllPVSCall.data.results.find((pvs) => pvs?.propertyVisitSummaryStatus?.id === 1);
      if (draftPVS) {
        const getPVSCall = await WorkTaskAPI.GetPVS(draftPVS.propertyVisitSummaryUniqueIdentifier);
        if (!getPVSCall?.successful) {
          toast.warning("Error getting Previous Draft Site Visit.");
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to retrieve previous Draft Site Visit for this property",
            apiName: "GetPVS",
            responseUnsuccessful: getPVSCall?.unsuccessful,
            responseMessage: getPVSCall?.message,
          });
          return;
        }
        const fullPVSObj = getPVSCall?.data;

        const dateEntered = new Date(fullPVSObj?.dateEntered);
        let projects = fullPVSObj.projects?.map((project) => {
          return { value: project.projectUniqueReference, label: project.projectName };
        });
        data.previousSelectedProjects = projects ?? [];
        if (projects?.length < 1) {
          projects = await this.getInheritedProjectsForSiteVisit(propertyGuid);
        }
        data.selectedProjects = projects;
        const formattedDate = `${dateEntered.getMonth() + 1
          }/${dateEntered.getDate()}/${dateEntered.getFullYear()} - ${UtilityFunctions.getWeekDayFromDate(dateEntered)}`;
        const contactName = await this.getUserData(getPVSCall?.data?.performedByWsUserProfileUniqueReference);
        const fullUserData = await this.getFullUserData(contactName?.wsUserUniqueIdentifier);
        await this.updateTakesDataOnWorkPerformed(fullPVSObj);
        const takesData = await this.getTakesData(fullPVSObj?.dcWorkTaskPerformed?.workPerformed);
        this.setState({
          propertyVisitSummaryObj: fullPVSObj,
          performedBy: {
            value: fullPVSObj?.performedByWsUserProfileUniqueReference,
            label: contactName?.fullName,
            wsUserUniqueIdentifier: contactName?.wsUserUniqueIdentifier,
            userProperties: fullUserData?.userProperties,
          },
          pvsDate: formattedDate,
          creationFieldsDisabled: true,
          takesData: takesData,
          data,
        });
        toast.success("Previous Draft Site Visit found.");
      } else {
        let inheritedProjects = await this.getInheritedProjectsForSiteVisit(propertyGuid);
        data.selectedProjects = inheritedProjects;
        const currentUser = JSON.parse(localStorage.getItem("currentUserRole"));
        const fullUserData = await this.getFullUserData(currentUser?.wsUserUniqueIdentifier);
        this.setState({
          performedBy: {
            value: currentUser?.wsUserProfileUniqueIdentifier,
            label: currentUser?.userName,
            wsUserUniqueIdentifier: currentUser?.wsUserUniqueIdentifier,
            userProperties: fullUserData?.userProperties,
          },
        });
      }

      let sortedPVS = getAllPVSCall?.data?.results?.sort(
        (a, b) => new Date(a.dateEntered).getTime() - new Date(b.dateEntered).getTime()
      );
      let mostRecentPVS = sortedPVS[sortedPVS.length - 1];
      let previousPVS = sortedPVS.length > 1 ? sortedPVS[sortedPVS.length - 2] : "";
      const getMostRecentPVS = await WorkTaskAPI.GetPVS(mostRecentPVS?.propertyVisitSummaryUniqueIdentifier);
      const getPreviousPVS = previousPVS?.propertyVisitSummaryUniqueIdentifier
        ? await WorkTaskAPI.GetPVS(previousPVS?.propertyVisitSummaryUniqueIdentifier)
        : "";
      this.setState({
        mostRecentPVS: getMostRecentPVS?.data,
        previousPVS: getPreviousPVS?.data,
        data,
      });
    } else if (getAllPVSCall?.message?.toLowerCase().includes("not found")) {
      let inheritedProjects = await this.getInheritedProjectsForSiteVisit(propertyGuid);
      data.selectedProjects = inheritedProjects;
      const currentUser = JSON.parse(localStorage.getItem("currentUserRole"));
      const fullUserData = await this.getFullUserData(currentUser?.wsUserUniqueIdentifier);
      this.setState({
        performedBy: {
          value: currentUser?.wsUserProfileUniqueIdentifier,
          label: currentUser?.userName,
          wsUserUniqueIdentifier: currentUser?.wsUserUniqueIdentifier,
          userProperties: fullUserData?.userProperties,
        },
        data,
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve previous Site Visits for this property",
        apiName: "GetAllPVSForSingleProperty",
        responseUnsuccessful: getAllPVSCall?.unsuccessful,
        responseMessage: getAllPVSCall?.message,
      });
    }
  }

  async getUserData(userProfileId) {
    const userDataResponse = await RoleAuthorizationAPI.GetUserWhoOwnsProfile(userProfileId);
    if (userDataResponse?.successful) {
      let userData = userDataResponse.data;
      userData.fullName = userData.firstName + " " + userData.lastName;
      userData.fieldAgentWsUserProfileUniqueReference = userProfileId;
      return userData;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve user data from profile",
        apiName: "GetUserWhoOwnsProfile",
        responseUnsuccessful: userDataResponse?.unsuccessful,
        responseMessage: userDataResponse?.message,
      });
    }
  }

  async getFullUserData(userId) {
    const userDataResponse = await RoleAuthorizationAPI.GetUser(userId);
    if (userDataResponse?.successful) {
      let userData = userDataResponse.data;
      return userData;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve user data",
        apiName: "GetUser",
        responseUnsuccessful: userDataResponse?.unsuccessful,
        responseMessage: userDataResponse?.message,
      });
    }
  }

  getTakesData = async (workPerformed) => {
    let takesData = [];
    if (workPerformed) {
      const workPerformedWithTakes = workPerformed.map((w) => w.workPerformedUniqueIdentifier);
      const takesResponse = await WorkTaskAPI.GetTakesForWorkPerformed(workPerformedWithTakes);

      if (takesResponse?.successful) {
        takesData = takesResponse.data ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve takes",
          apiName: "GetTakesForWorkPerformed",
          responseUnsuccessful: takesResponse?.unsuccessful,
          responseMessage: takesResponse?.message,
        });
      }
    }

    return takesData;
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    $("#ui-datepicker-div").hide();
  }

  handleScroll = () => {
    let personnelInfoClass = styles.staticPVSPersonnel;
    let stickyCardHeaderStyle = styles.topCardNonStickyHeader;
    let stickyCardTextStyle = styles.cardTitleText;
    let stickyCardBodyStyle = "";
    if (window.scrollY > 75) {
      personnelInfoClass = styles.stickyPVSPersonnel;
      stickyCardHeaderStyle = styles.stickyCardHeader;
      stickyCardTextStyle = styles.stickyCardText;
      stickyCardBodyStyle = styles.stickyCardBody;
    }
    this.setState({ personnelInfoClass, stickyCardHeaderStyle, stickyCardTextStyle, stickyCardBodyStyle });
  };

  jqueryInit() {
    if (this.state.data.agreementData?.agreementSignatureDate) {
      datepickerFactory($);
      const self = this;
      $(document).ready(function () {
        $("#pvsDate")
          .datepicker({
            dateFormat: "mm/dd/yy - DD",
            maxDate: 0,
            minDate: new Date(new Date(self.state.data.agreementData.agreementSignatureDate).getTime() + 14400000),
          })
          .change(async (e) => {
            self.setState({ pvsDate: e.currentTarget.value });
            await self.handlePersonnelInformationChange();
            $("#ui-datepicker-div").hide();
          });
      });
    }
  }

  contextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = React.useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    if (isCurrentEventKey) {
      return <img className={styles.headerChevron} src={ChevronDown} onClick={decoratedOnClick} alt="down arrow" />;
    } else {
      return <img className={styles.headerChevron} src={ChevronRight} onClick={decoratedOnClick} alt="right arrow" />;
    }
  }

  async refreshPropertyMethodInventory() {
    let { data } = this.state;
    this.setState({ isPMILoading: true });
    const deployedItems = await InventoryAPI.GetDeployedItemsOnProperty(
      this.state.data.propertyData?.propertyUniqueIdentifier
    );
    if (!deployedItems?.successful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve deployed items on property",
        apiName: "GetDeployedItemsOnProperty",
        responseUnsuccessful: deployedItems?.unsuccessful,
        responseMessage: deployedItems?.message,
      });
    }
    const deployedItemsData = deployedItems?.data;
    let propertyMethodInventory = [];
    for (let i = 0; i < deployedItemsData?.length; i++) {
      let item = deployedItemsData[i];
      propertyMethodInventory = this.addPropertyMethodInventoryParentObject(item, propertyMethodInventory);
      for (let i = 0; i < propertyMethodInventory.length; i++) {
        const row = propertyMethodInventory[i];
        if (row.methodUniqueIdentifier === item.methodUniqueReference) {
          let duplicateItem = false;
          for (let j = 0; j < row.propertyMethodInventorySubTable.length; j++) {
            let currentSubTableItem = row.propertyMethodInventorySubTable[j];
            currentSubTableItem.targets = this.getSpeciesStringForInventoryItem(
              currentSubTableItem.speciesItemIsTargetedBy
            );
            currentSubTableItem.protects = this.getResourceStringForInventoryItem(
              currentSubTableItem.resourcesItemIsProtecting
            );
            if (this.isPropertyMethodInventorySubTableRowDuplicate(item, currentSubTableItem)) {
              duplicateItem = true;
              item.quantityDeployed = 1;
              currentSubTableItem.aggregateDeployedItems.push({
                deployedItemUniqueIdentifier: item.deployedItemUniqueIdentifier,
                inventoryItemUniqueReference: item.inventoryItemUniqueReference,
                quantityDeployed: item.quantityDeployed,
              });
              currentSubTableItem.displayQuantity += item.quantityDeployed;
              row.methodQuantity += item.quantityDeployed;
            }
          }
          if (!duplicateItem) {
            const actions = await WorkTaskAPI.GetMethodUseConfigureActions([item.deployedWithMethodAllowedUseEnumId]);
            if (!actions?.successful) {
              ErrorHandler.handleApiErrorMessage({
                errorContextMessage: "Unable to method configuration actions for method inventory",
                apiName: "GetMethodUseConfigureActions",
                responseUnsuccessful: actions?.unsuccessful,
                responseMessage: actions?.message,
              });
            }
            const actionsData = actions?.data;
            item.configureActions = actionsData;
            item.quantityDeployed = 1;
            item.aggregateDeployedItems = [
              {
                deployedItemUniqueIdentifier: item.deployedItemUniqueIdentifier,
                inventoryItemUniqueReference: item.inventoryItemUniqueReference,
                quantityDeployed: item.quantityDeployed,
              },
            ];
            item.displayQuantity = item.quantityDeployed;
            item.targets = this.getSpeciesStringForInventoryItem(item.speciesItemIsTargetedBy);
            item.protects = this.getResourceStringForInventoryItem(item.resourcesItemIsProtecting);
            row.propertyMethodInventorySubTable.push(item);
            row.methodQuantity += item.displayQuantity;
          }
        }
      }
    }
    data.propertyMethodInventory = propertyMethodInventory;
    this.setState({ data, isPMILoading: false });
  }

  addPropertyMethodInventoryParentObject(item, propertyMethodInventory) {
    let { data } = this.state;
    let UOM = this.state.allMethodUOMs.find((UOM) => UOM.id === item.deployedWithUnitOfMeasureEnumId);
    let previousRowItem = data.propertyMethodInventory?.find(
      (prevItem) => prevItem.methodUniqueIdentifier === item.methodUniqueReference
    );
    let parentRowObject = {
      methodUniqueIdentifier: item.methodUniqueReference,
      methodName: item.methodName,
      methodQuantity: 0,
      methodUOM: UOM?.displayText,
      propertyMethodInventorySubTable: [],
      propertyMethodInventorySubTableSelected:
        previousRowItem?.propertyMethodInventorySubTableSelected?.length > 0
          ? previousRowItem?.propertyMethodInventorySubTableSelected
          : [],
    };
    let isDuplicateParent = false;
    let newPropertyMethodInventory = propertyMethodInventory?.filter(function (row) {
      if (row.methodUniqueIdentifier === parentRowObject.methodUniqueIdentifier) {
        isDuplicateParent = true;
      }
      return true;
    });
    if (!isDuplicateParent) {
      newPropertyMethodInventory.push(parentRowObject);
    }
    return newPropertyMethodInventory ?? [];
  }

  isPropertyMethodInventorySubTableRowDuplicate(item, currentSubTableItem) {
    return (
      currentSubTableItem.deployedWithUnitOfMeasureEnumId === item.deployedWithUnitOfMeasureEnumId &&
      currentSubTableItem.deployedWithMethodAllowedUseEnumId === item.deployedWithMethodAllowedUseEnumId &&
      currentSubTableItem.status === item.status &&
      !currentSubTableItem.latitude &&
      !currentSubTableItem.longitude &&
      !item.latitude &&
      !item.longitude &&
      currentSubTableItem.resourcesItemIsProtecting.every((resource) =>
        item.resourcesItemIsProtecting.includes(resource)
      ) &&
      currentSubTableItem.resourcesItemIsProtecting.length === item.resourcesItemIsProtecting.length &&
      currentSubTableItem.speciesItemIsTargetedBy.every((resource) =>
        item.speciesItemIsTargetedBy.includes(resource)
      ) &&
      currentSubTableItem.speciesItemIsTargetedBy.length === item.speciesItemIsTargetedBy.length
    );
  }

  populateDatesOnMethods(deployedItems) {
    deployedItems.forEach((item) => {
      if (item.latitude && item.longitude) {
        const matchingMethod = this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workPerformed.find(
          (w) =>
            w.inventoryItemLongitude === item.longitude &&
            w.inventoryItemLatitude === item.latitude &&
            w.methodUniqueReference === item.methodUniqueReference
        );
        item.createdDate = new Date(matchingMethod.createdDateTimeUTC).toLocaleDateString("en-US");
        item.lastModifiedDate = new Date(matchingMethod.lastModifiedDateTimeUTC).toLocaleDateString("en-US");
      }
    });
    return deployedItems;
  }

  getSpeciesStringForInventoryItem(speciesItemIsTargetedBy) {
    let result = "";
    let buildArray = speciesItemIsTargetedBy.map((guid) => {
      return this.state.propertyVisitSummaryObj?.associatedConflicts?.find(
        (c) => c.targetSpeciesUniqueReference === guid
      )?.targetSpeciesName;
    });
    if (buildArray.length > 0) {
      result = buildArray.join(", ");
    }
    return result;
  }

  getResourceStringForInventoryItem(resourcesItemIsProtecting) {
    // let result = "";
    // let buildArray = [];
    // resourcesItemIsProtecting.forEach((guid) => {
    //   this.state.propertyVisitSummaryObj?.associatedConflicts?.forEach((conflict) => {
    //     conflict.targetProtectedResources.forEach((resource) => {
    //       if (resource.protectedResourceUniqueReference === guid) {
    //         buildArray.push(resource.protectedResourceName);
    //       }
    //     });
    //   });
    // });
    // if (buildArray.length > 0) {
    //   result = buildArray.join(", ");
    // }
    // return result;

    let result = "";
    let buildArray = resourcesItemIsProtecting.map((guid) => {
      return this.state.propertyVisitSummaryObj?.associatedConflicts?.find(
        (c) => c.protectedResourceUniqueReference === guid
      )?.protectedResourceName;
    });
    if (buildArray.length > 0) {
      result = buildArray.join(", ");
    }
    return result;
  }

  async getInheritedProjectsForSiteVisit(propertyGuid) {
    const inheritedProjects = await WorkTaskAPI.FindInheritedPVSProjectOptions(propertyGuid);
    if (inheritedProjects?.successful) {
      return inheritedProjects?.data?.map((project) => {
        return { value: project.projectUniqueIdentifier, label: project.projectName ?? "No Project Name" };
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get and set inherited projects",
        apiName: "FindInheritedPVSProjectOptions",
        responseUnsuccessful: inheritedProjects?.unsuccessful,
        responseMessage: inheritedProjects?.message,
      });
      return [];
    }
  }

  async getFullAgreementObject(agreementUniqueIdentifier) {
    const fullAgreement = await AgreementAPI.GetAgreement(agreementUniqueIdentifier);
    if (fullAgreement?.successful) {
      return fullAgreement?.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retreive Agreement data",
        apiName: "GetAgreement",
        responseUnsuccessful: fullAgreement?.unsuccessful,
        responseMessage: fullAgreement?.message,
      });
      return [];
    }
  }

  async handleSpecificChanges(input) {
    if (input.name === "methodSearchSelection") {
      let { data } = this.state;
      const fullMethodObject = await ReferenceFileAPI.GetMethodForState(
        input.value,
        this.state.data.propertyData?.state
      );
      if (fullMethodObject?.successful) {
        if (
          (fullMethodObject.data?.isCMITSMethodGlobally || fullMethodObject.data?.isCMITSMethod) &&
          !this.props.permissions.canIWorktaskInteractionAssignedWarehouse()
        ) {
          toast.warning(
            "This is CMITS method and your role does not have permission to make warehouse transactions from work tasks"
          );
          return;
        }
        data.chosenMethod = fullMethodObject.data;
        data.methodSearchSelection = input.value ? input.value : fullMethodObject.data.methodUniqueIdentifier;
        if (data.chosenMethod?.allowedUOMsInState?.length === 1) {
          data.methodChosenUOM = data.chosenMethod?.allowedUOMsInState[0].displayText;
        }
        if (data.chosenMethod?.allowedUsesInState?.length === 1) {
          data.methodChosenUse = data.chosenMethod?.allowedUsesInState[0].displayText;
        }
        let geoRequired = false;
        if (data.chosenMethod?.isGeolocationRequiredGlobally !== null) {
          geoRequired = data.chosenMethod?.isGeolocationRequiredGlobally;
          if (data.chosenMethod?.isGeolocationRequiredInState !== null && !geoRequired) {
            geoRequired = data.chosenMethod?.isGeolocationRequiredInState;
          }
        }
        let labelRequired = false;
        if (data.chosenMethod?.isMethodLabelRequiredGlobally !== null) {
          labelRequired = data.chosenMethod?.isMethodLabelRequiredGlobally;
          if (data.chosenMethod?.isMethodLabelRequiredInState !== null && !labelRequired) {
            labelRequired = data.chosenMethod?.isMethodLabelRequiredInState;
          }
        }
        data.geoRequired = geoRequired;
        data.labelRequired = labelRequired;

        const isAviationCrewMethod = fullMethodObject.data?.methodName === "Aviation Ground Crewing";
        this.setState({
          data,
          isAviationCrewMethodSelected: isAviationCrewMethod,
          showAviationGroundCrewModal: isAviationCrewMethod,
        });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve method state data",
          apiName: "GetMethodForState",
          responseUnsuccessful: fullMethodObject?.unsuccessful,
          responseMessage: fullMethodObject?.message,
        });
      }
    }
    if (input.name === "pvsHours") {
      let { data } = this.state;
      if (isNaN(input.value) || Math.sign(input.value) === -1) {
        data.pvsHours = "";
        this.setState({ data });
      }
      if (parseFloat(input.value) > 0) {
        data.pvsHours = parseFloat(input.value);
        this.setState({ data });
        this.equalizeResourceEffortValues();
      }
    }
    if (input.name === "pvsMinutes") {
      let { data } = this.state;
      if (isNaN(input.value) || Math.sign(input.value) === -1) {
        data.pvsMinutes = 0;
        this.setState({ data });
      }
      if (parseFloat(input.value) >= 0) {
        data.pvsMinutes = parseInt(input.value);
        this.setState({ data });
        this.equalizeResourceEffortValues();
      }
    }
    if (input.name === "methodLongitude") {
      let { data } = this.state;
      if (input.value > 0 && this.state.data.propertyStateName !== "GU") {
        input.value = input.value * -1;
        data.methodLongitude = input.value;
        this.setState({ data });
      } else if (input.value < 0 && this.state.data.propertyStateName === "GU") {
        input.value = input.value * -1;
        data.methodLongitude = input.value;
        this.setState({ data });
      }
    }
    const uomNotEach = input.name === "methodChosenUOM" && input.value !== "Each";
    const quantityNotOne = input.name === "methodQuantity" && input.value !== "1";
    const coordinateFieldsNotEmpty =
      this.state.data.methodNickname !== "" ||
      this.state.data.methodLatitude !== "" ||
      this.state.data.methodLongitude !== "";
    if ((uomNotEach || quantityNotOne) && coordinateFieldsNotEmpty) {
      this.clearLatLongForm();
    }
  }

  saveAircraftsSelection = (updatedAircraftsSelection) => {
    const { data } = this.state;
    data.selectedAircrafts = updatedAircraftsSelection;
    this.setState({ data });
  };

  saveSamplesSelection = (sampleData) => {
    const { data } = this.state;
    this.setState({
      inProgressTakeData: {
        isOtherSampling: sampleData.isOtherSampling,
        rabiesSamplingQty: sampleData.rabiesSamplingQty,
        diseaseSamplingQty: sampleData.diseaseSamplingQty,
        isWithoutTake: true,
      },
    });

    if (sampleData.isOtherSampling) {
      this.setState({ showOtherSampleModal: true });
    } else if (Number.parseInt(sampleData.rabiesSamplingQty) > 0) {
      const rabiesSampleData = {
        rabiesSamplingQty: Number.parseInt(sampleData.rabiesSamplingQty),
        propertyStateName: data.propertyStateName,
        currentStateId: data.propertyData?.state,
        propertyCounty: data.propertyData?.county,
        town:
          data.propertyData?.additionalStreetAddresses?.length > 0
            ? data.propertyData?.additionalStreetAddresses[0]?.city
            : "",
      };
      this.setState({ rabiesSampleData, showRabiesSampleModal: true });
    } else if (Number.parseInt(sampleData.diseaseSamplingQty) > 0) {
      this.setState({ showDiseaseMonitoringModal: true });
    }
  };

  clearLatLongForm() {
    let { data } = this.state;
    data.methodLongitude = "";
    data.methodLatitude = "";
    data.methodNickname = "";
    this.setState({ data });
    toast.warning("Coordinates and Label require a Quantity of 1 and a Unit of Measure of Each.");
  }

  handlePerformedByChange = async (input) => {
    let state = this.state;
    state.performedBy = input;
    this.setState({ state });
    await this.handlePersonnelInformationChange();
  };

  async handlePersonnelInformationChange() {
    if (!this.state.propertyVisitSummaryObj && this.state.performedBy && this.state.pvsDate !== "") {
      //8-10-2022, removing expiry check for now to test if needed for 6202
      // if (new Date(this.state.data.agreementData?.expirationDate) <= new Date(this.state.pvsDate.split(" ")[0])) {
      //   toast.warning("Cannot create site visit, the date is after the expiration of the agreement");
      //   return;
      // }
      const requestDateTime = new Date(this.state.pvsDate.split(" ")[0]).toLocaleDateString("en-CA") + "T00:00:00.000Z";
      const createPVSCall = await WorkTaskAPI.CreatePVS(
        this.state.data.propertyData?.propertyUniqueIdentifier,
        this.state.performedBy?.value,
        this.state.performedBy?.value,
        requestDateTime
      );
      if (createPVSCall?.successful) {
        toast.success("Site Visit created.");
        this.setState({ propertyVisitSummaryObj: createPVSCall.data, creationFieldsDisabled: true });
        const getAllPVSCall = await WorkTaskAPI.GetAllPVSForSingleProperty(
          this.state.data.propertyData?.propertyUniqueIdentifier
        );
        if (getAllPVSCall?.successful) {
          let sortedPVS = getAllPVSCall?.data?.results?.sort(
            (a, b) => new Date(a.dateEntered).getTime() - new Date(b.dateEntered).getTime()
          );
          let mostRecentPVS = sortedPVS[sortedPVS.length - 1];
          let previousPVS = sortedPVS[sortedPVS.length - 2];

          const getMostRecentPVS = await WorkTaskAPI.GetPVS(mostRecentPVS?.propertyVisitSummaryUniqueIdentifier);
          const getPreviousPVS = previousPVS
            ? await WorkTaskAPI.GetPVS(previousPVS?.propertyVisitSummaryUniqueIdentifier)
            : "";
          this.setState(
            { mostRecentPVS: getMostRecentPVS?.data, previousPVS: getPreviousPVS?.data },
            this.checkForEnablePreviousEffortRatio(this.state.mostRecentPVS)
          );
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to get previous Site Visit on property",
            apiName: "GetAllPVSForSingleProperty",
            responseUnsuccessful: getAllPVSCall?.unsuccessful,
            responseMessage: getAllPVSCall?.message,
          });
        }
        if (this.state.previousPVS) {
          this.state.previousPVS.associatedConflicts.forEach(async (conflict) => {
            const getFullConflictObj = await ConflictsAPI.GetConflict(conflict.conflictUniqueReference);
            this.addConflictToPVS(getFullConflictObj?.data);
          });
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create Site Visit",
          apiName: "CreatePVS",
          responseUnsuccessful: createPVSCall?.unsuccessful,
          responseMessage: createPVSCall?.message,
        });
      }
    }
  }

  getWarehouseForWorkPerformed(item) {
    let warehouse = null;

    if (item.methodWarehouseSelection) {
      warehouse = item.methodWarehouseSelection;
    } else if (item.chosenMethod?.isCMITSMethodGlobally || item.chosenMethod?.isCMITSMethod) {
      let personalWarehouse = this.state.availableWarehouses.find(
        (x) =>
          x.warehouseManagerWSUserProfileUniqueReference === this.state.performedBy.value && x.warehouseType.id === 4
      );
      if (personalWarehouse) {
        warehouse = personalWarehouse.warehouseUniqueIdentifier;
      }
    }

    return warehouse;
  }

  async createWorkTaskOnPVSBeforeCreatingWorkPerformed() {
    if (!this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed) {
      const createWorkTaskCall = await WorkTaskAPI.CreateWorkTaskForPVS(
        this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier,
        [this.state.data.propertyData?.propertyUniqueIdentifier],
        this.state.data.pvsHours ? parseInt(this.state.data.pvsHours) : 0,
        this.state.data.pvsMinutes ? parseInt(this.state.data.pvsMinutes) : 0,
        this.state.activeWorkTaskType ? this.state.activeWorkTaskType : WorkTaskTypes.DirectWorkTask
      );
      if (createWorkTaskCall?.successful) {
        await this.refreshPVSObject();
        return true;
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create work task for Site Visit",
          apiName: "CreateWorkTaskForPVS",
          responseUnsuccessful: createWorkTaskCall?.unsuccessful,
          responseMessage: createWorkTaskCall?.message,
        });
        return false;
      }
    } else {
      return true;
    }
  }

  async doWork(item, mode) {
    let success = true;

    let createdWorkTask = await this.createWorkTaskOnPVSBeforeCreatingWorkPerformed();
    if (!createdWorkTask) {
      return false;
    }

    const useId = this.state.data.chosenMethod?.allowedUsesInState?.find(
      (use) => use.displayText === item.methodChosenUse
    )?.id;
    const uomId = this.state.data.chosenMethod?.allowedUOMsInState?.find(
      (uom) => uom.displayText === item.methodChosenUOM
    )?.id;
    const conflictTargetedSpeciesUniqueReferences = this.state.methodChosenTargets.map((target) => {
      return target.targetedSpeciesUniqueReference;
    });
    const conflictProtectedResourceUniqueReferences = this.state.methodChosenProtections.map((p) => {
      return p.protectedResourceUniqueReference;
    });

    if (mode === "Add") {
      const workPerformedPostBody = this.createWorkPerformedAddBody(
        item,
        conflictTargetedSpeciesUniqueReferences,
        conflictProtectedResourceUniqueReferences,
        useId,
        uomId
      );
      success = await this.handleWorkPerformedCreation(workPerformedPostBody, success, true);
    } else if (mode === "ConfigureGeo") {
      //Configure CreateWorkPerformedOnWorkTask here
      for (let i = 0; i < item.aggregateDeployedItems.length; i++) {
        let individualDeployedItem = item.aggregateDeployedItems[i];
        const workPerformedPostBody = {
          methodUniqueReference: item.methodUniqueReference,
          methodAllowedUseEnumId: null,
          methodAllowedUOMId: item.deployedWithUnitOfMeasureEnumId,
          workPerformedType: "Configure",
          configurationActionReference: item.configurationActionUniqueIdentifier,
          deployedItemUniqueReferences: [individualDeployedItem.deployedItemUniqueIdentifier],
          inventoryItemNewNickname: item.locationAlias ? item.locationAlias : null,
          inventoryItemNewLatitude: parseFloat(item.latitude) ? parseFloat(item.latitude) : null,
          inventoryItemNewLongitude: parseFloat(item.longitude) ? parseFloat(item.longitude) : null,
          cooperatorOrVolunteerCheck: item.cooperatorChecked ? true : false,
          warehouseUniqueReference: this.getWarehouseForWorkPerformed(item),
          projectUniqueReferences: this.state.data.selectedProjects?.map((project) => project.value) ?? [],
        };
        success = await this.handleWorkPerformedCreation(workPerformedPostBody, success);
      }
    } else if (mode === "ConfigureNonGeo") {
      const workPerformedPostBody = {
        methodUniqueReference: item.methodUniqueReference,
        methodAllowedUseEnumId: null,
        methodAllowedUOMId: item.deployedWithUnitOfMeasureEnumId,
        workPerformedType: "Configure",
        configurationActionReference: item.configurationActionUniqueIdentifier,
        deployedItemUniqueReferences: item.configureIds,
        inventoryItemNewNickname: item.locationAlias ? item.locationAlias : null,
        inventoryItemNewLatitude: parseFloat(item.latitude) ? parseFloat(item.latitude) : null,
        inventoryItemNewLongitude: parseFloat(item.longitude) ? parseFloat(item.longitude) : null,
        cooperatorOrVolunteerCheck: item.cooperatorChecked ? true : false,
        warehouseUniqueReference: this.getWarehouseForWorkPerformed(item),
      };
      const addWorkPerformedCall = await WorkTaskAPI.CreateWorkPerformedOnWorkTask(
        this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workTaskUniqueIdentifier,
        workPerformedPostBody
      );
      item.aggregateDeployedItems = item?.aggregateDeployedItems?.filter(
        (item) => item.deployedItemUniqueIdentifier !== workPerformedPostBody.deployedItemUniqueReference
      );
      if (addWorkPerformedCall?.successful) {
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create work performed on work task",
          apiName: "CreateWorkPerformedOnWorkTask",
          responseUnsuccessful: addWorkPerformedCall?.unsuccessful,
          responseMessage: addWorkPerformedCall?.message,
        });
        success = false;
      }
      await this.refreshPVSObject();
      await this.refreshPropertyMethodInventory();
    } else if (mode === "Consume") {
      const methodQuantity = parseInt(item.methodQuantity);
      const workPerformedPostBody = {
        methodUniqueReference: item.chosenMethod?.methodUniqueIdentifier,
        conflictUniqueReferences: this.getConflictIds(),
        methodAllowedUseEnumId: useId,
        methodAllowedUOMId: uomId,
        workPerformedType: "Consume",
        configurationActionReference: null,
        methodQtyAffect: methodQuantity && methodQuantity > 0 ? methodQuantity : 1,
        deployedItemUniqueReference: null,
        inventoryItemNewNickname: "",
        inventoryItemNewLatitude: null,
        inventoryItemNewLongitude: null,
        workPerformedAircraft: this.state.data.selectedAircrafts?.map((a) => a.aircraftId),
        warehouseUniqueReference: this.getWarehouseForWorkPerformed(item),
        projectUniqueReferences: this.state.data.selectedProjects?.map((project) => project.value) ?? [],
      };
      if (conflictTargetedSpeciesUniqueReferences?.length > 0) {
        workPerformedPostBody.conflictTargetedSpeciesUniqueReferences = conflictTargetedSpeciesUniqueReferences;
      }

      if (conflictProtectedResourceUniqueReferences?.length > 0) {
        workPerformedPostBody.conflictProtectedResourceUniqueReferences = conflictProtectedResourceUniqueReferences;
      }
      success = await this.handleWorkPerformedCreation(workPerformedPostBody, success);
      await this.refreshPVSObject();
      return success;
    } else if (mode === "Remove") {
      for (let i = 0; i < item.aggregateDeployedItems.length; i++) {
        let individualDeployedItem = item.aggregateDeployedItems[i];
        const workPerformedPostBody = {
          methodUniqueReference: item.methodUniqueReference,
          methodAllowedUseEnumId: null,
          configurationActionReference: item.configurationActionUniqueIdentifier
            ? item.configurationActionUniqueIdentifier
            : null,
          methodAllowedUOMId: item.deployedWithUnitOfMeasureEnumId,
          workPerformedType: "Configure",
          deployedItemUniqueReferences: [individualDeployedItem.deployedItemUniqueIdentifier],
          cooperatorOrVolunteerCheck: item.cooperatorChecked ? true : false,
          projectUniqueReferences: this.state.data.selectedProjects?.map((project) => project.value) ?? [],
          warehouseUniqueReference: this.getWarehouseForWorkPerformed(item),
        };
        success = await this.handleWorkPerformedCreation(workPerformedPostBody, success);
        this.handleSubRowOnSelect(
          {
            methodUniqueReference: item.methodUniqueReference,
            deployedItemUniqueIdentifier: individualDeployedItem.deployedItemUniqueIdentifier,
          },
          false
        );
      }
    }
    return success;
  }

  createWorkPerformedAddBody(
    item,
    conflictTargetedSpeciesUniqueReferences,
    conflictProtectedResourceUniqueReferences,
    useId,
    uomId
  ) {
    let workPerformedPostBody = {
      methodUniqueReference: item.chosenMethod?.methodUniqueIdentifier,
      conflictUniqueReferences: this.getConflictIds(),
      methodAllowedUseEnumId: useId,
      methodAllowedUOMId: uomId,
      workPerformedType: "Add",
      configurationActionReference: null,
      methodQtyAffect: parseInt(item.methodQuantity),
      deployedItemUniqueReference: null,
      inventoryItemNewNickname: item.methodNickname ? item.methodNickname : "",
      inventoryItemNewLatitude: parseFloat(item.methodLatitude) ? parseFloat(item.methodLatitude) : null,
      inventoryItemNewLongitude: parseFloat(item.methodLongitude) ? parseFloat(item.methodLongitude) : null,
      projectUniqueReferences: this.state.data.selectedProjects?.map((project) => project.value) ?? [],
    };

    if (conflictTargetedSpeciesUniqueReferences?.length > 0) {
      workPerformedPostBody.conflictTargetedSpeciesUniqueReferences = conflictTargetedSpeciesUniqueReferences;
    }

    if (conflictProtectedResourceUniqueReferences?.length > 0) {
      workPerformedPostBody.conflictProtectedResourceUniqueReferences = conflictProtectedResourceUniqueReferences;
    }

    if (item.methodWarehouseSelection) {
      workPerformedPostBody.warehouseUniqueReference = item.methodWarehouseSelection;
    }

    return workPerformedPostBody;
  }

  getConflictIds = () => {
    let conflictUniqueReferences = [];
    this.state.propertyConflicts.forEach((conflict) => {
      if (
        this.state.methodChosenTargets.find(
          (t) => t.targetedSpeciesUniqueReference === conflict.targetedSpeciesUniqueReference
        )
      ) {
        conflictUniqueReferences.push(conflict.conflictUniqueIdentifier);
      }
    });
    return conflictUniqueReferences;
  };

  async handleWorkPerformedCreation(workPerformedPostBody, success, isAdd) {
    const removeWorkPerformedCall = await WorkTaskAPI.CreateWorkPerformedOnWorkTask(
      this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workTaskUniqueIdentifier,
      workPerformedPostBody
    );
    if (removeWorkPerformedCall?.successful) {
      toast.success("Work Performed recorded.");
      await this.refreshPVSObject();
      await this.refreshPropertyMethodInventory();
      if (
        isAdd &&
        workPerformedPostBody?.inventoryItemNewLatitude &&
        workPerformedPostBody?.inventoryItemNewLongitude
      ) {
        let workJustPerformed =
          removeWorkPerformedCall.data?.workPerformed[removeWorkPerformedCall.data?.workPerformed?.length - 1];
        this.handleSubRowOnSelect(
          {
            methodUniqueReference: workJustPerformed?.methodUniqueReference,
            deployedItemUniqueIdentifier: workJustPerformed?.deployedItemUniqueReference,
          },
          true
        );
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to remove work performed entry",
        apiName: "CreateWorkPerformedOnWorkTask",
        responseUnsuccessful: removeWorkPerformedCall?.unsuccessful,
        responseMessage: removeWorkPerformedCall?.message,
      });
      success = false;
    }
    return success;
  }

  async handleMultipleWorkPerformedCreation(workPerformedPostBodyList, success) {
    let createdWorkTask = await this.createWorkTaskOnPVSBeforeCreatingWorkPerformed();
    if (!createdWorkTask) {
      success = false;
      return;
    }
    const workPerformedCall = await WorkTaskAPI.CreateManyWorkPerformedOnWorkTask(
      this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workTaskUniqueIdentifier,
      workPerformedPostBodyList
    );
    if (workPerformedCall?.successful) {
      toast.success("Work Performed recorded.");
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create multiple work performed",
        apiName: "CreateManyWorkPerformedOnWorkTask",
        responseUnsuccessful: workPerformedCall?.unsuccessful,
        responseMessage: workPerformedCall?.message,
      });
      success = false;
    }
  }

  async refreshPVSObject(responseObject) {
    if (!this.state.propertyVisitSummaryObj) {
      return;
    }
    if (!responseObject) {
      responseObject = await WorkTaskAPI.GetPVS(
        this.state.propertyVisitSummaryObj.propertyVisitSummaryUniqueIdentifier
      );
    }
    if (responseObject?.successful) {
      this.setState({ propertyVisitSummaryObj: responseObject.data, shouldRenderWorkPerformed: false }, async () => {
        let { propertyVisitSummaryObj } = this.state;
        await this.updateTakesDataOnWorkPerformed(propertyVisitSummaryObj);
        this.setState({
          propertyVisitSummaryObj,
          workPerformedDataColumns: this.getWorkPerformedDataColumns(),
          workPerformedTakesColumns: this.getWorkPerformedTakesColumns(),
          shouldRenderWorkPerformed: true,
        });
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to update current Site Visit",
        apiName: "GetPVS",
        responseUnsuccessful: responseObject?.unsuccessful,
        responseMessage: responseObject?.message,
      });
    }
  }

  addMethodToInventory = async () => {
    if (!this.state.propertyVisitSummaryObj) {
      toast.error("Performed By and Date must be entered before adding Work Performed.");
      return;
    }

    let { data } = this.state;
    if (data.geoRequired && (!data.methodLatitude || !data.methodLongitude)) {
      toast.error("Geolocation is required for this method, please add Latitude and Longitude values.");
      return;
    }
    if (data.labelRequired && !data.methodNickname) {
      toast.error("Equipment Label is required for this method");
      return;
    }
    if (this.state.isAviationCrewMethodSelected && !(data.selectedAircrafts?.length > 0)) {
      toast.warning("At least one aircraft must be selected for Aviation Crew Method.");
      return;
    }

    if (data.methodNickname && (!data.methodLatitude || !data.methodLongitude)) {
      toast.error("Please enter latitude and longitude if using an Equipment Label.");
      return;
    }
    this.setState({ dataLoadingText: "Adding Method...", isDataLoading: true });

    await this.addOrRemoveProjectsToSiteVisit();

    let item = {
      chosenMethod: data.chosenMethod,
      propertyMethodInventorySubTable: [],
      propertyMethodInventorySubTableSelected: [],
      methodChosenUse: data.methodChosenUse,
      methodChosenUOM: data.methodChosenUOM,
      methodQuantity: data.methodQuantity,
      methodNickname: data.methodNickname,
      methodLatitude: data.methodLatitude,
      methodLongitude: data.methodLongitude,
      methodTargets:
        this.state.methodChosenTargets.length > 0
          ? this.state.methodChosenTargets
            .map((species) => {
              return species.targetedSpeciesName;
            })
            .join(", ")
          : "",
      methodProtects:
        this.state.methodChosenProtections.length > 0
          ? this.state.methodChosenProtections
            .map((resource) => {
              return resource.protectedResourceName;
            })
            .join(", ")
          : "",
      methodWarehouseSelection:
        data.chosenMethod?.isCMITSMethodGlobally || data.chosenMethod?.isCMITSMethod
          ? data.methodWarehouseSelection
          : null,
    };
    const workSuccessful =
      this.state.isAviationCrewMethodSelected || !data.methodChosenUse.includes("Set -")
        ? await this.doWork(item, "Consume")
        : await this.doWork(item, "Add");
    if (!workSuccessful) {
      this.setState({ isDataLoading: false, dataLoadingText: "" });
      return;
    }

    data.chosenMethod = null;
    data.methodSearchSelection = "";
    data.methodChosenUse = "";
    data.methodChosenUOM = "";
    data.methodQuantity = "";
    data.methodNickname = "";
    data.methodLatitude = "";
    data.methodLongitude = "";

    if (this.state.availableWarehouses?.length !== 1) {
      data.methodWarehouseSelection = "";
    }

    this.setState({
      data,
      refreshMapItems: [],
      methodChosenProtections: [],
      methodChosenTargets: [],
      isDataLoading: false,
      dataLoadingText: "",
    });
  };

  findCurrentDifferenceOfSelectedProjects(data) {
    return data.selectedProjects?.filter(
      (currentProject) =>
        data.previousSelectedProjects?.map((previousProject) => previousProject.value).indexOf(currentProject.value) ===
        -1
    );
  }

  findPreviousDifferenceOfSelectedProjects(data) {
    return data.previousSelectedProjects?.filter(
      (currentProject) =>
        data.selectedProjects?.map((previousProject) => previousProject.value).indexOf(currentProject.value) === -1
    );
  }

  async addProjectsToPVS(currentDifference) {
    return await WorkTaskAPI.AddProjectsToPVS(
      this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier,
      currentDifference.map((project) => project.value)
    );
  }

  async removeProjectsFromPVS(previousDifference) {
    return await WorkTaskAPI.RemoveProjectsFromPVS(
      this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier,
      previousDifference.map((project) => project.value)
    );
  }

  addOrRemoveProjectsToSiteVisit = async (isEditPage) => {
    let { data } = this.state;
    let addProjectsResponse;
    let removeProjectsResponse;
    let addSuccess = true;
    let removeSuccess = true;
    const currentDifference = this.findCurrentDifferenceOfSelectedProjects(data);
    const previousDifference = this.findPreviousDifferenceOfSelectedProjects(data);
    if (currentDifference?.length === 0 && previousDifference?.length === 0) {
      return true;
    }
    if (currentDifference?.length > 0) {
      //add
      addProjectsResponse = await this.addProjectsToPVS(currentDifference);
      addSuccess = addProjectsResponse?.successful;
    }
    if (previousDifference?.length > 0) {
      //remove
      removeProjectsResponse = await this.removeProjectsFromPVS(previousDifference);
      removeSuccess = removeProjectsResponse?.successful;
    }
    if (addSuccess && removeSuccess && (addProjectsResponse || removeProjectsResponse)) {
      if (!isEditPage) {
        toast.success("Successfully updated Projects");
      }
      data.previousSelectedProjects = JSON.parse(JSON.stringify(data.selectedProjects));
      this.setState({ data });
      return true;
    } else {
      ErrorHandler.handleApiErrorMessage({
        overrideErrorForUser:
          "Unable to add or remove some projects to Site Visit. " +
          (addProjectsResponse?.message ?? "") +
          " " +
          (removeProjectsResponse?.message ?? ""),
        apiName: "RemoveProjectsFromPVS / AddProjectsToPVS",
        responseUnsuccessful: addSuccess && removeSuccess,
        errorContextMessage: "Unable to add or remove some projects to Site Visit",
      });
    }
  };

  refreshMap = () => {
    let refreshMapItems = [];

    let item = {
      latitude: this.state.data.methodLatitude,
      longitude: this.state.data.methodLongitude,
    };
    refreshMapItems.push(item);
    this.setState({ refreshMapItems });
  };

  handleProtectsSelection = (e, resource, index) => {
    let methodChosenProtections = this.state.methodChosenProtections;
    if (e.target.checked) {
      methodChosenProtections.push(resource);
    } else {
      methodChosenProtections.splice(index, 1);
    }
    this.setState({ methodChosenProtections });
  };

  handleManageWorkPerformedProtectingSelection = (e) => {
    this.setState({ methodChosenProtections: e ?? [] });
  };

  handleTargetsSelection = (e, target, index) => {
    let methodChosenTargets = this.state.methodChosenTargets;
    if (e.target.checked) {
      methodChosenTargets.push(target);
    } else {
      methodChosenTargets.splice(index, 1);
    }
    this.setState({ methodChosenTargets }, () => {
      this.updateMethodProtectedResourcesDropdown();
    });
  };

  handleManageWorkPerformedTargetsSelection = (e) => {
    this.setState({ methodChosenTargets: e ?? [] }, () => {
      this.updateMethodProtectedResourcesDropdown();
    });
  };

  renderDirectWorkTaskTab() {
    const { data } = this.state;
    return !this.state.isDetailsPage || this.state.isPageEditable ? (
      <Row>
        <Col className={styles.methodEntryContainer}>
          <div className={styles.methodEntryInnerContainer}>
            <Row>
              <Col>
                <div>
                  <span className={styles.tabTitle}>Method Entry</span>
                  {this.state.isAviationCrewMethodSelected && (
                    <Button
                      variant="link"
                      onClick={() => {
                        this.setState({ showAviationGroundCrewModal: true });
                      }}
                      className={styles.EditAircraftButton}
                    >
                      <img src={aircraftIcon} alt="" />
                      <span className={styles.editAircraftsLabel}>Edit Aircraft</span>
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
            <Row className={styles.methodEntryLabelRow}>
              <Col>
                <span id="methodEntryMethodSelectLabel">Method{" *"}</span>
              </Col>
              {(data.chosenMethod?.isCMITSMethodGlobally || data.chosenMethod?.isCMITSMethod) && (
                <Col>
                  <span id="methodEntryWarehouseSelectLabel">
                    Warehouse{data.chosenMethod?.isCMITSMethodGlobally || data.chosenMethod?.isCMITSMethod ? " *" : ""}
                  </span>
                </Col>
              )}
              <Col>
                <span id="methodEntryUseSelectLabel">Use{!this.state.isAviationCrewMethodSelected ? " *" : ""}</span>
              </Col>
              <Col>
                <span id="methodEntryQuantityLabel">
                  Quantity{!this.state.isAviationCrewMethodSelected ? " *" : ""}
                </span>
              </Col>
              <Col>
                <span id="methodEntryUOMSelectLabel">UOM{!this.state.isAviationCrewMethodSelected ? " *" : ""}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  aria-labelledby="methodEntryMethodSelectLabel"
                  name="methodSearchSelection"
                  as="select"
                  placeholder="Select Method"
                  onChange={this.handleChange}
                  value={data.methodSearchSelection}
                >
                  <option></option>
                  {data?.allowedMethods?.map((method) => {
                    return (
                      <option key={method.methodName} value={method.methodUniqueReference}>
                        {method.methodName}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              {(data.chosenMethod?.isCMITSMethodGlobally || data.chosenMethod?.isCMITSMethod) &&
                this.props.permissions.canIWorktaskInteractionAssignedWarehouse() && (
                  <Col>
                    <Form.Control
                      aria-labelledby="methodEntryWarehouseSelectLabel"
                      name="methodWarehouseSelection"
                      as="select"
                      placeholder="Select Warehouse"
                      onChange={this.handleChange}
                      value={data.methodWarehouseSelection}
                    >
                      <option></option>
                      {this.state.availableWarehouses?.map((warehouse) => {
                        return (
                          <option key={warehouse.warehouseUniqueIdentifier} value={warehouse.warehouseUniqueIdentifier}>
                            {warehouse.warehouseName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                )}
              <Col>
                <Form.Control
                  aria-labelledby="methodEntryUseSelectLabel"
                  name="methodChosenUse"
                  as="select"
                  disabled={!data.methodSearchSelection}
                  placeholder="Select use"
                  onChange={this.handleChange}
                >
                  {data.chosenMethod?.allowedUsesInState?.map((use, index) => {
                    if (index === 0 && data.chosenMethod?.allowedUsesInState?.length > 1) {
                      return [<option></option>, <option key={use.name}>{use.displayText}</option>];
                    } else {
                      return <option key={use.name}>{use.displayText}</option>;
                    }
                  })}
                </Form.Control>
              </Col>
              <Col>
                <Form.Control
                  aria-labelledby="methodEntryQuantityLabel"
                  name="methodQuantity"
                  as="input"
                  type="number"
                  placeholder="Enter Qty"
                  onChange={this.handleChange}
                  value={data.methodQuantity}
                  min="1"
                ></Form.Control>
              </Col>
              <Col>
                <Form.Control
                  aria-labelledby="methodEntryUOMSelectLabel"
                  name="methodChosenUOM"
                  as="select"
                  disabled={!data.methodSearchSelection}
                  placeholder="Select UOM"
                  onChange={this.handleChange}
                >
                  {data.chosenMethod?.allowedUOMsInState?.map((uom, index) => {
                    if (index === 0 && data.chosenMethod?.allowedUOMsInState?.length > 1) {
                      return [<option></option>, <option key={uom.name}>{uom.displayText}</option>];
                    } else {
                      return <option key={uom.name}>{uom.displayText}</option>;
                    }
                  })}
                </Form.Control>
              </Col>
            </Row>

            <Row className={styles.rowSpacing}>
              <Col lg={3}>
                <span id="methodEntryTargetSelectLabel">Target(s)</span>
                <Dropdown>
                  <Dropdown.Toggle
                    disabled={this.state.methodTargets.length === 0}
                    className={styles.selectProtectsDropdown}
                  >
                    Select Damage Agents
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                      {this.state.methodTargets.map((target, index) => (
                        <div
                          key={`formChecklistContainerProtections-${target.targetedSpeciesName}`}
                          className={styles.formCheckContainer}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input ml-0"
                            id={`${target.targetedSpeciesName}${target.targetedSpeciesUniqueReference}`}
                            key={`inline-${target.targetedSpeciesName}${target.targetedSpeciesUniqueReference}`}
                            onChange={(e) => this.handleTargetsSelection(e, target, index)}
                            checked={this.state.methodChosenTargets.find(
                              (t) => t.targetedSpeciesUniqueReference === target.targetedSpeciesUniqueReference
                            )}
                            data-toggle="button"
                          />
                          <p className="form-check-label ml-4" htmlFor="itemChecked">
                            {target.targetedSpeciesName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col lg={3}>
                <span id="methodEntryProtectSelectLabel">Protecting</span>
                <Dropdown>
                  <Dropdown.Toggle
                    disabled={this.state.methodProtects?.length === 0}
                    className={styles.selectProtectsDropdown}
                  >
                    Select Resources
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                      {this.state.methodProtects.map((resource, index) => (
                        <div
                          key={`formChecklistContainerProtections-${resource.protectedResourceName}`}
                          className={styles.formCheckContainer}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input ml-0"
                            id={`${resource.protectedResourceName}${resource.protectedResourceUniqueReference}`}
                            key={`inline-${resource.protectedResourceName}${resource.protectedResourceUniqueReference}`}
                            onChange={(e) => this.handleProtectsSelection(e, resource, index)}
                            checked={this.state.methodChosenProtections.find(
                              (p) => p.protectedResourceUniqueReference === resource.protectedResourceUniqueReference
                            )}
                            data-toggle="button"
                          />
                          <p className="form-check-label ml-4" htmlFor="itemChecked">
                            {resource.protectedResourceName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>

              <Col lg={3}>
                <span id="methodEntryEquipmentNameLabel">
                  {data.labelRequired ? "Equipment Label (Required)" : "Equipment Label (Optional)"}
                </span>
                <Form.Control
                  aria-labelledby="methodEntryEquipmentNameLabel"
                  name="methodNickname"
                  type="text"
                  placeholder="Enter name"
                  onChange={this.handleChange}
                  value={data.methodNickname}
                  disabled={data.methodChosenUOM !== "Each" || data.methodQuantity !== "1"}
                />
              </Col>

              <Col lg={3} md={3} sm={3} className={styles.latLongInputs}>
                <div className={styles.divBorder}>
                  <Row>
                    <Col lg={5}>
                      <span id="methodEntryLatitudeLabel">
                        {data.geoRequired ? "Latitude (Required)" : "Latitude (Optional)"}
                      </span>
                    </Col>
                    <Col lg={5}>
                      {" "}
                      <span id="methodEntryLongitudeLabel">
                        {data.geoRequired ? "Longitude (Required)" : "Longitude (Optional)"}
                      </span>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col lg={5}>
                      <Form.Control
                        aria-labelledby="methodEntryLatitudeLabel"
                        name="methodLatitude"
                        type="number"
                        step="0.00001"
                        placeholder="Enter Latitude"
                        onChange={this.handleChange}
                        value={data.methodLatitude}
                        disabled={data.methodChosenUOM !== "Each" || data.methodQuantity !== "1"}
                      />
                    </Col>
                    <Col lg={5}>
                      <Form.Control
                        aria-labelledby="methodEntryLongitudeLabel"
                        name="methodLongitude"
                        type="number"
                        max={data.propertyStateName === "GU" ? "" : "0"}
                        min={data.propertyStateName === "GU" ? "0" : ""}
                        step="0.00001"
                        placeholder="Enter Longitude"
                        onChange={this.handleChange}
                        value={data.methodLongitude}
                        disabled={data.methodChosenUOM !== "Each" || data.methodQuantity !== "1"}
                      />
                    </Col>
                    <Col>
                      <span>
                        <img src={refreshIcon} onClick={this.refreshMap} alt="Refresh Map" />
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className={styles.methodEntryLabelRow}>
              <Col lg={9}></Col>
              <Col lg={3}>
                <Button
                  variant="secondary"
                  className={styles.methodEntryAddButton}
                  onClick={this.addMethodToInventory}
                  disabled={
                    data.methodSearchSelection === "" ||
                    ((data.chosenMethod?.isCMITSMethodGlobally || data.chosenMethod?.isCMITSMethod) &&
                      !data.methodWarehouseSelection) ||
                    (!this.state.isAviationCrewMethodSelected &&
                      (data.methodChosenUse === "" || data.methodChosenUOM === "" || data.methodQuantity === ""))
                  }
                >
                  Add Method
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    ) : (
      <Row className={styles.dummyWorkTaskRow}></Row>
    );
  }

  renderRecordLossTab() {
    return (
      <div>
        <Row>
          <Col className={styles.methodEntryContainer}>
            <Row className="text-left px-2 py-2">
              <Col>
                <span className={styles.tabTitle}>Protected Resource Inventory</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <BootstrapTable
              keyField="protectedResourceId"
              data={this.state.protectedResourcesData}
              columns={this.state.protectedResourcesDataColumns}
              rowStyle={{ height: "35px !important" }}
              bootstrap4={true}
              hover={true}
              bordered={false}
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderAdminWorkTaskTab() {
    return (
      <div>
        <Row>
          <Col className={styles.methodEntryContainer}>
            <Row className="text-left px-2 py-2">
              <Col>
                <span className={styles.tabTitle}>Admin Work Task</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          {this.state.isAdminWorkTaskDataLoading ? (
            <Col>
              <Spinner animation="border" role="status">
                <span className="visually-hidden" aria-label="Loading..."></span>
              </Spinner>
            </Col>
          ) : (
            <Col>
              <BootstrapTable
                keyField="adminWorkTaskUniqueIdentifier"
                data={this.state.adminWorkTaskTableData}
                columns={this.state.adminWorkTaskDataColumns}
                rowStyle={{ height: "35px !important" }}
                bootstrap4={true}
                hover={true}
                bordered={false}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }

  renderRecordTATab() {
    return (
      <div>
        <Row>
          <Col className={styles.methodEntryContainer}>
            <Row className="text-left px-2 py-2">
              <Col>
                <span className={styles.tabTitle}>Cooperator Tech Assist</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          {this.state.isTAWorkTaskDataLoading ? (
            <Col>
              <Spinner animation="border" role="status">
                <span className="visually-hidden" aria-label="Loading..."></span>
              </Spinner>
            </Col>
          ) : (
            <Col>
              <BootstrapTable
                keyField="cooperatorTechnicalAssistWorkTaskUniqueIdentifier"
                data={this.state.cooperatorTAWorkTaskData}
                columns={this.state.cooperatorTAWorkTaskColumns}
                rowStyle={{ height: "35px !important" }}
                bootstrap4={true}
                hover={true}
                bordered={false}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }

  renderRecordSampleTab() {
    const { data } = this.state;
    return (
      <div className="text-left">
        <Row className="mb-2">
          <Col className={styles.methodEntryContainer}>
            <Row className="px-2 py-2">
              <Col>
                <span className={styles.tabTitle}>Sample(s) Recorded</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  addBulkItemsToWorkPerformed(rowObjects) {
    rowObjects.forEach((obj) => {
      let findMethod = this.state.data.propertyMethodInventory.find(
        (method) => method.chosenMethod?.methodName === obj.methodName && method.methodChosenUOM === obj.methodSubUOM
      );
      let item = {
        chosenMethod: findMethod?.chosenMethod,
        methodChosenUse: obj.methodStatus,
        methodChosenUOM: obj.methodSubUOM,
        methodQuantity: obj.methodSubQuantity,
        methodNickname: "",
        methodLatitude: obj.methodSubLatitude,
        methodLongitude: obj.methodSubLatitude,
      };
      this.submitWorkPerformed(item);
    });
  }

  addInventoryItemToWorkPerformed(row, statusUse) {
    let item = {
      chosenMethod: { methodName: row.methodName },
      methodChosenUse: statusUse,
      methodChosenUOM: this.state.allMethodUOMs.find((UOM) => UOM.id === row.deployedWithUnitOfMeasureEnumId)
        .displayText,
      methodQuantity: row.displayQuantity,
      methodNickname: row.locationAlias,
      methodLatitude: row.latitude,
      methodLongitude: row.longitude,
    };
    this.submitWorkPerformed(item);
  }

  submitWorkPerformed(item) {
    let { data } = this.state;
    let findLineItem = data.workPerformed.find(
      (work) =>
        work.chosenMethod?.methodName === item.chosenMethod?.methodName &&
        work.methodChosenUOM === item.methodChosenUOM &&
        work.methodChosenUse === item.methodChosenUse
    );
    if (findLineItem) {
      findLineItem.methodQuantity = parseInt(findLineItem.methodQuantity) + parseInt(item.methodQuantity);
    } else {
      data.workPerformed.push(item);
    }
    this.setState({ data });
  }

  handleGeolocatedAddItems(item, configureAction) {
    item.configurationActionUniqueIdentifier = configureAction.configurationActionUniqueIdentifier;
    this.doWork(item, "ConfigureGeo");
    this.addInventoryItemToWorkPerformed(item, configureAction.configurationAction);
    this.updateInventoryMapItems();
  }

  handleRemoveItems(item, configureAction) {
    item.configurationActionUniqueIdentifier = configureAction.configurationActionUniqueIdentifier;
    this.doWork(item, "Remove");
    this.addInventoryItemToWorkPerformed(item, "Remove");
    this.updateInventoryMapItems();
  }

  async handleNonGeolocatedAddItems(item, rowObjects) {
    let totalQuantity = 0;
    let removeQuantity = 0;
    for (let i = 0; i < rowObjects.length; i++) {
      const obj = rowObjects[i];
      if (obj.configureAction.resultingInventoryStatus === null) {
        removeQuantity += parseInt(obj.setQuantity);
        totalQuantity += parseInt(obj.setQuantity);
        continue;
      }
      totalQuantity += parseInt(obj.setQuantity);
    }
    if (totalQuantity > parseInt(item.displayQuantity)) {
      toast.warning("You cannot take action on a greater quantity of items that are in the Equipment Inventory.");
      return;
    }
    let success = false;
    let workPerformedObjectList = [];
    for (let i = 0; i < rowObjects.length; i++) {
      const obj = rowObjects[i];
      item.configurationActionUniqueIdentifier = obj.configurationActionUniqueIdentifier;
      item.configureIds = [];
      for (let j = 0; j < obj.setQuantity; j++) {
        item.configureIds.push(item?.aggregateDeployedItems[j]?.deployedItemUniqueIdentifier);
      }
      workPerformedObjectList.push({
        methodUniqueReference: item.methodUniqueReference,
        methodAllowedUseEnumId: null,
        methodAllowedUOMId: item.deployedWithUnitOfMeasureEnumId,
        workPerformedType: "Configure",
        configurationActionReference: item.configurationActionUniqueIdentifier,
        deployedItemUniqueReferences: item.configureIds,
        inventoryItemNewNickname: item.locationAlias ? item.locationAlias : null,
        inventoryItemNewLatitude: parseFloat(item.latitude) ? parseFloat(item.latitude) : null,
        inventoryItemNewLongitude: parseFloat(item.longitude) ? parseFloat(item.longitude) : null,
        cooperatorOrVolunteerCheck: item.cooperatorChecked ? true : false,
        warehouseUniqueReference: this.getWarehouseForWorkPerformed(item),
        projectUniqueReferences: this.state.data.selectedProjects?.map((project) => project.value) ?? [],
      });
      item.aggregateDeployedItems = item.aggregateDeployedItems.filter(
        (agg) => !item.configureIds.includes(agg.deployedItemUniqueIdentifier)
      );
      rowObjects = rowObjects.filter((rowObj) => rowObj.configurationActionUniqueIdentifier !== null);
    }
    await this.handleMultipleWorkPerformedCreation(workPerformedObjectList, success);
    if (success) {
      let workPerformedObjectListDeployedIds = workPerformedObjectList.map((w) => w.deployedItemUniqueReference);
      item.aggregateDeployedItems = item?.aggregateDeployedItems?.filter((item) =>
        workPerformedObjectListDeployedIds.includes(item.deployedItemUniqueIdentifier)
      );
    } else {
      this.refreshPropertyMethodInventory();
      this.refreshPVSObject();
    }
  }

  handlePropertyMethodInventoryRowOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      this.setState(() => ({
        propertyMethodInventoryRowExpanded: [
          ...this.state.propertyMethodInventoryRowExpanded,
          row?.methodUniqueIdentifier,
        ],
      }));
    } else {
      this.setState(() => ({
        propertyMethodInventoryRowExpanded: this.state.propertyMethodInventoryRowExpanded.filter(
          (x) => x !== row?.methodUniqueIdentifier
        ),
      }));
    }
  };

  handlePropertyMethodInventoryRowOnSelect = (row, isSelect) => {
    if (isSelect) {
      row.propertyMethodInventorySubTableSelected = [];
      for (let i = 0; i < row.propertyMethodInventorySubTable.length; i++) {
        row.propertyMethodInventorySubTableSelected.push(
          row?.propertyMethodInventorySubTable[i]?.deployedItemUniqueIdentifier
        );
      }
      this.setState(
        {
          propertyMethodInventoryRowSelected: [
            ...this.state.propertyMethodInventoryRowSelected,
            row?.methodUniqueIdentifier,
          ],
        },
        () => this.checkForEnableGlobalActions()
      );
    } else {
      row.propertyMethodInventorySubTableSelected = [];
      this.setState(
        {
          propertyMethodInventoryRowSelected: this.state.propertyMethodInventoryRowSelected.filter(
            (x) => x !== row?.methodUniqueIdentifier
          ),
        },
        () => this.checkForEnableGlobalActions()
      );
    }
    this.updateInventoryMapItems();
  };

  handleSubRowOnSelect = (row, isSelect) => {
    let { data } = this.state;
    let propertyMethodInventory = data.propertyMethodInventory;
    let deployedItemUniqueIdentifier = row.deployedItemUniqueIdentifier;
    let methodUniqueIdentifier = row.methodUniqueReference;
    let removeParentSelect = false;
    if (isSelect) {
      if (!this.state.propertyMethodInventoryRowSelected.includes(methodUniqueIdentifier)) {
        this.setState({
          propertyMethodInventoryRowSelected: [
            ...this.state.propertyMethodInventoryRowSelected,
            methodUniqueIdentifier,
          ],
        });
      }
      propertyMethodInventory.filter(function (item) {
        if (item.methodUniqueIdentifier === row.methodUniqueReference) {
          item.propertyMethodInventorySubTableSelected.push(deployedItemUniqueIdentifier);
        }
        return item;
      });
      this.setState({ data }, () => {
        this.checkForEnableGlobalActions();
      });
    } else {
      propertyMethodInventory.filter(function (item) {
        if (item.methodUniqueIdentifier === row.methodUniqueReference) {
          item.propertyMethodInventorySubTableSelected = item.propertyMethodInventorySubTableSelected.filter(
            (x) => x !== row?.deployedItemUniqueIdentifier
          );
        }
        if (item.propertyMethodInventorySubTableSelected.length < 1) {
          removeParentSelect = true;
        }
        return item;
      });
      if (removeParentSelect) {
        this.setState({
          propertyMethodInventoryRowSelected: this.state.propertyMethodInventoryRowSelected.filter(
            (x) => x !== row?.methodUniqueReference
          ),
        });
      }
      this.setState({ data }, () => {
        this.checkForEnableGlobalActions();
      });
    }
    this.updateInventoryMapItems();
  };

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const parentIds = rows.map((row) => {
        row.propertyMethodInventorySubTableSelected = [];
        row.propertyMethodInventorySubTable.forEach((subRow) => {
          row.propertyMethodInventorySubTableSelected.push(subRow.deployedItemUniqueIdentifier);
        });
        return row.methodUniqueIdentifier;
      });
      this.setState({ propertyMethodInventoryRowSelected: parentIds }, () => {
        this.checkForEnableGlobalActions();
      });
    } else {
      rows.forEach((row) => {
        row.propertyMethodInventorySubTableSelected = [];
      });
      this.setState({ propertyMethodInventoryRowSelected: [] }, () => {
        this.checkForEnableGlobalActions();
      });
    }
    this.updateInventoryMapItems();
  };

  updateInventoryMapItems() {
    const { data } = this.state;
    let selectedInventoryItems = [];
    for (let method of data.propertyMethodInventory) {
      if (method.propertyMethodInventorySubTableSelected?.length > 0) {
        // eslint-disable-next-line no-loop-func
        method.propertyMethodInventorySubTable.forEach((item) => {
          if (
            method.propertyMethodInventorySubTableSelected.includes(item.deployedItemUniqueIdentifier) &&
            item.latitude &&
            item.longitude
          ) {
            selectedInventoryItems.push(item);
          }
        });
      }
    }
    selectedInventoryItems = this.populateDatesOnMethods(selectedInventoryItems);
    this.setState({ inventoryMapItems: selectedInventoryItems });
  }

  async handleGlobalAddItems(configureAction) {
    let success = false;
    let createdWorkTask = await this.createWorkTaskOnPVSBeforeCreatingWorkPerformed();
    if (!createdWorkTask) {
      return;
    }
    const propertyMethodInventoryRowSelected = this.state.propertyMethodInventoryRowSelected;
    const propertyMethodInventory = this.state.data.propertyMethodInventory;
    let workPerformedObjectGlobalList = [];
    for (let i = 0; i < propertyMethodInventoryRowSelected.length; i++) {
      const selectedMethodId = propertyMethodInventoryRowSelected[i];
      const selectedMethod = propertyMethodInventory.find(
        (method) => method.methodUniqueIdentifier === selectedMethodId
      );
      const selectedSubRowList = selectedMethod?.propertyMethodInventorySubTableSelected;
      for (let j = 0; j < selectedSubRowList?.length; j++) {
        const selectedSubRowDeployedItemId = selectedSubRowList[j];
        const selectedSubRow = selectedMethod?.propertyMethodInventorySubTable.find(
          (row) => row.deployedItemUniqueIdentifier === selectedSubRowDeployedItemId
        );
        workPerformedObjectGlobalList.push({
          methodUniqueReference: selectedSubRow.methodUniqueReference,
          methodAllowedUseEnumId: null,
          methodAllowedUOMId: selectedSubRow.deployedWithUnitOfMeasureEnumId,
          workPerformedType: "Configure",
          configurationActionReference: configureAction.configurationActionUniqueIdentifier,
          deployedItemUniqueReferences: selectedSubRow?.aggregateDeployedItems?.map(
            (a) => a.deployedItemUniqueIdentifier
          ),
          inventoryItemNewNickname: selectedSubRow.locationAlias ? selectedSubRow.locationAlias : null,
          inventoryItemNewLatitude: parseFloat(selectedSubRow.latitude) ? parseFloat(selectedSubRow.latitude) : null,
          inventoryItemNewLongitude: parseFloat(selectedSubRow.longitude) ? parseFloat(selectedSubRow.longitude) : null,
          cooperatorOrVolunteerCheck: selectedSubRow.cooperatorChecked ? true : false,
          warehouseUniqueReference: this.getWarehouseForWorkPerformed(selectedSubRow),
          projectUniqueReferences: this.state.data.selectedProjects?.map((project) => project.value) ?? [],
        });
        selectedSubRow.aggregateDeployedItems = [];
      }
    }
    await this.handleMultipleWorkPerformedCreation(workPerformedObjectGlobalList, success);
    if (!success) {
      this.refreshPropertyMethodInventory();
      this.refreshPVSObject();
    }
  }

  checkForEnableGlobalActions() {
    let propertyMethodInventory = this.state.data.propertyMethodInventory;
    let propertyMethodInventoryRowSelected = this.state.propertyMethodInventoryRowSelected;
    let propertyMethodInventoryGlobalActions = this.state.propertyMethodInventoryGlobalActions;
    let checkedSubRowsArray = [];
    for (let i = 0; i < propertyMethodInventory.length; i++) {
      checkedSubRowsArray = checkedSubRowsArray.concat(
        propertyMethodInventory[i].propertyMethodInventorySubTableSelected
      );
    }
    if (checkedSubRowsArray.length > 0 || propertyMethodInventoryRowSelected.length > 0) {
      for (let i = 0; i < propertyMethodInventoryRowSelected.length; i++) {
        let row = propertyMethodInventory.find(
          (method) => method.methodUniqueIdentifier === propertyMethodInventoryRowSelected[i]
        );
        if (row) {
          for (let j = 0; j < row.propertyMethodInventorySubTable[0].configureActions.length; j++) {
            let rowAction = row.propertyMethodInventorySubTable[0].configureActions[j];
            if (
              propertyMethodInventoryGlobalActions.find(
                (action) => action.configurationActionUniqueIdentifier === rowAction.configurationActionUniqueIdentifier
              )
            ) {
              continue;
            } else {
              propertyMethodInventoryGlobalActions.push(rowAction);
            }
          }
        }
      }
      this.setState({
        propertyMethodInventoryGlobalActions: propertyMethodInventoryGlobalActions,
        globalActionsDropdownDisabled: false,
        globalAddItemsDisabled: false,
      });
    } else {
      this.setState({
        propertyMethodInventoryGlobalActions: [],
        globalActionsDropdownDisabled: true,
        globalAddItemsDisabled: true,
      });
    }
  }

  manageProjectsForWorkPerformed = (workPerformedRow) => {
    this.setState({ manageProjectsData: workPerformedRow, showManageProjectsModal: true });
  };

  manageWorkPerformed = (workPerformedRow) => {
    this.setState({ manageWorkPerformedData: workPerformedRow, showManageWorkPerformedModal: true });
  };

  enterTake = (workItem) => {
    const enterTakeData = {
      workItem,
      propertyData: this.state.data.propertyData,
      propertyConflicts: this.state.propertyConflicts,
    };
    this.setState({ enterTakeData, showEnterTakeModal: true });
  };

  editTake = (workItem) => {
    const enterTakeData = {
      isEditForm: true,
      workItem,
      propertyData: this.state.data.propertyData,
      propertyConflicts: this.state.propertyConflicts,
    };
    this.setState({ enterTakeData, showEnterTakeModal: true });
  };

  deleteTake = async (takeId) => {
    this.setState({ isDataLoading: true });
    const { propertyVisitSummaryObj } = this.state;
    const removeTakeResponse = await WorkTaskAPI.RemoveTake(takeId);
    if (removeTakeResponse?.successful) {
      toast.success("Take was succesfully removed.");
      await this.refreshPVSObject();
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to remove take",
        apiName: "RemoveTake",
        responseUnsuccessful: removeTakeResponse?.unsuccessful,
        responseMessage: removeTakeResponse?.message,
      });
    }
    this.setState({ propertyVisitSummaryObj, isDataLoading: false });
  };

  renderWorkPerformedTable() {
    const workPerformedData =
      this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workPerformed?.length > 0
        ? this.state.propertyVisitSummaryObj.dcWorkTaskPerformed.workPerformed
        : [];
    return (
      <div className={styles.workPerformedTableContainer}>
        <Row className="mb-2">
          <Col className={styles.workPerformedHeaderRow}>
            <span className={styles.workPerformedHeader}>Work Performed</span>
          </Col>
        </Row>
        <ToolkitProvider
          keyField="workPerformedUniqueIdentifier"
          data={workPerformedData}
          columns={this.state.workPerformedDataColumns}
          bootstrap4={true}
          hover={true}
        >
          {(props) => (
            <div>
              <BootstrapTable
                keyField="workPerformedUniqueIdentifier"
                wrapperClasses="siteVisit-workPerformed-table"
                bordered={false}
                condensed
                expandRow={this.getExpandRowObject()}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }

  getExpandRowObject = () => {
    return {
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "hidden",
      },
      renderer: (row) => {
        return (
          <div className={styles.workPerformedExpandedContent}>
            <Row className={styles.workPerformedExpandedData}>
              <Col>
                <span className={styles.smallGreenText}>Take(s) Entered</span>
              </Col>
            </Row>
            <Row className={styles.workPerformedExpandedData}>
              <Col>
                <ToolkitProvider
                  keyField="takeUniqueIdentifier"
                  data={row.takesData?.length > 0 ? row.takesData : []}
                  columns={this.state.workPerformedTakesColumns}
                  bootstrap4={true}
                  hover={true}
                >
                  {(props) => (
                    <div>
                      <BootstrapTable keyField="takeUniqueIdentifier" bordered={false} condensed {...props.baseProps} />
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </div>
        );
      },
      showExpandColumn: true,
      expandByColumnOnly: true,
      nonExpandable: this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workPerformed
        ?.filter((wp) => !(wp.takesData?.length > 0))
        .map((w) => w.workPerformedUniqueIdentifier),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b></b>;
        }
        return <b></b>;
      },
      expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
        let icon = <Image className={styles.chevron} src={ChevronRight} alt="right arrow" />;
        if (expanded) {
          icon = <Image className={styles.chevron} src={ChevronDown} alt="down arrow" />;
        }
        return expandable ? icon : null;
      },
    };
  };

  removeWorkPerformed = async (workPerformedGuid) => {
    const removeWorkCall = await WorkTaskAPI.RemoveWorkPerformedFromWorkTask(workPerformedGuid);
    if (removeWorkCall?.successful) {
      toast.success("Work Performed removed.");
      await this.refreshPVSObject();
      await this.refreshPropertyMethodInventory();
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to work performed",
        apiName: "RemoveWorkPerformedFromWorkTask",
        responseUnsuccessful: removeWorkCall?.unsuccessful,
        responseMessage: removeWorkCall?.message,
      });
    }
  };

  loadAvailableUsers = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
    const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText(searchObj);
    let outputContacts = [];
    if (contactsData?.data?.results?.length > 0) {
      let results = contactsData.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
              wsUserUniqueIdentifier: user.wsUserUniqueIdentifier,
              userProperties: user.userProperties,
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    return outputContacts;
  };

  setActiveWorkTaskType = (e) => {
    this.setState({ activeWorkTaskType: parseInt(e) });
  };

  pvsDateOnChange = (e) => {
    $("#ui-datepicker-div").hide();
  };

  pvsDateOnSelect = (e) => {
    if (this.state.data.agreementData?.agreementSignatureDate) {
      $("#ui-datepicker-div").show();
    } else {
      $("#ui-datepicker-div").hide();
      toast.error("Property Agreement must be signed to enter a date for Site Visit.");
    }
  };

  createConflict = () => {
    if (!this.state.propertyVisitSummaryObj) {
      toast.warning("Site Visit must be created first before Conflicts are added.");
    } else {
      this.setState({ showCreateConflictModal: true });
    }
  };

  renderCreateConflictModal() {
    let modal = null;
    modal = (
      <CreateConflictModal
        isModal={true}
        show={this.state.showCreateConflictModal}
        onHide={this.onCreateConflictModalClose}
        propertyGuid={this.state.data.propertyData?.propertyUniqueIdentifier}
        existingConflicts={this.state.propertyConflicts}
        agreementData={this.state.data.agreementData}
      />
    );
    return modal;
  }

  onCreateConflictModalClose = async (conflict) => {
    this.setState({ showCreateConflictModal: false, checkedForConflicts: false });
    await this.addConflictToPVS(conflict);
    await this.populateConflicts();
  };

  selectConflict = async (e, conflict) => {
    if (!this.state.propertyVisitSummaryObj) {
      e.preventDefault();
      toast.warning("Site Visit must be created first before Conflicts are added.");
      return;
    }
    this.setState({ isConflictSelectionLoading: true });
    if (e.target.checked) {
      await this.addConflictToPVS(conflict);
    } else {
      await this.removeConflictFromPVS(conflict);
    }
    this.setState({ isConflictSelectionLoading: false });
  };

  async addConflictToPVS(conflict) {
    if (!conflict) {
      return;
    }
    let { data } = this.state;
    if (!this.state.propertyVisitSummaryObj) {
      toast.warning("Site Visit must be created first before Conflicts are added.");
      return;
    }
    let targetProtectedResources = [];
    conflict.properties.forEach((property) => {
      property.protectedResources.forEach((resource) => {
        const effortItem = data.resourceEffortList.find(
          (resourceEffort) =>
            resourceEffort.resourceUniqueReference === resource.resourceUniqueReference &&
            resourceEffort.valuePerUnitOfMeasure === resource.valuePerUnitOfMeasure
        );
        if (!effortItem) {
          data.resourceEffortList.push(resource);
        }
        targetProtectedResources.push({
          protectedResourceUniqueReference: resource.protectedResourceUniqueReference,
        });
      });
    });
    let body = {
      conflictUniqueReference: conflict.conflictUniqueIdentifier,
      targetSpeciesUniqueReference: conflict.targetedSpeciesUniqueReference,
      targetProtectedResources: targetProtectedResources,
    };
    let pvsGuid = this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier;
    const addConflictToPVSCall = await WorkTaskAPI.AddConflictToPVS(pvsGuid, body);
    if (addConflictToPVSCall?.successful) {
      toast.success("Conflict added to Site Visit");
      this.setState({ data });
      this.equalizeResourceEffortValues();
      this.checkForEnablePreviousEffortRatio(addConflictToPVSCall.data);
      await this.refreshPVSObject(addConflictToPVSCall);
    } else if (addConflictToPVSCall?.message.includes("already")) {
      this.setState({ data });
      this.equalizeResourceEffortValues();
      toast.success("Conflict is already associated with Site Visit.");
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to add conflict to Site Visit",
        apiName: "AddConflictToPVS",
        responseUnsuccessful: addConflictToPVSCall?.unsuccessful,
        responseMessage: addConflictToPVSCall?.message,
      });
    }
  }

  async removeConflictFromPVS(conflict) {
    if (!conflict) {
      return;
    }
    let { data } = this.state;
    if (!this.state.propertyVisitSummaryObj) {
      toast.warning("Site Visit must be created first before Conflicts are removed.");
      return;
    }
    let pvsGuid = this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier;
    const removeConflictToPVSCall = await WorkTaskAPI.RemoveConflictsFromPVS(pvsGuid, [
      conflict.conflictUniqueIdentifier,
    ]);
    if (removeConflictToPVSCall?.successful) {
      toast.success("Conflict removed from to Site Visit");
      this.removeResourcesFromResourceEffortList(conflict, removeConflictToPVSCall.data);
      this.checkForEnablePreviousEffortRatio(removeConflictToPVSCall.data);
      await this.refreshPVSObject(removeConflictToPVSCall);
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to remove conflict from Site Visit",
        apiName: "RemoveConflictsFromPVS",
        responseUnsuccessful: removeConflictToPVSCall?.unsuccessful,
        responseMessage: removeConflictToPVSCall?.message,
      });
    }
    this.setState({ data });
    this.equalizeResourceEffortValues();
  }

  removeResourcesFromResourceEffortList(conflictRemoved, removeConflictToPVSCallData) {
    let { data } = this.state;
    let associatedResources = this.getResourceListFromPropertyConflicts(
      removeConflictToPVSCallData?.associatedConflicts ?? [],
      false
    );
    let targetProtectedResourcesAndValuationsToRemove = [];
    conflictRemoved.properties.forEach((property) => {
      property.protectedResources.forEach((resource) => {
        targetProtectedResourcesAndValuationsToRemove.push(resource);
      });
    });
    associatedResources.forEach((r) => {
      if (
        targetProtectedResourcesAndValuationsToRemove.some(
          (resourceAndValuation) =>
            r.resourceUniqueReference === resourceAndValuation.resourceUniqueReference &&
            r.valuePerUnitOfMeasure === resourceAndValuation.valuePerUnitOfMeasure
        )
      ) {
        targetProtectedResourcesAndValuationsToRemove = targetProtectedResourcesAndValuationsToRemove.filter(
          (resourceAndValuation) =>
            !(
              r.resourceUniqueReference === resourceAndValuation.resourceUniqueReference &&
              r.valuePerUnitOfMeasure === resourceAndValuation.valuePerUnitOfMeasure
            )
        );
      }
    });
    data.resourceEffortList = data.resourceEffortList.filter((r) =>
      targetProtectedResourcesAndValuationsToRemove.length > 0
        ? targetProtectedResourcesAndValuationsToRemove.every(
          (resourceAndValuation) =>
            !(
              r.resourceUniqueReference === resourceAndValuation.resourceUniqueReference &&
              r.valuePerUnitOfMeasure === resourceAndValuation.valuePerUnitOfMeasure
            )
        )
        : true
    );
  }

  renderConflictList() {
    return (
      <div>
        {this.state.propertyConflicts.map((conflict, index) => {
          let allProtectedResourcesString = "";
          for (var i = 0; i < conflict?.properties?.length; i++) {
            let currentProperty = conflict.properties[i];
            for (var j = 0; j < currentProperty.protectedResources.length; j++) {
              let currentResource = currentProperty.protectedResources[j];
              allProtectedResourcesString += currentResource.protectedResourceName + " + ";
            }
          }
          allProtectedResourcesString = allProtectedResourcesString.slice(0, -2);
          const fullString = allProtectedResourcesString;
          const cutoffLength = 72;
          if (allProtectedResourcesString.length > cutoffLength) {
            allProtectedResourcesString = allProtectedResourcesString.substring(0, cutoffLength - 4) + "...";
          }
          const conflictChecked = this.state.propertyVisitSummaryObj?.associatedConflicts?.find(
            (associatedConflict) => associatedConflict.conflictUniqueReference === conflict.conflictUniqueIdentifier
          );

          return (
            <Row className={styles.conflictCardRow} key={"conflictPVS" + index}>
              <Form.Check
                className={styles.conflictCheckbox}
                onChange={(e) => this.selectConflict(e, conflict)}
                checked={conflictChecked}
                disabled={!this.state.isPageEditable && this.state.isDetailsPage}
              />
              <Button className={styles.conflictSpeciesResources} variant="link" alt={fullString}>
                {conflict.targetedSpeciesName} - {allProtectedResourcesString}
              </Button>
            </Row>
          );
        })}
      </div>
    );
  }

  renderResourceEffortList() {
    return (
      <div>
        <Row>
          <Col align="left" className={styles.resourceEffortName} style={{ minHeight: "34px" }}>
            {this.state.displayResourceEffortWarning && (
              <React.Fragment>
                <span>
                  <i
                    className="fa fa-exclamation-circle"
                    style={{ color: "gray" }}
                    title="You've manually changed each Resource Effort, therefore no auto-calculation is taking place"
                    aria-hidden="true"
                  ></i>
                </span>
                {" Sum of Resource Effort not equal to 100%"}
              </React.Fragment>
            )}
          </Col>
        </Row>
        {this.state.data.resourceEffortList.map((resource, index) => {
          let disabled = !this.state.isPageEditable && this.state.isDetailsPage;
          return (
            <Row className={styles.conflictCardRow} key={index}>
              <Col lg={6} align="left">
                <p className={styles.resourceEffortName}>
                  {resource.protectedResourceName + " | Unit Value: " + resource.valuePerUnitOfMeasure}
                </p>
              </Col>
              <Col lg={2} className={styles.resourceEffortColumns}>
                <span className={styles.percentSign}>%</span>
                <Form.Control
                  className={styles.resourceEffortInput}
                  id={resource.protectedResourceUniqueReference + "percentInput"}
                  key={resource.protectedResourceUniqueReference + "percentInputKey"}
                  title={resource.protectedResourceName + "Percent Input Field"}
                  min="0"
                  max="100"
                  as="input"
                  step="1"
                  type="number"
                  onChange={(e) => this.handleResourceEffortInputValueChange(e, resource, "percent")}
                  disabled={disabled}
                />
              </Col>
              <Col lg={2} className={styles.resourceEffortColumns}>
                <Form.Control
                  className={styles.resourceEffortInput}
                  id={resource.protectedResourceUniqueReference + "hourInput"}
                  key={resource.protectedResourceUniqueReference + "hourInputKey"}
                  title={resource.protectedResourceName + "Hours Input Field"}
                  min="0"
                  as="input"
                  type="number"
                  placeholder="Hours"
                  onChange={(e) => this.handleResourceEffortInputValueChange(e, resource, "hours")}
                  disabled={disabled}
                />
              </Col>
              <Col lg={2} className={styles.resourceEffortColumns}>
                <Form.Control
                  className={styles.resourceEffortInput}
                  id={resource.protectedResourceUniqueReference + "minuteInput"}
                  key={resource.protectedResourceUniqueReference + "minuteInputKey"}
                  title={resource.protectedResourceName + "Minutes Input Field"}
                  min="0"
                  max="59"
                  as="input"
                  type="number"
                  placeholder="Minutes"
                  onChange={(e) => this.handleResourceEffortInputValueChange(e, resource, "minutes")}
                  disabled={disabled}
                />
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }

  handleCheckPreviousEffortRatio = (e) => {
    if ((parseInt(this.state.data.pvsHours) || parseInt(this.state.data.pvsMinutes)) && e.target.checked) {
      this.mapUserPropertiesEffortRatiosToCurrentEffortRatioList();
    } else if (!e.target.checked) {
      this.equalizeResourceEffortValues();
    } else {
      toast.warning("Please fill out time on property before applying the previous effort ratios.");
    }
  };

  mapUserPropertiesEffortRatiosToCurrentEffortRatioList() {
    const userProperty = this.state.performedBy?.userProperties?.find(
      (property) =>
        property.propertyUniqueReference === this.state.propertyVisitSummaryObj?.propertyVisitedUniqueReference
    );
    if (userProperty) {
      if (userProperty.propertyResources.length < 1) {
        toast.warning("There are no previously recorded resource effort ratios found on the user record.");
      } else {
        userProperty.propertyResources.forEach((resource) => {
          resource.percentageEffort = resource.ratio;
          this.handleResourceEffortInputValueChange({ target: { value: resource.ratio } }, resource, "percent");
        });
      }
    } else {
      toast.warning("Could not find user's previous effort ratios.");
    }
  }

  checkForEnablePreviousEffortRatio = (comparePVS) => {
    if (this.state.previousPVS && comparePVS) {
      let previousResources = [];
      this.state.previousPVS.associatedConflicts.forEach((conflict) => {
        conflict.targetProtectedResources.forEach((resource) => {
          previousResources.push(resource);
        });
      });
      let mostRecentResources = [];
      comparePVS.associatedConflicts.forEach((conflict) => {
        conflict.targetProtectedResources.forEach((resource) => {
          mostRecentResources.push(resource);
        });
      });
      this.setState({
        ableToUsePreviousEffortRatio: this.checkForSameResources(previousResources, mostRecentResources),
      });
    } else {
      this.setState({ ableToUsePreviousEffortRatio: false });
    }
  };

  checkForSameResources(a, b) {
    if (a.length !== b.length) {
      return false;
    }
    if (a === null || b === null) {
      return false;
    }
    a = a.sort(function (a, b) {
      return ("" + a.protectedResourceUniqueReference).localeCompare(b.protectedResourceUniqueReference);
    });
    b = b.sort(function (a, b) {
      return ("" + a.protectedResourceUniqueReference).localeCompare(b.protectedResourceUniqueReference);
    });
    for (let i = 0; i < a.length; i++) {
      if (a[i].protectedResourceUniqueReference !== b[i].protectedResourceUniqueReference) {
        return false;
      }
    }
    return true;
  }

  equalizeResourceEffortValues = () => {
    let { data } = this.state;
    const totalMinutes = parseInt(data.pvsHours * 60) + parseInt(data.pvsMinutes);
    for (let i = 0; i < data?.resourceEffortList?.length; i++) {
      let effortItem = data.resourceEffortList[i];
      effortItem.manuallyChanged = false;
      const effortItemPercentElementId = effortItem.protectedResourceUniqueReference + "percentInput";
      const effortItemHourElementId = effortItem.protectedResourceUniqueReference + "hourInput";
      const effortItemMinuteElementId = effortItem.protectedResourceUniqueReference + "minuteInput";
      let percentElement = document.getElementById(effortItemPercentElementId);
      let hourElement = document.getElementById(effortItemHourElementId);
      let minuteElement = document.getElementById(effortItemMinuteElementId);
      let effortItemAllottedTime = (totalMinutes ? totalMinutes : 0) / data.resourceEffortList.length;
      if (percentElement) {
        effortItem.percentageEffort = 100 / data.resourceEffortList.length;
        percentElement.value = Math.round(effortItem.percentageEffort);
      }
      if (hourElement && effortItemAllottedTime) {
        effortItem.hoursEffort = Math.floor(effortItemAllottedTime / 60);
        hourElement.value = Math.round(effortItem.hoursEffort * 10) / 10;
      }
      if (minuteElement && effortItemAllottedTime) {
        effortItem.minuteEffort = effortItemAllottedTime % 60;
        minuteElement.value = Math.round(effortItem.minuteEffort * 10) / 10;
      }
    }
    this.setState({ data });
  };

  handleResourceEffortInputValueChange = (e, resource, type) => {
    let { data } = this.state;
    const totalMinutes = parseFloat(data.pvsHours * 60) + parseFloat(data.pvsMinutes);
    resource.manuallyChanged = true;
    if (type === "percent") {
      if (parseInt(e.target.value) > 100) {
        const effortItemPercentElementId = resource.protectedResourceUniqueReference + "percentInput";
        let percentElement = document.getElementById(effortItemPercentElementId);
        percentElement.value = resource.percentageEffort;
        return;
      }
      if (!e.target.value) {
        this.zeroOutInputsForResourceEffort(resource);
      }
      const resourceTotalMinutes = (parseFloat(e.target.value) / 100) * totalMinutes;
      let totalResultPercent = parseInt(e.target.value);
      for (let i = 0; i < data?.resourceEffortList?.length; i++) {
        let effortItem = data.resourceEffortList[i];
        if (
          effortItem.protectedResourceUniqueReference === resource.protectedResourceUniqueReference &&
          !(totalResultPercent > 100 || totalResultPercent < 0)
        ) {
          const effortItemPercentElementId = effortItem.protectedResourceUniqueReference + "percentInput";
          const effortItemHourElementId = effortItem.protectedResourceUniqueReference + "hourInput";
          const effortItemMinuteElementId = effortItem.protectedResourceUniqueReference + "minuteInput";
          let percentElement = document.getElementById(effortItemPercentElementId);
          let hourElement = document.getElementById(effortItemHourElementId);
          let minuteElement = document.getElementById(effortItemMinuteElementId);
          effortItem.hoursEffort = Math.floor(resourceTotalMinutes / 60);
          hourElement.value = Math.round(effortItem.hoursEffort * 10) / 10;
          effortItem.minuteEffort = resourceTotalMinutes % 60;
          minuteElement.value = Math.round(effortItem.minuteEffort * 10) / 10;
          effortItem.percentageEffort = parseInt(e.target.value);
          percentElement.value = Math.round(effortItem.percentageEffort);
          continue;
        }
        totalResultPercent += effortItem.percentageEffort;
      }
      const amountNotManuallyChanged = data?.resourceEffortList?.filter((r) => !r.manuallyChanged)?.length;
      if (amountNotManuallyChanged === 0) {
        this.setState({ displayResourceEffortWarning: totalResultPercent > 100 || totalResultPercent < 100 });
        return;
      }
      if (totalResultPercent > 100 || totalResultPercent < 100) {
        let percentageToRedistribute = Math.abs(totalResultPercent - 100);
        let perResourcePercentageDifference = percentageToRedistribute / amountNotManuallyChanged;
        let perResourceMinuteDifference = (perResourcePercentageDifference / 100) * totalMinutes;
        for (let i = 0; i < data?.resourceEffortList?.length; i++) {
          let effortItem = data.resourceEffortList[i];
          if (effortItem.protectedResourceUniqueReference === resource.protectedResourceUniqueReference) {
            continue;
          }
          this.changeResourceEffortPercent(
            effortItem,
            totalResultPercent,
            perResourcePercentageDifference,
            perResourceMinuteDifference,
            !effortItem.manuallyChanged
          );
        }
      }
    }
    if (type === "hours") {
      let resourceTotalMinutes = parseFloat(e.target.value) * 60 + resource.minuteEffort;
      if (resourceTotalMinutes > totalMinutes) {
        const effortItemHourElementId = resource.protectedResourceUniqueReference + "hourInput";
        let hourElement = document.getElementById(effortItemHourElementId);
        hourElement.value = resource.hoursEffort;
        return;
      }
      let totalResultingTimeInMinutes = resourceTotalMinutes;
      if (!e.target.value) {
        resourceTotalMinutes = 0;
        this.zeroOutInputsForResourceEffort(resource);
      }
      for (let i = 0; i < data?.resourceEffortList?.length; i++) {
        let effortItem = data.resourceEffortList[i];
        if (
          effortItem.protectedResourceUniqueReference === resource.protectedResourceUniqueReference &&
          !(totalResultingTimeInMinutes > totalMinutes || totalResultingTimeInMinutes < 0)
        ) {
          const effortItemPercentElementId = effortItem.protectedResourceUniqueReference + "percentInput";
          const effortItemHourElementId = effortItem.protectedResourceUniqueReference + "hourInput";
          const effortItemMinuteElementId = effortItem.protectedResourceUniqueReference + "minuteInput";
          let percentElement = document.getElementById(effortItemPercentElementId);
          let hourElement = document.getElementById(effortItemHourElementId);
          let minuteElement = document.getElementById(effortItemMinuteElementId);
          effortItem.hoursEffort = parseFloat(e.target.value);
          hourElement.value = Math.round(effortItem.hoursEffort * 10) / 10;
          effortItem.minuteEffort = resourceTotalMinutes % 60;
          minuteElement.value = Math.round(effortItem.minuteEffort * 10) / 10;
          effortItem.percentageEffort = (resourceTotalMinutes / totalMinutes) * 100;
          percentElement.value = Math.round(effortItem.percentageEffort);
          continue;
        }
        totalResultingTimeInMinutes += effortItem.hoursEffort * 60 + effortItem.minuteEffort;
      }
      const amountNotManuallyChanged = data?.resourceEffortList?.filter((r) => !r.manuallyChanged)?.length;
      if (amountNotManuallyChanged === 0) {
        this.setState({
          displayResourceEffortWarning:
            totalResultingTimeInMinutes > totalMinutes || totalResultingTimeInMinutes < totalMinutes,
        });
        return;
      }
      if (totalResultingTimeInMinutes > totalMinutes || totalResultingTimeInMinutes < totalMinutes) {
        const minutesToRedistribute = Math.abs(totalResultingTimeInMinutes - totalMinutes);
        const perResourceMinuteDifference = minutesToRedistribute / (data?.resourceEffortList?.length - 1);
        const perResourcePercentageDifference = (perResourceMinuteDifference / totalMinutes) * 100;
        for (let i = 0; i < data?.resourceEffortList?.length; i++) {
          let effortItem = data.resourceEffortList[i];
          if (effortItem.protectedResourceUniqueReference === resource.protectedResourceUniqueReference) {
            continue;
          }
          this.changeResourceEffortTime(
            effortItem,
            totalResultingTimeInMinutes,
            totalMinutes,
            perResourcePercentageDifference,
            perResourceMinuteDifference,
            !effortItem.manuallyChanged
          );
        }
      }
    }
    if (type === "minutes") {
      let resourceTotalMinutes = resource.hoursEffort * 60 + parseFloat(e.target.value);
      if (resourceTotalMinutes > totalMinutes) {
        const effortItemMinuteElementId = resource.protectedResourceUniqueReference + "minuteInput";
        let minuteElement = document.getElementById(effortItemMinuteElementId);
        minuteElement.value = resource.minuteEffort;
        return;
      }
      let totalResultingTimeInMinutes = resourceTotalMinutes;
      if (!e.target.value) {
        resourceTotalMinutes = 0;
        this.zeroOutInputsForResourceEffort(resource);
      }
      for (let i = 0; i < data?.resourceEffortList?.length; i++) {
        let effortItem = data.resourceEffortList[i];
        if (
          effortItem.protectedResourceUniqueReference === resource.protectedResourceUniqueReference &&
          !(totalResultingTimeInMinutes > totalMinutes || totalResultingTimeInMinutes < 0)
        ) {
          const effortItemPercentElementId = effortItem.protectedResourceUniqueReference + "percentInput";
          const effortItemHourElementId = effortItem.protectedResourceUniqueReference + "hourInput";
          const effortItemMinuteElementId = effortItem.protectedResourceUniqueReference + "minuteInput";
          let percentElement = document.getElementById(effortItemPercentElementId);
          let hourElement = document.getElementById(effortItemHourElementId);
          let minuteElement = document.getElementById(effortItemMinuteElementId);
          effortItem.hoursEffort = Math.floor(resourceTotalMinutes / 60);
          hourElement.value = Math.round(effortItem.hoursEffort * 10) / 10;
          effortItem.minuteEffort = parseFloat(e.target.value);
          minuteElement.value = Math.round(effortItem.minuteEffort * 10) / 10;
          effortItem.percentageEffort = (resourceTotalMinutes / totalMinutes) * 100;
          percentElement.value = Math.round(effortItem.percentageEffort);
          continue;
        }
        totalResultingTimeInMinutes += effortItem.hoursEffort * 60 + effortItem.minuteEffort;
      }
      const amountNotManuallyChanged = data?.resourceEffortList?.filter((r) => !r.manuallyChanged)?.length;
      if (amountNotManuallyChanged === 0) {
        this.setState({
          displayResourceEffortWarning:
            totalResultingTimeInMinutes > totalMinutes || totalResultingTimeInMinutes < totalMinutes,
        });
        return;
      }
      if (totalResultingTimeInMinutes > totalMinutes || totalResultingTimeInMinutes < totalMinutes) {
        const minutesToRedistribute = Math.abs(totalResultingTimeInMinutes - totalMinutes);
        const perResourceMinuteDifference = minutesToRedistribute / (data?.resourceEffortList?.length - 1);
        const perResourcePercentageDifference = (perResourceMinuteDifference / totalMinutes) * 100;
        for (let i = 0; i < data?.resourceEffortList?.length; i++) {
          let effortItem = data.resourceEffortList[i];
          if (effortItem.protectedResourceUniqueReference === resource.protectedResourceUniqueReference) {
            continue;
          }
          this.changeResourceEffortTime(
            effortItem,
            totalResultingTimeInMinutes,
            totalMinutes,
            perResourcePercentageDifference,
            perResourceMinuteDifference,
            !effortItem.manuallyChanged
          );
        }
      }
    }
  };

  zeroOutInputsForResourceEffort(resource) {
    const effortItemHourElementId = resource.protectedResourceUniqueReference + "hourInput";
    let hourElement = document.getElementById(effortItemHourElementId);
    hourElement.value = 0;
    const effortItemMinuteElementId = resource.protectedResourceUniqueReference + "minuteInput";
    let minuteElement = document.getElementById(effortItemMinuteElementId);
    minuteElement.value = 0;
    const effortItemPercentElementId = resource.protectedResourceUniqueReference + "percentInput";
    let percentElement = document.getElementById(effortItemPercentElementId);
    percentElement.value = 0;
  }

  changeResourceEffortPercent(
    effortItem,
    totalResultPercent,
    perResourcePercentageDifference,
    perResourceMinuteDifference,
    condition
  ) {
    if (condition) {
      const effortItemPercentElementId = effortItem.protectedResourceUniqueReference + "percentInput";
      const effortItemHourElementId = effortItem.protectedResourceUniqueReference + "hourInput";
      const effortItemMinuteElementId = effortItem.protectedResourceUniqueReference + "minuteInput";
      let percentElement = document.getElementById(effortItemPercentElementId);
      let hourElement = document.getElementById(effortItemHourElementId);
      let minuteElement = document.getElementById(effortItemMinuteElementId);
      effortItem.percentageEffort =
        totalResultPercent > 100
          ? effortItem.percentageEffort - perResourcePercentageDifference
          : effortItem.percentageEffort + perResourcePercentageDifference;
      percentElement.value = Math.round(effortItem.percentageEffort);
      const currentResourceTotalMinutes =
        totalResultPercent > 100
          ? parseFloat(hourElement.value) * 60 + parseFloat(minuteElement.value) - perResourceMinuteDifference
          : parseFloat(hourElement.value) * 60 + parseFloat(minuteElement.value) + perResourceMinuteDifference;
      effortItem.hoursEffort = Math.floor(currentResourceTotalMinutes / 60);
      hourElement.value = Math.round(effortItem.hoursEffort * 10) / 10;
      effortItem.minuteEffort = currentResourceTotalMinutes % 60;
      minuteElement.value = Math.round(effortItem.minuteEffort * 10) / 10;
    }
  }

  changeResourceEffortTime(
    effortItem,
    totalResultingTimeInMinutes,
    totalMinutes,
    perResourcePercentageDifference,
    perResourceMinuteDifference,
    condition
  ) {
    if (condition) {
      const effortItemPercentElementId = effortItem.protectedResourceUniqueReference + "percentInput";
      const effortItemHourElementId = effortItem.protectedResourceUniqueReference + "hourInput";
      const effortItemMinuteElementId = effortItem.protectedResourceUniqueReference + "minuteInput";
      let percentElement = document.getElementById(effortItemPercentElementId);
      let hourElement = document.getElementById(effortItemHourElementId);
      let minuteElement = document.getElementById(effortItemMinuteElementId);
      effortItem.percentageEffort =
        totalResultingTimeInMinutes > totalMinutes
          ? effortItem.percentageEffort - perResourcePercentageDifference
          : effortItem.percentageEffort + perResourcePercentageDifference;
      percentElement.value = Math.round(effortItem.percentageEffort);
      const currentResourceTotalMinutes =
        totalResultingTimeInMinutes > totalMinutes
          ? parseFloat(hourElement.value) * 60 + parseFloat(minuteElement.value) - perResourceMinuteDifference
          : parseFloat(hourElement.value) * 60 + parseFloat(minuteElement.value) + perResourceMinuteDifference;
      effortItem.hoursEffort = Math.floor(currentResourceTotalMinutes / 60);
      hourElement.value = Math.round(effortItem.hoursEffort * 10) / 10;
      effortItem.minuteEffort = currentResourceTotalMinutes % 60;
      minuteElement.value = Math.round(effortItem.minuteEffort * 10) / 10;
    }
  }

  renderResourceEffortArrowIndicator() {
    if (this.state.data.resourceEffortList.length > 0) {
      return (
        <div className={styles.indicatorArrowContainer}>
          <span className={styles.arrowAligner}></span>
          <img className={styles.indicatorArrow} src={ChevronRight} alt="right arrow" />
        </div>
      );
    } else {
      return <div className={styles.indicatorDummyArrowContainer} style={{ width: "32px" }}></div>;
    }
  }

  cancelPVS = async () => {
    if (this.state.propertyVisitSummaryObj) {
      const cancelPVSCall = await WorkTaskAPI.CancelPVS(
        this.state.propertyVisitSummaryObj.propertyVisitSummaryUniqueIdentifier
      );
      if (cancelPVSCall?.successful) {
        toast.success("Site Visit Deleted.");
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to cancel Site Visit",
          apiName: "CancelPVS",
          responseUnsuccessful: cancelPVSCall?.unsuccessful,
          responseMessage: cancelPVSCall?.message,
        });
      }
    }
    this.navigateBackToPropertyPage();
  };

  getCompleteSiteVisitBody() {
    return {
      siteVisitHours: parseInt(this.state.data.pvsHours ? this.state.data.pvsHours : 0),
      siteVisitMinutes: parseInt(this.state.data.pvsMinutes ? this.state.data.pvsMinutes : 0),
      siteVisitConflictResources: this.state.data.resourceEffortList.map((res) => {
        return {
          protectedResourceUniqueReference: res.protectedResourceUniqueReference,
          protectedResourceEffortHours: res.hoursEffort ? parseInt(res.hoursEffort) : 0,
          protectedResourceEffortMinutes: res.minuteEffort ? parseInt(res.minuteEffort) : 0,
        };
      }),
    };
  }

  completePVS = async () => {
    if (this.state.propertyVisitSummaryObj) {
      const completePVSCall = await WorkTaskAPI.CompletePVS(
        this.state.propertyVisitSummaryObj.propertyVisitSummaryUniqueIdentifier,
        this.getCompleteSiteVisitBody()
      );
      if (completePVSCall?.successful) {
        let body = {
          userProtectedResourceRatios: this.state.data.resourceEffortList.map((res) => {
            return {
              protectedResourceUniqueReference: res.protectedResourceUniqueReference,
              protectedResourceRatio: res.percentageEffort ? (res.percentageEffort < 0 ? 0 : res.percentageEffort) : 0,
            };
          }),
        };
        const storeUserResourceRatios = await RoleAuthorizationAPI.AddUserPropertyProtectedResourceRatios(
          this.state.propertyVisitSummaryObj.createdBy,
          this.state.propertyVisitSummaryObj.propertyVisitedUniqueReference,
          body
        );

        if (storeUserResourceRatios?.unsuccessful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to store user's resource ratios",
            apiName: "AddUserPropertyProtectedResourceRatios",
            responseUnsuccessful: storeUserResourceRatios?.unsuccessful,
            responseMessage: storeUserResourceRatios?.message,
          });
        }

        if (this.state.propertyVisitSummaryObj?.dcWorkTaskPerformed?.workPerformed) {
          await this.performCMITSTransactions();
        }

        toast.success("Site Visit Submitted.");

        this.navigateBackToPropertyPage();
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to complete Site Visit",
          apiName: "CompletePVS",
          responseUnsuccessful: completePVSCall?.unsuccessful,
          responseMessage: completePVSCall?.message,
        });
      }
    } else {
      toast.error("Incomplete Site Visit cannot be submitted.");
    }
  };

  async performCMITSTransactions() {
    let transactionList = [];

    const methodGuidsToResolve = this.state.propertyVisitSummaryObj.dcWorkTaskPerformed.workPerformed.map(
      (x) => x.methodUniqueReference
    );
    const resolveMethods = await ReferenceFileAPI.ResolveMethods(methodGuidsToResolve);
    if (resolveMethods.unsuccessful) {
      return;
    }

    this.state.propertyVisitSummaryObj.dcWorkTaskPerformed.workPerformed.forEach((element) => {
      const resolvedMethod = resolveMethods.data.find((x) => x.methodUniqueIdentifier == element.methodUniqueReference);
      const shouldWePerformTransaction =
        element.workPerformedType.id === 2 ||
        element.configurationActionName === "Missing/Stolen" ||
        element.configurationActionName === "Punctured" ||
        element.configurationActionName === "Fired" ||
        element.configurationActionName === "Torn/Damaged";

      if (resolvedMethod.isCMITSMethod && shouldWePerformTransaction) {
        transactionList.push({
          directControlWorkTaskUniqueReference:
            this.state.propertyVisitSummaryObj.dcWorkTaskPerformed.workTaskUniqueIdentifier,
          methodUniquereference: element.methodUniqueReference,
          quantity: element.methodQtyAffected,
          methodUOMEnumId: element.methodAllowedUOMId,
          transactionTypeEnumId: 9,
          enteredByUserProfileUniqueReference: this.state.performedBy.value,
          warehouseTransferFromUniqueReference: element.warehouseUniqueReference,
          warehouseTransferFromStateUniqueReference: this.state.data.propertyData.state,
          propertyUniqueReference: this.state.data.propertyData.propertyUniqueIdentifier,
          inventoryAdjustmentDate: new Date().toISOString(),
        });
      }
    });

    if (transactionList.length > 0) {
      const performTransactions = await InventoryAPI.BulkPerformWarehouseTransactions(transactionList);
      if (performTransactions.successful) {
        toast.success(`${performTransactions.data.length} CMITS Transactions performed.`);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to perform warehouse transactions",
          apiName: "BulkPerformWarehouseTransactions",
          responseUnsuccessful: performTransactions?.unsuccessful,
          responseMessage: performTransactions?.message,
        });
      }
    }
  }

  navigateBackToPropertyPage() {
    const correctedStates = this.props.history.location.state?.states
      ?.map((state) => ({
        stateUniqueIdentifier: state.id,
        state_name: state.name,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    this.props.history.push({
      pathname: "/property/details",
      state: {
        entityData: this.state.data.propertyData,
        states: correctedStates,
      },
    });
  }

  saveDiseaseMonitoring = async (diseaseMonitoringData, animalSampleTable, takeData) => {
    const { propertyVisitSummaryObj } = this.state;
    await this.updateTakesDataOnWorkPerformed(propertyVisitSummaryObj);
    this.setState({
      propertyVisitSummaryObj,
      inProgressTakeData: takeData,
      diseaseMonitoringData: diseaseMonitoringData,
      diseaseMonitoringAnimalSampleTable: animalSampleTable,
      showDiseaseMonitoringModal: false,
      showDiseaseShippingModal: true,
    });
  };

  saveOtherSample = async (otherSampleData, takeData) => {
    let showDisease = false;
    //create Other sample
    const takeDateTime = takeData.date
      ? new Date(takeData.date).toLocaleDateString("en-CA") + "T00:00:00.000Z"
      : new Date(otherSampleData.date).toISOString();
    let otherSampleData1 = {
      propertyUniqueReference: this.state.data.propertyData?.propertyUniqueIdentifier,
      isFromTake: takeData?.takeUniqueIdentifier ? true : false,
      wsUserUniqueReference: this.state.performedBy?.value,
      speciesUniqueReference: takeData.isUnintentionalTake
        ? takeData.unintentionalSpecies?.value
        : takeData.intentionalSpecies
          ? takeData.intentionalSpecies
          : otherSampleData.speciesUniqueId,
      dateTaken: takeDateTime,
      latitude: takeData.latitude?.toString(),
      longitude: takeData.longitude?.toString(),
      locationTypeEnumId: otherSampleData.locationTypeEnumId,
      samples: otherSampleData.samples,
      takeUniqueReference: takeData.takeUniqueIdentifier ? takeData.takeUniqueIdentifier : null,
    };

    const createTakeResponse = await WorkTaskAPI.CreateOtherSample(otherSampleData1);
    if (createTakeResponse?.successful) {
      toast.success("Other Sample was successfully added to the take.");
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Other Sample could not be added to the take",
        apiName: "CreateOtherSample",
        responseUnsuccessful: createTakeResponse?.unsuccessful,
        responseMessage: createTakeResponse?.message,
      });
    }

    const { propertyVisitSummaryObj } = this.state;
    if (Number.parseInt(takeData.rabiesSamplingQty) > 0) {
      this.executeRabiesSamplesProcess(takeData);
    } else if (Number.parseInt(takeData.diseaseSamplingQty) > 0) {
      showDisease = true;
    } else {
      await this.updateTakesDataOnWorkPerformed(propertyVisitSummaryObj);
    }

    this.setState({
      propertyVisitSummaryObj,
      inProgressTakeData: takeData,
      otherSampleData: otherSampleData,
      showOtherSampleModal: false,
      showDiseaseMonitoringModal: showDisease,
    });
  };

  saveDiseaseShipping = () => {
    this.setState({ showDiseaseShippingModal: false });
  };

  attachTakeToWorkPerformed = async (takeData) => {
    this.setState({ dataLoadingText: "Saving Take", isDataLoading: false });
    let workPerformedUniqueReferences = [];
    workPerformedUniqueReferences.push(this.state.enterTakeData?.workItem?.workPerformedUniqueIdentifier);

    let body = {
      workPerformedAssociationsToAdd: workPerformedUniqueReferences,
      workPerformedAssociationsToRemove: [],
    };

    const associateTakeResponse = await WorkTaskAPI.ModifyWorkPerformedAssociationsOfTake(
      takeData.selectedTake.value,
      body
    );
    if (associateTakeResponse?.successful) {
      toast.success("Take was successfully associated to the work task.");
      await this.refreshPVSObject();
      let takeDataObj = await takeData;
      const inProgressTakeData = {
        ...takeDataObj,
        takeUniqueIdentifier: associateTakeResponse.data?.takeUniqueIdentifier,
      };
      this.setState({ inProgressTakeData });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to modify take associations",
        apiName: "ModifyWorkPerformedAssociationsOfTake",
        responseUnsuccessful: associateTakeResponse?.unsuccessful,
        responseMessage: associateTakeResponse?.message,
      });
    }
    this.setState({ dataLoadingText: "Saving Take", isDataLoading: false });
  };

  addTakeToWorkPerformed = async (takeData) => {
    this.setState({ dataLoadingText: "Saving Take", isDataLoading: true });
    const createTakeResponse = await WorkTaskAPI.CreateTake(this.getTakeCreationBody(takeData));
    if (createTakeResponse?.successful) {
      toast.success("Take was successfully added to the work task.");

      await this.addBandsToTake(takeData, createTakeResponse.data?.takeUniqueIdentifier);

      await this.refreshPVSObject();
      let takeDataObj = await takeData;
      const inProgressTakeData = {
        ...takeDataObj,
        takeUniqueIdentifier: createTakeResponse.data?.takeUniqueIdentifier,
      };
      this.setState({ inProgressTakeData });
      if (takeData?.isOtherSampling) {
        this.setState({ showOtherSampleModal: true });
      } else if (takeData?.rabiesSamplingQty > 0) {
        this.executeRabiesSamplesProcess(takeData);
      } else if (takeData?.diseaseSamplingQty > 0) {
        this.setState({ showDiseaseMonitoringModal: true });
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Take could not be added to the work task",
        apiName: "CreateTake",
        responseUnsuccessful: createTakeResponse?.unsuccessful,
        responseMessage: createTakeResponse?.message,
      });
    }
    this.setState({ isDataLoading: false, dataLoadingText: "" });
  };

  editTakeOnWorkPerformed = async (takeData) => {
    this.setState({ dataLoadingText: "Saving Changes", isDataLoading: true });
    if (takeData?.takeUniqueIdentifier && this.state.currentUsaState?.stateGuid) {
      const editTakeResponse = await WorkTaskAPI.EditTake(
        takeData.takeUniqueIdentifier,
        this.state.currentUsaState.stateGuid,
        this.getTakeEditBody(takeData)
      );
      if (editTakeResponse?.successful) {
        toast.success("Take was successfully edited");
        await this.addNewBandsToExistingTake(takeData);
        await this.removeBandsFromExistingTake(takeData);

        await this.refreshPVSObject();
        const takeDataObj = await this.getUpdatedTakeData(takeData);
        const inProgressTakeData = {
          ...takeDataObj,
          takeUniqueIdentifier: takeData.takeUniqueIdentifier,
        };
        this.setState({ inProgressTakeData });

        if (takeDataObj.isOtherSampling) {
          this.setState({ showOtherSampleModal: true });
        } else if (takeDataObj.rabiesSamplingQty > 0) {
          this.executeRabiesSamplesProcess(takeData);
        } else if (takeDataObj.diseaseSamplingQty > 0) {
          this.setState({ showDiseaseMonitoringModal: true });
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Take could not be updated",
          apiName: "EditTake",
          responseUnsuccessful: editTakeResponse?.unsuccessful,
          responseMessage: editTakeResponse?.message,
        });
      }
    }
    this.setState({ isDataLoading: false, dataLoadingText: "" });
  };

  async addBandsToTake(takeData, takeUniqueIdentifier) {
    if (takeUniqueIdentifier && takeData.bands?.length > 0) {
      const addBandsResponse = await WorkTaskAPI.AddBandsToTake(
        takeUniqueIdentifier,
        takeData.bands.map((b) => ({ bandingTypeEnumId: b.type?.value, referenceNumber: b.referenceNumber }))
      );
      if (!addBandsResponse?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Banding data could not be added to the Take",
          apiName: "AddBandsToTake",
          responseUnsuccessful: addBandsResponse?.unsuccessful,
          responseMessage: addBandsResponse?.message,
        });
      }
    }
  }

  async addNewBandsToExistingTake(takeData) {
    const bandsToAdd = takeData.bands.filter(
      (b) =>
        !this.state.enterTakeData.workItem?.bands.some(
          (existingBand) =>
            existingBand.referenceNumber === b.referenceNumber && existingBand.bandingType?.id === b.type?.value
        )
    );
    if (bandsToAdd?.length > 0) {
      const addBandsResponse = await WorkTaskAPI.AddBandsToTake(
        takeData.takeUniqueIdentifier,
        bandsToAdd.map((b) => ({ bandingTypeEnumId: b.type?.value, referenceNumber: b.referenceNumber }))
      );
      if (!addBandsResponse?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Banding data could not be added to the Take",
          apiName: "AddBandsToTake",
          responseUnsuccessful: addBandsResponse?.unsuccessful,
          responseMessage: addBandsResponse?.message,
        });
      }
    }
  }

  async removeBandsFromExistingTake(takeData) {
    const bandsToRemove = this.state.enterTakeData.workItem?.bands.filter(
      (existingBand) =>
        !takeData.bands.some(
          (b) => existingBand.referenceNumber === b.referenceNumber && existingBand.bandingType?.id === b.type?.value
        )
    );
    if (bandsToRemove?.length > 0) {
      const removeBandsResponse = await WorkTaskAPI.RemoveBandsFromTake(
        takeData.takeUniqueIdentifier,
        bandsToRemove.map((b) => b.takeBandUniqueIdentifier)
      );
      if (!removeBandsResponse?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Banding data could not be removed from the Take",
          apiName: "RemoveBandsFromTake",
          responseUnsuccessful: removeBandsResponse?.unsuccessful,
          responseMessage: removeBandsResponse?.message,
        });
      }
    }
  }

  async getUpdatedTakeData(takeData) {
    const { workItem: existingTakeData } = this.state.enterTakeData;
    const takeDataObj = { ...takeData };
    takeDataObj.isOtherSampling =
      takeData.isOtherSampling &&
        (!existingTakeData.isOther ||
          Number.parseInt(takeData?.totalAnimalsSampled) > existingTakeData.numberOfAnimalsSampled)
        ? true
        : false;
    takeDataObj.rabiesSamplingQty =
      Number.parseInt(takeData?.rabiesSamplingQty) > existingTakeData.numberOfNRMPTaken
        ? Number.parseInt(takeData?.rabiesSamplingQty) - existingTakeData.numberOfNRMPTaken
        : 0;
    takeDataObj.diseaseSamplingQty =
      Number.parseInt(takeData?.diseaseSamplingQty) > existingTakeData.numberOfDiseaseMonitoringTaken
        ? Number.parseInt(takeData?.diseaseSamplingQty) - existingTakeData.numberOfDiseaseMonitoringTaken
        : 0;
    return takeDataObj;
  }

  executeRabiesSamplesProcess(takeData) {
    const { data } = this.state;
    const rabiesSampleData = {
      rabiesSamplingQty: Number.parseInt(takeData.rabiesSamplingQty),
      propertyStateName: data.propertyStateName,
      currentStateId: data.propertyData?.state,
      propertyCounty: data.propertyData?.county,
      town:
        data.propertyData?.additionalStreetAddresses?.length > 0
          ? data.propertyData?.additionalStreetAddresses[0]?.city
          : "",
      speciesFromTake: takeData.isUnintentionalTake
        ? takeData.unintentionalSpecies
        : this.getIntentionalSpeciesObject(takeData.intentionalSpecies),
      workItemDate: this.state.enterTakeData?.workItem?.createdDateTimeUTC,
      isUnintentionalTake: takeData.isUnintentionalTake,
    };
    this.setState({ rabiesSampleData, showRabiesSampleModal: true });
  }

  getIntentionalSpeciesObject(speciesId) {
    let speciesObject = "";
    const associatedConflict = this.state.propertyConflicts.find((c) => c.targetedSpeciesUniqueReference === speciesId);
    if (associatedConflict) {
      speciesObject = { value: speciesId, label: associatedConflict.targetedSpeciesName };
    }

    return speciesObject;
  }

  saveRabiesSamples = async (sampleData) => {
    const { data } = this.state;
    let countyData = [];
    if (data.propertyData?.state) {
      const countiesData = await ReferenceFileAPI.GetCountiesByState(data.propertyData.state);
      if (countiesData?.length > 0) {
        countyData = countiesData.find((county) => county.county_name === data.propertyData?.county);
      }
    }

    if (sampleData?.length > 0) {
      let isCreateNRMPSuccessful = true;
      let createNRMPErrorMessage = "";
      let warehouseTransactionsErrorMessage = "";

      let warehouseTransactionsBody = [];
      await Promise.all(
        sampleData.map(async (sample) => {
          const isTothLabRequired = sample.pm1Checkbox || sample.pm2Checkbox || sample.k9Checkbox || sample.jawCheckbox;
          const isBloodLabAvailable =
            sample.rabiesCheckbox ||
            sample.canineDistemperCheckbox ||
            sample.other3Checkbox ||
            sample.other4Checkbox ||
            sample.noneCheckbox;
          const createNrmpMessageBody = {
            date: sample.sampleDate
              ? new Date(sample.sampleDate).toISOString()
              : new Date(this.state.enterTakeData?.workItem?.createdDateTimeUTC).toISOString(),
            takeUniqueReference: this.state.inProgressTakeData?.takeUniqueIdentifier
              ? this.state.inProgressTakeData.takeUniqueIdentifier
              : null,
            stateUniqueReference: data.propertyData?.state,
            countyUniqueReference: countyData?.countyUniqueIdentifier,
            townOrCity: sample.town,
            collectorEnumId: sample.collector ? sample.collector : null,
            wsNameUserUniqueReference: sample.wsName?.value,
            otherCollector: sample.otherCollector?.value ? sample.otherCollector.value : null,
            collectionActivityEnumId: sample.selectedCollectionActivity ? sample.selectedCollectionActivity : null,
            collectionMethodEnumId: sample.selectedCollectionMethod ? sample.selectedCollectionMethod : null,
            densityStudyYesNoEnumId: sample.densityStudy ? sample.densityStudy : null,
            densityStudyId: sample.densityStudyId,
            waypointNumber: sample.waypointNumber,
            isLatLongRecordedYesNoEnumId: sample.latLongRecorded ? sample.latLongRecorded : null,
            latitude: sample.latitude,
            longitude: sample.longitude,
            isOrvNativeYesNoEnumId: sample.orvNaive ? sample.orvNaive : null,
            orvBaitTypeEnumId: sample.baitType ? sample.baitType : null,
            otherBait: sample.otherBait,
            isNrmpTargetYesNoNoCaptureEnumId: sample.nrmpTargetSpecies?.value ? sample.nrmpTargetSpecies.value : null,
            isMisTargetEnumId: sample.misTargetSpecies?.value ? sample.misTargetSpecies.value : null,
            densityStudy2YesNoEnumId: null,
            speciesBeingTestedUniqueReference: sample.selectedSpecies?.value,
            fateOfTrapEnumId: sample.fateOfTrap ? sample.fateOfTrap : null,
            isRecaptureYesNoEnumId: sample.recaptureSelected ? sample.recaptureSelected : null,
            wasProcessedLessThanThirtyDaysAgoYesNoEnumId: sample.processedLessThan30DaysAgo
              ? sample.processedLessThan30DaysAgo
              : null,
            animalId: sample.animalId,
            animalSexEnumId: sample.sex ? sample.sex : null,
            ageEnumId: sample.relativeAge ? sample.relativeAge : null,
            isWeightRecordedYesNoEnumId: sample.weightRecorded ? sample.weightRecorded : null,
            weightKg: sample.weight ? Number.parseInt(sample.weight) : null,
            isLactatingYesNoNotRecordedEnumId: sample.lactation ? sample.lactation : null,
            isHandVaccinatedYesNoNotRecordedEnumId: sample.handVaccinated ? sample.handVaccinated : null,
            otherSampleEnumId: sample.selectedOtherSample?.value ? sample.selectedOtherSample.value : null,
            fateEnumId: sample.selectedFateForWs ? sample.selectedFateForWs : null,
            otherFate: sample.otherFate,
            euthanizedReasonEnumId: sample.euthanizedReason ? sample.euthanizedReason : null,
            comments: sample.comments,
            freeText: sample.freeText,
            isBloodTested: sample.bloodCheckbox,
            isPm1Tested: sample.pm1Checkbox,
            isPm2Tested: sample.pm2Checkbox,
            isK9Tested: sample.k9Checkbox,
            isJawTested: sample.jawCheckbox,
            isDnaTested: sample.dnaCheckbox,
            isBrainstemTested: sample.brainstemCheckbox,
            areOtherSamplesTested: sample.otherCheckbox,
            ersCategoryEnumId: sample.selectedErsCategory ? sample.selectedErsCategory : null,
            areTestingForRabies: sample.rabiesCheckbox,
            areTestingForCanineDistemper: sample.canineDistemperCheckbox,
            areTestingForOtherThird: sample.other3Checkbox,
            areTestingForOtherFourth: sample.other4Checkbox,
            areTestingForNothing: sample.noneCheckbox,
            isRabiesBrainstemTestYesNoNotRecordedEnumId: sample.rabiesBrainstemTest ? sample.rabiesBrainstemTest : null,
            labBrainSentToEnumId: sample.rabiesBrainLab ? sample.rabiesBrainLab : null,
            rabiesLabSpecimenNumber: sample.rabiesLabSpecimenNumber ? sample.rabiesLabSpecimenNumber : sample.animalId,
            conditionOfBrainstemEnumId: sample.rabiesBrainstemCondition ? sample.rabiesBrainstemCondition : null,
            stainingIntensityDRITEnumId: sample.stainingIntensity ? sample.stainingIntensity : null,
            antigenDistributionDRITEnumId: sample.antigenDistribution ? sample.antigenDistribution : null,
            brainstemResultsEnumId: sample.rabiesBrainstemResults ? sample.rabiesBrainstemResults : null,
            rabiesVariantEnumId: sample.rabiesVariant ? sample.rabiesVariant : null,
            rabiesTestComments:
              !sample.noneCheckbox && Number.parseInt(sample.rabiesBrainstemTest) === 1
                ? sample.rabiesTestComments
                : "",
            labToothSentToEnumId: isTothLabRequired && sample.toothLab ? sample.toothLab : null,
            isAgeRecordedYesNoEnumId: isTothLabRequired && sample.ageRecorded ? sample.ageRecorded : null,
            years: isTothLabRequired && sample.age ? Number.parseInt(sample.age) : null,
            isTTCCPresentYesNoNotTestedEnumId: isTothLabRequired && sample.ttccPresent ? sample.ttccPresent : null,
            ageTTCCComments: isTothLabRequired && sample.ageTtccComments ? sample.ageTtccComments : "",
            labBloodSentToEnumId: isBloodLabAvailable && sample.rabiesBloodLab ? sample.rabiesBloodLab : null,
            rabiesVna:
              !sample.noneCheckbox &&
                (!isBloodLabAvailable || Number.parseInt(data.rabiesBloodLab) !== 4) &&
                sample.rabiesVnaIu
                ? sample.rabiesVnaIu
                : null,
            rabiesInterpretationEnumId:
              !sample.noneCheckbox &&
                (!isBloodLabAvailable || Number.parseInt(data.rabiesBloodLab) !== 4) &&
                sample.vnaInterpretation
                ? sample.vnaInterpretation
                : null,
            rabiesVnaComments:
              !data.noneCheckbox && Number.parseInt(data.rabiesBloodLab) !== 4 && sample.vnaComments
                ? sample.vnaComments
                : "",
            distemperVna: sample.canineDistemperCheckbox && !sample.noneCheckbox ? sample.distemperVnaDilution : null,
            distemperInterpretationEnumId:
              sample.canineDistemperCheckbox && !sample.noneCheckbox && sample.distemperVnaInterpretation
                ? sample.distemperVnaInterpretation
                : null,
            distemperVnaComments:
              !sample.noneCheckbox &&
                (!isBloodLabAvailable || Number.parseInt(sample.rabiesBloodLab) !== 4) &&
                sample.distemperVnaComments
                ? sample.distemperVnaComments
                : "",
            adenoVna:
              (sample.other3Checkbox || sample.other4Checkbox) && !sample.noneCheckbox ? sample.adenoVnaDilution : null,
            adenoInterpretationEnumId:
              (sample.other3Checkbox || sample.other4Checkbox) && !sample.noneCheckbox && sample.adenoVnaInterpretation
                ? sample.adenoVnaInterpretation
                : null,
            adenoVnaComments: !sample.noneCheckbox ? sample.adenoVnaComments : "",
          };

          const validDrugsAndVaccinesEntries = sample.drugsAndVaccinesData?.filter(
            (entry) => entry.ieDrugOrVaccine?.value && entry.totalAmount
          );
          createNrmpMessageBody["drugsAndVaccines"] =
            validDrugsAndVaccinesEntries?.length > 0
              ? validDrugsAndVaccinesEntries.map((entry) => {
                let obj = {
                  vialNumber: entry.vialNumber,
                  totalAmountInCc: Number.parseFloat(entry.totalAmount),
                  substanceWarehouseItemUniqueReference: entry.ieDrugOrVaccine?.value
                    ? entry.ieDrugOrVaccine.value
                    : null,
                  itemUnitOfMeasureEnumId: entry.ieDrugOrVaccine?.methodUOM?.id
                    ? entry.ieDrugOrVaccine.methodUOM.id
                    : null,
                };
                return obj;
              })
              : [];

          if (sample.orvNaive !== 1 && sample.dateOfLastOrv) {
            createNrmpMessageBody["dateOfLastOrv"] = new Date(sample.dateOfLastOrv).toISOString();
          }
          if (sample.rabiesBrainTestDate) {
            createNrmpMessageBody["dateOfTest"] = new Date(sample.rabiesBrainTestDate).toISOString();
          }

          const createNRMPResponse = await WorkTaskAPI.CreateNRMP(createNrmpMessageBody);
          if (!createNRMPResponse?.successful) {
            isCreateNRMPSuccessful = false;
            createNRMPErrorMessage = createNRMPResponse?.errors || createNRMPResponse?.message;
          } else {
            validDrugsAndVaccinesEntries.forEach(async (drugData) => {
              warehouseTransactionsBody.push(this.getWarehouseTransactionBody(drugData, sample.wsName?.value));
            });
          }
        })
      );

      if (warehouseTransactionsBody.length > 0) {
        const warehouseTransactionsResponse = await InventoryAPI.BulkPerformWarehouseTransactions(
          warehouseTransactionsBody
        );
        if (!warehouseTransactionsResponse?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to perform warehouse transactions",
            apiName: "BulkPerformWarehouseTransactions",
            responseUnsuccessful: warehouseTransactionsResponse?.unsuccessful,
            responseMessage: warehouseTransactionsResponse?.message,
          });
        }
      }

      if (isCreateNRMPSuccessful) {
        toast.success("Rabies samples were successfully recorded.");
      } else {
        ErrorHandler.showErrorWithDetails("One or more rabies samples could not be recorded.", createNRMPErrorMessage);
      }

      if (warehouseTransactionsErrorMessage) {
        ErrorHandler.showErrorWithDetails(
          "One or more warehouse transactions could not be recorded.",
          warehouseTransactionsErrorMessage
        );
      }
    }

    if (this.state.inProgressTakeData?.diseaseSamplingQty > 0) {
      this.setState({ showDiseaseMonitoringModal: true });
    } else {
      const { propertyVisitSummaryObj } = this.state;
      await this.updateTakesDataOnWorkPerformed(propertyVisitSummaryObj);
      this.setState({ propertyVisitSummaryObj });
    }
  };

  getWarehouseTransactionBody(drugData, profileId) {
    return {
      methodUOMEnumId: drugData.ieDrugOrVaccine?.methodUOM?.id,
      transactionTypeEnumId: 9,
      enteredByUserProfileUniqueReference: profileId,
      warehouseTransferToUniqueReference: drugData.warehouse?.value,
      warehouseTransferToStateUniqueReference: this.state.data.propertyData?.state,
      inventoryAdjustmentDate: new Date().toISOString(),
      methodUniquereference: drugData.ieDrugOrVaccine?.methodUniqueReference,
      quantity: Number.parseInt(drugData.totalAmount),
      warehouseTransferFromUniqueReference: drugData.warehouse?.value,
      warehouseTransferFromStateUniqueReference: this.state.data.propertyData?.state,
    };
  }

  saveAdminWorkTask = async (taskData) => {
    const createAdminWorkTaskMessageBody = {
      datePerformed: new Date(taskData.taskDate).toISOString(),
      adminActivities: taskData.selectedActivity?.map((item) => {
        return { adminActivityUniqueReference: item.value };
      }),
      agreementUniqueReference: this.state.data.agreementData?.agreementUniqueIdentifier,
      hoursWorked: taskData.timeHours ? Number.parseInt(taskData.timeHours) : 0,
      minutesWorked: taskData.timeMinutes ? Number.parseInt(taskData.timeMinutes) : 0,
      comments: taskData.adminWorkTaskComments,
      propertyOnPvsUniqueReference: this.state.data.propertyData.propertyUniqueIdentifier,
      pvsUniqueReference: null,
    };
    if (this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier) {
      createAdminWorkTaskMessageBody.pvsUniqueReference =
        this.state.propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier;
    }
    const createWorkTaskResponse = await WorkTaskAPI.CreateAdminWorkTask(createAdminWorkTaskMessageBody);
    if (createWorkTaskResponse?.successful) {
      toast.success("Successfully created Admin Work Task");
      await this.updateAdminWorkTaskData();
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create admin work task",
        apiName: "CreateAdminWorkTask",
        responseUnsuccessful: createWorkTaskResponse?.unsuccessful,
        responseMessage: createWorkTaskResponse?.message,
      });
    }
  };

  saveInventoryLoss = async (lossData) => {
    const createLossResponse = await WorkTaskAPI.CreateLoss(this.getCreateLossBody(lossData));
    if (createLossResponse?.successful) {
      toast.success("Loss was successfully recorded.");
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Loss could not be added to the Protected Resource Inventory",
        apiName: "CreateLoss",
        responseUnsuccessful: createLossResponse?.unsuccessful,
        responseMessage: createLossResponse?.message,
      });
    }
  };

  saveNonInventoryLoss = async (lossData) => {
    if (lossData.addToProtectedResourceInventory) {
      const { lossUom, selectedResource, unitValue, selectedSpecies } = lossData;
      const resourcePropertyAssociationData = { lossUom, selectedResource, unitValue, selectedSpecies };
      if (lossData.selectedSpecies) {
        resourcePropertyAssociationData.lossData = lossData;
      }
      this.setState({ resourcePropertyAssociationData, showAssociateResourceInventoryModal: true });
    } else {
      await this.createNonInventoryLoss(lossData);
    }
  };

  saveResourcePropertyAssociation = async (resourceData) => {
    await this.autoAddNewResourceToConflicts(resourceData.resourcePropertyAssociationData.lossData, resourceData);
    this.createNonInventoryLoss(resourceData.resourcePropertyAssociationData.lossData);
  };

  async createNonInventoryLoss(lossData) {
    const createLossResponse = await WorkTaskAPI.CreateLoss(this.getNonInventoryLossObject(lossData));
    if (createLossResponse?.successful) {
      toast.success("Non Inventory Loss was successfully added");
    } else {
      ErrorHandler.showErrorWithDetails(
        createLossResponse?.data,
        "Non Inventory Loss could not be created. Please contact system admin for more details"
      );
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Non Inventory Loss could not be created",
        apiName: "CreateLoss",
        responseUnsuccessful: createLossResponse?.unsuccessful,
        responseMessage: createLossResponse?.message,
      });
    }
  }

  getNonInventoryLossObject(lossData) {
    const { data } = this.state;
    const lossObject = {
      propertyUniqueReference: data.propertyData?.propertyUniqueIdentifier,
      dateLossOccurred: new Date(lossData.lossDate).toISOString(),
      resourceUniqueReferece: lossData.selectedResource?.value,
      resourceUOMEnumId: lossData.lossUom?.value,
      unitsLostQuantity: parseInt(lossData.lossQuantity),
      monetaryLossUnitValue: parseFloat(lossData.unitValue),
      monetaryLossTotalValue: parseFloat(lossData.lossAmount),
      speciesUniqueReference: lossData.selectedSpecies?.value,
      damageUniqueReference: lossData.selectedDamage?.value,
      diseaseUniqueReference: lossData.selectedDisease?.value ? lossData.selectedDisease.value : null,
      isVerified: lossData.reportedOrVerified === "Verified",
      isInventoryLoss: true,
    };
    return lossObject;
  }

  getCreateLossBody(lossData) {
    const totalLossValue = Number.parseFloat(lossData.lossAmount);
    const lossQuantity = Number.parseInt(lossData.lossQuantity);
    const createLossBody = {
      propertyUniqueReference: this.state.data.propertyData?.propertyUniqueIdentifier,
      dateLossOccurred: new Date(lossData.lossDate).toISOString(),
      resourceUniqueReferece: lossData.resourceUniqueId,
      resourceUOMEnumId: lossData.lossUom?.value,
      unitsLostQuantity: lossQuantity,
      monetaryLossUnitValue: totalLossValue ? totalLossValue / lossQuantity : null,
      monetaryLossTotalValue: totalLossValue ? totalLossValue : null,
      speciesUniqueReference: lossData.selectedSpecies?.value,
      damageUniqueReference: lossData.selectedDamage?.value,
      diseaseUniqueReference: lossData.selectedDisease?.value ? lossData.selectedDisease.value : null,
      isVerified: lossData.reportedOrVerified === "Verified",
      isInventoryLoss: true,
    };
    return createLossBody;
  }

  enterNonInventoryLoss = () => {
    this.setState({ showNonInventoryLossModal: true });
  };

  enterAdminWorkTask = () => {
    const agreementData = this.state.data.agreementData;
    const adminWorkTaskData = {
      selectedAgreement: agreementData
        ? { value: agreementData.agreementUniqueIdentifier, label: agreementData.commonName }
        : "",
      pvsDate: new Date(this.state.pvsDate.split(" ")[0]).toLocaleDateString("en-CA"),
    };

    this.setState({ adminWorkTaskData, showAdminWorkTaskModal: true });
  };

  enterTAWorkTask = () => {
    const agreementData = this.state.data.agreementData;
    const technicalAssistWorkTaskData = {
      selectedAgreement: agreementData
        ? { value: agreementData.agreementUniqueIdentifier, label: agreementData.commonName }
        : "",
      pvsDate: new Date(this.state.pvsDate.split(" ")[0]).toLocaleDateString("en-CA"),
    };

    this.setState({ technicalAssistWorkTaskData, showTAWorkTaskModal: true });
  };

  enterSampleTask = () => {
    this.setState({ showEnterSampleModal: true, fromEnterSample: true });
  };

  closeAdminWorkTaskModal = () => {
    this.setState({ showAdminWorkTaskModal: false });
  };

  closeTAWorkTaskModal = async (success) => {
    if (success) {
      await this.updateTAWorkTaskData();
    }
    this.setState({ showTAWorkTaskModal: false });
  };

  closeNonInventoryLossModal = () => {
    this.setState({ showNonInventoryLossModal: false });
  };

  closeAssociateResourceInventoryModal = () => {
    this.setState({ showAssociateResourceInventoryModal: false });
  };

  closeDiseaseMonitoringModal = () => {
    this.setState({ showDiseaseMonitoringModal: false });
  };

  backDiseaseMonitoringModal = () => {
    this.setState({ showDiseaseMonitoringModal: false });
    if (this.state.inProgressTakeData?.rabiesSamplingQty > 0) {
      this.setState({ showRabiesSampleModal: true });
    } else if (this.state.inProgressTakeData?.isOtherSampling) {
      this.setState({ showOtherSampleModal: true });
    } else if (this.state.fromEnterSample) {
      this.setState({ showEnterSampleModal: true });
    } else {
      this.setState({ showEnterTakeModal: true });
    }
  };

  backOtherSampleModal = () => {
    this.setState({ showOtherSampleModal: false, showEnterTakeModal: true });
  };

  closeOtherSampleModal = () => {
    this.setState({ showOtherSampleModal: false });
  };

  closeOtherSampleModal = () => {
    this.setState({ showOtherSampleModal: false });
  };

  closeDiseaseShippingModal = () => {
    this.setState({ showDiseaseShippingModal: false });
  };

  backDiseaseShippingModal = () => {
    this.setState({ showDiseaseShippingModal: false, showDiseaseMonitoringModal: true });
  };

  findConflictWithMatchingSpecies(lossData) {
    return this.state.propertyConflicts.find(
      (c) => c.targetedSpeciesUniqueReference === lossData.selectedSpecies.value
    );
  }

  async autoAddNewResourceToConflicts(lossData, resourceData) {
    const conflictWithMatchingSpecies = this.findConflictWithMatchingSpecies(lossData);
    if (conflictWithMatchingSpecies) {
      let resourceOnConflict = null;
      conflictWithMatchingSpecies.properties.forEach((property) => {
        property.protectedResources.forEach((resource) => {
          if (resource.protectedResourceName === lossData.selectedResource.label) {
            resourceOnConflict = resource;
          }
        });
      });
      if (!resourceOnConflict) {
        const addResourceToAgreementPropertyCall = await AgreementAPI.AddResourcesToAgreementProperties(
          this.state.data.agreementData.agreementUniqueIdentifier,
          [
            {
              resourceUniqueReference: lossData.selectedResource.value,
              unitOfMeasureEnumId: lossData.lossUom.value,
              currencyCodeEnumId: 0,
              valuationPerUnitOfMeasure: parseInt(lossData.unitValue),
              quantity: parseInt(resourceData.quantity),
              totalValuation: parseInt(resourceData.totalValue),
              valuationYear: parseInt(new Date().getFullYear()),
              addToProperties: [
                {
                  propertyUniqueReference: this.state.propertyVisitSummaryObj.propertyVisitedUniqueReference,
                },
              ],
            },
          ]
        );
        if (addResourceToAgreementPropertyCall?.successful) {
          let agreementResourceUniqueIdentifier = addResourceToAgreementPropertyCall?.data?.agreementProperties
            .find(
              (p) => p.propertyUniqueReference === this.state.propertyVisitSummaryObj.propertyVisitedUniqueReference
            )
            .agreementResources.find(
              (r) => r.resourceUniqueReference === lossData.selectedResource.value
            ).agreementResourceUniqueIdentifier;
          const body = [
            {
              propertyUniqueReference: this.state.propertyVisitSummaryObj.propertyVisitedUniqueReference,
              protectedResourceUniqueReference: agreementResourceUniqueIdentifier,
              threatTypeEnumId: resourceData.threatType.value,
            },
          ];
          const addResourceToConflictCall = await ConflictsAPI.AddProtectedResourcesToConflict(
            conflictWithMatchingSpecies.conflictUniqueIdentifier,
            body
          );
          if (addResourceToConflictCall?.successful) {
            toast.success("Resource added to Inventory");
            let { data } = this.state;
            data.agreementData = addResourceToAgreementPropertyCall.data;
            this.setState({ data, protectedResourcesData: this.getProtectedResourcesData() });
            this.refreshPVSObject();
            this.setState({ checkedForConflicts: false });
            await this.populateResourceEffort();
          }
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to add Protected Resources to Conflict",
            apiName: "AddProtectedResourcesToConflict",
            responseUnsuccessful: addResourceToConflictCall?.unsuccessful,
            responseMessage: addResourceToConflictCall?.message,
          });
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to add agreements to property",
            apiName: "AddProtectedResourcesToConflict",
            responseUnsuccessful: addResourceToAgreementPropertyCall?.unsuccessful,
            responseMessage: addResourceToAgreementPropertyCall?.message,
          });
        }
      } else {
        toast.success("Resource is already associated to Inventory.");
      }
    } else {
      const addResourceToAgreementPropertyCall = await AgreementAPI.AddResourcesToAgreementProperties(
        this.state.data.agreementData.agreementUniqueIdentifier,
        [
          {
            resourceUniqueReference: lossData.selectedResource.value,
            unitOfMeasureEnumId: lossData.lossUom.value,
            currencyCodeEnumId: 0,
            valuationPerUnitOfMeasure: parseInt(lossData.unitValue),
            quantity: parseInt(resourceData.quantity),
            totalValuation: parseInt(resourceData.totalValue),
            valuationYear: parseInt(new Date().getFullYear()),
            addToProperties: [
              {
                propertyUniqueReference: this.state.propertyVisitSummaryObj.propertyVisitedUniqueReference,
              },
            ],
          },
        ]
      );
      if (addResourceToAgreementPropertyCall?.successful) {
        toast.success("Resource added to Inventory");
        let { data } = this.state;
        data.agreementData = addResourceToAgreementPropertyCall.data;
        this.setState({ data, protectedResourcesData: this.getProtectedResourcesData() });
        this.refreshPVSObject();
        this.setState({ checkedForConflicts: false });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to update property",
          apiName: "AddResourcesToAgreementProperties",
          responseUnsuccessful: addResourceToAgreementPropertyCall?.unsuccessful,
          responseMessage: addResourceToAgreementPropertyCall?.message,
        });
      }
    }
  }

  getAttachTakeCreationBody(takeData) {
    let workPerformedUniqueReferences = [];

    workPerformedUniqueReferences.push(this.state.enterTakeData?.workItem?.workPerformedUniqueIdentifier);

    const callBody = {
      speciesTakenUniqueReference: takeData.speciesUniqueReference,
      conflictUniqueReference: takeData.conflictUniqueReference,
      fateUniqueReference: takeData.fateUniqueReference,
      workPerformedUniqueReferences: workPerformedUniqueReferences,
      methodUniqueReference: takeData.methodUniqueReference,
      quantity: parseInt(takeData.quantity),
      countTypeEnumId: takeData.countTypeEnumId,
      unitOfMeasureEnumId: takeData.unitOfMeasure.id,
      remarks: "",
      tags: takeData.tags,
      wasIntentional: takeData.wasIntentional,
      numberOfAnimalsSampled: takeData.numberOfAnimalsSampled,
      numberOfNRMPTaken: takeData.numberOfNRMPTaken,
      numberOfDiseaseMonitoringTaken: takeData.numberOfDiseaseMonitoringTaken,
      isOther: takeData.isOther,
    };

    if (takeData.latitude && takeData.longitude) {
      callBody.latitude = parseFloat(takeData.latitude);
      callBody.longitude = parseFloat(takeData.longitude);
    }

    callBody.permitUniqueReference = takeData.permitUniqueReference;

    callBody.intermediateFateUniqueReferences = takeData.intermediateFateUniqueReferences?.map((f) => f.value);

    return callBody;
  }

  getTakeCreationBody(takeData) {
    let workPerformedUniqueReferences = [];

    if (takeData.isExistingTake) {
      workPerformedUniqueReferences.push(this.state.enterTakeData?.workItem?.workPerformedUniqueIdentifier);
    } else {
      workPerformedUniqueReferences.push(takeData.workPerformedId);
    }
    const callBody = {
      speciesTakenUniqueReference: takeData.isUnintentionalTake
        ? takeData.unintentionalSpecies?.value
        : takeData.intentionalSpecies,
      conflictUniqueReference: takeData.conflictId,
      fateUniqueReference: takeData.selectedFate,
      workPerformedUniqueReferences: workPerformedUniqueReferences,
      methodUniqueReference: takeData.methodUniqueId,
      quantity: parseInt(takeData.quantity),
      countTypeEnumId: takeData.actualCount,
      unitOfMeasureEnumId: takeData.selectedUom,
      remarks: "",
      tags: takeData.tags,
      wasIntentional: !takeData.isUnintentionalTake,
      numberOfAnimalsSampled: takeData.totalAnimalsSampled ? parseInt(takeData.totalAnimalsSampled) : 0,
      numberOfNRMPTaken: takeData.rabiesSamplingQty ? parseInt(takeData.rabiesSamplingQty) : 0,
      numberOfDiseaseMonitoringTaken: takeData.diseaseSamplingQty ? parseInt(takeData.diseaseSamplingQty) : 0,
      isOther: takeData.isOtherSampling,
    };

    if (takeData.latitude && takeData.longitude) {
      callBody.latitude = parseFloat(takeData.latitude);
      callBody.longitude = parseFloat(takeData.longitude);
    }

    if (takeData.selectByPermit) {
      callBody.permitUniqueReference = takeData.selectByPermit.permitUniqueIdentifier;
    }

    if (takeData.selectedIntermediateFates?.length > 0) {
      callBody.intermediateFateUniqueReferences = takeData.selectedIntermediateFates.map((f) => f.value);
    }

    return callBody;
  }

  getTakeEditBody(takeData) {
    const speciesId = takeData.isUnintentionalTake ? takeData.unintentionalSpecies?.value : takeData.intentionalSpecies;
    return {
      speciesTakenUniqueReference: speciesId ? speciesId : null,
      fateUniqueReference: takeData.selectedFate,
      wasIntentional: !takeData.isUnintentionalTake,
      quantity: Number.parseInt(takeData.quantity),
      countTypeEnumId: takeData.actualCount,
      unitOfMeasureEnumId: takeData.selectedUom,
      permitUniqueReference: takeData.selectByPermit ? takeData.selectByPermit?.value : null,
      latitude: takeData.latitude,
      longitude: takeData.longitude,
    };
  }

  handleEditSiteVisit = async () => {
    if (this.state.isPageEditable) {
      let updatedSiteVisit = await this.updateSiteVisitPerformedBy();
      let editedEffortTime = await this.editProtectResourceEffortTime();
      let addedOrRemovedProjects = await this.addOrRemoveProjectsToSiteVisit(true);
      if (updatedSiteVisit && editedEffortTime && addedOrRemovedProjects) {
        toast.success("All changes to Site Visit applied.");
        this.refreshPVSObject(updatedSiteVisit);
      }
      this.setState({ isPageEditable: false });
    } else {
      this.setState(
        {
          isPageEditable: true,
        },
        () =>
          this.setState({
            workPerformedDataColumns: this.getWorkPerformedDataColumns(),
            workPerformedTakesColumns: this.getWorkPerformedTakesColumns(),
          })
      );
    }
  };

  async editProtectResourceEffortTime() {
    let { propertyVisitSummaryObj } = this.state;
    const editProtectResourceEffortTimeOnPVSResponse = await WorkTaskAPI.EditProtectResourceEffortTimeOnPVS(
      propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier,
      this.getCompleteSiteVisitBody()
    );
    if (editProtectResourceEffortTimeOnPVSResponse?.successful) {
      return editProtectResourceEffortTimeOnPVSResponse;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to change Site Visit effort",
        apiName: "EditProtectResourceEffortTimeOnPVS",
        responseUnsuccessful: editProtectResourceEffortTimeOnPVSResponse?.unsuccessful,
        responseMessage: editProtectResourceEffortTimeOnPVSResponse?.message,
      });
      return;
    }
  }

  async updateSiteVisitPerformedBy() {
    let { performedBy, propertyVisitSummaryObj } = this.state;
    if (performedBy?.value === propertyVisitSummaryObj.performedByWsUserProfileUniqueReference) {
      return true;
    }
    const updatePVSResponse = await WorkTaskAPI.UpdatePVS(
      propertyVisitSummaryObj?.propertyVisitSummaryUniqueIdentifier,
      {
        performedByWsUserProfileUniqueReference: performedBy?.value,
        enteredByWsUserProfileUniqueReference: performedBy?.value,
      }
    );
    if (updatePVSResponse?.successful) {
      return updatePVSResponse;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to change Site Visit performed by field",
        apiName: "UpdatePVS",
        responseUnsuccessful: updatePVSResponse?.unsuccessful,
        responseMessage: updatePVSResponse?.message,
      });
      return;
    }
  }

  renderModalButtonsForTabs() {
    let modalButtonObject = "";
    if (this.state.activeWorkTaskType === WorkTaskTypes.RecordLoss) {
      modalButtonObject = (
        <Button variant="outline-primary" className={styles.tabsModalButtons} onClick={this.enterNonInventoryLoss}>
          Enter Non-Inventory Loss
        </Button>
      );
    } else if (this.state.activeWorkTaskType === WorkTaskTypes.AdminWT) {
      modalButtonObject = (
        <Button variant="outline-primary" className={styles.tabsModalButtons} onClick={this.enterAdminWorkTask}>
          Enter Admin WT
        </Button>
      );
    } else if (this.state.activeWorkTaskType === WorkTaskTypes.RecordTA) {
      modalButtonObject = (
        <Button variant="outline-primary" className={styles.tabsModalButtons} onClick={this.enterTAWorkTask}>
          Enter TA Work Task
        </Button>
      );
    } else if (this.state.activeWorkTaskType === WorkTaskTypes.RecordSample) {
      modalButtonObject = (
        <Button variant="outline-primary" className={styles.tabsModalButtons} onClick={this.enterSampleTask}>
          Enter Sample
        </Button>
      );
    }

    return (
      <Row className={styles.modalButtonsRow}>
        <Col className="text-right pr-0">{modalButtonObject}</Col>
      </Row>
    );
  }

  setSelectPointLatitude = (num) => {
    let { data } = this.state;
    if (this.state.data.methodChosenUOM === "Each" && this.state.data.methodQuantity === "1") {
      data.methodLatitude = num;
      this.setState({ data });
    }
  };

  setSelectPointLongitude = (num) => {
    let { data } = this.state;
    if (this.state.data.methodChosenUOM === "Each" && this.state.data.methodQuantity === "1") {
      data.methodLongitude = num;
      this.setState({ data });
    }
  };

  setHighlightedMapPointMethod = (method) => {
    this.setState({ highlightedMapPointMethod: method });
  };

  showSiteVisitParcelMap = () => {
    if (sessionStorage.getItem("mapAccess") === "Yes") {
      return (
        <SiteVisitParcelMap
          address={this.state.data.propertyAddress}
          name={this.state.data.propertyName}
          location={this.state.data.mapPoint}
          parcelDetails={this.state.data.parcels}
          setSelectPointLatitude={this.setSelectPointLatitude}
          setSelectPointLongitude={this.setSelectPointLongitude}
          inventoryMapItems={this.state.inventoryMapItems}
          setHighlightedMapPointMethod={this.setHighlightedMapPointMethod}
          propertyMethodInventory={this.state.data.propertyMethodInventory}
          siteVisitInstance={this}
          agreementData={this.state.data.agreementData}
          refreshMapItems={this.state.refreshMapItems}
        />
      );
    } else {
      return (
        <WebMap
          address={this.state.data.propertyAddress}
          name={this.state.data.propertyName}
          location={this.state.data.mapPoint}
          setSelectPointLatitude={this.setSelectPointLatitude}
          setSelectPointLongitude={this.setSelectPointLongitude}
          inventoryMapItems={this.state.inventoryMapItems}
          setHighlightedMapPointMethod={this.setHighlightedMapPointMethod}
          propertyMethodInventory={this.state.data.propertyMethodInventory}
          siteVisitInstance={this}
          refreshMapItems={this.state.refreshMapItems}
        />
      );
    }
  };

  renderEditablePersonnelInfoFields() {
    return (
      <React.Fragment>
        <Row className={styles.personnelInformationLabelRow}>
          <Col lg={3} id="performedByLabel">
            Performed By
          </Col>
          <Col lg={2} id="siteVisitDateLabel">
            Date
          </Col>
          <Col lg={2} id="siteVisitHoursLabel">
            Hours
          </Col>
          <Col lg={2} id="siteVisitMinutesLabel">
            Minutes
          </Col>
          <Col lg={3} id="siteVisitAvailableProjectsLabel">
            Available Project(s)
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <AsyncSelect
              id="siteVisitPerformedBySearch"
              aria-labelledby="performedByLabel"
              name="performedBy"
              value={this.state.performedBy}
              openMenuOnClick={false}
              placeholder="Select One"
              components={{ DropdownIndicator, Option }}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                option: (provided) => ({ ...provided, textAlign: "left", fontSize: "14px" }),
                singleValue: (provided) => ({ ...provided, fontSize: "14px" }),
              }}
              theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
              loadOptions={this.loadAvailableUsers}
              onChange={this.handlePerformedByChange}
              isDisabled={this.state.creationFieldsDisabled}
            />
          </Col>
          <Col lg={2}>
            <Form.Control
              aria-labelledby="siteVisitDateLabel"
              name="pvsDate"
              id="pvsDate"
              type="text"
              className={styles.pvsDateInput}
              value={this.state.pvsDate}
              disabled={this.state.creationFieldsDisabled}
              onChange={this.pvsDateOnChange}
              onSelect={this.pvsDateOnSelect}
            />
          </Col>
          <Col lg={2}>
            <Form.Control
              aria-labelledby="siteVisitHoursLabel"
              value={this.state.data.pvsHours}
              name="pvsHours"
              type="number"
              placeholder="Hours"
              onChange={this.handleChange}
              min="0"
            />
          </Col>
          <Col lg={2}>
            <Form.Control
              aria-labelledby="siteVisitMinutesLabel"
              as="select"
              type="number"
              onChange={this.handleChange}
              value={this.state.data.pvsMinutes}
              name="pvsMinutes"
            >
              <option value="0" key="pvsMinutes0">
                0
              </option>
              <option value="15" key="pvsMinutes15">
                15
              </option>
              <option value="30" key="pvsMinutes30">
                30
              </option>
              <option value="45" key="pvsMinutes45">
                45
              </option>
            </Form.Control>
          </Col>
          <Col lg={3}>
            <Select
              placeholder="Select"
              id="siteVisitAvailableProjectsSelect"
              aria-labelledby="siteVisitAvailableProjectsLabel"
              value={this.state.data.selectedProjects}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                option: (provided) => ({ ...provided, textAlign: "left", fontSize: "14px" }),
                singleValue: (provided) => ({ ...provided, fontSize: "14px" }),
              }}
              isMulti
              options={this.state.availableProjects}
              onChange={(e) => {
                const { data } = this.state;
                data.selectedProjects = e ?? [];
                this.setState({ data });
              }}
              isDisabled={false}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderNonEditablePersonelInfoFields() {
    const { data } = this.state;
    return (
      <Row className="text-left">
        <Col>{this.renderLabel("performedByName", "Performed By", this.state.performedBy?.label)}</Col>
        <Col>{this.renderLabel("siteVisitDate", "Date", this.state.pvsDate)}</Col>
        <Col>
          {this.renderLabel(
            "timeOnProperty",
            "Time On Property",
            data.pvsHours || data.pvsMinutes ? data.pvsHours + "h " + data.pvsMinutes + "m" : ""
          )}
        </Col>
        <Col>
          {this.renderLabel(
            "selectedProjects",
            "Available Project(s)",
            data.selectedProjects.map((project) => project.label).join(", ")
          )}
        </Col>
      </Row>
    );
  }

  render() {
    const { isDetailsPage, isPageEditable } = this.state;
    const isStickyComponentAvailable = !isDetailsPage || isPageEditable;
    let propertyType = "Non-Parcel";
    if (this.state.data.propertyType?.displayText) {
      propertyType = this.state.data.propertyType.displayText;
    } else {
      propertyType = this.state.data.propertyType;
    }

    const expandRow = {
      parentClassName: "selectParentRow",
      renderer: (row) => {
        const selectSubRow = {
          mode: "checkbox",
          clickToSelect: false,
          hideSelectAll: true,
          selected: row.propertyMethodInventorySubTableSelected,
          onSelect: this.handleSubRowOnSelect,
          style: (row) => {
            const style = {};
            if (
              row.deployedItemUniqueIdentifier === this.state.highlightedMapPointMethod?.deployedItemUniqueIdentifier
            ) {
              style.backgroundColor = "#ADD8E6";
            } else {
              style.backgroundColor = "#DAE9E5";
            }
            return style;
          },
          headerColumnStyle: {
            borderTop: "hidden",
            borderBottom: "2px solid #008767",
          },
          selectColumnStyle: {
            verticalAlign: "middle",
          },
        };
        const dummyExpandRow = {
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
              return <b></b>;
            }
            return <b></b>;
          },
        };
        return (
          <div className={styles.subTableContainer}>
            <ToolkitProvider
              keyField="deployedItemUniqueIdentifier"
              data={row.propertyMethodInventorySubTable}
              columns={this.state.propertyMethodInventorySubTableColumns}
              bootstrap4={true}
              hover={true}
            >
              {(props) => (
                <div>
                  <BootstrapTable
                    keyField="deployedItemUniqueIdentifier"
                    bordered={false}
                    condensed
                    expandRow={dummyExpandRow}
                    selectRow={selectSubRow}
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        );
      },
      showExpandColumn: true,
      expandByColumnOnly: true,
      expanded: this.state.propertyMethodInventoryRowExpanded,
      onExpand: this.handlePropertyMethodInventoryRowOnExpand,
      nonExpandable: this.state.propertyMethodInventoryRowNotExpandable,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b></b>;
        }
        return <b></b>;
      },
      expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
        if (expanded) {
          return <img className={styles.chevron} src={ChevronDown} alt="down arrow" />;
        }
        return <img className={styles.chevron} src={ChevronRight} alt="right arrow" />;
      },
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.propertyMethodInventoryRowSelected,
      onSelect: this.handlePropertyMethodInventoryRowOnSelect,
      onSelectAll: this.handleOnSelectAll,
      style: { backgroundColor: "#f5f5f5" },
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "hidden",
        paddingBottom: "15px",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #dee2e6",
      },
      hideSelectAll: !isStickyComponentAvailable,
    };

    return (
      <div>
        <LoadingOverlay
          active={this.state.isDataLoading}
          spinner
          text={`${this.state.dataLoadingText ? this.state.dataLoadingText : "Loading data..."}`}
          styles={{
            wrapper: (base) => ({
              ...base,
              height: "100%",
            }),
            overlay: (base) => ({
              ...base,
              width: "100%",
              height: "100%",
            }),
            content: (base) => ({
              ...base,
              position: "fixed",
              top: "60%",
              left: "49%",
            }),
            spinner: (base) => ({
              ...base,
              position: "fixed",
              top: "50%",
              left: "50%",
              width: "100px",
              marginBottom: "99%",
              "& svg circle": {
                stroke: "#007bff",
              },
            }),
          }}
        >
          <Form noValidate validated={this.state.data.validated} onSubmit={this.handleSubmit}>
            <Row className={styles.pageHeader}>
              <Col>
                <h5 className={globalStyles.pageTitle}>
                  {isDetailsPage ? "Site Visit Details Page" : "Create Site Visit"}
                </h5>
              </Col>
              {this.state.isDetailsPage && (
                <Col className="form-inline justify-content-end">
                  <Button variant="primary" onClick={this.handleEditSiteVisit}>
                    <span className={globalStyles.modalSubmitButtonText}>
                      {this.state.isPageEditable ? "Apply Changes" : "Edit"}
                    </span>
                  </Button>
                </Col>
              )}
            </Row>
            <Row className={styles.pageRow}>
              <div className={isStickyComponentAvailable ? this.state.personnelInfoClass : styles.staticPVSPersonnel}>
                <Col lg={12}>
                  <Card>
                    <div
                      className={
                        isStickyComponentAvailable ? this.state.stickyCardHeaderStyle : styles.topCardNonStickyHeader
                      }
                    >
                      <i className="fa fa-home fa-2x mr-2" aria-hidden="true"></i>
                      <span
                        className={isStickyComponentAvailable ? this.state.stickyCardTextStyle : styles.cardTitleText}
                      >
                        Property: {this.state.data.propertyData?.propertyName}
                      </span>
                    </div>
                    <Card.Body className={isStickyComponentAvailable ? this.state.stickyCardBodyStyle : ""}>
                      {isStickyComponentAvailable
                        ? this.renderEditablePersonnelInfoFields()
                        : this.renderNonEditablePersonelInfoFields()}
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            </Row>
            <Row className={styles.pageRow}>
              <Col>
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle as="div" eventKey="0">
                    <div className={styles.conflictsAccordionToggle}>
                      <this.contextAwareToggle eventKey="0" />
                      <span className={styles.conflictInfoText}>Conflict Information</span>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Row noGutters="true">
                      <Col lg={6} className={styles.conflictsCard}>
                        <Card className={styles.conflictsCard}>
                          <Card.Header className={styles.conflictsCardHeader}>
                            <span className={styles.cardTitleText}>
                              Conflicts - {this.state.propertyConflicts.length}
                            </span>
                            {/* DO NOT DELETE: FUNCTIONALITY DEFERRED FOR LATER SPRINT
                          <div className={styles.conflictHeaderLink}>
                            <Image src={menuIcon} className="pl-2" />
                            <Button
                              variant="link"
                              onClick={() => this.setState({ showAllConflicts: !this.state.showAllConflicts })}
                            >
                              View All
                            </Button>
                          </div>*/}
                            <Button
                              hidden={!this.state.isPageEditable && this.state.isDetailsPage}
                              variant="link"
                              className={styles.conflictHeaderLink}
                              onClick={this.createConflict}
                            >
                              + Create New Conflict
                            </Button>
                          </Card.Header>
                          <Card.Body className={styles.conflictsCardBody}>{this.renderConflictList()}</Card.Body>
                        </Card>
                      </Col>
                      <Col lg={6} className={styles.conflictsCard}>
                        {this.renderResourceEffortArrowIndicator()}
                        <Card className={styles.conflictsCard}>
                          <Card.Header className={styles.conflictsCardHeader}>
                            <span className={styles.cardTitleText}>Resource Effort</span>
                            <div className={styles.resourceEffortHeaderCheckboxContainer}>
                              <input
                                id="previousEffortRatioCheckbox"
                                title={"Use Previous Effort Ratio checkbox"}
                                label="Use Previos Effort Ratio"
                                type="checkbox"
                                disabled={!this.state.ableToUsePreviousEffortRatio}
                                className={styles.resourceEffortHeaderCheckbox}
                                onChange={this.handleCheckPreviousEffortRatio}
                              ></input>
                              <label
                                htmlFor="previousEffortRatioCheckbox"
                                className={this.state.ableToUsePreviousEffortRatio ? "" : styles.greyedOut}
                              >
                                Use Previous Effort Ratio
                              </label>
                            </div>
                          </Card.Header>
                          <Card.Body className={styles.conflictsCardBody + " pt-0"}>
                            {this.renderResourceEffortList()}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Accordion.Collapse>
                </Accordion>
              </Col>
            </Row>
            <Row className={styles.pageRow}>
              <Col className={styles.propertyMapPVSPageCard}>
                <Card>
                  <Card.Header className={styles.cardHeader}></Card.Header>
                  <div>
                    <Row className={styles.mapContainer} hidden={propertyType === "Parcel"}>
                      <WebMap
                        address={this.state.data.propertyAddress}
                        name={this.state.data.propertyName}
                        location={this.state.data.mapPoint}
                        setSelectPointLatitude={this.setSelectPointLatitude}
                        setSelectPointLongitude={this.setSelectPointLongitude}
                        inventoryMapItems={this.state.inventoryMapItems}
                        setHighlightedMapPointMethod={this.setHighlightedMapPointMethod}
                        propertyMethodInventory={this.state.data.propertyMethodInventory}
                        siteVisitInstance={this}
                        refreshMapItems={this.state.refreshMapItems}
                      />
                    </Row>
                    <Row className={styles.mapContainer} hidden={propertyType === "Non-Parcel"}>
                      {this.showSiteVisitParcelMap()}
                    </Row>
                    {this.renderModalButtonsForTabs()}
                    <Row className={styles.pageRow}>
                      <Col>
                        <Tabs onSelect={this.setActiveWorkTaskType} className={styles.workTaskTabs}>
                          <Tab
                            eventKey="1"
                            title="Direct Control WT"
                            id="directWorkTaskTab"
                            className={styles.workTaskSpecificTab}
                          >
                            {this.renderDirectWorkTaskTab()}
                            <Accordion defaultActiveKey="1" className={styles.pageRowAccordion}>
                              <Card className={styles.accordionCardStyle}>
                                <Card.Header id={styles.accordionTableHeader}>
                                  <div>
                                    <this.contextAwareToggle eventKey="0" />
                                    <span className={styles.accordionTableHeaderText}>Property Method Inventory</span>
                                  </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                  <div className={styles.pmiInnerTable}>
                                    {this.state.isPMILoading ? (
                                      <Spinner animation="border" role="status">
                                        <span className="visually-hidden" aria-label="Loading..."></span>
                                      </Spinner>
                                    ) : (
                                      <Row>
                                        <ToolkitProvider
                                          keyField="methodUniqueIdentifier"
                                          data={this.state.data.propertyMethodInventory}
                                          columns={this.getPropertyMethodInventorySubTableColumns()}
                                          bootstrap4={true}
                                          hover={true}
                                        >
                                          {(props) => (
                                            <div>
                                              <BootstrapTable
                                                keyField="methodUniqueIdentifier"
                                                bordered={false}
                                                condensed
                                                expandRow={expandRow}
                                                selectRow={selectRow}
                                                {...props.baseProps}
                                              />
                                            </div>
                                          )}
                                        </ToolkitProvider>
                                      </Row>
                                    )}
                                  </div>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                            {this.state.shouldRenderWorkPerformed && this.renderWorkPerformedTable()}
                          </Tab>
                          <Tab eventKey="2" title="Enter Loss" id="recordLoss">
                            {this.renderRecordLossTab()}
                          </Tab>
                          <Tab eventKey="3" title="Admin WT" id="adminWorkTaskTab">
                            {this.renderAdminWorkTaskTab()}
                          </Tab>
                          <Tab eventKey="4" title="Tech Assist WT" id="TATab">
                            {this.renderRecordTATab()}
                          </Tab>
                          <Tab eventKey="5" title="Add Sample" id="recordSampleTab">
                            {this.renderRecordSampleTab()}
                          </Tab>
                        </Tabs>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
            {!this.state.isDetailsPage && (
              <Row className={styles.pvsPageButtons}>
                <Col>
                  <span className={styles.pvsSubmitButton}>
                    <Button className={globalStyles.modalSubmitButton} onClick={this.completePVS}>
                      <span className={globalStyles.modalSubmitButtonText}>Submit</span>
                    </Button>
                  </span>
                  <span className={styles.pvsCancelButton}>
                    <Button className={globalStyles.modalCancelButton} onClick={this.cancelPVS}>
                      <span className={globalStyles.modalCancelButtonText}>Cancel</span>
                    </Button>
                  </span>
                </Col>
              </Row>
            )}
          </Form>
        </LoadingOverlay>
        <EnterLossModal
          show={this.state.showLossModal}
          onHide={this.closeLossModal}
          resourceData={this.state.currentResourceRecord}
          onSubmitLoss={this.saveInventoryLoss}
        />
        <EnterTakeModal
          show={this.state.showEnterTakeModal}
          onHide={this.closeEnterTakeModal}
          data={this.state.enterTakeData}
          onAddTake={this.addTakeToWorkPerformed}
          onAttachTake={this.attachTakeToWorkPerformed}
          onEditTake={this.editTakeOnWorkPerformed}
          pvsDate={this.state.pvsDate}
          availableTakes={this.state.takesData}
        />
        <NonInventoryLossModal
          show={this.state.showNonInventoryLossModal}
          onHide={this.closeNonInventoryLossModal}
          onSubmitLoss={this.saveNonInventoryLoss}
          resourceData={this.state.resourcePropertyAssociationData}
        />
        <AdminWorkTaskModal
          show={this.state.showAdminWorkTaskModal}
          onHide={this.closeAdminWorkTaskModal}
          onSubmitTask={this.saveAdminWorkTask}
          data={this.state.adminWorkTaskData}
        />
        <TAWorkTaskModalWithTopics
          show={this.state.showTAWorkTaskModal}
          onHide={this.closeTAWorkTaskModal}
          fromSiteVisit={true}
          isCooperator={true}
          agreementData={this.state.data.agreementData}
          propertyData={this.state.data.propertyData}
          states={this.props.history.location.state?.states}
          propertyVisitSummary={this.state.propertyVisitSummaryObj}
          permissions={this.props.permissions}
        />
        <AssociateResourceInventoryModal
          show={this.state.showAssociateResourceInventoryModal}
          onHide={this.closeAssociateResourceInventoryModal}
          onSubmitResourceAssociation={this.saveResourcePropertyAssociation}
          data={this.state.resourcePropertyAssociationData}
          propertyName={this.state.data.propertyName}
          propertyConflicts={this.state.propertyConflicts}
        />
        <OtherSampleModal
          show={this.state.showOtherSampleModal}
          onHide={this.closeOtherSampleModal}
          back={this.backOtherSampleModal}
          otherSampleData={this.state.otherSampleData}
          save={this.saveOtherSample}
          fromSiteVisit={!this.state.inProgressTakeData?.isWithoutTake}
          takeData={this.state.inProgressTakeData}
          currentUsaState={this.currentUsaState}
          propertyData={this.state.data.propertyData}
          pvsDate={this.state.pvsDate}
          employeeName={this.state.performedBy?.label}
        />
        <DiseaseMonitoringModal
          show={this.state.showDiseaseMonitoringModal}
          onHide={this.closeDiseaseMonitoringModal}
          back={this.backDiseaseMonitoringModal}
          takeData={this.state.inProgressTakeData}
          currentUsaState={this.currentUsaState}
          propertyData={this.state.data.propertyData}
          fromSiteVisit={!this.state.inProgressTakeData?.isWithoutTake}
          pvsDate={this.state.pvsDate}
          employee={this.state.performedBy}
          save={this.saveDiseaseMonitoring}
        />
        <DiseaseShippingModal
          show={this.state.showDiseaseShippingModal}
          onHide={this.closeDiseaseShippingModal}
          back={this.backDiseaseShippingModal}
          diseaseMonitoringData={this.state.diseaseMonitoringData}
          diseaseMonitoringAnimalSampleTable={this.state.diseaseMonitoringAnimalSampleTable}
          takeData={this.state.inProgressTakeData}
          save={this.saveDiseaseShipping}
        />
        <RabiesSamplesModal
          show={this.state.showRabiesSampleModal}
          onHide={() => {
            this.setState({ showRabiesSampleModal: false });
          }}
          data={this.state.rabiesSampleData}
          onSubmitSamples={this.saveRabiesSamples}
          diseaseSamplingQty={this.state.inProgressTakeData?.diseaseSamplingQty}
        />
        {this.renderCreateConflictModal()}
        <AviationGroundCrewingModal
          show={this.state.showAviationGroundCrewModal}
          onHide={() => {
            this.setState({ showAviationGroundCrewModal: false });
          }}
          data={this.state.data.selectedAircrafts}
          onSave={this.saveAircraftsSelection}
        />
        <EnterSampleModal
          show={this.state.showEnterSampleModal}
          onHide={() => {
            this.setState({ showEnterSampleModal: false });
          }}
          onSave={this.saveSamplesSelection}
        />
        {this.state.showManageProjectsModal && (
          <ManageProjectsModal
            show={this.state.showManageProjectsModal}
            onHide={() => {
              this.setState({ showManageProjectsModal: false });
            }}
            availableProjects={this.state.availableProjects}
            manageProjectsData={this.state.manageProjectsData}
            refreshPVSObject={() => {
              this.refreshPVSObject();
            }}
          />
        )}
        {this.state.showManageWorkPerformedModal && (
          <ManageWorkPerformedModal
            show={this.state.showManageWorkPerformedModal}
            onHide={() => {
              this.setState({ showManageWorkPerformedModal: false });
            }}
            methodTargets={this.state.methodTargets}
            methodChosenTargets={this.state.methodChosenTargets}
            methodProtects={this.state.methodProtects}
            methodChosenProtections={this.state.methodChosenProtections}
            manageWorkPerformedData={this.state.manageWorkPerformedData}
            refreshPVSObject={() => {
              this.refreshPVSObject();
            }}
            handleManageWorkPerformedTargetsSelection={this.handleManageWorkPerformedTargetsSelection}
            handleManageWorkPerformedProtectingSelection={this.handleManageWorkPerformedProtectingSelection}
            updateMethodProtectedResourcesDropdown={this.updateMethodProtectedResourcesDropdown}
            propertyVisitSummaryObj={this.state.propertyVisitSummaryObj}
            getConflictIds={this.getConflictIds}
          />
        )}
      </div>
    );
  }
}

export default withRouter(PropertyVisitSummary);
