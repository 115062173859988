import React from "react";
import styles from "../AgreementEntities/Contact/Contact.module.scss";
import { Card, Row, Col, Form, InputGroup, FormControl, Button } from "react-bootstrap";

const ContactOrgAddressDisplay = ({ addresses, states, countries }) => {
	return (
		<div className="mx-2">
			{addresses.map((address, index) => {
				let stateName = "";
				let countryName = "";
				if (
					address.mailingStateUniqueId &&
					address.mailingStateUniqueId !== "" &&
					address.mailingStateUniqueId !== "00000000-0000-0000-0000-000000000000"
				) {
					if (states.find((state) => state.id === address.mailingStateUniqueId) !== undefined)
						stateName = states.find((state) => state.id === address.mailingStateUniqueId).name;
				}
				if (
					address.mailingCountryUniqueId &&
					address.mailingCountryUniqueId !== "" &&
					address.mailingCountryUniqueId !== "00000000-0000-0000-0000-000000000000"
				) {
					countryName = countries.find((country) => country.id === address.mailingCountryUniqueId).name;
				}
				return (
					<div key={index}>
						<Row>
							<Col>
								<Form.Group controlId={"mailingStreet" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>Street Address or PO Box</h6>
									<p className={styles.dataFields}>{address.mailingStreet}</p>
								</Form.Group>
							</Col>
						</Row>
						<Row xs={1} sm={2} md={4}>
							<Col>
								<Form.Group controlId={"mailingCity" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>City</h6>
									<p className={styles.dataFields}>{address.mailingCity}</p>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId={"mailingStateUniqueId" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>State</h6>
									<p className={styles.dataFields}>{stateName}</p>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId={"mailingCountryUniqueId" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>Country</h6>
									<p className={styles.dataFields}>{countryName}</p>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId={"mailingZip" + "-" + index} className="text-left">
									<h6 className={styles.fieldLabels}>ZIP Code</h6>
									<p className={styles.dataFields}>{address.mailingZip}</p>
								</Form.Group>
							</Col>
						</Row>
					</div>
				);
			})}
		</div>
	);
};

export default ContactOrgAddressDisplay;
