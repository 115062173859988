import React from "react";
import CustomForm from "../common/form";
import styles from "./agreements.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CheckedPropertySelection from "./checkedPropertySelection";

class PropertyParcelAssociationModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      isInitialConfiguration: true,
      checkedProperties: [],
    };
  }

  handleSave = () => {
    let checkedProperties = [];
    if (
      this.state.isInitialConfiguration &&
      this.props.checkedProperties.length === 0 &&
      this.props.properties.length === 1
    )
      checkedProperties = JSON.parse(JSON.stringify(this.props.properties));
    else checkedProperties = this.state.checkedProperties;

    this.props.onSaveAssociation(checkedProperties);
    this.props.onHide();
    this.setState({ isInitialConfiguration: true });
  };

  handleCancel = () => {
    this.props.onHide();
    this.setState({ isInitialConfiguration: true });
  };

  handlePropertyCheck = ({ currentTarget: input }) => {
    let { isInitialConfiguration, checkedProperties } = this.state;

    let existingCheckedProperties = "";
    if (isInitialConfiguration) {
      isInitialConfiguration = false;
      if (this.props.checkedProperties.length === 0 && this.props.properties.length === 1)
        existingCheckedProperties = JSON.parse(JSON.stringify(this.props.properties));
      else existingCheckedProperties = JSON.parse(JSON.stringify(this.props.checkedProperties));
    } else {
      existingCheckedProperties = [...checkedProperties];
    }

    const inputIdData = input.id.split("_");
    const propertyId = inputIdData[1];
    checkedProperties = this.getUpdatedPropertySelection(existingCheckedProperties, propertyId);

    this.setState({ isInitialConfiguration, checkedProperties });
  };

  getUpdatedPropertySelection(existingCheckedProperties, propertyId) {
    let checkedProperties = "";

    const originalPropery = JSON.parse(
      JSON.stringify(
        this.props.properties.find(
          (p) =>
            (p.propertyUniqueIdentifier && p.propertyUniqueIdentifier === propertyId) ||
            (p.propertyUniqueReference && p.propertyUniqueReference === propertyId)
        )
      )
    );

    if (
      existingCheckedProperties.length > 0 &&
      existingCheckedProperties.find(
        (property) =>
          (property.propertyUniqueReference ? property.propertyUniqueReference : property.propertyUniqueIdentifier) ===
          propertyId
      )
    ) {
      checkedProperties = existingCheckedProperties.filter(
        (property) =>
          (property.propertyUniqueReference ? property.propertyUniqueReference : property.propertyUniqueIdentifier) !==
          propertyId
      );
    } else if (existingCheckedProperties.length > 0) {
      checkedProperties = [...existingCheckedProperties, originalPropery];
    } else {
      checkedProperties = [originalPropery];
    }

    return checkedProperties;
  }

  getPropertyParcelAssociationDescription(entityType) {
    if (entityType === "protectedResource") return "Select one property associated with the resource.";
    else return "Select one or multiple properties.";
  }

  render() {
    const { properties, entityType } = this.props;

    let checkedProperties = [];
    if (!this.state.isInitialConfiguration)
      checkedProperties = JSON.parse(JSON.stringify(this.state.checkedProperties));
    else if (this.props.checkedProperties.length === 0 && properties?.length === 1)
      checkedProperties = JSON.parse(JSON.stringify(properties));
    else checkedProperties = JSON.parse(JSON.stringify(this.props.checkedProperties));

    const checkedPropertyIds = checkedProperties.map(
      (property) => property.propertyUniqueIdentifier || property.propertyUniqueReference
    );
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCancel}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.modalDialog}
      >
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Associate with Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-2 mb-3 mx-0">
            <Col>
              <span className={styles.smallComments}>
                <i className="fa fa-exclamation-circle " style={{ color: "gray" }} aria-hidden="true"></i>{" "}
                {this.getPropertyParcelAssociationDescription(entityType)}
              </span>
            </Col>
          </Row>
          <Row className="my-4 mx-0">
            <Col>
              <span className={styles.formLabel}>Property Selection</span>
            </Col>
          </Row>
          {properties
            .filter(
              (x) =>
                (x.propertyUniqueIdentifier && checkedPropertyIds.includes(x.propertyUniqueIdentifier)) ||
                (x.propertyUniqueReference && checkedPropertyIds.includes(x.propertyUniqueReference))
            )
            .map((property, index) => {
              return (
                <CheckedPropertySelection
                  property={property}
                  onPropertyChange={this.handlePropertyCheck}
                  key={index}
                  separator={!(properties?.length === checkedProperties.length && index === properties.length - 1)}
                  isSelectionDisable={this.props.isSelectionDisable}
                />
              );
            })}
          {properties
            .filter(
              (x) =>
                (x.propertyUniqueIdentifier && !checkedPropertyIds.includes(x.propertyUniqueIdentifier)) ||
                (x.propertyUniqueReference && !checkedPropertyIds.includes(x.propertyUniqueReference))
            )
            .map((property, index) => {
              return (
                <Row
                  className={`${index === 0 ? "mb-2 mt-4" : "my-2"} mx-0`}
                  key={property.propertyUniqueIdentifier || property.propertyUniqueReference}
                >
                  <Col>
                    <Form.Group
                      controlId={"property_" + (property.propertyUniqueIdentifier || property.propertyUniqueReference)}
                    >
                      <Form.Check
                        type="checkbox"
                        label={property.propertyName}
                        onClick={this.handlePropertyCheck}
                        disabled={
                          this.props.isSelectionDisable ||
                          (entityType === "protectedResource" && checkedProperties.length > 0)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button
            variant="primary"
            onClick={this.handleSave}
            disabled={
              checkedProperties?.length === 0 ||
              checkedProperties.find(
                (p) =>
                  p?.parcels?.length === 0 &&
                  properties.find(
                    (property) =>
                      (property.propertyUniqueIdentifier &&
                        property.propertyUniqueIdentifier === p.propertyUniqueIdentifier) ||
                      (property.propertyUniqueReference &&
                        property.propertyUniqueReference === p.propertyUniqueReference)
                  )?.parcels?.length !== 0
              )
            }
          >
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PropertyParcelAssociationModal;

