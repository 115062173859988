import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CreateEditFateModal from "../ReferenceFilesCreatePages/CreateEditFateModal";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import ConfigureFateModal from "../ReferenceFilesEditModals/ConfigureFateModal";
import CustomTextFilter from "../common/customTextFilter";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class FateTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fateData: [],
      currentUsaState: props.currentUsaState,
      defaultSort: [{ dataField: "name", order: "asc" }],
      columns: [
        {
          dataField: "fateUniqueIdentifier",
          hidden: true,
          csvText: "Fate Unique Id",
        },
        {
          dataField: "name",
          text: "Name",
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomTextFilter
              onFilter={onFilter}
              column={column}
              title="Fate Name Search"
              labelledBy="fateNameHeader"
            />
          ),
          sort: true,
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3" };
          },
          headerAttrs: { id: "fateNameHeader" },
          attrs: { headers: "fateNameHeader", tabIndex: "0" },
        },
        {
          dataField: "isAllowedGlobally",
          text: "Status",
          formatter: (cell, row, rowIndex) => {
            return row.isAllowedGlobally ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          },
          csvFomatter: (cell, row, rowIndex) => {
            return row.isAllowedGlobally ? "Active" : "Inactive";
          },
          sort: true,
          sortFunc: (a, b, order) => {
            if (order === "asc") {
              return b - a;
            }
            return a - b; // desc
          },
          align: "left",
          headerAlign: "left",
          style: this.tableTextStyle,
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          headerAttrs: { id: "fateAllowedHeader" },
          attrs: { headers: "fateAllowedHeader", tabIndex: "0" },
        },
        {
          dataField: "lastModifiedDateTimeUTC",
          text: "Last Modified Date",
          formatter: (cell, row, rowIndex, formatExtraData) => {
            return this.formatDate(cell);
          },
          sort: true,
          sortFunc: (a, b, order) => {
            let dateA = new Date(a);
            let dateB = new Date(b);
            if (order === "asc") {
              return dateB - dateA;
            }
            return dateA - dateB; // desc
          },
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
          },
          style: this.tableTextStyle,
          headerAttrs: { id: "fateLastModifiedHeader" },
          attrs: { headers: "fateLastModifiedHeader", tabIndex: "0" },
        },
        {
          dataField: "fateActions",
          text: "Actions",
          csvExport: false,
          editable: false,
          formatter: (cell, row) => {
            return (
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton id="dropdown-fate-actions" title="Actions" variant="link" className={styles.buttonLink}>
                  <Dropdown.Item
                    eventKey="fateEdit"
                    onSelect={() => this.handleFateEdit(row)}
                    hidden={!this.props.permissions.canIEditReferenceData()}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="fateHistoryTracking" onSelect={() => this.handleFateHistory(row)}>
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            );
          },
          headerStyle: () => {
            return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
          },
          headerAlign: "right",
          align: "right",
          headerAttrs: { id: "fateActionsHeader" },
          attrs: { headers: "fateActionsHeader", tabIndex: "0" },
        },
      ],
      showCreateModal: false,
      showConfigureModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
    };
  }

  tableTextStyle = {};

  static getDerivedStateFromProps(nextProps) {
    return {
      currentUsaState: nextProps.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier) {
      await this.updateFateData();
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "fateTab") {
      await this.updateFateData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "fateTab") {
      await this.updateFateData();
    }
  }

  async updateFateData() {
    this.props.setIsLoading(true);
    const fateData = await this.getDataFromAPI();
    this.setState({ fateData }, () => {
      this.props.setIsLoading(false);
    });
    window.scrollTo(0, 0);
  }

  async getDataFromAPI() {
    const { currentUsaState } = this.state;
    if (!currentUsaState?.stateGuid) return [];

    let fates = await ReferenceFileAPI.GetAllFates();
    if (fates?.successful) {
      return fates.data.results;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve fate records",
        apiName: "GetAllFates",
        responseUnsuccessful: fates?.unsuccessful,
        responseMessage: fates?.message,
      });
      return [];
    }
  }

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleDateString("en-US");
  }

  closeAllModals = () => {
    this.setState({ showCreateModal: false, showEditModal: false, showConfigureModal: false });
  };

  handleCreateFate = () => {
    this.setState({ showCreateModal: true });
  };

  handleFateConfigure = (row) => {
    this.setState({ modalData: row, showConfigureModal: true });
  };

  handleFateEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleFateHistory = (row) => {
    //do nothing for now
  };

  async configureFate(modalData) {
    let configureResult = null;
    if (modalData.isAllowed === "Yes" || modalData.isAllowed === true) {
      configureResult = await ReferenceFileAPI.AllowFateForState(
        modalData.fateUniqueIdentifier,
        this.props.currentUsaState?.stateGuid
      );
    } else if (this.state.data.isAllowed === "No" || this.state.data.isAllowed === false) {
      configureResult = await ReferenceFileAPI.DisallowFateForState(
        modalData.fateUniqueIdentifier,
        this.props.currentUsaState?.stateGuid
      );
    }
    if (configureResult.successful || configureResult.message.includes("already")) {
      this.setState({ showEditSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to configure fate record",
        apiName:
          modalData.isAllowed === "Yes" || modalData.isAllowed === true ? "AllowFateForState" : "DisallowFateForState",
        responseUnsuccessful: configureResult?.unsuccessful,
        responseMessage: configureResult?.message,
      });
    }
    this.closeAllModals();
  }

  createFate = async (modalData) => {
    try {
      if (modalData.name === "") {
        throw new Error("Fate Name must be present.");
      }
      let createBody = { name: modalData.name };
      let createFateResult = await ReferenceFileAPI.CreateFate(createBody);
      if (createFateResult?.successful) {
        let allowFateResult = await ReferenceFileAPI.AllowFateGlobally(createFateResult?.data?.fateUniqueIdentifier);
        if (allowFateResult?.successful) {
          this.insertNewFateFromResponse(createFateResult.data, allowFateResult.data);
          this.setState({ showEditSuccessModal: true });
        } else {
          this.insertNewFateFromResponse(createFateResult.data);
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to set fate record to allowed",
            apiName: "AllowFateGlobally",
            responseUnsuccessful: allowFateResult?.unsuccessful,
            responseMessage: allowFateResult?.message,
            overrideErrorForUser: `Successfully created Fate record, but unable to set Fate to allowed. ${allowFateResult?.message} Please try again.`,
          });
          throw new Error("Successfully created Fate record, but unable to set Fate to allowed. Please try again.");
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to create fate record",
          apiName: "CreateFate",
          responseUnsuccessful: createFateResult?.unsuccessful,
          responseMessage: createFateResult?.message,
        });
      }
    } catch (error) {
      ErrorHandler.showError(error, error.message);
    }
    this.closeAllModals();
  };

  insertNewFateFromResponse(createFateData, allowedFateData) {
    let fateTable = this.state.fateData;
    fateTable.push(allowedFateData ? allowedFateData : createFateData);
    this.setState({ fateData: fateTable });
  }

  updateFateFromResponse(fateData) {
    let fateTable = this.state.fateData;
    for (let i = 0; i < fateTable.length; i++) {
      if (fateTable[i].fateUniqueIdentifier === fateData?.fateUniqueIdentifier) {
        fateTable[i] = fateData;
        break;
      }
    }
    this.setState({ fateData: fateTable });
  }

  editFate = async (modalData) => {
    try {
      if (modalData.name === "") {
        throw new Error("Fate Name must be present.");
      }
      let editFateResult = null;
      let editFateSuccess = null;
      let allowanceResult = null;
      let allowanceSuccess = null;
      if (modalData.name !== this.state.modalData.name) {
        let editBody = { name: modalData.name };
        editFateResult = await ReferenceFileAPI.UpdateFate(modalData.fateUniqueIdentifier, editBody);
        if (editFateResult?.successful) {
          editFateSuccess = true;
          this.updateFateFromResponse(editFateResult.data);
        }
      } else {
        editFateResult = false;
      }
      if (modalData.isAllowedGlobally !== this.state.modalData.isAllowedGlobally) {
        allowanceResult = await this.getAllowanceResult(modalData);
        if (this.isSuccessfulResult(allowanceResult)) {
          allowanceSuccess = true;
          this.updateFateFromResponse(allowanceResult?.data);
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: modalData.isAllowedGlobally === "Active" ? "Unable to allow fate record" : "",
            apiName: modalData.isAllowedGlobally === "Active" ? "AllowFateGlobally" : "DisallowFateGlobally",
            responseUnsuccessful: allowanceResult?.unsuccessful,
            responseMessage: allowanceResult?.message,
          });
        }
      } else {
        allowanceResult = false;
      }
      if ((editFateResult && editFateSuccess) || (allowanceResult && allowanceSuccess)) {
        this.setState({ showEditSuccessModal: true, showEditModal: false });
        this.closeAllModals();
      } else if (editFateResult === false && allowanceResult === false) {
        toast.warning("No changes detected.");
      } else {
        this.closeAllModals();
      }
    } catch (error) {
      ErrorHandler.showError(error, error.message);
      this.setState({ showEditModal: false });
    }
  };

  async getAllowanceResult(modalData) {
    let allowanceResult = null;
    if (modalData.isAllowedGlobally === "Active" || modalData.isAllowedGlobally === true) {
      allowanceResult = await ReferenceFileAPI.AllowFateGlobally(modalData.fateUniqueIdentifier);
    } else if (modalData.isAllowedGlobally === "Inactive" || modalData.isAllowedGlobally === false) {
      allowanceResult = await ReferenceFileAPI.DisallowFateGlobally(modalData.fateUniqueIdentifier);
    }
    return allowanceResult;
  }

  isSuccessfulResult(result) {
    return result.successful || result.message?.includes("already") ? true : false;
  }

  renderCreateModal() {
    return (
      <CreateEditFateModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode={"Create"}
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
        createFate={this.createFate}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditFateModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode={"Edit"}
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        editFate={this.editFate}
      />
    );
  }

  renderConfigureModal() {
    return (
      <ConfigureFateModal
        show={this.state.showConfigureModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        usaStateGuid={this.props.currentUsaState?.stateGuid}
        onSubmit={this.configureFate}
      />
    );
  }

  renderModals = () => {
    return (
      <React.Fragment>
        {this.state.showCreateModal ? this.renderCreateModal() : null}
        {this.state.showEditModal ? this.renderEditModal() : null}
        {this.state.showConfigureModal ? this.renderConfigureModal() : null}
      </React.Fragment>
    );
  };

  render() {
    return (
      <ToolkitProvider
        keyField="fateUniqueIdentifier"
        data={this.state.fateData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "fate-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Fate"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleCreateFate}
                  recordCount={this.state.fateData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="fateUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.renderModals()}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="Fate"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="Fate"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default FateTab;
