import React from "react";
import styles from "./EditModalPages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { toast } from "react-toastify";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { Button, Modal, Form, Row, Col, Dropdown } from "react-bootstrap";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class EditMethodModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedRadio: this.props.allowedRadio,
      requiresGeolocationRadio: this.props.requiresGeolocationRadio,
      requiresLabelRadio: this.props.requiresLabelRadio,
      allowedUsesData: [],
      allowedUOMsData: [],
      isInitialConfiguration: true,
    };
  }

  async configureMethod() {
    let configureCall;
    let requireGeolocationCall;
    let requireLabelCall;
    let methodBody = {};
    let methodGuid = this.props.data.methodUniqueIdentifier;
    let stateGuid = this.props.usaStateData?.stateGuid;
    const intialAllowedRadioChange = this.props.data.isAllowedInState !== this.props.allowedRadio;
    const intialRequiredGeolocationRadioChange =
      this.props.data.isGeolocationRequiredInState !== this.props.requiresGeolocationRadio;
    const intialRequiredLabelRadioChange =
      this.props.data.isMethodLabelRequiredInState !== this.props.requiresLabelRadio;
    if (stateGuid) {
      if (!this.props.allowedRadio && intialAllowedRadioChange) {
        configureCall = await ReferenceFileAPI.DisallowMethodInState(methodBody, methodGuid, stateGuid);
      } else if (this.props.allowedRadio && intialAllowedRadioChange) {
        configureCall = await ReferenceFileAPI.AllowMethodInState(methodBody, methodGuid, stateGuid);
      } else {
        configureCall = false;
      }

      if (!this.props.requiresGeolocationRadio && intialRequiredGeolocationRadioChange) {
        requireGeolocationCall = await ReferenceFileAPI.SetGeoLocationOptionalInState(methodGuid, stateGuid);
      } else if (this.props.requiresGeolocationRadio && intialRequiredGeolocationRadioChange) {
        requireGeolocationCall = await ReferenceFileAPI.SetGeoLocationRequiredInState(methodGuid, stateGuid);
      } else {
        requireGeolocationCall = false;
      }

      if (!this.props.requiresLabelRadio && intialRequiredLabelRadioChange) {
        requireLabelCall = await ReferenceFileAPI.SetMethodLabelToOptionalInState(methodGuid, stateGuid);
      } else if (this.props.requiresLabelRadio && intialRequiredLabelRadioChange) {
        requireLabelCall = await ReferenceFileAPI.SetMethodLabelToRequiredInState(methodGuid, stateGuid);
      } else {
        requireLabelCall = false;
      }

      if (configureCall?.successful) {
        this.props.updateMethodFromResponse(configureCall.data);
      } else if (!(configureCall === false)) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set allowed status of method",
          apiName: this.props.allowedRadio && intialAllowedRadioChange ? "AllowMethodInState" : "DisallowMethodInState",
          responseUnsuccessful: configureCall?.unsuccessful,
          responseMessage: configureCall?.message,
        });
      }

      if (requireGeolocationCall?.successful) {
        this.props.updateMethodFromResponse(requireGeolocationCall.data);
      } else if (!(requireGeolocationCall === false)) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set geolocation status of method",
          apiName:
            this.props.requiresGeolocationRadio && intialRequiredGeolocationRadioChange
              ? "SetGeoLocationRequiredInState"
              : "SetGeoLocationOptionalInState",
          responseUnsuccessful: requireGeolocationCall?.unsuccessful,
          responseMessage: requireGeolocationCall?.message,
        });
      }

      if (requireLabelCall?.successful) {
        this.props.updateMethodFromResponse(requireLabelCall.data);
      } else if (!(requireLabelCall === false)) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set label required status of method",
          apiName:
            this.props.requiresLabelRadio && intialRequiredLabelRadioChange
              ? "SetMethodLabelToRequiredInState"
              : "SetMethodLabelToOptionalInState",
          responseUnsuccessful: requireLabelCall?.unsuccessful,
          responseMessage: requireLabelCall?.message,
        });
      }

      await this.updateAllowedUses();
      await this.updateAllowedUOMs();
    }
    this.clearData();
    this.props.onHide();
  }

  async updateAllowedUses() {
    await this.enableAllowedUsesForMethod();
    await this.disableAllowedUsesForMethod();
  }

  async updateAllowedUOMs() {
    await this.enableAllowedUOMsForMethod();
    await this.disableAllowedUOMsForMethod();
  }

  async enableAllowedUsesForMethod() {
    const allowedUsesToAdd = this.state.allowedUsesData.filter(
      (i) => !this.props.data.allowedUsesInState?.find((allowedUse) => allowedUse.id === i.id)
    );
    if (allowedUsesToAdd?.length > 0) {
      const enableAllowedUsesResponse = await ReferenceFileAPI.EnableMethodUsesInState(
        this.props.data.methodUniqueIdentifier,
        this.props.usaStateData.stateGuid,
        allowedUsesToAdd.map((i) => i.id)
      );
      if (enableAllowedUsesResponse?.successful) {
        this.props.updateMethodFromResponse(enableAllowedUsesResponse.data);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to enable method uses",
          apiName: "EnableMethodUsesInState",
          responseUnsuccessful: enableAllowedUsesResponse?.unsuccessful,
          responseMessage: enableAllowedUsesResponse?.message,
        });
      }
    }
  }

  async disableAllowedUsesForMethod() {
    const allowedUsesData = this.state.isInitialConfiguration
      ? [...this.props.data.allowedUsesInState]
      : [...this.state.allowedUsesData];
    const allowedUsesToRemove = this.props.data.allowedUsesInState?.filter(
      (allowedUse) => !allowedUsesData.find((i) => i.id === allowedUse.id)
    );

    if (allowedUsesToRemove?.length > 0) {
      const disableAllowedUsesResponse = await ReferenceFileAPI.DisableMethodUsesInState(
        this.props.data.methodUniqueIdentifier,
        this.props.usaStateData.stateGuid,
        allowedUsesToRemove.map((i) => i.id)
      );
      if (disableAllowedUsesResponse?.successful) {
        this.props.updateMethodFromResponse(disableAllowedUsesResponse.data);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to disable method uses",
          apiName: "DisableMethodUsesInState",
          responseUnsuccessful: disableAllowedUsesResponse?.unsuccessful,
          responseMessage: disableAllowedUsesResponse?.message,
        });
      }
    }
  }

  async enableAllowedUOMsForMethod() {
    const allowedUOMsToAdd = this.state.allowedUOMsData.filter(
      (i) => !this.props.data.allowedUOMsInState?.find((allowedUom) => allowedUom.id === i.id)
    );

    if (allowedUOMsToAdd?.length > 0) {
      const enableAllowedUOMsResponse = await ReferenceFileAPI.EnableMethodUOMsInState(
        this.props.data.methodUniqueIdentifier,
        this.props.usaStateData.stateGuid,
        allowedUOMsToAdd.map((i) => i.id)
      );
      if (enableAllowedUOMsResponse.successful) {
        this.props.updateMethodFromResponse(enableAllowedUOMsResponse.data);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to enable method units of measure",
          apiName: "EnableMethodUOMsInState",
          responseUnsuccessful: enableAllowedUOMsResponse?.unsuccessful,
          responseMessage: enableAllowedUOMsResponse?.message,
        });
      }
    }
  }

  async disableAllowedUOMsForMethod() {
    let allowedUOMsData = this.state.isInitialConfiguration
      ? [...this.props.data.allowedUOMsInState]
      : [...this.state.allowedUOMsData];
    const allowedUOMsToRemove = this.props.data.allowedUOMsInState?.filter(
      (allowedUom) => !allowedUOMsData.find((i) => i.id === allowedUom.id)
    );

    if (allowedUOMsToRemove?.length > 0) {
      const disableAllowedUOMsResponse = await ReferenceFileAPI.DisableMethodUOMsInState(
        this.props.data.methodUniqueIdentifier,
        this.props.usaStateData.stateGuid,
        allowedUOMsToRemove.map((i) => i.id)
      );
      if (disableAllowedUOMsResponse.successful) {
        this.props.updateMethodFromResponse(disableAllowedUOMsResponse.data);
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to disable method units of measure",
          apiName: "DisableMethodUOMsInState",
          responseUnsuccessful: disableAllowedUOMsResponse?.unsuccessful,
          responseMessage: disableAllowedUOMsResponse?.message,
        });
      }
    }
  }

  clearData() {
    this.setState({ allowedUsesData: [], allowedUOMsData: [], isInitialConfiguration: true });
  }

  handleUsesCheckClick(item) {
    let allowedUsesData = this.state.isInitialConfiguration
      ? [...this.props.data.allowedUsesInState]
      : [...this.state.allowedUsesData];
    let allowedUOMsData = this.state.isInitialConfiguration
      ? [...this.props.data.allowedUOMsInState]
      : [...this.state.allowedUOMsData];

    if (document.getElementById(item.name + item.id).checked) {
      allowedUsesData.push(item);
    } else {
      allowedUsesData = allowedUsesData.filter((i) => i.id !== item.id);
    }

    this.setState({ allowedUsesData, allowedUOMsData, isInitialConfiguration: false });
  }

  handleUOMsCheckClick(item) {
    let allowedUsesData = this.state.isInitialConfiguration
      ? [...this.props.data.allowedUsesInState]
      : [...this.state.allowedUsesData];
    let allowedUOMsData = this.state.isInitialConfiguration
      ? [...this.props.data.allowedUOMsInState]
      : [...this.state.allowedUOMsData];

    if (document.getElementById(item.name + item.id).checked) {
      allowedUOMsData.push(item);
    } else {
      allowedUOMsData = allowedUOMsData.filter((i) => i.id !== item.id);
    }

    this.setState({ allowedUsesData, allowedUOMsData, isInitialConfiguration: false });
  }

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.getMonth() + "/" + dateTime.getDay() + "/" + dateTime.getFullYear();
  }

  render() {
    if (!this.props.data) {
      return null;
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Configure Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.configureModalBody}>
            <Row>
              <Col className={styles.configureModalItem}>
                <label htmlFor="ConfigurMethodModalCategory" className={globalStyles.modalFormLabel}>
                  Category
                </label>
                <p>{this.props.data.type}</p>
              </Col>
            </Row>
            <Row>
              <Col className={styles.configureModalItem}>
                <label htmlFor="ConfigureMethodModalName" className={globalStyles.modalFormLabel}>
                  Name
                </label>
                <p>{this.props.data.methodName}</p>
              </Col>
            </Row>
          </div>
          <div>
            <Form.Row>
              <Col xs={6}>
                <Form.Group controlid="allowedUsesList">
                  <h4 className={globalStyles.modalFormLabel}>Allowed Uses</h4>
                  <div className={styles.editPageMethodVariableSelectColumn}>
                    <Dropdown data-testid="UsesDropdown">
                      <Dropdown.Toggle className={styles.dropdownToggle}>Change Allowed Uses</Dropdown.Toggle>
                      <Dropdown.Menu className={styles.dropdownToggle}>
                        <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                          {this.props.data?.globalAllowedUses?.map((item) => (
                            <div key={`formChecklistContainerUses-${item.name}`} className={styles.formCheckContainer}>
                              <input
                                type="checkbox"
                                className="form-check-input ml-0"
                                id={`${item.name}${item.id}`}
                                key={`inline-${item.name}${item.id}`}
                                data-toggle="button"
                                onClick={() => this.handleUsesCheckClick(item)}
                                checked={
                                  this.state.isInitialConfiguration
                                    ? this.props.data.allowedUsesInState?.find((i) => i.id === item.id)
                                    : this.state.allowedUsesData.find((i) => i.id === item.id)
                                }
                              />
                              <p className="form-check-label ml-4" htmlFor="itemChecked">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlid="allowedUOMList">
                  <h4 className={globalStyles.modalFormLabel}>Allowed UOMs</h4>
                  <div className={styles.createPageMethodVariableSelectColumn}>
                    <Dropdown data-testid="UOMsDropdown">
                      <Dropdown.Toggle className={styles.dropdownToggle}>Change Allowed UOMs</Dropdown.Toggle>
                      <Dropdown.Menu className={styles.dropdownToggle}>
                        <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="button">
                          {this.props.data?.globalAllowedUOMs?.map((item) => (
                            <div key={`formChecklistContainerUOMs-${item.name}`} className={styles.formCheckContainer}>
                              <input
                                type="checkbox"
                                className="form-check-input ml-0"
                                id={`${item.name}${item.id}`}
                                key={`inline-${item.name}${item.id}`}
                                data-toggle="button"
                                onClick={() => this.handleUOMsCheckClick(item)}
                                checked={
                                  this.state.isInitialConfiguration
                                    ? this.props.data.allowedUOMsInState?.find((i) => i.id === item.id)
                                    : this.state.allowedUOMsData.find((i) => i.id === item.id)
                                }
                              />
                              <p className="form-check-label ml-4" htmlFor="itemChecked">
                                {item.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form>
              <Row>
                <Col className={styles.configureModalItem}>
                  <label htmlFor="ConfigureMethodDate" className={globalStyles.modalFormLabel}>
                    Submitted Date
                  </label>
                  <p>{this.formatDate(this.props.data.lastModifiedDateTimeUTC)}</p>
                </Col>
              </Row>

              <Form.Group controlid="submittedDateField">
                <h4 className={globalStyles.modalFormLabel}>Set New Date</h4>
                <Form.Control type="date" placeholder="Date" title="Set New Date" />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group controlid="allowedRadio">
                    <h4 className={globalStyles.modalFormLabel}>Allowed</h4>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      id="yes"
                      checked={this.props.allowedRadio}
                      onClick={this.props.handleAllowedRadioClick}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      id="no"
                      checked={!this.props.allowedRadio}
                      onClick={this.props.handleAllowedRadioClick}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlid="requiredGeolocationRadio">
                    <h4 className={globalStyles.modalFormLabel}>Requires Geolocation?</h4>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      id="yes-geo"
                      checked={this.props.requiresGeolocationRadio}
                      onClick={this.props.handleRequiresGeolocationRadioClick}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      id="no-geo"
                      checked={!this.props.requiresGeolocationRadio}
                      onClick={this.props.handleRequiresGeolocationRadioClick}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 6, offset: 6 }}>
                  <Form.Group controlid="requiredLabelRadio">
                    <h4 className={globalStyles.modalFormLabel}>Requires Label?</h4>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      id="yes-label"
                      checked={this.props.requiresLabelRadio}
                      onClick={this.props.handleRequiresLabelRadioClick}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      id="no-label"
                      checked={!this.props.requiresLabelRadio}
                      onClick={this.props.handleRequiresLabelRadioClick}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className={globalStyles.modalFooter}>
          <Button className={globalStyles.modalCancelButton} onClick={this.props.onHide}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button type="submit" className={globalStyles.modalSubmitButton} onClick={() => this.configureMethod()}>
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditMethodModal;
