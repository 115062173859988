import React from "react";
import { Row, Col, Button, Card, Image, Spinner } from "react-bootstrap";
import styles from "./Properties.module.scss";
import BootstrapTable from "react-bootstrap-table-next";
import WorkTaskAPI from "../../../api/WorkTask/WorkTaskAPI";
import UtilityFunctions from "../../common/UtilityFunctions";
import { withRouter } from "react-router-dom";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

class RecentPropertiesWidget extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      isPropertyDataLoading: false,
      recentlyVisitedPropertiesForUser: [],
      recentlyVisitedPropertiesColumns: [
        { dataField: "propertyVisitedUniqueReference", hidden: true },
        {
          dataField: "propertyName",
          text: "Property Name",
          sort: true,
          formatter: (cell, row) => {
            return (
              <Button
                variant="link"
                className="pl-0"
                title={`${cell}-${row.propertyVisitedUniqueReference}`}
                onClick={async () => {
                  let propertyObj = await this.getFullPropertyDataFromAPI(row.propertyVisitedUniqueReference);
                  //row.propertyUniqueIdentifier = row.propertyVisitedUniqueReference;
                  this.props.history.push({
                    pathname: "/property/details",
                    state: {
                      states: this.props.usaStates,
                      countries: this.props.countries,
                      entityData: propertyObj,
                    },
                  });
                }}
              >
                <span>{cell}</span>
              </Button>
            );
          },
          align: "left",
          headerAlign: "left",
          headerStyle: () => {
            return { width: "20%" };
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
        },
        {
          dataField: "lastSiteVisitDate",
          text: "Last Site Visit",
          sort: true,
          formatter: (cell, row) => {
            return UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell).toLocaleDateString("en-US");
          },
          sortFunc: (a, b, order) => {
            let dateA = new Date(a);
            let dateB = new Date(b);
            if (order === "asc") {
              return dateB - dateA;
            }
            return dateA - dateB; // desc
          },
          align: "left",
          headerAlign: "left",
          style: () => {
            return { verticalAlign: "middle" };
          },
        },
        {
          dataField: "linkToSiteVisit",
          text: "",
          formatter: (cell, row) => {
            return (
              <Button
                variant="link"
                className="pl-0"
                title={`link-${row.propertyVisitedUniqueReference}`}
                onClick={async () => {
                  let propertyObj = await this.getFullPropertyDataFromAPI(row.propertyVisitedUniqueReference);
                  if (propertyObj) {
                    this.props.history.push({
                      pathname: "/sitevisit",
                      state: {
                        propertyData: propertyObj,
                        states: this.props.usaStates,
                        countries: this.props.countries,
                        parcels: propertyObj.parcels,
                        agreementInfo: null,
                      },
                    });
                  }
                }}
              >
                <span>Site Visit</span>
              </Button>
            );
          },
          align: "right",
          headerAlign: "eight",
          style: () => {
            return { verticalAlign: "middle" };
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const userProfile = JSON.parse(localStorage.getItem("currentUserRole"));
    if (userProfile) {
      this.setState({ isPropertyDataLoading: true });
      const getRecentlyVisitedPropertiesForUserResponse =
        await WorkTaskAPI.GetRecentSiteVisitPropertiesForFieldSpecialist(
          userProfile?.wsUserProfileUniqueIdentifier,
          this.controller.signal
        );
      if (getRecentlyVisitedPropertiesForUserResponse?.successful) {
        this.setState({
          recentlyVisitedPropertiesForUser: getRecentlyVisitedPropertiesForUserResponse.data,
          isPropertyDataLoading: false,
        });
      } else {
        if (this.controller.signal.aborted) {
          return;
        }
        this.setState({ isPropertyDataLoading: false });
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get RecentSiteVisitPropertiesForFieldSpecialist",
          apiName: "GetRecentSiteVisitPropertiesForFieldSpecialist",
          responseUnsuccessful: getRecentlyVisitedPropertiesForUserResponse?.unsuccessful,
          responseMessage: getRecentlyVisitedPropertiesForUserResponse?.message,
        });
      }
    }
  }

  async getFullPropertyDataFromAPI(propertyGuid) {
    const getRecentlyVisitedPropertiesForUserResponse = await AgreementEntitiesAPI.GetProperty(
      propertyGuid,
      this.controller.signal
    );
    if (getRecentlyVisitedPropertiesForUserResponse?.successful) {
      return getRecentlyVisitedPropertiesForUserResponse.data;
    } else {
      ErrorHandler.showError(
        "Unable to retrieve property for navigation. " +
          (getRecentlyVisitedPropertiesForUserResponse?.unsuccessful
            ? getRecentlyVisitedPropertiesForUserResponse?.message
            : "Please try again later.")
      );
      return;
    }
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("RecentPropertiesWidget.jsx got unmounted");
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card className={this.props.isExpanded ? styles.expandedCards : styles.nonExpandedCard}>
          <Card.Body>
            <Row>
              <Col className={`text-left pl-0 ${this.props.isExpanded ? "pr-0" : ""}`}>
                <span className={styles.cardTitleText} style={{ color: "#002d72" }}>
                  My Recent Properties
                </span>
              </Col>
              <Col className="text-right pr-2">
                <Image
                  name="recentProperties"
                  aria-label="Expand My Recent Properties Card"
                  style={{ cursor: "pointer" }}
                  src={this.props.isExpanded ? this.props.collapseIcon : this.props.expandIcon}
                  onClick={(e) => this.props.onExpand(e)}
                />
              </Col>
            </Row>
            {this.state.isPropertyDataLoading ? (
              <Spinner animation="border" className="mt-4" role="status">
                <span className="visually-hidden" aria-label="Loading..."></span>
              </Spinner>
            ) : (
              <Row className="mt-3" noGutters>
                <Col className="text-left">
                  {this.state.recentlyVisitedPropertiesForUser.length > 0 ? (
                    <ToolkitProvider
                      keyField="propertyVisitedUniqueReference"
                      data={this.state.recentlyVisitedPropertiesForUser}
                      columns={this.state.recentlyVisitedPropertiesColumns}
                      bootstrap4={true}
                    >
                      {(props) => (
                        <BootstrapTable
                          keyField="propertyVisitedUniqueReference"
                          data={this.state.recentlyVisitedPropertiesForUser}
                          columns={this.state.recentlyVisitedPropertiesColumns}
                          bootstrap4={true}
                          hover={true}
                          bordered={false}
                          defaultSorted={[{ dataField: "lastSiteVisitDate", order: "asc" }]}
                          defaultSortDirection="asc"
                          {...props.baseProps}
                        />
                      )}
                    </ToolkitProvider>
                  ) : (
                    <Row>
                      <Col className="mt-4 text-center">{`There are no properties.`}</Col>
                    </Row>
                  )}
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

export default withRouter(RecentPropertiesWidget);
