import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./AdminCenter.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import UserTable from "./UserTable";
import RolesTable from "./RolesTable";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import DistrictsTable from "./DistrictsTable";

class AdminCenter extends React.Component {
  constructor(props) {
    super(props);
    let defaultTab = "usersTab";
    if (this.props.history.location.state) {
      defaultTab = this.props.history.location.state.tab;
    } else if (!this.props.permissions.canIViewGlobalUserRecord() && this.props.permissions.canIViewGlobalRole()) {
      defaultTab = "rolesTab";
    }

    this.state = {
      currentTab: defaultTab,
      availableRoles: [],
    };
  }

  async componentDidMount() {
    if (this.state.availableRoles.length === 0) {
      var getRoles = await RoleAuthorizationAPI.GetAllRoles();
      if (getRoles.successful) {
        this.setState({ availableRoles: getRoles.data.results });
      }
    }
  }

  handleTabClick(tab) {
    this.setState({ currentTab: tab });
  }

  updateStateData = (fieldName, value) => {
    let data = this.state;
    data[fieldName] = value;
    this.setState({ data });
  };

  render() {
    return (
      <div className={styles.adminCenterContainer}>
        <Row className={styles.adminCenterHeaderRow}>
          <Col>
            <h1 className={globalStyles.pageTitle}>Admin Center</h1>
          </Col>
        </Row>
        <Tab.Container id="adminCenterTabs" defaultActiveKey={this.state.currentTab}>
          <Row>
            <Col>
              <Nav variant="pills">
                <Nav.Item className={styles.navItemLeft}>
                  <Nav.Link
                    eventKey="dashboardTab"
                    onSelect={(e) => this.handleTabClick(e)}
                    className={styles.navLinkLeft}
                  >
                    Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={styles.navItemMiddle}>
                  <Nav.Link
                    eventKey="usersTab"
                    onSelect={(e) => this.handleTabClick(e)}
                    className={styles.navLinkMiddle}
                  >
                    Users
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={styles.navItemMiddle}>
                  <Nav.Link
                    eventKey="rolesTab"
                    onSelect={(e) => this.handleTabClick(e)}
                    className={styles.navLinkMiddle}
                  >
                    Roles
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={styles.navItemMiddle}>
                  <Nav.Link
                    eventKey="districtsTab"
                    onSelect={(e) => this.handleTabClick(e)}
                    className={styles.navLinkMiddle}
                  >
                    Districts
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={styles.navItemMiddle}>
                  <Nav.Link
                    eventKey="settingsTab"
                    onSelect={(e) => this.handleTabClick(e)}
                    className={styles.navLinkMiddle}
                  >
                    Settings
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="dashboardTab"></Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="usersTab">
                  <UserTable availableRoles={this.state.availableRoles} permissions={this.props.permissions} />
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="rolesTab">
                  <RolesTable passDataUp={this.updateStateData} permissions={this.props.permissions} />
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="districtsTab">
                  <DistrictsTable
                    passDataUp={this.updateStateData}
                    permissions={this.props.permissions}
                    currentTab={this.state.currentTab}
                  />
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="settingsTab"></Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="dashboardTab"></Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

export default withRouter(AdminCenter);
