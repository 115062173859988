import React, { Component } from "react";
import { Form, InputGroup } from "react-bootstrap";
import searchIcon from "../../assets/search.svg";

const SearchIcon = () => {
  return <img src={searchIcon} aria-hidden="true" alt="" />;
};

class CustomTextFilter extends Component {
  constructor(props) {
    super(props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.state = {
      filteredValue: "",
    };
  }

  handleFilterChange = ({ currentTarget: input }) => {
    if (this.props.dataType && this.props.dataType === "date") {
      let dateValue = input.value ? new Date(input.value)?.toISOString()?.substring(0, 11) : "";
      this.props.onFilter(dateValue);
    } else {
      this.props.onFilter(input.value);
    }
    if (this.props.extraRoleTableEvents) {
      if (input.value) {
        this.props.extraRoleTableEvents.setState({ serviceGroupBannerIndexes: {} });
        this.props.extraRoleTableEvents.unmarkBannerRows();
      } else {
        this.props.extraRoleTableEvents.setState({ bannerRowStyleUpToDate: false });
        this.props.extraRoleTableEvents.populateServiceGroupBannerIndexes(
          this.props.extraRoleTableEvents.state.rolePermissions
        );
        this.props.extraRoleTableEvents.markBannerRow();
      }
    }

    if (this.props.setColumnFilterValue) {
      this.props.setColumnFilterValue.setState({
        [`${this.props.columnName}FilterValue`]: input.value,
      });
      let parentComponent = this.props.setColumnFilterValue;
      setTimeout(async function () {
        await parentComponent.getDataFromAPI();
      }, 2500);
      parentComponent.setState({ waitingForAPIData: false });
    }

    this.setState({ filteredValue: input.value });
  };

  render() {
    let ariaLabelledBy = {};
    if (this.props.labelledBy) {
      ariaLabelledBy = { "aria-labelledby": this.props.labelledBy };
    }
    return (
      <div className="mb-2 mt-3" onClick={(e) => e.stopPropagation()}>
        <InputGroup>
          <Form.Control
            type={this.props.dataType && this.props.dataType === "date" ? "date" : "text"}
            value={this.state.filteredValue}
            placeholder={this.props.placeholder ? this.props.placeholder : "Search"}
            onChange={this.handleFilterChange}
            aria-label={this.props.title}
            {...ariaLabelledBy}
          ></Form.Control>
          {!this.props.dataType && (
            <div className="searchIcon">
              <SearchIcon />
            </div>
          )}
        </InputGroup>
      </div>
    );
  }
}

export default CustomTextFilter;
