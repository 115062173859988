import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import globalStyles from "../../OARS.module.scss";

const ContactOrgAddress = ({ addresses, countries, states, onAddressChange, onExtraAddress, dataTestid = "" }) => {
  if (addresses.length === 1 && addresses[0].mailingCountryUniqueId === "") {
  }

  return (
    <div className="mx-2">
      {addresses.map((address, index) => {
        return (
          <div key={index} data-testid={dataTestid}>
            <Row>
              <Col>
                <Form.Group controlId={"mailingStreet" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">Street Address or PO Box</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    value={address.mailingStreet}
                    onChange={onAddressChange}
                    title={`Additional Street Address or PO Box ${index + 1}`}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row xs={1} sm={2} md={4}>
              <Col>
                <Form.Group controlId={"mailingCity" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    value={address.mailingCity}
                    onChange={onAddressChange}
                    title={`Additional City ${index + 1}`}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"mailingStateUniqueId" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">
                    State <span className={globalStyles.asterisk508}>{" *"}</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    type="text"
                    onChange={onAddressChange}
                    value={address.mailingStateUniqueId}
                    title={`Additional State ${index + 1}`}
                  >
                    <option>Select State</option>
                    {states.map((state) => (
                      <option value={state.id} key={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"mailingCountryUniqueId" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">
                    Country <span className={globalStyles.asterisk508}>{" *"}</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    type="text"
                    onChange={onAddressChange}
                    value={address.mailingCountryUniqueId}
                    title={`Additional Country ${index + 1}`}
                  >
                    <option>Select Country</option>
                    {countries.map((country) => (
                      <option value={country.id} key={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={"mailingZip" + "-" + index} className="text-left">
                  <Form.Label className="formLabel">ZIP Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter ZIP Code"
                    value={address.mailingZip}
                    onChange={onAddressChange}
                    title={`Additional Zip Code ${index + 1}`}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Button
                variant="link"
                onClick={onExtraAddress}
                value={addresses.length > index + 1 ? index + 1 : "add"}
                style={addresses.length > index + 1 ? { color: "red" } : {}}
                title={addresses.length > index + 1 ? `Remove Address ${index + 2}` : `Add Address ${index + 2}`}
              >
                {addresses.length > index + 1 ? "- Remove Address" : "+ Add Address"}
              </Button>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default ContactOrgAddress;
