import React from "react";
import styles from "./agreements.module.scss";
import { Row, Col, Form } from "react-bootstrap";

const CheckedPropertySelection = ({ property, onPropertyChange, separator, isSelectionDisable }) => {
  const propertyId = property.propertyUniqueIdentifier
    ? property.propertyUniqueIdentifier
    : property.propertyUniqueReference;
  return (
    <Row className="mt-3 mx-0">
      <Col>
        <Form.Group controlId={"property_" + propertyId}>
          <Form.Check
            type="checkbox"
            label={property.propertyName}
            onChange={onPropertyChange}
            checked
            disabled={isSelectionDisable}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default CheckedPropertySelection;
