import React from "react";
import CustomForm from "../common/form";
import { withRouter } from "react-router-dom";
import styles from "./Reports.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Row, Col, Button, Card, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { components } from "react-select";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter, customFilter } from "react-bootstrap-table2-filter";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import WorkTaskAPI from "../../api/WorkTask/WorkTaskAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import UtilityFunctions from "../common/UtilityFunctions";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import CustomTextFilter from "../common/customTextFilter";
import ExportCSVButton from "../ReferenceFiles/ExportCSVButton";

const mLocalizer = momentLocalizer(moment);

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className={styles.noOptionsMessage}>
        <i className="fa fa-exclamation-circle " style={{ color: "gray", padding: "4px" }} aria-hidden="true"></i>
        Sorry, we couldn't find a match for “{props.selectProps.inputValue}”. Please try another search
      </span>
    </components.NoOptionsMessage>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

const ReportTypeValues = {
  ProjectAvailability: 0,
  EmployeeItinerary: 1,
  SupervisorItinerary: 2,
};

export class ReportPage extends CustomForm {
  constructor(props) {
    let reportTypesList = [];
    if (props.permissions.canIViewProjectsAnyStates()) {
      reportTypesList.push({ value: 0, label: "Project Availability Report" });
    }
    reportTypesList.push({ value: 1, label: "Employee Itinerary" });
    if (
      props.permissions.canIViewTaWorkTasks() ||
      props.permissions.canIViewAerialWorkTask() ||
      (props.permissions.canIViewPVSForAssignedStates() && props.permissions.canIViewPVSForSupervisor())
    ) {
      reportTypesList.push({ value: 2, label: "Supervisor Itinerary View" });
    }

    super(props);
    this.state = {
      data: {
        selectedStartDate: null,
        selectedEndDate: null,
        selectedState: "",
        selectedDistrict: "",
        selectedEmployee: "",
        generatedReportData: [],
      },
      columns: this.getProjectAvailabilityReportColumns(),
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
      currentUserRole: JSON.parse(localStorage.getItem("currentUserRole")),
      districts: [],
      usaStates: [],
      districtEmployees: [],
      activeReportType: "",
      reportTypes: reportTypesList,
      alreadyQueriedDateRange: [],
      currentCalendarView: "month",
      shownReport: "",
      isReportLoading: false,
      useSupervisorStyles: false,
    };
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      textAlign: "left",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
      minHeight: "35px",
    }),
    container: (base) => ({
      ...base,
      minHeight: "35px",
    }),
  };

  async componentDidMount() {
    let { data, usaStates } = this.state;
    if (this.state.currentUserRole) {
      data.selectedEmployee = {
        value: this.state.currentUserRole.wsUserProfileUniqueIdentifier,
        label: this.state.currentUserRole.userName,
        profileName: this.state.currentUserRole.profileName,
      };
    }
    if (this.state.currentUsaState) {
      data.selectedState = { value: this.state.currentUsaState.stateGuid, label: this.state.currentUsaState.stateName };
    }
    let statesList = await ReferenceFileAPI.GetUSStates();
    usaStates = statesList
      ? statesList.map((state) => ({ value: state.stateUniqueIdentifier, label: state.state_name }))
      : [];
    this.setState({
      data,
      activeReportType: this.state.reportTypes[0],
      usaStates,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.activeReportType.value !== this.state.activeReportType.value) {
      if (this.state.activeReportType.value === ReportTypeValues.SupervisorItinerary) {
        this.setState({ districts: await this.getDistrictsForCurrentUser() });
      }
    }
  }

  async getDistrictsForCurrentUser() {
    let districtData = [];
    if (this.state.currentUserRole) {
      const districtResponse = await RoleAuthorizationAPI.GetDistrictsForProfiles([
        this.state.currentUserRole.wsUserProfileUniqueIdentifier,
      ]);
      if (districtResponse?.successful) {
        districtData = districtResponse.data[0]?.districtsProfileBelongsTo?.map((district) => {
          district.value = district.districtUniqueIdentifier;
          district.label = district.name;
          return district;
        });
      } else if (districtResponse?.unsuccessful) {
        ErrorHandler.showErrorWithMessage("Unable to retrieve districts for current user. " + districtResponse.message);
      } else {
        ErrorHandler.showErrorWithMessage("Unable to retrieve districts for current user. Please try again later.");
      }
    }
    return districtData;
  }

  handleUserSearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
    const contactsData = await RoleAuthorizationAPI.SearchUsersFreeText(searchObj);
    let outputContacts = [];
    if (contactsData?.data?.results?.length > 0) {
      let results = contactsData.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
            };
            outputContacts.push(userObject);
          }
        }
      }
    }
    return outputContacts;
  };

  getProjectAvailabilityReportColumns() {
    return [
      {
        dataField: "projectUniqueIdentifier",
        hidden: true,
        csvText: "Project Unique Id",
      },
      {
        dataField: "projectName",
        text: "Name",
        filter: textFilter(),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "projectNameHeader" },
        attrs: { headers: "projectNameHeader", tabIndex: "0" },
      },
      {
        dataField: "createdBy",
        text: "Created By",
        filter: textFilter(),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "projectCreatedByHeader" },
        attrs: { headers: "projectCreatedByHeader", tabIndex: "0" },
      },
      {
        dataField: "createdDateTimeUTC",
        text: "Created Date",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return cell
            ? UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell).toLocaleDateString("en-US")
            : "N/A";
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Date Created" />
        ),
        sort: true,
        sortFunc: (a, b, order) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          if (order === "asc") {
            return dateB - dateA;
          }
          return dateA - dateB; // desc
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "projectCreatedDateHeader" },
        attrs: { headers: "projectCreatedDateHeader", tabIndex: "0" },
      },
      {
        dataField: "startDate",
        text: "Start Date",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return cell
            ? UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell).toLocaleDateString("en-US")
            : "N/A";
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="Start Date" />
        ),
        sort: true,
        sortFunc: (a, b, order) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          if (order === "asc") {
            return dateB - dateA;
          }
          return dateA - dateB; // desc
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "projectStartDateHeader" },
        attrs: { headers: "projectStartDateHeader", tabIndex: "0" },
      },
      {
        dataField: "endDate",
        text: "End Date",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return cell
            ? UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell).toLocaleDateString("en-US")
            : "N/A";
        },
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} dataType="date" title="End Date" />
        ),
        sort: true,
        sortFunc: (a, b, order) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          if (order === "asc") {
            return dateB - dateA;
          }
          return dateA - dateB; // desc
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "projectEndDateHeader" },
        attrs: { headers: "projectEndDateHeader", tabIndex: "0" },
      },
      {
        dataField: "userProfiles",
        text: "# of Employees",
        formatter: (cell) => {
          return cell?.length ?? "N/A";
        },
        csvFormatter: (cell) => {
          return cell?.length ?? "N/A";
        },
        filter: textFilter({
          onFilter: (filterValue, data) => {
            if (filterValue) {
              return data?.filter((entry) => entry.userProfiles?.length === parseInt(filterValue));
            }
          },
          "aria-label": "Enter Number of Employees",
        }),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "projectEmployeesHeader" },
        attrs: { headers: "projectEmployeesHeader", tabIndex: "0" },
      },
      {
        dataField: "parentProjects",
        text: "Parent Projects",
        formatter: (cell) => {
          return cell?.length > 0 ? cell.map((project) => project.projectName).join(", ") : "None";
        },
        csvFormatter: (cell) => {
          return cell?.length > 0 ? cell.map((project) => project.projectName).join(", ") : "None";
        },
        filter: textFilter({
          onFilter: (filterValue, data) => {
            if (filterValue) {
              return data?.filter((entry) =>
                entry.parentProjects?.some((project) => project.projectName?.includes(filterValue?.toLowerCase()))
              );
            }
          },
          "aria-label": "Enter Parent Project",
        }),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "projectParentHeader" },
        attrs: { headers: "projectParentHeader", tabIndex: "0" },
      },
      {
        dataField: "childProjects",
        text: "Child Projects",
        formatter: (cell) => {
          return cell?.length > 0 ? cell.map((project) => project.projectName).join(", ") : "None";
        },
        csvFormatter: (cell) => {
          return cell?.length > 0 ? cell.map((project) => project.projectName).join(", ") : "None";
        },
        filter: textFilter({
          onFilter: (filterValue, data) => {
            if (filterValue) {
              return data?.filter((entry) =>
                entry.childProjects?.some((project) => project.projectName?.includes(filterValue?.toLowerCase()))
              );
            }
          },
          "aria-label": "Enter Child Project",
        }),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "projectChildHeader" },
        attrs: { headers: "projectChildHeader", tabIndex: "0" },
      },
    ];
  }

  handleSelectChanges = async (e, fieldName) => {
    const { data } = this.state;
    data[fieldName] = e ?? "";
    if (
      fieldName === "selectedDistrict" &&
      this.state.activeReportType.value === ReportTypeValues.SupervisorItinerary
    ) {
      if (e) {
        await this.getDistrictAndSetDistrictEmployees(e?.value);
      } else {
        this.setState({ districtEmployees: [] });
      }
      data.selectedEmployee = [];
    }
    this.setState({ data });
  };

  async getDistrictAndSetDistrictEmployees(districtId) {
    if (districtId) {
      const districtResponse = await RoleAuthorizationAPI.GetDistrict(districtId);
      if (districtResponse?.successful) {
        let employees = districtResponse.data?.employees?.map((e) => {
          e.value = e.wsUserProfileUniqueReference;
          e.label = e.firstName + " " + e.lastName;
          return e;
        });
        this.setState({ districtEmployees: employees });
      } else if (districtResponse?.unsuccessful) {
        ErrorHandler.showErrorWithMessage("Unable to retrieve district. " + districtResponse.message);
      } else {
        ErrorHandler.showErrorWithMessage("Unable to retrieve district. Please try again later.");
      }
    }
  }

  renderReportContent = () => {
    if (this.state.shownReport === "Employee Itinerary") {
      return this.renderEmployeeItineraryReport();
    }
    if (this.state.shownReport === "Project Availability") {
      return this.renderProjectReport();
    }
  };

  renderReportOptions = () => {
    if (
      this.state.activeReportType.value === ReportTypeValues.SupervisorItinerary ||
      this.state.activeReportType.value === ReportTypeValues.EmployeeItinerary
    ) {
      return (
        <React.Fragment>
          <Col md={3} align="left" hidden={this.state.activeReportType.value === ReportTypeValues.EmployeeItinerary}>
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>District</Form.Label>
              <Select
                value={this.state.data.selectedDistrict}
                placeholder="Select"
                aria-label="District Select"
                isClearable
                styles={this.customSelectStyles}
                options={this.state.districts}
                onChange={(e) => this.handleSelectChanges(e, "selectedDistrict")}
              />
            </Form.Group>
          </Col>
          <Col md={3} align="left">
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Employee</Form.Label>
              <Select
                value={this.state.data.selectedEmployee}
                placeholder="Select"
                aria-label="Employee User Search"
                openMenuOnClick={true}
                closeMenuOnSelect={false}
                isMulti={this.state.activeReportType.value === ReportTypeValues.SupervisorItinerary}
                isDisabled={this.state.activeReportType.value === ReportTypeValues.EmployeeItinerary}
                styles={this.customSelectStyles}
                components={{ NoOptionsMessage, Option }}
                options={this.state.districtEmployees}
                onChange={(e) => this.handleSelectChanges(e, "selectedEmployee")}
              />
            </Form.Group>
          </Col>
        </React.Fragment>
      );
    } else if (this.state.activeReportType.value === ReportTypeValues.ProjectAvailability) {
      return (
        <React.Fragment>
          <Col md={2} align="left">
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>Start Date</Form.Label>
              <Form.Control
                type="date"
                title="Start Date"
                className={styles.dateInputs}
                value={this.state.data.selectedStartDate}
                onChange={(e) => this.handleSelectChanges(e.target.value, "selectedStartDate")}
              />
            </Form.Group>
          </Col>
          <Col md={2} align="left">
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>End Date</Form.Label>
              <Form.Control
                type="date"
                title="End Date"
                className={styles.dateInputs}
                value={this.state.data.selectedEndDate}
                onChange={(e) => this.handleSelectChanges(e.target.value, "selectedEndDate")}
              />
            </Form.Group>
          </Col>
          <Col md={2} align="left">
            <Form.Group>
              <Form.Label className={globalStyles.formLabel}>State</Form.Label>
              <Select
                value={this.state.data.selectedState}
                placeholder="Select"
                aria-label="State Select"
                styles={this.customSelectStyles}
                options={this.state.usaStates}
                onChange={(e) => this.handleSelectChanges(e, "selectedState")}
              />
            </Form.Group>
          </Col>
        </React.Fragment>
      );
    }
  };

  handleGenerateReportClick = () => {
    if (
      this.state.activeReportType.value === ReportTypeValues.SupervisorItinerary ||
      this.state.activeReportType.value === ReportTypeValues.EmployeeItinerary
    ) {
      let { data, alreadyQueriedDateRange } = this.state;
      data.generatedReportData = [];
      alreadyQueriedDateRange = [];
      let currentDate = new Date();
      this.setState(
        {
          data,
          shownReport: "",
          alreadyQueriedDateRange,
          useSupervisorStyles:
            this.state.activeReportType.value === ReportTypeValues.SupervisorItinerary &&
            data.selectedEmployee.length > 1,
        },
        () => {
          this.generateItineraryReport(currentDate.getMonth() + 1, currentDate.getFullYear());
        }
      );
    } else if (this.state.activeReportType.value === ReportTypeValues.ProjectAvailability) {
      this.generateProjectAvailabilityReport();
    }
  };

  async generateProjectAvailabilityReport() {
    const { data } = this.state;
    this.setState({ isReportLoading: true, shownReport: "" });
    const getProjectsReportResponse = await AgreementEntitiesAPI.GetProjectsReport(
      data.selectedState?.value,
      new Date(data.selectedStartDate).toISOString(),
      new Date(data.selectedEndDate).toISOString()
    );
    if (getProjectsReportResponse?.successful) {
      this.setState({
        generatedReportData: getProjectsReportResponse.data,
        shownReport: "Project Availability",
        columns: this.getProjectAvailabilityReportColumns(),
      });
    } else if (getProjectsReportResponse?.unsuccessful) {
      ErrorHandler.showErrorWithDetails(`Unable to generate project report. `, getProjectsReportResponse?.message);
    } else {
      ErrorHandler.showErrorWithMessage(`Unable to generate project report. Please try again later.`);
    }
    this.setState({ isReportLoading: false });
  }

  async generateItineraryReport(month, year) {
    const { data } = this.state;
    if (
      this.state.activeReportType.value === ReportTypeValues.EmployeeItinerary ||
      (this.state.activeReportType.value === ReportTypeValues.SupervisorItinerary &&
        data.selectedEmployee?.length === 1)
    ) {
      this.generateEmployeeItineraryReport(month, year);
    } else if (
      this.state.activeReportType.value === ReportTypeValues.SupervisorItinerary &&
      data.selectedEmployee?.length > 1
    ) {
      this.generateSupervisorItineraryReport(month, year);
    }
  }

  async generateSupervisorItineraryReport(monthNumber, year) {
    const { data, alreadyQueriedDateRange } = this.state;
    this.setState({ isReportLoading: true });
    const supervisorItineraryReportResponse = await WorkTaskAPI.GetEmployeeItinerariesForSupervisor(
      data.selectedEmployee.map((employee) => employee.value),
      monthNumber,
      year
    );
    if (supervisorItineraryReportResponse?.successful) {
      const employeeReportData = supervisorItineraryReportResponse.data?.itineraryWorkTasks;
      let mappedReportData = [];
      employeeReportData.forEach((item) => {
        let event;
        let indexToChange;
        if (
          mappedReportData.some((x, index) => {
            let condition =
              x.title.includes(item.employeeWhoPerformedTask) &&
              new Date(x.fullObjects[0].dateWorkTaskPerformed).getTime() ===
                new Date(item.dateWorkTaskPerformed).getTime();
            if (condition) {
              indexToChange = index;
            }
            return condition;
          })
        ) {
          mappedReportData[indexToChange].numEventsPerDay += 1;
          mappedReportData[indexToChange].title =
            item.employeeWhoPerformedTask + " (" + mappedReportData[indexToChange].numEventsPerDay + ")";
          mappedReportData[indexToChange].fullObjects = [...mappedReportData[indexToChange].fullObjects, item];
        } else {
          event = {
            id: item.workTaskUniqueIdentifier,
            title: item.employeeWhoPerformedTask + " (1)",
            start: UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(item.dateWorkTaskPerformed),
            end: UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(item.dateWorkTaskPerformed),
            numEventsPerDay: 1,
            fullObjects: [item],
          };
        }
        if (event) {
          mappedReportData.push(event);
        }
      });
      data.generatedReportData = [...data.generatedReportData, ...mappedReportData];
      alreadyQueriedDateRange.push(monthNumber + "/" + year);
      this.setState({ data, shownReport: "Employee Itinerary", alreadyQueriedDateRange });
    } else if (supervisorItineraryReportResponse?.unsuccessful) {
      ErrorHandler.showErrorWithDetails(
        `Unable to generate report for ${monthNumber}/${year}.`,
        supervisorItineraryReportResponse?.message
      );
    } else {
      ErrorHandler.showErrorWithMessage(
        `Unable to generate report for ${monthNumber}/${year}. Please try again later.`
      );
    }
    this.setState({ isReportLoading: false });
  }

  async generateEmployeeItineraryReport(monthNumber, year) {
    const { data, alreadyQueriedDateRange } = this.state;
    this.setState({ isReportLoading: true });
    const employeeItineraryReportResponse = await WorkTaskAPI.GetEmployeeItinerary(
      data.selectedEmployee.value || data.selectedEmployee[0].value,
      monthNumber,
      year
    );
    if (employeeItineraryReportResponse?.successful) {
      const reportData = employeeItineraryReportResponse.data.itineraryWorkTasks;
      let mappedReportData = reportData.map((item) => {
        let mappedObject = {
          id: item.workTaskUniqueIdentifier,
          title: item.workTaskType.displayText,
          start: UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(item.dateWorkTaskPerformed),
          end: UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(item.dateWorkTaskPerformed),
          fullObjects: [item],
        };
        if (item.workTaskType.id === 1) {
          mappedObject.title = (item.propertyWorkTaskPerformedOn ?? "") + " (" + item.workTaskType.displayText + ")";
        }
        return mappedObject;
      });
      data.generatedReportData = [...data.generatedReportData, ...mappedReportData];
      alreadyQueriedDateRange.push(monthNumber + "/" + year);
      this.setState({ data, shownReport: "Employee Itinerary", alreadyQueriedDateRange });
    } else if (employeeItineraryReportResponse?.unsuccessful) {
      ErrorHandler.showErrorWithDetails(
        `Unable to generate report for ${monthNumber}/${year}.`,
        employeeItineraryReportResponse?.message
      );
    } else {
      ErrorHandler.showErrorWithMessage(
        `Unable to generate report for ${monthNumber}/${year}. Please try again later.`
      );
    }
    this.setState({ isReportLoading: false });
  }

  onCalendarRangeChange = async (e) => {
    if (this.state.currentCalendarView === "week" && e[0]) {
      const startDate = e[0];
      const endDate = e[e.length - 1];
      let useStart = true;
      let useEnd = true;
      for (let i = 0; i < this.state.alreadyQueriedDateRange.length; i++) {
        let currentDate = this.state.alreadyQueriedDateRange[i];
        if (currentDate === startDate.getMonth() + 1 + "/" + startDate.getFullYear()) {
          useStart = false;
        }
        if (currentDate === endDate.getMonth() + 1 + "/" + endDate.getFullYear()) {
          useEnd = false;
        }
        if (!useStart && !useEnd) {
          break;
        }
      }
      if (useStart) {
        await this.generateItineraryReport(startDate.getMonth() + 1, startDate.getFullYear());
      } else if (useEnd) {
        await this.generateItineraryReport(endDate.getMonth() + 1, endDate.getFullYear());
      }
    }
  };

  onCalendarNavigate = async (e) => {
    if (this.state.currentCalendarView === "month" && e) {
      const month = e.getMonth() + 1;
      const year = e.getFullYear();
      if (!this.state.alreadyQueriedDateRange.some((q) => q === month + "/" + year)) {
        await this.generateItineraryReport(month, year);
      }
    }
  };

  onCalendarEventSelect = (e) => {
    //will be used to display more information about the event
  };

  renderEmployeeItineraryReport = () => {
    return (
      <div
        className={
          this.state.useSupervisorStyles ? "reactBigCalendarContainerSupervisor" : "reactBigCalendarContainerEmployee"
        }
      >
        <Row>
          <Col>
            <p className={globalStyles.pageTitle} style={{ fontWeight: "600" }} data-testid="reportTitle">
              <span>
                <i className="fa fa-file-text-o"></i>
              </span>
              {this.state.useSupervisorStyles ? " Supervisor Itinerary Report" : " Emloyee Itinerary Report"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Calendar
              components={components}
              events={this.state.data.generatedReportData}
              localizer={mLocalizer}
              showMultiDayTimes
              popup
              step={30}
              dayLayoutAlgorithm="no-overlap"
              views={{ month: true, week: true }}
              style={{ height: "550px" }}
              onRangeChange={this.onCalendarRangeChange}
              onNavigate={this.onCalendarNavigate}
              onSelectEvent={this.onCalendarEventSelect}
              onView={(e) => {
                this.setState({ currentCalendarView: e });
              }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderProjectReport = () => {
    return (
      <ToolkitProvider
        keyField="projectUniqueIdentifier"
        data={this.state.generatedReportData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "project-availablity-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <React.Fragment>
            <Row>
              <Col>
                <p className={globalStyles.pageTitle} style={{ fontWeight: "600" }} data-testid="reportTitle">
                  <span>
                    <i className="fa fa-file-text-o"></i>
                  </span>
                  {" Project Report"}
                </p>
              </Col>
              <Col align="right">
                <ExportCSVButton {...props.csvProps} />
              </Col>
            </Row>
            <Row>
              <Col>
                <BootstrapTable
                  keyField="projectUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSortDirection="asc"
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </ToolkitProvider>
    );
  };

  render() {
    return (
      <div className={styles.ReportPage}>
        <Row className="mt-4">
          <Col>
            <p className={globalStyles.pageTitle} data-testid="dashboardTitle">
              Reports
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={4} align="left" className={"pr-4 " + styles.reportTypeSelectionColumn}>
                    <Form.Group>
                      <Form.Label className={globalStyles.formLabel}>Report Type</Form.Label>
                      <Select
                        value={this.state.activeReportType}
                        placeholder="Select"
                        aria-label="Report Type Select"
                        styles={this.customSelectStyles}
                        options={this.state.reportTypes}
                        onChange={(e) => {
                          let { data } = this.state;
                          if (e?.value === 1) {
                            if (this.state.currentUserRole) {
                              data.selectedEmployee = {
                                value: this.state.currentUserRole.wsUserProfileUniqueIdentifier,
                                label: this.state.currentUserRole.userName,
                                profileName: this.state.currentUserRole.profileName,
                              };
                            }
                          }
                          if (e?.value === 2) {
                            data.selectedEmployee = [];
                          }
                          this.setState({ data, activeReportType: e ?? "" });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {this.renderReportOptions()}
                  <Col
                    md={{
                      span: 2,
                      offset: this.state.activeReportType.value === ReportTypeValues.EmployeeItinerary ? 3 : 0,
                    }}
                    align="right"
                  >
                    <Form.Group className={styles.submitButtonWrapper}>
                      <Button
                        type="submit"
                        className={"m-0" + globalStyles.modalSubmitButton}
                        onClick={this.handleGenerateReportClick}
                      >
                        <span>Generate Report</span>
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Card>
              <Card.Body>
                <Row className="pl-4">
                  <Col>
                    {this.state.isReportLoading && (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden" aria-label="Loading..."></span>
                      </Spinner>
                    )}
                  </Col>
                </Row>
                {this.renderReportContent()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(ReportPage);
