import React from "react";
import { Modal, Button, Row, Col, CloseButton, Form } from "react-bootstrap";
import CustomForm from "../../../components/common/form";
import globalStyles from "../../../OARS.module.scss";
import styles from "../../Agreements/Agreement.module.scss";

class AgreementAmendmentHistory extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: { reasonForRejection: "" },
      errors: {},
    };
  }

  handleSpecificChanges = () => {
    //do nothing
  };

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.reasonForRejection = "";
    this.setState({ data });
  }

  getAmendmentModalBody() {
    const generalFieldsBody = this.renderGeneralFieldsBody();
    const otherFieldsBody = this.renderOtherFieldsBody();

    return (
      <React.Fragment>
        {generalFieldsBody}
        {otherFieldsBody}
      </React.Fragment>
    );
  }

  renderGeneralFieldsBody() {
    let generalFieldsBody = null;
    const generalFieldsModified = this.getGeneralFieldsAmendedData();
    if (generalFieldsModified?.length > 0) {
      generalFieldsBody = (
        <React.Fragment>
          <Row className="mx-0 my-2 text-left">
            <Col>
              <span className={globalStyles.cardTitleText}>Agreement Information</span>
            </Col>
          </Row>
          <Row className="mx-0 text-left">
            <Col>{this.renderAmendedField(generalFieldsModified[0])}</Col>
            <Col>{generalFieldsModified?.length > 1 ? this.renderAmendedField(generalFieldsModified[1]) : null}</Col>
          </Row>
          <Row className="mx-0 text-left">
            <Col>{generalFieldsModified?.length > 2 ? this.renderAmendedField(generalFieldsModified[2]) : null}</Col>
            <Col>{generalFieldsModified?.length > 3 ? this.renderAmendedField(generalFieldsModified[3]) : null}</Col>
          </Row>
          <Row className="mx-0 text-left">
            <Col>{generalFieldsModified?.length > 4 ? this.renderAmendedField(generalFieldsModified[4]) : null}</Col>
            <Col>{generalFieldsModified?.length > 5 ? this.renderAmendedField(generalFieldsModified[5]) : null}</Col>
          </Row>
        </React.Fragment>
      );
    }
    return generalFieldsBody;
  }

  renderOtherFieldsBody() {
    let projectsBody = null;
    const modifiedFieldsData = this.getModifiedFieldsData();
    if (modifiedFieldsData?.length > 0) {
      projectsBody = (
        <React.Fragment>
          <Row className="mx-0 my-2 text-left">
            <Col>
              <span className={globalStyles.cardTitleText}>Agreement Data</span>
            </Col>
          </Row>
          {modifiedFieldsData.map((modifiedField, index) => (
            <Row key={index} className="mx-0 my-1 text-left">
              <Col className={globalStyles.formLabel}>
                *{" "}
                {`${modifiedField.fieldName}${
                  modifiedField.amendedActions?.length > 0 ? ` (${modifiedField.amendedActions.join(", ")})` : ""
                }`}
              </Col>
            </Row>
          ))}
        </React.Fragment>
      );
    }
    return projectsBody;
  }

  getGeneralFieldsAmendedData() {
    const { agreementAmendmentData } = this.props;
    let generalFieldsModified = [];
    if (agreementAmendmentData) {
      if (agreementAmendmentData?.AgreementNumber) {
        generalFieldsModified.push(
          this.getAmendedFieldObject(agreementAmendmentData.AgreementNumber, "Agreement Number")
        );
      }
      if (agreementAmendmentData?.CommonName) {
        generalFieldsModified.push(this.getAmendedFieldObject(agreementAmendmentData.CommonName, "Common Name"));
      }
      if (agreementAmendmentData?.AgreementType?.DisplayText) {
        generalFieldsModified.push(
          this.getAmendedFieldObject(agreementAmendmentData.AgreementType.DisplayText, "Agreement Type")
        );
      }
    }
    return generalFieldsModified;
  }

  getModifiedFieldsData() {
    const { agreementAmendmentData } = this.props;
    let modifiedFieldsData = [];
    if (agreementAmendmentData) {
      if (agreementAmendmentData.Cooperator?.ContactUniqueReference) {
        modifiedFieldsData.push({
          fieldName: "Cooperator",
        });
      }
      if (agreementAmendmentData.PrimaryFieldAgent?.FieldAgentWsUserProfileUniqueReference) {
        modifiedFieldsData.push({
          fieldName: "Primary Field Agent",
        });
      }
      if (agreementAmendmentData.ProjectUniqueReferences) {
        modifiedFieldsData.push({
          fieldName: "Projects",
          amendedActions: this.getFieldAmendedActions(agreementAmendmentData.ProjectUniqueReferences),
        });
      }
      if (agreementAmendmentData.AdditionalContacts) {
        modifiedFieldsData.push({
          fieldName: "Additional Contacts",
          amendedActions: this.getFieldAmendedActions(agreementAmendmentData.AdditionalContacts),
        });
      }
      if (agreementAmendmentData.AgreementProperties) {
        modifiedFieldsData.push({
          fieldName: "Agreement Properties",
          amendedActions: this.getFieldAmendedActions(agreementAmendmentData.AgreementProperties),
        });
      }
    }
    return modifiedFieldsData;
  }

  getFieldAmendedActions(amendedObject) {
    let amendedActions = [];
    if (amendedObject.Added) {
      amendedActions.push("Added");
    }
    if (amendedObject.Removed) {
      amendedActions.push("Removed");
    }
    if (amendedObject.Modified) {
      amendedActions.push("Modified");
    }
    return amendedActions;
  }

  getAmendedFieldObject(amendedObject, fieldName) {
    return {
      fieldName,
      oldValue: amendedObject.compared,
      currentValue: amendedObject.current,
    };
  }

  renderAmendedField(generalFieldModified) {
    return (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>{generalFieldModified.fieldName}</Form.Label>
        <Form.Control defaultValue={generalFieldModified.currentValue} readOnly />
        <Form.Text className={styles.smallCommentsText}>{`OLD: ${generalFieldModified.oldValue}`}</Form.Text>
      </Form.Group>
    );
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.viewAgreementAmendmentModal}
      >
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>View Amendment</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>{this.getAmendmentModalBody()}</Modal.Body>
        <Modal.Footer>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.handleModalCancel}>
            <span className={globalStyles.modalSubmitButtonText}>Close</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AgreementAmendmentHistory;
