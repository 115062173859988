import React from "react";
import CustomForm from "../common/form";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Form, Button, Row, Col, Image, Dropdown, CloseButton } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import nav1 from "../../assets/resourceSelectionModalNav1.png";
import nav2 from "../../assets/resourceSelectionModalNav2.png";
import chevronDownIcon from "../../assets/chevron-down.svg";
import searchIcon from "../../assets/search.svg";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const ChevronDown = () => {
  return <img src={chevronDownIcon} alt="down arrow" />;
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (

  <Button
    ref={ref}
    iconafter={searchIcon}
    onClick={onClick}
    className={styles.propertyAssociationDropdownMenuButton}
    variant="light"
  >
    <Row className="mx-0">
      <Col className="text-left pr-1 pl-0">
        <span className={styles.propertyAssocDropdownPlaceholder}>{children}</span>
      </Col>
      <Col sm={1} className="text-right px-0 pb-2">
        <ChevronDown />
      </Col>
    </Row>
  </Button>

));

const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-label="Crew Menu">
      <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
    </div>
  );
});

class ResourceSelectionModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        selectedResources: [],
        resourceNameSearch: "",
        resourceMainCategorySearch: "",
        resourceSubCategory1Search: "",
        isValuationUpdatedForAllSelectedResources: false,
      },
      favoriteResources: [],
      allResources: [],
      allResourcesOriginalData: [],
      resourceMainCategories: [],
      resourceSubCategories1: [],
      resourceSubCategories2: [],
      isInitialPage: true,
      errors: {},
      resourceTypes: [],
      resourceSubTypes: [],
    };
  }

  async componentDidMount() {
    if (!this.props.currentUsaState?.stateGuid) {
      toast.error("Error loading current State Id on Resource Selection Modal");
      return;
    }

    let { allResources, allResourcesOriginalData, resourceMainCategories } = this.state;
    const allResourcesResponse = await ReferenceFileAPI.GetResourcesForState(this.props.currentUsaState.stateGuid);
    if (allResourcesResponse?.successful) {
      allResources = allResourcesResponse.data.results
        .filter((r) => r.isAllowed)
        .map((r) => ({ ...r, isSelected: false }));
      allResourcesOriginalData = JSON.parse(JSON.stringify(allResources));
      const resourceTypesResponse = await ReferenceFileAPI.GetResourceTypesEnum();
      if (resourceTypesResponse?.successful && resourceTypesResponse?.data?.length > 0) {
        resourceMainCategories = resourceTypesResponse?.data?.map((type) => {
          if (!type) {
            return { value: type.displayText, label: "No Type" };
          }
          return { value: type.displayText, label: type.displayText };
        });
      } else {
        ErrorHandler.showErrorWithMessage("Error retrieving Resource Types from the server.");
      }

      this.setState({
        allResources,
        allResourcesOriginalData,
        favoriteResources: this.getFavoriteResources(allResources),
        resourceMainCategories,
        resourceTypes: resourceTypesResponse?.data,
      });
    } else if (allResourcesResponse?.data?.results?.length > 0) {
      ErrorHandler.showErrorWithMessage("No resources found for this state.");
    } else if (allResourcesResponse.unsuccessful) {
      ErrorHandler.showErrorWithMessage(allResourcesResponse.message);
    } else {
      ErrorHandler.showErrorWithMessage("Error retrieving Resources from the server.");
    }
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  getFavoriteResources(allResources) {
    let favoriteResources = [];
    if (this.props.favoriteResourceIds?.length > 0 && allResources?.length > 0) {
      this.props.favoriteResourceIds.forEach((id) => {
        const matchingResource = allResources.find((r) => r.resourceUniqueIdentifier === id);
        if (matchingResource) {
          favoriteResources.push({ resourceUniqueIdentifier: id, name: matchingResource.name, isFavorite: true });
        }
      });
    }

    return favoriteResources;
  }

  doSubmit = async () => {
    if (this.state.isInitialPage) {
      if (!(this.state.data.selectedResources?.length > 0)) {
        toast.warning("At least one resource must be selected.");
        return;
      }

      const { data } = this.state;
      if (!data.isValuationUpdatedForAllSelectedResources) {
        await this.updateResourceValuationData(data.selectedResources);
        data.isValuationUpdatedForAllSelectedResources = true;
      }

      this.setState({ data, isInitialPage: false });
    } else {
      if (!this.isFormDataValid()) {
        toast.warning(
          "Please provide a valid quantity, unit value and unit of measure for each resource, as well as one associated property at least."
        );
        return;
      }

      this.props.onHide();
      this.props.onSave(this.state.data.selectedResources);
      this.clearData();
    }
  };

  async updateResourceValuationData(selectedResources) {
    await Promise.all(
      selectedResources.map(async (resource) => {
        if (this.props.currentUsaState?.stateGuid) {
          const resourceServerResponse = await ReferenceFileAPI.GetResourceForState(
            resource.resourceUniqueIdentifier,
            this.props.currentUsaState.stateGuid
          );
          if (resourceServerResponse?.successful) {
            const resourceData = resourceServerResponse.data;
            if (resourceData?.stateResourceValuations?.length > 0 && resourceData.stateValuationSetting?.id !== 1) {
              resource.unitValue = this.getProtectedResourceValuation(resourceData);
            }
            if (resourceData.stateValuationUnitOfMeasure) {
              const { id, name } = resourceData.stateValuationUnitOfMeasure;
              resource.resourceUom = { value: id, label: name };
            }
          }
        }
      })
    );
  }

  getProtectedResourceValuation(resourceData) {
    const resourceValuation = resourceData.stateResourceValuations.sort((a, b) =>
      a.valuationYear > b.valuationYear ? -1 : 1
    )[0];
    return resourceValuation ? resourceValuation.valuePerUnitOfMeasure : "";
  }

  isFormDataValid() {
    let isDataCompleted = true;
    this.state.data.selectedResources.forEach((resource) => {
      let isDataInvalidCondition =
        !resource.quantity || !resource.resourceUom || !(resource.checkedProperties?.length > 0);

      if (resource?.stateValuationSetting?.id == 3) {
        isDataInvalidCondition = isDataInvalidCondition || !resource.unitValue || !resource.totalValue;
      }

      if (isDataInvalidCondition) {
        isDataCompleted = false;
      }
    });
    return isDataCompleted;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data, allResourcesOriginalData } = this.state;
    data.selectedResources = [];
    data.isValuationUpdatedForAllSelectedResources = false;

    this.clearFilters();
    this.setState({
      data,
      isInitialPage: true,
      allResources: JSON.parse(JSON.stringify(allResourcesOriginalData)),
      favoriteResources: this.getFavoriteResources(allResourcesOriginalData),
    });
  }

  clearFilters = () => {
    const { data } = this.state;
    data.resourceNameSearch = "";
    data.resourceMainCategorySearch = "";
    data.resourceSubCategory1Search = "";
    this.setState({ data });
  };

  handleResourceSelection = (selectedResource) => {
    const { data, favoriteResources, allResources } = this.state;

    const favoriteResource = favoriteResources.find(
      (r) => r.resourceUniqueIdentifier === selectedResource.resourceUniqueIdentifier
    );
    if (favoriteResource) {
      favoriteResource.isSelected = !favoriteResource.isSelected;
    }

    const regularResource = allResources.find(
      (r) => r.resourceUniqueIdentifier === selectedResource.resourceUniqueIdentifier
    );
    if (regularResource) {
      regularResource.isSelected = !regularResource.isSelected;
    }

    if (data.selectedResources.find((r) => r.resourceUniqueIdentifier === selectedResource.resourceUniqueIdentifier)) {
      data.selectedResources = data.selectedResources.filter(
        (r) => r.resourceUniqueIdentifier !== selectedResource.resourceUniqueIdentifier
      );
    } else {
      selectedResource.checkedProperties = [];
      data.selectedResources.push(selectedResource);
      data.isValuationUpdatedForAllSelectedResources = false;
    }

    this.setState({ data, favoriteResources, allResources });
  };

  handleResourceFilterSearch = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleResourceFilterSelect = async (input, selectionField) => {
    const data = { ...this.state.data };
    if (!input) {
      data[selectionField] = "";
      if (selectionField === "resourceMainCategorySearch") {
        data.resourceSubCategory1Search = "";
      }
    } else {
      data[selectionField] = input;
      if (selectionField === "resourceMainCategorySearch") {
        const typeEnumId = this.state.resourceTypes.find((x) => x.displayText === input.value)?.id;
        const newSubTypeList = await ReferenceFileAPI.GetResourceSubTypesEnumForType(typeEnumId);
        if (newSubTypeList && newSubTypeList?.data?.length > 0) {
          this.setState({
            resourceSubCategories1: newSubTypeList?.data?.map((subType) => {
              if (!subType) {
                return { value: subType.displayText, label: "No Type" };
              }
              return { value: subType.displayText, label: subType.displayText };
            }),
          });
          data.resourceSubCategory1Search = "";
        }
      }
    }
    this.setState({ data });
  };

  onResourceSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data, favoriteResources, allResources } = this.state;

      const removedFavoriteResourceIndex = favoriteResources.findIndex(
        (r) => r.resourceUniqueIdentifier === removedValue.value
      );
      if (removedFavoriteResourceIndex !== -1) {
        favoriteResources[removedFavoriteResourceIndex].isSelected = false;
      }

      const removedResourceIndex = allResources.findIndex((r) => r.resourceUniqueIdentifier === removedValue.value);
      if (removedResourceIndex !== -1) {
        allResources[removedResourceIndex].isSelected = false;
      }

      data.selectedResources = data.selectedResources.filter((r) => r.resourceUniqueIdentifier !== removedValue.value);
      this.setState({ data, allResources, favoriteResources });
    }
  };

  handlePageNavigation = async (step) => {
    switch (step) {
      case 1:
        this.setState({ isInitialPage: true });
        break;
      case 2:
        if (this.state.data.selectedResources?.length > 0) {
          const { data } = this.state;
          if (!data.isValuationUpdatedForAllSelectedResources) {
            await this.updateResourceValuationData(data.selectedResources);
            data.isValuationUpdatedForAllSelectedResources = true;
          }
          this.setState({ data, isInitialPage: false });
        } else {
          toast.warning("Please select at least one resource.");
        }
        break;
    }
  };

  handleResourceChanges = ({ currentTarget: input }, index) => {
    const data = { ...this.state.data };

    const resourceToUpdate = data.selectedResources[index];
    switch (input.name) {
      case "quantity":
        if (input.value) {
          resourceToUpdate.quantity =
            resourceToUpdate.resourceUom?.value === 6
              ? Math.round(Number.parseFloat(input.value))
              : Number.parseFloat(Number.parseFloat(input.value).toFixed(2));
          resourceToUpdate.totalValue = resourceToUpdate.unitValue
            ? resourceToUpdate.quantity * resourceToUpdate.unitValue
            : resourceToUpdate.quantity;
        } else {
          resourceToUpdate.quantity = "";
        }
        break;
      case "unitValue":
        resourceToUpdate.unitValue = input.value ? Number.parseFloat(Number.parseFloat(input.value).toFixed(2)) : "";
        resourceToUpdate.totalValue =
          resourceToUpdate.quantity && resourceToUpdate.unitValue
            ? resourceToUpdate.quantity * resourceToUpdate.unitValue
            : resourceToUpdate.totalValue;
        break;
      case "totalValue":
        resourceToUpdate.totalValue = input.value ? Number.parseFloat(Number.parseFloat(input.value).toFixed(2)) : "";
        if (resourceToUpdate.unitValue) {
          const resourceQuantity =
            resourceToUpdate.totalValue && resourceToUpdate.unitValue
              ? resourceToUpdate.totalValue / resourceToUpdate.unitValue
              : 0;
          resourceToUpdate.quantity =
            resourceToUpdate.resourceUom?.value === 6
              ? Math.round(resourceQuantity)
              : Number.parseFloat(resourceQuantity.toFixed(2));
        }
        break;
    }

    this.setState({ data });
  };

  handleResourceUomChange = (input, index) => {
    const data = { ...this.state.data };
    const resourceToUpdate = data.selectedResources[index];

    resourceToUpdate.resourceUom = input;
    if (input.value === 6 && resourceToUpdate.quantity) {
      resourceToUpdate.quantity = Math.round(resourceToUpdate.quantity);
      resourceToUpdate.totalValue = resourceToUpdate.unitValue
        ? resourceToUpdate.quantity * resourceToUpdate.unitValue
        : resourceToUpdate.quantity;
    }

    this.setState({ data });
    return input;
  };

  handleDeleteResource = (resourceToDelete) => {
    const { data, favoriteResources, allResources } = this.state;
    if (resourceToDelete.isFavorite) {
      const favoriteResource = favoriteResources.find(
        (r) => r.resourceUniqueIdentifier === resourceToDelete.resourceUniqueIdentifier
      );
      favoriteResource.isSelected = false;
    } else {
      const regularResource = allResources.find(
        (r) => r.resourceUniqueIdentifier === resourceToDelete.resourceUniqueIdentifier
      );
      regularResource.isSelected = false;
    }

    data.selectedResources = data.selectedResources.filter(
      (r) => r.resourceUniqueIdentifier !== resourceToDelete.resourceUniqueIdentifier
    );
    this.setState({ data });
  };

  handlePropertySelection = (updatedResource, property) => {
    const { data } = this.state;
    const resourceToUpdate = data.selectedResources.find(
      (r) => r.resourceUniqueIdentifier === updatedResource.resourceUniqueIdentifier
    );

    if (resourceToUpdate) {
      let matchingPropertyList = [];
      if (property !== undefined && property !== null) {
        property.forEach((p) => {
          const matchingProperty = this.props.properties?.find((prop) => prop.propertyUniqueIdentifier === p.value);
          if (matchingProperty) {
            matchingPropertyList.push(matchingProperty);
          }
        });
      }

      resourceToUpdate.checkedProperties = matchingPropertyList.filter(() => true);
      this.setState({ data });
    }
  };


  renderResourceSelectionPage = () => {
    const { data } = this.state;
    return (
      <React.Fragment>
        <Row className="mx-0 mt-4">
          <Col>
            <span className={styles.refFilesModalSubTitle}>Favorite Resources</span>
          </Col>
        </Row>
        <Row className="mx-0 my-2">
          <Col>
            <span className={styles.refFilesModalSubTitle}>
              <span className={styles.refFilesModalBoldListing}>Resource Name</span>
            </span>
          </Col>
        </Row>
        <div className={styles.favoriteResourcesContainer}>
          {this.state.favoriteResources.map((resource, index) => {
            return (
              <div key={index}>
                {index === 0 && <Row className={styles.greySeparatorRow}></Row>}
                <Row className="my-2 mx-0">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      inline
                      checked={resource.isSelected}
                      onChange={() => this.handleResourceSelection(resource)}
                      title={resource.name}
                    />
                    <span className={globalStyles.formData}>{resource.name}</span>
                  </Col>
                </Row>
                <Row className={styles.greySeparatorRow}></Row>
              </div>
            );
          })}
        </div>
        <Row className="mx-0 mt-4">
          <Col>
            <span className={styles.refFilesModalSubTitle}>Search Additional Resources</span>
          </Col>
        </Row>
        <Row className="mx-0 mt-2">
          <Col className="pr-0">
            <Form.Control
              aria-label="Resource Name Search Input"
              name="resourceNameSearch"
              placeholder="Enter Resource Name"
              value={data.resourceNameSearch}
              onChange={this.handleResourceFilterSearch}
            />
          </Col>
          <Col className="pr-0">
            <Select
              aria-label="Resource Type Select"
              name="resourceTypeSearch"
              placeholder="Type"
              isClearable
              value={data.resourceMainCategorySearch}
              onChange={async (e) => await this.handleResourceFilterSelect(e, "resourceMainCategorySearch")}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                  backgroundColor: isFocused ? "lightgray" : "white",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                }),
                menuList: (styles) => ({ ...styles, textAlign: "left" }),
              }}
              options={this.state.resourceMainCategories}
            />
          </Col>
          <Col>
            <Select
              aria-label="Resource Sub Type Select"
              name="resourceSubTypeSearch"
              placeholder="Sub Type"
              isClearable
              value={data.resourceSubCategory1Search}
              onChange={async (e) => await this.handleResourceFilterSelect(e, "resourceSubCategory1Search")}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                  backgroundColor: isFocused ? "lightgray" : "white",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "black",
                }),
                menuList: (styles) => ({ ...styles, textAlign: "left" }),
              }}
              options={this.state.resourceSubCategories1}
            />
          </Col>
        </Row>
        <Row>
          <Col align="right">
            <Button variant="link" onClick={this.clearFilters} style={{ fontSize: "14px" }}>
              Clear All Filters
            </Button>
          </Col>
        </Row>
        <Row className={styles.resultsBox}>{this.renderSearchResults()}</Row>
        <Row className="mx-0 mt-4">
          <Col>
            <Form.Group>
              <Form.Label className={styles.refFilesModalSubTitle} htmlFor="resourcesSelected">
                Resources Selected
              </Form.Label>
              <Select
                aria-label="Resources Selected"
                name="resourcesSelected"
                id="resourcesSelected"
                placeholder="Resources Selected"
                isMulti
                menuIsOpen={false}
                isClearable={false}
                isSearchable={false}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: "white",
                      border: "solid 0.5px #D8DDE6",
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: "#0070D2",
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: "#53698D",
                    ":hover": {
                      backgroundColor: "white",
                    },
                  }),
                }}
                components={{
                  DropdownIndicator: (props) => {
                    return "";
                  },
                }}
                value={this.state.data?.selectedResources?.map((r) => ({
                  value: r.resourceUniqueIdentifier,
                  label: r.name,
                }))}
                onChange={this.onResourceSelectionChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderSearchResults = () => {
    let results = null;
    const { data, allResources } = this.state;
    if (data.resourceNameSearch || data.resourceMainCategorySearch || data.resourceSubCategory1Search) {
      results = (
        <React.Fragment>
          {allResources
            .filter((resource) => {
              let isMatch = true;
              if (data.resourceNameSearch) {
                isMatch = isMatch && resource.name.toLowerCase().includes(data.resourceNameSearch.toLowerCase());
              }
              if (data.resourceMainCategorySearch) {
                isMatch = isMatch && resource.type === data.resourceMainCategorySearch.value;
              }
              if (data.resourceSubCategory1Search) {
                isMatch = isMatch && resource.subType === data.resourceSubCategory1Search.value;
              }

              return isMatch;
            })
            .map((result, index) => {
              return (
                <div key={index}>
                  <Row className={`mb-2 mx-0 ${index === 0 ? "mt-3" : "mt-2"}`}>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        inline
                        checked={result.isSelected}
                        onChange={() => this.handleResourceSelection(result)}
                        title={result.name}
                      />
                      <span className={globalStyles.formData}>{result.name}</span>
                    </Col>
                  </Row>
                  <Row className={styles.greySeparatorRow}></Row>
                </div>
              );
            })}
        </React.Fragment>
      );
    }

    return results;
  };

  renderResourceAssociationPage = () => {
    return (
      <React.Fragment>
        {this.state.data.selectedResources?.map((resource, index) => {
          return (
            <div key={index}>
              <Row className={`mr-0 ml-4 ${index === 0 ? "mt-5 mb-3" : "my-3"} px-0`}>
                <Col md={1} className="pl-0 pr-1">
                  <Form.Control
                    id={`resourceYear-${index}`}
                    name="resourceYear"
                    type="number"
                    aria-label="Resource Year"
                    style={{ fontSize: "14px", fontFamily: "Source Sans Pro" }}
                    className="px-1 text-center"
                    defaultValue={new Date().getFullYear()}
                    onChange={(e) => this.handleResourceChanges(e, index)}
                    readOnly
                  />
                </Col>
                <Col md={3} className="px-1">
                  <Form.Control
                    id={`resourceName-${index}`}
                    name="resourceName"
                    type="text"
                    style={{ fontSize: "14px", fontFamily: "Source Sans Pro" }}
                    className="px-1"
                    placeholder="Search Resource"
                    value={resource.name}
                    onChange={(e) => this.handleResourceChanges(e, index)}
                    readOnly
                  />
                </Col>
                <Col md={1} className="px-1">
                  <Form.Control
                    id={`resourceQuantity-${index}`}
                    name="quantity"
                    aria-label="Protected Resource Quantity Input"
                    className="px-1"
                    style={{ fontSize: "14px", fontFamily: "Source Sans Pro" }}
                    type="number"
                    placeholder="Qty."
                    value={resource.quantity}
                    onChange={(e) => this.handleResourceChanges(e, index)}
                  />
                </Col>
                <Col md={1} className="px-1">
                  <Select
                    id={`resourceUom-${index}`}
                    name="resourceUom"
                    aria-label="Select UOM Dropdown"
                    value={resource.resourceUom}
                    placeholder="UOM"
                    options={this.props.resourceUOMs}
                    onChange={(e) => this.handleResourceUomChange(e, index)}
                    styles={{
                      indicatorSeparator: () => {
                        //do nothing
                      },
                      control: (styles) => ({ ...styles, fontSize: "14px", height: 35, minHeight: 35 }),
                      placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
                      valueContainer: (styles) => ({
                        ...styles,
                        paddingRight: "0 !important",
                        paddingLeft: "0 !important",
                      }),
                      dropdownIndicator: (styles) => ({
                        ...styles,
                        paddingLeft: "0 !important",
                      }),
                      option: (styles) => ({ ...styles, fontSize: "14px", fontWeight: "normal", color: "black" }),
                      singleValue: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                        fontWeight: "normal",
                        color: "black",
                      }),
                    }}
                  />
                </Col>
                <Col md={1} className="px-1">
                  {resource?.stateValuationSetting?.id != 1 && (
                    <Form.Control
                      id={`resourceUnitValue-${index}`}
                      name="unitValue"
                      aria-label="Protected Resource Unit Value Input"
                      className="px-1"
                      style={{ fontSize: "14px", fontFamily: "Source Sans Pro" }}
                      type="number"
                      placeholder="Unit Value (USD)"
                      value={resource.unitValue}
                      onChange={(e) => this.handleResourceChanges(e, index)}
                    />
                  )}
                </Col>
                <Col md={1} className="pl-1 pr-0">
                  {resource?.stateValuationSetting?.id != 1 && (
                    <Form.Control
                      id={`resourceTotalValue-${index}`}
                      name="totalValue"
                      aria-label="Protected Resource Total Value Input"
                      className="px-1"
                      style={{ fontSize: "14px", fontFamily: "Source Sans Pro" }}
                      type="number"
                      placeholder="Total Value (USD)"
                      value={resource.totalValue}
                      onChange={(e) => this.handleResourceChanges(e, index)}
                    />
                  )}
                </Col>
                <Col md={3} className="pl-2 pr-0">
                  <Select
                    value={resource.checkedProperties?.map((property) => {
                      return { value: property.propertyUniqueIdentifier, label: property.propertyName };
                    })}
                    placeholder="Associate w/ Property"
                    isMulti={true}
                    styles={this.customSelectStyles}
                    options={this.props.properties?.map((property) => {
                      return { value: property.propertyUniqueIdentifier, label: property.propertyName };
                    })}
                    onChange={(property) => this.handlePropertySelection(resource, property)}
                  />
                  {/* Keeping this block just in case something breaks*/}
                  {/* <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                      <span className={styles.smallFormDataText}>Associate w/ Property</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu} className={styles.propertyAssociationDropdownMenu}>
                      <Dropdown.Header className="px-1 py-0 mb-3"></Dropdown.Header>
                      {this.props.properties?.map((property, index) => {
                        const isPropertyDisabled =
                          resource.checkedProperties?.length > 0 &&
                          !resource.checkedProperties.some(
                            (p) => p.propertyUniqueIdentifier === property.propertyUniqueIdentifier
                          );
                        return (
                          <Row key={index} className="px-2 mx-0 mb-3">
                            <Form.Check
                              type="checkbox"
                              inline
                              checked={resource.checkedProperties?.some(
                                (p) => p.propertyUniqueReference === property.propertyUniqueReference
                              )}
                            >
                              <Form.Check.Input
                                id={`checkbox-${property.propertyUniqueReference}`}
                                type="checkbox"
                                value={resource.checkedProperties?.some(
                                  (p) => p.propertyUniqueReference === property.propertyUniqueReference
                                )}
                                onChange={() => this.handlePropertySelection(resource, property)}
                                aria-disabled={isPropertyDisabled}
                                disabled={isPropertyDisabled}
                                className="mr-2"
                              />
                              <Form.Check.Label
                                className={styles.smallFormDataText}
                                htmlFor={`checkbox-${property.propertyUniqueReference}`}
                                readOnly={isPropertyDisabled}
                              >
                                {property.propertyName}
                              </Form.Check.Label>
                            </Form.Check>
                          </Row>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown> */}
                </Col>
                <Col className="text-center pt-2 px-0" md={1}>
                  <i
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    onClick={() => this.handleDeleteResource(resource)}
                  ></i>
                </Col>
              </Row>
              <Row className={styles.propertyAssociationGreySeparatorRow}></Row>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  render() {
    let bodyContent = null;
    let submitButtonText = "Next";
    let navImage = nav1;

    if (!this.state.isInitialPage) {
      submitButtonText = "Save";
      navImage = nav2;
      bodyContent = this.renderResourceAssociationPage();
    } else {
      bodyContent = this.renderResourceSelectionPage();
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.refFilesAssociationModal}
      >
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Add Protected Resources</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0 my-3">
            <Col className="text-center">
              <Image src={navImage} useMap="#resourcesSelectionStepperMap" />
              <map name="resourcesSelectionStepperMap" className={styles.refFileStepper}>
                <area
                  alt="Add Resources"
                  title="Add Resources"
                  onClick={() => this.handlePageNavigation(1)}
                  coords="344,38,4,3"
                  shape="rect"
                />
                <area
                  alt="Add Information"
                  title="Add Information"
                  onClick={() => this.handlePageNavigation(2)}
                  coords="347,4,693,37"
                  shape="rect"
                />
              </map>
            </Col>
          </Row>
          {bodyContent}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={this.handleModalCancel}>
            <span>Cancel</span>
          </Button>
          <Button className="ml-2" variant="primary" type="submit" onClick={this.doSubmit}>
            <span className={globalStyles.modalSubmitButtonText}>{submitButtonText}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ResourceSelectionModal;
