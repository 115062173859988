import React from "react";
import { Modal, Button, Form, Dropdown, Row, Col, CloseButton } from "react-bootstrap";
import CustomForm from "../common/form";
import styles from "./EditModalPages.module.scss";
import globalStyles from "../../OARS.module.scss";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class EditResourceModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        category: "",
        resourceName: "",
        lastModifiedDate: "",
        setNewDate: new Date().toLocaleDateString("en-us"),
        isResourceAllowed: "",
        resourceData: this.props.resourceData,
        stateUOMsToAdd: [],
        stateUOMsToRemove: [],
        stateValuationSetting: "",
        stateValuationValue: "",
        stateValuationYear: "",
      },
      isInitialConfiguration: true,
      errors: {},
    };
  }

  componentDidMount() {
    let stateValuationValue = "";
    if (this.props?.resourceData?.stateResource?.stateResourceValuations && !this.state.data.stateValuationValue) {
      let latestYear = Math.max.apply(
        Math,
        this.props.resourceData.stateResource.stateResourceValuations.map(function (o) {
          return o.valuationYear;
        })
      );

      stateValuationValue = this.props.resourceData.stateResource.stateResourceValuations.find(function (o) {
        return o.valuationYear == latestYear;
      })?.valuePerUnitOfMeasure;

      let data = this.state.data;
      data.stateValuationValue = stateValuationValue;
      data.stateValuationYear = latestYear;
      data.stateValuationSetting = this.props.resourceData?.stateResource?.stateValuationSetting?.displayText;
      this.setState({ data });
    }
  }

  activateRadio() {
    const { data } = this.state;
    data.isResourceAllowed = true;
    this.setState({ data });
  }

  deactivateRadio() {
    const { data } = this.state;
    data.isResourceAllowed = false;
    this.setState({ data });
  }

  handleStateUoMs(item, resourceUOMs) {
    let { data } = this.state;
    const resourceData = this.props.resourceData?.stateResource;

    if (
      data.stateUOMsToAdd.length === 0 &&
      data.stateUOMsToRemove.length === 0 &&
      resourceData?.stateAllowedUnitOfMeasures
    ) {
      let chosenUOMs = resourceData.stateAllowedUnitOfMeasures.map((allowedUOM) => allowedUOM.unitOfMeasure.id);
      resourceUOMs.forEach((uom) => {
        if (chosenUOMs.find((enabledUOM) => enabledUOM === uom.id)) {
          data.stateUOMsToAdd.push({
            unitOfMeasureEnumId: uom.id,
            conversionFactor: 1,
          });
        } else {
          data.stateUOMsToRemove.push(uom.id);
        }
      });
    } else if (
      data.stateUOMsToAdd.length === 0 &&
      data.stateUOMsToRemove.length === 0 &&
      !resourceData?.stateAllowedUnitOfMeasures
    ) {
      resourceUOMs.forEach((uom) => {
        data.stateUOMsToRemove.push(uom.id);
      });
    }

    let uomEnumId = parseInt(item?.id);
    let existingEnabledIndex = data.stateUOMsToAdd.findIndex((uom) => uom.unitOfMeasureEnumId === uomEnumId);
    let existingDisabledIndex = data.stateUOMsToRemove.findIndex((uom) => uom === uomEnumId);
    if (existingEnabledIndex === -1) {
      data.stateUOMsToAdd.push({
        unitOfMeasureEnumId: uomEnumId,
        conversionFactor: 1,
      });
      data.stateUOMsToRemove.splice(existingDisabledIndex, 1);
    } else {
      data.stateUOMsToRemove.push(uomEnumId);
      data.stateUOMsToAdd.splice(existingEnabledIndex, 1);
    }
    this.setState({ data, isInitialConfiguration: false });
  }

  async handleSave(resourceData, usaStateData) {
    const { data } = this.state;
    const isResourceAllowed = data.isResourceAllowed;

    let resourceId = resourceData.resourceUniqueIdentifier;
    let stateId = usaStateData.stateGuid;
    this.setState({ data });
    let isConfigureResourceSuccessful = true;
    let errorMessages = [];

    if (isResourceAllowed !== "") {
      const configureCall = isResourceAllowed
        ? await ReferenceFileAPI.AllowResourceForState(stateId, resourceId)
        : await ReferenceFileAPI.DisallowResourceForState(stateId, resourceId);

      if (!configureCall?.successful) {
        isConfigureResourceSuccessful = false;
        errorMessages.push(configureCall.message);
      } else {
        this.props.resourceData.stateResource = configureCall.data;
      }

      data.isResourceAllowed = "";
      this.setState({ data });
    }

    let isAddUOMSuccessful = true;
    let isRemoveUOMSuccessful = true;
    if (data.stateUOMsToAdd?.length > 0) {
      let addUOMResponse = await ReferenceFileAPI.EnableResourceUOMsInState(
        resourceId,
        stateId,
        data.stateUOMsToAdd.map((uom) => uom.unitOfMeasureEnumId)
      );
      if (!addUOMResponse?.successful) {
        isConfigureResourceSuccessful = false;
        errorMessages.push(addUOMResponse.message);
      } else {
        this.props.resourceData.stateResource = addUOMResponse.data;
      }
    }
    if (data.stateUOMsToRemove?.length > 0) {
      const removeUOMResponse = await ReferenceFileAPI.DisableResourceUOMsInState(
        resourceId,
        stateId,
        data.stateUOMsToRemove
      );
      if (!removeUOMResponse?.successful) {
        isConfigureResourceSuccessful = false;
        errorMessages.push(removeUOMResponse.message);
      } else {
        this.props.resourceData.stateResource = removeUOMResponse.data;
      }
    }

    let isValuationSettingCallSuccessful = true;
    if (data.stateValuationSetting !== resourceData?.stateValuationSetting?.displayText) {
      let valSettingId = this.props.apiValuationSettings.find(
        (x) => x.displayText === this.state.data.stateValuationSetting
      )?.id;

      var setStateValuationSettingAction = await ReferenceFileAPI.SetStateResourceValuationSetting(
        resourceId,
        stateId,
        valSettingId
      );

      if (setStateValuationSettingAction?.unsuccessful) {
        isValuationSettingCallSuccessful = false;
        if (setStateValuationSettingAction.message) {
          errorMessages.push(setStateValuationSettingAction.message);
        } else {
          errorMessages.push("Error setting Resource Valuation Setting.");
        }
      } else if (setStateValuationSettingAction?.successful) {
        this.props.resourceData.stateResource = setStateValuationSettingAction?.data;
      }
    }

    let isStateValuationActionSuccessful = true;
    if (this.state.data.stateValuationValue) {
      const overrideGlobalValuationAction = await ReferenceFileAPI.OverrideGlobalValuationForState(
        resourceId,
        stateId,
        new Date().getFullYear(),
        this.state.data.stateValuationValue
      );

      if (overrideGlobalValuationAction?.unsuccessful) {
        isStateValuationActionSuccessful = false;
        if (overrideGlobalValuationAction.message) {
          errorMessages.push(overrideGlobalValuationAction.message);
        } else {
          errorMessages.push("Error setting State Resource Valuation value.");
        }
      } else if (overrideGlobalValuationAction?.successful) {
        this.props.resourceData.stateResource = overrideGlobalValuationAction?.data;
      }
    } else {
      const removeGlobalValuationOverrideAction = await ReferenceFileAPI.RemoveGlobalValuationOverrideForState(
        resourceId,
        stateId,
        this.state.data.stateValuationYear
      );

      if (removeGlobalValuationOverrideAction?.unsuccessful) {
        isStateValuationActionSuccessful = false;
        if (removeGlobalValuationOverrideAction.message) {
          errorMessages.push(removeGlobalValuationOverrideAction.message);
        } else {
          errorMessages.push("Error setting State Resource Valuation value.");
        }
      } else if (removeGlobalValuationOverrideAction?.successful) {
        this.props.resourceData.stateResource = removeGlobalValuationOverrideAction?.data;
      }
    }

    if (
      !isAddUOMSuccessful ||
      !isRemoveUOMSuccessful ||
      !isValuationSettingCallSuccessful ||
      !isStateValuationActionSuccessful
    ) {
      isConfigureResourceSuccessful = false;
    }

    if (isConfigureResourceSuccessful) {
      toast.success("Resource was successfully configured.");
    } else {
      ErrorHandler.showErrorWithDetails(
        { errorMessages },
        "Resource configuration failed. Please contact system admin for more details."
      );
    }

    this.clearData();
    this.props.onHide();
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const data = {
      category: "",
      resourceName: "",
      lastModifiedDate: "",
      setNewDate: new Date().toLocaleDateString("en-ca"),
      isResourceAllowed: "",
      resourceData: "",
      stateUOMsToAdd: [],
      stateUOMsToRemove: [],
      stateValuationSetting: "",
    };
    this.setState({ data, isInitialConfiguration: true });
  }

  handleSpecificChanges(input) {}

  render() {
    let { resourceData, usaStateData } = this.props;
    let dateLastUpdated = resourceData?.stateResource?.lastModifiedDateTimeUTC
      ? new Date(resourceData?.stateResource?.lastModifiedDateTimeUTC).toLocaleDateString("en-US")
      : resourceData?.stateResource?.lastModifiedDateTimeUTC;

    const globalAllowedUnitOfMeasures =
      this.props.resourceData?.globalResource?.globalAllowedUnitOfMeasures?.length > 0
        ? this.props.resourceData.globalResource.globalAllowedUnitOfMeasures.map((uom) => uom.unitOfMeasure)
        : [];
    const stateAllowedUnitOfMeasures =
      this.props.resourceData?.stateResource?.stateAllowedUnitOfMeasures?.length > 0
        ? this.props.resourceData.stateResource.stateAllowedUnitOfMeasures.map((uom) => uom.unitOfMeasure)
        : [];

    const globalValuationUnitOfMeasure = this.props.resourceData?.globalResource?.globalValuationUnitOfMeasure;

    let stateValuationOptionComponent = this.renderLabel(
      "stateValuationSetting",
      "State Valuation Setting",
      resourceData?.stateResource?.stateValuationSetting?.displayText
    );
    let areValuationsOptional = resourceData?.globalResource?.globalValuationSetting?.name?.includes("optional");
    if (areValuationsOptional) {
      stateValuationOptionComponent = this.renderInlineCheckBoxSet(
        "stateValuationSetting",
        "State Valuation Setting",
        this.props.apiValuationSettings.map((x) => x.displayText),
        "radio",
        this.state.data.stateValuationSetting
          ? this.state.data.stateValuationSetting
          : resourceData?.stateResource?.stateValuationSetting?.displayText
      );
    }

    let globalValuationValue = "";
    if (this.props?.resourceData?.globalResource?.globalResourceValuations) {
      let latestYear = Math.max.apply(
        Math,
        this.props.resourceData.globalResource.globalResourceValuations.map(function (o) {
          return o.valuationYear;
        })
      );

      globalValuationValue = this.props.resourceData.globalResource.globalResourceValuations.find(function (o) {
        return o.valuationYear == latestYear;
      })?.valuePerUnitOfMeasure;
    }

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
        <Modal.Header className={globalStyles.modalHeader}>
          <Row className={globalStyles.modalHeaderRow}>
            <Col className="px-0">
              <Modal.Title className={globalStyles.modalTitleText}>Configure Resource</Modal.Title>
            </Col>
            <Col>
              <CloseButton onClick={this.handleModalCancel} />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-0">
            <Col>{this.renderLabel("category", "Category", resourceData?.stateResource?.type)}</Col>
          </Row>
          <Row className="mx-0">
            <Col>{this.renderLabel("resourceName", "Name", resourceData?.stateResource?.name)}</Col>
          </Row>
          <Row className="mx-0">
            <Col>
              <Dropdown data-testid="uomStateDropdown" id="uomStateDropdown" className="mb-1">
                <Dropdown.Toggle variant="light">Change State Allowed UOMs</Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className={styles.dropdownToggle} data-toggle="button">
                    {globalAllowedUnitOfMeasures?.map((item, index) => (
                      <div className="ml-3 mr-5 my-1" key={index}>
                        <input
                          tabIndex="0"
                          title={item.name + " checkbox"}
                          type="checkbox"
                          className={styles.dropdownCheckbox}
                          id={item.id}
                          key={item.id}
                          data-toggle="button"
                          value={
                            this.state.isInitialConfiguration
                              ? stateAllowedUnitOfMeasures?.some((uom) => uom.id === item.id)
                              : this.state.data.stateUOMsToAdd?.some((uom) => uom.unitOfMeasureEnumId === item.id)
                          }
                          defaultChecked={stateAllowedUnitOfMeasures?.find((uom) => uom.id === item.id)}
                          onChange={() => this.handleStateUoMs(item, globalAllowedUnitOfMeasures)}
                          disabled={globalValuationUnitOfMeasure?.id === item.id}
                        />
                        <Dropdown.Item
                          eventKey={item.id}
                          key={`formChecklistContainerUses-${item.name}`}
                          onSelect={() => this.handleStateUoMs(item, globalAllowedUnitOfMeasures)}
                        >
                          <p className="form-check-label ml-2" htmlFor="itemChecked">
                            {item.displayText || item.name}
                          </p>
                        </Dropdown.Item>
                      </div>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col>{this.renderInput("lastModifiedDate", "Last Modified Date", "text", dateLastUpdated, "readOnly")}</Col>
          </Row>
          <Row className="mx-0">
            <Col>
              <h4 className={globalStyles.modalFormLabel}>Allowed</h4>
              <Form.Group>
                <Form.Check
                  type="radio"
                  name="activeRadios"
                  inline
                  label="Yes"
                  id="activeRadio"
                  onChange={() => this.activateRadio()}
                  defaultChecked={resourceData?.stateResource?.isAllowed}
                />
                <Form.Check
                  type="radio"
                  name="activeRadios"
                  inline
                  label="No"
                  id="inactiveRadio"
                  onChange={() => this.deactivateRadio()}
                  defaultChecked={!resourceData?.stateResource?.isAllowed}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col>
              {this.renderLabel(
                "globalValuationSetting",
                "Global Valuation Setting",
                resourceData?.globalResource?.globalValuationSetting?.displayText
              )}
            </Col>
          </Row>
          <Row className="mx-0">
            <Col>{stateValuationOptionComponent}</Col>
          </Row>
          {areValuationsOptional && (
            <span>
              <Row className="mx-0">
                <Col>
                  {this.renderLabel("globalValuationValue", "Global Valuation Unit Value", globalValuationValue)}
                </Col>
                <Col>
                  {this.renderLabel(
                    "globalValuationUnitOfMeasure",
                    "Valuation Unit of Measure",
                    resourceData?.globalResource?.globalValuationUnitOfMeasure?.name
                  )}
                </Col>
              </Row>
              <Row className="mx-0">
                <Col>{this.renderInput("stateValuationValue", "State Valuation Unit Value", "text")}</Col>
              </Row>
              <Row>
                <span className={styles.resourceConfigureInfoText}>
                  <i>If the State Valuation is deleted then saved, the most recent Global Valuation will be shown.</i>
                </span>
              </Row>
            </span>
          )}
        </Modal.Body>
        <Modal.Footer className={globalStyles.modalFooter}>
          <Button className={globalStyles.modalCancelButton} onClick={this.handleModalCancel}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button
            type="submit"
            className={globalStyles.modalSubmitButton}
            onClick={() => this.handleSave(resourceData?.stateResource, usaStateData)}
          >
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditResourceModal;
