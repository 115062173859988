import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col } from "react-bootstrap";
import CustomForm from "../common/form";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class CreateEditCountryModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: this.props?.modalData
        ? this.props.modalData
        : {
            countryUniqueIdentifier: "",
            country_name: "",
            country_code: "",
            active: true,
            createdDateTimeUTC: "",
            lastModifiedDateTimeUTC: "",
            isCountryEdited: false,
          },
      errors: {},
    };
  }

  submitModal = () => {
    let { data } = this.state;
    if (data.country_name && data.country_code) {
      if (data.country_code.length > 3) {
        ErrorHandler.showError("Country code cannot exceed 3 characters");
        return;
      }

      this.props.onSubmit(data, this.state.modalMode);
      this.props.onHide();
    } else {
      ErrorHandler.showError("Country Name and Code are required");
    }
  };

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  createCountrytModalUI() {
    return (
      <div className={styles.createDamageModalBody}>
        <Row>
          <Col>{this.renderInput("country_name", "Country Name", "text", "Enter Country Name", "required")}</Col>
        </Row>
        <Row>
          <Col>{this.renderInput("country_code", "Country Code", "text", "Enter Country Code", "required")}</Col>
        </Row>
      </div>
    );
  }

  editCountryModalUI() {
    return (
      <div className={styles.editDamageModalBody}>
        <Row>
          <Col>{this.renderInput("country_name", "Country Name", "text", "Enter Country Name", "readOnly")}</Col>
        </Row>
        <Row>
          <Col>{this.renderInput("country_code", "Country Code", "text", "Enter Country Code", "readOnly")}</Col>
        </Row>
        <Row>
          <Col className={styles.editDamageModalStatus}>
            {this.renderInlineCheckBoxSet(
              "active",
              "Status",
              ["Active", "Inactive"],
              "radio",
              this.state.data.active ? "Active" : "Inactive"
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editDamageModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editDamageModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  handleSpecificChanges(input) {
    if (input.name === "active") {
      let { data } = this.state;
      if (input.value === "Inactive") {
        data.active = false;
      } else {
        data.active = true;
      }
      data.isCountryEdited = true;
      this.setState({ data });
    }
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createCountrytModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editCountryModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} Country</Modal.Title>
          </Modal.Header>
          <Modal.Body>{bodyContent}</Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button type="submit" className={globalStyles.ModalCreateButton} onClick={this.submitModal}>
              <span className={globalStyles.ModalCreateButtonText}>{submitButtonText}</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default CreateEditCountryModal;
