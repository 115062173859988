import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import { withRouter } from "react-router-dom";
import PropertiesDisplay from "./PropertiesDisplay";
import ShowHistoricalLossModal from "../Agreements/showHistoricalLossModal";
import { v1 as uuidv1 } from "uuid";
import UtilityFunctions from "../common/UtilityFunctions";

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cooperatorData: {},
      properties: this.props.step4Data.agreementProperties,
      species: this.props.step4Data.species,
      methods: this.props.step4Data.methods,
      resources: this.props.step4Data.protectedResources,
      specialInstructions: this.props.step4Data.specialInstructions,
      historicalResources: this.props.step4Data.historicalResourcesInventory,
      contacts: this.props.step4Data.contacts,
      agreementContactRoles: this.props.step4Data.agreementContactRoles,
      agreementTypes: this.props.step4Data.agreementTypes,
      agreementTypeEnumId: this.props.step4Data.agreementTypeEnumId,
      agreementProjects: this.props.step4Data.agreementProjects,
      propertySpecies: [],
      propertyMethods: [],
      propertyResources: [],
      propertyHistoricalResources: [],
      propertySpecialInstructions: [],
      propertyContacts: [],
      propertyLosses: [],
      isSpceciesSelected: false,
      isResourcesSelected: false,
      isMethodsSelected: false,
      isHistoricalResourcesSelected: false,
      isSpecialInstructions: false,
      isContacts: false,
      currentHistoricalRecord: "",
      showHistoricalLossModal: false,
      pageHeader: this.props.step4Data.pageHeader,
      landCLass: "",
      mapPoint: "",
      selectedRecommendations: this.props.step4Data.recommendations,
      selectedCEMs: this.props.step4Data.cooperatorEmployedMethods,
      selectedRecTableData: [],
      selectedCemTableData: [],
    };
    let speciesArray = [];
    let methodsArray = [];
    let resourcesArray = [];
    let historicalResourcesArray = [];
    let contactsArray = [];
    let instructionsArray = [];
    // let lossesArray = [];
    let data = this.state;

    let isMethodsSelected = false;
    let isResourcesSelected = false;
    let isSpceciesSelected = false;
    let isHistoricalResourcesSelected = false;
    let isSpecialInstructions = false;
    let isContacts = false;
    let parcelCount = 0;
    let tabKey = "Property1";

    const selectedProperties = JSON.parse(JSON.stringify(this.state.properties));

    for (var k = 0; k < selectedProperties.length; k++) {
      tabKey = "Property" + k;

      if (this.state.species.length > 0) {
        speciesArray = this.getSpeciesData(
          data.properties[k].propertyUniqueIdentifier
            ? data.properties[k].propertyUniqueIdentifier
            : data.properties[k].propertyUniqueReference,
          data.properties[k].propertyName
        );
        if (speciesArray.length > 0) isSpceciesSelected = true;
      }

      data.properties[k].propertySpecies = speciesArray;
      if (this.state.methods.length > 0) {
        methodsArray = this.getMethodsData(
          data.properties[k].propertyUniqueIdentifier
            ? data.properties[k].propertyUniqueIdentifier
            : data.properties[k].propertyUniqueReference,
          data.properties[k].propertyName
        );
        if (methodsArray.length > 0) isMethodsSelected = true;
      }
      data.properties[k].propertyMethods = methodsArray;
      if (this.state.resources.length > 0) {
        resourcesArray = this.getResourcesData(
          data.properties[k].propertyUniqueIdentifier,
          data.properties[k].propertyName
        );
        if (resourcesArray.length > 0) isResourcesSelected = true;
      }
      data.properties[k].propertyResources = resourcesArray;
      if (this.state.historicalResources.length > 0) {
        historicalResourcesArray = this.getHistoricalResourcesData(
          k,
          data.properties[k].propertyUniqueIdentifier
            ? data.properties[k].propertyUniqueIdentifier
            : data.properties[k].propertyUniqueReference,
          data.properties[k].propertyName
        );
        if (historicalResourcesArray.length > 0) isHistoricalResourcesSelected = true;
      }
      data.properties[k].propertyHistoricalResources = historicalResourcesArray;
      if (this.state.contacts.length > 0) {
        contactsArray = this.getContactsData(
          data.properties[k].propertyUniqueIdentifier
            ? data.properties[k].propertyUniqueIdentifier
            : data.properties[k].propertyUniqueReference,
          data.properties[k].propertyName
        );
        if (contactsArray.length > 0) isContacts = true;
      }
      data.properties[k].propertyContacts = contactsArray;
      if (this.state.specialInstructions.length > 0) {
        instructionsArray = this.getInstructionsData(
          data.properties[k].propertyUniqueIdentifier
            ? data.properties[k].propertyUniqueIdentifier
            : data.properties[k].propertyUniqueReference,
          data.properties[k].propertyName
        );
        if (instructionsArray.length > 0) isSpecialInstructions = true;
      }
      data.properties[k].parcels = data.properties[k].propertyObject
        ? data.properties[k].propertyObject?.parcels
        : data.properties[k]?.parcels;
      data.properties[k].parcelCount = data.properties[k].propertyObject
        ? data.properties[k].propertyObject?.parcelCount
        : data.properties[k]?.parcelCount;
      data.properties[k].propertySpecialInstructions = instructionsArray;
      data.properties[k].isMethodsSelected = isMethodsSelected;
      data.properties[k].isResourcesSelected = isResourcesSelected;
      data.properties[k].isSpceciesSelected = isSpceciesSelected;
      data.properties[k].isHistoricalResourcesSelected = isHistoricalResourcesSelected;

      data.properties[k].isSpecialInstructions = isSpecialInstructions;
      data.properties[k].isContacts = isContacts;
      data.properties[k].landClass = data.properties[k].propertyObject
        ? data.properties[k].propertyObject?.landClass?.displayText
        : data.properties[k].landClass?.displayText;
      data.properties[k].tabKey = tabKey;

      if (!data.properties[k].address) {
        let propertyAddress = this.getPropertyAddress(
          data.properties[k].propertyObject?.additionalStreetAddresses[0],
          data.properties[k].state
        );
        data.properties[k].address = propertyAddress;
      }

      data.properties[k].mapPoint = JSON.stringify([
        data.properties[k].propertyObject
          ? data.properties[k].propertyObject.latitude?.toString()
          : data.properties[k].latitude?.toString(),
        data.properties[k].propertyObject
          ? data.properties[k].propertyObject.longitude?.toString()
          : data.properties[k].longitude?.toString(),
      ]);

      //reset the flags
      isMethodsSelected = false;
      isResourcesSelected = false;
      isSpceciesSelected = false;
      isHistoricalResourcesSelected = false;
      isSpecialInstructions = false;
      isContacts = false;
    }

    this.setState({
      data,
    });
  }

  getPropertyAddress(address, state) {
    let propertyAddress = address?.streetAddress.concat(", ", address?.city, ", ", state, ", ", address?.zipCode);
    return propertyAddress;
  }

  async componentDidMount() {
    if (this.props.step4Data.primaryCooperatorUniqueIdentifier) {
      let getCooperatorData = await AgreementEntitiesAPI.GetContact(
        this.props.step4Data.primaryCooperatorUniqueIdentifier
      );
      this.setState({ cooperatorData: getCooperatorData.data });
    }

    const { properties } = this.state;
    properties.forEach((property) => {
      const selectedResourcesForProperty = this.state.resources?.filter(
        (r) =>
          r.checkedProperties?.length > 0 &&
          (r.checkedProperties[0].propertyUniqueIdentifier === property.propertyUniqueIdentifier ||
            r.checkedProperties[0].propertyUniqueReference === property.propertyUniqueIdentifier)
      );
      if (selectedResourcesForProperty?.length > 0) {
        property.isResourcesSelected = true;
        property.propertyResources = selectedResourcesForProperty.map((r) => ({
          resourceUniqueIdentifier: r.resourceUniqueIdentifier,
          resourceName: r.resourceName,
          resourceYear: r.year,
          resourceQuantity: r.quantity,
          resourceUom: r.unitOfMeasureName,
          resourceUnitValue: r.unitValue,
          resourceTotalValue: r.totalValue,
        }));
      }

      const historicalResourcesForProperty = this.state.historicalResources?.filter(
        (r) =>
          r.checkedProperties?.length > 0 &&
          (r.checkedProperties[0].propertyUniqueIdentifier === property.propertyUniqueIdentifier ||
            r.checkedProperties[0].propertyUniqueReference === property.propertyUniqueIdentifier)
      );
      if (historicalResourcesForProperty?.length > 0) {
        property.isHistoricalResourcesSelected = true;
        property.propertyHistoricalResources = historicalResourcesForProperty.map((r) => ({
          resourceUniqueIdentifier: r.resourceUniqueIdentifier,
          resourceName: r.resourceName,
          resourceYear: r.year,
          resourceQuantity: r.quantity,
          resourceUom: r.unitOfMeasureName,
          resourceUnitValue: r.unitValue,
          resourceTotalValue: r.totalValue,
          historicalLosses:
            r.historicalLosses?.length > 0
              ? r.historicalLosses.map((loss) => ({
                  resourceName: loss.resourceName,
                  quantity: loss.quantity,
                  UOM: loss.unitOfMeasureName,
                  percentage: loss.percentage,
                  totalValue: loss.totalValue,
                  species: loss.species?.label,
                }))
              : [],
        }));
      }
    });

    this.setState({
      selectedRecTableData: this.getRecCemTableData(),
      selectedCemTableData: this.getRecCemTableData(false),
      properties,
    });

    window.scrollTo(0, 0);
  }

  getRecCemTableData(isRecommendations = true) {
    const { selectedRecommendations, selectedCEMs } = this.state;
    const selectedRecCemData = isRecommendations ? selectedRecommendations : selectedCEMs;
    let selectedRecCemTableData = [];

    for (let recCem of selectedRecCemData) {
      recCem.checkedProperties.forEach((property) => {
        let propertyID = property.propertyUniqueIdentifier
          ? property.propertyUniqueIdentifier
          : property.propertyUniqueReference;
        const newTableId = `${recCem.recCemId}|${propertyID}`;
        if (!selectedRecCemTableData.find((r) => r.tableId === newTableId)) {
          selectedRecCemTableData.push({
            tableId: newTableId,
            recCemId: recCem.recCemId,
            recCemName: recCem.recCemName,
            property: {
              propertyUniqueIdentifier: propertyID,
              propertyName: property.propertyName,
            },
          });
        }
      });
    }
    return selectedRecCemTableData;
  }

  getInstructionsData(propertyUniqueIdentifier, propertyName) {
    let instructionsArray = [];
    const selectedInstructions = JSON.parse(JSON.stringify(this.state.specialInstructions));
    for (var k = 0; k < selectedInstructions.length; k++) {
      if (selectedInstructions[k].checkedProperties.length > 0) {
        let selectedInstruction = selectedInstructions[k];

        for (var i = 0; i < selectedInstruction.checkedProperties.length; i++) {
          if (selectedInstruction.checkedProperties[i].propertyUniqueIdentifier === propertyUniqueIdentifier) {
            let parcelStr = this.getParcelInfo(selectedInstruction.checkedProperties[i].parcels);

            instructionsArray.push({
              text: selectedInstruction.text,
              propertyName: propertyName,
              parcels: parcelStr,
            });
          }
        }
      }
    }
    return instructionsArray;
  }

  getSpeciesData(propertyUniqueIdentifier, propertyName) {
    let speciesArray = [];
    const selectedSpecies = JSON.parse(JSON.stringify(this.state.species));
    for (var k = 0; k < selectedSpecies.length; k++) {
      if (selectedSpecies[k].checkedProperties.length > 0) {
        let selecteSpecies = selectedSpecies[k];

        for (var i = 0; i < selecteSpecies.checkedProperties.length; i++) {
          let propertyID = selecteSpecies.checkedProperties[i].propertyUniqueIdentifier
            ? selecteSpecies.checkedProperties[i].propertyUniqueIdentifier
            : selecteSpecies.checkedProperties[i].propertyUniqueReference;
          if (propertyID === propertyUniqueIdentifier) {
            let parcelStr = this.getParcelInfo(selecteSpecies.checkedProperties[i].parcels);

            speciesArray.push({
              speciesName: selecteSpecies.name,
              propertyName: propertyName,
              parcels: parcelStr,
            });
          }
        }
      }
    }
    return speciesArray;
  }

  getMethodsData(propertyUniqueIdentifier, propertyName) {
    let methodsArray = [];
    const selectedMethods = JSON.parse(JSON.stringify(this.state.methods));
    for (var k = 0; k < selectedMethods.length; k++) {
      if (selectedMethods[k].checkedProperties.length > 0) {
        let selectedMethod = selectedMethods[k];

        for (var i = 0; i < selectedMethod.checkedProperties.length; i++) {
          let propertyID = selectedMethod.checkedProperties[i].propertyUniqueIdentifier
            ? selectedMethod.checkedProperties[i].propertyUniqueIdentifier
            : selectedMethod.checkedProperties[i].propertyUniqueReference;
          if (propertyID === propertyUniqueIdentifier) {
            let parcelStr = this.getParcelInfo(selectedMethod.checkedProperties[i].parcels);

            methodsArray.push({
              methodName: selectedMethod.methodName,
              propertyName: propertyName,
              parcels: parcelStr,
            });
          }
        }
      }
    }
    return methodsArray;
  }

  getResourcesData(propertyUniqueIdentifier, propertyName) {
    let resourcesArray = [];
    const selectedResources = JSON.parse(JSON.stringify(this.state.resources));
    for (var k = 0; k < selectedResources.length; k++) {
      if (selectedResources[k].checkedProperties.length > 0) {
        let selectedResource = selectedResources[k];

        for (var i = 0; i < selectedResource.checkedProperties.length; i++) {
          if (selectedResource.checkedProperties[i].propertyUniqueIdentifier === propertyUniqueIdentifier) {
            let parcelStr = this.getParcelInfo(selectedResource.checkedProperties[i].parcels);

            resourcesArray.push({
              resourceName: selectedResource.resourceName,
              resourceYear: selectedResource.year,
              resourceQuantity: selectedResource.quantity,
              resourceUom: selectedResource.unitOfMeasureName,
              resourceUnitValue: selectedResource.unitValue,
              resourceTotalValue: selectedResource.totalValue,
              propertyName: propertyName,
              parcels: parcelStr,
            });
          }
        }
      }
    }
    return resourcesArray;
  }

  getHistoricalResourcesData(p, propertyUniqueIdentifier, propertyName) {
    let resourcesArray = [];
    let lossesArray = [];
    let isLossesSelected = false;
    const selectedResources = JSON.parse(JSON.stringify(this.state.historicalResources));
    for (var k = 0; k < selectedResources.length; k++) {
      if (selectedResources[k].checkedProperties.length > 0) {
        let selectedResource = selectedResources[k];

        for (var i = 0; i < selectedResource.checkedProperties.length; i++) {
          if (selectedResource.checkedProperties[i].propertyUniqueIdentifier === propertyUniqueIdentifier) {
            let parcelStr = this.getParcelInfo(selectedResource.checkedProperties[i].parcels);

            resourcesArray.push({
              resourceUniqueIdentifier: selectedResource.resourceUniqueIdentifier,
              resourceName: selectedResource.resourceName,
              resourceYear: selectedResource.year,
              resourceQuantity: selectedResource.quantity,
              resourceUom: selectedResource.unitOfMeasureName,
              resourceUnitValue: selectedResource.unitValue,
              resourceTotalValue: selectedResource.totalValue,
              propertyName: propertyName,
              parcels: parcelStr,
              historicalLosses: selectedResource.historicalLosses,
            });
            if (selectedResource.historicalLosses) {
              for (var j = 0; j < selectedResource.historicalLosses.length; j++) {
                let selectedLoss = selectedResource.historicalLosses[j];
                lossesArray.push({
                  resourceName: selectedResource.resourceName,
                  quantity: selectedLoss.quantity,
                  UOM: selectedLoss.unitOfMeasureName,
                  percentage: selectedLoss.percentage,
                  totalValue: selectedLoss.totalValue,
                  species: selectedLoss.species.label,
                });
              }
            }
            //this.addLossesToProperties(propertyUniqueIdentifier, lossesArray);
          }
        }
      }
    }
    return resourcesArray;
  }

  /*
  addLossesToProperties(propertyUniqueIdentifier, lossesArray) {
    const selectedProperties = JSON.parse(JSON.stringify(this.state.properties));
    let data = this.state;
    for (var k = 0; k < selectedProperties.length; k++) {
      if (data.properties[k].propertyUniqueIdentifier === propertyUniqueIdentifier) {
        data.properties[k].propertyLosses = lossesArray;
        if (lossesArray.length > 0) this.state.properties[k].isLossesSelected = true;
        else this.state.properties[k].isLossesSelected = false;
      }
    }
  }
*/

  renameKey = (obj, oldKey, newKey) => {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  };

  goToDetails = (contact) => {
    this.props.history.push({
      pathname: "/contact/details",
      state: { entityData: contact },
    });
  };

  getHistoricalResourceLosses = (resource) => {
    let historicalLosses = [];
    let checkedProperties = [];
    let resourceLosses = [];
    let updatedCheckedProperties = [];
    const agreementResource = this.state.historicalResources.find(
      (r) => r.resourceUniqueIdentifier === resource.resourceUniqueIdentifier && r.year === resource.resourceYear
    );
    if (agreementResource) {
      checkedProperties = agreementResource.checkedProperties;
      updatedCheckedProperties = JSON.parse(JSON.stringify(checkedProperties));
      updatedCheckedProperties.forEach((obj) => this.renameKey(obj, "parcels", "checkedParcels"));
      if (agreementResource.historicalLosses) {
        historicalLosses = historicalLosses.concat(
          agreementResource.historicalLosses.map((loss) => ({
            historicalLossUniqueIdentifier: uuidv1(),
            resourceName: agreementResource.resourceName,
            lossQuantity: loss.quantity,
            unitOfMeasureName: loss.unitOfMeasureName,
            causingSpeciesName: loss.species.label,
            totalValuation: loss.totalValue,
            lossPercentage: loss.percentage,
          }))
        );
      }
      resourceLosses = {
        resourceTableId: uuidv1(),
        resourceUniqueReference: resource.resourceUniqueIdentifier,
        resourceName: resource.resourceName,
        quantity: Number.parseFloat(resource.resourceQuantity),
        valuationUnitOfMeasure: { name: resource.resourceUom },
        valuePerUnitOfMeasure: Number.parseFloat(resource.resourceUnitValue),
        totalValuation: Number.parseFloat(resource.resourceTotalValue),
        valuationYear: Number.parseInt(resource.resourceYear),
        historicalLossRecords: historicalLosses,
        checkedProperties: updatedCheckedProperties,
      };
    }

    // let resourceDetails = {checkedProperties:updatedCheckedProperties, historicalLosses:historicalLosses }
    return resourceLosses;
  };

  viewLossModal = (resource) => {
    let resourceLosses = this.getHistoricalResourceLosses(resource);
    this.setState({ currentHistoricalRecord: resourceLosses, showHistoricalLossModal: true });
  };

  closeHistoricalLossModal = () => {
    this.setState({ currentHistoricalRecord: "", showHistoricalLossModal: false });
  };

  getContactsData(propertyUniqueIdentifier, propertyName) {
    let contactsArray = [];
    const selectedContacts = JSON.parse(JSON.stringify(this.state.contacts));
    for (var k = 0; k < selectedContacts.length; k++) {
      let selectedContact = selectedContacts[k];
      let agreementRole = "";
      if (selectedContact.contactRoleEnumId !== "") {
        let agreementRoleLookup = this.state.agreementContactRoles.find(
          (role) => role.id === parseInt(selectedContact.contactRoleEnumId)
        );
        if (agreementRoleLookup) {
          agreementRole = agreementRoleLookup.displayText;
        }
      }
      let phoneNumber = "";
      if (selectedContact.phoneNumbers) {
        if (selectedContact.phoneNumbers.length > 0) {
          phoneNumber = UtilityFunctions.formatPhoneNumber(selectedContact.phoneNumbers[0].phoneNumber);
        }
      }
      contactsArray.push({
        contactName: selectedContact.contactName,
        phoneNumber: phoneNumber,
        emailAddress: selectedContact.emailAddress,
        agreementRole: agreementRole,
        contactObject: selectedContact,
        //parcels:parcelStr
      });
    }
    return contactsArray;
  }

  handleTabClick(tab) {
    // this.setState({ tabKey: tab });
  }
  handleClickEdit = (step) => {
    this.props.passDataUp("step", step);
  };

  getParcelInfo(selectedParcels) {
    let parcelStr = [];

    for (var k = 0; k < selectedParcels.length; k++) {
      parcelStr.push(selectedParcels[k].parcelName);
    }

    return parcelStr.join(",");
  }

  cooperatorInformationCard = () => {
    let displayPhoneNumber = "";
    let displayAddress = "";
    if (this.state.cooperatorData.phoneNumbers.length > 0) {
      displayPhoneNumber = UtilityFunctions.formatPhoneNumber(this.state.cooperatorData.phoneNumbers[0].phoneNumber);
    }
    if (this.state.cooperatorData.primaryMailingAddress) {
      let state = "";
      const address = this.state.cooperatorData.primaryMailingAddress;
      for (let i = 0; i < this.props.step4Data.states.length; i++) {
        if (this.props.step4Data.states[i].id === address.mailingStateUniqueId) {
          state = this.props.step4Data.states[i].name;
          break;
        }
      }
      displayAddress =
        (address.mailingStreet || address.street || address.streetAddress) +
        ", " +
        (address.mailingCity || address.city) +
        ", " +
        state +
        " " +
        (address.mailingZip || address.zipCode);
    }
    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col lg={6}> Cooperator Information</Col>
            <Col>
              <div align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.handleClickEdit(1)}
                >
                  Edit
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className={styles.step4RowSpacing}>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Cooperator Name</h6>
              <p className={globalStyles.informationText}>
                {this.props.step4Data.primaryCooperatorName ? this.props.step4Data.primaryCooperatorName : ""}
              </p>
            </Col>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Phone Number</h6>
              <p className={globalStyles.informationText}>{displayPhoneNumber ? displayPhoneNumber : ""}</p>
            </Col>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Email</h6>
              <p className={globalStyles.informationText}>
                {this.state.cooperatorData.emailAddress ? this.state.cooperatorData.emailAddress : ""}
              </p>
            </Col>
          </Row>
          <Row className={styles.step4RowSpacing}>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Address</h6>
              <p className={globalStyles.informationText}>{displayAddress ? displayAddress : ""}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  fieldAgentInformationCard = () => {
    let displayAddress = "";
    let displayPhoneNumber = UtilityFunctions.formatPhoneNumber(
      this.props.step4Data.primaryFieldAgentPhoneNumber ? this.props.step4Data.primaryFieldAgentPhoneNumber : ""
    );

    if (this.props.step4Data.primaryFieldAgentMailingAddress) {
      let state = "";
      const address = this.props.step4Data.primaryFieldAgentMailingAddress;
      for (let i = 0; i < this.props.step4Data.states.length; i++) {
        if (this.props.step4Data.states[i].id === address.mailingStateUniqueId) {
          state = this.props.step4Data.states[i].name;
          break;
        }
      }
      displayAddress =
        (address.mailingStreet || address.street || address.streetAddress) +
        ", " +
        (address.mailingCity || address.city) +
        ", " +
        state +
        " " +
        (address.mailingZip || address.zipCode);
    }
    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col lg={6}>WS Specialists Information</Col>
            <Col>
              <div align="right">
                {" "}
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.handleClickEdit(1)}
                >
                  Edit
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row Row className={styles.step4RowSpacing}>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Primary WS Specialist Name</h6>
              <p className={globalStyles.informationText}>
                {this.props.step4Data.primaryFieldAgentName ? this.props.step4Data.primaryFieldAgentName : ""}
              </p>
            </Col>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Phone Number</h6>
              <p className={globalStyles.informationText}>{displayPhoneNumber ? displayPhoneNumber : ""}</p>
            </Col>
            <Col align="left">
              <h6 className={styles.fieldLabels}>State Office</h6>
              <p className={globalStyles.informationText}>{displayAddress ? displayAddress : ""}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  projectInformationCard = () => {
    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col lg={6}>Project Information</Col>
            <Col>
              <div align="right">
                {" "}
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.handleClickEdit(1)}
                >
                  Edit
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {this.state.agreementProjects?.map((project, index) => {
            return (
              <div key={index}>
                <Row>
                  <Col align="left" lg={2}>
                    <p className={globalStyles.informationText}>{project.label}</p>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Card.Body>
      </Card>
    );
  };

  agreementInformationCard = () => {
    let displayAgreementType = "";
    if (this.state.agreementTypes.length > 0) {
      for (let i = 0; i < this.state.agreementTypes.length; i++) {
        if (parseInt(this.state.agreementTypes[i].id) === parseInt(this.state.agreementTypeEnumId)) {
          displayAgreementType = this.state.agreementTypes[i].displayText;
          break;
        }
      }
    }

    return (
      <Card className={styles.createPageCards}>
        <Card.Header as="h4" className={styles.cardTitles}>
          <Row>
            <Col lg={6}>Agreement Information</Col>
            <Col>
              <div align="right">
                {" "}
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.handleClickEdit(1)}
                >
                  Edit
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className={styles.step4RowSpacing}>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Agreement Number</h6>
              <p className={globalStyles.informationText}>{this.props.step4Data.agreementNumber}</p>
            </Col>
          </Row>
          <Row className={styles.step4RowSpacing}>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Agreement Common Name</h6>
              <p className={globalStyles.informationText}>{this.props.step4Data.agreementCommonName}</p>
            </Col>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Agreement Type</h6>
              <p className={globalStyles.informationText}>{displayAgreementType}</p>
            </Col>
            <Col align="left">
              <h6 className={styles.fieldLabels}>Agreement Renewal Status</h6>
              <p className={globalStyles.informationText}>New</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  render() {
    let cooperatorCard = null;
    let fieldAgentsCard = null;
    if (!this.state.cooperatorData.contactUniqueId) {
      cooperatorCard = (
        <Card className={styles.createPageCards} hidden={this.state.agreementTypeEnumId === 2}>
          <Card.Header as="h4" className={styles.cardTitles}>
            <Row>
              <Col lg={6}>Cooperator Information</Col>
              <Col>
                <div align="right">
                  {" "}
                  <Button
                    className={styles.addButtonLinksInHeader}
                    variant="link"
                    onClick={() => this.handleClickEdit(1)}
                  >
                    Edit
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row className={styles.step4RowSpacing}>
              <Col align="left">
                <h6 className={styles.fieldLabels}>Cooperator Name</h6>
              </Col>
              <Col align="left">
                <h6 className={styles.fieldLabels}>Phone Number</h6>
              </Col>
              <Col align="left">
                <h6 className={styles.fieldLabels}>Email</h6>
              </Col>
            </Row>
            <Row className={styles.step4RowSpacing}>
              <Col align="left">
                <h6 className={styles.fieldLabels}>Address</h6>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    } else {
      cooperatorCard = <this.cooperatorInformationCard />;
    }
    if (!this.props.step4Data.primaryFieldAgentUniqueIdentifier) {
      fieldAgentsCard = (
        <Card className={styles.createPageCards}>
          <Card.Header as="h4" className={styles.cardTitles}>
            <Row>
              <Col lg={6}>WS Specialists Information</Col>
              <Col>
                <div align="right">
                  {" "}
                  <Button
                    className={styles.addButtonLinksInHeader}
                    variant="link"
                    onClick={() => this.handleClickEdit(1)}
                  >
                    Edit
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row Row className={styles.step4RowSpacing}>
              <Col align="left">
                <h6 className={styles.fieldLabels}>Primary WS Specialist Name</h6>
              </Col>
              <Col align="left">
                <h6 className={styles.fieldLabels}>Phone Number</h6>
              </Col>
              <Col align="left">
                <h6 className={styles.fieldLabels}>State Office</h6>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    } else {
      fieldAgentsCard = <this.fieldAgentInformationCard />;
    }

    return (
      <div className={styles.Step1} data-testid="Step4">
        <h5 className={styles.pageTitles}>{this.state.pageHeader}</h5>
        <this.agreementInformationCard />
        {fieldAgentsCard}
        {cooperatorCard}
        <this.projectInformationCard />
        <PropertiesDisplay
          properties={this.state.properties}
          agreementTypeEnumId={this.state.agreementTypeEnumId}
          handleTabClick={this.handleTabClick}
          handleClickEdit={this.handleClickEdit}
          viewLossModal={this.viewLossModal}
          contacts={this.state.contacts}
          goToDetails={this.goToDetails}
          recCemData={{
            selectedRecTableData: this.state.selectedRecTableData,
            selectedCemTableData: this.state.selectedCemTableData,
          }}
        />
        <ShowHistoricalLossModal
          show={this.state.showHistoricalLossModal}
          onHide={this.closeHistoricalLossModal}
          historicalResourceData={this.state.currentHistoricalRecord}
        />
      </div>
    );
  }
}

export default withRouter(Step4);

