import React from "react";
import { Modal, Row, Col, Form, Button, CloseButton } from "react-bootstrap";
import styles from "./PropertyVisitSummary.module.scss";
import globalStyles from "../../../../OARS.module.scss";
import CustomForm from "../../../common/form";
import { toast } from "react-toastify";

class EnterSampleModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        totalAnimalsSampled: 0,
        isOtherSampling: false,
        rabiesSamplingQty: 0,
        diseaseSamplingQty: 0,
        fromRecordSample: true,
      },
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    this.validateStateData(currentUsaState);

    this.setState({
      currentUsaState,
    });
  }

  validateStateData(currentUsaState) {
    if (!currentUsaState) {
      toast.warning(
        "Failed to load current USA State info. Please go back to the home page(or previous page) and re-visit the Conflict windows from there."
      );
    }
  }

  handleSpecificChanges() {
    // do something
  }

  isFormDataValid() {
    const { data } = this.state;
    return (
      parseInt(data.totalAnimalsSampled) > 0 &&
      (data.isOtherSampling || data.rabiesSamplingQty || data.diseaseSamplingQty) &&
      parseInt(data.totalAnimalsSampled) >= parseInt(data.rabiesSamplingQty) + parseInt(data.diseaseSamplingQty)
    );
  }

  doSubmit() {
    if (!this.isFormDataValid()) {
      toast.warning(
        "At least one sample must be entered and the total Number of Animals Sampled should be bigger than the specific quantities for each type."
      );
      return;
    }

    this.props.onHide();
    const { data } = this.state;
    this.props.onSave(data);
    this.clearData(data);
    this.setState({ data });
  }

  handleModalCancel = () => {
    const { data } = this.state;
    this.props.onHide();
    this.clearData(data);
    this.setState({ data });
  };

  handleOtherSelection = ({ currentTarget: input }) => {
    const { data } = this.state;
    data.isOtherSampling = input.checked;
    this.setState({ data });
  };

  clearData(data) {
    data.totalAnimalsSampled = 0;
    data.isOtherSampling = false;
    data.rabiesSamplingQty = 0;
    data.diseaseSamplingQty = 0;
  }

  render() {
    const { data } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        dialogClassName={styles.modalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={globalStyles.modalHeader}>
            <Row className={globalStyles.modalHeaderRow}>
              <Col className="px-0">
                <Modal.Title className={globalStyles.modalTitleText}>Enter Sample</Modal.Title>
              </Col>
              <Col>
                <CloseButton onClick={this.handleModalCancel} />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <Row className="my-3  mx-0">
              <Col>
                <span className={styles.formLabelTitle}>Enter Sample</span>
              </Col>
            </Row>
            <Row className="my-1 mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>Number of Animals Sampled</Form.Label>
                  <Form.Control
                    name="totalAnimalsSampled"
                    placeholder="Qty"
                    value={data.totalAnimalsSampled}
                    min="0"
                    type="number"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2 mt-1 mx-0">
              <Col className="text-left">
                <Form.Check type="checkbox" label="Other" onChange={this.handleOtherSelection} />
              </Col>
            </Row>
            <Row className="my-1 mx-0">
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>NRMP</Form.Label>
                  <Form.Control
                    name="rabiesSamplingQty"
                    placeholder="Qty"
                    value={data.rabiesSamplingQty}
                    min="0"
                    type="number"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className={globalStyles.formLabel}>WS Disease Monitoring</Form.Label>
                  <Form.Control
                    name="diseaseSamplingQty"
                    placeholder="Qty"
                    value={data.diseaseSamplingQty}
                    min="0"
                    type="number"
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" type="submit">
              <span className={globalStyles.modalSubmitButtonText}>Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EnterSampleModal;
