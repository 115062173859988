import React from "react";
import styles from "./ReferenceFiles.module.scss";
import globalStyles from "../../OARS.module.scss";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, Button, Form, Row, Col, Nav, Modal, Image } from "react-bootstrap";
import SpeciesTab from "./SpeciesTab";
import MethodTab from "./MethodTab";
import ResourcesTab from "./ResourcesTab";
import FateTab from "./FateTab";
import DamageTab from "./DamageTab";
import DiseaseTab from "./DiseaseTab";
import OrganizationTab from "./OrganizationTab";
import RECCEMsTab from "./RECCEMsTab";
import AircraftTab from "./AircraftTab";
import TAActivityTab from "./TAActivityTab";
import AdminWorkTaskActivityTab from "./AdminWorkTaskActivityTab";
import LoadingOverlay from "react-loading-overlay";
import CountriesTab from "./CountriesTab";
import TopicsTab from "./TopicsTab";
import { toast } from "react-toastify";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import IntermediateFateTab from "./IntermediateFateTab";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";

class ReferenceFiles extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    var defaultTab = "aircraftTab";
    var defaultData = false;
    if (this.props.history.location.state) {
      defaultTab = this.props.history.location.state.tab;
      defaultData = this.props.history.location.state.globalData ? true : false;
    }
    this.state = {
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
      history: this.props.history,
      currentTab: defaultTab,
      useGlobalData: defaultData,
      disableGlobalDataSwitch: false,
      isLoading: true,
      favorites: [
        {
          name: "Damage Agent",
          singularName: "Damage Agent",
          eventKey: "favoriteSpecies",
          favoriteList: [],
          modalChanges: [],
        },
        { name: "Methods", singularName: "Method", eventKey: "favoriteMethods", favoriteList: [], modalChanges: [] },
        {
          name: "Resources",
          singularName: "Resource",
          eventKey: "favoriteResources",
          favoriteList: [],
          modalChanges: [],
        },
      ],
      showFavoritesModal: false,
      isFavoritesDataLoaded: false,
    };
  }

  async componentDidMount() {
    let { disableGlobalDataSwitch, useGlobalData, favorites } = this.state;
    if (!this.props.permissions?.canIViewNationalRefData()) {
      disableGlobalDataSwitch = true;
      useGlobalData = false;
    }

    this.setState({ disableGlobalDataSwitch, useGlobalData, favorites }, () =>
      this.handleTabClick(this.state.currentTab)
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentTab !== this.state.currentTab && !this.props.permissions?.canIViewNationalRefData()) {
      this.setState({ disableGlobalDataSwitch: true, useGlobalData: false });
    }

    if (!this.state.isFavoritesDataLoaded && this.props.userData) {
      await this.updateFavoritesData();
    }
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("ReferenceFiles.jsx got unmounted");
    }
  }

  async updateFavoritesData() {
    const { favorites } = this.state;

    await this.updateFavoritesSpeciesData(favorites);
    await this.updateFavoritesMethodsData(favorites);
    await this.updateFavoritesResourcesData(favorites);

    this.setState({ favorites, isFavoritesDataLoaded: true });
  }

  async updateFavoritesSpeciesData(favorites) {
    const { userData } = this.props;
    if (userData?.preferredSpeciesUniqueReferences?.length > 0) {
      const favoriteSpecies = favorites.find((f) => f.name === "Damage Agent");
      if (favoriteSpecies) {
        const resolveSpeciesResponse = await ReferenceFileAPI.ResolveSpecies(userData.preferredSpeciesUniqueReferences);
        if (resolveSpeciesResponse?.successful && resolveSpeciesResponse.data?.length > 0) {
          favoriteSpecies.favoriteList = userData.preferredSpeciesUniqueReferences.map((speciesId) => ({
            speciesUniqueIdentifier: speciesId,
            name: resolveSpeciesResponse.data.find((s) => s.speciesUniqueIdentifier === speciesId)?.name,
          }));
        }
      }
    }
  }

  async updateFavoritesMethodsData(favorites) {
    const { userData } = this.props;
    if (userData?.preferredMethodsUniqueReferences?.length > 0) {
      const favoriteMethods = favorites.find((f) => f.name === "Methods");
      if (favoriteMethods) {
        const resolveMethodsResponse = await ReferenceFileAPI.ResolveMethods(userData.preferredMethodsUniqueReferences);
        if (resolveMethodsResponse?.successful && resolveMethodsResponse.data?.length > 0) {
          favoriteMethods.favoriteList = userData.preferredMethodsUniqueReferences.map((methodId) => ({
            methodUniqueIdentifier: methodId,
            name: resolveMethodsResponse.data.find((m) => m.methodUniqueIdentifier === methodId)?.methodName,
          }));
        }
      }
    }
  }

  async updateFavoritesResourcesData(favorites) {
    const { userData } = this.props;
    if (userData?.preferredResourcesUniqueReferences?.length > 0) {
      const favoriteResources = favorites.find((f) => f.name === "Resources");
      if (favoriteResources) {
        const resolveResourcesResponse = await ReferenceFileAPI.ResolveResources(
          userData.preferredResourcesUniqueReferences
        );
        if (resolveResourcesResponse?.successful && resolveResourcesResponse.data?.length > 0) {
          favoriteResources.favoriteList = userData.preferredResourcesUniqueReferences.map((resourceId) => ({
            resourceUniqueIdentifier: resourceId,
            name: resolveResourcesResponse.data.find((r) => r.resourceUniqueIdentifier === resourceId)?.name,
          }));
        }
      }
    }
  }

  static getDerivedStateFromProps() {
    return {
      currentUsaState: JSON.parse(localStorage.getItem("currentState")),
    };
  }

  renderFavoritesModal = () => {
    return (
      <Modal
        show={this.state.showFavoritesModal}
        onHide={this.closeFavoritesModal}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header className={globalStyles.modalHeader} closeButton>
          <Modal.Title className={globalStyles.modalTitleText}>Manage Favorites</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey={this.state.favorites[0].eventKey}>
            {this.state.favorites.map((favoriteObj) => {
              return (
                <Tab eventKey={favoriteObj.eventKey} title={`${favoriteObj.name} (${favoriteObj.modalChanges.length})`}>
                  <Row className={styles.favoriteNameHeader}>
                    <Col align="left" className={styles.favoriteNameHeaderCol}>
                      {favoriteObj.singularName} Name
                    </Col>
                  </Row>
                  {favoriteObj.modalChanges.map((f, index) => {
                    const favoriteName = f.name || f.methodName;
                    return (
                      <Row className={styles.favoriteMemberRow}>
                        <Col align="left" className={styles.favoriteMemberCol}>
                          {favoriteName}
                        </Col>
                        <Col align="right" className={styles.favoriteMemberColRight}>
                          <Row>
                            <Button
                              variant="link"
                              onClick={() => this.removeFavorite(favoriteObj.name, index)}
                              aria-label={`Remove ${favoriteName} from favorites`}
                            >
                              Remove
                            </Button>
                            <button
                              className={styles.moveFavoritesArrowUp}
                              aria-label={`Move favorite ${favoriteName} up`}
                              onClick={() => this.moveFavoriteUp(favoriteObj.name, index)}
                            />
                            <button
                              className={styles.moveFavoritesArrowDown}
                              aria-label={`Move favorite ${favoriteName} down`}
                              onClick={() => this.moveFavoriteDown(favoriteObj.name, index)}
                            />
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </Tab>
              );
            })}
          </Tabs>
        </Modal.Body>
        <Modal.Footer className={globalStyles.modalFooter}>
          <Button className={globalStyles.modalCancelButton} onClick={this.closeFavoritesModal}>
            <span className={globalStyles.modalCancelButtonText}>Cancel</span>
          </Button>
          <Button type="submit" className={globalStyles.modalSubmitButton} onClick={this.saveFavoritesModal}>
            <span className={globalStyles.modalSubmitButtonText}>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  removeFavorite = (favoriteName, index) => {
    let favorites = this.state.favorites;
    let currentFavoriteObj = favorites.find((f) => f.name === favoriteName);
    currentFavoriteObj.modalChanges.splice(index, 1);
    this.setState({ favorites });
  };

  moveFavoriteUp = (favoriteName, index) => {
    if (index === 0) {
      toast.error("Can't move favorite up any further.");
      return;
    }
    let favorites = this.state.favorites;
    let currentFavoriteObj = favorites.find((f) => f.name === favoriteName);
    let currentModalChanges = currentFavoriteObj.modalChanges;
    let itemToMoveUp = currentModalChanges[index];
    let itemToMoveDown = currentModalChanges[index - 1];
    currentModalChanges[index] = itemToMoveDown;
    currentModalChanges[index - 1] = itemToMoveUp;
    this.setState({ favorites });
  };

  moveFavoriteDown = (favoriteName, index) => {
    let favorites = this.state.favorites;
    let currentFavoriteObj = favorites.find((f) => f.name === favoriteName);
    let currentModalChanges = currentFavoriteObj.modalChanges;
    if (index + 1 === currentModalChanges.length) {
      toast.error("Can't move favorite down any further.");
      return;
    }
    let itemToMoveDown = currentModalChanges[index];
    let itemToMoveUp = currentModalChanges[index + 1];
    currentModalChanges[index] = itemToMoveUp;
    currentModalChanges[index + 1] = itemToMoveDown;
    this.setState({ favorites });
  };

  openFavoritesModal = () => {
    let favorites = this.state.favorites;
    favorites.forEach((favoriteObj) => {
      favoriteObj.modalChanges = JSON.parse(JSON.stringify(favoriteObj.favoriteList));
    });
    this.setState({ favorites, showFavoritesModal: true });
  };

  closeFavoritesModal = () => {
    let favorites = this.state.favorites;
    favorites.forEach((favoriteObj) => {
      favoriteObj.modalChanges = [];
    });
    this.setState({ favorites, showFavoritesModal: false });
  };

  saveFavoritesModal = async () => {
    this.setState({ isLoading: true });
    let favorites = this.state.favorites;
    let speciesUniqueReferences = [];
    let methodUniqueReferences = [];
    let resourceUniqueReferences = [];

    favorites.forEach((favoriteObj) => {
      favoriteObj.favoriteList = JSON.parse(JSON.stringify(favoriteObj.modalChanges));
      if (favoriteObj.favoriteList?.length > 0) {
        if (favoriteObj?.name === "Damage Agent") {
          speciesUniqueReferences = favoriteObj.favoriteList.map((f) => f.speciesUniqueIdentifier);
        }
        if (favoriteObj?.name === "Methods") {
          methodUniqueReferences = favoriteObj.favoriteList.map((f) => f.methodUniqueIdentifier);
        }
        if (favoriteObj?.name === "Resources") {
          resourceUniqueReferences = favoriteObj.favoriteList.map((f) => f.resourceUniqueIdentifier);
        }
      }
    });

    this.setState({ favorites, showFavoritesModal: false });

    const userData = this.props.userData;
    const saveFavoritesResponse = await RoleAuthorizationAPI.SetUserFavoriteReferenceFileData(userData?.userPrinciple, {
      speciesUniqueReferences,
      methodUniqueReferences,
      resourceUniqueReferences,
    });
    if (saveFavoritesResponse?.successful && saveFavoritesResponse.data) {
      const updatedUserData = saveFavoritesResponse.data;
      userData.preferredSpeciesUniqueReferences = updatedUserData.preferredSpeciesUniqueReferences;
      userData.preferredMethodsUniqueReferences = updatedUserData.preferredMethodsUniqueReferences;
      userData.preferredResourcesUniqueReferences = updatedUserData.preferredResourcesUniqueReferences;
      toast.success("Favorites saved.");
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve property's conflicts",
        apiName: "GetConflictsByProperty",
        responseUnsuccessful: saveFavoritesResponse?.unsuccessful,
        responseMessage: saveFavoritesResponse?.message,
      });
    }
    this.setState({ isLoading: false });
  };

  async handleTabClick(tab) {
    if (tab === "fateTab" || tab === "countryTab" || tab === "taActivityTab") {
      let element = document.getElementById("global-data-switch");
      element.checked = true;
      this.setState({ useGlobalData: true, disableGlobalDataSwitch: true });
    } else if (tab === "aircraftTab" || tab === "organizationTab") {
      let element = document.getElementById("global-data-switch");
      element.checked = false;
      this.setState({ useGlobalData: false, disableGlobalDataSwitch: true });
    } else {
      this.setState({ disableGlobalDataSwitch: false });
    }
    this.setState({ currentTab: tab }, () => {
      this.props.history.push({
        state: {
          tab: this.state.currentTab,
        },
      });
    });
  }

  setIsLoading = (loading) => {
    this.setState({ isLoading: loading });
  };

  render() {
    const { userData } = this.props;
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            marginLeft: "-15px",
            marginTop: "-15px",
            width: "110%",
            height: "110%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
        text={this.state.useGlobalData ? "Loading Global Data" : "Loading State Data"}
      >
        <div className="mx-5 mt-4" data-testid="ReferenceFiles">
          <h1 className={styles.refFilesPageTitle}>
            {this.state.useGlobalData
              ? "Reference Files - National"
              : "Reference Files - " + this.state.currentUsaState.stateName}
          </h1>
          <Tab.Container id="referenceFilesTabs" defaultActiveKey={this.state.currentTab}>
            <Row className="mt-4">
              <Col lg={9}>
                <Nav variant="pills">
                  <Nav.Item className={styles.navItemLeft}>
                    <Nav.Link
                      eventKey="aircraftTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkLeft}
                    >
                      Aircraft
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="adminWorkTaskActivityTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Admin Activities
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="countryTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Countries
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="damageTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Damage
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="speciesTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Damage Agent
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="diseaseTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Disease
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="fateTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Fate
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="intermediateFateTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Intermediate Fate
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="methodTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Methods
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="organizationTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Organization
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="recCemsTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      REC/CEMs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="resourcesTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      Resources
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemMiddle}>
                    <Nav.Link
                      eventKey="taActivityTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkMiddle}
                    >
                      TA Activity
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={styles.navItemRight}>
                    <Nav.Link
                      eventKey="topicsTab"
                      onSelect={(e) => this.handleTabClick(e)}
                      className={styles.navLinkRight}
                    >
                      Topic
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col
                align="right"
                hidden={
                  this.state.currentTab === "organizationTab" ||
                  this.state.currentTab === "adminWorkTaskActivityTab" ||
                  this.state.currentTab === "countryTab" ||
                  this.state.currentTab === "aircraftTab" ||
                  this.state.currentTab === "damageTab" ||
                  this.state.currentTab === "diseaseTab" ||
                  this.state.currentTab === "recCemsTab" ||
                  this.state.currentTab === "intermediateFateTab" ||
                  this.state.currentTab === "fateTab" ||
                  this.state.currentTab === "taActivityTab" ||
                  this.state.currentTab === "topicsTab" ||
                  (this.state.currentTab === "speciesTab" && !this.state.useGlobalData) ||
                  (this.state.currentTab === "methodTab" && !this.state.useGlobalData) ||
                  (this.state.currentTab === "resourcesTab" && !this.state.useGlobalData)
                }
              >
                <Button variant="link" onClick={this.openFavoritesModal}>
                  Manage Favorites
                </Button>
              </Col>
              <Col align="right">
                <div className={styles.globalDataSwitchContainer}>
                  <Form.Check
                    label={
                      <span style={this.state.disableGlobalDataSwitch ? { color: "#717171" } : {}}>Global Data</span>
                    }
                    className={styles.globalDataSwitch}
                    disabled={this.state.disableGlobalDataSwitch}
                    type="switch"
                    id="global-data-switch"
                    title="Global Data Switch"
                    aria-label="Switch to enable and disable viewing of Global Reference File Data"
                    checked={this.state.useGlobalData}
                    onChange={() => {
                      this.setState({ useGlobalData: !this.state.useGlobalData });
                      this.props.history.push({
                        state: {
                          tab: this.state.currentTab,
                          globalData: this.state.useGlobalData,
                        },
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="speciesTab">
                    {this.state.currentTab === "speciesTab" && (
                      <SpeciesTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                        favoriteSpecies={this.state.favorites.find((f) => f.name === "Damage Agent")?.favoriteList}
                        userData={userData}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="methodTab">
                    {this.state.currentTab === "methodTab" && (
                      <MethodTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                        favoriteMethods={this.state.favorites.find((f) => f.name === "Methods")?.favoriteList}
                        userData={userData}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="resourcesTab">
                    {this.state.currentTab === "resourcesTab" && (
                      <ResourcesTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                        favoriteResources={this.state.favorites.find((f) => f.name === "Resources")?.favoriteList}
                        userData={userData}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="fateTab">
                    {this.state.currentTab === "fateTab" && (
                      <FateTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="intermediateFateTab">
                    {this.state.currentTab === "intermediateFateTab" && (
                      <IntermediateFateTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="damageTab">
                    {this.state.currentTab === "damageTab" && (
                      <DamageTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="diseaseTab">
                    {this.state.currentTab === "diseaseTab" && (
                      <DiseaseTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="organizationTab">
                    {this.state.currentTab === "organizationTab" && (
                      <OrganizationTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="recCemsTab">
                    {this.state.currentTab === "recCemsTab" && (
                      <RECCEMsTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="aircraftTab">
                    {this.state.currentTab === "aircraftTab" && (
                      <AircraftTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="taActivityTab">
                    {this.state.currentTab === "taActivityTab" && (
                      <TAActivityTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="adminWorkTaskActivityTab">
                    {this.state.currentTab === "adminWorkTaskActivityTab" && (
                      <AdminWorkTaskActivityTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="countryTab">
                    {this.state.currentTab === "countryTab" && (
                      <CountriesTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="topicsTab">
                    {this.state.currentTab === "topicsTab" && (
                      <TopicsTab
                        currentUsaState={this.state.currentUsaState}
                        useGlobalData={this.state.useGlobalData}
                        permissions={this.props.permissions}
                        setIsLoading={this.setIsLoading}
                        currentTab={this.state.currentTab}
                      />
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          {this.state.showFavoritesModal && this.renderFavoritesModal()}
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(ReferenceFiles);
