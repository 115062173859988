import React, { Component } from "react";
import { withRouter } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, customFilter, selectFilter } from "react-bootstrap-table2-filter";
import { Card, Row, Col, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import styles from "../../Agreements/Agreement.module.scss";
import ReassignFieldAgentModal from "../Agreements/ReassignFieldAgentModal";
import globalStyles from "../../../OARS.module.scss";
import UtilityFunctions from "../../../components/common/UtilityFunctions";
import AgreementEntitiesAPI from "../../../api/AgreementEntities/AgreementEntitiesAPI";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../../api/AgreementEntities/AgreementAPI";
import CustomTextFilter from "../../common/customTextFilter";
import { toast } from "react-toastify";
import StructuredSearchCard from "../../dashboard/StructuredSearchCard";
import WorkTaskAPI from "../../../api/WorkTask/WorkTaskAPI";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ExportCSVButton from "../../ReferenceFiles/ExportCSVButton";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import RoleAuthorizationAPI from "../../../api/RoleAuthorization/RoleAuthorizationAPI";

const SearchTypes = {
  AdminWorkTask: "adminWorkTask",
  Aerials: "aerials",
  Agreements: "agreement",
  Contacts: "contact",
  Permits: "permit",
  Project: "project",
  Property: "property",
  Samples: "sample",
  SiteVisit: "siteVisit",
  TAWorkTask: "taWorkTask",
  District: "district",
};

const TAWorkTaskTypes = {
  Hotline: "hotline",
  PublicOutreach: "publicOutreach",
  IndividualCooperator: "individualCooperator",
};

const SampleTypes = {
  NRMP: "nrmp",
  DiseaseMonitoring: "diseaseMonitoring",
  Other: "other",
};

class SearchAgreements extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      searchResultsData: [],
      entityType: "",
      searchData: "",
      tableKeyField: "id",
      usaStates: [],
      countries: [],
      contacts: [],
      selectedAgreements: [],
      columns: [
        { dataField: "id", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      isDataLoading: true,
      showReassignFAModal: false,
      pagedResultAPIContainer: {
        currentPage: 0,
        currentPageRecordFrom: 0,
        currentPageRecordTo: 0,
        pageCount: 0,
        pageSize: 0,
        recordCount: 0,
        isFirstPage: true,
        isLastPage: true,
        results: [],
      },
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning(
        "Failed to load State info. Please go back to the home page and re-visit the Searh Results from there."
      );
    }

    let { entityType, searchData } = this.props.history.location.state;
    const searchResults = await this.getSearchResultsFromAPI(entityType, searchData);
    let detailedEntityType = this.getDetailedEntityType(entityType, searchData);
    const columns = this.getSearchDataColumns(detailedEntityType);
    const tableKeyField = this.getTableKeyId(detailedEntityType);

    const usaStatesResponse = await ReferenceFileAPI.GetUSStates();
    const usaStates = usaStatesResponse && usaStatesResponse.length > 0 ? usaStatesResponse : [];
    const states = usaStates?.map((state) => ({ id: state.stateUniqueIdentifier, name: state.state_name }));
    const countries = await this.getAllCountriesFromAPI();

    if (this.controller.signal.aborted) {
      this.setState({ isDataLoading: false });
      return;
    }

    this.setState({
      entityType,
      searchData,
      searchResultsData: searchResults,
      columns,
      tableKeyField,
      usaStates,
      states,
      countries,
      currentUsaState,
      isDataLoading: false,
    });
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("searchAgreements.jsx got unmounted");
    }
  }

  showReassignFAModal = async () => {
    this.setState({ showReassignFAModal: true });
  };

  getSearchDataColumns(entityType) {
    switch (entityType) {
      case SearchTypes.Property:
        return this.getPropertyDataColumns();
      case SearchTypes.Agreements:
        return this.getAgreementDataColumns();
      case SearchTypes.SiteVisit:
        return this.getSiteVisitDataColumns();
      case SearchTypes.Aerials:
        return this.getAerialWorkTaskDataColumns();
      case SearchTypes.AdminWorkTask:
        return this.getAdminWorkTaskDataColumns();
      case SearchTypes.Contacts:
        return this.getContactDataColumns();
      case SearchTypes.Permits:
        return this.getPermitDataColumns();
      case SearchTypes.Project:
        return this.getProjectDataColumns();
      case SearchTypes.District:
        return this.getDistrictDataColumns();
      case TAWorkTaskTypes.Hotline:
        return this.getHotlineTADataColumns();
      case TAWorkTaskTypes.PublicOutreach:
        return this.getOutreachTADataColumns();
      case TAWorkTaskTypes.IndividualCooperator:
        return this.getIndividualCooperatorTADataColumns();
      case SampleTypes.NRMP:
        return this.getNRMPDataColumns();
      case SampleTypes.DiseaseMonitoring:
        return this.getDiseaseMonitoringDataColumns();
      case SampleTypes.Other:
        return this.getOtherSampleDataColumns();
      default:
        return [{ dataField: "id", hidden: true }];
    }
  }

  getTableKeyId(entityType) {
    switch (entityType) {
      case SearchTypes.Property:
        return "propertyUniqueIdentifier";
      case SearchTypes.Agreements:
        return "agreementUniqueIdentifier";
      case SearchTypes.SiteVisit:
        return "propertyVisitSummaryUniqueIdentifier";
      case SearchTypes.Aerials:
        return "aerialWorkTaskUniqueIdentifier";
      case SearchTypes.AdminWorkTask:
        return "adminWorkTaskUniqueIdentifier";
      case SearchTypes.Contacts:
        return "contactUniqueId";
      case SearchTypes.Permits:
        return "permitUniqueIdentifier";
      case SearchTypes.Project:
        return "projectUniqueIdentifier";
      case SearchTypes.District:
        return "districtUniqueIdentifier";
      case TAWorkTaskTypes.Hotline:
        return "hotlineTechnicalAssistWorkTaskUniqueIdentifier";
      case TAWorkTaskTypes.PublicOutreach:
        return "outreachTechnicalAssistWorkTaskUniqueIdentifier";
      case TAWorkTaskTypes.IndividualCooperator:
        return "cooperatorTechnicalAssistWorkTaskUniqueIdentifier";
      case SampleTypes.NRMP:
        return "nationalRabiesMonitoringProgramUniqueIdentifier";
      case SampleTypes.DiseaseMonitoring:
        return "diseaseMonitoringUniqueId";
      case SampleTypes.Other:
        return "otherSampleUniqueId";
      default:
        break;
    }
    return "";
  }

  getPropertyDataColumns() {
    return [
      { dataField: "propertyUniqueIdentifier", hidden: true, csvText: "Property Unique ID" },
      {
        dataField: "propertyName",
        text: "Property Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Property Name Search" placeholder="Enter Name" />
        ),
        formatter: (cell, row) => {
          let propertyName = row.propertyName;
          const cutoffLength = 17;
          if (propertyName?.length > cutoffLength) {
            propertyName = propertyName.substring(0, cutoffLength - 1) + "...";
          }
          return (
            <Button
              variant="link"
              className="pl-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={row.propertyName}
            >
              <span className={globalStyles.formDataLinks}>{propertyName}</span>
            </Button>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "propertyAddress",
        text: "Property Address",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByBropertyAddress,
        }),
        formatter: (cell, row) => {
          let displayedAddress = "";
          const propertyState = this.state.usaStates.find((s) => s.stateUniqueIdentifier === row.state);
          if (row.additionalStreetAddresses?.length > 0) {
            row.additionalStreetAddresses[0].stateCode = propertyState.state_code;
            displayedAddress = UtilityFunctions.getPropertyPrimaryAddress(row.additionalStreetAddresses[0]);
          }

          return <span className={globalStyles.formData}>{displayedAddress}</span>;
        },
        csvFormatter: (cell, row) => {
          let displayedAddress = "No Address";
          const propertyState = this.state.usaStates.find((s) => s.stateUniqueIdentifier === row.state);
          if (row.additionalStreetAddresses?.length > 0) {
            row.additionalStreetAddresses[0].stateCode = propertyState.state_code;
            displayedAddress = UtilityFunctions.getPropertyPrimaryAddress(row.additionalStreetAddresses[0]);
          }
          return displayedAddress;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return {
            width: "30%",
            backgroundColor: "#f3f3f3",
            fontSize: "14px",
            fontWeight: "700",
            verticalAlign: "text-top",
          };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "county",
        text: "County",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Property Name Search"
            placeholder="Enter County"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "cooperatorName",
        text: "Cooperator Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Property Name Search"
            placeholder="Enter Agreement"
          />
        ),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {row.cooperatorName ? UtilityFunctions.getContactNameFromDataObject(row.cooperatorName) : "No Cooperator"}
            </span>
          );
        },
        csvFormatter: (cell, row) => {
          return row.cooperatorName
            ? UtilityFunctions.getContactNameFromDataObject(row.cooperatorName)
            : "No Cooperator";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
    ];
  }

  selectAllAgreements = () => {
    const newAgreements = this.state.searchResultsData.map((ag) => {
      return { ...ag, agreementSelect: !ag.agreementSelect };
    });
    this.setState({
      searchResultsData: newAgreements,
      selectedAgreements: this.state.searchResultsData,
    });
  };

  selectAgreement = (incomingIndex) => {
    let ag = [];
    ag = this.state.searchResultsData;
    let agreements = this.state.selectedAgreements;
    agreements.push(ag[incomingIndex]);

    this.setState({ selectedAgreements: agreements });
  };

  getAgreementDataColumns() {
    return [
      { dataField: "agreementUniqueIdentifier", hidden: true, csvText: "Agreement Unique ID" },
      {
        dataField: "agreementSelect",
        text: "Agreement Select",
        csvExport: false,
        headerFormatter: () => {
          return (
            <div>
              <div style={{ paddingBottom: "25px" }}>Select</div>
              <input type="checkbox" onChange={(e) => this.selectAllAgreements(e)} aria-label="Select all Agreements" />
            </div>
          );
        },
        formatter: (cell, row) => {
          let index = this.state.searchResultsData.findIndex(
            (ag) => ag.agreementUniqueIdentifier === row.agreementUniqueIdentifier
          );

          return (
            <input
              type="checkbox"
              defaultChecked={cell}
              onChange={() => this.selectAgreement(index)}
              aria-label={`Select ${row.commonName} ${row.agreementNumber}`}
            />
          );
        },
        align: "center",
        headerAlign: "center",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-bottom" };
        },

        headerAttrs: {
          id: "agreementSelectionHeader",
          title: "Agreement Select Header",
        },
        attrs: { headers: "agreementSelectionHeader" },
      },
      {
        dataField: "commonName",
        text: "Agreement Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Agreement Name Search"
            placeholder="Enter Name"
          />
        ),
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="pl-0"
              title={row.commonName}
              onClick={() => this.handleEntityNavigation(row)}
            >
              <span className={globalStyles.formDataLinks}>{row.commonName ? row.commonName : "No Name"}</span>
            </Button>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
      },
      {
        dataField: "agreementNumber",
        text: "Agreement Number",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Agreement Number Search"
            placeholder="Enter Number"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell ? cell : "No Agreement Number"}</span>;
        },
        csvFormatter: (cell) => {
          return cell ? cell : "No Agreement Number";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "primaryFieldAgentName",
        text: "Primary WS Specialist",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Primary Field Agent Search"
            placeholder="Enter Name"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell ?? "No Primary WS Specialist"}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "cooperatorName",
        text: "Cooperator",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByCooperatorName,
        }),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {UtilityFunctions.getContactNameFromDataObject(row.cooperatorName)}
            </span>
          );
        },
        csvFormatter: (cell, row) => {
          return row.cooperatorName
            ? UtilityFunctions.getContactNameFromDataObject(row.cooperatorName)
            : "No Cooperator";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "agreementProperties",
        text: "Properties",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByProperties,
        }),
        formatter: (cell, row) => {
          let propertyNames = cell?.length > 0 ? cell.map((p) => p.propertyName)?.join(", ") : [];
          return <span className={globalStyles.formData}>{propertyNames ? propertyNames : "No Properties"}</span>;
        },
        csvFormatter: (cell, row) => {
          let propertyNames = cell?.length > 0 ? cell.map((p) => p.propertyName)?.join(", ") : [];
          return propertyNames ? propertyNames.toString() : "None";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "agreementPropertiesAddresses",
        text: "Properties Addresses",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByPropertiesAddresses,
        }),
        formatter: (cell, row) => {
          let propertyNames =
            row.agreementProperties?.length > 0
              ? row.agreementProperties?.map((p) => p.streetAddresses.toString())?.join(", ")
              : [];
          return <span className={globalStyles.formData}>{propertyNames ? propertyNames : "No Properties"}</span>;
        },
        csvFormatter: (cell, row) => {
          let propertyNames =
            row.agreementProperties?.length > 0
              ? row.agreementProperties?.map((p) => p.streetAddresses.toString())?.join(", ")
              : [];
          return propertyNames ? propertyNames.toString() : "None";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "agreementStatus",
        text: "Status",
        sort: true,
        filter: textFilter({
          onFilter: (filterValue, data) => {
            if (filterValue) {
              return data?.filter((agreement) =>
                agreement.agreementStatus?.displayText?.toLowerCase().includes(filterValue.toLowerCase())
              );
            }
          },
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell?.displayText ? cell.displayText : "No Status"}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell?.displayText ? cell.displayText : "No Status";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "expirationDate",
        text: "Expiration Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Entered Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
    ];
  }

  getSiteVisitDataColumns() {
    return [
      { dataField: "propertyVisitSummaryUniqueIdentifier", hidden: true, csvText: "Site Visit Unique ID" },
      {
        dataField: "propertyName",
        text: "Property Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Property Name Search" placeholder="Enter Name" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "propertyCounty",
        text: "County",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="County Name Search" placeholder="Enter Name" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "performedByWsProfileUniqueReference",
        text: "Performed By",
        sort: true,
        filter: textFilter({ onFilter: this.filterResultsByPeformerName }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{row.performerFirstName + " " + row.performerLastName}</span>;
        },
        csvFormatter: (cell, row) => {
          return row.performerFirstName + " " + row.performerLastName;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "dateEntered",
        text: "Date Performed",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Entered Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "createdDateTimeUTC",
        text: "Created Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Created Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "view",
        text: "View",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="p-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={"View Record Page Link"}
            >
              <span className={globalStyles.formDataLinks}>View Record Page</span>
            </Button>
          );
        },
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAttrs: {
          id: "siteVisitViewHeader",
          title: "View Column Header",
        },
      },
    ];
  }

  getAerialWorkTaskDataColumns() {
    return [
      { dataField: "aerialWorkTaskUniqueIdentifier", hidden: true, csvText: "Aerial Work Task Unique ID" },
      {
        dataField: "pilot",
        text: "Pilot",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Pilot User Search"
            placeholder="Enter UserName"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{row.pilot?.userName}</span>;
        },
        csvFormatter: (cell, row) => {
          return row.pilot ? row.pilot?.userName : "No Pilot Username";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "airCraftCrewMembers",
        text: "Crew Members",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByAerialCrewMembers,
        }),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {cell?.length > 0 ? cell.map((c) => c.userName).join(", ") : "No Crew Members"}
            </span>
          );
        },
        csvFormatter: (cell, row) => {
          return cell?.length > 0 ? cell.map((c) => c.userName).join(", ") : "No Crew Members";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "aerialWorkTaskStatus.displayText",
        text: "Status",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByAerialWtStatus,
        }),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {UtilityFunctions.getDisplayTextFromFieldObject(row.aerialWorkTaskStatus)}
            </span>
          );
        },
        csvFormatter: (cell, row) => {
          return row.aerialWorkTaskStatus
            ? UtilityFunctions.getDisplayTextFromFieldObject(row.aerialWorkTaskStatus)
            : "No Status";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "activityType",
        text: "Activity Type",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByAerialActivityType,
        }),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {UtilityFunctions.getDisplayTextFromFieldObject(row.activityTypeEnum)}
            </span>
          );
        },
        csvFormatter: (cell, row) => {
          return row.activityTypeEnum
            ? UtilityFunctions.getDisplayTextFromFieldObject(row.activityTypeEnum)
            : "No Type";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "aircraftTailNumber",
        text: "Aircraft #",
        sort: true,
        filter: textFilter(),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell ?? "No Aircraft #"}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell ?? "No Aircraft #";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "datePerformed",
        text: "Date Performed",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Entered Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "view",
        text: "View",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="p-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={"View Record Page Link"}
            >
              <span className={globalStyles.formDataLinks}>View Record Page</span>
            </Button>
          );
        },
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAttrs: {
          id: "siteVisitViewHeader",
          title: "View Column Header",
        },
      },
    ];
  }

  getAdminWorkTaskDataColumns() {
    return [
      { dataField: "adminWorkTaskUniqueIdentifier", hidden: true, csvText: "Admin Work Task Unique ID" },
      {
        dataField: "propertyOnPvsUniqueReference",
        text: "Property ID",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Property ID Search" placeholder="Enter ID" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell ? cell : "N/A"}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell ? cell : "N/A";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "workDate",
        text: "Work Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Entered Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "adminActivities",
        text: "Admin Activities",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByAdminActivities,
        }),
        formatter: (cell, row) => {
          let adminActivityNames = cell?.length > 0 ? cell.map((a) => a.adminActivityName).join(", ") : [];
          return <span className={globalStyles.formData}>{adminActivityNames}</span>;
        },
        csvFormatter: (cell, row) => {
          let adminActivityNames = cell?.length > 0 ? cell.map((a) => a.adminActivityName).join(", ") : [];
          return adminActivityNames ? adminActivityNames : "No Admin Activities";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
    ];
  }

  getHotlineTADataColumns() {
    return [
      { dataField: "hotlineTechnicalAssistWorkTaskUniqueIdentifier", hidden: true, csvText: "Hotline TA Unique ID" },
      {
        dataField: "city",
        text: "City",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="City Search" placeholder="Enter City" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "county",
        text: "County",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="County Search" placeholder="Enter County" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "workDate",
        text: "Date Performed",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Entered Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "createdDateTimeUTC",
        text: "Created Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Created Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "performedByUserName",
        text: "Performed By",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Created By Search"
            placeholder="Enter username"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell ?? "No Name"}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "view",
        text: "View",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="p-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={"View Record Page Link"}
            >
              <span className={globalStyles.formDataLinks}>View Record Page</span>
            </Button>
          );
        },
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAttrs: {
          id: "hotlineTAViewHeader",
          title: "View Column Header",
        },
      },
    ];
  }

  getOutreachTADataColumns() {
    return [
      {
        dataField: "outreachTechnicalAssistWorkTaskUniqueIdentifier",
        hidden: true,
        csvText: "Public Outreach TA Unique ID",
      },
      {
        dataField: "city",
        text: "City",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="City Search" placeholder="Enter City" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "county",
        text: "County",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="County Search" placeholder="Enter County" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "datePerformed",
        text: "Date Performed",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Performed Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "createdDateTimeUTC",
        text: "Created Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Created Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "performedByUserName",
        text: "Performed By",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Created By Search"
            placeholder="Enter username"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell ?? "No Name"}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "view",
        text: "View",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="p-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={"View Record Page Link"}
            >
              <span className={globalStyles.formDataLinks}>View Record Page</span>
            </Button>
          );
        },
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAttrs: {
          id: "outreachTAViewHeader",
          title: "View Column Header",
        },
      },
    ];
  }

  getIndividualCooperatorTADataColumns() {
    return [
      {
        dataField: "cooperatorTechnicalAssistWorkTaskUniqueIdentifier",
        hidden: true,
        csvText: "Individual/Cooperator TA Unique ID",
      },
      {
        dataField: "city",
        text: "City",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="City Search" placeholder="Enter City" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "county",
        text: "County",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="County Search" placeholder="Enter County" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "datePerformed",
        text: "Date Performed",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Performed Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "createdDateTimeUTC",
        text: "Created Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Created Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          let date = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(cell);
          return (
            <span className={globalStyles.formData}>
              {date ? date.toLocaleDateString("en-US", { timeZone: "UTC" }) : "No Date"}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "performedByUserName",
        text: "Performed By",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Created By Search"
            placeholder="Enter username"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell ?? "No Name"}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "view",
        text: "View",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="p-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={"View Record Page Link"}
            >
              <span className={globalStyles.formDataLinks}>View Record Page</span>
            </Button>
          );
        },
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAttrs: {
          id: "cooperatorTAViewHeader",
          title: "View Column Header",
        },
      },
    ];
  }

  getContactDataColumns() {
    return [
      { dataField: "contactUniqueId", hidden: true, csvText: "Contact Unique ID" },
      {
        dataField: "firstName",
        text: "First Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="Property Name Search" placeholder="Enter Name" />
        ),
        formatter: (cell, row) => {
          return (
            <Button className="pl-0" variant="link" onClick={() => this.handleEntityNavigation(row)}>
              <span className={globalStyles.formDataLinks}>{row.firstName}</span>
            </Button>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "middleName",
        text: "Middle Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Middle Name Search"
            placeholder="Enter Middle Name"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "lastName",
        text: "Last Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Last Name Search"
            placeholder="Enter Last Name"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "phoneNumber",
        text: "Phone Number",
        filter: textFilter({
          onFilter: this.filterResultsByContactPhoneNumber,
        }),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {UtilityFunctions.formatPhoneNumber(this.getPrimaryPhoneNumber(row))}
            </span>
          );
        },
        csvFormatter: (cell, row) => {
          return UtilityFunctions.formatPhoneNumber(this.getPrimaryPhoneNumber(row));
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return {
            backgroundColor: "#f3f3f3",
            fontSize: "14px",
            fontWeight: "700",
            verticalAlign: "text-top",
          };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "emailAddress",
        text: "Email Address",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Email Address Search"
            placeholder="Enter Email Address"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "primaryMailingAddress",
        text: "Address",
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{this.getPrimaryAddress(row)}</span>;
        },
        csvFormatter: (cell, row) => {
          return this.getPrimaryAddress(row);
        },
        filter: textFilter({
          onFilter: this.filterResultsByContactMailingAddress,
        }),
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
    ];
  }

  getPermitDataColumns() {
    return [
      { dataField: "permitUniqueIdentifier", hidden: true, csvText: "Permit Unique ID" },
      {
        dataField: "permitName",
        text: "Permit Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Permit Name Search"
            placeholder="Enter Permit Name"
          />
        ),
        formatter: (cell, row) => {
          let permitName = row.permitName;
          const cutoffLength = 17;
          if (permitName?.length > cutoffLength) {
            permitName = permitName.substring(0, cutoffLength - 1) + "...";
          }
          return (
            <Button
              variant="link"
              className="pl-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={row.permitName}
            >
              <span className={globalStyles.formDataLinks}>{permitName}</span>
            </Button>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "permitNumber",
        text: "Permit Number",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Permit Number Search"
            placeholder="Enter Permit Number"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "permitExpirationDate",
        text: "Expiration Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Expiration Date Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {new Date(cell).toLocaleDateString("en-US", { timeZone: "UTC" })}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "statesOfPermit",
        text: "States of Permit",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByPermitStates,
        }),
        formatter: (cell, row) => {
          let statesOfPermitNames = cell?.length > 0 ? cell.map((s) => s.stateName).join(", ") : [];
          return <span className={globalStyles.formData}>{statesOfPermitNames}</span>;
        },
        csvFormatter: (cell, row) => {
          let statesOfPermitNames = cell?.length > 0 ? cell.map((s) => s.stateName).join(", ") : "No States";
          return statesOfPermitNames;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "speciesOfPermit",
        text: "Damage Agent",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByPermitSpecies,
        }),
        formatter: (cell, row) => {
          let speciesOfPermitNames = cell?.length > 0 ? cell.map((s) => s.speciesName).join(", ") : [];
          return <span className={globalStyles.formData}>{speciesOfPermitNames}</span>;
        },
        csvFormatter: (cell, row) => {
          let speciesOfPermitNames = cell?.length > 0 ? cell.map((s) => s.speciesName).join(", ") : "No Damage Agent";
          return speciesOfPermitNames;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
    ];
  }

  getOtherSampleDataColumns() {
    const headerStyle = { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
    return [
      { dataField: "otherSampleUniqueId", hidden: true, csvText: "Sample Unique ID" },
      {
        dataField: "dateTaken",
        text: "Date Taken",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Date Taken Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {new Date(cell).toLocaleDateString("en-US", { timeZone: "UTC" })}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "profileName",
        text: "Employee Profile",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByEmployee,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "speciesName",
        text: "Damage Agent",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsBySpecies,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "diseaseName",
        text: "Disease",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsBySpecies,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "sampleType",
        text: "Sample Type",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsBySampleTypes,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{UtilityFunctions.getDisplayTextFromFieldObject(cell)}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell ? UtilityFunctions.getDisplayTextFromFieldObject(cell) : "No Location Type";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "propertyName",
        text: "Property",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByProperty,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "isFromTake",
        text: "Is From Take?",
        sort: true,
        filter: selectFilter({
          options: { true: "Yes", false: "No" },
          onFilter: this.filterDiseaseIsFromTakeColumn,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell ? "Yes" : "No"}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell ? "Yes" : "No";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "view",
        text: "View",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="p-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={"View Record Page Link"}
            >
              <span className={globalStyles.formDataLinks}>View Record Page</span>
            </Button>
          );
        },
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAttrs: {
          id: "otherSampleViewHeader",
          title: "View Column Header",
        },
      },
    ];
  }

  getNRMPDataColumns() {
    const headerStyle = { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
    return [
      { dataField: "nationalRabiesMonitoringProgramUniqueIdentifier", hidden: true, csvText: "NRMP Unique ID" },
      {
        dataField: "date",
        text: "Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="NRNP Date Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {new Date(cell).toLocaleDateString("en-US", { timeZone: "UTC" })}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "stateUniqueReference",
        text: "State",
        sort: true,
        filter: selectFilter({
          options: this.getStateFilterOptionsOptions(),
        }),
        formatter: (cell, row) => {
          const stateObject = this.state.states?.find((s) => s.id === cell);
          return <span className={globalStyles.formData}>{stateObject ? stateObject.name : ""}</span>;
        },
        csvFormatter: (cell, row) => {
          const stateObject = this.state.states?.find((s) => s.id === cell);
          return <span className={globalStyles.formData}>{stateObject ? stateObject.name : ""}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "countyName",
        text: "County",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="NRMP County Search" placeholder="Enter County" />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "townOrCity",
        text: "Town or City",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="NRMP Town or City Search"
            placeholder="Enter Town or City"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "animalId",
        text: "Animal ID",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="NRMP Animal ID Search"
            placeholder="Enter Animal ID"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "collectionActivity",
        text: "Collection Activity",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByNRMPCollectionActivity,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{UtilityFunctions.getDisplayTextFromFieldObject(cell)}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell ? UtilityFunctions.getDisplayTextFromFieldObject(cell) : "No Collection Activity";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "speciesName",
        text: "Damage Agent",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsBySpecies,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "waypointNumber",
        text: "Waypoint ID",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="NRMP Waypoint ID Search"
            placeholder="Enter Waypoint ID"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "view",
        text: "View",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="p-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={"View Record Page Link"}
            >
              <span className={globalStyles.formDataLinks}>View Record Page</span>
            </Button>
          );
        },
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAttrs: {
          id: "nrmpViewHeader",
          title: "View Column Header",
        },
      },
    ];
  }

  getDiseaseMonitoringDataColumns() {
    const headerStyle = { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
    return [
      { dataField: "diseaseMonitoringUniqueId", hidden: true, csvText: "Disease Monitoring Unique ID" },
      {
        dataField: "date",
        text: "Date Taken",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Disease Monitoring Date Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {new Date(cell).toLocaleDateString("en-US", { timeZone: "UTC" })}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "subjectId",
        text: "Subject ID",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Disease MOnitoring Subject ID Search"
            placeholder="Filter by Subject ID"
          />
        ),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "employee",
        text: "Employee Profile",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByEmployee,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell?.userFullName}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell?.userFullName;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "species",
        text: "Damage Agent",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsBySpecies,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell?.speciesName}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell?.speciesName;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "diseases",
        text: "Diseases",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsBySpecies,
        }),
        formatter: (cell, row) => {
          const diseasesNamesArray = cell?.map((disease) => disease.diseaseName);
          const diseasesDataToDisplay = diseasesNamesArray?.length > 0 ? diseasesNamesArray.join(", ") : "N/A";
          return <span className={globalStyles.formData}>{diseasesDataToDisplay}</span>;
        },
        csvFormatter: (cell, row) => {
          const diseasesNamesArray = cell?.map((disease) => disease.diseaseName);
          const diseasesDataToDisplay = diseasesNamesArray?.length > 0 ? diseasesNamesArray.join(", ") : "N/A";
          return <span className={globalStyles.formData}>{diseasesDataToDisplay}</span>;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "property",
        text: "Property",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByProperty,
        }),
        formatter: (cell, row) => {
          return <span className={globalStyles.formData}>{cell?.propertyName}</span>;
        },
        csvFormatter: (cell, row) => {
          return cell?.propertyName;
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "view",
        text: "View",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="p-0"
              onClick={() => this.handleEntityNavigation(row)}
              title={"View Record Page Link"}
            >
              <span className={globalStyles.formDataLinks}>View Record Page</span>
            </Button>
          );
        },
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
          return headerStyle;
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAttrs: {
          id: "diseaseMonitoringViewHeader",
          title: "View Column Header",
        },
      },
    ];
  }

  getProjectDataColumns() {
    return [
      { dataField: "projectUniqueIdentifier", hidden: true, csvText: "Project Unique ID" },
      {
        dataField: "projectName",
        text: "Project Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Project Name Search"
            placeholder="Filter by Name"
          />
        ),
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="pl-0"
              title={`${cell}-${row.projectUniqueIdentifier}`}
              onClick={() => this.handleEntityNavigation(row)}
            >
              <span className={globalStyles.formDataLinks}>{cell}</span>
            </Button>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "startDate",
        text: "Start Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Start Date Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {new Date(cell).toLocaleDateString("en-US", { timeZone: "UTC" })}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "endDate",
        text: "End Date",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="End Date Search"
            placeholder="Filter by Date"
            dataType="date"
          />
        ),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {new Date(cell).toLocaleDateString("en-US", { timeZone: "UTC" })}
            </span>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
    ];
  }

  getDistrictDataColumns() {
    return [
      { dataField: "districtUniqueIdentifier", hidden: true, csvText: "District Unique ID" },
      {
        dataField: "name",
        text: "District Name",
        sort: true,
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="District Name Search"
            placeholder="Filter by Name"
          />
        ),
        formatter: (cell, row) => {
          return (
            <Button
              variant="link"
              className="pl-0"
              title={`${cell}-${row.districtUniqueIdentifier}`}
              onClick={() => this.handleEntityNavigation(row)}
            >
              <span className={globalStyles.formDataLinks}>{cell}</span>
            </Button>
          );
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "districtSupervisor",
        text: "District Supervisor",
        sort: true,
        filter: textFilter({
          onFilter: this.filterResultsByCooperatorName,
        }),
        formatter: (cell, row) => {
          return (
            <span className={globalStyles.formData}>
              {UtilityFunctions.getContactNameFromDataObject(row.districtSupervisor)}
            </span>
          );
        },
        csvFormatter: (cell, row) => {
          return row.cooperatorName
            ? UtilityFunctions.getContactNameFromDataObject(row.districtSupervisor)
            : "No Supervisor";
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", fontSize: "14px", fontWeight: "700", verticalAlign: "text-top" };
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
      },
    ];
  }

  getStateFilterOptionsOptions() {
    let statesFilterOptions = {};
    if (this.state.usaStates?.length > 0) {
      this.state.usaStates.forEach((s) => {
        statesFilterOptions[s.stateUniqueIdentifier] = s.state_name;
      });
    }

    return statesFilterOptions;
  }

  filterResultsByBropertyAddress = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (property) =>
          property.additionalStreetAddresses?.length > 0 &&
          (property.additionalStreetAddresses[0].streetAddress.toLowerCase().includes(filterValue.toLowerCase()) ||
            property.additionalStreetAddresses[0].city.toLowerCase().includes(filterValue.toLowerCase()) ||
            property.additionalStreetAddresses[0].zipCode.toLowerCase().includes(filterValue.toLowerCase()))
      );
    }
  };

  filterResultsByCooperatorName = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (agreement) =>
          agreement.cooperatorName?.firstName?.toLowerCase().includes(filterValue.toLowerCase()) ||
          agreement.cooperatorName?.middleName?.toLowerCase().includes(filterValue.toLowerCase()) ||
          agreement.cooperatorName?.lastName?.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByProperties = (filterValue, data) => {
    if (filterValue) {
      return data?.filter((agreement) =>
        agreement.agreementProperties
          ?.map((p) => p.propertyName)
          ?.join(", ")
          ?.toString()
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByPropertiesAddresses = (filterValue, data) => {
    if (filterValue) {
      return data?.filter((agreement) =>
        agreement.agreementProperties
          ?.map((p) => p.streetAddresses)
          ?.toString()
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByPeformerName = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (siteVisit) =>
          siteVisit.performerFirstName?.toLowerCase().includes(filterValue.toLowerCase()) ||
          siteVisit.performerLastName?.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByAerialWtStatus = (filterValue, data) => {
    if (filterValue) {
      return data.filter((task) =>
        UtilityFunctions.getDisplayTextFromFieldObject(task.aerialWorkTaskStatus)
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByAerialCrewMembers = (filterValue, data) => {
    if (filterValue) {
      return data.filter((task) =>
        task.airCraftCrewMembers
          ?.map((c) => c.userName)
          .join("")
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByAerialActivityType = (filterValue, data) => {
    if (filterValue) {
      return data.filter((task) =>
        UtilityFunctions.getDisplayTextFromFieldObject(task.activityTypeEnum)
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByAdminActivities = (filterValue, data) => {
    if (filterValue) {
      return data.filter((task) =>
        task.adminActivities?.some((a) => a.adminActivityName?.toLowerCase().includes(filterValue.toLowerCase()))
      );
    }
  };

  filterResultsByContactPhoneNumber = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (contact) =>
          contact.phoneNumbers?.length > 0 &&
          contact.phoneNumbers.some((p) => p.phoneNumber?.toLowerCase().includes(filterValue.toLowerCase()))
      );
    }
  };

  filterResultsByContactMailingAddress = (filterValue, data) => {
    if (filterValue) {
      return data.filter(
        (contact) =>
          contact.primaryMailingAddress?.mailingStreet?.toLowerCase().includes(filterValue.toLowerCase()) ||
          contact.primaryMailingAddress?.mailingCity?.toLowerCase().includes(filterValue.toLowerCase()) ||
          contact.primaryMailingAddress?.mailingZip?.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByPermitStates = (filterValue, data) => {
    if (filterValue) {
      return data.filter((permit) =>
        permit.statesOfPermit?.some((s) => s.stateName?.toLowerCase().includes(filterValue.toLowerCase()))
      );
    }
  };

  filterResultsByPermitSpecies = (filterValue, data) => {
    if (filterValue) {
      return data.filter((permit) =>
        permit.speciesOfPermit?.some((s) => s.speciesName?.toLowerCase().includes(filterValue.toLowerCase()))
      );
    }
  };

  filterResultsBySampleTypes = (filterValue, data) => {
    if (filterValue) {
      return data.filter((sample) =>
        UtilityFunctions.getDisplayTextFromFieldObject(sample.sampleType)
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByNRMPCollectionActivity = (filterValue, data) => {
    if (filterValue) {
      return data.filter((sample) =>
        UtilityFunctions.getDisplayTextFromFieldObject(sample.collectionActivity)
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
  };

  filterResultsByEmployee = (filterValue, data) => {
    if (filterValue) {
      return data.filter((sample) => sample.userFullName?.toLowerCase().includes(filterValue.toLowerCase()));
    }
  };

  filterResultsByProperty = (filterValue, data) => {
    if (filterValue) {
      return data.filter((sample) => sample.property?.propertyName?.toLowerCase().includes(filterValue.toLowerCase()));
    }
  };

  filterResultsBySpecies = (filterValue, data) => {
    if (filterValue) {
      return data.filter((sample) => sample.species?.speciesName?.toLowerCase().includes(filterValue.toLowerCase()));
    }
  };

  getStateNameFromId(stateId) {
    const { states } = this.state;
    const currentState = states.find((state) => state.id === stateId);
    return currentState ? currentState.name : "";
  }

  getCountryNameFromId(countryId) {
    const { countries } = this.state;
    const country = countries.find((country) => country.id === countryId);
    return country ? country.name : "";
  }

  getCooperatorName(cooperator) {
    const { firstname, middlename, lastName, suffix } = cooperator;
    return [firstname, middlename, lastName, suffix].join(" ");
  }

  getCooperatorNameFromId(contactUniqueIdentifier) {
    const contactData = this.state.contacts.find((contact) => contact.id === contactUniqueIdentifier);
    return contactData ? contactData.name : "";
  }

  getPrimaryPhoneNumber(row) {
    let phoneNumber = "";
    if (row.phoneNumbers?.length > 0) {
      phoneNumber = row.phoneNumbers[0].phoneNumber;
    }

    return phoneNumber;
  }

  getPrimaryAddress(row) {
    let primaryAddress = "";

    if (row.primaryMailingAddress) {
      const { mailingStreet, mailingCity, mailingStateUniqueId, mailingZip, mailingCountryUniqueId } =
        row.primaryMailingAddress;

      primaryAddress = `${mailingStreet ? mailingStreet + ", " : ""}${mailingCity ? mailingCity + ", " : ""}${
        mailingStateUniqueId ? this.getStateNameFromId(mailingStateUniqueId) + ", " : ""
      }${mailingZip ? mailingZip + ", " : ""}${
        mailingCountryUniqueId ? this.getCountryNameFromId(mailingCountryUniqueId) : ""
      }`;
    }

    return primaryAddress;
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  async getSearchResultsFromAPI(entityType, searchData) {
    switch (entityType) {
      case SearchTypes.Property:
        return await this.getPropertiesDataFromAPI(searchData);
      case SearchTypes.Agreements:
        return await this.getAgreementsDataFromAPI(searchData);
      case SearchTypes.SiteVisit:
        return await this.getSiteVisitsDataFromAPI(searchData);
      case SearchTypes.TAWorkTask:
        return await this.getTaWorkTaskDataFromAPI(searchData);
      case SearchTypes.Aerials:
        return await this.getAerialWorkTaskDataFromAPI(searchData);
      case SearchTypes.AdminWorkTask:
        return await this.getAdminWorkTaskDataFromAPI(searchData);
      case SearchTypes.Contacts:
        return await this.getContactsDataFromAPI(searchData);
      case SearchTypes.Permits:
        return await this.getPermitsDataFromAPI(searchData);
      case SearchTypes.Samples:
        return await this.getSamplesDataFromAPI(searchData);
      case SearchTypes.Project:
        return await this.getProjectsDataFromAPI(searchData);
      case SearchTypes.District:
        return await this.getDistrictsDataFromAPI(searchData);
      default:
        return [];
    }
  }

  async getPropertiesDataFromAPI(searchData) {
    let propertiesData = [];
    if (this.isCurrentStateValid()) {
      const propertiesDataResponse = await AgreementEntitiesAPI.PropertiesStructuredSearch(
        this.getSearchPropertiesBody(searchData),
        this.controller.signal
      );
      if (propertiesDataResponse?.successful && propertiesDataResponse?.data?.results?.length > 0) {
        propertiesData = propertiesDataResponse.data.results;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: propertiesDataResponse?.data });
    }

    return propertiesData;
  }

  async getAgreementsDataFromAPI(searchData) {
    let agreementsData = [];
    if (this.isCurrentStateValid()) {
      const agreementsDataResponse = await AgreementAPI.AgreementStructuredSearch(
        this.getSearchAgreementsBody(searchData),
        this.controller.signal
      );
      if (agreementsDataResponse?.successful && agreementsDataResponse.data?.results?.length > 0) {
        agreementsData = agreementsDataResponse.data?.results;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: agreementsDataResponse?.data });
    }

    return agreementsData;
  }

  async getSiteVisitsDataFromAPI(searchData) {
    let siteVisitsData = [];
    if (this.isCurrentStateValid()) {
      const siteVisitsDataResponse = await WorkTaskAPI.PVSStructuredSearch(
        this.getSearchSiteVisitsBody(searchData),
        this.controller.signal
      );
      if (siteVisitsDataResponse?.successful && siteVisitsDataResponse.data?.results?.length > 0) {
        siteVisitsData = siteVisitsDataResponse.data?.results;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: siteVisitsDataResponse?.data });
    }

    return siteVisitsData;
  }

  async getTaWorkTaskDataFromAPI(searchData) {
    let taWorkTaskData = [];
    if (this.isCurrentStateValid() && searchData.taWorkTaskType) {
      let taWorkTaskDataResponse;
      switch (searchData.taWorkTaskType) {
        case TAWorkTaskTypes.Hotline:
          taWorkTaskDataResponse = await WorkTaskAPI.HotlineTAStructuredSearch(
            this.getSearchTaWorkTaskBody(searchData),
            this.controller.signal
          );
          break;
        case TAWorkTaskTypes.PublicOutreach:
          taWorkTaskDataResponse = await WorkTaskAPI.OutreachTAStructuredSearch(
            this.getSearchTaWorkTaskBody(searchData),
            this.controller.signal
          );
          break;
        case TAWorkTaskTypes.IndividualCooperator:
          taWorkTaskDataResponse = await WorkTaskAPI.CooperatorTAStructuredSearch(
            this.getSearchTaWorkTaskBody(searchData),
            this.controller.signal
          );
          break;
        default:
          break;
      }

      if (taWorkTaskDataResponse?.successful && taWorkTaskDataResponse.data?.results?.length > 0) {
        taWorkTaskData = taWorkTaskDataResponse.data?.results;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: taWorkTaskDataResponse?.data });
    }
    return taWorkTaskData;
  }

  async getAerialWorkTaskDataFromAPI(searchData) {
    let aerialWorkTaskData = [];
    if (this.isCurrentStateValid()) {
      const aerialWorkTaskDataResponse = await WorkTaskAPI.AerialWorkTaskStructuredSearch(
        this.getSearchAerialWorkTaskBody(searchData),
        this.controller.signal
      );
      if (aerialWorkTaskDataResponse?.successful && aerialWorkTaskDataResponse.data?.results?.length > 0) {
        aerialWorkTaskData = aerialWorkTaskDataResponse.data?.results;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: aerialWorkTaskDataResponse?.data });
    }

    return aerialWorkTaskData;
  }

  async getAdminWorkTaskDataFromAPI(searchData) {
    let adminWorkTaskData = [];
    if (this.isCurrentStateValid()) {
      const adminWorkTaskDataResponse = await WorkTaskAPI.AdminWorkTaskStructuredSearch(
        this.getSearchAdminWorkTaskBody(searchData),
        this.controller.signal
      );
      if (adminWorkTaskDataResponse?.successful && adminWorkTaskDataResponse.data?.results?.length > 0) {
        adminWorkTaskData = adminWorkTaskDataResponse.data?.results;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: adminWorkTaskDataResponse?.data });
    }

    return adminWorkTaskData;
  }

  async getContactsDataFromAPI(searchData) {
    let contactsData = [];
    if (this.isCurrentStateValid()) {
      const contactsDataResponse = await AgreementEntitiesAPI.ContactsStructuredSearch(
        this.getSearchContactsBody(searchData),
        this.controller.signal
      );
      if (contactsDataResponse?.successful && contactsDataResponse.data?.results?.length > 0) {
        contactsData = contactsDataResponse.data?.results;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: contactsDataResponse?.data });
    }

    return contactsData;
  }

  async getPermitsDataFromAPI(searchData) {
    let permitsData = [];

    if (this.isCurrentStateValid()) {
      const permitsDataResponse = await WorkTaskAPI.PermitsStructuredSearch(
        this.getSearchPermitsBody(searchData),
        this.controller.signal
      );
      if (permitsDataResponse?.successful && permitsDataResponse.data?.results?.length > 0) {
        permitsData = permitsDataResponse.data?.results;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: permitsDataResponse?.data });
    }

    return permitsData;
  }

  async getSamplesDataFromAPI(searchData) {
    let samplesData = [];

    if (this.isCurrentStateValid() && searchData.sampleType) {
      switch (searchData.sampleType) {
        case SampleTypes.NRMP:
          samplesData = await this.getFormattedNRMPData(searchData);
          break;
        case SampleTypes.DiseaseMonitoring:
          samplesData = await this.getFormattedDiseaseMonitoringData(searchData);
          break;
        case SampleTypes.Other:
          samplesData = await this.getFormattedOtherSampleData(searchData);
          break;
        default:
          break;
      }
    }
    return samplesData;
  }

  async getFormattedNRMPData(searchData) {
    let samplesData = [];
    const nrmpDataResponse = await WorkTaskAPI.NRMPStructuredSearch(
      this.getSearchNRMPBody(searchData),
      this.controller.signal
    );
    if (!nrmpDataResponse?.successful) {
      ErrorHandler.showErrorWithMessage(
        nrmpDataResponse?.message ? nrmpDataResponse.message : "NRMP Data could not be retrieved."
      );
    } else if (nrmpDataResponse.data?.results?.length > 0) {
      samplesData = nrmpDataResponse.data.results;
    }
    if (this.controller.signal.aborted) {
      return;
    }
    this.setState({ pagedResultAPIContainer: nrmpDataResponse?.data });

    return samplesData;
  }

  async getFormattedDiseaseMonitoringData(searchData) {
    let samplesData = [];
    const diseaseMonitoringResponse = await WorkTaskAPI.DiseaseMonitoringStructuredSearch(
      this.getSearchDiseaseMonitoringBody(searchData),
      this.controller.signal
    );
    if (!diseaseMonitoringResponse?.successful) {
      ErrorHandler.showErrorWithMessage(
        diseaseMonitoringResponse?.message
          ? diseaseMonitoringResponse.message
          : "Disease Monitoring Data could not be retrieved."
      );
    } else if (diseaseMonitoringResponse.data?.results?.length > 0) {
      diseaseMonitoringResponse.data.results.forEach((diseaseEntry) => {
        if (diseaseEntry.samplesRecorded?.length > 0) {
          samplesData = [
            ...samplesData,
            ...diseaseEntry.samplesRecorded.map((s) => ({
              diseaseMonitoringUniqueId: `${diseaseEntry.diseaseMonitoringUniqueIdentifier}-${s.subjectId}`,
              ...diseaseEntry,
              ...s,
            })),
          ];
        }
      });

      if (samplesData?.length > 0) {
        if (searchData.sampleDisease) {
          samplesData = samplesData.filter((s) =>
            s.diseases?.some((d) => d.diseaseUniqueReference === searchData.sampleDisease)
          );
        }
        if (searchData.subjectId) {
          samplesData = samplesData.filter((s) => s.subjectId.includes(searchData.subjectId));
        }
        if (searchData.diseaseSampleType) {
          samplesData = samplesData.filter((s) =>
            s.diseases?.some((d) => d.sampleType?.id === searchData.diseaseSampleType)
          );
        }
      }
    }
    if (this.controller.signal.aborted) {
      return;
    }
    this.setState({ pagedResultAPIContainer: diseaseMonitoringResponse?.data });

    return samplesData;
  }

  async getFormattedOtherSampleData(searchData) {
    let samplesData = [];
    const otherSamplesResponse = await WorkTaskAPI.OtherSampleStructuredSearch(
      this.getSearchOtherSamplesBody(searchData),
      this.controller.signal
    );
    if (!otherSamplesResponse?.successful) {
      ErrorHandler.showErrorWithMessage(
        otherSamplesResponse?.message ? otherSamplesResponse.message : "Other Sample Data could not be retrieved."
      );
    } else if (otherSamplesResponse.data?.results?.length > 0) {
      otherSamplesResponse.data.results.forEach((otherSampleEntry) => {
        if (otherSampleEntry.samples?.length > 0) {
          samplesData = [
            ...samplesData,
            ...otherSampleEntry.samples.map((s) => ({
              otherSampleUniqueId: `${otherSampleEntry.otherSampleUniqueIdentifier}-${s.sampleDataUniqueIdentifier}`,
              ...otherSampleEntry,
              ...s,
            })),
          ];
        }
      });

      if (searchData.sampleDisease && samplesData?.length > 0) {
        samplesData = samplesData.filter((s) => s.diseaseUniqueReference === searchData.sampleDisease);
      }
    }
    if (this.controller.signal.aborted) {
      return;
    }
    this.setState({ pagedResultAPIContainer: otherSamplesResponse?.data });

    return samplesData;
  }

  async getProjectsDataFromAPI(searchData) {
    let projectsData = [];

    const projectsDataResponse = await AgreementEntitiesAPI.ProjectStructuredSearch(
      this.getSearchProjectsBody(searchData),
      this.controller.signal
    );
    if (projectsDataResponse?.successful && projectsDataResponse.data?.results?.length > 0) {
      projectsData = projectsDataResponse.data?.results;
    }
    if (this.controller.signal.aborted) {
      return;
    }
    this.setState({ pagedResultAPIContainer: projectsDataResponse?.data });

    return projectsData;
  }

  async getDistrictsDataFromAPI(searchData) {
    let districtsData = [];
    const stateId = this.getCurrentStateId();

    if (stateId) {
      const districtsDataResponse = await RoleAuthorizationAPI.SearchDistrictFreetext(
        stateId,
        this.getSearchDistrictsBody(searchData),
        this.controller.signal
      );
      if (districtsDataResponse?.successful && districtsDataResponse.data?.length > 0) {
        districtsData = districtsDataResponse.data;
      }
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ pagedResultAPIContainer: districtsDataResponse?.data });
    }

    return districtsData;
  }

  isCurrentStateValid() {
    return this.state.currentUsaState?.stateGuid || JSON.parse(localStorage.getItem("currentState"))?.stateGuid;
  }

  handleSearch = async (entityType, searchData) => {
    this.setState({ isDataLoading: true });
    let searchResults = await this.getSearchResultsFromAPI(entityType, searchData);
    let detailedEntityType = this.getDetailedEntityType(entityType, searchData);
    const columns = this.getSearchDataColumns(detailedEntityType);
    const tableKeyField = this.getTableKeyId(detailedEntityType);

    if (this.controller.signal.aborted) {
      this.setState({ isDataLoading: false });
      return;
    }

    this.setState({ searchResultsData: searchResults, columns, tableKeyField, searchData, isDataLoading: false });
  };

  handleEntityNavigation = async (row) => {
    let route;
    let fullObject;
    switch (this.state.entityType) {
      case SearchTypes.Contacts:
        route = "/contact/details";
        fullObject = await AgreementEntitiesAPI.GetContact(row.contactUniqueId);
        break;
      case SearchTypes.Property:
        route = "/property/details";
        break;
      case SearchTypes.Agreements:
        route = "/agreement/details";
        break;
      case SearchTypes.Permits:
        route = "/permit/details";
        break;
      case SearchTypes.SiteVisit:
        route = "/sitevisit";
        break;
      case SearchTypes.TAWorkTask:
        route = "/taworktask/details";
        break;
      case SearchTypes.Project:
        route = "/project/details";
        break;
      case SearchTypes.Aerials:
        route = "/aerialworktask/details";
        break;
      case SearchTypes.Samples:
        route = this.getSampleNavigationRoute();
        break;
      case SearchTypes.District:
        route = "/district/details";
        break;
      default:
        break;
    }

    const navigationObject = {
      pathname: route,
      state: {
        states: this.state.usaStates,
        countries: this.state.countries,
      },
    };

    if (this.state.entityType === SearchTypes.SiteVisit) {
      navigationObject.state.propertyData = await this.getPropertyDataFromAPI(row);
      navigationObject.state.siteVisitData = row;
      navigationObject.state.isDetailsPage = true;
    } else {
      navigationObject.state.entityData = fullObject ? fullObject?.data : row;
    }

    if (this.state.entityType === SearchTypes.TAWorkTask) {
      navigationObject.state.entityType = this.state.searchData?.taWorkTaskType;
    }

    this.props.history.push(navigationObject);
  };

  getSampleNavigationRoute() {
    let route;
    switch (this.state.searchData?.sampleType) {
      case SampleTypes.NRMP:
        route = "/nrmp/details";
        break;
      case SampleTypes.Other:
        route = "/othersample/details";
        break;
      case SampleTypes.DiseaseMonitoring:
        route = "/diseasemonitoring/details";
        break;
      default:
        break;
    }
    return route;
  }

  getDetailedEntityType(entityType, searchData) {
    let detailedEntityType = JSON.parse(JSON.stringify(entityType));
    if (entityType === SearchTypes.TAWorkTask) {
      detailedEntityType = searchData.taWorkTaskType;
    } else if (entityType === SearchTypes.Samples) {
      detailedEntityType = searchData.sampleType;
    }
    return detailedEntityType;
  }

  async getPropertyDataFromAPI(siteVisitData) {
    let propertyData = {};
    if (siteVisitData?.propertyVisitedUniqueReference) {
      const propertyDataResponse = await AgreementEntitiesAPI.GetProperty(
        siteVisitData?.propertyVisitedUniqueReference,
        this.controller.signal
      );
      if (propertyDataResponse?.successful) {
        propertyData = propertyDataResponse.data;
      }
    }
    return propertyData;
  }

  async getContactData(contactUniqueId) {
    const contactDataResponse = await AgreementEntitiesAPI.GetContact(contactUniqueId, this.controller.signal);
    if (!contactDataResponse?.successful) {
      return null;
    }
    let contactData = contactDataResponse.data;
    contactData.fullName = UtilityFunctions.getContactNameFromDataObject(contactData);
    return contactData;
  }

  getSearchPropertiesBody(searchData) {
    return {
      stateUniqueReference: this.getCurrentStateId(),
      propertyName: searchData.propertyName,
      street: searchData.propertyStreet,
      city: searchData.propertyCity,
      zip: searchData.propertyZip,
    };
  }

  getSearchAgreementsBody(searchData) {
    return {
      stateUniqueReference: this.getCurrentStateId(),
      primaryFieldAgentWsProfileUniqueReference: searchData.agreementPrimaryWsSpecialist?.value
        ? searchData.agreementPrimaryWsSpecialist.value
        : null,
      commonNameFreetext: searchData.agreementName,
      agreementNumberFreetext: searchData.agreementNumber,
      propertyStreetAddressFreetext: searchData.agreementPropertyAddress,
      firstNameOfCooperator: searchData.agreementFirstNameOfCooperator,
      lastNameOfCooperator: searchData.agreementLastNameOfCooperator,
      propertyName: searchData.agreementPropertyName,
      countyOfProperty: searchData.agreementCounty?.label,
      agreementStatusEnumId: searchData.agreementStatus ? searchData.agreementStatus : null,
    };
  }

  getSearchSiteVisitsBody(searchData) {
    return {
      stateUniqueReference: this.getCurrentStateId(),
      perfomedByWsProfileUniqueReference: searchData.siteVisitPerformedBy?.value
        ? searchData.siteVisitPerformedBy.value
        : null,
      datePerformedStart: searchData.datePerformedStartDate
        ? new Date(Date.parse(searchData.datePerformedStartDate)).toISOString()
        : null,
      datePerformedEnd: searchData.datePerformedEndDate
        ? new Date(Date.parse(searchData.datePerformedEndDate)).toISOString()
        : null,
      dateRecordCreatedStart: searchData.siteVisitEnteredStartDate
        ? new Date(Date.parse(searchData.siteVisitEnteredStartDate)).toISOString()
        : null,
      dateRecordCreatedEnd: searchData.siteVisitEnteredEndDate
        ? new Date(Date.parse(searchData.siteVisitEnteredEndDate)).toISOString()
        : null,
      propertyName: searchData.siteVisitPropertyName,
      countyName: searchData.siteVisitCounty?.label,
      doesPVSHaveTakesAssociated: searchData.siteVisitTakesRecorded,
    };
  }

  getSearchTaWorkTaskBody(searchData) {
    let taWorkTaskSearchBody = {
      stateUniqueReference: this.getCurrentStateId(),
      performedByWsProfileUniqueReference: searchData.taWorkTaskPerformedBy?.value
        ? searchData.taWorkTaskPerformedBy.value
        : null,
      datePerformedStart: searchData.taWorkTaskDatePerformedStart
        ? new Date(Date.parse(searchData.taWorkTaskDatePerformedStart)).toISOString()
        : null,
      datePerformedEnd: searchData.taWorkTaskDatePerformedEnd
        ? new Date(Date.parse(searchData.taWorkTaskDatePerformedEnd)).toISOString()
        : null,
      dateRecordCreatedStart: searchData.taWorkTaskEnteredStartDate
        ? new Date(Date.parse(searchData.taWorkTaskEnteredStartDate)).toISOString()
        : null,
      dateRecordCreatedEnd: searchData.taWorkTaskEnteredEndDate
        ? new Date(Date.parse(searchData.taWorkTaskEnteredEndDate)).toISOString()
        : null,
    };
    searchData.taWorkTaskType === "individualCooperator"
      ? (taWorkTaskSearchBody.countyName = searchData.taWorkTaskCounty)
      : (taWorkTaskSearchBody.county = searchData.taWorkTaskCounty);
    taWorkTaskSearchBody.topicUniqueReference = searchData.taWorkTaskTopic ? searchData.taWorkTaskTopic : null;

    return taWorkTaskSearchBody;
  }

  getSearchAerialWorkTaskBody(searchData) {
    return {
      stateUniqueReference: this.getCurrentStateId(),
      propertyCommonName: searchData.aerialPropertyName ?? null,
      county: searchData.aerialCountyName?.label ?? null,
      aircraftNumber: searchData.aerialAircraftNumber ?? null,
      pilotWsProfileUniqueReference: searchData.aerialPilot?.value ?? null,
      crewMemberWsProfileUniqueReference: searchData.aerialCrewMember?.value ?? null,
      datePerformedStart: searchData.aerialEnteredDateStart
        ? new Date(searchData.aerialEnteredDateStart)?.toISOString()
        : null,
      datePerformedEnd: searchData.aerialEnteredDateEnd
        ? new Date(searchData.aerialEnteredDateEnd)?.toISOString()
        : null,
    };
  }

  getSearchAdminWorkTaskBody(searchData) {
    return {
      stateUniqueReference: this.getCurrentStateId(),
      performedByWsProfileUniqueReference: searchData.adminWorkTaskPerformedBy?.value
        ? searchData.adminWorkTaskPerformedBy.value
        : null,
      datePerformedStart: searchData.adminWorkTaskDatePerformedStart
        ? new Date(Date.parse(searchData.adminWorkTaskDatePerformedStart)).toISOString()
        : null,
      datePerformedEnd: searchData.adminWorkTaskDatePerformedEnd
        ? new Date(Date.parse(searchData.adminWorkTaskDatePerformedEnd)).toISOString()
        : null,
      dateRecordCreated: searchData.adminWorkTaskEnteredDate
        ? new Date(Date.parse(searchData.adminWorkTaskEnteredDate)).toISOString()
        : null,
    };
  }

  getSearchContactsBody(searchData) {
    return {
      stateUniqueReference: searchData.contactState ? searchData.contactState : null,
      firstName: searchData.contactFirstName,
      lastName: searchData.contactLastName,
      phoneNumber: UtilityFunctions.formatPhoneNumberWithoutDashes(searchData.contactPhoneNumber),
      email: searchData.contactEmail,
      street: searchData.contactStreetAddress,
      city: searchData.contactCity,
      zip: searchData.contactZip,
    };
  }

  getSearchPermitsBody(searchData) {
    return {
      stateUniqueReference: this.getCurrentStateId(),
      permitName: searchData.permitName,
      permitNumber: searchData.permitNumber,
      speciesUniqueReference: searchData.permitSpecies?.value ? [searchData.permitSpecies.value] : [],
      isCurrentlyValid: searchData.permitIsValid,
    };
  }

  getSearchOtherSamplesBody(searchData) {
    return {
      isFromTake: searchData.sampleIsFromTake,
      dateTaken: searchData.sampleDateTaken ? new Date(Date.parse(searchData.sampleDateTaken)).toISOString() : null,
      wsUserUniqueReference: searchData.sampleProfile?.value ? searchData.sampleProfile.value : null,
      speciesUniqueReference: searchData.sampleSpecies?.value ? searchData.sampleSpecies.value : null,
      diseaseUniqueReference: searchData.sampleDisease ? searchData.sampleDisease : null,
      propertyUniqueReference: searchData.sampleProperty?.value ? searchData.sampleProperty.value : null,
    };
  }

  getSearchDiseaseMonitoringBody(searchData) {
    return {
      stateUniqueReference: this.getCurrentStateId(),
      propertyToSearchFor: searchData.sampleProperty?.value ? searchData.sampleProperty.value : null,
      propertyNameToSearchFor: null,
      dateStart: searchData.sampleDateStart ? new Date(Date.parse(searchData.sampleDateStart)).toISOString() : null,
      dateEnd: searchData.sampleDateEnd ? new Date(Date.parse(searchData.sampleDateEnd)).toISOString() : null,
      subjectId: searchData.subjectId ? searchData.subjectId : null,
      speciesSampled: searchData.sampleSpecies?.value ? searchData.sampleSpecies.value : null,
      diseaseSampled: searchData.sampleDisease ? searchData.sampleDisease : null,
      typeOfSampleEnumId: searchData.diseaseSampleType ? searchData.diseaseSampleType : null,
    };
  }

  handleReassignAgreement = async (fieldAgent) => {
    let agreementsToReassign = [];
    for (let ag of this.state.selectedAgreements) {
      agreementsToReassign.push(ag.agreementUniqueIdentifier);
    }
    let addCallBody = {
      newPrimaryFieldAgentWsUserProfileUniqueReference: fieldAgent.selectedFieldAgent.contactGuid,
      agreementsToApplyReassignTo: agreementsToReassign,
    };

    const addFieldAgentsCall = await AgreementAPI.ReassignAgreementsToNewPrimaryFieldAgent(
      fieldAgent.stateGuid,
      addCallBody
    );
    if (addFieldAgentsCall.successful) {
      toast.success("Field Agent Reassigned");
      let { searchData } = this.props.history.location.state;
      let searchResultsData = await this.getAgreementsDataFromAPI(searchData);

      const newAgreements = searchResultsData.map((ag) => {
        return { ...ag, agreementSelect: false };
      });
      this.setState({ searchResultsData: newAgreements });
    }
  };

  getSearchNRMPBody(searchData) {
    return {
      stateUniqueReference: this.getCurrentStateId(),
      dateNRMPStartedStart: searchData.sampleDateStart
        ? new Date(Date.parse(searchData.sampleDateStart)).toISOString()
        : null,
      dateNRMPStartedEnd: searchData.sampleDateEnd
        ? new Date(Date.parse(searchData.sampleDateEnd)).toISOString()
        : null,
      countyUniqueReference: searchData.sampleCounty?.value ? searchData.sampleCounty.value : null,
      townOrCity: searchData.sampleTownOrCity ? searchData.sampleTownOrCity : null,
      userProfileWhoEnteredNRMP: searchData.sampleProfile?.value ? searchData.sampleProfile.value : null,
      speciesBeingTestedUniqueReference: searchData.sampleSpecies?.value ? searchData.sampleSpecies.value : null,
      waypointNumber: searchData.waypointNumber ? searchData.waypointNumber : null,
      animalId: searchData.animalId ? searchData.animalId : null,
      collectionActivityEnumId: searchData.collectionActivityId ? searchData.collectionActivityId : null,
    };
  }

  getSearchProjectsBody(searchData) {
    return {
      projectName: searchData.projectName,
    };
  }

  getSearchDistrictsBody(searchData) {
    return {
      searchFreetext: searchData.districtName,
    };
  }

  getCurrentStateId() {
    return this.state.currentUsaState?.stateGuid
      ? this.state.currentUsaState.stateGuid
      : JSON.parse(localStorage.getItem("currentState"))?.stateGuid;
  }

  handleEntityTypeChange = (updatedEntityType) => {
    this.setState({ entityType: updatedEntityType });
  };

  render() {
    const { searchResultsData, columns, tableKeyField } = this.state;

    return (
      <div className={styles.searchResultsPage} data-testid="searchAgreements">
        <LoadingOverlay
          active={this.state.isDataLoading}
          spinner
          text="Loading results..."
          styles={{
            wrapper: (base) => ({
              ...base,
              height: "100%",
            }),
            overlay: (base) => ({
              ...base,
              width: "100%",
              height: "100%",
            }),
          }}
        >
          <Row className="mb-2 mt-4">
            <Col>
              <h3 className={styles.searchResultsTitle}>Search Results</h3>
            </Col>
          </Row>

          <Row className="mt-2 mb-5">
            <Col>
              <StructuredSearchCard
                states={this.state.states?.length > 0 ? this.state.states : []}
                currentState={this.state.currentUsaState}
                onSearch={this.handleSearch}
                entityType={this.state.entityType}
                searchData={this.state.searchData}
                onEntityTypeChange={this.handleEntityTypeChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ToolkitProvider
                keyField={tableKeyField}
                data={searchResultsData}
                columns={columns}
                bootstrap4={true}
                exportCSV={{
                  fileName: this.state.entityType + "-search.csv",
                  ignoreHeader: false,
                  noAutoBOM: false,
                  onlyExportFiltered: true,
                  exportAll: false,
                }}
              >
                {(props) => (
                  <Card>
                    <Card.Header className={styles.searchResultsCardHeader}>
                      <Row className={styles.searchResultsAmount}>
                        <Col>
                          {searchResultsData ? searchResultsData.length : ""} Results{" "}
                          {this.state.pagedResultAPIContainer.recordCount > 50
                            ? `of ${this.state.pagedResultAPIContainer.recordCount}. Please use additional search criteria to better narrow your results.`
                            : ""}
                        </Col>
                        <Col align="right" lg={2}>
                          <ExportCSVButton {...props.csvProps} />
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <div align="right">
                        <Button
                          variant="primary"
                          onClick={this.showReassignFAModal}
                          className="mr-2"
                          hidden={this.state.entityType !== SearchTypes.Agreements && searchResultsData}
                        >
                          Reassign WS Specialist
                        </Button>
                        <hr></hr>
                      </div>
                      <BootstrapTable
                        keyField={tableKeyField}
                        data={searchResultsData}
                        columns={columns}
                        filter={filterFactory()}
                        {...props.baseProps}
                        bootstrap4={true}
                        hover={true}
                        bordered={false}
                      />
                    </Card.Body>
                  </Card>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
          <ReassignFieldAgentModal
            show={this.state.showReassignFAModal}
            onHide={() => {
              this.setState({ showReassignFAModal: false });
            }}
            onSave={this.handleReassignAgreement}
            agreements={this.state.selectedAgreements}
          />
        </LoadingOverlay>
      </div>
    );
  }
}

export default withRouter(SearchAgreements);
