import React from "react";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Card, Row, Col, Form, Tabs, Tab, Button } from "react-bootstrap";
import WebMap from "./../ESRIIntegration/EsriMap";
import UtilityFunctions from "../common/UtilityFunctions";
import ParcelMapView from "../ESRIIntegration/ParcelMapView";

class PropertiesDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: this.props.properties,
      currentProperty: this.props.properties[0],
      contacts: this.props.contacts,
      protectedResourcesBody: [],
      targetedSpeciesBody: [],
      agreementTypeEnumId: this.props.agreementTypeEnumId,
      selectedRecColumns: [
        { dataField: "tableId", hidden: true },
        {
          dataField: "recCemName",
          text: "Recommendations",
          headerAlign: "left",
          align: "left",
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
        },
        {
          dataField: "checkedProperties",
          text: "Property",
          formatter: (cell, row) => {
            return row.property?.propertyName;
          },
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
          headerAlign: "left",
          align: "left",
        },
      ],
      selectedCemColumns: [
        { dataField: "tableId", hidden: true },
        {
          dataField: "recCemName",
          text: "CEMs",
          headerAlign: "left",
          align: "left",
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
        },
        {
          dataField: "checkedProperties",
          text: "Property",
          formatter: (cell, row) => {
            return row.property?.propertyName;
          },
          headerStyle: () => {
            return { fontSize: "14px", borderBottom: "0.8px solid #008767" };
          },
          style: { fontSize: "14px" },
          headerAlign: "left",
          align: "left",
        },
      ],
    };
  }

  handleTabClick = (e) => {
    let currentProp = this.props.properties.filter(function (property) {
      return property.tabKey === e;
    });
    this.setState({ currentProperty: currentProp[0] });
  };
  showMap = (property) => {
    if (sessionStorage.getItem("mapAccess") === "Yes") {
      return (
        <ParcelMapView
          parcelDetails={property.parcels}
          address={property.address}
          name={property.propertyName}
          location={property.mapPoint}
          agreementData={""}
        />
      );
    } else {
      return <WebMap address={property.address} name={property.propertyName} location={property.mapPoint} />;
    }
  };

  render() {
    const { recCemData } = this.props;
    return (
      <div>
        <Card className={styles.createPageCards}>
          <Card.Header as="h5" className={styles.cardTitles}>
            <Row>
              <Col align="left">Property Summary</Col>
              <Col align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.props.handleClickEdit(2)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Tabs id="propertiesTabs" onSelect={(e) => this.handleTabClick(e)} variant="pills">
              {this.state.properties.map((property, index) => {
                return (
                  <Tab eventKey={property.tabKey} title={property.propertyName}>
                    <div key={index}>
                      <hr style={{ borderBottom: "0.8px solid #E8E8E8" }} />
                      <Row>
                        <div className={styles.mapContainer} hidden={property.parcels.length > 0}>
                          <WebMap
                            address={property.address}
                            name={property.propertyName}
                            location={
                              property.mapPoint
                                ? property.mapPoint
                                : JSON.stringify([
                                    property?.propertyObject?.latitude?.toString(),
                                    property?.propertyObject?.longitude?.toString(),
                                  ])
                            }
                          />
                        </div>
                        <div className={styles.mapContainer} hidden={property.parcels.length === 0}>
                          {this.showMap(property)}
                        </div>
                      </Row>
                      <Row>
                        <br></br>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId={"property-" + index} className="text-left">
                            <h6 className={styles.fieldLabels}>Property Name</h6>
                            <p className={globalStyles.informationText}>{property.propertyName}</p>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={"property-" + index} className="text-left">
                            <h6 className={styles.fieldLabels}>Property Address</h6>
                            <p className={globalStyles.informationText}>{property.address}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId={"country-" + index} className="text-left">
                            <h6 className={styles.fieldLabels}>County</h6>
                            <p className={globalStyles.informationText}>{property.county}</p>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={"zipCode-" + index} className="text-left">
                            <h6 className={styles.fieldLabels}>State</h6>
                            <p className={globalStyles.informationText}>{property.state}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId={"country-" + index} className="text-left">
                            <h6 className={styles.fieldLabels}>Acreage</h6>
                            <p className={globalStyles.informationText}>{property.acres}</p>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={"zipCode-" + index} className="text-left">
                            <h6 className={styles.fieldLabels}>Land Class</h6>
                            <p className={globalStyles.informationText}>{property.landClass}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards}>
          <Card.Header as="h5" className={styles.cardTitles}>
            <Row>
              <Col align="left">Protected Resources</Col>
              <Col align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.props.handleClickEdit(3)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className={styles.smallTableCardBodyCenter}>
            <div hidden={!this.state.currentProperty.isResourcesSelected} align="left">
              <Row className={styles.smallTableHeaderRow} noGutters="true">
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Year</h6>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Resource Name</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Quantity</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>UOMs</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Unit Value</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Total Value</h6>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Property</h6>
                  </Form.Group>
                </Col>
              </Row>
              {this.state.currentProperty.propertyResources
                ?.sort(UtilityFunctions.agreementEntityTableSortFunc("resourceName"))
                ?.map((resource, index) => {
                  return (
                    <div key={index}>
                      <Row className={styles.smallTableTextRow} noGutters="true">
                        <Col md={1}>
                          <Form.Group controlId={"resource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{resource.resourceYear}</p>
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          <Form.Group controlId={"resource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{resource.resourceName}</p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"resource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{resource.resourceQuantity}</p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"resource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{resource.resourceUom}</p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"resource-" + index} className="m-0">
                            <p className={styles.smallTableText}>
                              {UtilityFunctions.getValueAsCurrency(resource.resourceUnitValue)}
                            </p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"resource-" + index} className="m-0">
                            <p className={styles.smallTableText}>
                              {UtilityFunctions.getValueAsCurrency(resource.resourceTotalValue)}
                            </p>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={"resource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{this.state.currentProperty.propertyName}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </div>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards}>
          <Card.Header as="h5" className={styles.cardTitles}>
            <Row>
              <Col align="left">Targeted Damage Agents</Col>
              <Col align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.props.handleClickEdit(3)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className={styles.smallTableCardBodyCenter}>
            <div hidden={!this.state.currentProperty.isSpceciesSelected} align="left">
              <Row className={styles.smallTableHeaderRow} noGutters="true">
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Damage Agent</h6>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Property</h6>
                  </Form.Group>
                </Col>
              </Row>
              {this.state.currentProperty.propertySpecies
                ?.sort(UtilityFunctions.agreementEntityTableSortFunc("speciesName"))
                ?.map((specie, index) => {
                  return (
                    <div key={index}>
                      <Row className={styles.smallTableTextRow} noGutters="true">
                        <Col>
                          <Form.Group controlId={"specie-" + index} className="m-0">
                            <p className={styles.smallTableText}>{specie.speciesName}</p>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={"specie-" + index} className="m-0">
                            <p className={styles.smallTableText}>{this.state.currentProperty.propertyName}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </div>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards}>
          <Card.Header as="h5" className={styles.cardTitles}>
            <Row>
              <Col align="left">Method</Col>
              <Col align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.props.handleClickEdit(3)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className={styles.smallTableCardBodyCenter}>
            <div hidden={!this.state.currentProperty.isMethodsSelected} align="left">
              <Row className={styles.smallTableHeaderRow} noGutters="true">
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Methods</h6>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Property</h6>
                  </Form.Group>
                </Col>
              </Row>
              {this.state.currentProperty.propertyMethods
                ?.sort(UtilityFunctions.agreementEntityTableSortFunc("methodName"))
                ?.map((method, index) => {
                  return (
                    <div key={index}>
                      <Row className={styles.smallTableTextRow} noGutters="true">
                        <Col>
                          <Form.Group controlId={"method-" + index} className="m-0">
                            <p className={styles.smallTableText}>{method.methodName}</p>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={"method-" + index} className="m-0">
                            <p className={styles.smallTableText}>{this.state.currentProperty.propertyName}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </div>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards} hidden={this.state.agreementTypeEnumId === 2}>
          <Card.Header as="h5" className={styles.cardTitles}>
            <Row>
              <Col align="left">Recommendations and/or Cooperator Employed Methods</Col>
              <Col align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.props.handleClickEdit(3)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className={styles.smallTableCardBodyCenter}>
            <div hidden={!(recCemData?.selectedRecTableData?.length > 0)} align="left">
              <Row className={styles.smallTableHeaderRow} noGutters="true">
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Recommendations</h6>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Property</h6>
                  </Form.Group>
                </Col>
              </Row>
              {recCemData?.selectedRecTableData
                .filter(
                  (r) => r.property?.propertyUniqueIdentifier === this.state.currentProperty?.propertyUniqueIdentifier
                )
                .map((recommendation, index) => {
                  return (
                    <div key={index}>
                      <Row className={styles.smallTableTextRow} noGutters="true">
                        <Col>
                          <Form.Group controlId={"recommendation-" + index} className="m-0">
                            <p className={styles.smallTableText}>{recommendation.recCemName}</p>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={"recommendation-" + index} className="m-0">
                            <p className={styles.smallTableText}>{this.state.currentProperty?.propertyName}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </div>
            <div hidden={!(recCemData?.selectedCemTableData?.length > 0)} align="left" className="mt-3">
              <Row className={styles.smallTableHeaderRow} noGutters="true">
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>CEMs</h6>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Property</h6>
                  </Form.Group>
                </Col>
              </Row>
              {recCemData?.selectedCemTableData
                .filter(
                  (r) => r.property?.propertyUniqueIdentifier === this.state.currentProperty?.propertyUniqueIdentifier
                )
                .map((cem, index) => {
                  return (
                    <div key={index}>
                      <Row className={styles.smallTableTextRow} noGutters="true">
                        <Col>
                          <Form.Group controlId={"cem-" + index} className="m-0">
                            <p className={styles.smallTableText}>{cem.recCemName}</p>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId={"cem-" + index} className="m-0">
                            <p className={styles.smallTableText}>{this.state.currentProperty?.propertyName}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </div>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards}>
          <Card.Header as="h5" className={styles.cardTitles}>
            <Row>
              <Col align="left">Special Instructions</Col>
              <Col align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.props.handleClickEdit(3)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className={styles.centerCardBody}>
            <div hidden={!this.state.currentProperty.isSpecialInstructions} align="left">
              {this.state.currentProperty.propertySpecialInstructions.map((instruction, index) => {
                return (
                  <div key={index}>
                    <Row>
                      <Col>
                        <h6 className={styles.fieldLabels}>{this.state.currentProperty.propertyName}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId={"instruction-" + index} className="text-left">
                          <p className={styles.smallTableText + " p-0"}>{instruction.text}</p>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards}>
          <Card.Header as="h5" className={styles.cardTitles}>
            <Row>
              <Col align="left">Historical Resources and Losses</Col>
              <Col align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.props.handleClickEdit(3)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className={styles.smallTableCardBodyCenter}>
            <div hidden={!this.state.currentProperty.isHistoricalResourcesSelected} align="left">
              <Row className={styles.smallTableHeaderRow} noGutters="true">
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Year</h6>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Resource Name</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Quantity</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>UOMs</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Unit Value</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Total Value</h6>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Property</h6>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group className="m-0">
                    <h6 className={styles.smallTableHeader}>Total Loss Value</h6>
                  </Form.Group>
                </Col>
                <Col>
                  <p></p>
                </Col>
              </Row>
              {this.state.currentProperty.propertyHistoricalResources
                ?.sort(UtilityFunctions.agreementEntityTableSortFunc("resourceName"))
                ?.map((resource, index) => {
                  let totalLosses;
                  if (resource.historicalLosses?.length > 0) {
                    totalLosses = resource.historicalLosses.reduce(
                      (acumulator, currentLoss) => acumulator + currentLoss.totalValue,
                      0
                    );
                  }

                  return (
                    <div key={index}>
                      <Row className={styles.smallTableTextRow} noGutters="true">
                        <Col md={1}>
                          <Form.Group controlId={"hresource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{resource.resourceYear}</p>
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          <Form.Group controlId={"hresource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{resource.resourceName}</p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"hresource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{resource.resourceQuantity}</p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"hresource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{resource.resourceUom}</p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"hresource-" + index} className="m-0">
                            <p className={styles.smallTableText}>
                              {UtilityFunctions.getValueAsCurrency(resource.resourceUnitValue)}
                            </p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"hresource-" + index} className="m-0">
                            <p className={styles.smallTableText}>
                              {UtilityFunctions.getValueAsCurrency(resource.resourceTotalValue)}
                            </p>
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          <Form.Group controlId={"hresource-" + index} className="m-0">
                            <p className={styles.smallTableText}>{this.state.currentProperty.propertyName}</p>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group controlId={"resourceTotalLoss-" + index} className="m-0">
                            {totalLosses ? (
                              <Button
                                className={styles.smallTableText}
                                variant="link"
                                onClick={() => this.props.viewLossModal(resource)}
                              >
                                {UtilityFunctions.getValueAsCurrency(totalLosses)}
                              </Button>
                            ) : (
                              <p className={styles.smallTableText}>N/A</p>
                            )}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="m-0 text-right">
                            <Button
                              variant="link"
                              className={styles.smallTableText}
                              onClick={() => this.props.viewLossModal(resource)}
                            >
                              View Loss
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </div>
          </Card.Body>
        </Card>
        <Card className={styles.createPageCards} hidden={this.state.agreementTypeEnumId === 2}>
          <Card.Header as="h5" className={styles.cardTitles}>
            <Row>
              <Col align="left">Contacts</Col>
              <Col align="right">
                <Button
                  className={styles.addButtonLinksInHeader}
                  variant="link"
                  onClick={() => this.props.handleClickEdit(3)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className={styles.centerCardBody}>
            <div hidden={!this.state.currentProperty.isContacts} align="left">
              {this.state.currentProperty.propertyContacts.map((contact, index) => {
                return (
                  <div key={index}>
                    <Row className={styles.informationRow} noGutters="true">
                      <Col align="left">
                        <Button
                          className="p-0"
                          variant="link"
                          onClick={() => this.props.goToDetails(contact.contactObject)}
                        >
                          <p style={{ fontSize: "14px", textAlign: "left", margin: "0" }}>{contact.contactName}</p>
                        </Button>
                      </Col>
                      <Col align="left">
                        <p className={globalStyles.informationText}>
                          {contact.phoneNumber
                            ? UtilityFunctions.formatPhoneNumber(contact.phoneNumber)
                            : "No Phone Number"}
                        </p>
                      </Col>
                      <Col align="left">
                        <p className={globalStyles.informationText}>
                          {contact.emailAddress ? contact.emailAddress : "No Email"}
                        </p>
                      </Col>
                      <Col align="left">
                        <p className={globalStyles.informationText}>{contact.agreementRole}</p>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default PropertiesDisplay;
