import API from "../API";
import { APIRoutes } from "../APIRoutes";

class AgreementAPI {
  static async GetAllAgreementTypes() {
    let url = "/Agreements/GetAllAgreementTypes";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async GetAllAgreementRenewalStatuses() {
    let url = "/Agreements/GetAllAgreementRenewalStatuses";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async GetAllAgreementStatuses() {
    let url = "/Agreements/GetAllAgreementStatuses";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async GetAllAgreementContactRoles(signal) {
    let url = "/Agreements/GetAllAgreementContactRoles";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async GetAgreement(agreementUniqueIdentifier, signal) {
    let url = "/Agreements/GetAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async Get12aAttachment(agreementUniqueIdentifier) {
    let url = "/Agreements/Get12aAttachment/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get([{ optionName: "responseType", optionContent: "blob" }]);
    return result;
  }

  static async GetSignature(agreementUniqueIdentifier) {
    let url = "/Agreements/GetSignature/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async GetOtherAgreementAttachment(agreementUniqueIdentifier, agreementOtherAttachmentUniqueIdentifier) {
    let url =
      "/Agreements/GetOtherAgreementAttachment/" +
      agreementUniqueIdentifier +
      "/" +
      agreementOtherAttachmentUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get([{ optionName: "responseType", optionContent: "blob" }]);
    return result;
  }

  static async CreateAgreement(body) {
    let url = "/Agreements/CreateAgreement";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddFieldAgentsToAgreement(agreementUniqueIdentifier, body) {
    const api = new API(`/Agreements/AddFieldAgentsToAgreement/${agreementUniqueIdentifier}`, APIRoutes.Agreements);
    const result = await api.post(body);
    return result;
  }

  static async ReassignAgreementsToNewPrimaryFieldAgent(stateUniqueIdentifier, body) {
    const api = new API(
      `/Agreements/ReassignAgreementsToNewPrimaryFieldAgent/${stateUniqueIdentifier}`,
      APIRoutes.Agreements
    );
    const result = await api.post(body);
    return result;
  }

  static async RemoveFieldAgentsFromAgreement(agreementUniqueIdentifier, body) {
    const api = new API(
      `/Agreements/RemoveFieldAgentsFromAgreement/${agreementUniqueIdentifier}`,
      APIRoutes.Agreements
    );
    const result = await api.post(body);
    return result;
  }

  static async AddPropertiesToAgreement(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddPropertiesToAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddCooperatorRoleToAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddCooperatorRoleToAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemovePropertiesFromAgreement(agreementUniqueIdentifier, body) {
    let url = "/Agreements/RemovePropertiesFromAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddSpecialInstructionsToAgreement(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddSpecialInstructionsToAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddContactsToAgreement(agreementUniqueIdentifier, body) {
    const api = new API(`/Agreements/AddContactsToAgreement/${agreementUniqueIdentifier}`, APIRoutes.Agreements);
    const result = await api.post(body);
    return result;
  }

  static async RemoveContactsFromAgreement(agreementUniqueIdentifier, body) {
    const api = new API(`/Agreements/RemoveContactsFromAgreement/${agreementUniqueIdentifier}`, APIRoutes.Agreements);
    const result = await api.post(body);
    return result;
  }

  static async AddContactsToAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddContactsToAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveContactsFromAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/RemoveContactsFromAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddAllowedMethodsToAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddAllowedMethodsToAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddContactsToAgreementParcels(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddContactsToAgreementParcels/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddAllowedMethodsToAgreementParcels(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddAllowedMethodsToAgreementParcels/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddSpecialInstructionsToAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddSpecialInstructionsToAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveSpecialInstructionsFromAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/RemoveSpecialInstructionsFromAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddRecCemsToAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddRecCemsToAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveRecommendationsFromAgreementProperties(agreementUniqueIdentifier, body) {
    const api = new API(
      `/Agreements/RemoveRecommendationsFromAgreementProperties/${agreementUniqueIdentifier}`,
      APIRoutes.Agreements
    );
    let result = await api.post(body);
    return result;
  }

  static async RemoveCooperatorEmployedMethodsFromAgreementProperties(agreementUniqueIdentifier, body) {
    const api = new API(
      `/Agreements/RemoveCooperatorEmployedMethodsFromAgreementProperties/${agreementUniqueIdentifier}`,
      APIRoutes.Agreements
    );
    let result = await api.post(body);
    return result;
  }

  static async AddTargetedSpeciesToAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddTargetedSpeciesToAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveTargetedSpeciesFromAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/RemoveTargetedSpeciesFromAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveAllowedMethodsFromAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/RemoveAllowedMethodsFromAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddTargetedSpeciesToAgreementParcels(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddTargetedSpeciesToAgreementParcels/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveTargetedSpeciesFromAgreementParcels(agreementUniqueIdentifier, body) {
    let url = "/Agreements/RemoveTargetedSpeciesFromAgreementParcels/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveAllowedMethodsFromAgreementParcels(agreementUniqueIdentifier, body) {
    let url = "/Agreements/RemoveAllowedMethodsFromAgreementParcels/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddResourcesToAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddResourcesToAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveResourcesFromAgreementProperties(body) {
    let url = "/Agreements/RemoveResourcesFromAgreementProperties";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async UpdateResourcesOnAgreementProperties(agreementUniqueIdentifier, body) {
    let url = `/Agreements/UpdateResourcesOnAgreementProperties/${agreementUniqueIdentifier}`;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async AddHistoricalLossesToAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/AddHistoricalLossesToAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async RemoveHistoricalLossesFromAgreementProperties(agreementUniqueIdentifier, body) {
    let url = "/Agreements/RemoveHistoricalLossesFromAgreementProperties/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async SignAgreement12a(agreementUniqueIdentifier, expirationDate, signatureDate, body) {
    let url = "/Agreements/SignAgreement12a/" + agreementUniqueIdentifier + "/" + expirationDate + "/" + signatureDate;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async UploadOtherAgreementAttachments(agreementUniqueIdentifier, body) {
    let url = "/Agreements/UploadOtherAgreementAttachments/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async SetAgreementExpirationDate(agreementUniqueIdentifier, expirationDate) {
    let url = "/Agreements/SetAgreementExpirationDate/" + agreementUniqueIdentifier + "/" + expirationDate;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async SearchAgreementFreeText(body, stateUniqueIdentifier) {
    let url = "/Agreements/SearchAgreementFreeText/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.put(body);
    return result;
  }

  static async GetAgreementsByProperty(propertyUniqueIdentifier) {
    let url = "/Agreements/GetAgreementsByProperty/" + propertyUniqueIdentifier + "/10";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async GetAgreementsByContact(contactUniqueIdentifier) {
    const api = new API(`/Agreements/GetAgreementsByContact/${contactUniqueIdentifier}/10`, APIRoutes.Agreements);
    const result = await api.get();
    return result;
  }

  static async SearchActiveAgreementPropertiesByTargetedSpecies(body) {
    let url = "/Agreements/SearchActiveAgreementPropertiesByTargetedSpecies";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.put(body);
    return result;
  }

  static async GetTargetedSpeciesByActiveAgreementProperties(body) {
    let url = "/Agreements/GetTargetedSpeciesByActiveAgreementProperties";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.put(body);
    return result;
  }

  static async ConfirmPropertyBelongsToActiveAgreementOnDate(body) {
    let url = "/Agreements/ConfirmPropertyBelongsToActiveAgreementOnDate";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.put(body);
    return result;
  }

  static async GetAgreementPropertyFromActiveAgreement(propertyUniqueIdentifier) {
    let url = "/Agreements/GetAgreementPropertyFromActiveAgreement/" + propertyUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async GetActiveAgreementByProperty(propertyUniqueIdentifier) {
    let url = "/Agreements/GetActiveAgreementByProperty/" + propertyUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async SendAgreementForApproval(agreementUniqueIdentifier) {
    let url = "/Agreements/SendAgreementForApproval/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async ExtendFederalLandAgreementOneQuarter(agreementUniqueIdentifier) {
    let url = "/Agreements/ExtendFederalLandAgreementOneQuarter/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async RecallAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/RecallAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async ApproveAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/ApproveAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async UpdateAgreement(agreementUniqueIdentifier, body) {
    const api = new API(`/Agreements/UpdateAgreement/${agreementUniqueIdentifier}`, APIRoutes.Agreements);
    const result = await api.post(body);
    return result;
  }

  static async RemoveDraftAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/RemoveDraftAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async ResourcesNotRequiredForAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/ResourcesNotRequiredForAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async ResourcesRequiredForAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/ResourcesRequiredForAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async HistoricalResourcesNotRequiredForAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/HistoricalResourcesNotRequiredForAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async HistoricalResourcesRequiredForAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/HistoricalResourcesRequiredForAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async HistoricalLossesNotRequiredForAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/HistoricalLossesNotRequiredForAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async HistoricalLossesRequiredForAgreement(agreementUniqueIdentifier) {
    let url = "/Agreements/HistoricalLossesRequiredForAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async GetMyAgreements(profileId, agreementStatusId) {
    let url = `/Agreements/GetMyAgreements/${profileId}/${agreementStatusId}`;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async GetMyAgreementsInState(stateGuid, profileId, agreementStatusId) {
    let url = `/Agreements/GetMyAgreementsInState/${stateGuid}/${profileId}/${agreementStatusId}`;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }

  static async AgreementStructuredSearch(body, signal) {
    let url = "/Agreements/AgreementStructuredSearch";
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.put(body, [{ optionName: "signal", optionContent: signal }]);
    return result;
  }
  static async FlagAgreement(agreementUniqueIdentifier, body) {
    let url = "/Flag/FlagAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }
  static async GetFlagsForAgreement(agreementUniqueIdentifier) {
    let url = "/Flag/GetFlagsForAgreement/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }
  static async AddMessageToFlag(flagUniqueIdentifier, body) {
    let url = "/Flag/AddMessageToFlag/" + flagUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post(body);
    return result;
  }

  static async GetAllActiveFlagsInState(stateUniqueIdentifier, signal) {
    let url = "/Flag/GetAllActiveFlagsInState/" + stateUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get([{ optionName: "signal", optionContent: signal }]);
    return result;
  }

  static async GetAgreementsForApprovalInState(stateUniqueIdentifier, signal) {
    let url = `/Agreements/GetAgreementsForApprovalInState/${stateUniqueIdentifier}/0/0`;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    if (result?.data?.recordCount > 0) {
      url =
        `/Agreements/GetAgreementsForApprovalInState/${stateUniqueIdentifier}/` + result?.data?.recordCount + `/` + 1;
      api = new API(url, APIRoutes.Agreements);
      result = await api.get([{ optionName: "signal", optionContent: signal }]);
    }
    return result;
  }

  static async SetupAgreementForAmendmentOrRenewal(agreementUniqueIdentifier) {
    let url = "/Agreements/SetupAgreementForAmendmentOrRenewal/" + agreementUniqueIdentifier;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.post();
    return result;
  }

  static async GetWorkableAgreementForParcel(esriParcelId) {
    const api = new API(`/Agreements/GetWorkableAgreementForParcel/${esriParcelId}`, APIRoutes.Agreements);
    const result = await api.get();
    return result;
  }

  static async CanTheseParcelsMakeUpANewAgreement(parcelIdArray) {
    const api = new API(`/Agreements/CanTheseParcelsMakeUpANewAgreement`, APIRoutes.Agreements);
    const result = await api.put(parcelIdArray);
    return result;
  }

  static async GetAmendedAgreementChangedFields(agreementUniqueId, amendedAgreementUniqueIdId) {
    let url = `/Agreements/GetAmendedAgreementChangedFields/${agreementUniqueId}/${amendedAgreementUniqueIdId}`;
    let api = new API(url, APIRoutes.Agreements);
    let result = await api.get();
    return result;
  }
}

export default AgreementAPI;
