import React from "react";
import styles from "./CreatePermitModal.module.scss";
import globalStyles from "../../../OARS.module.scss";
import { Modal, Row, Col, Form, Button, Image } from "react-bootstrap";
import CustomForm from "../../common/form";
import UtilityFunctions from "../../common/UtilityFunctions";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { components } from "react-select";
import searchIcon from "../../../assets/search.svg";
import ReferenceFileAPI from "../../../api/ReferenceFiles/ReferenceFileAPI";
import WorkTaskAPI from "../../../api/WorkTask/WorkTaskAPI";
import xCircle from "../../../assets/x-circle.svg";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      There seems to be no UOMs for this damage agent, please try reselecting the damage agent or check the reference
      file.
    </components.NoOptionsMessage>
  );
};

class CreatePermitModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        selectedStates: [],
        permitStartDate: "",
        permitExpirationDate: "",
        permitNumber: "",
        permitName: "",
        selectedIssuingAgency: "",
        totalTakesCap: "",
        totalThreshold: "",
        notification: "",
        speciesList: [],
        filesToAttach: [],
      },
      uomList: [],
      issuedByList: [],
      notificationList: [],
      errors: {},
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    if (prevProps.show !== this.props.show && this.props.show) {
      if (!this.props.fromDetailsPage) {
        data.speciesList.push({
          index: 0,
          species: "",
          speciesUomList: [],
          speciesUom: 0,
          totalCapForSpecies: 0,
          threshold: 0,
          speciesNotification: 0,
        });
        this.setState({
          data,
          uomList: await this.getSpeciesUOMsFromAPI(),
          issuedByList: await this.getExternalOrganizationsForState(this.props.currentUsaState.stateGuid),
          notificationList: await this.getNotificationOptions(),
        });
      } else {
        if (this.props.detailsState?.speciesOfPermit?.length < 1) {
          data.speciesList.push({
            index: 0,
            species: "",
            speciesUomList: [],
            speciesUom: 0,
            totalCapForSpecies: 0,
            threshold: 0,
            speciesNotification: 0,
          });
        } else {
          data.speciesList = this.props.detailsState.speciesOfPermit.map((s, index) => {
            s.species = { label: s.speciesName, value: s.speciesUniqueReference };
            s.index = index;
            return s;
          });
        }
        this.setState({
          data,
          notificationList: await this.getNotificationOptions(),
          uomList: await this.getSpeciesUOMsFromAPI(),
        });
      }
    }
  }

  async getSpeciesUOMsFromAPI() {
    return [
      {
        id: 1,
        name: "nest",
        displayText: "Nest",
      },
      {
        id: 2,
        name: "each",
        displayText: "Each",
      },
      {
        id: 3,
        name: "burrow",
        displayText: "Burrow",
      },
    ];
  }

  async getExternalOrganizationsForState(stateGuid) {
    const orgs = await ReferenceFileAPI.GetAllExternalOrganizationsInState(stateGuid);
    let orgsList = [];
    if (orgs?.successful) {
      const orgssData = orgs.data?.results;
      for (let i = 0; i < orgssData.length; i++) {
        if (orgssData[i].isAllowedInState) {
          orgsList.push({
            value: orgssData[i].externalOrganizationUniqueIdentifier,
            label: orgssData[i].externalOrganizationName,
          });
        }
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get all external organizations in the state",
        apiName: "GetAllExternalOrganizationsInState",
        responseUnsuccessful: orgs?.unsuccessful,
        responseMessage: orgs?.message,
      });
    }
    return orgsList;
  }

  getSpeciesFromAPI = async (searchValue) => {
    let searchObj = {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
    };

    if (this.props.currentUsaState?.stateGuid) {
      const speciesData = await ReferenceFileAPI.FilterSpecies(this.props.currentUsaState.stateGuid, searchObj);

      if (speciesData?.data?.results) {
        let results = speciesData.data.results;
        return results.map((result) => ({
          value: result.speciesUniqueIdentifier,
          label: result.name,
          allowedUOMsInState: result.allowedUOMsInState,
        }));
      }
    }
    return "";
  };

  getNotificationOptions = async () => {
    const notifResponse = await WorkTaskAPI.GetNotificationActionOptions();
    if (notifResponse?.successful) {
      return notifResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to  retreive Notification options.",
        apiName: "GetNotificationActionOptions",
        responseUnsuccessful: notifResponse?.unsuccessful,
        responseMessage: notifResponse?.message,
      });
      return [];
    }
  };

  handleSpecificChanges = async (input) => {
    const { data } = this.state;
    if (input.name === "totalTakesCap") {
      if (parseInt(input.value) < 0) {
        data.totalThreshold = 0;
        data.totalTakesCap = 0;
      } else {
        let thresholdValue = parseInt((input.value ? input.value : 0) * 0.85);
        data.totalTakesCap = parseInt(input.value);
        data.totalThreshold = thresholdValue;
      }
      this.setState({ data });
    }
    if (input.name === "totalThreshold") {
      if (parseInt(input.value) < 0) {
        data.totalThreshold = 0;
      } else if (parseInt(input.value) > data.totalTakesCap) {
        data.totalThreshold = data.totalTakesCap;
      } else {
        data.totalThreshold = parseInt(input.value);
      }
      this.setState({ data });
    }
  };

  handleStateSelection = (e) => {
    const { data } = this.state;
    data.selectedStates = e;
    this.setState({ data });
  };

  handleIssuedBySelection = (e) => {
    const { data } = this.state;
    data.selectedIssuingAgency = e;
    this.setState({ data });
  };

  handleNotificationSelection = (e) => {
    const { data } = this.state;
    data.notification = e;
    this.setState({ data });
  };

  handleSpeciesSelection = (input, i) => {
    const { data } = this.state;
    data.speciesList = data.speciesList.map((item) => {
      if (item.index !== i) {
        return item;
      } else {
        item.species = input;
        item.speciesUomList = input.allowedUOMsInState;
        return item;
      }
    });
    this.setState({ data });
  };

  handleUOMSelection = (input, i) => {
    const { data } = this.state;
    data.speciesList = data.speciesList.map((item) => {
      if (item.index !== i) {
        return item;
      } else {
        item.speciesUom = input;
        return item;
      }
    });
    this.setState({ data });
  };

  handleSpeciesNotificationSelection = (input, i) => {
    const { data } = this.state;
    data.speciesList = data.speciesList.map((item) => {
      if (item.index !== i) {
        return item;
      } else {
        item.speciesNotification = input;
        return item;
      }
    });
    this.setState({ data });
  };

  handleTakeCapChange = (input, i) => {
    const { data } = this.state;
    data.speciesList = data.speciesList.map((item) => {
      if (item.index !== i) {
        return item;
      } else {
        if (parseInt(input.target.value) < 0) {
          item.threshold = 0;
          item.totalCapForSpecies = 0;
        } else {
          let thresholdValue = parseInt((input.target.value ? input.target.value : 0) * 0.85);
          item.totalCapForSpecies = parseInt(input.target.value);
          item.threshold = thresholdValue;
        }
        return item;
      }
    });

    this.setState({ data });
  };

  handleThresholdChange = (input, i) => {
    const { data } = this.state;
    data.speciesList = data.speciesList.map((item) => {
      if (item.index !== i) {
        return item;
      } else {
        if (parseInt(input.target.value) < 0) {
          item.threshold = 0;
        } else if (parseInt(input.target.value) > item.totalCapForSpecies) {
          item.threshold = item.totalCapForSpecies;
        } else {
          item.threshold = parseInt(input.target.value);
        }
        return item;
      }
    });
    this.setState({ data });
  };

  handleRemoveSpecies = (i) => {
    const { data } = this.state;
    if (this.state.data.speciesList.length !== 1) {
      data.speciesList = data.speciesList
        .filter((item) => item.index !== i)
        .map((item, i) => {
          item.index = i;
          return item;
        });
    }
    this.setState({ data });
  };

  handleAddSpecies = () => {
    const { data } = this.state;
    data.speciesList.push({
      index: data.speciesList.length,
      species: "",
      speciesUom: 0,
      speciesUomList: [],
      totalCapForSpecies: 0,
      threshold: 0,
      speciesNotification: 0,
    });

    this.setState({ data });
  };

  handleFileSelection = (e) => {
    const { data } = this.state;
    let file = e.currentTarget.files;
    data.filesToAttach = [...data.filesToAttach, ...Array.from(file)];
    if (file) this.setState({ data });
  };

  validateData() {
    const { data } = this.state;
    return (
      data.selectedStates.length > 0 &&
      data.permitName &&
      data.permitStartDate &&
      data.permitExpirationDate &&
      data.selectedIssuingAgency
    );
  }

  handleSubmit = async () => {
    if (this.props.fromDetailsPage) {
      this.props.handleSpeciesEdit(this.state.data.speciesList);
      return;
    }
    if (!this.validateData()) {
      toast.warn("Please fill out all required fields");
      return;
    }
    const { data } = this.state;
    let obj = {
      permitNumber: data.permitNumber,
      permitName: data.permitName,
      permitStartDate: new Date(data.permitStartDate.split(" ")[0]).toISOString(),
      permitExpirationDate: new Date(data.permitExpirationDate.split(" ")[0]).toISOString(),
      issuedByIssuingAgencyUniqueReference: data.selectedIssuingAgency.value,
      totalTakesCap: data.totalTakesCap ? data.totalTakesCap : 0,
      threshold: data.totalThreshold ? data.totalThreshold : 0,
      notificationActionEnumId: data.notification?.value,
      statesPermitIsEffectiveIn: data.selectedStates.map((state) => {
        return { stateUniqueReference: state.value };
      }),
      speciesPermitAllowsToBeTaken: data.speciesList
        .filter((item) => item.species)
        .map((species) => {
          return {
            speciesUniqueReference: species.species.value,
            totalCapForSpecies: species.totalCapForSpecies,
            threshold: species.threshold,
            notificationActionEnumId: species?.speciesNotification?.value,
            speciesUnitOfMeasureReferenceFileEnumId: species?.speciesUom?.value,
          };
        }),
    };
    const createPermit = await WorkTaskAPI.CreatePermit(obj);
    if (createPermit?.successful) {
      if (data.filesToAttach.length > 0) {
        let attachmentFailResponsesFileNames = [];
        await Promise.all(
          data.filesToAttach.map(async (file) => {
            let data = new FormData();
            data.append("permitPDF", file, file?.name);
            let attachPDFToPermitResponse = await WorkTaskAPI.AttachPDFToPermit(
              data,
              createPermit.data?.permitUniqueIdentifier
            );
            if (!attachPDFToPermitResponse?.successful) {
              attachmentFailResponsesFileNames.push(
                file?.name +
                  " failed to attach. " +
                  (attachPDFToPermitResponse ? attachPDFToPermitResponse.message + " " : "")
              );
            }
          })
        );
        if (attachmentFailResponsesFileNames.length !== 0) {
          ErrorHandler.showErrorWithMessage(
            "Unable to attach some files. Please try again later. " + attachmentFailResponsesFileNames.toString()
          );
        }
      }
      toast.success("Successfully created Permit");
      this.closeModal();
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create permit",
        apiName: "CreatePermit",
        responseUnsuccessful: createPermit?.unsuccessful,
        responseMessage: createPermit?.message,
      });
    }
  };

  closeModal = () => {
    if (this.props.fromDetailsPage) {
      this.props.onHide();
      return;
    }
    let { data } = this.state;
    data = {
      selectedStates: [],
      permitStartDate: "",
      permitExpirationDate: "",
      permitNumber: "",
      permitName: "",
      selectedIssuingAgency: "",
      totalTakesCap: "",
      totalThreshold: "",
      notification: "",
      speciesList: [],
      filesToAttach: [],
    };
    this.setState({ data });
    this.props.onHide();
  };

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
      minHeight: "35px",
    }),
    container: (base) => ({
      ...base,
      minHeight: "35px",
    }),
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.closeModal} backdrop="static" centered size="xl">
        <Form noValidate>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>
              {this.props.fromDetailsPage ? "Edit Damage Agent" : "Create Permit"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <div hidden={this.props.fromDetailsPage}>
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      States<span className={globalStyles.asterisk508}>{" *"}</span>
                    </Form.Label>
                    <Select
                      value={this.state.data.selectedStates}
                      aria-label="States Selection"
                      placeholder="Select"
                      isMulti={true}
                      styles={this.customSelectStyles}
                      options={this.props.states.map((state) => {
                        return { value: state.stateUniqueIdentifier, label: state.state_code };
                      })}
                      onChange={(e) => this.handleStateSelection(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className={"pr-2 " + globalStyles.formLabel}>Attach Permits</Form.Label>
                    <input onChange={this.handleFileSelection} type="file" accept=".pdf" multiple />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mt-3 mb-3">
                    {this.state.data.filesToAttach.map((file, index) => {
                      return (
                        <Row key={file?.name + index}>
                          <Col align="left">
                            <Button
                              className={styles.removePDFLinkButton}
                              variant="link"
                              onClick={() => {
                                let url = window.URL.createObjectURL(file);
                                window.open(url, "_blank");
                              }}
                            >
                              <Row>
                                <Col>
                                  <span className={styles.actionButtonsText}>{file?.name ? file.name : "No Name"}</span>
                                </Col>
                              </Row>
                            </Button>
                          </Col>
                          <Col align="right">
                            <Image
                              hidden={this.state.isDetailsPage}
                              style={{ width: "15px", cursor: "pointer" }}
                              src={xCircle}
                              onClick={() => {
                                let { data } = this.state;
                                data.filesToAttach.splice(index, 1);
                                this.setState({ data });
                              }}
                              tabIndex="0"
                              alt={"Remove file named: " + file?.name}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>{this.renderInput("permitNumber", "Permit Number", "text", "Enter")}</Col>
                <Col>{this.renderInput("permitName", "Permit Name", "text", "Enter", "required")}</Col>
                <Col>{this.renderInput("permitStartDate", "Permit Start Date", "date", "Select Date", "required")}</Col>
                <Col>
                  {this.renderInput(
                    "permitExpirationDate",
                    "Permit Expiration Date",
                    "date",
                    "Select Date",
                    "required"
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>
                      Issued By<span className={globalStyles.asterisk508}>{" *"}</span>
                    </Form.Label>
                    <Select
                      value={this.state.data.selectedIssuingAgency}
                      aria-label="issuedByAgency"
                      placeholder="Select"
                      styles={this.customSelectStyles}
                      options={this.state.issuedByList}
                      onChange={(e) => this.handleIssuedBySelection(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>{this.renderInput("totalTakesCap", "Total Takes Cap", "number", "Enter")}</Col>
                <Col>{this.renderInput("totalThreshold", "Threshold", "number", "Auto")}</Col>
                <Col>
                  <Form.Group>
                    <Form.Label className={globalStyles.formLabel}>Notification</Form.Label>
                    <Select
                      value={this.state.data.notification}
                      aria-label="permit notification"
                      placeholder="Select"
                      styles={this.customSelectStyles}
                      options={this.state.notificationList?.map((notif) => {
                        return { value: notif.id, label: notif.displayText };
                      })}
                      onChange={(e) => this.handleNotificationSelection(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {this.state.data.speciesList.map((item, i) => {
              return (
                <div key={i + "speciesList"}>
                  <Row className={styles.speciesListBackground}>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>Damage Agents List</Form.Label>
                        <AsyncSelect
                          value={item.species}
                          aria-label="Search For Damage Agents"
                          placeholder="Search here.."
                          components={{ DropdownIndicator }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "",
                              primary: "#ced4da",
                            },
                          })}
                          styles={this.customSelectStyles}
                          loadOptions={this.getSpeciesFromAPI}
                          onChange={(e) => this.handleSpeciesSelection(e, item.index, item)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={styles.speciesListSmallCol}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>UOM</Form.Label>
                        <Select
                          value={
                            item.speciesUom
                              ? {
                                  value: item.speciesUom?.value,
                                  label: item.speciesUom?.label,
                                }
                              : item.unitOfMeasure
                              ? {
                                  value: item.unitOfMeasure?.id,
                                  label: item.unitOfMeasure?.displayText,
                                }
                              : ""
                          }
                          aria-label="UOM"
                          placeholder="Select"
                          styles={this.customSelectStyles}
                          options={item.speciesUomList?.map((uom) => {
                            return { value: uom.id, label: uom.displayText };
                          })}
                          components={{ NoOptionsMessage }}
                          onChange={(e) => this.handleUOMSelection(e, item.index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={styles.speciesListSmallCol}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>Take Cap</Form.Label>
                        <Form.Control
                          aria-label={"Damage Agents List Take Cap" + i}
                          name="takecap"
                          type="number"
                          className={globalStyles.formData}
                          placeholder="Enter"
                          onChange={(e) => this.handleTakeCapChange(e, item.index)}
                          value={item.totalCapForSpecies}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>Threshold</Form.Label>
                        <Form.Control
                          aria-label={"Damage Agents List Threshold" + i}
                          name="threshold"
                          type="number"
                          className={globalStyles.formData}
                          placeholder="Enter"
                          onChange={(e) => this.handleThresholdChange(e, item.index)}
                          value={item.threshold}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className={globalStyles.formLabel}>Notification</Form.Label>
                        <Select
                          value={
                            item.speciesNotification
                              ? {
                                  value: item.speciesNotification?.value,
                                  label: item.speciesNotification?.label,
                                }
                              : item.notificationAction
                              ? {
                                  value: item.notificationAction?.id,
                                  label: item.notificationAction?.displayText,
                                }
                              : ""
                          }
                          aria-label="Damage Agent notification"
                          placeholder="Select"
                          styles={this.customSelectStyles}
                          options={this.state.notificationList?.map((notif) => {
                            return { value: notif.id, label: notif.displayText };
                          })}
                          onChange={(e) => this.handleSpeciesNotificationSelection(e, item.index)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col align="right">
                      <Button variant="link" onClick={() => this.handleRemoveSpecies(item.index)}>
                        <span className={globalStyles.formDataLinks}>
                          {this.state.data.speciesList.length !== 1 ? "- Remove" : ""}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
            <Row>
              <Col align="right">
                <Button variant="link" onClick={() => this.handleAddSpecies()}>
                  <span className={globalStyles.formDataLinks}>{"+ Add another Damage Agent"}</span>
                </Button>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.closeModal}>
              <span>Cancel</span>
            </Button>
            <Button className="ml-2" variant="primary" onClick={this.handleSubmit}>
              <span>{this.props.fromDetailsPage ? "Save" : "Create"}</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
export default CreatePermitModal;
