import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Image, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";
import { toast } from "react-toastify";

class CreateEditAdminWorkTaskActivityModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      validated: false,
      data: this.props?.modalData
        ? this.props.modalData
        : {
            adminActivityUniqueIdentifier: "",
            activityName: "",
            description: "",
            isEnabledGlobally: true,
            isEnabledInState: true,
            createdDateTimeUTC: "",
            lastModifiedDateTimeUTC: "",
          },
      errors: {},
    };
  }

  submitModal = () => {
    let { data } = this.state;
    if (!data.activityName || !data.description) {
      toast.error("Please fill out the name and description.");
      return;
    }
    this.props.onSubmit(data, this.state.modalMode);
    this.props.onHide();
  };

  modalCleanUp = () => {
    let { data } = this.state;
    data = {};
    this.setState({ data });
    this.props.onHide();
  };

  createActivityModalUI() {
    return (
      <div className={styles.createDamageModalBody}>
        <Row>
          <Col>{this.renderInput("activityName", "Activity Name", "text", "Enter name", "required")}</Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="adminWorkTaskDescription" className="formLabel">
              Activity Description
              <span className="required"> *</span>
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              id="adminWorkTaskDescription"
              as="textarea"
              name="description"
              value={this.state.data.description}
              placeholder="Enter Description"
              rows="5"
              onChange={this.handleChange}
              aria-labelledby="adminWorkTaskDescriptionLabel"
              className="form-control formData"
            />
          </Col>
        </Row>
      </div>
    );
  }

  editActivityModalUI() {
    return (
      <div className={styles.editDamageModalBody}>
        <Row>
          <Col className={styles.editDamageModalGuid}>
            <label htmlFor="editDamageModalGuid" className={globalStyles.modalFormLabel}>
              ID
            </label>
            <p id="editDamageModalGuid">{this.state.data.adminActivityUniqueIdentifier}</p>
          </Col>
        </Row>
        <Row>
          <Col className={styles.awtActivityNameEditModalRow}>
            {this.renderInput("activityName", "Activity Name", "text", "Enter name", "required")}
          </Col>
        </Row>
        <div className={styles.awtActivityDescriptionEditModalRow}>
          <Row>
            <Col>
              <label htmlFor="adminWorkTaskDescription" className="formLabel">
                Activity Description
                <span className="required"> *</span>
              </label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                id="adminWorkTaskDescription"
                as="textarea"
                name="description"
                value={this.state.data.description}
                placeholder="Enter Description"
                rows="5"
                onChange={this.handleChange}
                aria-labelledby="adminWorkTaskDescriptionLabel"
                className="form-control formData"
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col className={styles.editDamageModalStatus}>
            {this.props.useGlobalData
              ? this.renderInlineCheckBoxSet(
                  "isEnabledGlobally",
                  "Status",
                  ["Active", "Inactive"],
                  "radio",
                  this.state.data.isEnabledGlobally ? "Active" : "Inactive"
                )
              : this.renderInlineCheckBoxSet(
                  "isEnabledInState",
                  "Allowed",
                  ["Yes", "No"],
                  "radio",
                  this.state.data.isEnabledInState ? "Yes" : "No"
                )}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <span className={styles.editDamageModalDates}>
              Date added: {new Date(this.state.data.createdDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
          <Col>
            <span className={styles.editDamageModalDates}>
              Date last modified: {new Date(this.state.data.lastModifiedDateTimeUTC).toLocaleDateString("en-US")}
            </span>
          </Col>
        </Row>
      </div>
    );
  }

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    let submitButtonText = "Create";
    let bodyContent = this.createActivityModalUI();
    if (this.state.modalMode === "Edit") {
      if (!this.state.data) {
        this.setState({ data: this.props.modalData });
      }
      submitButtonText = "Update";
      bodyContent = this.editActivityModalUI();
    }
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Modal.Header className={globalStyles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>{this.state.modalMode} Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>{bodyContent}</Modal.Body>
          <Modal.Footer className={globalStyles.modalFooter}>
            <Button className={globalStyles.modalCancelButton} onClick={this.modalCleanUp}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <Button type="submit" className={globalStyles.modalSubmitButton} onClick={this.submitModal}>
              <span className={globalStyles.modalSubmitButtonText}>{submitButtonText}</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CreateEditAdminWorkTaskActivityModal;
