import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CreateEditDamageModal from "../ReferenceFilesCreatePages/CreateEditDamageModal";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import ConfigureDamageModal from "../ReferenceFilesEditModals/ConfigureDamageModal";
import CustomTextFilter from "../common/customTextFilter";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class DamageTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      damageData: [],
      currentUsaState: props.currentUsaState,
      defaultSort: [{ dataField: "name", order: "asc" }],
      columns: this.setColumns(),
      showCreateModal: false,
      showConfigureModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
    };
  }

  tableTextStyle = {};

  static getDerivedStateFromProps(nextProps) {
    return {
      currentUsaState: nextProps.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier) {
      await this.updateDamageData();
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "damageTab") {
      await this.updateDamageData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "damageTab") {
      await this.updateDamageData();
    }
  }

  setColumns() {
    let columns = [
      {
        dataField: "damageUniqueIdentifier",
        hidden: true,
        csvText: "Damage Unique Id",
      },
      {
        dataField: "name",
        text: "Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            title="Damage Name Search"
            labelledBy="damageNameHeader"
          />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "damageNameHeader" },
        attrs: { headers: "damageNameHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "isAllowedGlobally" : "isAllowedInState",
        text: this.props.useGlobalData ? "Status" : "Allowed",
        formatter: (cell, row, rowIndex) => {
          if (this.props.useGlobalData) {
            return row.isAllowedGlobally ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          } else {
            return row.isAllowedInState ? <span style={{ color: "#008767" }}>Allowed</span> : "Not Allowed";
          }
        },
        csvFormatter: (cell, row, rowIndex) => {
          if (this.props.useGlobalData) {
            return row.isAllowedGlobally ? "Allowed" : "Not Allowed";
          } else {
            return row.isAllowedInState ? "Allowed" : "Not Allowed";
          }
        },
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === "asc") {
            return b - a;
          }
          return a - b; // desc
        },
        align: "left",
        headerAlign: "left",
        style: this.tableTextStyle,
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        headerAttrs: { id: "damageAllowedHeader" },
        attrs: { headers: "damageAllowedHeader", tabIndex: "0" },
      },
      {
        dataField: "lastModifiedDateTimeUTC",
        text: this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return this.formatDate(cell);
        },
        sort: true,
        sortFunc: (a, b, order) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          if (order === "asc") {
            return dateB - dateA;
          }
          return dateA - dateB; // desc
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "damageLastUpdatedHeader" },
        attrs: { headers: "damageLastUpdatedHeader", tabIndex: "0" },
      },
      {
        dataField: "damageActions",
        text: "Actions",
        csvExport: false,
        editable: false,
        formatter: (cell, row) => {
          let configureDropdownItem = [];
          if (!this.props.useGlobalData) {
            configureDropdownItem.push(
              <Dropdown.Item
                eventKey="damageConfigure"
                onSelect={() => this.handleDamageConfigure(row)}
                hidden={!this.props.permissions.canIConfigureRefData()}
              >
                Configure
              </Dropdown.Item>
            );
          } else {
            configureDropdownItem.push(
              <Dropdown.Item
                eventKey="damageEdit"
                onSelect={() => this.handleDamageEdit(row)}
                hidden={!this.props.permissions.canIEditReferenceData()}
              >
                Edit
              </Dropdown.Item>
            );
          }
          return (
            <div className={styles.refFilesActionButtonContainer}>
              <DropdownButton id="dropdown-damage-actions" title="Actions" variant="link" className={styles.buttonLink}>
                {configureDropdownItem}
                <Dropdown.Item eventKey="damageHistoryTracking" onSelect={() => this.handleDamageHistory(row)}>
                  History Tracking
                </Dropdown.Item>
              </DropdownButton>
            </div>
          );
        },
        headerStyle: () => {
          return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
        },
        headerAlign: "right",
        align: "right",
        headerAttrs: { id: "damageActionsHeader" },
        attrs: { headers: "damageActionsHeader", tabIndex: "0" },
      },
    ];
    return columns;
  }

  async updateDamageData() {
    this.props.setIsLoading(true);
    const damageData = await this.getDataFromAPI();
    this.setState({ damageData, columns: this.setColumns() }, () => {
      this.props.setIsLoading(false);
    });
    window.scrollTo(0, 0);
  }

  async getDataFromAPI() {
    const { currentUsaState } = this.state;
    if (!currentUsaState?.stateGuid) return [];

    let damages = [];
    if (this.props.useGlobalData) {
      const damagesResponse = await ReferenceFileAPI.GetAllDamages();
      if (damagesResponse?.successful) {
        damages = damagesResponse?.data?.results ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve global damage records",
          apiName: "GetAllDamages",
          responseUnsuccessful: damagesResponse?.unsuccessful,
          responseMessage: damagesResponse?.message,
        });
      }
    } else {
      const damagesResponse = await ReferenceFileAPI.GetDamagesForState(currentUsaState.stateGuid);
      if (damagesResponse?.successful) {
        damages = damagesResponse?.data?.results?.filter((t) => t.isAllowedGlobally) ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve damage records",
          apiName: "GetDamagesForState",
          responseUnsuccessful: damagesResponse?.unsuccessful,
          responseMessage: damagesResponse?.message,
        });
      }
    }
    return damages;
  }

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleDateString("en-US");
  }

  closeAllModals = async () => {
    this.setState({ showCreateModal: false, showEditModal: false, showConfigureModal: false, modalData: null });
    await this.updateDamageData();
  };

  handleCreateDamage = () => {
    this.setState({ showCreateModal: true });
  };

  handleDamageConfigure = (row) => {
    this.setState({ modalData: row, showConfigureModal: true });
  };

  handleDamageEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleDamageHistory = (row) => {};

  renderCreateModal() {
    return (
      <CreateEditDamageModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode={"Create"}
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditDamageModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode={"Edit"}
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
      />
    );
  }

  renderConfigureModal() {
    return (
      <ConfigureDamageModal
        show={this.state.showConfigureModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        usaStateGuid={this.props.currentUsaState?.stateGuid}
      />
    );
  }

  renderModals = () => {
    return (
      <React.Fragment>
        {this.state.showCreateModal ? this.renderCreateModal() : null}
        {this.state.showEditModal ? this.renderEditModal() : null}
        {this.state.showConfigureModal ? this.renderConfigureModal() : null}
      </React.Fragment>
    );
  };

  render() {
    return (
      <ToolkitProvider
        keyField="damageUniqueIdentifier"
        data={this.state.damageData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "damage-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Damage"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleCreateDamage}
                  useGlobalData={this.props.useGlobalData}
                  recordCount={this.state.damageData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="damageUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.renderModals()}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="Damage"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="Damage"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default DamageTab;
