import React from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import HorizontalPipeSeparator from "../common/horizontal-pipe-separator/horizontal-pipe-separator";
import { withRouter } from "react-router-dom";
import styles from "./navbar-utilities.module.scss";
import { toast } from "react-toastify";

class NavbarUtilities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentState: props.currentState,
      states: props.states,
      username: props.username,
      identity: props.identity,
      onStateSelect: props.onStateSelect,
      isSearchBoxDisplayed: false,
      userProfiles: props.userProfiles,
      currentUserProfile: props.currentUserProfile,
      onRoleSelect: props.onRoleSelect,
      permissions: props.permissions,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      states: nextProps.states,
      currentState: nextProps.currentState,
      username: nextProps.username,
      identity: nextProps.identity,
      userProfiles: nextProps.userProfiles,
      currentUserProfile: nextProps.currentUserProfile,
      permissions: nextProps.permissions,
    };
  }

  logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    console.log("logOut");
    window.location =
      window.location.origin +
      "/roleauth/Logout?ReturnUrl=" +
      encodeURIComponent(window.location.origin + "/");
  };

  closeEntitiesSearchModal = () => {
    this.setState({ isSearchBoxDisplayed: false });
  };

  handleEntitiesSearchToggle = () => {
    if (this.props.history?.location?.pathname === "/agreemententities/search") {
      toast.warning("You are on the Search Results page. Please use the search functionality of this page.");
    } else {
      this.props.onSearchBoxToggled();
    }
    this.setState({ isSearchBoxDisplayed: !this.state.isSearchBoxDisplayed });
  };

  renderStatesDropdown() {
    let dropdown = (
      <DropdownButton
        className="no-mobile-utilities"
        id="state-dropdown"
        title={
          <span className={styles.dropdownsTitle}>
            {this.state.currentState?.stateName ? this.state.currentState.stateName : ""}
          </span>
        }
        variant="light"
      >
        {this.state.states.map((state) => (
          <Dropdown.Item
            className="no-mobile-utilities"
            onSelect={() => this.state.onStateSelect(state)}
            key={state.stateGuid}
          >
            <span className={styles.dropdownsTitle}>{state.stateName}</span>
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
    if (this.props.location?.pathname !== "/home") {
      dropdown = (
        <span className={styles.stateDropdownText} tabIndex="0">
          {this.state.currentState?.stateName ? this.state.currentState.stateName : ""}
        </span>
      );
    }
    return dropdown;
  }

  renderRolesDropdown() {
    let dropdown = (
      <DropdownButton
        className="no-mobile-utilities mr-2"
        id="roles-dropdown"
        title={
          <span className={styles.dropdownsTitle}>
            {this.state.currentUserProfile?.profileName ? this.state.currentUserProfile.profileName : ""}
          </span>
        }
        variant="light"
      >
        {this.state.userProfiles.map((role) => (
          <Dropdown.Item
            className="no-mobile-utilities"
            onSelect={() => this.state.onRoleSelect(role)}
            key={role.roleUniqueReference}
          >
            <span className={styles.dropdownText}>{role.profileName}</span>
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
    if (this.props.location?.pathname !== "/home") {
      dropdown = (
        <span className={styles.userRolesDropdownText} tabIndex="0">
          {this.state.currentUserProfile?.profileName ? this.state.currentUserProfile.profileName : ""}
        </span>
      );
    }
    return dropdown;
  }

  render() {
    return (
      <Form inline className="ml-auto mr-4" data-testid="navbarUtilities">
        {this.renderRolesDropdown()}
        {this.renderStatesDropdown()}
        <HorizontalPipeSeparator />
        <div>
          <i
            className="fa fa-search mx-3 blue-icons"
            onClick={this.handleEntitiesSearchToggle}
            style={{ cursor: "pointer" }}
            title="Search System"
            tabIndex="0 "
          ></i>
          <i className="fa fa-bell-o mx-3 blue-icons" aria-hidden="true" title="Notifications"></i>
        </div>
        <HorizontalPipeSeparator />
        <span className="no-mobile-utilities mx-3 blue-icons">
          <i className="fa fa-user-circle mr-3" aria-hidden="true"></i>
          <span title={this.state.identity}>{this.state.username}</span>
        </span>
        <HorizontalPipeSeparator />
        <span className="no-mobile-utilities blue-icons" style={{ cursor: "pointer" }} onClick={this.logOut}>
          <i className="fa fa-sign-out mx-3" aria-hidden="true" title="Log Out"></i>Logout
        </span>
      </Form>
    );
  }
}

export default withRouter(NavbarUtilities);
