import React from "react";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import CustomForm from "../common/form";

class CreateEditIntermediateFateModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: this.props.modalMode,
      data: this.props?.modalData
        ? this.props.modalData
        : {
            isEnabledGlobally: "",
            name: "",
          },
      errors: {},
    };
  }

  doSubmit = async () => {
    if (!this.isFormDataValid()) {
      const { data, errors } = this.state;
      if (!data.name) {
        errors.name = "Please enter a name";
      }
      this.setState({ errors });
    } else {
      this.props.onHide();
      this.props.onSubmit({ ...this.state.data });
      this.clearData();
    }
  };

  isFormDataValid() {
    return this.state.data.name ? true : false;
  }

  handleModalCancel = () => {
    this.clearData();
    this.props.onHide();
  };

  clearData() {
    const { data } = this.state;
    data.name = "";
    data.isEnabledGlobally = "";
    this.setState({
      data,
      errors: {},
    });
  }

  handleStatusChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data.isEnabledGlobally = input.id === "active" ? true : false;
    this.setState({ data });
  };

  handleSpecificChanges(input) {
    //do nothing
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} backdrop="static" centered>
          <Form noValidate onSubmit={this.handleSubmit}>
            <Modal.Header className={globalStyles.modalHeader} closeButton>
              <Modal.Title className={globalStyles.modalTitleText}>
                {this.state.modalMode} Intermediate Fate
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="mx-0">
                <Col>{this.renderInput("name", "Fate Name", "text", "Enter name", "required")}</Col>
              </Row>
              {this.state.modalMode === "Edit" && (
                <Row className="mx-0">
                  <Col>
                    <Form.Label className={globalStyles.formLabel}>Status</Form.Label>
                    <Form.Group>
                      <Form.Check
                        type="radio"
                        name="stateStatus"
                        inline
                        label="Active"
                        id="active"
                        onChange={this.handleStatusChange}
                        defaultChecked={this.state.data.isEnabledGlobally}
                      />
                      <Form.Check
                        type="radio"
                        name="stateStatus"
                        inline
                        label="Inactive"
                        id="inactive"
                        onChange={this.handleStatusChange}
                        defaultChecked={!this.state.data.isEnabledGlobally}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer className={globalStyles.modalFooter}>
              <Button className={globalStyles.modalCancelButton} onClick={this.handleModalCancel}>
                <span className={globalStyles.modalCancelButtonText}>Cancel</span>
              </Button>
              <Button
                className={this.isFormDataValid() ? globalStyles.ModalCreateButton : styles.greyedOutModalSaveButton}
                variant="primary"
                type="submit"
                onClick={this.doSubmit}
              >
                <span
                  className={this.isFormDataValid() ? globalStyles.modalSubmitButtonText : styles.greyedOutButtonText}
                >
                  {this.state.modalMode === "Edit" ? "Update" : "Create"}
                </span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default CreateEditIntermediateFateModal;
