import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { selectFilter, customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { withRouter } from "react-router-dom";
import ConfigureSpeciesModal from "../ReferenceFilesEditModals/ConfigureSpeciesModal";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CustomTextFilter from "../common/customTextFilter";
import { toast } from "react-toastify";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import moment from "moment";

class SpeciesTab extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      species: [],
      columns: this.getColumns(),
      defaultSort: [{ dataField: "name", order: "asc" }],
      currentUsaState: props.currentUsaState,
      history: this.props.history,
      classFilter: null,
      orderFilter: null,
      familyFilter: null,
      categoryFilter: null,
      showModal: false,
      modalData: {},
      allowedUOMsFilter: null,
      nameFilter: null,
      allowedFilter: null,
      dateFilter: null,
      classFilterValue: "",
      orderFilterValue: "",
      familyFilterValue: "",
      categoryFilterValue: "",
      nameFilterValue: "",
      allowedUOMsFilterValue: "",
      allowedFilterValue: "",
      lastUpdatedDateFilterValue: "",
      sortColumn: "name",
      isAscending: true,
      waitingForAPIData: false,
    };
  }

  getColumns = () => {
    return [
      {
        dataField: "speciesUniqueIdentifier",
        hidden: true,
        csvText: "Species Unique Id",
      },
      {
        dataField: "class",
        text: "Class",
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "speciesTypeHeader" },
        attrs: { headers: "speciesTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "order",
        text: "Order",
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "speciesSubTypeHeader" },
        attrs: { headers: "speciesSubTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "family",
        text: "Family",
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "speciesSubTypeHeader" },
        attrs: { headers: "speciesSubTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "subType",
        text: "Category",
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "speciesSubTypeHeader" },
        attrs: { headers: "speciesSubTypeHeader", tabIndex: "0" },
      },
      {
        dataField: "name",
        text: "Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            setColumnFilterValue={this}
            columnName="name"
            title="Species Name Filter"
          />
        ),
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "speciesNameHeader" },
        attrs: { headers: "speciesNameHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "globalAllowedUOMs" : "allowedUOMsInState",
        text: "Allowed UOMs",
        csvFormatter: (cell, row) => {
          return this.getUOMString(row);
        },
        filter: customFilter({
          onFilter: (filterValue, data) => {
            return data.filter((x) => this.getUOMString(x).toLowerCase().includes(filterValue.toLowerCase()));
          },
        }),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            setColumnFilterValue={this}
            columnName="allowedUOMs"
            title="Species Allowed UOMs Filter"
          />
        ),
        formatter: (cell, row) => {
          return this.getUOMString(row);
        },
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "speciesUOMsHeader" },
        attrs: { headers: "speciesUOMsHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "isAllowedGlobally" : "isAllowedForState",
        text: this.props.useGlobalData ? "Status" : "Allowed",
        filter: selectFilter({
          options: this.props.useGlobalData
            ? { true: "Active", false: "Inactive" }
            : { true: "Allowed", false: "Not Allowed" },
          getFilter: (filter) => {
            this.setState({ allowedFilter: filter });
          },
          onInput: (e) => {
            let textValue = "";
            if (e.currentTarget.value === "true") {
              textValue = "Allowed";
            } else if (e.currentTarget.value === "false") {
              textValue = "Not Allowed";
            }
            this.setState({ allowedFilterValue: textValue });
          },
          onFilter: (filterValue, data) => {
            if (filterValue === "") {
              return data;
            }

            if (this.props.useGlobalData) {
              let results = data.filter((x) => x.isAllowedGlobally.toString() == filterValue);
              return results;
            } else {
              let results = data.filter((x) => x.isAllowedForState.toString() == filterValue);
              return results;
            }
          },
        }),
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },

        formatter: (cell, row, rowIndex) => {
          if (this.props.useGlobalData) {
            return row.isAllowedGlobally ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          } else {
            return row.isAllowedForState ? <span style={{ color: "#008767" }}>Allowed</span> : "Not Allowed";
          }
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "speciesAllowedHeader" },
        attrs: { headers: "speciesAllowedHeader", tabIndex: "0" },
      },
      {
        dataField: "lastModifiedDateTimeUTC",
        text: this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            dataType="date"
            setColumnFilterValue={this}
            columnName="lastUpdatedDate"
            title="Search by Last Updated Date"
          />
        ),
        formatter: (cell) => {
          return cell ? this.formatDate(cell) : "No Date";
        },
        sort: true,
        onSort: (field, order) => {
          this.setState({ sortColumn: field, isAscending: order === "asc" ? true : false });
        },

        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "speciesDateHeader" },
        attrs: { headers: "speciesDateHeader", tabIndex: "0" },
      },
      {
        dataField: "edit",
        text: "Actions",
        csvExport: false,
        formatter: (cell, row) => {
          let configureDropdownItem = [];
          if (!this.props.useGlobalData) {
            configureDropdownItem.push(
              <Dropdown.Item
                eventKey="speciesConfigure"
                key={"speciesConfigure" + row.speciesUniqueIdentifier}
                onSelect={() => this.setModalData(row)}
                hidden={!this.props.permissions.canIConfigureRefData()}
              >
                Configure
              </Dropdown.Item>
            );
          } else {
            configureDropdownItem.push(
              <React.Fragment>
                <Dropdown.Item
                  eventKey="favoriteSpeciesAdd"
                  key={"favoriteSpeciesAdd" + row.speciesUniqueIdentifier}
                  onSelect={() => this.addFavoriteSpecies(row)}
                  hidden={!row.isAllowedGlobally}
                >
                  Add to Favorites
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="damageEdit"
                  onSelect={() => this.goToEditPage(row)}
                  hidden={!this.props.permissions.canIEditReferenceData()}
                >
                  Edit
                </Dropdown.Item>
              </React.Fragment>
            );
          }
          let infoIcon = null;
          if (row.comments) {
            infoIcon = (
              <div className={styles.descriptionInfoIcon} title={row.comments}>
                <i className="fa fa-info" aria-hidden="true" title={row.comments}></i>
              </div>
            );
          }
          return (
            <div>
              {infoIcon}
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton
                  id="dropdown-species-actions"
                  title="Actions"
                  variant="link"
                  className={styles.buttonLink}
                >
                  {configureDropdownItem}
                  <Dropdown.Item eventKey="damageHistoryTracking" onSelect={() => this.handleSpeciesHistory(row)}>
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          );
        },
        headerStyle: () => {
          return { width: "20%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
        },
        headerAlign: "right",
        align: "right",
        headerAttrs: {
          id: "speciesActionsHeader",
          title: "Actions Column Header",
        },
        attrs: { headers: "speciesActionsHeader" },
      },
    ];
  };

  getUOMString(row) {
    let uomList = row?.globalAllowedUOMs ? row?.globalAllowedUOMs : row?.allowedUOMsInState;
    uomList.sort((a, b) => a.displayText.localeCompare(b.displayText, undefined, { sensitivity: "base" }));
    let uomString = "No UOMs";

    if (uomList?.length > 0) {
      let newAllowedUOMsInState = "";
      for (let j = 0; j < uomList.length; j++) {
        newAllowedUOMsInState += uomList[j]?.displayText + ", ";
      }
      uomString = newAllowedUOMsInState.slice(0, -2);
    }

    return uomString;
  }

  addFavoriteSpecies = async (species) => {
    if (this.props.favoriteSpecies.find((s) => s.speciesUniqueIdentifier === species.speciesUniqueIdentifier)) {
      toast.success("This Damage Agent is already a favorite.");
    } else {
      if (this.props.favoriteSpecies.length === 15) {
        toast.success("You already have 15 favorite Damage Agents.");
      } else {
        this.props.favoriteSpecies.push(species);

        const userData = this.props.userData;
        const saveFavoritesResponse = await RoleAuthorizationAPI.SetUserFavoriteReferenceFileData(
          userData?.userPrinciple,
          {
            speciesUniqueReferences: this.props.favoriteSpecies.map((f) => f.speciesUniqueIdentifier),
            methodUniqueReferences: userData.preferredMethodsUniqueReferences,
            resourceUniqueReferences: userData.preferredResourcesUniqueReferences,
          }
        );
        if (saveFavoritesResponse?.successful && saveFavoritesResponse.data) {
          const updatedUserData = saveFavoritesResponse.data;
          userData.preferredSpeciesUniqueReferences = updatedUserData.preferredSpeciesUniqueReferences;
          userData.preferredMethodsUniqueReferences = updatedUserData.preferredMethodsUniqueReferences;
          userData.preferredResourcesUniqueReferences = updatedUserData.preferredResourcesUniqueReferences;
          toast.success("Damage Agent added to favorites.");
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to set user's favorites",
            apiName: "SetUserFavoriteReferenceFileData",
            responseUnsuccessful: saveFavoritesResponse?.unsuccessful,
            responseMessage: saveFavoritesResponse?.message,
          });
        }
      }
    }
  };

  tableTextStyle = {
    //empty method
  };

  handleSpeciesHistory = (row) => {
    //history tracking -added later
  };

  goToEditPage = async (rowData) => {
    rowData.lastModifiedDateTimeUTC = this.formatDate(rowData.lastModifiedDateTimeUTC);
    rowData.createdDateTimeUTC = this.formatDate(rowData.createdDateTimeUTC);

    let getGlobalSpecies = await ReferenceFileAPI.GetSpecies(rowData.speciesUniqueIdentifier);
    if (getGlobalSpecies?.successful) {
      this.props.history.push({
        pathname: "/species/edit",
        state: {
          rowData: getGlobalSpecies.data,
          usaStateData: this.state.currentUsaState,
          globalData: this.props.useGlobalData,
        },
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve damage agent record",
        apiName: "GetSpecies",
        responseUnsuccessful: getGlobalSpecies?.unsuccessful,
        responseMessage: getGlobalSpecies?.message,
      });
    }
  };

  formatDate(date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleDateString("en-US");
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      currentUsaState: nextProps.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentUsaState.stateUniqueIdentifier !== this.state.currentUsaState.stateUniqueIdentifier ||
      prevState.classFilterValue !== this.state.classFilterValue ||
      prevState.orderFilterValue !== this.state.orderFilterValue ||
      prevState.familyFilterValue !== this.state.familyFilterValue ||
      prevState.categoryFilterValue !== this.state.categoryFilterValue ||
      prevState.allowedFilterValue !== this.state.allowedFilterValue ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.isAscending !== this.state.isAscending
    ) {
      await this.getDataFromAPI();
      this.setState({ waitingForAPIData: false });
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "speciesTab") {
      this.setColumnHeadersBasedOnGlobalData();
      await this.getDataFromAPI();
      this.setState({ waitingForAPIData: false });
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "speciesTab") {
      await this.getDataFromAPI();
      if (this.controller.signal.aborted) {
        return;
      }
      this.setState({ waitingForAPIData: false });
      if (this.props.history.location.state?.speciesCreateResponseData) {
        this.insertNewSpeciesFromResponse(this.props.history.location.state?.speciesCreateResponseData);
      }
      if (this.props.history.location.state?.speciesEditResponseData) {
        this.updateSpeciesFromResponse(this.props.history.location.state?.speciesEditResponseData);
      }
    }
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort("SpeciesTab.jsx got unmounted");
    }
  }

  async getDataFromAPI() {
    if (!this.state.currentUsaState || this.state.waitingForAPIData) {
      return;
    }

    this.setState({ waitingForAPIData: true });

    this.props.setIsLoading(true);
    let species = [];
    let usaStateValue = null;
    if (!this.props?.useGlobalData && this.state?.currentUsaState?.stateGuid) {
      usaStateValue = this.state.currentUsaState.stateGuid;
    }

    let dateValue = "";
    if (this.state.lastUpdatedDateFilterValue !== "") {
      dateValue = moment(this.state.lastUpdatedDateFilterValue, "YYYY-MM-DD").utc().format("MM/DD/YYYY");
    }

    const getSpeciesAction = await ReferenceFileAPI.GetFilteredSpeciesTableData(
      this.state.classFilterValue,
      this.state.orderFilterValue,
      this.state.familyFilterValue,
      this.state.categoryFilterValue,
      this.state.nameFilterValue,
      this.state.allowedUOMsFilterValue,
      this.state.allowedFilterValue,
      dateValue,
      this.state.sortColumn,
      this.state.isAscending,
      usaStateValue,
      this.controller.signal
    );

    if (getSpeciesAction?.unsuccessful || !getSpeciesAction) {
      if (this.controller.signal.aborted) {
        return;
      }
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get filtered damage agent table data",
        apiName: "GetFilteredSpeciesTableData",
        responseUnsuccessful: getSpeciesAction?.unsuccessful,
        responseMessage: getSpeciesAction?.message,
        signal: this.controller.signal,
      });
      this.props.setIsLoading(false);
    } else {
      if (this.props.useGlobalData) {
        species = getSpeciesAction.data.results;
      } else {
        species = getSpeciesAction.data.results?.filter((t) => t.isAllowedGlobally);
      }

      let speciesAllCategorizations = await ReferenceFileAPI.GetAllSpeciesCategorizationInfo(
        this.state.currentUsaState.stateGuid
      );

      if (!speciesAllCategorizations || speciesAllCategorizations?.unsuccessful) {
        speciesAllCategorizations = [];
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get damage agents categorizations",
          apiName: "GetAllSpeciesCategorizationInfo",
          responseUnsuccessful: speciesAllCategorizations?.unsuccessful,
          responseMessage: speciesAllCategorizations?.message,
        });
      }

      let currentColumns = this.state.columns;
      let classColumn = currentColumns.find((col) => col.dataField === "class");
      let classFilterValues = this.turnArrayIntoEnum(speciesAllCategorizations.data?.allClasses);
      let orderColumn = currentColumns.find((col) => col.dataField === "order");
      let orderFilterValues = this.turnArrayIntoEnum(speciesAllCategorizations.data?.allOrders);
      let familyColumn = currentColumns.find((col) => col.dataField === "family");
      let familyFilterValues = this.turnArrayIntoEnum(speciesAllCategorizations.data?.allFamilies);
      let categoryColumn = currentColumns.find((col) => col.dataField === "subType");
      let categoryFilterValues = this.turnArrayIntoEnum(speciesAllCategorizations.data?.allSubTypes);

      classColumn.filter = selectFilter({
        options: classFilterValues,
        defaultValue: 0,
        getFilter: (filter) => {
          this.setState({ classFilter: filter });
        },
        onInput: (e) => {
          this.setState({ classFilterValue: e.currentTarget.value });
        },
      });
      orderColumn.filter = selectFilter({
        options: orderFilterValues,
        defaultValue: 0,
        getFilter: (filter) => {
          this.setState({ orderFilter: filter });
        },
        onInput: (e) => {
          this.setState({ orderFilterValue: e.currentTarget.value });
        },
      });
      familyColumn.filter = selectFilter({
        options: familyFilterValues,
        defaultValue: 0,
        getFilter: (filter) => {
          this.setState({ familyFilter: filter });
        },
        onInput: (e) => {
          this.setState({ familyFilterValue: e.currentTarget.value });
        },
      });
      categoryColumn.filter = selectFilter({
        options: categoryFilterValues,
        defaultValue: 0,
        getFilter: (filter) => {
          this.setState({ categoryFilter: filter });
        },
        onInput: (e) => {
          this.setState({ categoryFilterValue: e.currentTarget.value });
        },
      });

      this.setState({ species: species, columns: currentColumns }, () => {
        this.props.setIsLoading(false);
      });
      window.scrollTo(0, 0);
    }
  }

  setColumnHeadersBasedOnGlobalData() {
    let currentColumns = this.getColumns();
    let allowedColumn;
    let dateColumn;
    currentColumns.forEach((col) => {
      if (col.dataField === "isAllowedGlobally") {
        allowedColumn = col;
      }
      if (col.dataField === "isAllowedForState") {
        allowedColumn = col;
      }
      if (col.dataField === "lastModifiedDateTimeUTC") {
        dateColumn = col;
      }
    });
    if (allowedColumn) {
      allowedColumn.text = this.props.useGlobalData ? "Status" : "Allowed";
    }
    if (dateColumn) {
      dateColumn.text = this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date";
    }
    this.setState({ columns: currentColumns });
  }

  setModalData = async (row) => {
    row.lastModifiedDateTimeUTC = this.formatDate(row.lastModifiedDateTimeUTC);

    let getGlobalSpeciesCall = await ReferenceFileAPI.GetSpecies(row.speciesUniqueIdentifier);
    let getStateSpeciesCall = await ReferenceFileAPI.GetStateSpecies(
      row.speciesUniqueIdentifier,
      row.stateUniqueIdentifier
    );

    if (getGlobalSpeciesCall?.successful && getStateSpeciesCall?.successful) {
      let fullSpeciesObj = {
        speciesUniqueIdentifier: row.speciesUniqueIdentifier,
        globalSpecies: getGlobalSpeciesCall?.data,
        stateSpecies: getStateSpeciesCall?.data,
      };
      this.setState({ showModal: true, modalData: fullSpeciesObj });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get damage agent record for modal",
        apiName: "GetSpecies",
        responseUnsuccessful: getGlobalSpeciesCall?.unsuccessful,
        responseMessage: getGlobalSpeciesCall?.message,
      });
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get state damage agent record for modal",
        apiName: "GetStateSpecies",
        responseUnsuccessful: getStateSpeciesCall?.unsuccessful,
        responseMessage: getStateSpeciesCall?.message,
      });
    }
  };

  insertNewSpeciesFromResponse = (createSpeciesData) => {
    let speciesTable = this.state.species;
    if (speciesTable.some((s) => s.speciesUniqueIdentifier === createSpeciesData.speciesUniqueIdentifier)) {
      return;
    }
    speciesTable.push(createSpeciesData);
    this.setState({ species: speciesTable });
  };

  updateSpeciesFromResponse = (updateSpeciesData, allow) => {
    this.setState({
      species: this.state.species.map((s) => {
        if (s.speciesUniqueIdentifier === updateSpeciesData?.speciesUniqueIdentifier) {
          s = updateSpeciesData;
        }
        return s;
      }),
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  turnArrayIntoEnum(array) {
    let result = {};
    array.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
    for (var i = 0; i < array?.length; i++) {
      var current = array[i];
      result[current] = current;
    }
    return result;
  }

  handleCreateSpeciesClick = () => {
    this.props.history.push({
      pathname: "/species/create",
      state: {
        usaStateData: this.state.currentUsaState,
        globalData: this.props.useGlobalData,
      },
    });
  };

  clearSpeciesFilters = () => {
    this.setState({
      nameFilter: "",
      dateFilter: "",
      classFilter: "",
      orderFilter: "",
      familyFilter: "",
      categoryFilter: "",
      allowedFilter: "",
      allowedUOMsFilter: "",
    });
  };

  render() {
    return (
      <ToolkitProvider
        keyField="speciesUniqueIdentifier"
        data={this.state.species}
        columns={this.state.columns}
        bootstrap4={true}
        search={{ searchFormatted: true }}
        exportCSV={{
          fileName: "species-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div data-testid="SpeciesTab">
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="Damage Agent"
                  createButtonEvent={this.handleCreateSpeciesClick}
                  permissions={this.props.permissions}
                  useGlobalData={this.props.useGlobalData}
                  recordCount={this.state.species.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="speciesUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                  {...props.baseProps}
                />
              </Card.Body>
            </Card>
            <ConfigureSpeciesModal
              show={this.state.showModal}
              onHide={this.closeModal}
              speciesData={this.state.modalData}
              usaStateData={this.state.currentUsaState}
              updateSpeciesFromResponse={this.updateSpeciesFromResponse}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(SpeciesTab);
