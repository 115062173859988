import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./ReferenceFiles.module.scss";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import { withRouter } from "react-router-dom";
import ReferenceFileCardHeader from "./ReferenceFileCardHeader";
import CustomTextFilter from "../common/customTextFilter";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import CreateEditRECCEMModal from "../ReferenceFilesCreatePages/CreateEditRECCEMModal";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ConfigureRecCemModal from "../ReferenceFilesEditModals/ConfigureRecCemModal";

class RECCEMsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recCemData: [],
      defaultSort: [{ dataField: "recCemName", order: "asc" }],
      columns: this.getRecCemColumns(),
      showCreateModal: false,
      showConfigureModal: false,
      showEditModal: false,
      modalData: null,
      showCreateSuccessModal: false,
      showEditSuccessModal: false,
      currentUsaState: props.currentUsaState,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentUsaState?.stateUniqueIdentifier !== this.state.currentUsaState?.stateUniqueIdentifier) {
      await this.updateRecCemData();
    }

    const propsChange =
      prevProps.currentTab !== this.props.currentTab || prevProps.useGlobalData !== this.props.useGlobalData;
    if (propsChange && this.props.currentTab === "recCemsTab") {
      await this.updateRecCemData();
    }
  }

  async componentDidMount() {
    if (this.props.currentTab === "recCemsTab") {
      await this.updateRecCemData();
    }
  }

  async updateRecCemData() {
    this.props.setIsLoading(true);
    const recCemData = await this.getDataFromAPI();
    const columns = this.getRecCemColumns();
    this.setState({ recCemData, columns }, () => {
      this.props.setIsLoading(false);
    });
    window.scrollTo(0, 0);
  }

  async getDataFromAPI() {
    let recCEMResults = [];

    if (this.props.useGlobalData) {
      const getRecCEMResponse = await ReferenceFileAPI.GetRecCems();
      if (getRecCEMResponse?.successful) {
        recCEMResults = getRecCEMResponse.data.results ?? [];
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get RecCem records",
          apiName: "GetRecCems",
          responseUnsuccessful: getRecCEMResponse?.unsuccessful,
          responseMessage: getRecCEMResponse?.message,
        });
      }
    } else {
      if (this.state.currentUsaState?.stateGuid) {
        const getRecCEMResponse = await ReferenceFileAPI.GetAllRecCems(this.state.currentUsaState.stateGuid);
        if (getRecCEMResponse?.successful) {
          recCEMResults = getRecCEMResponse.data.results?.filter((t) => t.isAllowedGlobally) ?? [];
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Unable to get RecCem records",
            apiName: "GetAllRecCems",
            responseUnsuccessful: getRecCEMResponse?.unsuccessful,
            responseMessage: getRecCEMResponse?.message,
          });
        }
      }
    }
    return recCEMResults;
  }

  getRecCemColumns() {
    return [
      {
        dataField: "recCemUniqueIdentifier",
        hidden: true,
        csvText: "RecCem Unique Id",
      },
      {
        dataField: "recCemName",
        text: "Name",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomTextFilter onFilter={onFilter} column={column} title="RecCem Name" />
        ),
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3" };
        },
        headerAttrs: { id: "recCemNameHeader" },
        attrs: { headers: "recCemNameHeader", tabIndex: "0" },
      },
      {
        dataField: this.props.useGlobalData ? "isAllowedGlobally" : "isAllowedInState",
        text: this.props.useGlobalData ? "Status" : "Allowed",
        formatter: (cell, row, rowIndex) => {
          if (this.props.useGlobalData) {
            return row.isAllowedGlobally ? <span style={{ color: "#008767" }}>Active</span> : "Inactive";
          } else {
            return row.isAllowedInState ? <span style={{ color: "#008767" }}>Allowed</span> : "Not Allowed";
          }
        },
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === "asc") {
            return b - a;
          }
          return a - b; // desc
        },
        align: "left",
        headerAlign: "left",
        style: this.tableTextStyle,
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        headerAttrs: { id: "diseaseAllowedHeader" },
        attrs: { headers: "diseaseAllowedHeader", tabIndex: "0" },
      },
      {
        dataField: "lastModifiedDateTimeUTC",
        text: this.props.useGlobalData ? "Last Modified Date" : "Last Updated Date",

        formatter: (cell, row, rowIndex, formatExtraData) => {
          return new Date(cell).toLocaleDateString("en-US");
        },
        sort: true,
        sortFunc: (a, b, order) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          if (order === "asc") {
            return dateB - dateA;
          }
          return dateA - dateB; // desc
        },
        align: "left",
        headerAlign: "left",
        headerStyle: () => {
          return { backgroundColor: "#f3f3f3", verticalAlign: "text-top" };
        },
        style: this.tableTextStyle,
        headerAttrs: { id: "recCemDateHeader" },
        attrs: { headers: "recCemDateHeader", tabIndex: "0" },
      },
      {
        dataField: "recCemActions",
        text: "Actions",
        csvExport: false,
        editable: false,
        formatter: (cell, row) => {
          if (this.props.useGlobalData) {
            return (
              <DropdownButton id="dropdown-recCem-actions" title="Actions" variant="link" className={styles.buttonLink}>
                <Dropdown.Item
                  eventKey="recCemEdit"
                  onSelect={() => this.handleRecCemEdit(row)}
                  hidden={!this.props.permissions.canIEditReferenceData()}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item eventKey="recCemHistoryTracking" onSelect={() => this.handleRecCemHistory(row)}>
                  History Tracking
                </Dropdown.Item>
              </DropdownButton>
            );
          } else {
            return (
              <div className={styles.refFilesActionButtonContainer}>
                <DropdownButton
                  id="dropdown-recCem-actions"
                  title="Actions"
                  variant="link"
                  className={styles.buttonLink}
                >
                  <Dropdown.Item
                    eventKey="recCemConfigure"
                    onSelect={() => this.handleRecCemConfigure(row)}
                    hidden={!this.props.permissions.canIConfigureRefData()}
                  >
                    Configure
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="recCemHistoryTracking" onSelect={() => this.handleRecCemHistory(row)}>
                    History Tracking
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            );
          }
        },
        headerStyle: () => {
          return { width: "40%", backgroundColor: "#f3f3f3", verticalAlign: "text-top", paddingRight: "2rem" };
        },
        headerAlign: "right",
        align: "right",
        headerAttrs: {
          id: "recCemActionsHeader",
          title: "Actions Column Header",
        },
        attrs: { headers: "recCemActionsHeader" },
      },
    ];
  }

  insertNewRecCemFromResponse(createRecCEMData) {
    let recCEMTable = this.state.recCemData;
    recCEMTable.push(createRecCEMData);
    this.setState({ recCemData: recCEMTable });
  }

  updateRecCemFromResponse(updateRecCEMData) {
    let recCEMTable = this.state.recCemData;
    for (let i = 0; i < recCEMTable.length; i++) {
      if (recCEMTable[i].recCemUniqueIdentifier === updateRecCEMData?.recCemUniqueIdentifier) {
        recCEMTable[i] = updateRecCEMData;
        break;
      }
    }
    this.setState({ recCemData: recCEMTable });
  }

  onModalSubmit = async (newRecCemData, mode) => {
    this.props.setIsLoading(true);
    if (mode === "Create") {
      await this.handleRecCemCreation(newRecCemData);
    } else if (mode === "Edit") {
      await this.handleRecCemGlobalEdit(newRecCemData);
    } else {
      await this.handleRecCenConfiguration(newRecCemData);
    }
    this.props.setIsLoading(false);
  };

  async handleRecCemCreation(newRecCemData) {
    const createRecCEMBody = {
      recCemName: newRecCemData.recCemName,
      isAllowedGlobally: true,
    };
    const createRecCEMResponse = await ReferenceFileAPI.CreateRecCem(createRecCEMBody);
    if (createRecCEMResponse?.successful) {
      this.insertNewRecCemFromResponse(createRecCEMResponse.data);
      this.setState({ showCreateSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to create RecCem",
        apiName: "CreateRecCem",
        responseUnsuccessful: createRecCEMResponse?.unsuccessful,
        responseMessage: createRecCEMResponse?.message,
      });
    }
  }

  async handleRecCenConfiguration(newRecCemData) {
    let configureResult = null;
    if (newRecCemData.isAllowed === "Yes" || newRecCemData.isAllowed === true) {
      configureResult = await ReferenceFileAPI.AllowRecCemInStates(
        newRecCemData.recCemUniqueIdentifier,
        this.props.currentUsaState?.stateGuid
      );
    } else if (newRecCemData.isAllowed === "No" || newRecCemData.isAllowed === false) {
      configureResult = await ReferenceFileAPI.DisallowRecCemInStates(
        newRecCemData.recCemUniqueIdentifier,
        this.props.currentUsaState?.stateGuid
      );
    }
    if (configureResult.successful || configureResult.message.includes("already")) {
      this.updateRecCemFromResponse(configureResult.data[0]);
      this.setState({ showEditSuccessModal: true });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage:
          newRecCemData.isAllowed === "Yes" || newRecCemData.isAllowed === true
            ? "Unable to allow RecCem"
            : "Unable to disallow RecCem",
        apiName:
          newRecCemData.isAllowed === "Yes" || newRecCemData.isAllowed === true
            ? "AllowRecCemInStates"
            : "DisallowRecCemInStates",
        responseUnsuccessful: configureResult?.unsuccessful,
        responseMessage: configureResult?.message,
      });
    }
  }

  async handleRecCemGlobalEdit(newRecCemData) {
    const { modalData } = this.state;
    let isEditNameSuccesful = true;
    let isEditStatusSuccesful = true;
    let updateRecCemResponse = null;
    let updateRecCemStatusResponse = null;
    if (newRecCemData.recCemName !== modalData.recCemName) {
      const editRecCEMBody = {
        recCemName: newRecCemData.recCemName,
      };
      updateRecCemResponse = await ReferenceFileAPI.UpdateRecCem(newRecCemData.recCemUniqueIdentifier, editRecCEMBody);
      if (updateRecCemResponse?.successful) {
        this.updateRecCemFromResponse(updateRecCemResponse.data);
      } else {
        isEditNameSuccesful = false;
      }
    } else {
      updateRecCemResponse = false;
    }
    if (newRecCemData.isAllowedGlobally !== modalData.isAllowedGlobally) {
      updateRecCemStatusResponse = newRecCemData.isAllowedGlobally
        ? await ReferenceFileAPI.AllowRecCemGlobally(newRecCemData.recCemUniqueIdentifier)
        : await ReferenceFileAPI.DisallowRecCemGlobally(newRecCemData.recCemUniqueIdentifier);
      if (!updateRecCemStatusResponse?.successful) {
        isEditStatusSuccesful = false;
      } else {
        this.updateRecCemFromResponse(updateRecCemStatusResponse.data);
      }
    } else {
      updateRecCemStatusResponse = false;
    }
    this.handleGlobalEditErrors({
      updateRecCemResponse,
      isEditNameSuccesful,
      updateRecCemStatusResponse,
      isEditStatusSuccesful,
      newRecCemData,
    });
  }

  handleGlobalEditErrors({
    updateRecCemResponse,
    isEditNameSuccesful,
    updateRecCemStatusResponse,
    isEditStatusSuccesful,
    newRecCemData,
  }) {
    if ((updateRecCemResponse && isEditNameSuccesful) || (updateRecCemStatusResponse && isEditStatusSuccesful)) {
      this.setState({ showEditSuccessModal: true });
    } else if (updateRecCemResponse !== false && isEditNameSuccesful !== true) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to udpate RecCem records",
        apiName: "UpdateRecCem",
        responseUnsuccessful: updateRecCemResponse?.unsuccessful,
        responseMessage: updateRecCemResponse?.message,
      });
    } else if (updateRecCemStatusResponse !== false && isEditStatusSuccesful !== true) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to set RecCem allowed status",
        apiName: newRecCemData?.isAllowedGlobally ? "AllowRecCemGlobally" : "DisallowRecCemGlobally",
        responseUnsuccessful: updateRecCemStatusResponse?.unsuccessful,
        responseMessage: updateRecCemStatusResponse?.message,
      });
    } else if (updateRecCemResponse === false && updateRecCemStatusResponse === false) {
      ErrorHandler.showErrorWithMessage("No changes detected.");
    }
  }

  handleRecCemCreate = () => {
    this.setState({ showCreateModal: true });
  };

  handleRecCemConfigure = (row) => {
    this.setState({ modalData: row, showConfigureModal: true });
  };

  handleRecCemEdit = (row) => {
    this.setState({ modalData: row, showEditModal: true });
  };

  handleRecCemHistory = (row) => {};

  closeAllModals = () => {
    this.setState({ showCreateModal: false, showEditModal: false, showConfigureModal: false, modalData: null });
  };

  renderCreateModal() {
    return (
      <CreateEditRECCEMModal
        show={this.state.showCreateModal}
        onHide={this.closeAllModals}
        modalData={null}
        modalMode="Create"
        showSuccessModal={() => {
          this.setState({ showCreateSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderEditModal() {
    return (
      <CreateEditRECCEMModal
        show={this.state.showEditModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        modalMode="Edit"
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        useGlobalData={this.props.useGlobalData}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  renderConfigureModal() {
    return (
      <ConfigureRecCemModal
        show={this.state.showConfigureModal}
        onHide={this.closeAllModals}
        modalData={this.state.modalData}
        showSuccessModal={() => {
          this.setState({ showEditSuccessModal: true });
        }}
        usaStateGuid={this.props.currentUsaState?.stateGuid}
        onSubmit={this.onModalSubmit}
      />
    );
  }

  render() {
    return (
      <ToolkitProvider
        keyField="recCemUniqueIdentifier"
        data={this.state.recCemData}
        columns={this.state.columns}
        filter={filterFactory()}
        bootstrap4={true}
        hover={true}
        exportCSV={{
          fileName: "recCem-data.csv",
          ignoreHeader: false,
          noAutoBOM: false,
          onlyExportFiltered: true,
          exportAll: false,
        }}
      >
        {(props) => (
          <div>
            <Card className={styles.refFilesDataCard}>
              <Card.Body>
                <ReferenceFileCardHeader
                  referenceFileType="REC/CEMs"
                  permissions={this.props.permissions}
                  createButtonEvent={this.handleRecCemCreate}
                  useGlobalData={this.props.useGlobalData}
                  recordCount={this.state.recCemData.length}
                  {...props.csvProps}
                />
                <BootstrapTable
                  keyField="recCemUniqueIdentifier"
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  defaultSorted={this.state.defaultSort}
                  defaultSortDirection="asc"
                />
              </Card.Body>
            </Card>
            {this.state.showCreateModal ? this.renderCreateModal() : null}
            {this.state.showEditModal ? this.renderEditModal() : null}
            {this.state.showConfigureModal ? this.renderConfigureModal() : null}
            <SuccessfulRecordModifiedModal
              show={this.state.showCreateSuccessModal}
              onHide={() => {
                this.setState({ showCreateSuccessModal: false });
              }}
              messageType="create"
              objectName="RecCem"
            />
            <SuccessfulRecordModifiedModal
              show={this.state.showEditSuccessModal}
              onHide={() => {
                this.setState({ showEditSuccessModal: false });
              }}
              messageType="save"
              objectName="RecCem"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(RECCEMsTab);
