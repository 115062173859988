import "./map.css";
import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";
import { esriPortalConfig } from "../../../package.json";

function WebMap(property) {
  const mapDiv = useRef(null);

  //default map values
  let longitude = -77.62544;
  let latitude = 39.52566;

  if (property.location) {
    let mapLoc = JSON.parse(property.location);
    longitude = mapLoc[1];
    latitude = mapLoc[0];
  }

  useEffect(() => {
    if (mapDiv.current) {
      loadModules(["esri/Map", "esri/views/MapView", "esri/config", "esri/widgets/BasemapToggle"]).then(
        ([Map, MapView, esriConfig, BasemapToggle]) => {
          esriConfig.url = esriPortalConfig.url;

          const map = new Map({ basemap: "hybrid" });

          const view = new MapView({
            map: map,
            center: [longitude, latitude],
            container: mapDiv.current,
            zoom: 12,
          });

          loadModules(["esri/layers/GraphicsLayer", "esri/Graphic"]).then(([GraphicsLayer, Graphic]) => {
            const graphicsLayer = new GraphicsLayer();
            map.add(graphicsLayer);

            const point = {
              //Create a point
              type: "point",
              longitude: longitude,
              latitude: latitude,
            };
            const simpleMarkerSymbol = {
              type: "simple-marker",
              color: "red",
              outline: {
                color: [255, 255, 255], // White
                width: 1,
              },
            };

            let addPropertyAction = {
              title: "Add",
              id: "add-property",
              image: "",
            };

            let popupTemplate;
            if (property.agreementData) {
              popupTemplate = {
                title: "{Name}",
                content: [
                  {
                    type: "fields",
                    fieldInfos: [
                      { fieldName: "Address", label: "Address" },
                      { fieldName: "AgreementName", label: "Agreement Name" },
                      { fieldName: "AgreementStatus", label: "Agreement Status" },
                      { fieldName: "PrimaryFieldAgent", label: "Primary WS Specialist" },
                      { fieldName: "ExpirationDate", label: "Expiration Date" },
                    ],
                  },
                ],
              };
            } else {
              popupTemplate =
                property?.isAddPropertyAvailable && property.name
                  ? {
                      title: "{Name}",
                      content: "{Description}",
                      actions: [addPropertyAction],
                    }
                  : {
                      title: "{Name}",
                      content: "{Description}",
                    };
            }

            const attributes = {
              Name: property.name,
              Description: property.address,
              Address: property.address,
              AgreementName: property.agreementData?.commonName,
              AgreementStatus: getAdreementStatus(property),
              PrimaryFieldAgent: getPrimaryFieldAgent(property),
              ExpirationDate: new Date(property.agreementData?.expirationDate).toLocaleDateString("en-US"),
            };

            if (property.location) {
              const pointGraphic = new Graphic({
                geometry: point,
                symbol: simpleMarkerSymbol,
                attributes: attributes,
                popupTemplate: popupTemplate,
              });
              graphicsLayer.add(pointGraphic);
            }
          });

          // 1 - Create the widget
          var toggle = new BasemapToggle({
            // 2 - Set properties
            view: view, // view that provides access to the map's 'topo-vector' basemap
            nextBasemap: "topo", // allows for toggling to the 'hybrid' basemap
          });

          // Add widget to the top right corner of the view
          view.ui.add(toggle, "bottom-right");
          view.when(function () {
            let canvas = document.getElementsByTagName("CANVAS")[0];
            if (canvas) {
              canvas.title = "Search Map Canvas";
            }
          });
          let element = document.querySelector(".esri-view-surface");
          if (element) {
            element.title = "Search Map";
          }
          view.popup.on("trigger-action", (e) => {
            if (e.action?.id === "add-property" && property.name) {
              property.onAddProperty();
            }
          });
        }
      );
    }
  });

  return <div className="mapDiv" ref={mapDiv}></div>;
}

function getPrimaryFieldAgent(property) {
  let primaryFieldAgent = "";
  if (property.agreementData?.primaryFieldAgentName) {
    const { firstName, middleName, lastName, suffix } = property.agreementData?.primaryFieldAgentName;
    primaryFieldAgent = [firstName, middleName, lastName, suffix].join(" ");
  }
  return primaryFieldAgent;
}

function getAdreementStatus(property) {
  let agreementStatus = "";
  if (property.agreementData?.agreementStatus) {
    if (property.agreementData.agreementStatus.displayText) {
      agreementStatus = property.agreementData.agreementStatus.displayText;
    } else if (property.agreementData.agreementStatus.name) {
      agreementStatus = property.agreementData.agreementStatus.name;
    }
  }
  return agreementStatus;
}

export default WebMap;
