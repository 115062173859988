import React, { Fragment } from "react";
import styles from "./Agreement.module.scss";
import globalStyles from "../../OARS.module.scss";
import CustomForm from "../common/form";
import { Card, Row, Col, Form, Button, Tab, Tabs, Image } from "react-bootstrap";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import { components } from "react-select";
import searchIcon from "../../assets/search.svg";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import GeoCoding from "../common/GeoCoding";
import SearchMap from "../ESRIIntegration/SearchMap";
import ParcelMap from "../ESRIIntegration/ParcelMap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ParcelMapView from "../ESRIIntegration/ParcelMapView";
import missingmap from "../../assets/map_not_available.png";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ConflictingAgreementParcelModal from "../AgreementEntities/Properties/ConflictingAgreementParcelModal";

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className={styles.noOptionsMessage}>
        <i className="fa fa-exclamation-circle " style={{ color: "gray", padding: "4px" }} aria-hidden="true"></i>
        Sorry, we couldn’t find a match for “{props.selectProps.inputValue}”. Please try another search
      </span>
    </components.NoOptionsMessage>
  );
};

const Option = (props) => {
  let option = props.data;
  return (
    <components.Option {...props}>
      <Row className={styles.informationRow}>
        <Col lg={3} align="left">
          <p className={styles.informationTextTypeahead} style={{ fontWeight: "600" }}>
            {option.propertyName}
          </p>
        </Col>
        <Col lg={2} align="left">
          <p className={styles.informationTextTypeahead}>{option.city ? option.city : "No City"}</p>
        </Col>
        <Col lg={2} align="left">
          <p className={styles.informationTextTypeahead}>{option.state ? option.state : "No State"}</p>
        </Col>
        <Col lg={3} align="left">
          <p className={styles.informationTextTypeahead}>
            {option.cooperatorName ? option.cooperatorName : "No Cooperator"}
          </p>
        </Col>
        <Col lg={1} align="left">
          <p className={styles.informationTextTypeahead}>{option.numberofParcels ? option.numberofParcels : "0"}</p>
        </Col>
      </Row>
    </components.Option>
  );
};

const SingleValue = (props) => {
  return <components.SingleValue {...props}>{props.data?.propertyName}</components.SingleValue>;
};

class Step2 extends CustomForm {
  constructor(props) {
    super(props);

    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));

    this.state = {
      data: {
        propertyType: currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE" ? "Non-Parcel" : "Parcel",
        landClass: 0,
        district: "",
        county: "",
        countyName: "",
        mapPoint: "",
        propertyName: "",
        acres: "",
        calculatedAcres: "",
        parcelAcres: "",
        parcelCalculatedAcres: "",
        primaryCooperator: "",
        streetAddress: "",
        city: "",
        zipCode: "",
        state: "",
        stateName: "",
        country: "",
        countryName: "",
        latitude: "", //39.52566,
        longitude: "", //-77.62544,
        propertyTypeEdit: "",
        propertyNameEdit: "",
        primaryCooperatorEdit: "",
        acresEdit: "",
        calculatedAcresEdit: "",
        parcelAcresEdit: "",
        parcelCalculatedAcresEdit: "",
        landClassEdit: 0,
        streetAddressEdit: "",
        cityEdit: "",
        stateEdit: "",
        countryEdit: "",
        zipCodeEdit: "",
        districtEdit: "",
        countyEditGuid: null,
        countyEdit: "",
        latitudeEdit: "",
        longitudeEdit: "",
        propertyOwner: "",
        propertyOwnerEdit: "",
        currentAddress: [],
        currentProperty: [],
        useCooperatorAddressChecked: false,
      },
      errors: {},
      validated: false,
      landClasses: [],
      counties: [],
      states: [],
      countries: [],
      showDuplicatedPropertyModal: false,
      showSuccessfullCreateModal: false,
      duplicatedRecordData: "",
      createMethodBody: "",
      showMap: false,
      updateAccessibilityTags: true,
      latitudeForMapFind: "",
      longitudeForMapFind: "",
      addressStringForMapFind: "",
      hasMapRendered: false,
      doesMapNeedRerender: true,
      isParcelSelected: false,
      parcelData: [],
      selectedProperties: [],
      isLoading: false,
      isUserEditing: false,
      formMode: this.props.step2Data?.agreementProperties?.length > 0 ? "search" : "create",
      selectedOptions: [],
      isPropertySelected: false,
      isPropertySelectedEdit: false,
      selectedOption: "",
      usaCountryId: "",
      primaryCooperatorID: this.props.step2Data.primaryCooperatorUniqueIdentifier,
      primaryCooperatorName: this.props.step2Data.primaryCooperatorName,
      primaryCooperatorState: this.props.step2Data.primaryCooperatorStateUniqueIdentifier,
      primaryCooperatorCity: this.props.step2Data.primaryCooperatorCity,
      primaryCooperatorCountry: this.props.step2Data.primaryCooperatorCountryUniqueIdentifier,
      primaryCooperatorStreet: this.props.step2Data.primaryCooperatorStreet,
      primaryCooperatorZip: this.props.step2Data.primaryCooperatorZip,
      currentUsaState: this.props.step2Data.currentUsaState,
      pageHeader: this.props.step2Data.pageHeader,
      agreementTypeEnumId: this.props.step2Data.agreementTypeEnumId ? this.props.step2Data.agreementTypeEnumId : 1,
      removedParcels: [],
      addedParcels: [],
      currentTab: this.props.step2Data?.agreementProperties?.length > 0 ? "search" : "create",
      conflictingAgreementUniqueIdentifiers: [],
      showConflictingAgreementParcelModal: false,
      dismissedConflictingAgreementParcelModal: false,
    };
  }

  async componentDidMount() {
    let { data } = this.state;

    let states = [];
    let countries = [];
    let counties = [];

    const landClasses = await this.getLandClasses();
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (currentUsaState?.stateGuid) {
      counties = await this.getCountyListbyState(currentUsaState.stateGuid);
    }
    if (currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE") {
      countries = await this.getAllCountriesFromAPI();
      states = await this.getAvailableStates();
      const dodObject = counties.find((county) => county.name === "DOD");
      data.county = dodObject.id;
      data.countyName = dodObject.name;
    } else {
      countries = await this.getAllCountriesFromAPI();
      const usaObject = countries.find((country) => country.name === "United States of America");
      states = await this.getAvailableStates(usaObject.id);
      data.country = usaObject.id;
      data.countryName = usaObject.name;
    }
    if (currentUsaState?.stateGuid) {
      counties = await this.getCountyListbyState(currentUsaState.stateGuid);
    }
    data.state = currentUsaState.stateGuid;
    data.stateName = currentUsaState.stateName;
    let element = document.getElementById("countyCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "county");
    element = document.getElementById("stateCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "state");
    element = document.getElementById("countryCol").getElementsByTagName("input");
    element[element.length - 1].setAttribute("title", "country");

    this.setState({
      landClasses,
      counties,
      countries,
      states,
      currentUsaState,
      data,
    });
    await this.setPropertiesData();
  }

  async setPropertiesData() {
    if (this.props.step2Data !== null) {
      let properties = this.props.step2Data.agreementProperties;
      if (properties.length > 0) {
        for (var i = 0; i < properties.length; i++) {
          let propertyID = properties[i].propertyUniqueReference
            ? properties[i].propertyUniqueReference
            : properties[i].propertyUniqueIdentifier;
          let propertyObject = await this.getProperty(propertyID);
          properties[i].propertyObject = propertyObject;
          let propertyState = this.getStateNameFromId(propertyObject?.state);
          properties[i].state = propertyState;
          properties[i].acres = propertyObject.acres;
        }
      }
      const parcelData = properties[0]?.propertyObject?.parcels?.length > 0 ? properties[0].propertyObject.parcels : [];

      this.setState({ selectedProperties: properties, parcelData });
    }
  }

  onViewProperty = async (e, index) => {
    let data = this.state;

    let selection = data.selectedProperties[index];
    let propertyID = selection.propertyUniqueReference
      ? selection.propertyUniqueReference
      : selection.propertyUniqueIdentifier;
    const fullProperty = await this.getProperty(propertyID);
    selection.propertyObject = fullProperty;

    data = this.populateEditPropertyObject(data, fullProperty);

    this.setState({
      selectedOption: selection,
      data,
      isLoading: false,
      latitudeEditForMapFind: data.latitudeEdit ? parseFloat(data.latitudeEdit).toFixed(5) : "",
      longitudeEditForMapFind: data.longitudeEdit ? parseFloat(data.longitudeEdit).toFixed(5) : "",
      addressEditStringForMapFind: this.getPropertyAddress(),
      doesMapNeedRerender: true,
      parcelData: fullProperty.parcels,
      currentTab: "search",
    });
    this.renderEditPropertyForm();
  };

  onRemove = (e, index) => {
    let data = this.state;
    data.selectedProperties.splice(index, 1);
    this.props.passDataUp("agreementProperties", data.selectedProperties);
    this.setState({ data });

    if (data.selectedOptions.length === 0) {
      this.setState({ isPropertySelected: false });
    }
    this.clearEditFields();
  };

  componentDidUpdate(prevProps) {
    if (this.state.doesMapNeedRerender) {
      this.setState({ doesMapNeedRerender: false });
    }
    let { data } = this.state;
    if (!data.state) {
      const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
      data.state = currentUsaState.stateGuid;
      data.stateName = currentUsaState.stateName;
      this.setState({ data });
    }
  }

  async getAllCountriesFromAPI() {
    const results = await ReferenceFileAPI.GetAllCountries();

    if (results) {
      return results
        .map((country) => ({
          id: country.countryUniqueIdentifier,
          name: country.country_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return [];
  }

  async getContactTypeId(contactType) {
    const contactTypesData = await AgreementEntitiesAPI.GetContactTypes();

    let contactTypeId = "";
    if (contactTypesData.data) contactTypeId = contactTypesData.data.find((element) => element.name === contactType).id;

    return contactTypeId;
  }

  async getCooperatorsFromAPI(searchValue) {
    const contactsData = await AgreementEntitiesAPI.SearchContactFreeText({
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    });
    let cooperators = "";

    if (contactsData.data.results) {
      let results = contactsData.data.results;
      cooperators = results.map((result) => ({
        value: result.contactUniqueId,
        label: result.firstName.concat(" ", result.middleName, " ", result.lastName),
      }));
    }

    return cooperators;
  }

  async getLandClasses() {
    const landClasses = await AgreementEntitiesAPI.GetAllPropertyLandClasses();
    if (!landClasses) return [];
    let dataResults = landClasses.data;
    if (!this.state.primaryCooperatorID) {
      dataResults = landClasses.data.filter((lc) => lc.id === 1 || lc.id === 4);
    }
    return dataResults;
  }

  async getCountyListbyState(stateGuid) {
    const county = await ReferenceFileAPI.GetCountiesByState(stateGuid);
    let countyList = [];
    if (county) {
      countyList = county
        .map((county) => ({
          id: county.countyUniqueIdentifier,
          name: county.county_name,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    return countyList;
  }

  isDuplicate(property) {
    let isDuplicate = false;
    if (this.state.selectedProperties.length > 0) {
      let matchedProperty = this.state.selectedProperties.find(
        (p) =>
          (p.propertyUniqueIdentifier ? p.propertyUniqueIdentifier : p.propertyUniqueReference) ===
          property.propertyUniqueIdentifier
      );
      if (matchedProperty) {
        isDuplicate = true;
      }
    }
    return isDuplicate;
  }

  setParcelProperty() {
    const { data } = this.state;
    data.propertyType = "Parcel";
    this.setState({ data });
  }

  setNonParcelProperty() {
    const { data } = this.state;
    data.propertyType = "Non-Parcel";
    this.setState({ data });
  }

  handleSpecificChanges(input) {
    let { data } = this.state;
    if (
      !this.state.data.streetAddress &&
      !this.state.data.city &&
      !this.state.data.state &&
      !this.state.data.country &&
      !this.state.data.longitude &&
      !this.state.data.latitude
    ) {
      this.setState({ hasMapRendered: false });
    }

    //to do - add calculated area
    if (input.name === "acres" || input.name === "parcelAcres") {
      if (input.name === "parcelAcres") {
        data.parcelAcres = input.value;
      }
      if (input.name === "acres") {
        data.acres = input.value;
        data.calculatedAcres = input.value;
      }
      this.setState({ data });
    }
  }

  async doSubmit() {
    const { data, errors } = this.state;
    if (errors.propertyName) {
      toast.warning("Please address all the errors before submitting the form");
      return;
    }

    if (data.propertyType === "Parcel") {
      if (data.propertyName !== "" && this.state.parcelData && data.parcelAcres !== "" && data.county !== "") {
        this.setState({ validated: true });
      } else {
        if (!this.state.parcelData) {
          toast.warning("Parcel selection is required to create Property with Parcels");
        } else {
          toast.warning("All required fields must be completed, Acres, County and Property Name are required");
        }
        return;
      }
    } else {
      if (data.propertyName !== "" && data.acres !== "" && data.county !== "" && data.landClass) {
        this.setState({ validated: true });
      } else {
        toast.warning(
          "All required fields must be completed, Acres, County, Land Class and Property Name are required."
        );

        return;
      }
    }

    let propertyData = await this.createProperty();

    if (!propertyData) return;

    let selectedProperty = this.populateProperty(propertyData);

    let selProperties = this.state.selectedProperties;
    selProperties.push(selectedProperty);

    this.setState({
      selectedProperties: selProperties,
    });

    this.props.passDataUp("agreementProperties", this.state.selectedProperties);
  }

  populateProperty(propertyData) {
    let propertyState = this.getStateNameFromId(propertyData?.state);
    let addressStr = "";
    if (propertyData.additionalStreetAddresses) {
      if (propertyData.additionalStreetAddresses[0]?.streetAddress)
        addressStr =
          propertyData.additionalStreetAddresses[0].streetAddress +
          ", " +
          propertyData.additionalStreetAddresses[0].city +
          ", " +
          propertyState +
          ", " +
          propertyData.additionalStreetAddresses[0].zipCode;
    }
    let propertyID = propertyData.propertyUniqueIdentifier
      ? propertyData.propertyUniqueIdentifier
      : propertyData.propertyUniqueReference;
    let selectedProperty = {
      propertyUniqueIdentifier: propertyID,
      propertyName: propertyData.propertyName,
      parcels: propertyData.parcels,
      parcelCount: propertyData.parcelCount,
      acres: propertyData.acres,
      landClass: propertyData.landClass,
      state: propertyState,
      address: addressStr,
      county: propertyData.county,
      propertyObject: propertyData,
      mapPoint: JSON.stringify([propertyData.latitude?.toString(), propertyData.longitude?.toString()]),
      latitude: propertyData.latitude,
      longitude: propertyData.longitude,
    };
    return selectedProperty;
  }

  async addParceltoProperty(propertyID, parcelData) {
    //lanclass need to be captured
    let parcels = [];
    let parcelAddResponse = "";
    let acres = 0;
    for (let i = 0; i < parcelData.length; i++) {
      const parcel = parcelData[i];
      acres = parcel.acres ? parseFloat(parcel.acres, 10) : 0;
      let Owner = parcel.OwnerFirstName + " " + parcel.OwnerLastName;
      let parcelBody = {
        propertyUniqueIdentifier: propertyID,
        landClassEnumId: 8,
        acres: acres,
        district: "",
        parcelOwner: Owner,
        parcelId: parcel.acctid,
      };
      parcels.push(parcelBody);
    }
    parcelAddResponse = await AgreementEntitiesAPI.CreateParcels(parcels);

    return parcelAddResponse;
  }

  async createProperty() {
    const { data, states, countries } = this.state;
    let { duplicatedRecordData, showDuplicatedPropertyModal, showConflictingAgreementParcelModal } = this.state;

    let propertyAddress = this.getPropertyAddress();
    let location = "";
    let mapPoint = "";
    if (propertyAddress) {
      location = await GeoCoding.getLocationByAddress(propertyAddress);
      mapPoint = JSON.stringify(location);
    }
    data.mapPoint = mapPoint;

    const messageBody = this.getMessageBody(data);
    const duplicateResponse = await AgreementEntitiesAPI.FindDuplicateProperties(messageBody, 1);
    const parcelAgreementCheck = await this.canTheseParcelsMakeUpANewAgreement();

    if (!parcelAgreementCheck && !this.state.dismissedConflictingAgreementParcelModal) {
      showConflictingAgreementParcelModal = true;
    } else if (duplicateResponse?.data && duplicateResponse?.data?.length > 0) {
      duplicatedRecordData = this.getDuplicatedRecordData(duplicateResponse?.data[0], countries, states);
      toast.warning("Property at this address already exists.");
      this.handleReset(data.propertyType);
      return duplicateResponse?.data[0];
    } else {
      const createResponse = await AgreementEntitiesAPI.CreateProperty(messageBody);
      let response = createResponse?.data;
      if (createResponse && createResponse?.successful) {
        if (data.propertyType === "Parcel") {
          const parcelAdditionSuccess = await this.addParceltoProperty(
            createResponse?.data?.propertyUniqueIdentifier,
            this.state.parcelData
          );
          if (parcelAdditionSuccess?.successful) {
            response = parcelAdditionSuccess?.data[0];
            toast.info("Property created Successfully");
          }
        } else {
          toast.info("Property created Successfully");
        }

        this.handleReset(data.propertyType);
        return response;
      } else {
        toast.error("Property creation failed.");
      }
    }
    this.setState({
      createMethodBody: messageBody,
      duplicatedRecordData,
      showDuplicatedPropertyModal,
      showConflictingAgreementParcelModal,
    });
  }

  async canTheseParcelsMakeUpANewAgreement() {
    let result = true;
    let esriParcelIds = [];
    if (this.state.parcelData) {
      for (let parcel of this.state.parcelData) {
        let idToAdd = parcel.acctid;
        if (!idToAdd) {
          idToAdd = parcel.parcelId;
        }
        esriParcelIds.push(idToAdd);
      }
    }

    if (esriParcelIds.length > 0) {
      const parcelAgreementCheckAction = await AgreementAPI.CanTheseParcelsMakeUpANewAgreement(esriParcelIds);
      if (parcelAgreementCheckAction.successful) {
        result = parcelAgreementCheckAction.data.canTheseParcelsMakeANewAgreement;
        this.setState({
          conflictingAgreementUniqueIdentifiers: parcelAgreementCheckAction.data.conflictingAgreementIdentifiers,
        });
      }
    }

    return result;
  }

  handleReset = (propertyType) => {
    this.clearEditFields();
    this.setState({
      data: {
        propertyType: propertyType,
        landClass: 0,
        district: "",
        county: "",
        mapPoint: "",
        propertyName: "",
        acres: "",
        parcelAcres: "",
        calculatedAcres: "",
        primaryCooperator: "",
        streetAddress: "",
        city: "",
        zipCode: "",
        parcelCalculatedAcres: "",
        propertyOwner: "",
        parcelData: [],
        isParcelSelected: false,
        latitude: "",
        longitude: "",
      },
    });
  };

  validateEditFields() {
    let validate = false;
    let acres = 0;
    if (this.state.data.propertyTypeEdit === "Parcel") {
      acres = parseFloat(this.state.data.parcelAcresEdit);
      validate =
        this.state.data.propertyNameEdit &&
        this.state.data.landClassEdit &&
        this.state.data.streetAddressEdit &&
        this.state.data.cityEdit &&
        this.state.data.countyEditGuid &&
        this.state.data.districtEdit &&
        this.state.data.stateEdit &&
        this.state.data.countryEdit &&
        this.state.data.zipCodeEdit &&
        acres > 0;
    } else {
      acres = parseFloat(this.state.data.acresEdit);
      validate =
        this.state.data.propertyNameEdit &&
        this.state.data.landClassEdit &&
        this.state.data.streetAddressEdit &&
        this.state.data.cityEdit &&
        this.state.data.countyEditGuid &&
        this.state.data.districtEdit &&
        this.state.data.stateEdit &&
        this.state.data.countryEdit &&
        this.state.data.zipCodeEdit &&
        this.state.data.latitudeEdit &&
        this.state.data.longitudeEdit &&
        acres > 0;
    }

    if (validate) return true;
  }

  getPropertyAddress() {
    let propertyAddress = "";

    if (this.state.formMode === "create") {
      if (this.state.data.state && this.state.data.streetAddress && this.state.data.city && this.state.data.zipCode) {
        let stateName = this.state.states.find((state) => state.id === this.state.data.state)?.name;
        propertyAddress = this.state.data.streetAddress.concat(
          ", ",
          this.state.data.city,
          ", ",
          stateName ? stateName : "",
          ", ",
          this.state.data.zipCode
        );
      }
    } else if (this.state.formMode === "search") {
      if (
        this.state.data.stateEdit &&
        this.state.data.streetAddressEdit &&
        this.state.data.cityEdit &&
        this.state.data.zipCodeEdit
      ) {
        let stateName = this.state.states.find((state) => state.id === this.state.data.stateEdit)?.name;
        propertyAddress = this.state.data.streetAddressEdit.concat(
          ", ",
          this.state.data.cityEdit,
          ", ",
          stateName ? stateName : "",
          ", ",
          this.state.data.zipCodeEdit
        );
      }
    }

    return propertyAddress;
  }

  getDuplicatedRecordData(duplicatedRecord, countries, states) {
    duplicatedRecord.countryName = countries.find(
      (country) => country.id === duplicatedRecord.countryUniqueIdentifier
    ).name;
    duplicatedRecord.stateName = states.find((state) => state.id === duplicatedRecord.state).name;

    return duplicatedRecord;
  }

  handlePropertySearch = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 100,
      pageNumber: 1,
    };

    const { currentUsaState } = this.state;

    let outputProperties = [];
    if (currentUsaState && currentUsaState.stateGuid) {
      this.setState({ isLoading: true });
      const propertiesData = await AgreementEntitiesAPI.SearchPropertiesFreeText(searchObj, currentUsaState.stateGuid);

      if (propertiesData?.data?.results?.length > 0) {
        let results = propertiesData.data.results;
        if (this.state.agreementTypeEnumId === 2) {
          //FLA
          results = propertiesData.data.results.filter(
            (property) => property.landClass.id === 4 || property.landClass.id === 1
          );
        }

        let parcels = [];
        outputProperties = results.map((result) => ({
          propertyUniqueIdentifier: result.propertyUniqueIdentifier,
          propertyName: result.propertyName,
          numberofParcels: result.parcelCount,
          parcels: parcels,
          state: result.state,
          cooperatorName:
            result?.defaultCooperatorUniqueIdentifier !== null
              ? result?.cooperatorName?.firstname + " " + result?.cooperatorName?.lastName
              : "",
          defaultCooperatorUniqueIdentifier: result?.defaultCooperatorUniqueIdentifier,
          city: result.additionalStreetAddresses[0]?.city,
          county: result.county,
          acres: result.acres,
          addresses: "",
          landClass: result.landClass.displayText,
          mapPoint: result.mapPoint,
        }));
      }
    }
    if (outputProperties) {
      for (let i = 0; i < outputProperties.length; i++) {
        outputProperties[i].state = this.getStateNameFromId(outputProperties[i].state);
      }
    }

    this.setState({ options: outputProperties, isLoading: false, formMode: "search" });

    return outputProperties;
  };

  getStateNameFromId(stateId) {
    let states = this.state.states;
    const currentState = states.find((state) => state.id === stateId);
    return currentState ? currentState?.name : "";
  }

  formatMenu = (option) => {
    console.log(option);
    return (
      <Row className={styles.informationRow}>
        <Col lg={3} align="left">
          <p className={styles.informationTextTypeahead} style={{ fontWeight: "600" }}>
            {option.propertyName}
          </p>
        </Col>
        <Col lg={2} align="left">
          <p className={styles.informationTextTypeahead}>{option.city ? option.city : "No City"}</p>
        </Col>
        <Col lg={2} align="left">
          <p className={styles.informationTextTypeahead}>{option.state ? option.state : "No State"}</p>
        </Col>
        <Col lg={3} align="left">
          <p className={styles.informationTextTypeahead}>
            {option.cooperatorName ? option.cooperatorName : "No Cooperator"}
          </p>
        </Col>
        <Col lg={1} align="left">
          <p className={styles.informationTextTypeahead}>{option.numberofParcels ? option.numberofParcels : "0"}</p>
        </Col>
      </Row>
    );
  };

  async getProperty(propertyUniqueIdentifier) {
    if (propertyUniqueIdentifier) {
      const propertyDetails = await AgreementEntitiesAPI.GetProperty(propertyUniqueIdentifier);
      if (propertyDetails?.successful) {
        if (propertyDetails?.data.proposedEditsForPropertyWhenPartOfActiveAgreement) {
          return propertyDetails.data.proposedEditsForPropertyWhenPartOfActiveAgreement;
        } else {
          return propertyDetails.data;
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to retrieve property data",
          apiName: "GetProperty",
          responseUnsuccessful: propertyDetails?.unsuccessful,
          responseMessage: propertyDetails?.message,
        });
      }
    }
  }

  async updateProperty() {
    let errorsExist = false;
    let propertyUpdated = true;
    let propertyData = null;
    if (this.state.isUserEditing && !errorsExist) {
      if (!this.validateEditFields()) {
        toast.warning("All required fields must be entered");
        return;
      }

      const parcelAgreementCheck = await this.canTheseParcelsMakeUpANewAgreement();
      if (!parcelAgreementCheck && !this.state.dismissedConflictingAgreementParcelModal) {
        this.setState({ showConflictingAgreementParcelModal: true });
        return;
      }

      let parcelsChanged = false;
      if (this.state.addedParcels.length > 0) {
        let propertyID = this.state.selectedOption.propertyUniqueIdentifier
          ? this.state.selectedOption.propertyUniqueIdentifier
          : this.state.selectedOption.propertyUniqueReference;
        const parcelAdditionSuccess = await this.addParceltoProperty(propertyID, this.state.addedParcels);
        if (parcelAdditionSuccess?.successful) {
          parcelsChanged = true;
        }
      }

      // add/remove parcels
      let removedParcels = this.state.removedParcels;
      let parcelRemoveResponse = null;
      if (removedParcels.length > 0) {
        for (let i = 0; i < removedParcels.length; i++) {
          let parcelUniqueReference = removedParcels[i].parcelUniqueIdentifier
            ? removedParcels[i].parcelUniqueIdentifier
            : removedParcels[i].removedParcels[i].parcelUniqueIdentifier;
          parcelRemoveResponse = await AgreementEntitiesAPI.RemoveParcel(parcelUniqueReference);
        }
        if (!parcelRemoveResponse?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Parcel association could not be removed",
            apiName: "RemoveParcel",
            responseUnsuccessful: parcelRemoveResponse?.unsuccessful,
            responseMessage: parcelRemoveResponse?.message,
          });
        } else {
          parcelsChanged = true;
        }
      }
      let acres = 0;
      if (this.state.data.propertyTypeEdit === "Parcel") {
        acres = parseFloat(this.state.data.parcelAcresEdit);
      } else {
        acres = parseFloat(this.state.data.acresEdit);
      }

      let updatePropertyCall = "";
      let propertyID = this.state.selectedOption.propertyUniqueIdentifier
        ? this.state.selectedOption.propertyUniqueIdentifier
        : this.state.selectedOption.propertyUniqueReference;
      if (this.isPropertyChanged()) {
        const updatePropertyBody = {
          propertyName: this.state.data.propertyNameEdit,
          propertyAbbreviation: "",
          landClassEnumId: parseInt(this.state.data.landClassEdit),
          acres: acres,
          district: this.state.data.districtEdit,
          county: this.state.counties.find((county) => county.id === this.state.data.countyEditGuid)?.name,
          state: this.state.data.stateEdit,
          premID: "",
          latitude: this.state.data.latitudeEdit,
          longitude: this.state.data.longitudeEdit,
          defaultCooperatorUniqueIdentifier: this.state.data.primaryCooperatorEdit.value
            ? this.state.data.primaryCooperatorEdit.value
            : null,
          countryUniqueIdentifier: this.state.data.countryEdit,
        };

        updatePropertyCall = await AgreementEntitiesAPI.UpdateProperty(propertyID, updatePropertyBody);
        if (!updatePropertyCall?.successful) {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Property could not be updated",
            apiName: "UpdateProperty",
            responseUnsuccessful: updatePropertyCall?.unsuccessful,
            responseMessage: updatePropertyCall?.message,
          });
          propertyUpdated = false;
        }
      }

      if (this.isAddressChanged()) {
        const replaceAddressCall = await AgreementEntitiesAPI.ReplaceAdditionalAddressesOnProperty(propertyID, [
          {
            streetAddress: this.state.data.streetAddressEdit,
            city: this.state.data.cityEdit,
            zipCode: this.state.data.zipCodeEdit,
          },
        ]);
        if (replaceAddressCall?.successful) {
          propertyData = replaceAddressCall.data;
        } else {
          ErrorHandler.handleApiErrorMessage({
            errorContextMessage: "Property addresses could not be updated",
            apiName: "ReplaceAdditionalAddressesOnProperty",
            responseUnsuccessful: replaceAddressCall?.unsuccessful,
            responseMessage: replaceAddressCall?.message,
          });
          propertyUpdated = false;
        }
      } else {
        propertyData = await this.getProperty(propertyID);
      }

      if (!propertyUpdated) {
        ErrorHandler.showError("Property Edit failed.");
      } else {
        if (parcelsChanged) toast.success("Property edited with parcel changes and added to Agreement.");
        else toast.success("Property edited and added to Agreement.");
      }

      return propertyData;
    }
  }

  isAddressChanged = () => {
    let isAddressChanged = false;

    if (
      this.state.data.currentAddress.streetAddress !== this.state.data.streetAddressEdit ||
      this.state.data.currentAddress.city !== this.state.data.cityEdit ||
      this.state.data.currentAddress.zipCode !== this.state.data.zipCodeEdit
    ) {
      isAddressChanged = true;
    }
    return isAddressChanged;
  };

  isPropertyChanged = () => {
    let acres = this.state.data.acresEdit;
    if (this.state.propertyType === "Parcel") {
      acres = this.state.data.parcelAcresEdit;
    }
    let isPropertyChanged = false;
    if (
      this.state.data.currentProperty.propertyName !== this.state.data.propertyNameEdit ||
      this.state.data.currentProperty.landClassEnumId !== parseInt(this.state.data.landClassEdit) ||
      this.state.data.currentProperty.acres !== acres ||
      this.state.data.currentProperty.county !== this.state.data.countryEdit ||
      this.state.data.currentProperty.latitude !== this.state.data.latitudeEdit ||
      this.state.data.currentProperty.longitude !== this.state.data.longitudeEdit ||
      this.state.data.currentProperty.defaultCooperatorUniqueIdentifier !== this.state.data.primaryCooperatorEdit.value
    ) {
      isPropertyChanged = true;
    }
    return isPropertyChanged;
  };

  showParcelMapView = (parcels, location) => {
    // let parcelMap;
    if (sessionStorage.getItem("mapAccess") === "Yes") {
      return (
        <ParcelMapView
          parcelDetails={parcels}
          address={this.getPropertyAddress()}
          name={this.state.data.propertyNameEdit}
          location={location}
          agreementData={""}
          onAddProperty={this.handlePropertyAddition}
          onRemoveParcel={this.handleRemoveParcel}
          IsEditable={this.state.isUserEditing}
          IsEditParcelMap={true}
        />
      );
    } else {
      return (
        <SearchMap
          latitude={this.state.latitudeEditForMapFind}
          longitude={this.state.longitudeEditForMapFind}
          address={this.state.addressEditStringForMapFind}
          shouldIRecalculate={this.state.doesMapNeedRerender}
        />
      );
    }
  };
  renderEditPropertyForm = () => {
    let data = this.state.data;
    if (!this.state.selectedOption) {
      return <div style={{ paddingTop: "300px" }}></div>;
    }

    const isEditable = this.state.formMode === "search" && this.state.isUserEditing;

    let parcels = this.state.parcelData ? this.state.parcelData : this.state?.selectedOption?.propertyObject?.parcels;

    let location = JSON.stringify([
      this.state?.selectedOption?.propertyObject?.latitude?.toString(),
      this.state?.selectedOption?.propertyObject?.longitude?.toString(),
    ]);
    return (
      <div>
        <Row
          className={styles.addPropertyModalCreateMapContainer}
          id="search-map-container"
          hidden={data.propertyTypeEdit === "Parcel"}
        >
          <SearchMap
            latitude={this.state.latitudeEditForMapFind}
            longitude={this.state.longitudeEditForMapFind}
            address={this.state.addressEditStringForMapFind}
            shouldIRecalculate={this.state.doesMapNeedRerender}
          />
        </Row>
        <Row id="search-map-container" hidden={data.propertyTypeEdit === "Non-Parcel"}>
          <Col className="px-0">
            <div className={styles.mapContainer}>{this.showParcelMapView(parcels, location)}</div>
          </Col>
        </Row>

        <Row className={styles.addPropertySearchEditSubHeader}>
          <Col className="text-left"></Col>
          <Col></Col>
          <Col className="text-right">
            <Button
              variant="link"
              className={styles.addContactSearchEditLink}
              onClick={() => this.setState({ isUserEditing: !this.state.isUserEditing })}
            >
              <span className={styles.addContactSearchEditLinkText}>Edit</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <br></br>
        </Row>

        <div>
          {this.renderSelectedParcels()}
          <Row xs={1} md={2} hidden={data.propertyTypeEdit === "Non-Parcel"}>
            <Col lg={3}></Col>
            <Col lg={3}></Col>
            <Col lg={3}>
              {isEditable
                ? this.renderInput("parcelAcresEdit", "Area in Acres", "number", "Enter area in acres", "required")
                : this.renderLabel("parcelAcresEdit", "Area in Acres", this.state.data.acresEdit)}
              { }
            </Col>
            <Col lg={3}>
              {isEditable
                ? this.renderInput(
                  "parcelCalculatedAcresEdit",
                  "Area in Acres (Calculated)",
                  "text",
                  Number.parseFloat(data.parcelCalculatedAcresEdit) === 0 ? "" : data.parcelCalculatedAcresEdit,
                  "readOnly"
                )
                : this.renderLabel(
                  "parcelCalculatedAcresEdit",
                  "Area in Acres (Calculated)",
                  this.state.data.parcelCalculatedAcresEdit
                )}
            </Col>
          </Row>

          <Row className={styles.greenBottomLine} />
          <Row>
            <br></br>
          </Row>
          <Row className={styles.sectionTitles}>Property Information</Row>
          <Row className={styles.addPropertyModalRow}>
            <Col lg={12}>
              {isEditable
                ? this.renderInput("streetAddressEdit", "Street Address", "text", "Enter Address", "required")
                : this.renderLabel("streetAddressEdit", "Street Address", this.state.data.streetAddressEdit)}
            </Col>
          </Row>
          <Row className={styles.addPropertyModalRow}>
            <Col lg={3}>
              {isEditable
                ? this.renderInput("cityEdit", "City", "text", "Enter City", "required")
                : this.renderLabel("cityEdit", "City", this.state.data.cityEdit)}
            </Col>
            <Col lg={3}>
              {isEditable
                ? this.renderSelect("countyEditGuid", "County", this.state.counties, "Select County", "required")
                : this.renderLabel(
                  "countyEdit",
                  "County",
                  this.state.counties.find((county) => county.name === this.state.data.countyEdit)?.name
                )}
            </Col>
            <Col lg={3}>
              {this.renderLabel(
                "stateEdit",
                "State",
                this.state.states.find((state) => state.id === this.state.data.stateEdit)?.name
              )}
            </Col>
            <Col lg={3}>
              {isEditable
                ? this.renderInput("zipCodeEdit", "Zip Code", "text", "Enter Zip", "required")
                : this.renderLabel("zipCodeEdit", "Zip Code", this.state.data.zipCodeEdit)}
            </Col>
          </Row>
          <Row hidden={data.propertyTypeEdit === "Parcel"}></Row>
          <Row className={styles.addPropertyModalRow} xs={1} md={2}>
            <Col lg={3}>
              {this.renderLabel(
                "countryEdit",
                "Country",
                this.state.countries.find((country) => country.id === this.state.data.countryEdit)?.name
              )}
            </Col>
            <Col lg={3}>
              {isEditable
                ? this.renderInput(
                  "latitudeEdit",
                  "Latitude",
                  "number",
                  "Enter Latitude",
                  data.propertyTypeEdit === "Non-Parcel" ? "required" : ""
                )
                : this.renderLabel("latitudeEdit", "Latitude", this.state.data.latitudeEdit)}
            </Col>
            <Col lg={3}>
              {isEditable
                ? this.renderInput(
                  "longitudeEdit",
                  "Longitude",
                  "number",
                  "Enter Longitude",
                  data.propertyTypeEdit === "Non-Parcel" ? "required" : ""
                )
                : this.renderLabel("longitudeEdit", "Longitude", this.state.data.longitudeEdit)}
            </Col>
            <Col lg={3} hidden={data.propertyTypeEdit === "Non-Parcel"}>
              {isEditable
                ? this.renderInput("propertyOwnerEdit", "Property Owner", "text", "Enter Name")
                : this.renderLabel("propertyOwnerEdit", "Property Owner", this.state.data.propertyOwnerEdit)}
            </Col>
            <Col lg={3}>
              {isEditable ? (
                <Button
                  className={styles.findButton}
                  onClick={this.mapFind}
                  hidden={this.state.data.propertyTypeEdit === "Parcel"}
                >
                  <span className={globalStyles.modalSubmitButtonText}>Update Map Location</span>
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row xs={1} md={2}>
            <Col>
              {isEditable
                ? this.renderInput("propertyNameEdit", "Property Name", "text", "Enter name", "required")
                : this.renderLabel("propertyNameEdit", "Property Name", this.state.data.propertyNameEdit)}
            </Col>
            <Col className="text-left">
              {isEditable ? (
                <div>
                  <Form.Label className={styles.fieldLabels}>Primary Cooperator</Form.Label>
                  <Fragment>
                    <AsyncSelect
                      value={data.primaryCooperatorEdit}
                      openMenuOnClick={false}
                      placeholder="Enter Name"
                      aria-label="Primary Cooperator Search Field. Prepopulated if a cooperator was chosen in the previous page."
                      loadOptions={this.handleCooperatorSearch}
                      defaultInputValue={this.props.primaryCooperatorName}
                      onChange={this.handleCooperatorChange}
                      components={{ DropdownIndicator, NoOptionsMessage }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "",
                          primary: "#ced4da",
                        },
                      })}
                      styles={{
                        control: (styles) => ({ ...styles, fontSize: "14px" }),
                        menu: (styles) => ({ ...styles, marginTop: "0px" }),
                        noOptionsMessage: (base) => ({ ...base, backgroundColor: "#f2f2f2" }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          const color = "#EAF3F1";
                          return {
                            ...styles,
                            fontSize: "12px",
                            textAlign: "left",
                            borderBottom: "1px solid #f2f2f2",
                            backgroundColor: isDisabled ? null : isSelected ? color : isFocused ? color : null,
                            color: isDisabled ? "#ccc" : isSelected ? ("#ccc" ? "white" : "black") : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor: !isDisabled && (isSelected ? color : null),
                            },
                          };
                        },
                        indicatorSeparator: () => {
                          //do nothing
                        },
                        placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                      }}
                    />
                  </Fragment>
                </div>
              ) : (
                this.renderLabel(
                  "primaryCooperatorEdit",
                  "Primary Cooperator",
                  this.state.data?.primaryCooperatorEdit?.label
                )
              )}
            </Col>
          </Row>
          <Row xs={1} md={2} hidden={data.propertyTypeEdit === "Parcel"}>
            <Col>
              {isEditable
                ? this.renderInput("acresEdit", "Area in Acres", "number", "Enter area in acres", "required")
                : this.renderLabel("acresEdit", "Area in Acres", this.state.data.acresEdit)}
              { }
            </Col>
            <Col>
              {isEditable
                ? this.renderInput(
                  "calculatedAcresEdit",
                  "Area in Acres (Calculated)",
                  "text",
                  data.calculatedAcresEdit,
                  "readOnly"
                )
                : this.renderLabel(
                  "calculatedAcresEdit",
                  "Area in Acres (Calculated)",
                  this.state.data.calculatedAcresEdit
                )}
            </Col>
          </Row>
          <Row hidden={this.state.data.propertyTypeEdit === "Parcel"}>
            <Col lg={6}>
              {isEditable
                ? this.renderSelect("landClassEdit", "Land Class", this.state.landClasses, "Select Type")
                : this.renderLabel(
                  "landClassEdit",
                  "Land Class",
                  this.state.landClasses.find((landClass) => landClass.id === this.state.data.landClassEdit)
                    ?.displayText
                )}
            </Col>
          </Row>
          <Row xs={1} md={2}>
            <Col></Col>
          </Row>
        </div>

        <Row className={styles.spacerRow}></Row>
      </div>
    );
  };

  renderSearchEditSection = () => {
    return (
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <Row className="mt-3">
          <Col className="text-left">
            <Form.Label className={styles.searchPropertyHeader}>Search for an Existing Property</Form.Label>
            <div className={styles.asyncTypeaheadContainer}>
              <AsyncSelect
                id="asyncMultiColSelect"
                aria-labelledby="propertySearch"
                name="propertySearch"
                openMenuOnClick={false}
                placeholder="Search for a Property"
                value={this.state.selectedOption}
                components={{ DropdownIndicator, Option, SingleValue }}
                styles={{
                  indicatorSeparator: () => {
                    //do nothing
                  },
                  placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                  option: (provided, state) => ({
                    ...provided,
                    textAlign: "left",
                    fontSize: "14px",
                  }),
                  singleValue: (provided) => ({ ...provided, fontSize: "14px" }),
                }}
                theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary25: "#eaf3f1" } })}
                loadOptions={this.handlePropertySearch}
                onChange={this.handlePropertyChange}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{this.renderEditPropertyForm()}</Col>
        </Row>
        <Row xs={1} md={2}>
          <Col></Col>
        </Row>
        <Row className="text-right" hidden={!this.state.isUserEditing}>
          <Col>
            <Button className={styles.buttons} variant="outline-primary" onClick={() => this.handleReset("Non-Parcel")}>
              Cancel
            </Button>
            <Button
              className={styles.buttons}
              variant="primary"
              onClick={async () => {
                await this.handlePropertyUpdate();
              }}
            >
              Update
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  handlePropertyUpdate = async () => {
    let selProperties = this.state.selectedProperties;

    let propertyData = await this.updateProperty();
    if (propertyData) {
      let updatedProperty = this.populateProperty(propertyData);

      if (updatedProperty) {
        if (selProperties.length > 0) {
          for (var i = 0; i < selProperties.length; i++) {
            let propertyID = selProperties[i].propertyUniqueIdentifier
              ? selProperties[i].propertyUniqueIdentifier
              : selProperties[i].propertyUniqueReference;
            if (propertyID === updatedProperty.propertyUniqueIdentifier) {
              selProperties.splice(i, 1);
              selProperties.push(updatedProperty);
            }
          }
        }
        this.setState({
          selectedProperties: selProperties,
        });

        this.props.passDataUp("agreementProperties", this.state.selectedProperties);
        this.clearEditFields();
      }
    }
  };

  handlePropertyChange = async (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption) {
      let { data } = this.state;
      let selection = selectedOption;

      if (this.isDuplicate(selection)) {
        toast.info("Selected Property is already Associated");
      } else {
        const getAgreementsByPropertyCall = await AgreementAPI.GetActiveAgreementByProperty(
          selection.propertyUniqueIdentifier
        );
        if (getAgreementsByPropertyCall?.successful) {
          toast.warning(
            "Selected Property is already associated with a " +
            getAgreementsByPropertyCall.data.agreementStatus.displayText +
            " Agreement " +
            getAgreementsByPropertyCall.data.commonName
          );
        }
        let selProperties = this.state.selectedProperties;
        selProperties.push(selection);
        this.props.passDataUp("agreementProperties", selProperties);
        if (this.state.selectedProperties.length > 0) {
          this.setState({ isPropertySelected: true });
        }
        const fullProperty = await this.getProperty(selection.propertyUniqueIdentifier);
        selection.propertyObject = fullProperty;
        data = this.populateEditPropertyObject(data, selection.propertyObject);

        this.setState({
          selectedOption: selection,
          data,
          isLoading: false,
          latitudeEditForMapFind: data.latitudeEdit ? parseFloat(data.latitudeEdit).toFixed(5) : "",
          longitudeEditForMapFind: data.longitudeEdit ? parseFloat(data.longitudeEdit).toFixed(5) : "",
          addressEditStringForMapFind: this.getPropertyAddress(),
          doesMapNeedRerender: true,
          selectedProperties: selProperties,
          parcelData: selection.propertyObject?.parcels,
        });
      }
    } else {
      this.clearEditFields();
    }
  };

  clearEditFields() {
    let { data } = this.state;
    data.propertyTypeEdit = "";
    data.propertyNameEdit = "";
    data.primaryCooperatorEdit = "";
    data.acresEdit = "";
    data.calculatedAcresEdit = "";
    data.parcelAcresEdit = "";
    data.parcelCalculatedAcresEdit = "";
    data.landClassEdit = 0;
    data.streetAddressEdit = "";
    data.cityEdit = "";
    data.zipCodeEdit = "";
    data.districtEdit = "";
    data.countyEdit = "";
    data.countyEditGuid = null;
    data.latitudeEdit = "";
    data.longitudeEdit = "";
    data.propertyOwnerEdit = "";

    this.setState({
      data,
      selectedOption: null,
      isLoading: false,
      latitudeEditForMapFind: "",
      longitudeEditForMapFind: "",
      addressEditStringForMapFind: "",
      parcelData: [],
      isParcelSelected: false,
      addedParcels: [],
      removedParcels: [],
    });
  }

  populateEditPropertyObject(data, propertyObject) {
    if (propertyObject?.parcels.length > 0) {
      data.propertyTypeEdit = "Parcel";
    } else {
      data.propertyTypeEdit = "Non-Parcel";
    }
    data.propertyOwnerEdit = propertyObject?.propertyOwner;
    data.propertyNameEdit = propertyObject?.propertyName;
    data.primaryCooperatorEdit = {
      value: propertyObject.defaultCooperatorUniqueIdentifier ? propertyObject.defaultCooperatorUniqueIdentifier : "",
      label: propertyObject.cooperatorName
        ? `${propertyObject.cooperatorName?.firstname} ${propertyObject.cooperatorName?.lastName}`
        : "",
    };
    data.propertyUniqueIdentifier = propertyObject.propertyUniqueIdentifier;
    data.acresEdit = propertyObject.acres;
    data.calculatedAcresEdit = propertyObject.acres;
    data.parcelAcresEdit = propertyObject.acres;
    data.parcelCalculatedAcresEdit = propertyObject.acres;
    data.landClassEdit = propertyObject.landClass?.id;
    data.streetAddressEdit = propertyObject.additionalStreetAddresses
      ? propertyObject.additionalStreetAddresses[0]?.streetAddress
      : "";
    data.cityEdit = propertyObject.additionalStreetAddresses ? propertyObject.additionalStreetAddresses[0]?.city : "";
    data.stateEdit = propertyObject.state;
    data.countryEdit = propertyObject.countryUniqueIdentifier;
    data.zipCodeEdit = propertyObject.additionalStreetAddresses
      ? propertyObject.additionalStreetAddresses[0]?.zipCode
      : "";
    data.districtEdit = "10";
    data.countyEdit = propertyObject?.county;

    data.countyEditGuid = this.state.counties.find((county) => county.name === propertyObject?.county)?.id;

    data.latitudeEdit = propertyObject.latitude ? propertyObject.latitude : "";
    data.longitudeEdit = propertyObject.longitude ? propertyObject.longitude : "";
    data.currentAddress = {
      streetAddress: data.streetAddressEdit,
      city: data.cityEdit,
      zipCode: data.zipCodeEdit,
    };
    data.currentProperty = {
      propertyName: data.propertyNameEdit,
      landClassEnumId: parseInt(data.landClassEdit),
      acres: data.acresEdit,
      county: data.countryEdit,
      latitude: data.latitudeEdit,
      longitude: data.longitudeEdit,
      defaultCooperatorUniqueIdentifier: data.primaryCooperatorEdit,
    };
    return data;
  }

  getMessageBody(data) {
    //TODO -- Remove displayText after API Integration
    let countyName = this.state?.counties?.find((county) => county.id === data.county)?.name;

    return {
      propertyName: data.propertyName,
      propertyAbbreviation: "",
      landClassEnumId: data.propertyType === "Non-Parcel" ? parseInt(data.landClass, 10) : 8,
      propertyTypeEnumId: data.propertyType === "Non-Parcel" ? 2 : 1,
      acres: data.propertyType === "Non-Parcel" ? parseFloat(data.acres, 10) : parseFloat(data.parcelAcres, 10),
      district: data.district,
      county: countyName,
      state: data.state,
      specialInstructions: "",
      latitude: JSON.parse(data.mapPoint)[0] ? parseFloat(JSON.parse(data.mapPoint)[0]) : null,
      longitude: JSON.parse(data.mapPoint)[1] ? parseFloat(JSON.parse(data.mapPoint)[1]) : null,
      parcelCount: 0,
      defaultCooperatorUniqueIdentifier: data.primaryCooperator.value,
      countryUniqueIdentifier: data.country,
      additionalStreetAddresses: [
        {
          streetAddress: this.state.data.streetAddress,
          city: this.state.data.city,
          zipCode: this.state.data.zipCode,
        },
      ],
    };
  }

  handleRemoveParcel = (parcel, index) => {
    const { data } = this.state;
    let parcels = this.state.parcelData
      ? this.state.parcelData
      : this.state?.selectedProperties[0]?.propertyObject?.parcels;

    if (this.state.formMode === "create") {
      if (!index) {
        const rowIndex = parcels.findIndex((r) => r.acctid === parcel.acctid);
        index = rowIndex;
      }
      parcels.splice(index, 1);
      data.parcelCalculatedAcres = data.parcelCalculatedAcres - parcel.acres;
      this.setState({
        parcelData: parcels,
        data,
      });
    } else if (this.state.formMode === "search") {
      if (!index) {
        const rowIndex = parcels.findIndex((r) => (r.parcelId === parcel.acctid ? parcel.acctid : parcel.parcelId));
        index = rowIndex;
      }

      parcels.splice(index, 1);

      let removedParcels = this.state.removedParcels;
      removedParcels.push(parcel);
      data.parcelCalculatedAcresEdit = data.parcelCalculatedAcresEdit - parcel.acres;
      this.setState({
        parcelData: parcels,
        removedParcels: removedParcels,
        data,
      });
    }
  };
  handlePropertyAddition = async (parcelData) => {
    const { data } = this.state;
    if (this.state.formMode === "create") {
      let owner = parcelData.OwnerFirstName + " " + parcelData.OwnerLastName;
      let parcels = this.state.parcelData;
      let totalArea = 0;
      if (parcels?.length > 0) {
        let parcel = parcels.find((parcelObj) => parcelObj.acctid === parcelData.acctid);
        if (!parcel) {
          parcels.push(parcelData);
        }
        parcels.forEach((parcelObj2) => {
          totalArea += parcelObj2.acres ? parcelObj2.acres : 0;
        });
      } else {
        parcels = [];
        parcels.push(parcelData);
        totalArea = parcelData.acres;
      }
      data.parcelCalculatedAcres = totalArea;

      data.parcelCalculatedAcres = totalArea;
      data.propertyOwner = owner;
      data.latitude = parcelData.latitude;
      data.longitude = parcelData.longitude;
      this.setState({
        parcelData: parcels,
        isParcelSelected: true,
        propertyOwner: owner,
        data,
      });
    } else if (this.state.formMode === "search") {
      let owner = parcelData.OwnerFirstName + " " + parcelData.OwnerLastName;
      let addedParcels = this.state.addedParcels;
      addedParcels.push(parcelData);

      let parcels = this.state.parcelData
        ? this.state.parcelData
        : this.state?.selectedProperties[0]?.propertyObject?.parcels;

      let totalArea = 0;
      if (parcels?.length > 0) {
        let parcel = parcels.find((parcelObj) => parcelObj.acctid === parcelData.acctid);

        if (!parcel) {
          parcels.push(parcelData);
        }
        parcels.forEach((parcelObj2) => {
          totalArea += parcelObj2.acres ? parcelObj2.acres : 0;
        });
      } else {
        parcels.push(parcelData);
        totalArea = parcelData.acres;
      }
      data.parcelCalculatedAcresEdit = totalArea;
      data.propertyOwnerEdit = owner;
      data.latitudeEdit = parcelData.latitude;
      data.longitudeEdit = parcelData.longitude;
      this.setState({
        parcelData: parcels,
        isParcelSelected: true,
        addedParcels: addedParcels,
        data,
      });
    }
  };

  handleParcelAssociation = (e, parcel) => {
    const { data } = this.state;
    let stateID = this.getStateGuid(parcel.state);
    let countyID = this.getCountyGuid(parcel.county.toUpperCase());
    if (this.state.formMode === "create") {
      if (e.target.checked) {
        data.city = parcel.city;
        data.state = stateID;
        data.zipCode = parcel.zip;
        data.streetAddress = parcel.streetAddress;
        data.county = countyID;
        data.propertyOwner = parcel.OwnerFirstName + " " + parcel.OwnerLastName;
        data.latitude = parcel.latitude;
        data.longitude = parcel.longitude;
      } else {
        data.city = "";
        data.state = "";
        data.zipCode = "";
        data.streetAddress = "";
        data.parcelAcres = "";
        data.parcelCalculatedAcres = "";
        data.propertyOwner = "";
        data.latitude = "";
        data.longitude = "";
      }
    } else if (this.state.formMode === "search") {
      if (e.target.checked) {
        data.cityEdit = parcel.city ? parcel.city : data.cityEdit;
        data.stateEdit = this.state.data.stateEdit;
        data.zipCodeEdit = parcel.zip;
        data.streetAddressEdit = parcel.streetAddress;
        data.countyEdit = countyID;
        data.propertyOwnerEdit = parcel.parcelOwner
          ? parcel.parcelOwner
          : parcel.OwnerFirstName + " " + parcel.OwnerLastName;
        data.latitudeEdit = parcel.latitude;
        data.longitudeEdit = parcel.longitude;
      } else {
        data.cityEdit = "";
        data.stateEdit = "";
        data.zipCodeEdit = "";
        data.streetAddressEdit = "";
        data.parcelAcresEdit = "";
        data.parcelCalculatedAcresEdit = "";
        data.propertyOwnerEdit = "";
        data.latitudeEdit = "";
        data.longitudeEdit = "";
      }
    }

    this.setState({ data });
  };

  handleSelectChanges = (e, fieldName) => {
    const { data } = this.state;
    data[fieldName] = e;
    this.setState({ data });
  };

  async updateAvailableStates(countryId) {
    const states = await this.getAvailableStates(countryId);
    if (states) {
      this.setState({ states });
    }
  }

  async updateCountiesByState(stateId) {
    const counties = await this.getCountyListbyState(stateId);
    if (counties) this.setState({ counties });
  }

  async getAvailableStates(countryId) {
    let results = [];
    if (!countryId) {
      results = await ReferenceFileAPI.GetAllStates();
    } else {
      results = await ReferenceFileAPI.GetStatesByCountry(countryId);
    }
    let returnStates = [];
    if (results) {
      returnStates = results
        .map((state) => ({
          id: state.stateUniqueIdentifier,
          name: state.state_code,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    if (returnStates) {
      this.setState({ returnStates });
    }
    return returnStates;
  }

  handleCooperatorSearch = async (inputValue) => {
    return await this.getCooperatorsFromAPI(inputValue);
  };

  handleCooperatorChange = (input) => {
    const { data } = this.state;
    data.primaryCooperator = input;

    this.setState({ data });
    return input;
  };

  closeDuplicatedPropertyModal = () => {
    this.setState({ showDuplicatedPropertyModal: false });
  };

  handleForceCreation = () => {
    this.setState({ showSuccessfullCreateModal: true });
  };

  closeSuccessfulCreateModal = () => {
    this.setState({ showSuccessfullCreateModal: false });
  };

  getStateGuid = (state_code) => {
    let stateGuid = this.state.states.find((state) => state.name === state_code)?.id;
    return stateGuid;
  };

  getCountyGuid = (countyname) => {
    let countyGuid = this.state.counties.find((county) => county.name === countyname)?.id;

    return countyGuid;
  };
  handleModalModeSelection = (e) => {
    this.setState({ formMode: e });
  };

  renderSelectedParcels() {
    const { data } = this.state;

    if (data.propertyType === "Non-Parcel" || data.propertyTypeEdit === "Non-Parcel") {
      return;
    }

    if (this.state.formMode === "create") {
      if (this.state.isParcelSelected) {
        return (
          <div className={styles.addPropertyCreateModalRow}>
            <Row className={styles.sectionTitles}>{data.propertyType} Information</Row>
            <Row>
              <Col>
                <hr style={{ borderBottom: "0.6px solid #E8E8E8" }} />
              </Col>
            </Row>
            <Row>
              <Col align="left" lg={2}>
                <span className={globalStyles.formLabel}>Primary Parcel</span>
              </Col>
              <Col align="left" lg={2}>
                <span className={globalStyles.formLabel}>Parcel ID</span>
              </Col>
              <Col align="left" lg={3}>
                <span className={globalStyles.formLabel}>Parcel Owner</span>
              </Col>
              <Col align="left" lg={3}>
                <span className={globalStyles.formLabel}>Parcel Acreage</span>
              </Col>
              <Col align="left" lg={2}>
                <span className={globalStyles.formLabel}>Actions</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr style={{ borderBottom: "0.6px solid #E8E8E8" }} />
              </Col>
            </Row>

            {this.state.parcelData?.map((parcel, index) => {
              return (
                <React.Fragment key={index}>
                  <Row>
                    <Col align="left" lg={2}>
                      <Form.Check
                        type="radio"
                        name="parcel"
                        onChange={(e) => this.handleParcelAssociation(e, parcel)}
                      />
                    </Col>
                    <Col align="left" lg={2}>
                      <span className={globalStyles.formData}>{parcel.acctid}</span>
                    </Col>
                    <Col align="left" sm={3}>
                      <span className={globalStyles.formData}>
                        {parcel.OwnerFirstName + " " + parcel.OwnerLastName}
                      </span>
                    </Col>
                    <Col align="left" lg={3}>
                      <span className={globalStyles.formData}>{parcel.acres}</span>
                    </Col>

                    <Col align="left" lg={2}>
                      <Button
                        className={styles.removeButtonLinks}
                        variant="link"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleRemoveParcel(parcel, index)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                  <hr></hr>
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    } else if (this.state.formMode === "search") {
      let savedParcels = this.state.parcelData
        ? this.state.parcelData
        : this.state?.selectedProperties[0]?.propertyObject?.parcels;

      return (
        <div className={styles.addPropertyCreateModalRow}>
          <Row className={styles.sectionTitles}>{data.propertyType} Information</Row>
          <Row>
            <Col>
              <hr style={{ borderBottom: "0.6px solid #E8E8E8" }} />
            </Col>
          </Row>
          <Row>
            <Col align="left" lg={2} hidden={true}>
              <span className={globalStyles.formLabel}>Primary Parcel</span>
            </Col>
            <Col align="left" lg={2}>
              <span className={globalStyles.formLabel}>Parcel ID</span>
            </Col>
            <Col align="left" lg={3}>
              <span className={globalStyles.formLabel}>Parcel Owner</span>
            </Col>
            <Col align="left" lg={3}>
              <span className={globalStyles.formLabel}>Parcel Acreage</span>
            </Col>
            <Col align="left" lg={2} hidden={savedParcels?.length == 1 || !this.state.isUserEditing}>
              <span className={globalStyles.formLabel}>Actions</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr style={{ borderBottom: "0.6px solid #E8E8E8" }} />
            </Col>
          </Row>

          {savedParcels?.map((parcel, index) => {
            return (
              <React.Fragment key={index}>
                <Row>
                  <Col align="left" lg={2} hidden={true}>
                    <Form.Check type="radio" name="parcel" onChange={(e) => this.handleParcelAssociation(e, parcel)} />
                  </Col>
                  <Col align="left" lg={2}>
                    <span className={globalStyles.formData}>{parcel.acctid ? parcel.acctid : parcel.parcelId}</span>
                  </Col>
                  <Col align="left" sm={3}>
                    <span className={globalStyles.formData}>
                      {parcel.parcelOwner ? parcel.parcelOwner : parcel.OwnerFirstName + " " + parcel.OwnerLastName}
                    </span>
                  </Col>
                  <Col align="left" lg={3}>
                    <span className={globalStyles.formData}>{parcel.acres === 0 ? "N/A" : parcel.acres}</span>
                  </Col>

                  <Col align="left" lg={2} hidden={savedParcels?.length === 1 || !this.state.isUserEditing}>
                    <Button
                      className={styles.removeButtonLinks}
                      variant="link"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.handleRemoveParcel(parcel, index)}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
                <hr></hr>
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  }

  mapFind = async () => {
    let lat = parseFloat(this.state.data.latitude);
    let long = parseFloat(this.state.data.longitude);
    let propertyAddress = this.getPropertyAddress();

    if (lat && long) {
      this.setState({
        latitudeForMapFind: lat,
        longitudeForMapFind: long,
        addressStringForMapFind: "",
        hasMapRendered: true,
        doesMapNeedRerender: true,
      });
    } else if (propertyAddress) {
      let mapPointFromAddress = await GeoCoding.getLocationByAddress(propertyAddress);
      let data = this.state.data;
      if (mapPointFromAddress) {
        data.latitude = mapPointFromAddress[0];
        data.longitude = mapPointFromAddress[1];
        this.setState({
          addressStringForMapFind: propertyAddress,
          latitudeForMapFind: mapPointFromAddress[0],
          longitudeForMapFind: mapPointFromAddress[1],
          data,
          hasMapRendered: true,
          doesMapNeedRerender: true,
        });
      } else {
        ErrorHandler.showError("Please check the address");
        this.setState({
          hasMapRendered: false,
          doesMapNeedRerender: false,
        });
      }
    } else {
      ErrorHandler.showError(
        "Please provide either a full address or a latitude and longitude pair to find a map point."
      );
    }
  };

  showParcelMap() {
    if (sessionStorage.getItem("mapAccess") === "Yes") {
      return <ParcelMap onAddProperty={this.handlePropertyAddition} onRemoveParcel={this.handleRemoveParcel} />;
    } else {
      let map = (
        <div className={styles.mapContainer}>
          {" "}
          <Image src={missingmap} alt="missing map" className={styles.mapMissing} />
        </div>
      );
      return map;
    }
  }

  closeConflictingAgreementParcelModal = () => {
    this.setState({ showConflictingAgreementParcelModal: false, dismissedConflictingAgreementParcelModal: true });
  };

  useCooperatorAddressForNonParcelProperty = ({ currentTarget: input }) => {
    let { data } = this.state;

    if (this.state.primaryCooperatorState !== data.state) {
      ErrorHandler.showErrorWithMessage(
        "Cooperator does not live in the currently selected state. Cannot auto populate address."
      );
      return;
    }

    if (input.checked) {
      data.streetAddress = this.state.primaryCooperatorStreet;
      data.city = this.state.primaryCooperatorCity;
      data.country = this.state.primaryCooperatorCountry;
      data.state = this.state.primaryCooperatorState;
      data.zipCode = this.state.primaryCooperatorZip;
      data.useCooperatorAddressChecked = true;
    } else {
      data.streetAddress = "";
      data.city = "";
      data.country = "";
      data.state = "";
      data.zipCode = "";
      data.useCooperatorAddressChecked = false;
    }

    this.setState({ data });
  };

  render() {
    const { data, states, countries } = this.state;

    return (
      <div>
        <div className={styles.Step2} data-testid="searchAgreements">
          <h5 className={styles.pageTitles}>{this.state.pageHeader}</h5>
          <Card className="mb-4 mt-2">
            <Card.Header as="h4" className={styles.cardTitles}>
              Property Identification
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="text-center my-1">
                  <Tabs
                    className={styles.centerTabs}
                    defaultActiveKey={this.state.currentTab}
                    onSelect={this.handleModalModeSelection}
                  >
                    <Tab eventKey="create" title="Create a New Property">
                      <Row className="mt-4" xs={1} md={8}>
                        <Col className="text-center">
                          <Form.Group data-testid="propertyType">
                            <Form.Check
                              type="radio"
                              name="propertyType"
                              inline
                              label="Parcel"
                              id="parcel"
                              onChange={() => this.setParcelProperty()}
                              defaultChecked={data.propertyType === "Parcel"}
                              disabled={this.state.currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE"}
                            />
                            <Form.Check
                              type="radio"
                              name="propertyType"
                              inline
                              label="Non-Parcel"
                              id="nonParcel"
                              onChange={() => this.setNonParcelProperty()}
                              defaultChecked={data.propertyType === "Non-Parcel"}
                            />
                          </Form.Group>
                        </Col>
                        <Col></Col>
                      </Row>

                      <Row className={styles.greenBottomLine} />
                      <Row id="search-map-container" hidden={data.propertyType === "Non-Parcel"}>
                        <Col className="px-0">
                          <div className={styles.mapContainer}>{this.showParcelMap()}</div>
                        </Col>
                      </Row>
                      <Row xs={1} md={2}>
                        <Col></Col>
                      </Row>
                      {this.renderSelectedParcels()}
                      <Row
                        className={styles.addPropertyCreateModalRow}
                        xs={1}
                        md={2}
                        hidden={data.propertyType === "Non-Parcel"}
                      >
                        <Col lg={3}></Col>
                        <Col lg={3}></Col>
                        <Col lg={3}>
                          {this.renderInput(
                            "parcelAcres",
                            "Area in Acres",
                            "number",
                            "Enter area in acres",
                            "required"
                          )}
                        </Col>
                        <Col lg={3}>
                          {this.renderInput(
                            "parcelCalculatedAcres",
                            "Area in Acres (Calculated)",
                            "text",
                            data.parcelCalculatedAcres,
                            "readOnly"
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <br></br>
                      </Row>

                      <Row className={styles.greenBottomLine} hidden={data.propertyType === "Non-Parcel"} />
                      <Row className={styles.sectionTitles}>
                        <Col lg={12} className="text-left">
                          Property Information
                        </Col>
                      </Row>
                      <Row hidden={data.propertyType === "Parcel"}>
                        <Col lg={12} className="text-left">
                          <Form.Check
                            name="useCooperatorAddress"
                            label="Cooperator Lives on this Property"
                            onChange={this.useCooperatorAddressForNonParcelProperty}
                            isChecked={data.useCooperatorAddressChecked}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          {this.renderInput("streetAddress", "Street Address", "text", "Enter Address")}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>{this.renderInput("city", "City", "text", "Enter City")}</Col>
                        <Col lg={3} id="countyCol">
                          {this.state.currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE"
                            ? this.renderInput("countyName", "County", "text", "County", ["required", "readOnly"])
                            : this.renderSelect("county", "County", this.state.counties, "Select County", "required")}
                        </Col>
                        <Col lg={3} id="stateCol">
                          {this.renderInput("stateName", "State", "text", "State", ["required", "readOnly"])}
                        </Col>
                        <Col lg={3}>{this.renderInput("zipCode", "Zip Code", "text", "Enter Zip")}</Col>
                      </Row>

                      <Row xs={1} md={2}>
                        <Col lg={3} id="countryCol">
                          {this.state.currentUsaState.stateName.toUpperCase() === "DEPARTMENT OF DEFENSE"
                            ? this.renderSelect("country", "Country", this.state.countries, "Select", "required")
                            : this.renderInput("countryName", "Country", "text", "Country", ["required", "readOnly"])}
                        </Col>

                        <Col lg={3}>
                          {this.renderInput(
                            "latitude",
                            "Latitude",
                            "number",
                            "Enter Latitude",
                            data.propertyType === "Non-Parcel" ? "required" : ""
                          )}
                        </Col>
                        <Col lg={3}>
                          {this.renderInput(
                            "longitude",
                            "Longitude",
                            "number",
                            "Enter Longitude",
                            data.propertyType === "Non-Parcel" ? "required" : ""
                          )}
                        </Col>
                        <Col lg={3}>
                          <div hidden={data.propertyType === "Non-Parcel"}>
                            {this.renderInput("propertyOwner", "Property Owner", "text", "Enter Name")}
                          </div>
                          <div hidden={data.propertyType === "Parcel"}>
                            <Button className={styles.findButton} onClick={this.mapFind}>
                              <span className={globalStyles.modalSubmitButtonText}>Find</span>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <div className="mx-2">
                        <Row>
                          <br></br>
                        </Row>
                        <Row
                          hidden={!this.state.hasMapRendered || data.propertyType === "Parcel"}
                          className={styles.addPropertyModalCreateMapContainer}
                          id="search-map-container"
                        >
                          <SearchMap
                            latitude={this.state.latitudeForMapFind}
                            longitude={this.state.longitudeForMapFind}
                            address={this.state.addressStringForMapFind}
                            shouldIRecalculate={this.state.doesMapNeedRerender}
                          />
                        </Row>
                      </div>

                      <div className="mx-2" hidden={!this.state.hasMapRendered && data.propertyType === "Non-Parcel"}>
                        <Row xs={1} md={2}>
                          <Col>
                            {this.renderInput("propertyName", "Property Name", "text", "Enter name", "required")}
                          </Col>
                          <Col className="text-left">
                            <Form.Label className={styles.fieldLabels}>Primary Cooperator</Form.Label>
                            <Fragment>
                              <AsyncSelect
                                aria-labelledby="formLabel"
                                openMenuOnClick={false}
                                placeholder="Enter name"
                                loadOptions={this.handleCooperatorSearch}
                                onChange={this.handleCooperatorChange}
                              />
                            </Fragment>
                          </Col>
                        </Row>
                        <Row xs={1} md={2} hidden={data.propertyType === "Parcel"}>
                          <Col>
                            {this.renderInput("acres", "Area in Acres", "number", "Enter area in acres", "required")}
                          </Col>
                          <Col>
                            {this.renderInput(
                              "calculatedAcres",
                              "Area in Acres (Calculated)",
                              "text",
                              data.calculatedAcres,
                              "readOnly"
                            )}
                          </Col>
                        </Row>
                        <Row hidden={data.propertyType === "Parcel"}>
                          <Col lg={6}>
                            {this.renderSelect(
                              "landClass",
                              "Land Class",
                              this.state.landClasses,
                              "Select Type",
                              data.propertyType === "Non-Parcel" ? "required" : ""
                            )}
                          </Col>
                        </Row>
                        <Row xs={1} md={2}>
                          <Col></Col>
                        </Row>
                        <Row className="text-right">
                          <Col>
                            <Button
                              className={styles.buttons}
                              variant="outline-primary"
                              onClick={() => this.handleReset(this.state.data.propertyType)}
                            >
                              Cancel
                            </Button>
                            <Button
                              className={styles.buttons}
                              variant="primary"
                              onClick={async () => {
                                await this.doSubmit();
                              }}
                            >
                              Create
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                    <Tab className="justify-content: center" eventKey="search" title="Edit/Search Existing Properties">
                      <br></br>
                      <div>{this.renderSearchEditSection()}</div>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-4 mt-2">
            <Card.Header as="h4" className={styles.cardTitles}>
              Associated Properties
              <span style={{ color: "#B60202" }}>*</span>
            </Card.Header>
            <Card.Body className={styles.centerCardBody}>
              {this.state.selectedProperties?.map((item, index) => (
                <Row className={styles.informationRow} noGutters="true">
                  <Col align="left" lg={8}>
                    <p className={globalStyles.informationText}>{item?.propertyObject?.propertyName}</p>
                  </Col>
                  <Col align="right" lg={2}>
                    <Button
                      className={styles.removeButtonLinks}
                      variant="link"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.onViewProperty(e, index)}
                    >
                      View
                    </Button>
                  </Col>
                  <Col align="right" lg={2}>
                    <Button
                      className={styles.removeButtonLinks}
                      variant="link"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.onRemove(e, index)}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              ))}
            </Card.Body>
          </Card>
        </div>
        <ConflictingAgreementParcelModal
          show={this.state.showConflictingAgreementParcelModal}
          onHide={this.closeConflictingAgreementParcelModal}
          usedOnAgreementSignature={false}
          conflictingAgreementUniqueIdentifiers={this.state.conflictingAgreementUniqueIdentifiers}
        />
      </div>
    );
  }
}

export default Step2;
