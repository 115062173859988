import React from "react";
import styles from "./Conflicts.module.scss";
import globalStyles from "../../OARS.module.scss";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import CustomForm from "../common/form";
import Select, { components } from "react-select";
import searchIcon from "../../assets/search.svg";
import { toast } from "react-toastify";

class AddResourcesModal extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        protectedResources: [],
      },
      isInitialConfiguration: true,
      isResourceMenuOpen: false,
    };
  }

  handleResourceSelection = (resourceData) => {
    let { data, isInitialConfiguration } = this.state;

    if (isInitialConfiguration) {
      isInitialConfiguration = false;
      data.protectedResources = this.getAvailableResources();
    }

    let selectedResource = data.protectedResources.find(
      (resource) =>
        resource.resourceUniqueReference === resourceData.resourceUniqueReference &&
        resource.propertyUniqueIdentifier === resourceData.propertyUniqueIdentifier
    );
    if (selectedResource) {
      selectedResource.isSelected = !selectedResource.isSelected;
      this.setState({ data, isInitialConfiguration });
    }
  };

  handleSpecificChanges(input) {
    //do nothing
  }

  getAvailableResources() {
    let availableResources = [];
    this.props.properties.forEach((property) => {
      availableResources = availableResources.concat(
        property.agreementResources
          .filter((r) => {
            if (property.protectedResources?.length > 0) {
              return !property.protectedResources.some(
                (protectedResource) => protectedResource.resourceUniqueReference === r.resourceUniqueReference
              );
            }

            return true;
          })
          .map((resource) => ({
            resourceUniqueReference: resource.resourceUniqueReference,
            resourceName: resource.resourceName,
            isSelected: false,
            propertyName: property.propertyName,
            propertyUniqueIdentifier: property.propertyUniqueIdentifier,
            threatTypeEnumId: "",
          }))
      );
    });

    return availableResources;
  }

  SearchIcon = () => {
    return <img src={searchIcon} />;
  };

  handleDamageSelection = (resourceId, e) => {
    const { data } = this.state;
    const resourceToUpdate = data.protectedResources.find(
      (resource) => resource.resourceUniqueReference === resourceId
    );
    if (resourceToUpdate) {
      resourceToUpdate.threatTypeEnumId = e.value;
      resourceToUpdate.threatType = { threatTypeEnumId: e.value, displayText: e.label };
      resourceToUpdate.damageName = e.label;
      this.setState({ data });
    }
  };

  doSubmit() {
    const { data } = this.state;
    if (!this.isResourceDataValid()) {
      toast.warning("Invalid data provided. Please make sure to select a threat type.");
      return;
    }

    const updatedProperties = JSON.parse(JSON.stringify(this.props.properties));
    this.updateProtectedResourcesInProperties(updatedProperties);
    this.clearData(data);

    this.props.onSaveNewResources(updatedProperties);
    this.props.onHide();
    this.setState({ data, isInitialConfiguration: true });
  }

  isResourceDataValid() {
    let isValidData = true;
    this.state.data.protectedResources
      .filter((r) => r.isSelected)
      .forEach((resource) => {
        if (!resource.threatTypeEnumId) isValidData = false;
      });
    return isValidData;
  }

  updateProtectedResourcesInProperties(updatedProperties) {
    let isExistingResource = false;
    this.state.data.protectedResources
      .filter((r) => r.isSelected)
      .forEach((resource) => {
        const currentProperty = updatedProperties.find(
          (property) => property.propertyUniqueIdentifier === resource.propertyUniqueIdentifier
        );
        if (currentProperty) {
          if (this.resourceAlredyExist(currentProperty, resource)) {
            isExistingResource = true;
          } else {
            currentProperty.protectedResources = currentProperty.protectedResources
              ? [...currentProperty.protectedResources, resource]
              : [resource];
          }
        }
      });

    if (isExistingResource)
      toast.warning(
        "Some of the selected resource(s) were not included in the conflict, as they were previously added."
      );
  }

  resourceAlredyExist(currentProperty, resource) {
    return currentProperty.protectedResources?.some(
      (r) => r.resourceUniqueReference === resource.resourceUniqueReference
    );
  }

  clearData(data) {
    data.protectedResources = [];
  }

  handleModalCancel = () => {
    const { data } = this.state;
    this.clearData(data);
    this.props.onHide();
    this.setState({ data, isInitialConfiguration: true });
  };

  renderSelectedResourcesListing() {
    const selectedResources = this.state.data.protectedResources.filter((resource) => resource.isSelected);
    const { damages } = this.props;

    return (
      <React.Fragment>
        {selectedResources?.length > 0 &&
          selectedResources.map((resource, index) => (
            <div key={index}>
              <Row className="my-4">
                <Col sm={4} className={styles.softFormLabel}>
                  {resource.resourceName}
                </Col>
                <Col sm={4}>
                  <Select
                    value={damages ? damages.find((damage) => damage.value === resource.threatTypeEnumId) : ""}
                    placeholder="Damage Type"
                    styles={{
                      indicatorSeparator: () => {
                        //do nothing
                      },
                      placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
                      option: (styles) => ({ ...styles, fontSize: "14px", fontWeight: "normal", color: "black" }),
                      singleValue: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                        fontWeight: "normal",
                        color: "black",
                      }),
                    }}
                    options={damages}
                    onChange={(e) => this.handleDamageSelection(resource.resourceUniqueReference, e)}
                  />
                </Col>
              </Row>
              {index < selectedResources.length - 1 && <div className={styles.selectedResourceRowSeparator}></div>}
            </div>
          ))}
      </React.Fragment>
    );
  }

  renderResourceSelectionDropdown() {
    let protectedResources = [];
    if (!this.state.isInitialConfiguration) {
      protectedResources = JSON.parse(JSON.stringify(this.state.data.protectedResources));
    } else {
      protectedResources = this.getAvailableResources();
    }

    return (
      <Select
        aria-label="Select Resource"
        placeholder={this.state.isResourceMenuOpen ? "Search here" : "Select Resource"}
        styles={{
          indicatorSeparator: () => {
            //do nothing
          },
          placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
          menuList: (styles) => ({ ...styles, paddingLeft: "1rem", overflowX: "hidden" }),
        }}
        menuIsOpen={this.state.isResourceMenuOpen}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        onMenuClose={() => {
          this.setState({ isResourceMenuOpen: false });
        }}
        onFocus={() => {
          this.setState({ isResourceMenuOpen: true });
        }}
        onBlur={() => {
          this.setState({ isResourceMenuOpen: false });
        }}
        filterOption={(option, inputValue) => {
          return option?.data?.resourceName.toLowerCase().includes(inputValue.toLowerCase());
        }}
        components={{
          Option: (props) => {
            const { data, innerProps, innerRef } = props;
            return (
              <Row className="my-1" ref={innerRef} {...innerProps}>
                <Col sm={5}>
                  <Form.Check
                    type="checkbox"
                    inline
                    defaultChecked={data.isSelected}
                    onChange={() => this.handleResourceSelection(data)}
                    title={`Select ${data.resourceName} on Property ${data.propertyName}`}
                  />
                  <span className={data.isSelected ? styles.blueFormData : styles.formData}>{data.resourceName}</span>
                </Col>
                <Col className="text-left">
                  <span className={styles.formData}>{data.propertyName}</span>
                </Col>
              </Row>
            );
          },
          DropdownIndicator: (props) => {
            return this.state.isResourceMenuOpen ? (
              <components.DropdownIndicator {...props}>
                <this.SearchIcon />
              </components.DropdownIndicator>
            ) : (
              <components.DropdownIndicator {...props}></components.DropdownIndicator>
            );
          },
        }}
        options={protectedResources}
      />
    );
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        backdrop="static"
        centered
        scrollable
        dialogClassName={styles.modalDialog}
      >
        <Form noValidate onSubmit={this.handleSubmit}>
          <Modal.Header className={styles.modalHeader} closeButton>
            <Modal.Title className={globalStyles.modalTitleText}>Add Resources</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBoby}>
            <div className="container">
              <Row>
                <Col>
                  <label className={styles.formLabel}>Available Resources</label>
                </Col>
                <Col>
                  <Button
                    variant="link"
                    className={styles.createResourceLinkButton}
                    onClick={() => this.props.showCreateModal()}
                  >
                    Create New Resource
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>{this.renderResourceSelectionDropdown()}</Col>
              </Row>
              <Row>
                <Col>{this.renderSelectedResourcesListing()}</Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className>
            <Button variant="outline-primary" onClick={this.handleModalCancel}>
              <span className={globalStyles.modalCancelButtonText}>Cancel</span>
            </Button>
            <div className="ml-2">{this.renderButton("Save", "submit")}</div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AddResourcesModal;
