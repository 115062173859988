import React from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, Form, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import CustomForm from "../common/form";
import { toast } from "react-toastify";
import globalStyles from "../../OARS.module.scss";
import styles from "./TAWorkTask.module.scss";
import WorkTaskAPI from "../../api/WorkTask/WorkTaskAPI";
import AgreementAPI from "../../api/AgreementEntities/AgreementAPI";
import AgreementEntitiesAPI from "../../api/AgreementEntities/AgreementEntitiesAPI";
import UtilityFunctions from "../common/UtilityFunctions";
import searchIcon from "../../assets/search.svg";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import ConflictsAPI from "../../api/Conflicts/ConflictsAPI";
import SuccessfulRecordModifiedModal from "../common/create-updateOkMessageModal";
import RoleAuthorizationAPI from "../../api/RoleAuthorization/RoleAuthorizationAPI";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const TAWorkTaskTypes = {
  Hotline: "hotline",
  PublicOutreach: "publicOutreach",
  IndividualCooperator: "individualCooperator",
};

const SearchIcon = () => {
  return <img src={searchIcon} alt="search icon" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  let option = props.data;
  return <components.Option {...props}>{option.label + " - " + option.profileName}</components.Option>;
};

class TAWorkTaskDetailsPage extends CustomForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        cooperator: "",
        workDate: "",
        agreementName: "",
        selectedAgreement: "",
        propertyName: "",
        property: "",
        milesTraveled: "",
        city: "",
        stateName: "",
        selectedState: "",
        countyName: "",
        selectedCounty: "",
        hoursWorked: "",
        minutesWorked: "",
        activities: [],
        conflict: "",
        comments: "",
        recommendations: [],
        employedWorkItems: [],
        projects: [],
        referredTo: "",
        referredFrom: "",
        permitRecommendationType: "",
        permitAction: "",
        permitNumberFreeText: "",
        equipmentSelectedMethod: "",
        equipmentSelectedUom: "",
        equipmentMethodQuantity: 0,
        selectedGeneralTopics: [],
        selectedSpeciesTopics: [],
        selectedResourceTopics: [],
        resourceNameSearch: "",
        resourceMainCategorySearch: "",
        resourceSubCategory1Search: "",
        speciesNameSearch: "",
        speciesClassesSearch: "",
        speciesOrdersSearch: "",
        speciesFamiliesSearch: "",
        speciesCategoriesSearch: "",
        numberOfLeaflets: "",
        numberOfParticipants: "",
        workTaskUniqueIdentifier: "",
        performedByWSUserProfileUniqueReference: "",
        performedByUserName: "",
        performedBy: "",
      },
      workTaskData: "",
      isFormLoading: true,
      entityType: props.history.location.state?.entityType,
      states: props.history.location.state?.states.map((state) => {
        state.value = state.stateUniqueIdentifier || state.id;
        state.label = state.state_name || state.name;
        return state;
      }),
      countries: props.history.location.state?.countries,
      availableTaActivities: [],
      availableConflicts: [],
      availableRecCems: [],
      availableProjects: [],
      availableOrganizations: [],
      availableCounties: [],
      availablePermitRecommendationTypes: [],
      availablePermitActions: [],
      isEditMode: false,
      isAddingEquipment: false,
      cooperatorEquipmentTableColumns: [
        { dataField: "methodUniqueReference", hidden: true },
        { dataField: "test", text: "Loading" },
      ],
      generalTopicsTableColumns: [
        { dataField: "topicUniqueReference", hidden: true },
        {
          dataField: "topicName",
          text: "Name",
          align: "left",
          style: {
            borderBottom: "1px solid #E1E1E1",
            fontSize: "14px",
            verticalAlign: "middle",
            fontWeight: 400,
            height: "45px",
          },
          headerAlign: "left",
          headerStyle: {
            borderBottom: "1px solid #E1E1E1",
            fontSize: "14px",
            verticalAlign: "middle",
            fontWeight: 600,
            height: "45px",
          },
        },
      ],
      resourceTopicsTableColumns: [
        { dataField: "resourceUniqueReference", hidden: true },
        {
          dataField: "resourceName",
          text: "Name",
          align: "left",
          style: {
            borderBottom: "1px solid #E1E1E1",
            fontSize: "14px",
            verticalAlign: "middle",
            fontWeight: 400,
            height: "45px",
          },
          headerAlign: "left",
          headerStyle: {
            borderBottom: "1px solid #E1E1E1",
            fontSize: "14px",
            verticalAlign: "middle",
            fontWeight: 600,
            height: "45px",
          },
        },
      ],
      speciesTopicsTableColumns: [
        { dataField: "speciesUniqueReference", hidden: true },
        {
          dataField: "speciesName",
          text: "Name",
          align: "left",
          style: {
            borderBottom: "1px solid #E1E1E1",
            fontSize: "14px",
            verticalAlign: "middle",
            fontWeight: 400,
            height: "45px",
          },
          headerAlign: "left",
          headerStyle: {
            borderBottom: "1px solid #E1E1E1",
            fontSize: "14px",
            verticalAlign: "middle",
            fontWeight: 600,
            height: "45px",
          },
        },
      ],
      cooperatorEquipmentRowSelected: [],
      generalTopicsRowSelected: [],
      resourceTopicsRowSelected: [],
      speciesTopicsRowSelected: [],
      availableMethods: [],
      equipmentMethodUoms: [],
      allGeneralTopics: [],
      allAvailableResources: [],
      allAvailableSpecies: [],
      showSuccessfulUpdateModal: false,
      resourceMainCategories: [],
      resourceSubCategories1: [],
      speciesClasses: [],
      speciesOrders: [],
      speciesFamilies: [],
      speciesCategories: [],
      errors: {},
    };
  }

  async componentDidMount() {
    const currentUsaState = JSON.parse(localStorage.getItem("currentState"));
    if (!currentUsaState) {
      toast.warning("Failed to load State info. Please go back to the home page and navigate to this page again.");
    }

    const workTaskData = await this.getWorkTaskData();
    const data = workTaskData ? await this.updatePageData(workTaskData, currentUsaState) : { ...this.state.data };
    const generalTopicsRowSelected =
      data.selectedGeneralTopics?.length > 0 ? data.selectedGeneralTopics.map((t) => t.topicUniqueReference) : [];
    const resourceTopicsRowSelected =
      data.selectedResourceTopics?.length > 0 ? data.selectedResourceTopics.map((r) => r.resourceUniqueReference) : [];
    const speciesTopicsRowSelected =
      data.selectedSpeciesTopics?.length > 0 ? data.selectedSpeciesTopics.map((s) => s.speciesUniqueReference) : [];

    this.setState(
      {
        data,
        availableTaActivities: await this.getTaActivitiesFromAPI(),
        availableMethods:
          this.state.entityType === TAWorkTaskTypes.IndividualCooperator
            ? await this.getMethodsForState(currentUsaState?.stateGuid)
            : [],
        availableConflicts: await this.getConflictsFromAPI(currentUsaState?.stateGuid),
        availableRecCems: await this.getRecCemsForState(currentUsaState?.stateGuid),
        availableProjects: await this.getProjectsFromAPI(currentUsaState?.stateGuid),
        availableOrganizations: await this.getExternalOrganizationsForState(currentUsaState?.stateGuid),
        cooperatorEquipmentTableColumns: this.getCooperatorInventoryTableColumns(),
        allGeneralTopics: await this.getAllAvailableTopics(currentUsaState?.stateGuid),
        allAvailableResources: await this.getAllAvailableResources(currentUsaState?.stateGuid),
        allAvailableSpecies: await this.getAllAvailableSpecies(currentUsaState?.stateGuid),
        availablePermitRecommendationTypes:
          this.state.entityType !== TAWorkTaskTypes.PublicOutreach ? await this.getPermitRecommendationTypes() : [],
        availablePermitActions:
          this.state.entityType !== TAWorkTaskTypes.PublicOutreach ? await this.getPermitActions() : [],
        resourceMainCategories: await this.getResourceTypesFromAPI(),
        generalTopicsRowSelected,
        resourceTopicsRowSelected,
        speciesTopicsRowSelected,
        workTaskData,
        isFormLoading: false,
        currentUsaState,
      },
      () => {
        this.getAndSetAllSpeciesCategorizationInfo();
      }
    );
  }

  customSelectStyles = {
    indicatorSeparator: () => {
      //do nothing
    },
    placeholder: (styles) => ({ ...styles, fontStyle: "italic", fontSize: "14px" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "#313131",
      backgroundColor: isFocused ? "lightgray" : "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      fontWeight: "normal",
      color: "313131",
    }),
    control: (base) => ({
      ...base,
    }),
  };

  async getWorkTaskData() {
    const workTaskData = await this.getWorkTaskDataFromAPI();
    if (workTaskData) {
      if (workTaskData.cooperatorContactUniqueReference) {
        workTaskData.cooperatorData = await this.getContactData(workTaskData.cooperatorContactUniqueReference);
      }
      if (workTaskData.agreementUniqueReference) {
        workTaskData.agreementData = await this.getAgreementData(workTaskData.agreementUniqueReference);
      }
      if (workTaskData.propertyUniqueReference) {
        workTaskData.propertyData = await this.getPropertyData(workTaskData.propertyUniqueReference);
      }
      if (workTaskData.conflictUniqueReference) {
        workTaskData.conflictData = await this.getConflictData(workTaskData.conflictUniqueReference);
      }
    }
    return workTaskData;
  }

  async updatePageData(workTaskData, currentUsaState) {
    const data = { ...this.state.data };
    let availableCounties = [];
    if (currentUsaState) {
      availableCounties = await this.getAvailableCounties(currentUsaState?.stateGuid);
    }
    data.cooperator = {
      value: workTaskData.cooperatorData?.contactUniqueId,
      label: workTaskData.cooperatorData?.fullName,
      ...workTaskData.cooperatorData,
    };

    const { entityType } = this.state;
    let workDate = "";
    if (entityType === TAWorkTaskTypes.IndividualCooperator || entityType === TAWorkTaskTypes.PublicOutreach) {
      workDate = new Date(workTaskData.datePerformed);
    } else if (entityType === TAWorkTaskTypes.Hotline) {
      workDate = new Date(workTaskData.workDate);
    }

    data.workDate = workDate ? workDate?.toLocaleDateString("en-CA", { timeZone: "UTC" }) : "";
    data.agreementName = workTaskData.agreementData?.commonName;
    data.pvsUniqueReference = workTaskData.pvsUniqueReference;
    data.selectedAgreement = {
      value: workTaskData.agreementData?.agreementUniqueIdentifier,
      label: workTaskData.agreementData?.commonName,
    };
    data.propertyName = workTaskData.propertyData?.propertyName;
    data.property = {
      value: workTaskData.propertyData?.propertyUniqueIdentifier,
      label: workTaskData.propertyData?.propertyName,
    };
    data.milesTraveled = workTaskData.milesTraveled;
    data.city = workTaskData.city;
    if (workTaskData.stateUniqueReference) {
      let state = this.state.states?.find(
        (s) =>
          s.stateUniqueIdentifier === workTaskData.stateUniqueReference || s.id === workTaskData.stateUniqueReference
      );
      data.selectedState = {
        value: state.stateUniqueIdentifier || state.id,
        label: state.state_name || state.name,
      };
      data.stateName = state.state_name || state.name;
    }
    data.countyName = workTaskData.county;
    data.selectedCounty =
      this.state.availableCounties.length > 0
        ? this.state.availableCounties?.find((c) => c.label === workTaskData.county)
        : availableCounties?.find((c) => c.label === workTaskData.county);
    data.hoursWorked = workTaskData.hoursWorked;
    data.minutesWorked = workTaskData.minutesWorked;
    data.activities =
      workTaskData.workTaskActivities?.length > 0
        ? workTaskData.workTaskActivities.map((a) => ({
            value: a.workTaskActivityUniqueReference,
            label: a.activityName,
          }))
        : [];
    data.comments = workTaskData.comments;
    data.conflict = {
      value: workTaskData.conflictData?.conflictUniqueIdentifier,
      label: workTaskData.conflictData?.targetedSpeciesName,
    };
    data.recommendations =
      workTaskData.recommendations?.length > 0
        ? workTaskData.recommendations.map((r) => ({
            value: r.recCemUniqueReference,
            label: r.recommendationName,
          }))
        : [];
    data.employedWorkItems =
      workTaskData.cooperatorEmployedWork?.length > 0
        ? workTaskData.cooperatorEmployedWork.map((r) => ({
            value: r.recCemUniqueReference,
            label: r.workActivityName,
          }))
        : [];
    data.projects =
      workTaskData.projectsUniqueReferences?.length > 0
        ? workTaskData.projectsUniqueReferences.map((p) => ({
            value: p.projectUniqueReference,
            label: p.projectsName,
          }))
        : workTaskData.projects?.length > 0
        ? workTaskData.projects.map((p) => ({
            value: p.projectUniqueReference,
            label: p.projectsName,
          }))
        : [];
    data.referredTo = workTaskData.referredToExternalOrganizationUniqueReference;
    data.referredFrom = workTaskData.referredFromExternalOrganizationUniqueReference;
    data.numberOfLeaflets = workTaskData.numberOfLeafletsDistributed;
    data.numberOfParticipants = workTaskData.numberOfParticipants;
    data.permitRecommendationType = workTaskData.permitRecommendationType?.id;
    data.permitAction = workTaskData.permitAction?.id;
    data.permitNumberFreeText = workTaskData.permitNumberFreeText;
    if (workTaskData.generalTopics?.length > 0) {
      data.selectedGeneralTopics = workTaskData.generalTopics;
    }
    if (workTaskData.speciesAddressed?.length > 0) {
      data.selectedSpeciesTopics = workTaskData.speciesAddressed;
    }
    if (workTaskData.resourcesAddressed?.length > 0) {
      data.selectedResourceTopics = workTaskData.resourcesAddressed;
    }
    data.workTaskUniqueIdentifier =
      this.state.entityType === TAWorkTaskTypes.IndividualCooperator
        ? workTaskData.cooperatorTechnicalAssistWorkTaskUniqueIdentifier
        : this.state.entityType === TAWorkTaskTypes.Hotline
        ? workTaskData.hotlineTechnicalAssistWorkTaskUniqueIdentifier
        : workTaskData.outreachTechnicalAssistWorkTaskUniqueIdentifier;
    data.performedByWSUserProfileUniqueReference = workTaskData.performedByWSUserProfileUniqueReference;
    data.performedByUserName = workTaskData.performedByUserName;
    data.performedBy = {
      value: workTaskData.performedByWSUserProfileUniqueReference,
      label: workTaskData.performedByUserName,
    };
    this.setState({ availableCounties: availableCounties });
    return data;
  }

  async getWorkTaskDataFromAPI() {
    let workTaskData = "";
    const entityData = this.props.history.location.state?.entityData;
    if (entityData) {
      let taWorkTaskResponse;
      switch (this.state.entityType) {
        case TAWorkTaskTypes.IndividualCooperator:
          if (entityData.cooperatorTechnicalAssistWorkTaskUniqueIdentifier) {
            taWorkTaskResponse = await WorkTaskAPI.GetCoopTAWorkTask(
              entityData.cooperatorTechnicalAssistWorkTaskUniqueIdentifier
            );
          }
          break;
        case TAWorkTaskTypes.Hotline:
          if (entityData.hotlineTechnicalAssistWorkTaskUniqueIdentifier) {
            taWorkTaskResponse = await WorkTaskAPI.GetHotlineTAWorkTask(
              entityData.hotlineTechnicalAssistWorkTaskUniqueIdentifier
            );
          }
          break;
        case TAWorkTaskTypes.PublicOutreach:
          if (entityData.outreachTechnicalAssistWorkTaskUniqueIdentifier) {
            taWorkTaskResponse = await WorkTaskAPI.GetOutreachTAWorkTask(
              entityData.outreachTechnicalAssistWorkTaskUniqueIdentifier
            );
          }
          break;
        default:
          break;
      }
      if (taWorkTaskResponse?.successful) {
        workTaskData = taWorkTaskResponse.data;
      }
    }
    return workTaskData;
  }

  async getMethodsForState(stateGuid) {
    const allMethodsResponse = await ReferenceFileAPI.GetAllMethodsByState(stateGuid);
    let methodList = [];
    if (allMethodsResponse?.successful) {
      const methodData = allMethodsResponse.data?.results;
      for (let i = 0; i < methodData.length; i++) {
        if (methodData[i].isAllowedInState) {
          methodList.push({
            value: methodData[i].methodUniqueIdentifier,
            label: methodData[i].methodName,
            allowedUOMsInState: methodData[i].allowedUOMsInState,
          });
        }
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve methods ",
        apiName: "GetAllMethodsByState",
        responseUnsuccessful: allMethodsResponse?.unsuccessful,
        responseMessage: allMethodsResponse?.message,
      });
    }
    return methodList;
  }

  async getTaActivitiesFromAPI() {
    let activitiesData = "";
    let taType;
    switch (this.state.entityType) {
      case TAWorkTaskTypes.IndividualCooperator:
        taType = 1;
        break;
      case TAWorkTaskTypes.Hotline:
        taType = 3;
        break;
      case TAWorkTaskTypes.PublicOutreach:
        taType = 2;
        break;
      default:
        break;
    }

    if (taType) {
      const taActivitiesResponse = await ReferenceFileAPI.GetAllTechnicalAssistActivitiesForTaType(taType);
      if (taActivitiesResponse?.successful) {
        activitiesData = taActivitiesResponse.data.map((a) => ({
          value: a.technicalAssistActivityUniqueIdentifier,
          label: a.activityName,
        }));
      }
    }

    return activitiesData;
  }

  async getConflictsFromAPI(stateId) {
    let conflictsData = [];
    if (stateId) {
      const conflictsResponse = await ConflictsAPI.GetConflictsForState(stateId);
      if (conflictsResponse?.successful && conflictsResponse.data?.results?.length > 0) {
        conflictsData = conflictsResponse.data.results.map((c) => ({
          value: c.conflictUniqueIdentifier,
          label: c.targetedSpeciesName,
        }));
      }
    }

    return conflictsData;
  }

  async getRecCemsForState(stateGuid) {
    const recCemsResponse = await ReferenceFileAPI.GetAllRecCems(stateGuid);
    let recCemsData = [];
    if (recCemsResponse?.successful && recCemsResponse?.data.results?.length > 0) {
      recCemsData = recCemsResponse.data.results.map((r) => ({
        value: r.recCemUniqueIdentifier,
        label: r.recCemName,
      }));
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve Recommendations",
        apiName: "GetAllRecCems",
        responseUnsuccessful: recCemsResponse?.unsuccessful,
        responseMessage: recCemsResponse?.message,
      });
    }
    return recCemsData;
  }

  async getProjectsFromAPI(stateGuid) {
    let projectsData = [];
    const projectsDataResponse = await AgreementEntitiesAPI.GetMyAssociatedProjectsForState(stateGuid);
    if (projectsDataResponse?.successful) {
      projectsData = projectsDataResponse.data;
    } else if (projectsDataResponse?.unsuccessful) {
      ErrorHandler.showErrorWithMessage("Error getting projects from API. " + projectsDataResponse.message);
    } else {
      ErrorHandler.showErrorWithMessage("Error getting projects from API.");
    }
    let projectsArray = [];
    projectsData.forEach((project) => {
      let startDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.startDate);
      let endDate = UtilityFunctions.getCorrectTimeZoneDateFromISO8601DateString(project.endDate);
      let currentDate = new Date();
      if (currentDate >= startDate && currentDate < endDate) {
        projectsArray.push({ value: project.projectUniqueIdentifier, label: project.projectName });
      }
    });
    return projectsArray;
  }

  async getExternalOrganizationsForState(stateGuid) {
    let organizationsData = [];
    if (stateGuid) {
      const organizationsResponse = await ReferenceFileAPI.GetAllExternalOrganizationsInState(stateGuid);
      if (organizationsResponse?.successful && organizationsResponse.data?.results?.length > 0) {
        organizationsData = organizationsResponse.data.results.map((o) => ({
          id: o.externalOrganizationUniqueIdentifier,
          name: o.externalOrganizationName,
        }));
      }
    }

    return organizationsData;
  }

  async getPermitsFromAPI(stateGuid) {
    let permitsData = [];
    if (stateGuid) {
      const searchPermitsBody = {
        stateUniqueReference: stateGuid,
        speciesUniqueReference: null,
        permitNameNumberSearchFreetext: "",
      };
      const permitsResponse = await WorkTaskAPI.SearchForValidPermits(searchPermitsBody);
      if (permitsResponse?.successful && permitsResponse.data?.length > 0) {
        permitsData = permitsResponse.data.map((p) => ({
          id: p.permitUniqueIdentifier,
          name: p.permitName,
          ...p,
        }));
      }
    }

    return permitsData;
  }

  async getAvailableCounties(stateId) {
    let countiesData = [];
    if (stateId) {
      const countiesDataResults = await ReferenceFileAPI.GetCountiesByState(stateId);
      if (countiesDataResults?.length > 0) {
        countiesData = countiesDataResults.map((c) => ({ value: c.countyUniqueIdentifier, label: c.county_name }));
      }
    }
    return countiesData;
  }

  async getPermitRecommendationTypes() {
    let permitRecommendationTypesData = [];
    const permitRecommendationTypesDataResponse =
      this.state.entityType === TAWorkTaskTypes.Hotline
        ? await WorkTaskAPI.GetHotlinePermitRecommendationTypes()
        : await WorkTaskAPI.GetIndividualCooperatorPermitRecommendationTypes();
    if (permitRecommendationTypesDataResponse?.successful) {
      permitRecommendationTypesData = permitRecommendationTypesDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve permit recommendation types ",
        apiName: "GetHotlinePermitRecommendationTypes",
        responseUnsuccessful: permitRecommendationTypesDataResponse?.unsuccessful,
        responseMessage: permitRecommendationTypesDataResponse?.message,
      });
    }
    return permitRecommendationTypesData.map((permitType) => {
      return { id: permitType.id, name: permitType.displayText };
    });
  }

  async getPermitActions() {
    let permitActionsData = [];

    const permitActionsDataResponse =
      this.state.entityType === TAWorkTaskTypes.Hotline
        ? await WorkTaskAPI.GetHotlinePermitActions()
        : await WorkTaskAPI.GetIndividualCooperatorPermitActions();
    if (permitActionsDataResponse?.successful) {
      permitActionsData = permitActionsDataResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve permit actions ",
        apiName: "GetHotlinePermitActions",
        responseUnsuccessful: permitActionsDataResponse?.unsuccessful,
        responseMessage: permitActionsDataResponse?.message,
      });
    }
    return permitActionsData.map((permitType) => {
      return { id: permitType.id, name: permitType.displayText };
    });
  }

  getCooperatorInventoryTableColumns() {
    const rowsStyle = {
      borderBottom: "1px solid #E8E8E8",
      fontSize: "14px",
      verticalAlign: "middle",
    };
    return [
      {
        dataField: "methodUniqueReference",
        hidden: true,
      },
      {
        dataField: "inventoryItemName",
        text: "Method Name",
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 600 },
      },
      {
        dataField: "unitOfMeasure",
        text: "UOM",
        formatter: (cell, row) => {
          return UtilityFunctions.getDisplayTextFromFieldObject(cell);
        },
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 600 },
      },
      {
        dataField: "quantity",
        text: "Qty",
        align: "left",
        style: { ...rowsStyle, fontWeight: 400, height: "45px" },
        headerAlign: "left",
        headerStyle: { ...rowsStyle, fontWeight: 600 },
      },
    ];
  }

  async getContactData(contactUniqueId) {
    const contactDataResponse = await AgreementEntitiesAPI.GetContact(contactUniqueId);
    if (!contactDataResponse?.successful) {
      return null;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get contact data ",
        apiName: "GetContact",
        responseUnsuccessful: contactDataResponse?.unsuccessful,
        responseMessage: contactDataResponse?.message,
      });
    }

    let contactData = contactDataResponse.data;
    contactData.primaryPhoneNumber = UtilityFunctions.getPrimaryPhoneNumber(contactData.phoneNumbers);
    contactData.fullName = UtilityFunctions.getContactNameFromDataObject(contactData);
    return contactData;
  }

  async getAgreementData(agreementId) {
    let agreementData = "";
    const getAgreementResponse = await AgreementAPI.GetAgreement(agreementId);
    if (getAgreementResponse?.successful) {
      agreementData = getAgreementResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get agreement ",
        apiName: "GetAgreement",
        responseUnsuccessful: getAgreementResponse?.unsuccessful,
        responseMessage: getAgreementResponse?.message,
      });
    }
    return agreementData;
  }

  async getPropertyData(propertyId) {
    let propertyData = "";
    const getPropertyResponse = await AgreementEntitiesAPI.GetProperty(propertyId);
    if (getPropertyResponse?.successful) {
      propertyData = getPropertyResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get property ",
        apiName: "GetProperty",
        responseUnsuccessful: getPropertyResponse?.unsuccessful,
        responseMessage: getPropertyResponse?.message,
      });
    }
    return propertyData;
  }

  async getConflictData(conflictId) {
    let conflictData = "";
    const getConflictResponse = await ConflictsAPI.GetConflict(conflictId);
    if (getConflictResponse?.successful) {
      conflictData = getConflictResponse.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get conflict ",
        apiName: "GetConflict",
        responseUnsuccessful: getConflictResponse?.unsuccessful,
        responseMessage: getConflictResponse?.message,
      });
    }
    return conflictData;
  }

  async getAllAvailableTopics(stateId) {
    let topicsData = [];
    if (stateId) {
      const allGeneralTopicsResponse = await ReferenceFileAPI.GetTopicsForState(stateId);
      if (allGeneralTopicsResponse?.successful && allGeneralTopicsResponse.data?.results?.length > 0) {
        topicsData = allGeneralTopicsResponse.data.results
          .filter((s) => s.isAllowedGlobally)
          .map((t) => ({ topicUniqueReference: t.topicUniqueIdentifier, topicName: t.name }));
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get topics for the state ",
          apiName: "GetTopicsForState",
          responseUnsuccessful: allGeneralTopicsResponse?.unsuccessful,
          responseMessage: allGeneralTopicsResponse?.message,
        });
      }
    }

    return topicsData;
  }

  async getAllAvailableResources(stateId) {
    let resourcesData = [];
    if (stateId) {
      const allResourcesResponse = await ReferenceFileAPI.GetResourcesForState(stateId);
      if (allResourcesResponse?.successful && allResourcesResponse.data?.results?.length > 0) {
        resourcesData = allResourcesResponse.data.results
          .filter((s) => s.isAllowed)
          .map((r) => ({ resourceUniqueReference: r.resourceUniqueIdentifier, resourceName: r.name, ...r }));
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get all available resources",
          apiName: "GetResourcesForState",
          responseUnsuccessful: allResourcesResponse?.unsuccessful,
          responseMessage: allResourcesResponse?.message,
        });
      }
    }

    return resourcesData;
  }

  async getAllAvailableSpecies(stateId) {
    let speciesData = [];
    if (stateId) {
      const allSpeciesResponse = await ReferenceFileAPI.GetAllSpeciesByState(stateId);
      if (allSpeciesResponse?.successful && allSpeciesResponse.data?.results?.length > 0) {
        speciesData = allSpeciesResponse.data.results
          .filter((s) => s.isAllowedForState)
          .map((s) => ({ speciesUniqueReference: s.speciesUniqueIdentifier, speciesName: s.name, ...s }));
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get all damage agents by the state",
          apiName: "GetAllSpeciesByState",
          responseUnsuccessful: allSpeciesResponse?.unsuccessful,
          responseMessage: allSpeciesResponse?.message,
        });
      }
    }

    return speciesData;
  }

  async getResourceTypesFromAPI() {
    let resourcesTypeData = [];
    const resourceTypesResponse = await ReferenceFileAPI.GetResourceTypes();
    if (resourceTypesResponse?.successful && resourceTypesResponse?.data?.length > 0) {
      resourcesTypeData = resourceTypesResponse?.data?.map((type) => {
        if (!type) {
          return { value: type, label: "No Type" };
        }
        return { value: type, label: type };
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get resource types",
        apiName: "GetResourceTypes",
        responseUnsuccessful: resourceTypesResponse?.unsuccessful,
        responseMessage: resourceTypesResponse?.message,
      });
    }
    return resourcesTypeData;
  }

  async getAndSetAllSpeciesCategorizationInfo() {
    let { speciesClasses, speciesOrders, speciesFamilies, speciesCategories } = this.state;
    const speciesAllCategorizationResponse = await ReferenceFileAPI.GetAllSpeciesCategorizationInfo(
      this.state.currentUsaState?.stateGuid
    );
    if (speciesAllCategorizationResponse?.successful) {
      speciesClasses = speciesAllCategorizationResponse.data?.allClasses?.map((item) => {
        return { value: item, label: item };
      });
      speciesOrders = speciesAllCategorizationResponse.data?.allOrders?.map((item) => {
        return { value: item, label: item };
      });
      speciesFamilies = speciesAllCategorizationResponse.data?.allFamilies?.map((item) => {
        return { value: item, label: item };
      });
      speciesCategories = speciesAllCategorizationResponse.data?.allSubTypes?.map((item) => {
        return { value: item, label: item };
      });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to retrieve damage agents categorization info",
        apiName: "GetAllSpeciesCategorizationInfo",
        responseUnsuccessful: speciesAllCategorizationResponse?.unsuccessful,
        responseMessage: speciesAllCategorizationResponse?.message,
      });
    }
    this.setState({
      speciesClasses,
      speciesOrders,
      speciesFamilies,
      speciesCategories,
    });
  }

  isCurrentStateValid() {
    return this.state.currentUsaState?.stateGuid || JSON.parse(localStorage.getItem("currentState"))?.stateGuid;
  }

  getCurrentStateId() {
    return this.state.currentUsaState?.stateGuid
      ? this.state.currentUsaState.stateGuid
      : JSON.parse(localStorage.getItem("currentState"))?.stateGuid;
  }

  editWorkTask = () => {
    if (!this.state.isEditMode) {
      this.setState({ isEditMode: true });
    } else {
      this.state.entityType !== TAWorkTaskTypes.PublicOutreach ? this.handleSubmit() : this.handlePOSubmit();
    }
  };

  cancelEditWorkTask = async () => {
    let pageData = await this.updatePageData(this.state.workTaskData);
    this.setState({ data: pageData, isEditMode: false });
  };

  handlePOSubmit = async () => {
    const { data } = this.state;
    if (
      (data.selectedCounty || data.countyName) &&
      data.workDate &&
      data.hoursWorked &&
      data.activities?.length > 0 &&
      data.numberOfParticipants
    ) {
      let createObject = {
        datePerformed: new Date(data.workDate.split(" ")[0]).toISOString(),
        stateUniqueReference: data.selectedState?.value,
        city: data.city,
        county: data.countyName,
        hoursWorked: parseInt(data.hoursWorked),
        minutesWorked: parseInt(data.minutesWorked),
        numberOfParticipants: parseInt(data.numberOfParticipants),
        workTaskActivities: data.activities?.map((activity) => {
          return { technicalAssistActivityUniqueReference: activity.value };
        }),
        referredFromExternalOrganizationUniqueReference: data.referredFrom ? data.referredFrom : null,
        referredToExternalOrganizationUniqueReference: data.referredTo ? data.referredTo : null,
        recommendations: data.recommendations?.map((rec) => {
          return { recommendationUniqueReference: rec.value };
        }),
        projectUniqueReferences: data.projects?.map((project) => {
          return project.value;
        }),
        projects: data.projects?.map((project) => {
          return {
            projectUniqueReference: project.value,
          };
        }),
        generalTopics: data.selectedGeneralTopics?.map((topic) => {
          return { topicUniqueReference: topic.topicUniqueReference || topic.topicUniqueIdentifier };
        }),
        speciesAddressed: data.selectedSpeciesTopics?.map((s) => {
          return { speciesUniqueReference: s.speciesUniqueReference || s.speciesUniqueIdentifier };
        }),
        resourcesAddressed: data.selectedResourceTopics?.map((r) => {
          return { resourceUniqueReference: r.resourceUniqueReference || r.resourceUniqueIdentifier };
        }),
        performedByWSUserProfileUniqueReference: data.performedBy ? data.performedBy.value : null,
      };
      if (data.numberOfLeaflets) {
        createObject.numberOfLeafletsDistributed = parseInt(data.numberOfLeaflets);
      }
      const updateOutreachTA = await WorkTaskAPI.UpdateOutreachTAWorkTask(data.workTaskUniqueIdentifier, createObject);
      if (updateOutreachTA?.successful) {
        const workTaskData = await this.getWorkTaskData();
        let newData = workTaskData
          ? await this.updatePageData(workTaskData, this.state.currentUsaState)
          : { ...this.state.data };
        this.setState({ data: newData, showSuccessfulUpdateModal: true, isEditMode: false });
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to Update Public Outreach TA",
          apiName: "UpdateOutreachTAWorkTask",
          responseUnsuccessful: updateOutreachTA?.unsuccessful,
          responseMessage: updateOutreachTA?.message,
        });
      }
    } else {
      let date = data.workDate ? "" : "Date, ";
      let time = data.hoursWorked === "" || data.hoursWorked === "0" ? "" : "Hours or Minutes, ";
      let participants = data.numberOfParticipants ? "" : "# of Participants, ";
      let act = data.activities?.length > 0 ? "" : "Activities, ";
      if (data.selectedCounty || data.countyName) {
        toast.error(("Missing required fields: " + date + time + participants + act).slice(0, -2));
      } else {
        toast.error(("Missing required fields: County, " + date + time + participants + act).slice(0, -2));
      }
    }
  };

  handleSubmit = async () => {
    if (!this.validateData()) {
      toast.warn("Please fill out all required fields");
      return;
    }
    const { data } = this.state;
    let obj = {
      datePerformed: new Date(data.workDate.split(" ")[0]).toISOString(),
      cooperatorContactUniqueReference: data.cooperator?.contactUniqueIdentifier || data.cooperator?.value,
      agreementUniqueReference: data.selectedAgreement?.agreementUniqueIdentifier
        ? data.selectedAgreement?.agreementUniqueIdentifier
        : data.selectedAgreement?.value,
      city: data.city,
      pvsUniqueReference: data.pvsUniqueReference ? data.pvsUniqueReference : null,
      county: data.countyName,
      stateUniqueReference: data.selectedState?.value,
      workTaskActivities: data.activities?.map((act) => {
        return this.state.entityType === TAWorkTaskTypes.Hotline
          ? { technicalAssistActivityUniqueReference: act.value }
          : { workTaskActivityUniqueReference: act.value };
      }),
      recommendations: data.recommendations?.map((rec) => {
        return { recommendationUniqueReference: rec.value };
      }),
      cooperatorEmployedWork: data.employedWorkItems?.map((rec) => {
        return { recommendationUniqueReference: rec.value };
      }),
      projectUniqueReferences: data.projects?.map((project) => {
        return project.value;
      }),
      projects: data.projects?.map((project) => {
        return {
          projectUniqueReference: project.value,
        };
      }),
      generalTopics: data.selectedGeneralTopics?.map((topic) => {
        return { topicUniqueReference: topic.topicUniqueReference || topic.topicUniqueIdentifier };
      }),
      speciesAddressed: data.selectedSpeciesTopics?.map((s) => {
        return { speciesUniqueReference: s.speciesUniqueReference || s.speciesUniqueIdentifier };
      }),
      resourcesAddressed: data.selectedResourceTopics?.map((r) => {
        return { resourceUniqueReference: r.resourceUniqueReference || r.resourceUniqueIdentifier };
      }),
      comments: data.comments,
      performedByWSUserProfileUniqueReference: data.performedBy ? data.performedBy.value : null,
    };
    if (this.state.workTaskData?.itemsSuppliedToCooperator?.length > 0) {
      obj.itemsSuppliedToCooperator = data.methodTable?.map((item) => {
        return {
          methodUniqueReference: item.methodUniqueReference,
          unitOfMeasureEnumId: item.unitOfMeasure?.id,
          quantity: parseInt(item.quantity),
        };
      });
    }
    if (data.property) {
      obj.propertyUniqueReference = data.property?.propertyUniqueIdentifier
        ? data.property?.propertyUniqueIdentifier
        : data.property?.value;
    }
    if (data.conflict) {
      obj.conflictUniqueReference = data.conflict?.value;
    }
    if (data.hoursWorked) {
      obj.hoursWorked = parseInt(data.hoursWorked);
    }
    if (data.minutesWorked) {
      obj.minutesWorked = parseInt(data.minutesWorked);
    }
    if (data.milesTraveled) {
      obj.milesTraveled = parseInt(data.milesTraveled);
    }
    if (data.referredFrom) {
      obj.referredFromExternalOrganizationUniqueReference = data.referredFrom;
    }
    if (data.referredTo) {
      obj.referredToExternalOrganizationUniqueReference = data.referredTo;
    }
    obj.permitRecommendationTypeEnumId = data.permitRecommendationType;
    obj.permitActionEnumId = data.permitAction;
    obj.permitNumberFreeText = data.permitNumberFreeText;
    const createTAWorkTask =
      this.state.entityType === TAWorkTaskTypes.Hotline
        ? await WorkTaskAPI.UpdateHotlineTAWorkTask(data.workTaskUniqueIdentifier, obj)
        : await WorkTaskAPI.UpdateCoopTAWorkTask(data.workTaskUniqueIdentifier, obj);
    if (createTAWorkTask?.successful) {
      toast.success("Successfully updated TA Work Task");
      const workTaskData = await this.getWorkTaskData();
      let newData = workTaskData
        ? await this.updatePageData(workTaskData, this.state.currentUsaState)
        : { ...this.state.data };
      this.setState({ data: newData, showSuccessfulUpdateModal: true, isEditMode: false });
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to update TA work task",
        apiName: "UpdateHotlineTAWorkTask",
        responseUnsuccessful: createTAWorkTask?.unsuccessful,
        responseMessage: createTAWorkTask?.message,
      });
    }
  };

  validateData() {
    const { data } = this.state;
    if (data.workDate && (data.county || data.countyName) && data.activities?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getPropertiesFromAPI = async (searchValue) => {
    let searchObj = {
      textToSearchFor: searchValue,
      pageSize: 10,
      pageNumber: 1,
    };

    if (this.state.currentUsaState?.stateGuid) {
      const propertiesData = await AgreementEntitiesAPI.SearchPropertiesFreeText(
        searchObj,
        this.state.currentUsaState.stateGuid
      );

      if (propertiesData?.data?.results) {
        let results = propertiesData.data.results;
        return results.map((result) => ({
          value: result.propertyUniqueIdentifier,
          label: result.propertyName,
        }));
      }
    }
    return "";
  };

  handleSpecificChanges = () => {
    //do nothing
  };

  handleSelectChanges = async (e, fieldName) => {
    const { data, errors } = this.state;
    data[fieldName] = e;
    if (fieldName === "selectedState") {
      data.selectedCounty = "";
      data.stateName = e?.label;
      this.setState({ availableCounties: e ? await this.getAvailableCounties(e.value) : [] });
    }
    if (fieldName === "selectedAgreement") {
      data.agreementName = e?.label;
    }
    if (fieldName === "selectedCounty") {
      data.countyName = e?.label;
    }
    if (fieldName === "property") {
      data.propertyName = e?.label;
    }
    if (fieldName === "performedBy") {
      data.performedByWSUserProfileUniqueReference = e?.value;
      data.performedByUserName = e?.label;
    }
    delete errors[fieldName];
    this.setState({ data, errors });
  };

  loadAvailableContacts = async (searchValue) => {
    const contactsData = await AgreementEntitiesAPI.SearchContactFreeText(this.getSearchContactsBody(searchValue));
    let contactsResults = [];
    if (contactsData?.data?.results) {
      let results = contactsData.data.results;
      contactsResults = results.map((result) => ({
        value: result.contactUniqueId,
        label: result.firstName.concat(" ", result.middleName, " ", result.lastName),
      }));
    }
    return contactsResults;
  };

  loadAvailableAgreements = async (searchValue) => {
    let agreementsResults = [];
    const agreementsResponse = await AgreementAPI.SearchAgreementFreeText(
      this.getSearchAgreementsBody(searchValue),
      this.getCurrentStateId()
    );
    if (agreementsResponse?.successful && agreementsResponse.data?.results?.length > 0) {
      agreementsResults = agreementsResponse.data.results.map((a) => ({
        value: a.agreementUniqueIdentifier,
        label: a.commonName,
      }));
    }
    return agreementsResults;
  };

  getSearchContactsBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
      filterByTypeOfContactIds: [1, 2, 3],
    };
  }

  getSearchAgreementsBody(searchValue) {
    return {
      textToSearchFor: searchValue,
      pageSize: 100,
      pageNumber: 1,
    };
  }

  handlePropertyMethodInventoryRowOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        cooperatorEquipmentRowSelected: [...this.state.cooperatorEquipmentRowSelected, row?.methodUniqueReference],
      });
    } else {
      this.setState({
        cooperatorEquipmentRowSelected: this.state.cooperatorEquipmentRowSelected.filter(
          (x) => x !== row?.methodUniqueReference
        ),
      });
    }
  };

  handleGeneralTopicsRowOnSelect = (row, isSelect) => {
    const { data } = this.state;
    if (isSelect) {
      data.selectedGeneralTopics = [...data.selectedGeneralTopics, row];
      this.setState({
        generalTopicsRowSelected: [...this.state.generalTopicsRowSelected, row?.topicUniqueReference],
        data,
      });
    } else {
      data.selectedGeneralTopics = data.selectedGeneralTopics.filter(
        (t) => t.topicUniqueReference !== row.topicUniqueReference
      );
      this.setState({
        generalTopicsRowSelected: this.state.generalTopicsRowSelected.filter((x) => x !== row?.topicUniqueReference),
        data,
      });
    }
  };

  handleResourceTopicsRowOnSelect = (row, isSelect) => {
    const { data } = this.state;
    if (isSelect) {
      data.selectedResourceTopics = [...data.selectedResourceTopics, row];
      this.setState({
        resourceTopicsRowSelected: [...this.state.resourceTopicsRowSelected, row?.resourceUniqueReference],
        data,
      });
    } else {
      data.selectedResourceTopics = data.selectedResourceTopics.filter(
        (r) => r.resourceUniqueReference !== row.resourceUniqueReference
      );
      this.setState({
        resourceTopicsRowSelected: this.state.resourceTopicsRowSelected.filter(
          (x) => x !== row?.resourceUniqueReference
        ),
        data,
      });
    }
  };

  handleSpeciesTopicsRowOnSelect = (row, isSelect) => {
    const { data } = this.state;
    if (isSelect) {
      data.selectedSpeciesTopics = [...data.selectedSpeciesTopics, row];
      this.setState({
        speciesTopicsRowSelected: [...this.state.speciesTopicsRowSelected, row?.speciesUniqueReference],
        data,
      });
    } else {
      data.selectedSpeciesTopics = data.selectedSpeciesTopics.filter(
        (s) => s.speciesUniqueReference !== row.speciesUniqueReference
      );
      this.setState({
        speciesTopicsRowSelected: this.state.speciesTopicsRowSelected.filter((x) => x !== row?.speciesUniqueReference),
        data,
      });
    }
  };

  handleOnSelectAllCooperatorEquipment = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ cooperatorEquipmentRowSelected: rows.map((r) => r.methodUniqueReference) });
    } else {
      this.setState({ cooperatorEquipmentRowSelected: [] });
    }
  };

  handleOnSelectAllGeneralTopics = (isSelect, rows) => {
    const { data } = this.state;
    if (isSelect) {
      data.selectedGeneralTopics = rows;
      this.setState({ generalTopicsRowSelected: rows.map((r) => r.topicUniqueReference), data });
    } else {
      data.selectedGeneralTopics = [];
      this.setState({ generalTopicsRowSelected: [], data });
    }
  };

  handleOnSelectAllResourceTopics = (isSelect, rows) => {
    const { data } = this.state;
    if (isSelect) {
      data.selectedResourceTopics = rows;
      this.setState({ resourceTopicsRowSelected: rows.map((r) => r.resourceUniqueReference), data });
    } else {
      data.selectedResourceTopics = [];
      this.setState({ resourceTopicsRowSelected: [], data });
    }
  };

  handleOnSelectAllSpeciesTopics = (isSelect, rows) => {
    const { data } = this.state;
    if (isSelect) {
      data.selectedSpeciesTopics = rows;
      this.setState({ speciesTopicsRowSelected: rows.map((s) => s.speciesUniqueReference), data });
    } else {
      data.selectedSpeciesTopics = [];
      this.setState({ speciesTopicsRowSelected: [], data });
    }
  };

  handleEquipmentAdd = () => {
    const { workTaskData, data } = this.state;
    workTaskData.itemsSuppliedToCooperator.push({
      inventoryItemName: data.equipmentSelectedMethod?.label,
      methodUniqueReference: data.equipmentSelectedMethod?.value,
      unitOfMeasure: data.equipmentSelectedUom,
      quantity: data.equipmentMethodQuantity,
    });
    this.setState({ workTaskData, isAddingEquipmentL: false }, () => {
      data.equipmentMethodQuantity = 0;
      data.equipmentSelectedMethod = "";
      data.equipmentSelectedUom = "";
      this.setState({ data });
    });
  };

  handleEquipmentRemove = () => {
    const { workTaskData, cooperatorEquipmentRowSelected } = this.state;
    if (cooperatorEquipmentRowSelected.length > 0) {
      workTaskData.itemsSuppliedToCooperator = workTaskData.itemsSuppliedToCooperator.filter(
        (i) => !cooperatorEquipmentRowSelected.includes(i.methodUniqueReference)
      );
      this.setState({ workTaskData, cooperatorEquipmentRowSelected: [] });
    } else {
      toast.warning("Please select the rows you want to remove.");
    }
  };

  onGeneralTopicsSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data } = this.state;
      data.selectedGeneralTopics = data.selectedGeneralTopics.filter(
        (t) => t.topicUniqueReference !== removedValue.value
      );
      this.setState({
        data,
        generalTopicsRowSelected: this.state.generalTopicsRowSelected.filter((t) => t !== removedValue.value),
      });
    }
  };

  onResourceTopicsSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data } = this.state;
      data.selectedResourceTopics = data.selectedResourceTopics.filter(
        (r) => r.resourceUniqueReference !== removedValue.value
      );
      this.setState({
        data,
        resourceTopicsRowSelected: this.state.resourceTopicsRowSelected.filter((r) => r !== removedValue.value),
      });
    }
  };

  onSpeciesTopicsSelectionChange = (value, { action, removedValue }) => {
    if (action === "remove-value") {
      const { data } = this.state;
      data.selectedSpeciesTopics = data.selectedSpeciesTopics.filter(
        (s) => s.speciesUniqueReference !== removedValue.value
      );
      this.setState({
        data,
        speciesTopicsRowSelected: this.state.speciesTopicsRowSelected.filter((s) => s !== removedValue.value),
      });
    }
  };

  handleGeneralTopicsSelection = (selectedGeneralTopic) => {
    const { data, allGeneralTopics } = this.state;

    const regularGeneral = allGeneralTopics.find(
      (s) => s.topicUniqueIdentifier === selectedGeneralTopic.topicUniqueIdentifier
    );
    if (regularGeneral) {
      regularGeneral.isSelected = !regularGeneral.isSelected;
    }

    if (
      data.selectedGeneralTopics.find((s) => s.topicUniqueIdentifier === selectedGeneralTopic.topicUniqueIdentifier)
    ) {
      data.selectedGeneralTopics = data.selectedGeneralTopics.filter(
        (s) => s.topicUniqueIdentifier !== selectedGeneralTopic.topicUniqueIdentifier
      );
    } else {
      data.selectedGeneralTopics.push(selectedGeneralTopic);
    }

    this.setState({ data, allGeneralTopics });
  };

  handleResourceFilterSelect = async (input, selectionField) => {
    const data = { ...this.state.data };
    if (!input) {
      data[selectionField] = "";
      if (selectionField === "resourceMainCategorySearch") {
        data.resourceSubCategory1Search = "";
      }
    } else {
      data[selectionField] = input;
      if (selectionField === "resourceMainCategorySearch") {
        const newSubTypeListResponse = await ReferenceFileAPI.GetResourceSubTypes(input.value);
        if (newSubTypeListResponse?.successful && newSubTypeListResponse?.data?.length > 0) {
          this.setState({
            resourceSubCategories1: newSubTypeListResponse?.data?.map((subType) => {
              if (!subType) {
                return { value: subType, label: "No Type" };
              }
              return { value: subType, label: subType };
            }),
          });
          data.resourceSubCategory1Search = "";
        }
      }
    }
    this.setState({ data });
  };

  handleSpeciesFilterSelect = (input, selectionField) => {
    const data = { ...this.state.data };
    if (!input) {
      data[selectionField] = "";
    } else {
      data[selectionField] = input;
    }
    this.setState({ data });
  };

  renderPageHeader() {
    let taType = "";
    switch (this.state.entityType) {
      case TAWorkTaskTypes.IndividualCooperator:
        taType = "Cooperator";
        break;
      case TAWorkTaskTypes.Hotline:
        taType = "Hotline";
        break;
      case TAWorkTaskTypes.PublicOutreach:
        taType = "Public Outreach";
        break;
      default:
        break;
    }
    return (
      <Row className="mt-4">
        <Col className="pl-0">
          <h5 className={globalStyles.pageTitle}>TA Record Page - {taType}</h5>
        </Col>
        {this.props.permissions.canIEditTaWorkTasks() && (
          <React.Fragment>
            <Col xs={2} className="text-right pr-0" hidden={!this.state.isEditMode}>
              <Button variant="secondary" onClick={this.cancelEditWorkTask}>
                <span className={globalStyles.modalSubmitButtonText}>Cancel</span>
              </Button>
            </Col>
            <Col xs={1} className="text-right pr-0">
              <Button variant="primary" onClick={this.editWorkTask}>
                <span className={globalStyles.modalSubmitButtonText}>
                  {this.state.isEditMode ? "Apply Changes" : "Edit"}
                </span>
              </Button>
            </Col>
          </React.Fragment>
        )}
      </Row>
    );
  }

  renderInformationCard() {
    const { entityType } = this.state;
    let taType = "";
    let isCooperatorTA = false;
    let isHotlineTA = false;
    switch (entityType) {
      case TAWorkTaskTypes.IndividualCooperator:
        taType = "Cooperator";
        isCooperatorTA = true;
        break;
      case TAWorkTaskTypes.Hotline:
        taType = "Hotline TA";
        isHotlineTA = true;
        break;
      case TAWorkTaskTypes.PublicOutreach:
        taType = "Public TA";
        break;
      default:
        break;
    }

    return (
      <Row className="mt-4">
        <Col className="px-0">
          <Card>
            <Card.Header className={globalStyles.cardTitles}>
              <i className="fa fa-user-circle-o fa-2x mr-2" aria-hidden="true"></i>
              <span className={globalStyles.cardTitleText}>{`${taType} Information`}</span>
            </Card.Header>
            <Card.Body>
              <Row className="text-left mt-3">
                <Col md={4} className={styles.infoCardLeftSideContentColumn}>
                  {this.renderGeneralWorkTaskInfo()}
                </Col>
                <Col>
                  {this.renderAditionalInfo()}
                  {(isCooperatorTA || isHotlineTA) && this.renderPermitActionsSection()}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderGeneralWorkTaskInfo() {
    const { isEditMode, data, entityType } = this.state;
    const isCooperatorTA = entityType === TAWorkTaskTypes.IndividualCooperator;
    const isHotlineTA = entityType === TAWorkTaskTypes.Hotline;
    const isPublicOutreach = entityType === TAWorkTaskTypes.PublicOutreach;

    return (
      <React.Fragment>
        {(isCooperatorTA || isHotlineTA) && (
          <Row className="mx-0">
            <Col className="pl-0">{this.renderCooperatorField()}</Col>
          </Row>
        )}
        <Row className="mx-0">
          <Col className="pl-0">
            {this.renderEditableField(isEditMode, "workDate", "Work Date", data.workDate, "date", "", "required")}
          </Col>
        </Row>
        {isPublicOutreach && (
          <Row className="mx-0">
            <Col className="pl-0">
              {this.renderEditableField(
                isEditMode,
                "numberOfLeaflets",
                "# of Leaflets",
                data.numberOfLeaflets,
                "number"
              )}
            </Col>
            <Col className="pl-0">
              {this.renderEditableField(
                isEditMode,
                "numberOfParticipants",
                "# of Participants",
                data.numberOfParticipants,
                "number"
              )}
            </Col>
          </Row>
        )}
        {(isCooperatorTA || isHotlineTA) && (
          <Row className="mx-0">
            <Col className="pl-0">{this.renderIdentifyAgreementField()}</Col>
          </Row>
        )}
        {isCooperatorTA && (
          <Row className="mx-0">
            <Col className="pl-0">{this.renderIdentifyPropertyField()}</Col>
            <Col className="pl-0">
              {this.renderEditableField(isEditMode, "milesTraveled", "Miles", data.milesTraveled, "number", "")}
            </Col>
          </Row>
        )}
        <Row className="mx-0">
          <Col className="pl-0">{this.renderEditableField(isEditMode, "city", "City", data.city, "text", "", "")}</Col>
          <Col className="pl-0">{this.renderIdentifyStateField()}</Col>
        </Row>
        <Row className="mx-0">
          <Col md={6} className="pl-0">
            {this.renderCountyField()}
          </Col>
        </Row>
        <Row className="mx-0">
          <Col className="pl-0">
            {this.renderEditableField(isEditMode, "hoursWorked", "Hours", data.hoursWorked, "number")}
          </Col>
          <Col className="pl-0">
            {this.renderEditableField(isEditMode, "minutesWorked", "Minutes", data.minutesWorked, "number")}
          </Col>
        </Row>
        <Row className="mx-0">{this.renderPerformedBy()}</Row>
      </React.Fragment>
    );
  }

  renderPerformedBy() {
    return this.state.isEditMode ? (
      <Col className="pl-0">
        <Form.Group>
          <Form.Label className={globalStyles.formLabel}>Performed By</Form.Label>
          <AsyncSelect
            value={this.state.data.performedBy}
            aria-label="Search For User Profiles"
            isClearable={true}
            openMenuOnClick={false}
            placeholder="Search"
            components={{ DropdownIndicator, Option }}
            styles={this.customSelectStyles}
            loadOptions={this.searchMatchingUsers}
            onChange={(e) => this.handleSelectChanges(e, "performedBy")}
            isDisabled={this.props.data}
          />
        </Form.Group>
      </Col>
    ) : (
      <Col className="pl-0">
        {this.renderLabel("performedByUserName", "Performed By", this.state.data.performedByUserName)}
      </Col>
    );
  }

  searchMatchingUsers = async (inputValue) => {
    let searchObj = {
      textToSearchFor: inputValue,
      pageSize: 25,
      pageNumber: 1,
      stateUniqueReference: this.state.currentUsaState.stateGuid,
    };
    const searchUserResponse = await RoleAuthorizationAPI.SearchUsersFreeText(searchObj);
    let outputUsers = [];
    if (searchUserResponse?.data?.results?.length > 0) {
      let results = searchUserResponse.data.results;
      for (let i = 0; i < results.length; i++) {
        let userObject;
        let user = results[i];
        for (let j = 0; j < user.userProfiles.length; j++) {
          let userProfile = user.userProfiles[j];
          if (userProfile) {
            userObject = {
              value: userProfile.wsUserProfileUniqueIdentifier,
              label: user.firstName + " " + user.lastName,
              profileName: userProfile.profileName,
            };
            outputUsers.push(userObject);
          }
        }
      }
    }
    return outputUsers;
  };

  renderAditionalInfo() {
    const { isEditMode, data, entityType } = this.state;
    const isCooperatorTA = entityType === TAWorkTaskTypes.IndividualCooperator;
    const isHotlineTA = entityType === TAWorkTaskTypes.Hotline;
    const isPublicOutreach = entityType === TAWorkTaskTypes.PublicOutreach;

    return (
      <React.Fragment>
        <Row className={styles.infoCardHeaderRows}>
          <Col>
            <span className={globalStyles.cardTitleText}>Additional Information</span>
          </Col>
        </Row>
        <Row className={`mx-0${isPublicOutreach ? " mb-4" : ""}`}>
          <Col>{this.renderActivitiesField()}</Col>
          {(isCooperatorTA || isHotlineTA) && (
            <Col>{this.renderEditableField(isEditMode, "comments", "Comments", data.comments)}</Col>
          )}
          {isCooperatorTA && <Col>{this.renderConflictField()}</Col>}
          {(isHotlineTA || isPublicOutreach) && <Col>{this.renderProjectsField()}</Col>}
          {isPublicOutreach && <Col>{this.renderRecommendationsField()}</Col>}
        </Row>
        <Row className="mx-0">
          {(isHotlineTA || isCooperatorTA) && <Col>{this.renderRecommendationsField()}</Col>}
          {(isHotlineTA || isCooperatorTA) && <Col>{this.renderCooperatorEmployedWorkField()}</Col>}
          {isCooperatorTA && <Col>{this.renderProjectsField()}</Col>}
          {isPublicOutreach && (
            <Col md={4}>
              {this.renderEditableField(
                isEditMode,
                "referredFrom",
                "Referred From:",
                data.referredFrom,
                "select",
                this.state.availableOrganizations,
                "clearable"
              )}
            </Col>
          )}
          {isPublicOutreach && (
            <Col md={4}>
              {this.renderEditableField(
                isEditMode,
                "referredTo",
                "Referred To:",
                data.referredTo,
                "select",
                this.state.availableOrganizations,
                "clearable"
              )}
            </Col>
          )}
          {isHotlineTA && (
            <Col>
              {this.renderEditableField(
                isEditMode,
                "referredFrom",
                "Referred From:",
                data.referredFrom,
                "select",
                this.state.availableOrganizations,
                "clearable"
              )}
            </Col>
          )}
        </Row>
        <Row className="mx-0 mb-3">
          {isCooperatorTA && (
            <Col md={4}>
              {this.renderEditableField(
                isEditMode,
                "referredFrom",
                "Referred From:",
                data.referredFrom,
                "select",
                this.state.availableOrganizations,
                "clearable"
              )}
            </Col>
          )}
          {(isCooperatorTA || isHotlineTA) && (
            <Col>
              {this.renderEditableField(
                isEditMode,
                "referredTo",
                "Referred To:",
                data.referredTo,
                "select",
                this.state.availableOrganizations,
                "clearable"
              )}
            </Col>
          )}
        </Row>
      </React.Fragment>
    );
  }

  renderPermitActionsSection() {
    const { isEditMode, data } = this.state;
    return (
      <React.Fragment>
        <Row className={styles.infoCardHeaderRows}>
          <Col>
            <span className={globalStyles.cardTitleText}>Permit Actions</span>
          </Col>
        </Row>
        <Row className="mx-0">
          <Col>
            {this.renderEditableField(
              isEditMode,
              "permitRecommendationType",
              "Permit Recommendation Type",
              data.permitRecommendationType,
              "select",
              this.state.availablePermitRecommendationTypes
            )}
          </Col>
          <Col>
            {this.renderEditableField(
              isEditMode,
              "permitAction",
              "Permit Action",
              data.permitAction,
              "select",
              this.state.availablePermitActions
            )}
          </Col>
          <Col>
            {this.renderEditableField(isEditMode, "permitNumberFreeText", "Permit Number", data.permitNumberFreeText)}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderCooperatorField() {
    const { data, isEditMode } = this.state;
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Cooperator</Form.Label>
        <AsyncSelect
          placeholder="Search for Contact"
          aria-label="Cooperator Search"
          value={this.state.data.cooperator}
          styles={this.customSelectStyles}
          components={{ DropdownIndicator }}
          loadOptions={this.loadAvailableContacts}
          onChange={(e) => this.handleSelectChanges(e, "cooperator")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("cooperator", "Cooperator", data.cooperator?.label)
    );
  }

  renderIdentifyAgreementField() {
    const { data, isEditMode } = this.state;
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Identify Agreement</Form.Label>
        <AsyncSelect
          placeholder="Search for Agreement"
          aria-label="Agreement Search"
          value={this.state.data.selectedAgreement}
          isClearable
          styles={this.customSelectStyles}
          components={{ DropdownIndicator }}
          loadOptions={this.loadAvailableAgreements}
          onChange={(e) => this.handleSelectChanges(e, "selectedAgreement")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("agreementName", "Agreement", data.agreementName)
    );
  }

  renderIdentifyPropertyField() {
    const { data, isEditMode } = this.state;
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Identify Property</Form.Label>
        <AsyncSelect
          value={this.state.data.property}
          aria-label="Search For Properties"
          openMenuOnClick={false}
          placeholder="Search"
          isClearable
          components={{ DropdownIndicator }}
          styles={this.customSelectStyles}
          loadOptions={this.getPropertiesFromAPI}
          onChange={(e) => this.handleSelectChanges(e, "property")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("propertyName", "Property", data.propertyName)
    );
  }

  renderIdentifyStateField() {
    const { data, isEditMode } = this.state;
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>State</Form.Label>
        <Select
          placeholder="Select"
          aria-label="State Select"
          isClearable
          value={data.selectedState}
          styles={this.customSelectStyles}
          options={this.state.states}
          s
          onChange={(e) => this.handleSelectChanges(e, "selectedState")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("stateName", "State", data.stateName)
    );
  }

  renderCountyField() {
    const { data, isEditMode, availableCounties } = this.state;
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>
          County<span className={globalStyles.asterisk508}> *</span>
        </Form.Label>
        <Select
          placeholder="Select"
          aria-label="County Select"
          value={data.selectedCounty}
          styles={this.customSelectStyles}
          options={availableCounties}
          onChange={(e) => this.handleSelectChanges(e, "selectedCounty")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("countyName", "County", data.countyName)
    );
  }

  renderActivitiesField() {
    const { data, isEditMode } = this.state;
    let activitiyNames = data.activities?.map((a) => a.label)?.join(", ");
    if (activitiyNames?.length > 20) {
      activitiyNames = activitiyNames.substring(0, 19) + "...";
    }
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>
          Activity(s)<span className={globalStyles.asterisk508}> *</span>
        </Form.Label>
        <Select
          placeholder="Select"
          aria-label="Activities Select"
          value={this.state.data.activities}
          styles={this.customSelectStyles}
          isMulti
          options={this.state.availableTaActivities}
          onChange={(e) => this.handleSelectChanges(e, "activities")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("activities", "Activity(s)", activitiyNames)
    );
  }

  renderConflictField() {
    const { data, isEditMode } = this.state;
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>
          Conflict<span className={globalStyles.asterisk508}> *</span>
        </Form.Label>
        <Select
          placeholder="Select"
          aria-label="Conflict Select"
          value={this.state.data.conflict}
          styles={this.customSelectStyles}
          options={this.state.availableConflicts}
          onChange={(e) => this.handleSelectChanges(e, "conflict")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("conflict", "Conflict", data.conflict?.label)
    );
  }

  renderRecommendationsField() {
    const { data, isEditMode } = this.state;
    let recommendationNames = data.recommendations?.map((r) => r.label)?.join(", ");
    if (recommendationNames?.length > 20) {
      recommendationNames = recommendationNames.substring(0, 19) + "...";
    }
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Recommendations</Form.Label>
        <Select
          placeholder="Select"
          aria-label="Recommendations Select"
          value={this.state.data.recommendations}
          styles={this.customSelectStyles}
          isMulti
          options={this.state.availableRecCems}
          onChange={(e) => this.handleSelectChanges(e, "recommendations")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("recommendations", "Recommendations", recommendationNames)
    );
  }

  renderCooperatorEmployedWorkField() {
    const { data, isEditMode } = this.state;
    let employedWorkNames = data.employedWorkItems?.map((e) => e.label)?.join(", ");
    if (employedWorkNames?.length > 20) {
      employedWorkNames = employedWorkNames.substring(0, 19) + "...";
    }
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Cooperator Employed Work</Form.Label>
        <Select
          placeholder="Select"
          aria-label="Recommendations Select"
          value={this.state.data.employedWorkItems}
          styles={this.customSelectStyles}
          isMulti
          options={this.state.availableRecCems}
          onChange={(e) => this.handleSelectChanges(e, "employedWorkItems")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("employedWorkItems", "Cooperator Employed Work", employedWorkNames)
    );
  }

  renderProjectsField() {
    const { data, isEditMode } = this.state;
    let projectsNames = data.projects?.map((p) => p.label)?.join(", ");
    if (projectsNames?.length > 20) {
      projectsNames = projectsNames.substring(0, 19) + "...";
    }
    return isEditMode ? (
      <Form.Group>
        <Form.Label className={globalStyles.formLabel}>Projects</Form.Label>
        <Select
          placeholder="Select"
          aria-label="Projects Select"
          value={this.state.data.projects}
          styles={this.customSelectStyles}
          isMulti
          options={this.state.availableProjects}
          onChange={(e) => this.handleSelectChanges(e, "projects")}
        />
      </Form.Group>
    ) : (
      this.renderLabel("projects", "Projects", projectsNames)
    );
  }

  handleMethodSelection = (e) => {
    const { data } = this.state;
    data.equipmentSelectedMethod = e;
    this.setState({
      data,
      equipmentMethodUoms: e?.allowedUOMsInState?.map((uom) => {
        uom.value = uom.id;
        uom.label = uom.displayText;
        return uom;
      }),
    });
  };

  handleUomSelection = (e) => {
    const { data } = this.state;
    data.equipmentSelectedUom = e;
    this.setState({ data });
  };

  renderEquipmentCard() {
    const { isEditMode, isAddingEquipment } = this.state;
    const areDataRowsSelected = this.state.cooperatorEquipmentRowSelected?.length > 0;
    return (
      <Row className="mt-4">
        <Col className="px-0">
          <Card>
            <Card.Header className={globalStyles.cardTitles}>
              <Row>
                <Col>
                  <span className={globalStyles.cardTitleText}>Loaned/Sold/Distributed Equipment</span>
                </Col>
                {isEditMode && (
                  <Col className="text-right pr-0">
                    <Button
                      variant="link"
                      onClick={() => {
                        this.setState({ isAddingEquipment: true });
                      }}
                    >
                      <span className={globalStyles.formDataLinks}>
                        {isAddingEquipment ? "" : "+ Add Another Equipment"}
                      </span>
                    </Button>
                  </Col>
                )}
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="text-right">
                <Col className="pr-0">
                  {isEditMode && !isAddingEquipment && (
                    <Button
                      className={areDataRowsSelected ? "" : styles.greyedOutRemoveButton}
                      onClick={this.handleEquipmentRemove}
                    >
                      <span className={areDataRowsSelected ? "" : styles.greyedOutButtonText}>Remove</span>
                    </Button>
                  )}
                  {isEditMode && isAddingEquipment && (
                    <React.Fragment>
                      <Row>
                        <Col xs={6} align="left">
                          <Form.Group>
                            <Form.Label className={globalStyles.formLabel}>Method</Form.Label>
                            <Select
                              value={this.state.data.equipmentSelectedMethod}
                              aria-label="Method Selection"
                              placeholder="Select"
                              isClearable={true}
                              styles={this.customSelectStyles}
                              options={this.state.availableMethods}
                              onChange={(e) => this.handleMethodSelection(e)}
                            />
                          </Form.Group>
                        </Col>
                        <Col align="left">
                          <Form.Label className={globalStyles.formLabel}>
                            UOM
                            <span className={globalStyles.asterisk508}>
                              {this.state.data.selectedMethod ? " *" : ""}
                            </span>
                          </Form.Label>
                          <Select
                            value={this.state.data.equipmentSelectedUom}
                            aria-label="Method UOM Selection"
                            placeholder="Select"
                            isClearable={true}
                            styles={this.customSelectStyles}
                            options={this.state.equipmentMethodUoms}
                            onChange={(e) => this.handleUomSelection(e)}
                          />
                        </Col>
                        <Col>
                          {this.renderInput(
                            "equipmentMethodQuantity",
                            "Quantity",
                            "number",
                            "Enter",
                            this.state.data.selectedMethod ? "required" : ""
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ offset: 9, span: 3 }} align="right">
                          <Button
                            variant="primary"
                            onClick={this.handleEquipmentAdd}
                            disabled={
                              !(
                                this.state.data.equipmentSelectedMethod &&
                                this.state.data.equipmentSelectedUom &&
                                this.state.data.equipmentMethodQuantity
                              )
                            }
                          >
                            <span className={globalStyles.modalSubmitButtonText}>Add</span>
                          </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="pr-0">{this.renderCooperatorEquipmentTable()}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderCooperatorEquipmentTable() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.cooperatorEquipmentRowSelected,
      onSelect: this.handlePropertyMethodInventoryRowOnSelect,
      onSelectAll: this.handleOnSelectAllCooperatorEquipment,
      style: { backgroundColor: "#f5f5f5" },
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "1px solid #e8e8e8",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #dee2e6",
      },
      hideSelectAll: !this.state.isEditMode,
      hideSelectColumn: !this.state.isEditMode,
    };

    const equipmentData =
      this.state.workTaskData?.itemsSuppliedToCooperator?.length > 0
        ? this.state.workTaskData.itemsSuppliedToCooperator
        : [];

    return (
      <ToolkitProvider
        keyField="methodUniqueReference"
        data={equipmentData}
        columns={this.state.cooperatorEquipmentTableColumns}
        bootstrap4={true}
        hover={true}
      >
        {(props) => (
          <div>
            <BootstrapTable
              keyField="methodUniqueReference"
              bordered={false}
              condensed
              selectRow={selectRow}
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }

  renderTopicInfoCard() {
    const { isEditMode } = this.state;
    return (
      <Row className="mt-4">
        <Col className="px-0">
          <Card>
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Topic Information</span>
            </Card.Header>
            <Card.Body>
              <Tab.Container id="topicsTabs" defaultActiveKey="generalTopicsTab">
                <Row className="mx-0">
                  <Col className="px-0">
                    <Nav variant="pills" className="mx-0">
                      <Nav.Item className={styles.topicsNavItemLeft}>
                        <Nav.Link eventKey="generalTopicsTab" className={styles.topicsNavLinkLeft}>
                          General
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className={styles.topicsNavItemMiddle}>
                        <Nav.Link eventKey="speciesTopicsTab" className={styles.topicsNavLinkMiddle}>
                          Damage Agents
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className={styles.topicsNavItemRight}>
                        <Nav.Link eventKey="resourcesTopicsTab" className={styles.topicsNavLinkRight}>
                          Resources
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Tab.Content>
                      <Tab.Pane eventKey="generalTopicsTab">
                        {this.renderGeneralTopicInfoTable()}
                        {isEditMode && this.renderGeneralTopicsSelectionResults()}
                      </Tab.Pane>
                      <Tab.Pane eventKey="speciesTopicsTab">
                        {this.renderSpeciesTopicInfotable()}
                        {isEditMode && this.renderSpeciesTopicsSelectionResults()}
                      </Tab.Pane>
                      <Tab.Pane eventKey="resourcesTopicsTab">
                        {this.renderResourcesTopicInfotable()}
                        {isEditMode && this.renderResourceTopicsSelectionResults()}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderGeneralTopicsSelectionResults = () => {
    return (
      <Row className="text-left mt-4">
        <Col>
          <Form.Group>
            <Form.Label className={globalStyles.formLabel}>General Selected</Form.Label>
            <Select
              aria-label="General Topics Selected"
              name="generalSelected"
              id="generalSelected"
              placeholder="General Topics Selected"
              isMulti
              isSearchable={false}
              menuIsOpen={false}
              isClearable={false}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: "white",
                    border: "solid 0.5px #D8DDE6",
                  };
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: "#0070D2",
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: "#53698D",
                  ":hover": {
                    backgroundColor: "white",
                  },
                }),
              }}
              components={{
                DropdownIndicator: (props) => {
                  return "";
                },
              }}
              value={this.state.data?.selectedGeneralTopics?.map((s) => ({
                value: s.topicUniqueReference,
                label: s.topicName,
              }))}
              onChange={this.onGeneralTopicsSelectionChange}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  renderResourceTopicsSelectionResults = () => {
    return (
      <Row className="text-left mt-4">
        <Col>
          <Form.Group>
            <Form.Label className={globalStyles.formLabel}>Resources Selected</Form.Label>
            <Select
              aria-label="Resource Topics Selected"
              name="resourcesSelected"
              id="resourcesSelected"
              placeholder="Resource Topics Selected"
              isMulti
              isSearchable={false}
              menuIsOpen={false}
              isClearable={false}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: "white",
                    border: "solid 0.5px #D8DDE6",
                  };
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: "#0070D2",
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: "#53698D",
                  ":hover": {
                    backgroundColor: "white",
                  },
                }),
              }}
              components={{
                DropdownIndicator: (props) => {
                  return "";
                },
              }}
              value={this.state.data?.selectedResourceTopics?.map((r) => ({
                value: r.resourceUniqueReference,
                label: r.resourceName,
              }))}
              onChange={this.onResourceTopicsSelectionChange}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  renderSpeciesTopicsSelectionResults = () => {
    return (
      <Row className="text-left mt-4">
        <Col>
          <Form.Group>
            <Form.Label className={globalStyles.formLabel}>Species Selected</Form.Label>
            <Select
              aria-label="Species Topics Selected"
              name="speciesSelected"
              id="speciesSelected"
              placeholder="Species Topics Selected"
              isMulti
              isSearchable={false}
              menuIsOpen={false}
              isClearable={false}
              styles={{
                indicatorSeparator: () => {
                  //do nothing
                },
                placeholder: (styles) => ({ ...styles, fontStyle: "italic !important", fontSize: "14px" }),
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: "white",
                    border: "solid 0.5px #D8DDE6",
                  };
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: "#0070D2",
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: "#53698D",
                  ":hover": {
                    backgroundColor: "white",
                  },
                }),
              }}
              components={{
                DropdownIndicator: (props) => {
                  return "";
                },
              }}
              value={this.state.data?.selectedSpeciesTopics?.map((s) => ({
                value: s.speciesUniqueReference,
                label: s.speciesName,
              }))}
              onChange={this.onSpeciesTopicsSelectionChange}
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  renderGeneralTopicInfoTable() {
    const { isEditMode } = this.state;
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.generalTopicsRowSelected,
      onSelect: this.handleGeneralTopicsRowOnSelect,
      onSelectAll: this.handleOnSelectAllGeneralTopics,
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "1px solid #e1e1e1",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #e1e1e1",
      },
      hideSelectAll: !isEditMode,
      hideSelectColumn: !isEditMode,
    };

    let topicsData = [];
    if (isEditMode) {
      topicsData = this.state.allGeneralTopics;
    } else {
      topicsData = this.state.data.selectedGeneralTopics?.length > 0 ? this.state.data.selectedGeneralTopics : [];
    }

    return (
      <div className={isEditMode ? styles.topicsResultsBox : ""}>
        <ToolkitProvider
          keyField="topicUniqueReference"
          data={topicsData}
          columns={this.state.generalTopicsTableColumns}
          bootstrap4={true}
          hover={true}
        >
          {(props) => (
            <div>
              <BootstrapTable
                keyField="topicUniqueReference"
                bordered={false}
                condensed
                selectRow={selectRow}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }

  renderResourcesTopicInfotable() {
    const { isEditMode, data } = this.state;
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.resourceTopicsRowSelected,
      onSelect: this.handleResourceTopicsRowOnSelect,
      onSelectAll: this.handleOnSelectAllResourceTopics,
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "1px solid #e1e1e1",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #e1e1e1",
      },
      hideSelectAll: !isEditMode,
      hideSelectColumn: !isEditMode,
    };

    let resourcesData = [];
    if (isEditMode) {
      resourcesData = this.state.allAvailableResources.filter((resource) => {
        let isMatch = true;
        if (data.resourceNameSearch) {
          isMatch = isMatch && resource.name?.toLowerCase().includes(data.resourceNameSearch);
        }
        if (data.resourceMainCategorySearch) {
          isMatch = isMatch && resource.type === data.resourceMainCategorySearch.value;
        }
        if (data.resourceSubCategory1Search) {
          isMatch = isMatch && resource.subType === data.resourceSubCategory1Search.value;
        }

        return isMatch;
      });
    } else {
      resourcesData = this.state.data.selectedResourceTopics?.length > 0 ? this.state.data.selectedResourceTopics : [];
    }

    return (
      <div className={isEditMode ? styles.topicsResultsBox : ""}>
        {isEditMode && this.renderResourceFiltersRow()}
        <ToolkitProvider
          keyField="resourceUniqueReference"
          data={resourcesData}
          columns={this.state.resourceTopicsTableColumns}
          bootstrap4={true}
          hover={true}
        >
          {(props) => (
            <div>
              <BootstrapTable
                keyField="resourceUniqueReference"
                bordered={false}
                condensed
                selectRow={selectRow}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }

  renderSpeciesTopicInfotable() {
    const { isEditMode, data } = this.state;
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      selected: this.state.speciesTopicsRowSelected,
      onSelect: this.handleSpeciesTopicsRowOnSelect,
      onSelectAll: this.handleOnSelectAllSpeciesTopics,
      headerColumnStyle: {
        borderTop: "hidden",
        borderBottom: "1px solid #e1e1e1",
      },
      selectColumnStyle: {
        verticalAlign: "middle",
        borderBottom: "1px solid #e1e1e1",
      },
      hideSelectAll: !isEditMode,
      hideSelectColumn: !isEditMode,
    };

    let speciesData = [];
    if (isEditMode) {
      speciesData = this.state.allAvailableSpecies.filter((species) => {
        let isMatch = true;
        if (data.speciesNameSearch) {
          isMatch = isMatch && species.name?.toLowerCase().includes(data.speciesNameSearch);
        }
        if (data.speciesClassesSearch) {
          isMatch = isMatch && species.class === data.speciesClassesSearch.value;
        }
        if (data.speciesOrdersSearch) {
          isMatch = isMatch && species.order === data.speciesOrdersSearch.value;
        }
        if (data.speciesFamiliesSearch) {
          isMatch = isMatch && species.family === data.speciesFamiliesSearch.value;
        }
        if (data.speciesCategoriesSearch) {
          isMatch = isMatch && species.subType === data.speciesCategoriesSearch.value;
        }

        return isMatch;
      });
    } else {
      speciesData = this.state.data.selectedSpeciesTopics?.length > 0 ? this.state.data.selectedSpeciesTopics : [];
    }

    return (
      <div className={isEditMode ? styles.topicsResultsBox : ""}>
        {isEditMode && this.renderSpeciesFiltersRow()}
        <ToolkitProvider
          keyField="speciesUniqueReference"
          data={speciesData}
          columns={this.state.speciesTopicsTableColumns}
          bootstrap4={true}
          hover={true}
        >
          {(props) => (
            <div>
              <BootstrapTable
                keyField="speciesUniqueReference"
                bordered={false}
                condensed
                selectRow={selectRow}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }

  renderGeneralSearchResults = () => {
    let results = [];
    const { allGeneralTopics } = this.state;
    results = (
      <React.Fragment>
        {allGeneralTopics.map((result, index) => {
          return (
            <div key={index}>
              <Row className={`mb-2 mx-0 ${index === 0 ? "mt-3" : "mt-2"}`}>
                <Col>
                  <Form.Check
                    type="checkbox"
                    inline
                    checked={result.isSelected}
                    onChange={() => this.handleGeneralTopicsSelection(result)}
                    title={result.name}
                  />
                  <span className={globalStyles.formData}>{result.name}</span>
                </Col>
              </Row>
              <Row className={styles.greySeparatorRow}></Row>
            </div>
          );
        })}
      </React.Fragment>
    );
    return results;
  };

  renderResourceFiltersRow() {
    const { data } = this.state;
    return (
      <Row className={styles.topicsFiltersRow}>
        <Col className="pr-0">
          <Form.Control
            aria-label="Resource Name Search Input"
            name="resourceNameSearch"
            placeholder="Enter Resource Name"
            value={data.resourceNameSearch}
            onChange={this.handleChange}
          />
        </Col>
        <Col className="pr-0">
          <Select
            aria-label="Resource Type Select"
            name="resourceTypeSearch"
            placeholder="Type"
            isClearable
            value={data.resourceMainCategorySearch}
            onChange={async (e) => await this.handleResourceFilterSelect(e, "resourceMainCategorySearch")}
            styles={{
              indicatorSeparator: () => {
                //do nothing
              },
              placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
              option: (styles, { isFocused }) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
                backgroundColor: isFocused ? "lightgray" : "white",
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
              }),
              menuList: (styles) => ({ ...styles, textAlign: "left" }),
            }}
            options={this.state.resourceMainCategories}
          />
        </Col>
        <Col>
          <Select
            aria-label="Resource Sub Type Select"
            name="resourceSubTypeSearch"
            placeholder="Sub Type"
            isClearable
            value={data.resourceSubCategory1Search}
            onChange={async (e) => await this.handleResourceFilterSelect(e, "resourceSubCategory1Search")}
            styles={{
              indicatorSeparator: () => {
                //do nothing
              },
              placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
              option: (styles, { isFocused }) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
                backgroundColor: isFocused ? "lightgray" : "white",
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
              }),
              menuList: (styles) => ({ ...styles, textAlign: "left" }),
            }}
            options={this.state.resourceSubCategories1}
          />
        </Col>
      </Row>
    );
  }

  renderSpeciesFiltersRow() {
    const { data } = this.state;
    return (
      <Row className={styles.topicsFiltersRow}>
        <Col className="pr-0">
          <Form.Control
            aria-label="SpeciesName Search"
            name="speciesNameSearch"
            placeholder="Enter Damage Agent Name"
            value={data.speciesNameSearch}
            onChange={this.handleChange}
          />
        </Col>
        <Col className="pr-0">
          <Select
            aria-label="Species Class"
            name="speciesClassesSearch"
            placeholder="Class"
            isClearable
            value={data.speciesClassesSearch}
            onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesClassesSearch")}
            styles={{
              indicatorSeparator: () => {
                //do nothing
              },
              placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
              option: (styles, { isFocused }) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
                backgroundColor: isFocused ? "lightgray" : "white",
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
              }),
              menuList: (styles) => ({ ...styles, textAlign: "left" }),
            }}
            options={this.state.speciesClasses}
          />
        </Col>
        <Col className="pr-0">
          <Select
            aria-label="Species Order"
            name="speciesOrdersSearch"
            placeholder="Order"
            isClearable
            value={data.speciesOrdersSearch}
            onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesOrdersSearch")}
            styles={{
              indicatorSeparator: () => {
                //do nothing
              },
              placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
              option: (styles, { isFocused }) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
                backgroundColor: isFocused ? "lightgray" : "white",
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
              }),
              menuList: (styles) => ({ ...styles, textAlign: "left" }),
            }}
            options={this.state.speciesOrders}
          />
        </Col>
        <Col className="pr-0">
          <Select
            aria-label="Species Family"
            name="speciesFamiliesSearch"
            placeholder="Family"
            isClearable
            value={data.speciesFamiliesSearch}
            onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesFamiliesSearch")}
            styles={{
              indicatorSeparator: () => {
                //do nothing
              },
              placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
              option: (styles, { isFocused }) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
                backgroundColor: isFocused ? "lightgray" : "white",
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
              }),
              menuList: (styles) => ({ ...styles, textAlign: "left" }),
            }}
            options={this.state.speciesFamilies}
          />
        </Col>
        <Col>
          <Select
            aria-label="Species Category"
            name="speciesCategoriesSearch"
            placeholder="Category"
            value={data.speciesCategoriesSearch}
            isClearable
            onChange={(e) => this.handleSpeciesFilterSelect(e, "speciesCategoriesSearch")}
            styles={{
              indicatorSeparator: () => {
                //do nothing
              },
              placeholder: (styles) => ({ ...styles, fontStyle: "normal !important", fontSize: "14px" }),
              option: (styles, { isFocused }) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
                backgroundColor: isFocused ? "lightgray" : "white",
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: "14px",
                fontWeight: "normal",
                color: "black",
              }),
              menuList: (styles) => ({ ...styles, textAlign: "left" }),
            }}
            options={this.state.speciesCategories}
          />
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isFormLoading}
        spinner
        text="Loading TA Work Task Data..."
        styles={{
          wrapper: (base) => ({
            ...base,
            height: "100%",
          }),
          overlay: (base) => ({
            ...base,
            width: "100%",
            height: "100%",
          }),
          content: (base) => ({
            ...base,
            position: "fixed",
            top: "70%",
            left: "49%",
          }),
          spinner: (base) => ({
            ...base,
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px",
            marginBottom: "99%",
            "& svg circle": {
              stroke: "#007bff",
            },
          }),
        }}
      >
        <div className={styles.workTaskPage}>
          <Form onSubmit={this.handleSubmit}>
            {this.renderPageHeader()}
            {this.renderInformationCard()}
            {this.state.entityType === TAWorkTaskTypes.IndividualCooperator && this.renderEquipmentCard()}
            {this.renderTopicInfoCard()}
          </Form>
        </div>
        <SuccessfulRecordModifiedModal
          show={this.state.showSuccessfulUpdateModal}
          onHide={() => this.setState({ showSuccessfulUpdateModal: false })}
          routeData=""
          messageType="update"
          objectName="TA Work Task"
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(TAWorkTaskDetailsPage);
