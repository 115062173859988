import React from "react";
import { withRouter } from "react-router";
import CustomForm from "../common/form";
import { Card, Row, Col, Dropdown, Button, Form } from "react-bootstrap";
import ReferenceFileAPI from "../../api/ReferenceFiles/ReferenceFileAPI";
import styles from "./ReferenceFilesCreatePages.module.scss";
import globalStyles from "../../../src/OARS.module.scss";
import { toast } from "react-toastify";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const ValuationRequiredEnum = {
  None: "None",
  Optional: "Optional",
  Mandatory: "Mandatory",
};

class ResourceForm extends CustomForm {
  state = {
    data: {
      resourceId: "",
      resourceName: "",
      abbreviation: "",
      resourceDescription: "",
      scientificName: "",
      resourceType: "",
      resourceSubType: "",
      startDate: "",
      endDate: "",
      taxonomyNumber: null,
      taxonomyParentNumber: null,
      aouNumber: "",
      resourceUom: "",
      stateUOMsToAdd: [],
      stateUOMsToRemove: [],
      resourceValue: "",
      resourceStatus: "",
      dateAdded: "",
      dateLastUpdated: "",
      createdBy: "",
      lastModifiedBy: "",
      valuePerUnitOfMeasure: "",
      valuationYear: "",
      valuationRequiredEnumId: ValuationRequiredEnum.Optional,
      globalValuationSetting: "",
      isValuationChanged: false,
    },
    errors: {},
    globalUOMEnum: "",
    resourceTypes: [],
    resourceSubTypes: [],
    resourceUOMs: [],
    resourceData: {},
    apiValuationSettings: [],
    validated: false,
    isValuationChanged: false,
  };

  async componentDidMount() {
    const resourceTypes = await this.getResourceTypes();
    const resourceUOMs = await this.getResourceUOMs();
    let resourceSubTypes = [];

    let { data, resourceData } = this.state;
    const { formType } = this.props;

    if (formType === "create") {
      data.resourceStatus = "Active";
    } else {
      resourceData = await ReferenceFileAPI.GetGlobalResource(this.props.match.params.id);

      if (resourceData?.successful) {
        data = this.mapToDataModel(resourceData, resourceUOMs);
        if (data.resourceType) {
          resourceSubTypes = await this.getResourceSubTypes(data.resourceType);
        }
      } else {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to get resource records",
          apiName: "GetGlobalResource",
          responseUnsuccessful: resourceData?.unsuccessful,
          responseMessage: resourceData?.message,
        });
      }
    }

    let apiValuationSettings = [];
    const getSettingsAction = await ReferenceFileAPI.GetAllResourceValuationSettings();
    if (getSettingsAction?.successful) {
      apiValuationSettings = getSettingsAction.data;
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to resource valuations",
        apiName: "GetAllResourceValuationSettings",
        responseUnsuccessful: getSettingsAction?.unsuccessful,
        responseMessage: getSettingsAction?.message,
      });
    }

    this.setState({
      resourceTypes,
      resourceSubTypes,
      resourceUOMs,
      data,
      resourceData,
      apiValuationSettings,
    });
  }

  async getResourceTypes() {
    let resourceTypesData = await ReferenceFileAPI.GetResourceTypesEnum();
    if (!resourceTypesData || resourceTypesData.unsuccessful) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get resource types",
        apiName: "GetResourceTypesEnum",
        responseUnsuccessful: resourceTypesData?.unsuccessful,
        responseMessage: resourceTypesData?.message,
      });
      return [];
    } else {
      return resourceTypesData.data.map((resourceType) => ({
        id: resourceType.id,
        name: resourceType.displayText.toUpperCase(),
      }));
    }
  }

  async getResourceUOMs() {
    let resourceUomsResult = await ReferenceFileAPI.GetAllResourceUOMs();
    if (resourceUomsResult?.successful) {
      if (resourceUomsResult.data) {
        return resourceUomsResult.data;
      }
    } else {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get resource units of measures",
        apiName: "GetAllResourceUOMs",
        responseUnsuccessful: resourceUomsResult?.unsuccessful,
        responseMessage: resourceUomsResult?.message,
      });
    }
  }

  handleSpecificChanges(input) {
    if (input.name === "resourceType") this.updateResourceSubTypes(input);
    if (input.name === "resourceUom") this.updateGlobalValuationUOM(input);
    if (input.name === "resourceYear" || input.name === "resourceValue") {
      this.setState({ isValuationChanged: true });
    }
  }

  async updateResourceSubTypes(input) {
    let resourceSubTypes = [];
    if (input.value) {
      resourceSubTypes = await this.getResourceSubTypes(input.value);
    }

    this.setState({ resourceSubTypes });
  }

  updateGlobalValuationUOM(input) {
    this.setState({ globalUOMEnum: parseInt(input.value, 10) });
  }

  async getResourceSubTypes(resourceType) {
    const resourceSubTypesData = await ReferenceFileAPI.GetResourceSubTypesEnumForType(resourceType);
    if (!resourceSubTypesData) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: "Unable to get resource sub types",
        apiName: "GetResourceSubTypesEnumForType",
        responseUnsuccessful: resourceSubTypesData?.unsuccessful,
        responseMessage: resourceSubTypesData?.message,
      });
      return [];
    } else {
      return resourceSubTypesData.data.map((subType) => ({
        id: subType.id,
        name: subType.displayText,
      }));
    }
  }

  mapToDataModel(resource, resourceUOMs) {
    const { data } = resource;
    let uom = "";

    if (data.globalValuationUnitOfMeasure) {
      uom = data.globalValuationUnitOfMeasure.id;
      this.setState({ globalUOMEnum: uom });
    }

    let mostRecentValuationYear = Math.max.apply(
      Math,
      data.globalResourceValuations.map(function (val) {
        return val.valuationYear;
      })
    );
    let mostRecentValuation = data.globalResourceValuations.find(function (val) {
      return val.valuationYear === mostRecentValuationYear;
    });

    let addUOMArray = data.globalAllowedUnitOfMeasures.map((uom) => {
      return {
        unitOfMeasureEnumId: uom.unitOfMeasure.id,
        conversionFactor: uom.conversionFactor,
      };
    });
    let removeUOMArray = [];
    resourceUOMs.forEach((uom) => {
      if (!data.globalAllowedUnitOfMeasures.find((item) => item.unitOfMeasure.id === uom.id)) {
        removeUOMArray.push(uom.id);
      }
    });

    return {
      resourceId: data.resourceUniqueIdentifier,
      resourceName: data.name,
      abbreviation: data.abbreviation,
      resourceDescription: data.description ? data.description : "",
      scientificName: data.scientificName ? data.scientificName : "",
      resourceType: data.resourceType.id,
      resourceSubType: data.resourceSubType.id,
      startDate: data.startDate ? new Date(data.startDate).toLocaleDateString("en-us") : data.startDate,
      endDate: data.endDate ? new Date(data.endDate).toLocaleDateString("en-us") : data.endDate,
      taxonomyNumber: data.taxonomyNumber,
      taxonomyParentNumber: data.taxonomyParentNumber,
      aouNumber: data.aouNumber,
      resourceUom: uom,
      resourceValue: mostRecentValuation?.valuePerUnitOfMeasure ? mostRecentValuation?.valuePerUnitOfMeasure : "",
      resourceStatus: data.isAllowedGlobally ? "Active" : "Inactive",
      dateAdded: data.createdDateTimeUTC
        ? new Date(data.createdDateTimeUTC).toLocaleDateString("en-ca")
        : data.createdDateTimeUTC,
      dateLastUpdated: data.lastModifiedDateTimeUTC
        ? new Date(data.lastModifiedDateTimeUTC).toLocaleDateString("en-ca")
        : data.lastModifiedDateTimeUTC,
      valuationYear: mostRecentValuation?.valuationYear,
      stateUOMsToAdd: addUOMArray,
      stateUOMsToRemove: removeUOMArray,
      comments: data.comments,
      globalValuationSetting: data.globalValuationSetting?.displayText,
    };
  }

  async doSubmit() {
    const { formType } = this.props;
    const { data, resourceData } = this.state;
    if (!data.globalValuationSetting) {
      ErrorHandler.showError("Global Valuation Setting must be provided");
      return;
    }
    const messageBody =
      formType === "create"
        ? this.getMessageBodyToCreateResource(data)
        : this.getMessageBodyToEditResource(data, resourceData.data);

    let serverResponse = null;
    let addValuationResult = null;
    let addUOMCall = null;
    let removeUOMCall = null;
    if (formType === "create") {
      serverResponse = await ReferenceFileAPI.CreateResource(messageBody);
    } else {
      serverResponse = await ReferenceFileAPI.UpdateResource(data.resourceId, messageBody);
      this.setGlobalValuationSetting();
    }

    if (serverResponse?.unsuccessful || !serverResponse) {
      ErrorHandler.handleApiErrorMessage({
        errorContextMessage: `Unable to ${formType === "create" ? "create" : "update"} resource record`,
        apiName: formType === "create" ? "CreateResource" : "UpdateResource",
        responseUnsuccessful: serverResponse?.unsuccessful,
        responseMessage: serverResponse?.message,
      });
      return;
    } else {
      toast.success(`${formType === "create" ? "Create" : "Edit"} resource successful.`);
    }
    if (serverResponse?.successful) {
      let valuationPresent = false;
      if (resourceData?.data?.globalResourceValuations) {
        valuationPresent =
          resourceData.data.globalResourceValuations.find((v) => v.valuationYear === parseInt(data.valuationYear)) !==
          undefined;
      }
      let valSettingId = this.state.apiValuationSettings.find(
        (x) => x.displayText === this.state.data.globalValuationSetting
      )?.id;

      if (valSettingId !== 1) {
        if (serverResponse && data.resourceValue !== "" && data.valuationYear !== "") {
          if (this.state.isValuationChanged && valuationPresent) {
            let addValuationResult = await ReferenceFileAPI.RemoveGlobalValuation(
              serverResponse.data.resourceUniqueIdentifier,
              data.valuationYear
            );
            if (!addValuationResult || addValuationResult?.unsuccessful) {
              ErrorHandler.handleApiErrorMessage({
                errorContextMessage: "Unable to remove global valuation",
                apiName: "RemoveGlobalValuation",
                responseUnsuccessful: addValuationResult?.unsuccessful,
                responseMessage: addValuationResult?.message,
              });
            }
          }
          addValuationResult = await ReferenceFileAPI.AddGlobalValuation(
            serverResponse.data.resourceUniqueIdentifier,
            data.valuationYear,
            Math.round(Number.parseFloat(data.resourceValue))
          );
          if (!addValuationResult || addValuationResult?.unsuccessful) {
            ErrorHandler.handleApiErrorMessage({
              errorContextMessage: "Unable to add global valuation",
              apiName: "AddGlobalValuation",
              responseUnsuccessful: addValuationResult?.unsuccessful,
              responseMessage: addValuationResult?.message,
            });
          }
        }
      }

      if (serverResponse?.data?.resourceUniqueIdentifier) {
        if (data.stateUOMsToAdd.length > 0) {
          addUOMCall = await ReferenceFileAPI.AddAdditionalResourceUOMs(
            serverResponse.data.resourceUniqueIdentifier,
            data.stateUOMsToAdd
          );
        }
        if (data.stateUOMsToRemove.length > 0) {
          removeUOMCall = await ReferenceFileAPI.RemoveAdditionalResourceUOMs(
            serverResponse.data.resourceUniqueIdentifier,
            data.stateUOMsToRemove
          );
          if (addUOMCall?.unsuccessful || removeUOMCall?.unsuccessful || !addUOMCall || !removeUOMCall) {
            ErrorHandler.showError("UOM selection failed");
            if (removeUOMCall?.unsuccessful || !removeUOMCall) {
              ErrorHandler.handleApiErrorMessage({
                errorContextMessage: "Unable to remove additional resource units of measure",
                apiName: "RemoveAdditionalResourceUOMs",
                responseUnsuccessful: removeUOMCall?.unsuccessful,
                responseMessage: removeUOMCall?.message,
              });
            }
            if (addUOMCall?.unsuccessful || !addUOMCall) {
              ErrorHandler.handleApiErrorMessage({
                errorContextMessage: "Unable to add additional resource units of measure",
                apiName: "AddAdditionalResourceUOMs",
                responseUnsuccessful: addValuationResult?.unsuccessful,
                responseMessage: addValuationResult?.message,
              });
            }
          }
        }
      }
    }

    if (formType === "create") {
      this.props.history.push({
        pathname: "/referencefiles",
        state: {
          tab: "resourcesTab",
          globalData: this.props.history.location.state.globalData,
          resourceCreateResponseData:
            removeUOMCall?.data || addUOMCall?.data || addValuationResult?.data || serverResponse?.data,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/referencefiles",
        state: {
          tab: "resourcesTab",
          globalData: this.props.history.location.state.globalData,
          resourceEditResponseData:
            removeUOMCall?.data || addUOMCall?.data || addValuationResult?.data || serverResponse?.data,
        },
      });
    }
  }

  async setGlobalValuationSetting() {
    let valSettingId = this.state.apiValuationSettings.find(
      (x) => x.displayText === this.state.data.globalValuationSetting
    )?.id;
    if (this.state.resourceData.data.globalValuationSetting?.id !== valSettingId) {
      var setGlobalValuationSettingAction = await ReferenceFileAPI.SetGlobalResourceValuationSetting(
        this.state.data.resourceId,
        valSettingId
      );
      if (!setGlobalValuationSettingAction?.successful) {
        ErrorHandler.handleApiErrorMessage({
          errorContextMessage: "Unable to set resource valuation",
          apiName: "SetGlobalResourceValuationSetting",
          responseUnsuccessful: setGlobalValuationSettingAction?.unsuccessful,
          responseMessage: setGlobalValuationSettingAction?.message,
        });
      }
    }
  }

  getMessageBodyToCreateResource(data) {
    return {
      name: data.resourceName,
      abbreviation: data.abbreviation,
      scientificName: data.scientificName,
      resourceTypeEnumId: data.resourceType,
      resourceSubTypeEnumId: data.resourceSubType ? data.resourceSubType : null,
      startDate: new Date(),
      endDate: null,
      taxonomyNumber: data.taxonomyNumber,
      taxonomyParentNumber: data.taxonomyParentNumber,
      aouNumber: data.aouNumber,
      description: data.resourceDescription,
      globalValuationUnitOfMeasureEnumId: parseInt(data.resourceUom),
      globalValuationCurrencyCode: "USD",
      comments: data.resourceDescription,
      globalValuationSettingEnumId: this.state.apiValuationSettings.find(
        (x) => x.displayText === data.globalValuationSetting
      )?.id,
    };
  }

  getMessageBodyToEditResource(data, resourceData) {
    let resourceBody = {
      resourceUniqueIdentifier: data.resourceId,
      name: data.resourceName,
      abbreviation: data.abbreviation,
      scientificName: data.scientificName,
      resourceTypeEnumId: data.resourceType,
      resourceSubTypeEnumId: data.resourceSubType ? data.resourceSubType : null,
      startDate: new Date(data.startDate)?.toISOString(),
      endDate: new Date(data.endDate)?.toISOString(),
      taxonomyNumber: data.taxonomyNumber,
      taxonomyParentNumber: data.taxonomyParentNumber,
      aouNumber: data.aouNumber,
      description: data.resourceDescription,
      isAllowedGlobally: resourceData.isAllowedGlobally,
      globalValuationUnitOfMeasureEnumId: this.state.globalUOMEnum,
      globalValuationCurrencyCode: "USD",
      comments: data.resourceDescription,
    };
    return resourceBody;
  }

  objectRenameKey = (obj, keys) => {
    keys.forEach((key) => {
      obj[key[1]] = obj[key[0]];
      delete obj[key[0]];
    });

    return obj;
  };

  handleStateUoMs(item) {
    let { data, resourceData, resourceUOMs } = this.state;
    if (
      data.stateUOMsToAdd.length === 0 &&
      data.stateUOMsToRemove.length === 0 &&
      resourceData?.globalAllowedUnitOfMeasures
    ) {
      let chosenUOMs = resourceData.globalAllowedUnitOfMeasures.map((allowedUOM) => allowedUOM.unitOfMeasure.id);
      resourceUOMs.forEach((uom) => {
        if (chosenUOMs.find((enabledUOM) => enabledUOM === uom.id)) {
          data.stateUOMsToAdd.push({
            unitOfMeasureEnumId: uom.id,
            conversionFactor: 1,
          });
        } else {
          data.stateUOMsToRemove.push(uom.id);
        }
      });
    } else if (
      data.stateUOMsToAdd.length === 0 &&
      data.stateUOMsToRemove.length === 0 &&
      !resourceData?.globalAllowedUnitOfMeasures
    ) {
      resourceUOMs.forEach((uom) => {
        data.stateUOMsToRemove.push(uom.id);
      });
    }

    let uomEnumId = parseInt(item?.id);
    let existingEnabledIndex = data.stateUOMsToAdd.findIndex((uom) => uom.unitOfMeasureEnumId === uomEnumId);
    let existingDisabledIndex = data.stateUOMsToRemove.findIndex((uom) => uom === uomEnumId);
    if (existingEnabledIndex === -1) {
      data.stateUOMsToAdd.push({
        unitOfMeasureEnumId: uomEnumId,
        conversionFactor: 1,
      });
      data.stateUOMsToRemove.splice(existingDisabledIndex, 1);
    } else {
      data.stateUOMsToRemove.push(uomEnumId);
      data.stateUOMsToAdd.splice(existingEnabledIndex, 1);
    }
    this.setState({ data });
  }

  getValuationFieldStatus() {
    let output = "";
    if (this.state.data.valuationRequiredEnumId === ValuationRequiredEnum.None) {
      output = "readOnly";
    } else if (this.state.data.valuationRequiredEnumId === ValuationRequiredEnum.Mandatory) {
      output = "required";
    }
    return output;
  }

  render() {
    const { formType } = this.props;
    let valuationSettingButtonLabels = this.state.apiValuationSettings.map((x) => x.displayText);

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="container w-75">
          <h4 className="text-left">{formType === "edit" ? "Edit" : "Create"} Resource</h4>
          <Card className="my-4">
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Resource Information</span>
            </Card.Header>
            <Card.Body>
              <div className="mx-2">
                <Row xs={1} md={2}>
                  <Col>{this.renderInput("resourceId", "Resource ID", "text", "", "readOnly")}</Col>
                  <Col>{this.renderInput("resourceName", "Resource Name", "text", "", "required")}</Col>
                </Row>
                <Row xs={1} md={2}>
                  <Col>{this.renderInput("scientificName", "Resource Scientific Name")}</Col>
                  <Col></Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
          <Card className="my-4">
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Resource Categorization</span>
            </Card.Header>
            <Card.Body>
              <div className="mx-2">
                <Row xs={1} md={2}>
                  <Col>
                    {this.renderSelect("resourceType", "Resource Type", this.state.resourceTypes, "", "required")}
                  </Col>
                  <Col>{this.renderSelect("resourceSubType", "Resource SubType", this.state.resourceSubTypes)}</Col>
                </Row>
                <Row xs={1} md={2}>
                  <Col>
                    {this.renderSelect(
                      "resourceUom",
                      "UOM",
                      this.state.resourceUOMs,
                      "Select Unit of Measure",
                      "required"
                    )}
                  </Col>
                  <Col style={{ textAlign: "left" }}>
                    <label className="formLabel" htmlFor="uomStateDropdown">
                      State Allowed UOMs
                    </label>
                    <Dropdown data-testid="uomStateDropdown" id="uomStateDropdown">
                      <Dropdown.Toggle variant="light">Change State Allowed UOMs</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className={styles.dropdownToggle} data-toggle="button">
                          {this.state.resourceUOMs.map((item, index) => (
                            <div className="ml-3 mr-5 my-1" key={index}>
                              <input
                                tabIndex="0"
                                title={item.name + " checkbox"}
                                type="checkbox"
                                className={styles.dropdownCheckbox}
                                id={item.id}
                                key={item.id}
                                data-toggle="button"
                                checked={this.state.data.stateUOMsToAdd.find(
                                  (uom) => uom.unitOfMeasureEnumId === item.id
                                )}
                                onChange={() => this.handleStateUoMs(item)}
                              />
                              <Dropdown.Item
                                eventKey={item.id}
                                key={`formChecklistContainerUses-${item.name}`}
                                onSelect={() => this.handleStateUoMs(item)}
                              >
                                <p className="form-check-label ml-2" htmlFor="itemChecked">
                                  {item.displayText || item.name}
                                </p>
                              </Dropdown.Item>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
          <Card className="my-4">
            <Card.Header className={globalStyles.cardTitles}>
              <span className={globalStyles.cardTitleText}>Additional Information</span>
            </Card.Header>
            <Card.Body>
              <div className="mx-2">
                <Row xs={1} md={2}>
                  <Col>
                    {this.renderInput(
                      "resourceValue",
                      "Resource Value",
                      "text",
                      "Enter Resource Value",
                      this.getValuationFieldStatus()
                    )}
                  </Col>
                  <Col>
                    {this.renderInput(
                      "valuationYear",
                      "Resource Year",
                      "text",
                      "Enter Resource Year",
                      this.getValuationFieldStatus()
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* {this.renderInput(
                      "resourceDescription",
                      "Resource Description",
                      "text",
                      "Enter Resource Description",
                      this.getValuationFieldStatus()
                    )} */}
                    <Form.Group
                      title="Enter Resource Description"
                      controlid="formResourceDescription"
                      className="text-left"
                    >
                      <Form.Label className="formLabel text-left" htmlFor="resourceDescription">
                        Resource Description
                      </Form.Label>
                      <Form.Control
                        value={this.state.data.resourceDescription}
                        id="resourceDescription"
                        title="Resource Description"
                        as="textarea"
                        maxLength="155"
                        placeholder="Enter Description"
                        name="resourceDescription"
                        onChange={this.handleChange}
                        className="form-control formData"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {this.renderInlineCheckBoxSet(
                      "globalValuationSetting",
                      "Global Valuation Setting",
                      valuationSettingButtonLabels,
                      "radio",
                      this.state.data.valuationRequiredEnumId,
                      ["required"]
                    )}
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
          {formType === "edit" && (
            <Card className="my-4">
              <Card.Body>
                <div className="mx-2">
                  <Row xs={1} md={2}>
                    <Col>{this.renderInput("dateAdded", "Date Added", "date", "", "readOnly")}</Col>
                    <Col>{this.renderInput("dateLastUpdated", "Date Last Updated", "date", "", "readOnly")}</Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          )}
          <div className="text-right">
            <Button
              className={globalStyles.modalSubmitButton}
              variant="outline-primary"
              onClick={() =>
                this.props.history.push({
                  pathname: "/referencefiles",
                  state: { tab: "resourcesTab", globalData: this.props.history.location.state.globalData },
                })
              }
            >
              Cancel
            </Button>
            <Button className={globalStyles.modalSubmitButton} type="submit" variant="primary">
              <span className={globalStyles.modalSubmitButtonText}>{formType === "edit" ? "Update" : "Create"}</span>
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(ResourceForm);
